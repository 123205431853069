<app-dialog></app-dialog>
<div class="header">
    <div class="header__page-name">
      <h1 class="heading heading--main">System Accounts</h1>
    </div>
    <div class="header__top-nav">
      <div class="top-menu" >

        <div class="top-menu__user-stat">
          <span>Active Accounts</span>{{ userCount }} accounts
        </div>
      
        <div class="top-menu__menu">
        </div>
      
        <div class="top-menu__search">
      
          <div class="search-wrapper">
      
            <div class="sarch-box">
      
              <input type="text" [(ngModel)]="searchValue" (keyup)="searchString($event)" />
            </div>
      
          </div>
      
          <div class="add-user">
            <!-- [skipLocationChange]="true" -->
            <a [routerLink]="['/add/systemaccount']" class="btn btn--blue btn--mini">
              Add New Account
            </a>
      
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper--dash-content dash-content">
    <div *ngIf="errorString" class="message message--is-invalid">
      {{errorString}}
    </div>
  
    <table datatable [dtOptions]="dtOptions" class="row-border hover" id="systemaccount">
        <thead>
          <tr>
            <th>Label</th>
            <th>Email Address</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
      </table>
  </div>
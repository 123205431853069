/* eslint-disable @typescript-eslint/explicit-member-accessibility */
export class DoctorAvailiabilityModel {
    doctor_availability_id: number;
    doctor_id: number;
    available_date: string;
    start_time: string;
    end_time: string;
    updated_at: string;
    remarks: string;
    timezone: string;

    constructor() {
      this.updated_at='';
      this.remarks='';
      this.timezone='';
    }
}

<app-dialog></app-dialog>
<app-partner-create></app-partner-create>
<div class="row">
	<div class="col col__md-8 single-centered-col">
		<h2>{{title}}</h2>
		<form [formGroup]="partnerForm" novalidate (ngSubmit)="createPartner(partnerForm, $event)">
			<div class="box box--white box--form box--radius">
				<div class="form">
					<h3 class="form__title">Basic Information </h3>
					<div class="row">
						<div class="col col__md-6 gutter--right-2x">

							<div class="form__row">
								<label for="" class="form__label">Client Name</label>
								<div class="form__field">
									<input formControlName="partner_name" type="text" [appFormatOnly]='"trim"' />
									<!-- <input formControlName="partner_name" type="text" (change)="checkForPartner(this.partnerForm.controls['partner_name'].value,partnerModel.partner_id, $event)"/> -->
									<div *ngIf="partnerForm.controls['partner_name'].invalid && (partnerForm.controls['partner_name'].dirty || partnerForm.controls['partner_name'].touched)"
										class="message message--is-invalid">
										<div *ngIf="partnerForm.controls['partner_name'].errors.required">
											Client Name is required.
										</div>
										<div *ngIf="partnerForm.controls['partner_name'].errors.formulaInjection ">
											Please remove any unauthorized characters at the beginning or end.
										  </div>
									</div>
									<div class="message message--is-invalid"
										*ngIf="partnerForm.controls['partner_name'].invalid && partnerForm.controls['partner_name'].errors.maxlength">
										Client name must not exceed
										{{partnerForm.controls['partner_name'].errors.maxlength.requiredLength}}
										characters.
									</div>

								</div>
							</div>

						</div>

						<div class="col col__md-6">

							<div class="form__row">
								<label for="" class="form__label">Client's Report Cover</label>

								<div class="form__field upload">
									<div class="col col__md-4" *ngIf="!uploader?.isUploading">

										<input type="file" accept="image/*" class="btn btn--blue btn--small"
											name="photo" ng2FileSelect [uploader]="uploader" />
										<a href="#" class="btn btn--blue btn--small btn--full-width">Upload</a>

									</div>

									<div class="col col__md-4" *ngIf="uploader.isUploading">

										<a class="btn btn--blue btn--small btn--full-width is-loading">
											<span class="icon icon--loading "></span>
										</a>

									</div>
								</div>

							</div>


						</div>
					</div>
					<div class="row">
						<div class="col col__md-6 gutter--right-2x">

							<div class="form_row">
								<div class="col col__md-6 gutter--right-2x">
									<div class="form__row">
										<label for="" class="form__label">Organization Type</label>
										<div class="form__field">
											<select formControlName="partner_organization_id">
												<option *ngFor="let organizationType of organizationTypeList"
													[ngValue]="organizationType.organization_id">
													{{organizationType.organization_type}}</option>
											</select>
											<div *ngIf="partnerForm.controls['partner_organization_id'].invalid && (partnerForm.controls['partner_organization_id'].dirty || partnerForm.controls['partner_organization_id'].touched)"
												class="message message--is-invalid">
												<div
													*ngIf="partnerForm.controls['partner_organization_id'].errors.required">
													Client Organization is required.
												</div>
											</div>

										</div>
									</div>
								</div>
								<div class="col col__md-6">
									<div class="form__row">
										<label for="" class="form__label">Case Type</label>
										<div class="form__field">
											<select formControlName="case_type_id">
												<option *ngFor="let planType of planTypeList"
													value="{{planType.plan_id}}">{{planType.plan_type}}</option>
											</select>
											<div *ngIf="partnerForm.controls['case_type_id'].invalid && (partnerForm.controls['case_type_id'].dirty || partnerForm.controls['case_type_id'].touched)"
												class="message message--is-invalid">
												<div *ngIf="partnerForm.controls['case_type_id'].errors.required">
													Case Type is required.
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- <div class="form__row" hidden>
								<div class="form__row">
									<label for="" class="form__label">Phrase Text</label>
									<div class="form__field">
										<textarea formControlName="phrase_text"></textarea>
										<div class="message message--is-invalid" *ngIf="partnerForm.controls['phrase_text'].invalid && partnerForm.controls['phrase_text'].errors.maxlength">
											Phrase text must not exceed {{partnerForm.controls['phrase_text'].errors.maxlength.requiredLength}} characters.
										</div>
									</div>
								</div>
							</div> -->

							<div class="form__row">
								<div class="form__field">
									<input type="checkbox" text="Display Specialist" value="Display Specialist">
									<div class="form__field--checkbox">
										<input type="checkbox" formControlName="display_specialist"
											id="display_specialist" />
										<label for="display_specialist">Display Specialist</label>
									</div>
								</div>
							</div>
							<div class="form__row">
								<label for="" class="form__label">Scan Link</label>
								<div class="form__field">
									<input formControlName="scan_link" type="text" [appFormatOnly]='"trimonly"' />
									<!-- <input formControlName="partner_name" type="text" (change)="checkForPartner(this.partnerForm.controls['partner_name'].value,partnerModel.partner_id, $event)"/> -->
									<div *ngIf="partnerForm.controls['scan_link'].invalid && (partnerForm.controls['scan_link'].dirty || partnerForm.controls['scan_link'].touched)"
										class="message message--is-invalid">
										<div *ngIf="partnerForm.controls['scan_link'].errors.required">
											Scan link is required.
										</div>
										<div *ngIf="partnerForm.controls['scan_link'].errors?.formulaInjection">
											Please remove any unauthorized characters at the beginning or end.
										  </div>
									</div>

								</div>
							</div>
							<div class="form__row">
								<label for="" class="form__label">Release Form Link</label>
								<div class="form__field">
									<input formControlName="release_form_link" type="text" [appFormatOnly]='"trimonly"' />
									<div *ngIf="partnerForm.controls['release_form_link'].invalid && (partnerForm.controls['release_form_link'].dirty || partnerForm.controls['release_form_link'].touched)"
										class="message message--is-invalid">
										<div *ngIf="partnerForm.controls['release_form_link'].errors.required">
											Release form link is required.
										</div>
										<div *ngIf="partnerForm.controls['release_form_link']?.errors?.formulaInjection">
											Please remove any unauthorized characters at the beginning or end.
										  </div>
									</div>

								</div>
							</div>

							<div class="form__row">
								<label for="" class="form__label">Ally Client Reference</label>
								<div class="form__field">
									<input formControlName="allyPartnerRef" type="text" [appFormatOnly]='"trimonly"' />
									<div *ngIf="partnerForm.controls['allyPartnerRef'].invalid && (partnerForm.controls['allyPartnerRef'].dirty || partnerForm.controls['allyPartnerRef'].touched)"
										class="message message--is-invalid">
										<div *ngIf="partnerForm.controls['allyPartnerRef']?.errors?.formulaInjection">
											Please remove any unauthorized characters at the beginning or end.
										  </div>
										</div>
								</div>
							</div>

						</div>

						<div class="col col__md-6">

							<div class="img-wrapper" [class.heightFix]="partnerBannerImage == null">
								<div *ngIf="partnerBannerImage == null" class="information"> No Photo uploaded</div>
								<img *ngIf="partnerBannerImage != null" [src]="partnerBannerImage" />
							</div>
						</div>
					</div>
					<div class="hr hr--space-up-x hr--space-down-x"></div>
					<div class="row">
						<div class="col col__md-6 gutter--right-2x">

							<div class="form__row">
								<label for="" class="form__label">Client's Logo</label>

								<div class="form__field upload">
									<div class="col col__md-4" *ngIf="!logoUploader?.isUploading">

										<input type="file" accept="image/*" class="btn btn--blue btn--small"
											name="photo" ng2FileSelect [uploader]="logoUploader" />
										<a href="#" class="btn btn--blue btn--small btn--full-width">Upload</a>

									</div>

									<div class="col col__md-4" *ngIf="logoUploader.isUploading">

										<a class="btn btn--blue btn--small btn--full-width is-loading">
											<span class="icon icon--loading "></span>
										</a>

									</div>
								</div>

							</div>


						</div>

						<div class="col col__md-6">
							<div class="img-wrapper" [class.heightFixLogo]="partnerLogoImage == null">
								<div *ngIf="partnerLogoImage == null" class="informationLogo">No Client logo uploaded
								</div>
								<img *ngIf="partnerLogoImage != null" [src]="partnerLogoImage" />
							</div>
						</div>
					</div>
					<div class="hr hr--space-up-x hr--space-down-x"></div>
					<div class="row">
						<div class="col col__md-4">
							<div class="form__row">
								<label for="" class="form__label">Start Date</label>
								<div class="form__field">
									<div class="form__field--date">
										<input formControlName="start_date" [owlDateTime]="dt3" (dateTimeChange)="emitValue()" readonly>
										<span class="trigger  icon icon--calendar" [owlDateTimeTrigger]="dt3"></span>
										<owl-date-time [pickerType]="'calendar'" #dt3></owl-date-time>
										<!-- <input type="date" formControlName="start_date" /> -->
									</div>
									<div *ngIf="partnerForm.controls['start_date'].invalid && (partnerForm.controls['start_date'].dirty || partnerForm.controls['start_date'].touched)"
										class="message message--is-invalid">
										<div *ngIf="partnerForm.controls['start_date'].errors.required">
											Start Date is required.
										</div>
									</div>
									<div *ngIf="dateInvalid" class="message message--is-invalid">
										Start Date should be less than End date.
									</div>
								</div>
							</div>
						</div>
						<div class="col col__md-2">
							<div class="date--start-end">-</div>
						</div>
						<div class="col col__md-4">
							<div class="form__row">
								<label for="" class="form__label">End Date</label>
								<div class="form__field">
									<div class="form__field--date">
										<input formControlName="end_date" [owlDateTime]="dt4" (dateTimeChange)="emitValue()" readonly>
										<span class="trigger  icon icon--calendar" [owlDateTimeTrigger]="dt4"></span>
										<owl-date-time [pickerType]="'calendar'" #dt4></owl-date-time>
										<!-- <input type="date" formControlName="end_date" /> -->
									</div>
									<div *ngIf="partnerForm.controls['end_date'].invalid && (partnerForm.controls['end_date'].dirty || partnerForm.controls['end_date'].touched)"
										class="message message--is-invalid">
										<div *ngIf="partnerForm.controls['end_date'].errors.required">
											End Date is required.
										</div>
									</div>
									<div *ngIf="dateInvalid" class="message message--is-invalid">
										End Date should be greater than Start date.
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="form__row">
							<label for="" class="form__label">Notification Emails</label>
							<div class="form__field">
								<textarea formControlName="notification_email"></textarea>
								<div *ngIf="partnerForm.controls['notification_email'].invalid && (partnerForm.controls['notification_email'].dirty || partnerForm.controls['notification_email'].touched)"
										class="message message--is-invalid">
										
								<div *ngIf="partnerForm.controls['notification_email']?.errors?.formulaInjection">
									Please remove any unauthorized characters at the beginning or end.
								</div>
								</div>
							</div>
						</div>
					</div>
					<div class="hr hr--space-up-x hr--space-down-x"></div>
					<h3 class="form__title">Primary POC</h3>
					<div class="row">
						<div class="col col__md-6 gutter--right-2x">
							<div class="form__row">
								<label for="" class="form__label">Full Name</label>
								<div class="form__field">
									<input formControlName="primary_poc_fullname" type="text"
										[appFormatOnly]='"trim"' />

									<div *ngIf="partnerForm.controls['primary_poc_fullname'].invalid && (partnerForm.controls['primary_poc_fullname'].dirty || partnerForm.controls['primary_poc_fullname'].touched)"
										class="message message--is-invalid">
										<div *ngIf="partnerForm.controls['primary_poc_fullname'].errors.required">
											Primary POC Name is required.
										</div>
										<div *ngIf="partnerForm.controls['primary_poc_fullname']?.errors?.formulaInjection">
											Please remove any unauthorized characters at the beginning or end.
										</div>
									</div>
									<!-- <div class="message message--is-invalid" *ngIf="partnerForm.controls['primary_poc_fullname'].errors.maxength">Primary POC name must not exceed {{partnerForm.controls['primary_poc_fullname'].errors.maxlength.requiredLength}} characters.</div> -->
								</div>
							</div>
						</div>
						<div class="col col__md-6">
							<div class="form__row">
								<label for="" class="form__label">Title/Function</label>
								<div class="form__field">
									<input formControlName="primary_poc_title" type="text" [appFormatOnly]='"trim"' />
									<div *ngIf="partnerForm.controls['primary_poc_title'].invalid && (partnerForm.controls['primary_poc_title'].dirty || partnerForm.controls['primary_poc_title'].touched)"
									class="message message--is-invalid">
									<div *ngIf="partnerForm.controls['primary_poc_title']?.errors?.formulaInjection">
										Please remove any unauthorized characters at the beginning or end.
									</div>
									</div>
								
									<!-- <div class="message message--is-invalid" *ngIf="partnerForm.controls['primary_poc_title'].errors.maxlength">Primary POC title / function must not exceed {{partnerForm.controls['primary_poc_title'].errors.maxlength.requiredLength}} characters.</div> -->
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col col__md-6 gutter--right-2x">
							<div class="form__row">
								<label for="" class="form__label">Phone Number</label>
								<div class="form__field">
									<input formControlName="primary_poc_number" type="text" maxlength="15"
										PhoneNumber />
									<div *ngIf="primary_poc_number.touched && primary_poc_number.invalid"
										class="message message--is-invalid">
										<div *ngIf="primary_poc_number.errors.minlength">Please enter a valid phone
											number</div>
										<div *ngIf="primary_poc_number.errors.maxlength">Please enter a valid phone
											number</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col col__md-6">
							<div class="form__row">
								<label for="" class="form__label">Email</label>
								<div class="form__field">
									<input formControlName="primary_poc_email" type="text" [appFormatOnly]='"email"' />
									<div *ngIf="primary_email.touched && primary_email.invalid && !primary_email.errors.required"
										class="message message--is-invalid">
										<div *ngIf="primary_email.errors.email && !primary_email.errors.required">Enter
											a valid email</div>
											<div *ngIf="partnerForm.controls['primary_poc_email']?.errors?.formulaInjection">
												Please remove any unauthorized characters at the beginning or end.
											</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="hr hr--space-up-x hr--space-down-x"></div>
					<h3 class="form__title">Secondary POC</h3>
					<div class="row">
						<div class="col col__md-6 gutter--right-2x">
							<div class="form__row">
								<label for="" class="form__label">Full Name</label>
								<div class="form__field">
									<input formControlName="secondary_poc_fullname" type="text"
										[appFormatOnly]='"trim"' />
										<div *ngIf="secondary_poc_fullname_control.touched && secondary_poc_fullname_control.invalid && !secondary_poc_fullname_control.errors.required"
										class="message message--is-invalid">
										<div *ngIf="partnerForm.controls['secondary_poc_fullname']?.errors?.formulaInjection">
											Please remove any unauthorized characters at the beginning or end.
										</div>
										</div>
										
									<!-- <div class="message message--is-invalid" *ngIf="partnerForm.controls['secondary_poc_fullname'].errors.maxlength">Secondary POC name must not exceed {{partnerForm.controls['secondary_poc_fullname'].errors.maxlength.requiredLength}} characters.</div> -->
								</div>
							</div>
						</div>
						<div class="col col__md-6">
							<div class="form__row">
								<label for="" class="form__label">Title/Function</label>
								<div class="form__field">
									<input formControlName="secondary_poc_title" type="text" [appFormatOnly]='"trim"' />
									<div *ngIf="secondary_poc_title_control.touched && secondary_poc_title_control.invalid && !secondary_poc_title_control.errors.required"
										class="message message--is-invalid">
										<div *ngIf="partnerForm.controls['secondary_poc_title']?.errors?.formulaInjection">
										Please remove any unauthorized characters at the beginning or end.
										</div>
										</div>
									
									<!-- <div class="message message--is-invalid" *ngIf="partnerForm.controls['secondary_poc_title'].errors.maxlength">Secondary POC title / function must not exceed {{partnerForm.controls['secondary_poc_title'].errors.maxlength.requiredLength}} characters.</div> -->
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col col__md-6 gutter--right-2x">
							<div class="form__row">
								<label for="" class="form__label">Phone Number</label>
								<div class="form__field">
									<input formControlName="secondary_poc_number" type="text" maxlength="15"
										PhoneNumber />
									<div *ngIf="secondary_poc_number.touched && secondary_poc_number.invalid"
										class="message message--is-invalid">
										<div *ngIf="secondary_poc_number.errors.minlength">Please enter a valid phone
											number</div>
										<div *ngIf="secondary_poc_number.errors.maxlength">Please enter a valid phone
											number numbers.
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col col__md-6">
							<div class="form__row">
								<label for="" class="form__label">Email</label>
								<div class="form__field">
									<input formControlName="secondary_poc_email" type="text"
										[appFormatOnly]='"email"' />
									<div *ngIf="secondary_email.touched && secondary_email.invalid"
										class="message message--is-invalid">
										<div *ngIf="secondary_email.errors.email && !secondary_email.errors.required">
											Enter a valid email</div>
											<div *ngIf="partnerForm.controls['secondary_poc_fullname']?.errors?.formulaInjection">
											Please remove any unauthorized characters at the beginning or end.
											</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="hr hr--space-up-x hr--space-down-x"></div>
					<h3 class="form__title">Service Type</h3>
					<div class="row">
						<div class="col col__md-6 gutter--right-2x">
							<div class="form__row">
								<div class="form__field">
									<div class="form__field--checkbox">
										<input type="checkbox" formControlName="serviceA"
											id="serviceA" />
										<label for="serviceA">Offering A</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col col__md-6">
							<div class="form__row">
								<div class="form__field">
									<div class="form__field--checkbox">
										<input type="checkbox" formControlName="serviceB"
											id="serviceB" />
										<label for="serviceB">Offering B</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col col__md-6 gutter--right-2x">
							<div class="form__row">
								<div class="form__field">
									<div class="form__field--checkbox">
										<input type="checkbox" formControlName="serviceC"
											id="serviceC" />
										<label for="serviceC">Offering C</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col col__md-6">
							<div class="form__row">
								<div class="form__field">
									<div class="form__field--checkbox">
										<input type="checkbox" formControlName="serviceD"
											id="serviceD" />
										<label for="serviceD">Offering D</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col col__md-6 gutter--right-2x">
							<div class="form__row">
								<div class="form__field">
									<div class="form__field--checkbox">
										<input type="checkbox" formControlName="serviceE"
											id="serviceE" />
										<label for="serviceE">Offering E</label>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="hr hr--space-up-x hr--space-down-x"></div>
					<h3 class="form__title">Eligibility Method</h3>
					<div class="row">
						<div class="col col__md-6 gutter--right-2x">
							<div class="form__row">
								<div class="form__field">
									<div class="form__field--checkbox">
										<input type="checkbox" formControlName="first_name"
											id="first_name" />
										<label for="first_name">First Name</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col col__md-6">
							<div class="form__row">
								<div class="form__field">
									<div class="form__field--checkbox">
										<input type="checkbox" formControlName="last_name"
											id="last_name" />
										<label for="last_name">Last Name</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col col__md-6 gutter--right-2x">
							<div class="form__row">
								<div class="form__field">
									<div class="form__field--checkbox">
										<input type="checkbox" formControlName="dob"
											id="dob" />
										<label for="dob">Date of Birth</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col col__md-6">
							<div class="form__row">
								<div class="form__field">
									<div class="form__field--checkbox">
										<input type="checkbox" formControlName="gender"
											id="gender" />
										<label for="gender">Gender</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col col__md-6 gutter--right-2x">
							<div class="form__row">
								<div class="form__field">
									<div class="form__field--checkbox">
										<input type="checkbox" formControlName="company_name"
											id="company_name" />
										<label for="company_name">Company Name</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col col__md-6">
							<div class="form__row">
								<div class="form__field">
									<div class="form__field--checkbox">
										<input type="checkbox" formControlName="enableEmailDomain"
											id="enableEmailDomain" (click)="enableEmailDomainCheck()" />
										<label for="enableEmailDomain">Enable Email Domain</label>
										<div *ngIf="emailDomainCheck.touched && emailDomainCheck.invalid"
										class="message message--is-invalid">
										<div *ngIf="partnerForm.controls['enableEmailDomain']?.errors?.formulaInjection">
										Please remove any unauthorized characters at the beginning or end.
										</div>
										</div>
										
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col col__md-6 gutter--right-2x">
							<div class="form__row">
								<div class="form__field">
									<div class="form__field--checkbox">
										<input type="checkbox" formControlName="enableCode"
											id="enableCode" (click)="enableCodeCheck()"  />
										<label for="enableCode">Enable Code</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col col__md-6" *ngIf="emailDomainCheck.value">
							<div class="form__row">
								<div class="form__field">
									<div class="form__field--checkbox">
										<label for="email_domain">Email Domain <span class="message">(Example: gmail.com,consumermedical.com)</span></label>
										<input type="text" formControlName="email_domain"
											id="email_domain" (input)="is_email_domain_changed = true" />
										<div *ngIf="partnerForm.controls['email_domain'].invalid && (partnerForm.controls['email_domain'].dirty || partnerForm.controls['email_domain'].touched)"
											class="message message--is-invalid">
											<div *ngIf="partnerForm.controls['email_domain'].errors.required">
												Email Domain is required.
											</div>
											<div *ngIf="partnerForm.controls['email_domain']?.errors?.formulaInjection">
											Please remove any unauthorized characters at the beginning or end.
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row" *ngIf="codeCheck.value">
						<div class="col col__md-6 gutter--right-2x">
							<div class="form__row">
								<div class="form__field">
									<label for="code_value">Code Value</label>
									<input type="text" [appFormatOnly]='"trimonly"' formControlName="code_value" id="code_value" (input)="is_code_value_changed = true" />
									<div *ngIf="partnerForm.controls['code_value'].invalid && (partnerForm.controls['code_value'].dirty || partnerForm.controls['code_value'].touched)"
									class="message message--is-invalid">
									<div *ngIf="partnerForm.controls['code_value'].errors.required">
										Code Value is required.
									</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row" *ngIf="codeCheck.value">
						<div class="col col__md-5 gutter--right-2x">
							<div class="form__row">
								<label for="" class="form__label">Code Start Date</label>
								<div class="form__field">
									<div class="form__field--date">
										<input formControlName="code_start_date" [owlDateTime]="dt5" (dateTimeChange)="emitValue()" readonly>
										<span class="trigger  icon icon--calendar" [owlDateTimeTrigger]="dt5"></span>
										<owl-date-time [pickerType]="'calendar'" #dt5></owl-date-time>
									</div>
									<div *ngIf="partnerForm.controls['code_start_date'].invalid && (partnerForm.controls['code_start_date'].dirty || partnerForm.controls['code_start_date'].touched)"
										class="message message--is-invalid">
										<div *ngIf="partnerForm.controls['code_start_date'].errors.required">
											Code Start Date is required.
										</div>
									</div>
									<div *ngIf="codeDateInvalid" class="message message--is-invalid">
										Code Start Date should be less than End date.
									</div>
									
								</div>
							</div>
						</div>
						<div class="col col__md-2">
							<div class="date--start-end">-</div>
						</div>
						<div class="col col__md-5">
							<div class="form__row">
								<label for="" class="form__label">Code Expire Date</label>
								<div class="form__field">
									<div class="form__field--date">
										<input formControlName="code_expiry_date" [owlDateTime]="dt6" (dateTimeChange)="emitValue()" readonly>
										<span class="trigger  icon icon--calendar" [owlDateTimeTrigger]="dt6"></span>
										<owl-date-time [pickerType]="'calendar'" #dt6></owl-date-time>
									</div>
										<div *ngIf="partnerForm.controls['code_expiry_date'].invalid && (partnerForm.controls['code_expiry_date'].dirty || partnerForm.controls['code_expiry_date'].touched)"
										class="message message--is-invalid">
											<div *ngIf="partnerForm.controls['code_expiry_date'].errors.required">
												Code Expiry Date is required.
											</div>
										</div>
										<div *ngIf="codeDateInvalid" class="message message--is-invalid">
											Code Expiry Date should be greater than Start date.
										</div>

										<div *ngIf="codeStartDateInvalid" class="message message--is-invalid">
											Code End Date should be greater than the current date.
										</div>
									
								</div>
							</div>
						</div>
					</div>

					<app-partner-setting [settingForm]="partnerForm"></app-partner-setting>
				</div>
			</div>
			<div class="btns-row text-center">
				<button href="#" class="btn btn--green" type="submit">{{label}}</button>
			</div>
		</form>
	</div>
</div>

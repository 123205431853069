<ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand"
  let-onCheckedChange="onCheckedChange">
  <div class="form-inline row-item client-tree-item">
    <i *ngIf="item.children" class="mr-1" (click)="onCollapseExpand()" aria-hidden="true" [ngSwitch]="item.collapsed">
      <svg *ngSwitchCase="true" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-right-fill"
        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
      </svg>
      <svg *ngSwitchCase="false" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
      </svg>
    </i>
    <i *ngIf="!item.children && (item.text == 'Ally Clients' || item.text == 'Non Ally Clients')" class="mr-1" style="width: 0.8rem;"></i>
    <div class="form-check">
      <input type="checkbox" class="form-check-input" [(ngModel)]="item.checked" (ngModelChange)="onCheckedChange()"
        [disabled]="item.disabled" [indeterminate]="getIndeterminateChecked(item)" />
      <label class="form-check-label ellipsis" (click)="item.checked = !item.checked; onCheckedChange()">
        {{item.text}}
      </label>
    </div>
  </div>
</ng-template>
<ng-template #headerTemplate let-config="config" let-item="item" let-onCollapseExpand="onCollapseExpand"
  let-onCheckedChange="onCheckedChange" let-onFilterTextChange="onFilterTextChange">
  <div *ngIf="config.hasFilter" class="row row-filter">
    <div class="col-12">
      <input class="form-control" style="padding: 0.375rem 0.75rem;" type="text" [placeholder]="i18n.getFilterPlaceholder()" [(ngModel)]="filterText"
        (ngModelChange)="onFilterTextChange($event)" />
    </div>
  </div>
  <div *ngIf="config.hasAllCheckBox || config.hasCollapseExpand" class="row row-all" style="margin-top: 10px;">
    <div class="col-12">
      <div class="form-check form-check-inline" *ngIf="config.hasAllCheckBox">
        <input type="checkbox" class="form-check-input" [(ngModel)]="item.checked" (ngModelChange)="onCheckedChange()"
          [indeterminate]="item.indeterminate" />
        <label class="form-check-label" (click)="item.checked = !item.checked; onCheckedChange()">
          {{i18n.getAllCheckboxText()}}
        </label>
      </div>
      <label *ngIf="config.hasCollapseExpand" class="float-right form-check-label" (click)="onCollapseExpand()">
        <i [title]="i18n.getTooltipCollapseExpandText(item.collapsed)" aria-hidden="true" [ngSwitch]="item.collapsed">
          <svg *ngSwitchCase="true" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrows-angle-expand"
            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M1.5 10.036a.5.5 0 0 1 .5.5v3.5h3.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5z" />
            <path fill-rule="evenodd"
              d="M6.354 9.646a.5.5 0 0 1 0 .708l-4.5 4.5a.5.5 0 0 1-.708-.708l4.5-4.5a.5.5 0 0 1 .708 0zm8.5-8.5a.5.5 0 0 1 0 .708l-4.5 4.5a.5.5 0 0 1-.708-.708l4.5-4.5a.5.5 0 0 1 .708 0z" />
            <path fill-rule="evenodd"
              d="M10.036 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 1 1-1 0V2h-3.5a.5.5 0 0 1-.5-.5z" />
          </svg>
          <svg *ngSwitchCase="false" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrows-angle-contract"
            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M9.5 2.036a.5.5 0 0 1 .5.5v3.5h3.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5z" />
            <path fill-rule="evenodd"
              d="M14.354 1.646a.5.5 0 0 1 0 .708l-4.5 4.5a.5.5 0 1 1-.708-.708l4.5-4.5a.5.5 0 0 1 .708 0zm-7.5 7.5a.5.5 0 0 1 0 .708l-4.5 4.5a.5.5 0 0 1-.708-.708l4.5-4.5a.5.5 0 0 1 .708 0z" />
            <path fill-rule="evenodd"
              d="M2.036 9.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V10h-3.5a.5.5 0 0 1-.5-.5z" />
          </svg>
        </i>
      </label>
      <label lass="float-right form-check-label treeview-right" style="float: right; margin-right: 3px; cursor: pointer;margin-top: 5px;" (click)="reset()">Reset</label>
    </div>
  </div>
  <div *ngIf="config.hasDivider" class="dropdown-divider"></div>
</ng-template>

<div class="row">
  <div class="col-12">
    <div class="form-group" style="width: 348px;">
      <div>
        <ngx-dropdown-treeview [config]="config" [buttonClass]="'client-tree-view'" [items]="items"
          [itemTemplate]="itemTemplate" [headerTemplate]="headerTemplate" (selectedChange)="onSelectedChange($event)">
        </ngx-dropdown-treeview>
      </div>
    </div>
  </div>
</div>
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigAssetLoaderService } from '../services/config-asset-loader.service';
import { DoctorCaseQuestionAnswerModel } from '../domains/doctorCaseQuestionAnswerModel';
import { BaseDoctorOnePager } from '../domains/case/consultation/doctor-one-pager';
import { LoginService } from './login.service';
import { CareteamReport } from '../domains/case/general-case/careteam-report-update';
import { Socket } from 'ngx-socket-io';
import { CASE_TECHNICAL, getHeaderWithToken } from  './constant';

@Injectable()
export class CaseService {


  constructor(
		private http: HttpClient,
		private loginServce: LoginService,
		private socket: Socket
  ) {
  }

  addCase(caseObject: any): Observable<any> {

    if (caseObject && caseObject.earliest_available_date) {
      const earliestAvailableDate = caseObject.earliest_available_date.substring(0, 10);
      caseObject.earliest_available_date = earliestAvailableDate;
    }

    const currentUser = this.loginServce.getCurrentUser();

    if (currentUser.user_type.type.toLowerCase() === 'patient') {
      return this.http.post<any>(
        ConfigAssetLoaderService.getApiURL() + '/' + 'case/create/bymember',
        caseObject,
        this.getTokenHeader()
      );
    }
    else {
      return this.http.post<any>(
        ConfigAssetLoaderService.getApiURL() + '/' + 'case/create/bypartner',
        caseObject,
        this.getTokenHeader()
      );
    }
  }

  // method to convert the Video Consultation to Written Consultation
  convertCase(case_id: number, case_type: string) {
    const payload = {
      case_id,
      case_type,
    };

    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/case/convert',
      payload,
      this.getTokenHeader()
    );
  }

  getActiveUserForReleaseNotification(userType: number){
    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + '/users/activeCases/'+ userType,
      this.getTokenHeader()
    );
  }

  submitRequested(caseStatus: any): Observable<any> {

    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'case/requested',
      caseStatus,
      this.getTokenHeader()
    );
  }

  submitMdMgmt(mdMgmt: any): Observable<any> {

    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'case/mdMgmt',
      mdMgmt,
      this.getTokenHeader()
    );
  }

  revertToCompileMr(caseId: number,userId: number) {
    const data ={
      caseId: caseId,
      userId: userId
    };
    return this.http.put(ConfigAssetLoaderService.getApiURL() +'/cases/summary/revert', data, this.getTokenHeader());
  }

  
  createOrUpdateRequested(requestedValue: any, _caseId: number) {

    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'case_requested',
      requestedValue,
      this.getTokenHeader()
    );
  }

  createOrUpdateMdMgmt(mdMgmtValue: any, _caseId: number) {
    if (mdMgmtValue.primary_md) {
      delete mdMgmtValue['primary_md'];
    }

    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'case_mdMgmt',
      mdMgmtValue,
      this.getTokenHeader()
    );
  }

  createOrUpdateMdMgmtAsAdmin(case_id: number) {
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/admin/case_mdMgmt',
      { case_id },
      this.getTokenHeader()
    );
  }

  /**
 * This service is used to list out all the cases in the technical settings
 * @return {*}  {Observable<any>}
 * @memberof CaseService
 */
  listAllCases(): Observable<any> {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${CASE_TECHNICAL.LIST}`;
    return this.http.get<any>(URL, getHeaderWithToken());
  }

  /**
 * This service is used to list out all the cases in the technical settings
 * @return {*}  {Observable<any>}
 * @memberof CaseService
 */
  getCaseDetail(id: number): Observable<any> {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${CASE_TECHNICAL.GET}/${id}`;
    return this.http.get<any>(URL, getHeaderWithToken());
  }

  /**
 * This service is used to list out all the cases in the technical settings
 * @return {*}  {Observable<any>}
 * @memberof CaseService
 */
  updateCaseDetail(id: number, payload): Observable<any> {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${CASE_TECHNICAL.PUT}/${id}`;
    return this.http.put<any>(URL, payload, getHeaderWithToken());
  }

  getCaseById(id: number, user_id: number): Observable<any> {
    const url = ConfigAssetLoaderService.getApiURL() + `/case/${id}?id=${user_id}`;
   
    return this.http.get<any>(
      url,
      this.getTokenHeader()
    );
  }

  getCaseForPatient(id: number): Observable<any> {
    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'case/patient/' + id,
      this.getTokenHeader()
    );
  }

  getV1CaseForPatient(id: number): Observable<any> {
    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + '/v1/patient_details/' + id,
      this.getTokenHeader()
    );
  }


  fetchCaseMedications(id: number): Observable<any> {
    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'caseMedication/' + id,
      this.getTokenHeader()
    );
  }

  getDoctorCaseById(id: number, user_id: number): Observable<any> {
    const url = ConfigAssetLoaderService.getApiURL() + `/doctorCase/${id}?user_id=${user_id}`;
   
    const tokenHeader = this.getTokenHeader();
    tokenHeader['observe'] = 'response';
    return this.http.get<any>(
      url,
      tokenHeader
    ).pipe(
      map(
        result => {
          delete result['video_mp4'];
					
          result.body.case_questions.forEach(eachQuestion => {
            delete eachQuestion['video_mp4'];

            return eachQuestion;
          });

          return result;
        }
      )
    );
  }

  orderCaseAttachmentsByUploadStage(id: number, user_id: number): Observable<any> {

    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'doctorCaseAttachments/' + id + '?id=' + user_id,
      this.getTokenHeader()
    );
  }

  getCaseByPatientId(patient_id: number): Observable<any> {

    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'patient_case/' + patient_id,
      this.getTokenHeader()
    );
  }

  getAssigneeList(case_id: number): Observable<any> {

    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'case/assigned_users/' + case_id,
      this.getTokenHeader()
    );
  }

  getCaseMessages(case_id: number, to_user_id: number): Observable<any> {

    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'case/case_messages/' + case_id + '/' + to_user_id,
      this.getTokenHeader()
    );


  }

  getCaseMessagesForPartner(case_id: number, user_id: number): Observable<any> {

    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'case/case_messages_partner/' + case_id + '/' + user_id,
      this.getTokenHeader()
    );


  }

  getFinishedList(case_id: number): Observable<any> {
    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'case/casehistory/' + case_id,
      this.getTokenHeader()
    );
  }

  deleteDocument(deleteId: number) {

    return this.http.delete<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'case/delete/' + deleteId,
      this.getTokenHeader()
    );
  }

  deleteVideoLink(deleteId: number) {
    return this.http.delete<any>(
      ConfigAssetLoaderService.getApiURL() + '/v1/case-document/' + deleteId,
      this.getTokenHeader()
    );
  }

  cloneDocument(documentDetail: any) {
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'case-document/clone',
      documentDetail,
      this.getTokenHeader()
    );
  }

  /** This is the legacy download PDF service method
   * Adding the name legacy before the method.
   * We can delete in future if we do not need.
   */
  legacyDownloadPDF(documentId: number, documentName?: string): Observable<any> {
    const ext = documentName.split('.').pop();
    const imageMime = ['jpg', 'jpeg', 'png', 'gif', 'tif', 'bmp'];

    const header = this.getTokenHeader();
    header['responseType'] = 'blob';

    return this.http.get(
      ConfigAssetLoaderService.getApiURL() + '/' + 'case/download/' + documentId,
      header
    ).pipe(map(
      function (res) {
        if (this.ext == null) {
          return new Blob([res], { type: 'application/*' });
        }

        if (this.ext.toLowerCase() === 'pdf') {
          return new Blob([res], { type: 'application/pdf' });
        }

        if (this.ext.toLowerCase() === 'jpg') {
          return new Blob([res], { type: 'image/jpg' });
        }

        if (this.ext.toLowerCase() === 'jpeg') {
          return new Blob([res], { type: 'image/jpeg' });
        }

        if (this.ext.toLowerCase() === 'png') {
          return new Blob([res], { type: 'image/png' });
        }

        if (this.ext.toLowerCase() === 'gif') {
          return new Blob([res], { type: 'image/gif' });
        }

        if (this.ext.toLowerCase() === 'tif') {
          return new Blob([res], { type: 'image/tif' });
        }

        if (this.ext.toLowerCase() === 'bmp') {
          return new Blob([res], { type: 'image/bmp' });
        }

        return new Blob([res], { type: 'application/*' });


      }.bind({ ext: ext, imageMime: imageMime })
    ));
  }

  downloadPDF(documentId: number, _file: string, caseAssignmentId = 0 , isTest ?: boolean): Observable<any> {
    let url = ConfigAssetLoaderService.getApiURL() + `/case-document/download/${documentId}?isTest=${isTest}`;
    if (caseAssignmentId > 0) {
      url = ConfigAssetLoaderService.getApiURL() + `/case-document/download/${documentId}?assignmentId=${caseAssignmentId}&isTest=${isTest}`;
    }
    return this.http.get(
      url,
      this.getTokenHeader()
    );
  }

  addComment(comment: any) {
    this.socket.emit('new_comment',{comment,token: localStorage.getItem('token')});
  }

  markOldComment(case_id: number, user_id: number) {

    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'case/markOldComment',
      { case_id: case_id, user_id: user_id },
      this.getTokenHeader()
    );
  }

  saveOnePager(doctorResponse: BaseDoctorOnePager, isSubmit, manualSave = false): Observable<any> {
    const fullUrl = ConfigAssetLoaderService.getApiURL() + '/' + 'saveDoctorOnePager';

    doctorResponse['is_submit'] = isSubmit;
    doctorResponse['manualSave'] = manualSave;

    return this.http.post<any>(
      fullUrl,
      doctorResponse,
      this.getTokenHeader()
    );
  }

  saveDoctorResponse(doctorResponse: DoctorCaseQuestionAnswerModel): Observable<DoctorCaseQuestionAnswerModel> {
    const fullUrl = ConfigAssetLoaderService.getApiURL() + '/' + 'saveDoctorResponse';

    return this.http.post<any>(
      fullUrl,
      doctorResponse,
      this.getTokenHeader()
    );
  }

  saveMissingInformation(case_id: number, missing_information: string, sendEmail: boolean, manualSave = false) {
    const fullUrl = ConfigAssetLoaderService.getApiURL() + '/' + 'saveMissingInformation/' + case_id;
    const missing_info = { missing_information: missing_information, sendEmail: sendEmail, manualSave };

    return this.http.post<any>(
      fullUrl,
      missing_info,
      this.getTokenHeader()
    );
  }

  createOrUpdateTranslateMr(translateMRValue: any, _caseId: number) {
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'case_translate_mr',
      translateMRValue,
      this.getTokenHeader()
    );
  }

  createOrUpdateTranslateWr(translateWRValue: any, caseId: number) {
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'case_translate_wr/' + caseId, translateWRValue,
      this.getTokenHeader()
    );
  }

  createOrUpdateTranslateDr(translateDRValue: any, _caseId: number) {

    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'case_translate_dr',
      translateDRValue,
      this.getTokenHeader()
    );
  }

  createOrUpdateCaseSchedule(caseScheduleValue: any, _caseId: number) {

    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'case_schedule',
      caseScheduleValue,
      this.getTokenHeader()
    );
  }

  updateResponse(case_question_id: number, answer: string) {

    const abc = { answer: answer };
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'written/' + case_question_id,
      abc,
      this.getTokenHeader()
    );
  }

  updateVideoNotes(case_id: number, notes: string, doctor: any, linkUrl: string, identifier: string) {

    const abc = { video_notes: notes, doctor: doctor, linkUrl: linkUrl, id: case_id, identifier: identifier };
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'video',
      abc,
      this.getTokenHeader()
    );
  }

  updateSummary(_case_id: number, written_summary: string) {

    const abc = { written_summary: written_summary };
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'caseSummary',
      abc,
      this.getTokenHeader()
    );
  }

  changeCaseStatus(case_status: any) {
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'case/changeCaseStatus/',
      case_status,
      this.getTokenHeader()
    );
  }

  updateReportNotes(data) {
    const { case_id } = data;
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/v1/case/complete/' + case_id,
      data,
      this.getTokenHeader()
    );
  }

  updateStaus(statusList, caseId) {

    return this.http.patch<any>(
      ConfigAssetLoaderService.getApiURL() + '/v1/caseStatus/case/' + caseId,
      statusList,
      this.getTokenHeader()
    );
  }

  updateDocumentType(reqBody) {

    return this.http.patch<any>(
      ConfigAssetLoaderService.getApiURL() + '/v1/document',
      reqBody,
      this.getTokenHeader()
    );
  }

  updateVideoLink(reqBody) {
    return this.http.patch<any>(
      ConfigAssetLoaderService.getApiURL() + '/v1/caseDocument/videolink',
      reqBody,
      this.getTokenHeader()
    );
  }

  generatePDF(caseDetail) {

    const requestPayload = {};
    requestPayload['written_summary'] = caseDetail.written_summary;
    requestPayload['id'] = caseDetail.id;
    requestPayload['case_questions'] = caseDetail.case_questions;
    requestPayload['comment_summary'] = caseDetail.comment_summary;
    requestPayload['case_medication'] = caseDetail.written_summary;
    requestPayload['patient_last_name'] = caseDetail.patient.user.last_name;
    requestPayload['patient_first_name'] = caseDetail.patient.user.first_name;
    requestPayload['partner_careteam_email'] = caseDetail.user.email;
    requestPayload['partner_careteam_fullname'] = caseDetail.user.full_name;
    requestPayload['partner_careteam_lastname'] = caseDetail.user.last_name;
    requestPayload['send_email'] = true;
    requestPayload['linkUrl'] = ConfigAssetLoaderService.getDomainURL() + '/' + 'case/' + caseDetail.id;
    requestPayload['case_type_id'] = caseDetail.patient.user.user_partners && caseDetail.patient.user.user_partners[0].partner && caseDetail.patient.user.user_partners[0].partner.case_type_id;

    const header = this.getTokenHeader();
    header['observe'] = 'response';

    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'generatePDF',
      requestPayload,
      header
    );
  }

  getCaseStatuses(caseId) {

    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'cases/currentstatus/' + caseId,
      this.getTokenHeader()
    );
  }

  getCaseQuestions(caseId, caseAssignmentId = 0) {
    let url = ConfigAssetLoaderService.getApiURL() + `/v1/cases/caseQuestionsAnswers/${caseId}`;
    if (caseAssignmentId > 0) {
      url = ConfigAssetLoaderService.getApiURL() + `/v1/cases/caseQuestionsAnswers/${caseId}?assignmentId=${caseAssignmentId}`;
    }
    return this.http.get<any>(
      url,
      this.getTokenHeader()
    );
  }

  getCaseDocuments(caseId, caseAssignmentId = 0) {
    let url = ConfigAssetLoaderService.getApiURL() + `/case/casedocuments/${caseId}`;
    if (caseAssignmentId > 0) {
      url = ConfigAssetLoaderService.getApiURL() + `/case/casedocuments/${caseId}?assignmentId=${caseAssignmentId}`;
    }
    return this.http.get<any>(
      url,
      this.getTokenHeader()
    );
  }

  sendEmailToMD(payload: {caseid: number, email_template: string, isFollowUpEmail?: boolean}) {
    const template = { isFollowUpEmail: payload.isFollowUpEmail, email_template: payload.email_template };
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + `/email/${payload.caseid}`,
      template,
      this.getTokenHeader()
    );
  }

  sendEmailInSummary(case_id: number, doctor: any, linkUrl: string, identifier: string) {
    const abc = { doctor: doctor, linkUrl: linkUrl, id: case_id, identifier: identifier };
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'emailInSummary/' + case_id,
      abc,
      this.getTokenHeader()
    );
  }

  addScanList(scan) {

    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'add/scans/',
      scan,
      this.getTokenHeader()
    );
  }

  getStatus(userType: string) {
    let status = null;

    switch (true) {
    case (userType.toLowerCase() === 'admin' || userType.toLowerCase() === 'careteam' || userType.toLowerCase() === 'casecoordinator' || userType.toLowerCase() === 'mdmanager' || userType.toLowerCase() === 'cmmanager'): {
      status = [
        {
          id: 1,
          status: 'Requested'
        },
        {
          id: 2,
          status: 'MD Management'
        },
        {
          id: 3,
          status: 'Compile MR'
        },
        {
          id: 6,
          status: 'Schedule'
        },
        {
          id: 7,
          status: 'Video'
        },
        {
          id: 8,
          status: 'Summary'
        },
        {
          id: 9,
          status: 'Translate DR'
        },
        {
          id: 10,
          status: 'Report'
        },
        {
          id: 'HOLD',
          status: 'Hold'
        },
        {
          id: 'CANCELLED',
          status: 'Cancelled'
        }
      ];
      break;
    }
    case (userType.toLowerCase() === 'doctor'): {
      status = [
        // {
        // 	id: 'REQUESTED',
        // 	status: 'Requested'
        // },
        {
          id: 'READYFORREVIEW',
          status: 'Ready for review'
        },
        {
          id: 'SCHEDULED',
          status: 'Scheduled'
        },
        {
          id: 'REPORTPENDING',
          status: 'Report Pending'
        },
        {
          id: 'FINISHED',
          status: 'Finished'
        },
        {
          id: 'HOLD',
          status: 'Hold'
        }
      ];
      break;
    }
    case (userType.toLowerCase() === 'partner'): {
      status = [
        {
          id: 1,
          status: 'Requested'
        },
        {
          id: 2,
          status: 'Md Management'
        },
        {
          id: 'PROCESSING',
          status: 'Processing'
        },
        {
          id: 7,
          status: 'Video'
        },
        {
          id: 'REVIEWING',
          status: 'Reviewing'
        },
        {
          id: 10,
          status: 'Report'
        },
        {
          id: 'HOLD',
          status: 'Hold'
        },
        {
          id: 'CANCELLED',
          status: 'Cancelled'
        }
      ];
      break;
    }
    default: {
      return [];
    }
    }

    return status;

  }

  updateQuestions(form: any, caseId: number) {
    const question = form.questions;

    for (let i = 0; i < question.length; i++) {
      question[i].question_number = i + 1;
      question[i].case_id = caseId;
    }

    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'questions/' + caseId,
      question,
      this.getTokenHeader()
    ).pipe(
      map(
        result => {
          result.questions.forEach(eachQuestion => {
            delete eachQuestion['video_mp4'];

            return eachQuestion;
          });

          return result;
        }
      )
    );
  }

  updateCommunicationData(form: any, caseId: number) {
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/v1/communication/' + caseId,
      form,
      this.getTokenHeader()
    );
  }

  updateMedicalData(form: any, caseId: number) {

    if (!form['category_field_id']) { form.category_field_id = null; }

    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/v1/medicaldata/' + caseId,
      form,
      this.getTokenHeader()
    );
  }
	
  addCareteamNote(form: any,caseId: number) {

    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'careTeamNotes/' + caseId,
      form,
      this.getTokenHeader()
    );
  }
	
  updateCareteamNote(form: any, careTeamNoteId: number) {

    return this.http.put<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'careTeamNotes/' + careTeamNoteId,
      form,
      this.getTokenHeader()
    );
  }

  sendCompileMREmail(caseId: number) {

    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'compilemr/email/' + caseId,
      this.getTokenHeader()
    );
  }

  updateEditSummary(value: CareteamReport) {

    return this.http.post<CareteamReport>(
      ConfigAssetLoaderService.getApiURL() + '/v1/summary/update',
      value,
      this.getTokenHeader()
    );
  }

  preferredCommunicationMode() {
    return this.http.get<any[]>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'communication',
      this.getTokenHeader()
    );
  }

  bestCommunicationTime() {
    return this.http.get<any[]>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'communication/time',
      this.getTokenHeader()
    );
  }

  downloadSamplePdf() {
    const header = this.getTokenHeader();
    header['responseType'] = 'blob';
    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'download/sample',
      header
    ).pipe(map(
      function (res) {
        return new Blob([res], { type: 'application/pdf' });
      }
    ));
  }

  requestReopen(caseId) {
    const header = this.getTokenHeader();

    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'reopen',
      { case_id: caseId },
      header
    );
  }

  private getTokenHeader() {
    const token = localStorage.getItem('token');

    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token })
    };
  }

  updateVIPStatus(data: { caseid: number, is_vip: boolean }): Observable<any> {
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'vipstatus/update',
      data,
      this.getTokenHeader()
    );
  }

  updateType(value: any) {
    const tokenHeader = this.getTokenHeader();
    tokenHeader['observe'] = 'response';
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'type',
      value,
      tokenHeader
    );
  }

  extractVideoS3(key: string) {
    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'video/s3/' + key,
      this.getTokenHeader()
    );
  }

  getAllActivities(caseId: number) {
    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + '/case/activity/'+caseId,
      this.getTokenHeader()
    );
  }

  insertIntakeForm(case_id: number, data:any) {
	  return this.http.put<any[]>(ConfigAssetLoaderService.getApiURL() + '/v1/case/intakeForm/' + case_id, data, this.getTokenHeader());
  }

  generateIntakePDF(case_id: number) {
	  return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/case/generateIntakeFormPdf/' + case_id, this.getTokenHeader());
  }

  caseUpsert(data): Observable<any> {
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/case/upsert',
      data,
      this.getTokenHeader()
    );
  }

  getCaseDetailsSupport() {
    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + '/case_upsert_tools',
      this.getTokenHeader()
    );
  }

  getCaseTrackingLines(caseId) {
    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + '/case/tracking/' + caseId,
      this.getTokenHeader()
    );
  }

  markAsAddressOrUnaddressMissingInfo(case_id: number, is_missing_info_addressed: boolean) {
    const fullUrl = ConfigAssetLoaderService.getApiURL() + '/case/missing_info/' + case_id;
    const missing_info = { is_missing_info_addressed };

    return this.http.post<any>(
      fullUrl,
      missing_info,
      this.getTokenHeader()
    );
  }

  reTriggerWebhookFor({ logId, payload }) {
    let data = null;
    if (payload) {
      data = {};
      data.payload = payload;
    }
    const fullUrl = ConfigAssetLoaderService.getApiURL() + '/v2/re_trigger_webhook/' + logId;
    return this.http.post<any>(
      fullUrl,
      data,
      this.getTokenHeader()
    );
  }

  getMineAssignedCases(): Observable<any> {
    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + '/v2/cases/assigned/me',
      this.getTokenHeader()
    );
  }

  getAssignedCasesByUserId(user_id: number): Observable<any> {
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + '/v2/cases/assigned/' + user_id,
      {},
      this.getTokenHeader()
    );
  }

  resendMail(id, sendMail) {
    const data = { sendMail };
    const fullUrl = ConfigAssetLoaderService.getApiURL() + '/retrigger-mail/' + id;
    return this.http.post<any>(
      fullUrl,
      data,
      this.getTokenHeader()
    );
  }

  getCaseFormFieldOrder(caseId: number, formId?: number, formName?: string) {
    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() 
      + '/form_field_order/' 
      + caseId 
      + `?${formId ? `formId=${formId}`: ''}${formName ? `&formName=${formName}`: ''}`,
      this.getTokenHeader()
    );
  }

  patchCaseFormFieldOrder(firstResourceId: number, firstResourceNewIndex: number, secondResourceId: number, secondResourceNewIndex: number) {
    return this.http.patch<any>(
      ConfigAssetLoaderService.getApiURL() 
      + '/form_field_order/',
      {firstResourceId, firstResourceNewIndex, secondResourceId, secondResourceNewIndex},
      this.getTokenHeader()
    );
  }

}

/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/**
 * Login model call for submitting login form*/

export class LoginModel {

  /**
  * Stores username entered in form
  */
  email: string;

  /**
  * Stores password entered in form
  */
  password: string;

  /**
  * Stores dob entered in form
  */
  dob?: string;
}

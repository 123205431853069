<app-dialog></app-dialog>

<div class="header">
  <div class="header__page-name">
    <h1 class="heading heading--main">Outgoing Email Server Settings</h1>
  </div>
</div>

<div class="wrapper--dash-content dash-content">
  <div class="tabs__content" [class.overlay--visible]="isInprogress">
    <div class="overlay__content">
      <div class="overlay__spinner">
        <span class="icon icon--loading"></span>
      </div>
    </div>

    <div class="form tabs__content--white-bg">
		<form [formGroup]="mailServerForm" novalidate>
            <h2>Basic Details</h2>
			<div class="row">
                <!-- Outgoing mail server name -->
				<div class="col col__md-6">
					<div class="form__row">
						<label for="" class="form__label">Name*</label>
						<div class="form__field">
							<input type="text" formControlName="name" [appFormatOnly]='"trimonly"' />
							<div *ngIf="name.invalid && (name.dirty || name.touched )" class="message message--is-invalid">
								<div *ngIf="name.errors.required">
									Name is required.
								</div>
							</div>
						</div>
					</div>
                </div>
                
                <!-- Sequence/Priority -->
                <div class="col col__md-6">
					<div class="form__row margin--lr-x">
						<label for="" class="form__label">Sequence*</label>
						<div class="form__field">
							<input type="number" formControlName="sequence" [appFormatOnly]='"trimonly"' />
                            <div *ngIf="sequence.invalid && (sequence.dirty || sequence.touched )" class="message message--is-invalid">
								<div *ngIf="sequence.errors.required">
									Sequence is required.
								</div>
							</div>
						</div>
					</div>
                </div>
            </div>
            <div class="row">
                <!-- Outgoing mail server description -->
				<div class="col col__md-12">
					<div class="form__row">
						<label for="" class="form__label">Description</label>
						<div class="form__field">
                            <ckeditor class="customheader" formControlName="description" #ckeditorField [config]="{toolbar: []}"></ckeditor>
						</div>
					</div>
                </div>
            </div>
            <div class="row">
                <!-- Mail server active/passive -->
                <div class="col col__md-12">
                    <div class="form__row">
                        <div class="form__field">
                            <div class="form__field--checkbox">
                                <input id="mail_option" type="checkbox" formControlName="active" />
                                <label for="mail_option">Active</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hr hr--space-down-2x hr--space-up-x"></div>
            <div class="row">
                <h3 class="form__title">Email services</h3>
                <p><strong>Note: </strong>If this is selected then no need to add the smtp settings.</p>
                <!-- Email services list -->
                <div class="col col__md-12">
					<div class="form__row">
						<label for="" class="form__label">Email Service</label>
						<div class="form__field">
							<select (change)="onEmailServiceChange($event)" formControlName="email_service">
								<option value="" >Select</option>
                                <option *ngFor="let service of emailServiceList" [value]="service?.value">
                                    {{ service?.label }}
                                </option>
							</select>
							<div *ngIf="email_service.invalid && (email_service.dirty || email_service.touched )"
									class="message message--is-invalid">
								<div *ngIf="email_service.errors.required">
									Email Service is required.
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
            <div class="hr hr--space-down-2x hr--space-up-x"></div>
            <div class="row">
                <h3 class="form__title">SMTP Settings</h3>
                <!-- Smpt host name -->
                <div class="col col__md-6" *ngIf="showSMTPSetting">
					<div class="form__row">
						<label for="" class="form__label">Smtp Host*</label>
						<div class="form__field">
							<input type="text" formControlName="smtp_host" [appFormatOnly]='"trimonly"' />
							<div *ngIf="smtp_host.invalid && (smtp_host.dirty || smtp_host.touched )"
									class="message message--is-invalid">
								<div *ngIf="smtp_host.errors.required">
									Smtp host is required.
								</div>
							</div>
						</div>
					</div>
                </div>

                <!-- Smtp port -->
                <div class="col col__md-6" *ngIf="showSMTPSetting">
                    <div class="form__row margin--lr-x">
                        <label for="" class="form__label">Smtp Port*</label>
                        <div class="form__field">
                            <input type="number" formControlName="smtp_port" [appFormatOnly]='"trimonly"' />
                            <div *ngIf="smtp_port.invalid && (smtp_port.dirty || smtp_port.touched )"
                                    class="message message--is-invalid">
                                <div *ngIf="smtp_port.errors.required">
                                    Smtp port is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- Smtp User -->
                <div class="col col__md-6">
                    <div class="form__row">
                        <label for="" class="form__label">Smtp User*</label>
                        <div class="form__field">
                            <input type="text" formControlName="smtp_user" [appFormatOnly]='"trimonly"' />
                        </div>
                    </div>
                </div>

                <!-- Smtp Password -->
                <div class="col col__md-6">
                    <div class="form__row margin--lr-x">
                        <label for="" class="form__label">Smtp Password*</label>
                        <div class="form__field">
                            <div class="input-group" id="show_hide_password">
                                <input type="password" formControlName="smtp_password" autocomplete="off" [appFormatOnly]='"trimonly"' class="form-control"/>
                                <button type="button" class="btn btn-secondary" (click)="$event.preventDefault()" appPasswordToggle>
                                <span [ngClass]="password_type == 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- Smtp Encryption -->
                <div class="col col__md-12">
					<div class="form__row">
						<label for="" class="form__label">Smtp Encryption*</label>
						<div class="form__field">
							<select formControlName="smtp_encryption">
								<option value="">Select</option>
                                <option value="none">None</option>
                                <option value="tls">TLS (STARTTLS)</option>
                                <option value="ssl">SSL/TLS</option>
							</select>
							<div *ngIf="smtp_encryption.invalid && (smtp_encryption.dirty || smtp_encryption.touched )"
									class="message message--is-invalid">
								<div *ngIf="smtp_encryption.errors.required">
									Smtp Encryption is required.
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
            
            <div class="btn-row">
                <button [disabled]="mailServerForm.invalid" [swal]="submitMailServer" class="btn btn--blue">Finish</button>
                <swal #submitMailServer title="Confirmation" text="Are you sure? You want to add the new mail server ?"
                    (confirm)="onSubmit()" closeOnConfirm="true" closeOnCancel="true" backdrop="#eef3f6" cancelButtonColor="#db161e"
                    reverseButtons="true" showCancelButton="true" confirmButtonColor="green" confirmButtonText="Continue">
                </swal>
                <a [routerLink]="['/settings/outgoing-mail-server']" class="btn btn--red">Cancel</a>
            </div>
		</form>

	</div>
  </div>
</div>


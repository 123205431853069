/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { PopUpService } from '../../../services/pop-up.service';
import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonFuntions } from '../../../common/common.function';
import { LoginService } from '../../../services/login.service';
import { Router } from '@angular/router';
import { CaseService } from '../../../services/case.service';
import { OnChanges, AfterContentInit } from '@angular/core';
import * as moment from 'moment/moment';

@Component({
  selector: 'translate-mr',
  templateUrl: './translate-mr.component.html',
  styleUrls: ['./translate-mr.component.css']
})
export class TranslateMrComponent implements OnInit, OnChanges, AfterContentInit {
  @Input() caseDetail: any;
  // @Input() disableEdits;
  @Input() isInProgress: boolean;
  @Input() canContinue: Function;
  @Input() final_reports;
  @Input() translated_document;
  @Input() document_for_download;
  @Input() isMdManager;
  @Output() caseEmitter = new EventEmitter<any>();
  @Output() intakeFormDocumentEmitter = new EventEmitter<any>();
  @Output() showStatusEmitter = new EventEmitter<string>();
  translateMRForm: FormGroup;
  translator;
  disableInputs
  isEmailSending: boolean
  isCompileMRCollapse = true;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private caseService: CaseService,
    private popUpService: PopUpService
  ) { }

  ngOnChanges(_changes: SimpleChanges): void {
    this.setDataOnChange();
  }

  ngAfterContentInit(): void {
    this.setDataOnChange();
  }

  ngOnInit() {

    this.translateMRForm = this.getFormGroup();

  }

  public setDataOnChange() {
    if (this.caseDetail != null) {
      this.translator = this.caseDetail.status.find(element => element.status.status.toLowerCase() === 'translatedmr');

      if (this.translateMRForm && this.translateMRForm != null) {
        this.translateMRForm.get('case_id').setValue(this.caseDetail.id);
      }

      if (this.translateMRForm && this.translateMRForm != null && this.caseDetail != null
        && this.caseDetail.case_translate_mr != null) {
        this.translateMRForm.get('medical_record_translation').setValue(this.caseDetail.case_translate_mr.medical_record_translation);
        this.translateMRForm.get('notes').setValue(this.caseDetail.case_translate_mr.notes);
      }

      this.disableInputs = CommonFuntions.checkIfHoldOrCancelledOrFinishedorMd(this.caseDetail, this.isMdManager);
    }
  }

  private getFormGroup() {
    return this.fb.group(
      {
        medical_record_translation: [
          false,
          CommonFuntions.isChecked
        ],
        notes: [
          null, Validators.maxLength(50000)
        ],
        case_id: [
          this.caseDetail == null ? null : this.caseDetail.id,
          Validators.required
        ],
        sendEmail: [
          false
        ]
      },
      {
        updateOn: 'change'
      }
    );
  }

  get medical_record_translation() {
    return this.translateMRForm.get('medical_record_translation');
  }

  get notes() {
    return this.translateMRForm.get('notes');
  }

  private showAlert(message: string ,positiveCallback: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'alert',
      message,
      'Ok',
    );

    this.popUpService.setPositiveAction(positiveCallback);

    const alertDialog = this.popUpService.getPopUpView('alertDialog');
    this.popUpService.showDiv(alertDialog);
  }

  public finishTranslateMR(form: FormGroup) {
    if (CommonFuntions.guardForAction(this.loginService,
      this.router)) {
      if (form.valid) {
        this.isInProgress = true;
        if (!this.canContinue(this.caseDetail, 'translatedmr')) {
          this.showAlert('Please complete previous stages to complete \'Compile MR\'.' ,function () {
            this.popUpService.hideDiv('alertDialog');
          }.bind(this));
          this.isInProgress = false;
          return;
        }

        if (this.caseDetail.case_md_management != null) {
          const isMDEmailSent = this.caseDetail.case_md_management.is_email_sent;

          this.translateMRForm.addControl(
            'sendEmail',
            new FormControl(isMDEmailSent)
          );
        }

        this.caseService.createOrUpdateTranslateMr(
          form.value,
          this.caseDetail.id
        ).subscribe(
          function (response) {
            const index = this.caseDetail.status.findIndex(eachStatus => eachStatus.status.status.toLowerCase() === 'translatedmr');
            if (index > -1) {
              this.caseDetail.status[index].state = 'FINISHED';
            }
            const inprogressList = CommonFuntions.setStatusInProgress(this.caseDetail.status, 'translatedmr');

            const statusList = [];

            inprogressList.forEach(eachStatus => {
              statusList.push({ status_id: eachStatus.status.id });
            });

            if (statusList.length > 0) {
              this.caseService.updateStaus(statusList, this.caseDetail.id).subscribe(
                function (_result) {
                  this.object.showAlert(`${this.response.message}.` ,function () {
                    this.object.popUpService.hideDiv('alertDialog');
                  }.bind(this));	
                  const user = this.object.loginService.getCurrentUser();
                  this.object.caseService.getCaseById(this.object.caseDetail.id, user.user_id).subscribe(
                    function (caseDetails) {
                      caseDetails;
                      caseDetails.date_mr_complete = moment(caseDetails.date_mr_complete).format('MM/DD/YYYY, h:mm A');
                      caseDetails['final_reports'] = this.object.final_reports;
                      caseDetails['document_for_download'] = this.object.document_for_download;
                      caseDetails['translated_document'] = this.object.translated_document;
                      this.object.caseEmitter.emit(caseDetails);
                      this.object.showStatusEmitter.emit('translatedmr');
                      this.object.isCaseAccepted.emit('false');   
                      // this.object.showStatusEmitter.emit(this.inprogressList[0].status.status);
                    }.bind({
                      object: this.object,
                      inprogressList
                    })
                  );
                }.bind({
                  object: this,
                  inprogressList,
                  response
                })
              );
            }
            else {
              const user = this.loginService.getCurrentUser();
              this.caseService.getCaseById(this.caseDetail.id, user.user_id).subscribe(
                function (caseDetails) {
                  this.object.showAlert(`${response.message}.` ,function () {
                    this.object.popUpService.hideDiv('alertDialog');
                  }.bind(this));	
                  caseDetails;
                  caseDetails.date_mr_complete = moment(caseDetails.date_mr_complete).format('MM/DD/YYYY, h:mm A');
                  caseDetails['final_reports'] = this.object.final_reports;
                  caseDetails['document_for_download'] = this.object.document_for_download;
                  caseDetails['translated_document'] = this.object.translated_document;
                  this.object.caseEmitter.emit(caseDetails);
                }.bind({
                  object: this,
                  inprogressList
                })
              );
            }
          }.bind(this),
          function(error) {
            const msg = error.error? error.error.message : error.message;
            this.showAlert(`${msg}.` ,function () {
              this.popUpService.hideDiv('alertDialog');
            }.bind(this));
            this.isInProgress = false;
          }.bind(this),
          () => {
            this.isInProgress = false;
          }
        );
      }
      else {
        CommonFuntions.validateAllFormFields(form);
      }
    }
  }

  sendEmail() {
    this.isEmailSending = true;
    this.caseService.sendCompileMREmail(this.caseDetail.id).subscribe(
      result => {
        this.showAlert(`${result.message}.` ,function () {
          this.popUpService.hideDiv('alertDialog');
          this.caseDetail.case_translate_mr.is_email_sent = true;
          this.isEmailSending = false;
        }.bind(this));
      },
      function(error) {
        this.showAlert(`${error.error.message}.` ,function () {
          this.popUpService.hideDiv('alertDialog');
          this.isEmailSending = false;
        }.bind(this));
      }.bind(this)
    );
  }

  public notify() {
    this.intakeFormDocumentEmitter.emit();
  }

}

/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, HostListener } from '@angular/core';
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { LoginService } from './services/login.service';
import { TimeoutService } from './services/timeout.service';
import { DatashareService } from './services/datashare.service';
import { ConfigAssetLoaderService } from './services/config-asset-loader.service';
import { Meta } from '@angular/platform-browser';
import { ErrorsHandlerService } from './errorshandler.service';

/**
 * The main component
 *
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

	/**
	*  Title module of the project
	*/
	title = 'app';
	profilePicPath: string;

	openUser = 0;
	user: any;
	constructor(
		private timeout: TimeoutService,
		public loginService: LoginService,
		public router: Router,
		public route: ActivatedRoute,
		public dataShare: DatashareService,
		private errorService: ErrorsHandlerService,
		private meta: Meta,
	) {

	  this.addMetaTag(ConfigAssetLoaderService.getApiURL());

	  const token = localStorage.getItem('token');
	  if (token) {
	    const user = this.loginService.getCurrentUser();
	    sessionStorage.setItem('userId', user.user_id);
	  }
	}

	ngOnInit(): void {
	  this.profilePicPath = '/assets/images/profile-pic-small.jpg';
	  sessionStorage.setItem('demo', 'this is a demo storage');

	}

	@HostListener('document:mouseover', ['event'])
	onMouseOver() {
	  this.checkTimeout();
	}

	@HostListener('document:keyup', ['event'])
	onkeyUp() {
	  this.checkTimeout();
	}

	@HostListener('window:load', ['$event'])
	beforeLoad() {

	  const token = localStorage.getItem('token');
	  if (token && token != null) {
	    this.user = this.loginService.getCurrentUser();
	    sessionStorage.setItem('userId', this.user.user_id);
	  }
	}

	@HostListener('window:focus', ['$event'])
	checkForLogin() {
	  const timedOut = this.checkTimeout();

	  if (!timedOut) { return; }

	  let token = localStorage.getItem('token');
	  const currentUrl = this.router.url;
	  if ((!token || token == null)
			&&
			(
			  !/\/login*/.test(currentUrl)
				&& !/\/forgotpassword*/.test(currentUrl)
				&& !/\/reset\/password*/.test(currentUrl)
				&& !/\/signup*/.test(currentUrl)
			)
	  ) {
	    this.router.navigate(['/login'], { queryParams: { returnUrl: currentUrl } });
	    return;
	  }
	  else if (token && token != null && /\/login*/.test(currentUrl)) {

	    const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');

	    if (returnUrl != null) {
	      this.router.navigateByUrl(returnUrl);
	      return;
	    }

	    this.user = this.loginService.getCurrentUser();
	    if (this.user.user_type.type === 'Partner') {
	      this.router.navigate(['/clients/dashboard/' + this.user.partners[0].partner_id]);
	    }
	    else {
	      this.router.navigate(['/case'], { queryParams: { list: 'current' } });
	    }
	  }

	  const sessionUserId = sessionStorage.getItem('userId');
	  token = localStorage.getItem('token');
	  if (token && token != null) {
	    const user = this.loginService.getCurrentUser();
	    if (sessionUserId == null || parseInt(sessionUserId, 10) !== user.user_id) {
	      location.reload();
	    }
	    this.errorService.checkForPasswordAlert(user);
	  }
	}

	public checkTimeout() {
	  const timeout = this.timeout.timedOut;
	  if (!timeout) return true;

	  setTimeout(() => {
	    const statusFilterSaveStateString = localStorage.getItem('statusFilterSaveState');
	    const statusFilterSaveState = JSON.parse(statusFilterSaveStateString);

	    if (statusFilterSaveState != null && (statusFilterSaveState.userType.toLowerCase() !== 'careteam' || statusFilterSaveState.userType.toLowerCase() !== 'casecoordinator')) {
	      localStorage.removeItem('statusFilterSaveState');
	    }
	  }, 300);

	  localStorage.removeItem('refresh_token');
	  localStorage.removeItem('token');
	  localStorage.removeItem('user_partners');

	  this.timeout.stopSession();

	  const currentUrl = this.router.url;
	  if (
	    !/\/login*/.test(currentUrl)
			&& !/\/forgotpassword*/.test(currentUrl)
			&& !/\/reset\/password*/.test(currentUrl)
			&& !/\/page-not-found*/.test(currentUrl)
			&& !/\/signup*/.test(currentUrl)
	  ) {
	    this.router.navigate(['/login'], { queryParams: { returnUrl: currentUrl } });
	  }

	  return false;
	}

	private addMetaTag(url) {
	  if (this.meta.getTag('http-equiv="Content-Security-Policy"')) {
	    this.meta.removeTag('http-equiv="Content-Security-Policy"');
	  }

	  // remove http// or https// and port :3000 for socket connection
	  this.meta.addTags(
	    [
	      {
	        'http-equiv': 'Content-Security-Policy',
	        'content': `
			default-src 'self'  blob: ;
			media-src * blob:;
			connect-src 'self' ${url} ws:  wss: ;
			style-src 'self' 'unsafe-inline' ;
			script-src 'self' 'unsafe-inline' 'unsafe-eval' ;
			img-src 'self' data: ;
			`
	      }
	    ]
	  );
	}
}

/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';

@Component({
  selector: 'app-notification-title',
  templateUrl: './notification-text.component.html',
  styleUrls: ['./notification-text.component.css']
})
export class NotificationTextComponent implements OnInit {
  @Input() notification: any;
  @Output() closeBox = new EventEmitter();

  role = null;
  constructor(
    private router: Router,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    const user = this.loginService.getCurrentUser();
    if (user) {
      this.role = user.user_type.type || null;
    }
  }

  redirect(caseId: number, assignmentType = null): void {
    this.closeBox.emit();

    if (!assignmentType) {
      if (this.role.toLowerCase() === 'doctor' || this.role.toLowerCase() === 'interpreter') {
        this.redirectTo('/case/doctor-consultation/'+caseId);
      }
      else {
        this.redirectTo('/case/'+caseId);
      }
    }
    else {
      this.redirectTo('/dashboard');
    }
  }

  redirectTo(uri:string): void {
    this.router.navigateByUrl(uri);
  }

}

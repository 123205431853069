<div class="notification-box" *ngIf="role !== 'mdmanager'">
  <div class="top-menu__wrapper">
    <div class="bell-icon notification-trigger" tabindex="1" (click)="openNotificationBox($event, content)"
      #bellInput>
      <svg tabindex="1" version="1.1" x="0px" y="0px" width="50px" height="30px" viewBox="0 0 50 30"
        enable-background="new 0 0 50 30" xml:space="preserve">
        <g class="bell-icon__group">
          <path class="bell-icon__ball" id="ball" fill-rule="evenodd" stroke-width="1.5" clip-rule="evenodd"
            fill="#1991EB" stroke="#fff" stroke-miterlimit="10"
            d="M28.7,25 c0,1.9-1.7,3.5-3.7,3.5s-3.7-1.6-3.7-3.5s1.7-3.5,3.7-3.5S28.7,23,28.7,25z" />
          <path class="bell-icon__shell" id="shell" fill-rule="evenodd" clip-rule="evenodd" fill="#1991EB" stroke="#fff"
            stroke-width="2" stroke-miterlimit="10"
            d="M35.9,21.8c-1.2-0.7-4.1-3-3.4-8.7c0.1-1,0.1-2.1,0-3.1h0c-0.3-4.1-3.9-7.2-8.1-6.9c-3.7,0.3-6.6,3.2-6.9,6.9h0 c-0.1,1-0.1,2.1,0,3.1c0.6,5.7-2.2,8-3.4,8.7c-0.4,0.2-0.6,0.6-0.6,1v1.8c0,0.2,0.2,0.4,0.4,0.4h22.2c0.2,0,0.4-0.2,0.4-0.4v-1.8 C36.5,22.4,36.3,22,35.9,21.8L35.9,21.8z" />
        </g>
      </svg>
      <div *ngIf="notificationBadge > 0" class="notification-amount">
        <span>{{notificationBadge}}</span>
      </div>
    </div>

    <!-- Start Tabs HTML -->
    <div *ngIf="toggle" class="notification-outer" [ngStyle]="{'height.px': backDropHeight, 'width.px': backDropWidth}" (click)="closeBox()">
      <div class="notification-tabs" (click)="stopPropagation($event)" #bellBoxInput>

        <a *ngIf="(notificationBadge !== 0 )" href="javascript:void(0);" (click)="markAsReadAll()" class="mark-all" style="font-size: 12px;">Mark all as read</a>

        <input type="radio" id="tab1" name="tab-control" checked>
        <input type="radio" id="tab2" name="tab-control">
        <ul class="tab-ul">
          <li class="tab-li" title="Cases" (click)="changeTab(true)" [ngClass]="[caseTypes == true ? 'active-tab-li' : '']"><label for="tab1" role="button"><span>Cases <span *ngIf="caseCounts>0">({{caseCounts}})</span></span></label></li>
          <li class="tab-li" title="Tracking Lines" (click)="changeTab(false)" [ngClass]="[ caseTypes == false ? 'active-tab-li' : '']"><label for="tab2" role="button" ><span>Tracking Lines <span *ngIf="trackingCounts>0">({{trackingCounts}})</span></span></label>
          </li>
        </ul>
        
        <!-- <div class="slider">
          <div class="indicator"></div>
        </div> -->
        <div #content class="content" [ngStyle]="{'max-height.px': outerHeight}" >
          <section>
            <div class="notification-list notification_dd">
              <div *ngIf="!caseLoading && caseNotifications.length == 0" class="empty-section">
                <img class="empty-box" src="assets/images/emptybox.svg" />
              </div>
              <ul *ngIf="caseNotifications.length != 0" class="item-section notification_ul slide-fade" style="min-height: 250px">
                <li class="notification-item" *ngFor="let item of caseNotifications;" [ngClass]="[itemToRemove === item.id ? 'removeItemAnimate' : 'show']">
                  <div class="notify_icon">
                    <span *ngIf="!item.read" class="unread-item" title="Mark as read" (click)="markAsReadOrUnread(item, true)"></span>
                    <span *ngIf="item.read" class="read-item" title="Mark as Unread" (click)="markAsReadOrUnread(item, false)"></span>
                    <div class="notification-image">

                      <img *ngIf="( !item.sender || !item.sender.avatar || item.sender.avatar.length < 100); else showUserAvatar"
                      [src]="profilePicPath" alt="default" width="40"/>
                      <ng-template #showUserAvatar>
                        <img [src]="'data:image/jpg;base64,'+item.sender.avatar"  alt="avatar" style="width:40px; height: 100%;"/>
                      </ng-template>

                    </div>
                  </div>
                  <div class="notify_data">
                    <app-notification-title [notification]="item" (closeBox)="closeBox($event)"></app-notification-title>
                    <div class="sub_title">
                      {{item.sender.full_name}} <span class="dot"></span> {{getDateString(item.createdAt)}}
                    </div>
                  </div>
                  <div class="notify_status">
                    <img src="assets/images/icons/cancel.svg" alt="x" (click)="deleteNotification(item)"
                      title="Clear Notification" />
                  </div>
                </li>
              </ul>
              <div *ngIf="(caseOffet + caseOffetCounts) < totalCaseCount" class="notification-item load-more" style="opacity: 1!important;padding: 10px 0px;">
                <div *ngIf="caseLoading" class="load-more-section">
                  <img class="rotate" style="width: 25px;" src="assets/images/rotate.svg">
                </div>
                <div *ngIf="!caseLoading && totalCaseCount > 20" class="load-more-section">
                  <a href="javascript:void(0)" (click)="loadMore(true)" class="btn btn--purple btn--mini text-white">Load More
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="notification-list notification_dd">
              <div *ngIf="!nonCaseLoading && trackingLineNotifications.length == 0" class="empty-section">
                <img class="empty-box" src="assets/images/emptybox.svg" />
              </div>
              <ul *ngIf="trackingLineNotifications.length != 0" class="item-section notification_ul slide-fade" style="min-height: 250px">
                <li class="notification-item" *ngFor="let item of trackingLineNotifications;" [ngClass]="[itemToRemove === item.id ? 'removeItemAnimate' : 'show']">
                  <div class="notify_icon">
                    <span *ngIf="!item.read" class="unread-item" title="Mark as read" (click)="markAsReadOrUnread(item, true)"></span>
                    <span *ngIf="item.read" class="read-item" title="Mark as Unread" (click)="markAsReadOrUnread(item, false)"></span>
                    <div class="notification-image">
                      
                      <img *ngIf="( !item.sender || !item.sender.avatar || item.sender.avatar.length < 100); else showUserAvatar"
                      [src]="profilePicPath" alt="default" width="40"/>
                      <ng-template #showUserAvatar>
                        <img [src]="'data:image/jpg;base64,'+item.sender.avatar"  alt="avatar" style="width:40px; height: 100%;"/>
                      </ng-template>

                    </div>
                  </div>
                  <div class="notify_data">
                    <app-notification-title [notification]="item" (closeBox)="closeBox($event)"></app-notification-title>
                    <div class="sub_title">
                      {{item.sender.full_name}} <span class="dot"></span> {{getDateString(item.createdAt)}}
                    </div>
                  </div>
                  <div class="notify_status">
                    <img src="assets/images/icons/cancel.svg" alt="x" (click)="deleteNotification(item)"
                      title="Clear Notification" />
                  </div>
                </li>
              </ul>
              <div *ngIf="(trackingOffset + trackingOffsetCounts) < totalTackingCount" class="notification-item load-more" style="opacity: 1!important;padding: 10px 0px;">
                <div *ngIf="nonCaseLoading" class="load-more-section">
                  <img class="rotate" style="width: 25px;" src="assets/images/rotate.svg">
                </div>
                <div *ngIf="!nonCaseLoading && totalTackingCount > 20" class="load-more-section">
                  <a href="javascript:void(0)" (click)="loadMore(false)" class="btn btn--purple btn--mini text-white">Load More
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
        
      </div>
    </div>
    <!-- End Tabs HTML -->


  </div>
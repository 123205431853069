/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */

import {takeWhile} from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../services/userservice.service';
import { DoctorProfileModel } from '../../../domains/doctorProfileModel';
import { OnDestroy } from '@angular/core';
import { Contact } from '../../../domains/contactModel';
import { UserModel } from '../../../domains/userModel';
import { LoginService } from '../../../services/login.service';
import { CommonFuntions } from '../../../common/common.function';
import { Router, UrlTree, UrlSegmentGroup, UrlSegment, PRIMARY_OUTLET, ActivatedRoute } from '@angular/router';
import * as moment from 'moment/moment';
import { PopUpService } from '../../../services/pop-up.service';
import { CaseService } from '../../../services/case.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-doctor-profile',
  templateUrl: './doctor-profile.component.html',
  styleUrls: ['./doctor-profile.component.css']
})
export class DoctorProfileComponent implements OnInit, OnDestroy {
  @ViewChild('confirmUserInactiveSwal') private confirmUserSwal: SwalComponent;
  @ViewChild('continueCaseSwal') private continueCaseSwal: SwalComponent;
  
  alive: boolean
  avatarimage: any;

  profilePicPath: string;
  userType = '';
  userId: number;
  doctorId: number;

  userTypeMap: Map<number, string>
  sub: any;
  weChatIds: Contact[];

  doctorProfileModel: DoctorProfileModel = new DoctorProfileModel();
  user: UserModel = new UserModel();
  operation: string;

  doctor_availiable_dates: any;

  display_date_calender: any;

  public selectedMoment = new Date();

  countLoop: number;

  today: string;

  week_reoccurring = []

  showEdit: boolean;

  showSpinner = true;

  users: any;
  isSettings: any;
  certifications: any;
  conditions: any;
  loadingImage=false

  showAllyRef = false;
  showCaseList = false;
  caseList;
  allAllyClients= [];
  allNonAllyClients=[];
  showNonAllyClients = false;
  showAllyClients = false;
  hideDeleteUserBox = true;
  
  modalMessage = '';

  constructor(
    public loginService: LoginService,
    public userService: UserService,
    public router: Router,
    private route: ActivatedRoute,
    private popUpService: PopUpService,
    private caseService: CaseService,
    private toastr:ToastrService
  ) {

  }
  

  ngOnInit(): void {
    this.alive = true;
    this.showEdit = false;
    this.countLoop = 0;
    this.display_date_calender = [];

    this.userTypeMap = new Map();

    this.users = this.loginService.getCurrentUser();

    if (['admin', 'cmmanager'].includes(this.users.user_type.type.toLowerCase())) {
      this.isSettings = true;
    }
    else {
      this.isSettings = false;
    }

    if (this.users.user_type.type.toLowerCase() == 'admin' || this.users.user_type.type.toLowerCase() == 'careteam') {
      this.showAllyRef = true;
    }

    this.sub = this.route.params.pipe(
      takeWhile(() => this.alive))
      .subscribe(
        params => {
          this.userId = +params['id'];
          if (this.userId) {
            this.loadDoctor();
          }
        }
      );

    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g.segments;

    this.operation = s[0].path;
    this.operation = this.operation.toLowerCase();
  }

  toggleDeleteTemplate(value: boolean): void {
    this.hideDeleteUserBox = value;
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  ngAfterViewInit() {
    if (this.userId) {
      this.loadCalender();
    }
  }

  loadCalender() {
    this.getDoctorCalenderSelected(this.userId).pipe(takeWhile(() => this.alive)).subscribe(
      result => {
        if (result != '') {this.showEdit = true;}
        if (result) {
          result.forEach(element => {
            if (element.is_reoccurring) {
              this.colorAllWeekDay(element.week_number);
            }
            else {
              this.colorDate(element.date);

            }

          });

        }

      }
    );
  }

  colorAllWeekDay(week_number: number) {
    if (this.week_reoccurring) {
      const getWeekDate = this.week_reoccurring.filter(wn => wn.week_number == week_number);
      if (getWeekDate) {
        getWeekDate.forEach(element => {
          this.colorDate(element.date);
        });
      }

    }
  }


  colorDate(element: string) {
    // coloring
    const calenderTable = $('div[class=\'calendar\']');
    // var calenderTable = $("table[class='owl-dt-calendar-table']")
    // aria - disabled
    calenderTable.find('td[aria-label=\'' + element + '\']').removeClass('owl-dt-calendar-cell-disabled').find('span').addClass('owl-dt-calendar-cell-selected');
    calenderTable.find('td[aria-label=\'' + element + '\']').removeAttr('aria-disabled');
    calenderTable.find('td[aria-label=\'' + this.today + '\']').find('span').removeClass('owl-dt-calendar-cell-today');

    /* var addLink = calenderTable.find("td[aria-label='" + element + "']").find('span')
    if (addLink.find('a')){
      addLink.prepend('<a _ngcontent-c5 ng-reflect-router-link="/calender/create" href="/calender/create"></a>');
    } */

  }


  editCalender() {
    if (this.doctorId) {
      // this.router.navigate(['/calender/edit',this.doctorId]);
      this.router.navigate(['../edit'], { relativeTo: this.route });
    }
  }


  public disableCalender = (d: Date): boolean => {
    const flag = false;
    this.countLoop += 1;
    if (this.countLoop % 42 == 0) {
      this.loadCalender();
    }

    this.week_reoccurring.push({
      date: moment(d).format('MMMM D, YYYY'),
      week_number: d.getDay()
    });
    return flag;


  }

  loadDoctor() {
    this.getDoctorInfo();
    this.getDoctorCertifications();
    this.getDoctorCondition();
    this.getUserCaseList();
  }

  processDoctorAvailability(availability: string){
    if(availability == 'MEDIUM_AVAILABILITY'){
      this.doctorProfileModel.availability = 'Medium Availability';
    }
    else if(availability == 'HIGH_AVAILABILITY'){
      this.doctorProfileModel.availability = 'High Availability';
    }
    else{
      this.doctorProfileModel.availability = 'Low Availability';
    }
  }

  getDoctorInfo(): void {
    this.userService.getDoctorProfile(this.userId).pipe(
      takeWhile(() => this.alive))
      .subscribe(
        result => {
          this.doctorProfileModel = result as DoctorProfileModel;
          this.doctorProfileModel.partners.forEach((item)=>{
            item.allyPartnerRef? this.allAllyClients.push(item.partner_name) : this.allNonAllyClients.push(item.partner_name);
          });
          this.processDoctorAvailability(this.doctorProfileModel.availability);
          this.today = this.doctorProfileModel.today;
          this.doctorId = this.doctorProfileModel.profile_id;
          this.showSpinner = false;
          this.loadingImage=true;
          this.userService.getUploadedFile(this.doctorProfileModel.avatar)
            .pipe(takeWhile(() => this.alive)).subscribe(
              image => {
                this.avatarimage = image.image;
                this.loadingImage = false;
              },
              (_error) => {
                this.avatarimage = null;
                this.loadingImage = false;
                this.profilePicPath = '/assets/images/profile-pic-male.png';
              }
            );

        },
        function (error) {
          if (error.status == 403) {
            this.router.navigate(['/']);
          }
          else if (error.status == 401) {
            this.router.navigate(['/doctor-role']);
          }
        }.bind(this)
      );
  }


  getDoctorCertifications() {
    this.userService.getDoctorCertifications(this.userId)
      .pipe(takeWhile(() => this.alive)).subscribe(
        result => {
          this.certifications = result['doctor_certifications'];
          // this.showSpinner = false;

        }
      );
  }
  
  getDoctorCondition() {
    this.userService.getDoctorCondition(this.userId).
      pipe(takeWhile(() => this.alive)).subscribe(
        result => {
          this.conditions = result['doctor_md_types'];
        }
      );
  }

  getDoctorCalenderSelected(user_id: number) {
    return this.userService.getDoctorCalenderSelected(user_id);
  }

  checkDate(d: Date, user_id: number) {
    return this.userService.checkDate(d, user_id);
  }

  inActivateUser(event: Event): void {
    if (event) event.preventDefault();

    this.hideDeleteUserBox = false;
  }

  validReason():void {
    const minLength = 3;
    const maxLength = 5000;
    if (this.confirmUserSwal === undefined) { return; }
    this.confirmUserSwal.inputValidator = (value) => {
      return new Promise<any>((resolve) => {

        if (value.length >= minLength && value.length <= maxLength) {

          this.continueCaseSwal.fire();
          this.modalMessage =value;
        }
        else if(value.length >= maxLength){
          resolve('This field must not contain more than 50,000 characters !');
        }
        else {
          resolve('This field must contain at least 3 characters!');
        }
      });
    };
  }


  deleteUserDoctor(reason: string): void {
    if (!CommonFuntions.guardForAction(this.loginService, this.router)) {return;}
    this.userService.deleteUser(this.doctorProfileModel.doctor_id, reason).subscribe((response)=>{

      if(response){
        const message = '\'' + this.doctorProfileModel.full_name + '\' has been successfully inactivated from the system.';
        this.toastr.success(message);
        if (this.userId) {
          this.getDoctorInfo();
        }
        
        this.router.navigate(['/doctor-role']);
      }
      else{
        const message = 'Failed to delete \'' + this.doctorProfileModel.full_name + '\' from the system.';
        this.toastr.error(message);
      } 
    },(error)=>{
      this.toastr.error(error['error'].message);
    });
  }

  deleteDoctor(reason: string): void {
    if (!CommonFuntions.guardForAction(this.loginService, this.router)) {return;}
    this.popUpService.destoryConfirmDialog();

    const message = 'Do you want to inactivate \'' + this.doctorProfileModel.full_name + '\' from the system?';
    this.popUpService.setDialogParams(
      'Inactivate Confirmation',
      message,
      'Yes',
      'Cancel',
      function () {
        this.popUpService.hideDiv('confirmDialog');
        this.userService.deleteUser(this.doctorProfileModel.doctor_id, reason).
          pipe(takeWhile(() => this.alive)).subscribe(
            function (result) {
              if (result) {
                const message = '\'' + this.doctorProfileModel.full_name + '\' has been successfully inactivated from the system.';
                this.showAlert(message, function () {
                  this.popUpService.hideDiv('alertDialog');
                  if (this.userId) {
                    this.getDoctorInfo();
                  }
                  this.hideDeleteUserBox = true;
                  this.router.navigate(['/doctor-role']);
                }.bind(this));
              }
              else {
                const message = 'Failed to delete \'' + this.doctorProfileModel.full_name + '\' from the system.';
                this.showAlert(message, function () {
                  this.hideDiv('alertDialog');
                });
              }
            }.bind(this),
            function (error) {
              const serverError = error.error ? error.error : error;
              this.showAlert(serverError.message, function () {
                this.popUpService.hideDiv('confirmDialog');
                this.popUpService.hideDiv('alertDialog');
              }.bind(this));
            }.bind(this)
          );
      }.bind(this),
      function () {
        this.hideDiv('confirmDialog');
      }
    );

    const confirmDialog = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(confirmDialog);

  }


  restoreModalDoctor(doctor_id: number): void {
    if (!CommonFuntions.guardForAction(this.loginService, this.router)) {return;}
    this.userService.restoreUser(doctor_id).pipe(takeWhile(() => this.alive)).subscribe((result)=>{
      if (result) {
        const message = '\'' + this.doctorProfileModel.full_name + '\' has been successfully activated in the system.';
        if (this.userId) {
          this.getDoctorInfo();
        }
        this.toastr.success(message);
      }
      else {
        const message = 'Failed to activate \'' + this.doctorProfileModel.full_name + '\' in the system.';
        this.toastr.error(message);
      }
    },(error)=>{
      const errorMessage = error.error.message;
      this.toastr.error(errorMessage);
    }
    );
  }



  restoreDoctor(event: Event, doctor_id: number): void {
    event.preventDefault();
    if (!CommonFuntions.guardForAction(this.loginService, this.router)) {return;}

    const message = 'Do you want to activate \'' + this.doctorProfileModel.full_name + '\' in the system?';
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Activate Confirmation',
      message,
      'Yes',
      'Cancel',
      function () {
        this.popUpService.hideDiv('confirmDialog');
        this.userService.restoreUser(doctor_id).pipe(takeWhile(() => this.alive)).subscribe(
          function (result) {
            if (result) {
              const message = '\'' + this.doctorProfileModel.full_name + '\' has been successfully activated in the system.';
              this.showAlert(message, function () {
                this.popUpService.hideDiv('alertDialog');
                if (this.userId) {
                  this.getDoctorInfo();
                }
              }.bind(this));
            }
            else {
              const message = 'Failed to activate \'' + this.doctorProfileModel.full_name + '\' in the system.';
              this.showAlert(message, function () {
                this.hideDiv('alertDialog');
              });
            }
          }.bind(this),
          function (error) {
            this.userService.handleError(error, 'delete');
          }.bind(this)
        );
      }.bind(this),
      function () {
        this.hideDiv('confirmDialog');
      }
    );

    const confirmDialog = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(confirmDialog);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private showAlert(message: string, positiveCallback: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok',
    );

    this.popUpService.setPositiveAction(positiveCallback);

    const alertDialog = this.popUpService.getPopUpView('alertDialog');
    this.popUpService.showDiv(alertDialog);
  }

  getUserCaseList(): void {
    this.caseService.getAssignedCasesByUserId(this.userId).subscribe(
      result => {
        this.caseList = result?.data || {};
      }
    );
  }
}

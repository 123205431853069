/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */

import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ConfigAssetLoaderService } from '../services/config-asset-loader.service';
import { LoginModel } from '../domains/loginModel';
import { TokenModel } from '../domains/tokenModel';
import { Router } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';
import { PopUpService } from './pop-up.service';

const httpOptions = {
  headers: new HttpHeaders()
};

const USER_ROLE = {
  PARTNER: 'Partner',
  PATIENT: 'Patient',
  DEFAULT: 'Default',
  CARETEAM: 'Careteam',
  CASE_COORDINATOR: 'CaseCoordinator'
};
/**
* Service used for login operations
*/
@Injectable()
export class LoginService {

  /**
	* Login service constructor
	*
	* @param {HttpClient} http Http client class for http operation
	*  */
  constructor(
    private http: HttpClient, 
    private router: Router, 
    private popUpService: PopUpService,
  ) { }


  /**
	* Recieves login model recorded on form. Posts the information to api url and sets jwt token in brower's
	*local storage if api returns token else returns false
	*
	* @param {LoginModel} model Login form data as LoginModel class
	* @return true if recieves jwt token
	*  */
  checkForLogin(model: LoginModel) {
    return this.http.post<any>(ConfigAssetLoaderService.getApiURL() + '/user/login',
      model,
      httpOptions).pipe(
      switchMap(token => {
        const jwtHelper = new JwtHelperService();
        const userInfo = jwtHelper.decodeToken(token.id_token);
        if (token && token.id_token) {
          localStorage.setItem('token', token.id_token);
        }
        if (token && token.refresh_token && token.refresh_token != null) {
          localStorage.setItem('refresh_token', token.refresh_token);
        }
        return this.gePartnerList(token.id_token, userInfo.user_type.type);
      })
    );
  }

  async ssoLogin() {
    const query = (new URL(window.location.href)).searchParams;
    const token = query.get('token');
    const redirect = query.get('returnUrl');
    const allyCaseRef = query.get('caseId');
    const errorMessage = query.get('errorMessage');

    const showErrorMessage = (msg?: string, onConfirm?: () => void) => {
      if (!msg && !errorMessage) return;

      this.popUpService.destoryConfirmDialog();

      this.popUpService.setDialogParams(
        'Error',
        msg || errorMessage,
        'Ok',
        undefined,
        function () {
          if (onConfirm) onConfirm();
          this.hideDiv('alertDialog');
        },
        undefined
      );
      const div = this.popUpService.getPopUpView('alertDialog');
      this.popUpService.showDiv(div);
    };

    if(token){
      await localStorage.setItem('token', token);
      if (allyCaseRef) {
        this.getCaseByAllyCaseRef(allyCaseRef).subscribe(result => {
          this.router.navigate([`/case/${+result.id}`]);
        }, 
        (error) => {
          showErrorMessage(error.error.message);
        });
      }
      else {
        const localAllyCaseRef = localStorage.getItem('allyCaseRef');

        if (localAllyCaseRef) {
          this.getCaseByAllyCaseRef(localAllyCaseRef).subscribe(result => {
            this.router.navigate([`/case/${+result.id}`]);
          }, 
          (error) => {
            this.router.navigate(['/page-not-found'], {
              queryParams: {
                message: `${error.error.message}`,
                title: 'Case not Found'
              } 
            });
          });
        }
        else this.router.navigate([redirect]);
      } 
    }
    else if (allyCaseRef) {
      localStorage.setItem('allyCaseRef', allyCaseRef);

      this.ssoLogin2().subscribe(result => {
        window.location.href = result;
      }, (error) => {
        console.log('error', error);
      });
    } 
    else if (errorMessage) {
      showErrorMessage();
    }
  }

  ssoLogin2() {
    return this.http.post<any>(ConfigAssetLoaderService.getApiURL() + '/user/sp-login', {});
  }

  getCaseByAllyCaseRef(allyCaseRef: string) {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    };

    return this.http.get<any>(ConfigAssetLoaderService.getApiURL() + `/case/case_by_ally_ref/${allyCaseRef}`, headers);
  }

  /**
	* Looks for jwt token in brower's local storage
	* @return true if brower has token and it is still not expired. false if brower has token & is expired or if brower doesnot have token
	*/
  isLoggedIn() {

    const token = localStorage.getItem('token');
    if (!token) {return false;}

    try {
      const jwtHelper = new JwtHelperService();
      const isExpired = jwtHelper.isTokenExpired(token);
      return !isExpired;
    }
    catch (error) {
      return false;
    }
  }

  /**
	* Looks for token in browers local storage. Retrieves information from it.
	* @return Object containing information from jwt token
	*/
  getCurrentUser() {
    const token = localStorage.getItem('token');
    if (!token){
      return null;
    }
    const jwtHelper = new JwtHelperService();
    const userInfo = jwtHelper.decodeToken(token);
    const partnerList = JSON.parse(localStorage.getItem('user_partners')) || [];
    if(partnerList){
      userInfo.partners = partnerList;
    }  
    return userInfo;
  }

  /**
	* Returns appropirate information string if error occurs.
	*
	* @param {HttpErrorResponse} error Http errors thrown by http service.
	* @return String according to HttpErrorResponse for login */
  handleError(error: HttpErrorResponse): string {
    // show server error
    if (error.error && error.error.description) {
      return error.error.description;
    }
    console.log(error);
    

    if (error.status === 401 || error.status === 400) {
      return 'Incorrect username and/or password';
    }
    else if (error.status === 402) {
      return error.error.message || error.error.msg;
    }
    else if (error.status === 429) {
      return error.error;
    }
    else {
      return 'Connection Failed. Please contact your administrator';
    }
  }

  /**
	* Erases token form brower's local storage
	*/
  removeToken() {
    const token = localStorage.getItem('token');
    if (token !== null) {
      const jwtHelper = new JwtHelperService();
      const payload = jwtHelper.decodeToken(token);

      const object = {};
      object['refresh_token'] = localStorage.getItem('refresh_token');
      object['user_id'] = payload.user_id;

      setTimeout(() => {
        const statusFilterSaveStateString = localStorage.getItem('statusFilterSaveState');
        const statusFilterSaveState = JSON.parse(statusFilterSaveStateString);

        if (statusFilterSaveState && statusFilterSaveState.userType.toLowerCase() !== 'careteam') {
          localStorage.removeItem('statusFilterSaveState');
        }
      }, 300);

      this.http.post(
        ConfigAssetLoaderService.getApiURL() + '/token/disable',
        object
      ).subscribe(
        result => {
          console.log(result);
        }
      );
    }

    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user_partners');
    localStorage.removeItem('passwordExpirySetting');
  }

  refreshToken() {
    const refresh_token = localStorage.getItem('refresh_token');
    const token = localStorage.getItem('token');

    if (!token || !refresh_token)  { return; }

    const jwtHelper = new JwtHelperService();
    const payload = jwtHelper.decodeToken(token);

    const object = {};
    object['refresh_token'] = localStorage.getItem('refresh_token');
    object['user_id'] = payload.user_id;

    this.http.post<TokenModel>(
      ConfigAssetLoaderService.getApiURL() + '/token/refresh',
      object
    ).subscribe(
      result => {
        localStorage.setItem('token', result.id_token);
      }
    );
  }
  
  gePartnerList(token: string, type = USER_ROLE.DEFAULT){
    // eslint-disable-next-line max-len
    if(type == USER_ROLE.CARETEAM || type == USER_ROLE.PARTNER  || type == USER_ROLE.CASE_COORDINATOR || type == USER_ROLE.PATIENT || type == USER_ROLE.DEFAULT){
      // fetch partner array for careteam, partner and patient
      const headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };
      return this.http.get<any>(ConfigAssetLoaderService.getApiURL() + '/user/partner/getPartnerList', headers).pipe(map(partnerList => {
        if(partnerList.userPartners){
          localStorage.setItem('user_partners', JSON.stringify(partnerList.userPartners));
        }
        return token;
      }));
    }
    else{
      return of(token);
    }
  }

  isSFLogin() {
    const token = localStorage.getItem('token');
    const jwtHelper = new JwtHelperService();

    const userInfo = jwtHelper.decodeToken(token);

    return userInfo?.isSFLogin;
  }

}

<app-dialog></app-dialog>

<div class="header">
  <div class="header__page-name">
    <h1 class="heading heading--main">Settings</h1>
  </div>
</div>
<div class="wrapper--dash-content dash-content">
  <div class="tabs__content" [class.overlay--visible]="isInprogress">
    <div class="overlay__content">
      <div class="overlay__spinner">
        <span class="icon icon--loading"></span>
      </div>
    </div>
    <div class="form tabs__content--white-bg">
      <form [formGroup]="settingForm">
        <h3 class="form__title margin--top-0">Default Careteam For Ally Clients</h3>
        <div class="row">
          <div class="col col__md-6 gutter--right-3x">
            <div class="form__row">
              <label for="" class="form__label">Alight Careteam</label>
              <div class="partnerList form__field is-sarch-input">
                <input type="text" auto-complete [source]="IMDCareteamList" [list-formatter]="suggestionListFormatter"
                  [value-formatter]="valueListFormatter.bind(this)" [re-focus-after-select]="false" #pat />
                <div class="search-list">
                  <div class="search-list" formArrayName="imdCareteamControl">
                    <div class="search-item" *ngFor="let partner of imdCareteamControl['controls']">
                      {{partner.value.full_name}}
                      <span (click)="clearPartner(partner)">X</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col col__md-6">
            <div class="form__row">
              <label for="" class="form__label">Client Careteam</label>
              <div class="partnerList form__field is-sarch-input">
                <input type="text" auto-complete [source]="partnerCareteamList"
                  [list-formatter]="suggestionListFormatter" [value-formatter]="valueListFormatter.bind(this)"
                  [re-focus-after-select]="false" #pat1 />
                <div class="search-list">
                  <div class="search-list" formArrayName="partnerCareteamControl">
                    <div class="search-item" *ngFor="let partner of partnerCareteamControl['controls']">
                      {{partner?.value?.full_name}}
                      <span (click)="clearPartner(partner)">X</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="btn-row text-right">
        <a href="javascript:void(0)" class="btn btn--green" (click)="addToDefaultList()">Save</a>
      </div>
    </div>

    <div class="form tabs__content--white-bg">
      <div class="row">
        <div class="col col__md-6 gutter--right-3x">
          <div class="box box--white box--radius box--no-pad box--margin-bottom-3x">
            <div class="team-list">
              <div class="team-list__title">
                <div class="team-list__title--title">Aligth Careteam</div>
                <div class="team-list__title--add-new">
                  <!-- <a href="#" (click)="addCareTeam($event)"
                    *ngIf="role?.toLowerCase()==='admin' && showAddCT">Add New</a> -->

                </div>
              </div>
              <div class="team-list__row" *ngFor="let careTeam of careteams">
                <div class="list-row">
                  <div class="list-row__image">
                    <div class="thumb thumb--small">
                      <img [src]="profilePicPath" *ngIf="!careTeam.avatarImage" />
                      <img [src]="'data:image/jpg;base64,'+careTeam.avatarImage" *ngIf="careTeam.avatarImage" />
                    </div>
                  </div>
                  <div class="list-row__name">
                    <a href="#" class="hideHREF" (click)="viewUserInfo($event, careTeam.user_id)">
                      {{careTeam.full_name}}
                    </a>
                  </div>
                  <div class="list-row__action">
                    <a href="javascript:viod(0)" title="Delete" (click)="removeThisCareTeam(careTeam, $event)">
                      <span class="icon icon--cross"></span>
                    </a>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="col col__md-6">
          <div class="box box--white box--radius box--no-pad box--margin-bottom-3x">
            <div class="team-list">
              <div class="team-list__title">
                <div class="team-list__title--title">Client Careteam</div>
                <div class="team-list__title--add-new">
                  <!-- <a href="#" (click)="addPartnerCareTeam($event)"  *ngIf="showAddPCT">Add New</a> -->
                </div>
              </div>
              <div class="team-list__row" *ngFor="let partnerCareTeam of partnerCareteams">
                <div class="list-row">
                  <div class="list-row__image">
                    <div class="thumb thumb--small">
                      <img [src]="profilePicPath" *ngIf="!partnerCareTeam.avatarImage" />
                      <img [src]="'data:image/jpg;base64,'+partnerCareTeam.avatarImage"
                        *ngIf="partnerCareTeam.avatarImage" />
                      <!-- <img [src]="profilePicPath" /> -->
                    </div>
                  </div>
                  <div class="list-row__name">
                    <a class="hideHREF" href="#" (click)="viewUserInfo($event, partnerCareTeam.user_id)">
                      {{partnerCareTeam.full_name}}
                    </a>
                  </div>
                  <div class="list-row__action">
                    <a href="javascript:viod(0)" title="Delete" (click)="removeThisCareTeam(partnerCareTeam, $event)">
                      <span class="icon icon--cross"></span>
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
    <!--calender__wrapper end-->

  </div>
</div>
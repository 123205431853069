/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable no-undef */
import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../app/services/login.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/userservice.service';

/**
 * Component to hold major views of dashboard.
 */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {

  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private router: Router){
  }

  /**
   * variable to store state if side navigation is collaped or inflated
   */
  isCollapsedDashboard: boolean;

  /**
   * This method is invoked when an event is triggered in it's sub component
   * 'Logo'. It captures the event and stores the result to isCollapsedDashboard.
   *
   * @param {boolean} collapsed Triggered event as boolean
  */
  isCollapsedLogo(collapsed: boolean) {
    this.isCollapsedDashboard = collapsed;
  }

  ngOnInit(): void {
    // this.router.navigate(['/case'], { queryParams: { list: 'current' } } )
    const user = this.loginService.getCurrentUser();
    this.loadUser(user.user_id);
  }

  loadUser(user_id : number): void{
	  this.userService.getUserForPasswordReset(user_id).subscribe(
	    user => {
		  if (user?.force_password_reset == true) {
	        this.router.navigateByUrl('/reset/password');
          return;
		  }

	  });
	  }

  isSFLogin() {
    return this.loginService.isSFLogin();
  }
}

/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Injectable, ElementRef } from '@angular/core';
import {  Subject } from 'rxjs';

@Injectable()
export class PopUpService {

  popUpViews = [];
  showPatient = new Subject<boolean>();
  showCareTeam = new Subject<boolean>();
  showPartnerCareTeam = new Subject<boolean>();

  onPositiveClick: Function;
  onNegativeClick: Function;

  title: string;
  positiveLabel: string;
  message: string;

  negativeLabel: string;
  isLoading: Boolean;

  constructor() {
    this.showPatient.next(false);
    this.showCareTeam.next(false);
    this.showPartnerCareTeam.next(false);
  }

  addPopUpView(name: string, div: ElementRef) {
    if (div) {
      const view = {};
      view['name'] = name;
      view['div'] = div;
      return this.popUpViews.push(view);
    }
  }

  removePopUpView() {
    /* this.popUpViews = this.popUpViews.filter(view => {
      if (view.name.toLowerCase() === 'confirmDialog' || view.name.toLowerCase() === 'alertDialog') {
        return true
      }

      return false
    }) */
    this.popUpViews = [];
  }

  getPopUpView(name: string) {
    const index = this.popUpViews.findIndex(
      view => view.name === name
    );

    if (index < 0) {
      return null;
    }

    return this.popUpViews[index].div;  
  }

  hideDiv(div: ElementRef | string) {
    if (typeof div === 'string') {
      const divToHide = this.getPopUpView(div);
      if (divToHide == null) {return;}
      this.hideDiv(divToHide);
    }
    else {
      div.nativeElement.hidden = true;
    }
  }

  showDiv(div: ElementRef) {
    div.nativeElement.hidden = false;
  }

  setPositiveAction(callback: Function) {
    this.onPositiveClick = callback;
  }

  setNegativeAction(callback: Function) {
    this.onNegativeClick = callback;
  }

  setDialogParams(title: string,
    message: string,
    positiveLable: string,
    negativeLabel?: string,
    positiveCallback?: Function,
    negativeCallback?: Function,


  ) {
    this.title = title;
    this.positiveLabel = positiveLable;
    this.message = message;

    this.negativeLabel = negativeLabel;
    this.onPositiveClick = positiveCallback;
    this.onNegativeClick = negativeCallback;
    this.isLoading = false;
  }

  destoryConfirmDialog() {
    this.title = null;
    this.positiveLabel = null;
    this.message = null;
    this.negativeLabel = null;
    this.onPositiveClick = null;
    this.onNegativeClick = null;
    this.isLoading = false;

  }

  setLoading() {
    this.isLoading = true;
  }
}

<div class="popup webhook-details" [hidden]='!showDetails'>
  <div class="popup__overlay"></div>
  <div class="popup__video overlay__wrapper" [class.overlay--visible]="loading">
    <div class="overlay__content" [class.overlay--visible]="loading">
      <div class="overlay__spinner">
        <span class="icon icon--loading"></span>
      </div>
    </div>
    <div class="popup__header d-flex justify-content-between">
      <h1>Webhook Details</h1>
      <button class="btn btn--small btn--red" (click)="toggleThisTemplate()">X</button>
    </div>
    <div class="popup__content webhook_logs_content">
      <!-------->
      <div class="request-url">
        <span class="urlKey method_{{logData?.method}}">{{logData?.method}} </span><span>{{logData?.webhookType}}</span>
        <label class="urlValue">{{logData?.url}}</label>
      </div>
      <div class="request-description">
        <div class="failSection">
          <span class="urlKey">Failure Reason </span>
          <span class="failReason">{{logData?.failureReason}}</span>
        </div>
        <div *ngIf="logData && logData.payload" class="payload">
          <label>Payload</label>
          <span *ngIf="!isValidJSON" style="color: red;"> Invalid payload</span>
          <a *ngIf="!logData.isSuccess" href="javascript:void(0)" class="btn btn--red btn--mini margin--l-x" [class.disabled]="!isValidJSON" (click)="trigger()" style="float: right;">{{isChanged? 'Save & Try Again': 'Try Again'}}</a>
          <!-- <pre><code>{{ logData?.payload | json}}</code></pre> -->
          <ace-editor [(text)]="code"  mode="json" [readOnly]="logData.isSuccess" style="height:200px;margin-top: 10px;"></ace-editor>
        </div>
        <div class="payload">
          <label>Response</label>
          <pre><code>{{ logData?.response | json}}</code></pre>
        </div>
      </div>
      <!-------->
    </div>
  </div>
</div>
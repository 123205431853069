import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from 'ng2-ckeditor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { TopmenuComponent } from '../dashboard/topmenu/topmenu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../routers/app-routing.module';
import { FileUploadModule } from 'ng2-file-upload';
import { NumbersOnlyDirective } from '../directives/numbers-only.directive';
import { FormatOnlyDirective } from '../directives/format-only.directive';
import { SafeHtmlPipe } from '../common/safe-html.pipe';
import { PhoneNumberDirective } from '../directives/phone-number.directive';
import { NavigationComponentComponent } from './navigation-component/navigation-component.component';
import { DialogComponent } from './dialog/dialog.component';
import { DoctorProfileComponent } from '../dashboard/users/doctor-profile/doctor-profile.component';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { LoaderComponent } from '../common/loader/loader.component';
import { TrackingLineDetailTemplateComponent } from './tracking-line-detail-template/tracking-line-detail-template.component';

/** Services */
import { ActivityService } from '../services/activity.service';
import { DeleteUserTemplateComponent } from './delete-user-template/delete-user-template.component';
import { UserHistoryComponent } from '../dashboard/users/user-history/user-history.component';
import { RejectCaseTemplateComponent } from './reject-case-template/reject-case-template.component';
import { FaqsTemplateComponent } from './faqs-template/faqs-template.component';
import { AssignedCasesComponent } from './assigned-cases/assigned-cases.component';
import { GenericButtonComponent } from './generic-button/generic-button.component';
import { ToastrModule } from 'ngx-toastr';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MomentModule } from 'angular2-moment';
import { PatientsQuestionsComponent } from './patients-questions/patients-questions.component';
import { FollowUpQuestionsComponent } from './follow-up-questions/follow-up-questions.component';
import { TranslatedDocumentsComponent } from './translated-documents/translated-documents.component';
import { DocsToBeTranslatedComponent } from './docs-to-be-translated/docs-to-be-translated.component';
import { PasswordToggleDirective } from '../directives/password-toggle.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FileUploadModule,
    CKEditorModule,
    FontAwesomeModule,
    ToastrModule,
    SweetAlert2Module,
    MomentModule,
  ],
  declarations: [
    TopmenuComponent,
    NumbersOnlyDirective,
    FormatOnlyDirective,
    SafeHtmlPipe,
    PhoneNumberDirective,
    NavigationComponentComponent,
    DialogComponent,
    DoctorProfileComponent,
    LoaderComponent,
    TrackingLineDetailTemplateComponent,
    DeleteUserTemplateComponent,
    UserHistoryComponent,
    RejectCaseTemplateComponent,
    FaqsTemplateComponent,
    AssignedCasesComponent,
    GenericButtonComponent,
    PatientsQuestionsComponent,
    FollowUpQuestionsComponent,
    TranslatedDocumentsComponent,
    DocsToBeTranslatedComponent,
    PasswordToggleDirective
  ],
  providers: [ActivityService],
  exports: [
    TopmenuComponent,
    NumbersOnlyDirective,
    PhoneNumberDirective,
    SafeHtmlPipe,
    PasswordToggleDirective,
    FormatOnlyDirective,
    NavigationComponentComponent,
    DialogComponent,
    DoctorProfileComponent,
    LoaderComponent,
    TrackingLineDetailTemplateComponent,
    DeleteUserTemplateComponent,
    UserHistoryComponent,
    RejectCaseTemplateComponent,
    FaqsTemplateComponent,
    AssignedCasesComponent,
    GenericButtonComponent,
    PatientsQuestionsComponent,
    FollowUpQuestionsComponent,
    TranslatedDocumentsComponent,
    DocsToBeTranslatedComponent,
  ],
})
export class SharedModule {}

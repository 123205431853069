<app-dialog></app-dialog>

<div class="tabs__content overlay__wrapper" [class.overlay--visible]="">
	<div class="overlay__content">
		<div class="overlay__spinner">
			<span class="icon icon--loading"></span>
		</div>
	</div>
	<div class="form tabs__content--white-bg">
		<form [formGroup]="eligibleMemberFormGroup" novalidate>
			<h3 class="form__title">Basic Information</h3>

			<div class="row">
				<div class="col col__md-4">
					<div class="form__row">
						<label for="" class="form__label">First Name *</label>
						<div class="form__field">
							<input type="text" formControlName="first_name" [appFormatOnly]='"trimonly"' />
							<div *ngIf="eligibleMemberFormGroup?.controls['first_name']?.invalid && (eligibleMemberFormGroup?.controls['first_name'].dirty || eligibleMemberFormGroup?.controls['first_name'].touched )"
									class="message message--is-invalid">
								<div *ngIf="eligibleMemberFormGroup.controls['first_name']?.errors.required">
									First Name is required.
								</div>
							</div>
						</div>
					</div>
                </div>
                
                <div class="col col__md-4">
					<div class="form__row margin--lr-x">
						<label for="" class="form__label">Middle Name</label>
						<div class="form__field">
							<input type="text" formControlName="middle_name" [appFormatOnly]='"trimonly"' />
						</div>
					</div>
                </div>
                
                <div class="col col__md-4">
					<div class="form__row">
						<label for="" class="form__label">Last Name *</label>
						<div class="form__field">
							<input type="text" formControlName="last_name" [appFormatOnly]='"trimonly"' />
							<div *ngIf="eligibleMemberFormGroup?.controls['last_name']?.invalid && (eligibleMemberFormGroup?.controls['last_name']?.dirty || eligibleMemberFormGroup?.controls['last_name']?.touched )"
									class="message message--is-invalid">
								<div *ngIf="eligibleMemberFormGroup?.controls['last_name']?.errors?.required">
									Last Name is required.
								</div>
							</div>
						</div>
					</div>
                </div>
            </div>	
            <div class="hr hr--space-down-2x hr--space-up-x"></div>

            <div class="row">
				<div class="col col__md-4">
					<div class="form__row">
						<label for="" class="form__label">Email *</label>
						<div class="form__field">
							<input type="email" formControlName="email" [appFormatOnly]='"trimonly"' />
							<div *ngIf="eligibleMemberFormGroup?.controls['email']?.invalid && (eligibleMemberFormGroup?.controls['email']?.touched )"
									class="message message--is-invalid">
								<div *ngIf="eligibleMemberFormGroup?.controls['email']?.errors.required">
									Email is required.
								</div>
								<div *ngIf="eligibleMemberFormGroup?.controls['email']?.errors.email && !eligibleMemberFormGroup?.controls['email']?.errors?.required">Enter a valid email</div>
							</div>
						</div>
					</div>
                </div>
                
                <div class="col col__md-4">
					<div class="form__row margin--lr-x">
						<label for="" class="form__label">Gender *</label>
						<div class="form__field">
							<select formControlName="gender">
								<option value="" [selected]="genders">Select</option>
								<option *ngFor="let gender of genders" value={{gender.value}}>
									{{gender.label}}</option>
							</select>
							<div *ngIf="eligibleMemberFormGroup?.controls['gender']?.invalid && (eligibleMemberFormGroup?.controls['gender']?.dirty || eligibleMemberFormGroup?.controls['gender']?.touched )"
									class="message message--is-invalid">
								<div *ngIf="eligibleMemberFormGroup?.controls['gender']?.errors.required">
									Gender is required.
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col col__md-4">
					<div class="form__row">
						<label for="" class="form__label">Phone Number *</label>
						<div class="form__field">
							<input type="tel" formControlName="phone_number" [appFormatOnly]='"trimonly"' maxlength="15"
							PhoneNumber />
							<div *ngIf="eligibleMemberFormGroup?.controls['phone_number'].invalid && (eligibleMemberFormGroup?.controls['phone_number'].dirty || eligibleMemberFormGroup.controls['phone_number'].touched )"
									class="message message--is-invalid">
								<div *ngIf="eligibleMemberFormGroup?.controls['phone_number'].errors.required">
									Phone Number is required.
								</div>
							</div>
						</div>
					</div>
				</div>
				
				
            </div>

            <div class="row">

				<div class="col col__md-4">
					<div class="form__row">
						<label for="" class="form__label">Company Name *</label>
						<div class="form__field">
							<select formControlName="company_name">
								<option value="" [selected]="partnerList">Select</option>
								<option *ngFor="let partner of partnerList" value={{partner.partner_name}}>
									{{partner.partner_name}}</option>
							</select>
							<div *ngIf="eligibleMemberFormGroup?.controls['company_name'].invalid && (eligibleMemberFormGroup.controls['company_name']?.dirty || eligibleMemberFormGroup?.controls['company_name'].touched )"
									class="message message--is-invalid">
								<div *ngIf="eligibleMemberFormGroup?.controls['company_name'].errors.required">
									Company Name is required.
								</div>
							</div>
						</div>
					</div>
                </div>

				<div class="col col__md-4">
					<div class class="form__row margin--lr-x">
						<label for="" class="form__label">Date of Birth *</label>
						<div class="form__field">
                            <div class="form__field--date">
                                <input type="date" placeholder="Enter Date" [owlDateTime]="dt2"
                                    (dateTimeChange)="onDateChange($event, dt2, dateInput)" [max]="currentDate"
                                    (click)="preventDefault($event)" #dateInput />
                                <span class="trigger  icon icon--calendar" [owlDateTimeTrigger]="dt2"></span>
                                <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
							</div>
                        </div>
						<div *ngIf="eligibleMemberFormGroup?.controls['dob'].invalid && (eligibleMemberFormGroup?.controls['dob'].dirty || eligibleMemberFormGroup?.controls['dob'].touched )"
								class="message message--is-invalid">
							<div *ngIf="eligibleMemberFormGroup.controls['dob']?.errors.required">
								Date of Birth is required.
							</div>
						</div>
					</div>
				</div>
				
				
			</div>
		</form>
		<div class="btn-row text-center">
			<a (click)="onSubmit(eligibleMemberFormGroup)" class="btn btn--green" > {{submitButtonLabel}} </a>
			<a (click)="onCancel($event)" class="btn btn--red">Cancel</a>
		</div>
	</div>
</div>
<div class="top-menu" >
	<div class="top-menu__user-stat">
		<span>Total Configuration</span> {{ totalRecord }}
	</div>
	<div class="top-menu__menu">
	</div>

	<!-- Search box -->
	<div class="top-menu__search">
		<div class="search-wrapper">
			<div class="sarch-box">
				<input type="text" [(ngModel)]="searchValue" (ngModelChange)="onModelChange()" />
			</div>
		</div>

		<div class="add-user" *ngIf="userRole === 'admin'">
			<a [routerLink]="['/settings/outgoing-mail-server/add']" class="btn btn--blue btn--mini">
				Add New Mail Server
			</a>
		</div>
	</div>
</div>
<div class="col space-coord single-centered-col">
    <div class="user-activites" *ngIf= "currentUser?.user_type?.type.toLowerCase() == 'admin' || currentUser?.user_type?.type.toLowerCase() == 'cmmanager' && currentUser.user_id !== userId">
        <div class="profile-card__label">
            <div class="text-center">
                <a href="javascript:void(0)" class="go-back" (click)="getActivities()" > {{showUserActivities === true && userActivitiesExists ?  'Hide': 'Show'}} User Activities</a>
                <div *ngIf="showUserActivities===true && userActivitiesExists ===false">No User Activities Found.</div>
            </div>
            <ul [hidden]="!showUserActivities"
                class="profile-card__details-list">
                <li *ngFor="let activity of activities" class="user-list-item-ul" [class]="activityService.getContainerColor(activity)">
                    <div class="d-flex w-100 h-100 align-items-center">
                        <div class="w-100 h-100">
                          <p class="activity-title-space title text-bold"> {{activity.title}}</p>
                          <!-- <p *ngIf = "activity.activity" class="activity-title-space subtitle">{{ (activity?.activity?.length>180)? (activity.activity | slice:0:180)+' ...':(activity.activity) }}</p> -->
                          <div class="d-flex justify-content-between w-100">
                            <p class="activity-title-space info">{{activity?.user?.full_name || 'Unknown'}}</p>
                            <p class="activity-title-space info">{{ activity?.updated_at | date: 'MM/dd/yyyy, h:mm a' }}</p>
                          </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>

/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
export class DoctorProfileModel {

    full_name: string;
    email: string;
    phone_number: string;
    institution: string;
    previous_employer: string;
    stage: string;
    avatar: string;
    title: any;
    rating: number;
    doctor_certifications: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    user_languages: string;
    is_deleted: boolean;
    delete_reason: string;
    today: string;
    profile_id: number;
    field_of_medicine: any;
    affiliated_hospital: any;
    website: any;
    education_list: any[];
    institution_list: any[];
    procedures: any;
    bio: any;
    contacts: any;
    partners: any[];
    country: any;
    referred_by: any;
    doctor_id: any;
    availability: string;
    note: string;
    allyUserRef: string;
    conditions: string;
    languages: string;


    constructor() {
    }

}

<button
    type="button"
    [disabled]="isBtnDisabled()" 
    (click)="onBtnClick()"
    class="btn {{class}}"
    [class.btn--grey]="isBtnGrey()"
    [class.btn--blue]="isBtnBlue()"
    [class.btn--purple]="isBtnPurple()"
    >
    <ng-content></ng-content>
</button>
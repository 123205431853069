/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnInit, Input, Output, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { ConfigAssetLoaderService } from '../../services/config-asset-loader.service';
import { ActivityService } from '../../services/activity.service';
import { BehaviorSubject, Subject } from 'rxjs';
import * as moment from 'moment';
import { CaseService } from '../../services/case.service';
import { LoginService } from '../../services/login.service';
import { CommonService } from '../../services/common.service';
import { DatashareService } from '../../services/datashare.service';
import { PopUpService } from '../../services/pop-up.service';

@Component({
  selector: 'app-tracking-line-detail-template',
  templateUrl: './tracking-line-detail-template.component.html',
  styleUrls: ['./tracking-line-detail-template.component.css']
})
export class TrackingLineDetailTemplateComponent implements OnInit {
  @ViewChild('trackingDocument', { static: false }) trackingDocument: ElementRef;
  @ViewChild('documentType', { static: false }) documentType: ElementRef;
  @Input() shouldHide: boolean;
  @Input() trackingLineObj;
  @Input() trackingLineNoteList;
  @Input() trackingline_upload;
  @Input() loadingNotes;
  @Input() isMdManager;
  @Output() toggleTemplate = new EventEmitter<boolean>();
  @Output() deleteTrackingDocument = new EventEmitter<boolean>();
  @Output() updateCaseDocuments = new EventEmitter<boolean>();
  uploaderObj: FileUploader;
  loading: boolean;
  uploading: boolean;
  profilePicPath: string;
  tabs: Subject<string> = new BehaviorSubject(null);
  errors;
  isLinkUploading:boolean;
  types: any[] = [{
    key: 'Select',
    value: 'select'
  }];

  trackingNoteForm: FormGroup;
  avatarimage;

  constructor(
    private fb: FormBuilder,
    private activityService: ActivityService,
    private caseService: CaseService,
    private loginService: LoginService,
    private commonService: CommonService,
    private dataShareService: DatashareService,
    private popUpService: PopUpService,
  ) { }
  /**
   * Function to initialize the note area form
   * @return {*}  {FormGroup}
   * @memberof TrackingLineDetailTemplateComponent
   */
  initializeForm(): FormGroup {
    return this.fb.group({
      message: [{value: '', disable: this.isMdManager}, [Validators.required, Validators.maxLength(50000)]],
      title: ['CARE TEAM COMMENT', [Validators.required]]
    });
  }

  /**
 * Function for setting the file upload properties
 * @return {*}  {FileUploader}
 * @memberof TrackingLineDetailTemplateComponent
 */
  enableUploadSettings(): FileUploader {
    const url = ConfigAssetLoaderService.getApiURL() + '/edit/upload';
    
    const token = localStorage.getItem('token');
    const fileUploader = new FileUploader(
      {
        url: url,
        itemAlias: 'document',
        authToken: `Bearer ${token}`,
        queueLimit: 1
      }
    );

    /** Function that is called when the file is uploaded. */
    fileUploader.onAfterAddingFile = function (file): void {
      file.withCredentials = false;

      if (fileUploader.queue.length > 1) {
        fileUploader.queue.splice(0, 1);
      }

      setTimeout(
        function (): void {
          if (this.trackingDocument) { this.trackingDocument.value = null; }
        }.bind(this)
        ,300);
    }.bind(this);

    /** Function called when the file upload is completed. */
    fileUploader.onCompleteItem = function (_item, response, status, _header): void {
      if (response == null || response === '') { return; }
      if (response !== '' && status !== 200) {
        const error = JSON.parse(response);
        let message = 'Failed to upload document.';
        if (error && error.msg) { message = error.msg; }
        this.showAlert(message);
      }
      else if (response !== '') {
        (this.trackingline_upload || []).push(JSON.parse(response));
        this.trackingDocument.nativeElement.value = '';
        this.documentType.nativeElement.value = 'select';
        this.updateCaseDocuments.emit();
      }
      this.uploading = false;
      this.uploaderObj.clearQueue();
    }.bind(this);

    fileUploader.onErrorItem = function (_item, _response, _status, _header) {
      this.uploading = false;
    };

    fileUploader.onWhenAddingFileFailed = function (_item, filter, _option): void {
      switch (filter.name) {
      case 'mimeType':
        this.trackingDocument.nativeElement.value = '';
        this.showAlert('System does not support this type of file format.');
        break;
      case 'fileSize':
        this.trackingDocument.nativeElement.value = '';
        this.showAlert('Files to be uploaded must be less than of size 50 MB.');
        break;
      default:
        break;
      }
    }.bind(this);
    this.uploading = false;
    return fileUploader;
  }

  ngOnInit(): void {
    this.profilePicPath = '/assets/images/profile-pic-male.png';

    this.trackingNoteForm = this.initializeForm();
    this.uploaderObj = this.enableUploadSettings();
    this.getDocumentType();
    this.dataShareService.$displayPicture.subscribe(function (image) {
      this.avatarimage = image;
    }.bind(this));

    if(this.isMdManager){
      this.trackingNoteForm.disable();
    }
  }
 
  /**
 * Function to upload the file calling the API
 * @return {*}  {void}
 * @memberof TrackingLineDetailTemplateComponent
 */
  uploadFile(docType): void {
    this.uploading = true;
    if (this.uploaderObj.queue.length < 1) {
      this.uploading = false;
      return;
    }
    
    const token = localStorage.getItem('token');
    this.uploaderObj.authToken = `Bearer ${token}`;
    this.uploaderObj.onBeforeUploadItem = function(item): void {
      const type = docType.value;
      const upload_stage = 'CASE_CREATION';
      const tracking_id = this.trackingLineObj.tl_id;
      const caseid = this.trackingLineObj.caseId;
      item.url = ConfigAssetLoaderService.getApiURL() + `/edit/upload?caseid=${+caseid}&type=${type}&upload_stage=${upload_stage}&tracking_id=${tracking_id}`;
    }.bind(this);

    this.uploaderObj.uploadAll();
  }

  /**
 * This function emit the value to its parent component. ie; Tracking line component
 * @memberof TrackingLineDetailTemplateComponent
 */
  toggleThisTemplate(): void {
    this.toggleTemplate.emit(false);
  }

  /**
 * Function to add note to the Tracking line
 * @memberof TrackingLineDetailTemplateComponent
 */
  postComment(): void {
    if (this.trackingNoteForm.valid) {
      this.loading = true;

      const payload = {
        trackingLineNotes: [
          {
            message: this.trackingNoteForm.get('message').value,
            title: this.trackingNoteForm.get('title').value
          }
        ],
        caseid: this.trackingLineObj.caseId,
        tlid: this.trackingLineObj.tl_id,
      };

      this.activityService.createTrackingLineActivity(payload).subscribe(
        result => {
          const { data } = result;
          data.map(res=>{
            res.user.image = {};
            res.user.image.image = this.avatarimage;
            return res;
          });
          this.trackingLineNoteList = [...data, ...this.trackingLineNoteList];
          this.loading = false;
          this.trackingNoteForm.get('message').setValue('');
          this.trackingNoteForm.markAsUntouched();
          this.showAlert('Note added successfully.');
        },
        (_error) => {
          this.loading = false;
          this.showAlert('Failed to add note.');
        }
      );
    } 
    else {
      this.showAlert('Failed to add note. Invalid Form.');
    }
  }

  /**
   * Returns tooltip text of the document
   * @return {string}  {string}
   * @memberof TrackingLineDetailTemplateComponent
  */
  getTitle(document){
    const date = moment(document.updated_at).format('MM/DD/YYYY');
    const fullName = document.user && document.user.full_name;
    return `${fullName} \n ${date}`;
  }

  /**
   * Changes tab
   * @return {*}  {void}
   * @memberof TrackingLineDetailTemplateComponent
  */
  changeTab(caseStatus: string, event?: Event) {
    if (event) { event.preventDefault(); }
    this.tabs.next(caseStatus);
    localStorage.removeItem('DataTables_casetable_active_/case');
  }

  /**
   * Returns Documents based of tracking lines
   * @return {Array}  {Array}
   * @memberof TrackingLineDetailTemplateComponent
  */
  getDocuments(trackingId){
    return [...this.trackingline_upload].filter(document=>(document.tracking_id == trackingId) && document.document_type);
  }

  /**
   * Returns scan links based of tracking lines
   * @return {Array}  {Array}
   * @memberof TrackingLineDetailTemplateComponent
  */
  getScanLinks(trackingId){
    return [...this.trackingline_upload].filter(document=>((document.tracking_id == trackingId) && !document.document_type));
  }

  public editAddScan(scanName: HTMLInputElement, scanLocation: HTMLInputElement, _section?: string, event?: Event) {
    if (event) { event.preventDefault(); }
    this.isLinkUploading = true;
    this.errors={};
    if(scanName.value.toLowerCase() === '') { 
      this.errors = {
        ...this.errors,
        scanName: 'Name is required.',
        scanError: true
      };
    }
    if(scanLocation.value.toLowerCase() === '') { 
      this.errors = {
        ...this.errors,
        scanLink: 'Link is required.',
        scanError: true
      };
    }

    if(this.errors.scanError){ 
      this.isLinkUploading = false;
      return;
    }

    const scan = {};
    scan['case_id'] = this.trackingLineObj.caseId;
    scan['document_name'] = scanName.value;
    scan['location'] = scanLocation.value;
    scan['tracking_id'] = this.trackingLineObj.tl_id;
    scan['upload_stage'] = 'TRANSLATOR_ATTACHMENT';


    this.caseService.addScanList(scan).subscribe(
      addScan => {
        (this.trackingline_upload || []).push(addScan);
        this.isLinkUploading = false;
        this.updateCaseDocuments.emit();
      },
      (_error) => {
        this.showAlert('Failed to add scan link.');
        this.isLinkUploading = false;
      }
    );

    scanName.value = '';
    scanLocation.value = '';
  }

  /**
 * Function to fetch all the document type 
 * @private
 * @memberof GeneralCaseComponent
 */
  private getDocumentType() {
    const user = this.loginService.getCurrentUser();
    this.commonService.getCaseDocumentType(user.user_type.type).subscribe(
      types => {
        const typesList = [{
          key: 'Select',
          value: 'SELECT'
        }];
        this.types = typesList.concat(types);
      }
    );
  }

  deleteDocument(data){
    this.deleteTrackingDocument.emit(data);
  }

  private showAlert(message: string, action?) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (!action) {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }
    else {
      this.popUpService.setPositiveAction(
        action
      );
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) { this.popUpService.showDiv(dialog); }
  }

  downloadDoc(id, filename?: string, event?: Event): void {
    if (event) { event.preventDefault(); }

    this.caseService.downloadPDF(id, filename).subscribe(
      result => {
        const { url } = result;
        window.open(url, '_blank');
      },
      error => {
        const errorText = error.error.message || 'Cannot open file';
        this.showAlert(errorText ,function (): void {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
      }
    );
  }

  htmlToPlaintext = (text) => {
    return text ? String(text).replace(/<[^>]+>/gm, '').replace('&nbsp;', ' ') : '';
  };

  getImageUrl = (note) => {
    if(note.message_from_name){
      return this.profilePicPath;
    }
    else{
      return note.user?.image?.image ? 'data:image/jpg;base64,'+ note.user.image.image : this.profilePicPath;
    }
  }
}

/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { PopUpService } from '../../../services/pop-up.service';
import { CommonFuntions } from '../../../common/common.function';
import { EligibleMember } from '../../../domains/eligible-member.model';
import { UserService } from '../../../services/userservice.service';
import { LoginService } from '../../../services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-eligible-member-profile',
  templateUrl: './eligible-member-profile.component.html',
  styleUrls: ['./eligible-member-profile.component.css']
})
export class EligibleMemberProfileComponent implements OnInit {
  @ViewChild('inactiveUserSwal') private inactiveUserSwal: SwalComponent;
  @ViewChild('inactiveUserConfirmationSwal') private inactiveUserConfirmationSwal: SwalComponent;
  avatarimage: any;
	profilePicPath: string;
	userType: string;
	userId: number;
  doctorId: number;
  user: EligibleMember;
  hideUserDeleteTemplate = true;
  reasons :any = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private userService: UserService,
    private popUpService: PopUpService,
    private toastrService:ToastrService
  ) { }

  ngOnInit(): void {
    this.profilePicPath = '/assets/images/profile-pic-male.png';
    const currentUser = this.loginService.getCurrentUser();
    this.userType = currentUser.user_type.type.toLowerCase();
    this.route.params.subscribe(response => {
      this.userId = response.id;
      this.userService.getEligibleMember(this.userId).subscribe(
        response => {
          this.user = response as EligibleMember;
        }
      );
    });
  }

  toggleUserDeleteTemplate(value: boolean): void {
    this.hideUserDeleteTemplate = value;
  }

  activateDeleteTemplate(event: Event): void {
    if (event) event.preventDefault();
    this.hideUserDeleteTemplate = false;
  }

  validReason():void {
    const minLength = 3;
    const maxLength = 5000;
    if (this.inactiveUserSwal === undefined) { return; }
    this.inactiveUserSwal.inputValidator = (value) => {
      return new Promise<any>((resolve) => {

        if (value.length >= minLength && value.length <= maxLength) {
          this.reasons = value;
          this.inactiveUserConfirmationSwal.fire();

        }
        else if(value.length >= maxLength){
          resolve('This field must not contain more than 50,000 characters !');
        }
        else {
          resolve('This field must contain at least 3 characters!');
        }
      });
    };
  }


  deleteUser(): void {
    const full_name = this.user.first_name + ' ' + this.user.middle_name + ' ' + this.user.last_name;
    if (!CommonFuntions.guardForAction(this.loginService, this.router)) {return;}
    
    this.userService.deleteEligibleMember(this.userId, this.reasons)
      .subscribe((result)=>{
        if(result){
          const message = '\'' + full_name + '\' has been successfully deleted from the system.';
          this.toastrService.success(message);
          this.router.navigate(['/eligible-member-list']);
        }
        else{
          const message = 'Failed to delete \'' + full_name + '\' from the system.';
          this.toastrService.error(message);
        }
      },(error)=>{
        this.toastrService.error(error['statusText']);
     
      }
      );
  }

  onRestore(userId: any){
    const full_name = this.user.first_name + ' ' + this.user.middle_name + ' ' + this.user.last_name;
    if (!CommonFuntions.guardForAction(this.loginService, this.router)) {return;}

    this.popUpService.hideDiv('confirmDialog');
    this.userService.restoreEligibleMember(userId)
      .subscribe((result)=>{
        
        if (result) {
          const message = '\'' + full_name + '\' has been successfully activated from the system.';
          this.toastrService.success(message);
          this.router.navigate(['/eligible-member-list']);
        }
        else {
          const message = 'Failed to activate \'' + full_name + '\' from the system.';
          this.toastrService.error(message);
        }

      },(error)=>{
        this.toastrService.error(error['statusText']);
         
      }
      );


  }

 

}

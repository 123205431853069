<div class="popup webhook-details" [hidden]='!showDetails'>
    <div class="popup__overlay"></div>
    <div class="popup__video overlay__wrapper" [class.overlay--visible]="loading">
        <div class="overlay__content" [class.overlay--visible]="loading">
            <div class="overlay__spinner">
                <span class="icon icon--loading"></span>
            </div>
        </div>
        <div class="popup__header d-flex justify-content-between">
            <h1>Mail Log Details</h1>
            <button class="btn btn--small btn--red" (click)="toggleThisTemplate()">X</button>
        </div>
        <div class="popup__content webhook_logs_content">
            <!-------->
            <div class="form__row mb10">
                <label for="" class="form__label smallLabel">Subject:</label>
                <div class="form__field">
                    <label class="contentText boldText">{{logData?.subject}}</label>
                </div>
            </div>
            <div class="form__row mb10">
                <label for="" class="form__label smallLabel">Type:</label>
                <div class="form__field">
                    <label class="contentText">{{logData?.mail_type}}</label>
                </div>
            </div>
            <div class="form__row mb10">
                <label for="" class="form__label smallLabel">To:</label>
                <div class="form__field">
                    <label class="contentText boldText">{{logData?.email_to}}</label>
                </div>
            </div>
            <div *ngIf="logData?.email_cc" class="form__row mb10">
                <label for="" class="form__label smallLabel">Cc:</label>
                <div class="form__field">
                    <label class="contentText">{{logData?.email_cc}}</label>
                </div>
            </div>
            <div *ngIf="logData?.email_bcc" class="form__row mb10">
                <label for="" class="form__label smallLabel">Bcc:</label>
                <div class="form__field">
                    <label class="contentText">{{logData?.email_bcc}}</label>
                </div>
            </div>
            <div class="form__row mb10">
                <label for="" class="form__label smallLabel">Status:</label>
                <div class="form__field">
                    <label class="contentText mail_{{logData?.status}}_text">{{(logData?.status === 'sent')? 'Sent': 'Failed :'}} {{logData?.failure_reason}}</label>
                </div>
            </div>
            <div class="form__row mb10">
                <label for="" class="form__label smallLabel">Text:</label>
                <div class="form__field">
                    <ckeditor class="customheader" [(ngModel)]="ckeditorContent" [readonly]="true" #ckeditorMailField [config]="{toolbar: []}"></ckeditor> 
                </div>
            </div>

            <!-------->
        </div>
    </div>
</div>
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PopUpService } from '../../services/pop-up.service';
import { CommonService } from '../../services/common.service';
import { Router } from '@angular/router';
import { LOG_BACKUP_TYPE } from '../../constant';

@Component({
  selector: 'app-archive-settings',
  templateUrl: './archive-settings.component.html',
  styleUrls: ['./archive-settings.component.css']
})
export class ArchiveSettingsComponent implements OnInit {
  showSaveCancelWebhook = false;
  hideEditButtonWebhook = false;
  showSaveCancelEmail = false;
  hideEditButtonEmail = false;
  formGrp: FormGroup;
  archivelist: any;
  webhookObj: any;
  logType: string;
  showSaveConformPopUp = false;

  constructor(
    private commonService: CommonService,
    private popUpService: PopUpService,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.formGrp = new FormGroup({
    });
    this.commonService.getScheduleLogs().subscribe(
      result => {
        this.archivelist = result;
      }
    );

  }
  EditClicked(event, value): void {
    event.preventDefault();
    if(value === LOG_BACKUP_TYPE.WEBHOOK){
      this.webhookObj = this.archivelist?.data?.find((item)=> item.log_type === 'WEBHOOK');
      this.logType = LOG_BACKUP_TYPE.WEBHOOK;
      this.formGrp = new FormGroup({
        days: new FormControl(this.webhookObj?.time_in_days ? this.webhookObj?.time_in_days : 90, [Validators.required, Validators.max(120), Validators.min(1), Validators.pattern('^[0-9]*$')]),
      });
      this.showSaveCancelWebhook = true;
      this.hideEditButtonWebhook = true;
      this.showSaveCancelEmail = false;
      this.hideEditButtonEmail = false;
    }
    if(value === LOG_BACKUP_TYPE.EMAIL){
      this.webhookObj = this.archivelist?.data?.find((item)=> item.log_type === 'EMAIL');
      this.logType = LOG_BACKUP_TYPE.EMAIL;
      this.formGrp = new FormGroup({
        days: new FormControl(this.webhookObj?.time_in_days ? this.webhookObj?.time_in_days : 90, [Validators.required, Validators.max(120), Validators.min(1), Validators.pattern('^[0-9]*$')]),
      });
      this.showSaveCancelEmail = true;
      this.hideEditButtonEmail = true;
      this.showSaveCancelWebhook = false;
      this.hideEditButtonWebhook = false;
    }
  }
  CancelEdit(event,value): void {
    event.preventDefault();
    if(value === LOG_BACKUP_TYPE.WEBHOOK){
      this.showSaveCancelWebhook = false;
      this.hideEditButtonWebhook = false;
    }
    if(value === LOG_BACKUP_TYPE.EMAIL){
      this.showSaveCancelEmail = false;
      this.hideEditButtonEmail = false;
    }
    this.formGrp.get('days').clearValidators();
  }
  confirmEdit(event, value, type): void {
    event.preventDefault();
    const message = `Are you sure you want to change Archive time for ${type} Logs (only logs prior to the set time will be archived)`;
    const postiveAction = function(): void {
      this.changeLogTime(value);
      this.popUpService.hideDiv('confirmDialog');
    }.bind(this);
    const negativeAction = function(): void {
      this.popUpService.hideDiv('confirmDialog');
    }.bind(this);
    if(value){
      this.showConfirmation(message, postiveAction, negativeAction);
    }

  }
  changeLogTime(value): void {
    this.commonService.setScheduleLogs({time_in_days: value , log_type: this.logType}).subscribe(
      (result: any) => {
        this.showAlert(result.msg);
      }
      , 
      (error: any) => {
        this.showAlert(error?.error?.msg);

      }
    );
  }

  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function): void {
    this.popUpService.destoryConfirmDialog();
    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );
  
    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }
    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }
  
    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);
  }

  public showAlert(message: string, action?: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (action) {
      this.popUpService.setPositiveAction(action);
      window.location.reload(); 
    }
    else {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
        window.location.reload(); 
      });
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) {this.popUpService.showDiv(dialog);}
  }

  get days() {
    return this.formGrp.get('days') as FormControl;
  }
}

<app-dialog></app-dialog>
<div class="header">
  <div class="header__page-name">
    <h1 class="heading heading--main">Profile</h1>
  </div>
</div>
<div class="wrapper--dash-content dash-content overlay__wrapper" [class.overlay--visible]="showSpinner">
  <div class="overlay__content">
    <div class="overlay__spinner">
      <span class="icon icon--loading"></span>
    </div>
  </div>
  <div class="row">
    <div class="col__md-7 gutter--right-2x">
      <div class="box box--white box--radius">
        <div class="profiles profiles--intro">
          <div class="profiles__image">
            <div class="profile-card__image">
              <div class="image-round">
                <app-loader *ngIf="loadingImage"></app-loader>
                <img [src]="profilePicPath" *ngIf="!loadingImage && !avatarimage && profilePicPath" />
                <img [src]="'data:image/jpg;base64,'+avatarimage"
                  *ngIf="!loadingImage && avatarimage && !profilePicPath" />
              </div>
            </div>
            <div class="profiles__name">{{doctorProfileModel.full_name}}</div>
          </div>
          <div class="profiles__details">
            <h1>Doctor - {{doctorProfileModel.field_of_medicine}}</h1>
            <div class="profiles__rating rating-row">
              <!-- <div class="rating">
               {{doctorProfileModel.rating}}
               
              </div>
              <div class="position has-border-l-r">{{doctorProfileModel.title}}</div> -->
              <div class="position">{{doctorProfileModel.title?.title}}</div>
              <div class="location">{{doctorProfileModel.affiliated_hospital}}</div>
            </div>

            <div class="profiles__url doctor__profile">
              <a [title]="doctorProfileModel.website" [href]="doctorProfileModel.website" [target]="'_blank'"
                *ngIf="doctorProfileModel.website">
                External Profile Link</a>
            </div>
          </div>
        </div>
      </div>
      <!--  <div class="box box--grey box--no-pad">
        <agm-map [latitude]="latitude" [longitude]="longitude">
          <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
      
        </agm-map>
      </div> -->
      <div class="box box--grey box--radius">
        <div class="resume resume--centered">
          <h2 class="resume__title">Qualifications and Experience</h2>
          <div class="resume__row">
            <div class="resume-field">Education</div>
            <div class="resume-details">
              <ul class="resume__list">
                <li *ngFor="let education of doctorProfileModel.education_list">{{education}}</li>

              </ul>
            </div>
          </div>
          <div class="resume__row">
            <div class="resume-field">Institution</div>
            <div class="resume-details">
              <ul class="resume__list">
                <li *ngFor="let institute of doctorProfileModel.institution_list">{{institute}}</li>

              </ul>
            </div>
          </div>
          <div class="resume__row">
            <div class="resume-field">Languages Spoken</div>
            <div class="resume-details">
              <ul class="resume__list">
                <li>{{ doctorProfileModel?.languages }}</li>
              </ul>
            </div>
          </div>
          <div class="resume__row" *ngIf="certifications">
            <div class="resume-field">Board Certifications</div>
            <div class="resume-details">
              <ul class="resume__list">
                <li *ngFor="let cert of certifications">{{cert}}</li>
              </ul>
            </div>
          </div>
          <div class="resume__row">
            <div class="resume-field">Conditions</div>
            <div class="resume-details">
              <ul class="resume__list">
                <li>{{doctorProfileModel?.conditions}}</li>
              </ul>
            </div>
          </div>

          <div class="resume__row" *ngIf="doctorProfileModel.procedures">
            <div class="resume-field">Procedural Expertise</div>
            <div class="resume-details">
              <ul class="resume__list">
                <li>{{doctorProfileModel.procedures}} </li>
              </ul>
            </div>
          </div>

          <div class="resume__row" *ngIf="doctorProfileModel.bio">
            <div class="resume-field">Bio</div>
            <div class="resume-details">
              <ul class="resume__list">
                <li>{{doctorProfileModel.bio}}</li>
              </ul>
            </div>
          </div>

        </div>
      </div>

      <app-user-history [userId]="userId"></app-user-history>
    </div>
    <div class="col__md-5">
      <div class="box box--white box--radius box--margin-bottom-2x">
        <div class="contact-details">
          <div class="contact-details__row">
            <div class="contact-details__label">Status</div>
            <div class="contact-details__details">
              <span *ngIf="!doctorProfileModel.is_deleted" class="btn btn--green btn--mini">Active</span>
              <span *ngIf="doctorProfileModel.is_deleted" class="btn btn--red btn--mini">Inactive</span>
            </div>
          </div>
          <div class="contact-details__row">
            <div class="contact-details__label">Phone Number</div>
            <div class="contact-details__details">{{doctorProfileModel.phone_number}}</div>
          </div>
          <div class="contact-details__row" *ngFor="let contact of doctorProfileModel.contacts">
            <div class="contact-details__label">{{contact.social_type.social_type}}</div>
            <div class="contact-details__details">{{contact.social_id}}</div>
          </div>
          <div class="contact-details__row">
            <div class="contact-details__label">Email</div>
            <div class="contact-details__details">{{doctorProfileModel.email}}</div>
          </div>
          <div class="hr hr--space-down-x hr--space-up-x"></div>
          <div class="contact-details__row doctor-profile">
            <div class="contact-details__label">
              Clients
              <div class="client-panel-group">
                <div class="client-list-group">
                  <!---ally clients---->
                  <div *ngIf="allAllyClients.length > 0">
                    <div class="client-list-item" (click)="showAllyClients = !showAllyClients">Ally
                      Clients
                      ({{allAllyClients.length}})
                      <a href="javascript:void(0)" class="go-back"><span
                          class="icon icon--time-nav client-list-item-toggle"
                          [ngClass]="{clientListActiveItem: showAllyClients===true}"></span></a>
                    </div>
                    <ul [hidden]="!showAllyClients"
                      class="profile-card__details-list client-list-item-ul">
                      <li *ngFor="let partner of allAllyClients">{{partner}}
                      </li>
                    </ul>
                  </div>
                  <!---non-ally clients---->
                  <div *ngIf="allNonAllyClients.length > 0">
                    <div class="client-list-item" (click)="showNonAllyClients = !showNonAllyClients">
                      Non-Ally Clients ({{allNonAllyClients.length}})
                      <a href="javascript:void(0)" class="go-back"><span
                          class="icon icon--time-nav client-list-item-toggle doctor-profile-client-toggle"
                          [ngClass]="{clientListActiveItem: showNonAllyClients===true}"></span></a>
                    </div>
                    <ul [hidden]="!showNonAllyClients"
                      class="profile-card__details-list client-list-item-ul">
                      <li *ngFor="let partner of allNonAllyClients">{{partner}}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="contact-details__row">
            <div class="contact-details__label">Stage</div>
            <div class="contact-details__details">{{doctorProfileModel.stage}}</div>
          </div>
          <div class="hr hr--space-down-x hr--space-up-x"></div>
          <div class="contact-details__row">
            <div class="contact-details__label">Doctor Preferences</div>
            <div class="contact-details__details">{{doctorProfileModel.availability}}</div>
          </div>
          <div class="contact-details__row--single">
            <div class="contact-details__label">Notes</div>
            <div class="text--dark-grey">
              {{ doctorProfileModel.note }}
            </div>
          </div>
          <div class="hr hr--space-down-x hr--space-up-x"></div>
          <div class="contact-details__row">
            <div class="contact-details__label">Address</div>
            <div class="contact-details__details">{{doctorProfileModel.address}}
              <br /> {{doctorProfileModel.city}} {{doctorProfileModel.state}} {{doctorProfileModel.zip}}
              <br /> {{doctorProfileModel.country}}
            </div>
          </div>
          <div class="hr hr--space-down-x hr--space-up-x"></div>
          <div class="contact-details__row">
            <div class="contact-details__label">Referred By</div>
            <div class="contact-details__details">{{doctorProfileModel.referred_by}}</div>
          </div>

          <ng-template [ngIf]="doctorProfileModel.allyUserRef && showAllyRef">
            <div class="hr hr--space-down-x hr--space-up-x"></div>
            <div class="contact-details__row">
              <div class="contact-details__label">Ally User Reference</div>
              <div class="contact-details__details">{{doctorProfileModel.allyUserRef}}</div>
            </div>
          </ng-template>

          <!-- <div>
            <div class="hr hr--space-down-x hr--space-up-x"></div>
            <div class="profile-card__row" (click)="showCaseList = !showCaseList">
              <div class="contact-details__label">Assigned Cases ({{caseList?.activeCases.length || 0}})</div>
              <div class="contact-details__details" style="margin-right: 10px;">
                <a href="javascript:void(0)" class="go-back"><span class="icon icon--time-nav client-list-item-toggle"
                    [ngClass]="{clientListActiveItem: showCaseList===true}"></span></a>
              </div>
            </div>
            <div [hidden]="!showCaseList" class="profile-case-list">
              <ul class="profile-card__details-list" *ngFor="let case of caseList?.activeCases">
                <li *ngIf="case.is_associated == 0; else makeLink" title="This case is not accessible">
                  {{case.case_identifier}} </li>
                <ng-template #makeLink>
                  <li class="linked-case"><a [routerLink]="['/case', case.id]" target="_blank">
                      {{case.case_identifier}}</a></li>
                </ng-template>
              </ul>
            </div>
            <div *ngIf="users?.user_type?.type.toLowerCase() == 'admin' && doctorProfileModel?.is_deleted" class="hr hr--space-down-x hr--space-up-x"></div>
            <div *ngIf="users?.user_type?.type.toLowerCase() == 'admin' && doctorProfileModel?.is_deleted" class="contact-details__row--single">
              <div class="contact-details__label">Delete Reason</div>
              <div class="text--dark-grey">
                {{ doctorProfileModel.delete_reason }}
              </div>
            </div>
          </div> -->
    
          <div *ngIf="users.user_type.type.toLowerCase() !== 'mdmanager'" class="hr hr--space-down-x hr--space-up-x">
          </div>
          <div class="contact-details__btns">
            <a [swal]="confirmUserInactiveSwal" *ngIf="!doctorProfileModel.is_deleted && (users.user_type.type.toLowerCase() == 'admin' || users.user_type.type.toLowerCase() == 'cmmanager')" href="#"
              class="btn btn--red btn--small">Inactivate User</a>

         
              <swal #confirmUserInactiveSwal title="Inactivate User"    [inputValidator]="validReason()" backdrop="#eef3f6" type="warning"  input="textarea"  confirmButtonText="Continue"  inputPlaceholder="Enter Reason"  iconColor="orange" cancelButtonColor="#db161e" reverseButtons="true" showCancelButton="true" confirmButtonColor="green" confirmButtonText="Inactive User" icon="question" closeOnConfirm="false" closeOnCancel="false">			
                <div class="form__field" *swalPortal>
                    <swal-title>User to delete: {{ doctorProfileModel.full_name }}</swal-title>
                    <swal-title><hr></swal-title>
                    <swal-title class="mt-2 ml-0"><label>Please let us know why you are <span class="badge bg-secondary">inactivating</span> this user</label>
                    </swal-title>			              
                </div>
              </swal>
     
              <swal  #continueCaseSwal title="Confirmation"  (confirm)="deleteUserDoctor($event)"  closeOnConfirm="true" closeOnCancel="true"  backdrop="#eef3f6" cancelButtonColor="#db161e"  reverseButtons="true" showCancelButton="true" confirmButtonColor="green" confirmButtonText="Continue">
                <swal-text *swalPortal >Are you sure you want to proceed?</swal-text>
              </swal>
       
              
              <!-----Activate button  ----- -->
            <button [swal]="activateUserSwal" *ngIf="doctorProfileModel.is_deleted && (users.user_type.type.toLowerCase() == 'admin'|| users.user_type.type.toLowerCase() == 'cmmanager')" href="#" class="btn btn--green btn--small" > Activate User</button>
          			<!--#Shows user active users confirmation  message when user click on active button in sweet alert box   -->
             <swal #activateUserSwal  title="Do you want to active ' {{ doctorProfileModel.full_name}} ' in the system?" (confirm)="activeConfirmation.fire()" closeOnConfirm="true" closeOnCancel="true"  backdrop="#eef3f6" cancelButtonColor="#db161e"  reverseButtons="true" showCancelButton="true" confirmButtonColor="green" confirmButtonText="Continue"> </swal>
              
             <swal  #activeConfirmation title="Confirmation"  (confirm)="restoreModalDoctor( doctorProfileModel.doctor_id)"  closeOnConfirm="true" closeOnCancel="true"  backdrop="#eef3f6" cancelButtonColor="#db161e"  reverseButtons="true" showCancelButton="true" confirmButtonColor="green" confirmButtonText="Continue">
                <swal-text *swalPortal >Are you sure you want to proceed?</swal-text>
              </swal>
              
            <a [routerLink]="['../../../edit', operation, doctorProfileModel.doctor_id]"
              [queryParams]="{isSettings: isSettings}" [skipLocationChange]="true" class="btn btn--blue btn--small">Edit
              Profile</a>
            <!-- <a href="#" class="btn btn--red btn--small">Delete User</a> -->
            <!-- <a href="#" class="btn btn--blue btn--small">Edit Profile</a> -->
          </div>
        </div>
      </div>

      <app-assigned-cases [userId]="userId" [caseList]="caseList"></app-assigned-cases>

      <div class="box box--white box--radius box--margin-bottom-2x">
        <div class="calendar">
          <div class="calendar__title">
            <h2>Availability</h2>
            <a *ngIf="users.user_type.type.toLowerCase() !== 'mdmanager'" [routerLink]="['/calender', doctorId]"
              class="edit-calendar">
              <span class="icon icon--edit-calendar"></span>
            </a>

          </div>
          <div class="calendar__wrapper">
            <!-- <input [owlDateTimeTrigger]="selectedDate" [owlDateTime]="selectedDate"> -->
            <owl-date-time-inline #selectedDate [pickerType]="'calendar'" [owlDateTimeFilter]="disableCalender">
            </owl-date-time-inline>

          </div>
        </div>
      </div>
    </div>
  </div>

  <app-delete-user-template [name]="doctorProfileModel?.full_name" [hideTemplate]="hideDeleteUserBox" (toggleTemplate)="toggleDeleteTemplate($event)" (inActivateUser)="deleteDoctor($event)"></app-delete-user-template>
</div>

<app-dialog></app-dialog>
<div class="header">
	<div class="header__top-nav">
		<div class="top-menu">

			<div class="top-menu__user-stat">
				<span>Total Cases</span>{{activeCases}} Cases
			</div>

			<div class="top-menu__menu">
				<ul class="top-menu__wrapper" *ngIf="((tabs | async) == null || (tabs | async)?.toLowerCase() === 'current') && (role.toLowerCase()!=='interpreter' && role.toLowerCase()!=='translator' && role.toLowerCase()!=='patient')">
					<li class="status-list-trigger">
						<a href="javascript:void(0)">Status</a>
						<ul class="status-list">
							<li *ngFor="let eachStatus of statusList"><input type="checkbox" [value]="eachStatus.id" [checked]="eachStatus.checked" (change)="addRemoveStatusFilter(eachStatus, $event)"> {{eachStatus.status}}</li>
						</ul>
					</li>			

				</ul>
			</div>

			<div class="top-menu__search">
				<div class="search-wrapper">
					<div class="search-input-trigger sarch-box">
						<input id="caseSearch" type="text" [(ngModel)]="searchValue" #searchBox autocomplete="off"/>
						
						<ul class="search-column" *ngIf="searchValue !== ''">
							<li (click)="currentSearch(column.key,column.value,searchValue)" *ngFor="let column of columnOptions | keyvalue"> <strong><em>{{column.value}}</em></strong> : {{searchValue}} 
								<!-- <button ></button> -->
							</li>
						</ul>
								
					</div>	
				</div>
				<small *ngIf="searchColumnValue !== ''">  <span class="btn btn--small--circle  btn--remove--search" ><strong>{{searchColumnName}}</strong>:	{{searchColumnValue}}<span (click)="clearSearch()"><b>&nbsp; X</b></span> </span></small> 

				<div class="topActions" *ngIf="false">
					<a href="#" class="btn btn--blue btn--mini margin--l-x" (click)="onCreateCase(role.toLowerCase(), $event)">
						Create Case
					</a>
				</div>
			</div>

			
		</div>
	</div>
</div>

<div class="wrapper--dash-content dash-content overlay__wrapper" [class.overlay--visible] ="isInprogress">
	<div class="overlay__content">
		<div class="overlay__spinner">
			<span class="icon icon--loading"></span>
		</div>
	</div>

	<div class="tabs">
		<ul class="tabs__tab tabs__tab--dash-table">
			<li [class.tab-active]="(tabs | async) == null || (tabs | async)?.toLowerCase() === 'current'">
				<a href="#" (click)="changeTab('current', $event)">Current Cases</a>
			</li>
			<li [class.tab-active]="(tabs | async)?.toLowerCase() === 'previous'">
				<a href="#" (click)="changeTab('previous', $event)">Completed Cases</a>
			</li>
		</ul>
		<div class="tabs__content overlay__wrapper overlay--visible">
			<table datatable [dtOptions]="dtOptions1" id="casetable_active" [class.doctor-table]="role.toLowerCase()==='doctor'" #activeCasetable>
			</table>
		</div>
	</div>
</div>
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { PopUpService } from '../../services/pop-up.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit, OnDestroy {

  @ViewChild('confirmDialog', {static: true})
  confirmDialog: ElementRef;

  @ViewChild('alertDialog', {static: true})
  alertDialog: ElementRef;

  constructor(
    public popUpService: PopUpService
  ) { }

  ngOnInit() {
    // add confimDialog popUpView to popUpService
    if (this.popUpService.getPopUpView('confirmDialog') == null && this.confirmDialog != null) {this.popUpService.addPopUpView('confirmDialog', this.confirmDialog);}

    // add alertDialog popUpView to popUpService
    if (this.popUpService.getPopUpView('alertDialog') == null && this.alertDialog != null) {this.popUpService.addPopUpView('alertDialog', this.alertDialog);}
  }

  ngOnDestroy(): void {
    this.popUpService.removePopUpView();
  }

  public onPositiveClick(event: Event) {
    event.preventDefault();
    if (this.popUpService.onPositiveClick != null) {
      this.popUpService.setLoading();
      this.popUpService.onPositiveClick();
    }
  }

  public onNegativeClick(event: Event) {
    event.preventDefault();
    if (this.popUpService.onNegativeClick != null) {
      this.popUpService.onNegativeClick();
    }
  }

}

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit, ViewChild } from '@angular/core';


import { ConfigAssetLoaderService } from '../../../services/config-asset-loader.service';
import { LoginService } from '../../../services/login.service';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import * as moment from 'moment/moment';

declare let $: any;


@Component({
  selector: 'app-partners-list',
  templateUrl: './partners-list.component.html',
  styleUrls: ['./partners-list.component.css']
})
export class PartnersListComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: true})
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  token = 'Bearer ' + localStorage.getItem('token');
  valueToSearch: string;
  errorString: string;
  activePartner: number
  title: string;
  partner_status_value: string;
  PARTNER_STATUS = [{
    id: 'All',
    value: 'All'
  },
  {
    id: 'Active',
    value: 'Active'
  },{
    id: 'Inactive',
    value: 'Inactive'
  },
  {
    id: 'Deleted',
    value: 'Deleted'
  }

  ];
  filterPartner(filter: string){
    this.partner_status_value = filter;
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => {
        dtInstance.ajax.url(ConfigAssetLoaderService.getApiURL() + `/v1/partner/group?type=${this.partner_status_value}`);
        dtInstance.ajax.reload();
      }
    );
  }

  constructor(
    public router: Router,
    public loginService: LoginService,
  ) {
  }

  ngOnInit() {
    this.activePartner = 0;
    this.title = 'Clients';
    const currentUser = this.loginService.getCurrentUser();
    const role = currentUser.user_type.type.toLowerCase();
    if (role == 'admin' || role == 'careteam' || role == 'partner' || role == 'cmmanager' || role == 'casecoordinator') {
      this.partner_status_value = 'Active';
      this.dtOptions = {
        ajax: {
          url: ConfigAssetLoaderService.getApiURL() + `/v1/partner/group?type=${this.partner_status_value}`,
          dataSrc: function(json) {
            console.log('Data', json);
            this.activePartner = json.recordsTotal;
            return json.data;
          }.bind(this),
          type: 'POST',
          beforeSend: function (xhr) {
            this.errorString = null;
            const token = localStorage.getItem('token');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }.bind(this),
          error: this.datatablesError.bind(this)
        },
        pagingType: 'full_numbers',
        serverSide: true,
        processing: true,
        lengthChange: false,
        searching: true,
        pageLength: 10,
        order: [[0, 'ASC']],
        info: false,
        columns: [
          {
            title: 'Client Name',
            render: function (_data, _type, row, _meta): string {
              let partnerInfo = $.fn.dataTable.render.text().display(row.partner_name) + '<br>POC: ' + $.fn.dataTable.render.text().display(row.primary_poc_fullname);
              if (row.allyPartnerRef) {
                partnerInfo = $.fn.dataTable.render.text().display(row.partner_name) + ' <span class=\'badge-small badge-yellow\'>Ally</span>' + '<br>POC: ' + $.fn.dataTable.render.text().display(row.primary_poc_fullname);
              }
              return partnerInfo;
            }
          }, {
            title: 'Client Type',
            render: function (_data, _type, row, _meta) {
              return row.organization_type;
            }
          },
          {
            title: 'Cases',
            render: function (_data, _type, row, _meta) {
              return 'Active: ' + row.active_cases + '<br/>Total: ' + row.total_cases;
            }
          },
          {
            title: 'Activation',
            render: function (_data, _type, row, _meta) {
              const compareDate = moment();
              const startDate = moment(row.start_date, 'YYYY-MM-DD');
              const endDate = moment(row.end_date, 'YYYY-MM-DD');
              const active = compareDate.isBetween(startDate, endDate);
              if (active) {
                return 'Active<br/>' + row.start_date + ' - ' + row.end_date;
              }
              else {
                return 'Inactive<br/>' + row.start_date + ' - ' + row.end_date;
              }
            }
          }],
        columnDefs: [
          {
            targets: 2,
            orderable: false
          },
          {
            targets: 3,
            orderable: false
          }
        ],
        rowCallback: (row: Node, data: any[] | Object, _index: number) => {
          const self = this;

          // Unbind first in order to avoid duplicate handler
          $('td', row).unbind('click');
          $('td', row).bind('click', () => {
            self.viewPartnerInfo(data);
          });
        }
      };
    }
    else {
      this.router.navigate(['/']);
    }
  }

  datatablesError(xhr, _error, _thrown) {
    const errorStatus = xhr.status;
    if (errorStatus == 401) {
      this.router.navigate(['/login']);
    }
    else if (errorStatus == 404) {
      this.errorString = 'Invalid Url';
    }
    else {
      this.errorString = 'Problem occurred. Please contact your administrator';
    }
  }

  search() {
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => {
        dtInstance.search(this.valueToSearch).draw();
      }
    );
  }

  searchString(searchValue: string) {
    this.valueToSearch = searchValue;
    this.search();
  }

  viewPartnerInfo(info: any) {
    this.router.navigate(['/clients/dashboard', info.partner_id]);
  }

}

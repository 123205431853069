<div class="left-menu">
	<h2 class="left-menu__heading">Menu</h2>
	<ul class="left-menu__wrapper">
		<li [class.left-menu--is-active]="currentTab === 'dashboard'">
			<a [routerLink]="['/dashboard']"  (click)="activateLink('dashboard')"
			 *ngIf=" user.user_type.type.toLowerCase() == 'doctor' || user.user_type.type.toLowerCase() == 'translator' ">
				<span class="icon left-menu__icon" title="Dashboard" [class.icon--home-active]="currentTab === 'dashboard'"
				 [class.icon--home]="currentTab !== 'dashboard'"></span>
				<span class="left-menu__text">My Dashboard</span>
			</a>
			<a [routerLink]="['/case']" [queryParams]="{list: 'current'}" (click)="activateLink('home')"
			 *ngIf="(user.user_type.type.toLowerCase() == 'careteam' || user.user_type.type.toLowerCase() == 'interpreter')">
				<span class="icon left-menu__icon" title="Home" [class.icon--home-active]="currentTab === 'home'"
				 [class.icon--home]="currentTab !== 'home'"></span>
				<span class="left-menu__text">Home</span>
			</a>
			<a [routerLink]="['/clients','dashboard',user?.partners[0].partner_id]" *ngIf="user.user_type.type.toLowerCase() == 'partner' && user?.partners.length === 1" (click)="activateLink('partners')">
				<span class="icon left-menu__icon" title="Home" [class.icon--home-active]="currentTab === 'partners'"
				 [class.icon--home]="currentTab !== 'partners'"></span>
				<span class="left-menu__text">
					{{user?.partners[0]?.partner.partner_name}}
				</span>
			</a>

			<a href="#" (click)="gotoPatient($event)" *ngIf="user.user_type.type.toLowerCase() == 'patient'">
				<span class="icon left-menu__icon" title="Home" [class.icon--home-active]="currentTab === 'home'"
				 [class.icon--home]="currentTab !== 'home'"></span>
				<span class="left-menu__text">Home</span>
			</a>
		</li>
		
		<li [class.left-menu--is-active]="currentTab === 'partners'" *ngIf="user.user_type.type.toLowerCase() === 'partner' && user?.partners.length > 1">
			<a routerLink="/clients" (click)="activateLink('partners')" title="Clients">
				<span class="icon left-menu__icon" [class.icon--partner-active]="currentTab === 'partners'" [class.icon--partner]="currentTab !== 'partners'"></span>
				<span class="left-menu__text">Clients</span>
			</a>
		</li>

		<li [class.left-menu--is-active]="currentTab === 'case'">
			<a [routerLink]="['/case']" [queryParams]="{list: 'current'}" (click)="activateLink('case')" title="Cases">
				<span class="icon left-menu__icon" [class.icon--case-active]="currentTab === 'case'" [class.icon--case]="currentTab !== 'case'"></span>
				<span class="left-menu__text">{{title}}</span>
			</a>
		</li>

		<li *ngIf="['cmmanager','mdmanager','admin'].includes(user.user_type.type.toLowerCase())"[class.left-menu--is-active]="currentTab === 'case-assignments'" title="Case Assignments">
			<a  [routerLink]="['/case-assignments']" (click)="activateLink('case-assignments')">
				<span class="icon icon--case left-menu__icon" [class.icon--support-active]="currentTab === 'case-assignments'"
				 [class.icon--support]="currentTab !== 'case-assignments'"></span>
				<span class="left-menu__text">Case Assignments</span>
			</a>
		</li>

		<li [class.left-menu--is-active]="currentTab === 'partner'" *ngIf="user.user_type.type.toLowerCase() == 'partner'">
			<a routerLink="/client-role" (click)="activateLink('partner')" (click)="expandUser($event)" title="Client Careteam">
				<span class="icon left-menu__icon icon--partner-careteam" [class.icon--partner-careteam-active]="currentTab === 'partner'"></span>
				<span class="left-menu__text">Client Careteams</span>
			</a>

		</li>
		<li [class.left-menu--is-active]="currentTab === 'patient'" *ngIf="user.user_type.type.toLowerCase() == 'partner'">
			<a routerLink="/patient-role" (click)="activateLink('patient')" (click)="expandUser($event)" title="Members">
				<span class="icon left-menu__icon icon--patient" [class.icon--patient-active]="currentTab === 'patient'"></span>
				<span class="left-menu__text">Members</span>
			</a>
		</li>

		<li [class.left-menu--is-active]="currentTab === 'partners'" *ngIf="user.user_type.type.toLowerCase() !== 'interpreter' && user.user_type.type.toLowerCase() !== 'translator' && user.user_type.type.toLowerCase() !== 'doctor'  && user.user_type.type.toLowerCase() !== 'partner' && user.user_type.type.toLowerCase() !== 'patient' && user.user_type.type.toLowerCase() !== 'mdmanager'">
			<a routerLink="/clients" (click)="activateLink('partners')" title="Clients">
				<span class="icon left-menu__icon" [class.icon--partner-active]="currentTab === 'partners'" [class.icon--partner]="currentTab !== 'partners'"></span>
				<span class="left-menu__text">Clients</span>
			</a>
		</li>

		<li [class.left-menu--is-active]="Users" *ngIf="user.user_type.type.toLowerCase() !== 'interpreter' && user.user_type.type.toLowerCase() !== 'translator' && user.user_type.type.toLowerCase() !== 'doctor' && user.user_type.type.toLowerCase() !== 'partner' && user.user_type.type.toLowerCase() !== 'patient'"
		 class="has-sub-menu" [class.sub-menu--is-visible]="expandUsers" (click)="expandUser($event)">
			<a href="#" title="Users">
				<span class="icon icon--user left-menu__icon"></span>
				<span class="left-menu__text">Users</span>
			</a>
			<ul class="left-menu__sub-menu">
				<li>
					<a routerLink="/doctor-role" (click)="activateLink('doctor')" (click)="expandUser($event)" title="Doctor">
						<span class="icon left-menu__icon icon--doctor" [class.icon--doctor-active]="currentTab === 'doctor'"></span>
						<span class="left-menu__text">Doctor</span>
					</a>
				</li>
				<li *ngIf="user.user_type.type.toLowerCase() !== 'mdmanager'">
					<a routerLink="/imd-careteam-role" (click)="activateLink('imdCareTeam')" (click)="expandUser($event)" title="Alight Careteam">
						<span class="icon left-menu__icon icon--imd-careteam" [class.icon--imd-careteam-active]="currentTab === 'imdCareTeam'"></span>
						<span class="left-menu__text">Alight Careteam</span>
					</a>
				</li>
				<li *ngIf="user.user_type.type.toLowerCase() !== 'mdmanager'">
					<a routerLink="/case-coordinator-role" (click)="activateLink('casecoordinator')" (click)="expandUser($event)" title="Alight Case Coordinator">
						<span class="icon left-menu__icon icon--imd-careteam" [class.icon--imd-careteam-active]="currentTab === 'casecoordinator'"></span>
						<span class="left-menu__text">Alight Case Coordinator</span>
					</a>
				</li>
				<li *ngIf="user.user_type.type.toLowerCase() !== 'mdmanager'">
					<a routerLink="/client-role" (click)="activateLink('partner')" (click)="expandUser($event)" title="Alight Client Careteam">
						<span class="icon left-menu__icon icon--partner-careteam" [class.icon--partner-careteam-active]="currentTab === 'partner'"></span>
						<span class="left-menu__text">Alight Client Careteam</span>
					</a>
				</li>
				<li *ngIf="user.user_type.type.toLowerCase() !== 'mdmanager'">
					<a routerLink="/patient-role" (click)="activateLink('patient')" (click)="expandUser($event)" title="Alight Member">
						<span class="icon left-menu__icon icon--patient" [class.icon--patient-active]="currentTab === 'patient'"></span>
						<span class="left-menu__text">Alight Member</span>
					</a>
				</li>
				<li *ngIf="user.user_type.type.toLowerCase() !== 'mdmanager'">
					<a routerLink="/translator-role" (click)="activateLink('translators')" (click)="expandUser($event)" title="Summary Coordinator">
						<span class="icon left-menu__icon  icon--translator" [class.icon--translator-active]="currentTab === 'translators'"></span>
						<span class="left-menu__text">Summary Coordinator</span>
					</a>
				</li>
				<li *ngIf="user.user_type.type.toLowerCase() !== 'mdmanager' && user.user_type.type.toLowerCase() !== 'cmmanager'">
					<a routerLink="/interpreter-role" (click)="activateLink('interpreters')" (click)="expandUser($event)" title="Alight Interpreter">
						<span class="icon left-menu__icon  icon--translator" [class.icon--translator-active]="currentTab === 'interpreters'"></span>
						<span class="left-menu__text">Alight Interpreter</span>
					</a>
				</li>
				<li *ngIf="user.user_type.type.toLowerCase() === 'admin'">
					<a routerLink="/admin-role" (click)="activateLink('admin')" (click)="expandUser($event)" title="Alight Admin">
						<span class="icon left-menu__icon  icon--translator" [class.icon--translator-active]="currentTab === 'admin'"></span>
						<span class="left-menu__text">Alight Admin</span>
					</a>
				</li>
				<li *ngIf="user.user_type.type.toLowerCase() === 'admin'">
					<a routerLink="/eligible-member-list" (click)="activateLink('eligible-member')" (click)="expandUser($event)" title="Alight Eligible Member">
						<span class="icon left-menu__icon icon--patient" [class.icon--patient-active]="currentTab === 'eligible-member'"></span>
						<span class="left-menu__text">Alight Eligible Member</span>
					</a>
				</li>
				<li *ngIf="user.user_type.type.toLowerCase() === 'admin'">
					<a routerLink="/md-manager-role" (click)="activateLink('mdmanager')" (click)="expandUser($event)" title="Alight MD Manager">
						<span class="icon left-menu__icon icon--doctor" [class.icon--doctor-active]="currentTab === 'mdmanager'"></span>
						<span class="left-menu__text">Alight MD Manager</span>
					</a>
				</li>
				<li *ngIf="user.user_type.type.toLowerCase() === 'admin'">
					<a routerLink="/cm-manager-role" (click)="activateLink('cmmanager')" (click)="expandUser($event)" title="Alight CM Manager">
						<span class="icon left-menu__icon icon--doctor" [class.icon--doctor-active]="currentTab === 'cmmanager'"></span>
						<span class="left-menu__text">Alight CM Manager</span>
					</a>
				</li>
			</ul>
		</li>

		<li [class.left-menu--is-active]="currentTab === 'availability'" *ngIf="showDoctorAvailiablity">
			<a routerLink="/calender" (click)="activateLink('availability')" title="Calendar">
				<span class="icon left-menu__icon" [class.icon--availability-active]="currentTab === 'availability'"
				 [class.icon--availability]="currentTab !== 'availability'"></span>
				<span class="left-menu__text">Calendar</span>
			</a>
		</li>

		<li *ngIf="user.user_type.type.toLowerCase() === 'admin'" [class.left-menu--is-active]="currentTab === 'settings'" title="Settings">
			<a routerLink="/settings" (click)="activateLink('settings')">
				<span class="icon icon--settings left-menu__icon" [class.icon--settings-active]="currentTab === 'Settings'"
				 [class.icon--setting]="currentTab !== 'Settings'"></span>
				<span class="left-menu__text">Settings</span>
			</a>
		</li>

		<li *ngIf="['admin'].includes(user?.user_type?.type.toLowerCase())" [class.left-menu--is-active]="currentTab === 'new-settings'" title="New Settings">
			<a routerLink="/new-settings" (click)="activateLink('new-settings')">
				<span class="icon icon--settings left-menu__icon" [class.icon--settings-active]="currentTab === 'New Settings'"
				 [class.icon--setting]="currentTab !== 'New Settings'"></span>
				<span class="left-menu__text">Technical</span>
			</a>
		</li>

		<li *ngIf="['cmmanager'].includes(user?.user_type?.type.toLowerCase())" [class.left-menu--is-active]="currentTab === 'mail-logs'" title="All Email Logs">
			<a routerLink="/mail-logs" (click)="activateLink('mail-logs')">
				<span class="icon icon--settings left-menu__icon" [class.icon--settings-active]="currentTab === 'mail-logs'"
				 [class.icon--setting]="currentTab !== 'New Settings'"></span>
				<span class="left-menu__text">Email Log(s)</span>
			</a>
		</li>

		<li *ngIf="user.user_type.type.toLowerCase() === 'admin'">
			<a routerLink="/system-accounts" (click)="activateLink('system-accounts')" title="Ally Admin">
				<span class="icon left-menu__icon  icon--translator" [class.icon--translator-active]="currentTab === 'system-accounts'"></span>
				<span class="left-menu__text">System Accounts</span>
			</a>
		</li>

		<li *ngIf="!['doctor','translator'].includes(user.user_type.type.toLowerCase())"[class.left-menu--is-active]="currentTab === 'support'" title="Support">
			<a (click)="activateLink('support')" [target]="'_blank'" [href]="'https://infinitemd.com/contact/'">
				<span class="icon icon--support left-menu__icon" [class.icon--support-active]="currentTab === 'support'"
				 [class.icon--support]="currentTab !== 'support'"></span>
				<span class="left-menu__text">Support</span>
			</a>
		</li>

		<li>
			<a href="#" (click)="logout($event)">
				<span class="icon icon--logout left-menu__icon"></span>
				<span class="left-menu__text">Logout</span>
			</a>
		</li>
	</ul>
</div>
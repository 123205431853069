import { ToastrService } from 'ngx-toastr';
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-undef */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-empty-function */

import { LoginService } from '../../../services/login.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../services/userservice.service';
import {  USER_ROLES_CHANGE_LIST } from '../../../constant';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.css']
})
export class UserRoleComponent implements OnInit {
  @ViewChild('editRoleConfirmation') private editRoleConfirmation: SwalComponent;
  @ViewChild('changeButtonSwal') private changeButtonSwal: SwalComponent;
  @Input() userRoleList;
  @Input() userToEdit;
  @Input() CasesCount;
  UserRoleLength : number;
  currentRoleForm: FormGroup;
  currentRoleName : string;
  showUserRoles = false;
  currentUser: any;
  newRoleName: string;
  roleLength  = 0;
  roleselectedvalue : string;
  casesAssignedCount  = 0;
  caseIdList: any;

  get newUserRole(){
    return this.currentRoleForm.controls['new_user_role'];
  }
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  constructor(private formGroup : FormBuilder,private loginService: LoginService,
	private userService: UserService,private toastr:ToastrService) { 
    this.currentRoleForm = this.formGroup.group({
      new_user_role: ['',Validators.required]
    });
  }

  ngOnInit() {
    this.currentUser = this.loginService.getCurrentUser();
    this.UserRoleLength = this.userRoleList.length;
    this.newUserRole.setValue(this.userRoleList[0].User_Type_Id);
    this.currentRoleName = USER_ROLES_CHANGE_LIST.filter(userRole => userRole.User_Type_Id == this.userToEdit.user_type_id)[0].User_Role;
  }

  getRoleName(){
    this.casesAssignedCount = this.CasesCount;
    this.newRoleName = this.userRoleList.filter(userRole => userRole.User_Type_Id == this.newUserRole.value)[0].User_Role;
    this.changeButtonSwal.fire();
  }

  // A method to change the role of the user
  changeUserRole(){
    const body = {
      toUserId: this.userToEdit.user_id,
      newRoleId: this.newUserRole.value
    };

    // api to change the user role from case assignment
    this.userService.changeUserRole(body).subscribe(
      (result) => {
        if(result.status == 200 && result.error == false){
          this.userRoleList = USER_ROLES_CHANGE_LIST.filter(user => user.User_Type_Id != this.newUserRole.value);
          this.newUserRole.setValue(this.userRoleList[0].User_Type_Id);
          // shows the confirmation in sweet alert message and reloads
          this.editRoleConfirmation.fire().then(()=>{
            window.location.reload();
          });
        }
        else{
          this.toastr.error(result.message);
        }
      }
      ,(error)=>{
        this.toastr.error(error['error'].message);
      });
  }

}
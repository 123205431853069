<div class="box box--white box--no-pad box--radius margin--bottom-2x">
	<div class="box__title case-section-box">
		<div class="box-title-left">
			<div class="left-title">MD Management</div>
			<div class="left-status">
				<span class="icon icon--calendar"></span>Assigned {{careteam?.created_at | amCalendar}}
			</div>
		</div>
		<div class="box-title-right">
			<div class="flexDisplay">
				<div class="mr20">
					<div class="right-name">{{careteam?.user?.full_name}}</div>
					<div class="right-status margin--top-x">Assignee</div>
				</div>
				<div>
					<a href="javascript:void(0)" class="go-back pull-right collapseToggle top10"
						[ngClass]="{collapseToggleActive: isMDMngmtCollapse===true}"
						(click)="isMDMngmtCollapse = !isMDMngmtCollapse">
						<span class="icon icon--time-nav"></span>
					</a>
				</div>
			</div>
		</div>
	</div>

	<div [hidden]="!isMDMngmtCollapse">
		<form [formGroup]="mdMgmtForm">
			<div class="padding-2x box--md-mgmt form">
				<div class="form__row">
					<div class="col col__md-2">
						<div class="form__label is-uppercase">DESCRIPTION</div>
					</div>
					<div class="col col__md-10" *ngIf="isAutoTriage; else nonTriageTemplate">
						<div class="form__field" *ngIf="doctor_time_selected== false && caseDetail?.reason != null">Auto
							Triage was successful but the patient was not satisfied. Please reach out to the Client to
							assign the appropriate
							doctor for consultation.</div>
						<div class="form__field" *ngIf="doctor_time_selected== false && caseDetail?.reason == null">
							Auto triage was successful. No times were provided. Reach out to Doctor for availability
						</div>
						<div class="form__field" *ngIf="doctor_time_selected== true">Auto Triage was successful and
							patient
							has chosen a Doctor and a Time
						</div>
					</div>
					<ng-template #nonTriageTemplate>
						<div class="col col__md-10">
							<div class="form__field">Auto Triage was not successful. Please reach out to the Client to
								assign
								the appropriate doctor for consultation.
							</div>
						</div>
					</ng-template>
				</div>
				<div class="form__row" *ngIf="!isAutoTriage">
					<div class="col col__md-2">
						<div class="form__label is-uppercase">SUGGESTED MDS</div>
					</div>
					<div class="col col__md-10">
						<a href="javascript:void(0)" class="suggested-md-list"
							*ngFor="let doctor of doctorsList">{{doctor.doctor_info.user.full_name}}</a>
					</div>
				</div>
				<div class="form__row" *ngIf="schedules.length > 0">
					<div class="col col__md-2">
						<div class="form__label is-uppercase">Schedule Time</div>
					</div>
					<div class="col col__md-10">
						<ul class="scheduled-time">
							<li *ngFor="let schedule of schedules">
								<span class="start-time">{{schedule.start_date_time | date:'MM/dd/yyyy hh:mm a'}}</span>
								<span class="end-time">{{schedule.end_date_time | date:'MM/dd/yyyy hh:mm a'}}</span>
							</li>
						</ul>
					</div>
				</div>
				<div class="form__row" *ngIf="!isAutoTriage">
					<div class="col col__md-2">
						<div class="form__label is-uppercase">REASON</div>
					</div>
					<div class="col col__md-10">
						<div class="form__field">{{caseDetail?.reason?.reason}}</div>
					</div>
				</div>
				<div class="form__row" *ngIf="!isAutoTriage">
					<div class="col col__md-2">
						<div class="form__label is-uppercase">NOTES</div>
					</div>
					<div class="col col__md-10">
						<div class="form__field">{{caseDetail?.reason_additional_note}}</div>
					</div>
				</div>
				<div class="form__row" *ngIf="caseDetail?.allyCaseRef || caseDetail?.allyCaseNumber">
					<div class="col col__md-2">
						<div class="form__label is-uppercase">MD ALLY Recommendation</div>
					</div>
					<div class="col col__md-10">
						<div class="form__field">{{caseDetail?.md_recommendation_field}}</div>
					</div>
				</div>
				<div class="form__row">
					<div class="col col__md-2">
						<div class="form__label is-uppercase">{{ (caseDetail?.case_type === 'MDT WRITTEN' ||
							caseDetail?.case_type === 'MDT VIDEO') ? "Assigned MDs" : "Assigned MD"}}</div>
					</div>
					<div class="col col__md-10">
						<div class="form__field">
							<input auto-complete [source]="md_list" type="text"
								[list-formatter]="suggestionListFormatter" [attr.disabled]="disableInputs || role === 'admin' ? true : null"
								[value-formatter]="valueListFormatter.bind(this)" [re-focus-after-select]="false"
								#assignedMD />
							<div *ngIf="assigned_md.invalid && !validAssignedMD" class="message message--is-invalid">
								<div *ngIf="assigned_md.errors.required">This field is required.</div>
								<div *ngIf="assigned_md.errors.maxlength">Cannot assign MD more than
									{{assigned_md.errors.maxlength.requiredLength}}</div>
							</div>
							<div class="search-list">
								<div class="search-list" formArrayName="assigned_md">
									<div class="search-item" *ngFor="let md of assigned_md.controls" [ngClass]="isActive(md)" title="{{ isActive(md) !== '' ? 'Doctor is inactive' : ''}}">
										{{md.value.doctor_name}}
										<span *ngIf="!disableInputs" (click)="clearPartner(md)">X</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form__row"
					*ngIf="(caseDetail?.case_type === 'MDT WRITTEN' || caseDetail?.case_type === 'MDT VIDEO') && selectionList.length > 1">
					<div class="col col__md-2">
						<div class="form__label is-uppercase">Champion MD</div>
					</div>
					<div class="col col__md-10">
						<div class="form__field">
							<select [attr.disabled]="disableInputs ? '' : null" formControlName="primary_md"
								(change)="changePrimaryMD($event.target.value)">
								<option *ngFor="let list of selectionList" [value]="list.assigned_doc_user_id">
									{{list.doctor_name}}
								</option>
							</select>
							<div *ngIf="primary_md.touched && primary_md.invalid" class="message message--is-invalid">
								<div *ngIf="primary_md.errors.required">This field is required.</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form__row">
					<div class="col col__md-2">
						<div class="form__label is-uppercase">Findings</div>
					</div>
					<div class="col col__md-10">
						<div class="form__field">
							<!-- <ckeditor formControlName="findings" [readonly]="true" #ckeditorField>
							</ckeditor> -->
							<ckeditor formControlName="findings" [readonly]="true" #ckeditorField 
							[config]="{ toolbar: [
								{ name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', 'NumberedList', 'BulletedList','Indent','Outdent'] }
								]}"></ckeditor>
							<div *ngIf="mdMgmtForm.get('findings').touched 
							&& mdMgmtForm.get('findings').invalid" class="message message--is-invalid">
								<div *ngIf="mdMgmtForm.get('findings').errors.required">This field is required.</div>
								<div *ngIf="mdMgmtForm.get('findings').errors.maxlength">This field must not contain
									characters more than {{mdMgmtForm.get('findings').errors.maxlength.requiredLength}}.
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form__row" *ngIf="isReAssign">
					<div class="col col__md-2">
						<div class="form__label is-uppercase">Email Template</div>
					</div>
					<div class="col col__md-10">
						<div class="form__field">
							<ckeditor class="customheader" formControlName="email_template" [readonly]="true" #ckeditorField
							[config]="{toolbar: []}"></ckeditor>			
							<!-- <textarea type="text" formControlName="email_template" [readonly]="true" #ckeditorField></textarea> -->
							<div *ngIf="mdMgmtForm.get('email_template').touched 
							&& mdMgmtForm.get('email_template').invalid" class="message message--is-invalid">
								<div *ngIf="mdMgmtForm.get('email_template').errors.required">This field is required.
								</div>
								<div *ngIf="mdMgmtForm.get('email_template').errors.maxlength">This field must not
									contain
									characters more than
									{{mdMgmtForm.get('email_template').errors.maxlength.requiredLength}}.
								</div>
							</div>
						</div>
					</div>
				</div>

				<!---Followup Question email--->
				<div class="form__row" *ngIf="followUpNumber > 0">
					<div class="col col__md-2">
						<div class="form__label is-uppercase">Follow Up Email Template</div>
					</div>
					<div class="col col__md-10">
						<div class="form__field">
							<ckeditor class="customheader" formControlName="follow_up_email_template" [readonly]="true" #ckeditorField
							[config]="{toolbar: []}"></ckeditor>
							<div *ngIf="mdMgmtForm.get('follow_up_email_template').touched 
							&& mdMgmtForm.get('follow_up_email_template').invalid" class="message message--is-invalid">
								<div *ngIf="mdMgmtForm.get('follow_up_email_template').errors.required">This field is
									required.</div>
								<div *ngIf="mdMgmtForm.get('follow_up_email_template').errors.maxlength">This field must
									not contain
									characters more than
									{{mdMgmtForm.get('follow_up_email_template').errors.maxlength.requiredLength}}.
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--Followup Question email end--->

				<div class="btn-wrapper" >
					<div class="btns__row text-right" *ngIf="(!disableInputs && role !== 'admin') && !(isStagePaused(2, [ 'FINISHED' ]) && role === 'careteam' ) ">
						<app-generic-button href="#" [class]="'is-loading'"
							*ngIf="caseDetail?.case_md_management != null && followUpNumber > 0 && !isEmailSending"
							(onClick)="sendFollowUpEmailToMD()">
							{{sendFollowUpEmailLabel}}
						</app-generic-button>

						<app-generic-button href="#" [class]="'is-loading'"
							*ngIf="(caseDetail?.case_md_management != null && !isEmailSending )"
							(onClick)="sendEmailToMD()">
							{{sendEmailLabel}}
						</app-generic-button>

						<div class="btn btn--blue is-loading" *ngIf="isEmailSending">
							<span class="icon icon--loading "></span>
						</div>

						<app-generic-button *ngIf="!isEmailSending" [disabled]="caseDetail.case_status === 'FINISHED'" (onClick)="onSubmit(mdMgmtForm)"
							#submit>{{isReAssign ? 'Reassign' : 'Finish'}}</app-generic-button>
					</div>
	
					<div class="btns__row text-right" *ngIf="role === 'admin' && !isStagePaused(2)">
						<app-generic-button [disabled]="caseDetail.case_status === 'FINISHED'" (onClick)="onCaseUpdate()"
							#submit>Finish</app-generic-button>
					</div>
				</div>

			</div>
		</form>
	</div>
</div>
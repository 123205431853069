/* eslint-disable @typescript-eslint/explicit-member-accessibility */


export class PartnersModel {
    partner_id: number;
    partner_organization_id: number;
    plan_type_id: number;
    primary_poc_fullname: string;
    primary_poc_title: string;
    primary_poc_number: string;
    primary_poc_email: string;
    secondary_poc_fullname: string;
    secondary_poc_title: string;
    secondary_poc_number: string;
    secondary_poc_email: string;
    logo_location: string;
    partner_logo: string;
    phrase_text: string;

    partner_name: string;
    isElevatedPartner: boolean;
    start_date: string;
    end_date: string;
    created_by: number;
    created_date: string;
    display_specialist: boolean;
    scan_link: string;
    release_form_link: string;
    notification_email: string

    serviceA: boolean;
    serviceB: boolean;
    serviceC: boolean;
    serviceD: boolean;
    serviceE: boolean;

    first_name: boolean;
    last_name: boolean;
    dob: boolean;
    gender: boolean;
    enableEmailDomain: boolean;
    email_domain: string;
    company_name: boolean;
    enableCode: boolean;
    code_value: string;
    code_start_date: string;
    code_expiry_date: string;

    allyPartnerRef: string;

    constructor() {
      this.partner_id = 0;
      this.partner_organization_id = 0;
      this.plan_type_id = 0;
      this.primary_poc_fullname='';
      this.primary_poc_title = '';
      this.primary_poc_number = '';
      this.primary_poc_email = '';
      this.secondary_poc_fullname = '';
      this.secondary_poc_title = '';
      this.secondary_poc_number = '';
      this.secondary_poc_email = '';
      this.partner_name = '';
      this.start_date = '';
      this.end_date = '';
      this.display_specialist = false;
      this.scan_link = '';
      this.partner_logo = '';
      this.release_form_link = '';
      this.notification_email = '';
      this.serviceA = false;
      this.serviceB = false;
      this.serviceC = false;
      this.serviceD = false;
      this.serviceD = false;
      this.serviceE = false;
      this.first_name = false;
      this.last_name = false;
      this.dob = false;
      this.gender = false;
      this.enableCode = false;
      this.email_domain = null;
      this.enableCode = false;
      this.code_value = null;
      this.code_start_date = null;
      this.code_expiry_date = null;
      this.company_name = false;
      this.allyPartnerRef = null;
    }

}

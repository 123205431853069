/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigAssetLoaderService } from '../../../services/config-asset-loader.service';
import { LoginService } from '../../../services/login.service';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { CommonFuntions } from '../../../common/common.function';

@Component({
  selector: 'app-admin-role',
  templateUrl: './admin-role.component.html',
  styleUrls: ['./admin-role.component.css']
})
export class AdminRoleComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: true})
  datatableElement: DataTableDirective;
  dtOptions = {};
  token = 'Bearer ' + localStorage.getItem('token');
  valueToSearch: string;
  errorString: string;

  title: string;
  searchValue = ''
  activeUsers: number
  inactiveUsers: number

  constructor(
    public router: Router,
    private loginService: LoginService
  ) {
  }

  ngOnInit() {
    this.title = 'Doctor';

    const user = this.loginService.getCurrentUser();

    this.dtOptions = {
      ajax: {
        url: ConfigAssetLoaderService.getApiURL() + '/user/role/1?type=' + user.user_type.type + '&userid=' + user.user_id,
        dataSrc: function(json) {
          this.userCount = json.recordsTotal;
          this.activeUsers = json.activeCount || 0;
          this.inactiveUsers = json.inActiveCount || 0;
          return json.data;
        }.bind(this),
        type: 'POST',
        beforeSend: function (xhr) {
          if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
            xhr.abort();
            return;
          }

          this.errorString = null;
          const token = localStorage.getItem('token');
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }.bind(this),
        error: this.datatablesError.bind(this)
      },
      pagingType: 'full_numbers',
      serverSide: true,
      processing: true,
      lengthChange: false,
      searching: true,
      pageLength: 25,
      order: [[0, 'ASC']],
      info: false,
      stateSave: true,
      stateDuration: 5 * 20,
      rowCallback: (_row: Node, _data: any[] | Object, _index: number) => {
        // Unbind first in order to avoid duplicate handler
        // $('td', row).unbind('click');
        // $('td', row).bind('click', function () {
        // 	this.viewUserInfo(data);
        // }.bind(this));
      },
      columns: [
        {
          title: 'Name',
          data: 'full_name',
          render: $.fn.dataTable.render.text()
        },
        {
          title: 'Email',
          data: 'email',
          render: $.fn.dataTable.render.text()
        },
        {
          title: 'Status',
          render: function (_data, _type, row, _meta) {
            if (row.is_deleted) {
              return 'Inactive';
            }
            else {
              return 'Active';
            }
          },
          orderable: false
        }
      ]
    };

    // this.setSearchValueFromSavedState()


  }

  viewUserInfo(info: any) {
    // if(this.user)
    this.router.navigate(['/profile', info.user_id]);
  }

  datatablesError(xhr, _error, _thrown) {
    const errorStatus = xhr.status;
    if (errorStatus == 401) {
      this.router.navigate(['/login']);
    }
    else if (errorStatus == 404) {
      this.errorString = 'Invalid Url';
    }
    else {
      this.errorString = 'Problem occurred. Please contact your administrator';
    }
  }


}

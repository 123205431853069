<div class="header">
  <div class="header__page-name">
    <h1 class="heading heading--main">Users - {{title}}</h1>
  </div>
  <div class="header__top-nav">
    <div class="top-menu">
      <div class="top-menu__user-stat">
        <span>Total Active Users</span>{{userCount}}
      </div>
      <div class="top-menu__menu">
      </div>

      <div class="top-menu__search">
        <div class="search-wrapper notAfter">
          <div class="sarch-box">
            <input type="text" [(ngModel)]="searchValue" (keyup)="search()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="wrapper--dash-content dash-content">
  <div *ngIf="errorString" class="message message--is-invalid">
    {{errorString}}
  </div>

  <table datatable [dtOptions]="dtOptions" class="row-border hover">
  </table>
</div>
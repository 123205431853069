/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { ConsultationAnswer } from '../consultation/consultation-answer';

export class Medication {
	case_medication_id: number;
	medicatoin: string;
	medication_by_user_id: number;
}

export class CareteamReport {
	case_medications: Medication[];
	comment_summary: string;
	written_summary: string;
	answer: ConsultationAnswer[];
	case_id: number;
    peer_to_peer_details: any;
}

<div class="box box--white box--no-pad box--radius margin--bottom-2x">
	<div class="box__title case-section-box">
		<div class="box-title-left">
			<div class="left-title">Report</div>
			<div class="left-status">
				<span class="icon icon--calendar"></span>Assigned 3 hours ago
			</div>
		</div>
		<div class="box-title-right">
			<div class="flexDisplay">
				<div>
					<div class="right-name">{{report?.user?.full_name}}</div>
					<div class="right-status margin--top-x">Assignee</div>
				</div>
				<div>
					<a href="javascript:void(0)" class="go-back pull-right collapseToggle ml20"
						[ngClass]="{collapseToggleActive: isReportCollapse===true}"
						(click)="isReportCollapse = !isReportCollapse">
						<span class="icon icon--time-nav"></span>
					</a>
				</div>
			</div>
		</div>
	</div>
	<div [hidden]="!isReportCollapse" class="padding-2x box--md-mgmt form">
		<div class="form__row">
			<div class="col col__md-2">
				<div class="form__label is-uppercase">Description</div>
			</div>
			<div class="col col__md-10">
				<div class="form__field">
					<div class="text">Verify that the Final Report is ready for submission and the case can be closed.
					</div>
				</div>
			</div>
		</div>

		<div class="form__row">
			<div class="col col__md-2">
				<div class="form__label is-uppercase">Final Report</div>
			</div>
			<div class="col col__md-10">
				<div class="form__field">
					<div class="form__field">
						<div class="form__field form__field--icon-download"
							*ngFor="let finalReport of caseDetail?.final_reports">
							<div *ngIf="finalReport?.upload_stage === 'CONSULTATION_SUMMARY' && finalReport?.document_name != caseDetail?.case_identifier+'_Consultation_Summary_Test.pdf'">
								<input type="text" [value]="finalReport.document_name" disabled="disabled" />
								<span (click)="downloadFile(finalReport.id , finalReport.document_name, $event)">
									<a href="#" (click)="$event.preventDefault()" class="icon icon--download"></a>
								</span>
								<div class="text text--small text--red">Submitted {{finalReport.created_at |
									amDateFormat: 'MM/DD/YYYY'}} at {{finalReport.created_at | amDateFormat: 'hh:mmA '}}</div>
							</div>
						</div>
					</div>
					<div class="form__field checkbox--in-row">
						<div class="form__field--checkbox">
							<input type="checkbox" [checked]="checkFinalReport" (click)="changeCheckBoxBoolean()"
								[attr.disabled]="disableInputs?'':null" />
							<!-- <input type="checkbox" [checked]="checkFinalReport" onclick="return false;" /> -->
							<label for="option">Checked Final Report</label>
							<div *ngIf="showMessage" class="message message--is-invalid">
								Please check this field to finish this report.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="form__row">
			<div class="col col__md-2">
				<div class="form__label is-uppercase">Notes</div>
			</div>
			<div class="col col__md-10">
				<div class="form__field report-stage">
					<textarea [value]="report_notes" [attr.disabled]="disableInputs?'':null"
						(keyup)="checkLength(reportNotes)" #reportNotes></textarea>
					<div class="message message--is-invalid" *ngIf="!reportNotesLengthValid">
						This field must not contain character more than 50000 characters
					</div>
				</div>
				<div class="col col__md-12 mt-4"
					*ngIf="caseDetail.speciality_program === 'MSO' && (caseDetail.allyCaseNumber || caseDetail.allyCaseRef)">
					<div class="form__label is-uppercase">REO Recommendations:</div>
				</div>
			</div>
		</div>
		<form [formGroup]="recommendationForm"
			*ngIf="caseDetail.speciality_program === 'MSO' && (caseDetail.allyCaseNumber || caseDetail.allyCaseRef)">
			<div class="form__row">
				<div class="col col__md-2">
					<div class="form__label is-uppercase">Surgical Intervention</div>
				</div>
				<div class="col col__md-10">
					<div class="form__field">
						<div class="form__row">
							<div class="form__field">
								<select formControlName="surgical_intervention" [attr.disabled]="disableInputs?'':null"
									(change)="onInterventionChange(surgical_intervention.value)" #surgicalIntervention>
									<option *ngFor="let intervention of interventionList"
										[attr.disabled]="disableInputs?'':null"
										value={{intervention.intervention_name}}>{{intervention.intervention_name}}
									</option>
								</select>
								<div *ngIf="surgical_intervention.invalid && validateRecommendation"
									class="message message--is-invalid">
									<div *ngIf="surgical_intervention.errors.required">This field is required.</div>
								</div>
								<div class="form__field report-stage mt-2"
									*ngIf="surgical_intervention.value === 'N/A'">
									<textarea formControlName="surgical_intervention_note"
										[attr.disabled]="disableInputs?'':null"
										(keyup)="checkLength(reportNotes)"></textarea>
									<div class="message message--is-invalid" *ngIf="!reportNotesLengthValid">
										This field must not contain character more than 50000 characters
									</div>
									<div *ngIf="surgical_intervention_note.invalid && (!surgical_intervention_note.dirty || surgical_intervention_note.touched)"
										class="message message--is-invalid">
										<div *ngIf="surgical_intervention_note.errors.required">This field is required.
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="form__row">
				<div class="col col__md-2">
					<div class="form__label is-uppercase reo-label">Recommendations Prior to Surgical Procedure</div>
				</div>
				<div class="col col__md-10">
					<div class="mdDegree form__field is-sarch-input">
						<input type="text" [attr.disabled]="disableInputs?'':null" auto-complete
							[source]="recommendationList" [list-formatter]="suggestionListFormatter"
							[value-formatter]="valueListFormatter.bind(this)" [re-focus-after-select]="false"
							#recommendationField />
						<div *ngIf="surgical_recommendation.invalid && validateRecommendation"
							class="message message--is-invalid">
							<div *ngIf="surgical_recommendation.errors.required">This field is required.</div>
						</div>
						<div class="search-list auto-height" formArrayName="surgical_recommendation"
							*ngIf="surgical_recommendation.length !== 0">
							<div class="search-item" *ngFor="let recommendation of surgical_recommendation?.controls">
								{{recommendation.value.recommendation_name}}
								<span (click)="	removeRecommendation(recommendation)">X</span>
							</div>
						</div>
						<div class="form__field report-stage mt-2" *ngIf="recommendationNotes">
							<textarea formControlName="surgical_recommendation_note"
								[attr.disabled]="disableInputs?'':null" (keyup)="checkLength(reportNotes)"></textarea>
							<div class="message message--is-invalid" *ngIf="!reportNotesLengthValid">
								This field must not contain character more than 50000 characters
							</div>
							<div *ngIf="surgical_recommendation_note.invalid && (!surgical_recommendation_note.dirty || surgical_recommendation_note.touched)"
								class="message message--is-invalid">
								<div *ngIf="surgical_recommendation_note.errors.required">This field is required.</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
		<div class="btns__row text-right" *ngIf="!disableInputs">
			<app-generic-button [isBlue]="true" *ngIf="!isLoading"
				(onClick)="saveNotes(caseDetail.id, reportNotes.value)">Finish</app-generic-button>
			<div class="btn btn--blue is-loading" *ngIf="isLoading">
				<span class="icon icon--loading "></span>
			</div>
		</div>

	</div>
</div>
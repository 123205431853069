<app-dialog></app-dialog>

<div class="header">
  <div class="header__page-name">
    <h1 class="heading heading--main">Archive Settings</h1>
  </div>
</div>

<div class="wrapper--dash-content dash-content">
  <div class="tabs__content">
    <form [formGroup]="formGrp">
        <div class="row">
          <div class="col col__md-12">
            <div class="box box--white overlay__wrapper box--radius box--margin-bottom-2x">
              <div class="overlay__content">
                <div class="overlay__spinner">
                  <span class="icon icon--loading"></span>
                </div>
              </div>

                <h3 class="settingTitle">1. Archive Time for Webhook logs</h3>
                <div class="contact-details__row">
                  <div class="contact-details__label">Webhook Logs</div>
                  <!-- <div *ngIf="showSaveCancel" >Archive Webhook logs prior <input id="webhookInput" type="text" /> days. </div> -->
                  
                  <div class="form__field" *ngIf="showSaveCancelWebhook">
                    <p *ngIf="showSaveCancelWebhook">Enter Number of days (<i>Webhook Logs older than entered number of days will be moved to archive</i>)</p>
                    <input type="number" name="title" id="title" formControlName="days">
                    <div *ngIf="formGrp.get('days').touched
                            && formGrp.get('days').invalid" class="message message--is-invalid">
                        <div *ngIf="formGrp.get('days').errors.required">Days cannot be empty.</div>
                        <div *ngIf="formGrp.get('days').errors.min">Minimum value 1 day.</div>
                        <div *ngIf="formGrp.get('days').errors.max">Maximum Value 120 days .</div>
                        <div *ngIf="formGrp.get('days').errors.pattern">Enter Valid Days.</div>
                    </div>
                </div>

                <div class="contact-details__details">
                    <div class = "btn btn--blue" (click)="EditClicked($event, 'WEBHOOK')" *ngIf="!hideEditButtonWebhook">Edit</div>
                    <button class = "btn btn--green"  *ngIf="showSaveCancelWebhook"  [disabled]="formGrp.get('days').errors" (click)="confirmEdit($event,formGrp.get('days').value, 'WEBHOOK' )">Save</button>
                    <button class = "btn btn--red" (click)="CancelEdit($event, 'WEBHOOK')"  *ngIf="showSaveCancelWebhook">Cancel</button>
              </div>
              </div>

              <h3 class="settingTitle">2. Archive Time for Email logs</h3>
              <div class="contact-details__row">
                <div class="contact-details__label">Email Logs</div>
                <!-- <div *ngIf="showSaveCancel" >Archive Webhook logs prior <input id="webhookInput" type="text" /> days. </div> -->
                
                <div class="form__field" *ngIf="showSaveCancelEmail">
                  <p *ngIf="showSaveCancelEmail">Enter Number of days (<i>Email Logs older than entered number of days will be moved to archive</i>)</p>
                  <input type="number" name="title" id="title" formControlName="days">
                  <div *ngIf="formGrp.get('days').touched
                          && formGrp.get('days').invalid" class="message message--is-invalid">
                      <div *ngIf="formGrp.get('days').errors.required">Days cannot be empty.</div>
                      <div *ngIf="formGrp.get('days').errors.min">Minimum value 1 day.</div>
                      <div *ngIf="formGrp.get('days').errors.max">Maximum Value 120 days .</div>
                      <div *ngIf="formGrp.get('days').errors.pattern">Enter Valid Days.</div>
                  </div>
              </div>

              <div class="contact-details__details">
                  <div class = "btn btn--blue" (click)="EditClicked($event, 'EMAIL')" *ngIf="!hideEditButtonEmail">Edit</div>
                  <button class = "btn btn--green"  *ngIf="showSaveCancelEmail"  [disabled]="formGrp.get('days').errors" (click)="confirmEdit($event,formGrp.get('days').value , 'EMAIL')">Save</button>
                  <button class = "btn btn--red" (click)="CancelEdit($event,'EMAIL')"  *ngIf="showSaveCancelEmail">Cancel</button>
            </div>
            </div>
            </div>
          </div>
         </div>

        </form>
    </div>
</div>
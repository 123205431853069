/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { PopUpService } from '../../../services/pop-up.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { PartnerService } from '../../../services/partner.service';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-export-report',
  templateUrl: './export-report.component.html',
  styleUrls: ['./export-report.component.css']
})
export class ExportReportComponent implements OnInit {
  @Input() partner_id: number;

  exportForm: FormGroup;

  constructor(
    private partnerService: PartnerService,
    private popUpService: PopUpService
  ) { }

  ngOnInit() {
    this.exportForm = this.setupExportForm();
  }

  get date_range(): FormControl {
    return this.exportForm.get('date_range') as FormControl;
  }

  exportReportCSV(event: Event): void {
    if (event) { event.preventDefault(); }
    this.date_range.markAsTouched();

    if (this.exportForm.valid) {
      this.partnerService.exportCSV(this.partner_id, this.exportForm.value).subscribe(result => {
        this.formatDataAsJson(result);
        this.exportForm.reset();
      },
      error => {
        console.log(error);
      });
    }
  }

  private showAlert(message: string, action?: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (!action) {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }
    else {
      this.popUpService.setPositiveAction(
        action
      );
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) { this.popUpService.showDiv(dialog); }
  }

  formatDataAsJson(data: any) {
    const jsonData = [];
    if (data.caseList.length > 0) {
      data.caseList.forEach(each => {
        const obj = {};
        const videoTypeList = ['MDT VIDEO', 'VIDEO CONSULTATION'];
        const age = moment().diff(moment(each.patient.dob), 'years');

        obj['Client Name'] = data.partner.partner_name ? data.partner.partner_name : 'N/A';
        obj['Case Identifier'] = each.case_identifier ? each.case_identifier : 'N/A';
        obj['Name'] = each.patient.user ? each.patient.user.full_name : 'N/A';
        obj['Age'] = age;
        obj['Gender'] = each.patient.gender === 'M' ? 'Male' : (each.patient.gender === 'F' ? 'Female' : 'N/A');
        obj['Category'] = each.category ? each.category.category : 'N/A';
        obj['Case Type'] = each.case_type ? each.case_type : 'N/A';
        obj['VIP'] = each.is_vip;
        obj['Request Date'] = moment(each.created_at).format('MM-DD-YYYY HH:mm');
        obj['Company'] = each.patient.associated_company ? each.patient.associated_company : 'N/A';
        obj['MR Complete'] = each.date_mr_complete ? moment(each.date_mr_complete).format('MM-DD-YYYY HH:mm') : 'N/A';
        obj['Consultation Date'] = videoTypeList.includes(each.case_type) ? moment(each.schedule.consultation_date_time).format('MM-DD-YYYY HH:mm') : (each.case_md_management && moment(each.case_md_management.assigned_md_list[0].updated_at).format('MM-DD-YYYY HH:mm'));
        obj['Completed Date'] = moment(each.status[0].updated_at).format('MM-DD-YYYY HH:mm');

        jsonData.push(obj);
      });

      this.partnerService.downloadAsCsv(jsonData, 'report');
    }
    else {
      this.showAlert('No cases found');
    }
  }

  private setupExportForm(): FormGroup {
    return new FormGroup({
      date_range: new FormControl('', Validators.required)
    });
  }

}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { PopUpService } from '../../../../services/pop-up.service';
import { ICaseRequestedInteractor } from './ICaseRequestedInteractor';
import { ICaseRequestedPresetner } from '../Presenter/ICaseRequestedPresetner';
import { CaseService } from '../../../../services/case.service';
import { CommonService } from '../../../../services/common.service';
import { UserService } from '../../../../services/userservice.service';
import { forkJoin } from 'rxjs';
import { CommonFuntions } from '../../../../common/common.function';
import * as moment from 'moment';
import { flatMap } from 'rxjs/operators';

export class CaseRequestedInteractor implements ICaseRequestedInteractor {

  constructor(
		private presenter: ICaseRequestedPresetner,
		private popUpService: PopUpService,
		private caseService: CaseService,
		private commonService: CommonService,
		private userService: UserService
  ) { }

  networkCallCaseQuestions(caseId: number): void {
    this.caseService.getCaseQuestions(caseId)
      .subscribe(
        question => {
          this.presenter.onCallCaseQuestionSuccess(question.case_questions);
        },
        error => {
          console.log(error);
        }
      );
  }

  networkCallCaseType(): void {
    this.commonService.getCaseType().subscribe(
      types => {
        this.presenter.onCallCaseTypeSuccess(types);
      },
      (_error) => {
        this.presenter.onCallCaseTypeSuccess([]);
      }
    );
  }

  networkCallUsers(userId: number, partnerId: number): void {
    forkJoin(
      [this.userService.getTranslatorList(),
        this.userService.getCareTeamList(userId, partnerId), this.userService.getCaseCoordinatorList(userId, partnerId)]
    ).subscribe(
      result => {
        const translator = this.mapUser(result[0], true, false);
        const careteam = this.mapUser(result[1], false, false);
        const casecoordinaor = this.mapUser(result[2], false, false);
        this.presenter.onUserListSuccess('translator', translator.concat(careteam, casecoordinaor));
        this.presenter.onUserListSuccess('translateDr', translator.concat(careteam, casecoordinaor));
        this.presenter.onUserListSuccess('schedule', careteam.concat(casecoordinaor));
        this.presenter.onUserListSuccess('video', careteam.concat(casecoordinaor));
        this.presenter.onUserListSuccess('report', careteam.concat(casecoordinaor));
        this.presenter.onUserListSuccess('mdMgmt', careteam.concat(casecoordinaor));
        this.presenter.onUserListSuccess('summary', careteam.concat(casecoordinaor));
        this.presenter.onUserListSuccess('written', careteam.concat(casecoordinaor));

      },
      error => {
        console.log(error);
      }
    );
  }

  private showAlert(message: string ,positiveCallback: Function): void {
    this.popUpService.hideDiv('confirmDialog'); // hide dialog box if alert active
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'alert',
      message,
      'Ok',
    );

    this.popUpService.setPositiveAction(positiveCallback);

    const alertDialog = this.popUpService.getPopUpView('alertDialog');
    this.popUpService.showDiv(alertDialog);
  }

  networkCallRequested(value: any, caseId: number, userId: number): void {
    let notifyAll = false;
    this.caseService.createOrUpdateRequested(
      value, caseId
    ).pipe(
      flatMap(
        _result => {
          this.presenter.notifyAssigneeChange();
          return this.caseService.getCaseById(caseId, userId);
        }
      ),
      flatMap(
        caseDetail => {
          this.presenter.notifyCaseDetail(caseDetail);
	
          const inprogressList = CommonFuntions.setStatusInProgress(caseDetail.status, 'requested');
          const statusList = [];
          inprogressList.forEach(eachStatus => {
            statusList.push({ status_id: eachStatus.status.id });
          });
          if (caseDetail.status[1].state === 'NOT STARTED') {
            notifyAll = true;
            return this.caseService.updateStaus(statusList, caseId).pipe(
              flatMap(
                _result => this.caseService.getCaseById(caseId, userId)
              )
            );
          }
          else {
            return this.caseService.getCaseById(caseId, userId);
          }
					
        }
      )
    ).subscribe(
      caseDetail => {
        caseDetail.date_mr_complete = moment(caseDetail.date_mr_complete).format('MM/DD/YYYY, h:mm A');
        this.presenter.notifyCaseDetail(caseDetail);
        if (notifyAll) { this.presenter.notifyCaseStatus('all'); }
        this.showAlert('Case assigned successfully.' ,function (): void {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
        this.presenter.notifyInprogress(false);
      },
      _error => {
        this.showAlert('Failed to assign case.' ,function (): void {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
        this.presenter.notifyInprogress(false);
      }
    );
		
  }

  networkCallAddUpdateQuestions(value: any, caseId: any): void {
    this.caseService.updateQuestions(
      value,
      caseId
    ).subscribe(
      function(result): void {
        this.presenter.notifyQuestionFormReset();
        this.presenter.onCallCaseQuestionSuccess(result.questions);

        this.presenter.notifyInprogress(false);
        this.showAlert('Questions update successful.' ,function (): void {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
      }.bind(this),
      function(_error): void {
        this.showAlert('Failed to update questions.' ,function (): void {
          this.popUpService.hideDiv('alertDialog');
          this.presenter.notifyOnCancel();
          this.presenter.notifyInprogress(false);
        }.bind(this));				
      }.bind(this)
    );
  }

  networkCallUpdateCaseType(value: any, caseDetail: any): void {
    this.caseService.updateType(value).subscribe(
      result => {

        caseDetail.case_type = result.body.case_type;
        caseDetail.status = result.body.status;
        this.presenter.notifyCaseDetail(caseDetail);

        this.showAlert(`Case Type changed to ${value.case_type}.` ,function (): void {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
      },
      error => {
        if (error.statusCode && error.statusCode === 400) {

          this.showAlert(`${error.message}.`,function (): void {
            this.popUpService.hideDiv('alertDialog');
          }.bind(this));
        }
        else {

          this.showAlert('Failed to change case type.' ,function (): void {
            this.popUpService.hideDiv('alertDialog');
          }.bind(this));			
        }
      }
    );
  }

  private mapUser(list: any[], isTranslator: boolean, isCmManager: boolean): any[] {
    return list.map(each => {
      const userObject = {};
      userObject['assigned_user_id'] = each.user_id;
      userObject['assignee_name'] = isTranslator || isCmManager ? each.full_name : each.user.full_name;
      userObject['assignee_first_name'] = isTranslator || isCmManager ? each.first_name : each.user.first_name;
      userObject['assigned_role'] = isTranslator ? 'translator' : isCmManager? 'cmmanager': 'careteam';
      userObject['email'] = isTranslator || isCmManager ? each.email : each.user.email;
      return userObject;
    });
  }
}

/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonFuntions } from '../../../common/common.function';
import { EmailTemplateService } from '../../../services/email-template.service';
import { PopUpService } from '../../../services/pop-up.service';
import { APPLY_TO, TEMPLATE_TAGS } from '../constant';


@Component({
  selector: 'app-new-email-template',
  templateUrl: './new-email-template.component.html',
  styleUrls: ['./new-email-template.component.css']
})
export class NewEmailTemplateComponent implements OnInit {
  isInprogress = false;
  emailTemplateForm: FormGroup;
  applyToList = APPLY_TO;
  items = TEMPLATE_TAGS;

  constructor(
    private fb: FormBuilder,
    private EmailTemplateService: EmailTemplateService,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private router: Router,
    private popUpService: PopUpService
  ) { }

  getemailTemplateForm(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      subject: ['', Validators.required],
      body: ['', Validators.required],
      // reply_to: [''],
      apply_to: ['', Validators.required],
    });
  }

  callSubmitAction(): void {
    const postiveAction = function() {
      this.popUpService.hideDiv('alertDialog');
      this.router.navigate(['/email-templates']);
    }.bind(this);

    this.isInprogress = true;
    if (this.emailTemplateForm.valid) {
      const payload = this.emailTemplateForm.value;
      this.EmailTemplateService.createEmailTemplate(payload).subscribe(
        result => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { status, data } = result;
          if (status === 200) {
            this.isInprogress = false;
            this.showAlert('New Email Template Added.', postiveAction);
          }
        },
        error => {
          this.isInprogress = false;
          this.showAlert(error.error.message ? error.error.message : 'Failed to add new email template.');
        }
      );
    }
    else {
      console.log('Not valid form');
      this.isInprogress = false;
      CommonFuntions.validateAllFormFields(this.emailTemplateForm);
    }
  }

  /** Function to execute when the form is submitted. */
  onSubmit() {
    if(this.emailTemplateForm.valid){
      const message = 'Are you sure you want to add new email template?';
      const postiveAction = function(): void {
        this.callSubmitAction();
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this);
      const negativeAction = function(): void {
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this);
      
      this.showConfirmation(message, postiveAction, negativeAction);
    }
    else{
      CommonFuntions.validateAllFormFields(this.emailTemplateForm);
    }
  }

  ngOnInit(): void {
    this.emailTemplateForm = this.getemailTemplateForm();
  }

  get name() {
    return this.emailTemplateForm.get('name');
  }

  get subject() {
    return this.emailTemplateForm.get('subject');
  }

  get body() {
    return this.emailTemplateForm.get('body');
  }

  // get reply_to() {
  //   return this.emailTemplateForm.get('reply_to');
  // }

  get apply_to() {
    return this.emailTemplateForm.get('apply_to');
  }

  dynamicVariable() {
    switch (this.apply_to.value) {
    case 'Careteam after Case Creation by Member':
    case 'Careteam when Case Created by Careteam or Client Careteam':
      this.items = [
        'CaseId',
        'CaseURL',
        'Diagnosis',
        'Initials',
      ];
      break;
    case 'Case Creation by Members':
      this.items = [
        'CaseId',
        'CaseURL',
        'Initials',
      ];
      break;
    case 'Client CT or IMD CT when Case Created by Careteam or Client Careteam':
    case 'Requested Stage':
      this.items = [
        'CaseId',
        'CaseURL',
      ];
      break;
    case 'Video Stage':
    case 'Compile MR Stage':
      this.items = [
        'CaseId',
        'CaseURL',
        'LastName'
      ];
      break;
    case 'Summary Stage':
      this.items = [
        'CaseId',
        'CaseURL',
        'CareTeam'
      ];
      break;
    case 'Report Stage':
      this.items = [
        'CaseId',
        'CaseURL',
        'ClientCareTeam'
      ];
      break;
    case 'MD after Scheduling is completed':
      this.items = [
        'CaseId',
        'CaseURL',
        'ZoomLink',
        'LastName',
        'Diagnosis',
        'Date/Time'
      ];
      break;
    case 'Interpreter after Scheduling is completed':
      this.items = [
        'CaseId',
        'ZoomLink',
        'FirstName',
        'Date/Time'
      ];
      break;
    case 'Client Careteam after Scheduling is completed':
      this.items = [
        'CaseId',
        'ZoomLink',
        'ClientCareTeam',
        'Date/Time'
      ];
      break;
    case 'MD submits a request for missing data':
      this.items = [
        'CaseId',
        'CaseURL',
        'MdMessage',
      ];
      break;
    case 'MR Coordinator uploads Medical Record Summary':
      this.items = [
        'CaseId',
        'CaseURL',
        'Filename',
      ];
      break;
    case 'Follow up Case Assignment to SC':
    case 'Case Assignment to DOC':
    case 'Case Assignment to SC':
    case 'P2P Case Assignment to DOC':
    case 'P2P Case Assignment to SC':
    case 'P2P Follow up Case Assignment to SC':
      this.items = [
        'CaseId',
        'CaseURL',
        'LastName',
      ];
      break;
    case 'Follow up Case Assignment to DOC':
    case 'P2P Follow up Case Assignment to DOC':
      this.items = [
        'CaseId',
        'CaseURL',
        'LastName',
        'Age',
        'Diagnosis'
      ];
      break;
    case 'Case Submission by MD':
      this.items = [
        'CaseId',
        'CaseURL',
        'MdFullName',
      ];
      break;
    case 'Case Retraction-No Action SC':
    case 'Case Retraction-No Action DOC':
    case 'Case Retraction-Error SC':
    case 'Case Retraction-Error DOC':
      this.items = [
        'CaseId',
        'LastName',
      ];
      break;
    case 'Message from one user to other':
      this.items = [
        'CaseId',
        'Sender',
        'ApplicationURL'
      ];
      break;
    default:
      break;
    }
    console.log('items :>> ', this.items);

  }


  /** Function to show the confirmation dialog
   * It needs the <app-dialog> component to be loaded 
   */
  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function): void {
    this.popUpService.destoryConfirmDialog();
  
    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );
  
    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }
    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }
  
    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);
  }

  /** Function to show the confirmation dialog
   * It needs the <app-dialog> component to be loaded 
   */
  private showAlert(message: string, action?: Function): void {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (!action) {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }
    else {
      this.popUpService.setPositiveAction(
        action
      );
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) { this.popUpService.showDiv(dialog); }
  }

}

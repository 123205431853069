/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-navigation-component',
  templateUrl: './navigation-component.component.html',
  styleUrls: ['./navigation-component.component.css']
})
export class NavigationComponentComponent implements OnInit {

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.route.queryParams.subscribe(
      params => {
        let redirectUrl = params['openUrl'];

        if (redirectUrl == null || redirectUrl === '') {
          return;
        }

        if (redirectUrl != null) {

          const patterns = /(http|https):\/\/.+/g;

          if (!patterns.test(redirectUrl)) {
            redirectUrl = 'https://'+redirectUrl;

          }
        }

        window.location.href = redirectUrl;
      }
    );
  }

}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigAssetLoaderService } from './config-asset-loader.service';

import { getHeaderWithToken, MAIL_SERVER, MAIL_SETTING } from './constant';

@Injectable()
export class MailServerService {
  constructor(
    private http: HttpClient
  ) { }

  /**
   * Function to fetch all the mail servers
   * @return {*}  {Observable<any>}
   * @memberof MailServerService
   */
  getAllMailServers(): Observable<any> {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${MAIL_SERVER.LIST}`;
    return this.http.get<any>(URL, getHeaderWithToken());
  }

  /**
   * Function to get the detail of MailServer by its id
   * @param {number} id
   * @return {*}  {Observable<any>}
   * @memberof MailServerService
   */
  getMailServerById(id: number): Observable<any> {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${MAIL_SERVER.GET}/${id}`;
    return this.http.get<any>(URL, getHeaderWithToken());
  }

  /**
   * create new mail server
   * @param {*} payload
   * @return {*}  {Observable<any>}
   * @memberof MailServerService
   */
  createMailServer(payload): Observable<any> {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${MAIL_SERVER.CREATE}`;
    return this.http.post<any>(URL, payload, getHeaderWithToken());
  }

  /**
   * Function to edit the MailServer
   * @param {number} id
   * @param {*} payload
   * @return {*}  {Observable<any>}
   * @memberof MailServerService
   */
  editMailServer(id: number, payload): Observable<any> {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${MAIL_SERVER.PUT}/${id}`;
    return this.http.put<any>(URL, payload, getHeaderWithToken());
  }

  /**
   * Verify the connection with the mail server details
   * @param {number} id
   * @return {*}  {Observable<any>}
   * @memberof MailServerService
   */
  verifyConnection(id: number): Observable<any> {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${MAIL_SERVER.VERIFYCONNECTION}/${id}`;
    return this.http.get<any>(URL, getHeaderWithToken());
  }

  /**
   * Delete the mailserver detail
   * @param {number} id
   * @return {*}  {Observable<any>}
   * @memberof MailServerService
   */
  deleteMailServer(id: number): Observable<any> {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${MAIL_SERVER.GET}/${id}`;
    return this.http.delete<any>(URL, getHeaderWithToken());
  }

  /**
     * Function to fetch all the mail setting
     * @return {*}  {Observable<any>}
     * @memberof MailServerService
     */
  getMailSettings(): Observable<any> {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${MAIL_SETTING.LIST}`;
    return this.http.get<any>(URL, getHeaderWithToken());
  }

  /**
   * create new mail setting
   * @param {*} payload
   * @return {*}  {Observable<any>}
   * @memberof MailServerService
   */
  createMailSetting(payload): Observable<any> {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${MAIL_SETTING.CREATE}`;
    return this.http.post<any>(URL, payload, getHeaderWithToken());
  }

  /**
   * Function to edit the MailSetting
   * @param {*} payload
   * @return {*}  {Observable<any>}
   * @memberof MailServerService
   */
  editMailSetting(id, payload, extraParameter=''): Observable<any> {
    let URL = `${ConfigAssetLoaderService.getApiURL()}/${MAIL_SETTING.PUT}/${id}`;
    if (extraParameter) URL = `${ConfigAssetLoaderService.getApiURL()}/${MAIL_SETTING.PUT}/${id}?filter=${extraParameter}`;
    return this.http.put<any>(URL, payload, getHeaderWithToken());
  }

}


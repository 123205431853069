import Swal from 'sweetalert2';
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-spread */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */

import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl , Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { CommonService } from '../../services/common.service';

import {  ConfigAssetLoaderService } from '../../services/config-asset-loader.service';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-faqs-template',
  templateUrl: './faqs-template.component.html',
  styleUrls: ['./faqs-template.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FaqsTemplateComponent implements OnInit {
  @ViewChild('faqSendEmailTemplate') private faqSendEmailTemplate: SwalComponent;
  @ViewChild('faqTemplateSwal') public faqTemplateSwal: SwalComponent;
  @ViewChild('faqSendEmailConfirmationTemplate') public faqSendEmailConfirmationTemplate: SwalComponent;
  
   hideTemplate =  true;
   toggleTemplate = true;
   emailTo: Array<String>;
   emailFrom: String;
   currentUser: any;
   currentQuestionAnswer: Object;
   showEmailTemplate = false;
   faqForm: FormGroup;
   isLoading = false;
   questionAnswer: any;
   title = '';

   public publicEmails = {
     profileDataChange: ConfigAssetLoaderService.getProduction() ? 'maria.kingston@alight.com' : 'IMDCloudDevNotification+faq@consumermedical.com',
     careTeamEmail: ConfigAssetLoaderService.getProduction() ? 'careteam@alight.com' : 'IMDCloudDevNotification+faq@consumermedical.com'
   }
public faqQuestionAnswer = [
  {
    questionNumber: 1,
    question: 'What is the preferred Browser?',
    answer: 'Chrome is the preferred browser.'
  },
  {
    questionNumber: 2,
    question: 'What happens if I do not receive my email notification(s) related to case assignment?',
    answer: 'If the email used to log in is correct then please check the following:',
    answerOptions: [
      '1. Check your Junk or Spam mail folders and reroute (move) emails to your emailinbox.',
      '2. If using a facility email address such as a hospital, please contact the IT Department to request that the CareTeam@Consumermedical.com email address is \'white listed\' (allowed access/entry into facilities environment). If facility IT refuses or due to policy cannot \'whitelist\' this email address, please change  your email address to another email address such a personal Gmail email, etc.'
    ]
  },
  {
    questionNumber: 3,
    question: 'How do I reset my password?',
    answer: 'There are multiple methods to change a password',
    answerOptions: [
      '1. Utilize the \'Change My Password\' option presented on the InfiniteMD sign-in page, click on the \'Change my Password\' > follow the prompts to:',
      ' - Input your email address then log into your email to retrieve the link sent in order to reset your password',
      ' - Go back to log-in page and sign-in using new password',
      `2. Contact the Care Team nurse at <button id="btn" onClick="Window.faqEmailTemplate.setDataInModal('${this.publicEmails.profileDataChange}')" id="${this.publicEmails.careTeamEmail}">${this.publicEmails.careTeamEmail}</button> , an agent will assist with the password change.`
    ]

  },
  {
    questionNumber: 4,
    question: 'How do I submit profile data changes such as a changed name or phone number or a change in employer/institution, or address or maybe you have a new certification.',
    answer: `Click on <button id="btn" onClick="Window.faqEmailTemplate.setDataInModal('${this.publicEmails.profileDataChange}')" id="${this.publicEmails.profileDataChange}">${this.publicEmails.profileDataChange}</button>. Provide specific additions or changes needed in body of email and submit. If there are questions related to information provided, someone will contact you for clarification.`

  },
  {
    questionNumber: 5,
    question: 'What do I do if I am unable to access my assigned case?',
    answer: `Click on <button id="btn" onClick="Window.faqEmailTemplate.setDataInModal('${this.publicEmails.careTeamEmail}')"  id="${this.publicEmails.careTeamEmail}">${this.publicEmails.careTeamEmail}</button> to let the Care Team know you are not able to access your case list. Someone will reach out to you same day during businesshours at your provided phone number.`


  },
  {
    questionNumber: 6, 
    question: 'How do I request additional medical record information related to an assigned case?',
    answer: 'Preferred method would be to, log into your case and create a Case Comment. This Case Comment will be sent to the assigned Care Team member.'

  },
  {
    questionNumber: 7, 
    question: 'How do I access my case specific notifications?',
    answer: 'Log into application',
    answerOptions: [
      '1. At the top right of every screen that is a \'bell\'. If you have a  notification(s), you will see a number highlighted in RED circle',
      '2. Click on the \'bell\' to open and review notification(s)',
      '3. To clear a notification, click on the \'X\' to the right of the notification message. If you do not clear your notifications, they will remain in place until you remove again by selecting the \'X\'.'
    ]
  },

]

constructor(
  private fb: FormBuilder,
  private faqService: CommonService,
  private loginService: LoginService
) { 
  Window['faqEmailTemplate'] = this;
}


showFaqData(questionAnswer: string) : void{
  this.faqTemplateSwal.fire();
  this.questionAnswer = questionAnswer;

}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
setDataInModal(email): void{
  this.title =  `Send Email to ${email}`;
  this.emailTo = [];
  if(email && (email == this.publicEmails.profileDataChange)){
    this.faqSendEmailTemplate.inputPlaceholder = `Dear ${this.publicEmails.profileDataChange}, \n \n \n \n Please enter your message here`;
  }
  if(email && (email === this.publicEmails.careTeamEmail)){
    this.faqSendEmailTemplate.inputPlaceholder = `Dear ${this.publicEmails.careTeamEmail}, \n \n \n \n Please enter your message here`;
  }
  this.emailTo.push(email);
  this.faqSendEmailTemplate.fire();
}

emailValidator():void {
  const minLength = 3;
  const maxLength = 5000;
  if (this.faqSendEmailTemplate === undefined) { return; }
  this.faqSendEmailTemplate.inputValidator = (value) => {
    return new Promise<any>((resolve) => {
      if (value.length >= minLength && value.length <= maxLength) {
        const htmlValue = '<pre>'+value+'</pre>';
        this.faqForm.controls['email_template'].setValue(htmlValue);
        this.faqSendEmailConfirmationTemplate.fire();
      }
      else if(value.length >= maxLength){
        resolve('This field must not contain more than 50,000 characters !');
      }
      else {
        resolve('This field must contain at least 3 characters!');
      }
    });
  };

 
}

sendFaqEmail(): void{

  this.isLoading = true;

  Swal.fire({
    html: '<h4>Please wait (this may take a few seconds) </h4> Sender : ' + this.currentUser.email ,
    icon: 'info',
    showConfirmButton: false,
    backdrop: '#eef3f6',
    allowOutsideClick: false
  });
  const { value } = this.faqForm.get('email_template');
  this.faqService.sendEmail({ emailTemplate: value + `Sender: ${this.currentUser.email}` , emailTo: this.emailTo ,emailFrom: this.currentUser.email, subject: 'FAQs'}).subscribe(
    _result => {
      Swal.fire({
        backdrop: '#eef3f6', 
        reverseButtons: true, 
        cancelButtonColor: '#DB161E',
        confirmButtonColor: '#249533',
        iconColor: 'green',
        icon: 'success',
        cancelButtonText: 'X Close',
        text: 'Email has been successfully sent',
        showCancelButton: true,
        showConfirmButton: false,

      }),
      this.isLoading = false;
    },
    _error => {

      Swal.fire({
        iconColor: 'red',
        backdrop: '#eef3f6', 
        reverseButtons: true, 
        cancelButtonColor: '#DB161E',
        icon: 'error',
        text: 'Unable to send email',
        showCancelButton: true,
        cancelButtonText: 'X Close',
        showConfirmButton: false,
      }),

      this.isLoading = false;
    }
  );

  
}
initializeForm(): FormGroup {
  return this.fb.group({
    email_template: ['', [Validators.required, Validators.maxLength(5000)]]
  });
}

ngOnInit(): void {
  this.currentUser =  this.loginService.getCurrentUser();
  this.faqForm = this.initializeForm();
}


get email_template(): FormControl {
  return this.faqForm.get('email_template') as FormControl;
}
}


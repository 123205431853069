/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment/moment';

import { LoginService } from '../services/login.service';
import { LoginModel } from '../domains/loginModel';
import { ActivatedRoute, Router } from '@angular/router';
import { TimeoutService } from '../services/timeout.service';
import { CaseService } from '../services/case.service';
import { PopUpService } from '../services/pop-up.service';
import { ErrorsHandlerService } from '../errorshandler.service';
import { USER_ROLE_TYPE } from '../constant';

/**
  * On initiating Login component. Take login form to valid state. And load logo path for image.
  *
  * checks if user is already logged in. If yes, then redirects to home page. 
  */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  @ViewChild('alertDialog', {static: true})
  alertDialog: ElementRef;
  
  /**
   * Stores error message from the form
   */

  errorMessage: string;
  /**
   * Give image path to load infinity md logo in Login page
   */

  logoPath: string;

  /**
  * Flag to indicate if username is invalid
  */

  isUserValid: boolean;

  /**
  * Flag to indicate if password is invalid
  */

  isPasswordValid: boolean;

  /**
  * Flag to indicate request is still in progress
  */

  isLoading: boolean;

  /**
   * Login model for two way data binding
   */
  model: LoginModel;

  enabledDOB: boolean;

  isDateValid: boolean;
  password_type = 'password';

  /**
  * Login component to define view of login page.
  * @param {LoginService} loginService service to be called for login operation
  * @param {Router} router module that maintains router links for module
  * @param {ActivatedRouter} route router for navigating back to prev page if view is redirected to login by guard.
  */
  constructor(
    private loginService: LoginService,
    private timeout: TimeoutService,
    private router: Router,
    private route: ActivatedRoute,
    private caseService: CaseService,
    private popUpService: PopUpService,
    private errorHandleService: ErrorsHandlerService
  ) {
    this.model = new LoginModel();
  }

  /**
  * resets the form to valid stage on initate.
  * initializes logo path.
  * navigates to dashboad if jwt token is available & is not expired
  */
  ngOnInit() {
    this.enabledDOB = false;
    this.isUserValid = true;
    this.isPasswordValid = true;
    this.isDateValid = true;
    this.isLoading = false;
    this.logoPath = '/assets/images/logos/Alight_H.png';

    if (this.loginService.isLoggedIn()) {
      const user = this.loginService.getCurrentUser();
      const query = (new URL(window.location.href)).searchParams;

      if (query.get('caseId')) {
        this.loginService.getCaseByAllyCaseRef(query.get('caseId')).subscribe(result => {
          this.router.navigate([`/case/${+result.id}`]);
        },
        (error) => {
          this.router.navigate(['/page-not-found'], {
            queryParams: {
              message: `${error.error.message}`,
              title: 'Case not Found'
            } 
          });
        });
      }
      else if (user.user_type.type === 'Partner') {
        let routePath = `/clients/dashboard/${user.partners[0].partner_id}`;
        if (user.partners.length > 1) {
          routePath = '/clients';
        }

        this.router.navigate([routePath]);
      }
      else if (
        user.user_type.type.toLowerCase() === USER_ROLE_TYPE.DOCTOR ||
        user.user_type.type.toLowerCase() === USER_ROLE_TYPE.TRANSLATOR
      ) {
        this.router.navigate(['/dashboard']);
      }
      else {
        this.router.navigate(['/case'], { queryParams: { list: 'current' } });
      }
    }
    else {
      try {
        this.loginService.ssoLogin();
      }
      catch (error) {
        console.log('error', error);
      }
      console.log('Please login');
    }

    // add alertDialog popUpView to popUpService
    if (this.popUpService.getPopUpView('alertDialog') == null && this.alertDialog != null) {this.popUpService.addPopUpView('alertDialog', this.alertDialog);}
  }

  /**
   * Validates the form and display error to view if any.
   * Calls for login service class for getting jwt token while form is valid.
   *
   * @param f Form view passed from HTML file.
   */
  onSubmit(f: NgForm) {
    this.errorMessage = null;
    this.isUserValid = true;
    this.isPasswordValid = true;
    this.isDateValid = true;

    localStorage.setItem('ng2Idle.main.idling', 'false');

    this.isLoading = true;
    // check if value does not exist for email
    if (!f.value.email) {

      this.errorMessage = 'Enter email address';
      this.isUserValid = false;

      // check if value does not exist for password too.
      if (!f.value.password) {
        this.errorMessage = 'Enter email address and password';
        this.isPasswordValid = false;
      }

      // return if form is invalid
      this.isLoading = false;
      return;
    }

    // check if value does not exist for password
    if (!f.value.password) {
      this.errorMessage = 'Enter password';
      this.isPasswordValid = false;
      this.isLoading = false;
      return;
    }

    if (!f.value.dob && this.enabledDOB) {
      this.errorMessage = 'Enter valid date of birth';
      this.isDateValid = false;
    }

    // store userName and password in let model
    const modelSubmit = new LoginModel();
    modelSubmit.email = f.value.email;
    modelSubmit.password = f.value.password;
    if (this.enabledDOB) modelSubmit.dob = moment(f.value.dob).format('YYYY-MM-DD');

    modelSubmit.email = modelSubmit.email.replace(/^\s+|\s+$/g, '');

    // calls for service
    this.loginService.checkForLogin(modelSubmit)
      .subscribe(
        result => {
          if (result) {
            this.clearDataTableState();

            let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
            const user = this.loginService.getCurrentUser();
            sessionStorage.setItem('userId', user.user_id);
            localStorage.removeItem('expiryToken');
            localStorage.removeItem('passwordExpirySetting');

            if (returnUrl != null) {
              returnUrl = returnUrl.replace(/%2F/g, '/');
              returnUrl = returnUrl.replace(/%3F/g, '=');
              returnUrl = returnUrl.replace(/%3D/g, '&');
            }


            if (user.user_type.type == 'Partner') {
              if (returnUrl && returnUrl != '/') {
                const splitUrl = returnUrl.split('?');

                const urlArray = [];
                let queryObject = null;

                const splitPath = splitUrl[0].split('/');
                splitPath.forEach(path => {
                  if (urlArray.length == 0) {
                    urlArray.push('/' + path);
                    return;
                  }

                  urlArray.push(path);
                });

                if (splitUrl.length > 1) {
                  queryObject = {};
                  const splitQuery = splitUrl[1].split('&');
                  splitQuery.forEach(query => {
                    queryObject[query.split('=')[0]] = query.split('=')[1];
                  });
                }

                if (queryObject == null) {
                  this.router.navigate(urlArray);
                }
                else {
                  this.router.navigate(urlArray, { queryParams: queryObject });
                }
              }
              else {
                let routePath = `/clients/dashboard/${user.partners[0].partner_id}`;
                if (user.partners.length > 1) {
                  routePath = '/clients';
                }
                
                this.router.navigate([routePath]);
              }
            }
            else if (user.user_type.type !== 'Patient') {
              if (returnUrl && returnUrl != '/') {
                const splitUrl = returnUrl.split('?');

                const urlArray = [];
                let queryObject = null;

                const splitPath = splitUrl[0].split('/');
                splitPath.forEach(path => {
                  if (urlArray.length == 0) {
                    urlArray.push('/' + path);
                    return;
                  }

                  urlArray.push(path);
                });

                if (splitUrl.length > 1) {
                  queryObject = {};
                  const splitQuery = splitUrl[1].split('&');
                  splitQuery.forEach(query => {
                    queryObject[query.split('=')[0]] = query.split('=')[1];
                  });
                }

                if (queryObject == null) {
                  this.router.navigate(urlArray);
                }
                else {
                  this.router.navigate(urlArray, { queryParams: queryObject });
                }
              }
            }

            this.checkForceReset(user);
            this.timeout.reset();
          }
          else {
            this.isLoading = false;
          }
        }, error => {
          this.isLoading = false;
          if (error.status === 405) {
            this.enabledDOB = true;
            this.errorMessage = 'Please provide birth date';
          }
          else {
            const serverError = error.error || error;
            if (serverError.type && serverError.type === 'PASSWORD_EXPIRED') {
              this.errorHandleService.handleAuthError(serverError);
            }
            else {
              this.errorMessage = this.loginService.handleError(error);
              if (this.enabledDOB && error.status === 401) this.errorMessage += ' and/or date of birth';
            }
          }
        }, () => {
          this.isLoading = false;
        }
      );
  }

  clearDataTableState() {
    localStorage.removeItem('DataTables_casetable_active_/case');
    localStorage.removeItem('DataTables_doctor_/doctor-role');
    localStorage.removeItem('DataTables_imd_careteams_/imd-careteam-role');
    localStorage.removeItem('DataTables_interpreter_/interpreter-role');
    localStorage.removeItem('DataTables_partner_careteam_/client-role');
    localStorage.removeItem('DataTables_patient_/patient-role');
    localStorage.removeItem('DataTables_translator_/translator-role');
    localStorage.removeItem('DataTables_mdmanager_/md-manager-role');
    localStorage.removeItem('DataTables_cmmanager/cm-manager-role');
    localStorage.removeItem('DataTables_case_coordinator/case-coordinator-role');
    localStorage.removeItem('DataTables_doctor_/email-templates');
  }


  routeToForgotPassword(event: Event) {

    if (event) { event.preventDefault(); }

    this.router.navigate(['/forgotpassword']);

  }

  routeToSignUp(event: Event) {
    if (event) { event.preventDefault(); }

    this.router.navigate(['/signup']);
  }

  private checkForceReset(user) {
    if (user && !user.force_password_reset) {
      // Check for password alert message
      setTimeout(() => {
        this.errorHandleService.checkForPasswordAlert(user);
      }, 2000);

      if (user && user.user_type && user.user_type.type.toLowerCase() === 'patient') {
        this.navigateToPatientLanding();
      }
      else if (
        user.user_type.type.toLowerCase() === USER_ROLE_TYPE.DOCTOR ||
        user.user_type.type.toLowerCase() === USER_ROLE_TYPE.TRANSLATOR
      ) {
        this.router.navigate(['/dashboard']);
      }
      else {
        this.router.navigate(['/case'], { queryParams: { list: 'current' } });
      }
      return;
    }
    this.router.navigate(['/reset', 'password']);
  }

  private navigateToPatientLanding() {
    const user = this.loginService.getCurrentUser();
    this.caseService.getV1CaseForPatient(user.user_id).subscribe(
      cases => {
        // navigate to caselist if member has any case
        // else navigate to dashboard for member

        if (cases.hasCases) { this.router.navigate(['/case'], { queryParams: { list: 'current' } }); }
        else { this.router.navigate(['/patientdashboard']); }

      }
    );
  }

  public onDateChange(_event, calender, dateInput) {
    const calenderDate = calender.selected;
    if (calenderDate == null) {
      dateInput.value = '';
      return;
    }
    if (calenderDate.getFullYear() < 1900 || calenderDate.getFullYear() > 9999) {
      if (calenderDate.getFullYear() > 9999) { dateInput.value = ''; }
      return;
    }
    const date = moment(calender.selected.toUTCString()).format('YYYY-MM-DD');

    if (moment().isBefore(date)) {
      dateInput.value = '';
      return;
    }

    dateInput.value = date;
  }

  preventDefault(event: Event) {
    const userAgent = window.navigator.userAgent;

    const browsers = { chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i };

    for (const key in browsers) {
      if (browsers[key].test(userAgent)) {
        if (key == 'firefox') {
          event.preventDefault();

        }
      }
    }
  }

  // spInitiatedLogin() {
  //   this.loginService.ssoLogin2().subscribe(result => {
  //     window.location.href = result
  //   }, (error) => {
  //     console.log('error', error)
  //   })
  // }
}
<app-case-wrapper [totalRecord]="totalCaseCount" (searchValueEmitter)="searchString($event)" [searchValue]="searchValue"></app-case-wrapper>

<div class="wrapper--dash-content dash-content">
	<div *ngIf="errorString" class="message message--is-invalid">
		{{errorString}}
	</div>

	<table datatable [dtOptions]="dtOptions" class="row-border hover" id="doctor">
		<thead>
			<tr>
			</tr>
		</thead>
	</table>
</div>
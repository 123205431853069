/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Subject } from 'rxjs';
import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  AfterContentInit,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { OnChanges } from '@angular/core';

import { WrittenCaseComponent } from '../written-case/written-case.component';

import { CommonFuntions } from '../../../common/common.function';
import { CaseService } from '../../../services/case.service';
import { PopUpService } from '../../../services/pop-up.service';
import { ConfigAssetLoaderService } from '../../../services/config-asset-loader.service';

import { CareteamReport } from '../../../domains/case/general-case/careteam-report-update';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-review-case',
  templateUrl: './review-case.component.html',
  styleUrls: ['./review-case.component.css']
})
export class ReviewCaseComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {



  disableInputs: boolean;
  @Input() caseDetail: any;
  @Input() final_reports;
  @Input() translated_document;
  @Input() document_for_download;
  @Input() isMdManager;
  @Input() caseMedications: any;
  @Input() constructHistory: Function;
  @Input() followUpNumber;
  @Output() caseEmitter = new EventEmitter<any>();
  @Output() showStatusEmitter = new EventEmitter<string>();
  @Output() refreshHistory = new EventEmitter<boolean>();
  review;
  report;
  translate_dr;
  @ViewChild('written', { static: true }) written: WrittenCaseComponent

  caseMedicationList: any;
  commentSummary: string;
  isReportGeneration: boolean
  sendEmailLabel;
  isEmailSending: boolean;
  isEditEnable: boolean;
  isProgress: boolean;
  mdtCase: boolean;
  form: FormGroup
  clinicalConsultationForm: FormGroup
  careteamReport: CareteamReport;
  unsubscribe$: Subject<void>;
  isSummaryCollapse = true;
  
  constructor(
    private caseService: CaseService,
    private formBuilder: FormBuilder,
    private popUpService: PopUpService,
  ) {
    this.unsubscribe$ = new Subject<void>();
  }

  setConsultationFormValue($event){
    this.clinicalConsultationForm.setValue($event);
  }

  ngOnInit() {
    this.sendEmailLabel = 'Re-send email to MD';
    this.isEmailSending = false;
    this.isReportGeneration = false;

    this.mdtCase = this.checkCaseType(this.caseDetail.case_type);
  }


  ngOnChanges(_changes: SimpleChanges): void {
    this.form = this.getForm();
    if(this.isPeerToPeer()){
      this.clinicalConsultationForm = this.getClinicalConsultationForm();
    }


    if (this.caseDetail) {
      this.review = this.caseDetail.status.find(element => element.status.status.toLowerCase() === 'summary');

      this.report = this.caseDetail.status.find(ele => ele.status.status.toLowerCase() === 'report');

      this.translate_dr = this.caseDetail.status.find(ele => ele.status.status.toLowerCase() === 'translatedr');
  
      this.disableInputs = CommonFuntions.checkIfHoldOrCancelledOrFinishedorMd(this.caseDetail, this.isMdManager);
    }

    this.commentSummary = this.caseMedications ? this.caseMedications.comment_summary : '';
    if (this.caseDetail.is_submitted) { this.comment_summary.setValue(this.commentSummary); }

    // if (this.caseDetail.is_email_sent) {
    // 	this.sendEmailLabel = "Re-send email to MD"
    // }

    if (this.caseMedications && this.caseMedications.case_medications) {

      this.caseMedicationList = this.sortMedicationByUserId(this.caseMedications.case_medications);

      this.caseMedicationList.forEach(each => {
        const medication = this.formBuilder.group(
          {
            case_medication_id: [0, [Validators.required]],
            medication: ['', [Validators.maxLength(50000)]],
            case_id: this.caseDetail.id,
            medication_by_user_id: [0, [Validators.required]],
            follow_up_id: [0]
          }
        );

        if (this.caseDetail.is_submitted) { medication.patchValue(each); }

        this.case_medications.push(medication);
      });
    }
  }

  ngAfterContentInit(): void {
    this.form = this.getForm();

    if (this.caseDetail) {
      this.review = this.caseDetail.status.find(element => element.status.status.toLowerCase() === 'summary');

      this.report = this.caseDetail.status.find(ele => ele.status.status.toLowerCase() === 'report');

      this.disableInputs = CommonFuntions.checkIfHoldOrCancelledOrFinishedorMd(this.caseDetail, this.isMdManager);
    }
    this.commentSummary = this.caseMedications && this.caseMedications.comment_summary;
    if (this.caseDetail.is_submitted) { this.comment_summary.setValue(this.commentSummary); }

    if (this.caseMedications && this.caseMedications.case_medications) {
      this.caseMedicationList = this.sortMedicationByUserId(this.caseMedications.case_medications);

      this.caseMedicationList.forEach(each => {
        const medication = this.formBuilder.group(
          {
            case_medication_id: [0, [Validators.required]],
            medication: ['', [Validators.maxLength(50000)]],
            case_id: this.caseDetail.id,
            medication_by_user_id: [0, [Validators.required]],
            follow_up_id: [0]
          }
        );

        if (this.caseDetail.is_submitted) { medication.patchValue(each); }

        this.case_medications.push(medication);
      });
    }

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private checkCaseType(caseType: string): boolean {
    let mdtType = false;

    if(caseType === 'MDT VIDEO' || caseType === 'MDT WRITTEN') {
      mdtType = true;
    }

    return mdtType;
  }

  public getUsername(id: number): string {
    let name = 'N/A';
    const userId = Number(id);

    const assignedMDList = this.caseDetail.case_md_management.assigned_md_list;
    if (assignedMDList && assignedMDList.length > 0) {
      const mdObj = assignedMDList.find(mdItem => mdItem.assigned_doc_user_id === userId);
      name = mdObj ? mdObj.user.full_name : 'N/A';
    }

    return name;
  }


  private showAlert(message: string ,positiveCallback: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'alert',
      message,
      'Ok',
    );

    this.popUpService.setPositiveAction(positiveCallback);

    const alertDialog = this.popUpService.getPopUpView('alertDialog');
    this.popUpService.showDiv(alertDialog);
  }

  public generatePDF(event: Event) {
    if (event) { event.preventDefault(); }

    if (
      !this.caseDetail
      || this.caseDetail == null
      || !this.caseDetail.is_submitted
      || this.review == null
      || this.review.state !== 'INPROGRESS'
    ) {
      return;
    }
    const peerDetails: any = this.written.validateAndReturnValue();
    console.log(peerDetails);
    this.caseDetail['case_medications'] = this.caseMedicationList;
    if(this.isPeerToPeer()){
      this.caseDetail['peer_to_peer_details'] = Object.assign(this.caseDetail.peer_to_peer_details,peerDetails.peer_to_peer_details);
    }

    this.isReportGeneration = true;
    this.caseService.generatePDF(this.caseDetail).subscribe(
      function (result) {
        let message = null;

        if (result.status === 200) {
          message = 'Final Report has been generated and verified';
        }
        else if (result.status === 202) {
          message = 'Final Report has been generated. Failed to verify report.';
        }

        this.written.isSummaryFinish = true;

        result = result.body;

        this.refreshHistory.emit(true);

        this.popUpService.destoryConfirmDialog();

        this.popUpService.setDialogParams(
          'Success',
          message,
          'OK'
        );

        this.popUpService.setPositiveAction(function () {

          this.caseDetail['final_reports'] = this.finalReport;
          this.caseDetail['document_for_download'] = this.object.document_for_download;
          this.caseDetail['translated_document'] = this.object.translated_document;

          this.caseEmitter.emit(this.caseDetail);

          let summary = this.caseDetail.status.find(eachStatus => eachStatus.status_id === 8);

          if (summary) { summary.state = 'FINISHED'; }

          summary = this.caseDetail.status.find(eachStatus => eachStatus.status_id === 9);

          if (summary) {
            summary.state = 'INPROGRESS';

            this.showStatusEmitter.emit('translateDR');
          }
          else {
            summary = this.caseDetail.status.find(eachStatus => eachStatus.status_id === 10);

            if (summary) { summary.state = 'INPROGRESS'; }

            this.showStatusEmitter.emit('report');
          }

          window.scroll(0, 0);

          this.popUpService.hideDiv('alertDialog');
        }.bind({
          popUpService: this.popUpService,
          caseDetail: this.caseDetail,
          caseEmitter: this.caseEmitter,
          showStatusEmitter: this.showStatusEmitter,
          finalReport: result,
          isReportGeneration: this.isReportGeneration,
          object: this
        }));

        const alertDialog = this.popUpService.getPopUpView('alertDialog');
        this.popUpService.showDiv(alertDialog);
        this.isReportGeneration = false;

      }.bind(this),
      function (error) {
        this.popUpService.destoryConfirmDialog();

        let statusText = 'Final Report generation failed';
        if(error.error && error.error.reason === 'summary-completed') {
          statusText = 'Summary stage has already been completed';
        }

        this.popUpService.setDialogParams(
          'Error',
          statusText,
          'OK'
        );

        this.popUpService.setPositiveAction(function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));

        this.isReportGeneration = false;

        const alertDialog = this.popUpService.getPopUpView('alertDialog');
        this.popUpService.showDiv(alertDialog);
      }.bind(this)
    );
  }

  public sendEmailToMD() {
    this.isEmailSending = true;
    const linkUrl = ConfigAssetLoaderService.getDomainURL() + '/case/doctor-consultation/' + this.caseDetail.id;

    const doctor = {};
    const doctorInfo = this.caseDetail.case_md_management.user;

    doctor['full_name'] = doctorInfo.full_name;
    doctor['user_id'] = doctorInfo.user_id;
    doctor['email'] = doctorInfo.email;
    doctor['first_name'] = doctorInfo.first_name;
    doctor['middle_name'] = doctorInfo.middle_name;
    doctor['last_name'] = doctorInfo.last_name;
    doctor['time_zone'] = doctorInfo.time_zone;
    doctor['time_zone_offset'] = doctorInfo.time_zone_offset;

    this.caseService.sendEmailInSummary(this.caseDetail.id, doctor, linkUrl, this.caseDetail.case_identifier).subscribe(
      (_result) => {

        this.showAlert('Email sent to MD.' ,function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
        this.sendEmailLabel = 'Re-send email to MD';
        this.isEmailSending = false;
      },
      (_error) => {
        this.showAlert('Failed to send email.' ,function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
        // this.caseDetail.case_md_management.is_email_sent = false
        this.isEmailSending = false;
      }
    );
  }

  onEditChange(edit: boolean) {
    this.isEditEnable = edit;
  }



  onEditSave() {

    if(this.form.valid){

      const written_form: any = this.written.validateAndReturnValue();
      const value = this.form.value;

      this.careteamReport = new CareteamReport();
      this.careteamReport.case_id = this.caseDetail.id;
      this.careteamReport.comment_summary = value.comment_summary;
      this.careteamReport.answer = written_form.answer;
      this.careteamReport.written_summary = written_form.written_summary;
      this.careteamReport.case_medications = value.case_medications;
      if(this.isPeerToPeer()){
        const clinicalDataParsedValue =  Object.assign(JSON.parse(this.caseDetail.peer_to_peer_details),written_form.peer_to_peer_details);
        this.careteamReport.peer_to_peer_details = clinicalDataParsedValue;
      }

      this.showHideProgress();

      this.updateSummary(this.careteamReport);

    }
    else {
      this.showAlert('An issue occured while saving.' ,function () {
        this.popUpService.hideDiv('alertDialog');
      }.bind(this));
    }
  }

  isPeerToPeer() {
    const { reason, speciality_program} = this.caseDetail;

    if (reason && reason.reason.includes('Peer to Peer')) return true; 
    if (speciality_program && speciality_program.includes('Peer to Peer')) return true; 

    return false;
  }
  private updateSummary(value: CareteamReport) {
    this.caseService.updateEditSummary(value).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      (result: any) => {
        this.isEditEnable = this.isEditEnable ? false : true;

        this.caseDetail.written_summary = value.written_summary;
        this.caseDetail.comment_summary = value.comment_summary;
        this.caseMedications.comment_summary = value.comment_summary;
        this.caseMedications.case_medications = value.case_medications;


        this.caseEmitter.emit(this.caseDetail);

        this.showAlert(`${result.message}.` ,function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));

        this.showHideProgress();
      },
      (_error) => {

        this.isEditEnable = this.isEditEnable ? false : true;

        this.showAlert('Case Summary update failed.' ,function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));

        this.showHideProgress();
      }
    );
  }

  private getForm() {
    return this.formBuilder.group(
      {
        case_medications: this.formBuilder.array([]),
        comment_summary: ['', [Validators.maxLength(50000)]]
      }
    );
  }

  private getClinicalConsultationForm(){
    return this.formBuilder.group({
      clinical_consult_diagnosis: ['',Validators.required],
      clinical_consult_diagnosis_notes: ['',Validators.compose([Validators.required,Validators.maxLength(100)])],
      clinical_consult_recommend_treatment_plan: ['',Validators.required],
      clinical_consult_recommend_treatment_plan_notes: ['',Validators.compose([Validators.required,Validators.maxLength(100)])],
    });
  }
  get case_medications() {
    return this.form.get('case_medications') as FormArray;
  }

  public showHideProgress() {
    this.isProgress = this.isProgress ? false : true;
  }

  get comment_summary() {
    return this.form.get('comment_summary');
  }

  private sortMedicationByUserId(medicationList: any[]) {
    let list = [];

    if (medicationList.length > 0) {
      list = medicationList.sort((a, b) => Number(a.medication_by_user_id) - Number(b.medication_by_user_id));
    }

    return list;
  }

}

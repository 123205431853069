/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable max-len */
/* eslint-disable no-undef */

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPasswordToggle]'
})
export class PasswordToggleDirective {
  private passwordVisible = false;

  constructor(private el: ElementRef) {}

  @HostListener('mousedown')
  onMouseDown():void{
    this.passwordVisible = true;
    this.updatePasswordVisibility();
  }

  @HostListener('mouseup')
  @HostListener('mouseleave')
  onMouseUp():void {
    this.passwordVisible = false;
    this.updatePasswordVisibility();
  }

  private updatePasswordVisibility() {
    const passwordInput = this.el.nativeElement.previousElementSibling;
    const iconElement = this.el.nativeElement.children[0];

    if (passwordInput && passwordInput.tagName === 'INPUT') {
      passwordInput.type = this.passwordVisible ? 'text' : 'password';
    }
    if (iconElement && iconElement.tagName === 'SPAN') {
      iconElement.className = this.passwordVisible ? 'fa fa-eye' : 'fa fa-eye-slash';
    }
  }
}
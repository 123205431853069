/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { PatientModel } from '../patientModel';
import { CategoryModel } from '../CategoryModel';

export class CaseModel {
    id: number;
    case_type: string;
    case_status: string;
    importance:  string;
    earliest_available_date: Date;
    patient_diagnosis: string;
    interpreter_needed: boolean;
    is_vip: boolean;
    translator_needed: boolean;
    case_additional_note: string;
    case_careteam_note: string;
    case_patient_note: string;
    reason_additional_note: string;
    md_recommendation_field: string;
    agreement: boolean;
    md_additional_note: string;
    case_identifier: string;
    written_summary: string;
    video_consultation: string;
    video_mp4: string;
    status_reason: string;
    video_notes: string;
    checked_final_report: boolean;
    report_notes: string;
    comment_summary: string;
    missing_information: string;
    date_mr_complete: Date;
    is_mr_complete: boolean;
    is_submitted: boolean;
    is_save: boolean;
    submitted_request_to_consult: number;
    turn_around_time: number;
    consult_complete_to_final_report_submit: number;
    last_hold_on: Date;
    total_hold_time: number;
    is_email_sent: boolean;
    preferred_communication_mode: string;
    best_communication_time: string;
    phone_number: string;
    allyCaseNumber: string;
    allyCaseRef: string;
    summary_history_of_present_illness: string;
    summary_past_medical_history: string;
    summary_past_surgical_history: string;
    summary_family_history: string;
    summary_key_studies_and_intervention: string;
    summary_social_history: string;
    summary_allergies: string;
    summary_medications: string;
    summary_files_summarized: string;
    summary_case_summary: string;
    summary_state: string;
    speciality_program: string;
    surgical_intervention: string;
    surgical_intervention_note: string;
    surgical_recommendation: string;
    surgical_recommendation_note: string;
    is_missing_info_addressed: boolean;
    peer_to_peer_details: any;
    case_owner_id: number;
    patient_id: number;
    patient: PatientModel;
    category: CategoryModel;

    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    deleted_by: number;

    static CASE_STATUS = {
      REQUESTED: 'Active',
      CANCELLED: 'Cancelled',
      FINISHED: 'Finished',
      HOLD: 'On Hold'
    };


    constructor(data) {
      this.id = data.id;
      this.case_type = data.case_type;
      this.case_status = data.case_status;
      this.importance = data.importance;
      this.earliest_available_date = data.earliest_available_date;
      this.patient_diagnosis = data.patient_diagnosis;
      this.interpreter_needed = data.interpreter_needed;
      this.is_vip = data.is_vip;
      this.translator_needed = data.translator_needed;
      this.case_additional_note = data.case_additional_note;
      this.case_careteam_note = data.case_careteam_note;
      this.case_patient_note = data.case_patient_note;
      this.reason_additional_note = data.reason_additional_note;
      this.md_recommendation_field = data.md_recommendation_field;
      this.agreement = data.agreement;
      this.md_additional_note = data.md_additional_note;
      this.case_identifier = data.case_identifier;
      this.written_summary = data.written_summary;
      this.video_consultation = data.video_consultation;
      this.video_mp4 = data.video_mp4;
      this.status_reason = data.status_reason;
      this.video_notes = data.video_notes;
      this.checked_final_report = data.checked_final_report;
      this.report_notes = data.report_notes;
      this.comment_summary = data.comment_summary;
      this.missing_information = data.missing_information;
      this.date_mr_complete = data.date_mr_complete;
      this.is_mr_complete = data.is_mr_complete;
      this.is_submitted = data.is_submitted;
      this.is_save = data.is_save;
      this.submitted_request_to_consult = data.submitted_request_to_consult;
      this.turn_around_time = data.turn_around_time;
      this.consult_complete_to_final_report_submit = data.consult_complete_to_final_report_submit;
      this.last_hold_on = data.last_hold_on;
      this.total_hold_time = data.total_hold_time;
      this.is_email_sent = data.is_email_sent;
      this.preferred_communication_mode = data.preferred_communication_mode;
      this.best_communication_time = data.best_communication_time;
      this.phone_number = data.phone_number;
      this.allyCaseNumber = data.allyCaseNumber;
      this.allyCaseRef = data.allyCaseRef;
      this.surgical_intervention = data.surgical_intervention;
      this.surgical_intervention_note = data.surgical_intervention_note;
      this.surgical_recommendation = data.surgical_recommendation;
      this.surgical_recommendation_note = data.surgical_recommendation_note;
      this.is_missing_info_addressed = data.is_missing_info_addressed;
      this.peer_to_peer_details = data.peer_to_peer_details;
      this.case_owner_id = data.case_owner_id;
      this.patient = data.patient as PatientModel;
      this.category = data.category && new CategoryModel(data.category);
      this.created_at = data.created_at;
      this.updated_at = data.updated_at;
      this.deleted_at = data.deleted_at;
      this.deleted_by = data.deleted_by;
    }

    get isAllyCase(): boolean {
      return !!this.allyCaseRef;
    }

    get PatientName(): string {
      return this.patient?.full_name || 'N/A';
    }

    get PatientPartner(): string {
      return this.patient?.user_partners[0]?.partner?.partner_name || 'N/A';
    }

    get CategoryName(): string {
      return this.category?.category || 'N/A';
    }

    get CaseStatus(): string {
      return CaseModel.CASE_STATUS[this.case_status] || 'N/A';
    }
}
/* eslint-disable no-undef */
/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { filter, isNil } from 'lodash';
import { TreeviewI18n, TreeviewItem, TreeviewConfig, DropdownTreeviewComponent, TreeviewHelper } from 'ngx-treeview';
// import { DropdownTreeviewSelectI18n } from './dropdown-treeview-select-i18n';

@Component({
  selector: 'client-dropdown-tree-view',
  templateUrl: './client-dropdown-tree-view.html',
  styleUrls: [],
//   providers: [
//     { provide: TreeviewI18n, useClass: DropdownTreeviewSelectI18n }
//   ]
})
export class ClientDropdownTreeView implements OnChanges {
  @Input() config: TreeviewConfig;
  @Input() items: TreeviewItem[];
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();
  @Output() resetClients = new EventEmitter<any>();
  @ViewChild(DropdownTreeviewComponent, { static: false }) dropdownTreeviewComponent: DropdownTreeviewComponent;
  filterText: string;
  //private dropdownTreeviewSelectI18n: DropdownTreeviewSelectI18n;

  constructor(
    public i18n: TreeviewI18n
  ) {
    this.config = TreeviewConfig.create({
      hasAllCheckBox: true,
      hasCollapseExpand: false,
      hasFilter: true,
      maxHeight: 400,
    });
    // this.dropdownTreeviewSelectI18n = i18n as DropdownTreeviewSelectI18n;
  }

  onSelectedChange(value: number): void {
    this.valueChange.emit(value);
  }

  ngOnChanges(): void {
    this.updateSelectedItem();
  }

  select(item: TreeviewItem): void {
    if (!item.children) {
      this.selectItem(item);
    }
  }

  private updateSelectedItem(): void {
    if (!isNil(this.items)) {
      const selectedItem = TreeviewHelper.findItemInList(this.items, this.value);
      this.selectItem(selectedItem);
    }
  }

  private selectItem(_item: TreeviewItem): void {
    // if (this.dropdownTreeviewSelectI18n.selectedItem !== item) {
    //   this.dropdownTreeviewSelectI18n.selectedItem = item;
    //   if (this.dropdownTreeviewComponent) {
    //     this.dropdownTreeviewComponent.onSelectedChange([item]);
    //   }

    //   if (item) {
    //     if (this.value !== item.value) {
    //       this.value = item.value;
    //       this.valueChange.emit(item.value);
    //     }
    //   }
    // }
  }

  getIndeterminateChecked(item) {
    if (item.children) {
      const selectedChilds = filter(item.children, (subItem) => subItem.checked);
      if (selectedChilds.length === item.children.length) {
        return false;
      }

      if (selectedChilds.length > 0) {
        return true;
      }
    }

    return false;
  }

  onFilterTextChange(text: string): void {
    console.log('text', text);
  }
  
  reset() {
    this.resetClients.emit();
  }

  getAllCheck(item) {
    console.log('item', item);
  }
}
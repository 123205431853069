/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CaseService } from '../../services/case.service';
import { CaseAssignmentService } from '../../services/case-assignment.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { FileUploader } from 'ng2-file-upload';
import { ConfigAssetLoaderService } from '../../services/config-asset-loader.service';
import { CommonService } from '../../services/common.service';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { CommonFuntions } from '../../common/common.function';
import { PopUpService } from '../../services/pop-up.service';
import {
  CASE_ASSIGNMENT_STATUS,
  CASE_STATUS_TYPE,
  CASE_STAGE_ID,
} from '../../constant';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-translator-case',
  templateUrl: './translator-case.component.html',
  styleUrls: ['./translator-case.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TranslatorCaseComponent implements OnInit {
  @Input() caseAssignmentId = 0;
  case_questions: any[];
  isQuestionCollapse = true;
  isFollowUpQuestionCollapse = true;
  maxFollowUpId: number;
  profilePicPath: string;
  caseId: number;
  case: any;
  showSpinner = true;
  uploader: FileUploader;
  commentImageUploader: FileUploader;
  files = {};
  @ViewChild('document', { static: true }) inputDocument;
  @ViewChild('ckeditorField', { static: true }) ckeditor: any;
  @ViewChild('documentType', { static: true }) inputDocumentType;
  uploadingFiles = [];
  countIndex = 0;
  selectedFileName: string;
  comment: any = {
    message: '',
    case_id: 0,
    from_user_id: 0,
    to_user_id: 0,
  };
  role: string;
  isDocumentCollapsed = false;
  caseDocumentType = [
    {
      key: 'Select',
      value: 'SELECT',
    },
  ];
  frmAssignee: FormGroup;
  validateAssignee = false;
  finalHistory = [];
  assigneeList = [];
  finishedList = [];
  caseMessages = [];
  token = localStorage.getItem('token');
  userId: number;
  patientGender: String;
  patientAge: number;
  hideDeleteUserBox = true;
  selectedRejectReason = '';
  disableComponent = false;
  showIntakeForm = false;
  showAcceptDeclineButton = false;
  showDeclineButton = false;
  showMessage = false;
  caseAssignments: {};

  constructor(
    private caseService: CaseService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private loginService: LoginService,
    private popUpService: PopUpService,
    public caseAssignmentService: CaseAssignmentService
  ) {
    this.profilePicPath = '/assets/images/profile-pic-small.jpg';
    this.caseId = +this.activatedRoute.snapshot.paramMap.get('caseid');
    this.commentImageUploader = new FileUploader({
      url: ConfigAssetLoaderService.getApiURL() + '/comment/image',
      itemAlias: 'image',
      authToken: 'Bearer ' + this.token,
    });
    this.uploader = new FileUploader({
      url: ConfigAssetLoaderService.getApiURL() + '/translated/upload',
      itemAlias: 'document',
      authToken: 'Bearer ' + this.token,
      maxFileSize: 10 * 1024 * 1024,
    });
    this.case_questions = [];
    this.maxFollowUpId = null;
  }

  ngOnInit(): void {
    //detech param change
    this.hideDeleteUserBox = true;
    this.activatedRoute.params.subscribe((params) => {
      this.showSpinner = true;
      this.caseId = params['caseid'];
      this.getCaseById(this.caseId);
    });

    this.getUploader();
    this.fetchCaseQuestions();
    this.setUploaderProperties(this.uploader, this.files);

    this.commentImageUploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    this.frmAssignee = new FormGroup({
      message: new FormControl(),
    });

    this.frmAssignee.addControl(
      'user_case_messages',
      new FormArray([], CommonFuntions.fomrArrayMinLength(1))
    );

    const user = this.loginService.getCurrentUser();
    this.userId = user.user_id;

    if (!this.caseAssignments) this.getDocumentType(user.user_type.type);

    this.role = user.user_type.type.toLowerCase();

    this.markOldComment(this.caseId, user.user_id);
    this.changeCaseAssignmentStatus();

    this.commentImageUploader.onCompleteItem = function (
      _item: any,
      response: any,
      status: any,
      _headers: any
    ) {
      if (status && status == 200) {
        const urlvalue = JSON.parse(response).url;
        this.addImageToCK(urlvalue);
      }
    }.bind(this);
  }

  private shouldComponentBeDisabled(
    caseStatus: string,
    statusList: any[]
  ): boolean {
    let check = false;

    const COMPILE_MR_STAGE = statusList.find(
      (each) => each?.status_id === CASE_STAGE_ID.COMPILE_MR
    );

    if (
      [
        CASE_STATUS_TYPE.FINISHED,
        CASE_STATUS_TYPE.CANCELLED,
        CASE_STATUS_TYPE.HOLD,
      ].includes(caseStatus) ||
      (statusList.length &&
        COMPILE_MR_STAGE &&
        COMPILE_MR_STAGE?.state.toUpperCase() === CASE_STATUS_TYPE.FINISHED)
    ) {
      check = true;
    }

    return check;
  }

  private changeCaseAssignmentStatus() {
    const payload = {
      caseId: this.caseId,
      status: CASE_ASSIGNMENT_STATUS.IN_REVIEW,
      comments: '',
    };
    this.caseAssignmentService.acceptRejectCase(payload).subscribe(
      (_result) => {
        console.log(_result);
        this.maxFollowUpId = _result.follow_up_id;
      },
      (_error) => {
        console.log(_error);
      }
    );
  }

  getDocumentType(userType: string): void {
    this.commonService.getCaseDocumentType(userType).subscribe((response) => {
      const typesList = [
        {
          key: 'Select',
          value: 'SELECT',
        },
      ];
      this.caseDocumentType = typesList.concat(response);
    });
  }

  fetchCaseQuestions(): void {
    this.caseService
      .getCaseQuestions(this.caseId, this.caseAssignmentId)
      .subscribe((result) => {
        this.case_questions.push(...result.case_questions);
        this.case_questions.forEach((item) => {
          if (item.follow_up_id > this.maxFollowUpId) {
            this.maxFollowUpId = item.follow_up_id;
          }
        });
      });
  }

  acceptCase(event: Event, action: string): void {
    if (event) event.preventDefault();

    const confirmDialog = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.destoryConfirmDialog();
    this.popUpService.setDialogParams(
      'Case Confirmation',
      'Are you sure you want to ACCEPT the case ' + this.case.case_identifier + ' ?',
      'Yes',
      'No',
      function () {
        const payload = {
          caseId: this.caseId,
          comments: this.selectedRejectReason,
          status: CASE_ASSIGNMENT_STATUS.ACCEPTED,
        };
        this.caseAssignmentService.acceptRejectCase(payload).subscribe(
          function (_result) {
            const { dueDate } = _result;
            this.case.summarizer_due_date = dueDate;

            this.showAlert(action + '  successful.' ,function (): void {
              window.location.reload();
              this.popUpService.hideDiv('alertDialog');
            }.bind(this));
         
          }.bind(this),
          function (err) {
            this.showAlert(`${err.error.message}.`);
          }.bind(this)
        );

        this.popUpService.hideDiv(confirmDialog);
      }.bind(this),
      function () {
        this.hideDiv(confirmDialog);
      }
    );
    this.popUpService.showDiv(confirmDialog);
  }

  rejectCase(event: Event): void {
    if (event) event.preventDefault();
    this.hideDeleteUserBox = false;
  }

  handleValueSubmit = (value): void => {
    this.selectedRejectReason = value.reject_reason
      ? value.reject_reason
      : value.reject_type;
    const payload = {
      caseId: this.caseId,
      comments: this.selectedRejectReason,
      status: CASE_ASSIGNMENT_STATUS.DECLINED,
    };
    this.caseAssignmentService.acceptRejectCase(payload).subscribe(
      function () {
        // this.showAlert('Case rejected sucessfully.');
        this.router.navigate(['/dashboard']);
      }.bind(this),
      function (err) {
        this.showAlert(`${err.error.message}.`);
      }.bind(this)
    );
    this.hideDeleteUserBox = false;
  };

  getCaseDocuments(): void {
    this.caseService
      .getCaseDocuments(this.caseId, this.caseAssignmentId)
      .subscribe((case_documents) => {
        this.case['final_reports'] = case_documents.final_reports;
        this.case['document_for_download'] =
          case_documents.document_for_download;
        this.case['translated_document'] = case_documents.translated_document;
      });
  }

  markOldComment(_id: number, user_id: number): void {
    this.caseService
      .markOldComment(this.caseId, user_id)
      .subscribe((_result) => {});
  }

  toggleDeleteTemplate(value: boolean): void {
    this.hideDeleteUserBox = value;
  }

  constructCaseHistory(): void {
    // this.finalHistory = [];
    this.finalHistory = this.caseMessages.concat(this.finishedList);
    // if (this.case.case_status.toLowerCase() == "hold") {
    this.finalHistory = this.finalHistory.concat(this.case);
    // }
    this.finalHistory.sort(function (obj1, obj2) {
      const dateA = new Date(obj1.updated_at),
        dateB = new Date(obj2.updated_at);
      return dateB.getTime() - dateA.getTime();
    });
  }

  getCaseById(_id: number): void {
    const currentUser = this.loginService.getCurrentUser();

    this.caseService.getCaseById(this.caseId, currentUser.user_id).subscribe(
      (retrievedCase) => {
        this.case = retrievedCase;
        this.disableComponent = this.shouldComponentBeDisabled(
          this.case?.case_status,
          this.case?.status
        );
        this.constructCaseHistory();
        this.getCaseDocuments();
        this.patientGender =
          this.case.patient.gender === 'M'
            ? 'Male'
            : this.case.patient.gender === 'F'
              ? 'Female'
              : 'Unspecified';
        this.patientAge = moment().diff(
          moment(this.case.patient.user.dob),
          'years'
        );
        // eslint-disable-next-line max-len
        this.caseAssignments = this.case?.case_assignments?.find(
          (value) =>
            value.user_id === currentUser.user_id && value.status !== 'ACCEPTED'
        )
          ? this.case?.case_assignments?.find(
            (value) =>
              value.user_id === currentUser.user_id &&
                value.status !== 'ACCEPTED'
          )
          : null;
        // eslint-disable-next-line max-len
        const caseAssignmentCheck = this.case?.case_assignments?.find(
          (value) =>
            value.user_id === currentUser.user_id && value.status !== 'ACCEPTED'
        )
          ? this.case?.case_assignments?.find(
            (value) =>
              value.user_id === currentUser.user_id &&
                value.status !== 'ACCEPTED'
          )
          : null;
        // eslint-disable-next-line max-len
        const isAcceptedCheck = this.case?.case_assignments?.find(
          (value) =>
            value.user_id === currentUser.user_id &&
            value.status === 'ACCEPTED' &&
            this.caseId == value.case_id
        )
          ? this.case?.case_assignments?.find(
            (value) =>
              value.user_id === currentUser.user_id &&
                value.status === 'ACCEPTED' &&
                this.caseId == value.case_id
          )
          : null;
        if (!caseAssignmentCheck) {
          this.showAcceptDeclineButton = false;
          this.showDeclineButton = false;
          this.showIntakeForm = false;
          this.showMessage = true;
        }

        if (!caseAssignmentCheck && isAcceptedCheck?.status === 'ACCEPTED') {
          this.showDeclineButton = true;
          this.showAcceptDeclineButton = false;
          this.showIntakeForm = true;
          this.showMessage = true;
        }
        if (
          caseAssignmentCheck?.status === 'DRAFT' ||
          caseAssignmentCheck?.status === 'IN REVIEW'
        ) {
          this.showDeclineButton = true;
          this.showAcceptDeclineButton = true;
          this.showMessage = true;
        }
      },

      function (error) {
        let message = 'Something went wrong.';

        if (error && error.status) {
          if (error.status == 401) {
            message = 'This case cannot be accessed.';
          }
          if (error.status == 400) {
            message = error.error.message;
          }
        }

        const action = function () {
          this.popUpService.hideDiv('alertDialog');
          this.router.navigate(['/case']);
        }.bind(this);

        this.showAlert(message, action);
        return null;
      }.bind(this),
      () => {
        this.showSpinner = false;
      }
    );
  }

  setUploaderProps(event: any) {
    this.setUploaderProperties(event.uploader, event.file);
  }

  setUploaderProperties(uploader: FileUploader, _files?: any) {
    uploader.onAfterAddingFile = function (fileItem) {
      if (uploader.queue.length > 1) {
        uploader.queue.splice(0, 1);
      }
      fileItem.withCredentials = false;
      this.selectedFileName = fileItem.file.name;
      this.files['size'] = fileItem.file.size / 1024;
    }.bind(this);

    uploader.onWhenAddingFileFailed = function (_item, filter, _options) {
      switch (filter.name) {
      case 'mimeType':
        this.inputDocument.nativeElement.value = '';
        this.showAlert('Only pdfs can be uploaded.');
        break;
      case 'fileSize':
        this.inputDocument.nativeElement.value = '';
        this.showAlert(
          'Files to be uploaded must be less than of size 50 MB.'
        );
        break;
      default:
        break;
      }
    }.bind(this);

    uploader.onCompleteItem = function (_item, response, status, _header) {
      if (response == null || response === '') {
        return;
      }

      if (status !== 200) {
        if (response == null || response === '') {
          return;
        }

        const error = JSON.parse(response);
        let alertText = null;
        if (!error.msg) {
          alertText = 'Failed to upload file.';
        }
        else {
          alertText = `${error.msg}.`;
        }
        this.showAlert(alertText);
        return;
      }

      const responseObj = JSON.parse(response);
      responseObj.size = Math.ceil(+responseObj.size);
      this.case.translated_document.push(responseObj);
    }.bind(this);

    uploader.onProgressAll = function (_progress) {}.bind(this);

    uploader.onCancelItem = function (_item, _response, _status, _header) {};

    uploader.onWhenAddingFileFailed = function (_item, filter, _option) {
      switch (filter.name) {
      case 'mimeType':
        this.inputDocument.nativeElement.value = '';
        this.showAlert('System does not support this type of file format.');
        break;
      case 'fileSize':
        this.inputDocument.nativeElement.value = '';
        this.showAlert(
          'Files to be uploaded must be less than of size 50 MB.'
        );
        break;
      default:
        break;
      }
    }.bind(this);
  }

  getUploader(): void {
    this.uploader = new FileUploader({
      url: ConfigAssetLoaderService.getApiURL() + '/translated/upload',
      itemAlias: 'document',
      authToken: 'Bearer ' + this.token,
      maxFileSize: 50 * 1024 * 1024,
      allowedMimeType: ['application/pdf'],
    });
  }

  uploadDocs(event): void {
    event.preventDefault();
    const docType = this.inputDocumentType.nativeElement.value;
    if (this.uploader.getNotUploadedItems().length && docType !== 'SELECT') {
      this.uploader.setOptions({
        url:
          ConfigAssetLoaderService.getApiURL() +
          '/translated/upload' +
          '?caseid=' +
          this.caseId +
          '&type=' +
          docType,
        itemAlias: 'documents',
        authToken: 'Bearer ' + this.token,
      });
      this.uploader.uploadAll();
      this.files['type'] = docType;
      this.files['name'] = this.selectedFileName;
      this.files['uploader'] = this.uploader;
      this.uploadingFiles.push(this.files);
      this.files = {};
      this.getUploader();
      this.setUploaderProperties(this.uploader, {});
      this.inputDocument.nativeElement.value = null;
      this.inputDocumentType.nativeElement.value = 'SELECT';
    }
  }

  cancelUpload(files, event: Event) {
    event.preventDefault();
    const uploader = files.uploader;
    uploader.cancelAll();
  }

  deleteFile(document, event) {
    event.preventDefault();

    const confirmDialog = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.destoryConfirmDialog();
    this.popUpService.setDialogParams(
      'Delete Confimation',
      'Are you sure you want to delete ' +
        document.document_name +
        ' from the system',
      'Yes',
      'No',
      function () {
        this.caseService.deleteDocument(document.id).subscribe(
          function (_result) {
            this.showAlert(document.document_name + ' deleted sucessfully.');
            this.getCaseById(this.caseId);
          }.bind(this),
          function (err) {
            this.showAlert(`${err.error.message}.`);
          }.bind(this)
        );

        this.popUpService.hideDiv(confirmDialog);
      }.bind(this),
      function () {
        this.hideDiv(confirmDialog);
      }
    );
    this.popUpService.showDiv(confirmDialog);
  }

  get message() {
    return this.frmAssignee.get('message') as FormControl;
  }

  openDialog(inputField: HTMLInputElement) {
    inputField.addEventListener(
      'change',
      function () {
        this.uploadImage();
        inputField.value = null;
      }.bind(this)
    );
    inputField.click();
  }

  addImageToCK(url: string) {
    try {
      const img = this.ckeditor.instance.document.createElement('img');
      img.setAttribute('src', url);

      this.ckeditor.instance.insertElement(img);
    }
    catch (error) {}
  }

  uploadImage() {
    if (this.commentImageUploader.getNotUploadedItems().length) {
      this.commentImageUploader.uploadAll();
    }
  }

  public showAlert(message: string, action?: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams('Alert', message, 'Ok');

    if (action) {
      this.popUpService.setPositiveAction(action);
    }
    else {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) {
      this.popUpService.showDiv(dialog);
    }
  }

  public notifyDocumentChanges() {
    this.getCaseDocuments();
  }

  isPeerToPeer() {
    if (this.case?.reason && this.case?.reason.reason.includes('Peer to Peer'))
      return true;
    if (
      this.case?.speciality_program &&
      this.case?.speciality_program.includes('Peer to Peer')
    )
      return true;

    return false;
  }

  stylingCondition() {
    // If already accepted, do not center           This condition below handles if Decline is gone, code uses the 2 variables
    if (
      !this.showAcceptDeclineButton ||
      !this.showDeclineButton ||
      !this.showAcceptDeclineButton
    )
      return '';

    const isP2P = this.isPeerToPeer();
    const isFollowUp = this.maxFollowUpId > 0;

    if (isFollowUp && isP2P) return '';

    if (isFollowUp || isP2P) return 'center__badge';

    return '';
  }
}


/* eslint-disable max-len */
/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../services/userservice.service';
import { UserModel } from '../../../domains/userModel';
import { CommonService } from '../../../services/common.service';
import { Contact } from '../../../domains/contactModel';
import { LoginService } from '../../../services/login.service';
import { CaseService } from '../../../services/case.service';
import { PopUpService } from '../../../services/pop-up.service';
import { CommonFuntions } from '../../../common/common.function';
import { get, orderBy } from 'lodash';
import {  FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import {  USER_ROLES_CHANGE, USER_ROLES_CHANGE_LIST } from '../../../../app/constant';

const rolePath = {
  1: 'admin-role',
  2: 'imd-careteam-role',
  3: 'client-role',
  4: 'doctor-role',
  5: 'patient-role',
  6: 'translator-role',
  7: 'interpreter-role',
  8: 'md-manager-role',
  10: 'cm-manager-role',
  11: 'case-coordinator-role'
};

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class UserProfileComponent implements OnInit {
  avatarimage: any;
  @ViewChild('confirmUserInactiveSwal') private confirmUserInactiveSwal: SwalComponent;
  @ViewChild('continueCaseSwal') private continueCaseSwal: SwalComponent;
  profilePicPath = '/assets/images/profile-pic-male.png';
  userType = '';
  userId: number;
  userTypeMap: Map<number, string>
  sub: any;
  weChatIds: Contact[];
  user: any;
  currentUser: any;
  caseDetail;
  isSettings: any;
  associatedPartner = [];
  partnerNameList = [];
  currentRoleForm: FormGroup;
  showButtons = false;
  loading=true
  CopyText  = 'Click to Copy';
  showAllyRef = false;
  showNonAllyClients = false;
  showAllyClients = false;
  showUserRoles= false;
  allAllyClients = [];
  allNonAllyClients = [];
  hideUserDeleteTemplate = true;
  role: any;
  deleteUserForm: FormGroup;
  userRoleList: any[];
  userToEdit : any;
  userRoleLength  = 0;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private loginService: LoginService,
    private commonService: CommonService,
    private caseService: CaseService,
    private popUpService: PopUpService,
    private toastr :ToastrService,
    public readonly swalTargets: SwalPortalTargets,
    private fb: FormBuilder
  ) {
    
  }
  get delete_reason(): FormControl {
    return this.deleteUserForm.get('delete_reason') as FormControl;
  }

  getUserCaseList(_userType): void {
    if(_userType !== 'doctor' && _userType !== 'translator'){
      this.caseService.getAssignedCasesByUserId(this.userId).subscribe(
        result => {
          this.caseDetail = result?.data || {};
        }
      );
    }
  }

  initializeForm(): FormGroup {
    this.currentRoleForm = this.fb.group({
      new_user_role: ['', Validators.required]
    });


    return this.fb.group({
      delete_reason: ['', Validators.compose([Validators.required, Validators.maxLength(50000) ])]
    });
  }
  ngOnInit(): void {
    this.loading=true;
    this.userTypeMap = new Map();
    this.deleteUserForm = this.initializeForm();
    this.sub = this.route.params
      .subscribe(params => {
        if (isNaN(params['id'])) {
          this.router.navigate(['/page-not-found']);
          return;
        }

        this.userId = +params['id'];

        this.commonService.getUserType().subscribe(
          result => {
            for (const usertype of result) {
              this.userTypeMap.set(usertype['user_type_id'], usertype['type']);
            }
          }
        );

        this.currentUser = this.loginService.getCurrentUser();

        if (this.currentUser.user_type.type.toLowerCase() === 'admin' || this.currentUser.user_type.type.toLowerCase() === 'careteam' || this.currentUser.user_type.type.toLowerCase() === 'casecoordinator' || this.currentUser.user_type.type.toLowerCase() === 'cmmanager') {
          this.showButtons = true;
          this.showAllyRef = true;
        }

        if (['admin', 'cmmanager'].includes(this.currentUser.user_type.type.toLowerCase())) {
          this.isSettings = true;
        }
        else {
          this.isSettings = false;
        }

        this.role = this.currentUser.user_type.type.toLowerCase();
        if(CommonFuntions.getPartnersForAuthorizedUser(this.role)) {
          this.commonService.getPartners().subscribe(
            function (partners): void {
              const user = this.loginService.getCurrentUser();
              if (user && user != null) {
                partners.forEach(
                  partner => {
                    const partnerObj = {};
                    partnerObj['partner_id'] = partner.partner_id;
                    partnerObj['partner_name'] = partner.partner_name;
                    this.partnerNameList.push(partner.partner_name);
                  }
                );
                // fetch user details
                this.getUserDetails();
              }
            }.bind(this),
            () => {
              this.getUserDetails();
            }
          );
        }
      });
    this.loading=false;
  }

  checkUserRole(userPartner: any): void{
    
    if(userPartner.user?.role == 'Care Team'){
      this.userRoleList.push(USER_ROLES_CHANGE.CARE_TEAM.CASE_COORDINATOR);
      this.userRoleList.push(USER_ROLES_CHANGE.CARE_TEAM.CM_MANAGER);
    }
    else if(userPartner.user?.role == 'case coordinator'){
      this.userRoleList.push(USER_ROLES_CHANGE.CASE_COORDINATOR);
    }
    else if(userPartner.user?.role == 'client care team'){
      this.userRoleList.push(USER_ROLES_CHANGE.CLIENT_CARE_TEAM);
    }
    else if(userPartner.user?.role == 'CM Manager'){
      this.userRoleList.push(USER_ROLES_CHANGE.CM_MANAGER);
    }
  }


  changeUserRolesList(user_type_id){
    const userTypeIDs : number[] = [2, 3, 10, 11];
    
    if(userTypeIDs.includes(user_type_id)){
      this.userRoleList = USER_ROLES_CHANGE_LIST.filter(v => v.User_Type_Id != user_type_id);
      this.userRoleLength = this.userRoleList.length;
    }
  }

  getUserDetails() {
    this.userService.getUser(this.userId).subscribe(
      result => {
        this.userToEdit = result;
        this.changeUserRolesList(result.user_type_id);
        if (result.user_partners) {
          //this.checkUserRole(result.user_partners);
          // order by partner name
          result.user_partners = orderBy(result.user_partners, ['partner.partner_name'], ['asc']);
          result.user_partners.forEach(partnerItem => {
            if (get(partnerItem, 'partner.allyPartnerRef')) {
              this.allAllyClients.push(get(partnerItem, 'partner.partner_name'));
            }
            else {
              this.allNonAllyClients.push(get(partnerItem, 'partner.partner_name'));
            }
          });
        }

        this.user = result as UserModel;

        this.userType = this.userTypeMap.get(this.user.user_type_id);
        this.user['type'] = this.userTypeMap.get(this.user.user_type_id);

        // fetc case list.
        this.getUserCaseList(this.userType.toLowerCase());
        
        this.user['type'].toLowerCase() === 'patient' ? this.showButtons = true : '';
        this.user['type'].toLowerCase() === 'partner' ? this.showButtons = true : '';
        this.commonService.getSocialTypes().subscribe(
          function (types) {
            const contactType = types;
            contactType.forEach(element => {
              element['contacts'] = [];
            });

            if (this.user.contacts == null) {
              this.user.contacts = [];
            }

            this.user.contacts.forEach(function (element) {

              const groupedContact = this.contactType.find(
                (obj) => obj.social_type_id === element.social_type_id
              );
              groupedContact.contacts.push(element);
            }.bind(
              {
                contactType: contactType
              }
            ));

            this.user['contactType'] = contactType;

            this.user.contactType = this.user.contactType.filter(
              function (type) {
                return type.contacts.length > 0;
              }
            );
          }.bind(
            {
              user: this.user
            }
          )
        );
        
        if (result.avatar) {
          this.getUserProfile(result.avatar);
        }

        if (!['admin', 'careteam', 'cmmanager', 'partner', 'mdmanager'].includes(this.currentUser.user_type.type.toLowerCase())) {
          this.showButtons = false;
        }

      },
      function (error) {
        if (error.status == 403) {
          this.router.navigate(['/']);
        }
      }.bind(this)
    );
  }


  /** Function to fetch the profile picture of the user using the avatar location */
  getUserProfile(avatar: string): void {
    this.loading = true;
    this.userService.getUploadedFile(avatar).subscribe(
      result => {
        const { image } = result;
        this.avatarimage = image;
        this.loading = false;
      },
      (_error) => {
        this.loading = false;
      }
    );
  }
  
  copyText(val: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.CopyText = 'Copied';
  }

  toggleUserDeleteTemplate(value: boolean): void {
    this.hideUserDeleteTemplate = value;
  }

  activateUserDeleteTemplate(event: Event): void {
    if (event) event.preventDefault();
    this.hideUserDeleteTemplate = false;
  }



  validReason():void {
    const minLength = 3;
    const maxLength = 5000;
    if (this.confirmUserInactiveSwal === undefined) { return; }
    this.confirmUserInactiveSwal.inputValidator = (value) => {
      return new Promise<any>((resolve) => {
        if (value.length >= minLength && value.length <= maxLength) {
          this.deleteUserForm.controls['delete_reason'].setValue(value);
          this.continueCaseSwal.fire();
        }
        else if(value.length >= maxLength){
          resolve('This field should should not have more than 50,000 characters !');
        }
        else {
          resolve('The description should be at least 3 characters!');
        }
      });
    };
  }


  deleteUser(reason: string): void {
    if (!CommonFuntions.guardForAction(this.loginService, this.router)) {return;}

    const message = 'Do you want to inactivate \'' + this.user.full_name + '\' from the system?';
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Inactivate Confirmation',
      message,
      'Yes',
      'Cancel',
      function () {
        this.userService.deleteUser(this.userId, reason).subscribe(
          function (result) {
            if (result) {
              const routePath = rolePath[this.user.user_type_id];
              const message = '\'' + this.user.full_name + '\' has been successfully inactivated from the system';
              this.showAlert(message, function () {
                this.router.navigate([`/${routePath}`]);
                this.popUpService.hideDiv('alertDialog');
                this.hideUserDeleteTemplate = true;
              }.bind(this));

            }
            else {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const message = 'Failed to inactivate \'' + this.user.full_name + '\' from the system';
            }
          }.bind(this), error => {
            const serverError = error.error ? error.error : error;
            this.showAlert(serverError.message, function () {
              this.popUpService.hideDiv('confirmDialog');
              this.popUpService.hideDiv('alertDialog');
            }.bind(this));
          }
        );
      }.bind(this),
      function () {
        this.hideDiv('confirmDialog');
      }
    );

    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);
  }


  deleteModalUser(): void {

    this.userService.deleteUser(this.userId, this.delete_reason.value).subscribe((res)=>{
      if (res) {
        const routePath = rolePath[this.user.user_type_id];
        const message = this.user.full_name +' has been successfully inactivated from the system';
        this.toastr.success(message);
        this.router.navigate([`/${routePath}`]);
      }
      else {
        const message = 'Failed to inactivate ' + this.user.full_name +' from the system';
        this.toastr.error(message);
      }
    },(error)=>{
      this.toastr.error(error['error'].message);
    });
  }


  restoreUserModal(){
    this.userService.restoreUser(this.userId).subscribe((data) =>{
      if (data) {
        console.log('data');
        const routePath = rolePath[this.user.user_type_id];
        const message = '\'' + this.user.full_name + '\' has been successfully activated from the system';
        this.toastr.success(message);
        this.router.navigate([`/${routePath}`]);
      }
      else {
       
        const message = 'Failed to activate \'' + this.user.full_name + '\' in the system';
        this.toastr.error(message);
    
      }
    },(error)=>{
      this.toastr.error(error['error'].message);
    });
  
  }

  public checkPartnerActivity(event: Event) {
    event.preventDefault();
    if (this.user.user_partners[0].partner.active == false) {
      this.showAlert('Client is not active. Please contact administrator to activate client.');
    }
    else {
      this.router.navigate(['/case/create/', this.user.user_id]);
    }
  }

  public showAlert(message: string, positiveAction?: Function) {
    this.popUpService.destoryConfirmDialog();
    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );
    if (positiveAction) {
      this.popUpService.setPositiveAction(positiveAction);
    }
    else {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }
    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) {this.popUpService.showDiv(dialog);}
  }

  goToEditProfile() {
    const currentRole = this.currentUser.user_type.type.toLowerCase();
    this.router.navigate(['/edit', currentRole, this.currentUser.user_id, { isSettings: true }]);
  }
}
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit,Input } from '@angular/core';
import { UserModel } from '../../../domains/userModel';
import { ActivityModel } from '../../../domains/ActivityModel';
import { LoginService } from '../../../services/login.service';
import { UserService } from '../../../services/userservice.service';
import { ActivityService } from '../../../services/activity.service';
// import { ActivitycardComponent } from '../../../../app/cases/casehistory/activitycard/activitycard.component';
// eslint-disable-next-line @typescript-eslint/no-var-requires

@Component({
  selector: 'app-user-history',
  templateUrl: './user-history.component.html',
  styleUrls: ['./user-history.component.css']
})
export class UserHistoryComponent implements OnInit {
  @Input() userId: number;
  activities: ActivityModel[];
  showUserActivities = false;
  activityArray: any;
  user: any;
  currentUser: any;
  color: any;
  userActivitiesExists = false;
  constructor(
    private loginService: LoginService,
    private userService: UserService,
    public activityService: ActivityService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.loginService.getCurrentUser();
  }



  getActivities(): void {
    this.showUserActivities = !this.showUserActivities;
    if(this.showUserActivities === true){
      this.userService.getAllUserActivities(this.userId).subscribe(
        _result =>{
          if (!_result) {this.userActivitiesExists = false;}
          if (_result){
            this.userActivitiesExists = true;
            this.activities = _result.data.map((activity)=>{
              activity.user = activity.user as UserModel;
              return activity as ActivityModel;
            });
          }
        }
      );
    }
  }
}

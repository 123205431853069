<div class="header">
	<div class="header__page-name">
	  <h1 class="heading heading--main">Users - Admin</h1>
	</div>
	<div class="header__top-nav">
	  <!-- <app-topmenu (searchValueEmitter)="searchString($event)" [userList]="'careteam'" [searchValue]="searchValue"></app-topmenu> -->
	  <div class="top-menu" >

		<div class="top-menu__user-stat">
			<span>Active Users</span>
			<a *ngIf="activeUsers > 0" [routerLink]="['/active-users/admin']">{{ activeUsers }} Users</a>
			<span *ngIf="activeUsers == 0">{{ activeUsers }} Users</span>
			<span class="ml-3">Inactive Users</span>
			<a *ngIf="inactiveUsers > 0" [routerLink]="['/inactive-users/admin']">{{ inactiveUsers }} Users</a>
			<span *ngIf="inactiveUsers == 0">{{ inactiveUsers }} Users</span>
		</div>
	</div>
	</div>
  </div>
  <div class="wrapper--dash-content dash-content">
	<div *ngIf="errorString" class="message message--is-invalid">
	  {{errorString}}
	</div>
  
	<table datatable [dtOptions]="dtOptions" class="row-border hover" id="admin">
	</table>
  </div>
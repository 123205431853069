import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { MyDashboardComponent } from './my-dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';



@NgModule({
  declarations: [
    MyDashboardComponent
  ],
  imports: [
    CommonModule,
    DataTablesModule,
    SweetAlert2Module,
    SharedModule,
    FormsModule
  ]
})
export class MyDashboardModule { }

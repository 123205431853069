/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/naming-convention */
import { EligibleMember } from '../domains/eligible-member.model';

import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { ConfigAssetLoaderService } from '../services/config-asset-loader.service';
import { UserModel } from '../domains/userModel';
import { LoginService } from './login.service';
import { DatePipe } from '@angular/common';
import { Observable, of } from 'rxjs';
import { DoctorProfileModel } from '../domains/doctorProfileModel';
import { CalenderSelectedModel } from '../domains/calenderSelectedModel';
import { DoctorModel } from '../domains/doctorModel';
import { DoctorCalenderModel } from '../domains/doctorCalenderModel';
import { DoctorAvailiabilityModel } from '../domains/doctorAvailiabilityModel';
import { tz } from 'moment-timezone';
import * as moment from 'moment/moment';
import { ToastrService } from 'ngx-toastr';

declare global {
	interface String {
		pick(min, max): string;
		shuffle(): string;
	}
}

@Injectable()
export class UserService {

	usertypeMap = new Map();

	constructor(
		private http: HttpClient,
		private loginService: LoginService,
		private toastrService :ToastrService
	) {
	}

	addEligibleMember(eligibleMember: EligibleMember): Observable<EligibleMember> {
	  const fullUrl = ConfigAssetLoaderService.getApiURL() + '/eligibleMember/create';
	  return this.http.post<EligibleMember>(fullUrl, eligibleMember, this.getHeaderWithToken(localStorage.getItem('token')));
	}

	getEligibleMember(userId: any): Observable<EligibleMember> {
	  const fullUrl = ConfigAssetLoaderService.getApiURL() + `/eligibleMember/${userId}`;
	  return this.http.get<EligibleMember>(fullUrl, this.getHeaderWithToken(localStorage.getItem('token'))).pipe(map(
	    (result) => result
	  ));
	}

	updateEligibleMember(userId: any, eligibleMember: EligibleMember) {
	  const fullUrl = ConfigAssetLoaderService.getApiURL() + `/eligibleMember/edit/${userId}`;
	  return this.http.put(fullUrl, eligibleMember, this.getHeaderWithToken(localStorage.getItem('token')));
	}

	deleteEligibleMember(userId: any, reason: string) {
	  const fullUrl = ConfigAssetLoaderService.getApiURL() + `/eligibleMember/delete/${userId}?reason=${reason}`;
	  return this.http.delete(fullUrl, this.getHeaderWithToken(localStorage.getItem('token'))).pipe(map(
	    (result) => result
	  ));
	}

	restoreEligibleMember(userId: any) {
	  const fullUrl = ConfigAssetLoaderService.getApiURL() + `/eligibleMember/restore/${userId}`;
	  return this.http.delete(fullUrl, this.getHeaderWithToken(localStorage.getItem('token'))).pipe(map(
		  (result) => result
	  ));
	  }

	verifyMember(email: string): any {
	  const fullUrl = ConfigAssetLoaderService.getApiURL() + '/verifyMember';
	  const emailObject = { email: email };
	  return this.http.post(fullUrl, emailObject);
	}

	memberRegistration(memberDetail: Object): any {
	  const fullUrl = ConfigAssetLoaderService.getApiURL() + '/member/register';
	  return this.http.post(fullUrl, memberDetail);
	}


	addUser(user: UserModel): Observable<UserModel> {
	  const fullUrl = ConfigAssetLoaderService.getApiURL() + '/user/create';
	  user.created_by = this.loginService.getCurrentUser().user_id;

	  const date = new DatePipe('en-US').transform(Date.now(), 'MM/dd/yyyy');
	  user.created_date = date;

	  return this.http.post<UserModel>(fullUrl,
	    user,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  );
	}

	updateUser(user: UserModel) {
	  delete user['active'];

	  if (user.hasOwnProperty('is_all_partners')) {
	    if (user['is_all_partners'] == null) { user['is_all_partners'] = 0; }
	  }

	  const fullUrl = ConfigAssetLoaderService.getApiURL() + '/user/edit/' + user.user_id;
	  return this.http.put(fullUrl,
	    user,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    (response: any) => {
	      if (response == null) {
	        // #shows the error response in a toastr when response is empty or have a null  
	        this.toastrService.error(response.msg);
	        return false;
	      }
	      else{
	        // #shows the success in a  toastr when response have some value  
	        this.toastrService.success(response.msg);
	      
		  }
		  // if passward changed then reset tokens
		  if (response && response.id_token) {
	        localStorage.setItem('token', response.id_token);
		  }
		  if (response && response.refresh_token && response.refresh_token != null) {
	        localStorage.setItem('refresh_token', response.refresh_token);
		  }
	      return true;
	    }
	  ));

	}

	getUser(userId: number): any {
	  return this.http.get(ConfigAssetLoaderService.getApiURL() + '/user/' + userId,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    (result: any) => {
	    //   delete result['force_password_reset'];

	      if (result === null) {
	        return new UserModel();
	      }
	      // uncomment to update partner list on every refresh
	      // if(result.user_partners){
	      //   localStorage.setItem('user_partners', JSON.stringify(result.user_partners));
	      // }
	      return result;
	    }
	  ));
	}

	getUserForPasswordReset(userId: number): any {
	  return this.http.get(ConfigAssetLoaderService.getApiURL() + '/user/' + userId + '/password/reset',
		  this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
		  (result: any) => {
	      if (result === null) {
			  return new UserModel();
	      }
		
	      return result;
		  }
	  ));
	  }
	getUserCount(role_id: number, userid: number): any {
	  return this.http.get(ConfigAssetLoaderService.getApiURL() + '/userListCount/' + role_id + '?userid=' + userid,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    (result) => result
	  ));
	}

	getDoctorEdit(userId: number): any {
	  const fullURL = ConfigAssetLoaderService.getApiURL() + '/user/' + userId;
	  return this.http.get(fullURL,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    (result) => {
	      if (result === null) {
	        return new DoctorModel();
	      }
	      return result;
	    }));
	}

	getDetailsEdit(userId: number): any {
	  const fullURL = ConfigAssetLoaderService.getApiURL() + '/user/doctorEditDetails/' + userId;
	  return this.http.get(fullURL,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    (result) => {
	      if (result === null) {
	        return new DoctorModel();
	      }
	      return result;
	    }));
	}

	getDetailsTitle(userId: number): any {
	  const fullURL = ConfigAssetLoaderService.getApiURL() + '/user/doctorEditTitle/' + userId;
	  return this.http.get(fullURL,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    (result) => {
	      if (result === null) {
	        return new DoctorModel();
	      }
	      return result;
	    }));
	}

	getDetailsMDType(userId: number): any {
	  const fullURL = ConfigAssetLoaderService.getApiURL() + '/user/doctorEditMDType/' + userId;
	  return this.http.get(fullURL,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    (result) => {
	      if (result === null) {
	        return new DoctorModel();
	      }
	      return result;
	    }));
	}

	getDetailsOrganSystem(userId: number): any {
	  const fullURL = ConfigAssetLoaderService.getApiURL() + '/user/doctorEditOrganSystem/' + userId;
	  return this.http.get(fullURL,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    (result) => {
	      if (result === null) {
	        return new DoctorModel();
	      }
	      return result;
	    }));
	}

	getDetailsDegree(userId: number): any {
	  const fullURL = ConfigAssetLoaderService.getApiURL() + '/user/doctorEditDegree/' + userId;
	  return this.http.get(fullURL,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    (result) => {
	      if (result === null) {
	        return new DoctorModel();
	      }
	      return result;
	    }));
	}

	getSubSpecialityDoctor(userId: number): any {
	  const fullURL = ConfigAssetLoaderService.getApiURL() + '/user/doctorEditSubSpeciality/' + userId;
	  return this.http.get(fullURL,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    (result) => {
	      if (result === null) {
	        return new DoctorModel();
	      }
	      return result;
	    }));
	}

	getDetailsProcedure(userId: number): any {
	  const fullURL = ConfigAssetLoaderService.getApiURL() + '/user/doctorEditProcedures/' + userId;
	  return this.http.get(fullURL,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    (result) => {
	      if (result === null) {
	        return new DoctorModel();
	      }
	      return result;
	    }));
	}

	getDetailsCertification(userId: number): any {
	  const fullURL = ConfigAssetLoaderService.getApiURL() + '/user/doctorEditCertification/' + userId;
	  return this.http.get(fullURL,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    (result) => {
	      if (result === null) {
	        return new DoctorModel();
	      }
	      return result;
	    }));
	}

	getDetailsConditions(userId: number): any {
	  const fullURL = ConfigAssetLoaderService.getApiURL() + '/user/doctorEditConditions/' + userId;
	  return this.http.get(fullURL,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    (result) => {
	      if (result === null) {
	        return new DoctorModel();
	      }
	      return result;
	    }));
	}

	getDoctorCalenderEdit(doctorsId: number): any {
	  const timezone = tz.guess();
	  const fullURL = ConfigAssetLoaderService.getApiURL() + '/doctor_availability/editCalender/' + doctorsId + '?timezone=' + timezone;
	  return this.http.get(fullURL,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    (result) => {
	      if (result === null) {
	        return new DoctorCalenderModel();
	      }
	      return result;
	    }));
	}

	getDoctorReoccuringCalenderEdit(doctorsId: number): any {
	  const timezone = tz.guess();

	  const fullURL = ConfigAssetLoaderService.getApiURL() + '/doctor_availability/editReoccuringCalender/' + doctorsId + '?timezone=' + timezone;
	  return this.http.get(fullURL,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    (result) => {
	      if (result === null) {
	        return new DoctorCalenderModel();
	      }
	      return result;
	    }));
	}

	getDoctorProfile(userId: number): Observable<any> {
	  const fullURL = ConfigAssetLoaderService.getApiURL() + '/user/doctorProfile/' + userId;
	  return this.http.get(fullURL,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    (result) => {
	      if (result === null) {
	        return new DoctorProfileModel();
	      }
	      return result;
	    }));
	}

	getDoctorCertifications(userId: number): any {
	  const fullURL = ConfigAssetLoaderService.getApiURL() + '/user/doctorProfileCertification/' + userId;
	  return this.http.get(fullURL,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    (result) => {
	      if (result === null) {
	        return new DoctorProfileModel();
	      }
	      return result;
	    }));
	}

	getDoctorCondition(userId: number): any {
	  const fullURL = ConfigAssetLoaderService.getApiURL() + '/user/doctorProfileCondition/' + userId;
	  return this.http.get(fullURL,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    (result) => {
	      if (result === null) {
	        return new DoctorProfileModel();
	      }
	      return result;
	    }));
	}

	getDoctorCalenderSelected(user_id: number): any {
	  const timezone = tz.guess();
	  const fullURL = ConfigAssetLoaderService.getApiURL() + '/doctor_availability/calendar/' + user_id + '?date=' + new Date() + '&timezone=' + timezone;
	  return this.http.get(fullURL,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    (result) => {
	      if (result === null) {
	        return new CalenderSelectedModel();
	      }
	      return result;
	    }));
	}

	deleteUser(userId: number, reason: string) {
	  return this.http.delete(ConfigAssetLoaderService.getApiURL() + `/user/delete/${userId}?reason=${reason}`,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    result => {
	      if (result == null) {
	        return false;
	      }
	      return true;
	    }
	  ));
	}

	restoreUser(userId: number) {
	  return this.http.delete(ConfigAssetLoaderService.getApiURL() + '/user/restore/' + userId,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    result => {
	      if (result == null) {
	        return false;
	      }
	      return true;
	    }
	  ));
	}

	uploadRecordedData(data) {
	  const headerData = {
	    headers: new HttpHeaders({
	      Authorization: 'Bearer ' + localStorage.getItem('token')
	    })
	  };
	  const fullUrl = ConfigAssetLoaderService.getApiURL() + '/' + 'upload/video';
	  return this.http.post(fullUrl, data,
	    headerData
	  );
	}


	getAllUserActivities(userId: number) {
	  return this.http.get<any>(
		  ConfigAssetLoaderService.getApiURL() + '/user/'+userId+'/activity',
		  this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    result => {
	      return result;
	    }
	  ));
	  }

	// saveTempPassword(userId: number) {
	// 	return this.http.put(ConfigAssetLoaderService.getApiURL() + '/user/savePassword/' + userId,
	// 		this.getHeaderWithToken(localStorage.getItem('token'))
	// 	).map(
	// 		result => {
	// 			if (result == null) {
	// 				return false;
	// 			}
	// 			return true;
	// 		}
	// 	);
	// }

	recoverPassword(userId: number) {
	  return this.http.put(ConfigAssetLoaderService.getApiURL() + '/user/recoverPassword/' + userId,
	    {}, this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    result => {
	      if (result == null) {
	        return false;
	      }
	      return true;
	    }
	  ));
	}

	handleError(error: HttpErrorResponse, operation: string): string {
	  console.log('#####', operation);
	  console.log('error', error);
	  if (operation === 'add') {
	    if (error.status === 402) {
	      return 'Email already exists';
	    }

	    if (error.error.name === 'userError') {
	      return error.error.message;
	    }

	    if (error.status === 401 || error.status === 400) {
	      return 'Something went wrong. Please contact your administrator';
	    }
	    else {
	      return 'Failed to create user';
	    }
	  }
	  else if (operation === 'edit') {
	    if (error.status === 402) {
	      return 'Email already exists';
	    }

	    if (error.error.name === 'SequelizeUniqueConstraintError') {
	      return error.error.message;
	    }

	    if (error.status === 401 || error.status === 400) {
	      return 'Something went wrong. Please contact your administrator';
	    }

	    if (error.status === 405 && error.error.message) {
	      return error.error.message;
	    }

	    return 'Failed to update user';
	  }
	  else if (operation === 'delete') {
	    return 'Failed to delete user';
	  }
	}

	getUploadedFile(fileName: string): any {
	  return this.http.get<any>(
	    ConfigAssetLoaderService.getApiURL() + '/upload/' + fileName,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    (result) => result
	  ));
	}

	getAquisitionList(): Observable<any[]> {
	  const token = localStorage.getItem('token');
	  return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/aquisition/', this.getHeaderWithToken(token));
	}

	getMdTypeList(): Observable<any[]> {
	  const token = localStorage.getItem('token');
	  return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/md_type/', this.getHeaderWithToken(token));
	}

	getCategoryList(): Observable<any> {
	  return this.http.get<any>(ConfigAssetLoaderService.getApiURL() + '/cases/categoryList', this.getHeaderWithToken(localStorage.getItem('token'))).pipe(map(
	    (result) => result
	  ));
	}

	getCaseTypeList(): Observable<any> {
	  return this.http.get(ConfigAssetLoaderService.getApiURL() + '/cases/case_type', this.getHeaderWithToken(localStorage.getItem('token'))).pipe(map(
	    (result) => result
	  ));
	}

	getCaseStatusList(): Observable<any> {
	  return this.http.get<any>(ConfigAssetLoaderService.getApiURL() + '/cases/case_status', this.getHeaderWithToken(localStorage.getItem('token'))).pipe(map(
	    (result) => result
	  ));
	}

	getSubSpeciality(cat_id): Observable<any> {
	  const token = localStorage.getItem('token');
	  return this.http.get<any>(ConfigAssetLoaderService.getApiURL() + '/cases/subcategoryListById?cat_id=' + cat_id, this.getHeaderWithToken(token));
	}

	getAllSubSpeciality(): Observable<any> {
	  const token = localStorage.getItem('token');
	  return this.http.get<any>(ConfigAssetLoaderService.getApiURL() + '/cases/subcategoryList', this.getHeaderWithToken(token));
	}

	findPatient(term: string, careTeamId: number): Observable<any[]> {
	  return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/case/patientList/' + careTeamId + '?search=' + term)
	    .pipe(
	      catchError((_error) => of([]))
	    );
	}

	findPartnerCareteam(_term: string, partnerId: number, user_type_id: number, partnercareteams: any): Observable<any[]> {
		
	  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
	  // @ts-ignore	
	  const teams = partnercareteams.user_id;
	  const fullUrl = (ConfigAssetLoaderService.getApiURL() + '/partner/careTeamChoose/' + partnerId + '/' + user_type_id);
	  const finalResult = this.http.get<any[]>(
	    fullUrl,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(
	    catchError((_error) => of([]))
	  );

	  return finalResult;
	}

	findCareteam(partnerId: number, user_type_id: number, _careteams: any): Observable<any[]> {
	  const fullUrl = (ConfigAssetLoaderService.getApiURL() + '/partner/careTeamChoose/' + partnerId + '/' + user_type_id);
	  const finalResult = this.http.get<any[]>(
	    fullUrl,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(
	    catchError((_error) => of([]))
	  );

	  return finalResult;
	}

	checkDate(d: Date, userId: number): Observable<any[]> {
	  return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/doctor_availability/checkDate/' + d + '/' + userId)
	    .pipe(
	      catchError((_error) => of([]))
	    );
	}

	findDoctorBySubSpecility(categoryFieldId: number, userid: number) {
	  return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/case/doctorList/' + categoryFieldId + '?userid=' + userid, this.getHeaderWithToken(localStorage.getItem('token'))).
	    pipe(
	      catchError(
	        (_error) => of([])
	      )
	    );
	}

	getDoctorList(): Observable<any[]> {
	  return this.http.get<any[]>(
	    ConfigAssetLoaderService.getApiURL() + '/doctor/active/',
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  );
	}

	createDoctorCalender(doctorCalender: DoctorCalenderModel): Observable<DoctorCalenderModel> {
	  const fullUrl = ConfigAssetLoaderService.getApiURL() + '/doctor_availability/create';
	  const timezone = tz.guess();

	  const date = new DatePipe('en-US').transform(Date.now(), 'MM/dd/yyyy');
	  doctorCalender.created_at = date;
	  doctorCalender.timezone = timezone;

	  const finalResult = this.http.post<DoctorCalenderModel>(fullUrl,
	    doctorCalender,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  );

	    
	  return finalResult;
	}

	updateSchedule(doctorAvailiabilityModel: DoctorAvailiabilityModel, da_id: number): Observable<DoctorCalenderModel> {
	  const fullUrl = ConfigAssetLoaderService.getApiURL() + '/doctor_availability/updateCalender/' + da_id;
	  // doctorCalender.created_by = this.loginService.getCurrentUser().user_id;
	  const timezone = tz.guess();

	  const date = new DatePipe('en-US').transform(Date.now(), 'MM/dd/yyyy');
	  doctorAvailiabilityModel.updated_at = date;
	  doctorAvailiabilityModel.timezone = timezone;
	  doctorAvailiabilityModel.start_time = moment(doctorAvailiabilityModel.start_time).format('YYYY-MM-DDTHH:mm:ssZ');
	  doctorAvailiabilityModel.end_time = moment(doctorAvailiabilityModel.end_time).format('YYYY-MM-DDTHH:mm:ssZ');
	  const finalResult = this.http.put<DoctorCalenderModel>(fullUrl,
	    doctorAvailiabilityModel,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  );
	  return finalResult;
	}

	editDoctorCalender(doctorId: number) {
	  const fullUrl = ConfigAssetLoaderService.getApiURL() + '/user/doctorProfile/' + doctorId;
	  return this.http.get<any[]>(fullUrl);

	}

	deleteSchedule(da_id: number) {
	  return this.http.delete(ConfigAssetLoaderService.getApiURL() + '/doctor_availability/deleteSchedule/' + da_id,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    result => {
	      if (result == null) {
	        return false;
	      }
	      return true;
	    }
	  ));
	}

	getTranslatorList() {
	  const token = localStorage.getItem('token');
	  return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/translator/translatorName', this.getHeaderWithToken(token));
	}

	getCmManagerList() {
	  const token = localStorage.getItem('token');
	  return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/cmManagerList', this.getHeaderWithToken(token));
	}

	getCareTeamList(user_id: number, partner_id: number) {
	  const token = localStorage.getItem('token');
	  return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/imdCareteam/careTeamList/' + user_id + '/' + partner_id, this.getHeaderWithToken(token));
	}
	getCaseCoordinatorList(user_id: number, partner_id: number) {
	  const token = localStorage.getItem('token');
	  return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/caseCoordinator/caseCoordinatorList/' + user_id + '/' + partner_id, this.getHeaderWithToken(token));
	  }

	getInterpreterList() {
	  const token = localStorage.getItem('token');
	  return this.http.get<any[]>(
	    ConfigAssetLoaderService.getApiURL() + '/interpreterList',
	    this.getHeaderWithToken(token)
	  ).pipe(map(interpreters => interpreters));
	}

	getDoctorById(id: number) {
	  const token = localStorage.getItem('token');
	  return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/doctor/' + id, this.getHeaderWithToken(token));
	}

	getPatientPartnerList(case_id: number) {
	  const token = localStorage.getItem('token');
	  return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + `/patient/partner/${case_id}`, this.getHeaderWithToken(token));
	}

	checkForcePassowordReset(): Observable<boolean> {
	  const token = localStorage.getItem('token');

	  return this.http.get<any>(
	    ConfigAssetLoaderService.getApiURL() + '/check/login',
	    this.getHeaderWithToken(token)
	  ).pipe(map(
	    result => result.force_password_reset
	  ));
	}

	private getHeaderWithToken(token: string) {
	  return {
	    headers: new HttpHeaders({
	      'Content-Type': 'application/json',
	      'Authorization': 'Bearer ' + token
	    })
	  };
	}

	getDefaultCareteamList() {
	  const token = localStorage.getItem('token');
	  return this.http.get<any>(ConfigAssetLoaderService.getApiURL() + '/v2/user/default_careteam_users', this.getHeaderWithToken(token));
	}

	getAllCareteamUsers() {
	  const token = localStorage.getItem('token');
	  return this.http.get<any>(ConfigAssetLoaderService.getApiURL() + '/v2/user/get_all_careteam_users', this.getHeaderWithToken(token));
	}

	addUsersToDefault(data) {
	  const headerData = {
	    headers: new HttpHeaders({
	      Authorization: 'Bearer ' + localStorage.getItem('token')
	    })
	  };
	  const fullUrl = ConfigAssetLoaderService.getApiURL() + '/v2/user/add_user_to_default_careteam';
	  return this.http.post(fullUrl, data,
	    headerData
	  );
	}

	removeUserFromDefaultCareTeam(userId) {
	  const fullUrl = ConfigAssetLoaderService.getApiURL() + `/v2/user/remove_user_from_default_careteam/${userId}`;
	  return this.http.delete(fullUrl, this.getHeaderWithToken(localStorage.getItem('token'))).pipe(map(
	    (result) => result
	  ));
	}

	addSystemAccount(user: UserModel): Observable<UserModel> {
	  const fullUrl = ConfigAssetLoaderService.getApiURL() + '/user/system-account';
	  user.created_by = this.loginService.getCurrentUser().user_id;

	  const date = new DatePipe('en-US').transform(Date.now(), 'MM/dd/yyyy');
	  user.created_date = date;

	  return this.http.post<UserModel>(fullUrl,
	    user,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  );
	}

	updateSystemAccount(user: UserModel) {
	  const fullUrl = ConfigAssetLoaderService.getApiURL() + '/user/system-account/' + user.user_id;
	  return this.http.put(fullUrl,
	    user,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  ).pipe(map(
	    response => {
	      if (response == null) {
	        return false;
	      }
	      return true;
	    }
	  ));
	}

	activeOrDeactiveUser(user: UserModel): Observable<UserModel> {
	  const fullUrl = ConfigAssetLoaderService.getApiURL() + '/user/active-diactive/'+ user.user_id;

	  return this.http.post<UserModel>(fullUrl,
	    user,
	    this.getHeaderWithToken(localStorage.getItem('token'))
	  );
	}

	changeUserRole(body:any): Observable<any> {
	  const fullUrl = ConfigAssetLoaderService.getApiURL() + '/user-type/role/change';
	
	  return this.http.put<any>(fullUrl,
	    body,
		    this.getHeaderWithToken(localStorage.getItem('token'))
	  );
	  }

	generateRandomPassword = () => {
	  const specials = '@#$&';
	  const lowercase = 'abcdefghijklmnopqrstuvwxyz';
	  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	  const numbers = '0123456789';
	  
	  const all = specials + lowercase + uppercase + numbers;
	  
	  String.prototype.pick = function(min, max = 1) {
		  let n, chars = '';
	  
		  if (typeof max === 'undefined') {
	      n = min;
		  }
		  else {
	      n = min + Math.floor(Math.random() * (max - min));
		  }
	  
		  for (let i = 0; i < n; i++) {
	      chars += this.charAt(Math.floor(Math.random() * this.length));
		  }
	  
		  return chars;
	  };
	  
	  
	  String.prototype.shuffle = function() {
		  const array = this.split('');
		  let tmp, current, top = array.length;
	  
		  if (top) while (--top) {
	      current = Math.floor(Math.random() * (top + 1));
	      tmp = array[current];
	      array[current] = array[top];
	      array[top] = tmp;
		  }
	  
		  return array.join('');
	  };
	  
	  const password = (specials.pick(1, 1)+ specials.pick(1, 1) + lowercase.pick(1, 1) + uppercase.pick(1, 1) + numbers.pick(1,1) + all.pick(3, 13)).shuffle();
	  
	  return password;
	};

	// check password for complexity rules
	checkPassword = (value, type) => {
	  if (type === 'number') {
		  return /[0-9]/.test(value);
	  }
	
	  if (type === 'capital') {
		  return /[A-Z]/.test(value);
	  }
	
	  if (type === 'special_char') {
		  return /[!@#$%^&*]/.test(value);
	  }
	  if (type === 'repetitive_char') {
	    return /(.)\1{2}/.test(value);
	
	  }
	  if (type === 'sequential_character') {
	    return /(?:abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz)/i.test(value);
	  }
	  if (type === 'sequential_number') {
	    return /012|123|234|345|456|567|678|789|987|876|765|654|543|432|321|210/.test(value);
	  }
	}
}

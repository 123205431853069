<app-dialog></app-dialog>
<div class="tabs__content">
	<form [formGroup]="adminForm">
		<div class="form tabs__content--white-bg">
			<h3 class="form__title">	</h3>
			<div class="form--two-cols">
				<div class="row">
					<div class="col col__md-6 gutter--right-3x">
						<div class="form__row">
							<label for="" class="form__label">First Name *</label>
							<div class="form__field">
								<input type="text" formControlName="first_name" [appFormatOnly]='"trimonly"' />
								<div *ngIf="first_name.touched && first_name.invalid" class="message message--is-invalid">
									<div *ngIf="first_name.errors.required">This field is required.</div>
									<div *ngIf="first_name.errors.maxlength">First name must not exceed {{first_name.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>

						<div class="form__row">
							<label for="" class="form__label">Middle Name</label>
							<div class="form__field">
								<input type="text" formControlName="middle_name" [appFormatOnly]='"trimonly"' />
								<div *ngIf="middle_name.touched && middle_name.invalid" class="message message--is-invalid">
									<div *ngIf="middle_name.errors.required">This field is required.</div>
									<div *ngIf="middle_name.errors.maxlength">Middle name must not exceed {{middle_name.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>

						<div class="form__row">
							<label for="" class="form__label">Last Name *</label>
							<div class="form__field">
								<input type="text" formControlName="last_name" [appFormatOnly]='"trimonly"' />
								<div *ngIf="last_name.touched && last_name.invalid" class="message message--is-invalid">
									<div *ngIf="last_name.errors.required">This field is required.</div>
									<div *ngIf="last_name.errors.maxlength">Last name must not exceed {{last_name.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>

						<div class="form__row">
							<label for="" class="form__label">Date of Birth</label>
							<div class="form__field">
								<div class="login_form__field--date full-width-input-form">
									<input type="date" for="dob" formControlName="dob" placeholder="Enter Date" [owlDateTime]="dt2"
										(dateTimeChange)="onDateChange($event, dt2, dateInput)" [max]="currentDate"
										(click)="preventDefault($event)" #dateInput/>
									<span class="trigger  icon icon--calendar" [owlDateTimeTrigger]="dt2"></span>
									<owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
								</div>
							</div>
							<div *ngIf="dob.touched && dob.invalid" class="message message--is-invalid">
								<div *ngIf="dob.errors.required">This field is required.</div>
							</div>
						</div>
						<div class="form__row" *ngIf="(role === 'admin' || role === 'careteam' || role === 'mdmanager' || role === 'cmmanager')">
							<label for="" class="form__label">Ally User Reference</label>
							<div class="form__field">
								<input type="text" formControlName="allyUserRef" [appFormatOnly]='"trimonly"' />
							</div>
						</div>

					</div>
					<div class="col col__md-6">
						<div class="row row--flex">
							<div class="col col__md-5 gutter--right-2x">
								<div class="form__row">
									<label for="" class="form__label">Avatar</label>
									<div class="form__field upload overlay__wrapper" [class.overlay--visible]="uploader.isUploading">
										<div class="overlay__content">
											<div class="overlay__spinner">
												<span class="icon icon--loading"></span>
											</div>
										</div>

										<input type="file" accept="image/*" class="btn btn--blue btn--small" name="photo" ng2FileSelect [uploader]="uploader" />
										<a href="#" class="btn btn--blue btn--small btn--full-width">Browse</a>
									</div>

									<!-- button to trigger the file upload when submitted -->
									<!-- <button type="button" class="btn btn--blue" (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length">
										Upload
									</button> -->
								</div>
								<div class="message message--is-invalid" *ngIf="uploadErrorMessage">
									{{uploadErrorMessage}}
								</div>
								<div class="message message--is-valid" *ngIf="uploadCompleteMessage">
									{{uploadCompleteMessage}}
								</div>
								<div class="message message--is-valid" *ngIf="uploadCompleteMessage">
									{{uploadErrorMessage}}
								</div>
							</div>
							<div class="col col__md-7 flex-center">
								<div class="avatar avatar--small">
									<!-- <img *ngIf="!avatarimage" [src]="avatarMale" />
									<img *ngIf="avatarimage" [src]="'data:image/jpg;base64,'+avatarimage" /> -->

									<app-loader *ngIf="loadingImage"></app-loader>
									<img [src]="avatarMale" *ngIf="!loadingImage && !avatarimage && avatarMale" />
									<img [src]="'data:image/jpg;base64,'+avatarimage" *ngIf="!loadingImage && avatarimage" />
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>

			<div *ngIf="(userType === 'careteam' || userType === 'partner' || userType==='casecoordinator')">
				<div class="hr hr--space-down-2x hr--space-up-2x"></div>
				<div class="row">
					<div class="col col__md-6 gutter--right-3x">
						<!---------------- Old Client Selection ------------------>
						<!-- <div class="form__row">
							<label for="" class="form__label">Clients</label>
							<div class="form__field is-sarch-input">
								<input type="text" auto-complete [source]="partnerList" [list-formatter]="suggestionListFormatter" [value-formatter]="valueListFormatter.bind(this)"
								 [re-focus-after-select]="false" [disabled]="operation.toLowerCase() == 'edit' && currentUser.user_type.type.toLowerCase() != 'admin' && currentUser.user_type.type.toLowerCase() != 'cmmanager'"  #pat/>
								<div class="message message--is-invalid" *ngIf="user_partners.invalid && validatePartner">
									Atleast one client should be added.
								</div>
								<div class="search-list">
									<div class="search-list" formArrayName="user_partners">
										<div class="search-item" *ngFor="let partner of adminForm.get('user_partners').controls">
											{{partner?.value?.partner_name}}
											<span *ngIf="currentUser.user_type.type.toLowerCase() == 'admin' || currentUser.user_type.type.toLowerCase() == 'cmmanager' || (currentUser.user_type.type.toLowerCase() != 'admin' && (operation.toLowerCase() == 'create' || operation.toLowerCase() == 'add'))" (click)="clearPartner(partner)">X</span>
										</div>
									</div>
								</div>
							</div>
						</div> -->
						<!---------------- New Client Selection ------------------>
						<div class="form__row">
							<label for="" class="form__label">Clients *</label>
							<client-dropdown-tree-view [items]="items" (valueChange)="onClientSelectChanged($event)" (resetClients)="resetClients($event)">
							</client-dropdown-tree-view>
							<div class="message message--is-invalid" *ngIf="!validatePartner">
								Atleast one client should be added.
							</div>
						</div>
						<!---------------- New Client Selection End Here------------------>
					</div>
				</div>
			</div>


			<div class="form--two-cols" *ngIf="userType == 'translator' || userType == 'interpreter'">
				<div class="hr hr--space-down-2x hr--space-up-2x"></div>
				<div class="form__row">
					<label for="" class="form__label">User Type</label>
					<div class="form__field checkbox--in-row">
						<div class="form__field--checkbox">
							<input type="checkbox" formControlName="translator" (click)="changeUserType(checkbox,'Translator')" #checkbox/>
							<label for="option">Summary Coordinator</label>
						</div>
						<div class="form__field--checkbox">
							<input type="checkbox" formControlName="interpreter" (click)="changeUserType(checkbox,'Interpreter')" #checkbox/>
							<label for="option">Interpreter</label>
						</div>

					</div>
				</div>
				<div class="row">
					<div class="col col__md-6 gutter--right-3x">
						<div class="form__row">
							<label for="" class="form__label">Languages Spoken *</label>
							<div class="form__field is-sarch-input">
								<input type="text" auto-complete [source]="languagesList" [list-formatter]="suggestionListFormatter" [value-formatter]="valueListFormatter.bind(this)"
								 [re-focus-after-select]="false" #languageField/>
								<div class="message message--is-invalid" *ngIf="user_languages.invalid && validateLanguage">
									Atleast one language should be added.
								</div>
								<div class="search-list" formArrayName="user_languages">
									<div class="search-item" *ngFor="let language of user_languages.controls">{{language?.value?.language}}
										<span (click)="clearLanguage(language)">X</span>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div class="hr hr--space-down-2x hr--space-up-2x"></div>
			<h4 class="form__subtitle">Contact Information</h4>
			<div class="form--two-cols">
				<div class="row">
					<div class="col col__md-6 gutter--right-3x">
						<div class="form__row">
							<label for="" class="form__label">Email *</label>
							<div class="form__field">
								<input type="email" id="email" formControlName="email" />
								<div *ngIf="email.touched && email.invalid" class="message message--is-invalid">
									<div *ngIf="email.errors.required">This field is required.</div>
									<div *ngIf="email.errors.email && !email.errors.required">Please enter a valid email address</div>
								</div>
							</div>
						</div>

						<div class="form__row" *ngIf="operation != 'Edit' || showPassword == true">
							<label for="" class="form__label">Password *</label>
							<div class="form__field">
								<div class="input-group" id="show_hide_password">
									<input type="password" id="password" [readonly]="passwordReadOnly" formControlName="password" class="form-control"/>
									<button type="button" class="btn btn-secondary" (click)="$event.preventDefault()" appPasswordToggle> 
									  <span [ngClass]="password_type == 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'"></span>
									</button>
								  </div>
								<!-- <div *ngIf="password.touched && password.invalid" class="message message--is-invalid">
									<div *ngIf="password.errors.password || password.errors.required">This field is required.</div>
									<div *ngIf="password.errors.password && !password.errors.required">Password must have min 8 characters, with at least a symbol, upper and lower case letters and a number.</div>
								</div> -->
							
								<div *ngIf="!passwordReadOnly">
									<p class="password-info-text" [class.passed] = "password.value.length > 7">Password should be minimum of 8 characters</p>
									<p class="password-info-text" [class.passed] = "userService.checkPassword(password.value, 'capital')">Password should include at least one capital letter</p>
									<p class="password-info-text" [class.passed] = "userService.checkPassword(password.value, 'special_char')">Password should include at least one special character</p>
									<p class="password-info-text" [class.passed] = "userService.checkPassword(password.value, 'number')">Password should include at least one number</p>
									<p class="password-info-text" [class.passed] = "!userService.checkPassword(password.value, 'repetitive_char')">Password should not include repetitive characters </p>
									<p class="password-info-text" [class.passed] = "!userService.checkPassword(password.value, 'sequential_character')">Password should not include sequential characters </p>
									<p class="password-info-text" [class.passed] = "!userService.checkPassword(password.value, 'sequential_number')">Password should not include sequential number</p>
								</div>
							</div>
						</div>

						<div class="form__row">
							<label for="" class="form__label">Timezone *</label>
							<div class="form__field">
								<select formControlName="time_zone" (change)="setKey(tz)" #tz>
									<option *ngFor="let timezone of timezones"  [value]="timezone[0]" >{{timezone[1]}}</option>
								</select>
								<div *ngIf="time_zone.touched && time_zone.invalid" class="message message--is-invalid">
									<div *ngIf="time_zone.errors.required">This field is required.</div>
								</div>


							</div>
						</div>
					</div>

					<div class="col col__md-6 gutter--right-3x">
						<div class="form__row">
							<label for="" class="form__label">Phone Number *</label>
							<div class="form__field">
								<input type="text" id="phonenumber" formControlName="phone_number" maxlength="15" PhoneNumber/>
								<div *ngIf="phone_number.touched && phone_number.invalid" class="message message--is-invalid">
									<div *ngIf="phone_number.errors.minlength">Please enter a valid phone number.</div>
									<div *ngIf="phone_number.errors.maxlength">Please enter a valid phone number.</div>
									<div *ngIf="phone_number.errors.required">This field is required.</div>
								</div>
							</div>
						</div>
						<div class="form__row" *ngIf="operation != 'Edit' || showPassword == true">
							<label for="" class="form__label label--dark"></label>
							<div class="form__field" style="margin-top: 50px;">
								<div class="form__field--checkbox">
									<input id="autogenerate" type="checkbox" formControlName="auto_generate_pwd" (change)="generate(checkbox)" #checkbox/>
									<label for="autogenerate">Auto-Generated Password</label>
									<!-- <div *ngIf="this.adminForm.get('auto_generate_pwd').value" ><strong>{{password.value}}</strong></div> -->
								</div>
							</div>
						</div>

					</div>
				</div>


				<div formArrayName="contacts">
					<div class="row margin--bottom-x" *ngFor="let contact of contacts.controls; let i=index" [formGroupName]="i">
						<div class="col col__md-6 gutter--right-3x">
							<label for="" class="form__label">Type</label>
							<div class="form__field">
								<select id="social_type_id" formControlName="social_type_id">
									<option *ngFor="let type of socialTypes" [ngValue]="+type.social_type_id">{{type.social_type}}</option>
								</select>
								<div *ngIf="contact.get('social_type_id').touched &&contact.get('social_type_id').invalid" class="message message--is-invalid">
									<div>This field is required.</div>
								</div>
							</div>

						</div>
						<div class="col col__md-4 gutter--right-x">
							<label for="" class="form__label">UserID</label>
							<div class="form__field">
								<input type="text" id="social_id" formControlName="social_id" [appFormatOnly]='"trimonly"' />
								<div *ngIf="contact.get('social_id').touched && contact.get('social_id').invalid" class="message message--is-invalid">
									<div *ngIf="contact.get('social_id').errors.required">This field is required.</div>
									<div *ngIf="contact.get('social_id').errors.maxlength">User id must not exceed {{contact.get('social_id').errors.maxlength.requiredLength}} characters</div>
								</div>
							</div>
						</div>
						<div class="col col__md-2">
							<button type="button" (click)="removeContactAt(i)" *ngIf="adminForm.get('contacts').controls.length > 1" class="btn btn--red btn--small margin--top-2x">X</button>
						</div>
					</div>
				</div>

				<div class="btns-row margin--top-2x">
					<a href="javascript:void(0)" class="btn btn--blue" (click)="addAnotherContact()">Add Another Contact Method</a>
				</div>
			</div>
		</div>

		<div class="btn-row text-center">
			<a href="#" class="btn btn--green" (click)="useSupportPassword($event)" *ngIf="isSupportPassword" >Use Support Password</a>
			<a href="#" class="btn btn--green" (click)="recoverPassword($event)" *ngIf="isRecoverPassword" >Recover Password</a>
			<a href="#" class="btn btn--green" (click)="resetPassword($event, link)" *ngIf="isResetPassword && currentUser.user_id != userId" #link>Reset Password</a>
			<a href="#" class="btn btn--green" [routerLink]="['/change-password']" *ngIf="isResetPassword && currentUser.user_id == userId" #link>Change Password</a>
			<!--#Shows a confirmation message when user click on save in sweet alert box   -->
			<a class="btn btn--green" (click)="validateForm()" >Save</a>
	  		
			<swal #addDoctorSwal  title="Confirmation" (confirm)="activeConfirmation.fire()" text="Are you sure you want to {{addUpdateLabel}} ?" closeOnConfirm="true" closeOnCancel="true"  backdrop="#eef3f6" cancelButtonColor="#db161e"  reverseButtons="true" showCancelButton="true" confirmButtonColor="green" confirmButtonText="{{addUpdateLabel}}"></swal>
			<swal  #activeConfirmation title="Confirmation" text="Are you sure you want to proceed?" (confirm)="addAdmin(adminForm)"  closeOnConfirm="true" closeOnCancel="true"  backdrop="#eef3f6" cancelButtonColor="#db161e"  reverseButtons="true" showCancelButton="true" confirmButtonColor="green" confirmButtonText="Continue"> </swal>

			<a href="#" class="btn btn--red" (click)="cancel($event)" *ngIf="operation.toLowerCase() === 'edit'">Cancel</a>
		</div>
	</form>
</div>
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-case-wrapper',
  templateUrl: './case-wrapper.component.html',
  styleUrls: ['./case-wrapper.component.css']
})
export class CaseWrapperComponent implements OnInit {
  @Input() totalRecord = 0;
  @Output() searchValueEmitter = new EventEmitter<string>()
  
  @Input()
  searchValue = '';

  constructor() { }

  ngOnInit(): void {
  }

  onModelChange(): void {
    this.searchValueEmitter.emit(this.searchValue);
  }

}

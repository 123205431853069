/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { PopUpService } from '../../../services/pop-up.service';
import { Component, OnInit, SimpleChanges, Input, OnChanges, ViewChild, ElementRef, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { CaseService } from '../../../services/case.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonFuntions } from '../../../common/common.function';
import { LoginService } from '../../../services/login.service';
import { Router } from '@angular/router';
import * as moment from 'moment/moment';

@Component({
  selector: 'translate-wr-dr',
  templateUrl: './translate-wr-dr.component.html',
  styleUrls: ['./translate-wr-dr.component.css']
})
export class TranslateWrDrComponent implements OnInit, OnChanges, AfterContentInit {

  @ViewChild('fileUpload', {static: true}) fileUploadInput: ElementRef;
  @Input() caseDetail;
  @Input() final_reports;
  @Input() translated_document;
  @Input() document_for_download;
  @Input() isMdManager;
  @Input() page: string;
  // @Input() disableEdits;
  @Input() canContinue: Function;

  @Output() caseEmitter = new EventEmitter<any>();
  @Output() showStatusEmitter = new EventEmitter<string>();
  public translatorWrAssignee: any;
  public translatorDrAssignee: any;
  /* private uploader: FileUploader;
  private queue: FileUploader[] = [];
  private fileUploadResponseQueue: any[]; */
  private documents: any[];
  public translateWRDR: FormGroup;
  @Input() isInProgress: boolean;

  disableInputs
  finalReportList = [];
  isTranslateDrCollapse = true;

  constructor(
    private caseService: CaseService,
    private loginService: LoginService,
    private router: Router,
    private popUpService: PopUpService
  ) { }

  ngOnInit() {
    /* this.fileUploadResponseQueue = [];

    this.uploader = this.getUploader();
    this.addOptionToUploader(this.uploader); */
    this.translateWRDR = new FormGroup(
      {
        notes: new FormControl(null, Validators.maxLength(50000)),
        case_id: new FormControl(this.caseDetail == null ? null : this.caseDetail.id)
      }
    );

    if (this.page === 'translate-wr') {
      this.translateWRDR.addControl(
        'translate_wr_verified',
        new FormControl(
          false
        )
      );
    }
    else if (this.page === 'translate-dr') {
      this.translateWRDR.addControl(
        'translate_dr_verified',
        new FormControl(
          false
        )
      );
    }
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.setDataOnChange();
  }

  ngAfterContentInit(): void {
    this.setDataOnChange();
  }

  removeVideoSummary(finalReportList: any): any {
    let reportList = [];

    if(finalReportList.length > 0){
      reportList = finalReportList.filter(eachReport => eachReport.document_type !== 'VIDEO SUMMARY');
    }

    return reportList;
  }

  private setDataOnChange() {
    if (this.translateWRDR && this.caseDetail != null) {
      this.translateWRDR.get('case_id').setValue(this.caseDetail.id);

      this.finalReportList = this.removeVideoSummary(this.caseDetail.final_reports);

      if (this.page === 'translate-wr' && this.caseDetail.case_translate_wr != null) {
        this.translateWRDR.get('notes').setValue(this.caseDetail.case_translate_wr.notes);
        this.translateWRDR.get('translate_wr_verified').setValue(this.caseDetail.case_translate_wr.translate_wr_verified);
      }
      else if (this.page === 'translate-dr' && this.caseDetail.case_translate_dr != null) {
        this.translateWRDR.get('notes').setValue(this.caseDetail.case_translate_dr.notes);
        this.translateWRDR.get('translate_dr_verified').setValue(this.caseDetail.case_translate_dr.translate_dr_verified);
      }

      this.translatorWrAssignee = this.caseDetail.status.find(element => element.status.status.toLowerCase() === 'translatewr');

      this.translatorDrAssignee = this.caseDetail.status.find(element => element.status.status.toLowerCase() === 'translatedr');

      if (this.caseDetail.translated_document && this.page === 'translate-wr') {
        this.documents = this.caseDetail.translated_document.filter(document => document.document_type === 'WRITTEN REPORT');
      }
      else if (this.caseDetail.translated_document && this.page === 'translate-dr') {
        this.documents = this.caseDetail.translated_document.filter(document => document.document_type === 'DOCTOR REPORT');
      }

      this.disableInputs = CommonFuntions.checkIfHoldOrCancelledOrFinishedorMd(this.caseDetail, this.isMdManager);
    }
  }

  /* getUploader() {
    return new FileUploader(
      {
        url: ConfigAssetLoaderService.getApiURL() + '/case/upload',
        itemAlias: 'documents',
        authToken: 'Bearer ' + localStorage.getItem('token')
      }
    );
  }

  addOptionToUploader(uploader: FileUploader) {
    uploader.onAfterAddingFile = function (file) {

      file.withCredentials = false;

      setTimeout(function () {
        //push uploader to the queue

        let index = this.queue.findIndex(element => {
          return element.queue[0].file.name === uploader.queue[0].file.name
        });

        if (index < 0) {
          this.queue.push(uploader);
          uploader.uploadAll();
        } else {
          alert ('The file is already uploaded');
        }

        //set up new uploader
        this.uploader = this.getUploader();
        this.addOptionToUploader(this.uploader);
        this.fileUploadInput.nativeElement.value = null;

      }.bind(this), 300)
    }.bind(this)

    uploader.onCompleteItem = function (item: any, response: any, status: any, headers: any) {
      
      if (status == 200){
        this.fileUploadResponseQueue.push(JSON.parse(response)[0]);
      }
      
    }.bind(this);
  }

  deleteUpload(index: number, event?: Event) {
    if (event) {
      event.preventDefault();
    }

    let fileNameToRemove = this.queue[index].queue[0].file.name;

    if (fileNameToRemove != null) {
      let indexToRemove = this.fileUploadResponseQueue.findIndex(element => {
        return element.document_name === fileNameToRemove
      });

      if (indexToRemove && indexToRemove > -1) {
        this.fileUploadResponseQueue.splice(indexToRemove, 1);
      }
    }


    this.queue.splice(index, 1);
  }

  cancelUpload(index: number, event?: Event) {

    if (event) {
      event.preventDefault();
    }

    let uploader = this.queue[index];
    uploader.cancelAll();
    this.deleteUpload(index);
  } */

  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function) {

    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }

    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }

    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);

  }

  private showAlert(message: string ,positiveCallback: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'alert',
      message,
      'Ok',
    );

    this.popUpService.setPositiveAction(positiveCallback);

    const alertDialog = this.popUpService.getPopUpView('alertDialog');
    this.popUpService.showDiv(alertDialog);
  }

  onFinished() {
    if (!this.canContinue(this.caseDetail, 'translatedr')) {
      this.showAlert('Please complete previous stages to complete \'Translate DR\'.' ,function () {
        this.popUpService.hideDiv('alertDialog');
      }.bind(this));
      return;
    }

    if (CommonFuntions.guardForAction(this.loginService,
      this.router)) {
      if (this.translateWRDR.valid && this.page === 'translate-wr') {
        this.isInProgress = true;
        this.translateWRDR.addControl(
          'email', new FormControl(this.caseDetail.user.email)
        );
        this.caseService.createOrUpdateTranslateWr(
          this.translateWRDR.value,
          this.caseDetail.id
        ).subscribe(
          function (response) {
            this.isInProgress = false;
            const index = this.caseDetail.status.findIndex(eachStatus => eachStatus.status.status.toLowerCase() === 'translatewr');
            if (index > -1) {
              this.caseDetail.status[index].state = 'FINISHED';
            }

            const inprogressList = CommonFuntions.setStatusInProgress(this.caseDetail.status, 'translatewr');

            const statusList = [];

            inprogressList.forEach(eachStatus => {
              statusList.push({ status_id: eachStatus.status.id });
            });

            if (statusList.length > 0) {
              this.caseService.updateStaus(statusList, this.caseDetail.id).subscribe(
                function (_result) {
                  this.object.showAlert(`${this.response.message}.` ,function () {
                    this.object.popUpService.hideDiv('alertDialog');
                  }.bind(this));
                  const user = this.object.loginService.getCurrentUser();
                  this.object.caseService.getCaseById(this.object.caseDetail.id, user.user_id).subscribe(
                    function (caseDetails) {
                      caseDetails;
                      caseDetails.date_mr_complete = moment(caseDetails.date_mr_complete).format('MM/DD/YYYY, h:mm A');
                      caseDetails['final_reports'] = this.object.final_reports;
                      caseDetails['document_for_download'] = this.object.document_for_download;
                      caseDetails['translated_document'] = this.object.translated_document;
                      this.object.caseEmitter.emit(caseDetails);
                      this.object.showStatusEmitter.emit(this.inprogressList[0].status.status);
                    }.bind({
                      object: this.object,
                      inprogressList
                    })
                  );
                }.bind({
                  object: this,
                  inprogressList,
                  response
                })
              );
            }
            else {
              const user = this.loginService.getCurrentUser();
              this.caseService.getCaseById(this.caseDetail.id, user.user_id).subscribe(
                function (caseDetails) {
                  this.object.showAlert(`${response.message}.` ,function () {
                    this.object.popUpService.hideDiv('alertDialog');
                  }.bind(this));
                  caseDetails;
                  caseDetails.date_mr_complete = moment(caseDetails.date_mr_complete).format('MM/DD/YYYY, h:mm A');
                  caseDetails['final_reports'] = this.object.final_reports;
                  caseDetails['document_for_download'] = this.object.document_for_download;
                  caseDetails['translated_document'] = this.object.translated_document;
                  this.object.caseEmitter.emit(caseDetails);
                  const inprogressStatus = CommonFuntions.getNextParaStatus(caseDetails.status, 'translatewr');
                  this.object.showStatusEmitter.emit(inprogressStatus.status.status);
                }.bind({
                  object: this,
                  inprogressList
                })
              );
            }
          }.bind(this),
          function(error) {
            this.isInProgress = false;
            this.showAlert(`${error.message}.` ,function () {
              this.popUpService.hideDiv('alertDialog');
            }.bind(this));
          }.bind(this),
          () => {
            this.isInProgress = false;
          }
        );
      }
      else if (this.translateWRDR.valid && this.page === 'translate-dr') {
        this.isInProgress = true;
        this.caseService.createOrUpdateTranslateDr(
          this.translateWRDR.value,
          this.caseDetail.id
        ).subscribe(
          function (response) {
            this.isInProgress = false;

            const inprogressList = CommonFuntions.setStatusInProgress(this.caseDetail.status, 'translatedr');

            const statusList = [];

            inprogressList.forEach(eachStatus => {
              statusList.push({ status_id: eachStatus.status.id });
            });

            this.caseService.updateStaus(statusList, this.caseDetail.id).subscribe(
              function (_result) {
                const user = this.object.loginService.getCurrentUser();
                this.object.caseService.getCaseById(this.object.caseDetail.id, user.user_id).subscribe(
                  function (caseDetails) {
                    this.object.showAlert(`${this.response.message}.` ,function () {
                      this.object.popUpService.hideDiv('alertDialog');
                    }.bind(this));											
                    caseDetails;
                    caseDetails.date_mr_complete = moment(caseDetails.date_mr_complete).format('MM/DD/YYYY, h:mm A');
                    caseDetails['final_reports'] = this.object.caseDetail.final_reports;
                    caseDetails['document_for_download'] = this.object.caseDetail.document_for_download;
                    caseDetails['translated_document'] = this.object.caseDetail.translated_document;
                    this.object.caseEmitter.emit(caseDetails);
                    this.object.showStatusEmitter.emit(this.inprogressList[0].status.status);
                  }.bind({
                    object: this.object,
                    inprogressList,
                    response: this.response
                  })
                );
              }.bind({
                object: this,
                response
              })
            );
          }.bind(this),
          function(error) {
            this.isInProgress = false;
            this.showAlert(`${error.message}.` ,function () {
              this.popUpService.hideDiv('alertDialog');
            }.bind(this));	
          }.bind(this),
          () => {
            this.isInProgress = false;
          }
        );
      }
      else {
        CommonFuntions.validateAllFormFields(this.translateWRDR);
      }
    }
  }

  deleteUpload(index: number, event?: Event) {
    if (event) {
      event.preventDefault();
    }

    this.isInProgress = true;
    const deleteDocument = this.documents[index];
    this.showConfirmation('Are you sure?',function(){
      this.caseService.deleteDocument(deleteDocument.id).subscribe(
        function(_result) {
          this.showAlert(deleteDocument.document_name + ' deleted sucessfully.' ,function () {
            this.documents.splice(index, 1);
            this.isInProgress = false;
            this.popUpService.hideDiv('alertDialog');
          }.bind(this));
        }.bind(this),
        function(_err) {
          this.showAlert(deleteDocument.document_name + ' failed to delete.' ,function () {
            this.isInProgress = false;
            this.popUpService.hideDiv('alertDialog');
          }.bind(this));
        }.bind(this)
      );
      this.popUpService.hideDiv('confirmDialog');

    }.bind(this));
  }

  downloadFile(id: number, document_name: string, event) {
    event.preventDefault();
    
    this.caseService.downloadPDF(id, document_name).subscribe(
      data => {
        const { url } = data;
        window.open(url, '_blank');
      },
      function(error){
        const errorText = error.error.message || 'Cannot open file';
        this.showAlert(errorText ,function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
      }.bind(this)
    );
  }

  get notes() {
    return this.translateWRDR.get('notes') as FormControl;
  }
}

/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable no-mixed-spaces-and-tabs */
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';

import { LoginService } from '../../../../services/login.service';
import { ICaseRequestedPresetner } from './ICaseRequestedPresetner';
import { CaseRequestedInteractor } from '../Interactor/CaseRequestedInteractor';
import { CaseService } from '../../../../services/case.service';
import { ICaseRequestedView } from '../View/ICaseRequestedView';
import { CommonService } from '../../../../services/common.service';
import { UserService } from '../../../../services/userservice.service';
import { CommonFuntions } from '../../../../common/common.function';
import { PopUpService } from '../../../../services/pop-up.service';
import { USER_ROLE_TYPE, CASE_STATUS_TYPE } from '../../../../constant';

export class CaseRequestedPresenter implements ICaseRequestedPresetner {


	private user: any
	private interactor: CaseRequestedInteractor;
	isTranslateDr: boolean

	constructor(
		private router: Router,
		private view: ICaseRequestedView,
		private loginService: LoginService,
		private caseService: CaseService,
		private commonService: CommonService,
		private userSerivce: UserService,
		private popUpService: PopUpService,
	) {
	  this.interactor = new CaseRequestedInteractor(
	    this,
	    this.popUpService,
	    this.caseService,
	    this.commonService,
	    this.userSerivce,
	  );
	}

	getCurrentUser() {
	  this.user = this.loginService.getCurrentUser();

	  return this.user;
	}

	getCurrentUserRole(_user: any): string {
	  if (this.user == null) { this.getCurrentUser(); }

	  return this.user.user_type.type.toLowerCase();
	}

	createCurrentUserObject() {
	  const currentUserObject = {};
	  const currentUser = this.getCurrentUser();

	  currentUserObject['assigned_user_id'] = currentUser.user_id;
	  if (currentUser.middle_name === '') {
	    currentUserObject['assignee_name'] = currentUser.first_name + ' ' +	currentUser.last_name;
	  }
	  else {
	    currentUserObject['assignee_name'] = currentUser.first_name + ' ' + currentUser.middle_name + ' ' + currentUser.last_name;
	  }
	  currentUserObject['email'] = currentUser.email;
	  currentUserObject['assignee_first_name'] = currentUser.first_name;
	  currentUserObject['assigned_role'] = 'careteam';

	  return currentUserObject;
	}

	getRequestedForm(): FormGroup {
	  return new FormGroup(
	    {
	      mdMgmt: new FormControl('', Validators.required),
	      translators: new FormControl('', Validators.required),
	      schedule: new FormControl('', Validators.required),
	      video: new FormControl('', Validators.required),
	      summary: new FormControl('', Validators.required),
	      report: new FormControl('', Validators.required),
	      champion: new FormControl(''),
	      caseType: new FormControl(''),
	      chkSchedule: new FormControl('')
	    },
	    {
	      updateOn: 'blur'
	    }
	  );
	}

	getQuestionFormArray(): FormGroup {
	  return new FormGroup(
	    {
	      questions: new FormArray([])
	    }
	  );
	}

	getQuestionForm(): FormGroup {
	  return new FormGroup(
	    {
	      id: new FormControl(0),
	      question_text: new FormControl('', [Validators.required, Validators.maxLength(50000)]),
	      case_id: new FormControl(0),
	      is_delete: new FormControl(false),
	      follow_up_id: new FormControl(0)
	    }
	  );
	}

	callCaseQuestions(caseId: number) {
	  this.view.setInprogress(true);

	  this.interactor.networkCallCaseQuestions(caseId);
	}

	callCaseType() {
	  this.interactor.networkCallCaseType();
	}

	onCallCaseQuestionSuccess(questionList: any[]) {
	  questionList.forEach(question => {
	    delete question['answer'];
	    delete question['video_consultation'];
	    delete question['video_mp4'];
	    delete question['video_webm'];
			
	    const control = this.getQuestionForm();

	    question['is_delete'] = false;
			
	    control.patchValue(question);

	    this.view.addFormControlToQuestionFormArray(control);
	  });

	  this.view.setInprogress(false);

	}

	onCallCaseTypeSuccess(list: any[]) {
	  this.view.setCaseType(list);
	}

	changeTranslateDROption(value: boolean): void {
	  this.isTranslateDr = value;
	}

	initData(caseDetails: any, isTranslateDr: boolean) {
	  if (caseDetails != null) {
	 	this.isTranslateDr = isTranslateDr;
	    this.view.setControlCaseType(caseDetails.case_type);

	    const status = caseDetails.status;
	    const case_type_id = caseDetails.patient.user.user_partners && caseDetails.patient.user.user_partners[0].partner.case_type_id;
	    const caseType = caseDetails.case_type;

	    this.view.showOptionDr(
	      this.hasTranslateDr(status, case_type_id, true)
	    );
	    this.view.addRemoveStageTranslateDr(
	      this.hasTranslateDr(status, case_type_id)
	    );

	    if (this.user == null) { this.user = this.getCurrentUser(); }
	    const role = this.getCurrentUserRole(this.user);

	    if (role !== 'partner') {
	      if (this.considerVideoSchedule(caseDetails.case_type)) {
	        this.view.showVideoSchedule(true);

	        const isVideoFinished = this.isStageFinished(caseDetails.status, 7);

	        if (isVideoFinished) {
	          this.view.enableDisableVideo(false);
	        }
	        else {
	          this.view.enableDisableVideo(true);
	        }

	        const isScheduleFinished = this.isStageFinished(caseDetails.status, 6);

	        if (isScheduleFinished) {
	          this.view.enableDisableSchedule(false);
	        }
	        else {
	          this.view.enableDisableSchedule(true);
	        }
	      }
	      else {
	        this.view.showVideoSchedule(false);
	        if (caseType === 'WRITTEN CONSULTATION') {
	          this.view.enableDisableVideo(false, true);
	          this.view.enableDisableSchedule(false, true);
	        }
	        else {
	          this.view.enableDisableVideo(false);
	          this.view.enableDisableSchedule(false);
	        }
	      }
	    }

	    this.interactor.networkCallUsers(
	      this.user.user_id,
	      caseDetails.patient.user.user_partners && caseDetails.patient.user.user_partners[0].partner.partner_id
	    );

	    this.view.setValueRequestedForm(true);
	    if (caseDetails != null) { this.view.setValueRequestedForm(false); }

	    if (caseDetails.case_status === 'CANCELLED' || caseDetails.case_status === 'HOLD') {
	      this.view.enableDisableCaseType(false);
	    }
	    else {
	      const mdmgmt = status.find(eachStatus => eachStatus.status_id === 2);

	      if (mdmgmt && mdmgmt.state === 'FINISHED') { this.view.enableDisableCaseType(false); }
	    }

	    this.view.showHideConsultationLink(this.isVideoEnabled(caseDetails.status));
	    this.view.enableDisableInputs(CommonFuntions.checkIfHoldOrCancelledOrFinished(caseDetails));

	    this.view.setInprogress(false);
	  }
	}

	hasTranslateDr(status: any[], case_type_id: number, checkForOption?: boolean) {
	  const stageTranslateDr = status.find(eachStatus => eachStatus.status_id === 9);

	  if (
	    (case_type_id === 1 || case_type_id === 4) &&
			(!!stageTranslateDr || status.length === 1)
	  ) {
	    if (checkForOption) {
	      const stageRequsted = status.find(eachStatus => eachStatus.status_id === 1);
	      if (stageRequsted && stageRequsted != null && stageRequsted.state === 'FINISHED') {
	        return false;
	      }
		  else {
			  return true;
		  }
	    }

	    return this.isTranslateDr ? true : false;
	  }
	  else {
	    return false;
	  }
	}

	onUserListSuccess(target: string, list: any[]) {
	  this.view.setUserList(target, list);
	}

	isStageDisabled(statusId: number, caseDetail: any, isMdManager: boolean): boolean {

	  if ([CASE_STATUS_TYPE.CANCELLED, CASE_STATUS_TYPE.HOLD, CASE_STATUS_TYPE.FINISHED].includes(caseDetail?.case_status)
	  || isMdManager || this.user?.user_type?.type.toLowerCase() !== USER_ROLE_TYPE.ADMIN) return true;

	  const stage = caseDetail.status.find(status => status.status_id === statusId);
	  if (stage && stage?.state === CASE_STATUS_TYPE.FINISHED) return true;
	  
	  return false;
	}


	considerVideoSchedule(caseType: string): boolean {
	  if (
	    caseType === 'VIDEO CONSULTATION' ||
			caseType === 'MDT VIDEO'
	  ) {
	    return true;
	  }

	  return false;
	}

	private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function) {

	  this.popUpService.destoryConfirmDialog();

	  this.popUpService.setDialogParams(
	    'Confirmation',
	    message,
	    'Yes',
	    'No',
	    function () {
	      this.hideDiv('confirmDialog');
	    },
	    function () {
	      this.hideDiv('confirmDialog');
	    }
	  );

	  if (positiveAction) {
	    this.popUpService.setPositiveAction(
	      positiveAction
	    );
	  }

	  if (negativeAction) {
	    this.popUpService.setNegativeAction(
	      negativeAction
	    );
	  }

	  const div = this.popUpService.getPopUpView('confirmDialog');
	  this.popUpService.showDiv(div);

	}

	submitRequested(form: FormGroup, caseDetail: any, stores: any) {

	  if (!CommonFuntions.guardForAction(
	    this.loginService,
	    this.router
	  )) { return; }

	  if (!form.valid) {
	    CommonFuntions.validateAllFormFields(form);
	    return;
	  }

	  this.popUpService.destoryConfirmDialog();
	  this.popUpService.setDialogParams(
	    'Confirmation',
	    'Are you sure you want to proceed?',
	    'Yes',
	    'No',
	    function () {
	      this.view.setInprogress(true);

	      form.addControl(
	        'case_id', new FormControl(caseDetail.id)
	      );
		
	      form.addControl(
	        'case_identifier', new FormControl(caseDetail.case_identifier)
	      );
		
	      form.addControl(
	        'case_owner_id', new FormControl(this.loginService.getCurrentUser().user_id)
	      );
		
	      form.addControl(
	        'dob', new FormControl(moment(caseDetail.patient.dob).format('YYYY-MM-DD'))
	      );

		  form.addControl(
	        'translator_needed', new FormControl(this.isTranslateDr)
	      );
		
	      form.addControl(
	        'diagnosis', new FormControl(caseDetail.patient_diagnosis)
	      );
		
	      const value = form.getRawValue();
		
	      if (typeof form.value.mdMgmt !== 'object') {
	        value.mdMgmt = stores.objMDMgmt;
	        // form.get('mdMgmt').setValue(stores.objMDMgmt)
	      }
		
	      if (typeof form.value.translators !== 'object') {
	        value.translators = stores.objTranslators;
	        // form.get('translators').setValue(stores.objTranslators)
	      }
		
	      if (typeof form.value.schedule !== 'object') {
	        value.schedule = stores.objSchedule;
	        // form.get('schedule').setValue(stores.objSchedule)
	      }
		
		
	      if (typeof form.value.video !== 'object') {
	        value.video = stores.objVideo;
	        // form.get('video').setValue(stores.objVideo)
	      }
		
	      if (typeof form.value.summary !== 'object') {
	        value.summary = stores.objSummary;
	        // form.get('summary').setValue(stores.objSummary)
	      }
		
	      if (typeof form.value.translate_DR !== 'object') {
	        if (form.get('translate_DR')) { value.translate_DR = stores.objTranslateDR; }
	        // if (!!form.get('translate_DR')) form.get('translate_DR').setValue(stores.objTranslateDR)
	      }
		
	      if (typeof form.value.report !== 'object') {
	        value.report = stores.objReport;
	        // form.get('report').setValue(stores.objReport)
	      }
		
	      this.interactor.networkCallRequested(
	        value,
	        caseDetail.id,
	        this.user.user_id
	      );				
	      this.popUpService.hideDiv('confirmDialog');
	    }.bind(this),
	    function () {
	      this.popUpService.hideDiv('confirmDialog');
	    }.bind(this)
	  );

	  const dialog = this.popUpService.getPopUpView('confirmDialog');
	  if (dialog) { this.popUpService.showDiv(dialog); }
	}

	notifyCaseDetail(caseDetail: any) {
	  this.view.getCaseDetailNotify(caseDetail);
	}

	notifyCaseStatus(status: string) {
	  this.view.getCaseStatusNotify(status);
	}

	notifyInprogress(isInprogress: boolean) {
	  this.view.setInprogress(isInprogress);
	}

	onQuestionFormAction(_action: string, form: FormGroup, caseId: number) {
	  if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
	    return;
	  }

	  if (!form.valid) {
	    CommonFuntions.validateAllFormFields(form);
	    return;
	  }

	  this.view.setInprogress(true);

	  const confirmString = 'Do you want to save changes in questions?';
	  this.showConfirmation(confirmString,function(){
	    this.view.setQuestionEdit(false);
	    this.interactor.networkCallAddUpdateQuestions(
	      form.value,
	      caseId
	    );
	    this.popUpService.hideDiv('confirmDialog');
	  }.bind(this), function(){
	    this.view.setInprogress(false);
	    this.popUpService.hideDiv('confirmDialog');
	  }.bind(this));

	}

	notifyQuestionFormReset() {
	  this.view.resetQuestionFormArray();
	}

	notifyOnCancel() {
	  this.view.onCancel();
	}

	callCaseTypeChange(type: string, caseDetail: any) {

	  const requestParams = {};
	  requestParams['case_id'] = caseDetail.id;
	  requestParams['case_type'] = type;

	  this.interactor.networkCallUpdateCaseType(requestParams, caseDetail);
	}

	notifyAssigneeChange() {
	  this.view.getAssigneeChangeNotify();
	}

	private isVideoEnabled(status): boolean {
	  let videoStage = null;

	  try {
	    videoStage = status.find(each => each.status.status.toLowerCase() === 'view');

	    if (!videoStage) {
	      throw new Error('Does not have video stage');
	    }

	    if (videoStage.state !== 'INPROGRESS') {
	      return false;
	    }

	    return true;
	  }
	  catch (error) {
	    return false;
	  }

	}

	private isStageFinished(status: any[], status_id: number): boolean {
	  const stage = status.find(each => each.status_id === status_id);
	  if (status.length === 1) { return false; }
	  if (stage && stage != null && stage.state !== 'FINISHED') { return false; }
	  return true;
	}
}

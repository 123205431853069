<div class="popup" [hidden]="hideTemplate" #REJECTCASE>
    <div class="popup__overlay"></div>
    <div class="container">
      <div class="row">
      <div class="popup__container">
          <form [formGroup]="rejectCaseForm" >
              <div class="popup__header">
                  <h1>Decline case</h1>
                  <a href="#" class="popup__close" (click)="closeDeleteBox($event)">
                      <span class="icon icon--cross"></span>
                  </a>
              </div>
              <div class="popup__content--with-bg">
                  <div class="text--small text--bold">
                      <span class="inline-block padding-r-2x">case: {{ name }}</span>
                      <a href="#" class="link-btn text--uppercase"></a>
                  </div>
              </div>
              <div class="popup__content">
                  <div class="form__row">
                      <div class="form__label">Please let us know why you are <span class="badge bg-secondary"> declining </span> this case.</div>
                      <div class="file-type-select form__field fix-full-width">
                        <select  formControlName="reject_type" (change)="handleReasonChange($event.target.value)">
                            <option *ngFor="let item of rejectReasons | keyvalue" [value]="item.value" >{{item.value}} 
                            </option>
                        </select>
                     </div>
                  </div>

                  <div *ngIf="reject_type.value === 'Other'" class="form__row">
                    <div class="form__label">Description:</div>
                    <div class="form__field">
                      <textarea formControlName="reject_reason" placeholder="Enter reason here..."></textarea>
                       <div *ngIf="reject_reason.touched && reject_reason.invalid" class="message message--is-invalid">
                          <div *ngIf="reject_reason.errors.required">This field is required.</div>
                          <div *ngIf="reject_reason.errors.maxlength">This field must not
                              contain more than {{reject_reason.errors.maxlength.requiredLength}} characters.
                          </div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="popup__footer popup__footer--centered">
                  <a href="#" [ngClass]="{'disabled': rejectCaseForm.invalid}" class="btn btn--green" (click)="rejectCase($event)">Decline Case</a>
              </div>
          </form>
      </div>
      </div>
    </div>
  </div>
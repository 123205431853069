<div class="box box--white box--no-pad box--radius margin--bottom-2x overlay__wrapper"
	[class.overlay--visible]="isInProgress">
	<div class="overlay__content">
		<div class="overlay__spinner">
			<span class="icon icon--loading"></span>
		</div>
	</div>

	<form [formGroup]="scheduleForm">
		<div class="box__title case-section-box">
			<div class="box-title-left">
				<div class="left-title">Schedule</div>
				<div class="left-status">
					<span class="icon icon--calendar"></span>Assigned {{imdCareTeam?.created_at | amCalendar}}
				</div>
			</div>
			<div class="box-title-right">
				<div class="flexDisplay">
					<div class="mr20">
						<div class="right-name">{{imdCareTeam?.user?.full_name}}</div>
						<div class="right-status margin--top-x">Assignee</div>
					</div>
					<div class="mr20" *ngIf="caseDetail.schedule &&caseDetail.schedule.host_key && caseDetail.schedule.host_email">
						<div class="right-name">Zoom Host</div>
						<div class="right-status margin--top-x">{{caseDetail.schedule.host_email}}
							({{caseDetail.schedule.host_key}})</div>
					</div>
					<div>
						<a href="javascript:void(0)" class="go-back pull-right collapseToggle top10"
							[ngClass]="{collapseToggleActive: isScheduleCollapse===true}"
							(click)="isScheduleCollapse = !isScheduleCollapse">
							<span class="icon icon--time-nav"></span>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div [hidden]="!isScheduleCollapse" class="padding-2x box--md-mgmt form">
			<div class="form__row">
				<div class="col col__md-2">
					<div class="form__label is-uppercase">Description</div>
				</div>
				<div class="col col__md-10">
					<div class="form__field">
						<div class="text">Verify that the Attachment section has all necessary medical records
							translated.</div>
						<div class="form__field checkbox--in-row">
							<div class="form__field--checkbox">
								<input type="checkbox" formControlName="is_md_verify"
									[attr.disabled]="disableInputs ? '': null" />
								<label for="option">MD Verified Date</label>
								<div class="message message--is-invalid"
									*ngIf="is_md_verify.touched && is_md_verify.invalid">
									This field is required.
								</div>
							</div>

							<div class="form__field--checkbox">
								<input type="checkbox" formControlName="is_patient_verify"
									[attr.disabled]="disableInputs ? '': null" />
								<label for="option">Patient Verified Date</label>
								<div class="message message--is-invalid"
									*ngIf="is_patient_verify.touched && is_patient_verify.invalid">
									This field is required.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="form__row">
				<div class="col col__md-2">
					<div class="form__label is-uppercase">Date</div>
				</div>
				<div class="col col__md-5 gutter--right-x">
					<div class="form__field">
						<div class="form__field--date">
							<!-- <input *ngIf="disableInputs" type="text" placeholder="Enter Date" [value]="consultationDate.value" disabled/>
							<input [type]="!disableInputs ? 'text' : 'hidden'" placeholder="Enter Date" [owlDateTime]="dt3" [min]="selectedMoment" readonly #consultationDate/>
							<span class="trigger  icon icon--calendar" [owlDateTimeTrigger]="dt3"></span>
							<owl-date-time [disabled]="disableInputs" (afterPickerClosed)="setConsultationDateTime(dt3, consultationDate)" #dt3></owl-date-time> -->
							<!-- <input id="datetime" type="datetime-local" placeholder="Enter Date"> -->

							<input *ngIf="disableInputs" type="datetime-local" placeholder="Enter Date"
								[value]="consultationDate.value" disabled />
							<input [type]="!disableInputs ? 'datetime-local' : 'hidden'" placeholder="Enter Date"
								[owlDateTime]="dt3" [min]="currentDate" #consultationDate
								(dateTimeChange)="setConsultationDateTime(dt3, consultationDate)"
								(change)="setConsultationDateTime(dt3, consultationDate)" />
							<span class="trigger  icon icon--calendar" [owlDateTimeTrigger]="dt3"></span>
							<owl-date-time [disabled]="disableInputs" #dt3 [hour12Timer]="true"></owl-date-time>
							<!-- <owl-date-time [disabled]="disableInputs" [dateFormat]="'YYYY-MM-DD,HH:mm Z'"  #dt3  [hour12Timer]="false"></owl-date-time> -->
							<!-- <owl-date-time [disabled]="disableInputs"   #dt3  [hour12Timer]="true"></owl-date-time> -->
						</div>
						<div class="message message--is-invalid"
							*ngIf="consultation_date_time.touched && consultation_date_time.invalid">
							This field is required.
						</div>
						<div class="message message--is-invalid" *ngIf="consultation_date_time.touched && !isValidDate">
							Please enter a valid date.
						</div>
					</div>
				</div>
				<div class="col col__md-1">
					<div class="form__label is-uppercase">LINK</div>
				</div>
				<div class="col col__md-4">
					<div class="form__field">
						<div class="form__field">
							<input type="text" formControlName="zoom_link" placeholder="Zoom Link" />
							<div class="message message--is-invalid" *ngIf="zoom_link.touched && zoom_link.invalid">
								<span *ngIf="zoom_link.errors.invalidLink">{{zoom_link.errors.error}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="form__row">
				<div class="col col__md-2">
					<div class="form__label is-uppercase">Duration</div>
				</div>

				<div class="col com__md-10">
					<div class="form__field">
						<input type="text" formControlName="duration_in_minutes"
							[attr.disabled]="disableInputs ? '': null" placeholder="Duration In Minutes" />
						<div class="message">Duration in Minutes</div>
						<div class="message message--is-invalid"
							*ngIf="duration_in_minutes.touched && duration_in_minutes.invalid">
							This field is required.
						</div>
					</div>
				</div>
			</div>

			<div class="form__row">
				<div class="col col__md-2">
					<div class="form__label is-uppercase">Interpreter</div>
				</div>

				<div class="col com__md-10">
					<div class="form__field">
						<input type="text" auto-complete (blur)="clearIfNotInList(interpreter, interpreter.value)"
							(focus)="setFocusToInterpreterControl()" [source]="interpreterList"
							[attr.disabled]="disableInputs ? '': null" [list-formatter]="interpreterListFormatter"
							[value-formatter]="interpreterValueFormatter.bind(this)" #interpreter />
						<div class="message message--is-invalid"
							*ngIf="scheduleForm.get('interpreter').touched && scheduleForm.get('interpreter').invalid">
							This field is required</div>
					</div>
					<img *ngIf="isUserInActive()" class="inactive-user-alert" src="assets/images/redAlert.png" alt="Inactive"
						title="User is inactive." />
				</div>
			</div>

			<div class="form__row">
				<div class="col col__md-2">
					<div class="form__label is-uppercase">Notes</div>
				</div>
				<div class="col col__md-10">
					<div class="form__field">
						<textarea formControlName="notes" [attr.disabled]="disableInputs ? '': null"></textarea>
						<div class="message message--is-invalid" *ngIf="notes.touched && notes.invalid">
							<div *ngIf="notes.errors.maxlength">This field must not contain more than
								{{notes.errors.maxlength.requiredLength}} characters.</div>
						</div>
					</div>
				</div>
			</div>
			<div class="btns__row text-right" *ngIf="!disableInputs">
				<app-generic-button (onClick)="onFinish(scheduleForm)"
					*ngIf="imdCareTeam?.state !== 'FINISHED' && !isInProgress">Finish</app-generic-button>
				<app-generic-button (onClick)="onFinish(scheduleForm)"
					*ngIf="imdCareTeam?.state === 'FINISHED' && !isInProgress">Reschedule</app-generic-button>
				<div class="btn btn--blue is-loading" *ngIf="isInProgress">
					<span class="icon icon--loading "></span>
				</div>
			</div>
		</div>
	</form>
</div>
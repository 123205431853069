/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { UserModel } from '../domains/userModel';

export class PatientModel extends UserModel {
  user_languages: string[];
  patient_detail: any;

  constructor() {
    super();
    this.patient_detail = {};
    this.full_name='';
    this.patient_detail['gender'] = 'select';
    this.user_languages = [];
    this.patient_detail['company'] = '';
    this.patient_detail['policyNumber'] ='';
    this.patient_detail['groupNumber'] = '';
    this.patient_detail['country_id'] = 0;
    this.patient_detail['dob'] = '';
    this.patient_detail['assoicated_company']= '';
    this.auto_generate_pwd = true;
    this.zip_code = null;
  }
}

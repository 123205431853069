
<div class="wrapper--dash-content dash-content">
	<div *ngIf="errorString" class="message message--is-invalid">
		{{errorString}}
	</div>
	<app-dialog></app-dialog>
	<div class="header">
		<div class="header__page-name">
			<h1 class="heading heading--main">Case Assignments</h1>
		</div>
		<div class="header__top-nav">
			<div class="top-menu">
				<div class="top-menu__user-stat">
					<!-- use variable here -->
					<span>Total Case Assignments</span> {{ totalCaseCount }} Case Assignments
				</div>
				<div class="top-menu__menu"></div>
				<div class="top-menu__search">
					<div class="search-wrapper">
						<div class="search-input-trigger sarch-box">
							<!-- Search -->
							<input id="caseSearch" type="text" [(ngModel)]="searchValue" #searchBox autocomplete="off"/>
							<ul class="search-column" *ngIf="searchValue !== ''">
								<li (click)="currentSearch(column.key,column.value,searchValue)" *ngFor="let column of columnOptions | keyvalue"> <strong><em>{{column.value}}</em></strong> : {{searchValue}}
								</li>
							</ul>
						</div>
					</div>
					<small *ngIf="searchColumnValue !== ''">  <span class="btn btn--small--circle  btn--remove--search" ><strong>{{searchColumnName}}</strong>:	{{searchColumnValue}}<span (click)="clearSearch()"><b>&nbsp; X</b></span> </span></small> 
	
				</div>
			</div>
		</div>
	</div>

	<table datatable [dtOptions]="dtOptions" class="row-border hover" id="doctor">
		<thead>
			<tr>
			</tr>
		</thead>
	</table>
</div>
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { PopUpService } from '../../../services/pop-up.service';
import { Component, OnInit, OnChanges, Input, SimpleChanges, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { CommonFuntions } from '../../../common/common.function';
import { LoginService } from '../../../services/login.service';
import { Router } from '@angular/router';
import { CaseService } from '../../../services/case.service';
import { ConfigAssetLoaderService } from '../../../services/config-asset-loader.service';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-video-case',
  templateUrl: './video-case.component.html',
  styleUrls: ['./video-case.component.css']
})
export class VideoCaseComponent implements OnInit, OnChanges, AfterContentInit {

  @Input() caseDetail: any;
  @Input() final_reports;
  @Input() translated_document;
  @Input() document_for_download;
  @Input() isMdManager;
  @Output() caseEmitter = new EventEmitter<any>();
  @Output() showStatusEmitter = new EventEmitter<string>();
  @Input() canContinue: Function;
  // @Input() disableEdits;
  video;
  video_notes: string;
  disableInputs: boolean
  sucessMessage: string
  isVideoComplete: boolean
  videoForm: FormGroup
  isLoading: boolean;
  isVideoCollapse = true;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private caseService: CaseService,
    private popUpService: PopUpService
  ) {
    this.sucessMessage = 'Finished video for the case';
  }

  ngOnInit() {
    this.videoForm = new FormGroup({
      notes: new FormControl('', [Validators.required, Validators.maxLength(50000)])
    });
    if (this.caseDetail?.allyCaseRef) this.videoForm.disable();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.contentInit();
  }

  ngAfterContentInit(): void {
    this.contentInit();
  }

  private showAlert(message: string ,positiveCallback: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'alert',
      message,
      'Ok',
    );

    this.popUpService.setPositiveAction(positiveCallback);

    const alertDialog = this.popUpService.getPopUpView('alertDialog');
    this.popUpService.showDiv(alertDialog);
  }

  private contentInit() {
    if (this.caseDetail) {
      this.video = this.caseDetail.status.find(element => element.status.status.toLowerCase() === 'view');

      if (this.video.state === 'FINISHED') {
        this.isVideoComplete = true;
      }
      else {
        this.isVideoComplete = false;
      }

      if (this.caseDetail?.allyCaseRef) this.disableInputs = true;
      else this.disableInputs = CommonFuntions.checkIfHoldOrCancelledOrFinishedorMd(this.caseDetail, this.isMdManager);
    }

    if (this.caseDetail && this.caseDetail.video_notes) {
      if (this.videoForm != null) {this.videoForm.get('notes').setValue(this.caseDetail.video_notes);}
      this.sucessMessage = 'Updated video for the case';
    }
    else {
      if (this.videoForm != null) {this.videoForm.get('notes').setValue('');}
    }
  }

  saveVideoNotes(case_id: number) {
    if (CommonFuntions.guardForAction(this.loginService,
      this.router)) {
      if (this.videoForm.valid) {

        if (this.isVideoComplete) {
          return;
        }

        const notes = this.videoForm.get('notes').value;

        if (!case_id) {return;}

        if (!this.canContinue(this.caseDetail, 'view')) {
          this.showAlert('Please complete previous stages to complete \'Video\'.' ,function () {
            this.popUpService.hideDiv('alertDialog');
          }.bind(this));
          return;
        }

        if (notes && case_id > 0) {
          if (CommonFuntions.guardForAction(this.loginService,
            this.router)) {

            const linkUrl = ConfigAssetLoaderService.getDomainURL() + '/case/doctor-consultation/' + this.caseDetail.id;

            const doctor = {};
            const doctorInfo = this.caseDetail.case_md_management.user;
            
            doctor['full_name'] = doctorInfo.full_name;
            doctor['user_id'] = doctorInfo.user_id;
            doctor['email'] = doctorInfo.email;
            doctor['first_name'] = doctorInfo.first_name;
            doctor['middle_name'] = doctorInfo.middle_name;
            doctor['last_name'] = doctorInfo.last_name;
            doctor['time_zone'] = doctorInfo.time_zone;
            doctor['time_zone_offset'] = doctorInfo.time_zone_offset;

            this.isLoading = true;
            this.caseService.updateVideoNotes(case_id, notes, doctor, linkUrl, this.caseDetail.case_identifier).subscribe(
              function (_result) {
                this.showAlert('Finished video for the case.',function () {
                  this.popUpService.hideDiv('alertDialog');
                }.bind(this));
                const user = this.loginService.getCurrentUser();
                this.caseService.getCaseById(this.caseDetail.id, user.user_id).subscribe(
                  function (caseDetails) {
                    this.caseDetail = caseDetails;

                    const user = this.loginService.getCurrentUser();

                    const inprogressList = CommonFuntions.setStatusInProgress(this.caseDetail.status, 'view');

                    const statusList = [];

                    inprogressList.forEach(eachStatus => {
                      statusList.push({ status_id: eachStatus.status.id });
                    });
                    this.caseService.updateStaus(
                      statusList,
                      this.caseDetail.id
                    ).subscribe(
                      function (_result) {
                        this.caseService.getCaseById(this.caseDetail.id, user.user_id).subscribe(
                          function (caseDetails) {
                            caseDetails;
                            caseDetails.date_mr_complete = moment(caseDetails.date_mr_complete).format('MM/DD/YYYY, h:mm A');
                            caseDetails['final_reports'] = this.object.final_reports;
                            caseDetails['document_for_download'] = this.object.document_for_download;
                            caseDetails['translated_document'] = this.object.translated_document;
                            this.object.caseEmitter.emit(caseDetails);
                            this.object.showStatusEmitter.emit(this.inprogressList[0].status.status);
                            this.object.isLoading = false;
                          }.bind({
                            object: this,
                            inprogressList
                          }),
                          function(_error){
                            this.showAlert('Error while getting the case.' ,function () {
                              this.popUpService.hideDiv('alertDialog');
                            }.bind(this));
                            this.caseDetail = null;
                            this.isLoading = false;
                          }.bind(this)
                        );
                      }.bind(this),
                      function(_error) {
                        this.showAlert('Cannot update status of case.' ,function () {
                          this.popUpService.hideDiv('alertDialog');
                        }.bind(this));
                        this.isLoading = false;
                      }.bind(this)
                    );
                  }.bind(this),
                  function(_error) {
                    this.showAlert('Error while getting the case.' ,function () {
                      this.popUpService.hideDiv('alertDialog');
                    }.bind(this));										
                    this.caseDetail = null;
                    this.isLoading = false;
                  }.bind(this)
                );
              }.bind(this), (_error) => {
                this.isLoading = false;
                // alert(this.userService.handleError(error, this.operation.toLowerCase()));
              }
            );
          }
        }
        else {
          this.isLoading = false;
        }

      }
      else {
        CommonFuntions.validateAllFormFields(this.videoForm);
        this.isLoading = false;
      }
    }
  }

  get videoNotes() {
    return this.videoForm.get('notes') as FormControl;
  }
}




<div class="box box--white box--no-pad box--radius margin--bottom-2x  overlay__wrapper ">
	<h2 class="questions__title pull-left case-section-title" style="cursor: pointer;">
		Patient’s Questions
	</h2>
	<a href="javascript:void(0)" class="go-back pull-right collapseToggle top10"
		[ngClass]="{collapseToggleActive: isQuestionCollapse===true}"
		(click)="isQuestionCollapse = !isQuestionCollapse">
		<span class="icon icon--time-nav"></span>
	</a>
	<div class="clearfix"></div>
	<div class="collapsibleContent" *ngIf="isQuestionCollapse">
		<div *ngFor="let question of case_questions">
			<div class="questions questionTxtbox"
				*ngIf="question.follow_up_id === null || question.follow_up_id !== maxFollowUpId || question.follow_up_id === 0">
				<div class=" questions__row">
					<div class="row">
						<div class="col col-md-2 ">
							<div class="questions__label ">
								Question{{question.question_number}}
							</div>
						</div>
						<div class="col-md-10 ">
							<div class="questions__questions">
								{{question.question_text}}
							</div>
						</div>
					</div>
					<ul>
					</ul>
				</div>
			</div>

		</div>
	</div>
</div>
<div class="header">
	<div class="header__page-name">
		<h1 class="heading heading--main">Users - Eligible Member</h1>
	</div>
	<div class="header__top-nav">
	
		<app-topmenu (searchValueEmitter)="searchString($event)" [searchValue]="searchValue" [userList]="'eligibleMember'" [activeUsers]="activeUsers" [inactiveUsers]="inactiveUsers" ></app-topmenu>
	</div>
</div>
<div class="wrapper--dash-content dash-content">
	<div *ngIf="errorString" class="message message--is-invalid">
		{{errorString}}
	</div>

	<table datatable [dtOptions]="dtOptions" class="row-border hover" id="doctor">
		<thead>
			<tr>
				<!-- <th>Name</th>
				<th>Email Address</th> -->
			</tr>
		</thead>
	</table>
</div>

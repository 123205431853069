/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PopUpService } from '../../services/pop-up.service';
import { forkJoin as observableForkJoin } from 'rxjs';
import { Component, OnInit, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { CommonFuntions } from '../../common/common.function';
import { LoginService } from '../../services/login.service';
import { CaseService } from '../../services/case.service';
import { UserService } from '../../services/userservice.service';
import { takeWhile } from 'rxjs/operators';

import { MessageModel } from './model/MessageModel';
import { StatusModel, CaseStatusEnum } from './model/StatusModel';
import { Status } from '../../common/model/Status';

import { Socket } from 'ngx-socket-io';
import { ActivityModel } from '../../domains/ActivityModel';
import { UserModel } from '../../domains/userModel';
import { find, get, isEqual, uniqBy } from 'lodash';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-casehistory',
  templateUrl: './casehistory.component.html',
  styleUrls: ['./casehistory.component.css']
})
export class CasehistoryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() caseDetail: any;
  @Input() activityChanges;
  @Input() caseStatusData;
  @Input() isMdManager;
  @Input() cmManagerList;
  frmAssignee: FormGroup;
  showStatus: string;
  messageThreadObj: any;
  role: string;
  profilePicPath: string;
  alive: boolean;
  comment: any = {
    message: '',
    case_id: 0,
    from_user_id: 0,
    to_user_id: 0
  };
  public caseDetails: any;
  assigneeList = [];
  careteamList = [];
  finishedList: StatusModel[];
  caseMessages: MessageModel[];
  caseMessagesPartner = [];
  sub: any;
  caseId: number;
  validateAssignee = false;
  showError: boolean;
  public userAvatar: any;
  messageReceiving = false
  activities: ActivityModel[]
  messageThread: any;
  combinedActivities = []
  filteredActivities = []
  finalHistory: (MessageModel | StatusModel)[];
  filter = 'Messages';
  isAllyCase = false;
  allyGroups = [
    {
      full_name: 'Nurse Ally',
      user_type: 'Nurse Ally',
      isAlly: true
    },
    {
      full_name: 'Case Coordinator',
      user_type: 'Case Coordinator',
      isAlly: true
    }
  ];
  isHistoryCollapse = true;
  
  constructor(
    private loginService: LoginService,
    private caseService: CaseService,
    private userService: UserService,
    private route: ActivatedRoute,
    private socket: Socket,
    private toastrService: ToastrService,
    private popUpService: PopUpService
  ) {
    this.caseMessages = [];
    this.finishedList = [];
    this.finalHistory = [];
  }

  ngOnInit() {
    this.socket.connect();
    this.showError = true;
    this.alive = true;
    this.profilePicPath = '/assets/images/profile-pic-male.png';
    this.userAvatar = {};
    const user = this.loginService.getCurrentUser();
    this.role = user.user_type.type.toLowerCase();

    if(this.role.toLowerCase() !== 'doctor' && this.role.toLowerCase() !== 'translator'){
      this.isAllyCase = this.checkAllyCase();
    }

    this.sub = this.route.params
      .subscribe(params => {
        const oldCaseId = this.caseId;
        if (this.role === 'doctor') {
          this.caseId = +params['caseId'];
        }
        else {
          this.caseId = +params['caseid'];
        }
        if (oldCaseId != this.caseId) {
          
          // do not add member to list for doctors
          this.setDataInit();
          // disconnect previous socket and make new connections
          console.log('socket:check');
          if (this.socket) this.socket.disconnect();
          setTimeout(()=>{
            this.socket.connect();
            this.socketConnection();
          }, 200);
        }

        const user = this.loginService.getCurrentUser();
        this.role = user.user_type.type.toLowerCase();

        if(this.role.toLowerCase() !== 'doctor' && this.role.toLowerCase() !== 'translator'){
          this.isAllyCase = this.checkAllyCase();
        }

        let title = 'Consulting Physician Note to Cloud';
        let titleValidator = [];
        if(this.role !== 'doctor'){
          titleValidator = [Validators.required];
          title = '';
        }
        this.frmAssignee = new FormGroup({
          message: new FormControl({value: '', disabled: this.isMdManager}, [Validators.required, Validators.maxLength(50000)]),
          title: new FormControl(title, titleValidator),
        });

        this.frmAssignee.addControl('user_case_messages', new FormArray([], CommonFuntions.fomrArrayMinLength(1)));

        if (this.isMdManager){
          this.frmAssignee.disable();
        }

        this.getAssigneeList();
      });
  }

  socketConnection() {
    const user = this.loginService.getCurrentUser();
    const socketId = user.user_id.toString() + this.caseId.toString();

    this.socket.on(window.btoa(socketId), (data) => {
      console.log('message:new', this.caseId);
      if (data.error) {
        this.showAlert('Failed to add comment.');
        return;
      }

      // to remove duplicate listener
      const isFound = find(this.combinedActivities, { uniqMessageRef: data.uniqMessageRef });
      
      if (!isFound && data.caseId && this.caseId === data.caseId) {
        const msg: MessageModel = new MessageModel(data, this.role);
        if (data.sender_user_id == user.user_id) {
          this.toastrService.success('Comment added successfully.');
          setTimeout(()=>{
            window.location.reload();
          },1000);
       
          
        }
        this.messageReceiving = true;
        if (msg.avatar) {
          this.userService.getUploadedFile(msg.avatar)
            .pipe(takeWhile(() => this.alive))
            .subscribe(
              result => {
                this.userAvatar[msg.sender_user_id] = result.image;
                msg.avatar = result.image;
                this.combinedActivities.unshift(msg);
                if (this.filter === 'Messages') {
                  this.filteredActivities.unshift(msg);
                }
                this.finalHistory.unshift(msg);
                this.messageReceiving = false;
              },
              (_err) => {
                msg.avatar = this.profilePicPath;
                this.combinedActivities.unshift(msg);
                this.finalHistory.unshift(msg);
                if (this.filter === 'Messages') {
                  this.filteredActivities.unshift(msg);
                }
                this.messageReceiving = false;
              }
            );
        }
        else {
          msg.avatar = this.profilePicPath;
          this.combinedActivities.unshift(msg);
          this.finalHistory.unshift(msg);
          if (this.filter === 'Messages') {
            this.filteredActivities.unshift(msg);
          }
          this.messageReceiving = false;
        }
      }
      else {
        console.log('ignored socket message as not related to current case');
      }
    });
  }

  private checkAllyCase(){
    return !!this.caseDetail.allyCaseNumber || !!this.caseDetail.allyCaseRef;

  }

  public showAlert(message: string, positiveAction?: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(positiveAction);
    }
    else {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) { this.popUpService.showDiv(dialog); }
  }

  getAvatar(avatar: string) {
    this.userService.getUploadedFile(avatar)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        result => result.image
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setDataInit();

    if (!!changes.caseStatusData && changes.caseStatusData.currentValue !== undefined) {
      this.handleCaseStatusDataChanges(changes.caseStatusData.currentValue);
    }
    else if (!!changes.activityChanges && changes.activityChanges.currentValue !== undefined) {
      this.handleActivitiesChanges(changes.activityChanges.currentValue);
    }

    // for fist time when case detail is received
    if (changes.caseDetail && changes.caseDetail.currentValue && !changes.caseDetail.previousValue) {
      this.addMDInAssigneeList();
    }
    
    if(this.role && this.role.toLowerCase() !== 'doctor' && this.role.toLowerCase() !== 'translator'){
      this.isAllyCase = this.checkAllyCase();
    }

    // refresh all case related data if case detail changed
    if (changes.caseDetail && changes.caseDetail.previousValue && changes.caseDetail.currentValue && changes.caseDetail.currentValue.id !== changes.caseDetail.previousValue.id) {
      this.addMDInAssigneeList();
      if (changes.activityChanges) this.handleActivitiesChanges(changes.activityChanges.currentValue);
      if (changes.caseStatusData) this.handleCaseStatusDataChanges(changes.caseStatusData.currentValue);
      this.isAllyCase = this.checkAllyCase();
      this.getAssigneeList();
    }
  }

  private addMDInAssigneeList(): void {
    const user = this.loginService.getCurrentUser();
    this.role = get(user, 'user_type.type', '').toLowerCase();
    const assigned_md_lists = this.caseDetail.case_md_management && this.caseDetail.case_md_management.assigned_md_list;
    if (assigned_md_lists && assigned_md_lists.length > 0 && this.role.toLowerCase() !== 'admin') {
      this.assigneeList = this.assigneeList.filter(element => element.user_type.toLowerCase() !== 'doctor');

      assigned_md_lists.forEach(mdElement => {
        const mdObject = this.assigneeList.some(element => element.to_user_id === mdElement.assigned_doc_user_id);

        // if not present and not te current user
        if (!mdObject && user.user_id != mdElement.assigned_doc_user_id) {
          const assigneeObj = {};
          assigneeObj['to_user_id'] = mdElement.assigned_doc_user_id;
          assigneeObj['full_name'] = mdElement.user.full_name;
          assigneeObj['user_type'] = 'Doctor';
          assigneeObj['email'] = mdElement.user.email;

          this.assigneeList.push(assigneeObj);
        }
      });
    }
  }

  /**
   * initiate data initializtion for the component
   */
  private setDataInit() {
    if (this.caseDetail != null && this.caseId !== undefined) {
      this.finishedList = [];

      this.constructCaseHistory();
  
      const found = this.assigneeList.some(function (el) {
        return el.full_name === this.caseDetail.user.full_name;
      }.bind(this));

      if (!found && this.caseDetail.user.user_type_id != 9 && this.caseDetail.user.email !== this.loginService.getCurrentUser().email && !['admin', 'doctor'].includes(this.role.toLowerCase())) {
        //# gitlab 468 do not add partner user to careteam
        let isPartnerForCareteam = false;
        const createrUserType = get(this.caseDetail, 'user.user_type.type', '').toLowerCase();
        if (this.isAllyCase && createrUserType === 'partner' && (this.role.toLowerCase() === 'careteam' || this.role.toLowerCase() === 'casecoordinator')) {
          isPartnerForCareteam = true;
        }

        if (!isPartnerForCareteam) {
          this.assigneeList.push({
            to_user_id: this.caseDetail.user.user_id,
            full_name: this.caseDetail.user.full_name, 
            user_type: this.caseDetail.user.user_type && this.caseDetail.user.user_type.type, 
            email: this.caseDetail.user.email
          });
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.socket.disconnect();
  }

  get assignee() {
    return this.frmAssignee.get('assignee') as FormControl;
  }

  get message() {
    return this.frmAssignee.get('message') as FormControl;
  }

  get user_case_messages() {
    return this.frmAssignee.get('user_case_messages') as FormArray;
  }

  get title() {
    return this.frmAssignee.get('title') as FormControl;
  }

  getAssigneeList() {
    this.assigneeList = [];
    
    if (this.role.toLowerCase() === 'partner') {
      const iMDCT = {};
      iMDCT['full_name'] = 'Alight Care Team';
      iMDCT['user_type'] = 'IMDGroups';
      this.assigneeList.push(iMDCT);
    }

    if (this.isAllyCase && (this.role.toLowerCase() === 'admin' || this.role.toLowerCase() === 'careteam'|| this.role.toLowerCase() === 'casecoordinator' || this.role.toLowerCase() === 'cmmanager')) {
      this.assigneeList = [...this.assigneeList, ...this.allyGroups];
    }

    let allyPartnerRef = null;
    if (this.caseDetail?.patient && this.caseDetail?.patient?.user && this.caseDetail?.patient?.user?.user_partners && this.caseDetail?.patient?.user?.user_partners[0]?.partner?.allyPartnerRef) allyPartnerRef = this.caseDetail?.patient?.user?.user_partners[0]?.partner?.allyPartnerRef;

    if (this.role.toLowerCase() !== 'admin' && this.role.toLowerCase() !== 'doctor' && this.role.toLowerCase() !== 'translator' && allyPartnerRef === null) {
      const patientObj = {};
      patientObj['to_user_id'] = this.caseDetail.patient.user.user_id;
      patientObj['full_name'] = this.caseDetail.patient.user.full_name;
      patientObj['user_type'] = 'Patient';
      patientObj['email'] = this.caseDetail.patient.user.email;

      this.assigneeList.push(patientObj);
    }

    if (this.cmManagerList && this.cmManagerList.length > 0) {
      this.cmManagerList.forEach(manager => {
        this.assigneeList.push({
          to_user_id: manager.user_id,
          full_name: manager.full_name, 
          user_type: manager.user_type && manager.user_type.type, 
          email: manager.email
        });
      });
    }

    // if already have assignedUsers list in case detail
    if (this.caseDetail && this.caseDetail.assignedUsers) {
      this.parseGetAssigneeList(this.caseDetail.assignedUsers);
    }
    else {
      this.caseService.getAssigneeList(this.caseId).subscribe(
        assignees => {
          this.parseGetAssigneeList(assignees);
        }
      );
    }

    // check if creater in list
    const found = this.assigneeList.some(function (el) {
      return el.full_name === this.caseDetail.user.full_name;
    }.bind(this));

    if (!found && this.caseDetail && this.caseDetail.user.user_type_id != 9 && this.caseDetail.user.email !== this.loginService.getCurrentUser().email && !['admin', 'doctor'].includes(this.role.toLowerCase())) {
      //# gitlab 468 do not add partner user to careteam
      let isPartnerForCareteam = false;
      const createrUserType =get(this.caseDetail, 'user.user_type.type', '').toLowerCase();
      if (this.isAllyCase && createrUserType === 'partner' && (this.role.toLowerCase() === 'careteam' || this.role.toLowerCase() === 'cmmanager' || this.role.toLowerCase() === 'casecoordinator')) {
        isPartnerForCareteam = true;
      }

      if (!isPartnerForCareteam) {
        this.assigneeList.push({
          to_user_id: this.caseDetail.user.user_id,
          full_name: this.caseDetail.user.full_name, 
          user_type: this.caseDetail.user.user_type && this.caseDetail.user.user_type.type, 
          email: this.caseDetail.user.email
        });
      }
    }

    this.assigneeList = uniqBy(this.assigneeList, 'full_name');
  }

  parseGetAssigneeList(assignees) {
    const user = this.loginService.getCurrentUser();
    if (this.role.toLowerCase() === 'partner' && !this.caseDetail?.patient?.user?.user_partners[0]?.partner.isElevatedPartner) {
      assignees = assignees.filter(assignee => assignee.user_type.type !== 'Translator');
    }
    if (this.role.toLowerCase() === 'doctor') {
      assignees = assignees.filter(assignee => assignee.user_type.type !== 'Translator');

      this.careteamList = assignees.filter(assignee => assignee.user_type_id === 2);
      assignees = assignees.filter(assignee => assignee.user_type_id !== 2);

      const iMDCT = {};
      iMDCT['full_name'] = 'Alight Care Team';
      iMDCT['user_type'] = 'IMDGroups';
      this.assigneeList.push(iMDCT);

      // doctor will have CM Care Team & doctors only in To section
      return;
    }
    
    assignees.forEach(
      assignee => {
        const assigneeObj = {};
        assigneeObj['to_user_id'] = assignee.user_id;
        assigneeObj['full_name'] = assignee.full_name;
        assigneeObj['user_type'] = assignee.user_type.type;
        assigneeObj['email'] = assignee.email;

        const found = this.assigneeList.some(function (el) {
          return el.full_name === assigneeObj['full_name'];
        }.bind(this));

        if (!found && assignee.email !== user.email) {
          // gitlab 468
          switch(this.role.toLowerCase()) {
          case 'admin':
            {
              if (assigneeObj['user_type'].toLowerCase() === 'careteam' || assigneeObj['user_type'].toLowerCase() === 'cmmanager' || assigneeObj['user_type'].toLowerCase() === 'casecoordinator') {
                this.assigneeList.push(assigneeObj);
              }
            }
            break;
          case'casecoordinator':
          case 'careteam': {
            if (this.isAllyCase) {
              if (assigneeObj['user_type'].toLowerCase() !== 'partner') this.assigneeList.push(assigneeObj);
            }
            else {
              this.assigneeList.push(assigneeObj);
            }
          }
            break;
          case 'cmmanager':  
            if (this.isAllyCase) {
              if (assigneeObj['user_type'].toLowerCase() !== 'partner') this.assigneeList.push(assigneeObj);
            }
            else {
              if (['careteam', 'cmmanager', 'translator', 'doctor', 'casecoordinator'].includes(assigneeObj['user_type'].toLowerCase())) {
                this.assigneeList.push(assigneeObj);
              }
            }
            break;
          default: {
            this.assigneeList.push(assigneeObj);
          }
          }
        }
      }
    );
    
    if (!find(this.assigneeList, { user_type: 'Careteam'}) && !find(this.assigneeList, { user_type: 'CaseCoordinator'}) && !find(this.assigneeList, { user_type: 'IMDGroups'}) && this.role.toLowerCase() === 'admin') {
      const iMDCT = {};
      iMDCT['full_name'] = 'CM Care Team';
      iMDCT['user_type'] = 'IMDGroups';
      this.assigneeList.push(iMDCT);
    }
  }

  suggestionListFormatter(data: any): string {
    if (data.hasOwnProperty('full_name') && !['IMDGroups', 'Nurse Ally', 'EMO Ally', 'MR Ally', 'Case Coordinator'].includes(data.user_type)) {
      if(data.user_type === 'Partner'){
        return  data.full_name + ' - ' + 'Client';
      }
      else if(data.user_type === 'Translator'){
        return  data.full_name + ' - ' + 'Summary Coordinator';
      }
      else{
        return data.full_name + ' - ' + data.user_type;
      }
    }
    else {
      return data.full_name;
    }
  }

  valueListFormatter(data: any): string {
    if (data.hasOwnProperty('full_name')) {
      this.addAssignee(data.full_name);
    }
    return '';
  }

  clearAssignee(user: FormControl) {
    const index = this.user_case_messages.controls.indexOf(user);

    if (index < 0) { return; }

    const formControl = this.user_case_messages.controls[index];
    this.assigneeList.push(formControl.value);
    this.user_case_messages.removeAt(index);
  }


  addAssignee(assignee: string) {
    const index = this.assigneeList.findIndex(assigneeItem => assigneeItem.full_name === assignee);

    if (index < 0) { return; }

    const partnerObj = this.assigneeList[index];

    if (partnerObj.isAlly) {
      this.user_case_messages.controls.forEach((element) => {
        if (element.value.isAlly) {
          this.assigneeList.push(element.value);
        }
      });
      this.user_case_messages.controls = this.user_case_messages.controls.filter((item) => {
        if (!item.value.isAlly) return item;
      });
    }

    this.assigneeList.splice(index, 1);

    this.user_case_messages.push(new FormControl(partnerObj));

    this.validateAssignee = true;
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    }
    catch (e) {
      return false;
    }
    return true;
  }
  /**
   * initiate construction of case history
   */
  constructCaseHistory() {
    const user = this.loginService.getCurrentUser();

    let forkJoinList = null;

    const role = this.role.toLowerCase();

    if (role === 'admin' || role ==='mdmanager' || role ==='careteam' || role ==='casecoordinator' || role ==='cmmanager' || (role==='partner' && this.caseDetail?.patient?.user?.user_partners[0]?.partner.isElevatedPartner)) {
      forkJoinList = observableForkJoin(
        [
          this.caseService.getCaseMessages(this.caseId, user.user_id),
          this.caseService.getFinishedList(this.caseId),
          this.caseService.getAllActivities(this.caseId),
        ]
      );
    }
    else if ((this.role.toLowerCase() === 'doctor') || (this.role.toLowerCase() === 'translator')) {
      forkJoinList = observableForkJoin(
        [
          this.caseService.getCaseMessages(this.caseId, user.user_id),
        ]
      );
    }
    else {
      forkJoinList = observableForkJoin(
        [this.caseService.getCaseMessages(this.caseId, user.user_id)]
      );
    }

    forkJoinList.pipe(takeWhile(() => this.alive))
      .subscribe(
        ([caseMessages, finishedStatuses, activities]) => {

          if (activities && activities.length > 0) {
            this.activities = activities.map((activity) => {
              let parsedActivity;
              if(activity.activity.length>0 && this.isJsonString(activity.activity)) {
                parsedActivity = JSON.parse(activity.activity);
                activity.activity= parsedActivity;
              }
              activity.user = activity.user as UserModel;
              return activity as ActivityModel;
            });
          }

          if (finishedStatuses) {
            this.finishedList = this.constructCompletedStatus(finishedStatuses, this.caseDetail);
          }

          this.caseMessages = this.constructCaseMessages(caseMessages);

          this.finalHistory = this.combineHistoryList(
            this.finishedList,
            this.caseMessages,
            this.role.toLowerCase()
          );
          this.combinedActivities = this.combineActivitiesList(this.caseMessages, this.activities, this.role.toLowerCase()).filter(message=>message);
          if (this.filter === 'Messages') {
            this.filteredActivities = this.combinedActivities.filter(activity => (activity || {}).isComment && (activity.parent_message_id == null || activity.parent_message_id == ''));
            this.messageThread = this.combinedActivities.filter(activity => (activity || {}).isComment);
          }
        }
      );
  }

  private constructCompletedStatus(finishedStatuses: any[], caseDetail: Object): StatusModel[] {
    if (Array.isArray(finishedStatuses)) {
      finishedStatuses = finishedStatuses.map(
        finish => {
          const statusObj: StatusModel = new StatusModel(finish);
          statusObj.setFinishedList(true);

          return statusObj;
        }
      );
    }
    else {
      finishedStatuses = [];
    }

    if (caseDetail['case_status'].toLowerCase() === 'hold') {
      const holdStatus: StatusModel = new StatusModel();

      const status = new Status();
      status.status = 'hold';

      holdStatus.status = status;
      holdStatus.status_id = CaseStatusEnum.HOLD;
      holdStatus.user = null;
      holdStatus.created_at = caseDetail['created_at'];
      holdStatus.updated_at = caseDetail['updated_at'];
      holdStatus.follow_up_id = 9999999;
      holdStatus.finishedList = true;
      holdStatus.setHoldStatus(holdStatus.status_id);

      finishedStatuses.push(holdStatus);
    }

    // StatusModel.sort(finishedStatuses)

    return finishedStatuses;
  }

  combineActivitiesList(caseMessages: MessageModel[], activities: ActivityModel[], role: string) {
    let combinedList: (MessageModel | ActivityModel)[] = [];
    if (role === 'doctor' || role === 'interpreter') {
      combinedList = combinedList.concat(caseMessages);
    }
    else {
      combinedList = combinedList.concat(activities);
      combinedList = combinedList.concat(caseMessages);
    }
    combinedList.sort((obj1: MessageModel | ActivityModel, obj2: MessageModel | ActivityModel) => {
      const dateA = new Date(obj1.updated_at), dateB = new Date(obj2.updated_at);
      return dateA.getTime() - dateB.getTime();
    });
    const displayPicture: Object[] = combinedList.map((m: MessageModel | ActivityModel) => {
      const object = {};
      if (m instanceof MessageModel) {
        object['sender_user_id'] = m.sender_user_id;
        object['avatar'] = m.avatar;
        object['image'] = null;
      }
      else if (m instanceof ActivityModel) {
        object['sender_user_id'] = m.createdBy;
        object['avatar'] = m?.user?.avatar;
        object['image'] = null;
      }

      return object;
    });

    const distinct = (value, index, self) => {
      const objIndex = self.findIndex(obj => obj.sender_user_id === value.sender_user_id);

      if (!value.sender_user_id || value.sender_user_id == null || value.sender_user_id === undefined) { return false; }

      return objIndex === index;
    };

    const uniqueUsers = displayPicture.filter(distinct);

    this.getProfilePicture(uniqueUsers);

    return combinedList.reverse();
  }


  private combineHistoryList(finishedStatuses: StatusModel[], caseMessages: MessageModel[], role: string): (MessageModel | StatusModel)[] {
    let history: (MessageModel | StatusModel)[] = [];

    if (role === 'doctor' || role === 'interpreter') {
      history = history.concat(caseMessages);
    }
    else {
      history = history.concat(finishedStatuses);
      history = history.concat(caseMessages);
    }

    history.sort(function (obj1: MessageModel | StatusModel, obj2: MessageModel | StatusModel) {
      if (
        obj1 instanceof StatusModel &&
        obj2 instanceof StatusModel &&
        !obj1.isHold &&
        !obj2.isHold
      ) {
        if (obj1.status_id === 100 || obj2.status_id === 100) {
          if (new Date(obj1.updated_at) > new Date(obj2.updated_at)) {
            return 1;
          }
          else {
            return -1;
          }
        }

        if (obj1.status_id < 2 && obj2.status_id < 2) {
          if (obj1.status_id > obj2.status_id) {
            return 1;
          }
          else if (obj1.status_id < obj2.status_id) {
            return -1;
          }
        }
        else if (obj1.status_id < 2 || obj2.status_id < 2) { // if any of the stages is requested or mdmgmt do not compare
          if (obj2.status_id < 2) { return 1; }

          return -1;
        }

        if (obj1.follow_up_id < obj2.follow_up_id) {
          return -1;
        }
        else if (obj1.follow_up_id > obj2.follow_up_id) {
          return 1;
        }
        else {
          if (obj1.status_id > obj2.status_id) {
            return 1;
          }
          else if (obj1.status_id < obj2.status_id) {
            return -1;
          }
          return 0;
        }
      }
      else {
        const dateA = new Date(obj1.updated_at), dateB = new Date(obj2.updated_at);
        return dateA.getTime() - dateB.getTime();
      }
    });

    history.forEach(eachHistory => {
      if (eachHistory instanceof StatusModel) {
        if (eachHistory.getStatus().toLowerCase() === 'mdmgmt') {
          eachHistory.setStatus('MdManagement');
        }
        else if (eachHistory.getStatus().toLowerCase() === 'view') {
          eachHistory.setStatus('Video');
        }
        else if (eachHistory.getStatus().toLowerCase() === 'translatedmr') {
          eachHistory.setStatus('CompileMR');
        }
      }
    });

    const displayPicture: Object[] = history.filter(h => h instanceof MessageModel).map((m: MessageModel) => {
      const object = {};
      object['sender_user_id'] = m.sender_user_id;
      object['avatar'] = m.avatar;
      object['image'] = null;

      return object;
    });

    const distinct = (value, index, self) => {
      const objIndex = self.findIndex(obj => obj.sender_user_id === value.sender_user_id);

      if (!value.sender_user_id || value.sender_user_id == null || value.sender_user_id === undefined) { return false; }

      return objIndex === index;
    };

    const uniqueUsers = displayPicture.filter(distinct);

    this.getProfilePicture(uniqueUsers);

    return history.reverse();
  }


  /**
   * onClick function for 'Add Comment' button
   * @param event event on 'Add Comment' button click
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  postComment(messageThread: any) {
    let valueObj = {};
    valueObj['user_case_messages'] = this.frmAssignee.value.user_case_messages;
    valueObj['title'] = this.frmAssignee.value.title;
    // valueObj['parent_message_id'] = this.messageThreadObj.parent_message_id;
    // valueObj['chatter_identifier'] = this.messageThreadObj.chatter_identifier;
    this.validateAssignee = true;
    this.frmAssignee.get('message').markAsTouched();
    this.frmAssignee.get('title').markAsTouched();

    if ((this.message.value == null
      || this.message.value === '') && messageThread === undefined) {
      return;
    }
    if (this.role !== 'doctor' && (this.title.value == null
      || this.title.value === '')  && messageThread === undefined) {
      return;
    }

    if (this.role === 'doctor') {
      this.frmAssignee.addControl(
        'case_id', new FormControl(this.caseId)
      );

      let found = false;
      this.user_case_messages.controls.forEach(userControl => {
        if (userControl.value.full_name === 'CM Care Team') {
          valueObj['user_case_messages'] = valueObj['user_case_messages'].filter(user => user.full_name !== 'CM Care Team');

          found = true;
        }
      });

      if (found) {
        this.careteamList.forEach(careteam => {
          const assigneeObj = {};
          assigneeObj['to_user_id'] = careteam.user_id;
          assigneeObj['full_name'] = careteam.full_name;
          assigneeObj['user_type'] = careteam.user_type.type;
          assigneeObj['email'] = careteam.email;

          valueObj['user_case_messages'].push(assigneeObj);
        });
      }

    }
    else {
      this.frmAssignee.addControl(
        'case_id', new FormControl(this.caseDetail.id)
      );
    }

    if (this.role === 'mdmanager' || this.role === 'translator' || this.role === 'cmmanager' || this.role === 'patient') {
      this.frmAssignee.get('user_case_messages').clearValidators();
      this.frmAssignee.get('user_case_messages').updateValueAndValidity();
    }

    if (this.role === 'partner') {
      this.user_case_messages.controls.forEach(userControl => {
        if (userControl.value.full_name === 'CM Care Team') {
          valueObj['visibleForCareteam'] = true;
        }
      });
    }

    // Merging valueObj with form value but donot modify form value
    valueObj = Object.assign(Object.assign({}, this.frmAssignee.value), valueObj);

    if (this.frmAssignee.valid || messageThread !== undefined) {
      if (!valueObj['case_id']) valueObj['case_id'] = this.caseId;

      
      if(messageThread !== undefined){
        const assigneeObj = {};
        valueObj['message'] = messageThread.reply_message;
        valueObj['title'] = messageThread.reply_title;
        valueObj['chatter_identifier'] = messageThread.parent_activity.chatter_identifier;
        valueObj['parent_message_id'] = messageThread.parent_activity.id;
        assigneeObj['to_user_id'] = messageThread.parent_activity.sender_user_id;
        assigneeObj['full_name'] = messageThread.parent_activity.sender;
        assigneeObj['user_type'] = messageThread.parent_activity.user_type;
        valueObj['user_case_messages'].push(assigneeObj);
        valueObj['to_user_id'] = messageThread.parent_activity.sender_user_id;
        
      }
      
      
      this.caseService.addComment(valueObj);
      this.user_case_messages.controls.forEach(control => {
        const index = this.user_case_messages.controls.indexOf(control);
        if (index < 0) { return; }

        this.assigneeList.push(control.value);

      });

      this.user_case_messages.controls = [];
      valueObj = {};
      this.showError = false;
      this.resetForm();

      setTimeout(() => {
        this.showError = false;
        this.resetForm();
      }, 300);


    }
    else {
      this.toastrService.warning('Form Invalid');
      CommonFuntions.validateAllFormFields(this.frmAssignee);
      this.showError = true;
    }


  }

  /**
   * reset the view for add comment form
   * @param event user event after button click
   */
  resetForm(event?: Event) {
    if (event) { event.preventDefault(); }
    this.validateAssignee = false;
    this.frmAssignee.reset();
    if(this.role === 'doctor'){
      this.frmAssignee.get('title').setValue('Consulting Physician Note to Cloud');
      this.frmAssignee.get('title').clearValidators();
    }
    this.frmAssignee.get('message').markAsUntouched();
    this.frmAssignee.get('title').markAsUntouched();
    this.frmAssignee.markAsUntouched();
  }

  /**
   * revert frmAssignee formControl to initial state
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  removeControls() {
    this.frmAssignee.removeControl('case_id');
    this.frmAssignee.removeControl('sender');
    this.frmAssignee.removeControl('case_identifier');
    this.frmAssignee.removeControl('patient_full_name');
    this.frmAssignee.removeControl('user_id');
    this.frmAssignee.removeControl('linkUrl');
    this.frmAssignee.removeControl('user_case_messages');
    this.frmAssignee.removeControl('sender_full_name');
    this.frmAssignee.removeControl('partner_name');
    this.frmAssignee.removeControl('sender_user_type');
    this.frmAssignee.addControl('user_case_messages', new FormArray([], CommonFuntions.fomrArrayMinLength(1)));
  }

  /**
   * returns length of combinedActivitiesLength array for view
   * @returns boolean
   */
  public combinedActivitiesLength(): boolean {
    if (!this.combinedActivities) { return false; }

    return this.combinedActivities.length > 0 ? true : false;
  }

  /**
   * return boolean to show selectable reciever
   * @returns boolean
   */
  public showToField(): boolean {
    if (!this.role || this.role == null) { return false; }

    return (this.role === 'admin' || this.role === 'careteam' || this.role === 'casecoordinator' || this.role === 'cmmanager' || this.role === 'partner' || this.role === 'doctor') ? true : false;
  }

  /**
   * function to construct messages in the case
   * @param {any[] | object} messages list of messages viewable to the user for the case
   * @returns {MessageModel[]} case messages viewable to the logged user
   */
  private constructCaseMessages(messages: any[] | object): MessageModel[] {

    if (!Array.isArray(messages)) {
      return [];
    }

    const messagesList: MessageModel[] = [];

    messages.forEach(
      message => {

        const msg: MessageModel = new MessageModel(message, this.role);

        messagesList.push(msg);

      }
    );

    return messagesList;
  }

  /**
   * get avatar image and put it in a map
   * @param {any[]} avatar list of user_id and avatar location for case messages
   * @returns a map with user_id and avatar_location
   */
  private getProfilePicture(avatar: any[]) {
    avatar.forEach(img => {
      if (img.avatar) {
        this.userService.getUploadedFile(img.avatar)
          .pipe(takeWhile(() => this.alive))
          .subscribe(
            result => {
              this.userAvatar[img.sender_user_id] = result.image;
            }
          );
      }
    });

  }

  private handleCaseStatusDataChanges(data: any) {
    data.forEach(element => {
      const statusItem = new StatusModel(element);
      this.finalHistory.unshift(statusItem);
    });
  }
  private handleActivitiesChanges(data: any) {
    if (data[0]) {
      let filter = '';
      if (data[0].isComment && this.filter === 'Messages') {
        filter = 'Messages';
      }
      else if (data[0].activityEvent === 'CASE_STAGE' && this.filter === 'Activity') {
        filter = 'Activity';
      }
      else if (data[0].activityEvent === 'FILE' && this.filter === 'Uploads') {
        filter = 'Uploads';
      }
      else if (!data[0].isComment && data[0].activityEvent !== 'CASE_STAGE' && data[0].activityEvent !== 'FILE' && this.filter === 'Events') {
        filter = 'Events';
      }
      else {
        filter = this.filter;
      }
      
      // check duplicate if any
      const  doc = find(this.combinedActivities, { id: data[0].id });
      if (!doc || !isEqual(doc, data[0])) {
        this.combinedActivities.unshift(data[0]);
      }

      this.handleFilterChange(filter);
    }
  }

  public getFilterOptions(): string[] {
    return [
      'All',
      'Messages',
      'Activity',
      'Uploads',
      'Events',
    ];
  }

  public handleFilterChange(event): void {
    this.filter = event;
    let activities = [];
    switch (event) {
    case 'Messages':
      activities = this.combinedActivities.filter(message => (message || {}).isComment && !message.parent_message_id);
      break;
    case 'Activity':
      activities = this.combinedActivities.filter(message => (message || {}).activityEvent === 'CASE_STAGE');
      break;
    case 'Uploads':
      activities = this.combinedActivities.filter(message => (message || {}).activityEvent === 'FILE');
      break;
    case 'Events':
      activities = this.combinedActivities.filter(message => !(message || {}).isComment && (message || {}).activityEvent !== 'CASE_STAGE' && (message || {}).activityEvent !== 'FILE' && (message || {}).activityEvent !== 'TRACKING_LINE');
      break;
    default:
      activities = this.combinedActivities;
      break;

    }
    this.filteredActivities = activities;
  }

  get filterVisible(): boolean {
    return this.role==='admin' || this.role==='careteam' ||  this.role==='casecoordinator' || this.role==='cmmanager' || this.role==='mdmanager' || (this.role==='partner' && this.caseDetail?.patient?.user?.user_partners[0]?.partner.isElevatedPartner);
  }

  isElevatedPartnerCheck() {
    const isElevated = get(this.caseDetail, 'patient.user.user_partners.0.partner.isElevatedPartner', false);
    return isElevated;
  }

  isSFLogin() {
    return this.loginService.isSFLogin();
  }
}

/* eslint-disable @typescript-eslint/explicit-member-accessibility */
export class AttachmentCaseModel {

    caseCreationStage: any;
    translatorAttachmentStage: any;
    translateWRStage: any;
    translateDRStage: any;

    constructor() {
      this.caseCreationStage = [];
      this.translatorAttachmentStage = [];
      this.translateWRStage = [];
      this.translateDRStage = [];
    }

}

<app-dialog></app-dialog>

<div class="header">
  <div class="header__page-name">
    <h1 class="heading heading--main">Edit Email Template</h1>
  </div>
</div>

<div class="wrapper--dash-content dash-content">
  <div class="tabs__content" [class.overlay--visible]="isInprogress">
    <div class="overlay__content">
      <div class="overlay__spinner">
        <span class="icon icon--loading"></span>
      </div>
    </div>

    <div class="form tabs__content--white-bg">
		<form [formGroup]="emailTemplateForm" (mouseenter)="dynamicVariable()" novalidate>
			<div class="row">
				<div class="col col__md-6">
					<div class="form__row">
						<label for="" class="form__label">Name</label>
						<div class="form__field">
							<input type="text" formControlName="name" [appFormatOnly]='"trimonly"' />
							<div *ngIf="name.invalid && (name.dirty || name.touched )" class="message message--is-invalid">
								<div *ngIf="name.errors.required">
									Name is required.
								</div>
							</div>
						</div>
					</div>
                </div>
                <div class="col col__md-6">
					<div class="form__row">
						<label for="" class="form__label">Subject</label>
						<div class="form__field">
							<!-- <input type="text" [mention]="items" formControlName="subject" [appFormatOnly]='"trimonly"' /> -->
							<input type="text" formControlName="subject" [appFormatOnly]='"trimonly"' />
							<div *ngIf="subject.invalid && (subject.dirty || subject.touched )" class="message message--is-invalid">
								<div *ngIf="subject.errors.required">
									Subject is required.
								</div>
							</div>
						</div>
					</div>
                </div>
            </div>

			<div class="row">
				<div class="col col__md-6">
					<div class="form__row">
						<label for="" class="form__label">Body
							<span class="icon icon--info tooltip-box">
								<span class="tooltip-box-text top-minus-45 large-tool-text">
									Please use these variables for dynamic inputs. <br/>
									<span *ngFor="let item of items">@{{item}}<br/></span>
								</span>
							</span>
							</label>
						<div class="form__field">
							<ckeditor class="customheader" formControlName="body" rows="10" #ckeditorField [config]="{toolbar: []}"></ckeditor>
							<div *ngIf="body.invalid && (body.dirty || body.touched )" class="message message--is-invalid">
								<div *ngIf="body.errors.required">
									Body is required.
								</div>
							</div>
						</div>
					</div>
                </div>
            </div>

			<div class="row">
				<!-- <div class="col col__md-6">
					<div class="form__row">
						<label for="" class="form__label">Reply To</label>
						<div class="form__field">
							<input type="text" formControlName="reply_to" [appFormatOnly]='"trimonly"' />
						</div>
					</div>
                </div> -->
				<div class="col-6 col__md-6">
					<div class="form__row">
						<label for="" class="form__label">Apply To</label>
						<div class="form__field">
							<select formControlName="apply_to" (change)="dynamicVariable()">
								<option value="" >Select</option>
                                <option *ngFor="let apply of applyToList" [value]="apply?.value">
                                    {{ apply?.label }}
                                </option>
							</select>
							<div *ngIf="apply_to.invalid && (apply_to.dirty || apply_to.touched )" class="message message--is-invalid">
								<div *ngIf="apply_to.errors.required">
									Apply To is required.
								</div>
							</div>
						</div>
					</div>
                </div>

            </div>
            
            <div class="btn-row">
                <button class="btn btn--green" (click)="onSubmit()">Save</button>
                <a [routerLink]="['/email-templates']" class="btn btn--red">Cancel</a>
            </div>
		</form>

	</div>
  </div>
</div>


/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { LoginService } from '../../../services/login.service';
import { Router, UrlTree, UrlSegmentGroup, UrlSegment, PRIMARY_OUTLET } from '@angular/router';
import { CommonFuntions } from '../../../common/common.function';
import { UserService } from '../../../services/userservice.service';
import { ConfigAssetLoaderService } from '../../../services/config-asset-loader.service';
import { PlatformLocation } from '@angular/common';
import { PartnersModel } from '../../../domains/partnersModel';
import { PartnerService } from '../../../services/partner.service';
import * as moment from 'moment/moment';
import { ValidationErrors } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { PopUpService } from '../../../services/pop-up.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { formulaInjectionValidator } from '../../../common/validator';

@Component({
  selector: 'app-create-partner',
  templateUrl: './create-partner.component.html',
  styleUrls: ['./create-partner.component.css']
})
export class CreatePartnerComponent implements OnInit {

  title: string
  partner_id: number;
  partner_name: string;
  partner_organization_id: number;
  case_type_id: number;

  primary_poc_fullname: string;
  primary_poc_title: string;
  primary_poc_email: string;
  secondary_poc_fullname: string;
  secondary_poc_title: string;
  secondary_poc_email: string;
  display_specialist: boolean;

  start_date: string;
  end_date: string;

  operation: string;
  partnerForm: FormGroup;
  partnerModel = new PartnersModel();
  label: string;
  partnerId: number

  organizationTypeList: any[] = [];
  planTypeList: any[] = [];
  passName: string;

  first_name: boolean;
  last_name: boolean;
  dob: boolean;
  enableEmailDomain: boolean;
  email_domain: string;
  gender: boolean;
  company_name: boolean;
  enableCode: boolean;
  code_value: string;
  is_code_value_changed: boolean;
  is_email_domain_changed: boolean;
  code_start_date: string;
  code_expiry_date: string;

  serviceA: boolean;
  serviceB: boolean;
  serviceC: boolean;
  serviceD: boolean;
  serviceE: boolean;

  public uploader: FileUploader
  public logoUploader: FileUploader
  partnerBannerImage: SafeResourceUrl
  partnerLogoImage: SafeResourceUrl

  min = 5;
  max = 6.5;

  dateInvalid = false;
  codeDateInvalid = false;
  codeStartDateInvalid = false;
  isAllyInputDisabled = true;

  constructor(
    public loginService: LoginService,
    public userService: UserService,
    public router: Router,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private location: PlatformLocation,
    private partnerService: PartnerService,
    public popUpService: PopUpService,
    public _sanitizer: DomSanitizer
  ) {
  }

  emitValue(): void {
    if (this.partnerForm.value.start_date) {
      const startDateMoment = moment(this.partnerForm.value.start_date);
      const endDateMoment = moment(this.partnerForm.value.end_date);
      if (!startDateMoment.isValid() || !endDateMoment.isValid()) {return;}
      if (endDateMoment.isBefore(startDateMoment)) {
        this.dateInvalid = true;
      }
      else {
        this.dateInvalid = false;
      }
    }
    if (this.partnerForm.value.code_start_date) {
      const codeStartDate = moment(this.partnerForm.value.code_start_date);
      const codeEndDate = moment(this.partnerForm.value.code_expiry_date);
      if (!codeStartDate.isValid() || !codeEndDate.isValid()) {return;}
      const date = moment().toDate();
      if (codeEndDate.isBefore(date)) {
        this.codeStartDateInvalid = true;
      }
      else {
        this.codeStartDateInvalid = false;
      }

      if (codeEndDate.isBefore(codeStartDate)) {
        this.codeDateInvalid = true;
      }
      else {
        this.codeDateInvalid = false;
      }
    }
  }
  validFormulaInjection(): void {
    const controlNames: string[] = Object.keys(this.partnerForm.controls);

    controlNames.forEach((controlName) => {
      const controlValue = this.partnerForm.get(controlName).value;

      if (typeof controlValue === 'string' && controlName !== 'secondary_poc_number' && controlName !== 'primary_poc_number') {

        const validators = this.partnerForm.controls[controlName].validator;
        const newValidators = validators ? [validators, formulaInjectionValidator()] : formulaInjectionValidator();
        
        this.partnerForm.controls[controlName].setValidators(newValidators);
        this.partnerForm.controls[controlName].updateValueAndValidity();
      }
    });

  }

  public showAlert(message: string, positiveAction?: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(positiveAction);
    }
    else {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) {this.popUpService.showDiv(dialog);}
  }

  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function) {

    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }

    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }

    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);

  }

  createPartner(form: FormGroup, event: Event) {
    event.preventDefault();
    if (CommonFuntions.guardForAction(this.loginService,
      this.router)) {
      // Empty string fields set to null
      this.setNull();
      if (form.valid) {
        const partner: PartnersModel = form.getRawValue() as PartnersModel;
        const momentStartDate = moment(partner.start_date);
        const momentEndDate = moment(partner.end_date);
        const codeStartDate = moment(partner.code_start_date);
        const codeEndDate = moment(partner.code_expiry_date);
        if (momentStartDate.isSameOrBefore(momentEndDate) && (partner.code_start_date ? codeStartDate.isSameOrBefore(codeEndDate) : true)) {
          if (this.operation.toLowerCase() === 'create') {
            partner.start_date = moment(partner.start_date).format('MM/DD/YYYY, hh:mm:ss A');
            partner.end_date = moment(partner.end_date).format('MM/DD/YYYY, hh:mm:ss A');
            
            this.showConfirmation('Are you sure you want to add client?',function(){
              this.partnerService.createPartner(partner).subscribe(
                function(result) {
                  if (result.partner_id) {
                    this.showAlert('Client Added sucessfully.', function(){
                      this.router.navigate(['/clients/dashboard/' + result.partner_id]);
                    }.bind(this));
                  }
                  else {
                    let message = null;
                    let error = null;
                    if (result['msg']) {
                      error = new HttpErrorResponse({
                        status: 400,
                        error: { status: 400, message: result['msg'] }
                      });
                    }
                    else {
                      error = new HttpErrorResponse({
                        status: 400
                      });
                    }
  
                    message = this.partnerService.handleError(error, this.operation.toLowerCase());
                    this.showAlert(`${message}.`);
                  }
                  this.popUpService.hideDiv('confirmDialog');
  
                }.bind(this), function(error) {
                  const message = this.partnerService.handleError(error, this.operation.toLowerCase());
                  this.showAlert(`${message}.`);
                  this.popUpService.hideDiv('confirmDialog');

                }.bind(this)
              );
              this.popUpService.hideDiv('confirmDialog');

            }.bind(this));
          }
          else if (this.operation.toLowerCase() === 'edit') {
            this.showConfirmation('Are you sure you want to edit client?',function() {
              partner.start_date = moment(partner.start_date).format('MM/DD/YYYY, hh:mm:ss A');
              partner.end_date = moment(partner.end_date).format('MM/DD/YYYY, hh:mm:ss A');
              this.partnerService.updatePartner(partner, this.is_code_value_changed, this.is_email_domain_changed).subscribe(
                (_result) => {
                  if (partner.partner_id) {
                    this.showAlert('Client Edited sucessfully.',function(){
                      this.router.navigate(['/clients/dashboard/' + partner.partner_id]);
                    }.bind(this));
                  }
                  this.popUpService.hideDiv('confirmDialog');
  
                }, function(error) {
                  this.showAlert(`${this.partnerService.handleError(error, this.operation.toLowerCase())}.`);
                  this.popUpService.hideDiv('confirmDialog');
                }.bind(this)
              );
              this.popUpService.hideDiv('confirmDialog');
            }.bind(this));
          }
        }
        else {
          // this.dateInvalid = true
          CommonFuntions.validateAllFormFields(form);
          return false;
        }
      }
      else {
        CommonFuntions.validateAllFormFields(form);
      }
    }
  }


  getPartner(partner_id: number) {
    return this.partnerService.getPartner(partner_id);
  }

  deletePartner(partner_id: number, event: Event) {
    event.preventDefault();
    return this.partnerService.deletePartner(partner_id);
  }

  initializeForm(): FormGroup {
    return new FormGroup(
      {
        partner_name: new FormControl(this.partnerModel.partner_name, [
          Validators.required,
          Validators.maxLength(50)
        ]),
        scan_link: new FormControl(this.partnerModel.scan_link, [
          Validators.required,
        ]),
        release_form_link: new FormControl(this.partnerModel.release_form_link, [
          Validators.required,
        ]),
        partner_organization_id: new FormControl(0, [
          Validators.required,
          CommonFuntions.validateDropDown
        ]
        ),
        case_type_id: new FormControl(0, [
          Validators.required,
          CommonFuntions.validateDropDown
        ]
        ),
        primary_poc_fullname: new FormControl(this.partnerModel.primary_poc_fullname, [
          Validators.required,
          Validators.maxLength(50)
        ]),
        primary_poc_title: new FormControl(this.partnerModel.primary_poc_title/* , Validators.maxLength(50) */),
        primary_poc_number: new FormControl(this.partnerModel.primary_poc_number, [Validators.maxLength(15), Validators.minLength(9)]),
        primary_poc_email: new FormControl(this.partnerModel.primary_poc_email, this.customEmailValidator),
        secondary_poc_fullname: new FormControl(this.partnerModel.secondary_poc_fullname/* ,Validators.maxLength(50) */),
        secondary_poc_title: new FormControl(this.partnerModel.secondary_poc_title/* , Validators.maxLength(50) */),
        secondary_poc_number: new FormControl(this.partnerModel.secondary_poc_number, [Validators.maxLength(15), Validators.minLength(9)]),
        secondary_poc_email: new FormControl(this.partnerModel.secondary_poc_email, this.customEmailValidator/* , Validators.email */),
        start_date: new FormControl(this.partnerModel.start_date, Validators.required),
        end_date: new FormControl(this.partnerModel.end_date, Validators.required),
        phrase_text: new FormControl(
          this.partnerModel.phrase_text, Validators.maxLength(65)
        ),
        logo_location: new FormControl(this.partnerModel.logo_location),
        partner_logo: new FormControl(this.partnerModel.partner_logo),
        display_specialist: new FormControl(this.partnerModel.display_specialist, Validators.required),
        notification_email: new FormControl(this.partnerModel.notification_email),
        
        allyPartnerRef: new FormControl({ value: this.partnerModel.allyPartnerRef, disabled: this.isAllyInputDisabled },formulaInjectionValidator()),

        // for eligibility and service type
        first_name: new FormControl(this.first_name),
        last_name: new FormControl(this.last_name),
        dob: new FormControl(this.dob),
        enableEmailDomain: new FormControl(this.enableEmailDomain),
        email_domain: new FormControl(this.email_domain),
        gender: new FormControl(this.gender),
        company_name: new FormControl(this.company_name),
        enableCode: new FormControl(this.enableCode),
        code_value: new FormControl(this.code_value),
        code_start_date: new FormControl(this.code_start_date),
        code_expiry_date: new FormControl(this.code_expiry_date),
        serviceA: new FormControl(this.serviceA),
        serviceB: new FormControl(this.serviceB),
        serviceC: new FormControl(this.serviceC),
        serviceD: new FormControl(this.serviceD),
        serviceE: new FormControl(this.serviceE),
      }, 
      {
        updateOn: 'change'
      }
    );
  }

  ngOnInit(): void {
    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g.segments;

    this.operation = s[1].path;
    this.operation = this.operation.toLowerCase() === 'create' ? 'Create' : 'Edit';
    this.title = this.operation.toLowerCase() === 'create' ? 'Add New Client' : 'Edit Client';
    this.label = this.operation.toLowerCase() === 'create' ? 'Add Client' : 'Save';
    this.is_code_value_changed = false;
    this.is_email_domain_changed = false;

    this.partnerId = s[2] ? parseInt(s[2].path) : null;

    if (s[2] && isNaN(this.partnerId)) {
      this.router.navigate(['/page-not-found']);
      return;
    }

    this.uploader = new FileUploader(
      {
        url: ConfigAssetLoaderService.getApiURL() + '/partnerupload',
        itemAlias: 'file',
        allowedMimeType: [
          'image/jpg',
          'image/jpeg',
          'image/png',
          'image/gif',
          'image/tif',
          'image/bmp'
        ],
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer ' + localStorage.getItem('token')
          }
        ],
        maxFileSize: 10 * 1024 * 1024
      }
    );

    this.addPropertiesToUploader(this.uploader, 'cover');

    this.logoUploader = new FileUploader(
      {
        url: ConfigAssetLoaderService.getApiURL() + '/partnerbacklogo',
        itemAlias: 'file',
        allowedMimeType: [
          'image/jpg',
          'image/jpeg',
          'image/png',
          'image/gif',
          'image/tif',
          'image/bmp'
        ],
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer ' + localStorage.getItem('token')
          }
        ],
        maxFileSize: 10 * 1024 * 1024
      }
    );

    this.addPropertiesToUploader(this.logoUploader, 'logo');

    const currentUser = this.loginService.getCurrentUser();
    const role = currentUser.user_type.type.toLowerCase();

    if (role === 'admin') {
      this.isAllyInputDisabled = false;
    }

    this.partnerForm = this.initializeForm();
    this.validFormulaInjection();

    if (role == 'admin' || role == 'cmmanager' || role == 'careteam' || role == 'partner' || role =='casecoordinator') {
      if (this.partnerId) {
        this.getPartner(this.partnerId).subscribe(
          function (result) {
            this.uploader.options.url = this.uploader.options.url + '?partnerid=' + this.partnerId;

            this.logoUploader.options.url = this.logoUploader.options.url + '?partnerid=' + this.partnerId;

            this.partnerForm.addControl('partner_id', new FormControl(''));
            this.partnerModel = result as PartnersModel;

            if (this.partnerModel['logo_location'] && this.partnerModel['logo_location'] != null) {
              this.getPartnerBanner(this.partnerModel['logo_location']);
            }

            if (this.partnerModel['partner_logo'] && this.partnerModel['partner_logo'] != null) {
              this.getPartnerLogo(this.partnerModel['partner_logo']);
            }

            /* if (this.partnerModel['logo_location'] == null || this.partnerModel['logo_location'] ) {
              delete this.partnerModel['logo_location'];
            } */

            this.partnerForm.setValue(this.partnerModel);
          }.bind(this)
        );

      }
    
    }
    else {
      this.router.navigate(['/']);
    }

    this.partnerService.getOrganizationTypeList().subscribe(
      organizationType => {
        this.organizationTypeList = organizationType;
        this.organizationTypeList.splice(0, 0, {
          organization_id: 0,
          organization_type: 'Select'
        });
      }, (_error) => {
        this.organizationTypeList.splice(0, 0, {
          organization_id: 0,
          organization_type: 'Select'
        });
      }
    );

    this.partnerService.getPlanTypeList().subscribe(
      planType => {
        this.planTypeList = planType;
        this.planTypeList.splice(0, 0, {
          plan_id: 0,
          plan_type: 'Select'
        });
      }, (_error) => {
        this.planTypeList.splice(0, 0, {
          plan_id: 0,
          plan_type: 'Select'
        });
      }
    );

  }

  // reads the binary data and returns as base64 data url
  private readBase64(file): Promise<any> {
    const reader = new FileReader();

    const future = new Promise((resolve, reject) => {
      reader.addEventListener('load', function () {
        resolve(reader.result);
      }, false);

      reader.addEventListener('error', function (event) {
        reject(event);
      }, false);

      // read the binary data and encode it as base64 data url
      reader.readAsDataURL(file);
    });

    return future;
  }

  // function to calculate whether value lies within range or not
  private between(value: number, min: number, max: number): boolean {
    if (value >= min && value <= max) {
      return true;
    }
    else {
      return false;
    }
  }

  // This function gets the dimesion of image when it is selected. 
  // It is not in use for now.
  onFileSelected(file: File) {
    this.readBase64(file).then((value) => {
      const img = new Image();
      img.onload = () => {
        const ratio = img.width / img.height;
        if (!this.between(ratio, this.min, this.max)) {
          this.showAlert('Image ratio should be 32:5.');
          this.logoUploader.clearQueue();
          this.logoUploader.cancelAll();
        }
        else {
          this.logoUploader.uploadAll();
        }
      };
      img.src = value;
    });

  }

  checkForPartner(partner_name: string, partner_id: number, event: Event) {
    event.preventDefault();
    return this.partnerService.checkDuplicatePartnerName(partner_id, partner_name);

  }

  setNull() {
    this.partnerForm.controls.email_domain.value == '' && this.partnerForm.controls.email_domain.setValue(null);
    this.partnerForm.controls.code_value.value == '' && this.partnerForm.controls.code_value.setValue(null);
    this.partnerForm.controls.code_start_date.value == '' && this.partnerForm.controls.code_start_date.setValue(null);
    this.partnerForm.controls.code_expiry_date.value == '' && this.partnerForm.controls.code_expiry_date.setValue(null);
  }


  enableEmailDomainCheck() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const bool = this.partnerForm.controls.enableEmailDomain.valueChanges.subscribe((flag: boolean) => {
      if (flag) {
        this.partnerForm.controls.email_domain.reset();
        this.partnerForm.controls.email_domain.setValidators(Validators.required);
        this.partnerForm.controls.email_domain.updateValueAndValidity();
      }
      else {
        this.partnerForm.controls.email_domain.reset();
        this.partnerForm.controls.email_domain.clearValidators();
        this.partnerForm.controls.email_domain.updateValueAndValidity();
      }
    });
  }

  enableCodeCheck() {
    this.partnerForm.controls.enableCode.valueChanges.subscribe((flag: boolean) => {
      if (flag) {
        this.partnerForm.controls.code_value.reset();
        this.partnerForm.controls.code_start_date.reset();
        this.partnerForm.controls.code_expiry_date.reset();
        this.partnerForm.controls.code_value.setValidators(Validators.required);
        this.partnerForm.controls.code_start_date.setValidators(Validators.required);
        this.partnerForm.controls.code_expiry_date.setValidators(Validators.required);
      }
      else {
        this.partnerForm.controls.code_value.reset();
        this.partnerForm.controls.code_start_date.reset();
        this.partnerForm.controls.code_expiry_date.reset();
        this.partnerForm.controls.code_value.clearValidators();
        this.partnerForm.controls.code_value.updateValueAndValidity();
        this.partnerForm.controls.code_start_date.clearValidators();
        this.partnerForm.controls.code_start_date.updateValueAndValidity();
        this.partnerForm.controls.code_expiry_date.clearValidators();
        this.partnerForm.controls.code_expiry_date.updateValueAndValidity();
      }
    });
  }

  get emailDomainCheck():AbstractControl {
    return this.partnerForm.get('enableEmailDomain');
  }

  get codeCheck():AbstractControl {
    return this.partnerForm.get('enableCode');
  }

  get primary_email(): AbstractControl{
    return this.partnerForm.get('primary_poc_email');
  }
  get secondary_email(): AbstractControl {
    return this.partnerForm.get('secondary_poc_email');
  }
  get primary_poc_number(): AbstractControl {
    return this.partnerForm.get('primary_poc_number');
  }

  get secondary_poc_number(): AbstractControl {
    return this.partnerForm.get('secondary_poc_number');
  }
  get secondary_poc_title_control(): AbstractControl {
    return this.partnerForm.get('secondary_poc_title');
  }
  get secondary_poc_fullname_control(): AbstractControl {
    return this.partnerForm.get('secondary_poc_fullname');
  }
  

  get logo_location(): AbstractControl {
    return this.partnerForm.get('logo_location');
  }

  get partner_logo(): AbstractControl {
    return this.partnerForm.get('partner_logo');
  }

  private customEmailValidator(control: AbstractControl): ValidationErrors {
    if (!control.value) {
      return null;
    }

    return CommonFuntions.ValidateEmail(control);
  }

  private addPropertiesToUploader(uploader: FileUploader, image: string) {

    if (image === 'cover') {
      uploader.onAfterAddingFile = (file) => {
        file.withCredentials = false;
        this.uploader.uploadAll();
      };
    }

    if (image === 'logo') {
      uploader.onAfterAddingFile = (file) => {
        file.withCredentials = false;
        this.onFileSelected(file._file);
      };
    }

    if (image === 'cover') {
      uploader.onCompleteItem = function (_item: any, response: any, status: any, _headers: any) {

        if (status && status == 200) {
          const responseObject = JSON.parse(response);
          this.getPartnerBanner(responseObject.location);
          this.logo_location.setValue(responseObject.location);
        }

      }.bind(this);
    }

    if (image === 'logo') {
      uploader.onCompleteItem = function (_item: any, response: any, status: any, _headers: any) {

        if (status && status == 200) {
          const responseObject = JSON.parse(response);
          this.getPartnerLogo(responseObject.location);
          this.partner_logo.setValue(responseObject.location);
        }

      }.bind(this);
    }

    uploader.onWhenAddingFileFailed = function (_item, filter, _option) {
      switch (filter.name) {
      case 'mimeType':
        this.showAlert('File should be an image.');
        break;
      case 'fileSize':
        this.showAlert('Files to be uploaded must be less than of size 50 MB.');
        break;
      default:
        break;
      }
    }.bind(this);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private getPartnerBanner(location: string) {
    this.partnerService.getPartnerBanner(location).subscribe(
      function (image) {
        this.partnerBannerImage = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + image
        );
      }.bind(
        this
      )
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private getPartnerLogo(location: string) {
    this.partnerService.getPartnerLogo(this.partnerId, location).subscribe(
      function (image) {
        this.partnerLogoImage = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + image
        );
      }.bind(
        this
      )
    );
  }

}


/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { MailServerService } from '../../services/mail-server.service';
import { PopUpService } from '../../services/pop-up.service';

const EDIT_CASE_SETTING = 'editClientAssociationCheck';

@Component({
  selector: 'app-case-settings',
  templateUrl: './case-settings.component.html',
  styleUrls: ['./case-settings.component.css']
})
export class CaseSettingsComponent implements OnInit {
  mailSettingId: number;
  selected: boolean;

  isCaseSettingLoading: boolean;

  constructor(
    private mailServerService: MailServerService,
    private popUpService: PopUpService
  ) {
    this.selected = false;
  }

  ngOnInit(): void {
    this.getCaseSettings();
  }

  getCaseSettings(): void {
    this.isCaseSettingLoading = true;
    this.mailServerService.getMailSettings().subscribe(
      result => {
        this.mailSettingId = result?.id;
        if (result?.client_association_check) this.selected = true;
        this.isCaseSettingLoading = false;
      }, 
      (_error) => {
        this.isCaseSettingLoading = false;
      }
    );
  }

  onClientAssociationCheckChange(value: boolean): void {
    this.showConfirmation('Are you sure? Client association check will be changed.',
      function (): void {
        this.editClientAssociationCheck(value);
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this),
      function (): void {
        this.selected = !value;
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this)
    );
  }

  editClientAssociationCheck(value: boolean): void {
    if (this.mailSettingId) {
      this.isCaseSettingLoading = true;
      this.mailServerService.editMailSetting(this.mailSettingId, { client_association_check: value }, EDIT_CASE_SETTING).subscribe(
        _result => {
          this.showAlert('Client association check updated success!' , function (): void {
            this.popUpService.hideDiv('alertDialog');
            this.isCaseSettingLoading = false;
          }.bind(this));
        },
        _error => {
          this.showAlert('Client association check updated failed!' , function (): void {
            this.popUpService.hideDiv('alertDialog');
            this.selected = !value;
            this.isCaseSettingLoading = false;
          }.bind(this));
        }
      );
    }
  }

  private showAlert(message: string, action?: Function): void {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (!action) {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }
    else {
      this.popUpService.setPositiveAction(
        action
      );
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) { this.popUpService.showDiv(dialog); }
  }

  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function): void {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }

    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }

    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);
  }

}

/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-restricted-imports */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unreachable */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment/moment';
import {filter, find, orderBy} from 'lodash';
import { Socket } from 'ngx-socket-io';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { takeWhile } from 'rxjs/operators';

import { LoginService } from '../../services/login.service';
import { ChangeDetectorRef } from '@angular/core';

import { GROUP_TYPE } from './constant';
import { NotificationService } from '../../services/notification.service';
import { UserService } from '../../services/userservice.service';
import { PopUpService } from '../../services/pop-up.service';
import { DatashareService } from '../../services/datashare.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  @ViewChild('bellInput', {read: true}) bellInput;
  @ViewChild('content', { static: false }) content: ElementRef;

  user: any;

  alive: boolean;
  toggle = false;
  outerHeight;
  backDropHeight;
  backDropWidth;
  isBlock = false;
  caseTypes = true;
  caseLoading = false;
  nonCaseLoading = false;
  notificationBadge = 0;
  caseCounts = 0;
  trackingCounts = 0;
  caseOffet = 0;
  caseOffetCounts = 0;
  trackingOffset = 0;
  trackingOffsetCounts = 0;
  totalCaseCount  = 0;
  totalTackingCount = 0;
  role: string;
  loadMoreFlag = false;
  resultData = [];
  result = [];
  bellClick = 0;
  // notifications = [];
  caseNotifications = [];
  trackingLineNotifications = [];

  profilePicPath = '/assets/images/profile-pic-male.png';
  
  itemToRemove;
  isCaseRemoved = false;
  isTrackingRemoved = false;
  notificationCountListner: any;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private socket: Socket,
    private loginService: LoginService,
    private cd: ChangeDetectorRef,
    private notService: NotificationService,
    private userService: UserService,
    private popUpService: PopUpService,
    private dataShareService: DatashareService
  ) { 
    this.closeBox();
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  ngOnChanges() {
    // it must be last line
    // this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.socket.disconnect();
    if (this.notificationCountListner) { this.notificationCountListner.close(); }
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(_event) {
    this.outerHeight = (window.outerHeight - 250);
    this.backDropHeight = window.outerHeight;
    this.backDropWidth = window.outerWidth;
  }

  ngOnInit(): void {
    this.caseTypes = true;
    this.notificationBadge = 0;
    this.caseCounts = 0;
    this.trackingCounts = 0;
    this.caseOffet = 0;
    this.caseOffetCounts = 0;
    this.trackingOffset = 0;
    this.trackingOffsetCounts = 0;

    this.alive = true;
    this.socket.connect();
    this.user = this.loginService.getCurrentUser();
    this.role = this.user.user_type.type.toLowerCase();
    this.outerHeight = (window.outerHeight - 250);
    this.backDropHeight = window.outerHeight;
    this.backDropWidth = window.outerWidth;
    
    if (this.user && this.role !== 'mdmanager') {
      this.isBlock = true;
      this.getNotifications(true);
      this.getNotifications(false);
    }

    // listen for notification count from other tab
    this.notificationCountListner = new BroadcastChannel('notificationChannel');
    this.notificationCountListner.onmessage = function (ev: any) { 
      if (ev.data) {
        try {
          const data = JSON.parse(ev.data);
          if (data && this.user.user_id === data.userId && data.notificationBadge !== this.notificationBadge) {
            console.log('notification count mismatch');
            this.getNotifications(data.type !== 'caseType' ? false: true);
          }
        }
        catch(error) {
          console.log('Failed to update notification count');
        }
      }
    }.bind(this);

    const socketId = 'NOTIFICATION_USER_'+this.user.user_id.toString().trim();

    this.socket.on(window.btoa(socketId), (data) => {
      console.log('new:notifications', data.caseId);
      if (data.groupType === GROUP_TYPE.TRACKING_LINE) {
        this.trackingLineNotifications.push(data);
        this.trackingOffsetCounts++;
        this.totalTackingCount++;
        this.trackingCounts++;
        
        // emit event to tracking line
        this.dataShareService.sendEvent({ notification: data, action: 'unread'});
      }
      else {
        this.caseNotifications.push(data);
        this.caseOffetCounts++;
        this.totalCaseCount++;
        this.caseCounts++;
      }

      this.notificationBadge++;
      this.parseData([data]);
      
      // emit event for other tabs if not listner socket event then
      if (this.notificationCountListner) {
        const dataToTrigger = {
          userId: this.user.user_id,
          notificationBadge: this.notificationBadge
        };
        try {
          this.notificationCountListner.postMessage(JSON.stringify(dataToTrigger));
        }
        catch(err) {
          console.log('Failed to broadcast to another tabs');
        }
      }
    });
  }

  loadAll() {
    
  }
  changeTab(type, _e) {
    this.caseTypes = type;
    // if (!this.caseTypes) {
    //   this.trackingOffset = 0;
    //   this.getNotifications(false);
    // }
    // else {
    //   this.caseOffet = 0;
    //   this.getNotifications(true);
    // }
  }

  openNotificationBox(_e, _bellInputElm) {
    this.caseTypes = true;
    this.toggle = !this.toggle;
    
    this.cd.detectChanges(); // not required
    if (this.toggle) {
      this.document.body.classList.add('stopScroll');
    }
    else {
      this.document.body.classList.remove('stopScroll');
    }
    if(this.bellClick===0){
      this.parseData([...this.resultData[0],...this.resultData[1]],this.result[0]);
      this.result = [];
      this.resultData =[];
      this.bellClick =1;
    }

  }
  // manuall load more
  loadMore(isCaseType) {
    this.loadMoreFlag = true;
    if (isCaseType) {
      if (!this.isCaseRemoved && this.role !== 'mdmanager' && (this.caseOffet + this.caseOffetCounts) < this.totalCaseCount) {
        this.caseOffet += (this.caseOffetCounts + 20);
        this.isBlock = true;
        this.caseLoading = true;
        this.getNotifications(true);
      }
      else {
        // load same page 
        this.isBlock = true;
        this.caseLoading = true;
        this.getNotifications(true);
      }
    }
    // fetch tracking line type notifications
    else if (!isCaseType) {
      if (!this.isTrackingRemoved && this.role !== 'mdmanager' && (this.trackingOffset + this.trackingOffsetCounts) < this.totalTackingCount) {
        this.trackingOffset += (this.trackingOffsetCounts + 20);
        this.isBlock = true;
        this.nonCaseLoading = true;
        this.getNotifications(false);
      }
      else {
        // load same page 
        this.isBlock = true;
        this.nonCaseLoading = true;
        this.getNotifications(false);
      }
    }
    else {
      this.caseLoading = false;
      this.nonCaseLoading = false;
    }
  }

  closeBox() {
    this.toggle = false;
    this.document.body.classList.remove('stopScroll');
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  markAsReadOrUnread(notification, value) {
    const data = {
      action: value? 'read' : 'unread',
      notificationIds: [notification.id]
    };
    this.itemToRemove = notification.id;
    this.notService.markAsReadOrUnread(data).subscribe((_result: any) => {
      if (notification.groupType === GROUP_TYPE.CASE) {
        this.caseNotifications.forEach(element => {
          if (element.id === notification.id) element.read = value;
        });
        if (value) {
          this.caseCounts--;
        }
        if (!value) {
          this.caseCounts++;
        }
      }
      else {
        // emit event to tracking line
        this.dataShareService.sendEvent({ notification, action: data.action});

        this.trackingLineNotifications.forEach(element => {
          if (element.id === notification.id) element.read = value;
        });
        if (value) {
          this.trackingCounts--;
        }
        if (!value) {
          this.trackingCounts++;
        }
      }
      if (value) {
        this.notificationBadge--;
      }
      if (!value) {
        this.notificationBadge++;
      }
    
      setTimeout(function(){
        this.itemToRemove = null;
        this.orderNotification();
      }.bind(this), 500);

    });
  }

  markAsReadAll() {
    this.showConfirmation('Are you sure you want to mark all as read?',function(){
      const data = {
        action: 'read',
        notificationIds: ['all']
      };
      this.notService.markAsReadOrUnread(data).subscribe((_result: any) => {
        this.caseOffet = 0;
        this.trackingOffset = 0;
        this.notificationBadge = 0;
        this.caseCounts = 0;
        this.trackingCounts = 0;
        this.caseNotifications.forEach(element => {
          element.read = true;
        });
        this.trackingLineNotifications.forEach(element => {
          element.read = true;
        });
        this.getNotifications(this.caseTypes);
        this.popUpService.hideDiv('confirmDialog');
        // emit event to tracking line
        this.dataShareService.sendEvent({ notification: null, action: 'all'});
      });
    }.bind(this));
  }

  deleteNotification(notification) {
    this.itemToRemove = notification.id;
    this.notService.clearNotification([notification.id]).subscribe((_result: any) => {
      setTimeout(function(){
        if (notification.groupType === GROUP_TYPE.CASE) {
          this.isCaseRemoved = true;
          this.caseNotifications = filter(this.caseNotifications , (item) => {
            if (notification.id !== item.id) return item;
          });
          if (!notification.read) {
            this.notificationBadge--;
            this.caseCounts--;
          }
          
          // reload notificartion if delete all
          if (this.caseNotifications.length < 1) {
            this.caseOffet = 0;
            this.isBlock = true;
            this.caseLoading = true;
            this.getNotifications(true);
          }
        }
        else {
          this.isTrackingRemoved = true;
          this.trackingLineNotifications = filter(this.trackingLineNotifications , (item) => {
            if (notification.id !== item.id) return item;
          });
          if (!notification.read) {
            this.notificationBadge--;
            this.trackingCounts--;
          }
          
          // reload notificartion if delete all
          if (this.trackingLineNotifications.length < 1) {
            this.trackingOffset = 0;
            this.isBlock = true;
            this.nonCaseLoading = true;
            this.getNotifications(false);
          }
        }

        this.itemToRemove = null;
      }.bind(this), 500);
    });
  }

  getDateString(date) {
    return moment(date).fromNow(true)+' ago';
  }

  getNotifications(caseTypes) {
    let data = {}; 

    if (!caseTypes) {
      data = {
        offset: this.trackingOffset,
        limit: 20,
        groupType: GROUP_TYPE.TRACKING_LINE
      };
    }
    else {
      data = {
        offset: this.caseOffet,
        limit: 20,
        groupType: GROUP_TYPE.CASE
      };
    }
   
    this.notService.getAllNotifications(data).subscribe((result: any) => {
      this.isBlock = false;
      
      if (result.new && result.new.totalRecords) this.notificationBadge = result.new.totalRecords;

      if (result.new && result.new.case) this.caseCounts = result.new.case;

      if (result.new && result.new.trackings) this.trackingCounts = result.new.trackings;

      if (result.total && result.total.case) this.totalCaseCount = result.total.case;

      if (result.total && result.total.trackings) this.totalTackingCount = result.total.trackings;

      if (result.data) {
        if(this.loadMoreFlag){
          this.parseData(result.data, data);
        
        }
        this.resultData.push(result.data);
        this.result.push(data);
      }
      else {
        this.caseLoading = false;
        this.nonCaseLoading = false;
      } 
      // reset detete flags
      if (caseTypes) this.isCaseRemoved = false;
      if (!caseTypes) this.isTrackingRemoved = false;
    }, (_error) => {
      this.isBlock = false;
      this.caseLoading = false;
      this.nonCaseLoading = false;
    });
  }

  parseData(result, _query?) {
    const caseList = [];
    const trackingList = [];
    const avatarList = [];

    result.forEach(item => {
      if (item.id) {
        if (item.sender.avatar && item?.sender?.avatar?.length < 15) {
          if(!avatarList.includes(item.sender.avatar)){
            avatarList.push(item.sender.avatar);
          }
        }
        else {
          item.sender.avatar = this.profilePicPath;
        }
        
        // group data by group type  Case or tracking Lines
        if (item.groupType === GROUP_TYPE.TRACKING_LINE) {
          const data = find(this.trackingLineNotifications, ['id', item.id]);
          if (!data) {
            trackingList.push(item);
          }
        }
        else {
          const data = find(this.caseNotifications, ['id', item.id]);
          if (!data) {
            caseList.push(item);
          }
        }
      }
    });

    avatarList.forEach(avatar => {
      this.userService.getUploadedFile(avatar)
        .pipe(takeWhile(() => this.alive))
        .subscribe(
          res => {
            result.forEach(item => {
              if (item.sender.avatar?.length < 15) {
                if(item.sender.avatar === avatar){
                  item.sender.avatar = res.image;
                }
              }
            }
            );
          },
          _err => {
            result.forEach((item) => {
              if (item.sender.avatar.length < 15 && item.sender.avatar === avatar) {
                item.sender.avatar = this.profilePicPath;
              }
            });
          }
        );
    });

    this.caseNotifications = [...this.caseNotifications, ...caseList];
    this.trackingLineNotifications = [...this.trackingLineNotifications, ...trackingList];
    this.orderNotification();

    this.caseLoading = false;
    this.nonCaseLoading = false;
  }

  orderNotification() {
    this.caseNotifications = orderBy(this.caseNotifications, ['read', 'priority', 'createdAt'], ['asc', 'asc', 'desc']);
    this.trackingLineNotifications = orderBy(this.trackingLineNotifications, ['read', 'priority', 'createdAt'], ['asc', 'asc', 'desc']);
  }

  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function) {

    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }

    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }

    const div = this.popUpService.getPopUpView('confirmDialog');
    if (div) this.popUpService.showDiv(div);

  }
}

/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { UserModel } from './userModel';

export class ActivityModel{
  id: number;
  resourceId: number;
  profileImage: any;
  resourceType: string;
  activityType: string;
  activityEvent: string;
  subId?: number;
  subType?: string;
  title?: string;
  activity: any;
  created_at: Date;
  updated_at: Date;
  createdBy: number;
  user?: UserModel
}

<div class="box box--white box--no-pad box--radius margin--bottom-2x  overlay__wrapper" *ngIf="maxFollowUpId>0">
	<h2 class="questions__title pull-left case-section-title" style="cursor: pointer;">
		Follow-up Questions
	</h2>
	<a href="javascript:void(0)" class="go-back pull-right collapseToggle top10"
		[ngClass]="{collapseToggleActive: isFollowUpQuestionCollapse===true}"
		(click)="isFollowUpQuestionCollapse = !isFollowUpQuestionCollapse">
		<span class="icon icon--time-nav"></span>
	</a>
	<div class="clearfix"></div>
	<div class="collapsibleContent" *ngIf="isFollowUpQuestionCollapse">
		<!-- <form [formGroup]="questionForm" *ngIf="questionForm"> -->
		<!-- <div formArrayName="case_questions"> -->
		<div *ngFor="let question of getFollowUpQuestions(); let i=index">
			<div class="questions questionTxtbox" *ngIf="question.follow_up_id === maxFollowUpId ">
				<div class=" questions__row">
					<div class="row">
						<div class="col col-md-2 ">
							<div class="questions__label ">
								Question{{i+1}}
							</div>
						</div>
						<div class="col-md-10 ">
							<div class="questions__questions">
								{{question.question_text}}
							</div>
						</div>
					</div>
					<ul>
					</ul>
				</div>
			</div>
			<!-- </div>  -->
			<!-- </form> -->
		</div>
	</div>
</div>
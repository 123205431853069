<app-dialog></app-dialog>
<app-partner-create></app-partner-create>
<div class="wrapper--dash-content wrapper--dash-content-collapsed overlay__wrapper"
	[class.overlay--visible]="isInprogress">
	<div class="overlay__content">
		<div class="overlay__spinner">
			<span class="icon icon--loading"></span>
		</div>
	</div>
	<div class="row">
		<div class="col col__md-8 gutter--right-3x">
			<div class="box box--white box--radius box--margin-bottom-3x">
				<div class="group-details">
					<div class="group-details__title-row">
						<div class="group-details__title-row--title">
							Group Details
						</div>
						<div class="group-details__title-row--right" *ngIf="showCRUD">
							<a *ngIf="role?.toLowerCase()==='admin' || role?.toLowerCase()==='cmmanager'" href="#"
								(click)="editPartner(partnerDashboardModel.partner_id, $event)"
								class="btn btn--blue btn--mini">
								EDIT
							</a>
						</div>
					</div>
					<div class="group-details__title">
						{{partnerDashboardModel.partner_name}}
					</div>
					<div class="group-details__4cols">
						<div class="the-col">
							<div class="the-col__data">
								<div class="the-col__label">Primary POC</div>
								<div class="the-col__detail">{{partnerDashboardModel.primary_poc_fullname}}</div>
							</div>
						</div>
						<div class="the-col">
							<div class="the-col__data">
								<div class="the-col__label">Secondary POC</div>
								<div class="the-col__detail">{{partnerDashboardModel.secondary_poc_fullname}}</div>
							</div>
						</div>
						<div class="the-col">
							<div class="the-col__data">
								<div class="the-col__label">Organization Type</div>
								<div class="the-col__detail">{{partnerDashboardModel.partner_organization}}</div>
							</div>
							<div class="the-col__data">
								<div class="the-col__label">Start Date</div>
								<div class="the-col__detail">{{partnerDashboardModel.start_date}}</div>
							</div>
						</div>
						<div class="the-col">
							<div class="the-col__data">
								<div class="the-col__label">Case Type</div>
								<div class="the-col__detail">{{partnerDashboardModel.plan_type}}</div>
							</div>
							<div class="the-col__data">
								<div class="the-col__label">End Date</div>
								<div class="the-col__detail">{{partnerDashboardModel.end_date}}</div>
							</div>
						</div>

					</div>
					<div class="group-details__4cols" *ngIf="partnerDashboardModel.allyPartnerRef && partnerDashboardModel.allyPartnerRef!=''">
						<div class="the-col">
							<div class="the-col__data">
								<div class="the-col__label">Ally Client Reference</div>
								<div class="the-col__detail">{{partnerDashboardModel.allyPartnerRef}}</div>
							</div>
						</div>
					</div>
					<div class="group-details__title-row" *ngIf="notification_email_list.length > 0">
						<div class="group-details__title-row--title">
							Notification Emails
						</div>
					</div>
					<div class="group-details__title" *ngIf="notification_email_list.length > 0">
						<div *ngFor="let email of notification_email_list" class="group-details__title-row email-list">
							{{email}}
						</div>
					</div>
				</div>
			</div>

			<div *ngIf="role.toLowerCase() != 'partner'" class="box box--white box--radius box--margin-bottom-3x">
				<div class="atat">
					<div class="atat__title-row">
						<div class="atat__title-row--title">
							Average Turn Around Time
						</div>
						<!--  <div class="atat__title-row--right">
              ...
            </div> -->
					</div>
					<div class="atat__3cols">
						<div class="the-col">
							<div class="the-col__title">SUBMITTED REQUEST<br /> TO CONSULT</div>
							<div class="count-unit">
								<div class="the-col__count">{{submitted_request_actual_consult}}</div>
								<div class="the-col__unit">Days</div>
							</div>
						</div>
						<div class="the-col the-col--mid">
							<div class="the-col__title">TURN AROUND<br /> TIME</div>
							<div class="count-unit">
								<div class="the-col__count">{{first_availibility_patient_actual_consult}}</div>
								<div class="the-col__unit">Days</div>
							</div>
						</div>
						<div class="the-col">
							<div class="the-col__title">CONSULT COMPLETE TO FINAL<br /> REPORT SUBMITTED</div>
							<div class="count-unit">
								<div class="the-col__count">{{actual_consult_final_report}}</div>
								<div class="the-col__unit">Days</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row--equal-col margin--bottom-2x">
				<div class="equal-col">
					<div class="box box--white box--radius">
						<div class="stat-count">
							<div class="star-count__icon">
								<span class="icon icon--count-members"></span>
							</div>
							<div class="stat-count__details">
								<div class="stat-count__count">{{memberCount}}</div>
								<div class="stat-count__item">MEMBERS</div>
							</div>
						</div>
					</div>
				</div>
				<div class="equal-col">
					<div class="box box--white box--radius box--margin-lr-x">
						<div class="stat-count">
							<div class="star-count__icon">
								<span class="icon icon--count-cases"></span>
							</div>
							<div class="stat-count__details">
								<div class="stat-count__count">{{casesCount}}</div>
								<div class="stat-count__item">CASES</div>
							</div>
						</div>

					</div>
				</div>
				<div class="equal-col">
					<div class="box box--white box--radius">

						<div class="stat-count">
							<div class="star-count__icon">
								<span class="icon icon--count-patients"></span>
							</div>
							<div class="stat-count__details">
								<div class="stat-count__count">{{patientCount}}</div>
								<div class="stat-count__item">PATIENTS</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col col__md-4">
			<div class="box box--white box--radius box--margin-bottom-2x">
				<div class="contact-details case-details">
					<div class="contact-details__row">
						<div class="contact-details__label label-is-heading">Cases by Month</div>
						<div class="contact-details__details details-is-heading is-uppercase">{{casePartnerModel.month}}
						</div>
					</div>
					<div class="contact-details__row">
						<div class="contact-details__label">Canceled</div>
						<div class="contact-details__details">{{casePartnerModel.cases_by_month_cancelled}}</div>
					</div>
					<div class="contact-details__row">
						<div class="contact-details__label">Requested</div>
						<div class="contact-details__details">{{casePartnerModel.cases_by_month_requested}}</div>
					</div>
					<div class="contact-details__row">
						<div class="contact-details__label">Completed</div>
						<div class="contact-details__details">{{casePartnerModel.cases_by_month_completed}}</div>
					</div>
					<div class="hr hr--space-down-x hr--space-up-x"></div>
					<div class="contact-details__row">
						<div class="contact-details__label label-is-heading">Cases by Year</div>
						<div class="contact-details__details details-is-heading is-uppercase">{{casePartnerModel.year}}
						</div>
					</div>
					<div class="contact-details__row">
						<div class="contact-details__label">Canceled</div>
						<div class="contact-details__details">{{casePartnerModel.cases_by_year_cancelled}}</div>
					</div>
					<div class="contact-details__row">
						<div class="contact-details__label">Requested</div>
						<div class="contact-details__details">{{casePartnerModel.cases_by_year_requested}}</div>
					</div>
					<div class="contact-details__row">
						<div class="contact-details__label">Completed</div>
						<div class="contact-details__details">{{casePartnerModel.cases_by_year_completed}}</div>
					</div>
					<div class="hr hr--space-down-x hr--space-up-x"></div>
					<div class="contact-details__row">
						<div class="contact-details__label label-is-heading">Active Cases Currently</div>
						<div class="contact-details__details details-is-heading is-uppercase">
							{{casePartnerModel.active_cases}}</div>
					</div>
					<div class="contact-details__row">
						<div class="contact-details__label label-is-heading">Hold Cases Currently</div>
						<div class="contact-details__details details-is-heading is-uppercase">
							{{casePartnerModel.cases_hold}}</div>
					</div>
					<div class="contact-details__btns" *ngIf="!partnerDashboardModel.is_deleted && showCRUD">
						<a [swal]="deleteClientSwal" *ngIf="role?.toLowerCase()==='admin' || role?.toLowerCase()==='cmmanager'" href="#" class="btn btn--red">Delete Client</a>	
						
						<swal #deleteClientSwal title="Delete Client" text="Are you sure you want to remove `{{partnerDashboardModel.partner_name}}` from the system?" (confirm)="deleteClientSwalConfirmation.fire()" closeOnConfirm="true"
							closeOnCancel="true" backdrop="#eef3f6" cancelButtonColor="#db161e" reverseButtons="true" showCancelButton="true"
							confirmButtonColor="green" confirmButtonText="Continue">
						</swal>


						<swal #deleteClientSwalConfirmation title="Confirmation" (confirm)="deletePartner(partnerDashboardModel.partner_name, partnerDashboardModel.partner_id)"
							closeOnConfirm="true" closeOnCancel="true" backdrop="#eef3f6" cancelButtonColor="#db161e" reverseButtons="true"
							showCancelButton="true" confirmButtonColor="green" confirmButtonText="Continue">
						
							<swal-text *swalPortal>Are you sure you want to proceed?</swal-text>
						</swal>

					</div>
					<div class="contact-details__btns" *ngIf="partnerDashboardModel.is_deleted && showCRUD && role?.toLowerCase()==='admin' || role?.toLowerCase()==='cmmanager'">
						<a href="#" class="btn btn--green" [swal]="restoreClientSwal">Restore Client</a>	

							<swal #restoreClientSwal title="Restore Confirmation" text="Are you sure you want to restore user from the system ?" (confirm)="restoreClientSwalConfirmation.fire()" closeOnConfirm="true"
							closeOnCancel="true" backdrop="#eef3f6" cancelButtonColor="#db161e" reverseButtons="true" showCancelButton="true"
							confirmButtonColor="green" confirmButtonText="Continue">
						</swal>


						<swal #restoreClientSwalConfirmation title="Confirmation" (confirm)="restorePartner(partnerDashboardModel.partner_id, $event)"
							closeOnConfirm="true" closeOnCancel="true" backdrop="#eef3f6" cancelButtonColor="#db161e" reverseButtons="true"
							showCancelButton="true" confirmButtonColor="green" confirmButtonText="Continue">
						
							<swal-text *swalPortal>Are you sure you want to proceed?</swal-text>
						</swal>
					</div>
				</div>
			</div>
			<a href="#" *ngIf="role === 'admin' || role === 'cmmanager'" class="btn btn--blue" (click)="toggleExportMode($event)">{{ exportMode ? 'Done': 'Enable Export'}}</a><br /><br />
			<app-export-report *ngIf="exportMode" [partner_id]="partnerId"></app-export-report>
		</div>
	</div>
	<div class="row">
		<div class="col col__md-6 gutter--right-3x">
			<div class="box box--white box--radius box--no-pad box--margin-bottom-3x">
				<div class="team-list">
					<div class="team-list__title">
						<div class="team-list__title--title">Alight Careteam</div>
						<div class="team-list__title--add-new">
							<a href="#" (click)="addCareTeam($event)"
								*ngIf="(role?.toLowerCase()==='admin' || role?.toLowerCase()==='cmmanager') && showAddCT">Add New</a>

						</div>
					</div>
					<div class="team-list__row" *ngFor="let careTeam of careteams">
						<div class="list-row">
							<div class="list-row__image">
								<div class="thumb thumb--small">
									<img [src]="profilePicPath" *ngIf="!careTeam.avatarImage" />
									<img [src]="'data:image/jpg;base64,'+careTeam.avatarImage"
										*ngIf="careTeam.avatarImage" />
								</div>
							</div>
							<div class="list-row__name">
								<a href="#" class="hideHREF" (click)="viewUserInfo($event, careTeam.user_id)">
									{{careTeam.full_name}}
								</a>
							</div>
							<div class="list-row__action">
								<a href="#" title="Delete" *ngIf="role?.toLowerCase()==='admin' && showAddCT"
									(click)="removeThisCareTeam($event, careTeam.user_id,partnerDashboardModel.partner_id, 'CM Careteam', careTeam.full_name )">
									<span class="icon icon--cross"></span>
								</a>

							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<div class="col col__md-6">
			<div class="box box--white box--radius box--no-pad box--margin-bottom-3x">
				<div class="team-list">
					<div class="team-list__title">
						<div class="team-list__title--title">Client Care Team</div>
						<div class="team-list__title--add-new">
							<!-- <a href="#" (click)="addPartnerCareTeam($event)"  *ngIf="showAddPCT">Add New</a> -->
							<a [routerLink]="['/add/partner']" [skipLocationChange]="true">Add New</a>
						</div>
					</div>
					<div class="team-list__row" *ngFor="let partnerCareTeam of partnercareteams">
						<div class="list-row">
							<div class="list-row__image">
								<div class="thumb thumb--small">
									<img [src]="profilePicPath" *ngIf="!partnerCareTeam.avatarImage" />
									<img [src]="'data:image/jpg;base64,'+partnerCareTeam.avatarImage"
										*ngIf="partnerCareTeam.avatarImage" />
									<!-- <img [src]="profilePicPath" /> -->
								</div>
							</div>
							<div class="list-row__name">
								<a class="hideHREF" href="#" (click)="viewUserInfo($event, partnerCareTeam.user_id)">
									{{partnerCareTeam.full_name}}
								</a>
							</div>
							<div class="list-row__action">
								<a href="#" title="Delete" *ngIf="showAddPCT"
									(click)="removeThisCareTeam($event, partnerCareTeam.user_id,partnerDashboardModel.partner_id, 'Client Careteam', partnerCareTeam.full_name )">
									<span class="icon icon--cross"></span>
								</a>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>


<!-- <div class="popup" hidden #deleteConfirm>
	<div class="popup__overlay"></div>
	<div class="popup__container">
		<div class="popup__header">
			<a href="#" class="popup__close">
				<span class="icon icon--cross"></span>
			</a>
		</div>
		<div class="popup__content">
			<div class="message message--bold message--black">Are you sure you want to remove user from the system?</div>
		</div>
		<div class="popup__footer popup__footer--centered">
			<a href="#" class="btn btn--grey" (click)="closeDialog($event)">Cancel</a>
			<a href="#" class="btn btn--green" (click)="deletePartner(partnerDashboardModel.partner_id, $event)">Delete</a>
		</div>
	</div>
</div> -->

<div class="popup" hidden #chooseCareTeam>
	<div class="popup__overlay"></div>
	<div class="popup__container">
		<div class="popup__header">
			<h1>Add New Members</h1>
			<a href="#" class="popup__close" (click)="hideDiv('chooseCareTeam', $event)">
				<span class="icon icon--cross"></span>
			</a>
		</div>
		<div class="popup__content popup__content--no-pad">
			<div class="tabs-lined">
				<ul class="tabs-lined__tabs">
					<li class="active">
						<a href="#" (click)="$event.preventDefault();">IMD</a>
					</li>
				</ul>
				<div class="tabs-lined__content">
					<div class="team-list__row" *ngIf="cteam$ == null">
						<div class="list-row">
							No Alight Careteam Found
						</div>
					</div>
					<div class="team-list__row" *ngIf="cteam$?.length==0">
						<div class="list-row" *ngIf="isCareteamLoading">
							Getting Careteams
						</div>
						<div class="list-row" *ngIf="!isCareteamLoading">
							No Alight Careteam Present
						</div>
					</div>
					<div class="team-list__row" *ngFor="let cteam of cteam$">
						<div class="list-row" [id]="cteam.user_id"
							(click)="selectThisCareTeam($event, cteam.user_id, cteam.avatarImage)">
							<div class="list-row__image">
								<div class="thumb thumb--small">
									<img [src]="profilePicPath" *ngIf="!cteam.avatarImage" />
									<img [src]="'data:image/jpg;base64,'+cteam.avatarImage" *ngIf="cteam.avatarImage" />
								</div>
							</div>
							<div class="list-row__name">{{cteam.full_name}}</div>
							<div class="list-row__email">{{cteam.email}}</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<div class="popup__footer popup__footer--centered">
			<a href="#" class="btn btn--green"
				(click)="assignCareTeamToThisPartner($event,partnerDashboardModel.partner_id)">Add</a>
		</div>
	</div>
</div>
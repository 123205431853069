/* eslint-disable @typescript-eslint/explicit-member-accessibility */
export class BaseDoctorOnePager {

  id: number;
  written_summary: string;
  video_consultation: any;
  is_complete: boolean;
  comment_summary: string;
  case_medication: any;
  case_identifier: string;
  summary_assignee: string;
  case_owner: string;
  peer_to_peer_details: any;
  constructor() {
    this.written_summary = '';
    this.video_consultation = '';
    this.id = 0;
    this.case_medication = [];
    this.is_complete = false;
    this.comment_summary = '';
    this.case_identifier = '';
    this.summary_assignee = '';
    this.case_owner = '';
    this.peer_to_peer_details =  '';
  }
}

<div class="popup" hidden #confirmDialog>
	<div class="popup__overlay"></div>
	<div class="popup__container">
		<div class="popup__header">
			<h1>{{popUpService.title}}</h1>
			<a href="#" class="popup__close" (click)="onNegativeClick($event)">
				<span class="icon icon--cross"></span>
			</a>
		</div>
		<div class="popup__content">
			<div class="message message--bold message--black">{{popUpService.message}}</div>
		</div>
		<div *ngIf="!popUpService?.isLoading" class="popup__footer popup__footer--centered">
			<a href="#" class="btn btn--grey" (click)="onNegativeClick($event)">{{popUpService.negativeLabel}}</a>
			<a href="#" class="btn btn--green" (click)="onPositiveClick($event)">{{popUpService.positiveLabel}}</a>
		</div>
		<!--Confirmation pop up show loading by setLoading function-->
		<div *ngIf="popUpService?.isLoading" class="popup__footer popup__footer--centered">
			<div class="message message--bold message--black">Sending...</div>
			<div class="message message--black">This may take a few seconds.</div>
		</div>
	</div>
</div>

<div class="popup" hidden #alertDialog>
	<div class="popup__overlay"></div>
	<div class="popup__container">
		<div *ngIf="popUpService.title != 'alert'" class="popup__header">
			<h1 class="message--bold">{{popUpService.title}}</h1>
		</div>
		<div class="popup__content">
			<div class="message message--bold message--black"[innerHTML]="popUpService.message">{{popUpService.message}}</div>
		</div>
		<div class="popup__footer popup__footer--centered">
			<a href="#" class="btn btn--green" (click)="onPositiveClick($event)">{{popUpService.positiveLabel}}</a>
		</div>
	</div>
</div>
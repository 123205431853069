<app-dialog></app-dialog>
<div class="wrapper--dash-content dash-content">
	<div class="row">
		<div class="col col__md-8 gutter--right-3x">
			<div class="box box--white box--radius box--margin-bottom-2x overlay__wrapper"
				[class.overlay--visible]="showSpinner">
				<!-- Loader -->
				<app-loader *ngIf="showSpinner"></app-loader>
				<div class="box box--white box--radius">
					<div class="cases_doctor_right">
						<div class="col__md-6">
							<div class="contact-details__details">
							</div>
							<div *ngIf="case" class="cases__name__physician">
								{{case?.patient.user.first_name}}{{case?.patient.user.middle_name}}
								{{case?.patient.user.last_name}}
							</div>
							<div *ngIf="case" class="cases__age-sex">{{case?.patient?.user?.dob}}</div>
							<div *ngIf="case" class="cases__age-sex">{{patientAge}} y/o, {{patientGender}} </div>
							<div *ngIf="case" class="cases__details">
								<div class="row">
									<div class="col col__md-6">
										<div class="cases__type"><i class="fa fa-user-md" aria-hidden="true"></i>
											Diagnosis</div>
										<div class="cases__note" [innerHtml]="case.patient_diagnosis"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="col__md-6 cases_align_right">
							<div class="contact-details__details">
								<div class="badge__container  {{ stylingCondition() }}">
									<div class="badge badge-secondary follow_up__badge " *ngIf="maxFollowUpId > 0"> +{{
										maxFollowUpId }} Follow Up </div>
									<div class="badge badge-secondary follow_up__badge badge-orange "
										*ngIf="isPeerToPeer()">Peer to Peer</div>
								</div>

								<div *ngIf="case?.allyCaseRef">
									<a *ngIf="showAcceptDeclineButton" class="btn btn--green"
										(click)="acceptCase($event ,'ACCEPTED')"> Accept</a>
									<a *ngIf="showDeclineButton || showAcceptDeclineButton" class="btn btn--red"
										(click)="rejectCase($event)">Decline</a>
								</div>
								<div *ngIf="case" class="cases__name__physician">
									<div class="text--red--align--left">{{case?.case_identifier}}</div>
								</div>
							</div>
							<div *ngIf="case?.case_owner" class="cases_owner">
								<div class="cases__type"><i class="fa fa-user-md" aria-hidden="true"></i> Care Team
								</div>
								<div class="cases__age-sex">{{case?.case_owner?.full_name}}</div>
							</div>
							<div *ngIf="case?.allyCaseRef && case?.summarizer_due_date" class="cases_owner">
								<div class="cases__type"><i class="fa fa-calendar" aria-hidden="true"></i> Due Date:
								</div>
								<div class="cases__age-sex">{{case?.summarizer_due_date | date: 'short' }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Documnets to be translated -->
			<app-docs-to-be-translated *ngIf="case" [showSpinner]="showSpinner" [caseAssignmentId]="caseAssignmentId"
				(showAlert)="showAlert($event)" [case]="case">
			</app-docs-to-be-translated>
			<!-- Patient's  Questions -->
			<app-patients-questions [isQuestionCollapse]="isQuestionCollapse" [case_questions]="case_questions"
				[maxFollowUpId]="maxFollowUpId">
			</app-patients-questions>
			<!-- Follow-up  Questions -->
			<app-follow-up-questions [maxFollowUpId]="maxFollowUpId" [case_questions]="case_questions"
				[isFollowUpQuestionCollapse]="isFollowUpQuestionCollapse"></app-follow-up-questions>
			<!-- Translated Documents -->
			<app-translated-documents [showSpinner]="showSpinner" [case]="case" [role]="role" [userId]="userId"
				[caseDocumentType]="caseDocumentType" (uploadDocsEmiter)="uploadDocs($event)">
			</app-translated-documents>
			<app-intake-form *ngIf="case && showIntakeForm" [caseDetail]="case"
				(notifyDocumentChange)="notifyDocumentChanges($event)"></app-intake-form>
			<app-casehistory *ngIf="case && showMessage" [caseDetail]="case"></app-casehistory>
		</div>

	</div>
	<div class="hide">
		<input type="file" accept="image/*" class="btn btn--grey btn--small" name="fileInput" #fileInput ng2FileSelect
			[uploader]="commentImageUploader" />
	</div>
	<div *ngIf="!hideDeleteUserBox">
		<app-reject-case-template [name]="case?.case_identifier" [hideTemplate]="hideDeleteUserBox"
			(toggleTemplate)="toggleDeleteTemplate($event)" [handleValueSubmit]="handleValueSubmit">
		</app-reject-case-template>
	</div>
</div>
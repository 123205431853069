import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { DataTablesModule } from 'angular-datatables/src/angular-datatables.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from '../routers/app-routing.module';

import { TranslatorCaselistComponent } from '../cases/translator-caselist/translator-caselist.component';
import { ListComponent } from '../cases/list/list.component';
import { TranslatorCaseComponent } from './translator-case/translator-case.component';

import { CaseService } from '../services/case.service';
import { CKEditorModule } from 'ng2-ckeditor';
import { MomentModule } from 'angular2-moment';
import { CreateCaseComponent } from './create-case/create-case.component';
import { GeneralCaseComponent } from './general-case/general-case.component';
import { CaseComponent } from './case/case.component';
import { CaseRequestedComponent } from './general-case/case-requested/case-requested.component';
import { TranslateMrComponent } from './general-case/translate-mr/translate-mr.component';
import { NguiAutoCompleteModule, NguiAutoCompleteComponent } from '@ngui/auto-complete';
import { MdManagementComponent } from './general-case/md-management/md-management.component';

import { WrittenCaseComponent } from './general-case/written-case/written-case.component';
// import { WrittenCaseComponent } from './written-case/written-case.component';
import { TranslateWrDrComponent } from './general-case/translate-wr-dr/translate-wr-dr.component';
import { FileUploadModule } from 'ng2-file-upload';
import { ScheduleComponent } from './general-case/schedule/schedule.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';

import { VideoCaseComponent } from './general-case/video-case/video-case.component';
import { ReviewCaseComponent } from './general-case/review-case/review-case.component';
import { ReportCaseComponent } from './general-case/report-case/report-case.component';
import { DoctorConsultationComponent } from './doctor-consultation/doctor-consultation.component';
import { CasehistoryComponent } from './casehistory/casehistory.component';

import { MainPipe } from '../custom-pipe/main-pipe.module';

import { SocketIoModule } from 'ngx-socket-io';
import { ActivitycardComponent } from './casehistory/activitycard/activitycard.component';
import { IntakeFormComponent } from './translator-case/intake-form/intake-form.component';
import { TrackingLinesComponent } from './general-case/tracking-lines/tracking-lines.component';
import { ToastrModule } from 'ngx-toastr';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DataTablesModule,
    FormsModule,
    MainPipe,
    AppRoutingModule,
    ReactiveFormsModule,
    CKEditorModule,
    MomentModule,
    NguiAutoCompleteModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FileUploadModule,
    SocketIoModule,
    DragDropModule,
    ToastrModule,
    SweetAlert2Module
  ],
  declarations: [
    TranslatorCaselistComponent,
    ListComponent,
    TranslatorCaseComponent,
    CreateCaseComponent,
    GeneralCaseComponent,
    CaseComponent,
    CaseRequestedComponent,
    TranslateMrComponent,
    MdManagementComponent,
    WrittenCaseComponent,
    ScheduleComponent,
    VideoCaseComponent,
    ReviewCaseComponent,
    ReportCaseComponent,
    TranslateWrDrComponent,
    DoctorConsultationComponent,
    ActivitycardComponent,
    CasehistoryComponent,
    IntakeFormComponent,
    TrackingLinesComponent,
  ],
  providers: [
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-US' },
    CaseService
  ],
  entryComponents: [ NguiAutoCompleteComponent ]
})
export class CasesModule { }

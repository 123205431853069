/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit, ViewChild} from '@angular/core';
import { ConfigAssetLoaderService } from '../../../services/config-asset-loader.service';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { CommonFuntions } from '../../../common/common.function';
import { CaseModel } from '../../../domains/case/CaseModel';
import { CASE_TECHNICAL } from '../../../services/constant';

@Component({
  selector: 'app-case-list',
  templateUrl: './case-list.component.html',
  styleUrls: ['./case-list.component.css']
})
export class CaseListComponent implements OnInit {
  totalCaseCount = 0;
  errorString: string;

  @ViewChild(DataTableDirective, { static: true })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};

  searchValue = '';
  valueToSearch: string;

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private loginService: LoginService,
    private router: Router
  ) { }

  getColumnList(): any[] {
    return [
      {
        title: 'Id',
        data: 'id',
        render: function(_data, _type, row) {
          const { id, isAllyCase } = row;
          let caseid = id;
          if (isAllyCase) {
            caseid = `<div>${id} <span class='badge-small badge-yellow'>Ally</span></div>`;
          }
          return caseid;
        }
      },
      {
        title: 'AllyCaseNumber',
        data: 'AllyCaseNumber',
        render: function(_data, _type, row): string {
          const { allyCaseNumber } = row;
          return allyCaseNumber || 'N/A';
        }
      },
      {
        title: 'Type',
        data: 'case_type',
        render: $.fn.dataTable.render.text()
      },
      {
        title: 'Patient',
        data: 'PatientName',
        name: 'patient_name',
        render: $.fn.dataTable.render.text()
      },
      {
        title: 'Client',
        data: 'PatientPartner',
        name: 'patient_partner',
        render: $.fn.dataTable.render.text()
      },
      {
        title: 'Category',
        data: 'CategoryName',
        name: 'category_name',
        render: $.fn.dataTable.render.text()
      },
      {
        title: 'Status',
        data: 'CaseStatus',
        name: 'status',
        render: $.fn.dataTable.render.text()
      },
      {
        title: 'Actions',
        data: null,
        orderable: false,
        render: (_data, _type, _full) => {
          return '<button id="editCase" class="btn btn--green btn--mini" >Edit</button>';
        }
      }
    ];
  }

  setDatatableOption(): void {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${CASE_TECHNICAL.LIST}`;
    this.dtOptions = {
      ajax: {
        url: URL,
        dataSrc: function (json) {
          const { recordsTotal, data } = json;
          const model = data.map((item) => {
            const { user, ...data } = item.patient;
            item.patient = { ...data, ...user };
            return item;
          }).map(eachItem => new CaseModel(eachItem));
          this.totalCaseCount = recordsTotal;
          return model;
        }.bind(this),
        type: 'GET',
        beforeSend: function (xhr) {
          if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
            xhr.abort();
            return;
          }

          this.errorString = null;
          const token = localStorage.getItem('token');
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }.bind(this),
        error: this.datatablesError.bind(this)
      },
      pagingType: 'full_numbers',
      serverSide: true,
      processing: true,
      lengthChange: false,
      stateSave: true,
      searching: true,
      pageLength: 10,
      order: [[0, 'ASC']],
      info: false,
      columns: this.getColumnList(),
      rowCallback: (row: Node, data: CaseModel, _index: number) => {
        // Unbind first in order to avoid duplicate handler
        $('td', row).unbind('click');
        $('td', row).bind('click', () => {
          this.router.navigate(['technical/case/', data.id]);
        });

        /** Handle edit button click
         * Navigate to the outgoing mail server edit
         */
        $('#editCase', row).on('click', () => {
          this.router.navigate(['technical/case/edit/', data.id]);
        });
      }
    };

    this.setSearchValueFromSavedState();

  }

  ngOnInit(): void {
    this.setDatatableOption();
  }

  search(): void {
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => {
        dtInstance.search(this.valueToSearch).draw();
      }
    );
  }

  searchString(searchValue: string) {
    this.valueToSearch = searchValue;
    this.search();
  }

  datatablesError(xhr, _error, _thrown): void {
    const errorStatus = xhr.status;
    if (errorStatus == 401) {
      this.router.navigate(['/login']);
    }
    else if (errorStatus == 404) {
      this.errorString = 'Invalid Url';
    }
    else {
      this.errorString = 'Problem occurred. Please contact your administrator';
    }
  }

  setSearchValueFromSavedState() {

    const state = localStorage.getItem('DataTables_doctor_/technical/case');

    if (state == null) {
      this.searchValue = '';
      return;
    }

    const searchValue = JSON.parse(state).search['search'];

    if (searchValue != null) {

      this.searchValue = searchValue;

    }
  }

}
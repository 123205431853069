<div class="box box--white box--no-pad box--radius margin--bottom-2x overlay__wrapper"
	[class.overlay--visible]="isProgress" [formGroup]="form">
	<div class="overlay__content">
		<div class="overlay__spinner">
			<span class="icon icon--loading"></span>
		</div>
	</div>

	<div class="box__title case-section-box">
		<div class="box-title-left">
			<div class="left-title">Written Report</div>
			<div class="left-status" *ngIf="!forReport">
				<span class="icon icon--calendar"></span>Assigned {{written?.created_at | amCalendar}}
			</div>
		</div>
		<div class="box-title-right">
			<a href="javascript:void(0)" class="go-back pull-right collapseToggle ml20"
				[ngClass]="{collapseToggleActive: isWrittenCollapse===true}"
				(click)="isWrittenCollapse = !isWrittenCollapse">
				<span class="icon icon--time-nav"></span>
			</a>
			<a href="#" *ngIf="!isEditEnable && caseDetail?.is_submitted && !isSummaryFinish"
				class="btn btn--purple btn--mini editBtn pull-right" style="margin-top: 4px;"
				(click)="onEditClicked($event)">Edit</a>

		</div>
	</div>
	<div [hidden]="!isWrittenCollapse" class="box--written-report form">
		<div class="questions">
			<div class="form__row" *ngIf="!forReport">
				<div class="col__md-3">
					<div class="form__label is-uppercase">Description</div>
				</div>
				<div class="col__md-9">
					<div class="form__field">The MD is assigned the task of completing a Written Report for the patient
						to be submitted prior to the Video Consultation.
					</div>
				</div>
			</div>
			<div class="hr  hr--space-down-2x" *ngIf="!forReport"></div>

			<div class="questions__row">

				<div class="row">
					<div class="col__md-3">
						<div class="questions__label label--bold">
							Written Summary
						</div>
					</div>
					<div class="col__md-9">
						<div class="questions__question">
							<div class="form__field">
								<!-- <textarea class="validation" placeholder="Please enter your summary."
									formControlName="written_summary" [readonly]="!isEditEnable">
								</textarea> -->
								<ckeditor class="validation customheader" placeholder="Please enter your summary."
								[formControl]="form.controls['written_summary']" [readonly]="!isEditEnable" #ckeditorField
									[config]="{toolbar: []}"></ckeditor>
								<br />
								<div *ngIf="caseDetail?.is_submitted && caseDetail?.video_consultation">
									<video id="videoDbSummary" [src]="caseDetail.video_consultation" controls
										width="100%" height="200"></video>
									<div class="d-flex">
										<button (click)="downloadVideo(caseDetail.video_consultation)" type="button"
											class="btn btn--blue-border btn--small d-flex align-items-center"><span>download
												webm</span>
										</button>
										<button (click)="downloadVideo(caseDetail.video_mp4)" type="button"
											class="btn btn--blue-border btn--small d-flex align-items-center"><span>download
												mp4</span></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div formArrayName="case_questions">
				<div class="questions__row"
					*ngFor="let writtenReport of getUniqueQuestionsList(caseQuestions?.controls); let i = index"
					[formGroupName]="i">
					<div class="form__row">
						<div class="row">
							<div class="col__md-3">
								<div class="questions__label">Question {{i+1}}
									<br />
									<span
										*ngIf=" followUpNumber > 0 && followUpNumber == writtenReport.value.follow_up_id"
										class="badge-small badge-yellow">Followup</span>
								</div>
							</div>
							<div class="col__md-9">
								<div class="questions__question mb-2">
									{{writtenReport.value.question_text}}
								</div>
							</div>
						</div>
						<div class="row" formArrayName="answer">
							<ng-container *ngIf="caseDetail?.is_submitted; else answerNotSubmitted">
								<ng-container
									*ngIf="writtenReport?.get('answer')?.controls?.length > 0; else noAnswerByDoctor">
									<ng-container
										*ngFor="let eachAnswer of writtenReport.get('answer').controls; let answerIndex=index"
										[formGroupName]="answerIndex">
										<div class="col__md-3 mt-2">
											<div *ngIf="mdtCase" class="questions__label label--bold">Response by
												{{eachAnswer.get('user').value.full_name}}
											</div>
											<div *ngIf="!mdtCase" class="questions__label label--bold">Response
												{{ answerIndex +1 }}
											</div>
										</div>
										<div class="col__md-9 mt-2">
											<div class="questions__question">
												<div class="form__field">
													<!-- <textarea class="validation"
														placeholder="Please enter your response."
														formControlName="answer" [readonly]="writtenReport.value.follow_up_id !== followUpNumber || !isEditEnable">
											</textarea> -->
													<ckeditor class="validation customheader"
														placeholder="Please enter your response."
														formControlName="answer"
														[readonly]="writtenReport.value.follow_up_id !== followUpNumber || !isEditEnable"
														#ckeditorField ></ckeditor>
												</div>
											</div>
										</div>
									</ng-container>
								</ng-container>
								<ng-template #noAnswerByDoctor>
									<div class="col__md-3">
										<div class="questions__label label--bold"> &nbsp;
										</div>
									</div>
									<div class="col__md-9">
										<div class="questions__question">
											<div class="form__field">
												<textarea class="validation"
													placeholder="No doctors have responded to the question"
													[readonly]="true"></textarea>
											</div>
										</div>
									</div>
								</ng-template>
							</ng-container>
							<ng-template #answerNotSubmitted>
								<div class="col__md-3">
									<div class="questions__label label--bold"> &nbsp;
									</div>
								</div>
								<div class="col__md-9">
									<div class="questions__question">
										<div class="form__field">
											<textarea class="validation"
												placeholder="Doctors have not submitted their answer"
												[readonly]="true"></textarea>
										</div>
									</div>
								</div>
							</ng-template>
							<br *ngIf="writtenReport.value.video_consultation" />
							<ng-container *ngIf="caseDetail?.is_submitted && writtenReport.value.video_consultation">
								<div class="col__md-3 mt-3">
									<div class="questions__label label-bold"> &nbsp; </div>
								</div>
								<div class="col__md-9 mt-3">
									<div class="questions_question">
										<div class="form__field">
											<video id="videoDbSummary" [src]="writtenReport.value.video_consultation"
												controls width="100%" height="200"></video>
											<div class="d-flex">
												<button (click)="downloadVideo(writtenReport.value.video_consultation)"
													type="button"
													class="btn btn--blue-border btn--small d-flex align-items-center"><span>download
														webm</span>
												</button>
												<button (click)="downloadVideo(writtenReport.value.video_mp4)"
													type="button"
													class="btn btn--blue-border btn--small d-flex align-items-center"><span>download
														mp4</span></button>
											</div>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>

			<div class="questions__row" *ngIf="!forReport">

				<div class="row">
					<div class="col__md-3">
						<div class="questions__label label--bold">Missing Information</div>
					</div>
					<div class="col__md-9">
						<div class="questions__question">
							<div class="form__field">
								<textarea placeholder="Please enter any missing information." [value]="missingInfo"
									[readonly]="!isEditEnable" #missing_info></textarea>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="box box--white box--no-pad box--radius margin--bottom-2x  overlay__wrapper mt-5" *ngIf="isPeerToPeer()"
	[class.overlay--visible]="isProgress" [formGroup]="clinicalConsultForm">


	<div class="box__title case-section-box">
		<div class="box-title-left">
			<div class="left-title">Clinical Consultation</div>
		</div>
		<div class="box-title-right">
			<a href="javascript:void(0)" class="go-back pull-right collapseToggle ml20"
				[ngClass]="{collapseToggleActive: isClinicalConsultationCollapse === true}"
				(click)="isClinicalConsultationCollapse = !isClinicalConsultationCollapse">
				<span class="icon icon--time-nav"></span>
			</a>
		</div>
	</div>

	<div [hidden]="!isClinicalConsultationCollapse" class="box--written-report form">
		<div class="questions">
			<div class="questions__row">
				<div class="row">
					<div class="col__md-3">
						<div class="questions__label label--bold">
							<div class="form__label mt-3">Clinical Consult Diagnosis</div>
						</div>
					</div>
					<div class="col__md-9">
						<div class="questions__question">
							<ng-container *ngIf="this.caseDetail.is_submitted; else disabledClinicalDiagnosisTemplate ">

								<ng-container *ngIf="isEditEnable && !isSummaryFinish; else editNotEnableTemplate">

									<div class="form__field">
									
										<select [attr.disabled]="isEditEnable && role === 'careteam' || role ==='admin' || role==='cmmanager' ?  true : null "
											[class.is-invalid]="clinicalConsultForm.get('clinical_consult_diagnosis').invalid && (clinicalConsultForm.get('clinical_consult_diagnosis').dirty || clinicalConsultForm.get('clinical_consult_diagnosis').touched) "
											class="form-control"
											(change)="changeClinicalConsultDiagnosis($event.target.value)"
											formControlName="clinical_consult_diagnosis">
											<option>Agree with diagnosis</option>
											<option> Need additional testing</option>
											<option>Disagree with diagnosis</option>
											<option>Other</option>
										</select>
										<div *ngIf="clinicalConsultForm.get('clinical_consult_diagnosis').hasError('required') "
											class="message message--is-invalid">
											Please select the Clinical Consult Diagnosis
										</div>
									</div>

									<div
										*ngIf="isEditEnable && clinicalConsultForm.get('clinical_consult_diagnosis').value == 'Other'">

										<div class="form__label mt-3">Clinical Consult Diagnosis Notes</div>

										<textarea rows="3" class="form-control mt-3" [attr.disabled]="isEditEnable && role === 'careteam' || role ==='admin' || role==='cmmanager' ?  true : null "
											[class.is-invalid]="clinicalConsultForm.get('clinical_consult_diagnosis_notes').invalid && (clinicalConsultForm.get('clinical_consult_diagnosis_notes').dirty || clinicalConsultForm.get('clinical_consult_diagnosis_notes').touched)"
											formControlName="clinical_consult_diagnosis_notes"></textarea>

										<div *ngIf="clinicalConsultForm.get('clinical_consult_diagnosis_notes').hasError('required') &&  isEditEnable"
											class="message message--is-invalid">
											Clinical Diagnosis Diagnosis Notes cannot be en empty
										</div>
										<div *ngIf="clinicalConsultForm.get('clinical_consult_diagnosis_notes').hasError('maxlength') &&  isEditEnable"
											class="message message--is-invalid">
											Field should not contain less than 100 characters
										</div>

									</div>

								</ng-container>

								<ng-template #editNotEnableTemplate>

									<div class="form__field">
										<select class="form-control" [attr.disabled]="!isEditEnable">
											<option selected>{{this.peerToPairDetails?.clinical_consult_diagnosis}}
											</option>
										</select>
									</div>

									<ng-container *ngIf="this.peerToPairDetails?.clinical_consult_diagnosis == 'Other'">
										<div class="form__field">
											<div class="form__label mt-3">Clinical Consult Diagnosis Notes</div>
											<textarea rows="3" class="form-control"
												name="clinical_consult_diagnosis_notes"
												formControlName="clinical_consult_diagnosis_notes"
												[attr.disabled]="!isEditEnable"></textarea>
										</div>
									</ng-container>
								</ng-template>

							</ng-container>

							<ng-template #disabledClinicalDiagnosisTemplate>
								<div class="form__field">
									<select class="form-control" [attr.disabled]="true ">

									</select>
								</div>
								<div class="form__label mt-3">Clinical Consult Diagnosis Notes</div>
								<textarea rows="3" class="form-control" [attr.disabled]=" true "></textarea>

							</ng-template>
						</div>
					</div>
				</div>

				<hr>
				<div class="row">
					<div class="col-md-3 mt-4 ">
						<div class="form__label  mt-3">Clinical Diagnosis Treatment Plan</div>
					</div>

					<div class="col-md-9 mt-4">
						<ng-container *ngIf="caseDetail?.is_submitted ;  else notEditableTreatmentPlanTemplate">


							<ng-container *ngIf="isEditEnable && !isSummaryFinish  else treatmentPlanTemplate">
								<div class="form__field">

									<select [attr.disabled]="isEditEnable && role === 'careteam' || role ==='admin' || role==='cmmanager' ?  true : null "
										[class.is-invalid]="clinicalConsultForm.get('clinical_consult_recommend_treatment_plan').invalid && (clinicalConsultForm.get('clinical_consult_recommend_treatment_plan').dirty || clinicalConsultForm.get('clinical_consult_recommend_treatment_plan').touched)"
										(change)="changeClinicalConsultRecommendTreatmentPlan($event.target.value)"
										name="clinical_consult_recommend_treatment_plan" class="form-control"
										formControlName="clinical_consult_recommend_treatment_plan">
										<option>Agree with proposed plan</option>
										<option> Need additional testing to evaluate treatment plan</option>
										<option>Recommend clinical trial</option>
										<option>Other</option>
									</select>
								</div>


								<div *ngIf="clinicalConsultForm.get('clinical_consult_recommend_treatment_plan').hasError('required') "
									class="message message--is-invalid">
									Please select the Clinical Consult Diagnosis
								</div>
								<div *ngIf="clinicalConsultForm.get('clinical_consult_recommend_treatment_plan').hasError('maxlength') "
									class="message message--is-invalid">
									Field should not contain more than 100 characters
								</div>

								<div
									*ngIf="isEditEnable && clinicalConsultForm.get('clinical_consult_recommend_treatment_plan').value == 'Other'">
									<div class="form__label mt-3">Clinical Diagnosis Treatment Plan Notes</div>
									<textarea [attr.disabled]="isEditEnable && role === 'careteam' || role ==='admin' || role==='cmmanager'?  true : null " rows="3" name="clinical_consult_recommend_treatment_plan_notes"
										[class.is-invalid]="clinicalConsultForm.get('clinical_consult_recommend_treatment_plan_notes').invalid && (clinicalConsultForm.get('clinical_consult_recommend_treatment_plan_notes').dirty || clinicalConsultForm.get('clinical_consult_recommend_treatment_plan_notes').touched)"
										rows="3" class="form-control mt-3"
										formControlName="clinical_consult_recommend_treatment_plan_notes"></textarea>

									<div *ngIf="clinicalConsultForm.get('clinical_consult_recommend_treatment_plan_notes').hasError('required') &&  isEditEnable"
										class="message message--is-invalid">
										Clinical Diagnosis Treatment Plan Notes cannot be en empty
									</div>
									<div *ngIf="clinicalConsultForm.get('clinical_consult_recommend_treatment_plan_notes').hasError('maxlength') &&  isEditEnable"
										class="message message--is-invalid">
										Field should not contain more than 100 characters
									</div>
								</div>

							</ng-container>


							<ng-template #treatmentPlanTemplate>
								<div class="form__field">
									<div class="form__field">
										<select class="form-control" [attr.disabled]="!isEditEnable ">
											<option selected>
												{{this.peerToPairDetails?.clinical_consult_recommend_treatment_plan}}
											</option>
										</select>
									</div>

									<div
										*ngIf="this.peerToPairDetails?.clinical_consult_recommend_treatment_plan == 'Other'">
										<div class="form__label  mt-3">Clinical Diagnosis Treatment Plan Notes: </div>
										<textarea rows="3" class="form-control"
											[attr.disabled]="!isEditEnable || isSummaryFinish "
											formControlName="clinical_consult_recommend_treatment_plan_notes">
									</textarea>
									</div>
								</div>

							</ng-template>

						</ng-container>


						<ng-template #notEditableTreatmentPlanTemplate>

							<div class="form__field">

								<select class="form-control" [attr.disabled]=" isSummaryFinish "
									formControlName="clinical_consult_recommend_treatment_plan">

								</select>
							</div>
							<div class="form__label mt-3">Clinical Diagnosis Treatment Plan Notes</div>
							<textarea rows="3" class="form-control" [attr.disabled]=" isSummaryFinish "
								formControlName="clinical_consult_recommend_treatment_plan_notes"></textarea>

						</ng-template>

					</div>

				</div>

			</div>
		</div>

	</div>

</div>
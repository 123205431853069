/* eslint-disable @typescript-eslint/explicit-member-accessibility */
export class PartnerDashboardModel {

    partner_id: number;
    partner_organization: string;
    plan_type: string;
    primary_poc_fullname: string;
    primary_poc_title: string;
    primary_poc_number: number;
    primary_poc_email: string;
    secondary_poc_fullname: string;
    secondary_poc_title: string;
    secondary_poc_number: number;
    secondary_poc_email: string;

    partner_name: string;
    start_date: string;
    end_date: string;
    is_deleted: boolean;
    notification_email: string;

    serviceA: boolean;
    serviceB: boolean;
    serviceC: boolean;
    serviceD: boolean;
    serviceE: boolean;

    first_name: boolean;
    last_name: boolean;
    dob: boolean;
    gender: boolean;
    enableEmailDomain: boolean;
    email_domain: string;
    company_name: boolean;
    enableCode: boolean;
    code_value: string;
    code_start_date: string;
    code_expiry_date: string;
    allyPartnerRef: string;

    constructor() {
      this.partner_id=0;
      this.partner_organization = '';
      this.plan_type = '';
      this.primary_poc_fullname = '';
      this.primary_poc_title = '';
      this.primary_poc_number = 0;
      this.primary_poc_email = '';
      this.secondary_poc_fullname = '';
      this.secondary_poc_title = '';
      this.secondary_poc_number = 0;
      this.secondary_poc_email = '';
      this.partner_name = '';
      this.start_date = '';
      this.end_date = '';
      this.is_deleted=false;
      this.notification_email = '';
      this.serviceA = false;
      this.serviceB = false;
      this.serviceC = false;
      this.serviceD = false;
      this.serviceD = false;
      this.serviceE = false;
      this.first_name = false;
      this.last_name = false;
      this.dob = false;
      this.gender = false;
      this.enableEmailDomain = false;
      this.email_domain = null;
      this.enableCode = false;
      this.code_value = null;
      this.code_start_date = null;
      this.code_expiry_date = null;
      this.company_name = false;
      this.allyPartnerRef = null;
    }
}

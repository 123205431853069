<app-dialog></app-dialog>

<div class="header">
  <div class="header__page-name">
    <h1 class="heading heading--main">Case Details</h1>
  </div>
</div>
<div class="wrapper--dash-content dash-content overlay__wrapper" [class.overlay--visible]="isLoading">
  <div class="overlay__content">
    <div class="overlay__spinner">
      <span class="icon icon--loading"></span>
    </div>
  </div>

  <div class="row">
    <div class="col col__md-8 gutter--right-3x">
      <div class="box box--grey box--radius">
        <div class="resume resume--centered">
          <div class="contact-details__row" >
            <div class="contact-details__label">Created At</div>
            <div class="contact-details__details">
                {{caseDetails?.created_at | date: "MM/dd/yyyy hh:mm a ZZZZ"}}
            </div>
          </div>
          <div class="contact-details__row" >
            <div class="contact-details__label">Updated At</div>
            <div class="contact-details__details">
                {{caseDetails?.updated_at | date: "MM/dd/yyyy hh:mm a ZZZZ"}}
            </div>
          </div>
          <div class="contact-details__row" >
            <div class="contact-details__label">MR Complete Date</div>
            <div class="contact-details__details">
                {{caseDetails?.date_mr_complete | date: "MM/dd/yyyy hh:mm a ZZZZ"}}
            </div>
          </div>
          <div class="contact-details__row" >
            <div class="contact-details__label">Importance</div>
            <div class="contact-details__details">
                {{caseDetails?.importance}}
            </div>
          </div>
          <div class="contact-details__row" >
            <div class="contact-details__label">Client</div>
            <div class="contact-details__details">
                {{caseDetails?.patient?.user?.user_partners[0].partner.partner_name}}
            </div>
          </div>
          <div class="contact-details__row" >
            <div class="contact-details__label">Patient</div>
            <div class="contact-details__details">
                {{caseDetails?.patient?.user.full_name}}
            </div>
          </div>
          <div class="contact-details__row" >
            <div class="contact-details__label">Clinical Specialties Program</div>
            <div class="contact-details__details">
              {{caseDetails?.speciality_program == null? "N/A" : caseDetails?.speciality_program}}
            </div>
          </div>
          <div class="contact-details__row" >
            <div class="contact-details__label">Summary State</div>
            <div class="contact-details__details">
              {{caseDetails?.summary_state}}
            </div>
          </div>
          <div class="contact-details__row" >
            <div class="contact-details__label">Phone Number</div>
            <div class="contact-details__details">
              {{caseDetails?.phone_number == null? "N/A" : caseDetails?.phone_number}}
            </div>
          </div>
          <div class="contact-details__row" >
            <div class="contact-details__label">Interpreter Needed</div>
            <div class="contact-details__details">
              {{caseDetails?.interpreter_needed == true ? "Yes" : "No" }}
            </div>
          </div>
          <div class="contact-details__row" >
            <div class="contact-details__label">Translator Needed</div>
            <div class="contact-details__details">
              {{caseDetails?.translator_needed == true ? "Yes" : "No" }}
            </div>
          </div>
          <div class="contact-details__row" >
            <div class="contact-details__label">MR Completed</div>
            <div class="contact-details__details">
              {{caseDetails?.is_mr_complete == true ? "Yes" : "No" }}
            </div>
          </div>
          <div class="contact-details__row" >
            <div class="contact-details__label">Submitted</div>
            <div class="contact-details__details">
              {{caseDetails?.is_submitted == true ? "Yes" : "No" }}
            </div>
          </div>
          <div class="contact-details__row" >
            <div class="contact-details__label">VIP</div>
            <div class="contact-details__details">
              {{caseDetails?.is_vip == true ? "Yes" : "No" }}
            </div>
          </div>
          <div class="contact-details__row" >
            <div class="contact-details__label">Agreement</div>
            <div class="contact-details__details">
              {{caseDetails?.agreement == true ? "Yes" : "No" }}
            </div>
          </div>

          <div class="hr hr--space-down-x hr--space-up-x"></div>

          <div class="contact-details__row--single" >
            <div class="contact-details__label">Additional Note</div>
            <div class="text--dark-grey box--margin-bottom-x">
              {{caseDetails?.case_additional_note == null? "N/A" : caseDetails?.case_additional_note}}
            </div>
          </div>
          <div class="contact-details__row--single" >
            <div class="contact-details__label">Careteam Note</div>
            <div class="text--dark-grey box--margin-bottom-x">
              {{caseDetails?.case_careteam_note == null? "N/A" : caseDetails?.case_careteam_note}}
            </div>
          </div>
          <div class="contact-details__row--single" >
            <div class="contact-details__label">Patient Note</div>
            <div class="text--dark-grey box--margin-bottom-x">
              {{caseDetails?.case_patient_note == null? "N/A" : caseDetails?.case_patient_note}}
            </div>
          </div>
          <div class="contact-details__row--single" >
            <div class="contact-details__label">Report Notes</div>
            <div class="text--dark-grey box--margin-bottom-x">
              {{caseDetails?.report_notes == null? "N/A" : caseDetails?.report_notes}}
            </div>
          </div>
          <div class="contact-details__row--single" >
            <div class="contact-details__label">Patient Diagnosis</div>
            <div class="text--dark-grey box--margin-bottom-x" [innerHtml] = "caseDetails?.patient_diagnosis == null? 'N/A' : caseDetails?.patient_diagnosis">
            </div>
          </div>

          <div *ngIf="caseDetails?.speciality_program === 'MSO'">
            <div class="hr hr--space-down-x hr--space-up-x"></div>
              <div class="contact-details__row--single" >
                <div class="contact-details__label">Surgical Intervention</div>
                <div class="text--dark-grey box--margin-bottom-x">
                  {{caseDetails?.surgical_intervention == null? "N/A" : caseDetails?.surgical_intervention}}
                </div>
              </div>
              <div class="contact-details__row--single" >
                <div class="contact-details__label">Surgical Intervention Note</div>
                <div class="text--dark-grey box--margin-bottom-x">
                  {{caseDetails?.surgical_intervention_note == null? "N/A" : caseDetails?.surgical_intervention_note}}
                </div>
              </div>
              <div class="contact-details__row--single" >
                <div class="contact-details__label">Surgical Recommendation</div>
                <div class="text--dark-grey box--margin-bottom-x">
                  {{caseDetails?.surgical_recommendation == null? "N/A" : caseDetails?.surgical_recommendation}}
                </div>
              </div>
              <div class="contact-details__row--single" >
                <div class="contact-details__label">Surgical Recommendation Note</div>
                <div class="text--dark-grey box--margin-bottom-x">
                  {{caseDetails?.surgical_recommendation_note == null? "N/A" : caseDetails?.surgical_recommendation_note}}
                </div>
              </div>
           </div>
          </div>
      </div>
    </div>
    <div class="col col__md-4">
      <div class="box box--white box--radius box--margin-bottom-2x">
        <div class="contact-details">
          <div class="contact-details__row">
            <div class="contact-details__label">Case Type</div>
            <div class="contact-details__details">
                <a class="badge badge-secondary">{{caseDetails?.case_type}}</a>
            </div>
          </div>
          <div class="contact-details__row">
            <div class="contact-details__label">Case Status</div>
            <div class="contact-details__details">
                <a class="badge badge-secondary">{{caseDetails?.case_status}}</a>
            </div>
          </div>
          <div class="contact-details__row">
            <div class="contact-details__label">Case Identifier</div>
            <div class="contact-details__details">
                <a class="badge badge-secondary">{{caseDetails?.case_identifier}}</a>
            </div>
          </div>
          <div class="contact-details__row" *ngIf="caseDetails?.allyCaseNumber">
            <div class="contact-details__label">Ally Case Number</div>
            <div class="contact-details__details " (click)="copyText(caseDetails?.allyCaseNumber)">
                <a class="badge badge-secondary copytip"
                    id="allyCaseReference">{{caseDetails?.allyCaseNumber == null ? 'N/A' :
                    (caseDetails?.allyCaseNumber.length>15)? (caseDetails?.allyCaseNumber |
                    slice:0:15)+'...':(caseDetails?.allyCaseNumber) }} <i class="fa fa-clipboard "
                        aria-hidden="true">
                        <span class="copytiptext">{{CopyText}}</span> </i>
                </a>

            </div>
        </div>

          <div class="hr hr--space-down-x hr--space-up-x"></div>
          <div class="contact-details__btns">
            <a [routerLink]="['/technical/case/edit/', caseId]" class="btn btn--blue btn--small">Edit</a>
            <!-- <a href="#" class="btn btn--green btn--small" (click)="updateCaseStatus($event)" *ngIf="caseDetails?.case_status === 'CANCELLED'">Restore Case</a>
            <a href="#" class="btn btn--red btn--small" (click)="updateCaseStatus($event)" *ngIf="caseDetails?.case_status !== 'CANCELLED'">Cancel Case</a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
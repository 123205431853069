/* eslint-disable no-undef */
import '@angular/compiler';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Configuration, CONFIG_URL } from './app/services/config-asset-loader.service';
import { AppModule } from './app/app.module';

fetch(CONFIG_URL, { method: 'get' }).then((response) => {
  response.json().then((settings: Configuration) => {

    if (settings.production) {
      enableProdMode();
    }
        
    platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err));
  });
});

<div class="box box--white box--radius ">
    <div class="contact-details no-border no-pad no-mar">
        <div class="contact-details__row">
            <div class="contact-details__label label-is-heading">General FAQs
                <i class="fa fa-info-circle"> </i>
                </div>
        </div>
        <div class="contact-details__row" *ngFor="let questionAnswer of faqQuestionAnswer" (click)="showFaqData(questionAnswer)">
            * {{questionAnswer.question}}

        </div>  


        <swal #faqTemplateSwal showConfirmButton="false" confirmButtonText="X Close" backdrop="#eef3f6" confirmButtonColor="#DB161E" buttons="false">
            <form [formGroup]="faqForm" *swalPortal>
                <swal-title> <h5><b>Q. {{questionAnswer.question}} </b></h5></swal-title>
                <hr>
                <div class="form__row">
                    <div class="form__field" [innerHtml] = "questionAnswer.answer | safeHtml"></div>
                  </div>
                  <div class="form__row" *ngIf="questionAnswer.answerOptions">
                    <div *ngFor="let answerOption of questionAnswer.answerOptions">
                        <div class="form__field mt-3" [innerHtml] = "answerOption | safeHtml">{{answerOption}}</div>
                    </div>
                </div>                    
            </form>

        </swal>


        <swal #faqSendEmailTemplate showConfirmButton="true" showCancelButton="true" [inputValidator]="emailValidator()" input="textarea"  (dismiss)="this.showFaqData(questionAnswer)"  cancelButtonColor="#DB161E" confirmButtonColor="#1991EB" inputPlaceholder="textarea" confirmButtonText="Send Email" backdrop="#eef3f6"  reverseButtons="true">
           <div *swalPortal>
            <h6><b>{{title}}</b></h6>
           </div>
        </swal>

        <swal #faqSendEmailConfirmationTemplate title="Alert" text="Are you sure you want to send email ? " iconColor="orange" icon="warning" showConfirmButton="true" showCancelButton="true" (confirm)="sendFaqEmail()"  (dismiss)="faqSendEmailTemplate.fire()" cancelButtonText="No"  cancelButtonColor="#DB161E" confirmButtonColor="#1991EB" confirmButtonText="Yes" backdrop="#eef3f6" showCancelButton="true"   reverseButtons="true">
            <div *ngIf="isLoading">Sender: {{currentUser.email}}</div>
            <div *ngIf="isLoading"><small>Sending Email (this may take a few seconds) ...</small></div>
        </swal>

    </div>
</div>
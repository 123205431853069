<div>
  <img *ngIf="notification.priority === 'HIGH'" class="high-priority" src="assets/images/high.svg" title="High priority"/>
  <img *ngIf="notification.priority === 'LOW'" class="high-priority" src="assets/images/flag.svg"style="width:13px" title="Low priority"/>

  <span class="title" class="title" *ngIf="notification.eventType === 'CASE_CANCELLED_BY_ALLY' || notification.eventType === 'CASE_CANCELLED_BY_CLOUD'">Case <a class="caseLink" (click)="redirect(notification.caseId)" href="javascript:void(0);"> #{{notification?.caseDetails?.case_identifier}}</a> cancelled</span>

  <span class="title" *ngIf="notification.eventType === 'NEW_MESSAGE_FROM_ALLY'">New message from Ally on Case<a class="caseLink" (click)="redirect(notification.caseId)" href="javascript:void(0);"> #{{notification?.caseDetails?.case_identifier}}</a></span>

  <!-- from {{notification.sender.full_name}}  -->
  <span class="title" *ngIf="notification.eventType === 'NEW_MESSAGE_FROM_CLOUD'">New message on Case<a class="caseLink" (click)="redirect(notification.caseId)" href="javascript:void(0);"> #{{notification?.caseDetails?.case_identifier}}</a></span>
  
  <span class="title" *ngIf="notification.eventType === 'CHATTER_NOTE'">New note on Case<a class="caseLink" (click)="redirect(notification.caseId)" href="javascript:void(0);"> #{{notification?.caseDetails?.case_identifier}}</a></span>

  <span class="title" *ngIf="notification.eventType === 'TRACKING_UPDATED'">Tracking line has been updated for Case<a class="caseLink" (click)="redirect(notification.caseId)" href="javascript:void(0);"> #{{notification?.caseDetails?.case_identifier}}</a></span>

  <span class="title" *ngIf="notification.eventType === 'TRACKING_NOTE_ADDED'">New message on tracking line for Case<a class="caseLink" (click)="redirect(notification.caseId)" href="javascript:void(0);"> #{{notification?.caseDetails?.case_identifier}}</a></span>

  <span class="title" *ngIf="notification.eventType === 'TRACKING_STATUS_CHANGED'">Tracking line collection complete on Case<a class="caseLink" (click)="redirect(notification.caseId)" href="javascript:void(0);"> #{{notification?.caseDetails?.case_identifier}}</a></span>

  <span class="title" *ngIf="notification.eventType === 'TRACKING_STATUS_FINAL_CHANGED'">Final Tracking line Complete on Case<a class="caseLink" (click)="redirect(notification.caseId)" href="javascript:void(0);"> #{{notification?.caseDetails?.case_identifier}}</a></span>

  <span class="title" *ngIf="notification.eventType === 'QUESTIONS_ADDED_BY_ALLY'">Enhanced Questions added for Case<a class="caseLink" (click)="redirect(notification.caseId)" href="javascript:void(0);"> #{{notification?.caseDetails?.case_identifier}}</a></span>
  
  <span class="title" *ngIf="notification.eventType === 'QUESTIONS_UPDATED_BY_ALLY'">Enhanced Question Number {{notification?.description}} changed for Case<a class="caseLink" (click)="redirect(notification.caseId)" href="javascript:void(0);"> #{{notification?.caseDetails?.case_identifier}}</a></span>
  
  <span class="title" *ngIf="notification.eventType === 'CASE_ASSIGNMENT'">New Case Assignment with Case<a class="caseLink" (click)="redirect(notification.caseId)" href="javascript:void(0);"> #{{notification?.caseDetails?.case_identifier}}</a></span>

  <span class="title" *ngIf="notification.eventType === 'ALLY_CASE_ASSIGNMENT'">Ally Case Assignment on Case<a class="caseLink" (click)="redirect(notification.caseId, notification.eventType)" href="javascript:void(0);"> #{{notification?.caseDetails?.case_identifier}}</a></span>

  <span class="title" *ngIf="notification.eventType === 'ALLY_CASE_ASSIGNMENT_REMOVAL'">Removal from Ally Case Assignment on Case<a class="caseLink" (click)="redirect(notification.caseId, notification.eventType)" href="javascript:void(0);"> #{{notification?.caseDetails?.case_identifier}}</a></span>

  <span class="title" *ngIf="notification.eventType === 'QUESTIONS_UPDATED_BY_CLOUD'">Questions updated for Case<a class="caseLink" (click)="redirect(notification.caseId)" href="javascript:void(0);"> #{{notification?.caseDetails?.case_identifier}}</a></span>

  <span class="title" *ngIf="notification.eventType === 'REPORT_SUBMITTED_BY_MD'">MD has submitted report for Case<a class="caseLink" (click)="redirect(notification.caseId)" href="javascript:void(0);"> #{{notification?.caseDetails?.case_identifier}}</a></span>

  <span class="title" *ngIf="notification.eventType === 'MEDICAL_SUMMARY_UPLOADED_BY_MR_CORDINATOR'">Medical Record Summary uploaded for Case<a class="caseLink" (click)="redirect(notification.caseId)" href="javascript:void(0);"> #{{notification?.caseDetails?.case_identifier}}</a></span>

  <span class="title" *ngIf="notification.eventType === 'MD_REQUEST_FOR_ADDINGTION_INFO'">MD request for more information for Case<a class="caseLink" (click)="redirect(notification.caseId)" href="javascript:void(0);"> #{{notification?.caseDetails?.case_identifier}}</a></span>
 
</div>
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { UserModel } from '../domains/userModel';

export class DoctorModel extends UserModel  {

    phone_number: string;

    address: string;
    city: string;
    state: string;
    zip: string;
    gender: string;
    website: string;
    institution: string;
    previous_employer: string;
    stage: string;
    bio: string;
    medical_school: string;
    residency: string;
    residency_two: string;
    internship: string;
    internship_two: string;
    fellowship: string;
    fellowship_two: string;
    rate_per_hour: number;
    aquisition_id: number;
    referred_by: string;
    doctor_note: string;
    // time_zone: string;
    // time_zone_offset: string;
    title: string;

    user_languages: string[];
    doctor_conditions: string[];
    doctor_info: any;
    time_zone: any;
    time_zone_offset: any;

    availability: string;
    note: string;
    allyUserRef: string;
    dob?: string

    constructor() {
      super();
      this.doctor_info = {};
      this.full_name = '';
      this.doctor_info['gender'] = 'select';
      this.user_languages = [];
      this.auto_generate_pwd = true;

      
      this.doctor_info['address'] = '';
      this.doctor_info['city'] = '';
      this.doctor_info['state'] = '';
      this.doctor_info['zip'] = '';
      this.doctor_info['stage'] = '';
      this.doctor_info['previous_employer'] = '';

      this.doctor_info['title'] = '';
      this.doctor_info['website'] = '';
      this.doctor_info['institution'] = '';
      this.doctor_info['website'] = '';
      this.doctor_info['doctor_note'] = '';
      this.availability = '';
      this.note = '';
      this.allyUserRef = null;
      this.dob = null;
      this.availability= null;
      // this.doctor_info['time_zone'] = ''
      // this.doctor_info['time_zone_offset'] = ''


    }
}

/* eslint-disable no-undef */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-prototype-builtins */

import { FormGroup, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CASE_STATUS_TYPE, USER_ROLE_TYPE } from '../constant';
import { LoginService } from '../services/login.service';

export class CommonFuntions {
  public static validateAllFormFields = function (formGroup: FormGroup) {
    if (formGroup === null) { return; }

    Object.keys(formGroup.controls).forEach(
      field => {
        const control = formGroup.get(field);

        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
        else if (control instanceof FormGroup) {
          this.validateAllFormFields(control);
        }
        else if (control instanceof FormArray) {
          this.validateFormArray(control);
        }
      }
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private static validateFormArray = function (formArray: FormArray) {
    for (const index in formArray.controls) {
      if (formArray.controls.hasOwnProperty(index)) {
        const control = formArray.controls[index];
        if (control instanceof FormControl) {
          formArray.markAsTouched({ onlySelf: true });
        }
        else if ((control instanceof FormGroup)) {
          this.validateAllFormFields(formArray.controls[index]);
        }
        else if (control instanceof FormArray) {
          this.validateFormArray(control);
        }
      }
    }
  }

  public static guardForAction = function (loginService: LoginService, router: Router) {
    if (!loginService.isLoggedIn()) {
      const currentUrl = router.url;
      router.navigate(['/login'], {
        queryParams: {
          returnUrl: currentUrl
        }
      });
      return false;
    }
    return true;
  }

  public static validateDropDown(control: FormControl) {
    const value = control.value;

    if (!isNaN(value) && value === 0) {
      return { required: true };
    }
    else if (isNaN(value) && (value as string).toLowerCase() === 'select') {
      return { required: true, error: 'This field is required. Should select a value' };
    }

    return null;
  }

  public static isChecked(control: FormControl) {
    const value = control.value;

    if (!value) {
      return {
        checked: false,
        error: 'This value should be checked'
      };
    }
  }

  public static validateZoomLink(control: FormControl) {
    const value = control.value;
    const regex = /https:+\/\/+consumermedical.zoom.us\/+[a-z]\/+[0-9]+\W+[a-z]+=+[A-za-z0-9]+$/g;
    
    if(value){
      const valid = value.match(regex);

      if (!valid) {
        return {
          invalidLink: true,
          error: 'Incorrect Zoom Link'
        };
      }
    }

  }

  public static validateDropDownTime(control: FormControl) {
    const value = control.value;

    if (!isNaN(value) && value === -1) {
      return { required: true };
    }
    else if (isNaN(value) && (value as string).toLowerCase() === 'select') {
      return { required: true, error: 'This field is required. Should select a value' };
    }

    return null;
  }

  public static fomrArrayMinLength(min: number) {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value.length >= min) {
        return null;
      }

      return { minLengthArray: { valid: false } };
    };
  }

  public static fomrArrayMaxLength(max: number) {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value.length > max) {
        return { maxLengthArray: { valid: false } };
      }

      return null;
    };
  }

  public static sortByKey(jsObj) {
    const sortedArray = [];

    // Push each JSON Object entry in array by [key, value]
    for (const i in jsObj) {
      if (jsObj.hasOwnProperty(i)) {
        sortedArray.push([i, jsObj[i]]);
      }
    }

    // Run native sort function and returns sorted array.
    return sortedArray.sort();
  }

  public static setStatusInProgress(statusList, statusString) {
    statusList.sort(function (obj1, obj2) {
      return obj1.status.id - obj2.status.id;
    });

    const finishedIndex = statusList.findIndex(eachStatus => eachStatus.status.status.toLowerCase() === statusString.toLowerCase());
    let inprogressList = [];
    if (finishedIndex > -1) {

      const finishedStatus = statusList[finishedIndex];

      if (finishedStatus.status.status.toLowerCase() === 'requested') {
        const paraList = ['mdmgmt', 'translatedmr'];
        const includedParaList = [];
        paraList.forEach(statusToFind => {
          const index = statusList.findIndex(eachStatus => eachStatus.status.status.toLowerCase() === statusToFind);

          if (index > -1) {
            includedParaList.push(statusList[index]);
          }
        });

        // for (var i = 0; i < includedParaList.length; i++) {
        // 	if (includedParaList[i].state !== 'FINISHED') {
        // 		return inprogressList;
        // 	}
        // }

        includedParaList.sort(function (obj1, obj2) {
          return obj1.status.id - obj2.status.id;
        });

        // var largestParaListIndex = includedParaList[includedParaList.length - 1].status.id;
        const finishedIndex = 1;

        const indexToInprogress = finishedIndex + 1;
        let inprogressStatus = statusList[indexToInprogress];

        if (inprogressStatus.state.toLowerCase() === 'finished') {
          const listOfInprogress = this.setStatusInProgress(statusList, inprogressStatus.status.status);
          if (listOfInprogress != null && listOfInprogress.length > 0) {
            inprogressStatus = listOfInprogress[0];
            inprogressList.push(inprogressStatus);
          }
        }
        else {
          inprogressList.push(inprogressStatus);
        }

      }

      if (finishedStatus.status.status.toLowerCase() === 'translatedmr'
        || finishedStatus.status.status.toLowerCase() === 'written'
        || finishedStatus.status.status.toLowerCase() === 'schedule'
        || finishedStatus.status.status.toLowerCase() === 'translatewr') {
        const paraList = ['translatedmr', 'written', 'schedule', 'translatewr'];
        const includedParaList = [];

        paraList.forEach(statusToFind => {
          const index = statusList.findIndex(eachStatus => eachStatus.status.status.toLowerCase() === statusToFind);

          if (index > -1) {
            includedParaList.push(statusList[index]);
          }
        });

        for (let i = 0; i < includedParaList.length; i++) {
          if (includedParaList[i].state !== 'FINISHED') {
            inprogressList = includedParaList.filter(each => each.state !== 'FINISHED');
            return inprogressList;
          }
        }

        includedParaList.sort(function (obj1, obj2) {
          return obj1.status.id - obj2.status.id;
        });

        const largestParaListIndex = includedParaList[includedParaList.length - 1].status.id;
        const finishedIndex = statusList.findIndex(eachStatus => eachStatus.status.id === largestParaListIndex);

        const indexToInprogress = finishedIndex + 1;
        let inprogressStatus = statusList[indexToInprogress];

        if (inprogressStatus.state.toLowerCase() === 'finished') {
          const listOfInprogress = this.setStatusInProgress(statusList, inprogressStatus.status.status);
          if (listOfInprogress != null && listOfInprogress.length > 0) {
            inprogressStatus = listOfInprogress[0];
            inprogressList.push(inprogressStatus);
          }
        }
        else {
          inprogressList.push(inprogressStatus);
        }

      }
      else {

        const indexToInprogress = finishedIndex + 1;

        if (indexToInprogress >= statusList.length) { return inprogressList; }

        let inprogressStatus = statusList[indexToInprogress];

        if (inprogressStatus === null) { return inprogressList; }

        if (inprogressStatus.status.status.toLowerCase() === 'translatedmr'
          || inprogressStatus.status.status.toLowerCase() === 'written'
          || inprogressStatus.status.status.toLowerCase() === 'schedule'
          || inprogressStatus.status.status.toLowerCase() === 'translatewr') {
          const paraList = ['translatedmr', 'written', 'schedule', 'translatewr'];

          paraList.forEach(statusToFind => {
            const index = statusList.findIndex(eachStatus => eachStatus.status.status.toLowerCase() === statusToFind);

            if (index > -1) {
              inprogressList.push(statusList[index]);
            }
          });

          let isParaStatusFinished = true;

          for (let i = 0; i < inprogressList.length; i++) {
            if (inprogressList[i].state.toLowerCase() !== 'finished') {
              isParaStatusFinished = false;
              break;
            }
          }

          if (isParaStatusFinished) {
            const listOfInprogress = this.setStatusInProgress(statusList, inprogressList[inprogressList.length - 1].status.status);
            if (listOfInprogress != null && listOfInprogress.length > 0) {
              inprogressStatus = listOfInprogress[0];
              inprogressList.push(inprogressStatus);
            }
          }
        }
        else {
          if (inprogressStatus.state.toLowerCase() === 'finished') {
            const listOfInprogress = this.setStatusInProgress(statusList, inprogressStatus.status.status);
            if (listOfInprogress != null && listOfInprogress.length > 0) {
              inprogressStatus = listOfInprogress[0];
              inprogressList.push(inprogressStatus);
            }
          }
          else {
            inprogressList.push(inprogressStatus);
          }
        }
      }
    }

    if (inprogressList.length > 1) {
      inprogressList.sort(function (obj1, obj2) {
        return obj1.status.id - obj2.status.id;
      });
    }

    return inprogressList;
  }

  public static getNextParaStatus(statusList, statusString) {
    statusList.sort(function (obj1, obj2) {
      return obj1.status.id - obj2.status.id;
    });

    const finishedIndex = statusList.findIndex(eachStatus => eachStatus.status.status.toLowerCase() === statusString.toLowerCase());

    if (finishedIndex > -1) {
      const indexToInprogress = finishedIndex + 1;
      return statusList[indexToInprogress];
    }

    return null;
  }

  public static getFirstInProgressParaStatus(stastusList): any {
    const paraList = ['translatedmr', 'written', 'schedule', 'translatewr'];

    for (let i = 0; i < stastusList.length; i++) {
      const statusString = stastusList[i].status.status;

      if (paraList.includes(statusString.toLowerCase()) && stastusList[i].state.toLowerCase() === 'inprogress') {
        return stastusList[i];
      }
    }
  }

  /* public static convertUtcToLocalTimeZone(uctTime: string) {
    let moment = moment.utc(uctTime);
    let localTimeZone = momentTz.tz.guess();
    return moment.tz(localTimeZone).format('YYYY-MM-DD HH:mm');
  }

  public static convertLocalTimeZoneToUtc(localTime: string) {
    const moment = moment(localTime);
    return moment.tz('UTC').format('YYYY-MM-DD HH:mm');
  } */

  public static ValidateEmail(email: AbstractControl) {

    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return emailRegex.test(email.value) ? null : {
      email: {
        valid: false
      }
    };
  }

  public static ValidatePassword(password: AbstractControl) {

    const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    return passwordRegex.test(password.value) ? null : {
      password: {
        valid: false
      }
    };
  }

  public static checkIfHoldOrCancelledOrFinishedorMd(caseDetails: any, isMdManager: boolean) {
    let check = false;
    if ([CASE_STATUS_TYPE.HOLD, CASE_STATUS_TYPE.CANCELLED, CASE_STATUS_TYPE.FINISHED].includes(caseDetails.case_status.toUpperCase())
    || isMdManager) {
      check = true;
    }
    return check;
  }

  public static checkIfHoldOrCancelledOrFinished(caseDetails: any) {
    if (caseDetails.case_status.toLowerCase() === 'hold' ||
      caseDetails.case_status.toLowerCase() === 'cancelled' ||

      caseDetails.case_status.toLowerCase() === 'finished') {
      return true;
    }

    return false;
  }

  public static clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  public static getPartnersForAuthorizedUser(user_role: string): boolean{
    if([
      USER_ROLE_TYPE.ADMIN,
      USER_ROLE_TYPE.CM_MANAGER,
      USER_ROLE_TYPE.DOCTOR,
      USER_ROLE_TYPE.CASE_COORDINATOR,
      USER_ROLE_TYPE.CARE_TEAM,
      USER_ROLE_TYPE.PARTNER
    ].includes(user_role?.toLocaleLowerCase())){
      return true;
    } 
    return false;
  }
}
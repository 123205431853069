/* eslint-disable max-len */
/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable no-undef */
import { fromEvent } from 'rxjs';
import { Component, OnInit, ViewChildren, QueryList, Renderer2 } from '@angular/core';
import {find} from 'lodash';

import * as moment from 'moment/moment';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { WEBHOOK_LABELS} from './constant';
import { CommonFuntions } from '../../common/common.function';
import { CaseService } from '../../services/case.service';
import { PopUpService } from '../../services/pop-up.service';
import { ConfigAssetLoaderService } from '../../services/config-asset-loader.service';
import { LoginService } from '../../services/login.service';
import { DatashareService } from '../../services/datashare.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';


@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {

  @ViewChildren(DataTableDirective)
  datatableElement: QueryList<DataTableDirective>;
  dtOptions1: DataTables.Settings = {};
  searchValue = '';
  clearSearch(): void {
    this.searchValue = '';
    this.search('');
  }

  user: any;

  isInprogress: boolean;
  showDetails = false;
  currentData: any;
  allLogs = [];
  totalCounts = 0;
  logTypes = [
    {
      id: WEBHOOK_LABELS.ON_CARETEAM_ASSINGED,
      name: 'Careteam Assigned Trigger',
      checked: false
    },
    {
      id: WEBHOOK_LABELS.ON_NEW_MESSAGE,
      name: 'New Message Trigger',
      checked: false
    },
    {
      id: WEBHOOK_LABELS.ON_QUESTIONS_UPDATE,
      name: 'Questions Update Trigger',
      checked: false
    },
    {
      id: WEBHOOK_LABELS.ON_SUMMARY_STARTED,
      name: 'Summary Started Trigger',
      checked: false
    },
    {
      id: WEBHOOK_LABELS.ON_FINAL_REPORT_READY,
      name: 'Final Report Ready Trigger',
      checked: false
    },
    {
      id: WEBHOOK_LABELS.ON_VIDEO_CONSULTATION_SCHEDULED,
      name: 'Video Consultation Scheduled',
      checked: false
    },
    {
      id: WEBHOOK_LABELS.ON_VIDEO_CONSULTATION_COMPLETED,
      name: 'Video Consultation Completed',
      checked: false
    },
    {
      id: WEBHOOK_LABELS.ON_MR_COLLECTION_COMPLETE,
      name: 'MR Collection Complete',
      checked: false
    },
    {
      id: WEBHOOK_LABELS.ON_TRACKING_LINE_NOTE,
      name: 'Tracking Line Note',
      checked: false
    },
    {
      id: WEBHOOK_LABELS.ON_DOCTOR_ASSIGNED_OR_REASSIGNED,
      name: 'Doctor Assigned Or Reassigned',
      checked: false
    },
    {
      id: WEBHOOK_LABELS.ON_CARETEAM_SUMMARY_STAGE_ASSIGNED,
      name: 'Careteam Assigned Summary Stage',
      checked: false
    },
    {
      id: WEBHOOK_LABELS.ARCHIVED,
      name: 'Archived Logs',
      checked: false
    },

  ];
  filterIds = [];

  constructor(
    public renderer: Renderer2,
    public loginService: LoginService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public caseService: CaseService,
    public dataShare: DatashareService,
    public popUpService: PopUpService
  ) { }

  ngOnInit() {
    this.isInprogress = true;
    this.dtOptions1 = this.getDtOption(null);
    const user = this.loginService.getCurrentUser();
    if (user.user_type.type !=='Admin'){
      this.router.navigate(['/']);
    }
    this.handleSearch();
  }

  ngAfterViewInit(): void {
    this.renderer.listen('document', 'click', (event: any) => {
      if (event.target.hasAttribute('viewCase')) {
        const caseId = parseInt(event.target.getAttribute('viewCase'));
        console.log('viewcase', caseId);
        if (caseId) {
          this.router.navigate(['/case', caseId]);
        }
      }
      if (event.target.hasAttribute('viewData')) {
        const logId = parseInt(event.target.getAttribute('viewData'));
        const data = find(this.allLogs, { logId });
        console.log('data', logId, data);
        if (data) {
          this.currentData = {...data};
          this.toogleDetails();
        }
      }
      if (event.target.hasAttribute('triggerData')) {
        const logId = parseInt(event.target.getAttribute('triggerData'));
        const data = find(this.allLogs, { logId });
        if (data) {
          this.showConfirmation('Are you sure you want to re-trigger webhook?',function(){
            this.caseService.reTriggerWebhookFor({ logId }).subscribe(
              function(result) {
                if (result['msg']) {
                  this.showAlert(`${result['msg']}.`);
                  this.reloadData();
                }
                this.popUpService.hideDiv('confirmDialog');								
              }.bind(this), 
              function(error) {
                const message = error.error.message;
                this.showAlert(message);
                this.popUpService.hideDiv('confirmDialog');
  
              }.bind(this),
            );
            this.popUpService.hideDiv('confirmDialog');
          }.bind(this));
        }
      }
    });
    this.clearSearch();
  }

  getDtOption(_list: string) {
    const url = ConfigAssetLoaderService.getApiURL() + '/v2/webhook-logs';
    const option = {
      ajax: {
        url: url,
        dataSrc: function (json) {
          this.totalCounts = json.recordsTotal;
          this.allLogs = json.data;
          this.isInprogress = false;
          return json.data;
        }.bind(this),
        type: 'GET',
        data: function (d) {
          d.status = this.filterIds;
        }.bind(this),
        beforeSend: function (xhr) {
          if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
            xhr.abort();
            return;
          }
          this.errorString = null;
          const token = localStorage.getItem('token');
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }.bind(this)
      },
      pagingType: 'full_numbers',
      serverSide: true,
      processing: true,
      lengthChange: false,
      searching: true,
      pageLength: 10,
      stateSave: true,
      searchDelay: 350,
      stateDuration: 5 * 20,
      order: [[3, 'DESC']],
      info: false,
      language: {
        emptyTable: 'No data to show'
      },
      rowCallback: this.handleEvent.bind(this)
    };

    option['columns'] = [
      {
        title: 'Webhook',
        render: function (_data, _type, row) {
          const deletedAt = moment(row.deleted_at);
          const formattedDateTime = deletedAt.format('MM/DD/YYYY') + ' at ' + deletedAt.format('hh:mm a');
          const  consultationValue = deletedAt.isValid() ? formattedDateTime : row.deleted_at;
          
          let  cell = '<div class="text text--bold" style="min-width: 200px;">' + row.label + '</div>';
          if (consultationValue) {
            cell += '<div class="text--grey text--small">Archived</div>';
          }
          return cell;
        }
      },
      {
        title: 'Case',
        render: function (_data, _type, row) {
          const cell = `<div class="text text--bold" style="min-width: 100px;"><a href="javascript:void(0)" viewCase="${row.caseId}">${row.caseDetails.case_identifier}<a/></div>`;
          return cell;
        }
      },
      {
        title: 'Failure Reason',
        orderable: false,
        render: function (_data, _type, row) {
          const cell = '<div class="text-failure">' + row.failureReason + '</div>';
          return cell;
        }
      },
      {
        title: 'Created At',
        render: function (_data, _type, row) {
          const createdAt = moment(row.created_at);
          const formattedDateTime = createdAt.format('MM/DD/YYYY') + ' at ' + createdAt.format('hh:mm a');
          const consultationValue = createdAt.isValid() ? formattedDateTime : row.created_at;
          const cell = '<div  class="text text-center" style="width: 100px;">' + consultationValue + '</div>';
          return cell;
        }
      },
      {
        title: 'View Detail',
        orderable: false,
        render: function (_data, _type, row) {
          const cell = `<div class="text-center"> <a href="javascript:void(0)" class="btn btn--blue btn--mini margin--l-x" viewData="${row.logId}">
          View Details
        </a> </div>`;
          return cell;
        }
      },
      {
        title: 'Action',
        orderable: false,
        render: function (_data, _type, row) {
          let cell = '';
          if (!row.isSuccess) cell = `<div class="text-center" ><a href="javascript:void(0)" class="btn btn--red btn--mini margin--l-x" triggerData="${row.logId}">Try Again</a> </div>`;
          if (row.isSuccess) cell = '<div class="text-center"><span class="icon icon--green-tick" title="Already Executed"></span></div>';
          return cell;
        }
      }
    ];
    return option;
  }

  handleEvent(row: Node, _data: any[] | Object, _index: number) {
    // Unbind first in order to avoid duplicate handler
    $('td', row).unbind('click');
    $('td', row).bind('click', function () {
      
    }.bind(this));
  }

  /** Search only when the user stop typing the meaningful word
   * Search only when the previous query is different than current.
   */
  handleSearch(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const search$ = fromEvent(document.getElementById('caseSearch'), 'keyup');
    search$.pipe(
      map((event: any) => event.target.value),
      debounceTime(1500),
      distinctUntilChanged()
    ).subscribe(
      result => {
        this.search(result);
      }
    );
  }

  search(search: string): void {
    if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
      return;
    }
    this.datatableElement.toArray()[0].dtInstance.then(
      (dtInstance: DataTables.Api) => {
        dtInstance.search(search).draw();
      }
    );
  }

  toogleDetails(value?) {
    this.showDetails = value || !this.showDetails;
  }

  toggleTemplate(event) {
    console.log('event', event);
    this.toogleDetails(false);
  }

  reloadData() {
    const url = ConfigAssetLoaderService.getApiURL() + '/v2/webhook-logs';
    this.datatableElement.toArray()[0].dtInstance.then(
      (dtInstance: DataTables.Api) => {
        // abort previous api call
        dtInstance.settings()[0].jqXHR.abort();
        dtInstance.ajax.url(url).load();
      }
    );
    this.isInprogress = false;
  }

  addRemoveFilter(status: any, _event?: Event) {

    let isChecked;
    this.logTypes.forEach(element => {
      if (element.id === status.id) {
        element.checked = !element.checked;
        isChecked = element.checked;
      }
    });

    if (isChecked) {
      this.filterIds.push(status.id);
    }
    else {
      this.filterIds = this.filterIds.filter(element => {
        if (element !== status.id) return element;
      });
    }
    const url = ConfigAssetLoaderService.getApiURL() + '/v2/webhook-logs';
    this.datatableElement.toArray()[0].dtInstance.then(
      (dtInstance: DataTables.Api) => {
        // abort previous api call
        dtInstance.settings()[0].jqXHR.abort();
        dtInstance.ajax.url(url).load();
      }
    );
    this.isInprogress = false;
  }

  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function) {

    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }

    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }

    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);

  }

  public showAlert(message: string, positiveAction?: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(positiveAction);
    }
    else {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) {this.popUpService.showDiv(dialog);}
  }

}
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { PopUpService } from '../../../../services/pop-up.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from '../../../../services/login.service';
import { CaseService } from '../../../../services/case.service';

@Component({
  selector: 'app-patient-dashboard',
  templateUrl: './patient-dashboard.component.html',
  styleUrls: ['./patient-dashboard.component.css']
})
export class PatientDashboardComponent implements OnInit, OnDestroy {
  public user: any
  constructor(
    private loginService: LoginService,
    private caseService: CaseService,
    private router: Router,
    private popUpService: PopUpService,
  ) { }

  ngOnInit() {
    this.user = this.loginService.getCurrentUser();
  }

  ngOnDestroy() {
  }

  public showAlert(message: string, positiveAction?: Function) {
    this.popUpService.destoryConfirmDialog();
    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );
    if (positiveAction) {
      this.popUpService.setPositiveAction(positiveAction);
    }
    else {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }
    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) {this.popUpService.showDiv(dialog);}
  }

  downloadSamplePdf() {
    let popUp = null;
    popUp = window.open('', '_blank');
    popUp.document.write('Please wait a moment while your file is being downloaded');

    this.caseService.downloadSamplePdf()
      .subscribe(
        file => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(file);
          a.download = 'Sample Report.pdf';
          document.body.appendChild(a);
          a.click();
          popUp.close();
        },
        (_onError) => {
          this.showAlert('Cannot open file');
          if (popUp) { popUp.close(); }
        }
      );
  }

  onCreateCase() {
    if (!this.user?.partners[0]?.partner?.active) {
      alert('Client is not active. Please contact administrator to create a case.');
      return;
    }
    this.router.navigate(['/case', 'create', this.user.user_id]);
  }

}

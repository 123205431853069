/* eslint-disable @typescript-eslint/explicit-member-accessibility */
export class CasePartnerModel {
    cases_by_month_cancelled: number;
    cases_by_month_requested: number;
    cases_by_month_completed: number;
    cases_by_year_cancelled: number;
    cases_by_year_requested: number;
    cases_by_year_completed: number;
    active_cases: number;
    cases_hold: number;
    year: string;
    month: string;

    constructor() {

      this.cases_by_month_cancelled = 0;
      this.cases_by_month_requested = 0;
      this.cases_by_month_completed = 0;

      this.cases_by_year_cancelled = 0;
      this.cases_by_year_requested = 0;
      this.cases_by_year_completed = 0;
      this.active_cases = 0;
      this.cases_hold = 0;
      this.year = '';
      this.month = '';

    }

}

<app-dialog></app-dialog>

<div class="header">
  <div class="header__page-name">
    <h1 class="heading heading--main">Mail Server Details</h1>
  </div>
</div>
<div class="wrapper--dash-content dash-content overlay__wrapper" [class.overlay--visible]="isInprogress">
  <div class="overlay__content">
    <div class="overlay__spinner">
      <span class="icon icon--loading"></span>
    </div>
  </div>

  <div class="row">
    <div class="col col__md-8 gutter--right-3x">
      <div class="box box--grey box--radius">
        <div class="resume resume--centered">
          <!-- <h2 class="resume__title">Details</h2> -->
          <div class="resume__row">
            <div class="resume-field">Sequence</div>
            <div class="resume-details">
              <span>{{ mailObj?.sequence }}</span>
            </div>
          </div>
          <div class="resume__row">
            <div class="resume-field">Name</div>
            <div class="resume-details">
              <span>{{ mailObj?.name }}</span>
            </div>
          </div>
          <div class="resume__row">
            <div class="resume-field">Active</div>
            <div class="resume-details">
              <span>{{ mailObj?.displayActive }}</span>
            </div>
          </div>
          <div class="resume__row">
            <div class="resume-field">Smtp Encryption</div>
            <div class="resume-details">
              <span>{{ mailObj?.displaySmtpEncryption }}</span>
            </div>
          </div>
          <div class="resume__row">
            <div class="resume-field">Description</div>
            <div class="resume-details">
              <span [innerHTML]="mailObj?.description"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col col__md-4">
      <div class="box box--white box--radius box--margin-bottom-2x">
        <div class="contact-details">
          <div *ngIf="!mailObj?.email_service" class="contact-details__row">
            <div class="contact-details__label">Smtp Host</div>
            <div class="contact-details__details">{{ mailObj?.smtp_host }}</div>
          </div>
          <div *ngIf="!mailObj?.email_service" class="contact-details__row">
            <div class="contact-details__label">Smtp Port</div>
            <div class="contact-details__details">{{ mailObj?.smtp_port }}</div>
          </div>
          <div *ngIf="mailObj?.email_service" class="contact-details__row">
            <div class="contact-details__label">Email Service</div>
            <div class="contact-details__details">{{ mailObj?.displayEmailService }}</div>
          </div>
          <div class="contact-details__row">
            <div class="contact-details__label">Smtp User</div>
            <div class="contact-details__details">{{ mailObj?.smtp_user }}</div>
          </div>
          <div class="contact-details__row">
            <div class="contact-details__label">Smtp Pass</div>
            <div class="contact-details__details">XXXXXXXX</div>
          </div>
          <div class="hr hr--space-down-x hr--space-up-x"></div>
          <div class="contact-details__btns">
            <a href="#" class="btn btn--green btn--small" (click)="verifyConnection($event)">Test Connection</a>
            <!-- <a href="#" class="btn btn--red btn--small" (click)="deleteMailServer($event)">Delete</a> -->
            <a [routerLink]="['/settings/outgoing-mail-server/edit/', mailObj?.id]" class="btn btn--blue btn--small">Edit</a>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>
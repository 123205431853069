import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';

import { FirstStringPipe } from './first-string.pipe';
import { EnumDisplayPipe } from './enum-display.pipe';

@NgModule({
  declarations: [FirstStringPipe, EnumDisplayPipe],
  imports: [CommonModule],
  exports: [FirstStringPipe, EnumDisplayPipe]
})

export class MainPipe{}

/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';

@Injectable()
export class PasswordResetService implements CanActivate {

  constructor(
    private loginService: LoginService,
    private router: Router
  ) { }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const currentUser = this.loginService.getCurrentUser();
    
    if (!currentUser.user_type) {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('user_partners');
      this.router.navigate(['/login']);
      return false;
    }

    const role: string = currentUser.user_type.type.toLowerCase();
    const url: string = state.url;

    if (
      this.guardWithPassword(url) &&
      role === 'patient' &&
      this.checkPasswordReset(currentUser)
    ) {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('user_partners');
      this.router.navigate(['/login']);
      return false;
    }

    return true;
  }

  private guardWithPassword(url: string) {
    switch (true) {
    case /^\/login/.test(url): {
      return false;
    }
    case /^\/forgotpassword/.test(url): {
      return false;
    }
    case /^\/reset\/password/.test(url): {
      return false;
    }
    case /^\/redirect/.test(url): {
      return false;
    }
    case /^\/page-not-found/.test(url): {
      return false;
    }
    default: {
      return true;
    }
    }
  }

  private checkPasswordReset(user: any): boolean {
    if (user.force_password_reset) {
      return true;
    }

    return false;
  }

}

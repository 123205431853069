/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */

export class EmailTemplateModel {
  id: number;
  name: string;
  subject: string;
  body: string;
  apply_to: string;

  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.subject = data.subject;
    this.body = data.body;
    this.apply_to = data.apply_to;
  }

}

/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UrlTree, UrlSegmentGroup, UrlSegment } from '@angular/router';
import { PRIMARY_OUTLET } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DatashareService } from '../../../services/datashare.service';
import { LoginService } from '../../../services/login.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit {

  activeTab: string;
  title: string;
  operation: string;
  usermap: Map<string, string>;
  role: any;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private datashare: DatashareService,
    private loginService: LoginService
  ) {
    this.usermap = new Map();
    this.usermap.set('admin', 'Admin');
    this.usermap.set('careteam', 'CareTeam');
    this.usermap.set('partner', 'Client CT');
    this.usermap.set('doctor', 'Doctor');
    this.usermap.set('patient', 'Member');
    this.usermap.set('translator', 'Summary Coordinator');
    this.usermap.set('interpreter', 'Interpreter');
    this.usermap.set('eligible-member', 'Eligible Members');
    this.usermap.set('mdmanager', 'MD Manager');
    this.usermap.set('systemaccount', 'System Account');
    this.usermap.set('cmmanager', 'CM Manager');
    this.usermap.set('casecoordinator','Case Coordinator');
  }

  ngOnInit(
  ) {
    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const segmentGroup: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const segment: UrlSegment[] = segmentGroup.segments;

    this.activeTab = segment[1].path;

    this.operation = segment[0].path;
    const operationLabel = this.operation === 'add' ? 'Add' : 'Edit';
    this.title = operationLabel +' '+this.usermap.get(this.activeTab);

    if (operationLabel.toLowerCase() === 'edit') {
      this.datashare.shareData$.subscribe(
        data => {
          this.title = this.title + ' - ' + data;
        }
      );
    }

    this.activatedRoute.queryParams.subscribe(
      query => {
        const fromSearchQuery = query['action'];
        if(fromSearchQuery) {
          this.operation = 'hidetab';
        }
      }
    );

    const user = this.loginService.getCurrentUser();
    this.role = user.user_type.type;
  }

  activateTab(tab: string): void {
    this.activeTab = tab;
    const operationLabel = this.operation === 'add' ? 'Add' : 'Edit';
    this.title = operationLabel +' '+this.usermap.get(this.activeTab);
  }

}

/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { HistoryModel } from './HistoryModel';
import { UserCaseMessageModel } from './UserCaseMessageModel';

export class MessageModel extends HistoryModel {

  id: number;
  comment: string;
  created_at: string;
  updated_at: string;
  sender: string;
  user_type: string;
  avatar: string;
  sender_user_id: number;
  receiver: string[];
  user_case_messages: UserCaseMessageModel[]
  userRole: string;
  title: string;
  forAlly: boolean;
  groupNames: any;
  is_chatter_note: any;
  chatter_identifier: string;
  parent_message_id: string;


  constructor(source: Object, role: string) {
    super();
    this.id = source.hasOwnProperty('id') ? source['id'] : 0;
    this.comment = source.hasOwnProperty('message') ? source['message'] : null;
    this.title = source.hasOwnProperty('title') ? source['title']: null;
    this.forAlly = source.hasOwnProperty('for_ally') ? source['for_ally']: false;
    this.created_at = source.hasOwnProperty('created_at') ? source['created_at'] : null;
    this.updated_at = source.hasOwnProperty('updated_at') ? source['updated_at'] : null;
    this.sender = source.hasOwnProperty('sender') ? source['sender'] : null;
    this.user_type = source.hasOwnProperty('user_type') ? source['user_type'] : null;
    this.avatar = source.hasOwnProperty('avatar') ? source['avatar'] : null;
    this.sender_user_id = source.hasOwnProperty('user_id') ? source['user_id'] : 0;
    this.user_case_messages = source.hasOwnProperty('user_case_messages') ? source['user_case_messages'] : null;
    this.isComment = true;
    this.userRole = role ? role.toLowerCase() : '';
    this.groupNames = source.hasOwnProperty('groupNames') ? source['groupNames']: '';
    this.is_chatter_note =  source.hasOwnProperty('is_chatter_note')? source.hasOwnProperty('is_chatter_note'): false;
    this.chatter_identifier = source.hasOwnProperty('chatter_identifier') ? source['chatter_identifier']: null;
    this.parent_message_id = source.hasOwnProperty('parent_message_id') ? source['parent_message_id']: null;


  }

  public getRevieverName(isElevatedPCT: boolean): string {

    let receivers: string[];
    if (this.user_case_messages.length > 0) {
      receivers = this.user_case_messages.map(u => {
        if (u.user == null || !u.user) {
          return 'Alight Care Team';
        }
        else {
          // eslint-disable-next-line max-len
          if (this.userRole === 'admin' || this.userRole === 'careteam' || this.userRole === 'careteam' || this.userRole === 'mdmanager' || this.userRole === 'cmmanager') {
            return u.user.full_name;
          }
          else if (this.userRole === 'partner' && !isElevatedPCT) {
            if (u.user.user_type_id == 2 || u.user.user_type_id == 1 || u.user.user_type_id == 11) {
              return 'Alight Care Team';
            }
            return u.user.full_name;
          }
          else if (this.userRole === 'partner' && isElevatedPCT) {
            if (u.user.user_type_id == 2) {
              return 'Alight Care Team';
            }
            if (u.user.user_type_id == 11) {
              return 'Case Coordinator';
            }

            if (u.user.user_type_id == 1) {
              return 'Admin';
            }

            return u.user.full_name;
          }
          else if (this.userRole === 'patient') {
            if (u.user.user_type_id == 2 || u.user.user_type_id == 1 || u.user.user_type_id == 11)   {
              return 'Alight Care Team';
            }
            else if (u.user.user_type_id == 3) {
              return 'Client Care Team';
            }
            else {
              return u.user.full_name;
            }
          }
          else if (this.userRole === 'doctor') {
            if (u.user.user_type_id == 2) {
              return 'Alight Care Team';
            }
            if (u.user.user_type_id == 11) {
              return 'Case Coordinator';
            }
            else {
              return u.user.full_name;
            }
          }
          else {
            return u.user.full_name;
          }
        }
      });
      if (this.groupNames != '') {
        const groups = this.groupNames.split(',');
        receivers =[...receivers, ...groups];
      }
    }
    else {
      receivers = this.groupNames? this.groupNames.split(','): ['CM Care Team'];
    }

    // convert the array to set and re convert to array to make elements unique
    const receiverSet: any = new Set(receivers);
    const receiversArray: string[] = Array.from(receiverSet);

    const nonModifyNames = ['CM Care Team', 'Client Care Team', 'Nurse Ally', 'EMO Ally', 'MR Ally', 'Case Coordinator'];

    if (receiversArray) {
      receivers = receiversArray.map(r => {
        if (!nonModifyNames.includes(r)) {
          return r.split(' ')[0];
        }
        else {
          return r;
        }
      });
    }

    return receivers.join(', ');
  }

  public getSenderName(): string {
    const senderType = this.user_type.toLowerCase();
    // eslint-disable-next-line max-len
    if (this.userRole === 'admin' || this.userRole === 'careteam'|| this.userRole === 'casecoordinaotr' || this.userRole === 'mdmanager' || this.userRole === 'cmmanager') {
      return this.sender.split(' ')[0];
    }
    else if (this.userRole === 'partner') {
      if (senderType === 'admin' || senderType === 'careteam' || senderType === 'casecoordinator') {
        return 'Alight Care Team';
      }
      return this.sender.split(' ')[0];
    }
    else if (this.userRole === 'patient') {
      if (senderType === 'admin' || senderType === 'careteam' || senderType === 'casecoordinator') {
        return 'Alight Care Team';
      }
      else if (senderType === 'partner') {
        return 'Client Care Team';
      }
      return this.sender.split(' ')[0];
    }
    return this.sender.split(' ')[0];
  }
}

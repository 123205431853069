<div class="box box--white box--no-pad box--radius margin--bottom-2x">
	<div class="box__title case-section-box">
		<div class="box-title-left">
			<div class="left-title">Video</div>
			<div class="left-status">
				<span class="icon icon--calendar"></span>Assigned {{video?.created_at | amCalendar}}
			</div>
		</div>
		<div class="box-title-right" style="align-self:flex-start;">
			<div class="flexDisplay">
				<div class="mr20">
					<div class="right-name">{{video?.user?.full_name}}</div>
					<div class="right-status margin--top-x">Assignee</div>
				</div>
				<div class="mr20" *ngIf="caseDetail?.schedule?.zoom_link && !isVideoComplete">
					<a href="$event.preventDefault();" class="btn btn--blue" [href]="caseDetail?.schedule?.zoom_link"
						[target]="'_blank'">Start the consultation</a>
				</div>
				<div>
					<a href="javascript:void(0)" class="go-back pull-right collapseToggle top10"
						[ngClass]="{collapseToggleActive: isVideoCollapse===true}"
						(click)="isVideoCollapse = !isVideoCollapse">
						<span class="icon icon--time-nav"></span>
					</a>
				</div>
			</div>
		</div>

	</div>
	<div [hidden]="!isVideoCollapse" class="padding-2x box--md-mgmt form">
		<div class="form__row">
			<div class="col col__md-2">
				<div class="form__label is-uppercase">Description</div>
			</div>
			<div class="col col__md-10">
				<div class="form__field">
					<div class="text">The case is READY for consultation. When the video conference is over, please
						proceed by clicking Finished.</div>

				</div>
			</div>
		</div>

		<form [formGroup]="videoForm">
			<div class="form__row">
				<div class="col col__md-2">
					<div class="form__label is-uppercase">Notes</div>
				</div>
				<div class="col col__md-10">
					<div class="form__field">
						<textarea formControlName="notes"
							[attr.disabled]="(disableInputs || isVideoComplete) ? '': null"></textarea>
						<div class="message message--is-invalid"
							*ngIf="videoForm.get('notes').touched && videoForm.get('notes').invalid">
							<div *ngIf="videoForm.get('notes').errors.required">This field is required.</div>
							<div *ngIf="videoForm.get('notes').errors.maxlength">This field must not exceed more than
								{{videoForm.get('notes').errors.maxlength.requiredLength}}</div>
						</div>
					</div>
				</div>
			</div>
		</form>
		<div class="btns__row text-right" *ngIf="!disableInputs">
			<app-generic-button [disabled]="isVideoComplete"
				*ngIf="!isLoading" (onClick)="saveVideoNotes(caseDetail.id)">Finish</app-generic-button>
			<div class="btn btn--blue is-loading" *ngIf="isLoading">
				<span class="icon icon--loading "></span>
			</div>
		</div>
		<div class="btns__row text-right" *ngIf="isVideoComplete">
			<br />
			<div class="form__label">Completed: {{ video?.updated_at | date: 'medium' }} </div>
		</div>
	</div>
</div>
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  constructor(
		private _location: Location
  ) { }

  ngOnInit() {
  }

  public goBack(event: Event) {
    event.preventDefault();

    this._location.back();
  }

  message() {
    const query = new URL(window.location.href).searchParams;

    return query.get('message') || 'The page you were trying to access doesn\'t exist or is incorrect or has been removed.';
  }

  title() {
    const query = new URL(window.location.href).searchParams;

    return query.get('title') || 'Page not found';
  
  }

}

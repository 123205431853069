/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Injectable } from '@angular/core';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';
import { LoginService } from './login.service';

const IDLE_TIME = 5 * 60;
const TIME_OUT = 10 * 60;
@Injectable()
export class TimeoutService {
  public idleState = 'Not started.';
  public timedOut = false;
  public lastPing?: Date = null;
  public tokenRefresher: any

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  constructor(private idle: Idle, private keepalive: Keepalive, private router: Router, private loginService: LoginService) {

    idle.setIdle(IDLE_TIME);
    idle.setTimeout(TIME_OUT);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      console.log(this.idleState);
    });
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
    });
    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
      console.log(this.idleState);
    });
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      console.log(this.idleState);
    });

    keepalive.interval(60);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());
  }

  public reset(): void {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
    this.tokenRefresher = this.getTimer(1200000);
  }

  private getTimer(interval) {
    return setInterval(() => {
      this.loginService.refreshToken();
    }, interval);
  }

  stopSession(): void {
    clearInterval(this.tokenRefresher);
    this.idle.stop();
  }
}

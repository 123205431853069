/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigAssetLoaderService } from '../../../services/config-asset-loader.service';
import { LoginService } from '../../../services/login.service';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { CommonFuntions } from '../../../common/common.function';
import * as moment from 'moment/moment';
import { get } from 'lodash';


declare let $: any;

@Component({
  selector: 'app-patient-role',
  templateUrl: './patient-role.component.html',
  styleUrls: ['./patient-role.component.css']
})
export class PatientRoleComponent implements OnInit {


  @ViewChild(DataTableDirective, {static: true})
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  token = 'Bearer ' + localStorage.getItem('token');
  valueToSearch: string;
  errorString: string;

  title: string;
  searchValue = '';
  userType: string;

  userCount: number
  activeUsers: number
  inactiveUsers: number

  constructor(
    public router: Router,
    private loginService: LoginService,
  ) {
    this.userCount = 0;
  }
  /**
 * Function to return the list of column to be displayed in the Member List
 * @return {*}  {any[]}
 * @memberof PatientRoleComponent
 */
  getUserColumnList(): any[] {
    const columns = [
      {
        title: 'Name',
        render: function (_data, _type, row, _meta): string {
          const name = row.allyUserRef ? `${row.full_name} <span class='badge-small badge-yellow'>Ally</span>` : row.full_name;
          return name;
        }
      }, 
      {
        title: 'Client',
        render: function (_data, _type, row, _meta): string {
          let partner = get(row, 'user_partners.0.partner.partner_name', '');
          partner = $.fn.dataTable.render.text().display(partner);

          const cell = '<div class="text">' + (partner.length > 15 ? partner.substring(0, 15) + '...' : partner) + '</div>';
          return cell;
        }
      },
      {
        title: 'DOB',
        // data: 'patient_detail.dob.toDateString()',
        render: function (_data, _type, row, _meta): string {
          let patientDOB;
          if(row.dob){
            patientDOB =  moment(row.dob).format('MM/DD/YYYY');
          }
          else{
            patientDOB = 'N/A';
          }
          return patientDOB;
        },
        orderable: false
      },
      {
        title: 'Email Address',
        data: 'email',
        render: $.fn.dataTable.render.text()
      },
      {
        title: 'Status',
        render: function (_data, _type, row, _meta) {
          if (row.is_deleted) {
            return 'Inactive';
          }
          else {
            return 'Active';
          }
        },
        orderable: false
      }
    ];

    return columns;
  }

  ngOnInit(): void {
    this.title = 'Partner Role';
    const currentUser = this.loginService.getCurrentUser();
    this.userType = currentUser.user_type.type.toLowerCase();
    const url = ConfigAssetLoaderService.getApiURL() + '/user/role/5?type=' + currentUser.user_type.type + '&userid=' + currentUser.user_id;

    this.dtOptions = {
      ajax: {
        url: url,
        dataSrc: function(json): void {
          this.userCount = json.recordsTotal;
          this.activeUsers = json.activeCount || 0;
          this.inactiveUsers = json.inActiveCount || 0;
          return json.data;
        }.bind(this),
        type: 'POST',
        beforeSend: function(xhr): void {
          if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
            xhr.abort();
            return;
          }

          this.errorString = null;
          const token = localStorage.getItem('token');
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }.bind(this),
        error: this.datatablesError.bind(this)
      },
      pagingType: 'full_numbers',
      serverSide: true,
      processing: true,
      lengthChange: false,
      searching: true,
      pageLength: 25,
      order: [[0, 'ASC']],
      info: false,
      stateSave: true,
      stateDuration: 5 * 60,
      columns: this.getUserColumnList(),
      rowCallback: (row: Node, data: any[] | Object, _index: number) => {
        const self = this;

        // Unbind first in order to avoid duplicate handler
        $('td', row).unbind('click');
        $('td', row).bind('click', () => {
          self.viewUserInfo(data);
        });
      }
    };

    this.setSearchValueFromSavedState();
  }

  datatablesError(xhr, _error, _thrown): void {
    const errorStatus = xhr.status;
    if (errorStatus == 401) {
      this.router.navigate(['/login']);
    }
    else if (errorStatus == 404) {
      this.errorString = 'Invalid Url';
    }
    else {
      this.errorString = 'Problem occurred. Please contact your administrator';
    }
  }

  search(): void {
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => {
        dtInstance.search(this.valueToSearch).draw();
      }
    );
  }

  searchString(searchValue: string): void {
    this.valueToSearch = searchValue;
    this.search();
  }

  viewUserInfo(info: any): void {
    this.router.navigate(['/profile', info.user_id]);
  }

  setSearchValueFromSavedState(): void {
    const state = localStorage.getItem('DataTables_patient_/patient-role');

    if (state == null) {
      this.searchValue = '';
      return;
    }

    const searchValue = JSON.parse(state).search['search'];
    if (searchValue != null) {
      this.searchValue = searchValue;
    }
  }

}

/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, Input } from '@angular/core';
import { faBriefcaseMedical, faCapsules } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-assigned-cases',
  templateUrl: './assigned-cases.component.html',
  styleUrls: ['./assigned-cases.component.css']
})
export class AssignedCasesComponent {
  @Input() caseList;
  @Input() userId: number;
   faBriefcaseMedical = faBriefcaseMedical;
   faCapsules = faCapsules;
}

/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export class Status {
  id: number;
  status: string;
  created_at?: any;
  updated_at?: any;

  static mapStatus(status: string) {
    switch (status.toLowerCase()) {
    case 'mdmgmt': {
      return 'MDMgnt';
    }
    case 'translatedmr': {
      return 'CompileMR';
    }
    case 'view': {
      return 'Video';
    }
    default: {
      return status;
    }
    }
  }

  static mapStatusForPartner(status: string) {
    status = status.toLowerCase();

    const externalStatus = {
      'hold': 'Hold',
      'cancelled': 'Cancelled',
      'finished': 'Finished',
      'requested': 'Requested',
      'mdmgnt': 'MDMgnt',
      'compilemr': 'Processing',
      'written': 'Processing',
      'translatewr': 'Processing',
      'schedule': 'Processing',
      'video': 'Video',
      'summary': 'Reviewing',
      'translatedr': 'Reviewing',
      'report': 'Report',
      'n/a': 'N/A'
    };

    return externalStatus[status];
  }

  static mapStatusForDoctor(caseType: string, status: string, isSave: boolean, isSubmitted: boolean) {
    status = status.toLowerCase();

    let externalStatus = null;

    if (caseType === 'WRITTEN CONSULTATION' || caseType === 'ASYNCHRONOUS VIDEO' || caseType === 'MDT WRITTEN' || caseType === 'CONNECT' || caseType === 'CONNECT ASYNCHRONOUS') {
      externalStatus = {
        'hold': 'Hold',
        'cancelled': 'Cancelled',
        'finished': 'Completed',
        'requested': 'Requested',
        'mdmgnt': 'Requested',
        'compilemr': 'Requested',
        'written': isSubmitted ? 'Finished' : isSave ? 'Report Pending' : 'Ready For Review',
        'translatewr': isSubmitted ? 'Finished' : isSave ? 'Report Pending' : 'Ready For Review',
        'schedule': isSubmitted ? 'Finished' : isSave ? 'Report Pending' : 'Ready For Review',
        'video': isSubmitted ? 'Finished' : isSave ? 'Report Pending' : 'Ready For Review',
        'summary': isSubmitted ? 'Finished' : isSave ? 'Report Pending' : 'Ready For Review',
        'translatedr': 'Finished',
        'report': 'Finished',
        'n/a': 'N/A'
      };
    }
    else {
      externalStatus = {
        'hold': 'Hold',
        'cancelled': 'Cancelled',
        'finished': 'Completed',
        'requested': 'Requested',
        'mdmgnt': 'Requested',
        'compilemr': 'Requested',
        'written': 'Ready For Review',
        'translatewr': 'Ready For Review',
        'schedule': 'Ready For Review',
        'video': 'Scheduled',
        'summary': isSubmitted ? 'Finished' : isSave ? 'Report Pending' : 'Ready For Review',
        'translatedr': 'Finished',
        'report': 'Finished',
        'n/a': 'N/A'
      };
    }

    return externalStatus[status];
  }

  static mapStatusForTranslator(status: string) {
    status = status.toLowerCase();

    const externalStatus = {
      'hold': 'Hold',
      'cancelled': 'Cancelled',
      'finished': 'Finished',
      'requested': 'Requested',
      'mdmgnt': 'MDMgnt',
      'compilemr': 'Processing',
      'written': 'Processing',
      'translatewr': 'Processing',
      'schedule': 'Processing',
      'video': 'Ready',
      'summary': 'Reviewing',
      'translatedr': 'Reviewing',
      'report': 'Report',
      'n/a': 'N/A'
    };

    return externalStatus[status];
  }

  static mapStatusForMembers(caseType: string, status: string) {
    status = status.toLowerCase();

    let externalStatus = null;

    if (caseType === 'WRITTEN CONSULTATION' || caseType === 'ASYNCHRONOUS VIDEO' || caseType === 'MDT WRITTEN' || caseType === 'CONNECT' || caseType === 'CONNECT ASYNCHRONOUS') {
      externalStatus = {
        'hold': 'Hold',
        'cancelled': 'Cancelled',
        'finished': 'Finished',
        'requested': 'Collecting Medical Records',
        'mdmgnt': 'Matching MD',
        'compilemr': 'Analyzing Medical Records',
        'written': 'Sent to MD for review',
        'translatewr': 'Sent to MD for review',
        'schedule': 'Sent to MD for review',
        'video': 'Sent to MD for review',
        'summary': 'Sent to MD for review',
        'translatedr': 'Report',
        'report': 'Report',
        'n/a': 'N/A'
      };
    }
    else {
      externalStatus = {
        'hold': 'Hold',
        'cancelled': 'Cancelled',
        'finished': 'Finished',
        'requested': 'Collecting Medical Records',
        'mdmgnt': 'Matching MD',
        'compilemr': 'Analyzing Medical Records',
        'written': 'Scheduling',
        'translatewr': 'Scheduling',
        'schedule': 'Scheduling',
        'video': 'Video Consultation',
        'summary': 'Report',
        'translatedr': 'Report',
        'report': 'Report',
        'n/a': 'N/A'
      };
    }

    return externalStatus[status];
  }
}

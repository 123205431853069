/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable max-len */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  AfterContentInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { filter, orderBy } from 'lodash';

import { LoginService } from '../../../services/login.service';
import { CaseService } from '../../../services/case.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { UserService } from '../../../services/userservice.service';
import { PopUpService } from '../../../services/pop-up.service';
import { CommonService } from '../../../services/common.service';
import { ICaseRequestedView } from './View/ICaseRequestedView';
import { ICaseRequestedPresetner } from './Presenter/ICaseRequestedPresetner';
import { CaseRequestedPresenter } from './Presenter/CaseRequestedPresenter';
import { CASE_STATUS_TYPE, CASE_STAGE_ID } from '../../../constant';

@Component({
  selector: 'app-case-requested',
  templateUrl: './case-requested.component.html',
  styleUrls: ['./case-requested.component.css']
})
export class CaseRequestedComponent implements
  OnInit, AfterContentInit, OnChanges, ICaseRequestedView {


  public presenter: ICaseRequestedPresetner

  isInProgress: boolean;
  case_questions: any[];

  questionForm: FormGroup;
  requestedForm: FormGroup;

  requestedStatusValue: any;

  @Input() caseDetail;
  @Input() final_reports;
  @Input() translated_document;
  @Input() document_for_download;
  @Input() isMdManager;
  @Input() cmManagerList;
  @Input() followUpNumber;

  @Output() caseEmitter = new EventEmitter<any>();
  @Output() showStatusEmitter = new EventEmitter<string>();
  @Output() editDocumentEmitter = new EventEmitter<any>();
  @Output() assigneeEmitter = new EventEmitter();
  @ViewChild('txtVideo') video: ElementRef;
  @ViewChild('txtSchedule') txtSchedule: ElementRef;
  @ViewChild('txtTranslateDR') txtTranslateDR: ElementRef;

  avatarMale: string;
  case_id;
  translatorList = [];
  written_list = [];
  translate_dr_list = [];
  schedule_list = [];
  summary_list = [];
  video_list = [];
  statuses = [];
  report_list = [];
  objTranslators = {};
  objTranslator_WR = {};
  objSchedule = {};
  objVideo = {};
  objSummary = {};
  objTranslateDR = {};
  objReport = {};
  objMDMgmt = {};
  mdMgmtList = [];
  avatarimage: string = null;
  caseTypeList = [];
  isSchedule: boolean;
  isVideo: boolean;
  isShowOptionDR: boolean;


  validateTranslator = false;
  validateWrittenTranslator = false;
  validateTranslateWR = false;
  validateTranslateDR = false;
  role;
  disableInputs
  userObj = {};
  isQuestionEdit: boolean
  isFollowQuestionEdit: boolean;
  showConsultationLink: boolean
  showTranslateDr: boolean
  txtVideoDisable: boolean
  txtScheduleDisable: boolean
  canQuestionEdit: boolean;
  isTranslateDr: boolean;
  isAllyCase = false;
  user;

  // collapse
  isQuestionCollapse = true;
  isFollowUpQuestionCollapse = true;
  isRequestedCollapse = true;

  CM_MANAGER_LIST = [];

  constructor(private loginService: LoginService,
    private caseService: CaseService,
    private userService: UserService,
    private router: Router,
    private commonService: CommonService,
    private popUpService: PopUpService
  ) {
    this.avatarMale = 'assets/images/avatar-male.jpg';

    this.presenter = new CaseRequestedPresenter(
      this.router,
      this,
      this.loginService,
      this.caseService,
      this.commonService,
      this.userService,
      this.popUpService
    );

    this.case_questions = [];
  }

  private checkCanEditQuestion(role: string): boolean {
    let canEdit = false;

    if ((role === 'careteam' || role === 'cmmanager' || (role === 'partner' && this.caseDetail?.patient?.user?.user_partners[0]?.partner.isElevatedPartner)) && this.caseDetail.case_status !== 'CANCELLED' && this.caseDetail.case_status !== 'FINISHED' && !this.isQuestionEdit) {
      canEdit = true;
    }

    return canEdit;
  }

  setInprogress(isShow: boolean): void {
    this.isInProgress = isShow ? true : false;
  }

  setViewRequestedForm(form: FormGroup) {
    this.requestedForm = form;

    if (this.role !== 'admin') {
      this.requestedForm.disable();
    }
  }

  setViewQuestionForm(form: FormGroup) {
    this.questionForm = form;
  }

  setCaseType(list: any[]): void {
    this.caseTypeList = [];

    this.caseTypeList = this.caseTypeList.concat(list);
  }

  addFormControlToQuestionFormArray(control: FormGroup) {
    const duplicate = this.case_questions.find(que => que.id === control.value.id);
    if (!duplicate) {
      this.case_questions.push(control.value);
    }
    this.questions.push(control);
  }

  setControlCaseType(value: string) {
    if (this.caseType) { this.caseType.setValue(value); }
  }

  addRemoveStageTranslateDr(isAdd: boolean) {
    if (isAdd) {
      if (this.requestedForm) {
        this.requestedForm.addControl(
          'translate_DR', new FormControl(this.userObj['assignee_name'], Validators.required)
        );
      }
      this.objTranslateDR = this.userObj;
      this.showTranslateDr = true;
      this.presenter.changeTranslateDROption(true);
    }
    else {
      if (!!this.requestedForm && !!this.requestedForm.get('translate_DR')) { this.requestedForm.removeControl('translate_DR'); }
      this.objTranslateDR = {};
      this.showTranslateDr = false;
      this.presenter.changeTranslateDROption(false);
    }

  }

  enableDisableVideo(isEnable: boolean, resetAssignee?: boolean) {
    setTimeout(() => {
      if (this.video && this.video != null) {
        if (isEnable) {
          this.txtVideoDisable = false;
          const formValue = this.requestedForm.get('video').value;
          if (formValue == null || formValue === '') {
            this.requestedForm.get('video').setValue(this.userObj['assignee_name']);
            this.objVideo = this.userObj;
          }
        }
        else {
          this.txtVideoDisable = true;
          if (resetAssignee) {
            this.requestedForm.get('video').setValue('');
            this.objVideo = {};
          }
        }
      }
    }, 300);
  }

  enableDisableSchedule(isEnable: boolean, resetAssignee?: boolean) {
    setTimeout(() => {
      if (this.txtSchedule && this.txtSchedule != null) {
        if (isEnable) {
          this.txtScheduleDisable = false;
          const formValue = this.requestedForm.get('schedule').value;
          if (formValue == null || formValue === '') {
            this.requestedForm.get('schedule').setValue(this.userObj['assignee_name']);
            this.objSchedule = this.userObj;
          }
        }
        else {
          this.txtScheduleDisable = true;
          if (resetAssignee) {
            this.requestedForm.get('schedule').setValue('');
            this.objSchedule = {};
          }
        }
      }
    }, 300);
  }

  showVideoSchedule(isShow: boolean) {
    this.isSchedule = isShow;
    this.isVideo = isShow;

    if (!isShow) {
      if (!!this.requestedForm && this.requestedForm.get('schedule')) { this.requestedForm.get('schedule').clearValidators(); }
      if (!!this.requestedForm && this.requestedForm.get('schedule')) { this.requestedForm.get('schedule').updateValueAndValidity(); }
      if (!!this.requestedForm && this.requestedForm.get('video')) { this.requestedForm.get('video').clearValidators(); }
      if (!!this.requestedForm && this.requestedForm.get('video')) { this.requestedForm.get('video').updateValueAndValidity(); }
    }
    else {
      if (!!this.requestedForm && this.requestedForm.get('schedule')) {
        this.requestedForm.get('schedule').setValidators([Validators.required]);
      }
      if (!!this.requestedForm && this.requestedForm.get('schedule')) { this.requestedForm.get('schedule').updateValueAndValidity(); }
      if (!!this.requestedForm && this.requestedForm.get('video')) { this.requestedForm.get('video').setValidators([Validators.required]); }
      if (!!this.requestedForm && this.requestedForm.get('video')) { this.requestedForm.get('video').updateValueAndValidity(); }
    }
  }

  setUserList(target: string, list: any[]) {
    switch (target) {
    case 'translator': {
      this.translatorList = list.concat(this.CM_MANAGER_LIST);
      break;
    }
    case 'translateDr': {
      this.translate_dr_list = list.concat(this.CM_MANAGER_LIST);
      break;
    }
    case 'schedule': {
      this.schedule_list = list.concat(this.CM_MANAGER_LIST);
      break;
    }
    case 'video': {
      this.video_list = list.concat(this.CM_MANAGER_LIST);
      break;
    }
    case 'report': {
      this.report_list = list.concat(this.CM_MANAGER_LIST);
      break;
    }
    case 'mdMgmt': {
      this.mdMgmtList = list.concat(this.CM_MANAGER_LIST);
      break;
    }
    case 'summary': {
      this.summary_list = list.concat(this.CM_MANAGER_LIST);
      break;
    }
    case 'written': {
      this.written_list = list.concat(this.CM_MANAGER_LIST);
      break;
    }
    default: {
      break;
    }
    }
  }

  setValueRequestedForm(isInit: boolean, status?: any[]) {

    if (isInit) {
      if (
        this.caseDetail.case_type === 'VIDEO CONSULTATION' ||
        this.caseDetail.case_type === 'MDT VIDEO'
      ) {
        if (this.requestedForm) { this.requestedForm.get('schedule').setValue(this.userObj['assignee_name']); }
        if (this.requestedForm) { this.requestedForm.get('video').setValue(this.userObj['assignee_name']); }
        this.objSchedule = this.userObj;
        this.objVideo = this.userObj;
      }

      if (this.requestedForm) { this.requestedForm.get('mdMgmt').setValue(this.userObj['assignee_name']); }
      if (this.requestedForm) { this.requestedForm.get('translators').setValue(this.userObj['assignee_name']); }
      if (this.requestedForm) { this.requestedForm.get('summary').setValue(this.userObj['assignee_name']); }


      const case_type_id = this.caseDetail.patient.user.user_partner && this.caseDetail.patient.user.user_partner[0].partner.case_type_id;
      let stageTranslateDr = null;

      if (status) {
        stageTranslateDr = status.find(eachStatus => eachStatus.status_id === 9);
      }

      if ((case_type_id === 1 || case_type_id === 4) && stageTranslateDr) {
        this.requestedForm.get('translate_DR').setValue(this.userObj['assignee_name']);
        this.objTranslateDR = this.userObj;
      }


      if (this.requestedForm) { this.requestedForm.get('report').setValue(this.userObj['assignee_name']); }

      this.objMDMgmt = this.userObj;
      this.objTranslators = this.userObj;
      this.objSummary = this.userObj;
      this.objReport = this.userObj;

      return;
    }

    if (this.caseDetail && this.caseDetail?.status) {
      for (let i = 0; i < this.caseDetail?.status.length; i++) {
        let control;
        let assignedUserObj;
        const STATUS_ID = this.caseDetail?.status[i]?.status_id;
        if (this.caseDetail?.status[i]?.user) assignedUserObj = this.caseDetail?.status[i]?.user;

        /** MD Management Stage */
        if (STATUS_ID === CASE_STAGE_ID.MD_MGMT) {
          if (this.requestedForm) control = this.requestedForm.get('mdMgmt');
          if (assignedUserObj) {
            this.objMDMgmt = {
              assigned_user_id: assignedUserObj?.user_id,
              assignee_name: assignedUserObj?.full_name,
              email: assignedUserObj?.email
            };
            if (control) control.setValue(assignedUserObj?.full_name);
          }
          else {
            if (control) { control.setValue(this.userObj['assignee_name']); }
          }
        }

        /** CompileMR Stage */
        if (STATUS_ID === CASE_STAGE_ID.COMPILE_MR) {
          if (this.requestedForm) control = this.requestedForm.get('translators');
          if (assignedUserObj) {
            this.objTranslators = {
              assigned_user_id: assignedUserObj?.user_id,
              assignee_name: assignedUserObj?.full_name,
              email: assignedUserObj?.email
            };
            if (control) { control.setValue(assignedUserObj?.full_name); }
          }
          else {
            if (control) control.setValue(this.userObj['assignee_name']);
          }
        }

        /** Schedule Stage */
        if (STATUS_ID === CASE_STAGE_ID.SCHEDULE) {
          if (this.requestedForm) control = this.requestedForm.get('schedule');
          if (assignedUserObj) {
            this.objSchedule = {
              assigned_user_id: assignedUserObj?.user_id,
              assignee_name: assignedUserObj?.full_name,
              email: assignedUserObj?.email
            };
            if (control) { control.setValue(assignedUserObj?.full_name); }
          }
          else {
            if (control) { control.setValue(this.userObj['assignee_name']); }
          }
        }

        /** Video Stage */
        if (STATUS_ID === CASE_STAGE_ID.VIDEO) {
          if (this.requestedForm) control = this.requestedForm.get('video');
          if (assignedUserObj) {
            this.objVideo = {
              assigned_user_id: assignedUserObj?.user_id,
              assignee_name: assignedUserObj?.full_name,
              email: assignedUserObj?.email
            };
            if (control) { control.setValue(assignedUserObj?.full_name); }
          }
          else {
            if (control) { control.setValue(this.userObj['assignee_name']); }
          }
        }

        /** Summary Stage */
        if (STATUS_ID === CASE_STAGE_ID.SUMMARY) {
          if (this.requestedForm) control = this.requestedForm.get('summary');
          if (assignedUserObj) {
            this.objSummary = {
              assigned_user_id: assignedUserObj?.user_id,
              assignee_name: assignedUserObj?.full_name,
              email: assignedUserObj?.email
            };
            if (control) { control.setValue(assignedUserObj?.full_name); }
          }
          else {
            if (control) { control.setValue(this.userObj['assignee_name']); }
          }
        }

        /** TranslateDR Stage */
        if (STATUS_ID === CASE_STAGE_ID.TRANSLATE_DR) {
          if (this.requestedForm) control = this.requestedForm.get('translate_DR');
          if (assignedUserObj) {
            this.objTranslateDR = {
              assigned_user_id: assignedUserObj?.user_id,
              assignee_name: assignedUserObj?.full_name,
              email: assignedUserObj?.email
            };
            if (control) { control.setValue(assignedUserObj?.full_name); }
          }
          else {
            if (control) { control.setValue(this.userObj['assignee_name']); }
          }
        }

        /** Report Stage */
        if (STATUS_ID === CASE_STAGE_ID.REPORT) {
          if (this.requestedForm) control = this.requestedForm.get('report');
          if (assignedUserObj) {
            this.objReport = {
              assigned_user_id: assignedUserObj?.user_id,
              assignee_name: assignedUserObj?.full_name,
              email: assignedUserObj?.email
            };
            if (control) { control.setValue(assignedUserObj?.full_name); }
          }
          else {
            if (control) { control.setValue(this.userObj['assignee_name']); }
          }
        }

        // let control = null;
        // if (this.caseDetail?.status[i]?.status_id === CASE_STAGE_ID.MD_MGMT) { // MDMGMT
        //   if (this.requestedForm) { control = this.requestedForm.get('mdMgmt'); }
        //   if (this.caseDetail?.status[i]?.user) {
        //     this.objMDMgmt = {
        //       assigned_user_id: this.caseDetail?.status[i]?.user?.user_id,
        //       assignee_name: this.caseDetail?.status[i]?.user?.full_name,
        //       email: this.caseDetail?.status[i]?.user?.email
        //     };
        //     if (control) { control.setValue(this.caseDetail?.status[i]?.user?.full_name); }
        //   }
        //   else {
        //     if (control) { control.setValue(EMPTY_USER_OPTION); }
        //   }
        // }
        // if(this.caseDetail.status[i].status_id === 2 && this.caseDetail.status[i].user === null){
        //   this.requestedForm.get('mdMgmt').setValue(this.userObj['assignee_name']);
        // }
        // if (this.caseDetail.status[i].status_id === 3 && this.caseDetail.status[i].user !== null) { // translateMR
        //   this.objTranslators = {
        //     assigned_user_id: this.caseDetail.status[i].user.user_id,
        //     assignee_name: this.caseDetail.status[i].user.full_name,
        //     email: this.caseDetail.status[i].user.email
        //   };
        //   if (this.requestedForm) { control = this.requestedForm.get('translators'); }
        //   if (control) { control.setValue(this.caseDetail.status[i].user.full_name); }
        // }
        // if(this.caseDetail.status[i].status_id === 3 && this.caseDetail.status[i].user === null){
        //   this.requestedForm.get('translators').setValue(this.userObj['assignee_name']);
        // }
        // else if (this.caseDetail.status[i].status_id === 6) {  // schedule
        //   if (this.requestedForm) { control = this.requestedForm.get('schedule'); }
        //   if (control) { control.setValue(this.caseDetail.status[i].user.full_name); }
        //   this.objSchedule = {
        //     assigned_user_id: this.caseDetail.status[i].user.user_id,
        //     assignee_name: this.caseDetail.status[i].user.full_name,
        //     email: this.caseDetail.status[i].user.email
        //   };
        // }
        // else if (this.caseDetail.status[i].status_id === 7) { // video
        //   if (this.requestedForm) { control = this.requestedForm.get('video'); }
        //   if (control) { control.setValue(this.caseDetail.status[i].user.full_name); }
        //   this.objVideo = {
        //     assigned_user_id: this.caseDetail.status[i].user.user_id,
        //     assignee_name: this.caseDetail.status[i].user.full_name,
        //     email: this.caseDetail.status[i].user.email
        //   };
        // }
        // else if (this.caseDetail.status[i].status_id === 8) { // summary
        //   if (this.requestedForm) { control = this.requestedForm.get('summary'); }
        //   if (control) { control.setValue(this.caseDetail.status[i].user.full_name); }
        //   this.objSummary = {
        //     assigned_user_id: this.caseDetail.status[i].user.user_id,
        //     assignee_name: this.caseDetail.status[i].user.full_name,
        //     email: this.caseDetail.status[i].user.email
        //   };
        // }
        // else if (this.caseDetail.status[i].status_id === 9) { // translateDR
        //   if (this.requestedForm) { control = this.requestedForm.get('translate_DR'); }
        //   if (control) { control.setValue(this.caseDetail.status[i].user.full_name); }
        //   this.objTranslateDR = {
        //     assigned_user_id: this.caseDetail.status[i].user.user_id,
        //     assignee_name: this.caseDetail.status[i].user.full_name,
        //     email: this.caseDetail.status[i].user.email
        //   };
        // }
        // else if (this.caseDetail.status[i].status_id === 10) { // report
        //   if (this.requestedForm) { control = this.requestedForm.get('report'); }
        //   if (control) { control.setValue(this.caseDetail.status[i].user.full_name); }
        //   this.objReport = {
        //     assigned_user_id: this.caseDetail.status[i].user.user_id,
        //     assignee_name: this.caseDetail.status[i].user.full_name,
        //     email: this.caseDetail.status[i].user.email
        //   };
        // }
      }
    }
  }

  enableDisableCaseType(isEnable: boolean) {
    if (!isEnable) {
      if (this.caseType) { this.caseType.disable(); }
    }
    else {
      if (this.caseType) { this.caseType.enable(); }
    }
  }

  showHideConsultationLink(isShow: boolean) {
    this.showConsultationLink = isShow;
  }

  enableDisableInputs(isEnable: boolean) {
    this.disableInputs = isEnable;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  setEmptyStage(stage: FormControl, store: any, isSet: boolean) {
    if (isSet) {
      stage.setValidators([Validators.required]);
      stage.updateValueAndValidity();
      stage.setValue(this.userObj['assignee_name']);
      store = this.userObj;
    }
    else {
      stage.clearValidators();
      stage.updateValueAndValidity();
      stage.setValue('');
      store = {};
    }
  }

  getCaseDetailNotify(caseDetail: any) {
    caseDetail['final_reports'] = this.final_reports;
    caseDetail['document_for_download'] = this.document_for_download;
    caseDetail['translated_document'] = this.translated_document;
    this.caseEmitter.emit(caseDetail);
  }

  getCaseStatusNotify(status: string) {
    this.showStatusEmitter.emit(status);
  }

  setQuestionEdit(isEdit: boolean) {
    if (this.followUpNumber > 0) {
      this.isFollowQuestionEdit = isEdit;
    }
    else {
      this.isQuestionEdit = isEdit;
    }
  }

  showOptionDr(isShow: boolean) {
    this.isShowOptionDR = isShow;
  }

  getAssigneeChangeNotify() {
    this.assigneeEmitter.emit();
  }

  ngOnInit() {
    this.txtVideoDisable = true;
    this.txtScheduleDisable = true;
    this.user = this.presenter.getCurrentUser();
    this.role = this.presenter.getCurrentUserRole(this.user);
    this.userObj = this.presenter.createCurrentUserObject();
    this.canQuestionEdit = this.checkCanEditQuestion(this.role);
    this.setViewRequestedForm(this.presenter.getRequestedForm());
    this.setViewQuestionForm(this.presenter.getQuestionFormArray());
    this.presenter.callCaseType();
    this.presenter.callCaseQuestions(this.caseDetail.id);
    this.isTranslateDr = this.caseDetail.translator_needed;
    this.isAllyCase = this.checkAllyCase();

    if (this.isMdManager) {
      this.requestedForm.disable();
    }
  }
  
  ngAfterContentInit(): void {
    this.presenter.initData(this.caseDetail, this.isTranslateDr);
  }

  configureCMManagerList(): void {
    this.CM_MANAGER_LIST = this.cmManagerList.map((each) => {
      return {
        assigned_role: 'cmmanager',
        assigned_user_id: each.user_id,
        assignee_first_name: each.first_name,
        assignee_name: each.full_name,
        email: each.email
      };
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { cmManagerList } = changes;
    if (cmManagerList && cmManagerList.currentValue.length > 0) {
      this.configureCMManagerList();
    }
  }

  get mdMgmt() {
    return this.requestedForm.get('mdMgmt') as FormControl;
  }

  get caseType(): FormControl {
    if (this.requestedForm === undefined || !this.requestedForm || this.requestedForm == null) { return null; }
    return this.requestedForm.get('caseType') as FormControl;
  }

  get translators() {
    return this.requestedForm.get('translators') as FormControl;
  }

  get schedules() {
    return this.requestedForm.get('schedule') as FormControl;
  }

  get videos() {
    return this.requestedForm.get('video') as FormControl;
  }

  get translate_DR() {
    return this.requestedForm.get('translate_DR') as FormControl;
  }

  get report() {
    return this.requestedForm.get('report') as FormControl;
  }

  get questions() {
    return (this.questionForm.get('questions') as FormArray);
  }

  changeWorkflow(caseType: HTMLInputElement) {

    if (this.presenter.considerVideoSchedule(caseType.value)) {
      this.enableDisableVideo(true);
      this.enableDisableSchedule(true);
      this.showVideoSchedule(true);
    }
    else {
      this.enableDisableVideo(false, true);
      this.enableDisableSchedule(false, true);
      this.showVideoSchedule(false);
    }

    this.presenter.callCaseTypeChange(
      caseType.value,
      this.caseDetail
    );
  }

  changeTextBox(checkbox: HTMLInputElement, textbox: HTMLInputElement) {
    let whichType;
    if (typeof (checkbox) === 'boolean') {
      whichType = checkbox;
    }
    else if (checkbox instanceof HTMLInputElement) {
      whichType = checkbox.checked;
    }
    else {
      whichType = false;
    }
    if (whichType === false) {
      textbox.value = '';
      textbox.disabled = true;
      if (textbox.id === 'txtMDMgmt') {
        this.setEmptyStage(this.requestedForm.get('mdMgmt') as FormControl,
          this.objMDMgmt, false);
      }
      else if (textbox.id === 'txtTranslateMR') {
        this.setEmptyStage(this.requestedForm.get('translators') as FormControl,
          this.objTranslators, false);
      }
      else if (textbox.id === 'txtSchedule') {
        this.setEmptyStage(this.requestedForm.get('schedule') as FormControl,
          this.objSchedule, false);
      }
      else if (textbox.id === 'txtVideo') {
        this.setEmptyStage(this.requestedForm.get('video') as FormControl,
          this.objVideo, false);
      }
      else if (textbox.id === 'txtReview') {
        this.setEmptyStage(this.requestedForm.get('summary') as FormControl,
          this.objSummary, false);
      }
      else if (textbox.id === 'txtTranslateDR') {
        this.setEmptyStage(this.requestedForm.get('translate_DR') as FormControl,
          this.objTranslateDR, false);
      }
      else if (textbox.id === 'txtReport') {
        this.setEmptyStage(this.requestedForm.get('report') as FormControl,
          this.objReport, false);
      }
    }
    else {
      textbox.disabled = false;
      if (textbox.id === 'txtMDMgmt') {
        this.setEmptyStage(this.requestedForm.get('mdMgmt') as FormControl,
          this.objMDMgmt, true);
      }
      else if (textbox.id === 'txtTranslateMR') {
        this.setEmptyStage(this.requestedForm.get('translators') as FormControl,
          this.objTranslators, true);
      }
      else if (textbox.id === 'txtSchedule') {
        this.setEmptyStage(this.requestedForm.get('schedule') as FormControl,
          this.objSchedule, true);
      }
      else if (textbox.id === 'txtVideo') {
        this.setEmptyStage(this.requestedForm.get('video') as FormControl,
          this.objVideo, true);
      }
      else if (textbox.id === 'txtReview') {
        this.setEmptyStage(this.requestedForm.get('summary') as FormControl,
          this.objSummary, true);
      }
      else if (textbox.id === 'txtTranslateDR') {
        this.setEmptyStage(this.requestedForm.get('translate_DR') as FormControl,
          this.objTranslateDR, true);
      }
      else if (textbox.id === 'txtReport') {
        this.setEmptyStage(this.requestedForm.get('report') as FormControl,
          this.objReport, true);
      }
    }

  }

  suggestionListFormatter(data: any): string {
    if (data.hasOwnProperty('assignee_name')) {
      return data.assignee_name;
    }
    return '';
  }

  valueListFormatter(data: any): string {
    if (data.hasOwnProperty('assignee_name')) {
      return data.assignee_name;
    }
    return '';
  }

  Remove(event: Event, formControl: FormControl) {
    if (event) { event.preventDefault(); }
    if (typeof formControl.value !== 'object') {
      formControl.setValue('');
    }
  }

  onSubmit(event: Event, form: FormGroup) {
    if (event) { event.preventDefault(); }

    const store = {
      objMDMgmt: this.objMDMgmt,
      objTranslators: this.objTranslators,
      objSchedule: this.objSchedule,
      objVideo: this.objVideo,
      objSummary: this.objSummary,
      objTranslateDR: this.objTranslateDR,
      objReport: this.objReport
    };

    this.presenter.submitRequested(
      form,
      this.caseDetail,
      store
    );
  }

  resetQuestionFormArray() {
    const formArray = this.questions;

    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  public onEdit() {
    if (this.isReadOnly()) return console.info('For Non Read Access Only');

    if (!this.followUpNumber || this.followUpNumber === 0) {
      this.isQuestionEdit = this.isQuestionEdit ? false : true;
    }
    else {
      this.isFollowQuestionEdit = this.isFollowQuestionEdit ? false : true;
    }
  }

  addQuestion(event: Event) {
    if (event) { event.preventDefault(); }

    const form = this.presenter.getQuestionForm();
    if (form && form.controls['follow_up_id']) {
      form.controls['follow_up_id'].setValue(this.followUpNumber);
    }

    this.questions.push(form);

    this.sortFormArray(this.questions);
  }

  removeQuestion(index: number, event?: Event) {
    if (event) { event.preventDefault(); }

    const control = this.questions.at(index) as FormGroup;
    control.get('question_text').setValidators([]);
    control.get('question_text').updateValueAndValidity();

    if (!control.get('is_delete')) {
      control.addControl('is_delete', new FormControl(true));
    }

    control.get('is_delete').setValue(true);

    this.questions.removeAt(index);

    // if new question for remove then discart them directly
    if (control.get('is_delete').value && control.get('id').value !== 0) {
      this.questions.push(control);
    }

    this.sortFormArray(this.questions);
  }

  onQuestionAction(action: string, event?: Event) {
    if (event) { event.preventDefault(); }

    if (action === 'cancel') {
      this.isQuestionEdit = false;
      this.isFollowQuestionEdit = false;
      this.isInProgress = false;

      this.onCancel();
      return;
    }

    this.presenter.onQuestionFormAction(
      action.toLowerCase(),
      this.questionForm,
      this.caseDetail.id
    );
  }

  showConfirmation(dropDown: HTMLInputElement) {
    this.popUpService.destoryConfirmDialog();
    this.popUpService.setDialogParams(
      'Confirmation',
      'Are you sure? Consultation type for the case will be changed.',
      'Yes',
      'No',
      function () {
        this.changeWorkflow(dropDown);
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this),
      function () {
        dropDown.value = this.caseDetail.case_type;
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this)
    );
    const dialog = this.popUpService.getPopUpView('confirmDialog');
    if (dialog != null) { this.popUpService.showDiv(dialog); }
  }

  private sortFormArray(array: FormArray) {
    const myArray: any[] = array.value;
    myArray.sort(
      function (value1, value2) {
        if (!value1.hasOwnProperty('is_delete')) {
          value1['is_delete'] = false;
        }

        if (!value2.hasOwnProperty('is_delete')) {
          value2['is_delete'] = false;
        }

        const value1delete = value1['is_delete'];
        const value2delete = value2['is_delete'];

        return (value1delete === value2delete) ? 0 : value1delete ? 1 : -1;
      }
    );
    array.patchValue(myArray);
  }

  onCancel() {
    this.questions.controls = [];

    this.case_questions.forEach(eachQuestion => {
      if (!eachQuestion['id']) { eachQuestion['id'] = 0; }
      const form = this.presenter.getQuestionForm();
      this.questions.push(form);
    });

    const questions = {
      questions: this.case_questions
    };

    this.questionForm.setValue(questions);
  }

  checkQuestionLength(): boolean {
    const value = this.questionForm.value.questions.filter(question => !question.is_delete);

    if (value.length > 1) { return true; }

    return false;
  }

  editDocuments(event: Event) {
    if (event) { event.preventDefault(); }

    this.editDocumentEmitter.emit({ event, uploads: 'uploads' });
  }

  checkAllyCase() {
    return !!this.caseDetail.allyCaseNumber || !!this.caseDetail.allyCaseRef;
  }

  getCurrentFollowUpQuestionCount() {
    const result = this.case_questions.filter((item) => {
      if (item.follow_up_id === this.followUpNumber) return item;
    });
    return result.length > 0 ? 'Add or Edit Questions' : 'Add a Question';
  }

  isUserInActive(status_id, formControl) {
    if (this.caseDetail && this.caseDetail.status) {
      const statuses = orderBy(filter(this.caseDetail.status, (item) => {
        if (item.status_id === status_id) return item;
      }), ['follow_up_id'], ['desc']);
      if (statuses.length > 0 && statuses[0].user) {
        // if new assigned is not same as old one
        if (this.requestedForm.get(formControl).value != statuses[0].user.full_name) {
          return false;
        }

        if (!statuses[0].user.active) {
          return true;
        }
      }
    }
    return false;
  }

  isCaseStatusPaused(): boolean {
    const statusTypes = [ CASE_STATUS_TYPE.FINISHED, CASE_STATUS_TYPE.CANCELLED, CASE_STATUS_TYPE.HOLD ];

    return statusTypes.includes(this.caseDetail?.case_status);
  }

  isReadOnly(): boolean {
    this.user = this.loginService.getCurrentUser();
    return this.user.isReadOnly;
  }

  getFollowUpQuestions() {
    const followUpQuestions = [];
    this.questions.controls.forEach(obj => {
      if (obj.get('follow_up_id')?.value == this.followUpNumber)
        followUpQuestions.push(obj);
    });
    return followUpQuestions;
  }

}

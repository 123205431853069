/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */

import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonFuntions } from '../../common/common.function';
import { MailServerService } from '../../services/mail-server.service';
import { PopUpService } from '../../services/pop-up.service';

@Component({
  selector: 'app-zoom-settings',
  templateUrl: './zoom-settings.component.html',
  styleUrls: ['./zoom-settings.component.css']
})
export class ZoomSettingsComponent implements OnInit {

  zoomConfigForm: FormGroup;
  mailSettings: any;
  hostList: any;
  isEdit: boolean;
  password_type= 'password';

  constructor(
    private popUpService: PopUpService,
    private mailServerService: MailServerService,
  ) { }

  ngOnInit(): void {
    this.getMailSettings();
    this.zoomConfigForm = new FormGroup({
      email_host: new FormArray([
        this.getHostFormGroup('')
      ]),
      zoom_key: new FormControl('', [
        Validators.required,
      ]),
      secret_key: new FormControl('', [
        Validators.required,
      ]),
    });
  }
  
  private getHostFormGroup(value) {
    return new FormGroup(
      {
        host_name: new FormControl(value, [
          Validators.required,
          CommonFuntions.ValidateEmail
        ]),
      }
    );
  }

  get email_host() {
    return (this.zoomConfigForm.get('email_host') as FormArray);
  }

  get secretKey() {
    return (this.zoomConfigForm.get('secret_key') as FormControl);
  }

  get zoomKey() {
    return (this.zoomConfigForm.get('zoom_key') as FormControl);
  }

  addAnotherHost() {
    this.email_host.push(this.getHostFormGroup(''));
  }

  removeHostAt(index: number) {
    const host = this.email_host.controls[index];
    const value = host.value.host_name;
    if (value === '') {
      this.email_host.removeAt(index);
      return;
    }
    const confirmStr = 'Are you sure you want to delete ' + value + ' from host list?';
    if (confirm(confirmStr)) {
      this.email_host.removeAt(index);
    }
  }

  public showAlert(message: string, positiveAction?: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(positiveAction);
    }
    else {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) {this.popUpService.showDiv(dialog);}
  }

  generateHostList(result){
    const hostList = JSON.parse(result.email_host);
    this.email_host.clear();
    hostList?.map(host => {
      this.email_host.push(this.getHostFormGroup(host));
    });
  }

  getMailSettings() {
    this.mailServerService.getMailSettings().subscribe(
      result => {
        this.mailSettings = result;
        this.secretKey.setValue(result.secret_key);
        this.zoomKey.setValue(result.zoom_key);
        this.generateHostList(result);
      }, 
      (_error) => {
        this.mailSettings = null;
      }
    );
  }

  hostArray(data) {
    const hostArray = [];
    data.forEach(host => {
      hostArray.push(host.host_name);
    });
    return JSON.stringify(hostArray);
  }

  toggleEdit(){
    this.isEdit = !this.isEdit;
  }

  /** Function to execute when the form is submitted. */
  onSubmit() {
    if(this.zoomConfigForm.valid){
      const message = 'Are you sure? Zoom settings will be updated.';
      const postiveAction = function(): void {
        this.callSubmitAction();
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this);
      const negativeAction = function(): void {
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this);
  
      this.showConfirmation(message, postiveAction, negativeAction);
    }
    else{
      CommonFuntions.validateAllFormFields(this.zoomConfigForm);
    }
  }

  callSubmitAction(){
    if(this.mailSettings === null){
      this.zoomConfigForm.value.email_host = this.hostArray(this.zoomConfigForm.value.email_host);
      this.mailServerService.createMailSetting(this.zoomConfigForm.value).subscribe(
        result => {
          this.mailSettings = result.data; 
          this.secretKey.setValue(result.data.secret_key);
          this.zoomKey.setValue(result.data.zoom_key);
          this.generateHostList(result.data);
          this.isEdit = !this.isEdit;
          this.showAlert('Zoom Settings added sucessfully.');
        }, (_error) => {
          this.showAlert('Cannot add new zoom setting. Please try again.');
        }
      );
    }
    else{
      this.zoomConfigForm.value.email_host = this.hostArray(this.zoomConfigForm.value.email_host);
      this.mailServerService.editMailSetting(this.mailSettings.id,this.zoomConfigForm.value).subscribe(
        result => {
          this.mailSettings = result.data; 
          this.secretKey.setValue(result.data.secret_key);
          this.zoomKey.setValue(result.data.zoom_key);
          this.generateHostList(result.data);
          this.isEdit = !this.isEdit;
          this.showAlert('Zoom Settings updated sucessfully.');
        }, (_error) => {
          this.showAlert('Cannot update zoom settings. Please try again.');
        }
      );
    }
  }

  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function) {

    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }

    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }

    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);

  }



}

<app-dialog></app-dialog>
<div class="wrapper--dash-content dash-content overlay__wrapper">
  <div _ngcontent-c8="" class="overlay__content">
    <div _ngcontent-c8="" class="overlay__spinner">
      <span _ngcontent-c8="" class="icon icon--loading"></span>
    </div>
  </div>
  <div class="experts-search">
    <div class="experts-search__header">
      <div class="pre-header">
        <div class="experts-search__header__head">The top US doctors. At your fingertips.</div>
        <div class="experts-search__header__detail">When you recieve a life changing diagnosis, you deserve only the best advice.</div>
      </div>
      <div class="experts-search__header__head">
        Ask your most important questions from anywhere in the world. Gain peace of mind.
      </div>
      <div class="experts-search__header__detail">
        Recieve a <strong>Written Report</strong> or have a <strong>Live Video</strong> visit with a leading US
        specialist, from the
        comfort of home. <strong>Translation</strong> and <strong>Interpretation</strong> services included.
      </div>
    </div>
    <div class="experts-search__image">
      <img src="/assets/images/experts-search-image.png" />
    </div>
    <div class="experts-search__btn">
      <button type="button" class="btn btn--blue btn--large btn--full-width" (click)="onCreateCase()">Submit a request</button>
    </div>
    <div class="work-steps">
      <!-- (click)="downloadSamplePdf()" -->
      <div class="sample" >
        <div class="icon-holder">
          <span class="icon-pdf"></span>
        </div>
        <div>View a sample report</div>
      </div>
    </div>
    <div class="work-steps">
      <h2>How It Works</h2>
      <div class="work-steps--list">
        <ul>
          <li>
            <div>
              <img class="image-holder" src="assets/images/collect.png">
            </div>
            <div class="detail">
              <div class="detail-head">Collect</div>
              <div class="detail-content">We'll collect all relevant medical records - and provide any translation, if
                needed.</div>
            </div>
          </li>
          <li>
            <div>
              <img class="image-holder" src="assets/images/consult.png">
            </div>
            <div class="detail">
              <div class="detail-head">Consult</div>
              <div class="detail-content">Choose how you want to consult with your doctor by Written Report or Live
                Video. Our skilled clinical
                team will match you with a leading US expert and handle all the details.</div>
            </div>
          </li>
          <li>
            <div>
              <img class="image-holder" src="assets/images/report.png">
            </div>
            <div class="detail">
              <div class="detail-head">Report</div>
              <div class="detail-content">Recieve a comprehensive summary of your expect opinion to share with your
                treating physician. If you
                have follow-up questions, just reach out to our CareTeam anytime.</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable prefer-const */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, DoCheck, OnInit } from '@angular/core';

import { ViewChild, ElementRef } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormArray,
  Validators
} from '@angular/forms';

import * as moment from 'moment/moment';

import { DoctorModel } from '../../../domains/doctorModel';
import { CommonFuntions } from '../../../common/common.function';
import { FileUploader } from 'ng2-file-upload';
import { ConfigAssetLoaderService } from '../../../services/config-asset-loader.service';
import { LoginService } from '../../../services/login.service';
import {
  Router,
  UrlTree,
  UrlSegmentGroup,
  PRIMARY_OUTLET,
  UrlSegment,
  ActivatedRoute
} from '@angular/router';
import { UserService } from '../../../services/userservice.service';
import { CommonService } from '../../../services/common.service';
import { DatashareService } from '../../../services/datashare.service';
import { DoctorService } from '../../../services/doctor.service';
import { PopUpService } from '../../../services/pop-up.service';
import { TreeviewItem } from 'ngx-treeview';
import { orderBy } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-doctor',
  templateUrl: './doctor.component.html',
  styleUrls: ['./doctor.component.css']
})
export class DoctorComponent implements OnInit, DoCheck {
  @ViewChild('languageField', { static: true }) languageField: ElementRef;
  @ViewChild('degreeField', { static: true }) degreeField: ElementRef;
  @ViewChild('certificationField', { static: true }) certificationField: ElementRef;
  @ViewChild('subSpecialityField', { static: true }) subSpecialityField: ElementRef;
  @ViewChild('conditionField', { static: true }) conditionField: ElementRef;
  @ViewChild('procedureField', { static: true }) procedureField: ElementRef;
  @ViewChild('mdTypeField', { static: true }) mdTypeField: ElementRef;
  @ViewChild('titleField', { static: true }) titleField: ElementRef;
  @ViewChild('dateInput', { static: true }) dobInput;
  @ViewChild('addDoctorSwal') private addDoctorSwal: SwalComponent;
  time_zone_selected: string;

  avatarMale: string;
  doctorForm: FormGroup;
  languages: string[];
  partners: string[];
  certifications: string[];
  mdTypes: string[];
  sourceList = [];
  partnerList = [];
  countriesList: any[] = [];

  categories;
  category_fields;


  doctorModel: DoctorModel;
  userId: number;

  validatePartner = true;

  operation: string;
  userType: string;

  userTypeId: number;
  addUpdateLabel: string;
  showPassword = true;

  languagesList = [];
  conditionList = [];
  procedureList = [];
  subSpecialityList = [];
  certificationList = [];
  degreeList = [];
  mdTypesList = [];
  titlesList = [];

  filteredLanguageList: any = [];
  filteredConditionList: any = [];
  filteredProcedureList: any = [];
  filteredSubSpeciality: any = [];
  filteredCertification: any = [];
  filteredDegree: any = [];
  filteredMdType: any = [];
  filteredTitle: any = [];

  validateLanguage = false;
  validateCondition = false;
  validateProcedure = false;
  validateSubspeciality = false;
  validateCertification = false;
  validateDegree = false;
  validateMdType = false;
  validateTitle = false;
  showMaxTitle = false;
  isMdManager = false;

  uploadErrorMessage: string;
  uploadCompleteMessage: string;
  avatarimage: any;

  aquisitionList: any[] = [];
  mdTypeList: any[] = [];
  fieldOfMedicineList: any[] = [];
  categoryList: any[] = [];
  currentUser: any

  timezones: any = [];

  isSettings: boolean;
  isSupportPassword = false;
  isRecoverPassword = false;
  isResetPassword = false;
  isAllyInputDisabled = true;

  showSpinner = true;
  loadingImage = true;
  socialTypes = [
    {
      social_type: 'Select',
      social_type_id: 0
    }
  ];
  originalPartners = [];
  allPartnerList = [];
  isPartnerChecked = false;
  items: TreeviewItem[];
  allPartnerLength = 0;
  isPartnerListReady: boolean
  isPartnerListReadyCount: number

  public uploader: FileUploader = new FileUploader({
    url: ConfigAssetLoaderService.getApiURL() + '/upload',
    itemAlias: 'photo',
    authToken: `Bearer ${localStorage.getItem('token')}`,
    allowedMimeType: [
      'image/jpg',
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/tif',
      'image/bmp'
    ],
    maxFileSize: 2 * 1024 * 1024
  });

  currentDate;
  passwordValidation: boolean;
  password_type = 'password';

  constructor(
    private loginService: LoginService,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private datashare: DatashareService,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private doctorService: DoctorService,
    private popUpService: PopUpService,
    private toastrService: ToastrService  
  ) { }

  ngOnInit() {

    this.currentDate = new Date();
    this.currentDate.setHours(23, 59, 59, 999);

    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g.segments;

    this.currentUser = this.loginService.getCurrentUser();
    this.userType = this.currentUser.user_type.type.toLowerCase() || '';

    this.getTimezoneList();

    this.operation = s[0].path;
    this.operation = this.operation === 'add' ? 'Add' : 'Edit';
    if (this.operation === 'Add') {
      this.avatarMale = 'assets/images/avatar-male.jpg';
    }

    this.route.queryParams.subscribe(
      queries => {
        this.isSettings = queries['isSettings'] === 'true' ? true : false;
      }
    );

    this.datashare.$displayPicture.subscribe(function (image) {
      this.avatarimage = image;
    }.bind(this));

    this.checkAllyInputDisable();

    this.configFileUpload();

    // overide the onCompleteItem property of the uploader so we are 
    // able to deal with the server response.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const self = this;

    this.getLanguages();

    this.getSocialTypes();

    this.getConditions();

    this.getCertification();

    this.getDegreeList();

    this.getTitleList();

    this.getAquisitionList();

    this.getMdTypeList();

    this.getCategoryList();

    this.getCountries();

    this.doctorModel = new DoctorModel();

    this.doctorForm = this.initializeForm();

    this.showPassword = true;

    if (this.userType === 'mdmanager') {
      this.isMdManager = true;
      this.doctorForm.disable();
    }
    // this.doctorModel.password = this.generate(true);


    this.doctorForm.addControl('user_partners', new FormArray([], CommonFuntions.fomrArrayMinLength(0)));

    this.getPartnerList();

    this.route.params.subscribe(params => {
      const self = this;

      if (params['user_id'] && isNaN(params['user_id'])) {
        this.router.navigate(['/page-not-found']);
        return;
      }

      this.userId = params['user_id'];

      // this.userId = s[1].path;
      this.addUpdateLabel = 'Add';
      this.avatarMale = 'assets/images/avatar-male.jpg';

      /** Edit Doctor */
      if (this.userId) {
        this.doctorForm.get('user_id').setValue(this.userId);

        const contactControls = (this.doctorForm.get('contacts') as FormArray).controls;
        if (contactControls.length > 0) { contactControls[0].get('user_id').setValue(this.userId); }

        self.showPassword = false;

        this.uploader.options.url = this.uploader.options.url + '?userid=' + this.userId;

        this.addUpdateLabel = 'Save';

        self.userService.getDoctorEdit(this.userId).subscribe(
          result => {
            if (result.user_type_id != 4) {
              self.router.navigate(['/']);
              return;
            }

            this.configSettings(result);

            if (result.avatar && result.avatar != '') {
              this.loadingImage = true;
              this.userService.getUploadedFile(result.avatar).subscribe(
                image => {
                  this.avatarimage = image.image;
                  this.loadingImage = false;
                }, (_error) => {
                  this.avatarimage = null;
                  this.loadingImage = false;
                  this.avatarMale = 'assets/images/avatar-male.jpg';
                }
              );
              // }
            }

            self.doctorModel = result as DoctorModel;

            if (self.contacts.length < self.doctorModel.contacts.length) {
              for (let i = 1; i < self.doctorModel.contacts.length; i++) {
                self.contacts.push(self.generateContacts());
              }
            }


            self.first_name.setValue(self.doctorModel.first_name);
            self.middle_name.setValue(self.doctorModel.middle_name);
            self.last_name.setValue(self.doctorModel.last_name);
            self.email.setValue(self.doctorModel.email);
            self.phone_number.setValue(self.doctorModel.phone_number);
            self.avatar.setValue(self.doctorModel.avatar);
            self.time_zone.setValue(self.doctorModel.time_zone);
            self.allyUserRef.setValue(self.doctorModel.allyUserRef);
            this.time_zone_selected = self.doctorModel.time_zone_offset;

            if (self.doctorModel.dob) {
              self.dob.setValue(new Date(self.doctorModel.dob));
              this.dobInput.nativeElement.value = self.doctorModel.dob;
            }

            if (self.doctorModel.contacts.length > 0) {
              self.contacts.setValue(self.doctorModel.contacts);
            }
            if (self.user_languages.length < self.doctorModel.user_languages.length) {
              result.user_languages.forEach(
                user_language => {
                  this.addLanguages(user_language.language.language);
                }
              );
            }

            result.user_partners.forEach(
              user_partner => {
                this.originalPartners.push(user_partner.partner_id);
                this.addPartner(user_partner.partner);
              }
            );

            // enable do check for partner
            this.isPartnerChecked = false;

            // Request made for doctor info for edit
            self.userService.getDetailsEdit(this.userId).subscribe(
              result => {
                self.doctorModel = result as DoctorModel;
                self.address.setValue(self.doctorModel.doctor_info.address);
                self.city.setValue(self.doctorModel.doctor_info.city);
                self.state.setValue(self.doctorModel.doctor_info.state);
                self.zip.setValue(self.doctorModel.doctor_info.zip);
                self.stage.setValue(self.doctorModel.doctor_info.stage);
                self.doctorModel.doctor_info.institution? self.institution.setValue(self.doctorModel.doctor_info.institution): '';
                self.doctorModel.doctor_info.previous_employer? self.previous_employer.setValue(self.doctorModel.doctor_info.previous_employer): '';
                self.carePhilosophy.setValue(self.doctorModel.doctor_info.care_philosophy);
                self.bio.setValue(self.doctorModel.doctor_info.bio);
                self.doctorModel.doctor_info.research_interest? self.research_interest.setValue(self.doctorModel.doctor_info.research_interest) : '';
                self.doctorModel.doctor_info.medical_school? self.medical_school.setValue(self.doctorModel.doctor_info.medical_school): '';
                self.doctorModel.doctor_info.fellowship? self.fellowship.setValue(self.doctorModel.doctor_info.fellowship): '';
                self.doctorModel.doctor_info.fellowship_two? self.fellowship_two.setValue(self.doctorModel.doctor_info.fellowship_two): '';
                self.doctorModel.doctor_info.residency? self.residency.setValue(self.doctorModel.doctor_info.residency): '';
                self.doctorModel.doctor_info.residency_two? self.residency_two.setValue(self.doctorModel.doctor_info.residency_two): '';
                self.doctorModel.doctor_info.internship ? self.internship.setValue(self.doctorModel.doctor_info.internship): '';
                self.doctorModel.doctor_info.internship_two?  self.internship_two.setValue(self.doctorModel.doctor_info.internship_two) : '';
                self.rate_per_hour.setValue(self.doctorModel.doctor_info.rate_per_hour);
                self.aquisition_id.setValue(self.doctorModel.doctor_info.aquisition_id);
                self.referred_by.setValue(self.doctorModel.doctor_info.referred_by);
                self.doctor_id.setValue(self.doctorModel.doctor_info.doctor_id);
                self.website.setValue(self.doctorModel.doctor_info.website);
                self.country_id.setValue(self.doctorModel.doctor_info.country_id);
                self.affiliated_hospital.setValue(self.doctorModel.doctor_info.affiliated_hospital);
                self.website.setValue(self.doctorModel.doctor_info.website);
                self.gender.setValue(self.doctorModel.doctor_info.gender);
                self.category_id.setValue(self.doctorModel.doctor_info.category_id);
                self.procedures.setValue(self.doctorModel.doctor_info.procedures);
                self.availability.setValue(self.doctorModel.doctor_info.availability);
                self.note.setValue(self.doctorModel.doctor_info.note);
                self.doctor_note.setValue(self.doctorModel.doctor_info.doctor_note);
                self.carePhilosophy.setValue(self.doctorModel.doctor_info.care_philosophy);
                self.categoryString.setValue(self.doctorModel.doctor_info.category_value);
                self.conditionString.setValue(self.doctorModel.doctor_info.conditions);
                self.degreeString.setValue(self.doctorModel.doctor_info.degrees);
                self.titleString.setValue(self.doctorModel.doctor_info.titles);
                self.languageString.setValue(self.doctorModel.doctor_info.languages);

                // set doctor subspecility list by field of medicine

                this.checkUncheckStatus(self.doctorModel.doctor_info.status);
                self.publicly_listed.setValue(self.doctorModel.doctor_info.publicly_listed);
                if(this.category_id.value || self.doctorModel.doctor_info?.category_id){
                  self.userService.getSubSpecialityDoctor(this.userId).subscribe(
                    result => {
                      self.doctorModel = result as DoctorModel;
                      this.userService.getSubSpeciality(self.doctorModel.doctor_info.category_id).subscribe(
                        resultList => {
                          this.subSpecialityList = resultList;
                          result.doctor_info.doctor_category_fields.forEach(
                            doctor_sub => {
                              this.addCategoryField(doctor_sub.category_field.category_field);
                            }
                          );
                        }, (_error) => {
                          this.subSpecialityList = [];
                        }
                      );
                      this.showSpinner = false;
    
                      // self.doctorForm.setValue(self.doctorModel);
                    }, (_error) => {
                      this.showSpinner = false;
                      this.router.navigate(['/']);
                    }
                  );
                }

                this.showSpinner = false;

                // self.doctorForm.setValue(self.doctorModel);


              }, (_error) => {
                this.showSpinner = false;
                this.router.navigate(['/']);
              }
            );

            self.userService.getDetailsConditions(this.userId).subscribe(
              result => {
                self.doctorModel = result as DoctorModel;

                if (self.doctor_conditions.length < self.doctorModel.doctor_info.doctor_conditions.length) {
                  result.doctor_info.doctor_conditions.forEach(
                    doctor_condition => {
                      this.addCondition(doctor_condition.condition.condition);
                    }
                  );

                }
                this.showSpinner = false;

                // self.doctorForm.setValue(self.doctorModel);


              }, (_error) => {
                this.showSpinner = false;
                this.router.navigate(['/']);
              }
            );

            self.userService.getDetailsCertification(this.userId).subscribe(
              result => {
                self.doctorModel = result as DoctorModel;
                if (self.doctor_certifications.length < self.doctorModel.doctor_info.doctor_certifications.length) {
                  result.doctor_info.doctor_certifications.forEach(
                    doctor_certification => {
                      this.addCertification(doctor_certification.certification.certification);
                    }
                  );

                }

                this.showSpinner = false;

                // self.doctorForm.setValue(self.doctorModel);


              }, (_error) => {
                this.showSpinner = false;
                this.router.navigate(['/']);
              }
            );
            


            self.userService.getDetailsTitle(this.userId).subscribe(
              result => {
                // eslint-disable-next-line max-len
                result.doctor_info.doctor_titles[0] && result.doctor_info.doctor_titles[0].title ? self.title_one.setValue(result.doctor_info.doctor_titles[0].title?.title) : '';
                // eslint-disable-next-line max-len
                result.doctor_info.doctor_titles[1] && result.doctor_info.doctor_titles[1].title ? self.title_two.setValue(result.doctor_info.doctor_titles[1].title?.title) : '';
                // eslint-disable-next-line max-len
                result.doctor_info.doctor_titles[2] && result.doctor_info.doctor_titles[2].title ? self.title_three.setValue(result.doctor_info.doctor_titles[2].title?.title) : '';

                this.showSpinner = false;


              }, (_error) => {
                this.showSpinner = false;
                this.router.navigate(['/']);
              }
            );

            self.userService.getDetailsMDType(this.userId).subscribe(
              result => {
                self.doctorModel = result as DoctorModel;


                if (self.doctor_md_types.length < self.doctorModel.doctor_info.doctor_md_types.length) {
                  result.doctor_info.doctor_md_types.forEach(
                    doctor_md_type => {
                      this.addMdType(doctor_md_type.md_type.md_type);
                    }
                  );

                }
                this.showSpinner = false;

                // self.doctorForm.setValue(self.doctorModel);


              }, (_error) => {
                this.showSpinner = false;
                this.router.navigate(['/']);
              }
            );

            self.userService.getDetailsDegree(this.userId).subscribe(
              result => {

                self.doctorModel = result as DoctorModel;

                if (self.doctor_degrees.length < self.doctorModel.doctor_info.doctor_degrees.length) {
                  result.doctor_info.doctor_degrees.forEach(
                    doctor_degrees => {
                      this.addDegree(doctor_degrees.degree.degree_name);
                    }
                  );

                }



                // self.doctorForm.setValue(self.doctorModel);


              }, (_error) => {
                this.router.navigate(['/']);
              }
            );

            this.showSpinner = false;
          }, (_error) => {
            this.showSpinner = false;
            this.router.navigate(['/']);
          }
        );
      }
      /** Create Doctor */
      else {
        this.addFormControlOnCreate();
        this.showSpinner = false;
        this.checkUncheckStatus('Director');
      }
    });

    this.avatarMale = 'assets/images/avatar-male.jpg';
    this.loadingImage = false;

    if (this.password) this.generate(true);
  }

  ngDoCheck(): void {
    if (!this.isPartnerChecked && this.originalPartners.length > 0 && this.allPartnerList.length > 0) {
      console.log('client tree check trigger');
      this.dropDrownSelectionChange(this.originalPartners);
      this.isPartnerChecked = true;
    }
  }

  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function) {
    this.popUpService.destoryConfirmDialog();
    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );
    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }
    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }
    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);
  }

  private checkAllyInputDisable(): void {
    if (this.userType === 'admin') {
      this.isAllyInputDisabled = false;
    }
  }

  configFileUpload() {
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.uploader.uploadAll();
    };

    this.uploader.onCompleteItem = function (_item: any, response: any, status: any, _headers: any) {
      if (status && status == 200) {
        const responseObject = JSON.parse(response);

        const currentUser = this.loginService.getCurrentUser();

        if (this.userId == currentUser.user_id) {
          this.datashare.setDisplayPicture(responseObject.image);
          this.avatar.setValue(responseObject.location);
        }
        else {
          this.avatarimage = responseObject.image;

          this.avatar.setValue(responseObject.location);
        }

        this.uploadErrorMessage = null;
        this.uploadCompleteMessage = 'Upload successful.';
      }
      else if (status && status == 422) {
        this.uploadErrorMessage = 'Upload failed.';
        this.uploadCompleteMessage = null;
      }
      else {
        this.uploadErrorMessage = 'Upload failed through server.';
        this.uploadCompleteMessage = null;
      }
    }.bind(this);

    this.uploader.onWhenAddingFileFailed = function (_item, filter, _option) {
      switch (filter.name) {
      case 'mimeType':
        this.showAlert('System does not support this type of file format.');
        break;
      case 'fileSize':
        this.showAlert('Files uploaded must be less than 2MB in size.');
        break;
      default:
        break;
      }
    }.bind(this);
  }

  getPartnerList() {
    if(CommonFuntions.getPartnersForAuthorizedUser(this.userType)){
      this.commonService.getPartners().subscribe(
        function (partners) {
          const user = this.loginService.getCurrentUser();
          if (user && user != null) {
            partners.forEach(
              partner => {
                const partnerObj = {};
                partnerObj['partner_id'] = partner.partner_id;
                partnerObj['partner_name'] = partner.partner_name;
                partnerObj['is_editable'] = true;

                this.partnerList.push(partnerObj);
              }
            );

            this.allPartnerLength = this.partnerList.length;
            this.allPartnerList = this.partnerList;

            // order by name
            partners = orderBy(partners, ['partner_name'], ['asc']);
            
            const partnerInfo = { ally: [], nonAlly: [] };
            partners.forEach(partner => {
              const data = { text: partner.partner_name, value: partner.partner_id, checked: false };
              if (partner?.allyPartnerRef) {
                partnerInfo.ally.push(data);
              }
              else {
                partnerInfo.nonAlly.push(data);
              }
            });
            this.items = [new TreeviewItem({
              text: 'Ally Clients',
              value: 'ally',
              checked: false, // by default to set unchecked
              children: [...partnerInfo.ally],
            }),
            new TreeviewItem({
              text: 'Non Ally Clients',
              value: 'non-ally',
              checked: false, // by default to set unchecked
              children: [...partnerInfo.nonAlly],
            })];

            this.isPartnerListReady = true;
            // enable do check for partner
            this.isPartnerChecked = false;
          }
        }.bind(this), (_error) => {
          this.showSpinner = false;
          this.router.navigate(['/']);
        }
      );
    }
  }

  getTimezoneList() {
    this.commonService.getTimeZones().subscribe(
      result => {
        this.timezones = result;
      }, (_error) => {
        this.timezones = [];
      }
    );
  }

  getLanguages(): void {
    this.commonService.getLanguages().subscribe(
      languages => {
        this.languagesList = languages;
      }, (_error) => {
        this.languagesList = [];
      }
    );
  }

  getSocialTypes(): void {
    this.commonService.getSocialTypes().subscribe(
      function (socialtypes) {
        this.socialTypes = this.socialTypes.concat(socialtypes);
      }.bind(this)
    );
  }

  getConditions() {
    this.commonService.getConditions().subscribe(
      conditions => {
        this.conditionList = conditions;
      }, (_error) => {
        this.conditionList = [];
      }
    );
  }

  getCertification() {
    this.commonService.getCertification().subscribe(
      certificatations => {
        this.certificationList = certificatations;
      }, (_error) => {
        this.certificationList = [];
      }
    );
  }

  getDegreeList() {
    this.commonService.getDegreeList().subscribe(
      results => {
        this.degreeList = results;
      }, (_error) => {
        this.degreeList = [];
      }
    );
  }

  getTitleList() {
    this.commonService.getTitleList().subscribe(
      results => {
        this.titlesList = results;
      }, (_error) => {
        this.titlesList = [];
      }
    );
  }

  getAquisitionList() {
    this.userService.getAquisitionList().subscribe(
      results => {
        this.aquisitionList = results;
        this.aquisitionList.splice(0, 0, {
          aquisition_id: '',
          aquisition: 'Select'
        });
      }, (_error) => {
        this.aquisitionList.splice(0, 0, {
          aquisition_id: '',
          aquisition: 'Select'
        });
      }
    );
  }

  getMdTypeList() {
    this.userService.getMdTypeList().subscribe(
      mdType => {
        this.mdTypesList = mdType;
      }, (_error) => {
        this.mdTypesList = [];
      }
    );
  }

  getCategoryList() {
    this.userService.getCategoryList().subscribe(
      results => {
        this.categoryList = results;
        this.categoryList.splice(0, 0, {
          category_id: '',
          category: 'Select'
        });
      }, (_error) => {
        this.categoryList.splice(0, 0, {
          category_id: '',
          category: 'Select'
        });
      }
    );
  }

  getCountries() {
    this.commonService.getCountries().subscribe(
      countries => {
        this.countriesList = countries;
        this.countriesList.splice(0, 0, {
          country_id: 0,
          country_name: 'Select'
        });
      }, (_error) => {
        this.countriesList.splice(0, 0, {
          country_id: 0,
          country_name: 'Select'
        });
      }
    );
  }

  changeDoctorStatus(checkbox: HTMLInputElement | boolean, status: string) {
    let whichStatus;
    if (typeof (checkbox) === 'boolean') {
      whichStatus = checkbox;
    }
    else if (checkbox instanceof HTMLInputElement) {
      whichStatus = checkbox.checked;
    }
    else {
      whichStatus = false;
    }

    if (status == 'Chief' && whichStatus) {
      if (this.director.value) {
        this.checkUncheckStatus('Chief_Director');
      }
      else {
        this.checkUncheckStatus('Chief');
      }
    }
    else if (status == 'Chief' && !whichStatus) {
      if (this.director.value) {
        this.checkUncheckStatus('Director');
      }
      else {
        this.checkUncheckStatus('Undefined');
      }
    }
    else if (status == 'Director' && whichStatus) {
      if (this.chief.value) {
        this.checkUncheckStatus('Chief_Director');
      }
      else {
        this.checkUncheckStatus('Director');
      }
    }
    else if (status == 'Director' && !whichStatus) {
      if (this.chief.value) {
        this.checkUncheckStatus('Chief');
      }
      else {
        this.checkUncheckStatus('Undefined');
      }
    }

  }

  checkUncheckStatus(status: string) {
    if (status == 'Chief') {
      this.chief.setValue(true);
      this.director.setValue(false);
    }
    else if (status == 'Director') {
      this.director.setValue(true);
      this.chief.setValue(false);
    }
    else if (status == 'Chief_Director') {
      this.director.setValue(true);
      this.chief.setValue(true);
    }
    else {
      this.director.setValue(false);
      this.chief.setValue(false);
    }

    this.doctor_info.get('status').setValue(status);
  }

  addDoctor(form: FormGroup) {
    this.validateLanguage = true;
    this.validateCondition = true;
    this.validateProcedure = true;
    // this.validateSubspeciality = true;
    this.validateCertification = true;
    this.validateDegree = true;
    this.validateTitle = true;
    this.validateMdType = true;

    // set is all partner flag
    if (this.is_all_partners) {
      if (this.items && this.items.length > 0 && ['admin', 'cmmanager'].includes(this.userType)) {
        if (this.items[0].checked && this.items[1].checked) {
          this.is_all_partners.setValue(1);
        }
        else if (this.items[0].checked) {
          this.is_all_partners.setValue(2);
        }
        else if (this.items[1].checked) {
          this.is_all_partners.setValue(3);
        }
      }
      else {
        this.is_all_partners.setValue(0);
      }
    }

    if (CommonFuntions.guardForAction(this.loginService,
      this.router)) {
      if (this.operation.toLowerCase() === 'add') {
          
        // check if contact method exists and remove if not present
        if (form.getRawValue().contacts) {
          let i = 0;
          form.getRawValue().contacts.forEach(element => {
            if (element.social_id == '' || element.social_type_id == 0) {
              this.contacts.removeAt(i);
            }
            else {
              i++;

            }
          });
        }
        const doctor: DoctorModel = form.getRawValue() as DoctorModel;
        doctor.allyUserRef = doctor.allyUserRef || null;
        doctor.time_zone_offset = this.time_zone_selected;
        this.doctorService.addDoctor(doctor).subscribe(
          result => {
            this.toastrService.success('Doctor Added successfully.');
            this.router.navigate(['/doctor/profile', result.user_id]);
          }, error => {
            this.toastrService.error(this.userService.handleError(error, this.operation.toLowerCase()));
  
          }
        );
        
      }
      else if (this.operation.toLowerCase() === 'edit') {
        // check if contact method exists and remove if not present
        if (form.getRawValue().contacts) {
          let i = 0;
          form.getRawValue().contacts.forEach(element => {
            if (element.social_id == '' || element.social_type_id == 0) {
              this.contacts.removeAt(i);
            }
            else {
              i++;
            }
          });
        }
        const doctor: DoctorModel = form.getRawValue() as DoctorModel;
        doctor.allyUserRef = doctor.allyUserRef || null;
        doctor.time_zone_offset = this.time_zone_selected;
        doctor.user_id = this.userId;
        this.doctorService.updateDoctor(doctor).subscribe(
          (_result) => {
            this.toastrService.success('User Edited successfully.');
            this.router.navigate(['/doctor/profile', doctor.user_id]);
    
             
            // if self user and password changed then
            if (this.userId === this.currentUser.user_id && doctor.password) {
              localStorage.removeItem('passwordExpirySetting');
            }
          }, error => {
            this.toastrService.error(this.userService.handleError(error, this.operation.toLowerCase()));
            
          }
        );
      }
    }

  }

  get is_all_partners() {
    return this.doctorForm.get('is_all_partners');
  }

  get director() {
    return this.doctorForm.get('director');
  }

  get chief() {
    return this.doctorForm.get('chief');
  }

  get publicly_listed() {
    return this.doctor_info.get('publicly_listed');
  }


  get first_name() {
    return this.doctorForm.get('first_name');
  }

  get middle_name() {
    return this.doctorForm.get('middle_name');
  }

  get last_name() {
    return this.doctorForm.get('last_name');
  }

  get user_partners() {
    return this.doctorForm.get('user_partners') as FormArray;
  }

  get dob() {
    return this.doctorForm.get('dob');
  }

  get time_zone() {
    return this.doctorForm.get('time_zone');
  }

  get doctor_info() {
    return this.doctorForm.get('doctor_info');
  }

  get phone_number() {
    return this.doctorForm.get('phone_number');
  }

  get website() {
    return this.doctor_info.get('website');
  }
  get institution() {
    return this.doctor_info.get('institution');
  }
  get previous_employer() {
    return this.doctor_info.get('previous_employer');
  }
  get doctor_md_types() {
    return this.doctor_info.get('doctor_md_types') as FormArray;
  }
  get doctor_titles() {
    return this.doctor_info.get('doctor_titles');
  }
  get title_one() {
    return this.doctor_titles.get('title_one');
  }
  get title_two() {
    return this.doctor_titles.get('title_two');
  }
  get title_three() {
    return this.doctor_titles.get('title_three');
  }

  get category_id() {
    return this.doctor_info.get('category_id');
  }

  get affiliated_hospital() {
    return this.doctor_info.get('affiliated_hospital');
  }

  get gender() {
    return this.doctor_info.get('gender');
  }

  get address() {
    return this.doctor_info.get('address');
  }

  get city() {
    return this.doctor_info.get('city');
  }

  get state() {
    return this.doctor_info.get('state');
  }

  get zip() {
    return this.doctor_info.get('zip');
  }

  get stage() {
    return this.doctor_info.get('stage');
  }

  get research_interest() {
    return this.doctor_info.get('research_interest');
  }

  get bio() {
    return this.doctor_info.get('bio');
  }

  get doctor_note() {
    return this.doctor_info.get('doctor_note');
  }

  get medical_school() {
    return this.doctor_info.get('medical_school');
  }

  get residency() {
    return this.doctor_info.get('residency');
  }

  get residency_two() {
    return this.doctor_info.get('residency_two');
  }

  get internship() {
    return this.doctor_info.get('internship');
  }

  get internship_two() {
    return this.doctor_info.get('internship_two');
  }

  get fellowship() {
    return this.doctor_info.get('fellowship');
  }

  get fellowship_two() {
    return this.doctor_info.get('fellowship_two');
  }

  get procedures() {
    return this.doctor_info.get('procedures');
  }

  get availability() {
    return this.doctorForm.get('availability');
  }

  get note() {
    return this.doctorForm.get('note');
  }

  get rate_per_hour() {
    return this.doctor_info.get('rate_per_hour');
  }
  get aquisition_id() {
    return this.doctor_info.get('aquisition_id');
  }

  get referred_by() {
    return this.doctor_info.get('referred_by');
  }

  get country_id() {
    return this.doctor_info.get('country_id');
  }

  get doctor_id() {
    return this.doctor_info.get('doctor_id');
  }

  get email() {
    return this.doctorForm.get('email');
  }

  get contacts() {
    return this.doctorForm.get('contacts') as FormArray;
  }

  get avatar() {
    return this.doctorForm.get('avatar');
  }

  get password() {
    return this.doctorForm.get('password');
  }

  get user_languages() {
    return this.doctorForm.get('user_languages') as FormArray;
  }

  get doctor_conditions() {
    return this.doctor_info.get('doctor_conditions') as FormArray;
  }

  /* get doctor_procedures() {
    return this.doctor_info.get('doctor_procedures') as FormArray;
  } */

  get doctor_sub_speciality() {
    return this.doctor_info.get('doctor_sub_speciality') as FormArray;
  }

  get doctor_certifications() {
    return this.doctor_info.get('doctor_certifications') as FormArray;
  }

  get doctor_degrees() {
    return this.doctor_info.get('doctor_degrees') as FormArray;
  }

  get status() {
    return this.doctor_info.get('status');
  }

  get allyUserRef() {
    return this.doctorForm.get('allyUserRef');
  }

  get carePhilosophy() {
    return this.doctor_info.get('care_philosophy');
  }

  get categoryString() {
    return this.doctor_info.get('category_value');
  }

  get conditionString() {
    return this.doctor_info.get('conditions');
  }

  get degreeString() {
    return this.doctor_info.get('degrees');
  }

  get titleString() {
    return this.doctor_info.get('titles');
  }

  get languageString() {
    return this.doctor_info.get('languages');
  }

  addAnotherContact(event) {
    event.preventDefault();

    this.contacts.push(this.generateContacts());
  }

  removeContactAt(index: number) {
    const contact = this.contacts.controls[index];
    const value = contact.value.social_id;
    if (value === '') {
      this.contacts.removeAt(index);
      return;
    }
    const confirmStr = 'Are you sure you want to delete ' + value + ' from contact list?';
    this.showConfirmation(confirmStr, function () {
      this.contacts.removeAt(index);
      this.popUpService.hideDiv('confirmDialog');
    });
  }

  generate(checkbox: HTMLInputElement | boolean): string {
    let status;
    if (typeof (checkbox) === 'boolean') {
      status = checkbox;
    }
    else if (checkbox instanceof HTMLInputElement) {
      status = checkbox.checked;
    }
    else {
      status = false;
    }


    if (status) {
      this.password.markAsTouched({ onlySelf: true });
      const password = this.userService.generateRandomPassword();
      this.password.setValue(password, { onlySelf: true });
      this.password.disable();
      this.doctorForm.get('password').clearValidators();
      this.doctorForm.get('password').updateValueAndValidity();
      return password;
    }
    else {
      this.password.enable();
      this.password.reset();
      this.password.setValue('');
      this.doctorForm.get('password').setValidators([Validators.required, CommonFuntions.ValidatePassword]);
      this.doctorForm.get('password').updateValueAndValidity();
      return '';
    }
  }

  setKey(input: HTMLSelectElement) {
    this.time_zone_selected = input.selectedOptions[0].innerHTML;
  }

  generateLanguages() {
    return new FormGroup({
      user_language_id: new FormControl(0,
        [
          CommonFuntions.validateDropDown
        ]),
      language: new FormControl('',
        [
          Validators.required,
          Validators.maxLength(50)
        ]),
      language_id: new FormControl(),
      user_id: new FormControl(this.doctorModel.user_id)
    });
  }

  generateContacts() {
    return new FormGroup({
      social_type_id: new FormControl(0,
        [
          // CommonFuntions.validateDropDown
        ]),
      social_id: new FormControl('',
        [
          Validators.maxLength(50)
        ]),
      contact_id: new FormControl(),
      user_id: new FormControl(this.doctorModel.user_id)
    });
  }

  addLanguages(language: string) {
    let value;
    value = this.languagesList.find(
      object => object.language === language
    );
    this.languageField.nativeElement.blur();
    value = this.languagesList.find(
      object => object.language === language
    );

    this.filteredLanguageList = this.languagesList;
    const indexOf = this.languagesList.indexOf(value);
    this.languagesList.splice(indexOf, 1);

    if (value == null) { return; }

    this.user_languages.push(new FormControl(value));
    this.validateLanguage = true;
  }

  removeLanguages(language: FormControl) {
    const index = this.user_languages.controls.indexOf(language);
    this.user_languages.removeAt(index);

    this.languagesList.push(language.value);
    this.languagesList.sort(function (language1, language2) {
      return language1.language_id - language2.language_id;
    });
  }

  suggestionListFormatter(data: any): string {
    if (data.hasOwnProperty('language')) {
      return data.language;
    }
    if (data.hasOwnProperty('partner_name')) {
      return data.partner_name;
    }
  }

  valueListFormatter(data: any): string {
    if (data.hasOwnProperty('language')) {
      this.addLanguages(data.language);
    }
    if (data.hasOwnProperty('partner_name')) {
      this.addPartner(data);
    }
    return '';
  }

  // for doctor condition

  addCondition(condition: string) {
    let value;
    this.conditionField.nativeElement.blur();

    value = this.conditionList.find(
      object => object.condition === condition
    );

    this.filteredConditionList = this.conditionList;
    const indexOf = this.conditionList.indexOf(value);
    this.conditionList.splice(indexOf, 1);

    if (value == null) { return; }

    this.doctor_conditions.push(new FormControl(value));
    this.validateCondition = true;
  }

  removeCondition(condition: FormControl) {
    const index = this.doctor_conditions.controls.indexOf(condition);
    this.doctor_conditions.removeAt(index);

    this.conditionList.push(condition.value);

    this.conditionList.sort(function (condition1, condition2) {
      if (condition1.condition > condition2.condition) {
        return 1;
      }
      return -1;
    });

    const otherIndex = this.conditionList.findIndex(each => each.condition === 'Other');

    if (otherIndex > -1) {
      const other = this.conditionList[otherIndex];

      this.conditionList.splice(otherIndex, 1);

      this.conditionList.push(other);
    }
  }

  suggestionListCondition(data: any): string {
    if (data.hasOwnProperty('condition')) {
      return data.condition;
    }
  }

  valueListFormatterCondition(data: any): string {
    if (data.hasOwnProperty('condition')) {
      this.addCondition(data.condition);
    }
    return '';
  }

  /* // for procedure

  addProcedure(procedure: string) {
    var value;
    this.procedureField.nativeElement.blur();
    value = this.procedureList.find(
      object => {
        return object.procedure === procedure;
      }
    );

    this.filteredProcedureList = this.procedureList;
    var indexOf = this.procedureList.indexOf(value);
    this.procedureList.splice(indexOf, 1);

    if (value == null) return;

    this.doctor_procedures.push(new FormControl(value));
    this.validateProcedure = true;
  }

  removeProcedure(procedure: FormControl) {
    let index = this.doctor_procedures.controls.indexOf(procedure);
    this.doctor_procedures.removeAt(index);

    this.procedureList.push(procedure.value);
    this.procedureList.sort(function (procedure1, procedure2) {
      return procedure1.id - procedure2.id;
    });
  }

  suggestionListProcedure(data: any): string {
    if (data.hasOwnProperty('procedure')) {
      return data.procedure;
    }
  }

  valueListFormatterProcedure(data: any): string {
    if (data.hasOwnProperty('procedure')) {
      this.addProcedure(data.procedure);
    }
    return '';
  } */




  // for field of medicine

  addCategoryField(category_field: string) {
    let value;
    this.subSpecialityField.nativeElement.blur();
    value = this.subSpecialityList.find(
      object => object.category_field === category_field
    );

    this.filteredSubSpeciality = this.subSpecialityList;
    const indexOf = this.subSpecialityList.indexOf(value);
    this.subSpecialityList.splice(indexOf, 1);

    if (value == null) { return; }

    this.doctor_sub_speciality.push(new FormControl(value));
    // this.validateSubspeciality = true;
  }

  removeCategoryField(category_field: FormControl) {
    const index = this.doctor_sub_speciality.controls.indexOf(category_field);
    this.doctor_sub_speciality.removeAt(index);

    this.subSpecialityList.push(category_field.value);
    this.subSpecialityList.sort(function (category_field1, category_field2) {
      return category_field1.id - category_field2.id;
    });
  }

  suggestionListCategoryField(data: any): string {
    if (data.hasOwnProperty('category_field')) {
      return data.category_field;
    }
  }

  valueListFormatterCategoryField(data: any): string {
    if (data.hasOwnProperty('category_field')) {
      this.addCategoryField(data.category_field);
    }
    return '';
  }





  // for certification

  addCertification(certification: string) {
    let value;
    this.certificationField.nativeElement.blur();
    value = this.certificationList.find(
      object => object.certification === certification
    );

    this.filteredCertification = this.certificationList;
    const indexOf = this.certificationList.indexOf(value);
    this.certificationList.splice(indexOf, 1);

    if (value == null) { return; }

    this.doctor_certifications.push(new FormControl(value));
    this.validateCertification = true;
  }

  removeCertification(certification: FormControl) {
    const index = this.doctor_certifications.controls.indexOf(certification);
    this.doctor_certifications.removeAt(index);

    this.certificationList.push(certification.value);

    this.certificationList.sort(function (certification1, certification2) {
      if (certification1.certification > certification2.certification) {
        return 1;
      }
      return -1;
    });

    const otherIndex = this.certificationList.findIndex(each => each.certification === 'Other');

    if (otherIndex > -1) {
      const other = this.certificationList[otherIndex];

      this.certificationList.splice(otherIndex, 1);

      this.certificationList.push(other);
    }
  }

  suggestionListCertification(data: any): string {
    if (data.hasOwnProperty('certification')) {
      return data.certification;
    }
  }

  valueListFormatterCertification(data: any): string {
    if (data.hasOwnProperty('certification')) {
      this.addCertification(data.certification);
    }
    return '';
  }

  // for group

  addDegree(degree_name: string) {
    let value;
    this.degreeField.nativeElement.blur();
    value = this.degreeList.find(
      object => object.degree_name === degree_name
    );

    this.filteredDegree = this.degreeList;
    const indexOf = this.degreeList.indexOf(value);
    this.degreeList.splice(indexOf, 1);

    if (value == null) { return; }

    this.doctor_degrees.push(new FormControl(value));
    this.validateDegree = true;
  }

  removeDegree(degree_name: FormControl) {
    const index = this.doctor_degrees.controls.indexOf(degree_name);
    this.doctor_degrees.removeAt(index);

    this.degreeList.push(degree_name.value);
    this.degreeList.sort(function (degree_name1, degree_name2) {
      return degree_name1.id - degree_name2.id;
    });
  }

  suggestionListDegree(data: any): string {
    if (data.hasOwnProperty('degree_name')) {
      return data.degree_name;
    }
  }

  valueListFormatterDegree(data: any): string {
    if (data.hasOwnProperty('degree_name')) {
      this.addDegree(data.degree_name);
    }
    return '';
  }

  resetPassword(event, _link: HTMLLinkElement) {
    event.preventDefault();
    this.passwordValidation = true;
    this.doctorForm.removeControl('use_support_password');
    this.doctorForm.addControl('use_support_password', new FormControl(false));
    if (this.showPassword && this.operation.toLowerCase() === 'edit') { return; }
    this.showPassword = true;
    if (!this.doctorForm.controls.hasOwnProperty('password')) {
      this.doctorForm.addControl('password', new FormControl());
    }
    if (!this.doctorForm.controls.hasOwnProperty('auto_generate_pwd')) {
      this.doctorForm.addControl('auto_generate_pwd', new FormControl(true));
    }

    this.generate(true);
  }

  validateForm(){
    if(this.passwordValidation){
      this.addDoctorSwal.fire();
    }
    else{
      CommonFuntions.validateAllFormFields(this.doctorForm);
      if(this.doctorForm.invalid){
        this.validateMdType = true;
        this.toastrService.warning('Please fill all mandatory input fields','Invalid Form');
      }
      else{
        this.addDoctorSwal.fire();
      }
    }
    
  
  }


  useSupportPassword(event) {
    event.preventDefault();
    this.passwordValidation=true;
    this.passwordValidation = true;
    this.doctorForm.addControl('use_support_password', new FormControl(true));

    if (this.showPassword && this.operation.toLowerCase() === 'edit') { return; }
    this.showPassword = true;
    if (!this.doctorForm.controls.hasOwnProperty('password')) {
      this.doctorForm.addControl('password', new FormControl());
    }
    if (!this.doctorForm.controls.hasOwnProperty('auto_generate_pwd')) {
      this.doctorForm.addControl('auto_generate_pwd', new FormControl(true));
    }
    this.generate(true);


  }

  recoverPassword(event) {
    event.preventDefault();

    this.userService.recoverPassword(this.userId).subscribe(
      (_result) => {
        this.showAlert('Password recovered.', function () {
          this.router.navigate(['/doctor/profile', this.userId]);
        }.bind(this));
      }, error => {
        this.showAlert(this.userService.handleError(error, this.operation.toLowerCase()));
      }
    );
  }

  // for md type

  addMdType(md_type: string) {
    let value;
    this.mdTypeField.nativeElement.blur();
    value = this.mdTypesList.find(
      object => object.md_type === md_type
    );

    this.filteredMdType = this.mdTypesList;
    const indexOf = this.mdTypesList.indexOf(value);
    this.mdTypesList.splice(indexOf, 1);

    if (value == null) { return; }

    this.doctor_md_types.push(new FormControl(value));
    this.validateMdType = true;
  }

  removeMdType(md_type: FormControl) {
    const index = this.doctor_md_types.controls.indexOf(md_type);
    this.doctor_md_types.removeAt(index);

    this.mdTypesList.push(md_type.value);
    this.mdTypesList.sort(function (md_type1, md_type2) {
      const id1 = +md_type1.md_type_id;
      const id2 = +md_type2.md_type_id;

      return id1 - id2;
    });
  }

  suggestionListMdType(data: any): string {
    if (data.hasOwnProperty('md_type')) {
      return data.md_type;
    }
  }

  valueListFormatterMdType(data: any): string {
    if (data.hasOwnProperty('md_type')) {
      this.addMdType(data.md_type);
    }
    return '';
  }

  // for title

  /* 
    addTitle(title: string) {
      if (this.doctor_titles.length > 2) {
        this.doctor_titles.controls["maxSize"].setErrors({ backend: { someProp: "Backend message" } });
        this.showMaxTitle = true
        return
      };
  
      var value;
      this.titleField.nativeElement.blur();
      value = this.titlesList.find(
        object => {
          return object.title === title;
        }
      );
  
      this.filteredTitle = this.titlesList;
      var indexOf = this.titlesList.indexOf(value);
      this.titlesList.splice(indexOf, 1);
  
      if (value == null) return;
  
      this.doctor_titles.push(new FormControl(value));
      this.validateTitle = true;
    }
  
    removeTitle(title: FormControl) {
      let index = this.doctor_titles.controls.indexOf(title);
      this.doctor_titles.removeAt(index);
  
      this.titlesList.push(title.value);
      this.titlesList.sort(function (title1, title2) {
        return title1.id - title2.id;
      });
    }

    suggestionListTitle(data: any): string {
      if (data.hasOwnProperty('title')) {
        return data.title
      }
    }

    valueListFormatterTitle(data: any): string {
      if (data.hasOwnProperty('title')) {
        this.addTitle(data.title);
      }
      return '';
    }

    checkMaxTitleField() {
      if (this.doctor_titles.length > 2) {
        this.showMaxTitle = true
        return
      }
      else {
        this.showMaxTitle = false;
      }
    } */

  cancel(event: Event) {
    event.preventDefault();
    if (this.userId == this.currentUser.user_id) {
      return this.router.navigate(['/profile', this.userId]);
    }
    this.router.navigate(['/doctor/profile', this.userId]);
  }

  public showAlert(message: string, positiveAction?: Function) {
    this.popUpService.destoryConfirmDialog();
    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );
    if (positiveAction) {
      this.popUpService.setPositiveAction(positiveAction);
    }
    else {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }
    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) { this.popUpService.showDiv(dialog); }
  }

  addPartner(partner: any) {
    this.is_all_partners.setValue(0);
    this.user_partners.push(new FormControl(partner));
  }

  clearPartner(partner: FormControl) {

    const allList = [];

    this.partnerList.forEach(element => {
      if (element.partner_name != 'All') {
        allList.push(element);
      }
    });

    const index = this.user_partners.controls.indexOf(partner);
    allList.push(partner.value);
    allList.sort(
      (partner1, partner2) => partner1.partner_id - partner2.partner_id
    );
    this.user_partners.removeAt(index);

    this.partnerList = allList;

    const all = {};
    if (this.userType.toLowerCase() === 'admin') {
      all['partner_id'] = [];
      all['partner_name'] = 'All';
    }

    this.user_partners.controls.forEach(control => {
      all['partner_id'].push(control.value.partner_id);
    });


    this.partnerList.forEach(partner => {
      all['partner_id'].push(partner.partner_id);
    });

    all['partner_id'].sort();

    this.partnerList.splice(0, 0, all);

  }



  changeSubSpeciality(index) {
    this.subSpecialityList = [];
    this.purgeForm(this.doctor_sub_speciality);

    if (index < 1) {
      this.category_fields = [{
        category_id: 0,
        category: 'Select'
      }];
    }
    else {
      // populate sub specialities
      this.userService.getSubSpeciality(index).subscribe(
        results => {
          this.subSpecialityList = results;
        }, (_error) => {
          this.subSpecialityList = [];
        }
      );


    }

  }

  purgeForm(form: FormArray) {
    while (0 !== form.length) {
      form.removeAt(0);
    }
  }

  /** Add these form control only when doing create operation. */
  addFormControlOnCreate() {
    this.doctorForm.addControl('password', new FormControl('', { validators: [CommonFuntions.ValidatePassword], updateOn: 'change'}));
    this.doctorForm.addControl('auto_generate_pwd', new FormControl(true));
  }

  initializeForm(): FormGroup {
    return new FormGroup(
      {
        user_id: new FormControl(),
        first_name: new FormControl(this.doctorModel.first_name, [
          Validators.required,
          Validators.maxLength(50)
        ]),
        middle_name: new FormControl(this.doctorModel.middle_name, [
          Validators.maxLength(50)
        ]),
        last_name: new FormControl(this.doctorModel.last_name, [
          Validators.required,
          Validators.maxLength(50)
        ]),
        user_languages: new FormArray([],
          [
            // CommonFuntions.fomrArrayMinLength(1)
          ]),
        email: new FormControl(this.doctorModel.email, [
          Validators.required,
          CommonFuntions.ValidateEmail
        ]
        ),
        dob: new FormControl(this.doctorModel.dob),
        allyUserRef: new FormControl({ value: this.doctorModel.allyUserRef, disabled: this.isAllyInputDisabled }, [
          Validators.maxLength(200),
          Validators.required
        ]),
        phone_number: new FormControl(
          this.doctorModel.doctor_info.phone_number,
          [
            Validators.maxLength(15),
            Validators.minLength(9),
            // Validators.required
          ]
        ),
        contacts: new FormArray([
          this.generateContacts()
        ]),
        director: new FormControl(),
        chief: new FormControl(),
        avatar: new FormControl(this.doctorModel.avatar),
        user_type_id: new FormControl(4),
        is_all_partners: new FormControl(0),
        availability: new FormControl(this.doctorModel.availability, [
          //  Validators.required
        ]),
        note: new FormControl(this.doctorModel.note, [
          // Validators.required,
          Validators.maxLength(5000)
        ]),
        time_zone: new FormControl(
          this.doctorModel.time_zone,
          [
            Validators.required]
        ),
        doctor_info: new FormGroup({
          doctor_id: new FormControl(),
          address: new FormControl(
            this.doctorModel.doctor_info.address,
            [
              // Validators.required,
              Validators.maxLength(255),
              Validators.minLength(0)
            ]
          ),
          city: new FormControl(
            this.doctorModel.doctor_info.city,
            [
              // Validators.required,
              Validators.maxLength(255),
              Validators.minLength(0)
            ]
          ),
          state: new FormControl(
            this.doctorModel.doctor_info.state,
            [
              // Validators.required,
              Validators.maxLength(255),
              Validators.minLength(0)
            ]
          ),
          zip: new FormControl(
            this.doctorModel.doctor_info.zip,
            [
              // Validators.required,
              Validators.maxLength(12),
              // Validators.minLength(3)
            ]
          ),
          country_id: new FormControl(0,
            [
              // CommonFuntions.validateDropDown
            ]),
          gender: new FormControl(null,
            [
              // CommonFuntions.validateDropDown
            ]),
          website: new FormControl(
            this.doctorModel.doctor_info.website,
            [
              // Validators.required,
              Validators.maxLength(255),
              Validators.minLength(0)
            ]
          ),
          institution: new FormControl(
            this.doctorModel.doctor_info.institution,
            [
              Validators.required,
              Validators.maxLength(255),
              Validators.minLength(0)
            ]
          ),
          previous_employer: new FormControl(
            this.doctorModel.doctor_info.previous_employer,
            [
              // Validators.required,
              Validators.maxLength(255),
              Validators.minLength(0)
            ]
          ),
          stage: new FormControl(
            this.doctorModel.doctor_info.stage,
            [
              // Validators.required,
              Validators.maxLength(255),
              Validators.minLength(0)
            ]
          ),
          bio: new FormControl(
            this.doctorModel.doctor_info.bio,
            [
              Validators.required,
              Validators.maxLength(50000),
              Validators.minLength(0)
            ]
          ),
          status: new FormControl(
            this.doctorModel.doctor_info.status,
            [
              // Validators.required
            ]
          ),
          doctor_note: new FormControl(
            this.doctorModel.doctor_info.doctor_note
          ),
          medical_school: new FormControl(
            this.doctorModel.doctor_info.medical_school,
            [
              Validators.required,
              Validators.maxLength(255),
              Validators.minLength(0)
            ]
          ),
          residency: new FormControl(
            this.doctorModel.doctor_info.residency,
            [
              Validators.required,
              Validators.maxLength(255),
              Validators.minLength(0)
            ]
          ),
          residency_two: new FormControl(
            this.doctorModel.doctor_info.residency_two,
            [
              // Validators.required,
              Validators.maxLength(255),
              Validators.minLength(0)
            ]
          ),
          internship: new FormControl(
            this.doctorModel.doctor_info.internship,
            [
              // Validators.required,
              Validators.maxLength(255),
              Validators.minLength(0)
            ]
          ),
          internship_two: new FormControl(
            this.doctorModel.doctor_info.internship_two,
            [
              // Validators.required,
              Validators.maxLength(255),
              Validators.minLength(0)
            ]
          ),
          fellowship: new FormControl(
            this.doctorModel.doctor_info.fellowship,
            [
              // Validators.required,
              Validators.maxLength(255),
              // Validators.minLength(0)
            ]
          ),
          fellowship_two: new FormControl(
            this.doctorModel.doctor_info.fellowship_two,
            [
              // Validators.required,
              Validators.maxLength(255),
              Validators.minLength(0)
            ]
          ),
          rate_per_hour: new FormControl(
            this.doctorModel.doctor_info.rate_per_hour,
            [
              // Validators.required
            ]
          ),
          aquisition_id: new FormControl('', [
            // CommonFuntions.validateDropDown,
            // Validators.required
          ]),
          publicly_listed: new FormControl(this.doctorModel.doctor_info.publicly_listed, [
          ]),
          referred_by: new FormControl(
            this.doctorModel.doctor_info.referred_by,
            [
              // Validators.required,
              Validators.maxLength(255),
              Validators.minLength(0)
            ]
          ),
          doctor_titles: new FormGroup({
            title_one: new FormControl(
              '',
              [
                // Validators.required,
                Validators.maxLength(255),
                Validators.minLength(0)
              ]
            ),
            title_two: new FormControl(
              '',
              [
                Validators.maxLength(255),
                Validators.minLength(0)
              ]
            ),
            title_three: new FormControl(
              '',
              [
                Validators.maxLength(255),
                Validators.minLength(0)
              ]
            ),
          }),
          doctor_md_types: new FormArray([],
            [
              CommonFuntions.fomrArrayMinLength(1)
            ]),
          research_interest: new FormControl(
            this.doctorModel.doctor_info.research_interest,
            [
              Validators.required,
              Validators.maxLength(50000),
              Validators.minLength(0)
            ]
          ),
          procedures: new FormControl(
            this.doctorModel.doctor_info.procedures,
            [
              // Validators.required,
              Validators.maxLength(50000),
              Validators.minLength(0)
            ]
          ),
          category_id: new FormControl(
            '',
            [
              // CommonFuntions.validateDropDown,
              // Validators.required
            ]
          ),
          doctor_conditions: new FormArray([],
            [
              // CommonFuntions.fomrArrayMinLength(1),
              // this.generateConditions()
            ]),
          /* doctor_procedures: new FormArray([],
            [
              CommonFuntions.fomrArrayMinLength(0)
            ]), */
          doctor_sub_speciality: new FormArray([],
            [
              CommonFuntions.fomrArrayMinLength(0)
            ]),
          doctor_certifications: new FormArray([],
            [
              // CommonFuntions.fomrArrayMinLength(1)
            ]),
          doctor_degrees: new FormArray([],
            [
              // CommonFuntions.fomrArrayMinLength(1)
            ]),
          affiliated_hospital: new FormControl(
            this.doctorModel.doctor_info.affiliated_hospital,
            [
              // Validators.required,
              Validators.maxLength(255),
              Validators.minLength(0)
            ]
          ),
          care_philosophy: new FormControl(
            this.doctorModel.doctor_info.care_philosophy,
            [
              Validators.maxLength(50000),
              Validators.minLength(0)
            ]
          ),
          category_value: new FormControl(
            this.doctorModel.doctor_info.category_value,
            [
              Validators.required,
              Validators.maxLength(50000),
              // Validators.minLength(0)
            ]
          ),
          conditions: new FormControl(
            this.doctorModel.doctor_info.conditions,
            [
              Validators.required,
              Validators.maxLength(50000),
              // Validators.minLength(0)
            ]
          ),
          degrees: new FormControl(
            this.doctorModel.doctor_info.degrees,
            [
              Validators.required,
              Validators.maxLength(50000),
              // Validators.minLength(0)
            ]
          ),
          titles: new FormControl(
            this.doctorModel.doctor_info.titles,
            [
              Validators.required,
              Validators.maxLength(50000),
              // Validators.minLength(0)
            ]
          ),
          languages: new FormControl(
            this.doctorModel.doctor_info.languages,
            [
              Validators.required,
              Validators.maxLength(50000),
              // Validators.minLength(0)
            ]
          ),
        })
      }
    );
  }

  configSettings(result) {
    /** Check settings. */
    if (this.currentUser.user_id === result.user_id) {
      this.isSettings = true;
    }

    /** Check support password */
    if (this.operation.toLowerCase() === 'edit' && this.isSettings && !result.temp_password && this.currentUser.user_type.type.toLowerCase() === 'admin') {
      this.isSupportPassword = true;
    }

    /** Check recover password */
    if (this.operation.toLowerCase() === 'edit' && this.isSettings && result.temp_password && this.currentUser.user_type.type.toLowerCase() === 'admin') {
      this.isRecoverPassword = true;
    }

    /** Check reset password */
    if (this.operation.toLowerCase() === 'edit' && this.isSettings) {
      this.isResetPassword = true;
    }

    delete result.temp_password; /** Need to delete this. */
  }

  preventDefault(event: Event) {
    const userAgent = window.navigator.userAgent;

    const browsers = { chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i };

    for (const key in browsers) {
      if (browsers[key].test(userAgent)) {
        if (key == 'firefox') {
          event.preventDefault();

        }
      }
    }

  }

  public onDateChange(_event, calender, dateInput) {
    const calenderDate = calender.selected;
    if (calenderDate == null) {
      dateInput.value = '';
      return;
    }
    if (calenderDate.getFullYear() < 1900 || calenderDate.getFullYear() > 9999) {
      if (calenderDate.getFullYear() > 9999) { dateInput.value = ''; }
      return;
    }
    const date = moment(calender.selected.toUTCString()).format('YYYY-MM-DD');

    if (moment().isBefore(date)) {
      dateInput.value = '';
      return;
    }
    this.doctorForm.get('dob').setValue(date);
    dateInput.value = date;
  }

  dropDrownSelectionChange(clients) {
    if (this.items.length > 0) {
      this.isPartnerListReady = false;
      let isAllyChecked = 0;
      let isNonAllyChecked = 0;
      // loop ally clients
      if (this.items[0] && this.items[0].children) {
        this.items[0].children.forEach(client => {
        // if client already assigned then mark as checked
          if (clients.includes(client.value)) {
            isAllyChecked++;
            client.checked = true;
          }
          else {
            client.checked = false;
          }
        });

        // check ally parent checkbox if all allyClients checked
        if (isAllyChecked === this.items[0].children.length) {
          this.items[0].checked = true;
        }
      }

      

      // loop non ally clients
      if (this.items[1] && this.items[1].children) {
        this.items[1].children.forEach(client => {
          // if client already assigned then mark as checked
          if (clients.includes(client.value)) {
            isNonAllyChecked++;
            client.checked = true;
          }
          else {
            client.checked = false;
          }
        });
        // check non ally parent checkbox if all nonAllyClients checked
        if (isNonAllyChecked === this.items[1].children.length) {
          this.items[1].checked = true;
        }
      }

      if (isAllyChecked == 0) {
        this.items[0].checked = false;
      }

      if (isNonAllyChecked == 0) {
        this.items[1].checked = false;
      }

      this.items = [...this.items];
    }
    this.onClientSelectChanged(clients);
  }

  onClientSelectChanged(clients: number[]) {
    this.user_partners.controls = [];

    clients.forEach(clientId => {
      const partnerObj = this.partnerList.find(partnerItem => partnerItem.partner_id === clientId);
      if (partnerObj && partnerObj.partner_name != 'All') {
        this.user_partners.push(new FormControl(partnerObj));
      }
    });
  }

  resetClients(_e) {
    this.dropDrownSelectionChange(this.originalPartners);
  }
}
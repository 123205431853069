/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-spread */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CaseService } from '../../services/case.service';

@Component({
  selector: 'app-docs-to-be-translated',
  templateUrl: './docs-to-be-translated.component.html',
  styleUrls: ['./docs-to-be-translated.component.css'],
})
export class DocsToBeTranslatedComponent implements OnInit {
  @Input() showSpinner: boolean;
  @Input() case: any;
  @Input() caseAssignmentId: number;
  @Output() showAlert = new EventEmitter<any>();
  isDocumentCollapsed = false;

  constructor(private caseService: CaseService) {}

  ngOnInit(): void {}

  expandOrCollapsedDocument(event) {
    event.preventDefault();
    this.isDocumentCollapsed = this.isDocumentCollapsed == true ? false : true;
  }

  downloadFile(id: number, fileName: string, event?: Event): void {
    if (event) {
      event.preventDefault();
    }
    this.caseService.downloadPDF(id, fileName, this.caseAssignmentId).subscribe(
      (result) => {
        const { url } = result;
        window.open(url, '_blank');
      },
      (error) => {
        console.log(error);
        this.showAlert.emit('Cannot download file "' + fileName + '".');
      }
    );
  }
}

<div class="box box--white box--radius box--margin-bottom-2x">
    <div class="contact-details case-details">
        <div class="contact-details__row">
            <div class="contact-details__label label-is-heading">Export (CSV)</div>
            <div class="contact-details__details details-is-heading is-uppercase">
            </div>
        </div>
        <form [formGroup]="exportForm">
            <div class="form__row">
                <label for="" class="form__label">Date Range</label>
                <div class="form__field">
                    <div class="form__field--date">
                        <input formControlName="date_range" [owlDateTime]="dt3" [selectMode]="'range'" readonly>
                        <span class="trigger  icon icon--calendar" [owlDateTimeTrigger]="dt3"></span>
                        <owl-date-time [pickerType]="'calendar'" #dt3></owl-date-time>
                    </div>
                    <div *ngIf="date_range.invalid && date_range.touched"
                        class="message message--is-invalid">
                        <div *ngIf="date_range.errors.required">
                            * Date Range is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="btns__row text-left">
                <a href="#" class="btn btn--blue" (click)="exportReportCSV($event)">Export</a>
            </div>
        </form>
    </div>
</div>
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonFuntions } from '../../../common/common.function';
import { MailServerService } from '../../../services/mail-server.service';
import { EMAIL_SERVICE } from '../constant';

@Component({
  selector: 'app-new-outgoing-mail-server',
  templateUrl: './new-outgoing-mail-server.component.html',
  styleUrls: ['./new-outgoing-mail-server.component.css']
})
export class NewOutgoingMailServerComponent implements OnInit {

  isInprogress = false;
  mailServerForm: FormGroup;
  emailServiceList = EMAIL_SERVICE;
  showSMTPSetting = true;
  password_type = 'password';

  constructor(
    private fb: FormBuilder,
    private mailServerService: MailServerService,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private router: Router,
    private toastrService:ToastrService
  ) { }

  private updateFormValidator(value: string): void {
    if (value) {
      this.smtp_host.clearValidators();
      this.smtp_host.updateValueAndValidity();
      this.smtp_port.clearValidators();
      this.smtp_port.updateValueAndValidity();
      // this.smtp_encryption.clearValidators();
      // this.smtp_encryption.updateValueAndValidity();
      // this.smtp_user.clearValidators();
      // this.smtp_user.updateValueAndValidity();
      // this.smtp_password.clearValidators();
      // this.smtp_password.updateValueAndValidity();
    }
    else {
      this.smtp_host.setValidators([Validators.required]);
      this.smtp_host.updateValueAndValidity();
      this.smtp_port.setValidators([Validators.required]);
      this.smtp_port.updateValueAndValidity();
      // this.smtp_encryption.setValidators([Validators.required]);
      // this.smtp_encryption.updateValueAndValidity();
      // this.smtp_user.setValidators([Validators.required]);
      // this.smtp_user.updateValueAndValidity();
      // this.smtp_password.setValidators([Validators.required]);
      // this.smtp_password.updateValueAndValidity();
    }
  }

  onEmailServiceChange(event): void {
    const value = event.target.value;
    if (value) {
      this.showSMTPSetting = false;
    }
    else {
      this.showSMTPSetting = true;
    }
    this.updateFormValidator(value);
  }

  getMailServerForm(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      description: [''],
      sequence: [0, Validators.required],
      active: [false],
      smtp_host: ['', Validators.required],
      smtp_port: [0, Validators.required],
      smtp_encryption: ['', Validators.required],
      smtp_user: [null],
      smtp_password: [null],
      email_service: ['']
    });
  }

  callSubmitAction(): void {
    this.router.navigate(['/settings/outgoing-mail-server']);

    this.isInprogress = true;
    if (this.mailServerForm.valid) {
      const payload = this.mailServerForm.value;
      this.mailServerService.createMailServer(payload).subscribe(
        result => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { status, data } = result;
          if (status === 200) {
            this.isInprogress = false;
            this.toastrService.success('Outgoing mail server create success!.');
          }
        },
        error => {
          this.isInprogress = false;
          this.toastrService.error(error.error.message ? error.error.message : 'Failed to edit the mail server');
        }
      );
    }
    else {
      this.isInprogress = false;
      CommonFuntions.validateAllFormFields(this.mailServerForm);
    }
  }

  onFormChange() {
    this.mailServerForm.valueChanges.subscribe((formValue) => {
      if (formValue.smtp_user?.trim() === '') {
        this.smtp_user.setValue(null);
      }
      if (formValue.smtp_password?.trim() === '') {
        this.smtp_password.setValue(null);
      }
    });
  }

  /** Function to execute when the form is submitted. */
  onSubmit() {
    if(this.mailServerForm.valid){
      this.callSubmitAction();
    }
    else{
      CommonFuntions.validateAllFormFields(this.mailServerForm);
    }
  }

  ngOnInit(): void {
    this.mailServerForm = this.getMailServerForm();
    this.onFormChange();
  }

  get name() {
    return this.mailServerForm.get('name');
  }

  get description() {
    return this.mailServerForm.get('description');
  }

  get sequence() {
    return this.mailServerForm.get('sequence');
  }

  get active() {
    return this.mailServerForm.get('active');
  }

  get smtp_host() {
    return this.mailServerForm.get('smtp_host');
  }

  get smtp_port() {
    return this.mailServerForm.get('smtp_port');
  }

  get smtp_encryption() {
    return this.mailServerForm.get('smtp_encryption');
  }

  get smtp_user() {
    return this.mailServerForm.get('smtp_user');
  }

  get smtp_password() {
    return this.mailServerForm.get('smtp_password');
  }

  get email_service() {
    return this.mailServerForm.get('email_service');
  }



}

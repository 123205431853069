/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Contact } from '../domains/contactModel';

export class UserModel {
    user_id: number;
    full_name: string;
    avatar: string;
    email: string;
    allyUserRef: string;
    zip_code?: string;
    dob?: string;
    auto_generate_pwd: boolean;
    contacts: Contact[]
    active: number;
    user_type_id: number;
    created_by: number;
    created_date: string;
    password: string;
    phone_number: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    time_zone: string;
    time_zone_offset: string;
    temp_password: string;
    user_partners = [];
    is_deleted: boolean;
    delete_reason: string;

    constructor() {
      this.first_name = '';
      this.middle_name = '';
      this.last_name = '';
      this.full_name = '';
      this.avatar = '';
      this.email = '';
      this.auto_generate_pwd = false;
      this.contacts = [];
      this.password = '';
      this.time_zone = '';
      this.time_zone_offset = '';
      this.temp_password = '';
      this.allyUserRef='';
      this.zip_code= null;
    }
}

<div class="box box--white box--no-pad box--radius margin--bottom-2x overlay__wrapper" [class.overlay--visible]="isInProgress">
	<div class="overlay__content">
		<div class="overlay__spinner">
			<span class="icon icon--loading"></span>
		</div>
	</div>

	<form [formGroup]="translateMRForm">
		<div class="box__title case-section-box">
			<div class="box-title-left">
				<div class="left-title">CompileMR</div>
				<div class="left-status">
					<span class="icon icon--calendar"></span>Assigned {{translator?.created_at | amCalendar}}</div>
			</div>
			<div class="box-title-right">
				<div class="right-name">{{caseDetail?.case_owner?.full_name}}</div>
				<div class="right-status margin--top-x">Case Owner</div>
			</div>
			<div class="box-title-right">
				<div class="flexDisplay">
					<div class="mr20">
						<div class="right-name">{{translator?.user?.full_name}}</div>
						<div class="right-status margin--top-x">Assignee</div>
					</div>
					<div>
						<a href="javascript:void(0)" class="go-back pull-right collapseToggle top10"
							[ngClass]="{collapseToggleActive: isCompileMRCollapse===true}"
							(click)="isCompileMRCollapse = !isCompileMRCollapse">
							<span class="icon icon--time-nav"></span>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div [hidden]="!isCompileMRCollapse" class="padding-2x box--md-mgmt form">
			<div class="form__row">
				<div class="col col__md-2">
					<div class="form__label is-uppercase">Description</div>
				</div>
				<div class="col col__md-10">
					<div class="form__field">
						<div class="text">Verify that the Attachment section has all necessary medical records translated.</div>
						<div class="form__field checkbox--in-row">
							<div class="form__field--checkbox">
								<input type="checkbox" formControlName="medical_record_translation" [attr.disabled]="(disableInputs || translator.state === 'FINISHED') ? '': null" [readonly]="true"
								/>
								<label for="option">Medical Records Complete</label>
							</div>
						</div>
						<div class="message message--is-invalid" *ngIf="medical_record_translation.touched && medical_record_translation.invalid">
							This field is required.
						</div>
					</div>
				</div>
			</div>

			<div class="form__row">
				<div class="col col__md-2">
					<div class="form__label is-uppercase">Notes</div>
				</div>
				<div class="col col__md-10">
					<div class="form__field">
						<textarea formControlName="notes" [attr.disabled]="(disableInputs || translator.state === 'FINISHED') ? '': null" [readonly]="true" ></textarea>
						<div class="col col__md-10 message message--is-invalid" *ngIf="translateMRForm.get('notes').touched && translateMRForm.get('notes').invalid">
							<div *ngIf="translateMRForm.get('notes').errors.maxlength">The field must not exceed more than {{translateMRForm.get('notes').errors.maxlength.requiredLength}} characters.</div>
						</div>
					</div>
				</div>
			</div>
			<div class="btns__row text-right" *ngIf="!disableInputs">
				<div class="btn btn--blue is-loading" *ngIf="isEmailSending"> 
					<span class="icon icon--loading "></span>
				</div>
				<app-generic-button *ngIf="!isEmailSending && translator.state === 'FINISHED' && !caseDetail?.case_translate_mr?.is_email_sent"
				 (onClick)="sendEmail()">Send email to MD</app-generic-button>
				<app-generic-button *ngIf="!isEmailSending && translator.state === 'FINISHED' && caseDetail?.case_translate_mr?.is_email_sent"
				 (onClick)="sendEmail()">Re-send email to MD</app-generic-button>
				<app-generic-button *ngIf="!isInProgress" (onClick)="finishTranslateMR(translateMRForm)" [disabled]="translator.state === 'FINISHED'" >Finish</app-generic-button>
				<div class="btn btn--blue is-loading" *ngIf="isInProgress">
					<span class="icon icon--loading "></span>
				</div>
			</div>

		</div>
	</form>
</div>
<div class="box box--white box--no-pad box--radius margin--bottom-2x overlay__wrapper" [class.overlay--visible]="isInProgress">
<app-intake-form *ngIf="true" [caseDetail]="caseDetail" [sudoDisableDrag]="true" (notifyDocumentChange) ="notify($event)" ></app-intake-form>
</div>
<div class="document overlay__wrapper " [class.overlay--visible]="showSpinner">
	<div class="overlay__content">
		<div class="overlay__spinner">
			<span class="icon icon--loading"></span>
		</div>
	</div>
	<div class="document__title padding-2x" (click)="expandOrCollapsedDocument($event)">
		<div class="document__title-image">
			<span class="icon icon--document-title"></span>
		</div>
		<div class="document__title-name">
			<h2 class="title-heading">Documents to be Translated</h2>
			<div class="title-subheading">Available for download</div>
		</div>
		<div class="document__title-collapsible" [class.document__title--is-collapsed]="isDocumentCollapsed">
			<a href="#" class="collapse-state">
				<span class="icon icon--chevron-down"></span>
			</a>
		</div>
	</div>
	<div class="document__content padding-2x" [class.document__content--is-collapsed]="isDocumentCollapsed">
		<div class="doc-list">
			<h3 class="doc-list__title">Available Documents</h3>
			<ul class="doc-list__list doc-list__list--with-icon">
				<div *ngIf="case?.document_for_download?.length == 0 && case?.final_reports?.length == 0">No
					document to show</div>
				<li *ngFor="let document of case?.document_for_download">
					<a href="#" (click)="downloadFile(document.id, document.document_name, $event)"
						*ngIf="document.document_type != null"
						[title]="(document.updated_at | amLocal | amDateFormat: 'HH:mm') +'\n'+(document.user && (document.user.full_name))">
						<span class="icon icon--document-file"></span>{{document.document_type}} -
						{{document.document_name}} - {{document.created_at | date: 'MM/dd/yyyy'}}
					</a>
				</li>
				<li *ngFor="let document of case?.final_reports">
					<a href="#" (click)="downloadFile(document.id, document.document_name, $event)"
						*ngIf="document.upload_stage === 'CONSULTATION_SUMMARY'"
						[title]="(document.updated_at | amLocal | amDateFormat: 'HH:mm') +'\n'+(document.user && (document.user.full_name))">
						<span class="icon icon--document-file"></span>{{document.document_type}} -
						{{document.document_name}} - {{document.created_at | date: 'MM/dd/yyyy'}}
					</a>
				</li>
			</ul>
		</div>
	</div>
</div>
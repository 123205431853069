<app-dialog></app-dialog>
<div class="overlay__wrapper" [ngStyle]="{height: isLoading ? '100vh':'100%', overflow:isLoading ? 'hidden':'auto'}"
    [class.overlay--visible]="isLoading">
    <div class="overlay__content" [class.overlay--visible]="isLoading">
        <div class="overlay__spinner">
            <span class="icon icon--loading"></span>
        </div>
    </div>
    <div class="header">
        <div class="header__top-nav">
            <div class="top-menu">
                <div class="top-menu__user-stat" *ngIf="!isSFLogin()">
                    <a href="javascript:void(0)" class="go-back" (click)="navigateToOnePager()"
                        *ngIf="role == 'careteam' || role == 'admin' || role == 'mdmanager' || role == 'cmmanager' || role =='casecoordinator'">
                        <span class="icon icon--time-nav"></span>
                    </a>
                    <span>{{caseDetails?.patient.user.full_name}}</span>
                </div>
                <div class="top-menu__case-status">
                    <div class="case-status">
                        <div class="case-status__label" *ngIf="containsWhitelistStatus()" >Status:</div>
                        <ul class="case-status__list" *ngIf="role === 'admin' || role === 'careteam' || role === 'mdmanager' || role === 'cmmanager' || role =='casecoordinator'">
                            <ng-container *ngFor="let status of caseDetails?.status">
                                <li *ngIf="isWhitelisted(status.status_id)"
                                    (click)="setShowStatus(status.status.status, status.state)">
                                    <div class="case-icon">
                                        <span class="icon" [class.icon--orange-star]="status.state === 'INPROGRESS'"
                                            [class.icon--green-tick]="status.state === 'FINISHED'"
                                            [class.icon--black-star]="status.state === 'NOT STARTED'">
                                        </span>
                                    </div>
                                    <div class="case-type">{{getDisplayLabel(status.status.status)}}</div>
                                </li>
                            </ng-container>
                        </ul>
                        <ul class="case-status__list" *ngIf="role !== 'admin' && role !== 'careteam' && role !== 'mdmanager' && role !== 'cmmanager' && role !== 'casecoordinator' ">
                            <li *ngFor="let status of refinedStatus">
                                <div class="case-icon">
                                    <span class="icon" [class.icon--orange-star]="status.state === 'INPROGRESS'"
                                        [class.icon--green-tick]="status.state === 'FINISHED'"
                                        [class.icon--black-star]="status.state === 'NOT STARTED'">
                                    </span>
                                </div>
                                <div class="case-type">{{getDisplayLabel(status.status.status)}}</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="top-menu__case-button">
                    <button class="btn btn--blue btn--mini" [swal]="revertStageSwal" *ngIf="role == 'admin' && showStatus === 'Summary' && isCaseAccepted && caseDetails.case_type === 'WRITTEN CONSULTATION'">
                        Revert To CompileMR
                    </button>
               <swal  #revertStageSwal title="Alert"  closeOnConfirm="true" closeOnCancel="true" icon="warning" iconColor="orange" (confirm)="revertToCompileMR(caseDetails.id)" backdrop="#eef3f6"  cancelButtonColor="#db161e" reverseButtons="true" showCancelButton="true" confirmButtonColor="green" confirmButtonText="Revert">
                        <swal-text *swalPortal>Are you sure you want to revert case back to compileMR ?</swal-text>
                     </swal>

                </div>

                <div *ngIf="caseDetails?.case_status?.toLowerCase() !== 'finished'; else finishStatus">
                    <div *ngIf="caseDetails?.case_status.toLowerCase() !== 'cancelled'; else cancelStatus">
                        <a href="#" class="btn btn--brown btn--mini" (click)="cancelCase($event)" *ngIf='!isMdManager'
                            [ngClass]="{'btn--disabled': isAllyRef}">Cancel Case</a>
                    </div>
                    <ng-template #cancelStatus>
                        <div>
                            <p class="btn btn--brown btn--mini">Cancelled Case</p>
                        </div>
                    </ng-template>
                </div>
                <ng-template #finishStatus>
                    <div class="top-menu__case-button">
                        <p class="btn btn--brown btn--mini">Finished Case</p>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="showStatus?.toLowerCase() == ''">
        <div class='overlay--visible'>
            <div class="overlay__content">
                <div class="overlay__spinner">
                    <span class="icon icon--loading"></span>
                </div>
            </div>
        </div>
    </div>

    <div class="wrapper--dash-content dash-content overlay__wrapper">

        <div class="row" *ngIf="showStatus?.toLowerCase() != ''">
            <div class="col col__md-8 gutter--right-2x" *ngIf="!isEditEnabled">
                <div class="row" *ngIf="showStatus.toLowerCase() === 'requested'">
                    <app-case-requested [caseDetail]="caseDetails" [isMdManager]="isMdManager"
                        (editDocumentEmitter)="editDocuments($event)" [final_reports]="final_reports"
                        [translated_document]="translated_document" [document_for_download]="document_for_download"
                        (caseEmitter)="setEmittedCase($event)" (assigneeEmitter)="callGetAssignes($event)"
                        (showStatusEmitter)="setShowStatus($event)" [cmManagerList]="cmManagerList" [followUpNumber]="followUpNumber"></app-case-requested>
                </div>

                <div class="row"
                    *ngIf="showStatus?.toLowerCase() === 'mdmgmt' || (showStatus.toLowerCase() === 'all' &&  isStatusSelected('mdmgmt'))">
                    <app-md-management [caseDetail]="caseDetails" [final_reports]="final_reports" [isMdManager]="isMdManager"
                        [translated_document]="translated_document" [document_for_download]="document_for_download"
                        (caseEmitter)="setEmittedCase($event)" (showStatusEmitter)="setShowStatus($event)" [followUpNumber]="followUpNumber">
                    </app-md-management>
                </div>

                <div class="row"
                    *ngIf="showStatus?.toLowerCase() === 'translatedmr' || (showStatus.toLowerCase() === 'all' &&  isStatusSelected('translatedmr'))">
                    <translate-mr [caseDetail]="caseDetails" [final_reports]="final_reports" [isMdManager]="isMdManager"
                        [translated_document]="translated_document" [document_for_download]="document_for_download"
                        [canContinue]="canContinue" [isInProgress]="isInProgress" (caseEmitter)="setEmittedCase($event)"
                        (showStatusEmitter)="setShowStatus($event)"
                        (intakeFormDocumentEmitter)="intakePdfEmitter($event)"></translate-mr>
                </div>

                <div class="row"
                    *ngIf="showStatus?.toLowerCase() === 'written' || (showStatus.toLowerCase() === 'all' &&  isStatusSelected('written'))">
                    <app-written-case [caseDetail]="caseDetails" [final_reports]="final_reports"
                        [translated_document]="translated_document" [document_for_download]="document_for_download" [followUpNumber]="followUpNumber">
                    </app-written-case>
                </div>

                <div class="row"
                    *ngIf="showStatus?.toLowerCase() === 'translatewr' || (showStatus.toLowerCase() === 'all' &&  isStatusSelected('translatewr'))">
                    <translate-wr-dr [caseDetail]="caseDetails" [final_reports]="final_reports" [isMdManager]="isMdManager"
                        [translated_document]="translated_document" [document_for_download]="document_for_download"
                        [isInProgress]="isInProgress" [page]="'translate-wr'" (caseEmitter)="setEmittedCase($event)"
                        (showStatusEmitter)="setShowStatus($event)"></translate-wr-dr>
                </div>

                <div class="row"
                    *ngIf="showStatus?.toLowerCase() === 'schedule' || (showStatus.toLowerCase() === 'all' &&  isStatusSelected('schedule'))">
                    <schedule [caseDetail]="caseDetails" [final_reports]="final_reports" [isMdManager]="isMdManager"
                        [translated_document]="translated_document" [document_for_download]="document_for_download"
                        [isInProgress]="isInProgress" [canContinue]="canContinue" (caseEmitter)="setEmittedCase($event)"
                        (showStatusEmitter)="setShowStatus($event)"></schedule>
                </div>

                <div class="row"
                    *ngIf="showStatus?.toLowerCase() === 'view' || (showStatus.toLowerCase() === 'all' &&  isStatusSelected('view'))">
                    <app-video-case [caseDetail]="caseDetails" [final_reports]="final_reports" [isMdManager]="isMdManager"
                        [translated_document]="translated_document" [document_for_download]="document_for_download"
                        [canContinue]="canContinue" (caseEmitter)="setEmittedCase($event)"
                        (showStatusEmitter)="setShowStatus($event)"></app-video-case>
                </div>

                <div class="row"
                    *ngIf="showStatus?.toLowerCase() === 'summary' || (showStatus.toLowerCase() === 'all' &&  isStatusSelected('summary'))">
                    <app-review-case [caseDetail]="caseDetails" [final_reports]="final_reports" [isMdManager]="isMdManager"
                        [translated_document]="translated_document" [document_for_download]="document_for_download"
                        [caseMedications]="caseMedications" (caseEmitter)="setEmittedCase($event)"
                        (refreshHistory)="callConstructHisotry($event)" (showStatusEmitter)="setShowStatus($event)" [followUpNumber]="followUpNumber">
                    </app-review-case>
                </div>

                <div class="row"
                    *ngIf="showStatus?.toLowerCase() === 'translatedr' || (showStatus.toLowerCase() === 'all' &&  isStatusSelected('translatedr'))">
                    <translate-wr-dr [caseDetail]="caseDetails" [final_reports]="final_reports"
                        [translated_document]="translated_document" [document_for_download]="document_for_download"
                        [canContinue]="canContinue" [isInProgress]="isInProgress" [page]="'translate-dr'"
                        (caseEmitter)="setEmittedCase($event)" (showStatusEmitter)="setShowStatus($event)">
                    </translate-wr-dr>
                </div>

                <div class="row"
                    *ngIf="showStatus?.toLowerCase() === 'report' || (showStatus.toLowerCase() === 'all' &&  isStatusSelected('report'))">
                    <app-report-case [caseDetail]="caseDetails" [final_reports]="final_reports" [isMdManager]="isMdManager"
                        [translated_document]="translated_document" [document_for_download]="document_for_download"
                        [canContinue]="canContinue" (caseEmitter)="setEmittedCase($event)"
                        (showStatusEmitter)="setShowStatus($event)"></app-report-case>
                </div>

                <div *ngIf="(role == 'partner') || (role == 'careteam') || (role == 'admin') || (role == 'mdmanager') || (role == 'cmmanager') || (role == 'casecoordinator')">
                    <app-tracking-lines [trackingList]="trackingList" [isMRComplete]='isMRComplete' [isMdManager]="isMdManager" [role]="role" [dateMrComplete]="caseDetails?.date_mr_complete" 
                        (onMRComplete)='onMRComplete($event)' [caseStatus]="caseDetails?.case_status" [caseId]="caseDetails.id" (deleteDocument)="deleteDocument($event)" [trackingList]="trackingList" 
                        [trackingline_upload]="trackingline_upload" (updateCaseDocuments)="updateCaseDocuments($event)"></app-tracking-lines>
                </div>

                <div *ngIf="showStatus?.toLowerCase() != ''">
                    <app-casehistory [caseDetail]="caseDetails" [isMdManager]="isMdManager" [cmManagerList]="cmManagerList"
                        [caseStatusData]="caseStatusData" [activityChanges]="activities" #historyComponent>
                    </app-casehistory>
                </div>
            </div>

            <!-- When edit enabled -->
            <div class="col col__md-8 gutter--right-3x" *ngIf="isEditEnabled">
                <div class="box box--white box--radius box--margin-bottom-x box--pad-2x overlay__wrapper"
                    [class.overlay--visible]="isInProgress">
                    <div class="overlay__content">
                        <div class="overlay__spinner">
                            <span class="icon icon--loading"></span>
                        </div>
                    </div>
                    <h3 class="form__title" *ngIf="documentUploadType==='uploads'">Edit Uploaded Relevant Case
                        Documentation
                    </h3>
                    <h3 class="form__title" *ngIf="documentUploadType==='compiled'">Edit Compiled Relevant Case
                        Documentation
                    </h3>

                    <div class="text text--small text--dark-grey text--bold">Upload Relevant Document</div>
                    <div class="row">
                        <div class="col col__md-4 gutter--right-2x">
                            <div class="form__row">
                                <label for="" class="form__label">Type</label>
                                <div class="form__field">
                                    <select class="text--black" #documentType>
                                        <ng-container *ngFor="let docType of docTypeList">
                                            <option [value]="docType.value"
                                                *ngIf="documentUploadType==='uploads' && docType.value !== 'CONSULTATION SUMMARY TRANSLATED'">
                                                {{docType.key}}</option>
                                            <option [value]="docType.value"
                                                *ngIf="documentUploadType==='compiled' && (docType.value !== 'CONSULTATION SUMMARY TRANSLATED' || (docType.value === 'CONSULTATION SUMMARY TRANSLATED' && caseDetails?.final_reports?.length > 0))">
                                                {{docType.key}}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col col__md-6 gutter--right-2x">
                            <div class="form__row">
                                <label for="" class="form__label">Upload Document</label>

                                <div class="form__field upload-btn-file">

                                    <div class="form__field upload">
                                        <input type="file" class="btn btn--grey btn--small" name="document" #document
                                            ng2FileSelect [uploader]="uploader" />
                                        <a class="btn btn--grey btn--small btn--full-width" href="#">Browse</a>
                                    </div>
                                    <div class="uploaded-file">
                                        {{document.value == null || document.value === '' ? 'No File Choosen' :
                                        document?.files?.item(0)?.name}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col col__md-2">
                            <app-generic-button [class]="'btn--full-width btn-upload-doc margin--top-22'"
                                (onClick)="uploadUploadSectionFile(documentType, document, uploader, 'uploads')"
                                *ngIf="(role == 'partner') || ( (role == 'careteam' || role == 'admin' || role == 'patient' || role == 'cmmanager' || role == 'casecoordinator') && documentUploadType==='uploads' )">Upload</app-generic-button>
                            <app-generic-button [class]="'btn--full-width btn-upload-doc margin--top-22'"
                                (onClick)="uploadUploadSectionFile(documentType, document, uploader, 'translated')"
                                *ngIf="(role == 'careteam' || role == 'admin' || role == 'cmmanager' || role == 'casecoordinator') && documentUploadType==='compiled'">Upload</app-generic-button>
                        </div>
                    </div>

                    <div class="btns__row text-right" *ngIf="role == 'partner' || role == 'patient'">
                        <a [href]="caseDetails?.patient?.user?.user_partners[0]?.partner?.scan_link" [target]="'_blank'"
                            class="btn btn--blue margin-top-2x">Add Scans</a>
                    </div>

                    <div class="row" *ngIf="role == 'careteam' || role == 'admin' || role == 'cmmanager' || role == 'casecoordinator' ">
                        <div class="col col__md-4 gutter--right-2x">
                            <label for="" class="form__label">Scan Name</label>
                            <div class="form__field">
                                <input type="text" name="document_name" #documentName />
                                <div class="error">{{errors && errors.scanName}} </div>
                            </div>
                        </div>
                        <div class="col col__md-6 gutter--right-2x">
                            <div class="form__row">
                                <label for=" " class="form__label">Scan Link</label>
                                <div class="form__field">
                                    <input type="text" name="location" #documentLocation />
                                    <div class="error">{{errors && errors.scanLink}} </div>
                                </div>
                            </div>
                        </div>

                        <div class="col col__md-2">
                            <app-generic-button *ngIf="documentUploadType==='uploads'"
                                [class]="'btn--full-width btn-upload-doc margin--top-22'"
                                [class.is-loading]="isLinkUploading"
                                (onClick)="editAddScan(documentName, documentLocation, 'uploads')">
                                <span class="icon icon--loading "
                                    *ngIf="isLinkUploading && documentUploadType==='uploads'"></span> {{isLinkUploading
                                ? '': 'Add Scan'}}
                            </app-generic-button>
                            <app-generic-button *ngIf="documentUploadType==='compiled'"
                                [class]="'btn--full-width btn-upload-doc margin--top-22'"
                                [class.is-loading]="isLinkUploading"
                                (onClick)="editAddScan(documentName, documentLocation, 'compiled')">
                                <span class="icon icon--loading " *ngIf="isLinkUploading"></span> {{isLinkUploading ?
                                '': 'Add Scan'}}
                            </app-generic-button>
                        </div>
                    </div>

                    <!-- Video Link here -->
                    <div class="row" *ngIf="role == 'careteam' || role == 'admin' || role == 'mdmanager' || role == 'cmmanager' || role == 'casecoordinator' ">
                        <div class="col col__md-4 gutter--right-2x">
                            <label for="" class="form__label">Name</label>
                            <div class="form__field">
                                <input type="text" name="video_name" #videoName />
                                <div class="error">{{errors && errors.name}} </div>
                            </div>
                        </div>
                        <div class="col col__md-6 gutter--right-2x">
                            <div class="form__row">
                                <label for=" " class="form__label">Link</label>
                                <div class="form__field">
                                    <input type="text" name="video_location" #videoLocation />
                                    <div class="error"> {{errors && errors.link}} </div>
                                </div>
                            </div>
                        </div>

                        <div class="col col__md-2">
                            <app-generic-button *ngIf="documentUploadType==='uploads'"
                                [class]="'btn--full-width btn-upload-doc margin--top-22 video-link-button'"
                                [class.is-loading]="isVideoLinkUploading"
                                (onClick)="addVideoLink(videoName, videoLocation, 'uploads')">
                                <span class="icon icon--loading "
                                    *ngIf="isVideoLinkUploading && documentUploadType==='uploads'"></span>
                                {{isVideoLinkUploading ? '': 'Add Link'}}
                            </app-generic-button>
                            <app-generic-button *ngIf="documentUploadType==='compiled'"
                                [class]="'btn--full-width btn-upload-doc margin--top-22 video-link-button'"
                                [class.is-loading]="isVideoLinkUploading"
                                (onClick)="addVideoLink(videoName, videoLocation, 'compiled')">
                                <span class="icon icon--loading " *ngIf="isVideoLinkUploading"></span>
                                {{isVideoLinkUploading ? '': 'Add Link'}}
                            </app-generic-button>
                        </div>
                    </div>

                    <div class="text text--small text--dark-grey text--bold"> Uploads </div>
                    <div class="text text--small text--dark-grey margin--bottom-2x"
                        *ngIf="caseDetails?.document_for_download?.length < 1">
                        No document </div>
                    <div class="cloud-upload">
                        <div class="cloud-upload__block" *ngFor="let file of caseDetails?.document_for_download">
                            <div class="contact-details__details" *ngIf="file?.trackingDetail?.tl_number">
                                <div class="badge badge-secondary">#{{file.trackingDetail.tl_number}}</div>
                            </div>
                            <!-- for file -->
                            <div class="upload-doc cloud-progress" *ngIf="file?.document_type != null">
                                <div class="icon icon--uploaded-file"></div>
                                <div>
                                    <div class="text upload-doc__file-name">{{ getUploadsFileName(file) + ' - ' + (file.updated_at | amLocal | amDateFormat: 'MM/DD/YYYY') }}</div>
                                    <div class="cloud-upload__type">
                                        <div class="file-type-select form__field fix-full-width">
                                            <select [disabled]="role === 'patient'" [ngModel]="file?.document_type"
                                                #docType (change)="changeDocType(docType, file, 'uploads')"
                                                (focus)="dropFocus(doctypeSelect, $event)" #doctypeSelect>
                                                <ng-container *ngFor="let option of docTypeList;">
                                                    <option [value]="option.value"
                                                        *ngIf="option.value !== 'CONSULTATION SUMMARY TRANSLATED'">
                                                        {{option.key}}</option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="text text--small text--dark-grey">
                                        {{file.updated_at | amLocal | amDateFormat: 'MM/DD/YYYY HH:mm'}}</div>
                                    <div *ngIf="file.user && file.user.full_name"
                                        class="text text--small text--dark-grey">
                                        {{(file.user.first_name + ' ' + file.user.middle_name + ' ' +
                                        file.user.last_name)}}</div>
                                    <div class="text text--small text--dark-grey">
                                        {{getReadableFileSizeString(file.size * 1024)}}</div>
                                </div>
                                <div class="cloud-upload__btns">
                                    <a class="btn btn--blue btn--small margin--bottom-x" href="#"
                                        (click)="downloadDoc(file.id, file.document_name, $event)">Download</a>
                                    <a *ngIf="checkCanEditOrDelete(role, file.created_by)"
                                        class="btn btn--red btn--small margin--bottom-x" href="#"
                                        (click)="deleteDocument(file, $event)"> Delete</a>
                                    <button *ngIf="role === 'careteam' || role === 'cmmanager' || role === 'casecoordinator' || role === 'admin' " [class.btn--grey]="checkDisable(file)"
                                        class="btn btn--blue btn--small margin--bottom-x" href="#"
                                        (click)="cloneUploadedDocument(file, $event)">{{ checkDisable(file) ? 'Cloned' :
                                        'Clone' }}</button>
                                </div>
                            </div>

                            <!-- for links -->
                            <div class="upload-doc cloud-progress" *ngIf="file.document_type == null">
                                <div class="contact-details__details" *ngIf="file?.trackingDetail?.tl_number">
                                    <div class="badge badge-secondary">#{{file.trackingDetail.tl_number}}</div>
                                </div>
                                <div class="icon icon--uploaded-link"></div>
                                <div class="form__row" *ngIf="file.editEnabled && file.editEnabled == true">
                                    <div class="form__field">
                                        <input [(ngModel)]="file.modifyScanName" />
                                    </div>
                                </div>

                                <div *ngIf="!file.editEnabled || file.editEnabled == false" class="text">
                                    {{file.document_name}}</div>
                                <div class="form__row" *ngIf="file.editEnabled && file.editEnabled == true">
                                    <div class="form__field">
                                        <input [(ngModel)]="file.modifyLink" />
                                    </div>
                                </div>

                                <div *ngIf="!file.editEnabled || file.editEnabled == false">
                                    <a [href]="file.location" [target]="'_blank'">
                                        <div class="text">{{file.location}}</div>
                                    </a>
                                    <a *ngIf="checkCanEditOrDelete(role, file.created_by)" href="javascript:void(0)"
                                        (click)="enableLinkEdit($event, file)"
                                        class="btn btn--grey btn--mini margin--top-s">Edit</a>
                                </div>
                                <div class="cloud-upload__btns" *ngIf="!file.editEnabled || file.editEnabled == false">
                                    <a *ngIf="checkCanEditOrDelete(role, file.created_by)"
                                        class="btn btn--red btn--small margin--bottom-x margin--top-x" href="#"
                                        (click)="deleteDocument(file, $event)">Delete</a>
                                </div>
                                <div class="cloud-upload__btns" *ngIf="file.editEnabled && file.editEnabled == true">
                                    <a class="btn btn--red btn--small margin--bottom-x" href="#"
                                        (click)="file.editEnabled = false">Cancel</a>
                                    <a class="btn btn--blue btn--small margin--bottom-x" href="#"
                                        (click)="saveLinkEdit(file, $event)"
                                        *ngIf="file.editEnabled && file.editEnabled == true">Done</a>
                                </div>
                            </div>
                        </div>

                        <div *ngFor="let uploads of uploadsSectionUploader; let i = index">
                            <div class="cloud-upload__block" *ngIf="uploads.progress < 100">
                                <div class="upload-doc cloud-progress">
                                    <div class="icon icon--upload-cloud"></div>
                                    <div class="upload-row no-pad margin--bottom-2x">
                                        <div class="upload-row__file-name">
                                            <div class="status-row">
                                                <div class="status-row__bar-status">
                                                    <div class="bar-status">
                                                        <div class="bar-status__count"
                                                            [style.width.%]="uploads.progress">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="text upload-doc__file-name">Uploading
                                            {{uploads?.queue[0].file?.name}} ... </div>
                                        <div class="text text--small text--dark-grey">{{uploads?.progress}}% of
                                            {{getReadableFileSizeString(uploads?.queue[0]?.file?.size)}} Uploaded</div>
                                    </div>
                                    <a class="btn btn--red btn--small margin--bottom-x" href="#"
                                        (click)="cancelUpload(uploads, i, 'uploads', $event)">Cancel</a>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- Viewable only to admin and careteam -->
                    <div class="text text--small text--dark-grey  text--bold"
                        *ngIf="role.toLowerCase() === 'admin' || role.toLowerCase() === 'careteam' || role.toLowerCase() == 'mdmanager' || role.toLowerCase() == 'cmmanager' || role.toLowerCase() === 'casecoordinator'">
                        Compiled Document </div>
                    <div class="text text--small text--dark-grey margin--bottom-2x"
                        *ngIf="(caseDetails?.translated_document?.length < 1 && caseDetails?.translatedFinalReport?.length < 1) && (role.toLowerCase() === 'admin' || role.toLowerCase() === 'careteam' || role.toLowerCase() === 'casecoordinator' || role.toLowerCase() === 'cmmanager')">
                        No document </div>
                    <div class="cloud-upload"
                        *ngIf="role.toLowerCase() === 'admin' || role.toLowerCase() === 'careteam' || role.toLowerCase() === 'cmmanager' || role.toLowerCase() === 'casecoordinator'">
                        <div class="cloud-upload__block" *ngFor="let file of caseDetails?.translated_document">
                            <div class="contact-details__details" *ngIf="file?.trackingDetail?.tl_number">
                                <div class="badge badge-secondary">#{{file.trackingDetail.tl_number}}</div>
                            </div>
                            <!-- for file -->
                            <div class="upload-doc cloud-progress" *ngIf="file.document_type != null">
                                <div class="icon icon--uploaded-file"></div>
                                <div>
                                    <div class="text upload-doc__file-name">{{file.document_name}}</div>
                                    <div class="cloud-upload__type">
                                        <!-- <div class="file-type-label">Type</div> -->
                                        <div class="file-type-select form__field fix-full-width">
                                            <select [ngModel]="file.document_type" #docType
                                                (change)="changeDocType(docType, file, 'compiled')">
                                                <ng-container *ngFor="let option of docTypeList">
                                                    <option [value]="option.value"
                                                        *ngIf="option.value !== 'CONSULTATION SUMMARY TRANSLATED' || (option.value === 'CONSULTATION SUMMARY TRANSLATED' && caseDetails?.final_reports?.length > 0)">
                                                        {{option.key}}</option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="text text--small text--dark-grey">
                                        {{file.updated_at | amLocal | amDateFormat: 'MM/DD/YYYY HH:mm'}}</div>
                                    <div *ngIf="file.user && file.user.full_name"
                                        class="text text--small text--dark-grey">
                                        {{(file.user.first_name + ' ' + file.user.middle_name + ' ' +
                                        file.user.last_name)}}</div>
                                    <div class="text text--small text--dark-grey">
                                        {{getReadableFileSizeString(file.size * 1024)}}</div>
                                </div>
                                <div class="cloud-upload__btns">
                                    <a class="btn btn--blue btn--small margin--bottom-x" href="#"
                                        (click)="downloadDoc(file.id, file.document_name, $event)">Download</a>
                                    <a class="btn btn--red btn--small margin--bottom-x" href="#"
                                        (click)="deleteDocument(file, $event)">Delete</a>
                                </div>
                            </div>

                            <!-- for links -->
                            <div class="upload-doc cloud-progress" *ngIf="file.document_type == null">
                                <div class="icon icon--uploaded-link"></div>
                                <div class="form__row" *ngIf="file.editEnabled && file.editEnabled == true">
                                    <div class="form__field">
                                        <input [(ngModel)]="file.modifyScanName" />
                                    </div>
                                </div>

                                <div *ngIf="!file.editEnabled || file.editEnabled == false" class="text">
                                    {{file.document_name}}</div>
                                <div class="form__row" *ngIf="file.editEnabled && file.editEnabled == true">
                                    <div class="form__field">
                                        <input [(ngModel)]="file.modifyLink" />
                                    </div>
                                </div>

                                <div *ngIf="!file.editEnabled || file.editEnabled == false">
                                    <a href="#" [href]="file.location" [target]="'_blank'">
                                        <div class="text">{{file.location}}</div>
                                    </a>
                                    <a href="javascript:void(0)" (click)="enableLinkEdit($event, file)"
                                        class="btn btn--grey btn--mini margin--top-s">Edit</a>
                                </div>
                                <div class="cloud-upload__btns" *ngIf="!file.editEnabled || file.editEnabled == false">
                                    <a class="btn btn--red btn--small margin--bottom-x margin--top-x" href="#"
                                        (click)="deleteDocument(file, $event)">Delete</a>
                                </div>
                                <div class="cloud-upload__btns" *ngIf="file.editEnabled && file.editEnabled == true">
                                    <a class="btn btn--red btn--small margin--bottom-x" href="#"
                                        (click)="file.editEnabled = false">Cancel</a>
                                    <a class="btn btn--blue btn--small margin--bottom-x" href="#"
                                        (click)="saveLinkEdit(file, $event)"
                                        *ngIf="file.editEnabled && file.editEnabled == true">Done</a>
                                </div>
                            </div>

                        </div>

                        <div class="cloud-upload__block" *ngFor="let file of caseDetails?.translatedFinalReport">
                            <!-- for file -->
                            <div class="upload-doc cloud-progress"
                                *ngIf="file.document_type === 'CONSULTATION SUMMARY TRANSLATED'">
                                <div class="icon icon--uploaded-file"></div>
                                <div>
                                    <div class="text upload-doc__file-name">{{file.document_name}}</div>
                                    <div class="cloud-upload__type">
                                        <!-- <div class="file-type-label">Type</div> -->
                                        <div class="file-type-select form__field">
                                            <select [ngModel]="file.document_type" #docType
                                                (change)="changeDocType(docType, file, 'uploads')">
                                                <option [value]="option.value" *ngFor="let option of docTypeList">
                                                    {{option.key}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="text text--small text--dark-grey">
                                        {{getReadableFileSizeString(file.size * 1024)}}</div>
                                </div>
                                <div class="cloud-upload__btns">
                                    <a class="btn btn--blue btn--small margin--bottom-x" href="#"
                                        (click)="downloadDoc(file.id, file.document_name, $event)">Download</a>
                                    <a class="btn btn--red btn--small margin--bottom-x" href="#"
                                        (click)="deleteDocument(file, $event)">Delete</a>
                                </div>
                            </div>
                        </div>

                        <div *ngFor="let uploads of translatedSectionUploader; let i = index">
                            <div class="cloud-upload__block" *ngIf="uploads.progress < 100">
                                <div class="upload-doc cloud-progress">
                                    <div class="icon icon--upload-cloud"></div>
                                    <div class="upload-row no-pad margin--bottom-2x">
                                        <div class="upload-row__file-name">
                                            <div class="status-row">
                                                <div class="status-row__bar-status">
                                                    <div class="bar-status">
                                                        <div class="bar-status__count"
                                                            [style.width.%]="uploads.progress">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="text">Uploading {{uploads?.queue[0].file?.name}} ... </div>
                                        <div class="text text--small text--dark-grey">{{uploads?.progress}}% of
                                            {{getReadableFileSizeString(uploads?.queue[0]?.file?.size)}} Uploaded</div>
                                    </div>
                                    <a class="btn btn--red btn--small margin--bottom-x" href="#"
                                        (click)="cancelUpload(uploads, i, 'translated', $event)">Cancel</a>
                                </div>
                            </div>
                        </div>

                    </div>


                    <!-- Video Link Display -->
                    <div class="text text--small text--dark-grey  text--bold"
                        *ngIf="role.toLowerCase() === 'admin' || role.toLowerCase() === 'careteam' || role.toLowerCase() === 'cmmanager' || role.toLowerCase() === 'casecoordinator'">
                        Links
                    </div>
                    <div class="text text--small text--dark-grey margin--bottom-2x"
                        *ngIf="( (caseDetails?.videoLinkList?.length < 1) && (role.toLowerCase() === 'admin' || role.toLowerCase() === 'careteam' || role.toLowerCase() === 'casecoordinator' || role.toLowerCase() === 'mdmanager' || role.toLowerCase() === 'cmmanager') )">
                        No Links
                    </div>
                    <div class="cloud-upload"
                        *ngIf="role.toLowerCase() === 'admin' || role.toLowerCase() === 'careteam' || role.toLowerCase() === 'cmmanager' || role.toLowerCase() === 'casecoordinator'">
                        <div class="cloud-upload__block" *ngFor="let videoLink of caseDetails?.videoLinkList">
                            <div class="upload-doc cloud-progress" *ngIf="videoLink.document_type == 'VIDEO SUMMARY'">
                                <div class="video-icon icon--link--blue--lg m-auto mb-1"></div>
                                <div class="form__row" *ngIf="videoLink.editEnabled && videoLink.editEnabled == true">
                                    <div class="form__field">
                                        <input [(ngModel)]="videoLink.modifyVideoName" />
                                    </div>
                                </div>
                                <div class="text" *ngIf="!videoLink.editEnabled || videoLink.editEnabled == false">
                                    {{videoLink.document_name}}
                                </div>
                                <div class="form__row" *ngIf="videoLink.editEnabled && videoLink.editEnabled == true">
                                    <div class="form__field">
                                        <input [(ngModel)]="videoLink.modifyVideoLink" />
                                    </div>
                                </div>

                                <div *ngIf="!videoLink.editEnabled || videoLink.editEnabled == false">
                                    <a [href]="videoLink.location" [target]="'_blank'">
                                        <div class="text">{{videoLink.location}}</div>
                                    </a>
                                    <a href="javascript:void(0)" class="btn btn--grey btn--mini margin--top-s"
                                        (click)="editEnableVideoLink($event, videoLink)">Edit</a>
                                </div>
                                <div class="cloud-upload__btns"
                                    *ngIf="!videoLink.editEnabled || videoLink.editEnabled == false">
                                    <a class="btn btn--red btn--small margin--bottom-x margin--top-x" href="#"
                                        (click)="deleteVideoLink($event, videoLink)">Delete</a>
                                </div>
                                <div class="cloud-upload__btns"
                                    *ngIf="videoLink.editEnabled && videoLink.editEnabled == true">
                                    <a class="btn btn--red btn--small margin--bottom-x" href="#"
                                        (click)="videoLink.editEnabled = false">Cancel</a>
                                    <a class="btn btn--blue btn--small margin--bottom-x" href="#"
                                        (click)="saveVideoLinkEdit(videoLink, $event)"
                                        *ngIf="videoLink.editEnabled && videoLink.editEnabled == true">Done</a>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div class="btns__row text-right">
                        <app-generic-button (onClick)="completeDocEdit()">Done</app-generic-button>
                    </div>
                </div>
            </div>

            <div class="col col__md-4 case-sidebar">
                <div class="box box--white box--radius box--margin-bottom-x box--pad-2x overlay__wrapper"
                    [class.overlay--visible]="isInProgress">
                    <div class="overlay__content">
                        <div class="overlay__spinner">
                            <span class="icon icon--loading"></span>
                        </div>
                    </div>
                    <div class="contact-details no-border no-pad no-mar">
                        <div class="contact-details__row">
                            <div class="contact-details__label label-is-heading">Case Details  <span [innerHTML]="tagElement"></span></div>
                            <div class="contact-details__details" *ngIf="hasFollowUp">
                                <div class="text text--follow-up">+{{followUpNumber}} followup</div>
                            </div>
                        </div>
                        <div class="contact-details__row" *ngIf="role != 'patient'">
                            <div class="contact-details__label">Name
                                <i class="fa fa-info-circle tooltip-box top0 user-detail-tool"
                                    *ngIf="caseDetails?.patient?.user?.allyUserRef"> <span
                                        class="tooltip-box-text text-center topm9">{{caseDetails?.patient?.user?.allyUserRef}}</span></i>
                            </div>
                            <div class="contact-details__details">{{caseDetails?.patient?.user?.full_name}}
                            </div>
                        </div>
                        <div class="contact-details__row" *ngIf="role != 'patient'">
                            <div class="contact-details__label">Client</div>
                            <div class="contact-details__details">
                                {{caseDetails?.patient?.user?.user_partners[0]?.partner?.partner_name}}
                            </div>
                        </div>
                        <div class="contact-details__row">
                            <div class="contact-details__label">Assigned MD</div>
                            <div class="contact-details__details">
                                <ul>
                                    <li [class.submit-success]="doctor.is_submitted"
                                        *ngFor="let doctor of assignedMdList; let i=index">{{doctor?.user?.full_name}}
                                        <div *ngIf="assignedMdList?.length > 0 && i==0 && isMDT">(Champion)</div>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div class="contact-details__row" *ngIf="this.caseDetails.case_type.toLowerCase() === 'video consultation'">
                            <div class="contact-details__label">Scheduled Time</div>
                            <div class="contact-details__details">
                                {{caseDetails?.schedule?.consultation_date_time | date: "MM/dd/yyyy hh:mm a"}}
                            </div>
                        </div>
                        <div class="contact-details__row" *ngIf="role === 'careteam' || role === 'casecoordinator' ">
                            <div class="contact-details__label">Doctor Preferences</div>
                            <div class="contact-details__details">
                                {{caseDetails?.case_md_management?.user?.doctor_info?.availability |
                                enumDisplay:'doctor_availability'}}</div>
                        </div>
                        <div class="contact-details__row--single" *ngIf="role === 'careteam' || role === 'casecoordinator' ">
                            <div class="contact-details__label">Notes</div>
                            <div class="text--dark-grey">
                                {{caseDetails?.case_md_management?.user?.doctor_info?.note == null? "N/A" :
                                caseDetails?.case_md_management?.user?.doctor_info?.note}}
                            </div>
                        </div>
                        <br />
                        <div class="hr hr--space-down-x hr--space-up-x"></div>
                        <div class="contact-details__row" *ngIf="parentAllyCaseNumber">
                            <div class="contact-details__label">Parent Ally Case Number</div>
                            <div class="contact-details__details " (click)="copyText(parentAllyCaseNumber)">
                                <a class="badge badge-secondary copytip"
                                    id="allyCaseReference">{{parentAllyCaseNumber}} <i class="fa fa-clipboard "
                                        aria-hidden="true">
                                        <span class="copytiptext">{{CopyText}}</span> </i>
                                </a>
                            </div>
                        </div>
                        <div class="contact-details__row" *ngIf="parentAllyCaseRef">
                            <div class="contact-details__label">Parent Ally Case Ref</div>
                            <div class="contact-details__details " (click)="copyText(parentAllyCaseRef)">
                                <a class="badge badge-secondary copytip"
                                    id="allyCaseRef">{{parentAllyCaseRef}} <i class="fa fa-clipboard "
                                        aria-hidden="true">
                                        <span class="copytiptext">{{CopyText}}</span> </i>
                                </a>
                            </div>
                        </div>
                        <div class="contact-details__row" *ngIf="caseDetails?.allyCaseNumber">
                            <div class="contact-details__label">Ally Case Number</div>
                            <div class="contact-details__details " (click)="copyText(caseDetails?.allyCaseNumber)">
                                <a class="badge badge-secondary copytip"
                                    id="allyCaseReference">{{caseDetails?.allyCaseNumber == null ? 'N/A' :
                                    (caseDetails?.allyCaseNumber.length>15)? (caseDetails?.allyCaseNumber |
                                    slice:0:15)+'...':(caseDetails?.allyCaseNumber) }} <i class="fa fa-clipboard "
                                        aria-hidden="true">
                                        <span class="copytiptext">{{CopyText}}</span> </i>
                                </a>
                            </div>
                        </div>
                        <div class="contact-details__row" *ngIf="caseDetails?.allyCaseRef && role.toLowerCase() === 'admin'">
                            <div class="contact-details__label">Ally Case Ref</div>
                            <div class="contact-details__details " (click)="copyText(caseDetails?.allyCaseRef)">
                                <a class="badge badge-secondary copytip"
                                    id="allyCaseRef">{{caseDetails?.allyCaseRef == null ? 'N/A' :
                                    (caseDetails?.allyCaseRef.length>15)? (caseDetails?.allyCaseRef |
                                    slice:0:15)+'...':(caseDetails?.allyCaseRef) }} <i class="fa fa-clipboard "
                                        aria-hidden="true">
                                        <span class="copytiptext">{{CopyText}}</span> </i>
                                </a>
                            </div>
                        </div>
                        <div class="contact-details__row">
                            <div class="contact-details__label">Case Identifier</div>
                            <div class="contact-details__details">
                                <a class="badge badge-secondary">{{caseDetails?.case_identifier}}</a>
                            </div>
                        </div>
                        <div class="contact-details__row">
                            <div class="contact-details__label">Case Type</div>
                            <div class="contact-details__details">
                                <a class="badge badge-secondary">{{caseDetails?.case_type}}</a>
                            </div>
                        </div>
                        <div class="contact-details__row"
                            *ngIf="(role.toLowerCase() != 'patient' && role.toLowerCase() != 'doctor')">
                            <div class="contact-details__label">Case Priority</div>
                            <div class="contact-details__details" *ngIf="editVIP; else partnerView">
                                <div class="form__field">
                                    <label class="switch">
                                        <input type="checkbox" [disabled]="isReadOnly()" (change)="onVIPChanged(vipCheckBox)"
                                            [checked]="isVIP" #vipCheckBox />
                                        <div class="slider round">
                                            <span class="on">VIP</span>
                                            <span class="off">Normal</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <ng-template #partnerView>
                                <div class="contact-details__details">
                                    <a
                                        [ngClass]="{'badge' : isVIP, 'badge-secondary' : isVIP, 'badge-text-grey' : !isVIP}">{{isVIP
                                        ? 'VIP' : 'Normal'}}</a>
                                </div>
                            </ng-template>
                        </div>
                        <div class="contact-details__row" *ngIf="role != 'patient'">
                            <div class="contact-details__label">Submitted By</div>
                            <div class="contact-details__details">
                                <a class="badge badge-secondary" *ngIf="caseDetails?.user?.user_type?.type === 'Partner'">Client</a>
                                <a class="badge badge-secondary" *ngIf="caseDetails?.user?.user_type?.type === 'SystemAccount'">{{caseDetails?.user?.first_name}}</a>
                                <a class="badge badge-secondary" *ngIf="caseDetails?.user?.user_type?.type !== 'Partner' && caseDetails?.user?.user_type?.type !== 'SystemAccount'">{{caseDetails?.user?.user_type?.type}}</a>
                            </div>
                        </div>
                        <div class="hr hr--space-down-x hr--space-up-x" *ngIf="role != 'patient'"></div>
                        <div class="contact-details__row" *ngIf="role != 'patient'">
                            <div class="contact-details__label">Gender</div>
                            <div class="contact-details__details">
                                {{caseDetails?.patient?.gender == "F" ? "Female" : caseDetails?.patient?.gender == "M" ?
                                "Male" : "Unspecified" }}</div>
                        </div>
                        <div class="contact-details__row" *ngIf="role != 'patient'">
                            <div class="contact-details__label">DOB</div>
                            <div class="contact-details__details">{{caseDetails?.patient?.dob | date}}</div>
                        </div>
                        <div class="contact-details__row" *ngIf="role != 'patient'">
                            <div class="contact-details__label">Email</div>
                            <div class="contact-details__details">{{caseDetails?.patient?.user?.email}}</div>
                        </div>
                        <div class="contact-details__row" *ngIf="caseDetails?.patient?.user?.is_deleted">
                            <div class="contact-details__details text--bold text--red">This patient has been deleted
                                from the system</div>
                        </div>
                        <div class="contact-details__row" *ngIf="role != 'patient'">
                            <div class="contact-details__label">WeChatID</div>
                            <div *ngFor="let contact of caseDetails?.patient?.user?.contacts">
                                <div class="contact-details__details"
                                    *ngIf="contact.social_type.social_type.toLowerCase() === 'wechat'">
                                    {{contact.social_id}}
                                </div>
                            </div>
                            <div class="contact-details__details" *ngIf="!hasWeChatID(caseDetails)">
                                N/A
                            </div>
                        </div>
                        <div class="contact-details__row--single" *ngIf="role != 'patient'">
                            <div class="contact-details__label">
                                Member Request
                            </div>
                            <div class="text--dark-grey">
                                {{caseDetails?.case_patient_note == null? "N/A" : caseDetails?.case_patient_note}}
                            </div>
                        </div>

                        <div class="hr hr--space-down-x hr--space-up-x"></div>
                        <div class="contact-details__row--single">
                            <div class="contact-details__label contact-details__label--flex between__flex">
                                <div class="is__heading__custom">Communication Details</div>
                                <app-generic-button 
                                    (onClick)="onCommunicationRecordEdit()"
                                    *ngIf="(role.toLowerCase()==='careteam' || role.toLowerCase()==='cmmanager' || role.toLowerCase() == 'partner' || role.toLowerCase()==='casecoordinator') && !isCommunicationRecordEdit"
                                    [class]="'btn btn--mini'">
                                    Edit
                                </app-generic-button>
                                <app-generic-button
                                    (onClick)="saveCommunicationData()" 
                                    *ngIf="isCommunicationRecordEdit"
                                    [class]="'btn btn--blue btn--mini'">
                                    Done
                                </app-generic-button>
                            </div>
                        </div>

                        <form [formGroup]="communicationDetailForm">
                            <div class="contact-details__row">
                                <div class="contact-details__label">Preferred Communication Mode</div>
                                <div class="contact-details__details" *ngIf="!isCommunicationRecordEdit">{{
                                    caseDetails?.preferred_communication_mode | enumDisplay:'communication_mode'}}</div>
                                <div class="contact-details__details" *ngIf="isCommunicationRecordEdit">
                                    <div class="form__field">
                                        <select class="validation" [class.validation--is-invalid]="communicationDetailForm.get('preferred_communication_mode').touched 
                                        && communicationDetailForm.get('preferred_communication_mode').invalid"
                                            formControlName="preferred_communication_mode">
                                            <option value="">Select</option>
                                            <option value="EMAIL">Email</option>
                                            <option value="PHONE">Phone</option>
                                            <option value="EMAIL_PHONE">Email And Phone</option>
                                        </select>
                                    </div>
                                    <div class="message message--is-invalid"
                                        *ngIf="communicationDetailForm.get('preferred_communication_mode').touched && communicationDetailForm.get('preferred_communication_mode').invalid">
                                        <div>This field is required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="contact-details__row" *ngIf="showCommunicationTime">
                                <div class="contact-details__label">Best Communication Time</div>
                                <div class="contact-details__details" *ngIf="!isCommunicationRecordEdit">{{
                                    caseDetails?.best_communication_time | enumDisplay:'communication_time' }}</div>
                                <div class="contact-details__details" *ngIf="isCommunicationRecordEdit">
                                    <div class="form__field">
                                        <select class="validation" [class.validation--is-invalid]="communicationDetailForm.get('best_communication_time').touched 
                                        && communicationDetailForm.get('best_communication_time').invalid"
                                            formControlName="best_communication_time">
                                            <option value="">Select</option>
                                            <option value="MORNING">Morning</option>
                                            <option value="AFTERNOON">Afternoon</option>
                                            <option value="EVENING">Evening</option>
                                            <option value="ANYTIME">Anytime</option>
                                        </select>
                                    </div>
                                    <div class="message message--is-invalid"
                                        *ngIf="communicationDetailForm.get('best_communication_time').touched && communicationDetailForm.get('best_communication_time').invalid">
                                        <div>This field is required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="contact-details__row" *ngIf="showCommunicationTime">
                                <div class="contact-details__label"> Phone Number</div>
                                <div class="contact-details__details" *ngIf="!isCommunicationRecordEdit">{{
                                    caseDetails?.phone_number }}</div>
                                <div class="contact-details__details" *ngIf="isCommunicationRecordEdit">
                                    <div class="form__field">
                                        <input type="number" placeholder="Phone Number"
                                            formControlName="phone_number" />
                                    </div>
                                    <div class="message message--is-invalid"
                                        *ngIf="communicationDetailForm.get('phone_number').touched && communicationDetailForm.get('phone_number').invalid">
                                        <div>This field is required.</div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="hr hr--space-down-x hr--space-up-x"></div>
                        <div class="contact-details__row--single">
                            <div class="contact-details__label contact-details__label--flex between__flex">
                                <div class="is__heading__custom">Medical Data</div>
                                <app-generic-button
                                    (onClick)="onMedicalRecordEdit()"
                                    *ngIf="(role.toLowerCase()==='careteam' || role.toLowerCase()==='cmmanager' || role.toLowerCase()==='casecoordinator') && !isMedicalRecordEdit && caseDetails?.case_status==='REQUESTED'"
                                    [class]="'btn btn--grey btn--mini'" [disabled]="true">Edit</app-generic-button>
                                <app-generic-button 
                                    (onClick)="saveMedicalData()" 
                                    *ngIf="isMedicalRecordEdit"
                                    [class]="'btn btn--grey btn--mini'" [disabled]="true">
                                    Done
                                </app-generic-button>
                            </div>
                        </div>



                        <form [formGroup]="medicalForm">

                            <div class="contact-details__row">
                                <div class="contact-details__label">Specialty Program</div>
                                <div class="contact-details__details" *ngIf="!isMedicalRecordEdit">
                                    {{caseDetails?.speciality_program == null? "N/A" : caseDetails?.speciality_program}}
                                </div>
                                <div class="contact-details__details" *ngIf="isMedicalRecordEdit">
                                    <div class="form__field">
                                        <input class="validation" [class.validation--is-invalid]="medicalForm.get('speciality_program').touched 
                                                && medicalForm.get('speciality_program').invalid"
                                            formControlName="speciality_program" />

                                    </div>
                                    <div *ngIf="medicalForm.get('speciality_program').touched 
                                                && medicalForm.get('speciality_program').invalid"
                                        class="message message--is-invalid">
                                        <div *ngIf="medicalForm.get('speciality_program').errors.required">This field is
                                            required.
                                        </div>
                                        <div *ngIf="medicalForm.get('speciality_program').errors.maxlength">This field
                                            must not contain more than
                                            {{medicalForm.get('speciality_program').errors.maxlength.requiredLength}}
                                            characters.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="contact-details__row--single bottomSpace15">
                                <div class="contact-details__label">Diagnosis</div>
                                <div class="text--dark-grey" *ngIf="!isMedicalRecordEdit" [innerHtml]="caseDetails?.patient_diagnosis">
                                </div>
                                <div class="contact-details__details" *ngIf="isMedicalRecordEdit">
                                    <div class="form__field">
                                        <textarea class="validation" [class.validation--is-invalid]="medicalForm.get('patient_diagnosis').touched 
                                                && medicalForm.get('patient_diagnosis').invalid"
                                            formControlName="patient_diagnosis"> </textarea>

                                    </div>
                                    <div *ngIf="medicalForm.get('patient_diagnosis').touched 
                                                && medicalForm.get('patient_diagnosis').invalid"
                                        class="message message--is-invalid">
                                        <div *ngIf="medicalForm.get('patient_diagnosis').errors.required">This field is
                                            required.
                                        </div>
                                        <div *ngIf="medicalForm.get('patient_diagnosis').errors.maxlength">This field
                                            must not contain more than
                                            {{medicalForm.get('patient_diagnosis').errors.maxlength.requiredLength}}
                                            characters.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="contact-details__row">
                                <div class="contact-details__label">Category</div>
                                <div class="contact-details__details" *ngIf="!isMedicalRecordEdit">
                                    {{caseDetails?.category?.category || caseDetails?.category_value}}</div>
                                <div class="contact-details__details" *ngIf="isMedicalRecordEdit">
                                    <div class="form__field" *ngIf="caseDetails?.category?.category">
                                        <select class="validation" [class.validation--is-invalid]="medicalForm.get('category_id').touched 
                                        && medicalForm.get('category_id').invalid" formControlName="category_id"
                                            (change)="onCategorySelect(category.selectedIndex)" #category>
                                            <option *ngFor="let category of categories; let i = index"
                                                [ngValue]="category.category_id">{{category.category}}</option>
                                        </select>
                                    </div>
                                    <div class="form__field" *ngIf="caseDetails?.category_value">
                                        <input type="text" class="validation" formControlName="category_value">
                                    </div>
                                    <div *ngIf="medicalForm.get('category_id').touched 
                                        && medicalForm.get('category_id').invalid" class="message message--is-invalid">
                                        <div>This field is required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="contact-details__row">
                                <!-- Category Fields changed to Conditions -->
                                <div class="contact-details__label">Conditions</div>
                                <div class="contact-details__details" *ngIf="!isMedicalRecordEdit">
                                    {{caseDetails?.category_field?.category_field || caseDetails?.category_field_value}}</div>
                                <div class="contact-details__details" *ngIf="isMedicalRecordEdit">
                                    <div class="form__field">
                                        <div class="form__field" *ngIf="caseDetails?.category_field?.category_field">
                                            <select class="validation" [class.validation--is-invalid]="medicalForm.get('category_field_id').touched 
                                            && medicalForm.get('category_field_id').invalid"
                                                formControlName="category_field_id" #categoryFields>
                                                <option *ngFor="let field of category_fields; let i = index"
                                                    [ngValue]="field.category_field_id">{{field.category_field}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form__field" *ngIf="caseDetails?.category_field_value">
                                            <input type="text" class="validation" formControlName="category_field_value">
                                        </div>
                                        <div *ngIf="medicalForm.get('category_field_id').touched 
                                        && medicalForm.get('category_field_id').invalid"
                                            class="message message--is-invalid">
                                            <div>This field is required.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="contact-details__row">
                                <div class="contact-details__label">MD Type</div>
                                <div class="contact-details__details" *ngIf="!isMedicalRecordEdit">
                                    {{caseDetails?.md_type?.md_type || caseDetails?.md_type_value}}</div>
                                <div class="contact-details__details" *ngIf="isMedicalRecordEdit">
                                    <div class="form__field" *ngIf="caseDetails?.md_type?.md_type">
                                        <select class="validation" [class.validation--is-invalid]="medicalForm.get('md_type_id').touched 
                                        && medicalForm.get('md_type_id').invalid" formControlName="md_type_id">
                                            <option *ngFor="let md_type of md_types" [ngValue]="md_type.md_type_id">
                                                {{md_type.md_type}}</option>
                                        </select>
                                    </div>
                                    <div class="form__field" *ngIf="caseDetails?.md_type_value">
                                        <input type="text" class="validation" formControlName="md_type_value">
                                    </div>
                                    <div *ngIf="medicalForm.get('md_type_id').touched 
                                        && medicalForm.get('md_type_id').invalid" class="message message--is-invalid">
                                        <div>This field is required.</div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="hr hr--space-down-x hr--space-up-x" *ngIf="isPeerToPeer()"></div>

                        <div class="contact-details__row--single" *ngIf="isPeerToPeer()" >
                            <div class="contact-details__label contact-details__label--flex between__flex">
                                <div class="is__heading__custom">Peer to Peer Information</div>
                                <app-generic-button
                                    (onClick)="onPeerToPeerEdit()"  
                                    [class]="'btn btn--grey btn--mini'" [disabled]="true" *ngIf="false" >Edit</app-generic-button>
                                <app-generic-button 
                                    (onClick)="savePeerToPeerData()" 
                                    *ngIf="isPeerToPeerInfoEdit"
                                    [class]="'btn btn--grey btn--mini'" [disabled]="true" >
                                    Done
                                </app-generic-button>
                            </div>
                        </div>

                        <form [formGroup]="peerToPeerForm" *ngIf="isPeerToPeer()">

                            <div class="contact-details__row">
                                <div class="contact-details__label">Code</div>
                                <div class="contact-details__details" *ngIf="!isPeerToPeerInfoEdit">
                                    {{peerToPeerForm.get('code').value == null? "N/A" : peerToPeerForm.get('code').value}}
                                </div>
                                <div class="contact-details__details" *ngIf="isPeerToPeerInfoEdit">
                                    <div class="form__field">
                                        <input class="validation" [class.validation--is-invalid]="peerToPeerForm.get('code').touched 
                                                && peerToPeerForm.get('code').invalid"
                                            formControlName="code" />

                                    </div>
                                    <div *ngIf="peerToPeerForm.get('code').touched 
                                                && peerToPeerForm.get('code').invalid"
                                        class="message message--is-invalid">
                                        <div *ngIf="peerToPeerForm.get('code').errors.required">This field is
                                            required.
                                        </div>
                                        <div *ngIf="peerToPeerForm.get('code').errors.maxlength">This field
                                            must not contain more than
                                            {{peerToPeerForm.get('code').errors.maxlength.requiredLength}}
                                            characters.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="contact-details__row">
                                <div class="contact-details__label">Description</div>
                                <div class="contact-details__details" *ngIf="!isPeerToPeerInfoEdit">
                                    {{peerToPeerForm.get('description').value == null? "N/A" : peerToPeerForm.get('description').value}}
                                </div>
                                <div class="contact-details__details" *ngIf="isPeerToPeerInfoEdit">
                                    <div class="form__field">
                                        <input class="validation" [class.validation--is-invalid]="peerToPeerForm.get('description').touched 
                                                && peerToPeerForm.get('description').invalid"
                                            formControlName="description" />

                                    </div>
                                    <div *ngIf="peerToPeerForm.get('description').touched 
                                                && peerToPeerForm.get('description').invalid"
                                        class="message message--is-invalid">
                                        <div *ngIf="peerToPeerForm.get('description').errors.required">This field is
                                            required.
                                        </div>
                                        <div *ngIf="peerToPeerForm.get('description').errors.maxlength">This field
                                            must not contain more than
                                            {{peerToPeerForm.get('description').errors.maxlength.requiredLength}}
                                            characters.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="contact-details__row">
                                <div class="contact-details__label">Case Status</div>
                                <div class="contact-details__details" *ngIf="!isPeerToPeerInfoEdit">
                                    {{peerToPeerForm.get('case_status').value == null? "N/A" : peerToPeerForm.get('case_status').value}}
                                </div>
                                <div class="contact-details__details" *ngIf="isPeerToPeerInfoEdit">
                                    <div class="form__field">
                                        <input class="validation" [class.validation--is-invalid]="peerToPeerForm.get('case_status').touched 
                                                && peerToPeerForm.get('case_status').invalid"
                                            formControlName="case_status" />

                                    </div>
                                    <div *ngIf="peerToPeerForm.get('case_status').touched 
                                                && peerToPeerForm.get('case_status').invalid"
                                        class="message message--is-invalid">
                                        <div *ngIf="peerToPeerForm.get('case_status').errors.required">This field is
                                            required.
                                        </div>
                                        <div *ngIf="peerToPeerForm.get('case_status').errors.maxlength">This field
                                            must not contain more than
                                            {{peerToPeerForm.get('case_status').errors.maxlength.requiredLength}}
                                            characters.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="contact-details__row">
                                <div class="contact-details__label">Case Type</div>
                                <div class="contact-details__details" *ngIf="!isPeerToPeerInfoEdit">
                                    {{peerToPeerForm.get('case_type').value == null? "N/A" : peerToPeerForm.get('case_type').value}}
                                </div>
                                <div class="contact-details__details" *ngIf="isPeerToPeerInfoEdit">
                                    <div class="form__field">
                                        <input class="validation" [class.validation--is-invalid]="peerToPeerForm.get('case_type').touched 
                                                && peerToPeerForm.get('case_type').invalid"
                                            formControlName="case_type" />

                                    </div>
                                    <div *ngIf="peerToPeerForm.get('case_type').touched 
                                                && peerToPeerForm.get('case_type').invalid"
                                        class="message message--is-invalid">
                                        <div *ngIf="peerToPeerForm.get('case_type').errors.required">This field is
                                            required.
                                        </div>
                                        <div *ngIf="peerToPeerForm.get('case_type').errors.maxlength">This field
                                            must not contain more than
                                            {{peerToPeerForm.get('case_type').errors.maxlength.requiredLength}}
                                            characters.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="contact-details__row">
                                <div class="contact-details__label">Treatment Start Date</div>
                                <div class="contact-details__details" *ngIf="!isPeerToPeerInfoEdit">
                                    {{peerToPeerForm.get('treatment_start_date').value == null? "N/A" : peerToPeerForm.get('treatment_start_date').value}}
                                </div>
                                <div class="contact-details__details" *ngIf="isPeerToPeerInfoEdit">
                                    <div class="form__field">
                                        <input class="validation" [class.validation--is-invalid]="peerToPeerForm.get('treatment_start_date').touched 
                                                && peerToPeerForm.get('treatment_start_date').invalid"
                                            formControlName="treatment_start_date" />

                                    </div>
                                    <div *ngIf="peerToPeerForm.get('treatment_start_date').touched 
                                                && peerToPeerForm.get('treatment_start_date').invalid"
                                        class="message message--is-invalid">
                                        <div *ngIf="peerToPeerForm.get('treatment_start_date').errors.required">This field is
                                            required.
                                        </div>
                                        <div *ngIf="peerToPeerForm.get('treatment_start_date').errors.maxlength">This field
                                            must not contain more than
                                            {{peerToPeerForm.get('treatment_start_date').errors.maxlength.requiredLength}}
                                            characters.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="contact-details__row">
                                <div class="contact-details__label">Treatment Regimen</div>
                                <div class="contact-details__details" *ngIf="!isPeerToPeerInfoEdit">
                                    {{peerToPeerForm.get('treatment_regimen').value == null? "N/A" : peerToPeerForm.get('treatment_regimen').value}}
                                </div>
                                <div class="contact-details__details" *ngIf="isPeerToPeerInfoEdit">
                                    <div class="form__field">
                                        <input class="validation" [class.validation--is-invalid]="peerToPeerForm.get('treatment_regimen').touched 
                                                && peerToPeerForm.get('treatment_regimen').invalid"
                                            formControlName="treatment_regimen" />

                                    </div>
                                    <div *ngIf="peerToPeerForm.get('treatment_regimen').touched 
                                                && peerToPeerForm.get('treatment_regimen').invalid"
                                        class="message message--is-invalid">
                                        <div *ngIf="peerToPeerForm.get('treatment_regimen').errors.required">This field is
                                            required.
                                        </div>
                                        <div *ngIf="peerToPeerForm.get('treatment_regimen').errors.maxlength">This field
                                            must not contain more than
                                            {{peerToPeerForm.get('treatment_regimen').errors.maxlength.requiredLength}}
                                            characters.
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>

                        <div class="hr hr--space-down-x hr--space-up-x" *ngIf="role != 'patient'"></div>

                        <div class="contact-details__row" *ngIf="role != 'patient'">
                            <div class="contact-details__label">Interpreter</div>
                            <div class="contact-details__details">
                                {{caseDetails?.interpreter_needed == true ? "Yes" : "No" }}</div>
                        </div>
                        <div class="contact-details__row" *ngIf="role != 'patient'">
                            <div class="contact-details__label">Summary Coordinator</div>
                            <div class="contact-details__details">{{caseDetails?.translator_needed == true ? "Yes" :
                                "No"}}
                            </div>
                        </div>
                        <div class="hr hr--space-down-x hr--space-up-x"
                            *ngIf="role!=='patient' || (role==='patient' && caseDetails?.case_status==='FINISHED')">
                        </div>
                        <div class="contact-details__row--single" *ngIf="showReport">
                            <div class="contact-details__label contact-details__label--flex" *ngIf="role!=='patient'">
                                <span>Uploads</span>
                                <a href="#"
                                    *ngIf="(role == 'admin' || role == 'careteam' || role == 'partner' || role == 'cmmanager' || role.toLowerCase()==='casecoordinator') && !isEditEnabled && (caseDetails?.case_status !== 'FINISHED' && caseDetails?.case_status !== 'CANCELLED' && caseDetails?.case_status !== 'HOLD')"
                                    (click)="editDocuments({ event: $event, uploads: 'uploads' })"
                                    [class.btn--grey]="isReadOnly()"
                                    [class.btn--blue]="!isReadOnly()"
                                    class="btn btn--blue btn--mini">Edit</a>
                            </div>
                            <ul class="doc-list__list doc-list__list--sidebar"
                                *ngIf="getCombinedDocuments(caseDetails?.document_for_download, caseDetails?.trackingline_upload).length > 0 && role!=='patient'">
                                <li *ngFor="let upload of getCombinedDocuments(caseDetails?.document_for_download, caseDetails?.trackingline_upload)">
                                    <span class="icon icon--uploaded-link" *ngIf="upload.document_type == null"></span>
                                    <span class="icon icon--uploaded-file" *ngIf="upload.document_type != null"></span>
                                    <a href="#" (click)="downloadDoc(upload.id, upload.document_name, $event)"
                                        class="file-name" *ngIf="upload.document_type != null"
                                        [title]="getUploadsTooltipText(upload)">
                                        {{getUploadsFileName(upload)+ ' - ' + (upload.updated_at | amLocal | amDateFormat: 'MM/DD/YYYY')}}</a>
                                    <a [href]="upload.location" [target]="'_blank'" class="file-name"
                                        *ngIf="upload.document_type == null">{{upload.document_name}}</a>
                                </li>
                            </ul>
                            <div class="margin--bottom-x text--dark-grey"
                                *ngIf="getCombinedDocuments(caseDetails?.document_for_download, caseDetails?.trackingline_upload).length < 1 && role!=='patient'">N/A</div>

                            <div class="contact-details__label contact-details__label--flex"
                                *ngIf="role!=='partner' && role!=='patient'">
                                <span>Compiled Documents</span>
                                <a href="#"
                                    *ngIf="(role == 'admin' || role == 'careteam' || role == 'partner' || role == 'cmmanager' || role.toLowerCase()==='casecoordinator') && !isEditEnabled && (caseDetails?.case_status !== 'FINISHED' && caseDetails?.case_status !== 'CANCELLED' && caseDetails?.case_status !== 'HOLD')"
                                    (click)="editDocuments({ event: $event, uploads: 'compiled' })"
                                    [class.btn--grey]="isReadOnly()"
                                    [class.btn--blue]="!isReadOnly()"
                                    class="btn btn--blue btn--mini">Edit</a>
                            </div>


                            <ul class="doc-list__list doc-list__list--sidebar"
                                *ngIf="(caseDetails?.translated_document?.length > 0) && role!=='partner' && role!=='patient'">
                                <li *ngFor="let upload of caseDetails?.translated_document">
                                    <span class="icon icon--uploaded-link" *ngIf="upload.document_type == null"></span>
                                    <span class="icon icon--uploaded-file" *ngIf="upload.document_type != null"></span>
                                    <a href="#" (click)="downloadDoc(upload.id,  upload.document_name, $event)"
                                        [title]="getUploadsTooltipText(upload)"
                                        class="file-name" *ngIf="upload.document_type != null"
                                        [title]="(upload.updated_at | amLocal | amDateFormat: 'HH:mm') +'\n'+(upload.user && (upload.user.first_name + ' ' + upload.user.middle_name + ' ' + upload.user.last_name))">
                                        {{upload.document_type + ' - ' + (upload.updated_at | amLocal | amDateFormat: 'MM/DD/YYYY')}}</a>
                                    <a [href]="upload.location" [target]="'_blank'" class="file-name"
                                        [title]="getUploadsTooltipText(upload)"
                                        *ngIf="upload.document_type == null">{{upload.document_name}}</a>
                                </li>
                            </ul>
                            
                            <div class="margin--bottom-x text--dark-grey"
                                *ngIf="(caseDetails?.translated_document?.length < 1) && role!=='partner' && role!=='patient'">
                                N/A</div>
                            <div class="contact-details__label" *ngIf="caseDetails?.final_reports?.length > 0 
                                && showReport && 
                                (
                                    (role==='admin' || role==='careteam' || role==='casecoordinator' || role==='mdmanager' || role==='cmmanager') || ((role==='partner' || role==='patient') 
                                    && 
                                    caseDetails?.case_status==='FINISHED')
                                )
                                ">Final Reports</div>
                            <ul class="doc-list__list doc-list__list--sidebar"
                                *ngIf="caseDetails?.final_reports?.length > 0 && showReport">
                                <div *ngFor="let upload of caseDetails?.final_reports">
                                    <li
                                        *ngIf="((role==='partner' || role==='patient') && caseDetails?.case_status==='FINISHED') || (role==='admin' || role==='mdmanager' || role==='cmmanager' || role==='careteam' || role === 'casecoordinator')">
                                        <div>
                                            <div *ngIf="upload.document_type != 'VIDEO SUMMARY'">
                                                <a href="#"
                                                    (click)="downloadDoc(upload.id,  upload.document_name, $event)"
                                                    class="file-name d-flex"><span
                                                        class="icon icon--uploaded-file"></span>
                                                    {{upload?.document_type}} {{ getFollowUpText(caseDetails?.case_identifier, upload?.document_name) }}</a>
                                                <div class="text text--grey text--report-date">Submitted on
                                                    {{upload.created_at | date:'MM/dd/yyyy'}}</div>
                                            </div>
                                            <div
                                                *ngIf="(upload.document_type == 'VIDEO SUMMARY') && (role==='admin' || role==='careteam' || role=== 'casecoordinator'||role==='mdmanager' || role==='cmmanager' || role==='patient' || role==='partner')">
                                                <a [href]="upload.location" target="_blank"
                                                    class="file-name d-flex ml-negative"><span
                                                        class="video-icon icon--link--blue--sm"></span>
                                                    {{upload.document_name}} </a>
                                                <div class="text text--grey text--report-date">Submitted on
                                                    {{upload.created_at | date:'MM/dd/yyyy'}}</div>

                                            </div>
                                        </div>
                                    </li>
                                </div>
                            </ul>
                            <!---  test report  -->
                            <div class="contact-details__label" *ngIf="caseDetails?.test_document?.length > 0 
                                && ((role==='admin' || role==='careteam' || role==='casecoordinator' || role==='mdmanager' || role==='cmmanager') || ((role==='partner' || role==='patient')))">                           
                                <div *ngIf="caseDetails?.test_document?.length > 0">
                                   <hr>
                                   Test Reports
                               </div>
                              </div>
                           
                               <ul class="doc-list__list doc-list__list--sidebar"  *ngIf="caseDetails?.test_document?.length > 0 ">
                                   <div *ngFor="let upload of caseDetails?.test_document">
                                      <li *ngIf="(role==='partner' || role==='patient' || role==='admin' || role==='mdmanager' || role==='cmmanager' || role==='careteam' || role === 'casecoordinator')">
                                   
                                   <div *ngIf="caseDetails.test_document> 0"></div>
                                       <div *ngIf="upload.document_type != 'VIDEO SUMMARY'">
                                           <a href="#"
                                               (click)="downloadDoc(upload.id,  upload.document_name, $event ,true)"
                                               class="file-name d-flex"><span
                                                   class="icon icon--uploaded-file"></span>
                                              {{caseDetails?.case_identifier}}-TEST</a>
                                           <div class="text text--grey text--report-date">Submitted on
                                               {{upload.created_at | date:'MM/dd/yyyy'}}</div>
                                       </div>
                                       <div
                                           *ngIf="(upload.document_type == 'VIDEO SUMMARY') && (role==='admin' || role==='careteam' || role=== 'casecoordinator'||role==='mdmanager' || role==='cmmanager' || role==='patient' || role==='partner')">
                                           <a [href]="upload.location" target="_blank"
                                               class="file-name d-flex ml-negative"><span
                                                   class="video-icon icon--link--blue--sm"></span>
                                               {{upload.document_name}} </a>
                                           <div class="text text--grey text--report-date">Submitted on
                                               {{upload.created_at | date:'MM/dd/yyyy'}}</div>
                                       </div>
                                   </div>
                                   </ul>
                            <!-- Video Consultation Link -->
                            <div class="contact-details__label" *ngIf="videoConsultationList?.length > 0">Video
                                Consultation</div>
                            <div *ngFor="let list of videoConsultationList; let i = index">
                                <ul *ngIf="videoConsultationList?.length > 0"
                                    class="doc-list__list doc-list__list--sidebar mt-2 mb-2">
                                    <li class="d-flex ml-negative">
                                        <span class="video-icon video-icon--record--blue"></span>
                                        <a href="#" (click)="openVideo($event, list)">Video Consultation {{ i + 1 }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="hr hr--space-down-x hr--space-up-x" *ngIf="role != 'patient' && (!caseDetails?.allyCaseRef && !caseDetails?.allyCaseNumber)"></div>
                        <div class="contact-details__row" *ngIf="role != 'patient' && (!caseDetails?.allyCaseRef && !caseDetails?.allyCaseNumber)">
                            <div class="contact-details__ label"></div>
                            <div class="contact-details__details">
                                <app-generic-button 
                                    (onClick)="editDates()"
                                    *ngIf="(role.toLowerCase()=='careteam' || role.toLowerCase()=='casecoordinator' || role.toLowerCase()=='admin' || role.toLowerCase()=='cmmanager') && !isDateEditEnabled && caseDetails.case_status != 'CANCELLED'"
                                    [class]="'btn btn--mini'">
                                    Edit
                                </app-generic-button>
                                <app-generic-button (onClick)="saveDate()"
                                    *ngIf="isDateEditEnabled && caseDetails.case_status != 'CANCELLED'"
                                    [class]="'btn btn--mini'">
                                    Done
                                </app-generic-button>
                            </div>
                        </div>
                        <!-- <div class="hr hr--space-down-x hr--space-up-x"></div> -->
                        <form [formGroup]="dateForm" *ngIf="role != 'patient' && (!caseDetails?.allyCaseRef && !caseDetails?.allyCaseNumber)">
                            <div class="contact-details__row"
                                *ngIf="(caseDetails.case_type.toLowerCase() == 'video consultation' || caseDetails.case_type.toLowerCase() == 'mdt video' || caseDetails.case_type.toLowerCase() == 'pathology review' || caseDetails.case_type.toLowerCase() == 'else') && (role != 'patient')">
                                <div class="contact-details__label">Earliest Available</div>

                                <div class="contact-details__details" *ngIf="!isDateEditEnabled">
                                    {{caseDetails?.earliest_available_date | date:'MM/dd/yyyy'}}
                                </div>
                            </div>
                            <div class="contact-details__row" *ngIf="(caseDetails.case_type.toLowerCase() == 'video consultation' || 
                            caseDetails.case_type.toLowerCase() == 'mdt video' || 
                            caseDetails.case_type.toLowerCase() == 'pathology review' || 
                            caseDetails.case_type.toLowerCase() == 'else') && role != 'patient' && isDateEditEnabled">
                                <div class="form__field">
                                    <div class="form__field--date" style="display: -webkit-box;">
                                        <input style="width:100% !important" type="date" [owlDateTime]="dt2"
                                            [value]="caseDetails?.earliest_available_date"
                                            (dateTimeChange)="onDateChange($event, dt2, earliestAvailableDate)"
                                            [min]="createdAtStartOfDay" (click)="preventDefault($event)" #dateInput />
                                        <span class="trigger  icon icon--calendar" [owlDateTimeTrigger]="dt2"></span>
                                        <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
                                    </div>
                                    <div *ngIf="dateForm.get('earliest_available_date').touched 
                                    && dateForm.get('earliest_available_date').invalid"
                                        class="message message--is-invalid">
                                        <div>This field is required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="contact-details__row" *ngIf="role != 'patient'">
                                <div class="contact-details__label">Date MR Complete</div>
                                <div class="contact-details__details" *ngIf="!isDateEditEnabled">
                                    {{caseDetails?.date_mr_complete | date: 'MM/dd/yyyy, hh:mm aaa'}}</div>
                            </div>
                            <div class="contact-details__row" *ngIf="isDateEditEnabled">
                                <div class="form__field">
                                    <div class="form__field--date" style="display: -webkit-box">
                                        <input style="width:100% !important" type="datetime-local" [owlDateTime]="dt3"
                                            [min]="createdAtDate" [max]="currentDateTime"
                                            (dateTimeChange)="setDateTime(dt3, dateMrComplete)" #dateMrComplete
                                            [value]="caseDetails?.date_mr_complete"
                                            (change)="setDateTime(dt3, dateMrComplete)" />
                                        <span class="trigger  icon icon--calendar" [owlDateTimeTrigger]="dt3"></span>
                                        <owl-date-time #dt3 [pickerMode]="'popup'" [hour12Timer]="true"></owl-date-time>
                                    </div>
                                    <div class="message message--is-invalid"
                                        *ngIf="dateForm.get('date_mr_complete').touched && dateForm.get('date_mr_complete').invalid">
                                        This field is required.
                                    </div>
                                    <div class="message message--is-invalid"
                                        *ngIf="dateForm.get('date_mr_complete').touched && !isValidDate">
                                        Please enter a valid date.
                                    </div>
                                </div>
                            </div>
                            <div class="contact-details__row" *ngIf="role != 'patient'">
                                <div class="contact-details__label">Importance</div>
                                <div class="contact-details__details">{{caseDetails?.importance}}</div>
                            </div>
                        </form>

                        <div class="hr hr--space-down-x hr--space-up-x"></div>
                        <div class="contact-details__row--single" *ngIf="role === 'patient'">
                            <div class="contact-details__label">
                                Notes
                            </div>
                            <div class="text--dark-grey">
                                {{caseDetails?.case_patient_note == null? "N/A" : caseDetails?.case_patient_note}}
                            </div>
                        </div>
                        <div class="contact-details__row--single" *ngIf="role != 'patient'">
                            <div class="contact-details__label">
                                Notes
                                <button href="#" (click)="onNotesEdit()"
                                    *ngIf="(role.toLowerCase()=='careteam' || role.toLowerCase()=='casecoordinator' || role.toLowerCase()=='admin' || role.toLowerCase()=='cmmanager') 
                                    && !editNotes && caseDetails.case_status != 'CANCELLED'
                                    && isSFLogin()"
                                    class="btn btn--blue btn--mini">Edit</button>
                            </div>
                            <div class="text--dark-grey" *ngIf="!editNotes; else notesForm">
                                {{caseDetails?.case_additional_note == null? "N/A" : caseDetails?.case_additional_note}}
                            </div>
                            <ng-template #notesForm>
                                <form class="contact-details__details" [formGroup]="noteForm">
                                    <div class="form__field">
                                        <textarea class="validation" [class.validation--is-invalid]="noteForm.get('case_additional_note').touched 
                                                        && noteForm.get('case_additional_note').invalid"
                                            formControlName="case_additional_note"> </textarea>

                                    </div>
                                    <div *ngIf="noteForm.get('case_additional_note').touched 
                                                        && noteForm.get('case_additional_note').invalid"
                                        class="message message--is-invalid">
                                        <div *ngIf="noteForm.get('case_additional_note').errors.required">This field is
                                            required.
                                        </div>
                                        <div *ngIf="noteForm.get('case_additional_note').errors.maxlength">This field
                                            must not contain more than
                                            {{noteForm.get('case_additional_note').errors.maxlength.requiredLength}}
                                            characters.
                                        </div>
                                    </div>
                                    <div class="form__field">
                                        <button class="btn btn--blue btn--save" (click)="onNoteSave(saveNote)"
                                            #saveNote>
                                            <span class="icon icon--loading-white"></span>
                                            <div class="label">Save</div>
                                        </button>
                                    </div>
                                </form>
                            </ng-template>
                        </div>

                        <div *ngIf="role.toLowerCase()=='careteam' || role.toLowerCase()=='casecoordinator' || role.toLowerCase()=='admin' || role.toLowerCase()=='mdmanager' || role.toLowerCase()=='cmmanager' || caseDetails?.patient?.user?.user_partners[0]?.partner.isElevatedPartner">
                            <div class="hr hr--space-down-x hr--space-up-x"></div>
                            <div class="contact-details__row--single">
                                <div class="contact-details__label">
                                    Careteam Notes
                                    <button href="#" (click)="onCareTeamNotesUpdate()"
                                        *ngIf="!updateCareTeamNotes && caseDetails.case_status != 'CANCELLED' 
                                        && !isMdManager
                                        && isSFLogin()"
                                        class="btn btn--blue btn--mini">{{caseDetails?.case_careteam_note == null? "Add"
                                        : "Edit"}}</button>
                                </div>
                                <div class="text--dark-grey" *ngIf="!updateCareTeamNotes; else careteamNotesForm">
                                    {{caseDetails?.case_careteam_note == null? "N/A" : caseDetails?.case_careteam_note}}
                                </div>
                                <ng-template #careteamNotesForm>
                                    <form class="contact-details__details" [formGroup]="careteamNoteForm">
                                        <div class="form__field">
                                            <textarea class="validation" [class.validation--is-invalid]="careteamNoteForm.get('case_careteam_note').touched 
                                                        && careteamNoteForm.get('case_careteam_note').invalid"
                                                formControlName="case_careteam_note"> </textarea>
                                        </div>
                                        <div *ngIf="careteamNoteForm.get('case_careteam_note').touched 
                                                        && careteamNoteForm.get('case_careteam_note').invalid"
                                            class="message message--is-invalid">
                                            <div *ngIf="careteamNoteForm.get('case_careteam_note').errors.maxlength">
                                                This field must not contain more than
                                                {{careteamNoteForm.get('case_careteam_note').errors.maxlength.requiredLength}}
                                                characters.
                                            </div>
                                        </div>
                                        <div class="form__field">
                                            <button class="btn btn--blue btn--save"
                                                (click)="onCareteamNoteSave(addNote)" #addNote>
                                                <span class="icon icon--loading-white"></span>
                                                <div class="label">Save</div>
                                            </button>
                                        </div>
                                    </form>
                                </ng-template>
                            </div>
                        </div>

                        <div *ngIf="caseDetails?.missing_information && (role.toLowerCase()=='careteam' || role.toLowerCase()=='admin' || role.toLowerCase()=='mdmanager' || role.toLowerCase()=='cmmanager' || caseDetails?.patient?.user?.user_partners[0]?.partner.isElevatedPartner)">
                            <div class="hr hr--space-down-x hr--space-up-x"></div>
                            <div class="contact-details__row--single">
                                <div class="contact-details__label">
                                    Missing Information
                                    <a *ngIf="role.toLowerCase()=='careteam' || role.toLowerCase()=='admin' || role.toLowerCase()=='cmmanager'" href="javascript:void(0)" class="mark-as-address" (click)="markAsAddress(caseDetails?.is_missing_info_addressed)"> {{ caseDetails?.is_missing_info_addressed? "Mark as Pending": "Mark as Completed"}}</a>
                                </div>
                                <div class="text--dark-grey" [ngClass]="caseDetails?.is_missing_info_addressed ? 'line-through' : ''">
                                   <p [innerHTML]="caseDetails?.missing_information ? caseDetails?.missing_information : 'N/A' "></p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="contact-details__btns"
                    *ngIf="(caseDetails?.case_status.toLowerCase() !== 'cancelled' && caseDetails?.case_status.toLowerCase() !== 'finished' && !isMdManager); else finishButtonBar">
                    <app-generic-button
                        [class]="'btn btn--blue'"
                        (onClick)="holdResumeCase('REQUESTED')"
                        *ngIf="caseDetails?.case_status == 'HOLD'">
                        Resume Case
                    </app-generic-button>
                    
                    <button class="btn btn--green" *ngIf="caseDetails.case_status.toLowerCase() == 'hold' "  [swal]="putOnHoldSwal">Put On Hold</button>
                    <swal #putOnHoldSwal title="Put Case on Hold" [inputValidator]="validReason()" backdrop="#eef3f6"
                    input="textarea" inputPlaceholder="Please let us know why you are putting this case on hold? " 
                        cancelButtonColor="#db161e" reverseButtons="true" showCancelButton="true" confirmButtonColor="green"
                        confirmButtonText="Continue" closeOnConfirm="true" closeOnCancel="true">
                        <div class="form__field" *swalPortal>
                            <form [formGroup]="frmHoldCase">
                                <swal-title>
                                    ID: {{caseDetails?.case_identifier}} <span class="badge badge-secondary ">Started</span></swal-title>
                                <swal-title>
                                    <hr>
                                </swal-title>
                    
                            </form>
                    
                        </div>
                    
                    
                    </swal>
                                     
                    <swal  #continueCase title="Confirmation"  closeOnConfirm="true" closeOnCancel="true" (confirm)="changeModalStatus('HOLD', frmHoldCase) " backdrop="#eef3f6"  cancelButtonColor="#db161e" reverseButtons="true" showCancelButton="true" confirmButtonColor="green" confirmButtonText="Continue">
                        <swal-text *swalPortal>Are you sure you want to proceed?</swal-text>
                     </swal>


                </div>
                <ng-template #finishButtonBar>
                    <div *ngIf="(role==='admin' || role==='careteam' || role==='cmmanager') && caseDetails?.case_status !== 'CANCELLED'"
                        class="contact-details__btns">
                        <button *ngIf="false" class="btn btn--green reopen" (click)="onReopenCase(reopenButton)" #reopenButton>
                            <span class="icon icon--loading"></span>
                            <div class="label">Re-open Case</div>
                        </button>
                    </div>
                </ng-template>
            </div>

        </div>

    </div>

    <div class="popup" hidden #CANCELLED>
        <div class="popup__overlay"></div>
        <div class="popup__container">
            <form [formGroup]="frmCancelCase">

                <div class="popup__header">
                    <h1>Cancel Case</h1>
                    <a href="#" class="popup__close" (click)="hideDiv('CANCELLED', $event)">
                        <span class="icon icon--cross"></span>
                    </a>
                </div>
                <div class="popup__content--with-bg">
                    <div class="text--small text--bold">
                        <span class="inline-block padding-r-2x">ID: {{caseDetails?.case_identifier}}</span>
                        <a href="#" class="link-btn text--uppercase">Started</a>
                    </div>
                </div>
                <div class="popup__content">
                    <div class="form__row">
                        <div class="form__label">Please let us know why you are canceling this request</div>
                        <div class="form__field">
                            <textarea formControlName="status_reason" [class.validation--is-invalid]="frmCancelCase.get('status_reason').touched 
                            && frmCancelCase.get('status_reason').invalid">Enter Reason</textarea>
                            <div *ngIf="frmCancelCase.get('status_reason').touched 
                            && frmCancelCase.get('status_reason').invalid" class="message message--is-invalid">
                                <div *ngIf="frmCancelCase.get('status_reason').errors.required">This field is required.
                                </div>
                                <div *ngIf="frmCancelCase.get('status_reason').errors.maxlength">This field must not
                                    contain more than
                                    {{frmCancelCase.get('status_reason').errors.maxlength.requiredLength}} characters.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="popup__footer popup__footer--centered">
                    <a href="#" class="btn btn--green" (click)="changeStatus('CANCELLED', $event, frmCancelCase)">Cancel
                        Case</a>
                </div>
            </form>
        </div>

    </div>


    <div class="popup" hidden #HOLD>
        <div class="popup__overlay"></div>
        <div class="popup__container">
            <form [formGroup]="frmHoldCase">
                <div class="popup__header">
                    <h1>Put Case on Hold</h1>
                    <a href="#" class="popup__close" (click)="hideDiv('HOLD', $event)">
                        <span class="icon icon--cross"></span>
                    </a>
                </div>
                <div class="popup__content--with-bg">
                    <div class="text--small text--bold">
                        <span class="inline-block padding-r-2x">ID: {{caseDetails?.case_identifier}}</span>
                        <a href="#" class="link-btn text--uppercase">Started</a>
                    </div>
                </div>
                <div class="popup__content">
                    <div class="form__row">
                        <div class="form__label">Please let us know why you are putting this case on hold</div>
                        <div class="form__field">
                            <textarea formControlName="status_reason" [class.validation--is-invalid]="frmHoldCase.get('status_reason').touched 
                            && frmHoldCase.get('status_reason').invalid">Enter Reason</textarea>
                            <div *ngIf="frmHoldCase.get('status_reason').touched 
                                && frmHoldCase.get('status_reason').invalid" class="message message--is-invalid">
                                <div *ngIf="frmHoldCase.get('status_reason').errors.required">This field is required.
                                </div>
                                <div *ngIf="frmHoldCase.get('status_reason').errors.maxlength">This field must not
                                    contain more than
                                    {{frmHoldCase.get('status_reason').errors.maxlength.requiredLength}} characters.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="popup__footer popup__footer--centered">
                    <a href="#" class="btn btn--green" (click)="changeStatus('HOLD', $event, frmHoldCase)">Continue</a>
                </div>
            </form>
        </div>

    </div>

    <div class="popup" hidden #REQUESTED>
        <div class="popup__overlay"></div>
        <div class="popup__container">
            <form [formGroup]="frmHoldCase">
                <div class="popup__header">
                    <h1>Resume Case</h1>
                    <a href="#" class="popup__close" (click)="hideDiv('REQUESTED', $event)">
                        <span class="icon icon--cross"></span>
                    </a>
                </div>
                <div class="popup__content--with-bg">
                    <div class="text--small text--bold">
                        <span class="inline-block padding-r-2x">ID: {{caseDetails?.case_identifier}}</span>
                        <a href="#" class="link-btn text--uppercase">Started</a>
                    </div>
                </div>
                <div class="popup__content">
                    <div class="form__row">
                        <div class="form__label">Please let us know why you are resuming this case</div>
                        <div class="form__field">
                            <textarea formControlName="status_reason">Enter Reason</textarea>
                            <div *ngIf="frmHoldCase.get('status_reason').touched 
                                && frmHoldCase.get('status_reason').invalid" class="message message--is-invalid">
                                <div *ngIf="frmHoldCase.get('status_reason').errors.required">This field is required.
                                </div>
                                <div *ngIf="frmHoldCase.get('status_reason').errors.maxlength">This field must not
                                    contain more than
                                    {{frmHoldCase.get('status_reason').errors.maxlength.requiredLength}} characters.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="popup__footer popup__footer--centered">
                    <a href="#" class="btn btn--green"
                        (click)="changeStatus('REQUESTED', $event, frmHoldCase)">Continue</a>
                </div>
            </form>
        </div>

    </div>


    <div class="popup" hidden #VIDEO>
        <div class="popup__overlay"></div>
        <div class="popup__container">
            <div class="popup__header">
                <h1>Video Consultation</h1>
                <a href="#" class="popup__close" (click)="hideDiv('VIDEO', $event)">
                    <span class="icon icon--cross"></span>
                </a>
            </div>
            <div class="popup__content">
                <div class="form__row">
                    <div class="form__field">
                        <video *ngIf="videoSrc && videoSrc !== null" [src]="videoSrc" width="100%" height="300"
                            controls></video>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

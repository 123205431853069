<div id="activity-container" [class]="activityService.getContainerColor(activity)">
  <div class="d-flex w-100 h-100 align-items-center" *ngIf="!activity.isComment;else messageView">
    <div class="profile-avatar">
      <img [src]="(images[activity?.user?.avatar])?'data:image/jpg;base64,'+(images[activity?.user?.avatar]): profilePicPath" alt="">
    </div>
    <div class="w-100 h-100">
      <p class="activity-title-light title text-bold"> {{activity.title}}</p>
      <p class="activity-title-light subtitle"[innerHTML]="activity.activity"></p>
      <div class="d-flex justify-content-between w-100">
        <p class="activity-title-light info">{{activity?.user?.full_name || 'Unknown'}} ({{activity?.user?.user_type?.type || 'Unknown'}})</p>
        <p class="activity-title-light info">{{ activity?.updated_at | date: 'MM/dd/yyyy, h:mm a' }}</p>
      </div>
    </div>
  </div>
  <ng-template #messageView>
    <div class="d-flex w-100 h-100">
      <div class="profile-avatar">
        <img [src]="(images[activity?.sender_user_id])?'data:image/jpg;base64,'+(images[activity.sender_user_id]): profilePicPath" alt="">
      </div>
      <div class="w-100 h-100">
        <div class="d-flex justify-content-between w-100">
          <p *ngIf="activity?.user_type.toLowerCase()!=='patient';else patientView" class="activity-title-light title text-bold"> {{activity?.sender.includes('(') ? activity.sender : (activity.sender | firstString)}} to
            {{activity?.getRevieverName(isElevatedPCT)}} <span *ngIf="activity?.forAlly" class="ally-badge">a</span> </p>
          <ng-template #patientView>
            <p class="activity-title-light title text-bold"> {{activity?.sender | firstString}}</p>
          </ng-template>
          <p class="activity-title-light info">{{ activity?.updated_at | date: 'MM/dd/yyyy, h:mm a' }}</p>
        </div>
        <p class="message message-title" *ngIf="activity?.title">{{ activity?.title}}</p>
        <div class="activity-title-light message" *ngIf="activity.comment.includes('<')" [innerHTML]="activity.comment | safeHtml"></div>
        <div class="activity-title-light message" *ngIf="!activity.comment.includes('<')" ><p>{{activity.comment}}</p></div>

        <button class="btn btn--small btn--grey"  [swal]="viewReplySwal" (click)="replyMessage()" *ngIf="activity?.chatter_identifier" >View Replies</button> 
        <swal #viewReplySwal confirmButtonColor="#DB161E" [swalOptions]="{showClass: { backdrop: '',popup: ''}}"  backdrop="#eef3f6" showCloseButton="false">
          <div *swalPortal>
            <b class="mt-2">Message Thread for {{activity?.title}} </b>
         
            <form [formGroup]="formGrp" class="mt-5">
              <div class="form__field">
                <div class="form__row">
                  <div class="d-flex title">
                    <label for="" class="form__label">Title</label>
                  </div>
                  <div class="form__field">
                    <input type="text" name="title" id="title" formControlName="title">
                    <!-- <div *ngIf="showError && frmAssignee.get('title').touched 
                                    && frmAssignee.get('title').invalid" class="message message--is-invalid"><div *ngIf="frmAssignee.get('title').errors.required">Title cannot be empty.</div></div> -->
                  </div>
                </div>
                <div class="form__row">
                  <div class="d-flex title">
                    <label for="" class="form__label">Message</label>
                  </div>
                  <div class="form__field">
                    <!-- <ckeditor formControlName="message" [readonly]="isMdManager" placeholder="Add your comment"
                            #ckeditorField></ckeditor> -->
                    <ckeditor placeholder="Add your comment" class="customheader" formControlName="message" #ckeditorField
                      [config]="{toolbar: []}"></ckeditor>
                    <!-- <div *ngIf="showError && frmAssignee.get('message').touched 
                            && frmAssignee.get('message').invalid" class="message message--is-invalid"><div *ngIf="frmAssignee.get('message').errors.maxlength">This field cannot exceed more than
                            {{frmAssignee.get('message').errors.maxlength.requiredLength}} characters.</div><div *ngIf="frmAssignee.get('message').errors.required">Comments cannot be empty.</div></div> -->
                  </div>
        
                  <button [disabled]="formGrp.invalid" class="btn btn--blue mt-4 btn-block"
                    (click)="replyToMessageThread(activity)">Reply</button>
                </div>
              </div>
            </form>
            <hr>
            <p *ngIf="messagesReplythread">Recent Messages</p>
            <p *ngIf="!messagesReplythread" class="mt-5 mb-3 text-center">
             <strong> No Recent Message available</strong>
            </p>
            <div *ngFor="let eachMessage of messagesReplythread">
              <div id="activity-container" [class]="activityService.getContainerColor(eachMessage)">
                <div class="form__field">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-2">
                        <div class="profile-avatar">
                          <img [src]="(images[eachMessage?.sender_user_id])?'data:image/jpg;base64,'+(images[eachMessage.sender_user_id]): profilePicPath" alt="">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <p *ngIf="eachMessage?.user_type.toLowerCase()!=='patient';else patientView"
                          class="activity-title-light title text-bold"> {{eachMessage?.sender.includes('(') ? eachMessage.sender
                          : (eachMessage.sender | firstString)}} to {{eachMessage?.getRevieverName(isElevatedPCT)}}
                          <br>
                          <span *ngIf="!eachMessage?.parent_message_id" class="ally-badge">Parent Message</span>
                          <span *ngIf="eachMessage?.forAlly" class="ally-badge">a</span>                    
                        </p>
        
                        <ng-template #patientView>
                          <p class="activity-title-light title text-bold"> {{eachMessage?.sender | firstString}}</p>
                        </ng-template>
                        <p class="message message-title" *ngIf="eachMessage?.title">{{ eachMessage?.title}}</p>
                          <ng-container *ngIf="eachMessage.comment.includes('<'); else showMessageTemplate">
                            <div class="activity-title-light message" [innerHTML]="eachMessage.comment | safeHtml"></div>
                          </ng-container>
                          <ng-template #showMessageTemplate>
                            <div class="activity-title-light message">{{eachMessage.comment}}</div>
                          </ng-template>                        
                      </div>
                      <div class="col-md-4">
                        <div class="activity-title-light message">{{ eachMessage?.updated_at | date: 'MM/dd/yyyy, h:mm a' }}</div>
                      </div>
        
                    </div>
                  </div>
                </div>
              </div>
              <hr>
            </div>
          </div>
          <span *swalPortal="swalTargets.confirmButton">
           
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-1">
                    <i  class="icon icon--time-nav"></i>  
                  </div>
                  <div class="col-md-10 mt-1">
                    Collapse Thread
                  </div>
              </div>
            </div>
            </span>
          </swal>

      </div>
    </div>
  </ng-template>
</div>
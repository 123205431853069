import { SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, Input, OnInit, Output , EventEmitter} from '@angular/core';
import { ActivityService } from '../../../services/activity.service';
import { LoginService } from '../../../services/login.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { USER_ROLE_TYPE } from '../../../constant';

@Component({
  selector: 'app-activitycard',
  templateUrl: './activitycard.component.html',
  styleUrls: ['./activitycard.component.css']
})
export class ActivitycardComponent implements OnInit {
  @Input() caseId;
  @Input() activity;
  @Input() images;
  @Input() isElevatedPCT;
  @Input() messageThread;
  formGrp: FormGroup;
  @Output() messageThreadObj = new EventEmitter<any>();
  @Output() resetMesssages = new EventEmitter<any>();
  type = 'STATUS';
  profilePicPath: string;
  messagesReplythread: any;
  role: string;
  hideTemplate =  true;
   toggleTemplate = true;
   constructor(
    public activityService: ActivityService,
    public loginService: LoginService,
    public router: Router,
    public readonly swalTargets: SwalPortalTargets
   ) { }

   ngOnInit(): void {
     this.profilePicPath = '/assets/images/profile-pic-male.png';
     this.formGrp = new FormGroup({
       message: new FormControl({value: ''}, [Validators.required, Validators.maxLength(50000)]),
       title: new FormControl('', [Validators.required]),
     });
     const user = this.loginService.getCurrentUser();
     this.role = user.user_type.type.toLowerCase();
   }

   replyMessage(): void {
     this.messagesReplythread = this.messageThread.filter(item => item.chatter_identifier === this.activity.chatter_identifier);
   }
   replyToMessageThread(parentActivity): void {

     const messageReplyPayload = {};
     const replyMessage = this.formGrp.get('message').value;
     const replyTitle = this.formGrp.get('title').value;
     messageReplyPayload['parent_activity'] = parentActivity;
     messageReplyPayload['reply_message'] = replyMessage;
     messageReplyPayload['reply_title'] = replyTitle;
     this.messageThreadObj.emit(messageReplyPayload);
     this.formGrp.reset();
     if(this.role === USER_ROLE_TYPE.DOCTOR.toLowerCase()){
       
       this.router.navigate(['/case/doctor-consultation/'+this.caseId]);
     }
     else{
       this.router.navigate(['/case/'+this.caseId]);
     }
   }
   closeDeleteBox(event): void {
     if (event) event.preventDefault();
     this.hideTemplate = true;
   }

   get message() {
     return this.formGrp.get('message') as FormControl;
   }
   get title() {
     return this.formGrp.get('message') as FormControl;
   }

}

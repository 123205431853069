import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent } from './tabs/tabs.component';
import { DataTablesModule } from 'angular-datatables/src/angular-datatables.module';
import { TreeviewModule } from 'ngx-treeview';
import { AppRoutingModule } from '../../routers/app-routing.module';
import { GeneralUserComponent } from '../../dashboard/users/generalUser/general.user.component';
import { DoctorComponent } from './doctor/doctor.component';
import { PatientComponent } from './patient/patient.component';
import { TranslatorComponent } from './translator/translator.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UserProfileComponent } from './profile/profile.component';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { SharedModule } from '../../shared/shared.module';
import { FileUploadModule } from 'ng2-file-upload';

import { AceEditorModule } from 'ng2-ace-editor';

import { DoctorRoleComponent } from './doctor-role/doctor-role.component';
import { ImdCareteamRoleComponent } from './imd-careteam-role/imd-careteam-role.component';
import { PartnerRoleComponent } from './partner-role/partner-role.component';
import { TranslatorRoleComponent } from './translator-role/translator-role.component';
import { PatientRoleComponent } from './patient-role/patient-role.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InterpreterRoleComponent } from './interpreter-role/interpreter-role.component';
import { AdminRoleComponent } from './admin-role/admin-role.component';
import { PatientDashboardComponent } from './patient/patient-dashboard/patient-dashboard.component';
import { EligibleMemberListComponent } from './eligible-member-list/eligible-member-list.component';
import { EligibleMemberComponent } from './eligible-member/eligible-member.component';
import { EligibleMemberProfileComponent } from './eligible-member-profile/eligible-member-profile.component';
import { MdManagerRoleComponent } from './md-manager-role/md-manager-role.component';
import { SystemAccountRoleComponent } from './system-account/system-account.component';
import { SystemAccountUpsertComponent } from './system-account/system-account-upsert/system-account-upsert';
import { CmManagerRoleComponent } from './cm-manager-role/cm-manager-role.component';
import { ClientDropdownTreeView } from './generalUser/client-dropdown/client-dropdown-tree-view';
import { InactiveUsersComponent } from './active-inactive/inactive-users/inactive-users.component';
import { ActiveUsersComponent } from './active-inactive/active-users/active-users.component';
import { CaseCorordinatorRoleComponent } from './case-corordinator-role/case-corordinator-role.component';
import { AssignedCasesListComponent } from './assigned-cases-list/assigned-cases-list.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ToastrModule } from 'ngx-toastr';
import { UserRoleComponent } from '../settings/user-role/user-role.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DataTablesModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserModule,
    FileUploadModule,
    BrowserAnimationsModule,
    NguiAutoCompleteModule,
    AceEditorModule,
    SweetAlert2Module,
    ToastrModule,
    TreeviewModule.forRoot()
  ],
  declarations: [
    TabsComponent,
    GeneralUserComponent,
    DoctorComponent,
    PatientComponent,
    TranslatorComponent,
    UserProfileComponent,
    DoctorRoleComponent,
    ImdCareteamRoleComponent,
    PartnerRoleComponent,
    TranslatorRoleComponent,
    PatientRoleComponent,
    InterpreterRoleComponent,
    AdminRoleComponent,
    PatientDashboardComponent,
    EligibleMemberListComponent,
    EligibleMemberComponent,
    EligibleMemberProfileComponent,
    MdManagerRoleComponent,
    SystemAccountRoleComponent,
    SystemAccountUpsertComponent,
    CmManagerRoleComponent,
    ClientDropdownTreeView,
    InactiveUsersComponent,
    ActiveUsersComponent,
    CaseCorordinatorRoleComponent,
    AssignedCasesListComponent,
    UserRoleComponent,
  ]

})

export class UsersModule { }

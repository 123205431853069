/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { ConfigAssetLoaderService } from '../../services/config-asset-loader.service';
import { TimeoutService } from '../../services/timeout.service';
import {
  Router,
  UrlSegment,
  ActivatedRoute,
} from '@angular/router';

@Component({
  selector: 'app-leftmenu',
  templateUrl: './leftmenu.component.html',
  styleUrls: ['./leftmenu.component.css']
})
export class LeftmenuComponent implements OnInit {

  currentTab = 'home';
  user: any;
  expandUsers = false;
  showDoctorAvailiablity = false;
  title = '';
  production: boolean;
  constructor(
    private router: Router,
    private loginService: LoginService,
    private activatedRoute: ActivatedRoute,
    private timeout: TimeoutService
  ) {
  }

  ngOnInit(): void {
    this.user = this.loginService.getCurrentUser();
    // detech browser hard refresh
    // eslint-disable-next-line max-len
    this.showDoctorAvailiablity = (this.user.user_type.type == 'Admin' || this.user.user_type.type == 'Careteam' || this.user.user_type.type == 'CaseCoordinator' 
    || this.user.user_type.type == 'CmManager' || this.user.user_type.type == 'Doctor') ? true : false;
    this.title ='My Cases';
    this.production = ConfigAssetLoaderService.getProduction();

    setTimeout(() => {

      this.activatedRoute.firstChild.url.subscribe(
        result => {
          const s: UrlSegment[] = result;

          if (s.length > 0) {

            this.currentTab = s[0].path; // returns 'team'

            if (this.currentTab == 'imd-careteam-role') {
              this.expandUsers = this.expandUsers === true ? false : true;
              this.activateLink('imdCareTeam');
            }
            else if (this.currentTab == 'doctor-role') {
              this.expandUsers = this.expandUsers === true ? false : true;
              this.activateLink('doctor');
            }
            else if (this.currentTab == 'client-role') {
              this.expandUsers = this.expandUsers === true ? false : true;
              this.activateLink('partner');
            }
            else if (this.currentTab == 'translator-role') {
              this.expandUsers = this.expandUsers === true ? false : true;
              this.activateLink('translators');
            }
            else if (this.currentTab == 'interpreter-role') {
              this.expandUsers = this.expandUsers === true ? false : true;
              this.activateLink('interpreters');
            }
            else if(this.currentTab == 'patient-role') {
              this.expandUsers = this.expandUsers === true ? false : true;
              this.activateLink('patient');
            }
            else if(this.currentTab == 'eligible-member-list') {
              this.expandUsers = this.expandUsers === true ? false : true;
              this.activateLink('eligible-member');
            }
            else if(this.currentTab == 'md-manager-role') {
              this.expandUsers = this.expandUsers === true ? false : true;
              this.activateLink('mdmanager');
            }
            else if(this.currentTab == 'cm-manager-role') {
              this.expandUsers = this.expandUsers === true ? false : true;
              this.activateLink('cmmanager');
            }
            else if (this.currentTab == 'patientdashboard') {
              this.activateLink('home');
            }
            else if (this.currentTab == 'dashboard'){
              this.activateLink('dashboard');
            }
            else if (this.currentTab == 'case-assignments'){
              this.activateLink('case-assignments');
            }
            else if (this.currentTab == 'casecoordinator'){
              this.expandUsers = this.expandUsers === true ? false : true;
              this.activateLink('casecoordinator');
            }
          }
        }
      );
    });
  }

  /**
     * Calls removeToken function from login service.
     * Later, navigates back to login page.
     */
  logout(event: Event): void {
    event.preventDefault();

    this.loginService.removeToken();
    this.timeout.stopSession();
    this.router.navigate(['/login']);
  }

  partnerList(event: Event): void {
    event.preventDefault();
    this.router.navigate(['/clients']);
  }
  //
  activateLink(tab: string): void {
    if (tab === 'support') {
      return;
    }
    console.log(tab);
    

    this.currentTab = tab;
    console.log(this.currentTab);
    
  }

  expandUser(event?: Event): void {
    event.preventDefault();
    this.expandUsers = this.expandUsers === true ? false : true;
  }

  viewUserList(event: Event, role_id: number, role: string): void {
    event.preventDefault();
    this.router.navigate(['../../role/' + role_id]);
    this.activateLink(role);
  }

  gotoPatient(event: Event): void {
    event.preventDefault();
    this.activateLink('home');
    this.router.navigate(['/patientdashboard']);
  }

}

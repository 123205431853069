/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { PopUpService } from '../../../services/pop-up.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormArray,
  Validators
} from '@angular/forms';

import { PatientModel } from '../../../domains/patientModel';
import { CommonFuntions } from '../../../common/common.function';
import { FileUploader } from 'ng2-file-upload';
import { ConfigAssetLoaderService } from '../../../services/config-asset-loader.service';
import { LoginService } from '../../../services/login.service';
import * as moment from 'moment/moment';
import {
  Router,
  UrlTree,
  UrlSegmentGroup,
  PRIMARY_OUTLET,
  UrlSegment,
  ActivatedRoute
} from '@angular/router';
import { UserService } from '../../../services/userservice.service';
import { CommonService } from '../../../services/common.service';
import { DatashareService } from '../../../services/datashare.service';
import { ToastrService } from 'ngx-toastr';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css']
})

export class PatientComponent implements OnInit {
  @ViewChild('addPatientSwal') private addPatientSwal: SwalComponent;
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  avatarimage: any;
  countriesList: any[] = [];
  avatarMale: string;
  patientForm: FormGroup;
  validateLanguage = false;
  timezones: any = [];
  time_zone_selected: string;

  uploadErrorMessage: string;
  uploadCompleteMessage: string;

  private apiUrl = ConfigAssetLoaderService.getApiURL();
  private URL = this.apiUrl + '/upload';

  public uploader: FileUploader = new FileUploader({ url: this.URL, itemAlias: 'photo', authToken: `Bearer ${localStorage.getItem('token')}` });

  patientModel: PatientModel;
  operation: string;
  userId: number;
  showPassword = true;
  addUpdateLabel = 'Add Member';

  filteredLanguageList: any = [];
  languagesList = [];
  partnerList = [];
  validatePartner = false;
  passwordReadOnly = true;

  socialTypes: any[] = [];
  hasQuery = false;

  currentUser: any;
  isSettings: any;
  isSupportPassword = false;
  isRecoverPassword = false;
  isResetPassword = false;
  isAllyInputDisabled = true;

  currentDate

  @ViewChild('dateInput', {static: true})
  dobInput;
  
  showAllyRef = false;
  password_type= 'password';

  constructor(
    private loginService: LoginService,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private toastrService: ToastrService,
    private datashare: DatashareService,
    private popUpService: PopUpService
  ) { }

  ngOnInit() {
    this.avatarMale = 'assets/images/avatar-male.jpg';
    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g.segments;

    this.operation = s[0].path;
    this.operation = this.operation === 'add' ? 'Add' : 'Edit';

    this.currentUser = this.loginService.getCurrentUser();
    this.checkAllyInputDisable();

    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    // overide the onCompleteItem property of the uploader so we are 
    // able to deal with the server response.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const self = this;

    this.getTimeZones();

    this.currentDate = new Date();
    this.currentDate.setHours(23, 59, 59, 999);

    this.uploader.onCompleteItem = (_item: any, response: any, status: any, _headers: any) => {
      if (status && status == 200) {
        this.avatar.setValue(JSON.parse(response).path);
        this.uploadErrorMessage = null;
        this.uploadCompleteMessage = 'Upload successful.';
        this.userService.getUploadedFile(this.avatar.value).subscribe(
          image => {
            this.avatarimage = image;
          }, (_error) => {
            this.avatarimage = null;
          }
        );
      }
      else if (status && status == 422) {
        this.uploadErrorMessage = 'Upload failed.';
        this.uploadCompleteMessage = null;
      }
      else {
        this.uploadErrorMessage = 'Upload failed through server.';
        this.uploadCompleteMessage = null;
      }
    };

    this.getLanguages();

    this.getCountries();

    this.getSocialTypes();
    
    this.patientModel = new PatientModel();

    this.setPatientForm();

    if (['admin', 'cmmanager'].includes(this.currentUser.user_type.type.toLowerCase())) {
      this.isSettings = true;
    }
    else {
      this.isSettings = false;
    }

    // hide ally user ref input field
    if (this.currentUser.user_type.type.toLowerCase() === 'admin' || this.currentUser.user_type.type.toLowerCase() === 'careteam') {
      this.showAllyRef = true;
    }

    if(CommonFuntions.getPartnersForAuthorizedUser(this.currentUser.user_type.type)) {
      this.getPartnerList();
    }
  

    this.route.params
      .subscribe(params => {
        const self = this;
        if (params['user_id'] && isNaN(params['user_id'])) {
          this.router.navigate(['/page-not-found']);
          return;
        }

        this.userId = params['user_id'];
        /** Edit user */
        if (this.userId) {
          const formControl = this.patientForm.get('user_id');
          if (formControl) {formControl.setValue(this.userId);}

          const formArrayControls = (this.patientForm.get('contacts') as FormArray).controls;
          if (formArrayControls.length > 0) {formArrayControls[0].get('user_id').setValue(this.userId);}

          self.showPassword = false;

          self.uploader.options.url = self.uploader.options.url + '?userid=' + this.userId;

          this.addUpdateLabel = 'Save';

          this.getUserDetail();
        }
        /** Create user */
        else {
          this.addFormControlOnCreate();
        }
      }
      );

    this.route.queryParams.subscribe(
      queries => {
        const actionQuery = queries['action'];
        if (actionQuery && actionQuery === 'new') {
          this.hasQuery = true;
        }
      }
    );

    /* if (this.currentUser.user_type.type.toLowerCase() === 'partner') {
      this.user_partners.push(
        new FormControl(
          this.currentUser.partners[0]
        )
      )
    } */
    if (this.password) this.generate(true);
  }

  private checkAllyInputDisable(): void {
    if (this.currentUser.user_type.type.toLowerCase() === 'admin') {
      this.isAllyInputDisabled = false;
    }
  }

  getFile(result) {
    if (result.avatar && result.avatar != '') {
      this.userService.getUploadedFile(result.avatar).subscribe(
        image => {
          this.avatarimage = image;
        }, (_error) => {
          this.avatarimage = null;
        }
      );
    }
  }

  configLanguage(result) {
    if (this.user_languages.length < this.patientModel.user_languages.length) {
      const languageList = [];             
      result.user_languages.forEach(
        user_language => {
          const langugageObject: any = {};
          langugageObject['user_language_id'] = user_language.user_language_id;
          langugageObject['user_id'] = user_language.user_id;
          langugageObject['language_id'] = user_language.language_id;
          langugageObject['language'] = user_language.language.language;
          languageList.push(langugageObject);

          this.languagesList = this.languagesList.filter(
            language => !(language.language_id === langugageObject.language_id)
          );

          this.user_languages.push(new FormControl());
        }
      );

      result.user_languages = languageList;
    }

    return result;
  }

  configContact(result) {
    if (this.patientModel.contacts.length > 0) {
      if (this.contacts.length < this.patientModel.contacts.length) {
        for (let i = 1; i < this.patientModel.contacts.length; i++) {
          this.contacts.push(this.generateContacts());
        }
      }
    }
    else {
      const thisContactList = [{
        contact_id: null,
        user_id: 0,
        social_type_id: 0,
        social_id: ''
      }];
      result.contacts = thisContactList;
    }

    return result;
  }

  configPartner(result) {
    const partnerList = [];
    result.user_partners.forEach(
      user_partner => {
        const partnerObject: any = {};
        partnerObject['partner_id'] = user_partner.partner_id;
        partnerObject['partner_name'] = user_partner.partner.partner_name;
        partnerList.push(partnerObject);

        this.partnerList = this.partnerList.filter(
          partner => !(partner.partner_id === partnerObject.partner_id)
        );

        this.user_partners.push(new FormControl());
      }
    );

    result.user_partners = partnerList;

    return result;
  }

  getUserDetail() {
    this.userService.getUser(this.userId).subscribe(
      result => {
        if (result.user_type_id != 5) {
          this.router.navigate(['/']);
          return;
        }

        this.configSetting(result);

        if (this.currentUser.user_type.type.toLowerCase() == 'admin') {
          this.isAllyInputDisabled = false;
        }
        else {
          this.isAllyInputDisabled = true;
        }

        this.patientModel = result as PatientModel;

        this.datashare.setData(this.patientModel.full_name);
        delete this.patientModel['full_name'];

        this.patientForm.addControl('user_id', new FormControl(this.userId));

        this.getFile(result);

        result = this.configLanguage(result);
        
        result = this.configContact(result);

        result = this.configPartner(result);

        this.patientForm.patchValue(this.patientModel);

        if (this.patientModel.dob) this.dobInput.nativeElement.value = this.patientModel.dob;

      }, (_error) => {
        this.router.navigate(['/']);
      }
    );
  }

  getUserPartner(user, partners) {
    this.userService.getUser(user.user_id).subscribe(
      function (userDetails) {
        partners.forEach(
          function (partner) {
            const partnerObj = {};
            partnerObj['partner_id'] = partner.partner_id;
            partnerObj['partner_name'] = partner.partner_name;

            const check = userDetails.user_partners.some(
              indivPartner => indivPartner.partner.partner_id === partner.partner_id
            );
            if (check) {
              this.partnerList.push(partnerObj);
            }
          }.bind(this)
        );
      }.bind(this)
    );
  }

  getPartnerList() {
    this.commonService.getPartners().subscribe(
      partners => {
        const user = this.loginService.getCurrentUser();
        if (user && user != null) {
          if (user.user_type.type.toLowerCase() === 'admin' || user.user_type.type.toLowerCase() === 'cmmanager') {
            partners.forEach(
              partner => {
                const partnerObj = {};
                partnerObj['partner_id'] = partner.partner_id;
                partnerObj['partner_name'] = partner.partner_name;
                this.partnerList.push(partnerObj);
              }
            );
          }
          else if (user.user_type.type.toLowerCase() === 'careteam' ||
            user.user_type.type.toLowerCase() === 'partner') {
            this.getUserPartner(user, partners);
          }
        }
      }
    );
  }

  public showAlert(message: string, positiveAction?: Function) {
    this.popUpService.destoryConfirmDialog();
    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );
    if (positiveAction) {
      this.popUpService.setPositiveAction(positiveAction);
    }
    else {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }
    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) {this.popUpService.showDiv(dialog);}
  }


  get first_name() {
    return this.patientForm.get('first_name');
  }

  get middle_name() {
    return this.patientForm.get('middle_name');
  }

  get last_name() {
    return this.patientForm.get('last_name');
  }

  get phone_number() {
    return this.patientForm.get('phone_number');
  }

  get allyUserRef() {
    return this.patientForm.get('allyUserRef');
  }

  get zip_code() {
    return this.patientForm.get('zip_code');
  }

  get user_partners() {
    return this.patientForm.get('user_partners') as FormArray;
  }

  get patient_detail() {
    return this.patientForm.get('patient_detail');
  }

  get dob() {
    return this.patient_detail.get('dob');
  }

  get country_id() {
    return this.patient_detail.get('country_id');
  }

  get gender() {
    return this.patient_detail.get('gender');
  }

  get user_languages() {
    return this.patientForm.get('user_languages') as FormArray;
  }

  get company() {
    return this.patient_detail.get('company');
  }

  get policy_number() {
    return this.patient_detail.get('policy_number');
  }

  get group_number() {
    return this.patient_detail.get('group_number');
  }

  get email() {
    return this.patientForm.get('email');
  }

  get password() {
    return this.patientForm.get('password');
  }

  get contacts() {
    return this.patientForm.get('contacts') as FormArray;
  }

  get avatar() {
    return this.patientForm.get('avatar');
  }

  get time_zone() {
    return this.patientForm.get('time_zone');
  }

  setKey(input: HTMLSelectElement) {
    this.time_zone_selected = input.selectedOptions[0].innerHTML;
  }

  getTimeZones() {
    this.commonService.getTimeZones().subscribe(
      result => {
        this.timezones = result;
      }, (_error) => {
        this.timezones = [];
      }
    ); 
  }

  getLanguages() {
    this.commonService.getLanguages().subscribe(
      languages => {
        this.languagesList = languages;
      }, (_error) => {
        this.languagesList = [];
      }
    );
  }

  getCountries() {
    this.commonService.getCountries().subscribe(
      countries => {
        this.countriesList = countries;
        this.countriesList.splice(0, 0, {
          country_id: 0,
          country_name: 'Select'
        });
      }, (_error) => {
        this.countriesList.splice(0, 0, {
          country_id: 0,
          country_name: 'Select'
        });
      }
    );
  }

  getSocialTypes() {
    this.commonService.getSocialTypes().subscribe(
      (types) => {
        this.socialTypes = types;
        this.socialTypes.splice(0, 0, {
          social_type_id: 0,
          social_type: 'Select'
        });
      },
      (_error) => {
        this.socialTypes = [
          {
            social_type_id: 0,
            social_type: 'Select'
          }
        ];
      }
    );
  }

  generateContacts() {
    return new FormGroup({
      social_type_id: new FormControl(0,
        [
          // CommonFuntions.validateDropDown
        ]),
      social_id: new FormControl('',
        [
          // Validators.required,
          Validators.maxLength(50)
        ]),
      contact_id: new FormControl(),
      user_id: new FormControl(this.patientModel.user_id)
    });
  }

  addLanguages(language: string) {
    const value = this.languagesList.find(
      object => object.language === language
    );

    this.filteredLanguageList = this.languagesList;
    const indexOf = this.languagesList.indexOf(value);
    this.languagesList.splice(indexOf, 1);

    if (value == null) {return;}

    this.user_languages.push(new FormControl(value));
    this.validateLanguage = true;
  }

  removeLanguages(language: FormControl) {
    const index = this.user_languages.controls.indexOf(language);
    this.user_languages.removeAt(index);

    this.languagesList.push(language.value);
    this.languagesList.sort(function (language1, language2) {
      return language1.language_id - language2.language_id;
    });
  }

  generate(checkbox: HTMLInputElement | boolean): string {
    let status;
    if (typeof (checkbox) === 'boolean') {
      status = checkbox;
    }
    else if (checkbox instanceof HTMLInputElement) {
      status = checkbox.checked;
    }
    else {
      status = false;
    }

    if (status) {
      this.password.markAsTouched({ onlySelf: true });
      const password = this.userService.generateRandomPassword();
      this.password.setValue(password, { onlySelf: true });
      this.passwordReadOnly = true;
      this.patientForm.get('password').clearValidators();
      this.patientForm.get('password').updateValueAndValidity();
      return password;
    }
    else {
      this.passwordReadOnly = false;
      this.password.reset();
      this.password.setValue('');
      this.patientForm.get('password').setValidators([Validators.required, CommonFuntions.ValidatePassword]);
      this.patientForm.get('password').updateValueAndValidity();
      return '';
    }
  }

  addAnotherContact(event) {
    event.preventDefault();

    this.contacts.push(this.generateContacts());
  }

  removeContactAt(index: number) {
    const contact = this.contacts.controls[index];
    const value = contact.value.social_id;
    if (value === '') {
      this.contacts.removeAt(index);
      return;
    }
    const confirmStr = 'Are you sure you want to delete ' + value + ' from contact list';
    if (confirm(confirmStr)) {
      this.contacts.removeAt(index);
    }
  }

  resetPassword(event) {
    event.preventDefault();
    this.patientForm.removeControl('use_support_password');
    this.patientForm.addControl('use_support_password', new FormControl(false));
    if (this.showPassword && this.operation.toLowerCase() === 'edit') {return;}
    this.showPassword = true;

    if (!this.patientForm.controls.hasOwnProperty('password')) {
      this.patientForm.addControl('password', new FormControl('', { validators: [CommonFuntions.ValidatePassword], updateOn: 'change'}));
    }
    if (!this.patientForm.controls.hasOwnProperty('auto_generate_pwd')) {
      this.patientForm.addControl('auto_generate_pwd', new FormControl(true));
    }
    this.generate(true);
  }

  useSupportPassword(event) {
    event.preventDefault();

    this.patientForm.addControl('use_support_password', new FormControl(true));

    if (this.showPassword && this.operation.toLowerCase() === 'edit') {return;}
    this.showPassword = true;
    if (!this.patientForm.controls.hasOwnProperty('password')) {
      this.patientForm.addControl('password', new FormControl('', { validators: [CommonFuntions.ValidatePassword], updateOn: 'change'}));
    }
    if (!this.patientForm.controls.hasOwnProperty('auto_generate_pwd')) {
      this.patientForm.addControl('auto_generate_pwd', new FormControl(true));
    }
    this.generate(true);


  }

  recoverPassword(event) {
    event.preventDefault();

    this.userService.recoverPassword(this.userId).subscribe(
      (_result) => {
        this.showAlert('Password recovered.',function(){
          this.router.navigate(['/profile', this.userId]);
        }.bind(this));
      }, error => {
        this.showAlert(this.userService.handleError(error, this.operation.toLowerCase()));
      }
    );
  }

  submit(form: FormGroup) {

    this.validateLanguage = true;
    this.validatePartner = true;

    if (CommonFuntions.guardForAction(this.loginService,
      this.router)) {
      if (form.valid) {

        if (this.operation.toLowerCase() === 'add') {
          // check if contact method exists and remove if not present
          if (form.getRawValue().contacts) {
            let i = 0;
            form.getRawValue().contacts.forEach(element => {
              if (element.social_id == '' || element.social_type_id == 0) {
                this.contacts.removeAt(i);
              }
              else {
                i++;

              }
            });
          }
          const patient: PatientModel = form.getRawValue() as PatientModel;
          patient.allyUserRef = patient.allyUserRef || null;

          if (this.time_zone_selected != undefined) { patient.time_zone_offset = this.time_zone_selected; }
          this.userService.addUser(patient).subscribe((result) => {
            this.toastrService.success('User added successfully.');
            if (!this.hasQuery) {
              this.router.navigate(['/profile', result.user_id]);
            }
            else {
              this.router.navigate(['/case', 'create', result.user_id]);
            }
          }, (error) => {
            this.toastrService.error(error['statusText']);
          }
          );
        }

        else if (this.operation.toLowerCase() === 'edit') {

          if (!this.showPassword) {
            this.patientForm.removeControl('password');
          }

          if (form.getRawValue().contacts) {
            let i = 0;
            form.getRawValue().contacts.forEach(element => {
              if (element.social_id == '' || element.social_type_id == 0) {
                this.contacts.removeAt(i);
              }
              else {
                i++;

              }
            });

            const patient: PatientModel = form.getRawValue() as PatientModel;
            patient.allyUserRef = patient.allyUserRef || null;
            if (this.time_zone_selected != undefined) { patient.time_zone_offset = this.time_zone_selected; }
            patient.user_id = this.userId;
            this.userService.updateUser(patient).subscribe((_result) => {

              this.router.navigate(['/profile', patient.user_id]);

              // if self user and password changed then
              if (this.userId === this.currentUser.user_id && patient.password) {
                localStorage.removeItem('passwordExpirySetting');
              }
            }, (error) => {
              this.toastrService.error(error['statusText']);

            }

            );

          }
        }
        else {

          CommonFuntions.validateAllFormFields(form);
        }
      }
      else {
        this.toastrService.warning('Form Invalid');
        CommonFuntions.validateAllFormFields(form);
      }
    }
  }

  suggestionListFormatter(data: any): string {
    if (data.hasOwnProperty('language')) {
      return data.language;
    }
    if (data.hasOwnProperty('partner_name')) {
      return data.partner_name;
    }
  }

  valueListFormatter(data: any): string {
    if (data.hasOwnProperty('language')) {
      this.addLanguages(data.language);
    }
    if (data.hasOwnProperty('partner_name')) {
      this.addPartner(data.partner_name);
    }
    return '';
  }

  clearPartner(partner: FormControl) {
    const index = this.user_partners.controls.indexOf(partner);
    this.partnerList.push(partner.value);
    this.partnerList.sort(
      (partner1, partner2) => partner1.partner_id - partner2.partner_id
    );
    this.user_partners.removeAt(index);
  }

  addPartner(partner: string) {
    const partnerObj = this.partnerList.find(partnerItem => partnerItem.partner_name === partner);
    const index = this.partnerList.indexOf(partnerObj);
    this.partnerList.splice(index, 1);
    if (this.user_partners.controls.length > 0) {
      const dumpControl = this.user_partners.controls[0] as FormControl;
      this.clearPartner(dumpControl);
      this.user_partners.push(new FormControl(partnerObj));
    }
    else {
      this.user_partners.push(new FormControl(partnerObj));
    }
    this.validatePartner = true;
  }

  cancel(event: Event) {
    event.preventDefault();
    this.router.navigate(['/profile', this.userId]);
  }

  preventDefault(event: Event) {
    const userAgent = window.navigator.userAgent;

    const browsers = { chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i };

    for (const key in browsers) {
      if (browsers[key].test(userAgent)) {
        if (key == 'firefox') {
          event.preventDefault();

        }
      }
    }

  }

  public onDateChange(_event, calender, dateInput) {
    const calenderDate = calender.selected;
    if (calenderDate == null) {
      dateInput.value = '';
      return;
    }
    if (calenderDate.getFullYear() < 1900 || calenderDate.getFullYear() > 9999) {
      if (calenderDate.getFullYear() > 9999) {dateInput.value = '';}
      return;
    }
    const date = moment(calender.selected.toUTCString()).format('YYYY-MM-DD');

    if (moment().isBefore(date)) {
      dateInput.value = '';
      return;
    }

    this.patientForm.get('patient_detail').get('dob').setValue(date);
    dateInput.value = date;
  }

  addFormControlOnCreate() {
    this.patientForm.addControl('password', new FormControl('', { validators: [CommonFuntions.ValidatePassword], updateOn: 'change'}));
    this.patientForm.addControl('auto_generate_pwd', new FormControl(true));
  }

  private setPatientForm(){
    this.patientForm = new FormGroup({
      first_name: new FormControl(this.patientModel.full_name,
        [
          Validators.required,
          Validators.maxLength(50)
        ]),
      middle_name: new FormControl(this.patientModel.full_name,
        [
          Validators.maxLength(50)
        ]),
      last_name: new FormControl(this.patientModel.full_name,
        [
          Validators.required,
          Validators.maxLength(50)
        ]),
      allyUserRef: new FormControl({ value: this.patientModel.allyUserRef, disabled: this.isAllyInputDisabled }),
      zip_code: new FormControl(this.patientModel.zip_code),
      user_partners: new FormArray([],
        [
          CommonFuntions.fomrArrayMinLength(1),
        ]),
      phone_number: new FormControl('',
        [
          Validators.maxLength(15),
          Validators.minLength(9),
          Validators.required
        ]),
      time_zone: new FormControl(
        this.patientModel.time_zone,
        [
          Validators.required]
      ),
      time_zone_offset: new FormControl(this.patientModel.time_zone_offset),
      patient_detail: new FormGroup({
        patient_id: new FormControl(),
        user_id: new FormControl(this.patientModel.user_id),
        country_id: new FormControl(this.patientModel.patient_detail.country_id,
          [
            CommonFuntions.validateDropDown
          ]),
        gender: new FormControl(this.patientModel.patient_detail.gender,
          [
            CommonFuntions.validateDropDown
          ]),
        associated_company: new FormControl(this.patientModel.patient_detail.associated_company),
        dob: new FormControl(this.patientModel.patient_detail.dob,
          [
            Validators.required
          ]),
        company: new FormControl(this.patientModel.patient_detail.company),
        policy_number: new FormControl(this.patientModel.patient_detail.policyNumber),
        group_number: new FormControl(this.patientModel.patient_detail.groupNumber)
      }),
      user_languages: new FormArray([],
        [
          CommonFuntions.fomrArrayMinLength(1)
        ]),
      email: new FormControl(this.patientModel.email,
        [
          //not required now was required previously
          // Validators.required,
          // CommonFuntions.ValidateEmail
        ]),
      contacts: new FormArray([
        this.generateContacts()
      ]),
      avatar: new FormControl(this.patientModel.avatar),
      user_type_id: new FormControl(5)
    });
  }

  configSetting(result) {
    /** Check settings. */
    if (this.currentUser.user_id === result.user_id) {
      this.isSettings = true;
    }

    /** Check support password */
    if (this.operation.toLowerCase() === 'edit' && this.isSettings && !result.temp_password && this.currentUser.user_type.type.toLowerCase() === 'admin') {
      this.isSupportPassword = true;
    }

    /** Check recover password */
    if (this.operation.toLowerCase() === 'edit' && this.isSettings && result.temp_password && this.currentUser.user_type.type.toLowerCase() === 'admin') {
      this.isRecoverPassword = true;
    }

    /** Check reset password */
    if (this.operation.toLowerCase() === 'edit' && this.isSettings) {
      this.isResetPassword = true;
    }

    delete result.temp_password; /** Need to delete this. */
  }

  validateForm(){
    CommonFuntions.validateAllFormFields(this.patientForm);
    if(this.patientForm.valid){
      this.addPatientSwal.fire();
    }
    else{
      this.toastrService.warning('Please fill all mandatory input fields','Invalid Form');
      this.validateLanguage =true;
      this.validatePartner =true;
    }
  }

}
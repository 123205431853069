
  <div class="top-menu">
    <div class="top-menu__user-stat">
      <span>Total {{title}}</span>{{activePartner}} {{title}}</div>
    <div class="top-menu__menu">
  
    </div>
    <div class="top-menu__search">
      <div class="search-wrapper">
        <div class="sarch-box">
          <input type="text" [(ngModel)]="searchValue" (keyup)="callSearchPartner()" />
          <select name="partnerActiveInactive" class="partnerActiveInactive">
            <option (change)="filterPartner($event)" *ngFor="let type of PARTNER_STATUS " [value]="type?.id"  [selected]= "type.id == 'Active'">
              {{ type.value }}
            </option>
          </select>
        </div>
      </div>
      <div class="add-user">
        <a *ngIf="userRole?.toLowerCase()==='admin' || userRole?.toLowerCase()==='cmmanager'" [routerLink]="['/clients/create']" class="btn btn--blue btn--mini">
          Add Client
        </a>
      </div>
    </div>
  </div>

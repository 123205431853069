<div class="header">
	<div class="header__page-name">
		<h1 class="heading heading--main">Email Templates</h1>
	</div>
	<div class="header__top-nav">
		<app-email-template-header [totalRecord]="totalMailServerCount" (searchValueEmitter)="searchRecord($event)"></app-email-template-header>
	</div>
</div>
<div class="wrapper--dash-content dash-content">
	<div *ngIf="errorString" class="message message--is-invalid">
		{{errorString}}
	</div>

	<table datatable [dtOptions]="dtOptions" class="row-border hover" id="doctor">
		<thead>
			<tr>
			</tr>
		</thead>
	</table>
</div>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigAssetLoaderService } from './config-asset-loader.service';

import { getHeaderWithToken, EMAIL_TEMPLATE } from './constant';

@Injectable()
export class EmailTemplateService {
  constructor(
    private http: HttpClient
  ) { }

  /**
   * Function to fetch all the email templates
   * @return {*}  {Observable<any>}
   * @memberof EmailTemplateService
   */
  getAllMailServers(): Observable<any> {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${EMAIL_TEMPLATE.LIST}`;
    return this.http.get<any>(URL, getHeaderWithToken());
  }

  /**
   * Function to get the detail of email tepmlate by its id
   * @param {number} id
   * @return {*}  {Observable<any>}
   * @memberof EmailTemplateService
   */
  getEmailTemplateById(id: number): Observable<any> {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${EMAIL_TEMPLATE.GET}/${id}`;
    return this.http.get<any>(URL, getHeaderWithToken());
  }
  
  /**
   * Function to get the detail of email template by apply_to
   * @param {number} id
   * @return {*}  {Observable<any>}
   * @memberof EmailTemplateService
   */
  getEmailTemplateByApply_To(apply_to: string): Observable<any> {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${EMAIL_TEMPLATE.GET}?apply_to=`+apply_to;
    return this.http.get<any>(URL, getHeaderWithToken());
  }

  /**
   * create new email template
   * @param {*} payload
   * @return {*}  {Observable<any>}
   * @memberof EmailTemplateService
   */
  createEmailTemplate(payload): Observable<any> {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${EMAIL_TEMPLATE.CREATE}`;
    return this.http.post<any>(URL, payload, getHeaderWithToken());
  }

  /**
   * Function to edit the Email Template
   * @param {number} id
   * @param {*} payload
   * @return {*}  {Observable<any>}
   * @memberof EmailTemplateService
   */
  editEmailTemplate(id: number, payload): Observable<any> {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${EMAIL_TEMPLATE.PUT}/${id}`;
    return this.http.put<any>(URL, payload, getHeaderWithToken());
  }

  /**
   * Function to edit the Email Template
   * @param {number} id
   * @return {*}  {Observable<any>}
   * @memberof EmailTemplateService
   */
  deleteEmailTemplate(id: number): Observable<any> {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${EMAIL_TEMPLATE.DELETE}/${id}`;
    return this.http.delete<any>(URL, getHeaderWithToken());
  }
}


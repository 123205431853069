/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CaseStatus } from '../../domains/case-status-model';
import { Status } from '../../domains/status-model';

const usertype = {
  admin: 1,
  careteam: 2,
  partner: 3,
  doctor: 4,
  patient: 5,
  translator: 6,
  interpreter: 7,
  mdmanager: 8,
  cmmanager: 10,
  casecoordinator: 11
};

export function display(
  statusList: CaseStatus[],
  caseStatus: string,
  role: string,
  caseType?: string,
  isSave?: boolean,
  isSubmitted?: boolean,
) {
  const roleId = getUserType(role);

  switch (roleId) {
  case usertype.admin: {
    return getStatusForCareteam(statusList, caseStatus);
  }
  case usertype.careteam: {
    return getStatusForCareteam(statusList, caseStatus);
  }
  case usertype.casecoordinator: {
    return getStatusForCareteam(statusList, caseStatus);
  }
  case usertype.partner: {
    return Status.mapStatusForPartner(
      getStatusForCareteam(statusList, caseStatus)
    );
  }
  case usertype.doctor: {
    return Status.mapStatusForDoctor(
      caseType,
      getStatusForCareteam(statusList, caseStatus),
      isSave,
      isSubmitted
    );
  }
  case usertype.translator: {
    return Status.mapStatusForTranslator(
      getStatusForCareteam(statusList, caseStatus)
    );
  }
  case usertype.interpreter: {
    return Status.mapStatusForTranslator(
      getStatusForCareteam(statusList, caseStatus)
    );
  }
  case usertype.patient: {
    return Status.mapStatusForMembers(
      caseType,
      getStatusForCareteam(statusList, caseStatus)
    );
  }
  case usertype.mdmanager: {
    return getStatusForCareteam(statusList, caseStatus);
  }
  case usertype.cmmanager: {
    return getStatusForCareteam(statusList, caseStatus);
  }
  default: {
    return 'N/A';
  }
  }
}

function getUserType(role: string): number {
  role = role.toLowerCase();
  return usertype[role];
}

function getStatusForCareteam(statusList: CaseStatus[], caseStatus: string): string {
  if (
    caseStatus.toLowerCase() === 'hold' ||
		caseStatus.toLowerCase() === 'cancelled' ||
		caseStatus.toLowerCase() === 'finished'
  ) {
    return caseStatus;
  }

  const latestStage: CaseStatus = CaseStatus.getLatestStatus(statusList);

  if (!latestStage) { return 'N/A'; }

  return Status.mapStatus(latestStage.status.status);

}

export const SEARCH_OPTIONS = {
  Admin: {
    ALLY_CASE_REF: 'Ally Case Ref',
    ALLY_CASE_NUMBER: 'Ally Case Number',
    CASE_ID: 'Case ID',
    CASE_TYPE: 'Case type',
    PATIENT_NAME: 'Patient',
    MD_FIRST_NAME: 'Doctor\'s First name',
    MD_LAST_NAME: 'Doctor\'s Last name',
  },
  Careteam: {
    ALLY_CASE_REF: 'Ally Case Ref',
    ALLY_CASE_NUMBER: 'Ally Case Number',
    CASE_ID: 'Case ID',
    CASE_TYPE: 'Case type',
    PATIENT_NAME: 'Patient',
    MD_FIRST_NAME: 'Doctor\'s First name',
    MD_LAST_NAME: 'Doctor\'s Last name',
  },
  CaseCoordinator: {
    ALLY_CASE_REF: 'Ally Case Ref',
    ALLY_CASE_NUMBER: 'Ally Case Number',
    CASE_ID: 'Case ID',
    CASE_TYPE: 'Case type',
    PATIENT_NAME: 'Patient',
    MD_FIRST_NAME: 'Doctor\'s First name',
    MD_LAST_NAME: 'Doctor\'s Last name',
  },
  CmManager: {
    ALLY_CASE_REF: 'Ally Case Ref',
    ALLY_CASE_NUMBER: 'Ally Case Number',
    CASE_ID: 'Case ID',
    CASE_TYPE: 'Case type',
    PATIENT_NAME: 'Patient',
    MD_FIRST_NAME: 'Doctor\'s First name',
    MD_LAST_NAME: 'Doctor\'s Last name',
  },
  MdManager: {
    ALLY_CASE_REF: 'Ally Case Ref',
    ALLY_CASE_NUMBER: 'Ally Case Number',
    CASE_ID: 'Case ID',
    CASE_TYPE: 'Case type',
    PATIENT_NAME: 'Patient',
    MD_FIRST_NAME: 'Doctor\'s First name',
    MD_LAST_NAME: 'Doctor\'s Last name',
  },
  Translator: {
    //translator is summary coordinator
    CASE_ID: 'Case ID',
    CASE_TYPE: 'Case type',
    PATIENT_NAME: 'Patient',

  },
  Interpreter: {
    CASE_ID: 'Case ID',
    CASE_TYPE: 'Case type',
    PATIENT_NAME: 'Patient',
  },
  Doctor: {
    CASE_ID: 'Case ID',
    CASE_TYPE: 'Case type',
    PATIENT_NAME: 'Patient',

  },
  // partner is client care team
  Partner: {
    CASE_ID: 'Case ID',
    CASE_TYPE: 'Case type',
    PATIENT_NAME: 'Patient',
  },
  Patient: {
    CASE_TYPE: 'Case type',
    CASE_ID: 'Case ID',
    MD_FIRST_NAME: 'Doctor\'s First name',
    MD_LAST_NAME: 'Doctor\'s Last name',
  }
};

export const SEARCH_OPTION_DASHBOARD = {
  translator: {
    //translator is summary coordinator
    CASE_ID: 'Case ID',
    CASE_TYPE: 'Case type',
    PATIENT_NAME: 'Patient',
    STATUS: 'Status',
    DIAGNOSIS: 'Diagnosis'

  }
  ,
  doctor: {
    CASE_ID: 'Case ID',
    CASE_TYPE: 'Case type',
    PATIENT_NAME: 'Patient',
    STATUS: 'Status',
    DIAGNOSIS: 'Diagnosis'

  },

};

export const SEARCH_OPTION_CASE_ASSIGNMENTS = {
  admin: {
    CASE_ID: 'Case ID',
    ASSIGNMENT_TYPE: 'Assignment type',
    ASSIGNMENT_STATUS: 'Assignment status',
  },
  cmmanager: {
    CASE_ID: 'Case ID',
    ASSIGNMENT_TYPE: 'Assignment type',
    ASSIGNMENT_STATUS: 'Assignment status',
  },
  mdmanager: {
    CASE_ID: 'Case ID',
    ASSIGNMENT_TYPE: 'Assignment type',
    ASSIGNMENT_STATUS: 'Assignment status',
  }
};
export const SEARCH_OPTION_EMAIL_TEMPLATE = {
  admin: {
    NAME: 'Name',
    APPLY_TO: 'Apply To',
  },
};
<div class="hr hr--space-up-x hr--space-down-x"></div>
<h3 class="form__title">Settings</h3>
<div class="row">
    <div class="col col__md-6 gutter--right-2x">
        <div [formGroup]="settingForm" class="form__row">
            <div class="form__field">
                <div class="form__field--checkbox">
                    <input type="checkbox" formControlName="isElevatedPartner" id="elevated_partner" />
                    <label for="elevated_partner">Elevated Client Care Team</label> 
                    <span class="icon icon--info tooltip-box">
                        <span class="tooltip-box-text top-minus-45 large-tool-text">
                            If this setting is on then <br />
                            a. CCT would be able to change the vip status of case. <br />
                            b. CCT would be able to edit the case questions. <br />
                            c. CCT would have same level of access as IMDCT in the comments.
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
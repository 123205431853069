<app-written-case [caseDetail]="caseDetail" [forReport]="true" [isEditEnable]="isEditEnable" [isProgress]="isProgress"
    (edit)="onEditChange($event)" (updateConsultationForm)="setConsultationFormValue($event)" [caseStatus]="review.state" [followUpNumber]="followUpNumber" #written></app-written-case>

<div class="box box--white box--no-pad box--radius margin--bottom-2x overlay__wrapper"
    [class.overlay--visible]="isProgress" [formGroup]="form">
    <div class="overlay__content">
        <div class="overlay__spinner">
            <span class="icon icon--loading"></span>
        </div>
    </div>
    <div class="box__title case-section-box">
        <div class="box-title-left">
            <div class="left-title">Summary</div>
            <div class="left-status">
                <span class="icon icon--calendar"></span>Assigned 3 hours ago
            </div>
        </div>
        <div class="box-title-right">
            <div class="flexDisplay">
                <div class="mr20">
                    <div class="right-name">{{review?.user?.full_name}}</div>
                    <div class="right-status margin--top-x">Assignee</div>
                </div>
                <div>
                    <a href="javascript:void(0)" class="go-back pull-right collapseToggle ml20"
                        [ngClass]="{collapseToggleActive: isSummaryCollapse===true}"
                        (click)="isSummaryCollapse = !isSummaryCollapse">
                        <span class="icon icon--time-nav"></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div [hidden]="!isSummaryCollapse" class="padding-2x box--md-mgmt form">
        <div class="form__row">
            <div class="col col__md-3">
                <div class="form__label is-uppercase">Description</div>
            </div>
            <div class="col col__md-9">
                <div class="form__field" *ngIf="!caseDetail?.is_submitted">
                    <div class="text">Waiting for MD to submit case summary</div>
                </div>
                <div class="form__field" *ngIf="caseDetail?.is_submitted">
                    <div class="text">MD has submitted case summary</div>
                </div>
            </div>
        </div>
        <div>
            <div *ngIf="caseDetail?.is_submitted" formArrayName="case_medications">
                <div *ngFor="let medication_case of case_medications.controls; let i=index" [formGroupName]="i">
                    <div class="form__row">
                        <div class="col col__md-3">
                            <div *ngIf="mdtCase" class="form__label pr-2">Medication(s) mentioned by {{
                                getUsername(medication_case.get('medication_by_user_id').value) }}</div>
                            <div *ngIf="!mdtCase" class="form__label pr-2">Medication {{ i+ 1 }} </div>
                        </div>
                        <div class="col col__md-9">
                            <div class="form__field">
                                <input type="text" formControlName="medication" [readonly]="!isEditEnable"
                                    placeholder="Please enter medications recommended." />
                                <div class="message message--is-invalid"
                                    *ngIf="medication_case.get('medication').touched && medication_case.get('medication').errors">
                                    <div *ngIf="medication_case.get('medication').errors.required">This field is
                                        required
                                    </div>
                                    <div *ngIf="medication_case.get('medication').errors.maxlength">This field cannot
                                        contain more than
                                        {{medication_case.get('medication').errors.maxlength.requiredLength}}
                                        characters.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form__row">
                <div class="col col__md-3">
                    <div class="form__label is-uppercase">Comments</div>
                </div>
                <div class="col col__md-9">
                    <div class="form__field" *ngIf="!caseDetail?.is_submitted">
                        <!-- <textarea formControlName="comment_summary" [readonly]="!isEditEnable"
                            placeholder="Doctor’s consultation comments / summary not yet submitted"></textarea> -->
							<ckeditor class="customheader" formControlName="comment_summary" [readonly]="!isEditEnable"
                            placeholder="Doctor’s consultation comments / summary not yet submitted" #ckeditorField
							[config]="{toolbar: []}"></ckeditor>
                    </div>
                    <div class="form__field" *ngIf="caseDetail?.is_submitted">
                        <!-- <textarea formControlName="comment_summary" [readonly]="!isEditEnable"></textarea> -->
						<ckeditor class="customheader" formControlName="comment_summary" [readonly]="!isEditEnable" #ckeditorField
							[config]="{toolbar: []}"></ckeditor>
                        <div *ngIf="comment_summary.touched && comment_summary.invalid"
                            class="message message--is-invalid">
                            <div *ngIf="comment_summary.errors.maxlength">Field must not exceed
                                {{comment_summary.errors.maxlength.requiredLength}} characters.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="btns__row text-right" *ngIf="!disableInputs">
            <app-generic-button [isBlue]="true" [class]="'is-loading'" *ngIf="!caseDetail?.is_submitted && !isEmailSending"
                (onClick)="sendEmailToMD()">{{sendEmailLabel}}</app-generic-button>
            <div class="btn btn--blue is-loading" *ngIf="isEmailSending">
                <span class="icon icon--loading "></span>
            </div>
            <app-generic-button 
                [isBlue]="caseDetail?.is_submitted && review?.state === 'INPROGRESS'"
                [isGrey]="!caseDetail?.is_submitted || review?.state !== 'INPROGRESS'"
                (onClick)="generatePDF()" *ngIf="!isReportGeneration && !isEditEnable">
                Finish
            </app-generic-button>

            <app-generic-button [isBlue]="true" *ngIf="isEditEnable" (onClick)="onEditSave(this.clinicalConsultationForm?.value)">Save</app-generic-button>

            <div class="btn btn--blue is-loading" *ngIf="isReportGeneration">
                <span class="icon icon--loading "></span>
            </div>
        </div>

    </div>
</div>
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LoginService } from '../../../services/login.service';

@Component({
  selector: 'app-outgoing-mail-server-header',
  templateUrl: './outgoing-mail-server-header.component.html',
  styleUrls: ['./outgoing-mail-server-header.component.css']
})
export class OutgoingMailServerHeaderComponent implements OnInit {
  @Input() totalRecord = 0;
  @Output() searchValueEmitter = new EventEmitter<string>();

  userRole: string;
  searchValue: string;

  constructor(
    private loginService: LoginService
  ) { }

  onModelChange(): void {
    this.searchValueEmitter.emit(this.searchValue);
  }

  ngOnInit(): void {
    const user = this.loginService.getCurrentUser();
    this.userRole = user.user_type.type.toLowerCase();
  }

}

/* eslint-disable max-len */
/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable no-undef */
import { fromEvent } from 'rxjs';
import { Component, OnInit, ViewChildren, QueryList, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { find } from 'lodash';

import * as moment from 'moment/moment';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { CommonFuntions } from '../../../common/common.function';
import { CaseService } from '../../../services/case.service';
import { PopUpService } from '../../../services/pop-up.service';
import { ConfigAssetLoaderService } from '../../../services/config-asset-loader.service';
import { LoginService } from '../../../services/login.service';
import { DatashareService } from '../../../services/datashare.service';
import { USER_ROLE_TYPE } from '../../../constant';
import { SEARCH_OPTIONS } from '../../../cases/translator-caselist/constant';


@Component({
  selector: 'app-mail-logs',
  templateUrl: './mail-logs.component.html',
  styleUrls: ['./mail-logs.component.css']
})
export class MailLoggerComponent implements OnInit {

  @ViewChild('consultationDate', { static: true })
  consultationDateInput: ElementRef

  @ViewChildren(DataTableDirective)
  datatableElement: QueryList<DataTableDirective>;
  dtOptions1: DataTables.Settings = {};

  searchValue = '';
  user: any;

  isInprogress: boolean;
  showDetails = false;
  currentData: any;
  allLogs = [];
  totalCounts = 0;
  triggerAll = false;
  currentDate = new Date();
  selectedDate: any;
  sendingMails = false;
  isDateError = false;
  mailFrom: string;
  mailCount = 0;
  step = 0;
  columnOptions= {}
  searchColumnName;
  searchColumnValue;
  filterIds = [];
  logTypes = [
    {
      id: 'Archive',
      name: 'Archive',
      checked: false
    }

  ];

  constructor(
    public renderer: Renderer2,
    public loginService: LoginService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public caseService: CaseService,
    public dataShare: DatashareService,
    public popUpService: PopUpService
  ) { 
    this.user = this.loginService.getCurrentUser();
    if (![USER_ROLE_TYPE.ADMIN, USER_ROLE_TYPE.CM_MANAGER].includes(this.user?.user_type?.type.toLowerCase())) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.isInprogress = true;
    this.dtOptions1 = this.getDtOption(null);
    this.columnOptions = (SEARCH_OPTIONS[`${this.user?.user_type?.type}`]);
  }

  addRemoveFilter(status: any, _event?: Event) {

    let isChecked;
    this.logTypes.forEach(element => {
      if (element.id === status.id) {
        element.checked = !element.checked;
        isChecked = element.checked;
      }
    });

    if (isChecked) {
      this.filterIds.push(status.id);
    }
    else {
      this.filterIds = this.filterIds.filter(element => {
        if (element !== status.id) return element;
      });
    }
    const url = ConfigAssetLoaderService.getApiURL() + '/mail-logs';
    this.datatableElement.toArray()[0].dtInstance.then(
      (dtInstance: DataTables.Api) => {
        // abort previous api call
        dtInstance.settings()[0].jqXHR.abort();
        dtInstance.ajax.url(url).load();
      }
    );
    this.isInprogress = false;
    this.clearSearch();
  }

  ngAfterViewInit(): void {
    this.renderer.listen('document', 'click', (event: any) => {
      if (event.target.hasAttribute('viewCase')) {
        const caseId = parseInt(event.target.getAttribute('viewCase'));
        console.log('viewcase', caseId);
        if (caseId) {
          this.router.navigate(['/case', caseId]);
        }
      }
      if (event.target.hasAttribute('viewData')) {
        const id = parseInt(event.target.getAttribute('viewData'));
        const data = find(this.allLogs, { id });
        if (data) {
          this.currentData = { ...data };
          this.toogleDetails();
        }
      }
      if (event.target.hasAttribute('triggerData')) {
        const id = parseInt(event.target.getAttribute('triggerData'));
        const data = find(this.allLogs, { id });
        if (data) {
          this.showConfirmation('Are you sure you want to re-send mail?', function () {
            this.triggerMails(id, true);
            this.popUpService.setLoading();
          }.bind(this));
        }
      }
    });
  }

  getDtOption(_list: string) {
    const url = ConfigAssetLoaderService.getApiURL() + '/mail-logs';

    const option = {
      ajax: {
        url: url,
        dataSrc: function (json) {
          this.totalCounts = json.recordsTotal;
          this.allLogs = json.data;
          this.isInprogress = false;
          return json.data;
        }.bind(this),
        type: 'GET',
        data: function (_d) {
          _d.status = this.filterIds;
          // send extra data
        }.bind(this),
        beforeSend: function (xhr) {
          if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
            xhr.abort();
            return;
          }
          this.errorString = null;
          const token = localStorage.getItem('token');
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }.bind(this)
      },
      pagingType: 'full_numbers',
      serverSide: true,
      processing: true,
      lengthChange: false,
      searching: true,
      pageLength: 10,
      stateSave: true,
      searchDelay: 350,
      stateDuration: 5 * 20,
      order: [[3, 'DESC']],
      info: false,
      language: {
        emptyTable: 'No data to show'
      },
      rowCallback: this.handleEvent.bind(this)
    };

    option['columns'] = [
      {
        title: 'Mail Type',
        render: function (_data, _type, row) {
          const deletedAt = moment(row.deleted_at);
          const formattedDateTime = deletedAt.format('MM/DD/YYYY') + ' at ' + deletedAt.format('hh:mm a');
          const  consultationValue = deletedAt.isValid() ? formattedDateTime : row.deleted_at;
          let  cell = '<div class="text text--bold" style="min-width: 200px;">' + row.mail_type + '</div>';
          if (consultationValue) {
            cell += '<div class="text--grey text--small">Archived</div>';
          }
          return cell;
        }
      },
      {
        title: 'To',
        orderable: false,
        render: function (_data, _type, row) {
          const cell = `<div class="text-failure">
            <p style="margin-top: 0px;">To: ${row.email_to}</p>
            ${(row.email_cc ? `<p style="margin-top: 0px;">Cc: ${row.email_cc}</p>` : '')}
            ${(row.email_bcc ? `<p style="margin-top: 0px;">Bcc: ${row.email_bcc}</p>` : '')}
          </div>`;
          return cell;
        }
      },
      {
        title: 'Case',
        render: function (_data, _type, row) {
          if (row.caseId) {
            const cell = `<div class="text text--bold" style="min-width: 100px;"><a href="javascript:void(0)" viewCase="${row.caseId}">${row.caseDetails.case_identifier}<a/></div>`;
            return cell;
          }
          else {
            return '';
          }
        }
      },
      {
        title: 'Created At',
        render: function (_data, _type, row) {
          const createdAt = moment(row.created_at);
          const formattedDateTime = createdAt.format('MM/DD/YYYY') + ' at ' + createdAt.format('hh:mm a');
          const consultationValue = createdAt.isValid() ? formattedDateTime : row.created_at;
          const cell = '<div  class="text text-center" style="width: 100px;">' + consultationValue + '</div>';
          return cell;
        }
      },
      {
        title: 'Status',
        orderable: false,
        render: function (_data, _type, row) {
          let cell = '';
          if (row?.status === 'sent') {
            cell = '<label class="contentText" style="color:green">Sent</label>';
          }
          else {
            cell = '<label class="contentText" style="color:red">Failed</label>';
          }
          return cell;
        }
      },
      {
        title: 'View Detail',
        orderable: false,
        render: function (_data, _type, row) {
          const cell = `<div class="text-center"> <a href="javascript:void(0)" class="btn btn--blue btn--mini margin--l-x" viewData="${row.id}">
          View Details
        </a> </div>`;
          return cell;
        }
      },
      {
        title: 'Action',
        orderable: false,
        render: function (_data, _type, row) {
          let cell = '';
          if (row.mail_type === 'RESET_PASSWORD') {
            return '';
          }
          if (row?.status === 'fail') cell = `<div class="text-center" ><a href="javascript:void(0)" class="btn btn--red btn--mini margin--l-x" triggerData="${row.id}">Try Again</a> </div>`;
          if (row?.status === 'sent') cell = '<div class="text-center"><span class="icon icon--green-tick" title="Already Executed"></span></div>';
          return cell;
        }
      }
    ];
    return option;
  }

  handleEvent(row: Node, _data: any[] | Object, _index: number) {
    // Unbind first in order to avoid duplicate handler
    $('td', row).unbind('click');
    $('td', row).bind('click', function () {

    }.bind(this));
  }

  /** Search only when the user stop typing the meaningful word
   * Search only when the previous query is different than current.
   */
  handleSearch(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const search$ = fromEvent(document.getElementById('caseSearch'), 'keyup');

  }

  toogleDetails(value?) {
    this.showDetails = value || !this.showDetails;
  }

  toggleTemplate(event) {
    console.log('event', event);
    this.toogleDetails(false);
  }

  reloadData(columnKey?: string, columnValue?: string, searchValue?: string) {
    const url = new URL(ConfigAssetLoaderService.getApiURL() + '/mail-logs');

    if (columnKey && columnValue && searchValue) {
      url.searchParams.append('columnKey', columnKey);
      url.searchParams.append('columnValue', columnValue);
      url.searchParams.append('searchValue', searchValue);
    }

    this.datatableElement.toArray()[0].dtInstance.then(
      (dtInstance: DataTables.Api) => {
        // abort previous api call
        dtInstance.settings()[0].jqXHR.abort();
        dtInstance.ajax.url(url.href).load();
      }
    );
    this.isInprogress = false;
  }

  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function) {

    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }

    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }

    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);

  }

  public showAlert(message: string, positiveAction?: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(positiveAction);
    }
    else {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) { this.popUpService.showDiv(dialog); }
  }

  onDateTimeChange(date, consultationDateInput) {
    let momentDate;

    if (date._selected) {
      momentDate = moment(date._selected);
    }
    else {
      momentDate = moment(date);
    }

    // comparing selecting date is greater than today


    const userAgent = window.navigator.userAgent;

    const browsers = { chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i };
    let selectedDate = '';
    for (const key in browsers) {
      if (browsers[key].test(userAgent)) {
        if (key == 'firefox') {
          selectedDate = momentDate.format('MM/DD/YYYY, h:mm a').substring(0, 24);
        }
        else {
          if (userAgent.toLowerCase().indexOf('safari') != -1) {
            if (userAgent.toLowerCase().indexOf('chrome') > -1) {
              selectedDate = momentDate.format().substring(0, 16);
            }
            else {
              selectedDate = momentDate.format('MM/DD/YYYY, h:mm a').substring(0, 24);
            }
          }
        }
        consultationDateInput.value = selectedDate;
        this.selectedDate = moment(selectedDate).format();
        console.log('selectedDate', this.selectedDate);
      }
    }
  }

  resetModal() {
    this.triggerAll = false;
    this.sendingMails = false;
    this.isDateError = false;
    this.mailCount = 0;
    this.step = 0;
  }

  checkMails() {
    this.isDateError = false;
    if (this.mailFrom) {
      switch (this.mailFrom) {
      case 'all':
        {
          console.log('trigger all');
          this.triggerMails('all', false);
        }
        break;
      case 'fromDate':
        {
          if (this.selectedDate) {
            console.log('trigger from');
            this.triggerMails(this.selectedDate, false);
          }
          else {
            this.isDateError = true;
          }
        }
        break;
      default:
        break;
      }
    }
  }
  sendMails() {
    if (this.mailFrom) {
      switch (this.mailFrom) {
      case 'all':
        {
          console.log('trigger all');
          this.triggerMails('all', true);
        }
        break;
      case 'fromDate':
        {
          if (this.selectedDate) {
            console.log('trigger from');
            this.triggerMails(this.selectedDate, true);
          }
          else {
            this.isDateError = true;
          }
        }
        break;
      default:
        break;
      }
    }
  }

  triggerMails(id, sendMail) {
    if (sendMail) {
      this.sendingMails = true;
    }
    this.caseService.resendMail(id, sendMail).subscribe((result: any) => {
      if (!sendMail) {
        this.step = 1;
        this.mailCount = result.mailCount || 0;
        if (this.mailCount == 0) {
          this.resetModal();
          this.showAlert('There is no pending mails to sent');
        }
      }
      else if (result.type === 'single'){
        this.popUpService.hideDiv('confirmDialog');
        this.resetModal();
        this.reloadData();
        this.showAlert('Mail sent successfully');
      }
    },
    (_error) => {
      this.popUpService.hideDiv('confirmDialog');
      const message = 'Failed to re-send mail.';
      this.resetModal();
      if (!Number.isNaN(+id)) {
        this.showAlert(message);
      }
    });
  }

  currentSearch(columnKey, columnValue, searchValue) {
    this.reloadData(columnKey, columnValue, searchValue);
    this.searchColumnName = columnKey;
    this.searchColumnValue = columnValue;
  }


  clearSearch(): void{
    this.searchColumnName = '';
    this.searchColumnValue = '';
    this.reloadData();

  }

}

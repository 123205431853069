/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigAssetLoaderService } from '../services/config-asset-loader.service';
import { Observable } from 'rxjs';

@Injectable()
export class ActivityService {

  constructor(
		private http: HttpClient
  ) { }

  private getTokenHeader() {
    const token = localStorage.getItem('token');

    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token })
    };
  }
  /**
   * Function to create note in the tracking lines
   * @param {number} caseid
   * @param {number} tlid
   * @param {string} message
   * @return {*}  {Observable<any>}
   * @memberof ActivityService
   */
  createTrackingLineActivity(payload): Observable<any> {
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + `/v1/case-tracking/activity/${payload.caseid}/${payload.tlid}`,
      { trackingLineNotes: payload.trackingLineNotes, fromCloud: true },
      this.getTokenHeader()
    );
  }

  /**
 * Function to fetch the tracking line notes
 * @return {*}  {Observable<any>}
 * @memberof ActivityService
 */
  getTrackingLineActivity(caseid: number, tlid:number): Observable<any> {
    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + `/case/tracking/activity/${caseid}/${tlid}`,
      this.getTokenHeader()
    );
  }

  getContainerColor(activity): string {
    if(activity.isComment) return 'white';
    else if (activity.activityEvent == 'CASE_STATUS' && activity.activityType =='CANCEL') return 'red';
    else if (activity.activityEvent == 'USER_PASSWORD_UPDATED') return 'orange';
    else if (activity.activityType == 'RESTORE') return 'orange';
    else if (activity.activityType =='CREATE') return 'green';
    else if (activity.activityType =='UPDATE') return 'grey';
    else if (activity.activityType == 'DELETE') return 'red';
    else if (activity.activityType == 'FAILED') return 'red';
    else return 'default';
  }

}

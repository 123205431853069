<!-- <app-dialog></app-dialog> -->

<div class="header">
  <div class="header__page-name">
    <h1 class="heading heading--main">Edit Case Assignment</h1>
  </div>
</div>
<div class="wrapper--dash-content dash-content overlay__wrapper">
    <div class="overlay__content">
      <div class="overlay__spinner">
        <span class="icon icon--loading"></span>
      </div>
    </div>
    <form [formGroup]="caseAssignmentForm">
      <div class="form tabs__content--white-bg">
        <div class="row">
          <div class="col col__md-8 gutter--right-3x">
              <div class="box box--grey box--radius">
                <div class="resume resume--centered">
                    <div class="contact-details__row" >
                      <div class="contact-details__label">Case Assignment ID</div>
                      <div class="contact-details__details">
                          {{ caseAssignmentDetail?.id }}
                      </div>
                    </div>
                    <div class="contact-details__row" >
                      <div class="contact-details__label">Case ID</div>
                      <div class="contact-details__details">
                          {{ caseAssignmentDetail?.case?.case_identifier }}
                      </div>
                    </div>
                    <div class="contact-details__row" >
                      <div class="contact-details__label">Patient Name</div>
                      <div class="contact-details__details">
                          {{ caseAssignmentDetail?.case?.patient?.user?.full_name }}
                      </div>
                    </div>
                    <div class="contact-details__row" >
                      <div class="contact-details__label">Assigned User </div>
                      <div class="contact-details__details">
                          {{caseAssignmentDetail?.user?.full_name }}
                      </div>
                    </div>
                    <div class="contact-details__row" >
                      <div class="contact-details__label">Assigned User Role </div>
                      <div class="contact-details__details">
                          {{ caseAssignmentDetail?.user_type_id === 4 ? 'Physician' : 'Summary Coordinator' }}
                      </div>
                    </div>
                    <div class="contact-details__row" >
                      <div class="contact-details__label">Assignment Type</div>
                      <div class="contact-details__details">
                          {{ caseAssignmentDetail?.assignment_type }}
                      </div>
                    </div>
                    <div class="contact-details__row" >
                      <div class="contact-details__label">Follow up Id</div>
                      <div class="contact-details__details">
                          {{caseAssignmentDetail?.follow_up_id }}
                      </div>
                    </div>
                    <div class="contact-details__row" >
                      <div class="contact-details__label">Created</div>
                      <div class="contact-details__details">
                          {{caseAssignmentDetail?.createdAt | date: "MM/dd/yyyy hh:mm a ZZZZ"}}
                      </div>
                    </div>
                    <div class="contact-details__row" >
                      <div class="contact-details__label">Updated</div>
                      <div class="contact-details__details">
                          {{caseAssignmentDetail?.updatedAt | date: "MM/dd/yyyy hh:mm a ZZZZ"}}
                      </div>
                    </div>
                    <div class="contact-details__row" >
                      <div class="contact-details__label">Status</div>
                      <div class="contact-details__details">
                          {{caseAssignmentDetail?.status}}
                      </div>
                    </div>
                    
                    <div class="contact-details__row"  *ngIf="caseAssignmentStatus !== 'DECLINED' &&  role!== 'mdmanager'" >
                      <div class="contact-details__label"> Change Status</div>
                      <div class="contact-details__details">
                            <select formControlName="status_type" (change)="onStatusChange($event.target.value)">
                                <option *ngFor="let item of statusTypes | keyvalue"
                                [value]="item.value"[selected]="statusTypes.SELECT">{{item.value}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
        </div>
      </div>

      <div class="btn-row text-center" *ngIf="role!== 'mdmanager'">
          <!-- Disabled the button when the user doesn't select the given options -->
          <button (click)="onSaveCaseAssignment($event)"  [disabled]="status_type.value =='-select-'" class="btn btn--green" >Save</button>
          <a class="btn btn--red"(click)="onCancelCaseAssignment()" >Cancel</a>
      </div>
    </form>
    <app-reject-case-template [name]="caseAssignmentDetail?.case?.case_identifier" [hideTemplate]="hideDeleteUserBox" (toggleTemplate)="toggleDeleteTemplate($event)"  [handleValueSubmit]="handleValueSubmit"></app-reject-case-template>
</div>


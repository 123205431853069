/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstString'
})
export class FirstStringPipe implements PipeTransform {

  transform(value: any, _args?: any): any {
    if(value){
      return value.split(' ')[0];
    }
  }

}


// apply_to constants are enum so please dont change until you change enum
const APPLY_TO= [
  {
    label: 'Case Assignment to SC',
    value: 'Case Assignment to SC'
  },
  {
    label: 'Case Assignment to DOC',
    value: 'Case Assignment to DOC'
  },
  {
    label: 'Video Case Assignment to DOC',
    value: 'Video Case Assignment to DOC'
  },
  {
    label: 'Follow up Case Assignment to SC',
    value: 'Follow up Case Assignment to SC',
  },
  {
    label: 'Follow up Case Assignment to DOC',
    value: 'Follow up Case Assignment to DOC',
  },
  {
    label: 'P2P Case Assignment to SC',
    value: 'P2P Case Assignment to SC',
  },
  {
    label: 'P2P Case Assignment to DOC',
    value: 'P2P Case Assignment to DOC',
  },
  {
    label: 'P2P Follow up Case Assignment to SC',
    value: 'P2P Follow up Case Assignment to SC',
  },
  {
    label: 'P2P Follow up Case Assignment to DOC',
    value: 'P2P Follow up Case Assignment to DOC',
  },
  {
    label: 'Case Assignment to CT',
    value: 'Case Assignment to CT'
  },
  {
    label: 'Message/Chatter to SC/DOC from CT',
    value: 'Message/Chatter to SC/DOC from CT'
  },
  {
    label: 'Case Retraction-No Action SC',
    value: 'Case Retraction-No Action SC'
  },
  {
    label: 'Case Retraction-No Action DOC',
    value: 'Case Retraction-No Action DOC'
  },
  {
    label: 'Case Retraction-Error SC',
    value: 'Case Retraction-Error SC'
  },
  {
    label: 'Case Retraction-Error DOC',
    value: 'Case Retraction-Error DOC'
  },
  {
    label: 'Message from one user to other',
    value: 'Message from one user to other',
  },
  {
    label: 'Careteam when Case Created by Careteam or Client Careteam',
    value: 'Careteam when Case Created by Careteam or Client Careteam'
  },
  {
    label: 'Client CT or CM CT when Case Created by Careteam or Client Careteam',
    value: 'Client CT or IMD CT when Case Created by Careteam or Client Careteam'
  },
  {
    label: 'Requested Stage',
    value: 'Requested Stage'
  },
  {
    label: 'Video Stage',
    value: 'Video Stage'
  },
  {
    label: 'Compile MR Stage',
    value: 'Compile MR Stage'
  },
  {
    label: 'Summary Stage',
    value: 'Summary Stage'
  },
  {
    label: 'Report Stage',
    value: 'Report Stage'
  },
  {
    label: 'MD after Scheduling is completed',
    value: 'MD after Scheduling is completed'
  },
  {
    label: 'Interpreter after Scheduling is completed',
    value: 'Interpreter after Scheduling is completed'
  },
  {
    label: 'Client Careteam after Scheduling is completed',
    value: 'Client Careteam after Scheduling is completed'
  },
  {
    label: 'MD submits a request for missing data',
    value: 'MD submits a request for missing data'
  },
  {
    label: 'Case Submission by MD',
    value: 'Case Submission by MD'
  },
  {
    label: 'MR Coordinator uploads Medical Record Summary',
    value: 'MR Coordinator uploads Medical Record Summary'
  },
  {
    label: 'Pre Release',
    value: 'Pre Release'
  },
  {
    label: 'Post Release',
    value: 'Post Release'
  },
];

const TEMPLATE_TAGS = [
  'CaseId',
  'CaseURL',
  'ZoomLink',
  'Filename',
  'Diagnosis',
  'Initials',
  'FirstName',
  'LastName',
  'Date/Time',
  'ClientCareTeam',
  'CareTeam',
  'MdMessage',
  'Sender'
];

export { APPLY_TO, TEMPLATE_TAGS };
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-spread */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CaseAssignmentService } from '../../services/case-assignment.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';



@Component({
  selector: 'app-edit-case-assignments',
  templateUrl: './edit-case-assignments.component.html',
  styleUrls: ['./edit-case-assignments.component.css']
})
export class EditCaseAssignmentsComponent implements OnInit {
  caseAssignmentForm: FormGroup;
  rejectCaseForm: FormGroup;
  caseAssignmentId: number;
  caseAssignmentDetail: any;
  caseAssignmentStatus: string;
  ShowCaseDeclineForm =  false;
  showOtherField = false;
  role: string;
  user: any;
  hideDeleteUserBox = true;
  disableSave = true;

  public statusTypes: any;

  public rejectReasons = {
    a: 'Not my area of expertise.',
    b: 'No Interest.',
    c: 'No Time.',
    d: 'Not Appropriate for 2nd Opinion.',
    e: 'Other'
  }
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    public caseAssignmentService: CaseAssignmentService,
    public loginService: LoginService,
    private toastrService :ToastrService
  ) { 
    this.activatedRoute.params.subscribe(
      params => {
        const { id } = params;
        this.caseAssignmentId = id; 
      }
    );
  }
  toggleDeleteTemplate(value: boolean): void {
    this.hideDeleteUserBox = value;
  }
  handleValueSubmit = (value: { reject_type: string; reject_reason: any; }): void =>  {
    const statusChangePayload= {
      statusType: 'DECLINED',
      rejectReason: value.reject_type === 'Other' ? value.reject_reason : value.reject_type,
      caseId: this.caseAssignmentDetail.case_id,
      userId: this.caseAssignmentDetail.user_id
    };
    console.log(statusChangePayload);
    
    this.caseAssignmentService.changeCaseAssignmentStatus(statusChangePayload).subscribe(
      _result =>{ 

        this.toastrService.success('Status change successful.');

      }
      , _error => {
        this.toastrService.error('Failed to save status change.');
   

      });
    this.router.navigate(['/case-assignments']);
  }
  
  /**
   * saves the user Case assignment 
   */
  onSaveCaseAssignment(): void {
    const statusChangePayload= {
      statusType: this.caseAssignmentForm.value.status_type,
      rejectReason: this.caseAssignmentForm.value.reject_reason || '',
      caseId: this.caseAssignmentDetail.case_id,
      userId: this.caseAssignmentDetail.user_id
    };
    
    this.caseAssignmentService.changeCaseAssignmentStatus(statusChangePayload).subscribe(
      _result =>{
        if(_result){
        
          this.toastrService.success('Status change successful.');
        }
      }
      , _error => {
        if(_error){
          this.toastrService.error('Failed to save status change.');
        }
        
      });

    this.router.navigate(['/case-assignments']);

  }

  initializeForm(): FormGroup {
    return this.fb.group({
      status_type: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.user = this.loginService.getCurrentUser();
    if (!this.user) {
      throw new Error('No user logged in');
    }
    this.role = this.user.user_type.type.toLowerCase();
    this.caseAssignmentForm = this.initializeForm();
    this.getCaseAssignmetDetail(this.caseAssignmentId);
  }


  onCancelCaseAssignment(): void {
    this.router.navigate(['/case-assignments']);
  }

  /**
   * Creates a modal when user choose the options the selects box
   * @param value
   */
  onStatusChange(value: any): void {
  
    this.disableSave = false;
    if (value === this.statusTypes.REJECT){
      swal.fire({
        input: 'select',
        html: 'Case : '+ this.caseAssignmentDetail?.case?.case_identifier+'<hr>Please let us know why you are <span class="badge badge-secondary">DECLINING</span> this case.</h5> <br><br>' ,
        reverseButtons: true,
        inputOptions: this.rejectReasons,
        backdrop: '#eef3f6',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: 'green' ,
        title: 'Decline case',
        confirmButtonText: 'Decline case',
        iconColor: 'orange' ,
        cancelButtonColor: '#db161e',
        inputValidator: (value: string) => {
          return new Promise((resolve) => {
            if (value != '' || value !=null) {
              this.onSaveCaseAssignment();
              resolve('');
            }
            else {
              resolve('You need to select');
            }
          });
        },
      });
    
      this.disableSave = true;
    }
    else { 
      if (this.caseAssignmentForm.get('reject_reason')) this.caseAssignmentForm.removeControl('reject_reason');
    }
  }
  handleReasonChange(value: string): void {
    if (value === this.rejectReasons.e) {
      this.caseAssignmentForm.addControl('reject_reason', new FormControl('',[Validators.required,Validators.maxLength(500)]));
      this.showOtherField = true;
    }
    else {
      this.caseAssignmentForm.value.reject_reason = value;
      this.showOtherField = false;
      if (this.caseAssignmentForm.get('reject_reason')) this.caseAssignmentForm.removeControl('reject_reason');
    }
  }
  getCaseAssignmetDetail(id: number): void {
    this.caseAssignmentService.fetchCaseAssignment(id).subscribe(
      _result => {
        this.caseAssignmentDetail = _result.data[0];
        this.caseAssignmentStatus = _result.data[0]?.status;
        if(this.caseAssignmentStatus === 'ACCEPTED'){
          this.statusTypes = {
            SELECT: '-select-',
            REJECT: 'DECLINED'
          };
        }
        else if(this.caseAssignmentStatus === 'DECLINED'){
          this.statusTypes = {
            SELECT: '-select-',
          };
        }
        else{
          this.statusTypes = {
            SELECT: '-select-',
            ACCEPT: 'ACCEPTED',
            REJECT: 'DECLINED'
          };
        }
      },
      _error => {
        console.log(_error);
      }
    );
  }
  get status_type(): FormControl {
    return this.caseAssignmentForm.get('status_type') as FormControl;
  }
  get reject_reason(): FormControl {
    return this.caseAssignmentForm.get('reject_reason') as FormControl;
  }

}

export const WEBHOOK_LABELS = {
  ON_CARETEAM_ASSINGED: 'Careteam Assigned Trigger',
  ON_NEW_MESSAGE: 'New Message Trigger',
  ON_QUESTIONS_UPDATE: 'Questions Update Trigger', 
  ON_SUMMARY_STARTED: 'Summary Started Trigger',
  ON_FINAL_REPORT_READY: 'Final Report Ready Trigger',
  ON_VIDEO_CONSULTATION_SCHEDULED: 'Video Consultation Scheduled',
  ON_VIDEO_CONSULTATION_COMPLETED: 'Video Consultation Completed',
  ON_MR_COLLECTION_COMPLETE: 'MR Collection Complete',
  ON_TRACKING_LINE_NOTE: 'Tracking Line Note',
  ON_DOCTOR_ASSIGNED_OR_REASSIGNED: 'Doctor Assigned Or Reassigned',
  ON_CARETEAM_SUMMARY_STAGE_ASSIGNED: 'Careteam Assigned Summary Stage',
  ARCHIVED: 'Archived'
};
  
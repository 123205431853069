/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordService } from '../services/password.service';
import * as moment from 'moment/moment';
import { PopUpService } from '../services/pop-up.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonFuntions } from '../common/common.function';
import { UserService } from '../services/userservice.service';
import { LoginService } from '../services/login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit ,AfterContentChecked{
  resetToken: string
  resetForm: FormGroup
  isExpired: boolean
  forcePasswordReset: boolean
  isResetingPassword: boolean
  logoPath: string;
  type: string;
  message: any;
  showError: boolean;
  new_password_type = 'password';
  confirm_password_type ='password'


  constructor(
    private route: ActivatedRoute,
    private passwordService: PasswordService,
    private popUpService: PopUpService,
    private userService: UserService,
    private router: Router,
    private loginService: LoginService,
    private changeDetection: ChangeDetectorRef
  ) {
  }
  ngAfterContentChecked(): void {
    this.changeDetection.detectChanges();
  }

  showPasswordResetAlert():void{
    const user = this.loginService.getCurrentUser();
    this.loadUser(user?.user_id);
  }
  loadUser(user_id : number): void{
	  this.userService.getUserForPasswordReset(user_id).subscribe(
	    user => {
        if(user.force_password_reset== true){
          Swal.fire({
            html: '<hr><p>Please change your password to proceed.</p>',
            title: 'Alert',
            backdrop: '#eef3f6',
            reverseButtons: true,
            iconColor: 'orange',
            allowOutsideClick: false,
            icon: 'warning',
            showClass: {
              backdrop: 'swal2-noanimation', // disable backdrop animation
              popup: '',                     // disable popup animation
              icon: ''                       // disable icon animation
            },
            showCancelButton: false,
            showDenyButton: true,
            confirmButtonColor: '#249533',
            confirmButtonText: 'Proceed',
            denyButtonText: 'Logout'
          }).then((result)=>{
            if(result.isDenied){
              localStorage.removeItem('token');
              this.router.navigateByUrl('/login');
              Swal.close();
            }
            if(result.isConfirmed){
              this.router.navigateByUrl('/reset/password');
            }
          });
        }
	      
		  
	  });
	  }

  ngOnInit() {
    this.showPasswordResetAlert();
    this.isExpired = true;
    this.forcePasswordReset = false;
    this.isResetingPassword = false;
    this.logoPath = '/assets/images/logos/Alight_H.png';

    this.route.queryParams.subscribe(
      params => {
        this.resetToken = params['tokenreset'];
        this.type = params['type'];

        if (this.resetToken) {
          this.checkToken();
        }
        else {
    
          // if (user.user_type.type.toLowerCase() !== 'patient') {
          //   this.router.navigate(['/']);
          // }

          if (!this.type) this.checkForceReset();
          if (this.type) this.forcePasswordReset = true;
        }
      }
    );

    this.resetForm = this.getResetForm();

  }

  private checkForExpiration(result) {

    const currentMoment = moment().unix();

    if ((currentMoment > result.expiresIn
      || result.isExpired)) {
      return true;
    }

    return false;

  }

  private checkToken() {
    this.passwordService.checkToken(
      this.resetToken
    ).subscribe(
      result => {
        if (result.status && result.status == 200) {
          this.isExpired = this.checkForExpiration(result);

          if (this.isExpired) {
            this.showAlert(
              'The token is invalid or has already expired. Please request for a new link by clicking forgot password.',
              true,
              function () {
                this.popUpService.hideDiv('alertDialog');
                this.router.navigateByUrl('/login');
              }.bind(this)
            );
          }

        }
      },
      (_error) => {

        this.showAlert(
          'The token is invalid or has already expired. Please request for a new link by clicking forgot password.',
          true,
          function () {
            this.popUpService.hideDiv('alertDialog');
            this.router.navigateByUrl('/login');
          }.bind(this)
        );

      }
    );
  }

  private checkForceReset() {
    this.userService.checkForcePassowordReset().subscribe(
      result => {
        this.forcePasswordReset = result;

        if (!result) {
          this.router.navigate(['/']);
        }
      },
      (_error) => {
        this.router.navigate(['/login']);
      }
    );
  }

  private showAlert(message: string, hasPositiveAction?: boolean, callBackFunction?: Function) {


    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok',
    );

    if (hasPositiveAction) {

      if (callBackFunction) {
        this.popUpService.setPositiveAction(
          callBackFunction
        );
      }
      else {
        this.popUpService.setPositiveAction(
          function () {
            this.router.navigate(['/']);
          }.bind(
            this
          )
        );
      }
    }
    else {
      this.popUpService.setPositiveAction(
        function () {
          this.hideDiv('alertDialog');
        }
      );
    }

    const alertDialog = this.popUpService.getPopUpView('alertDialog');
    this.popUpService.showDiv(alertDialog);

  }

  private getResetForm() {

    return new FormGroup(
      {
        newPassword: new FormControl(
          '',
          {
            validators: [
              Validators.required,
              Validators.minLength(8),
              CommonFuntions.ValidatePassword
            ],
            updateOn: 'change'
          }
        ),
        confirmPassword: new FormControl(
          '',
          [
            Validators.required,
            Validators.minLength(8)
          ]
        ),
        token: new FormControl(null)
      }
    );

  }

  public changePassword() {

    if (this.resetForm.valid) {

      const newPassword = this.newPassword.value;

      const confirmPassword = this.confirmPassword.value;

      if (newPassword !== confirmPassword) {

        this.showAlert(
          'Passwords do not match',
          false
        );

        return;
      }

      this.resetForm.get('token').setValue(
        this.resetToken
      );

      this.callPasswordChange();

    }
    else {

      CommonFuntions.validateAllFormFields(
        this.resetForm
      );

    }

  }

  private callPasswordChange() {

    this.isResetingPassword = true;

    const data = { ...this.resetForm.value };
    if (this.type === 'expiryToken') {
      data.expiryToken = localStorage.getItem('expiryToken');
    }
    this.showError = false;
    this.passwordService.changePassword(data).subscribe(
      (_result) => {
        this.showAlert(
          'Password has been changed successfully',
          true,
          function () {
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('user_partners');
            localStorage.removeItem('expiryToken');
            this.popUpService.hideDiv('alertDialog');
            this.router.navigate(['/']);
          }.bind(this)
        );
        localStorage.removeItem('passwordExpirySetting');
        this.isResetingPassword = false;

      },
      error => {
        if(error.status == 401){
          this.message = error.error.message;
          this.showError= true;
          setTimeout(()=>{
            this.showError = false;
          },4000);
          return;
        }
        if (error.status == 400) {
          this.showAlert(
            'The link has expired or is invalid',
            true,
            function () {
              this.popUpService.hideDiv('alertDialog');
              this.router.navigateByUrl('/login');
            }.bind(this)
          );
          localStorage.removeItem('token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('user_partners');
          this.isResetingPassword = false;

          return;
        }

        if (error.error && error.error.type && error.error.type === 'OLD_PASSWORD') {
          return this.showAlert(
            error.error.message,
            false
          );
        }

        this.showAlert(
          'Failed to change password.',
          false
        );

        this.isResetingPassword = false;

      }
    );

  }

  get newPassword() {
    return this.resetForm.get('newPassword');
  }

  get confirmPassword() {
    return this.resetForm.get('confirmPassword');
  }

  
}

<div class="box box--white box--no-pad box--radius margin--bottom-2x overlay__wrapper"
	[class.overlay--visible]="isInProgress">
	<div class="overlay__content">
		<div class="overlay__spinner">
			<span class="icon icon--loading"></span>
		</div>
	</div>
	<div class="box__title case-section-box">
		<div class="box-title-left">
			<div class="left-title" *ngIf="page.toLowerCase() === 'translate-wr'">CompileWR</div>
			<div class="left-title" *ngIf="page.toLowerCase() === 'translate-dr'">TranslateDR</div>
			<div class="left-status" *ngIf="page.toLowerCase() === 'translate-wr'">
				<span class="icon icon--calendar"></span>Assigned {{translatorWrAssignee?.created_at | amCalendar}}
			</div>
			<div class="left-status" *ngIf="page.toLowerCase() === 'translate-dr'">
				<span class="icon icon--calendar"></span>Assigned {{translatorDrAssignee?.created_at | amCalendar}}
			</div>
		</div>
		<div class="box-title-right">
			<div class="flexDisplay">
				<div class="mr10">
					<div class="right-name">{{caseDetail?.case_owner?.full_name}}</div>
					<div class="right-status margin--top-x">Case Owner</div>
				</div>
				<div class="mr10">
					<div class="right-name" *ngIf="page.toLowerCase() === 'translate-wr'">
						{{translatorWrAssignee?.user.full_name}}</div>
					<div class="right-name" *ngIf="page.toLowerCase() === 'translate-dr'">
						{{translatorDrAssignee?.user.full_name}}</div>
					<div class="right-status margin--top-x">Assignee</div>
				</div>
				<div>
                    <a href="javascript:void(0)" class="go-back pull-right collapseToggle ml20"
                        [ngClass]="{collapseToggleActive: isTranslateDrCollapse===true}"
                        (click)="isTranslateDrCollapse = !isTranslateDrCollapse">
                        <span class="icon icon--time-nav"></span>
                    </a>
                </div>
			</div>

		</div>

	</div>
	<div [hidden]="!isTranslateDrCollapse">
		<form [formGroup]="translateWRDR">
			<div class="padding-2x box--md-mgmt form">
				<div class="form__row">
					<div class="col col__md-2">
						<div class="form__label is-uppercase">Description</div>
					</div>
					<div class="col col__md-10">
						<div class="form__field">
							<div class="text" *ngIf="page.toLowerCase() === 'translate-wr'"> Complete translation of the DR
								Responses to the Patient Questions </div>
							<div class="text" *ngIf="page.toLowerCase() === 'translate-dr'">Compile the final report from
								the DR’s review to submit for the patient. Follow the proper template for each partner.
							</div>
							<!-- <div class="form__field checkbox--in-row" *ngIf="page.toLowerCase() === 'translate-wr'">
								<div class="form__field--checkbox">
									<input type="checkbox" formControlName="translate_wr_verified" />
									<label for="option">Verify Written Translation</label>
								</div>
							</div>
							<div class="form__field checkbox--in-row" *ngIf="(page.toLowerCase() === 'translate-dr') && caseDetail?.final_reports.length < 1">
								<div class="form__field--checkbox">
									<input type="checkbox" formControlName="translate_dr_verified" />
									<label for="option">Verify Doctor Report Translation</label>
								</div>
							</div> -->
						</div>
					</div>
				</div>
	
	
				<!-- <div class="form__row">
					<div class="col col__md-2">
						<div class="form__label is-uppercase">Written Report</div>
					</div>
					<div class="col col__md-10">
						<div class="form__field">
							<div *ngFor="let document of documents; let i = index">
								<div class="box box--grey box--radius margin--bottom-2x" (click)="downloadFile(document.id, $event)">
									<div class="row">
										<div class="col col__md-8 gutter--right-3x upload-row__file-name">
											<div class="file-name">{{document.document_name}}</div>
											<div class="status-row__size-status">
												{{document.size}} kb
											</div>
										</div>
										<div class="col col__md-4 upload-row__action">
											<a href="#" class="link-btn is-uppercase" (click)="deleteUpload(i, $event)">Delete</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> -->
	
				<div class="form__row" *ngIf="page === 'translate-wr'">
					<div class="col col__md-2">
						<div class="form__label is-uppercase">Written Report</div>
					</div>
					<div class="col col__md-10">
						<div class="form__field">
							<div class="form__field">
								<div class="form__field form__field--width-half"
									*ngFor="let document of caseDetail?.translated_document">
									<input type="text" [value]="document.document_name" disabled="disabled" />
									<span (click)="downloadFile(document.id, document.document_name, $event)"><a
											href="#">Download</a></span>
								</div>
							</div>
						</div>
					</div>
				</div>
	
				<div class="form__row" *ngIf="page === 'translate-dr'">
					<div class="col col__md-2">
						<div class="form__label is-uppercase">Final Report</div>
					</div>
					<div class="col col__md-10">
						<div class="form__field">
							<div class="form__field">
								<div class="form__field form__field--icon-download"
									*ngFor="let finalReport of finalReportList">
								
									<div *ngIf="finalReport?.document_name !== caseDetail?.case_identifier+'_Consultation_Summary_Test.pdf'">
										<input type="text" [value]="finalReport.document_name" disabled="disabled" />
										<span (click)="downloadFile(finalReport.id, finalReport.document_name, $event)"><a
												href="#" class="icon icon--download"></a></span>
										<div class="text text--small text--red">Submitted {{finalReport.created_at |
											amDateFormat: 'MM/DD/YYYY'}} at {{finalReport.created_at | amDateFormat: 'hh:mm A'}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
	
				<div class="form__row">
					<div class="col col__md-2">
						<div class="form__label is-uppercase">Notes</div>
					</div>
					<div class="col col__md-10">
						<div class="form__field">
							<textarea formControlName="notes"
								[attr.disabled]="(page === 'translate-wr' && translatorWrAssignee.state === 'FINISHED') || (page === 'translate-dr' && translatorDrAssignee.state === 'FINISHED') || disableInputs? '': null"></textarea>
							<div class="message message--is-invalid" *ngIf="notes.touched && notes.invalid">
								<div *ngIf="notes.errors.maxlength">The field must not exceed more than
									{{notes.errors.maxlength.requiredLength}} characters.</div>
							</div>
						</div>
					</div>
				</div>
				<div class="btns__row text-right" *ngIf="!disableInputs">
					<app-generic-button [isBlue]="true" (onClick)="onFinished()"
						*ngIf="!(page === 'translate-wr' && translatorWrAssignee.state === 'FINISHED') && !(page === 'translate-dr' && translatorDrAssignee.state === 'FINISHED') && !isInProgress">Finish</app-generic-button>
					<app-generic-button [isGrey]="true"
						*ngIf="(page === 'translate-wr' && translatorWrAssignee.state === 'FINISHED') || (page === 'translate-dr' && translatorDrAssignee.state === 'FINISHED')  && !isInProgress">Finish</app-generic-button>
					<div class="btn btn--blue is-loading" *ngIf="isInProgress">
						<span class="icon icon--loading "></span>
					</div>
				</div>
	
			</div>
		</form>
	</div>
</div>
<app-dialog></app-dialog>
<div class="header">
    <div class="header__page-name">
        <h1 class="heading heading--main">Release Notice Settings</h1>
    </div>
</div>
<div class="row">

    <div class="col-md-8" >
        <div class="wrapper--dash-content dash-content overlay__wrapper">

            <div class="tabs__content" >
                <div class="box box--white overlay__wrapper box--radius box--margin-bottom-2x" style="height: 100vh">
                    <div class="spinner-bordertext-dark" role="status" >
                        <span class="sr-only">Loading...</span>
                      </div>
                    <div class="contact-details" *ngIf="!loading">
                        <h3 class="settingTitle">1. Enable/Disable Release Notice</h3>
                        <div class="contact-details__row">
                            <div class="contact-details__label">You can configure the release notice</div>
                            <div class="contact-details__details">
                                <div class="form__field">
                                    <label class="switch">
                                        <input type="checkbox" [disabled]="!created_at || !update_at" [(ngModel)]="selected" (ngModelChange)="enableDisableNotice($event)" />
                                        <div class="slider round">
                                            <span class="on">Enabled</span>
                                            <span class="off">Disabled</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>

                    <div class="col-md-7 mb-5 mx-auto mt-5" >
                        <h1 class="text-center">Create Notice </h1>
                        <div [class.overlay--visible]="true" *ngIf="loading">
                            <div class="overlay__content">
                               <div id="casetable_active_processing" class="dataTables_processing" role="status">
                                  <div><div></div><div></div><div></div><div></div></div>
                               </div>
                            </div>
                         </div>
                        <form #editReleaseForm="ngForm" class="form col-md-12" novalidate>
                            <div class="form__field mt-2">
                                <label for="release_priority">* Release Priority:</label>
                                <select ngModel #releasePriority="ngModel" [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="release_priority" class="form-control mt-2" name="release_priority"
                                    id="release_priority" required>
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                    <option value="High">High</option>
                                </select>
                            </div>
                            <div *ngIf="releasePriority?.invalid && (releasePriority?.dirty || releasePriority?.touched)"
                                class="alert alert-danger mt-2">
                                <div *ngIf="releasePriority?.errors?.required">Release Priority field is required</div>
                            </div>     
                            <div class="form__field mt-2">
                                <label for="subject">* Subject:</label>
                                <input type="text" maxlength="70" [class.is-invalid]="updateSubject?.invalid && (updateSubject?.dirty || updateSubject?.touched)" placeholder="Enter your subject" ngModel #updateSubject="ngModel"
                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="subject" class="form-control mt-2" name="subject"
                                    id="subject" required>
                            </div>
                            <div *ngIf="updateSubject?.invalid && (updateSubject?.dirty || updateSubject?.touched)"
                                class="alert alert-danger mt-2">
                                <div *ngIf="updateSubject?.errors?.required">Subject field is required</div>
                            </div>
<!-- 
                                <div class="form__field mt-2">
                                    <label for="body">* Content : </label>
                                    <textarea type="text" placeholder="Enter your content" class="form-control mt-2"
                                        #updateContent="ngModel" [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="content" cols="300" rows="20" name="content" id="content">
                                </textarea>

                                    <div *ngIf="updateContent?.invalid && (updateContent?.dirty || updateContent?.touched)"
                                        class="alert alert-danger mt-2">
                                        <div *ngIf="updateContent?.errors?.required">Content field is required</div>
                                    </div>
                                </div> -->
                                <div class="row mx-auto justify-content-center d-flex">
                                    <!-- <button type="submit" class="btn btn--blue mt-3 col-md-4"
                                        [swal]="updateNoticeModal">Update</button> -->
                         
                                        <button type="submit" class="btn  btn--full-width btn--green mt-5 col-md-4"
                                        [swal]="saveNoticeModal">Save</button>

                                    <!-- <swal #updateNoticeModal (confirm)="saveNotice(true)" title="Confirmation"
                                        icon="warning" iconColor="orange" closeOnConfirm="true" closeOnCancel="true"
                                        backdrop="#eef3f6" cancelButtonColor="#db161e" reverseButtons="true"
                                        showCancelButton="true" confirmButtonColor="green" confirmButtonText="Continue">
                                        <swal-text *swalPortal>Are you sure you want update release notice ?</swal-text>
                                    </swal> -->

                                    <swal #saveNoticeModal (confirm)="saveNotice(false)" title="Confirmation"
                                        icon="warning" iconColor="orange" closeOnConfirm="true" closeOnCancel="true"
                                        backdrop="#eef3f6" cancelButtonColor="#db161e" reverseButtons="true"
                                        showCancelButton="true" confirmButtonColor="green" confirmButtonText="Continue">
                                        <swal-text *swalPortal>Are you sure you want create new release notice
                                            ?</swal-text>
                                    </swal>


                                </div>


                           
                        </form>



                    </div>

                </div>

            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="wrapper--dash-content dash-content overlay__wrapper">
            <div [class.overlay--visible]="true" *ngIf="loading">
                <div class="overlay__content">
                   <div id="casetable_active_processing" class="dataTables_processing" role="status">
                      <div><div></div><div></div><div></div><div></div></div>
                   </div>
                </div>
             </div>
            <div class="tabs__content">
                <div class="box box--white overlay__wrapper box--radius box--margin-bottom-2x" style="height: 100vh">
                    <h4 class="text-center mt-5">Recent Release Notice Information</h4>
                    <hr>
                    <table class="table table-striped " *ngIf="created_at">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Release Date</td>
                                <td>{{created_at | date:'medium'}}</td>
                            </tr>
                            <tr *ngIf="is_update">
                                <td>Updated Date</td>
                                <td>{{update_at | date:'medium'}}</td>
                            </tr>
                            <tr>
                                <td>Notice Visible</td>
                                <td [innerText]="selected ? 'Enabled' : 'Disabled'"></td>
                            </tr>
                            <tr>
                                <td>Priority</td>
                                <td [innerText]="priority"></td>
                            </tr>
                            <tr>
                                <td>Subject</td>
                                <td [innerText]="current_subject"></td>
                            </tr>
                        </tbody>



                    </table>
                    <h5 class="mt-5 text-center" *ngIf="!created_at">No release notice was created</h5>
                </div>
            </div>

        </div>

    </div>


</div>
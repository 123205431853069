/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { IStatusModel } from '../../../common/interfaces/IStatusModel';
import { Status } from '../../../common/model/Status';
import { HistoryModel } from './HistoryModel';

class StatusModel extends HistoryModel implements IStatusModel {
    status: Status;
    status_id: number| CaseStatusEnum;
    user: Object;
    created_at: string;
    updated_at: string;
    follow_up_id: number;
    isHold: boolean
    finishedList: boolean;
    state: string;

    constructor(source?: Object) {
      super();

      if (source && source != null) {
        this.status = source['status'];
        this.status_id = source['status_id'];
        this.user = source['user'];
        this.created_at = source['created_at'];
        this.updated_at = source['updated_at'];
        this.follow_up_id = source['follow_up_id'];
        this.state = source['state'];
      }

      this.isComment = false;
      this.setHoldStatus(this.status_id);
    }

    public setFinishedList(isSet: boolean) {
      this.finishedList = isSet;
    }

    public getStatus(): string {
      return this.status.status;
    }

    public setStatus(status: string) {
      this.status.status = status;
    }

    public getAsigneeFullName(): string {
      return this.user != null ? this.user['full_name'] : '';
    }

    public setHoldStatus(status_id: number | CaseStatusEnum) {
      if (status_id == CaseStatusEnum.HOLD) {
        this.isHold = true;
        return;
      }

      this.isHold = false;
    }

    public static sort(list: StatusModel[]) {
      list.sort(function (status1, status2) {
        // if stage is requested or mdmgmt compare status_id and sort
        if (status1.status_id < 2 && status2.status_id < 2) {
          if (status1.status_id > status2.status_id) {
            return 1;
          }
          else if (status1.status_id < status2.status_id) {
            return -1;
          }
        }

        // if any of the stages is requested or mdmgmt do not compare
        if (status1.status_id < 2 || status2.status_id < 2) {
          if (status1.status_id < 2) {
            return -1;
          }
          else {
            return 1;
          }
        }

        if (status1.follow_up_id < status2.follow_up_id) {
          return -1;
        }
        else if (status1.follow_up_id > status2.follow_up_id) {
          return 1;
        }
        else {
          if (status1.status_id > status2.status_id) {
            return 1;
          }
          else if (status1.status_id < status2.status_id) {
            return -1;
          }
          return 0;
        }

      });
      return list;
    }
}

enum CaseStatusEnum {
    HOLD='HOLD',
    REQUESTED='REQUESTED',
    CANCELLED='CANCELLED'
}

export {StatusModel, CaseStatusEnum};

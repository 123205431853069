<div class="header">
	<div class="header__page-name">
		<div class="row">
			<div class="col-md-3">
				<h1 class="heading heading--main">Cases</h1>
			</div>
			<div class="col-md-6" *ngIf="is_visible">
				<div class="container-release-notice-banner">
					<div class="col-md-12">
						<div class="row">
							<div class="col-md-1 offset-1">
                                <h4 class="mt-3">
                                    <i class="fa fa-info-circle mr-5 blink_me"></i>
                                </h4>
							</div>
							<div class="col-md-10" >
								<p >{{subject}}</p>
							</div>
							<!-- <div class="col-md-2" *ngIf="priority">
								<span class="badge badge-green priority">{{priority}}</span>
							</div> -->
							<!-- <div class="col-md-2" *ngIf="content">
								<span [swal]="releaseNotice" id="read-more">Read More</span>
							</div> -->
						</div>
						
					</div>
				</div>
				<!-- <swal #releaseNotice title="{{subject}}" icon="warning" iconColor="red" closeOnConfirm="true"
					closeOnCancel="true" backdrop="#eef3f6" buttons="false" confirmButtonColor="#DB161E"
					confirmButtonText="X Close">
					<swal-text *swalPortal>
						<hr>
						<div *ngIf="is_update">
							<span class="badge badge-secondary">Updated:</span>
							<p class="text-center mt-2">Updated time: {{update_at | date:'short'}}</p>
						</div>
						<div *ngIf="!is_update">
							<span class="badge badge-secondary">New</span>
							<p class="text-center mt-2">Created time: {{created_at | date:'short'}}</p>
						</div>
						<br>
						 <textarea class="form-control" rows="30" readonly>
							{{content}}
						 </textarea>
						<hr>
					</swal-text>
				</swal> -->
			</div>
			<div class="col-md-3">
				
			</div>
		</div>
		<!-- <a href="#" class="btn btn--blue logout_btn" (click)="logout($event)"
		*ngIf="isSalesForceLogin()">Logout</a> -->
	</div>

</div>

<router-outlet></router-outlet>
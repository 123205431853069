<app-dialog></app-dialog>

<div class="header">
  <div class="header__page-name">
    <h1 class="heading heading--main">Zoom Settings</h1>
  </div>
</div>

<div class="wrapper--dash-content dash-content">
    <div class="form tabs__content--white-bg">
      <div class="row">
        <form [formGroup]="zoomConfigForm">
            <h4 class="form__subtitle">Host List</h4>
            <div formArrayName="email_host">
                    <div class="row" *ngFor="let host of email_host.controls; let i=index" [formGroupName]="i">
                    <div class="col col__md-6 gutter--right-3x">
                        <label class="form__label">Email</label>
                        <span *ngIf="((mailSettings === null || mailSettings?.email_host === null) && !isEdit)" class="label--bold">Please add a host.</span>
                        <div class="form__field margin--bottom-x">
                            <input *ngIf="isEdit" id="host_name" formControlName="host_name"/>
                            <span *ngIf="!isEdit" class="label--bold">{{host.get('host_name').value}}</span>
                            <div *ngIf="host.get('host_name').touched && host.get('host_name').invalid" class="message message--is-invalid">
                                <div *ngIf="host.get('host_name').errors.required">This field is required.</div>
                                <div *ngIf="host.get('host_name').errors.email && !host.get('host_name').errors.required">Please enter a valid email address</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col__md-2" *ngIf="isEdit">
                        <button type="button" (click)="removeHostAt(i)" *ngIf="email_host.controls.length > 1" class="btn btn--red btn--small margin--top-2x">X</button>
                    </div>
            </div>
            </div>   
            <div class="btns-row margin--top-x" *ngIf="isEdit">
                <a href="javascript:void(0)" class="btn btn--blue" (click)="addAnotherHost()">Add New Host</a>
            </div>
			<div class="form--two-cols">
                <div class="hr hr--space-down-2x hr--space-up-2x"></div>
                <h4 class="form__subtitle">Zoom Config</h4>
                <div class="row margin--bottom-x">
                    <div class="col col__md-6 gutter--right-3x">
                        <label for="" class="form__label">Zoom Key</label>
                        <div class="form__field">
                            <input *ngIf="isEdit" id="zoom_key" formControlName="zoom_key"/>
                            <span *ngIf="!isEdit" class="label--bold">{{zoomKey.value}}</span>
                            <span *ngIf="(mailSettings === null || mailSettings?.zoom_key === null) && !isEdit" class="label--bold">Please add a key.</span>
                            <div *ngIf="zoomKey.touched && zoomKey.invalid" class="message message--is-invalid">
                                <div>This field is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col__md-6 gutter--right-3x">
                        <label for="" class="form__label">Secret Key</label>
                        <div class="form__field">
                            <div class="input-group" id="show_hide_password" *ngIf="isEdit">
                                <input  type="password"  id="secret_key" formControlName="secret_key" class="form-control"/>
                                <button type="button" class="btn btn-secondary" (click)="$event.preventDefault()" appPasswordToggle>
                                  <span [ngClass]="password_type == 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'"></span>
                                </button>
                              </div>
                              
                            <span *ngIf="!isEdit" class="label--bold">{{secretKey.value}}</span>
                            <span *ngIf="(mailSettings === null || mailSettings?.secret_key === null) && !isEdit" class="label--bold">Please add a key.</span>
                            <div *ngIf="secretKey.touched && secretKey.invalid" class="message message--is-invalid">
                                <div>This field is required.</div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
            <div class="hr hr--space-down-2x hr--space-up-2x"></div>
            <div class="btns-row margin--top-2x">
                <a *ngIf='isEdit' class="btn btn--green" (click)="onSubmit()">Save</a>
                <a *ngIf='isEdit' class="btn btn--red" (click)="toggleEdit()">Cancel</a>
                <a *ngIf='!isEdit' class="btn btn--blue" (click)="toggleEdit()">Edit</a>
              
            </div>
        </form>
        </div>
        </div>
</div>
<app-dialog></app-dialog>
<div class="wrapper wrapper--login">

	<div class="app-logo" style="padding: 0px;">

		<img [src]="logoPath" />

	</div>

	<div class="row login">

		<div class="col col__md-5 login__form">

			<div class="login__form-content">

				<h2 class="heading heading--main heading--login" style="padding-left: 0px;">Sign In</h2>

				<div class="form">

					<form #f="ngForm" (submit)="onSubmit(f)">

						<div class="form__row">

							<label class="form__label" for="email">Email Address</label>

							<div class="form__field">

								<input type="email" class="validation" name="email" id="email" [appFormatOnly]='"trimonly"' [(ngModel)]="model.email" [class.validation--is-invalid]="!isUserValid"
								 required/>
								<!--<div class="message message&#45;&#45;is-valid"><span class="icon icon&#45;&#45;is-valid"></span>Positive Message</div>-->

							</div>

						</div>

						<div class="form__row">
							<label class="form__label" for="password">Password</label>

							<div class="form__field">
								<div class="input-group" id="show_hide_password">
									<input type="password" name="password" id="password" [(ngModel)]="model.password" [class.validation--is-invalid]="!isPasswordValid" class="form-control validation" required />
									<button type="button" class="btn btn-secondary" (click)="$event.preventDefault()" appPasswordToggle>
									  <span [ngClass]="password_type == 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'"></span>
									</button>
								  </div>		

							</div>

						</div>

						<div *ngIf="enabledDOB" class="form__row">

							<label for="" class="form__label">Date of Birth</label>

							<div class="form__field">

								<div class="login_form__field--date full-width-input-form">
									<input type="date" class="full-width-input" placeholder="Enter Date" [owlDateTime]="dt2"
										(dateTimeChange)="onDateChange($event, dt2, dateInput)" [max]="currentDate"
										(click)="preventDefault($event)" #dateInput name="dob" id="dateInput" [(ngModel)]="model.dob" [class.validation--is-invalid]="!isDateValid"/>
									<span class="trigger  icon icon--calendar" [owlDateTimeTrigger]="dt2"></span>
									<owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
								</div>

							</div>

						</div>

						<div class="form__row">
							<div *ngIf="errorMessage" class="message message--is-invalid">

								<span class="icon icon--is-invalid"></span>
								{{ errorMessage }}

							</div>
						</div>

						<div class="form__row">

							<input type="submit" value="Sign In" *ngIf="!isLoading" class="btn btn--green btn--full-width" />

							<div class="btn btn--green btn--full-width is-loading" *ngIf="isLoading">
								<span class="icon icon--loading "></span>
							</div>

							<div class="hr hr--space-down-2x"></div>

							<!-- <div class="w-100 text-center">
								<span>Or log in using:</span>
							</div>

							<div class="hr--space-down-x"></div>

							<input input="button" (click)="spInitiatedLogin()" value="SalesForce" *ngIf="!isLoading" class="btn btn--blue btn--full-width" />

							<div class="hr hr--space-down-2x"></div> -->

							<a href="#" (click)="routeToForgotPassword($event)">Forgot Password?</a>

							<div class="pt-1"></div>
							<!-- <a href="#" (click)="routeToSignUp($event)">Sign Up</a> -->
						</div>
					</form>
				</div>

			</div>

		</div>

		<div class="col col__md-7 login__welcome">
			<div class="login__welcome-content">
				<h1 class="heding heading--welcome">Welcome
				</h1>
				<p>A platform designed for doctors to help them manage their patients and partners. Created with love.</p>
			</div>
		</div>

	</div>

</div>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable prefer-const */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { PopUpService } from '../../../services/pop-up.service';
import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, AfterContentInit } from '@angular/core';
import { FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { CommonFuntions } from '../../../common/common.function';
import { LoginService } from '../../../services/login.service';
import { Router } from '@angular/router';
import { CaseService } from '../../../services/case.service';
import { OnChanges } from '@angular/core';

@Component({
  selector: 'app-report-case',
  templateUrl: './report-case.component.html',
  styleUrls: ['./report-case.component.css']
})
export class ReportCaseComponent implements OnInit, OnChanges, AfterContentInit {
  @Input() caseDetail: any;
  @Input() final_reports;
  @Input() translated_document;
  @Input() document_for_download;
  @Input() isMdManager;
  @Input() canContinue: Function;
  @Output() caseEmitter = new EventEmitter<any>();
  @Output() showStatusEmitter = new EventEmitter<string>();
  // @Input() disableEdits;
  report;
  report_notes: string;
  checkFinalReport: boolean;
  showMessage: boolean;
  disableInputs: boolean
  hasTranslateMR: boolean
  reportNotesLengthValid: boolean
  isLoading: boolean;
  filteredRecommendations: any = [];
  recommendationList = [];
  interventionList = [];
  validateRecommendation = false;
  recommendationNotes: boolean;
  recommendationForm: FormGroup;
  isReportCollapse = true;
  
  constructor(
    private loginService: LoginService,
    private router: Router,
    private caseService: CaseService,
    private popUpService: PopUpService
  ) { }

  ngOnInit() {
    this.checkFinalReport = false;
    this.showMessage = false;
    this.reportNotesLengthValid = true;

    this.recommendationForm = this.initializeForm();

    this.interventionList = [
      {
        intervention_name: 'Proceed with recommended surgical procedure'},
      { 
        intervention_name: 'Recommends against surgical procedure'},
      { 
        intervention_name: 'Suggested alternate/less invasive surgical procedure'},
      { 
        intervention_name: 'Patient decision'},
      { 
        intervention_name: 'N/A'},
    ];

    this.recommendationList = [
      { 
        recommendation_name: 'Additional Testing'},
      { 
        recommendation_name: 'Conservative Treatment'},
      { 
        recommendation_name: 'Alternative Therapy'},
      { 
        recommendation_name: 'Lifestyle Modifications'},
      { 
        recommendation_name: 'N/A'},
    ];

  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.caseDetail) {
      this.report = this.caseDetail.status.find(element => element.status.status.toLowerCase() === 'report');

      this.disableInputs = CommonFuntions.checkIfHoldOrCancelledOrFinishedorMd(this.caseDetail, this.isMdManager);
    }

    if (this.caseDetail && this.caseDetail.report_notes) {
      this.report_notes = this.caseDetail.report_notes;
    }
    else {
      this.report_notes = '';
    }
    if (this.caseDetail && this.caseDetail.checked_final_report) {
      this.checkFinalReport = true;
    }
    else {
      this.checkFinalReport = false;

    }
  }

  ngAfterContentInit(): void {

    if (this.caseDetail) {
      this.report = this.caseDetail.status.find(element => element.status.status.toLowerCase() === 'report');

      this.disableInputs = CommonFuntions.checkIfHoldOrCancelledOrFinishedorMd(this.caseDetail, this.isMdManager);
    }

    if (this.caseDetail && this.caseDetail.report_notes) {
      this.report_notes = this.caseDetail.report_notes;
    }
    else {
      this.report_notes = '';
    }
    if (this.caseDetail && this.caseDetail.checked_final_report) {
      this.checkFinalReport = true;
    }
    else {
      this.checkFinalReport = false;
    }

    if(this.caseDetail?.surgical_intervention){

      this.onInterventionChange(this.caseDetail.surgical_intervention);

      if(this.caseDetail.surgical_intervention_note){
        this.surgical_intervention_note.setValue(this.caseDetail.surgical_intervention_note);
      }
    }

    if(this.caseDetail?.surgical_recommendation){
      const case_rec = JSON.parse(this.caseDetail.surgical_recommendation);

      case_rec.map(
        value => this.addRecommendation(value)
      );

      if(this.caseDetail.surgical_recommendation_note){
        this.surgical_recommendation_note.setValue(this.caseDetail.surgical_recommendation_note);
      }
    }
    
  }

  changeCheckBoxBoolean() {
    this.showMessage = false;
    if (this.checkFinalReport) {
      this.checkFinalReport = false;
    }
    else {
      this.checkFinalReport = true;
    }
  }

  initializeForm(): FormGroup {
    return new FormGroup(
      {
        surgical_intervention: new FormControl('',
          [
            Validators.required,
            CommonFuntions.validateDropDown
          ]),
        surgical_recommendation: new FormArray([],
          [
            Validators.required,
            CommonFuntions.fomrArrayMinLength(1)
          ]),
        surgical_recommendation_note: new FormControl('',[Validators.maxLength(50000)]),
        surgical_intervention_note: new FormControl('',[Validators.maxLength(50000)])
      }
    );
  }

  get surgical_recommendation(): FormArray {
    return this.recommendationForm.get('surgical_recommendation') as FormArray;
  }

  get surgical_recommendation_note(): FormControl {
    return this.recommendationForm.get('surgical_recommendation_note') as FormControl;
  }

  get surgical_intervention_note(): FormControl {
    return this.recommendationForm.get('surgical_intervention_note') as FormControl;
  }

  get surgical_intervention(): FormControl {
    return this.recommendationForm.get('surgical_intervention') as FormControl;
  }

  private showAlert(message: string ,positiveCallback: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'alert',
      message,
      'Ok',
    );

    this.popUpService.setPositiveAction(positiveCallback);

    const alertDialog = this.popUpService.getPopUpView('alertDialog');
    this.popUpService.showDiv(alertDialog);
  }

  /**
   * Function complete the report stage 
   *
   * @param {number} case_id
   * @param {string} reportNotes
   * @param {Event} event
   * @return {*} 
   * @memberof ReportCaseComponent
   */
  saveNotes(case_id: number, reportNotes: string) {
    this.validateRecommendation = true;
    const { speciality_program, allyCaseNumber, allyCaseRef } = this.caseDetail;
    const forRecommendation =  speciality_program === 'MSO' && (allyCaseNumber || allyCaseRef);

    if (!this.canContinue(this.caseDetail, 'report')) {
      this.showAlert('Please complete previous stages to complete \'Report\'' ,function () {
        this.popUpService.hideDiv('alertDialog');
      }.bind(this));
      return;
    }

    if(!this.recommendationForm.valid && forRecommendation){
      CommonFuntions.validateAllFormFields;
      return;
    }

    if (!case_id) {return;}

    if (!this.reportNotesLengthValid) {return;} 

    if (!this.hasTranslateMR && !this.checkFinalReport) {
      this.showMessage = true;
      return;
    }

    this.recommendationForm.value.surgical_recommendation = JSON.stringify(this.extractValue(this.surgical_recommendation.value, 'recommendation_name')) ;

    if (case_id > 0) {
      if (CommonFuntions.guardForAction(this.loginService,
        this.router)) {
        this.isLoading = true;
        
        let data = {
          case_id,
          report_notes: reportNotes,
          checked_final_report: this.checkFinalReport
        };

        if (forRecommendation) {
          data = { ...data, ...this.recommendationForm.value };
        }

        this.caseService.updateReportNotes(data).subscribe(
          function (_result) {
            this.showAlert('Report saved successfully.' ,function () {
              const user = this.loginService.getCurrentUser();
              this.caseService.getCaseById(this.caseDetail.id, user.user_id).subscribe(
                function (caseDetails) {
                  this.caseDetail = caseDetails;
  
                  const user = this.loginService.getCurrentUser();
  
                  const inprogressList = CommonFuntions.setStatusInProgress(this.caseDetail.status, 'report');
                  if (inprogressList.length > 0) {
                    const statusList = [];
  
                    inprogressList.forEach(eachStatus => {
                      statusList.push({ status_id: eachStatus.status.id });
                    });
  
  
                    this.caseService.updateStaus(
                      statusList,
                      this.caseDetail.id
                    ).subscribe(
                      function (_result) {
                        this.caseService.getCaseById(this.caseDetail.id, user.user_id).subscribe(
                          function (caseDetails) {
                            caseDetails;
  
                            caseDetails['final_reports'] = this.object.final_reports;
                            caseDetails['document_for_download'] = this.object.document_for_download;
                            caseDetails['translated_document'] = this.object.translated_document;
                            this.object.caseEmitter.emit(caseDetails);
                            this.object.showStatusEmitter.emit(this.inprogressList[0].status.status);
                            this.object.isLoading = false;
                          }.bind({
                            object: this,
                            inprogressList
                          }),
                          (_error) => {
                            this.showAlert('Error while getting the case.' ,function () {
                              this.popUpService.hideDiv('alertDialog');
                            }.bind(this));
                            this.caseDetail = null;
                            this.isLoading = false;
                          }
                        );
                      }.bind(this),
                      (_error) => {
                        this.showAlert('Cannot update status of case.' ,function () {
                          this.popUpService.hideDiv('alertDialog');
                        }.bind(this));
                        this.isLoading = false;
                      }
                    );
                  }
                  else {
                    this.caseService.getCaseById(this.caseDetail.id, user.user_id).subscribe(
                      function (caseDetails) {
                        caseDetails;
  
                        caseDetails['final_reports'] = this.object.final_reports;
                        caseDetails['document_for_download'] = this.object.document_for_download;
                        caseDetails['translated_document'] = this.object.translated_document;
                        this.object.caseEmitter.emit(caseDetails);
                        this.object.isLoading = false;
                      }.bind({
                        object: this
                      }),
                      (_error) => {
                        this.showAlert('Error while getting the case.' ,function () {
                          this.popUpService.hideDiv('alertDialog');
                        }.bind(this));
                        this.caseDetail = null;
                        this.isLoading = false;
                      }
                    );
                  }
                }.bind(this),
                (_error) => {
                  this.showAlert('Error while getting the case.' ,function () {
                    this.popUpService.hideDiv('alertDialog');
                  }.bind(this));
                  this.caseDetail = null;
                  this.isLoading = false;
                }
              );
              
              if (!this.loginService.isSFLogin())
                this.router.navigate(['/case'], { queryParams: { list: 'previous' } });

              this.popUpService.hideDiv('alertDialog');

            }.bind(this));
          }.bind(this), (_error) => {
            this.showAlert('Failed to update report.' ,function () {
              this.popUpService.hideDiv('alertDialog');
            }.bind(this));
            this.isLoading = false;
          }
        );
      }
    }
    else {
      this.isLoading = false;
    }

  }

  downloadFile(id: number, document_name: string, event?: Event) {
    if (event) {event.preventDefault();}

    this.caseService.downloadPDF(id, document_name).subscribe(
      data => {
        const { url } = data;
        window.open(url, '_blank');
      },
      error => {
        const errorText = error.error.message || 'Cannot open file';
        this.showAlert(errorText ,function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
      }
    );
  }

  extractValue(arr, prop) {
    // extract value from property
    let extractedValue = arr.map(item => item[prop]);

    return extractedValue;
  }

  public checkLength(notes: HTMLInputElement) {

    const value = notes.value;

    if (value.length > 50000) {
      this.reportNotesLengthValid = false;
    }
    else {
      this.reportNotesLengthValid = true;
    }

  }

  addRecommendation(recommendation_name: string) {
    let value;
    value = this.recommendationList.find(
      object => object.recommendation_name === recommendation_name
    );
    const checkNA = this.surgical_recommendation.value.find(
      object => object.recommendation_name === 'N/A'
    );

    if(this.surgical_recommendation.length !== 0 && recommendation_name === 'N/A'){
      return;
    }

    if(checkNA){
      return;
    }

    if(recommendation_name === 'N/A'){
      this.recommendationNotes = true;
      this.recommendationList = this.recommendationList.filter(value=> {
        return value.recommendation_name === 'N/A';
      });
    }
    else{
      this.recommendationList = this.recommendationList.filter(value=> {
        return value.recommendation_name !== 'N/A';
      });
    }

    this.filteredRecommendations = this.recommendationList;
    const indexOf = this.recommendationList.indexOf(value);
    this.recommendationList.splice(indexOf, 1);
    
    if (value == null) {return;}

    this.surgical_recommendation.push(new FormControl(value));
    this.validateRecommendation = true;
  }

  removeRecommendation(recommendation_name: FormControl) {
    if(this.disableInputs){
      return;
    }

    if(recommendation_name.value.recommendation_name === 'N/A'){
      this.recommendationList.push({recommendation_name: 'Additional Testing'});
      this.recommendationList.push({recommendation_name: 'Conservative Treatment'});
      this.recommendationList.push({recommendation_name: 'Alternative Therapy'});
      this.recommendationList.push({recommendation_name: 'Lifestyle Modifications'});
    }
    const index = this.surgical_recommendation.controls.indexOf(recommendation_name);
    this.surgical_recommendation.removeAt(index);

    this.recommendationList.push(recommendation_name.value);
    this.recommendationList.sort(function (recommendation_name1, recommendation_name2) {
      return recommendation_name1.id - recommendation_name2.id;
    });

    const noteVisibility = this.surgical_recommendation.value.find(
      object => object.recommendation_name === 'N/A'
    );

    if(!noteVisibility){
      this.recommendationNotes = false;
      this.surgical_recommendation_note.setValue('');
      this.surgical_recommendation_note.clearValidators();
      this.surgical_recommendation_note.updateValueAndValidity();
    }

    if(this.surgical_recommendation.length === 0 && !this.recommendationList.find(value => value.recommendation_name === 'N/A')){
      this.recommendationList.push({recommendation_name: 'N/A'});
    }
  }

  suggestionListFormatter(data: any): string {
    if (data.hasOwnProperty('recommendation_name')) {
      return data.recommendation_name;
    }
  }

  valueListFormatter(data: any): string {
    if (data.hasOwnProperty('recommendation_name')) {
      this.addRecommendation(data.recommendation_name);
    }
    return '';
  }

  onInterventionChange(data: any) {
    this.surgical_intervention.setValue(data);
    this.surgical_intervention_note.setValue('');

    if(data === 'N/A'){
      this.surgical_intervention_note.setValidators(Validators.required);
      this.surgical_intervention_note.updateValueAndValidity();
    }
    else{
      this.surgical_intervention_note.clearValidators();
      this.surgical_intervention_note.updateValueAndValidity();
    }
  }
}

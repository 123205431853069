/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {
  Router,
  UrlTree,
  UrlSegmentGroup,
  UrlSegment,
  PRIMARY_OUTLET,
} from '@angular/router';
import { UserService } from '../../services/userservice.service';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-topmenu',
  templateUrl: './topmenu.component.html',
  styleUrls: ['./topmenu.component.css'],
})
export class TopmenuComponent implements OnInit {
  role: any;
  @Output()
  searchValueEmitter = new EventEmitter<string>();

  @Input()
  userList = 'admin';

  @Input()
  searchValue = '';

  @Input()
  activeUsers = 0;

  @Input()
  inactiveUsers = 0;

  constructor(
    public router: Router,
    public userService: UserService,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    localStorage.setItem('searchValue', this.searchValue);
    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const s: UrlSegment[] = g.segments;

    const user = this.loginService.getCurrentUser();
    this.role = user.user_type.type;
  }

  callSearch() {
    localStorage.setItem('searchValue', this.searchValue);
    this.searchValueEmitter.emit(this.searchValue);
  }
}

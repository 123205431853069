import { AbstractControl, ValidatorFn } from '@angular/forms';

export function formulaInjectionValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const formulaInjectionRegex = /^(?![-+=@])[\s\S]*(?<![-+=@])$/;
  
    if (typeof value === 'string' && value.trim() !== '' && !formulaInjectionRegex.test(value)) {
      return { formulaInjection: true };
    }
  
    return null;
  };
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables/src/angular-datatables.module';
import { AppRoutingModule } from '../../routers/app-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PartnersComponent } from '../partners/partners.component';
import { TopMenuPartnerComponent } from './top-menu-partner/top-menu-partner.component';

import { HttpClientModule } from '@angular/common/http';
import { PartnersListComponent } from './partners-list/partners-list.component';
import { PartnerProfileComponent } from './partner-profile/partner-profile.component';
import { CreatePartnerComponent } from './create-partner/create-partner.component';
import { PartnerDashboardComponent } from './partner-dashboard/partner-dashboard.component';
import { PartnerCreateComponent } from './partner-create/partner-create.component';

import { SharedModule } from '../../shared/shared.module';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FileUploadModule } from 'ng2-file-upload';
import { ExportReportComponent } from './export-report/export-report.component';
import { PartnerSettingComponent } from './partner-setting/partner-setting.component';
import { ToastrModule } from 'ngx-toastr';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';






@NgModule({
  imports: [
    CommonModule,
    DataTablesModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    FileUploadModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule,
    SweetAlert2Module
  ],
  declarations: [
    PartnersComponent,
    TopMenuPartnerComponent,
    PartnersListComponent,
    PartnerProfileComponent,
    CreatePartnerComponent,
    PartnerDashboardComponent,
    PartnerCreateComponent,
    ExportReportComponent,
    PartnerSettingComponent
  ]
})
export class PartnersModule {

}

<div class="top-menu" >
	<div class="top-menu__user-stat">
		<span>Total Templates</span> {{ totalRecord }}
	</div>
	<div class="top-menu__menu">
	</div>

	<!-- Search box -->
	<div class="top-menu__search">
		<div class="search-wrapper">
			<div class="search-input-trigger sarch-box">
				<!-- Search -->
				<input id="caseSearch" type="text" [(ngModel)]="searchValue" #searchBox autocomplete="off"/>
				<ul class="search-column" *ngIf="searchValue !== ''">
				
					<li (click)="currentSearch(column.key,column.value,searchValue)" *ngFor="let column of columnOptions | keyvalue"> <strong><em>{{column.value}}</em></strong> : {{searchValue}}
					</li>
				</ul>
			</div>
		</div>
		<small *ngIf="searchColumnValue !== ''">  <span class="btn btn--small--circle  btn--remove--search" (click)="clearSearch()" ><strong>{{searchColumnName}}</strong>:	{{searchColumnValue}}<span ><b>&nbsp; X</b></span> </span></small> 
	</div>

		<div class="add-user" *ngIf="userRole === 'admin'">
			<a [routerLink]="['/email-templates/add']" class="btn btn--blue btn--mini">
				Add New Email Template
			</a>
		</div>
	
</div>
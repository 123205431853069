<app-dialog></app-dialog>

<div class="header">
  <div class="header__page-name">
    <h1 class="heading heading--main">Case Settings</h1>
  </div>
</div>

<div class="wrapper--dash-content dash-content">
  <div class="tabs__content">
    <form>
        <div class="row">
          <div class="col col__md-12">
            <div class="box box--white overlay__wrapper box--radius box--margin-bottom-2x" [class.overlay--visible] ="isCaseSettingLoading">
              <div class="overlay__content">
                <div class="overlay__spinner">
                  <span class="icon icon--loading"></span>
                </div>
              </div>

              <div class="contact-details">
                <h3 class="settingTitle">1. Enable/Disable Client Association Check</h3>
                <div class="contact-details__row">
                  <div class="contact-details__label">Client Association</div>
                  <div class="contact-details__details">
                    <div class="form__field">
                          <label class="switch">
                              <input type="checkbox" name="client-association-switch" [(ngModel)]="selected" (ngModelChange)="onClientAssociationCheckChange($event)" />
                              <div class="slider round">
                                  <span class="on">Enabled</span>
                                  <span class="off">Disabled</span>
                              </div>
                          </label>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         </div>
        </form>
    </div>
</div>
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */

import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ReleaseSettingService } from '../../../../app/services/release-setting.service';

@Component({
  selector: 'app-release-notice',
  templateUrl: './release-notice.component.html',
  styleUrls: ['./release-notice.component.css'],
})
export class ReleaseNoticeComponent implements OnInit {
  selected: boolean;
  loading = true;
  subject = '';
  content = '';
  release_priority = '';
  priority:string;
  update_at: Date;
  created_at: Date;
  is_update: boolean;
  current_subject: string;
  constructor(
    private toastr: ToastrService,
    private releaseService: ReleaseSettingService
  ) {}

  ngOnInit(): void {
    this.getReleaseNotice();
    this.release_priority='Low';
  }

  enableDisableNotice(value: boolean) {
    this.loading = true;
    this.releaseService.updateModalVisibility(value).subscribe(() => {
      this.loading = false;
    },(error)=>{
      this.toastr.error(error.message, 'Error');
    });
  }

  saveNotice(is_update: boolean) {
    if (this.subject.trim() == '' || this.release_priority == '') {
      this.toastr.warning('Fill all the mandatory field ', 'Invalid form');
      return false;
    }

    const payload = {
      subject: this.subject,
      content: this.content,
      release_priority: this.release_priority,
      is_update: is_update
    };

    this.loading = true;
    this.releaseService.saveOrUpdateReleaseNotice(payload).subscribe((response) => {
      this.getReleaseNotice();
      this.toastr.success(response.message);
      this.loading = false;
    },(error)=>{
      this.toastr.error(error.message);
    });
  }

  getReleaseNotice(){
    this.loading = true;
    this.releaseService.getReleaseNotice().subscribe (response => {
      this.selected = response.data?.is_visible;
      this.update_at = response.data?.updated_at;
      this.created_at = response.data?.created_at;
      this.is_update = response.data?.is_update;
      this.priority = response.data?.release_priority;
      this.current_subject = response.data?.subject;
      this.loading = false;
    },(error)=>{
      if(error.error.status == 500){
        this.selected = false;
        this.toastr.error(error.error.message);
      }
    });
  }

}
/* eslint-disable no-undef */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, UrlTree, UrlSegmentGroup, UrlSegment, PRIMARY_OUTLET } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { PartnerService } from '../../../services/partner.service';
import { PartnersModel } from '../../../domains/partnersModel';

@Component({
  selector: 'app-partner-create',
  templateUrl: './partner-create.component.html',
  styleUrls: ['./partner-create.component.css']
})
export class PartnerCreateComponent implements OnInit {

  title: string
  operation: string;
  passName: string
  partnerModel = new PartnersModel();

  @Output()
  partnerNameEmitter = new EventEmitter<string>();

  constructor(
    public loginService: LoginService,
    public router: Router,
    private partnerService: PartnerService

  ) { }

  ngOnInit() {

    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g.segments;

    this.operation = s[1].path;
    this.operation = this.operation.toLowerCase();
    

    if (this.operation == 'create') {
      this.title = 'Create New Client';
    }
    else{
      this.title = 'Edit Client';
      const partnerId = s[2] ? parseInt(s[2].path) : null;
      if (partnerId) {
        this.getPartner(partnerId).subscribe(
          result => {
            this.partnerModel = result as PartnersModel;
            this.title = this.partnerModel.partner_name;
          }
        );

      }
      else if (this.operation.toLowerCase() === 'create') {

      }
    }

    const partnerId = s[2] ? parseInt(s[2].path) : null;
    if (partnerId) {
    }
    else if (this.operation.toLowerCase() === 'create') {
    }



  }

  callSearchPartner() {
    this.partnerNameEmitter.emit(this.passName);
  }

  getPartner(partner_id: number) {
    return this.partnerService.getPartner(partner_id);
  }

}

<div class="box box--white box--no-pad box--radius margin--bottom-2x  overlay__wrapper"
	[class.overlay--visible]="isInProgress">
	<div class="overlay__content">
		<div class="overlay__spinner">
			<span class="icon icon--loading"></span>
		</div>
	</div>
	<div class="box__title" style="border-bottom:none;" *ngIf="role == 'patient'">
		<div class="box-title-left full-flex">
			<div *ngIf="role == 'patient'" class="left-title">Next steps</div>
		</div>
		<a *ngIf="showConsultationLink && caseDetail?.schedule?.zoom_link" [href]="caseDetail?.schedule?.zoom_link"
			class="btn btn--blue remove-right-margin" [target]="'_blank'">Start a consultation</a>
	</div>
	<div class="row box__title =" *ngIf="role == 'patient'">
		<div class="col col__md-5 gutter--right-2x">
			<div>
				<a class="btn btn--blue-border btn--full-width btn--small btn--curved"
					[href]="caseDetail?.patient?.user?.user_partners[0]?.partner?.release_form_link"
					[target]="'_blank'">SIGN MEDICAL RELEASE FORM</a>
				<!-- <a href="#" class="btn btn--blue-border btn--full-width btn--small btn--curved"> SIGN MEDICAL RELEASE FORM</a> -->
			</div>
		</div>
		<div class="col col__md-5">
			<div *ngIf="role == 'patient'"><a href="#"
					class="btn btn--blue-border btn--full-width btn--small btn--curved" (click)="editDocuments($event)">
					UPLOAD MEDICAL RECORDS</a></div>
		</div>
	</div>
	<h2 class="questions__title pull-left case-section-title" *ngIf="role != 'patient'" style="cursor: pointer;">
		Patient’s Questions</h2>
	<h2 class="questions__title pull-left case-section-title" *ngIf="role == 'patient'" style="cursor: pointer;">My
		Questions</h2>
	<a href="javascript:void(0)" class="go-back pull-right collapseToggle top10"
		[ngClass]="{collapseToggleActive: isQuestionCollapse===true}"
		(click)="isQuestionCollapse = !isQuestionCollapse">
		<span class="icon icon--time-nav"></span>
	</a>
	<app-generic-button 
		(onClick)="onEdit()"
		[class]="'btn--mini editBtn pull-right'"
		[isPurple]="!isReadOnly()"
		*ngIf="false">Edit</app-generic-button>

	<div class="clearfix"></div>
	<div [hidden]="!isQuestionCollapse" class="collapsibleContent">
		<form [formGroup]="questionForm">
			<div formArrayName="questions">
				<div *ngFor="let eachQuestion of questions.controls; let i = index" [formGroupName]="i">
					<div class="questions questionTxtbox"
						*ngIf="!eachQuestion?.get('is_delete')?.value && (eachQuestion?.get('follow_up_id')?.value == null || eachQuestion?.get('follow_up_id')?.value == 0 || eachQuestion?.get('follow_up_id')?.value < followUpNumber)">
						<div class=" questions__row ">
							<div class="row">
								<div class="col col-md-2 ">
									<div class="questions__label ">
										<img class="x-btn" *ngIf="isQuestionEdit && checkQuestionLength()"
											(click)="removeQuestion(i)" src="assets/images/x-button.png" alt="X" />
										Question {{i+1}}
									</div>
								</div>
								<div class="col-md-10 ">
									<div class="questions__questions" *ngIf="!isQuestionEdit">
										{{eachQuestion.get('question_text').value}}</div>
									<div class="form__field" *ngIf="isQuestionEdit">
										<textarea class="validation"
											[class.validation--is-invalid]="eachQuestion.get('question_text').touched && eachQuestion.get('question_text').errors"
											formControlName="question_text">
									</textarea>
										<div class="message message--is-invalid"
											*ngIf="eachQuestion.get('question_text').touched && eachQuestion.get('question_text').errors">
											<div *ngIf="eachQuestion.get('question_text').errors.required">This field is
												required.</div>
											<div *ngIf="eachQuestion.get('question_text').errors.maxlength">This field
												cannot contain more than
												{{eachQuestion.get('question_text').errors.maxlength.requiredLength}}
												characters.</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>

		<div class="questions questions--borderedTop" *ngIf="isQuestionEdit">
			<a href="#" (click)="onQuestionAction('cancel',$event)" class="btn btn--red pull-right addBtn">Cancel</a>
			<a href="#" (click)="onQuestionAction('save',$event)" class="btn btn--green pull-right addBtn">Save</a>
			<a href="#" (click)="addQuestion($event)" class="btn btn--blue pull-right addBtn">Add Question</a>
			<div class="clearfix"></div>
		</div>
	</div>
</div>
<!--follow up questions-->
<div *ngIf="followUpNumber > 0" class="box box--white box--no-pad box--radius margin--bottom-2x  overlay__wrapper"
	[class.overlay--visible]="isInProgress">
	<h2 class="questions__title pull-left case-section-title" *ngIf="role != 'patient'">Follow-up Questions</h2>
	<h2 class="questions__title pull-left case-section-title" *ngIf="role == 'patient'">My Follow-up Questions</h2>
	<a href="javascript:void(0)" class="go-back pull-right collapseToggle top10"
		[ngClass]="{collapseToggleActive: isFollowUpQuestionCollapse===true}"
		(click)="isFollowUpQuestionCollapse = !isFollowUpQuestionCollapse">
		<span class="icon icon--time-nav"></span>
	</a>
	<!-- <a href="#" (click)="onEdit($event)" class="btn btn--purple btn--mini editBtn pull-right"
		*ngIf="canQuestionEdit && followUpNumber > 0"
		style="text-transform: none;">{{getCurrentFollowUpQuestionCount()}}</a> -->
	<a href="#" (click)="onEdit($event)" class="btn btn--purple btn--mini editBtn pull-right"
		*ngIf="false"
		style="text-transform: none;">{{getCurrentFollowUpQuestionCount()}}</a>
	<div class="clearfix"></div>
	<div [hidden]="!isFollowUpQuestionCollapse" class="collapsibleContent">
		<form [formGroup]="questionForm">
			<div formArrayName="questions">
				<div *ngFor="let eachQuestion of getFollowUpQuestions(); let i = index" [formGroupName]="i">
					<div class="questions questionTxtbox"
						*ngIf="!eachQuestion?.get('is_delete')?.value && eachQuestion?.get('follow_up_id')?.value == followUpNumber">
						<div class=" questions__row ">
							<div class="row">
								<div class="col__md-2 ">
									<div class="questions__label ">
										<img class="x-btn" *ngIf="isFollowQuestionEdit && checkQuestionLength()"
											(click)="removeQuestion(i)" src="assets/images/x-button.png" alt="X" />
										Question {{i+1}}
									</div>
								</div>
								<div class="col__md-10 ">
									<div class="questions__questions" *ngIf="!isFollowQuestionEdit">
										{{eachQuestion.get('question_text').value}}</div>
									<div class="form__field" *ngIf="isFollowQuestionEdit">
										<textarea class="validation"
											[class.validation--is-invalid]="eachQuestion.get('question_text').touched && eachQuestion.get('question_text').errors"
											formControlName="question_text">
										</textarea>
										<div class="message message--is-invalid"
											*ngIf="eachQuestion.get('question_text').touched && eachQuestion.get('question_text').errors">
											<div *ngIf="eachQuestion.get('question_text').errors.required">This field is
												required.</div>
											<div *ngIf="eachQuestion.get('question_text').errors.maxlength">This field
												cannot contain more than
												{{eachQuestion.get('question_text').errors.maxlength.requiredLength}}
												characters.</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>

		<div class="questions questions--borderedTop" *ngIf="isFollowQuestionEdit">
			<a href="#" (click)="onQuestionAction('cancel',$event)" class="btn btn--red pull-right addBtn">Cancel</a>
			<a href="#" (click)="onQuestionAction('save',$event)" class="btn btn--green pull-right addBtn">Save</a>
			<a href="#" (click)="addQuestion($event)" class="btn btn--blue pull-right addBtn">Add Question</a>
			<div class="clearfix"></div>
		</div>
	</div>
</div>
<!---->
<div class="box box--white box--no-pad box--radius margin--bottom-2x  overlay__wrapper"
	[class.overlay--visible]="isInProgress"
	*ngIf="(role === 'admin' || role === 'careteam' || role.toLowerCase() === 'casecoordinator' || role === 'mdmanager' || role === 'cmmanager') && !user.isSFLogin">
	<div class="overlay__content">
		<div class="overlay__spinner">
			<span class="icon icon--loading"></span>
		</div>
	</div>
	<div class="box__title case-section-box">
		<!-- <div class="box-title-image ">
			<div class="avatar avatar--rect ">
				<img [src]="profilePicPath " />
			</div>
		</div> -->
		<div class="box-title-image">
			<div class="avatar avatar--rect">
				<img *ngIf="!avatarimage" [src]="avatarMale" />
				<img *ngIf="avatarimage" [src]="'data:image/jpg;base64,'+avatarimage" />
			</div>
		</div>
		<div class="box-title-left ">
			<div class="left-title ">Requested</div>
			<div class="left-status ">
				<span class="icon icon--calendar "></span>Started 1 hour ago
			</div>
		</div>
		<div class="box-title-right ">
			<a href="javascript:void(0)" class="go-back pull-right collapseToggle top10"
				[ngClass]="{collapseToggleActive: isRequestedCollapse===true}"
				(click)="isRequestedCollapse = !isRequestedCollapse">
				<span class="icon icon--time-nav"></span>
			</a>
		</div>
	</div>
	<form [hidden]="!isRequestedCollapse" [formGroup]="requestedForm" novalidate
		(ngSubmit)="onSubmit($event, requestedForm)">
		<div class="padding-2x box--md-mgmt ">
			<div class="form__row ">
				<div class="col col__md-2 ">
					<div class="form__label is-uppercase ">WORKFLOW</div>
				</div>
				<div class="col col__md-5 ">
					<div class="form__field ">
						<select formControlName="caseType" (change)="showConfirmation(cbFlow)" #cbFlow>
							<option *ngFor="let caseType of caseTypeList" value="{{caseType.value}}">{{caseType.key}}
							</option>
						</select>
					</div>
				</div>
			</div>
			<div class="status-row" *ngIf="isShowOptionDR && !isMdManager">
				<div class="form__field checkbox--in-row">
					<div class="form__field--checkbox">
						<input type="checkbox" [checked]="isTranslateDr"
							(click)="addRemoveStageTranslateDr(cbAddRemoveDR.checked)" #cbAddRemoveDR />
						<label for="option">Enable TranslateDR</label>
					</div>
				</div>
			</div>
			<div class="form__row mt-2">
				<div class="col col__md-12 ">
					<div class="form__label is-uppercase">Status</div>
				</div>
			</div>
			<div class="form__row ">
				<div class="col col__md-5 ">
					<div class="status-row ">
						MD Management
					</div>
				</div>
				<div class="col col__md-5 ">
					<div class="form__field ">
						<input type="text" auto-complete autocomplete="off" [source]="mdMgmtList" id="txtMDMgmt"
							formControlName="mdMgmt" (blur)="Remove($event, mdMgmt)"
							[list-formatter]="suggestionListFormatter" [value-formatter]="valueListFormatter.bind(this)"
							[re-focus-after-select]="false"
							[attr.disabled]="presenter.isStageDisabled(2, caseDetail, isMdManager) ? true:null"
							#txtMDMgmt />
						<div *ngIf="requestedForm.get('mdMgmt').touched 
								 && requestedForm.get('mdMgmt').invalid" class="message message--is-invalid">
							<div>This field is required.</div>
						</div>
					</div>
					<img *ngIf="isUserInActive(2, 'mdMgmt')" class="inactive-user-alert" src="assets/images/redAlert.png" alt="Inactive"
						title="User is inactive." />
				</div>
			</div>
			<div class="form__row ">
				<div class="col col__md-5 ">
					<div class="status-row ">
						CompileMR
					</div>
				</div>
				<div class="col col__md-5 ">
					<div class="form__field ">
						<input type="text" auto-complete autocomplete="off" [source]="translatorList"
							id="txtTranslateMR" formControlName="translators" (blur)="Remove($event, translators)"
							[list-formatter]="suggestionListFormatter" [value-formatter]="valueListFormatter.bind(this)"
							[re-focus-after-select]="false"
							[attr.disabled]="true"
							#txtTranslateMR />
						<div *ngIf="requestedForm.get('translators').touched 
							 && requestedForm.get('translators').invalid" class="message message--is-invalid">
							<div>This field is required.</div>
						</div>
					</div>
					<img *ngIf="isUserInActive(3, 'translators')" class="inactive-user-alert" src="assets/images/redAlert.png" alt="Inactive"
						title="User is inactive." />
				</div>
			</div>

			<div class="form__row ">
				<div class="col col__md-5 ">
					<div class="status-row ">
						Schedule
					</div>
				</div>
				<div class="col col__md-5 ">
					<div class="form__field ">
						<input type="text" auto-complete autocomplete="off" [source]="schedule_list" id="txtSchedule"
							formControlName="schedule" [list-formatter]="suggestionListFormatter"
							[value-formatter]="valueListFormatter.bind(this)" [re-focus-after-select]="false"
							[attr.disabled]="presenter.isStageDisabled(6, caseDetail) || txtScheduleDisable ? true:null"
							#txtSchedule />
						<div *ngIf="requestedForm.get('schedule').touched 
							 && requestedForm.get('schedule').invalid" class="message message--is-invalid">
							<div>This field is required.</div>
						</div>
					</div>
					<img *ngIf="isUserInActive(6, 'schedule')" class="inactive-user-alert" src="assets/images/redAlert.png" alt="Inactive"
						title="User is inactive." />
				</div>
			</div>

			<div class="form__row ">
				<div class="col col__md-5 ">
					<div class="status-row ">
						Video
					</div>
				</div>

				<div class="col col__md-5 ">
					<div class="form__field ">
						<input type="text" auto-complete autocomplete="off" [source]="video_list" [attr.disabled]="true" id="txtVideo"
							formControlName="video" [list-formatter]="suggestionListFormatter"
							[value-formatter]="valueListFormatter.bind(this)" [re-focus-after-select]="false"
							[attr.disabled]="presenter.isStageDisabled(7, caseDetail, isMdManager) || txtVideoDisable ? true:null"
							#txtVideo />
						<div *ngIf="requestedForm.get('video').touched 
							 && requestedForm.get('video').invalid" class="message message--is-invalid">
							<div>This field is required.</div>
						</div>
					</div>
					<img *ngIf="isUserInActive(7, 'video')" class="inactive-user-alert" src="assets/images/redAlert.png" alt="Inactive"
						title="User is inactive." />
				</div>
			</div>

			<div class="form__row ">
				<div class="col col__md-5 ">
					<div class="status-row ">
						Summary
					</div>
				</div>
				<div class="col col__md-5 ">
					<div class="form__field ">
						<input type="text" auto-complete autocomplete="off" [source]="summary_list" id="txtReview"
							formControlName="summary" [list-formatter]="suggestionListFormatter"
							[value-formatter]="valueListFormatter.bind(this)" [re-focus-after-select]="false"
							[attr.disabled]="presenter.isStageDisabled(8, caseDetail, isMdManager) ? true:null"
							#txtReview />
						<div *ngIf="requestedForm.get('summary').touched 
							 && requestedForm.get('summary').invalid" class="message message--is-invalid">
							<div>This field is required.</div>
						</div>
					</div>
					<img *ngIf="isUserInActive(8, 'summary')" class="inactive-user-alert" src="assets/images/redAlert.png" alt="Inactive"
						title="User is inactive." />
				</div>
			</div>

			<div class="form__row " *ngIf="showTranslateDr">
				<div class="col col__md-5 ">
					<div class="status-row ">
						TranslateDR
					</div>
				</div>
				<div class="col col__md-5 ">
					<div class="form__field ">
						<input type="text" auto-complete autocomplete="off" [source]="translate_dr_list"
							id="txtTranslateDR" formControlName="translate_DR" (blur)="Remove($event, translate_DR)"
							[list-formatter]="suggestionListFormatter" [value-formatter]="valueListFormatter.bind(this)"
							[re-focus-after-select]="false"
							[attr.disabled]="presenter.isStageDisabled(9, caseDetail, isMdManager)? true:null"
							#txtTranslateDR />
						<div *ngIf="requestedForm.get('translate_DR').touched 
							 && requestedForm.get('translate_DR').invalid" class="message message--is-invalid">
							<div>This field is required.</div>
						</div>
					</div>
					<img *ngIf="isUserInActive(9, 'translate_DR')" class="inactive-user-alert" src="assets/images/redAlert.png" alt="Inactive"
						title="User is inactive." />
				</div>
			</div>

			<div class="form__row ">
				<div class="col col__md-5 ">
					<div class="status-row ">
						Report
					</div>
				</div>
				<div  class="col col__md-5 ">
					<div class="form__field ">
						<input type="text" auto-complete autocomplete="off" [source]="report_list" id="txtReport"
							formControlName="report" (blur)="Remove($event, report)"
							[list-formatter]="suggestionListFormatter" [value-formatter]="valueListFormatter.bind(this)"
							[re-focus-after-select]="false"
							[attr.disabled]="presenter.isStageDisabled(10, caseDetail, isMdManager) ? true:null"
							#txtReport />
						<div *ngIf="requestedForm.get('report').touched 
								&& requestedForm.get('report').invalid" class="message message--is-invalid">
							<div>This field is required.</div>
						</div>
					</div>
					<img *ngIf="isUserInActive(10, 'report')" class="inactive-user-alert" src="assets/images/redAlert.png" alt="Inactive"
						title="User is inactive." />
				</div>
			</div>
			<div *ngIf="role === 'admin' && !isCaseStatusPaused()"
				class="hr hr--space-up-2x hr--space-down-2x "></div>
			<div *ngIf="role === 'admin' && !isCaseStatusPaused()" class="btns-row text-right">
				<button class="btn btn--blue" type="submit" [attr.disabled]="!requestedForm.valid || caseDetail.case_status === 'FINISHED'"
					*ngIf="!isInProgress">Submit</button>
				<div class="btn btn--blue is-loading" *ngIf="isInProgress">
					<span class="icon icon--loading "></span>
				</div>
			</div>
		</div>
	</form>

	<div class="popup" hidden #CASEREQUESTED>
		<div class="popup__overlay"></div>
		<div class="popup__container">
			<div class="popup__header">
				<h1>Video Consultation</h1>
				<a href="#" class="popup__close" (click)="hideDiv('VIDEO', $event)">
					<span class="icon icon--cross"></span>
				</a>
			</div>
			<div class="popup__content">
				<div class="form__row">
					<div class="form__field">
						<video *ngIf="videoSrc && videoSrc !== null" [src]="videoSrc" width="100%" height="300"
							controls></video>
					</div>
				</div>
			</div>
		</div>
	</div>

</div>
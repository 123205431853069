<div class="dashboard" [class.is-collapsed]="isCollapsedDashboard">
	<div class="dashboard__menu-col" *ngIf="!isSFLogin()">
		<div class="header">
			<div class="header__page-name header__logo">
				<app-logo (collapseEmitter)="isCollapsedLogo($event)"></app-logo>
			</div>
			<div class="header__left-nav" [ngClass]="isCollapsedDashboard? 'collapseNav':''">
				<app-profile></app-profile>
				<app-leftmenu></app-leftmenu>
			</div>
		</div>
	</div>
	<!-- <div class="dashboard__content-col">
		<div class="header">
			<div class="header__page-name">
				<h1 class="heading heading--main">Users</h1>
			</div>
			<div class="header__top-nav">
				<app-topmenu (searchValueEmitter)="searchString($event)"></app-topmenu>
			</div>
		</div>
		<div class="wrapper--dash-content dash-content">
			<app-users></app-users>
		</div>
	</div> -->
	<div class="dashboard__content-col">
		<router-outlet></router-outlet>
	</div>

	<app-notifications *ngIf="!isSFLogin()" ></app-notifications>
	
</div>
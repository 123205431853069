<app-dialog></app-dialog>

<div class="header">
  <div class="header__page-name">
    <h1 class="heading heading--main">Email Settings</h1>
  </div>
</div>

<div class="wrapper--dash-content dash-content">
  <div class="tabs__content">
    <form [formGroup]="mailServerForm">
        <div class="row">
          <div class="col col__md-6">
            <div class="box box--white overlay__wrapper box--radius box--margin-bottom-2x" [class.overlay--visible] ="isMailServerLoading">
              <div class="overlay__content">
                <div class="overlay__spinner">
                  <span class="icon icon--loading"></span>
                </div>
              </div>
              <div class="contact-details">
                <h3 class="settingTitle">Error Notification Mail Server</h3>
                <div class="contact-details__row">
                  <div class="contact-details__label">Mail Server</div>
                  <div *ngIf='!isEdit' class="contact-details__details">
                    <span *ngIf="mailSettings === null || mailSettings?.error_mail_server_id === null">Please choose a server.</span>
                    <span *ngIf="mailSettings !== null" class="label--bold">{{errorMailServer}}</span>
                  </div>
                  <div *ngIf='isEdit' class="contact-details__details">
                    <div class="form__field">
                      <select id="error_mail_server_id" formControlName="error_mail_server_id">
                        <option *ngFor="let server of mailServers" [ngValue]="server.id">{{server.name}}</option>
                      </select>
                    </div>
                    <div *ngIf="errorServerId.touched && errorServerId.invalid" class="message message--is-invalid">
                      <div *ngIf="errorServerId.errors.required">This field is required.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col col__md-6">
            <div class="box box--white overlay__wrapper box--radius box--margin-bottom-2x" [class.overlay--visible] ="isMailServerLoading">
              <div class="overlay__content">
                <div class="overlay__spinner">
                  <span class="icon icon--loading"></span>
                </div>
              </div>
              <div class="contact-details">
                <h3 class="settingTitle">Business Email Mail Server</h3>
                <div class="contact-details__row" >
                  <div class="contact-details__label">Mail Server</div>
                  <div *ngIf='!isEdit' class="contact-details__details">
                    <span *ngIf="mailSettings === null || mailSettings?.business_mail_server_id === null">Please choose a server.</span>
                    <span *ngIf="mailSettings !== null" class="label--bold">{{businessMailServer}}</span>
                  </div>
                  <div *ngIf='isEdit' class="contact-details__details">
                    <div class="form__field">
                      <select id="business_mail_server_id" formControlName="business_mail_server_id">
                        <option *ngFor="let server of mailServers" [ngValue]="server.id">{{server.name}}</option>
                      </select>
                    </div>
                    <div *ngIf="businessServerId.touched && businessServerId.invalid" class="message message--is-invalid">
                      <div *ngIf="businessServerId.errors.required">This field is required.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box--margin-bottom-2x">
            <a *ngIf='isEdit' class="btn btn--green" (click)="onSubmit()">Save</a>
            <a *ngIf='isEdit' class="btn btn--red" (click)="toggleEdit()">Cancel</a>
            <a *ngIf='!isEdit' class="btn btn--blue" (click)="toggleEdit()">Edit Mail Server</a>
          </div>       
         </div>
        </form>
    <form [formGroup]="recipientForm">
        <div class="row">
          <div class="col col__md-6">
            <div class="box box--white overlay__wrapper box--radius box--margin-bottom-2x" [class.overlay--visible] ="isMailSettingLoading">
              <div class="overlay__content">
                <div class="overlay__spinner">
                  <span class="icon icon--loading"></span>
                </div>
              </div>
              <div class="contact-details">
                <h3 class="settingTitle">Error Email Recipient</h3>
                <div class="contact-details__row">
                  <div class="contact-details__label">Email Address</div>
                  <div *ngIf='!isRecipientEdit' class="contact-details__details">
                    <span *ngIf="mailSettings?.email_recipient === null">Please add a error recipient.</span>
                    <span *ngIf="mailSettings?.email_recipient !== null" class="label--bold" for="email_recipient">{{emailRecipient}}</span>
                  </div>
                  <div *ngIf='isRecipientEdit' class="contact-details__details">
                    <div class="form__field">
                      <input type="text" id="email_recipient" formControlName="email_recipient"/>
                    </div>
                    <div *ngIf="mailSettings === null" class="message message--is-invalid">Please add mail servers first.</div>
                    <div *ngIf="recipientEmailAddress.touched && recipientEmailAddress.invalid" class="message message--is-invalid">
                      <div *ngIf="recipientEmailAddress.errors.required">This field is required.</div>
                      <div *ngIf="recipientEmailAddress.errors.email && !recipientEmailAddress.errors.required">Enter a valid email</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
          <div class="col col__md-6">
            <div class="box box--white overlay__wrapper box--radius box--margin-bottom-2x" [class.overlay--visible] ="isMailSettingLoading">
              <div class="overlay__content">
                <div class="overlay__spinner">
                  <span class="icon icon--loading"></span>
                </div>
              </div>
              <div class="contact-details">
                <h3 class="settingTitle">Reply To</h3>
                <div class="contact-details__row">
                  <div class="contact-details__label">Email Address</div>
                  <div *ngIf='!isRecipientEdit' class="contact-details__details">
                    <span *ngIf="mailSettings?.email_recipient === null">Please add a reply to email.</span>
                    <span *ngIf="mailSettings?.email_recipient !== null" class="label--bold" for="email_recipient">{{replyToEmail}}</span>
                  </div>
                  <div *ngIf='isRecipientEdit' class="contact-details__details">
                    <div class="form__field">
                      <input type="text" id="reply_email" formControlName="reply_email"/>
                    </div>
                    <div *ngIf="mailSettings === null" class="message message--is-invalid">Please add mail servers first.</div>
                    <div *ngIf="replyEmail.touched && replyEmail.invalid" class="message message--is-invalid">
                      <div *ngIf="replyEmail.errors.required">This field is required.</div>
                      <div *ngIf="replyEmail.errors.email && !replyEmail.errors.required">Enter a valid email</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>    
         </div>
         <div class="box--margin-bottom-2x">
          <a *ngIf='isRecipientEdit' class="btn btn--green" (click)="onRecipientSubmit()">Save</a>
          <a *ngIf='isRecipientEdit' class="btn btn--red" (click)="toggleRecipientEdit()">Cancel</a>
          <a *ngIf='!isRecipientEdit' class="btn btn--blue" (click)="toggleRecipientEdit()">Edit Recipient</a>
        </div> 
        </form>
    </div>
</div>
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
export class PartnerCareTeamDashboardModel {

    user_id: number;
    full_name: string;
    avatar: string;

    constructor() {
      this.full_name='';
      this.user_id=0;
      this.avatar='';

    }

}

/* eslint-disable no-unused-vars */
export const PRIORITY = {
  HIGH: 'HIGH',
  LOW: 'LOW'
};

export const SOURCE = {
  IMD: 'IMD',
  ALLY: 'ALLY'
};

export const GROUP_TYPE = {
  CASE: 'CASE',
  TRACKING_LINE: 'TRACKING_LINE'
};

export const NOTIFICATION_TYPES = {

  // ALLY
  CASE_CANCELLED_BY_ALLY: 'CASE_CANCELLED_BY_ALLY',

  TRACKING_UPDATED: 'TRACKING_UPDATED',
  TRACKING_NOTE_ADDED: 'TRACKING_NOTE_ADDED',
  TRACKING_STATUS_CHANGED: 'TRACKING_STATUS_CHANGED',
  TRACKING_STATUS_FINAL_CHANGED: 'TRACKING_STATUS_FINAL_CHANGED',

  QUESTIONS_UPDATED_BY_ALLY: 'QUESTIONS_UPDATED_BY_ALLY',

  NEW_MESSAGE_FROM_ALLY: 'NEW_MESSAGE_FROM_ALLY',

  // IMD
  CASE_CANCELLED_BY_CLOUD: 'CASE_CANCELLED_BY_CLOUD',

  CASE_ASSIGNMENT: 'CASE_ASSIGNMENT',

  QUESTIONS_UPDATED_BY_CLOUD: 'QUESTIONS_UPDATED_BY_CLOUD',

  NEW_MESSAGE_FROM_CLOUD: 'NEW_MESSAGE_FROM_CLOUD',

  REPORT_SUBMITTED_BY_MD: 'REPORT_SUBMITTED_BY_MD',

  MEDICAL_SUMMARY_UPLOADED_BY_MR_CORDINATOR: 'MEDICAL_SUMMARY_UPLOADED_BY_MR_CORDINATOR',

  MD_REQUEST_FOR_ADDINGTION_INFO: 'MD_REQUEST_FOR_ADDINGTION_INFO',
};
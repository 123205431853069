<div class="profile">
  <div class="profile__pic">
    <div class="avatar">
      <!-- <img [src]="profilePicPath" /> -->
      <app-loader *ngIf="loadingImage"></app-loader>

      <img *ngIf="!loadingImage && avatarimage" [src]="'data:image/jpg;base64,'+avatarimage" />
      <img *ngIf="!loadingImage && profilePicPath && !avatarimage" [src]="profilePicPath" />
    </div>

  </div>
  <div class="profile__name">{{tokenPayLoad.first_name}} {{tokenPayLoad.middle_name}} {{tokenPayLoad.last_name}}</div>
  <div class="profile__settings"><a *ngIf="user"
      [routerLink]="['/profile', user?.user_id]" [queryParams]="{isSettings: 'true'}"
      [skipLocationChange]="true"><span class="icon icon--setting"></span> <span
        class="profile__settings-text" style="text-transform: capitalize">Profile</span></a></div>
</div>
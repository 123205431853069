import { ReleaseEmailComponent } from '../dashboard/release-settings/release-email/release-email.component';
import { ReleaseNoticeComponent } from '../dashboard/release-settings/release-notice/release-notice.component';
import { ReleaseSettingsComponent } from '../dashboard/release-settings/release-settings.component';
import { EligibleMemberProfileComponent } from '../dashboard/users/eligible-member-profile/eligible-member-profile.component';
import { EligibleMemberComponent } from '../dashboard/users/eligible-member/eligible-member.component';
import { EligibleMemberListComponent } from '../dashboard/users/eligible-member-list/eligible-member-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { GuardService } from '../services/guard.service';
import { DashboardComponent } from '../dashboard/dashboard/dashboard.component';
import { TabsComponent } from '../dashboard/users/tabs/tabs.component';
import { GeneralUserComponent } from '../dashboard/users/generalUser/general.user.component';
import { DoctorComponent } from '../dashboard/users/doctor/doctor.component';
import { PatientComponent } from '../dashboard/users/patient/patient.component';
import { UserProfileComponent } from '../dashboard/users/profile/profile.component';
import { PartnersComponent } from '../dashboard/partners/partners.component';
import { CreatePartnerComponent } from '../dashboard/partners/create-partner/create-partner.component';
import { PartnerDashboardComponent } from '../dashboard/partners/partner-dashboard/partner-dashboard.component';
import { TranslatorCaselistComponent } from '../cases/translator-caselist/translator-caselist.component';
import { ListComponent } from '../cases/list/list.component';
import { DoctorProfileComponent } from '../dashboard/users/doctor-profile/doctor-profile.component';
import { CreateCaseComponent } from '../cases/create-case/create-case.component';

import { DoctorRoleComponent } from '../dashboard/users/doctor-role/doctor-role.component';
import { ImdCareteamRoleComponent } from '../dashboard/users/imd-careteam-role/imd-careteam-role.component';
import { CaseCorordinatorRoleComponent } from '../dashboard/users/case-corordinator-role/case-corordinator-role.component';
import { TranslatorRoleComponent } from '../dashboard/users/translator-role/translator-role.component';
import { InterpreterRoleComponent } from '../dashboard/users/interpreter-role/interpreter-role.component';
import { PartnerRoleComponent } from '../dashboard/users/partner-role/partner-role.component';
import { CalenderComponent } from '../dashboard/calender/calender.component';
import { PatientRoleComponent } from '../dashboard/users/patient-role/patient-role.component';
import { CaseComponent } from '../cases/case/case.component';
import { DoctorConsultationComponent } from '../cases/doctor-consultation/doctor-consultation.component';
import { NavigationComponentComponent } from '../shared/navigation-component/navigation-component.component';
import { AuthGuardService } from '../services/auth-guard.service';
import { AdminRoleComponent } from '../dashboard/users/admin-role/admin-role.component';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { PatientDashboardComponent } from '../dashboard/users/patient/patient-dashboard/patient-dashboard.component';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';
import { PasswordResetService } from '../services/password-reset.service';
import { SettingsComponent } from '../dashboard/settings/settings.component';
import { NewSettingsComponent } from '../dashboard/new-settings/new-settings.component';
import { MdManagerRoleComponent } from '../dashboard/users/md-manager-role/md-manager-role.component';
import { CmManagerRoleComponent } from '../dashboard/users/cm-manager-role/cm-manager-role.component';
import { LogsComponent } from '../dashboard/logs/logs.component';
import { OutgoingMailServerComponent } from '../dashboard/outgoing-mail-server/list/outgoing-mail-server.component';
import { NewOutgoingMailServerComponent } from '../dashboard/outgoing-mail-server/add/new-outgoing-mail-server.component';
import { EditOutgoingMailServerComponent } from '../dashboard/outgoing-mail-server/edit/edit-outgoing-mail-server.component';
import { ViewOutgoingMailServerComponent } from '../dashboard/outgoing-mail-server/view/view-outgoing-mail-server.component';
import { CaseListComponent } from '../dashboard/settings-case-list/list/case-list.component';
import { EditCaseComponent } from '../dashboard/settings-case-list/edit/edit-case.component';
import { ViewCaseComponent } from '../dashboard/settings-case-list/view/view-case.component';
import { AssignedCasesListComponent } from '../dashboard/users/assigned-cases-list/assigned-cases-list.component';

import { SystemAccountRoleComponent } from '../dashboard/users/system-account/system-account.component';
import { SystemAccountUpsertComponent } from '../dashboard/users/system-account/system-account-upsert/system-account-upsert';
import { SettingListComponent } from '../dashboard/setting-list/setting-list.component';
import { CaseSettingsComponent } from '../dashboard/case-settings/case-settings.component';
import { ArchiveSettingsComponent } from '../dashboard/archive-settings/archive-settings.component';
import { EmailSettingsComponent } from '../dashboard/email-settings/email-settings.component';
import { ZoomSettingsComponent } from '../dashboard/zoom-settings/zoom-settings.component';
import { MailLoggerComponent } from '../dashboard/outgoing-mail-server/maillogs/mail-logs.component';
import { EmailTemplateComponent } from '../dashboard/email-template/list/email-template.component';
import { NewEmailTemplateComponent } from '../dashboard/email-template/add/new-email-template.component';
import { EditEmailTemplateComponent } from '../dashboard/email-template/edit/edit-email-template.component';
import { ViewEmailTemplateComponent } from '../dashboard/email-template/view/view-email-template.component';
import { ActiveUsersComponent } from '../dashboard/users/active-inactive/active-users/active-users.component';
import { InactiveUsersComponent } from '../dashboard/users/active-inactive/inactive-users/inactive-users.component';
import { ChangePasswordComponent } from '../dashboard/users/change-password/change-password.component';
import { ReportSettingsComponent } from '../dashboard/report-settings/report-settings.component';
import { MyDashboardComponent } from '../my-dashboard/my-dashboard.component';
import { CaseAssignmentsComponent } from '../case-assignments/case-assignments.component';
import { EditCaseAssignmentsComponent } from '../case-assignments/edit-case-assignments/edit-case-assignments.component';
import { HealthCheckComponent } from '../health-check/health-check.component';

/**
 * @constant 
 * @type Routes
 * @default
 */
const routes: Routes = [
  // {
  //   path: 'signup',
  //   component: SignupComponent
  // },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgotpassword',
    component: ForgetPasswordComponent
  },
  {
    path: 'reset/password',
    component: ResetPasswordComponent
  },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [GuardService, PasswordResetService],
    children: [
      {
        path: '',
        component: ListComponent,
        canActivate: [GuardService],
        children: [
          {
            path: '',
            component: TranslatorCaselistComponent,
            canActivate: [GuardService]
          }
        ]
      },
      {
        path: 'profile/:id',
        component: UserProfileComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'eligible-member/profile/:id',
        component: EligibleMemberProfileComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'add',
        component: TabsComponent,
        canActivate: [GuardService, AuthGuardService],
        children: [
          {
            path: 'admin',
            component: GeneralUserComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'careteam',
            component: GeneralUserComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'casecoordinator',
            component: GeneralUserComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'partner',
            component: GeneralUserComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'doctor',
            component: DoctorComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'patient',
            component: PatientComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'translator',
            component: GeneralUserComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'interpreter',
            component: GeneralUserComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'eligible-member',
            component: EligibleMemberComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'mdmanager',
            component: GeneralUserComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'cmmanager',
            component: GeneralUserComponent,
            canActivate: [GuardService, AuthGuardService]
          }
        ]
      },
      {
        path: 'edit',
        component: TabsComponent,
        canActivate: [GuardService, AuthGuardService],
        children: [
          {
            path: 'admin/:user_id',
            component: GeneralUserComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'careteam/:user_id',
            component: GeneralUserComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'casecoordinator/:user_id',
            component: GeneralUserComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'partner/:user_id',
            component: GeneralUserComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'doctor/:user_id',
            component: DoctorComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'patient/:user_id',
            component: PatientComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'translator/:user_id',
            component: GeneralUserComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'interpreter/:user_id',
            component: GeneralUserComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'eligible-member/:user_id',
            component: EligibleMemberComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'mdmanager/:user_id',
            component: GeneralUserComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'cmmanager/:user_id',
            component: GeneralUserComponent,
            canActivate: [GuardService, AuthGuardService]
          },
        ]
      },
      {
        path: 'doctor/profile/:id',
        component: DoctorProfileComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'doctor-role',
        component: DoctorRoleComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'patient-role',
        component: PatientRoleComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'imd-careteam-role',
        component: ImdCareteamRoleComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'case-coordinator-role',
        component: CaseCorordinatorRoleComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'translator-role',
        component: TranslatorRoleComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'interpreter-role',
        component: InterpreterRoleComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'admin-role',
        component: AdminRoleComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'client-role',
        component: PartnerRoleComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'eligible-member-list',
        component: EligibleMemberListComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'md-manager-role',
        component: MdManagerRoleComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'cm-manager-role',
        component: CmManagerRoleComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'system-accounts',
        component: SystemAccountRoleComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'user/:userId/cases',
        component: AssignedCasesListComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'calender',
        component: CalenderComponent
      },
      {
        path: 'dashboard',
        component: MyDashboardComponent
      },
      {
        path: 'case-assignments',
        component: CaseAssignmentsComponent
      },
      {
        path: 'case-assignments/edit/:id',
        component: EditCaseAssignmentsComponent,
        canActivate: [GuardService, AuthGuardService],
      },
      {
        path: 'calender/:id',
        component: CalenderComponent
      },
      {
        path: 'clients',
        component: PartnersComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'clients/dashboard/:id',
        component: PartnerDashboardComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'patientdashboard',
        component: PatientDashboardComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'clients/create',
        component: CreatePartnerComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'clients/edit/:id',
        component: CreatePartnerComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'case',
        component: ListComponent,
        canActivate: [GuardService],
        children: [
          {
            path: '',
            component: TranslatorCaselistComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'doctor-consultation/:caseId',
            component: DoctorConsultationComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: ':caseid',
            component: CaseComponent,
            canActivate: [GuardService, AuthGuardService]
          },
          {
            path: 'create/:patientid',
            component: CreateCaseComponent,
            canActivate: [GuardService, AuthGuardService]
          }
        ]
      },
      {
        path: 'settings',
        component: SettingListComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'case-settings',
        component: CaseSettingsComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'archive-settings',
        component: ArchiveSettingsComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'zoom-settings',
        component: ZoomSettingsComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'release-settings',
        component: ReleaseSettingsComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'release-settings/email',
        component: ReleaseEmailComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'release-settings/notice',
        component: ReleaseNoticeComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'careteamList',
        component: SettingsComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'emailSettings',
        component: EmailSettingsComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'reportSettings',
        component: ReportSettingsComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'new-settings',
        component: NewSettingsComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'settings/outgoing-mail-server',
        component: OutgoingMailServerComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'settings/outgoing-mail-server/add',
        component: NewOutgoingMailServerComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'settings/outgoing-mail-server/edit/:id',
        component: EditOutgoingMailServerComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'settings/outgoing-mail-server/:id',
        component: ViewOutgoingMailServerComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'settings/release-email',
        component: ReleaseEmailComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'settings/release-notice',
        component: ReleaseNoticeComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'email-templates',
        component: EmailTemplateComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'email-templates/add',
        component: NewEmailTemplateComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'email-templates/edit/:id',
        component: EditEmailTemplateComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'email-templates/:id',
        component: ViewEmailTemplateComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'technical/case',
        component: CaseListComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'technical/case/:id',
        component: ViewCaseComponent,
        canActivate: [GuardService, AuthGuardService],
      },
      {
        path: 'technical/case/edit/:id',
        component: EditCaseComponent,
        canActivate: [GuardService, AuthGuardService],
      },
      {
        path: 'logs',
        component: LogsComponent
      },
      {
        path: 'edit/systemaccount/:user_id',
        component: SystemAccountUpsertComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'add/systemaccount',
        component: SystemAccountUpsertComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'mail-logs',
        component: MailLoggerComponent
      },
      {
        path: 'active-users/:role',
        component: ActiveUsersComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'inactive-users/:role',
        component: InactiveUsersComponent,
        canActivate: [GuardService, AuthGuardService]
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [GuardService, AuthGuardService]
      },
    ]
  },
  {
    path: 'redirect',
    component: NavigationComponentComponent
  },
  {
    path: 'healthcheck',
    component: HealthCheckComponent
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent
  },
  {
    path: '**',
    redirectTo: 'page-not-found'
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'corrected' })
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})

/**
 * Routing module for app
 */
export class AppRoutingModule { }

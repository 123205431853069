<app-dialog></app-dialog>
<div class="header">
  <div class="header__page-name">
    <h1 class="heading heading--main">Webhook Logs</h1>
  </div>
</div>
<div class="header">
	<div class="header__top-nav">
		<div class="top-menu">

			<div class="top-menu__user-stat">
				<span>Total: </span> {{totalCounts}} 
			</div>

			<div class="top-menu__menu">
				<ul class="top-menu__wrapper">
					<li class="status-list-trigger">
						<a href="javascript:void(0)">Filter</a>
						<ul class="status-list" style="right: -15px;left: auto;">
							<li *ngFor="let type of logTypes" (click)="addRemoveFilter(type)" style="cursor:pointer;"><input type="checkbox" [value]="type.id" [checked]="type.checked"> {{type.name}}</li>
						</ul>
					</li>			

				</ul>
			</div>
			<div class="top-menu__search">
				<div class="search-wrapper">
					<div class="search-input-trigger sarch-box" >
						<input id="caseSearch" type="text" [(ngModel)]="searchValue" placeholder="Search by CaseId"/>		
					</div>	
				</div>
			</div>
      
		</div>
	</div>
</div>

<!-- [class.overlay--visible] ="isInprogress" -->
<div class="wrapper--dash-content dash-content overlay__wrapper" [class.overlay--visible] ="isInprogress">
	<div class="overlay__content">
		<div class="overlay__spinner">
			<span class="icon icon--loading"></span>
		</div>
	</div>

  <table datatable [dtOptions]="dtOptions1" id="logtable_active"  #activeLogtable></table>

</div>

<app-log-details 
 [showDetails]="showDetails" 
 [logData]="currentData" 
 (toggleTemplate)="toggleTemplate($event)"
 (reloadData)="reloadData()">
</app-log-details>
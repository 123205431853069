<app-dialog></app-dialog>
<div class="header">
	<div class="header__page-name">
		<h1 class="heading heading--main">Email Template Details</h1>
	</div>
</div>

<div class="wrapper--dash-content dash-content">

	<div class="">
		<div class="col col__md-5 single-centered-col">
			<div class="profile-card">

				<div class="template-card__row">
					<div class="template-card__label">Name</div>
					<div class="template-card__details">
                        {{ templateObj?.name }}</div>
				</div>
				<div class="template-card__row">
					<div class="template-card__label">Subject</div>
					<div class="template-card__details">
                        {{ templateObj?.subject }}</div>
				</div>
				<div>
					<div class="template-card__label">Body</div>
					<div class="template-card__details">
                        <span [innerHTML]="templateObj?.body"></span></div>
				</div>
				<div class="template-card__row">
					<div class="template-card__label">Apply To</div>
					<div class="template-card__details">
                        {{ templateObj?.apply_to }}</div>
				</div>

				<div class="profile-card__btns">
                    <a [routerLink]="['/email-templates/edit/', templateObj?.id]" class="btn btn--green">Edit</a>
                    <a (click)="deleteTemplate()" class="btn btn--red">Delete</a>
					<a [routerLink]="['/email-templates']" class="btn btn--blue">Back </a>
				</div>
			</div>
		</div>
	</div>

</div>
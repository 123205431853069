/* eslint-disable max-len */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { PopUpService } from '../../../services/pop-up.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, AfterContentInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CommonFuntions } from '../../../common/common.function';
import { LoginService } from '../../../services/login.service';
import { Router } from '@angular/router';
import { CaseService } from '../../../services/case.service';
import * as moment from 'moment/moment';
import { UserService } from '../../../services/userservice.service';
import { ConfigAssetLoaderService } from '../../../services/config-asset-loader.service';

@Component({
  selector: 'schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit, OnChanges, AfterContentInit, AfterViewInit {

  disableInputs;
  // @Input() disableEdits;
  @Input()
  caseDetail: any;
  @Input() final_reports;
  @Input() translated_document;
  @Input() document_for_download;
  @Input() isMdManager;

  @Input() canContinue: Function;

  @Input()
  isInProgress: boolean;

  @ViewChild('consultationDate', {static: true})
  consultationDateInput: ElementRef

  @ViewChild('interpreter', {static: true})
  interpreterInput: ElementRef

  @Output() caseEmitter = new EventEmitter<any>();
  @Output() showStatusEmitter = new EventEmitter<string>();

  scheduleForm: FormGroup

  imdCareTeam: any;

  interpreterList: any[] = [];

  time_zone

  time_zone_offset

  currentDate

  isValidDate: boolean;
  isScheduleCollapse = true;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private caseService: CaseService,
    private userService: UserService,
    private popUpService: PopUpService
  ) { }

  ngOnChanges(_changes: SimpleChanges): void {
    this.setOnDataChange();
  }

  ngAfterContentInit(): void {
    this.setOnDataChange();
  }

  ngAfterViewInit(): void {
    if (this.caseDetail.Interpreter != null) {
      this.interpreterInput.nativeElement.value = this.caseDetail.Interpreter.full_name;
    }
  }

  private setOnDataChange() {
    if (this.scheduleForm && this.caseDetail != null) {
      this.imdCareTeam = this.caseDetail.status.find(element => element.status.status.toLowerCase() === 'schedule');

      this.scheduleForm.get('case_id').setValue(this.caseDetail.id);

      if (this.caseDetail.schedule != null) {
        this.is_md_verify.setValue(this.caseDetail.schedule.is_md_verify);
        this.is_patient_verify.setValue(this.caseDetail.schedule.is_patient_verify);
        this.setConsultationDateTime(this.caseDetail.schedule.consultation_date_time, this.consultationDateInput.nativeElement, true);
        this.scheduleForm.get('zoom_link').setValue(this.caseDetail.schedule.zoom_link);
        this.scheduleForm.get('notes').setValue(this.caseDetail.schedule.notes);
        this.scheduleForm.get('duration_in_minutes').setValue(this.caseDetail.schedule.duration_in_minutes);
      }

      /* if (this.caseDetail.interpreter_needed) {
        this.scheduleForm.addControl(
          'interpreter',
          new FormControl(
            null,
            Validators.required
          )
        )
      } */

      if (this.caseDetail.Interpreter != null) {
        const interpreter = {};
        interpreter['id'] = this.caseDetail.Interpreter.user_id;
        interpreter['full_name'] = this.caseDetail.Interpreter.full_name;
        interpreter['first_name'] = this.caseDetail.Interpreter.first_name;
        interpreter['middle_name'] = this.caseDetail.Interpreter.middle_name;
        interpreter['last_name'] = this.caseDetail.Interpreter.last_name;
        interpreter['email'] = this.caseDetail.Interpreter.email;
        interpreter['time_zone'] = this.caseDetail.Interpreter.time_zone;
        interpreter['time_zone_offset'] = this.caseDetail.Interpreter.time_zone_offset;
        this.scheduleForm.get('interpreter').setValue(
          interpreter
        );
        this.scheduleForm.get('interpreter').markAsTouched();
      }

      if (this.caseDetail?.allyCaseRef) this.disableInputs = true;
      else this.disableInputs = CommonFuntions.checkIfHoldOrCancelledOrFinishedorMd(this.caseDetail, this.isMdManager);
    }
  }

  ngOnInit() {
    this.scheduleForm = this.getFormGroup();
    if (this.caseDetail?.allyCaseRef) this.scheduleForm.disable();
    this.isValidDate = true;
    this.userService.getInterpreterList().subscribe(
      list => {
        this.interpreterList = list;
      },
      (_error) => {
        this.interpreterList = [];
      }
    );

    this.currentDate = new Date();
    if (this.caseDetail.Interpreter != null) {
      this.time_zone = this.caseDetail.Interpreter.time_zone;
      this.time_zone_offset = this.caseDetail.Interpreter.time_zone_offset;
    }
  }

  setConsultationDateTime(date, consultationDateInput, programSet?: boolean) {
    if (!programSet) {this.scheduleForm.get('consultation_date_time').markAsTouched();}

    let momentDate;

    if (date._selected) {
      momentDate = moment(date._selected);
    }
    else {
      momentDate = moment(date);
    }


    // comparing selecting date is greater than today

    const abc = moment().diff(momentDate, 'days');
    this.isValidDate = (abc > 0) ? false : true;

    const userAgent = window.navigator.userAgent;

    const browsers = { chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i };
    let selectedDate = '';
    for (const key in browsers) {
      if (browsers[key].test(userAgent)) {
        if (key == 'firefox') {
          selectedDate = momentDate.format('MM/DD/YYYY, h:mm a').substring(0, 24);

        }
        else {
          if (userAgent.toLowerCase().indexOf('safari') != -1) {
            if (userAgent.toLowerCase().indexOf('chrome') > -1) {
              selectedDate = momentDate.format().substring(0, 16);
            }
            else {
              selectedDate = momentDate.format('MM/DD/YYYY, h:mm a').substring(0, 24);
            }
          }
        }



        consultationDateInput.value = selectedDate;
        this.scheduleForm.get('consultation_date_time').setValue(momentDate.format());
      }
    }
  }

  getFormGroup() {
    return this.formBuilder.group(
      {
        is_md_verify: [
          false,
          CommonFuntions.isChecked
        ],
        is_patient_verify: [
          false,
          CommonFuntions.isChecked
        ],
        consultation_date_time: [
          null,
          Validators.required
        ],
        interpreter: [
          null
        ],
        zoom_link: [
          null,
          CommonFuntions.validateZoomLink,
        ],
        notes: [
          null,
          Validators.maxLength(50000)
        ],
        case_id: [
          this.caseDetail == null ? null : this.caseDetail.id
        ],
        duration_in_minutes: [
          null,
          Validators.required
        ]
      }
    );
  }


  public showAlert(message: string, positiveAction?: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(positiveAction);
    }
    else {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) {this.popUpService.showDiv(dialog);}
  }

  onFinish(form: FormGroup) {
    if (CommonFuntions.guardForAction(this.loginService,
      this.router)) {
      if (form.valid && this.isValidDate) {
        if (!this.canContinue(this.caseDetail, 'schedule')) {
          this.showAlert('Please complete previous stages to complete \'Schedule\'.' ,function () {
            this.popUpService.hideDiv('alertDialog');
          }.bind(this));
          return;
        }
        const partner = {};
        partner['full_name'] = this.caseDetail.user.full_name;
        partner['email'] = this.caseDetail.user.email;

        this.scheduleForm.addControl(
          'partner', new FormControl(partner)
        );

        this.scheduleForm.addControl(
          'case_identifier', new FormControl(this.caseDetail.case_identifier)
        );

        this.scheduleForm.addControl(
          'consultation_url', new FormControl(ConfigAssetLoaderService.getDomainURL())
        );

        const doctor = {};
        doctor['last_name'] = this.caseDetail?.case_md_management?.user?.last_name;
        doctor['email'] = this.caseDetail?.case_md_management?.user?.email;

        this.scheduleForm.addControl(
          'doctor', new FormControl(doctor)
        );

        // var time_zone = '';
        // var time_zone_offset = '';



        this.scheduleForm.addControl(
          'time_zone', new FormControl(this.time_zone)
        );

        this.scheduleForm.addControl(
          'time_zone_offset', new FormControl(this.time_zone_offset)
        );

        this.scheduleForm.addControl(
          'assignee', new FormControl(this.imdCareTeam?.user?.email)
        );


        this.isInProgress = true;
        this.caseService.createOrUpdateCaseSchedule(
          form.value,
          this.caseDetail.id
        ).subscribe(
          function (response) {
            this.isInProgress = false;
            const index = this.caseDetail.status.findIndex(eachStatus => eachStatus.status.status.toLowerCase() === 'schedule');
            if (index > -1) {
              this.caseDetail.status[index].state = 'FINISHED';
            }

            const inprogressList = CommonFuntions.setStatusInProgress(this.caseDetail.status, 'schedule');

            const statusList = [];

            if (inprogressList.length < 1) {
              const statusObject = CommonFuntions.getFirstInProgressParaStatus(this.caseDetail.status);
              const user = this.loginService.getCurrentUser();
              
              this.caseService.getCaseById(this.caseDetail.id, user.user_id).subscribe(
                function (caseDetail) {
                  this.object.showAlert(`${this.response.message}.` ,function () {
                    this.object.popUpService.hideDiv('alertDialog');
                  }.bind(this));									
                  caseDetail;
                  caseDetail.date_mr_complete = moment(caseDetail.date_mr_complete).format('MM/DD/YYYY, h:mm A');
                  caseDetail['final_reports'] = this.object.final_reports;
                  caseDetail['document_for_download'] = this.object.document_for_download;
                  caseDetail['translated_document'] = this.object.document_for_download;
                  this.object.showStatusEmitter.emit(this.statusObject.status.status);
                  this.object.caseEmitter.emit(caseDetail);
                }.bind({
                  object: this,
                  statusObject,
                  response
                })
              );

            }
            else {
              inprogressList.forEach(eachStatus => {
                statusList.push({ status_id: eachStatus.status.id });
              });

              this.caseService.updateStaus(statusList, this.caseDetail.id).subscribe(
                function (_result) {
                  const user = this.object.loginService.getCurrentUser();
                  this.object.caseService.getCaseById(this.object.caseDetail.id, user.user_id).subscribe(
                    function (caseDetails) {
                      this.object.showAlert(`${this.response.message}.` ,function () {
                        this.object.popUpService.hideDiv('alertDialog');
                      }.bind(this));
                      caseDetails;
                      caseDetails.date_mr_complete = moment(caseDetails.date_mr_complete).format('MM/DD/YYYY, h:mm A');
                      caseDetails['final_reports'] = this.object.final_reports;
                      caseDetails['document_for_download'] = this.object.document_for_download;
                      caseDetails['translated_document'] = this.object.translated_document;
                      this.object.caseEmitter.emit(caseDetails);
                      this.object.showStatusEmitter.emit(this.inprogressList[0].status.status);
                    }.bind({
                      object: this.object,
                      inprogressList,
                      response: this.response
                    })
                  );
                }.bind({
                  object: this,
                  response
                })
              );
            }
          }.bind(this),
          function(error) {
            this.isInProgress = false;
            if(error.error.message === 'Host Unavailable'){
              this.showAlert('Currently all host are reserved in this time. Please pick a new time to schedule the meeting.' ,function () {
                this.popUpService.hideDiv('alertDialog');
              }.bind(this));
            }
            else{
              this.showAlert('Failed to update case schedule. Please try again.' ,function () {
                this.popUpService.hideDiv('alertDialog');
              }.bind(this));
            }						
          }.bind(this),
          () => {
            this.isInProgress = false;
          }
        );
      }
      else {
        CommonFuntions.validateAllFormFields(form);
      }
    }
  }

  get duration_in_minutes(){
    return this.scheduleForm.get('duration_in_minutes');
  }

  get is_md_verify() {
    return this.scheduleForm.get('is_md_verify');
  }

  get is_patient_verify() {
    return this.scheduleForm.get('is_patient_verify');
  }

  get consultation_date_time() {
    return this.scheduleForm.get('consultation_date_time');
  }

  get zoom_link() {
    return this.scheduleForm.get('zoom_link');
  }

  get notes() {
    return this.scheduleForm.get('notes') as FormControl;
  }

  public interpreterListFormatter(data: any): string {

    return data.full_name;
  }

  public interpreterValueFormatter(data: any): string {
    if (data.hasOwnProperty('time_zone')) {
      this.time_zone = data.time_zone;
      this.time_zone_offset = data.time_zone_offset;
    }
    return data.full_name;
  }

  clearIfNotInList(interpreterInput: HTMLInputElement, _value: any) {
    const interpreterName = interpreterInput.value;
    const interpreter = this.interpreterList.find(each => each.full_name === interpreterName);

    if (interpreter == null) {
      this.scheduleForm.get('interpreter').reset();
      this.scheduleForm.get('interpreter').markAsTouched();
      interpreterInput.value = null;
    }
    else {
      this.scheduleForm.get('interpreter').setValue(interpreter);
    }
  }

  setFocusToInterpreterControl() {

    if (this.scheduleForm.get('interpreter') != null) {
      this.scheduleForm.get('interpreter').markAsTouched();
    }
  }

  isUserInActive() {
    if (this.caseDetail && this.caseDetail.Interpreter != null && this.scheduleForm.get('interpreter').value && this.scheduleForm.get('interpreter').value.id) {
      const user_id = this.caseDetail.Interpreter.user_id || 0;
      if (this.scheduleForm.get('interpreter').value.id != user_id) {
        return false;
      }

      if (this.caseDetail.Interpreter.active == 0) {
        return true;
      }
    }
    return false;
  }

}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { USER_TYPE_BY_ID } from '../../../../app/constant';
import { CaseService } from '../../../../app/services/case.service';
import { CommonService } from '../../../../app/services/common.service';
import { EmailTemplateService } from '../../../services/email-template.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-release-email',
  templateUrl: './release-email.component.html',
  styleUrls: ['./release-email.component.css']
})
export class ReleaseEmailComponent implements OnInit {
  loading = true;
  release_email_subject = '';
  release_email_body = '';
  release_active_user_id = [];
  release_type= '';
  users = [];
  label: string;
  loadReleaseForm:boolean;

  constructor(
    private caseService: CaseService,
    private toastr: ToastrService,
    private commonService : CommonService,
    private emailTemplateService: EmailTemplateService,
  ) {}

  ngOnInit(): void {
    this.loadUsers(2);
    this.selectReleaseType('Pre Release');
    this.fetchReleaseEmailTemplate('Pre Release');
  }

  onSubmitReleaseForm(form) {
    if (this.release_email_body == '' || this.release_email_subject == '' || this.release_type == '') {
      this.toastr.warning('Fill all the mandatory field ', 'Invalid form');
      return false;
    }

    const payload = {};
    payload['emailTemplate'] = '<p> ' + this.release_email_body + '</p>'; // body of the release email
    payload['subject'] = this.release_email_subject;
    payload['mail_type'] = 'RELEASE';

    // push the email string to an array when its selected
    const userEmailList = [];
    for (const email in form.value) {
      if (form.value[email] === true) {
        userEmailList.push(email);
      }
    }

    if(userEmailList.length == 0){
      this.toastr.warning('You need to select at least one recipients', 'Invalid form');
      return false;
    }

    payload['emailTo'] = userEmailList;

    // shows loader while sending the form
    Swal.fire({
      html: '<b>Release email is sending to selected users. <b><br><br> * Don\'t close or refresh the page while processing this request',
      title: 'Please Wait',
      backdrop: '#eef3f6',
      reverseButtons: true,
      iconColor: 'green',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: false,
      showDenyButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    this.commonService.sendEmail(payload).subscribe((data) => {
      if (data.status == 200) {
        // shows the model if there is success
        Swal.fire({
          text: data['message'],
          title: 'Success',
          backdrop: '#eef3f6',
          reverseButtons: true,
          iconColor: 'green',
          icon: 'success',
          showCancelButton: false,
          showDenyButton: false,
          confirmButtonColor: '#db151e',
          confirmButtonText: 'X Close',
        });
      }
      else {
        // shows the model if there is any error messages
        Swal.fire({
          text: 'This is an error message',
          title: 'Error',
          backdrop: '#eef3f6',
          reverseButtons: true,
          iconColor: 'red',
          icon: 'error',
          showCancelButton: false,
          showDenyButton: false,
          confirmButtonColor: '#db151e',
          confirmButtonText: 'X Close',
        });
      }
    },error =>{
      if(error.status){
        Swal.fire({
          text: error.error.message,
          title: 'Error',
          backdrop: '#eef3f6',
          reverseButtons: true,
          iconColor: 'red',
          icon: 'error',
          showCancelButton: false,
          showDenyButton: false,
          confirmButtonColor: '#db151e',
          confirmButtonText: 'X Close',
        });
      }
    });
  }

  loadUsers(userType : number):void{
    this.loading = true;
    this.caseService
      .getActiveUserForReleaseNotification(userType)
      .subscribe((response) => {
        this.users = response.userWithActiveCases.map((user) => {
          return {
            ...user, // spread existing properties from the original object
            selected: true, // override the selected property with true
            user_type: USER_TYPE_BY_ID[user?.user_type_id],
          };
        });
        this.loading = false;
      });
  }

  selectUser($event:any):void {
    this.loadUsers($event);
  }

  selectAll($event:any):void {
    $event.preventDefault();
    this.users.forEach((user) => {
      user.selected = true;
    });
  }

  clearAll($event:any):void {
    $event.preventDefault();
    this.users.forEach((user) => {
      user.selected = false;
    });
  }

  selectReleaseType($event: string): void {
    if($event == 'Pre Release'){
      this.release_type =  'Pre Release';
      this.label = 'Send Pre Release Email';
    }
    else{
      this.release_type =  'Post Release';
      this.label = 'Send Post Release Email';
    }
    this.fetchReleaseEmailTemplate($event);
  }


  fetchReleaseEmailTemplate(apply_to: string):void{
    this.loadReleaseForm = true;
    this.emailTemplateService.getEmailTemplateByApply_To(apply_to).subscribe(
      result => {
        const { status, data } = result;
        if (status === 200) {
          this.release_email_body = data?.body;
          this.release_email_subject = data?.subject;
          this.loadReleaseForm = false;
        }
      }
    );
  }
}

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { ConfigAssetLoaderService } from '../../services/config-asset-loader.service';

@Component({
  selector: 'app-setting-list',
  templateUrl: './setting-list.component.html',
  styleUrls: ['./setting-list.component.css']
})
export class SettingListComponent implements OnInit {

  isProd : boolean;

  constructor() { 

  }

  ngOnInit(): void {
    this.isProd = ConfigAssetLoaderService.getProduction();
  }

}

/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, DoCheck, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { Validators } from '@angular/forms';
import * as moment from 'moment/moment';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { TreeviewItem } from 'ngx-treeview';

import { LoginService } from '../../../services/login.service';
import { Router, UrlTree, UrlSegmentGroup, UrlSegment, PRIMARY_OUTLET, ActivatedRoute } from '@angular/router';
import { CommonFuntions } from '../../../common/common.function';
import { AdminModel } from '../../../domains/adminModel';
import { UserService } from '../../../services/userservice.service';
import { ConfigAssetLoaderService } from '../../../services/config-asset-loader.service';
import { PlatformLocation } from '@angular/common';
import { CommonService } from '../../../services/common.service';
import { ViewChild, ElementRef } from '@angular/core';
import { DatashareService } from '../../../services/datashare.service';
import { PopUpService } from '../../../services/pop-up.service';
import { filter, orderBy } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-general-user',
  templateUrl: './general.user.component.html',
  styleUrls: ['./general.user.component.css']
})

export class GeneralUserComponent implements OnInit, DoCheck {
  @ViewChild('languageField') languageField: ElementRef;
  @ViewChild('dateInput', { static: true }) dobInput;
  @ViewChild('addDoctorSwal') private addDoctorSwal: SwalComponent;
  avatarMale: string;
  adminForm: FormGroup;
  adminModel = new AdminModel();
  operation: string;
  userId: number;
  uploadErrorMessage: string;
  uploadCompleteMessage: string;
  public uploader: FileUploader = new FileUploader({
    url: ConfigAssetLoaderService.getApiURL() + '/upload',
    itemAlias: 'photo',
    authToken: `Bearer ${localStorage.getItem('token')}`,
    allowedMimeType: [
      'image/jpg',
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/tif',
      'image/bmp'
    ],
    maxFileSize: 2 * 1024 * 1024
  });
  timezones: any = [];
  time_zone_selected: string;
  isSettings: any;
  userType: string;
  userTypeId: number;
  validatePartner = true;
  validateLanguage = false;
  showPassword = true;
  languageHasFocus = false;
  languagesList: any[] = [];
  filteredLanguageList = [];
  socialTypes: any[];
  items: TreeviewItem[];

  addUpdateLabel: string;
  allPartnerLength: number
  partnerList = [];
  partnerNameList = [];
  associatedList = [];
  sourceList = [];
  totalList = [];
  avatarimage: string = null;

  passwordReadOnly = false;
  currentUser: any;
  partnerInterval: any
  isPartnerListReady: boolean
  isPartnerListReadyCount: number
  loadingImage: boolean;
  isSupportPassword = false;
  isRecoverPassword = false;
  isResetPassword = false;
  isAllyInputDisabled = true;
  role: string;
  currentDate;
  originalPartners = [];
  allPartnerList = [];
  isPartnerChecked = false;
  password_type = 'password';
  constructor(
    public loginService: LoginService,
    public userService: UserService,
    public router: Router,
    private route: ActivatedRoute,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private location: PlatformLocation,
    private commonService: CommonService,
    private datashare: DatashareService,
    private popUpService: PopUpService,
    private toastrService:ToastrService
  ) {
    this.isPartnerListReadyCount = 0;

  }

  ngOnInit() {
    this.currentDate = new Date();
    this.currentDate.setHours(23, 59, 59, 999);

    this.allPartnerLength = 0;
    this.route.queryParams.subscribe(
      queries => {
        this.isSettings = queries['isSettings'] === 'true' ? true : false;
      }
    );

    this.getTimeZone();

    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g.segments;

    this.operation = s[0].path;
    this.operation = this.operation === 'add' ? 'Add' : 'Edit';

    this.userType = s[1].path;

    // this.getAvatar(); // Get user avatar
    this.configureFileUploadSettings(); //Set file upload configuration

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const self = this;

    const [userTypeId, label] = this.getUserIdAndLabel(this.userType);
    this.userTypeId = userTypeId;
    this.addUpdateLabel = label;

    this.currentUser = this.loginService.getCurrentUser();
    this.role = this.currentUser.user_type.type.toLowerCase() || '';
    this.checkAllyInputDisable();
    this.adminForm = this.initializeForm();
    this.dynamicForm();

    this.getSocialType();

    this.showPassword = true;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const sub = this.route.params
      .subscribe(params => {
        const self = this;

        if (params['user_id'] && isNaN(params['user_id'])) {
          this.router.navigate(['/page-not-found']);
          return;
        }

        this.userId = +params['user_id'];

        if (this.userId) {
          const formControl = this.adminForm.get('user_id');
          if (formControl) { formControl.setValue(this.userId); }

          const formArrayControls = (this.adminForm.get('contacts') as FormArray).controls;
          if (formArrayControls.length > 0) { formArrayControls[0].get('user_id').setValue(this.userId); }

          self.uploader.options.url = ConfigAssetLoaderService.getApiURL() + '/upload?userid=' + this.userId;

          self.showPassword = false;

          this.addUpdateLabel = 'Save';

          self.getUserDetail();
        }
        else {
          this.avatarMale = 'assets/images/avatar-male.jpg';
          this.loadingImage = false;
          if (self.userType.toLowerCase() == 'translator') { this.checkUncheckType('Translator'); }
          else if (self.userType.toLowerCase() == 'interpreter') { this.checkUncheckType('Interpreter'); }
          this.addFormControlOnCreate();
        }
      });
    if (this.password) this.generate(true);
  }

  ngDoCheck(): void {
    if (!this.isPartnerChecked && this.originalPartners.length > 0 && this.allPartnerList.length > 0) {
      console.log('client tree check trigger');
      this.dropDrownSelectionChange(this.originalPartners);
      this.isPartnerChecked = true;
    }
  }

  private checkAllyInputDisable(): void {
    if (this.role === 'admin' || this.role === 'cmmanager') {
      this.isAllyInputDisabled = false;
    }
  }

  /** Get the file from the S3 */
  getFile(result): void {
    this.loadingImage = true;
    if (result.avatar && result.avatar != '') {
      this.userService.getUploadedFile(result.avatar).subscribe(
        image => {
          this.avatarimage = image.image;
          this.loadingImage = false;
        }, (_error) => {
          this.avatarMale = 'assets/images/avatar-male.jpg';
          this.loadingImage = false;
          this.avatarimage = null;
        }
      );
    }
    else {
      this.avatarMale = 'assets/images/avatar-male.jpg';
      this.loadingImage = false;
    }
  }

  configTranslatorInterpreter(result) {
    if (result.user_type_id == 6) {
      this.changeUserType(true, 'Translator');
      result['translator'] = true;
      result['interpreter'] = false;
    }

    if (result.user_type_id == 7) {
      this.changeUserType(true, 'Interpreter');
      result['translator'] = false;
      result['interpreter'] = true;
    }

    return result;
  }

  configSocialContacts(result) {
    if (this.adminModel.contacts.length > 0) {
      if (this.contacts.length < this.adminModel.contacts.length) {
        for (let i = 1; i < this.adminModel.contacts.length; i++) {
          this.contacts.push(this.getContactFormGroup());
        }
      }
    }
    else {
      const thisContactList = [{
        contact_id: null,
        user_id: 0,
        social_type_id: 0,
        social_id: ''
      }];
      result.contacts = thisContactList;
    }

    return result;
  }

  configLanguage(result) {
    if ((this.userType == 'translator' || this.userType == 'interpreter')
      && this.user_languages.length < result.user_languages.length) {
      const languageList = [];

      result.user_languages.forEach(
        user_language => {
          const langugageObject = {
            language_id: user_language.language_id,
            language: user_language.language.language
          };
          languageList.push(langugageObject);

          this.languagesList = this.languagesList.filter(
            language => !(language.language_id === langugageObject.language_id)
          );

          this.user_languages.push(new FormControl());
        }
      );

      result.user_languages = languageList;
    }

    return result;
  }

  configPartner(result) {
    if ((this.userType === 'careteam' || this.userType === 'partner')
      && this.user_partners.length <= result.user_partners.length) {

      result.user_partners = result.user_partners.map(each => {
        this.originalPartners.push(each.partner.partner_id);
        const partnerObj = {
          partner_id: each.partner.partner_id,
          partner_name: each.partner.partner_name,
          active: each.partner.active
        };
        return partnerObj;
      });

      result.user_partners.forEach(
        (_user_partner) => {
          if (this.user_partners.length < result.user_partners.length) {
            this.user_partners.push(new FormControl());
          }
        }
      );
    }
    // enable do check for partner
    this.isPartnerChecked = false;
    return result;
  }

  getUserDetail() {
    this.userService.getUser(this.userId).subscribe(
      result => {
        this.adminModel = result as AdminModel;

        // partner null object handled
        if (result && result.user_partners) {
          result.user_partners = filter(result.user_partners, (partner) => {
            if (partner && partner.partner) {
              return partner;
            }
          });
          result.user_partners.forEach(each => {
            this.originalPartners.push(each.partner.partner_id);
          });
        }
        this.configSettings(result);

        this.checkValidEdit(result);

        this.datashare.setData(this.adminModel.full_name);
        delete result['full_name'];
        this.adminForm.addControl('user_id', new FormControl(this.adminModel.user_id));

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const user_type = '';

        result = this.configTranslatorInterpreter(result);

        this.getFile(result);

        result = this.configSocialContacts(result);

        result = this.configLanguage(result);

        result = this.configPartner(result);

        this.adminForm.patchValue(result);

        if (this.adminModel.dob) this.dobInput.nativeElement.value = this.adminModel.dob;
      },
      (_error) => {
        this.router.navigate(['/']);
      }
    );
  }

  getAvatar() {
    this.loadingImage = true;
    this.datashare.$displayPicture.subscribe(
      image => {
        this.avatarimage = image;
        console.log(image);
        this.loadingImage = false;
      },
      error => {
        this.loadingImage = false;
        console.log(error);
      }
    );
  }

  getUserIdAndLabel(userType: string): [number, string] {
    let userTypeId = 1;
    let label = 'Add Admin';

    if (userType === 'careteam') {
      userTypeId = 2;
      label = 'Add Careteam';
    }
    if (userType === 'casecoordinator') {
      userTypeId = 11;
      label = 'Add Case Coordinator';
    }

    if (userType === 'partner') {
      userTypeId = 3;
      label = 'Add Client Careteam';
    }

    if (userType === 'translator') {
      userTypeId = 6;
      label = 'Add Summary Coordinator';
    }

    if (userType === 'interpreter') {
      userTypeId = 7;
      label = 'Add Interpreter';
    }

    if (userType === 'doctor') {
      userTypeId = 4;
      label = 'Add Doctor';
    }

    if (userType === 'mdmanager') {
      userTypeId = 8;
      label = 'Add MD Manager';
    }

    if (userType === 'cmmanager') {
      userTypeId = 10;
      label = 'Add CM Manager';
    }

    if (userType === 'systemaccount') {
      userTypeId = 9;
      label = 'Add System Account';
    }

    return [userTypeId, label];
  }

  getPartners() {
    this.commonService.getPartners().subscribe(
      function (partners) {
        if (this.currentUser) {
          partners.forEach(
            partner => {
              const partnerObj = {};
              partnerObj['partner_id'] = partner.partner_id;
              partnerObj['partner_name'] = partner.partner_name;
              this.partnerNameList.push(partner.partner_name);
              this.partnerList.push(partner);
            }
          );

          this.allPartnerLength = this.partnerList.length;
          this.allPartnerList = this.partnerList;
          this.isPartnerListReady = true;

          // order by name
          partners = orderBy(partners, ['partner_name'], ['asc']);

          const partnerInfo = { ally: [], nonAlly: [] };
          partners.forEach(partner => {
            const data = { text: partner.partner_name, value: partner.partner_id, checked: false };
            if (partner?.allyPartnerRef) {
              partnerInfo.ally.push(data);
            }
            else {
              partnerInfo.nonAlly.push(data);
            }
          });
          this.items = [new TreeviewItem({
            text: 'Ally Clients',
            value: 'ally',
            checked: false, // by default to set unchecked
            children: [...partnerInfo.ally],
          }),
          new TreeviewItem({
            text: 'Non Ally Clients',
            value: 'non-ally',
            checked: false, // by default to set unchecked
            children: [...partnerInfo.nonAlly],
          })];
        }
      }.bind(this)
    );
  }

  initializeForm(): FormGroup {
    return new FormGroup(
      {
        first_name: new FormControl(this.adminModel.full_name, [
          Validators.required,
          Validators.maxLength(50)
        ]),
        middle_name: new FormControl(this.adminModel.full_name, [
          Validators.maxLength(50)
        ]),
        last_name: new FormControl(this.adminModel.full_name, [
          Validators.required,
          Validators.maxLength(50)
        ]),
        email: new FormControl(this.adminModel.email, [
          Validators.required,
          CommonFuntions.ValidateEmail
        ]
        ),
        phone_number: new FormControl(this.adminModel.phone_number, [
          Validators.maxLength(15),
          Validators.minLength(9),
          Validators.required
        ]),
        dob: new FormControl(this.adminModel.dob),
        allyUserRef: new FormControl({ value: this.adminModel.allyUserRef, disabled: this.isAllyInputDisabled }, [
          Validators.maxLength(200)
        ]),
        contacts: new FormArray([
          this.getContactFormGroup()
        ]),
        avatar: new FormControl(),
        user_type_id: new FormControl(this.userTypeId),
        time_zone: new FormControl(
          this.adminModel.time_zone,
          [
            Validators.required]
        ),
        time_zone_offset: new FormControl(this.adminModel.time_zone_offset)
      }, {
        updateOn: 'blur'
      }
    );
  }

  /** Add these form control only when doing create operation. */
  addFormControlOnCreate() {
    this.adminForm.addControl('password', new FormControl('', { validators: [CommonFuntions.ValidatePassword], updateOn: 'change' }));
    this.adminForm.addControl('auto_generate_pwd', new FormControl(true));
  }

  dynamicForm() {
    switch (this.userTypeId) {
    case 11:
    case 2:
    case 3:

      if(CommonFuntions.getPartnersForAuthorizedUser(this.role)){
        this.getPartners();
      }

      this.adminForm.addControl('user_partners', new FormArray([], CommonFuntions.fomrArrayMinLength(1)));
      this.adminForm.addControl('is_all_partners', new FormControl(0));
      break;
    case 6:
    case 7:
      this.getLanguages();
      this.adminForm.addControl('user_languages', new FormArray([], CommonFuntions.fomrArrayMinLength(1)));
      this.adminForm.addControl('translator', new FormControl());
      this.adminForm.addControl('interpreter', new FormControl());
      break;
    default:
      break;
    }
  }

  getTimeZone() {
    this.commonService.getTimeZones().subscribe(
      result => {
        this.timezones = result;
      },
      (_error) => {
        this.timezones = [];
      }
    );
  }

  getSocialType() {
    this.commonService.getSocialTypes().subscribe(
      (types) => {
        this.socialTypes = types;
        this.socialTypes.splice(0, 0, {
          social_type_id: 0,
          social_type: 'Select'
        });
      },
      (_error) => {
        this.socialTypes = [
          {
            social_type_id: 0,
            social_type: 'Select'
          }
        ];
      }
    );
  }

  getLanguages() {
    this.commonService.getLanguages().subscribe(
      result => {
        this.languagesList = result;
        this.filteredLanguageList = result;
      }, (_error) => {
        this.languagesList = [];
      }
    );
  }

  configureFileUploadSettings() {
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.uploader.uploadAll();
    };

    this.uploader.onCompleteItem = function (_item: any, response: any, status: any, _headers: any) {
      if (status && status == 200) {
        const responseObject = JSON.parse(response);

        if (this.currentUser.user_id == this.userId) {
          this.datashare.setDisplayPicture(responseObject.image);
        }
        else {
          this.avatarimage = responseObject.image;
        }

        this.avatar.setValue(responseObject.location);
        this.uploadErrorMessage = null;
        this.uploadCompleteMessage = 'Upload successful.';

      }
      else if (status && status == 422) {
        this.uploadErrorMessage = 'Upload failed.';
        this.uploadCompleteMessage = null;
      }
      else {
        this.uploadErrorMessage = 'Upload failed through server.';
        this.uploadCompleteMessage = null;
      }
    }.bind(this);


    this.uploader.onWhenAddingFileFailed = function (_item, filter, _option) {
      switch (filter.name) {
      case 'mimeType':
        this.showAlert('System does not support this type of file format.');
        break;
      case 'fileSize':
        this.showAlert('Files uploaded must be less than 2MB in size.');
        break;
      default:
        break;
      }
    }.bind(this);
  }

  setKey(input: HTMLSelectElement) {
    this.time_zone_selected = input.selectedOptions[0].innerHTML;
  }

  changeUserType(checkbox: HTMLInputElement | boolean, user_type) {
    let whichType;
    if (typeof (checkbox) === 'boolean') {
      whichType = checkbox;
    }
    else if (checkbox instanceof HTMLInputElement) {
      whichType = checkbox.checked;
    }
    else {
      whichType = false;
    }
    if (user_type == 'Translator' && whichType) {
      this.checkUncheckType('Translator');
      if (this.addUpdateLabel.toLowerCase() !== 'save') { this.addUpdateLabel = 'Add Summary Coordinator'; }

    }
    else if (user_type == 'Interpreter' && whichType) {
      this.checkUncheckType('Interpreter');
      if (this.addUpdateLabel.toLowerCase() !== 'save') { this.addUpdateLabel = 'Add Interpreter'; }

    }
    else if (user_type == 'Interpreter' && !whichType) {
      this.checkUncheckType('Translator');
      if (this.addUpdateLabel.toLowerCase() !== 'save') { this.addUpdateLabel = 'Add Summary Coordinator'; }

    }
    else {
      this.checkUncheckType('Translator');
      if (this.addUpdateLabel.toLowerCase() !== 'save') { this.addUpdateLabel = 'Add Summary Coordinator'; }
    }

  }

  checkUncheckType(user_type: string) {
    if (user_type == 'Translator') {
      this.translator.setValue(true);
      this.interpreter.setValue(false);

      this.adminForm.get('user_type_id').setValue(6);
    }
    else if (user_type == 'Interpreter') {
      this.interpreter.setValue(true);
      this.translator.setValue(false);
      this.adminForm.get('user_type_id').setValue(7);
    }

  }

  get translator() {
    return this.adminForm.get('translator');
  }

  get interpreter() {
    return this.adminForm.get('interpreter');
  }

  get first_name() {
    return this.adminForm.get('first_name');
  }

  get middle_name() {
    return this.adminForm.get('middle_name');
  }

  get time_zone() {
    return this.adminForm.get('time_zone');
  }

  get last_name() {
    return this.adminForm.get('last_name');
  }

  get email() {
    return this.adminForm.get('email');
  }

  get phone_number() {
    return this.adminForm.get('phone_number');
  }

  get contacts() {
    return (this.adminForm.get('contacts') as FormArray);
  }

  get avatar() {
    return this.adminForm.get('avatar');
  }

  get password() {
    return this.adminForm.get('password');
  }

  get user_partners() {
    return this.adminForm.get('user_partners') as FormArray;
  }

  get is_all_partners() {
    return this.adminForm.get('is_all_partners');
  }

  get dob() {
    return this.adminForm.get('dob');
  }

  addAnotherContact() {
    this.contacts.push(this.getContactFormGroup());
  }

  private getContactFormGroup() {
    return new FormGroup(
      {
        social_id: new FormControl('', [
          // Validators.required,
          Validators.maxLength(25)
        ]),
        social_type_id: new FormControl(0, [
          // CommonFuntions.validateDropDown
        ]),
        contact_id: new FormControl(null),
        user_id: new FormControl(this.adminModel.user_id)
      }
    );
  }

  get user_languages() {
    return this.adminForm.get('user_languages') as FormArray;
  }

  public showAlert(message: string, positiveAction?: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(positiveAction);
    }
    else {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) { this.popUpService.showDiv(dialog); }
  }

  validateForm(){
    CommonFuntions.validateAllFormFields(this.adminForm);
    if(this.adminForm.valid){
      this.addDoctorSwal.fire();
    }
    else{
      this.toastrService.warning('Please fill all mandatory input fields','Invalid Form');
    }
    
    if (this.user_partners && this.user_partners.length == 0) {
      this.validatePartner = false;
    }
    this.validateLanguage = true;

  }

  addAdmin(form: FormGroup) {
   
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    this.validateLanguage = true;

    // set is all partner flag
    if (this.is_all_partners) {
      if (this.items && this.items.length > 0 && ['admin', 'cmmanager'].includes(this.role)) {
        if (this.items[0].checked && this.items[1].checked) {
          this.is_all_partners.setValue(1);
        }
        else if (this.items[0].checked) {
          this.is_all_partners.setValue(2);
        }
        else if (this.items[1].checked) {
          this.is_all_partners.setValue(3);
        }
      }
      else {
        this.is_all_partners.setValue(0);
      }
    }

    if (CommonFuntions.guardForAction(this.loginService,
      this.router)) {
      
      if (this.user_partners && this.user_partners.length == 0) {
        this.validatePartner = false;
        return;
      }

      if (this.operation.toLowerCase() === 'add') {
       
        // check if contact method exists and remove if not present
        if (form.getRawValue().contacts) {
          let i = 0;
          form.getRawValue().contacts.forEach(element => {
            if (element.social_id == '' || element.social_type_id == 0) {
              this.contacts.removeAt(i);
            }
            else {
              i++;

            }
          });
        }

        const admin: AdminModel = form.getRawValue() as AdminModel;
        admin.allyUserRef = admin.allyUserRef || null;

        if (this.time_zone_selected != undefined) {
          admin.time_zone_offset = this.time_zone_selected;
        }

        this.userService.addUser(admin).subscribe((result)=>{
          this.toastrService.success(result['msg']);
          this.router.navigate(['/profile', result.user_id]);
        },(error)=>{
        
          this.toastrService.error(error['error'].message);
          if (this.user_partners && this.user_partners.length == 0) {
            this.validatePartner = false;
          }

          CommonFuntions.validateAllFormFields(form);
  
        }
       
        );

      }
      else if (this.operation.toLowerCase() === 'edit') {

        if (form.getRawValue().contacts) {
          let i = 0;
          form.getRawValue().contacts.forEach(element => {
            if (element.social_id === '' || element.social_type_id === 0) {
              this.contacts.removeAt(i);
            }
            else {
              i++;

            }
          });
        }

        const admin: AdminModel = form.getRawValue() as AdminModel;
        admin.allyUserRef = admin.allyUserRef || null;

        if (this.time_zone_selected != undefined) {
          admin.time_zone_offset = this.time_zone_selected;
        }

        admin.user_id = this.userId;

        this.userService.updateUser(admin).subscribe((_result) => {
          this.router.navigate(['/profile', this.userId]);     
          // if self user and password changed then
          if (this.userId === this.currentUser.user_id && admin.password) {
            localStorage.removeItem('passwordExpirySetting');
          }
        },(error)=>{
          
          this.toastrService.error(error['error'].message);
        }
          
        );
      }

     

    }
  }

  removeContactAt(index: number) {
    const contact = this.contacts.controls[index];
    const value = contact.value.social_id;
    if (value === '') {
      this.contacts.removeAt(index);
      return;
    }
    const confirmStr = 'Are you sure you want to delete ' + value + ' from contact list';
    if (confirm(confirmStr)) {
      this.contacts.removeAt(index);
    }
  }

  generate(checkbox: HTMLInputElement | boolean): string {
    let status;
    if (typeof (checkbox) === 'boolean') {
      status = checkbox;
    }
    else if (checkbox instanceof HTMLInputElement) {
      status = checkbox.checked;
    }
    else {
      status = false;
    }

    if (status) {
      this.password.markAsTouched({ onlySelf: true });
      const password = this.userService.generateRandomPassword();
      this.password.setValue(password, { onlySelf: true });
      this.passwordReadOnly = true;
      this.adminForm.get('password').clearValidators();
      this.adminForm.get('password').updateValueAndValidity();
      return password;
    }
    else {
      this.passwordReadOnly = false;
      this.password.reset();
      this.password.setValue('');
      this.adminForm.get('password').setValidators([Validators.required, CommonFuntions.ValidatePassword]);
      this.adminForm.get('password').updateValueAndValidity();
      return '';
    }
  }

  /** Sort partnerlist based on the partner_id */
  sortPartnerList() {
    const partnerArray = this.partnerList.sort(function (partner1, partner2) {
      return partner1.partner_id - partner2.partner_id;
    });

    return partnerArray;
  }

  /** Legacy logic: single partner adding in the PCT profile */
  // if (this.userType.toLowerCase() === 'partner' && this.user_partners.controls.length > 0) {
  //   this.partnerList.push(this.user_partners.controls[0].value);
  //   this.partnerList = this.sortPartnerList();
  //   this.user_partners.removeAt(0);
  // }

  addPartner(partner: string) {
  /** Check whether all options is selected from selection list */
    const partnerObj = this.partnerList.find(partnerItem => partnerItem.partner_name === partner);
    this.user_partners.push(new FormControl(partnerObj)); // Add partner to user_partners list

    /** Checking whether the number of partner list and added partner is same.
       * This is the case of association with all the partners.
       */
    const userPartnerLength = this.user_partners.length;
    const associateWillAll = this.allPartnerLength == userPartnerLength ? 1 : 0;
    this.is_all_partners.setValue(associateWillAll);
  }

  clearPartner(_partner: FormControl) {
  // older implementation
  }

  resetPassword(event, _link: HTMLLinkElement) {
    event.preventDefault();
    this.adminForm.removeControl('use_support_password');
    this.adminForm.addControl('use_support_password', new FormControl(false));
    if (this.showPassword && this.operation.toLowerCase() === 'edit') { return; }
    this.showPassword = true;
    if (!this.adminForm.controls.hasOwnProperty('password')) {
      this.adminForm.addControl('password', new FormControl('', { validators: [CommonFuntions.ValidatePassword], updateOn: 'change' }));
      this.adminForm.addControl('auto_generate_pwd', new FormControl(true));
    }
    this.generate(true);
  }

  useSupportPassword(event) {
    event.preventDefault();
    this.adminForm.addControl('use_support_password', new FormControl(true));

    if (this.showPassword && this.operation.toLowerCase() === 'edit') { return; }
    this.showPassword = true;
    if (!this.adminForm.controls.hasOwnProperty('password')) {
      this.adminForm.addControl('password', new FormControl('', { validators: [CommonFuntions.ValidatePassword], updateOn: 'change' }));
      this.adminForm.addControl('auto_generate_pwd', new FormControl(true));
    }
    this.generate(true);
  }

  recoverPassword(event) {
    event.preventDefault();
    this.userService.recoverPassword(this.userId).subscribe(
      (_result) => {
        this.showAlert('Password recovered', function () {
          this.router.navigate(['/profile', this.userId]);
        }.bind(this));
      }, error => {
        this.showAlert(this.userService.handleError(error, this.operation.toLowerCase()));
      }
    );
  }

  addLanguages(language: string) {
    this.languageField.nativeElement.blur();
    const value = this.languagesList.find(
      object => object.language === language
    );

    this.filteredLanguageList = this.languagesList;
    const indexOf = this.languagesList.indexOf(value);
    this.languagesList.splice(indexOf, 1);

    if (value == null) { return; }

    this.user_languages.push(new FormControl(value));
    this.validateLanguage = true;

  }

  clearLanguage(language: FormControl) {
    const index = this.user_languages.controls.indexOf(language);
    this.user_languages.removeAt(index);

    this.languagesList.push(language.value);
    this.languagesList.sort(function (language1, language2) {
      return language1.language_id - language2.language_id;
    });
  }

  searchLanguage(search: HTMLInputElement) {
    const value = search.value;

    if (value === '') {
      this.filteredLanguageList = this.languagesList;
      return;
    }

    const regex = new RegExp(value + '(.)*');
    this.filteredLanguageList = this.languagesList.filter(
      filterValue => filterValue.language.match(regex)
    );

  }

  suggestionListFormatter(data: any): string {
    if (data.hasOwnProperty('language')) {
      return data.language;
    }
    if (data.hasOwnProperty('partner_name')) {
      return data.partner_name;
    }
  }

  valueListFormatter(data: any): string {
    if (data.hasOwnProperty('language')) {
      this.addLanguages(data.language);
    }
    if (data.hasOwnProperty('partner_name')) {
      this.addPartner(data.partner_name);
    }
    return '';
  }

  cancel(event: Event) {
    event.preventDefault();
    this.router.navigate(['/profile', this.userId]);
  }

  checkValidEdit(resultUser) {
    if (this.userTypeId == resultUser.user_type_id) {
      return;
    }

    this.router.navigate(['/']);
  }

  configSettings(result) {
  /** Check settings. */
    if (this.currentUser.user_id === result.user_id) {
      this.isSettings = true;
    }

    /** Check support password */
    if (this.operation.toLowerCase() === 'edit' && this.isSettings && !result.temp_password && this.currentUser.user_type.type.toLowerCase() === 'admin') {
      this.isSupportPassword = true;
    }

    /** Check recover password */
    if (this.operation.toLowerCase() === 'edit' && this.isSettings && result.temp_password && this.currentUser.user_type.type.toLowerCase() === 'admin') {
      this.isRecoverPassword = true;
    }

    /** Check reset password */
    if (this.operation.toLowerCase() === 'edit' && this.isSettings) {
      this.isResetPassword = true;
    }

    delete result.temp_password; /** Need to delete this. */
  }

  public onDateChange(_event, calender, dateInput) {
    const calenderDate = calender.selected;
    if (calenderDate == null) {
      dateInput.value = '';
      return;
    }
    if (calenderDate.getFullYear() < 1900 || calenderDate.getFullYear() > 9999) {
      if (calenderDate.getFullYear() > 9999) { dateInput.value = ''; }
      return;
    }
    const date = moment(calender.selected.toUTCString()).format('YYYY-MM-DD');

    if (moment().isBefore(date)) {
      dateInput.value = '';
      return;
    }

    this.adminForm.get('dob').setValue(date);
    dateInput.value = date;
  }

  preventDefault(event: Event) {
    const userAgent = window.navigator.userAgent;

    const browsers = { chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i };

    for (const key in browsers) {
      if (browsers[key].test(userAgent)) {
        if (key == 'firefox') {
          event.preventDefault();
        }
      }
    }
  }

  onClientSelectChanged(clients: number[]) {
    this.user_partners.controls = [];

    if (clients.length > 0) this.validatePartner = true;

    clients.forEach(clientId => {
      const partnerObj = this.partnerList.find(partnerItem => partnerItem.partner_id === clientId);
      if (partnerObj && partnerObj.partner_name != 'All') {
        this.user_partners.push(new FormControl(partnerObj));
      }
    });
  }

  resetClients(_e) {
    this.dropDrownSelectionChange(this.originalPartners);
  }

  dropDrownSelectionChange(clients) {
    if (this.items.length > 0) {
      this.isPartnerListReady = false;
      let isAllyChecked = 0;
      let isNonAllyChecked = 0;
      // loop ally clients
      if (this.items[0] && this.items[0].children) {
        this.items[0].children.forEach(client => {
        // if client already assigned then mark as checked
          if (clients.includes(client.value)) {
            isAllyChecked++;
            client.checked = true;
          }
          else {
            client.checked = false;
          }
        });

        // check ally parent checkbox if all allyClients checked
        if (isAllyChecked === this.items[0].children.length) {
          this.items[0].checked = true;
        }
      }

      // loop non ally clients
      if (this.items[1] && this.items[1].children) {
        this.items[1].children.forEach(client => {
        // if client already assigned then mark as checked
          if (clients.includes(client.value)) {
            isNonAllyChecked++;
            client.checked = true;
          }
          else {
            client.checked = false;
          }
        });

        // check non ally parent checkbox if all nonAllyClients checked
        if (isNonAllyChecked === this.items[1].children.length) {
          this.items[1].checked = true;
        }
      }

      if (isAllyChecked == 0) {
        this.items[0].checked = false;
      }

      if (isNonAllyChecked == 0) {
        this.items[1].checked = false;
      }

      this.items = [...this.items];
    }
    this.onClientSelectChanged(clients);
  }

}

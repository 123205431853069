<app-dialog></app-dialog>
<div class="popup" hidden="" #doctorProfile>
    <div class="popup__overlay"></div>
    <div class="popup__container popup__container--doc-profile overlay--visible">
        <a class="popup__close popup__close--doc-profile" href="#" (click)="hideDocProfile($event)">
            <span class="icon icon--cross"></span>
        </a>
        <div class="overlay__spinner" *ngIf="!(selectedDoctorInfo && selectedDoctorBoardCertification != null && selectedDoctorCondition != null)">
            <span class="icon icon--loading"></span>
        </div>
        <div class="popup__content popup__content--doc-profile" *ngIf="selectedDoctorInfo && selectedDoctorBoardCertification != null && selectedDoctorCondition != null">
            <div class="row">
                <div class="col col__md-12">
                    <div class="box box--white box--radius">
                        <div class="profiles profiles--intro">
                            <div class="profiles__image">
                                <div class="profile-card__image">
                                    <div class="image-round">
                                        <img [src]="avatarMale" *ngIf="selectedDoctorAvatarImage == null" />
                                        <img [src]="'data:image/jpg;base64,'+selectedDoctorAvatarImage.image" *ngIf="selectedDoctorAvatarImage != null" />
                                    </div>
                                </div>
                                <div class="profiles__name">{{selectedDoctorInfo?.full_name}}</div>
                            </div>
                            <div class="profiles__details">
                                <h1>Doctor - {{ selectedDoctorInfo?.field_of_medicine }}</h1>
                                <div class="profiles__rating rating-row">
                                    <div class="position">{{selectedDoctorInfo?.title?.title}}</div>
                                    <div class="location">{{selectedDoctorInfo?.affiliated_hospital}}</div>
                                </div>
                                <div class="profiles__url doctor__profile">
                                    <a [title]="selectedDoctorInfo?.website" [href]="selectedDoctorInfo?.website" [target]="'_blank'" *ngIf="selectedDoctorInfo?.website">
										External Profile Link</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box box--grey box--radius">
                        <div class="resume resume--centered">
                            <h2 class="resume__title">Qualifications and Experience</h2>
                            <div class="resume__row">
                                <div class="resume-field">Education</div>
                                <div class="resume-details">
                                    <ul class="resume__list">
                                        <li *ngFor="let education of selectedDoctorInfo?.education_list">{{education}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="resume__row">
                                <div class="resume-field">Institution</div>
                                <div class="resume-details">
                                    <ul class="resume__list">
                                        <li *ngFor="let institute of selectedDoctorInfo.institution_list">{{institute}}
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="resume__row">
                                <div class="resume-field">Languages Spoken</div>
                                <div class="resume-details">
                                    <ul class="resume__list">
                                        <li *ngFor="let user_language of selectedDoctorInfo?.user_languages">
                                            {{user_language}}</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="resume__row">
                                <div class="resume-field">Board Certifications</div>
                                <div class="resume-details">
                                    <ul class="resume__list">
                                        <li *ngFor="let cert of selectedDoctorBoardCertification?.doctor_certifications">
                                            {{cert}}</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="resume__row">
                                <div class="resume-field">Conditions</div>
                                <div class="resume-details">
                                    <ul class="resume__list">
                                        <li *ngFor="let doctor_md_type of selectedDoctorCondition?.doctor_md_types">
                                            {{doctor_md_type}}</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="resume__row" *ngIf="selectedDoctorInfo?.procedures">
                                <div class="resume-field">Procedural Expertise</div>
                                <div class="resume-details">
                                    <ul class="resume__list">
                                        <li>{{selectedDoctorInfo?.procedures}} </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="resume__row" *ngIf="selectedDoctorInfo?.bio">
                                <div class="resume-field">Bio</div>
                                <div class="resume-details">
                                    <ul class="resume__list">
                                        <li>{{selectedDoctorInfo?.bio}}</li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div>
    <div [class.overlay--visible]="showSpinner" class="wrapper--dash-content largerView dash-content" *ngIf="formValid">
        <div class="overlay__content">
            <div class="overlay__spinner">
                <span class="icon icon--loading"></span>
            </div>
        </div>
        <div class="box box--white box--radius">
            <div class="cases_doctor_right">
                <div class="col__md-6">
                    <div class="contact-details__details"></div>
                    <!-- <span *ngIf="followUpNumber > 0" class="badge-small badge-yellow">Followup</span> -->
                    <div class="cases__name__physician">{{patientName}}</div>
                    <div class= "cases__age-sex">{{caseDetails?.patient?.user?.dob}}</div>
                    <div class="cases__age-sex">{{patientAge}} y/o, {{patientGender}} </div>
                    <div *ngIf="caseDetails.patient.height"  class="cases__age-sex">Height: {{caseDetails?.patient?.height}} 
                         </div>
                    <div  *ngIf = "caseDetails.patient.weight" class="cases__age-sex">
                            Weight:  {{caseDetails?.patient?.weight}} </div>
                    <div class="cases__details">
                        <div class="row">
                            <div class="col col__md-6">
                                <div class="cases__type"><i class="fa fa-user-md" aria-hidden="true"></i> Diagnosis</div>
                                <div class="cases__note" [innerHtml]="patient_diagnosis"></div>
                            </div>
                    </div>
                </div>
                </div>
             
                <div class="col__md-6 cases_align_right">
                    <div class="contact-details__details">

                        <div class="badge__container {{ stylingCondition() }}" >
                            <div class="badge badge-secondary follow_up__badge" *ngIf="followUpNumber > 0" > +{{ followUpNumber }} Follow Up </div>
                            
                            <div class="badge badge-secondary follow_up__badge badge-orange" *ngIf="isPeerToPeer()" >Peer to Peer</div>
                        </div>

                        <div *ngIf="currentAssignmentObj">
                            <a *ngIf="showAcceptDeclineButton" class="btn btn--green"(click)="acceptCase($event ,'ACCEPTED')"> Accept</a>
                            <a *ngIf="showDeclineButtonDoctor || showAcceptDeclineButton" class ="btn btn--red" (click)="rejectCase($event)">Decline</a>
                        </div>

                        <div class="cases__name__physician">
                            <div *ngIf="showPhysicianSubmitted" class="text-right submitted-btn">
                                <span class="btn btn--green btn--mini">submitted</span>
                            </div> 
                            <div class="text--red--align--left">
                                {{caseDetails?.case_identifier}}
                            </div>
                        </div>
                      </div>
                     
                    <div *ngIf="caseDetails?.case_owner" class="cases_owner">
                        <div class="cases__type"><i class="fa fa-user-md" aria-hidden="true"></i> Care Team</div>
                        <div class="cases__age-sex">{{caseDetails?.case_owner?.full_name}}</div>
                    </div>

                    <div *ngIf="showDueDate" class="cases_owner">
                        <div class="cases__type"><i class="fa fa-calendar" aria-hidden="true"></i> Due Date</div>
                        <div class="cases__age-sex">{{caseDetails?.physician_due_date | date: 'short'}}</div>
                    </div>
                </div>
            </div>
            <app-reject-case-template [name]="caseDetails?.case_identifier" [hideTemplate]="hideDeleteUserBox" (toggleTemplate)="toggleDeleteTemplate($event)" [handleValueSubmit]="handleValueSubmit"></app-reject-case-template>
         </div>
        <div class="accordion">
            <section class="accordion__row is-expanded" id="review_patient_questions">
                <a href="#" class="direction remove__underlined" (click)="toggleThis($event, 'review_patient_questions')">
                    <div class="accordion__title-row">
                        <div class="accordion__title">Review Patient Question(s)</div>
                        <div class="accordion__direction">
                            <a href="#" class="direction remove__underlined" (click)="noAction($event)">-</a>
                        </div>
                    </div>
                </a>
                
                <div class="accordion__content">
                    <form [formGroup]="doctorOnePagerForm">
                        <div class="qa">
                            <div formArrayName="case_questions">
                                <div class="qa__row qa__row--border-b" *ngFor="let case_question of case_questions.controls; let i=index" [formGroupName]="i">
                                    <div class="qa__question">
                                        <div class="row">
                                            <div class="col col__md-12">
                                                <div class="qa__question">
                                                    {{i + 1}} - {{case_question.get('question_text').value}}
                                                    <span *ngIf="followUpNumber > 0 && followUpNumber === case_question?.get('follow_up_id').value" class="badge-small badge-yellow">Followup</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </form>

                </div>
            </section>

            <section *ngIf="showIntakeForm" class="accordion__row is-expanded" id="missing_information">
                <a href="#" class="direction remove__underlined" (click)="toggleThis($event, 'missing_information')">
                    <div class="accordion__title-row">
                        <div class="accordion__title">Missing Information?</div>
                        <div class="accordion__direction">
                            <a href="#" class="direction remove__underlined" (click)="noAction($event)">-</a>
                        </div>
                    </div>
                </a>
                <div class="accordion__content">
                    <div class="qa">
                        <div class="qa__row">
                            <div class="qa__response">
                                <div class="row">
                                    <div class="qa__label-r form__label">Please let us know</div>
                                    <div class="form__field">
                                        <!-- <textarea [class.validation--is-invalid]="!isMissingInfoValid" style="min-height:120px" [attr.disabled]="disableInputs ? '': null" placeholder="Please enter missing information." [value]="missing_information" (keyup)="validateField('missingInformation',missingInfo)"
                                            #missingInfo></textarea> -->
                                            <ckeditor [class.validation--is-invalid]="!isMissingInfoValid" style="min-height:120px" [attr.disabled]="disableInputs ? '': null" placeholder="Please enter missing information." [value]="missing_information" (keyup)="validateField('missingInformation',missingInfo)"
                                            #missingInfo [config]="{ toolbar: [
												{ name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', 'NumberedList', 'BulletedList','Indent','Outdent'] }
												]}"></ckeditor>
                                        <div class="message message--is-invalid" *ngIf="!isMissingInfoValid">
                                            Field must have value and It should not exceed more than 50000 characters.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="btns__row text-right" *ngIf="!disableInputs">
                        <a href="#" class="btn btn--blue" (click)="confirmSaveMissingInformation($event, missingInfo.value)" *ngIf="state_two">Re-Request</a>
                        <a href="#" class="btn btn--blue" (click)="confirmSaveMissingInformation($event, missingInfo.value)" *ngIf="!state_two">Request</a>
                    </div>
                </div>
            </section>


            <section class="accordion__row is-expanded" id="attachment_case">
                <a href="#" class="direction remove__underlined" (click)="toggleThis($event, 'attachment_case')">
                    <div class="accordion__title-row">
                        <div class="accordion__title">Review Medical Records & Images</div>
                        <div class="accordion__direction">
                            <a href="#" class="direction remove__underlined" (click)="noAction($event)">-</a>
                        </div>
                    </div>
                </a>
                <div class="accordion__content">
                    <div>
                        <div class="doc-list" *ngIf="case_documents.length >0; else no_attachments">
                            <ul class="doc-list__list doc-list__list--grid">
                                <li *ngFor="let attachmentInstance of case_documents">
                                    <a href="#" (click)="downloadDoc($event,attachmentInstance.id,attachmentInstance.document_name)" class="file-name" *ngIf="attachmentInstance.document_type != null">
                                        <span class="icon icon--uploaded-file"></span>
                                        <span>{{attachmentInstance.document_type}}</span>
                                    </a>
                                    <a [title]="attachmentInstance.location" [href]="attachmentInstance.location" [target]="'_blank'" class="file-name" *ngIf="attachmentInstance.document_type == null">
                                        <span class="icon icon--uploaded-link"></span>
                                        <span>{{attachmentInstance.document_name}}</span>
                                    </a>
                                    <div class="text text--small text-center text--dark-grey" *ngIf="role != 'doctor'" >
                                        {{attachmentInstance.updated_at | amLocal | amDateFormat: 'MM/DD/YYYY HH:mm'}}</div>
                                    <div *ngIf = "attachmentInstance.user && attachmentInstance.user.full_name && (role != 'doctor')" class="text text--small text-center text--dark-grey">
                                        {{(attachmentInstance.user.full_name)}}</div>
                                </li>

                            </ul>

                        </div>
                        <ng-template #no_attachments>There is no attachment available currently.</ng-template>

                    </div>



                </div>
            </section>

            <section *ngIf="showIntakeForm || isCaseComplete" class="accordion__row is-expanded" id="video_consultation_toggleThis">
                <a href="#" class="direction remove__underlined" (click)="toggleThis($event, 'video_consultation_toggleThis')">
                    <div class="accordion__title-row">
                        <div class="accordion__title">Consultation Summary</div>
                        <div class="accordion__direction">
                            <a href="#" class="direction remove__underlined" (click)="noAction($event)">-</a>
                        </div>
                    </div>
                </a>

                <div class="accordion__content">
                    <form [formGroup]="doctorOnePagerForm" novalidate (ngSubmit)="saveOnePager($event,doctorOnePagerForm, 'save')">
                        <div class="qa">
                            <div class="qa__row qa__row--border-b">
                                <div class="qa__row">
                                    <div class="qa__response">
                                        <div class="row">
                                            <div class="qa__label-r form__label">{{ isSummaryEditable ? 'Case Summary:' : 'Case Summary by '+caseDetails?.case_md_management?.user?.full_name+', '+caseDetails?.case_md_management?.user?.doctorTitle+':'}}</div>
                                            <div class="form__field">
                                                <!-- <textarea style="min-height:243px" [class.validation--is-invalid]="!writtenSummary.valid" [attr.disabled]="disableInputs || !isSummaryEditable ? '': null" placeholder="Please enter your response." formControlName="written_summary" #summary></textarea> -->
                                                <ckeditor [config]="config" [readonly]="(this.followUpNumber > 0 && this.isEdit && this.showPhysicianSubmitted ) || ( isEdit &&(this.followUpNumber > 0) && !this.showPhysicianSubmitted) " style="min-height:243px" [class.validation--is-invalid]="!writtenSummary.valid" [attr.disabled]="disableInputs || !isSummaryEditable ? '': null" placeholder="Please enter your response." formControlName="written_summary"  #summary
												></ckeditor>
												<div *ngIf="writtenSummary.touched && writtenSummary.invalid" class="message message--is-invalid">
                                                    <div *ngIf="writtenSummary.errors.maxlength">Field must not exceed {{writtenSummary.errors.maxlength.requiredLength}} characters.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="qa mt-18" *ngIf="caseDetails.case_type === 'ASYNCHRONOUS VIDEO' || caseDetails.case_type === 'CONNECT ASYNCHRONOUS'">
                            <div class="qa__row qa__row--border-b">
                                <div class="qa__row">
                                    <div class="qa__response">
                                        <div class="row">
                                            <div class="qa__label-r form__label"></div>
                                            <div class="form__field">
                                                <p id="statusSummary" class="video-status">Click on record button to start recording.</p>
                                                <video id="videoLiveSummary" *ngIf="!caseDetails?.video_consultation || !caseDetails?.editVideoConsultation" class="videoEle"></video>
                                                <video id="videoRecordedSummary" class="videoEle hide"></video>
                                                <video *ngIf="caseDetails.video_consultation && caseDetails.editVideoConsultation" id="videoDbSummary" class="videoEle" [src]="caseDetails?.video_consultation" controls width="468" height="351"></video>
                                                <br />

                                                <div *ngIf="!hideRecordBtns">
                                                    <button id="recordSummary" type="button" class="btn btn-small btn--blue margin--top-s" (click)="start_recording('Summary')"><i class="fa fa-video-camera" aria-hidden="true"></i> {{caseDetails?.video_consultation?"Re-record":"Record"}}</button>
                                                    <button id="stopSummary" type="button" class="btn btn--blue margin--top-s d-none-customized" (click)="stop_recording()"><i class="fa fa-stop" aria-hidden="true"></i> Stop</button>
                                                    <button id="pauseSummary" type="button" class="btn btn--blue margin--top-s d-none-customized" (click)="pause()"><i class="fa fa-pause" aria-hidden="true"></i> Pause</button>
                                                    <button id="resumeSummary" type="button" class="btn btn--blue margin--top-s d-none-customized" (click)="resume()"><i class="fa fa-forward" aria-hidden="true"></i> Resume</button>
                                                    <button id="startOverSummary" type="button" class="btn btn--blue margin--top-s d-none-customized" (click)="start_over()"><i class="fa fa-refresh"></i> Start Over</button>
                                                    <button id="uploadSummary" type="button" class="btn btn--blue margin--top-s d-none-customized" (click)="uploadToServer()"><i class="fa fa-upload"></i> Upload</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="qa">
                            <div formArrayName="case_questions">
                                <div class="row">
                                    <div class="qa__label-r form__label title">Patient Questions:</div>
                                </div>
                                <div class="qa__row qa__row--border-b" *ngFor="let case_question of case_questions.controls; let i=index" [formGroupName]="i">
                                    <div class="qa__question">
                                        <div class="row">
                                            <div class="qa__label-r form__label">{{ i + 1 }} - {{case_question.get('question_text').value}}
                                                <br/>
									            <span *ngIf="followUpNumber > 0 && followUpNumber == case_question.get('follow_up_id').value" class="badge-small badge-yellow">Followup</span>
                                            </div>
                                            <ng-container formArrayName="answer">
                                                <div class="form__field" *ngFor="let doctorAnswer of case_question.get('answer')['controls']; let answerIndex=index" [formGroupName]="answerIndex">
                                                    <div class="answer-by" *ngIf="doctorAnswer.get('answer_by_user_id').value !== userId" (click)="showHideAnswer(doctorAnswer)">
                                                        Answered by {{doctorAnswer.get('user').get('full_name').value+', '+ doctorAnswer.get('user').get('doctorTitle').value}}
                                                    </div>
                                                    <!-- <textarea style="min-height:243px; margin-top: 15px;" placeholder="Please enter your response." [class.validation--is-invalid]="!doctorAnswer.get('answer').valid" [attr.disabled]="disableInputs || !case_question.get('editable').value || doctorAnswer.get('answer_by_user_id').value !== userId ? '': null"
                                                        formControlName="answer"></textarea> -->
                                                        <ckeditor style="min-height:243px; margin-top: 15px;" placeholder="Please enter your response." [class.validation--is-invalid]="!doctorAnswer.get('answer').valid" [attr.disabled]="disableInputs || !case_question.get('editable').value || doctorAnswer.get('answer_by_user_id').value !== userId ? '': null"
                                                        [readonly] = "followUpNumber == 0 && !isEdit ? false: (followUpNumber > 0 && followUpNumber == case_question.get('follow_up_id').value && !isEdit ? false: true)" formControlName="answer" [config]="{ toolbar: [
                                                             { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', 'NumberedList', 'BulletedList','Indent','Outdent'] }
                                                             ]}"></ckeditor>
 
                                                    <div *ngIf="doctorAnswer.get('answer').touched && doctorAnswer.get('answer').invalid" class="message message--is-invalid">
                                                        <div *ngIf="doctorAnswer.get('answer').errors.maxlength">Field must not exceed {{doctorAnswer.get('answer').errors.maxlength.requiredLength}} characters.
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <div class="qa mt-18" *ngIf="caseDetails.case_type === 'CONNECT ASYNCHRONOUS'">
                                                <div class="qa__row">
                                                    <div class="qa__row">
                                                        <div class="qa__response">
                                                            <div class="row">
                                                                <div class="qa__label-r form__label"></div>
                                                                <div class="form__field">
                                                                    <p id="{{ 'status' + i }}" class="video-status">Click on record button to start recording.</p>
                                                                    <video id="{{ 'videoLive' + i }}" *ngIf="!caseDetails?.case_questions[i].video_consultation || !caseDetails?.case_questions[i].editVideoConsultation" class="videoEle"></video>
                                                                    <video id="{{ 'videoRecorded' + i }}" class="videoEle hide"></video>
                                                                    <video id="videoDbSummary" class="videoEle" *ngIf="caseDetails?.case_questions[i].video_consultation && caseDetails?.case_questions[i].editVideoConsultation" [src]="caseDetails?.case_questions[i].video_consultation" controls width="468" height="351"></video>
                                                                    <br />
                                                                    <div *ngIf="!hideRecordBtns">
                                                                        <button id= "{{ 'record' + i }}" type="button" class="btn btn--blue margin--top-s" (click)="start_recording(i)"><i class="fa fa-video-camera" aria-hidden="true"></i> {{caseDetails?.case_questions[i].video_consultation?'Re-record':'Record'}}</button>
                                                                        <button id= "{{ 'stop' + i }}" type="button" class="btn btn--blue margin--top-s d-none-customized" (click)="stop_recording()"><i class="fa fa-stop" aria-hidden="true"></i> Stop</button>
                                                                        <button id= "{{ 'pause' + i }}" type="button" class="btn btn--blue margin--top-s d-none-customized" (click)="pause()"><i class="fa fa-pause" aria-hidden="true"></i> Pause</button>
                                                                        <button id= "{{ 'resume' + i }}" type="button" class="btn btn--blue margin--top-s d-none-customized" (click)="resume()"><i class="fa fa-forward"></i> Resume</button>
                                                                        <button id= "{{ 'startOver' + i }}" type="button" class="btn btn--blue margin--top-s d-none-customized" (click)="start_over()"><i class="fa fa-refresh"></i> Start Over</button>
                                                                        <button id= "{{ 'upload' + i }}" type="button" class="btn btn--blue margin--top-s d-none-customized" (click)="uploadToServer()"><i class="fa fa-upload"></i> Upload</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="qa__row qa__row--border-b">
                            <div formArrayName="case_medication">
                                <div class="row">
                                    <div class="qa__label-r form__label title">Please include specific medications mentioned above with dosage and frequency</div>
                                </div>

                                <section class="new_accordion__row is-open" [id]="key" *ngFor="let key of keys(submittedMedication)">
                                    <a href="#" class="direction remove__underlined" (click)="toggleOpenClose($event, key)">
                                        <div class="accordion__title-row">
                                            <div class="accordion__title text-transform-none pr-2">Medication(s) mentioned by {{ getUsername(key) }}</div>
                                            <div class="accordion__direction">
                                                <a href="#" class="direction remove__underlined" (click)="noAction($event)">-</a>
                                            </div>
                                        </div>
                                    </a>
                                    <div class="new_accordion__newcontent">
                                        <ul *ngFor="let medicationItem of submittedMedication[key]">
                                            <li>{{medicationItem.medication}}</li>
                                        </ul>
                                    </div>
                                </section>

                                <div class="form__row" *ngFor="let medicationInstance of case_medication.controls; let i=index" [formGroupName]="i">
                                    <div class="qa__label-r form__label">Medication {{i+1}} 
                                        <span *ngIf="followUpNumber > 0 && followUpNumber == medicationInstance.get('follow_up_id').value" class="badge-small badge-yellow" style="margin-left: 5px;">Followup</span>
                                    </div>
                                    <div class="form__field col col__md-10 gutter--right-x">
                                        <input [attr.disabled]="(medicationInstance && showPhysicianSubmitted && isEdit|| disableInputs || medicationInstance.get('follow_up_id').value != followUpNumber) ? '': null" type="text" id="medication" formControlName="medication" [appFormatOnly]='"trimonly"' />
                                        <div *ngIf="medicationInstance.get('medication').touched && medicationInstance.get('medication').invalid" class="message message--is-invalid">
                                            <div *ngIf="medicationInstance.get('medication').errors.maxlength">Field must not exceed {{medicationInstance.get('medication').errors.maxlength.requiredLength}} characters.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col__md-2" *ngIf="!disableInputs && !disableMedicationButton">
                                        <button *ngIf="medicationInstance.get('follow_up_id').value == followUpNumber" type="button" class="btn btn--red btn--small margin--top-s shift__remove" (click)="removeMedicationAt(i)">X</button>
                                    </div>

                                </div>
                                <div class="btns-row" *ngIf="!disableInputs && !disableMedicationButton">
                                    <a href="#" class="btn btn--blue" (click)="addAnotherMedication($event)">Add Another
										Medication</a>
                                </div>
                            </div>
                        </div>
                        <div class="qa__row qa__row--border-b">
                            <div class="form__row">
                                <div class="qa__label-r form__label">{{ isSummaryEditable ? 'Other Comments:' : 'Other Comments by '+caseDetails?.case_md_management?.user?.full_name+', '+caseDetails?.case_md_management?.user?.doctorTitle+':'}}</div>
                                <div class="form__field">
                                    <!-- <textarea style="min-height:243px" [attr.disabled]="disableInputs || !isSummaryEditable ? '': null" placeholder="Other comments relevant to the case (optional)" [class.validation--is-invalid]="!comment_summary.valid" formControlName="comment_summary"
                                        (blur)="validateField('otherComment',commentSummary)" #commentSummary></textarea> -->

									<ckeditor [config]="config"  style="min-height:243px" [attr.disabled]="disableInputs || !isSummaryEditable ? '': null" placeholder="Other comments relevant to the case (optional)" [class.validation--is-invalid]="!comment_summary.valid" formControlName="comment_summary"
									(blur)="validateField('otherComment',commentSummary)" #commentSummary
									></ckeditor>
                                    <div class="message message--is-invalid" *ngIf="!isOtherCommentValid">
                                        Field must not exceed more than 50000 characters.
                                    </div>
                                </div>
                            </div>
                        </div>



                        <ng-container class="container-peer-to-evicore-cases mt-5 mb-5" *ngIf="isPeerToPeer()">
                            <h3 class="mt-5 mb-5">Clinical Consultation </h3>
                            <div class="qa__row qa__row--border-b">
                                <div class="row">
                                    <div class="col__md-6">
                            
                                      <div class="qa__label-r form__label"> Clinical Consult Diagnosis *</div>
                                        <div class="form__field">
                                            <select formControlName="clinical_consult_diagnosis" [class.is-invalid]="doctorOnePagerForm.get('clinical_consult_diagnosis').invalid && (doctorOnePagerForm.get('clinical_consult_diagnosis').dirty || doctorOnePagerForm.get('clinical_consult_diagnosis').touched)"
                                                (change)="changeClinicalConsultDiagnosis($event.target.value)" name="clinical_consult_diagnosis"
                                                id="clinical_consult_diagnosis">
                                                <option [ngValue]="null" selected>-- Select --</option>
                                                <option > Agree with diagnosis</option>
                                                <option>Need additional testing</option>
                                                <option>Disagree with diagnosis</option>
                                                <option>Other</option>
                                            </select>
                                            <div *ngIf="doctorOnePagerForm.get('clinical_consult_diagnosis').invalid && (doctorOnePagerForm.get('clinical_consult_diagnosis').dirty || doctorOnePagerForm.get('clinical_consult_diagnosis').touched)" class="message message--is-invalid">
                                            <div *ngIf="doctorOnePagerForm.get('clinical_consult_diagnosis').hasError('required')" class="message message--is-invalid">
                                                Please select the Clinical Consult Diagnosis
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="col__md-6">
                                        <div class="form__row">
                                            <div class="qa__label-r form__label">Clinical Consult Recommend Treatment Plan *</div>
                                            <div class="form__field">
                                                <select formControlName="clinical_consult_recommend_treatment_plan"
                                                    [class.is-invalid]="doctorOnePagerForm.get('clinical_consult_recommend_treatment_plan').invalid && (doctorOnePagerForm.get('clinical_consult_recommend_treatment_plan').dirty || doctorOnePagerForm.get('clinical_consult_recommend_treatment_plan').touched)"
                                                    (change)="changeClinicalConsultRecommendTreatmentPlan($event.target.value)"
                                                    name="clinical_consult_recommend_treatment_plan" id="clinical_consult_recommend_treatment_plan">
                                                    <option [ngValue]="null" selected>-- Select --</option>
                                                    <option >Agree with proposed plan</option>
                                                    <option>Need additional testing to evaluate treatment plan</option>
                                                    <option>Recommend clinical trial</option>
                                                    <option>Other</option>
                                                </select>
                                                <div *ngIf="doctorOnePagerForm.get('clinical_consult_recommend_treatment_plan').invalid && (doctorOnePagerForm.get('clinical_consult_recommend_treatment_plan').dirty || doctorOnePagerForm.get('clinical_consult_recommend_treatment_plan').touched)" >
                                                    <div *ngIf="doctorOnePagerForm.get('clinical_consult_recommend_treatment_plan').hasError('required')" class="message message--is-invalid">
                                                        Please select the Clinical Consult Diagnosis
                                                    </div>
                                                </div>
                                           
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                                <div class="row">
                            
                                    <div class="col__md-6" *ngIf="isClinicalConsultDiagnosisValid">
                                       
                                            <div class="qa__label-r form__label">Clinical Consult Diagnosis Notes *</div>
                                            <div class="form__field">
                        
                                                <textarea style="min-height:70px;resize: none;" class="form-control"
                                                    [class.is-invalid]="doctorOnePagerForm.get('clinical_consult_diagnosis_notes').invalid && (doctorOnePagerForm.get('clinical_consult_diagnosis_notes').dirty || doctorOnePagerForm.get('clinical_consult_diagnosis_notes').touched)"
                                                    formControlName="clinical_consult_diagnosis_notes"
                                                    (blur)="validateField('clinical_consult_diagnosis_notes',clinical_consult_diagnosis_notes)"
                                                    name="clinical_consult_diagnosis_notes" 
                                                    >
                                                </textarea>
                                                <div *ngIf="doctorOnePagerForm.get('clinical_consult_diagnosis_notes').touched || doctorOnePagerForm.get('clinical_consult_diagnosis_notes').invalid" 
                                                    class="message message--is-invalid">
                                        
                                                    <div *ngIf="doctorOnePagerForm.get('clinical_consult_diagnosis_notes').hasError('required')">
                                                        Field is required
                                                    </div>
                                                    <div *ngIf="doctorOnePagerForm.get('clinical_consult_diagnosis_notes').hasError('maxlength')">
                                                        Field should be contain less than 100 characters
                                                    </div>
                                                </div>
                                            </div>
                                       
                                    </div>
                                    <div class="col__md-6"
                                        [ngClass]="isClinicalConsultDiagnosisValid == false &&  isClinicalConsultRecommendTreatmentPlanValid ==true ||  doctorOnePagerForm.get('clinical_consult_diagnosis').value != 'Other' ? 'offset-6' : ''"
                                        class="col__md-6" *ngIf="isClinicalConsultRecommendTreatmentPlanValid">
                                        
                                        <div class="qa__label-r form__label">Clinical Consult Recommended Treatment Plan Notes *</div>
                                        <div class="form__field">
                                        
                                            <textarea style="min-height:70px;resize: none;" class="form-control"
                                                formControlName="clinical_consult_recommend_treatment_plan_notes"
                                                [class.is-invalid]="doctorOnePagerForm.get('clinical_consult_recommend_treatment_plan_notes').invalid && (doctorOnePagerForm.get('clinical_consult_recommend_treatment_plan_notes').dirty || doctorOnePagerForm.get('clinical_consult_recommend_treatment_plan_notes').touched)"
                                                (blur)="validateField('clinical_consult_recommend_treatment_plan_notes',clinical_consult_recommend_treatment_plan_notes)"
                                                name="clinical_consult_recommend_treatment_plan_notes">
                                        
                                            </textarea>
                                         
                                            <div *ngIf="doctorOnePagerForm.get('clinical_consult_recommend_treatment_plan_notes').touched || doctorOnePagerForm.get('clinical_consult_recommend_treatment_plan_notes').invalid"
                                                class="message message--is-invalid">
                                            
                                                <div *ngIf="doctorOnePagerForm.get('clinical_consult_recommend_treatment_plan_notes').hasError('required')">
                                                    Field is required
                                                </div>
                                                <div *ngIf="doctorOnePagerForm.get('clinical_consult_recommend_treatment_plan_notes').hasError('maxlength')">
                                                    Field should be contain less than 100 characters
                                                </div>
                                            </div>
                                    
                                        </div>
                                    </div>                            
         
                                </div>
                            </div>

                        </ng-container>
             
                    <div *ngIf="showIntakeForm">
                        <div class="row" >
                            <div class="col-md-3" >
                                <div *ngIf="isEdit">
                                <a  class="btn btn--blue "*ngIf="showPhysicianSubmitted && !disableInputs" [ngClass]="isEdit ? 'btn--blue' : 'btn--red' " [innerText]="isEdit ? 'Enable Edit' : 'Disabled Edit'"  [swal]="enableEdit"></a>
                                <swal #enableEdit cancelButtonColor="#db161e" closeOnConfirm="true" closeOnCancel="true" (confirm)="isFormEditable()" reverseButtons="true" showCancelButton="true" confirmButtonColor="green" confirmButtonColor="green"  [swalOptions]="{showClass: { backdrop: '',popup: ''}}"  backdrop="#eef3f6" showCloseButton="false" icon="warning" iconColor="orange">
                                    <swal-text *swalPortal>
                                       <b>Are you sure you want to edit this form? </b>  <br>

                                       * Please note that you need to submit the form after editing
                                    </swal-text>
                                    </swal>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="btns__row text-right" *ngIf="!isEdit || !disableInputs  && (!currentAssignmentObj || (currentAssignmentObj && currentAssignmentObj?.status === CaseAssignmentStatus.ACCEPTED))">
                                    <a href="#" class="btn btn--blue" (click)="saveOnePager($event, doctorOnePagerForm)" *ngIf="!isEdit">Save</a>
                                    <!-- <a href="#" class="btn btn--blue" *ngIf="!state_one" (click)="saveOnePager($event, doctorOnePagerForm)">Save</a> -->
                                    <!-- <a href="#" class="btn btn--blue" *ngIf="state_one" (click)="saveOnePager($event, doctorOnePagerForm)">Update</a> -->
                                    <a href="#" class="btn btn--blue" *ngIf="!showPhysicianSubmitted" (click)="submitOnePager($event, doctorOnePagerForm)">
                                        Submit
                                    </a>
                                    <a href="#" class="btn btn--blue" *ngIf="showPhysicianSubmitted && !isEdit" (click)="submitOnePager($event, doctorOnePagerForm)">Re-Submit</a>
                                </div>
                            </div>
                        </div>
   

                    </div>
                    </form>

                </div>
            </section>

            <section class="accordion__row is-expanded" id="peer_to_peer_information" *ngIf="isPeerToPeer()" >
                <a href="#" class="direction remove__underlined" (click)="toggleThis($event, 'peer_to_peer_information')">
                    <div class="accordion__title-row">
                        <div class="accordion__title">Peer to Peer Information</div>
                        <div class="accordion__direction">
                            <a href="#" class="direction remove__underlined" (click)="noAction($event)">-</a>
                        </div>
                    </div>
                </a>
                <div class="accordion__content">
                    <div>
                        <span class="qa__label-r">
                            Code:
                        </span><span>{{ peerToPeerDetails?.code || 'N/A' }} </span>
                    </div>

                    <br />

                    <div>
                        <span class="qa__label-r" >
                            Description:
                        </span>
                        <span> {{ peerToPeerDetails?.description || 'N/A' }} </span>
                    </div>
                    
                    <br />

                    <div>
                        <span class="qa__label-r" >
                            Case Status:
                        </span>
                        <span> {{ peerToPeerDetails?.case_status || 'N/A' }} </span>
                    </div>

                    <br />

                    <div>
                        <span class="qa__label-r" >
                            Case Type:
                        </span>
                        <span> {{ peerToPeerDetails?.case_type || 'N/A' }} </span>
                    </div>
                    <br />

                    <div>
                        <span class="qa__label-r" >
                            Treatment Start Date:
                        </span>
                        <span> {{ peerToPeerDetails?.treatment_start_date || 'N/A' }} </span>
                    </div>
                    <br />

                    <div>
                        <span class="qa__label-r" >
                            Treatment Regimen:
                        </span>
                        <span> {{ peerToPeerDetails?.treatment_regimen || 'N/A' }} </span>
                    </div>
                    <br />

                </div>
            </section>

        </div>
    </div>
</div>

<div *ngIf="showMessage && role !== 'interpreter'" class="message-module">
    <app-casehistory *ngIf="role !== 'interpreter'" [caseDetail]="caseDetails"></app-casehistory>
</div>
<app-dialog></app-dialog>

<div class="popup" hidden #VIDEODIALOG>
    <div class="container-fluid">
        <div class="row">
            <div class="popup__overlay"></div>
            <div class="popup__video overlay__wrapper"  [class.overlay--visible]="uploading">
                <div class="overlay__content" [class.overlay--visible]="uploading">
                    <div class="overlay__spinner">
                        <span class="icon icon--loading"></span>
                    </div>
                </div>
                <div class="popup__header">
                    <h1>Video Consultation</h1>
                </div>
                <div class="popup__content">
                    <div class="row">
                        <div class="form__field">
                            <video id="videoPopup" class="videoEle"></video>
                        </div>
                    </div>
                </div>
                <div class="popup__footer">
                    <button type="button" class="btn btn--blue margin--top-s" (click)="start_over()"><i class="fa fa-refresh"></i> Start Over</button>
                    <button type="button" class="btn btn--blue margin--top-s" (click)="uploadToServer()"><i class="fa fa-upload"></i> Upload</button>
                </div>
            </div>
        </div>
    </div>
</div>
<app-dialog></app-dialog>
<div class="header">
	<div class="header__page-name">
		<h1 class="heading heading--main">Profile</h1>
	</div>
</div>

<div class="wrapper--dash-content dash-content">
	<div class="">
		<div class="col col__md-5 single-centered-col">
			<div class="profile-card">

				<div class="profile-card__image">
					<div class="image-round">
						<app-loader *ngIf="loading"></app-loader>
						<img [src]="profilePicPath" *ngIf="!loading && !avatarimage" />
						<img [src]="'data:image/jpg;base64,'+ avatarimage"
							*ngIf="!loading && avatarimage" />
					</div>
				</div>

				<div class="profile-card__name" style="margin-top: 10px;">{{user?.full_name}}</div>
				<div class="profile-card__email">{{user?.email}}</div>


				<div *ngIf="userType.toLowerCase() == 'admin' || userType.toLowerCase() == 'doctor'  || userType.toLowerCase() == 'patient'"
					class="profile-card__row">
					<div class="hr hr--space-down-x hr--space-up-x"></div>
					<div class="profile-card__label">User Type</div>
					<div class="profile-card__details">{{userType}}</div>
				</div>
				<div *ngIf="user?.allyUserRef && showAllyRef" class="profile-card__row">
					<div class="hr hr--space-down-x hr--space-up-x"></div>
					<div class="profile-card__label">Ally User Reference</div>
					<div class="profile-card__details reference-brk" (click)="copyText(user?.allyUserRef)"> <a
							class="badge badge-secondary copytip">{{user?.allyUserRef == null ? 'N/A' :
							(user?.allyUserRef.length>15)? (user?.allyUserRef | slice:0:15)+'. ..':(user?.allyUserRef) }}
							<i class="fa fa-clipboard " aria-hidden="true">
								<span class="copytiptext">{{CopyText}}</span> </i></a>
					</div>
				</div>
				<div *ngIf="currentUser.user_id != userId" class="profile-card__row">
					<div class="profile-card__label">Status</div>
					<div class="profile-card__details">
						<span *ngIf="!user?.is_deleted" class="btn btn--green btn--mini">Active</span>
						<span *ngIf="user?.is_deleted" class="btn btn--red btn--mini">Inactive</span>
					</div>
				</div>
				<div class="hr hr--space-down-x hr--space-up-x" *ngIf="userType.toLowerCase() !== 'partner'"></div>

				<div *ngIf="currentUser.user_id == userId && user?.dob != null" class="profile-card__row">
					<div class="profile-card__label">DOB</div>
					<div class="profile-card__details">{{user?.dob | date: 'MMM dd'}}</div>
				</div>

				<div *ngIf="currentUser.user_id == userId && user?.time_zone != null" class="profile-card__row">
					<div class="profile-card__label">Timezone</div>
					<div class="profile-card__details">{{user?.time_zone}}</div>
				</div>

				<div
					*ngIf="(userType.toLowerCase() === 'careteam' || userType.toLowerCase() === 'patient' || userType.toLowerCase() === 'partner') && currentUser.user_id != userId">
					<div class="">
						<!-- <div class="profile-card__label" *ngIf="userType.toLowerCase() === 'careteam' || userType.toLowerCase() === 'partner' || userType.toLowerCase() === 'patient'">Client</div>
						<div class="profile-card__details">
							<ul class="profile-card__details-list" *ngIf="user?.user_partners && user?.user_partners.length > 0">
								<li *ngFor="let partner of user.user_partners">{{partner?.partner?.partner_name}}</li>
							</ul>
							<div *ngIf="!user?.user_partners || user?.user_partners.length == 0">No Client</div>
						</div> -->
						<div class="client-panel-group">
							<div class="profile-card__label"
								*ngIf="userType.toLowerCase() === 'careteam' || userType.toLowerCase() === 'partner' || userType.toLowerCase() === 'patient'">
								Clients</div>
							<div class="client-list-group">
								<!---ally clients---->
								<div *ngIf="allAllyClients.length > 0">
									<div class="client-list-item" (click)="showAllyClients = !showAllyClients">Ally
										Clients
										({{allAllyClients.length}})
										<a href="javascript:void(0)" class="go-back"><span
												class="icon icon--time-nav client-list-item-toggle"
												[ngClass]="{clientListActiveItem: showAllyClients===true}"></span></a>
									</div>
									<ul [hidden]="!showAllyClients"
										class="profile-card__details-list client-list-item-ul">
										<li *ngFor="let partner of allAllyClients">{{partner}}
										</li>
									</ul>
								</div>
								<!---non-ally clients---->
								<div *ngIf="allNonAllyClients.length > 0">
									<div class="client-list-item" (click)="showNonAllyClients = !showNonAllyClients">
										Non-Ally Clients ({{allNonAllyClients.length}})
										<a href="javascript:void(0)" class="go-back"><span
												class="icon icon--time-nav client-list-item-toggle"
												[ngClass]="{clientListActiveItem: showNonAllyClients===true}"></span></a>
									</div>
									<ul [hidden]="!showNonAllyClients"
										class="profile-card__details-list client-list-item-ul">
										<li *ngFor="let partner of allNonAllyClients">{{partner}}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div class="profile-card__row" *ngIf="userType.toLowerCase() === 'patient'">
						<div class="profile-card__label">Company</div>
						<div class="profile-card__details">
							{{user?.patient_detail?.associated_company }}
						</div>
					</div>

					<div class="hr hr--space-down-x hr--space-up-x" *ngIf="showButtons"></div>

				</div>

				<div *ngIf="currentUser?.user_id != userId && userType?.toLowerCase() !== 'partner'">
					<div class="profile-card__row">
						<div class="profile-card__label">{{userType.toLowerCase() === 'patient'? 'Cases' : 'Total Cases'}} ({{caseDetail?.totalCases || 0}})</div>
						<div *ngIf="caseDetail?.totalCases > 0" class="profile-card__details" style="margin-right: 10px;">
							<a [routerLink]="['/user', userId, 'cases']" [queryParams]="{status: 'all'}"><span class="btn btn--green btn--mini">Details</span></a>
						</div>
					</div>
					<div class="hr hr--space-down-x hr--space-up-x"></div>
				</div>

				<div class="profile-card__row"
					*ngIf="userType.toLowerCase() === 'translator' || userType.toLowerCase() === 'interpreter'">
					<div class="profile-card__label">Languages Spoken</div>
					<div class="profile-card__details">
						<ul class="profile-card__details-list"
							*ngIf="user?.user_languages && user?.user_languages.length > 0">
							<li *ngFor="let user_language of user?.user_languages">{{user_language.language.language}}
							</li>
						</ul>
						<div *ngIf="!user?.user_languages || user?.user_languages.length == 0">No Language</div>
					</div>
					<!-- <div class="hr hr--space-down-x hr--space-up-x"></div> -->
				</div>

				<div class="profile-card__row">
					<div class="profile-card__label">Phone Number</div>
					<div class="profile-card__details">
						{{user?.phone_number != null ? user?.phone_number: 'No Number'}}</div>
				</div>

				<div *ngIf="currentUser.user_type.type.toLowerCase() === 'admin' || currentUser.user_type.type.toLowerCase() === 'cmmanager'  && user?.is_deleted" class="hr hr--space-down-x hr--space-up-x"></div>
				<div *ngIf="currentUser.user_type.type.toLowerCase() === 'admin' || currentUser.user_type.type.toLowerCase() === 'cmmanager'  && user?.is_deleted" class="contact-details__row--single">
					<div class="profile-card__label">Last Deleted Reason</div>
					<div class="text--dark-grey">
					  {{ user?.delete_reason }}
					</div>
				</div>

				<div *ngIf="userType.toLowerCase() === 'patient'">
					<div *ngFor="let contactType of user?.contactType">
						<div class="profile-card__row" *ngIf="contactType.social_type.toLowerCase()==='wechat'">

							<div class="profile-card__label">{{contactType.social_type}}</div>
							<div class="profile-card__details">
								<ul class="profile-card__details-list">
									<li *ngFor="let contact of contactType.contacts">{{contact.social_id}}</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<app-user-role [userRoleList]="userRoleList" [userToEdit]="userToEdit" [CasesCount]="caseDetail?.totalCases || 0" *ngIf="userRoleLength>0"></app-user-role>

				<div class="profile-card__btns" *ngIf="showButtons">
					<!-- <a *ngIf="
					(	
						user && 
						user.user_type_id !== 2 && 
						currentUser.user_type.type.toLowerCase() != 'admin' && 
						currentUser.user_type.type.toLowerCase() != 'cmmanager' && 
						currentUser.user_type.type.toLowerCase() != 'partner'
					) || 
					(
						user &&
						user.user_type_id === 5 &&
						currentUser.user_type.type.toLowerCase() === 'partner'
					)
					" href="javascript:void(0)" class="btn btn--red" (click)="deleteUser()">Inactivate User</a> -->

					<a *ngIf="user && !user?.is_deleted && (currentUser?.user_type?.type.toLowerCase() == 'admin' || currentUser?.user_type?.type.toLowerCase() == 'cmmanager') && currentUser.user_id !== userId"
					class="btn btn--red" [swal]="confirmUserInactiveSwal">Inactivate User</a>

				<swal #confirmUserInactiveSwal title="Inactivate User"   [inputValidator]="validReason()" backdrop="#eef3f6" type="warning"  input="textarea"  confirmButtonText="Continue"  inputPlaceholder="Enter Reason"  iconColor="orange" cancelButtonColor="#db161e" reverseButtons="true" showCancelButton="true" confirmButtonColor="green" confirmButtonText="Inactive User"
					icon="question" closeOnConfirm="false" closeOnCancel="false">			
					<div class="form__field" *swalPortal>
							<swal-title>User to delete: {{ user?.full_name }}</swal-title>
							<swal-title><hr></swal-title>
							<swal-title class="mt-2 ml-0"><label>Please let us know why you are <span class="badge bg-secondary">inactivating</span> this user</label>
							</swal-title>			
						
					</div>
				</swal>

				<swal  #continueCaseSwal title="Confirmation"  (confirm)="deleteModalUser()"  closeOnConfirm="true" closeOnCancel="true"  backdrop="#eef3f6" cancelButtonColor="#db161e"  reverseButtons="true" showCancelButton="true" confirmButtonColor="green" confirmButtonText="Continue">
					<swal-text *swalPortal >Are you sure you want to proceed?</swal-text>
				</swal>
	
			<swal #activateUserSwal title="Do you want to active ' {{ user?.full_name }} '  in the system?" (confirm)="restoreUserModal()" backdrop="#eef3f6" type="warning" iconColor="orange" cancelButtonColor="#db161e" reverseButtons="true" showCancelButton="true" confirmButtonColor="green" confirmButtonText="Active  User"
			icon="question" closeOnConfirm="true" closeOnCancel="true">
			
		</swal>

		
		<button *ngIf="user && user?.is_deleted && (currentUser?.user_type?.type.toLowerCase() == 'admin' || currentUser?.user_type?.type.toLowerCase() == 'cmmanager') && currentUser.user_id !== userId"
		[swal]="activateUserSwal" class="btn btn--green" >Activate User</button>
						
					<!-- [skipLocationChange]="true" -->
					<a *ngIf="user && user.user_type_id === 9 && currentUser.user_type.type.toLowerCase() == 'admin'"
						[routerLink]="['../../edit', userType.toLowerCase(), user?.user_id]"
						[queryParams]="{isSettings: isSettings}" class="btn btn--blue">Edit Profile</a>
					<a *ngIf="user && user.user_type_id !== 9 && (user?.email == currentUser.email || currentUser.user_type.type.toLowerCase() == 'admin' || currentUser.user_type.type.toLowerCase() == 'cmmanager') "
						[routerLink]="['../../edit', userType.toLowerCase(), user?.user_id]"
						[queryParams]="{isSettings: isSettings}" class="btn btn--blue">Edit Profile</a>
					<a *ngIf="
						(	
							user && 
							user.user_type_id !== 9 &&
							user.user_type_id !== 2 && 
							currentUser.user_type.type.toLowerCase() != 'admin' && 
							currentUser.user_type.type.toLowerCase() != 'cmmanager' && 
							currentUser.user_type.type.toLowerCase() != 'partner'
						) || 
						(
							user &&
							user.user_type_id !== 9 &&
							user.user_type_id === 5 &&
							currentUser.user_type.type.toLowerCase() === 'partner'
						)
					" [routerLink]="['../../edit', userType.toLowerCase(), user?.user_id]" [queryParams]="{isSettings: isSettings}"
						[skipLocationChange]="true" class="btn btn--blue">Edit Profile</a>

					<!-- <a [routerLink]="" class="btn btn--blue">Send Message </a> -->
					<a (click)="checkPartnerActivity($event)" class="btn btn--green"
						*ngIf="userType.toLowerCase() === 'patient' && !user.is_deleted && (currentUser?.user_type.type.toLowerCase() === 'partner' || currentUser?.user_type.type.toLowerCase() === 'careteam' || currentUser?.user_type.type.toLowerCase() === 'cmmanager')">Create
						Case</a>
					<a *ngIf="currentUser.user_id == userId" [routerLink]="['/change-password']" class="btn btn--orange">Change Password</a>
				</div>



				<!--self buttons-->
				<div class="profile-card__btns" *ngIf="!showButtons && currentUser.user_id == userId">
					<a class="btn btn--blue" href="javascript:void(0)" (click)="goToEditProfile()">Edit Profile</a>
					<a [routerLink]="['/change-password']" class="btn btn--orange">Change Password</a>
				</div>
			</div>
		</div>
		<div class="col col__md-10 single-centered-col">
			<app-user-history [userId]="userId"></app-user-history>
		</div>
	</div>

	<app-delete-user-template [name]="user?.full_name" [hideTemplate]="hideUserDeleteTemplate" (toggleTemplate)="toggleUserDeleteTemplate($event)" (inActivateUser)="deleteUser($event)"></app-delete-user-template>
</div>
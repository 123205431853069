<app-dialog></app-dialog>

<div class="header">
	<div class="header__page-name">
		<h1 class="heading heading--main">Calendar</h1>
	</div>
</div>
<div class="wrapper--dash-content dash-content">
	<div class="tabs__content" [class.overlay--visible]="showSpinner">
		<div class="overlay__content">
			<div class="overlay__spinner">
				<span class="icon icon--loading"></span>
			</div>
		</div>
		<div class="calender__wrapper">

			<div class="form tabs__content--white-bg">
				<form [formGroup]="calenderForm">
					<h3 class="form__title margin--top-0">{{title}} </h3>
					<div class="row" *ngIf="!isDoctor">
						<div class="col__md-4">
							<div class="form__row">
								<label for="" class="form__label">Doctor</label>
								<div class="form__field">
									<select formControlName="doctor_id" (change)="changeDoctorCalender($event)">
										<option *ngFor="let doctor of doctors" value="{{doctor.doctor_id}}">{{doctor.doctor}}</option>
									</select>
									<!-- <select formControlName="doctor_id">
                    <option *ngFor="let doctor of doctors" value="{{doctor.doctor_id}}">{{doctor.doctor}}</option>
                  </select> -->
									<div *ngIf="doctor_id.touched && doctor_id.invalid" class="message message--is-invalid">
										<div *ngIf="doctor_id.errors.required">This field is required.</div>
									</div>

								</div>
							</div>
						</div>

					</div>


					<div class="row">
						<div class="col col__md-4">
							<div class="form__row">
								<label for="" class="form__label">Availability Type</label>
								<div class="form__field">
									<div class="form__field checkbox--in-row">
										<div class="form__field--checkbox">
											<input type="checkbox" formControlName="single" (click)="changeOccurrence(checkbox,'Single',isNew)" #checkbox/>
											<label for="option">Single</label>
										</div>
										<div class="form__field--checkbox">
											<input type="checkbox" formControlName="multiple" (click)="changeOccurrence(checkbox,'Reoccurring',isNew)" #checkbox/>
											<label for="option">Reoccurring</label>
										</div>

									</div>

								</div>
							</div>
						</div>

					</div>

					<div class="hr hr--space-down-2x hr--space-up-2x"></div>

					<div formArrayName="doctor_availability">
						<div class="row margin--bottom-x" *ngFor="let da of doctor_availability.controls; let i=index" [formGroupName]="i">
							<div class="col col__md-3">
								<div class="form__row">
									<label for="" class="form__label">Pick Date</label>
									<div class="form__field">
										<div class="form__field--date">
											<input formControlName="available_date" [owlDateTime]="dt3" [min]="selectedMoment" #createAvailableDate readonly>
											<span class="trigger  icon icon--calendar" [owlDateTimeTrigger]="dt3"></span>
											<owl-date-time [pickerType]="'calendar'" #dt3></owl-date-time>
										</div>
										<div *ngIf="da.get('available_date').touched && da.get('available_date').invalid" class="message message--is-invalid">
											<div>This field is required.</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col col__md-4 gutter--right-x">
								<div class="form__row">
									<div class="form__row--time-range">
										<div class="start-time">
											<label for="" class="form__label">Start Time</label>
											<div class="form__field">
												<input [owlDateTimeTrigger]="dt4" [owlDateTime]="dt4" formControlName="start_time" readonly #minTime>
												<owl-date-time [pickerType]="'timer'" #dt4 [hour12Timer]="true" (afterPickerClosed)="removeInvalidMsg()"></owl-date-time>
											</div>

											<div *ngIf="da.get('start_time').touched && da.get('start_time').invalid" class="message message--is-invalid">
												<div>This field is required.</div>
											</div>
										</div>
										<div class="start-end-sep">
											<div class="date--start-end">-</div>
										</div>
										<div class="end-time">
											<label for="" class="form__label">End Time</label>
											<div class="form__field">
												<input [owlDateTimeTrigger]="dt5" [owlDateTime]="dt5" formControlName="end_time" readonly #maxTime>
												<owl-date-time [pickerType]="'timer'" #dt5 [hour12Timer]="true" (afterPickerClosed)="removeInvalidMsg()"></owl-date-time>
											</div>
											<div *ngIf="da.get('end_time').touched && da.get('end_time').invalid" class="message message--is-invalid">
												<div>This field is required.</div>

											</div>

										</div>
									</div>

								</div>
							</div>
							<div class="col col__md-3  gutter--right-x">
								<div class="form__row">
									<label for="" class="form__label">Remarks</label>
									<div class="form__field">
										<input type="text" id="remarks" formControlName="remarks" />
									</div>
								</div>

							</div>
							<div class="col col__md-2">
								<button [swal]="deleteAllTimeSwal" type="button" class="btn btn--red btn--small margin--top-2x"  *ngIf="doctor_availability.controls.length > 1">X</button>
								<swal #deleteAllTimeSwal title="Are you sure you want to delete this date time?"  (confirm)="removeTime(i)"  closeOnConfirm="true" closeOnCancel="true"  backdrop="#eef3f6" cancelButtonColor="#db161e"  reverseButtons="true" showCancelButton="true" confirmButtonColor="green" confirmButtonText="Continue">
								</swal>

							</div>


						</div>

						<div class="row margin--bottom-x">
							<div class="col col__md-3">
								<div class="form__row"></div>
							</div>
							<div class="col col__md-3">
								<div *ngIf="!valid_time" class="message message--is-invalid">
									<div>Please enter a valid time range.</div>

								</div>
							</div>
						</div>

					</div>

					<div class="btns-row">
						<a href="#" class="btn btn--blue" (click)="addAnotherTime($event)">Add Another Time</a>
						<button href="#" [swal]="saveSwal" class="btn btn--green " [disabled]="calenderForm.invalid" >Save</button>
				  
						  <swal #saveSwal title="Confirmation" (confirm)="createModalCalender(calenderForm)" closeOnConfirm="true" closeOnCancel="true"  backdrop="#eef3f6" cancelButtonColor="#db161e"  reverseButtons="true" showCancelButton="true" confirmButtonColor="green" confirmButtonText="Continue">
						   <div *swalPortal>
							
							 <swal-text>Are you sure you want to add this doctor availability?</swal-text>
						   </div>
						 </swal>
						 		
					</div>
				</form>

				<div class="hr hr--space-down-2x hr--space-up-2x"></div>
				<form [formGroup]="calenderEditForm">
					<div formArrayName="doctor_availability">
						<div class="row margin--bottom-x" *ngFor="let da of doctor_availability_edit.controls; let i=index" [formGroupName]="i">
							<div class="col col__md-3">
								<div class="form__row">
									<label for="" class="form__label">Pick Date</label>
									<div class="form__field">
										<div class="form__field--date">
											<input formControlName="available_date" [owlDateTime]="dt3" [min]="selectedMoment" readonly>
											<span class="trigger  icon icon--calendar" [owlDateTimeTrigger]="dt3"></span>
											<owl-date-time [pickerType]="'calendar'" #dt3></owl-date-time>
										</div>
										<div *ngIf="da.get('available_date').touched && da.get('available_date').invalid" class="message message--is-invalid">
											<div>This field is required.</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col col__md-4 gutter--right-x">
								<div class="form__row">
									<div class="form__row--time-range">
										<div class="start-time">
											<label for="" class="form__label">Start Time</label>
											<div class="form__field">
												<input [owlDateTimeTrigger]="dt4" [owlDateTime]="dt4" formControlName="start_time" readonly #minTime>
												<owl-date-time [pickerType]="'timer'" #dt4 [hour12Timer]="true" (afterPickerClosed)="removeMsgEdit()"></owl-date-time>
											</div>

											<div *ngIf="da.get('start_time').touched && da.get('start_time').invalid" readonly class="message message--is-invalid">
												<div>This field is required.</div>
											</div>
										</div>
										<div class="start-end-sep">
											<div class="date--start-end">-</div>
										</div>
										<div class="end-time">
											<label for="" class="form__label">End Time</label>
											<div class="form__field">
												<input [owlDateTimeTrigger]="dt5" [owlDateTime]="dt5" formControlName="end_time" readonly #maxTime>
												<owl-date-time [pickerType]="'timer'" #dt5 [hour12Timer]="true" (afterPickerClosed)="removeMsgEdit()"></owl-date-time>
											</div>
											<div *ngIf="da.get('end_time').touched && da.get('end_time').invalid" class="message message--is-invalid">
												<div>This field is required.</div>

											</div>

										</div>
									</div>

								</div>
							</div>
							<div class="col col__md-3  gutter--right-x">
								<div class="form__row">
									<label for="" class="form__label">Remarks</label>
									<div class="form__field">
										<input type="text" id="remarks" formControlName="remarks" />
									</div>
								</div>

							</div>
							<div class="col col__md-2">
								<button type="button" class="btn btn--green btn--small margin--top-2x" (click)="saveTime(i)">V</button>

								<button [swal]="deleteDoctorAvailabilitySwal" type="button" class="btn btn--red btn--small margin--top-2x" >X</button>

								<swal #deleteDoctorAvailabilitySwal title="Confirmation"  (confirm)="deleteTimeUpdate(i)"  closeOnConfirm="true" closeOnCancel="true"  backdrop="#eef3f6" cancelButtonColor="#db161e"  reverseButtons="true" showCancelButton="true" confirmButtonColor="green" confirmButtonText="Continue">
									<swal-text *swalPortal >Are you sure you want to delete this doctor availability?</swal-text>
								</swal>

							</div>
							<div class="row margin--bottom-x">
								<div class="col col__md-3">
									<div class="form__row"></div>
								</div>
								<div class="col col__md-3">
									<div [id]='"valid_time_"+i' class="message message--is-invalid hideErrMsg">
										<div>Please enter a valid time range.</div>

									</div>
								</div>
								<!-- <div class="col col__md-3">
                  <div *ngIf="!valid_time_edit" class="message message--is-invalid">
                    <div>Please enter a valid time range.</div>

                  </div>
                </div> -->
							</div>

						</div>



					</div>
				</form>

			</div>




		</div>


	</div>
</div>
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { BaseDoctorOnePager } from './doctor-one-pager';
import { ConsultationAnswer } from './consultation-answer';
import { DoctorOnePagerModel } from './doctorOnePagerModel';

export class DoctorOnePagerAnswer extends BaseDoctorOnePager {

  answer: ConsultationAnswer[];
  patient_first_name: string;
  patient_last_name: string;
  partner_careteam_email: string;
  send_email: boolean;
  partner_careteam_fullname: string;
  partner_careteam_lastname: string;
  linkUrl: string;
  status: any[];
  peer_to_peer_details: any;
  userId: number;

  constructor(model?: DoctorOnePagerModel) {
    super();
    this.answer = [];

    if (model) {
      this.written_summary = model.written_summary;
      this.id = model.id;
      this.case_medication = model.case_medication ? model.case_medication : [];
      this.is_complete = model.is_complete;
      this.comment_summary = model.comment_summary;
      this.case_identifier = model.case_identifier;
      this.summary_assignee = model.summary_assignee;
      this.case_owner = model.case_owner;
      this.peer_to_peer_details = model.peer_to_peer_details;
    }
  }

  setUserId(userId: number) {
    this.userId = userId;
  }

  setAnswer(question: any[], userId: number) {
    this.answer = [];
    if (question.length > 0) {
      question.forEach(eachQuestion => {
        this.answer = this.answer.concat(eachQuestion.answer);
      });

      this.answer = this.answer.filter((eachAnswer: any) =>
        (eachAnswer.answer_by_user_id == null || eachAnswer.answer_by_user_id === userId));

      this.answer = this.answer.map(eachAnswer => {
        const response = new ConsultationAnswer();
        response.id = eachAnswer.id;
        response.answer = eachAnswer.answer;
        response.case_question_id = eachAnswer.case_question_id;
        return response;
      }).filter(eachAnswer => (eachAnswer.answer != null));
    }
  }

  setPatientInfo(user: any) {
    this.patient_first_name = user.first_name;
    this.patient_last_name = user.last_name;
  }

  setPartnerCareteamInfo(user: any) {
    this.partner_careteam_email = user.email;
    this.partner_careteam_fullname = user.full_name;
    this.partner_careteam_lastname = user.last_name;
  }

  setSendEmail(isSend: boolean) {
    this.send_email = isSend;
  }

  setLinkUrl(url: string) {
    this.linkUrl = url;
  }

  setStatus(status: any[]) {
    this.status = status;
  }

  set_peer_to_peer_details(peer_to_peer_details: any) {
    this.peer_to_peer_details = peer_to_peer_details;
  }
}

/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-spread */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */

/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, Input, OnInit, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { LoginService } from '../../../services/login.service';
import { DataTableDirective } from 'angular-datatables';
import { SEARCH_OPTION_EMAIL_TEMPLATE } from '../../../cases/translator-caselist/constant';

@Component({
  selector: 'app-email-template-header',
  templateUrl: './email-template-header.component.html',
  styleUrls: ['./email-template-header.component.css']
})
export class EmailTemplateHeaderComponent implements OnInit {
  @Input() totalRecord = 0;
  @Output() searchValueEmitter = new EventEmitter<string>();
  @Output() searchOptions = new EventEmitter<string>();
  @ViewChildren(DataTableDirective)
  datatableElement: QueryList<DataTableDirective>;
  dtOptionsDirect: DataTables.Settings= {};
  dtOptionsQueue: DataTables.Settings = {};

  userRole: string;
  searchValue = '';
  searchColumn = {column: '',value: ''};
  searchColumnName = '';
  searchColumnValue='';
  columnOptions= {};
  
  clearSearch(): void{
    this.searchColumn = {
      column: '',
      value: ''
    };
    this.searchColumnName = '';
    this.searchColumnValue = '';
    
    const searchArray :any =[];
    searchArray['column'] = '';
    searchArray['value'] = '';
    this.searchValueEmitter.emit(searchArray);

    this.searchValue = '';
    
    this.searchColumn = {
      column: '',
      value: ''
    };
  }

  constructor(
    private loginService: LoginService,
  ) { }


  ngOnInit(): void {
    const user = this.loginService.getCurrentUser();
    this.userRole = user.user_type.type.toLowerCase();
    this.searchColumn = { column: '', value: '' };
    this.columnOptions = (SEARCH_OPTION_EMAIL_TEMPLATE[`${this.userRole}`]);
  }

  currentSearch(columnKey: string, columnValue: string, value: string): void {
    const searchArray :any =[];
    searchArray['column'] = columnKey;
    searchArray['value'] =  value.toLowerCase();
    searchArray['searchColumnName'] = columnValue;
    searchArray['searchColumnValue'] = value;
    this.searchValueEmitter.emit(searchArray);

    this.searchColumn.column = columnKey;
    this.searchColumn.value = value.toLowerCase();
    this.searchColumnName = columnValue;
    this.searchColumnValue = value;

  }

}

<div class="header">
  <div class="header__page-name">
    <h1 class="heading heading--main">{{title}}</h1>
  </div>
  <div class="header__top-nav">
    <app-top-menu-partner [activePartner]="activePartner" (searchPartnerEmitter)="searchString($event)" [PARTNER_STATUS]="PARTNER_STATUS" (partnerTypeFilter)="filterPartner($event)"></app-top-menu-partner>
  </div>
</div>
<div class="wrapper--dash-content dash-content">
  <div *ngIf="errorString" class="message message--is-invalid">
    {{errorString}}
  </div>

  <table datatable [dtOptions]="dtOptions" width="100%" border="0" class="row-border hover dataTable no-footer" id="partnertable">
    <thead>
      <tr>
        <th>Client Name</th>
        <th>Client Type</th>
        <th>Cases</th>
        <th>Activation</th>
      </tr>
    </thead>
    <tbody *ngIf="errorString">
      <tr>
          <td colspan="9" class="no-data-available">No data!</td>
      </tr>
  </tbody>
  </table>
</div>


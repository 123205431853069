<app-dialog></app-dialog>
<div class="header">
   <div class="header__page-name">
      <h1 class="heading heading--main">Release Email Settings</h1>
   </div>
</div>
<div class="wrapper--dash-content dash-content overlay__wrapper">
   <div class="tabs__content">
      <form #releaseForm="ngForm" class="form col-md-12" novalidate>
         <div class="row">
            <div class="col col__md-12">
               <div class="box box--white overlay__wrapper box--radius box--margin-bottom-2x">
                  <div class="row mt-3 mb-5">
                     <div class="col-md-8 ">
                        <table class="table table-bordered table-striped" aria-labelledby="">
                           <thead>
                              <tr>
                                 <th id="label-number">S.N</th>
                                 <th id="label-full-name"><b>Full Name</b></th>
                                 <th id="label-user-type"><b>User Type</b></th>
                                 <th id="label-email"><b>Email</b></th>
                                 <th id="label-checkbox"><b>Send To</b></th>
                              </tr>
                           </thead>
                           <div [class.overlay--visible]="true" *ngIf="loading">
                              <div class="overlay__content">
                                 <div id="casetable_active_processing" class="dataTables_processing" role="status">
                                    <div><div></div><div></div><div></div><div></div></div>
                                 </div>
                              </div>
                           </div>
                           <tbody class="custom-tbody">
                              <tr *ngFor="let user of users; let i = index">
                                 <td id="label-number">{{i + 1}}</td>
                                 <td id="label-full-name">{{user?.first_name}} {{user?.last_name}}</td>
                                 <td id="label-user-type">{{user?.user_type}}</td>
                                 <td id="label-email">{{user?.email}}</td>
                                 <td id="label-checkbox">
                                    <input type="checkbox" [(ngModel)]="user.selected" id="{{i.id}}"
                                       name="{{user?.email}}" value="{{user.full_name}}" class="offset-5">
                                 </td>
                              </tr>
                              <h1 class="text-center mt-5" *ngIf="users.length == 0  && !loading"> No user found</h1>
                           </tbody>

                        </table>
                        <div class="row">
                           <div class="col-md-6">
                              <div class="form__field">
                                 <select class="mt-3 form-control" [disabled]="loading" (change)="selectUser($event.target.value)">
                                    <option value="0">All</option>
                                    <option value="2" selected>Alight Care Team</option>
                                    <option value="4">Doctor</option>
                                    <option value="6">Summary Coordinator</option>
                                    <option value="10">Alight CM Manager</option>
                                    <option value="11">Alight Case Coordinator</option>
                                 </select>
                              </div>
                           </div>
                           <div class="col-md-4 offset-2" id="button-position-right">
                              <button class="mt-3 btn btn--red" [disabled]="users.length == 0 || loading" (click)="clearAll($event);">Unselect All</button>
                              <button class="mt-3 btn btn--blue"  [disabled]="users.length == 0 || loading" (click)="selectAll($event);">Select All</button>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-4" >
                        <div class="col-md-12">
                           <div class="form__field offset-1">
                              <label>* Release Type :</label>
                              <select class="form-control mt-2" (change)="selectReleaseType($event.target.value)"  [ngModelOptions]="{standalone: true}"  #releaseType="ngModel" id="release_type" name="release_type" ngModel [disabled]="users.length == 0"  [(ngModel)]="release_type" >
                                 <option value="Pre Release" selected >Pre Release</option>
                                 <option value="Post Release">Post Release</option>
                              </select>
                              <div *ngIf="releaseType?.invalid && (releaseType?.dirty || releaseType?.touched)" class="alert alert-danger mt-2">
                                 <div *ngIf="releaseType.errors.required">Release Type field is required</div>
                              </div>
                              <br>

                              <label>* Subject :</label>
                              <input type="text" name="release_email_subject"  [class.is-invalid]="releaseSubject.invalid && (releaseSubject.dirty || releaseSubject.touched)"
                               [disabled]="users.length == 0 || loadReleaseForm" [(ngModel)]="release_email_subject"
                                 #releaseSubject="ngModel" id="subject" class="mt-2" ngModel
                                 [ngModelOptions]="{standalone: true}" placeholder="Enter your release title" required>
                              <div *ngIf="releaseSubject?.invalid && (releaseSubject?.dirty || releaseSubject?.touched)" class="alert alert-danger mt-2">
                                 <div *ngIf="releaseSubject.errors.required">Subject field is required</div>
                              </div>
                              <br>
                              <label>* Body :</label>
                              <br> <br>
                              <ckeditor name="release_email_body"   [readonly]="users.length == 0 || loadReleaseForm " placeholder="Enter your body message here" ngModel
                              #releaseBody="ngModel" [ngModelOptions]="{standalone: true}" [config]="{toolbar: []}"  #ckeditorField 
                              [(ngModel)]="release_email_body" rows="35" cols="120" id="Body"
                               required></ckeditor>

                              <div *ngIf="releaseBody?.invalid && (releaseBody?.dirty || releaseBody?.touched)"
                                 class="alert alert-danger mt-2">
                                 <div *ngIf="releaseBody?.errors?.required">Body field is required</div>
                              </div>

                              <div class="col-md-4 offset-4 mt-1">
                                 <button class="mt-4 btn mt-5 btn--green"  [disabled]="users.length == 0 || loadReleaseForm" [swal]="releaseModal" value="submit" [innerText]="label">
                                 </button>
                                 <swal #releaseModal title="Confirmation" cancelButtonColor="#db161e"
                                    confirmButtonText="Send Email" closeOnConfirm="true" closeOnCancel="true"
                                    (confirm)="onSubmitReleaseForm(releaseForm)" reverseButtons="true"
                                    showCancelButton="true" confirmButtonColor="green"
                                    [swalOptions]="{showClass: { backdrop: '',popup: ''}}" backdrop="#eef3f6"
                                    showCloseButton="false" icon="warning" iconColor="orange">

                                    <swal-text *swalPortal>
                                       <b>Are you sure you want to send the release email to selected
                                          users? </b>
                                       <br>
                                    </swal-text>
                                 </swal>
                                 </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </form>
   </div>
</div>
/* eslint-disable prefer-const */
import { CdkDragDrop, CDK_DRAG_CONFIG, moveItemInArray } from '@angular/cdk/drag-drop';
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable no-undef */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as moment from 'moment/moment';
import { PopUpService } from '../../../services/pop-up.service';
import { CaseService } from '../../../services/case.service';
import { LoginService } from '../../../services/login.service';
import { SimpleChanges } from '@angular/core';
import { CASE_STATUS_TYPE } from '../../../constant';

const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000
};

@Component({
  selector: 'app-intake-form',
  templateUrl: './intake-form.component.html',
  styleUrls: ['./intake-form.component.css'],
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }]
})
export class IntakeFormComponent implements OnInit {
  @Input() caseDetail;
  @Input() sudoDisableDrag = false;
  @Output() notifyDocumentChange = new EventEmitter();
  intakeForm: FormGroup;
  isLoading: boolean;
  disableForm: boolean;
  isGeneratingPdf:boolean
  role:string
  buttonKey:string;
  isIntakeFormCollapse = true;
  formFields = ['Name', 'Date of birth', 'Age', 'Gender', 'Patient Diagnosis', 'Height', 'Weight', 'Key Studies and Intervention', 'Social History', 'Allergies', 'Medications', 'History of Present Illness', 'Past Medical History', 'Past Surgical History', 'Family History', 'Files Summarized', 'Case Summary'  ];
  nonDraggableFields = ['Name', 'Date of birth', 'Age', 'Gender', 'Height', 'Weight']
  draggableFields = ['Patient Diagnosis','Key Studies and Intervention', 'Social History', 'Allergies', 'Medications', 'History of Present Illness', 'Past Medical History', 'Past Surgical History', 'Family History', 'Files Summarized', 'Case Summary'  ];
  formFieldOrder = [];

  constructor(
    private fb: FormBuilder,
    private caseService: CaseService,
    private popUpService: PopUpService,
    private loginService: LoginService,
  ) { }

  ngOnInit(): void {
    const user = this.loginService.getCurrentUser();
    this.role = user.user_type.type.toLowerCase();
    console.log(this.role);
    
    this.buildIntakeForm();
    this.isLoading = false;
    this.isGeneratingPdf = false;

    this.fetchIntakeFormFieldOrder();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.caseDetail && changes.caseDetail.previousValue && changes.caseDetail.previousValue['id'] !== changes.caseDetail.currentValue.id) {
      this.buildIntakeForm();
    } 
  }

  private buildIntakeForm() {
    const {
      patient_diagnosis,
      summary_social_history, summary_key_studies_and_intervention, summary_allergies, summary_medications,
      summary_history_of_present_illness,
      summary_past_medical_history,
      summary_past_surgical_history,
      summary_family_history,
      summary_files_summarized,
      summary_case_summary,
      summary_state,
      patient: {
        dob,
        gender,
        height,
        weight,
        user: { full_name }
      }
    } = this.caseDetail;
    const years = moment().diff(dob, 'years',false);
    const months = moment().diff(dob, 'months', false) % 12;
    const age = `${years} Years ${months} Months`;

    this.intakeForm = this.fb.group({
      name: [full_name, Validators.required],
      dob: [dob, Validators.required],
      age: [age,  Validators.required],
      gender: [gender, Validators.required],
      patient_diagnosis: [patient_diagnosis, Validators.required],
      height: [height, Validators.required],
      weight: [weight, Validators.required],
      state: [summary_state, Validators.required],
      history_of_present_illness: [summary_history_of_present_illness, Validators.required],
      past_medical_history: [summary_past_medical_history, Validators.required],
      past_surgical_history: [summary_past_surgical_history, Validators.required],
      family_history: [summary_family_history, Validators.required],
      files_summarized: [summary_files_summarized, Validators.required],
      case_summary: [summary_case_summary, Validators.required],
      key_studies_and_intervention: [summary_key_studies_and_intervention, Validators.maxLength(5000)] ,
      social_history: [summary_social_history ,Validators.maxLength(5000)] ,
      allergies: [summary_allergies,Validators.maxLength(5000)],
      medications: [summary_medications, Validators.maxLength(5000)],
    });

    if (this.role === 'translator' && (this.caseDetail.summary_state === 'SUBMITTED' || this.caseDetail.summary_state === 'APPROVED')) {
      this.disableForm = true;
      this.intakeForm.disable();
    }
  
    else if ((this.role === 'admin' || this.role === 'careteam' || this.role === 'casecoordinator' ||  this.role === 'mdmanager' || this.role === 'cmmanager') && (this.caseDetail.summary_state === 'SAVED' || this.caseDetail.summary_state === 'APPROVED' || this.caseDetail.summary_state === 'DRAFT')) {
      this.disableForm = true;
      this.intakeForm.disable();
    }
    else if(this.role === 'mdmanager'){
      this.disableForm = true;
      this.intakeForm.disable();
    }
    else {
      this.disableForm = false;
      this.intakeForm.enable();
    }
  }

  disableFields() {
    this.intakeForm.get('age').disable();
    this.intakeForm.get('name').disable();
    this.intakeForm.get('dob').disable();
    this.intakeForm.get('gender').disable();
    // this.intakeForm.get('patient_diagnosis').disable();
  }


  get state() {
    return this.caseDetail.summary_state || 'DRAFT';
  }

  get stateDetail() {
    switch (this.state) {
    case 'SUBMITTED':
      return {
        text: this.state,
        color: 'badge-green'
      };
    case 'APPROVED':
      return {
        text: 'LOCKED',
        color: 'badge-orange'
      };
    default:
      return {
        text: 'PENDING',
        color: 'badge-orange'
      };

    }
  }


  handleFormSubmit = (_event, form, type) => {
    const state = this.getState(type);
    this.buttonKey = type;
    const formatedData = this.formatData(form);
    formatedData['summary_state'] = state;
    formatedData['activity_state'] = type;
    const confirmDialog = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.destoryConfirmDialog();
    this.popUpService.setDialogParams(
      'Confirm',
      `Are you sure you want to ${type} the Medical Record Summary ?`,
      'Yes',
      'No',
      () => {
        this.isLoading=true;
        this.caseService.insertIntakeForm(this.caseDetail.id, formatedData).subscribe(
          (_result) => {
            if (type !== 'edit') {
              this.showAlert(`Medical Record Summary ${this.getMessageText(type)} successfully.`);
            }
            this.caseDetail.summary_state = state;
            if (this.role === 'translator' && (state === 'SUBMITTED')) {
              this.disableForm = true;
              this.intakeForm.disable();
            }
            else if ((this.role === 'admin' ||this.role === 'careteam'|| this.role === 'casecoordinator' || this.role === 'mdmanager' || this.role === 'cmmanager') && (state === 'APPROVED')) {
              this.disableForm = true;
              this.intakeForm.disable();
            }
            else {
              this.disableForm = false;
              this.intakeForm.enable();
            }
            this.disableFields();
            this.isLoading=false;
          },
          error => {
            if(error.error?.errors[0]?.msg){
              this.showAlert(error.error?.errors[0]?.msg);
            }
            else{
              this.showAlert(error?.error?.message);
            }

            this.isLoading=false;
          }
        );

        this.popUpService.hideDiv(confirmDialog);
      },
      () => {
        this.popUpService.hideDiv(confirmDialog);
      }
    );
    this.popUpService.showDiv(confirmDialog);
  }

  generateIntakeFormPdf = (_event) => {
    const confirmDialog = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.destoryConfirmDialog();
    this.popUpService.setDialogParams(
      'Confirm',
      'Are you sure you want to generate the Medical Record Summary PDF?',
      'Yes',
      'No',
      () => {
        this.isGeneratingPdf=true;
        this.caseService.generateIntakePDF(this.caseDetail.id).subscribe(
          (_result:any) => {
            this.showAlert('Medical Record Summary PDF generated successfully.');
            this.notifyDocumentChange.emit();
            this.isGeneratingPdf=false;
          },
          (_error) => {
            this.showAlert('Failed to generate Medical Record Summary PDF.');
            this.isGeneratingPdf=false;
          }
        );

        this.popUpService.hideDiv(confirmDialog);
      },
      () => {
        this.popUpService.hideDiv(confirmDialog);
      }
    );
    this.popUpService.showDiv(confirmDialog);
  }

  public showAlert(message: string, action?: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (action) {
      this.popUpService.setPositiveAction(action);
    }
    else {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) {this.popUpService.showDiv(dialog);}
  }

  private formatData(form) {
    const formData = form.value;
    return {
      name: formData.name,
      dob: formData.dob,
      age: formData.age,
      gender: formData.gender,
      patient_diagnosis: formData.patient_diagnosis,
      height: formData.height,
      weight: formData.weight,
      summary_history_of_present_illness: formData.history_of_present_illness,
      summary_past_medical_history: formData.past_medical_history,
      summary_past_surgical_history: formData.past_surgical_history,
      summary_family_history: formData.family_history,
      summary_files_summarized: formData.files_summarized,
      summary_case_summary: formData.case_summary,
      summary_key_studies_and_intervention: formData.key_studies_and_intervention,
      summary_social_history: formData.social_history,
      summary_allergies: formData.allergies,
      summary_medications: formData.medications,
    };
  }

  private getState(key) {
    switch (key) {
    case 'save':
      return 'SAVED';
    case 'submit':
      return 'SUBMITTED';
    case 'lock':
      return 'APPROVED';
    case 'unlock':
      return 'SUBMITTED';
    case 'edit':
      return 'SAVED';
    default:
      return 'DRAFT';
    }
  }
  private getMessageText(key) {
    switch (key) {
    case 'save':
      return 'saved';
    case 'submit':
      return 'submitted';
    case 'lock':
      return 'locked';
    case 'unlock':
      return 'unlocked';
    default:
      return '';
    }
  }

  toggleThis(event, toggle_part: string) {
    event.preventDefault();
    $('section[id=\'' + toggle_part + '\']').toggleClass('is-expanded');
  }

  noAction(event) {
    event.preventDefault();
    return false;
  }

  generateFormName(label: string) {
    if (label === 'Date of birth') return 'dob';

    return label.replace(/ /g, '_').toLowerCase();
  }

  // All of these are disabled
  isNotTranslatorField(label:string) {
    return ['name', 'date of birth', 'age', 'gender' ].includes(label.toLowerCase());
  }

  isHeightOrWeight(label: string) {
    return ['height', 'weight'].includes(label.toLowerCase());
  }

  isCkEditor(label: string) {
    return ['history of present illness', 'past medical history', 'past surgical history', 'family history', 'files summarized', 'case summary'].includes(label.toLowerCase());
  }

  isTranslator() {
    return this.role === 'translator';
  }

  drop(event: CdkDragDrop<unknown>) {
    const prevIndex = this.formFields.indexOf(this.draggableFields[event.previousIndex]);
    const newIndex = this.formFields.indexOf(this.draggableFields[event.currentIndex]);

    if (prevIndex !== newIndex)
      this.caseService.patchCaseFormFieldOrder(this.formFieldOrder[prevIndex].id, newIndex, this.formFieldOrder[newIndex].id, prevIndex)
        .subscribe(() => {
          moveItemInArray(this.formFields, prevIndex, newIndex);
          moveItemInArray(this.formFieldOrder, prevIndex, newIndex);
          moveItemInArray(this.draggableFields, event.previousIndex, event.currentIndex);
        });

  }
 
  sortPredicate(index: number) {
    if (this.role === 'translator') 
      return index > this.nonDraggableFields.length - 1;

    return true;
  }

  fetchIntakeFormFieldOrder() {
    this.caseService.getCaseFormFieldOrder(this.caseDetail.id, undefined, 'INTAKE FORM')
      .subscribe((res) => {
        this.formFieldOrder = res;
        const newFormFields = [];
        // same array but diff order
        const newDraggableFields = [];
        const draggableFieldsSet = new Set(this.draggableFields);

        this.formFieldOrder.forEach((ffo) => {
          newFormFields.push(ffo.form_field_name);
        });

        this.formFields = [...newFormFields];
        
        newFormFields.forEach((field) => {
          if (draggableFieldsSet.has(field)) newDraggableFields.push(field);
        });

        this.draggableFields = newDraggableFields;

      });
  }

  isDraggable(field: any) {
    if (this.sudoDisableDrag) return false;

    return !this.nonDraggableFields.includes(field) && 
    (this.role==='translator' || this.role==='admin' || this.role==='cmmanager') 
    && (this.state === 'DRAFT' || this.state === 'SAVED');
  }

  isFormDisabled() {
    return this.state === 'APPROVED' || this.caseDetail.case_status === CASE_STATUS_TYPE.FINISHED;
  }

}

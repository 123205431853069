<app-dialog></app-dialog>
<div class="tabs__content overlay__wrapper" [class.overlay--visible]="showSpinner">
	<div class="overlay__content">
		<div class="overlay__spinner">
			<span class="icon icon--loading"></span>
		</div>
	</div>
	<div class="form tabs__content--white-bg">
		<form [formGroup]="doctorForm">
			<h3 class="form__title">Basic Information</h3>

			<div class="row">
				<div class="col col__md-4">
					<div class="form__row">
						<label for="" class="form__label">First Name *</label>
						<div class="form__field">
							<input type="text" formControlName="first_name" [appFormatOnly]='"trimonly"' />
							<div *ngIf="first_name.touched && first_name.invalid" class="message message--is-invalid">
								<div *ngIf="first_name.errors.required">This field is required.</div>
								<div *ngIf="first_name.errors.maxlength">First name must not exceed
									{{first_name.errors.maxlength.requiredLength}} characters.</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col col__md-4">
					<div class="form__row margin--lr-x">
						<label for="" class="form__label">Middle Name</label>
						<div class="form__field">
							<input type="text" formControlName="middle_name" [appFormatOnly]='"trimonly"' />
							<div *ngIf="middle_name.touched && middle_name.invalid" class="message message--is-invalid">
								<div *ngIf="middle_name.errors.required">This field is required.</div>
								<div *ngIf="middle_name.errors.maxlength">Middle name must not exceed
									{{middle_name.errors.maxlength.requiredLength}} characters.</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col col__md-4">
					<div class="form__row">
						<label for="" class="form__label">Last Name *</label>
						<div class="form__field">
							<input type="text" formControlName="last_name" [appFormatOnly]='"trimonly"' />
							<div *ngIf="last_name.touched && last_name.invalid" class="message message--is-invalid">
								<div *ngIf="last_name.errors.required">This field is required.</div>
								<div *ngIf="last_name.errors.maxlength">Last name must not exceed
									{{last_name.errors.maxlength.requiredLength}}
									characters.</div>
							</div>
						</div>
					</div>
				</div>


			</div>
			<div class="hr hr--space-down-2x hr--space-up-2x"></div>



			<div class="form--two-cols">
				<div class="row">
					<div class="col col__md-6 gutter--right-3x border-right">
						<div class="form__row" formGroupName="doctor_info">
							<label for="" class="form__label">Gender</label>
							<div class="form__field form__field--width-half">
								<select formControlName="gender">
									<option value="select">Select</option>
									<option value="M">Male</option>
									<option value="F">Female</option>
									<option value="U">Unspecified</option>
								</select>
							</div>
							<div *ngIf="gender.touched && gender.invalid" class="message message--is-invalid">
								<div *ngIf="gender.errors.required">This field is required.</div>
							</div>
						</div>

						<div class="form__row">
							<label for="" class="form__label">Date of Birth </label>
							<div class="form__field">
								<div class="login_form__field--date full-width-input-form">
									<input type="date" for="dob" formControlName="dob" placeholder="Enter Date"
										[owlDateTime]="dt2" (dateTimeChange)="onDateChange($event, dt2, dateInput)"
										[max]="currentDate" (click)="preventDefault($event)" #dateInput />
									<span class="trigger  icon icon--calendar" [owlDateTimeTrigger]="dt2"
										*ngIf='!isMdManager'></span>
									<owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
								</div>
							</div>
							<div *ngIf="dob.touched && dob.invalid" class="message message--is-invalid">
								<div *ngIf="dob.errors.required">This field is required.</div>
							</div>
						</div>

						<div class="form__row" formGroupName="doctor_info">
							<label for="" class="form__label">Stage</label>
							<div class="form__field">
								<input type="text" formControlName="stage" [appFormatOnly]='"trimonly"' />
								<div *ngIf="stage.touched && stage.invalid" class="message message--is-invalid">
									<div *ngIf="stage.errors.required">This field is required.</div>
									<div *ngIf="stage.errors.maxlength">Stage must not exceed
										{{stage.errors.maxlength.requiredLength}}
										characters.</div>
								</div>
							</div>
						</div>

						<!-- <app-timezone [zone_info]="doctorForm.get('zone_info')"></app-timezone> -->

					</div>

					<div class="col col__md-6" formGroupName="doctor_info">
						<div class="form__row">
							<label for="" class="form__label">Address(Number and Street Name, Apt., etc.)</label>
							<div class="form__field">
								<input type="text" formControlName="address" [appFormatOnly]='"trim"' />
								<div *ngIf="address.touched && address.invalid" class="message message--is-invalid">
									<div *ngIf="address.errors.required">This field is required.</div>
									<div *ngIf="address.errors.maxlength">City must not exceed
										{{address.errors.maxlength.requiredLength}}
										characters.</div>
								</div>
							</div>
						</div>
						<div class="form__row">
							<div class="csz-row">
								<div class="csz-row__city">
									<label for="" class="form__label">City</label>
									<div class="form__field">
										<input type="text" formControlName="city" [appFormatOnly]='"trim"' />
										<div *ngIf="city.touched && city.invalid" class="message message--is-invalid">
											<div *ngIf="city.errors.required">This field is required.</div>
											<div *ngIf="city.errors.maxlength">City must not exceed
												{{city.errors.maxlength.requiredLength}} characters.</div>
										</div>
									</div>
								</div>
								<div class="csz-row__state">
									<label for="" class="form__label">State</label>
									<div class="form__field">
										<input type="text" formControlName="state" [appFormatOnly]='"uppercase"' />
										<div *ngIf="state.touched && state.invalid" class="message message--is-invalid">
											<div *ngIf="state.errors.required">This field is required.</div>
											<div *ngIf="state.errors.maxlength">State must not exceed
												{{state.errors.maxlength.requiredLength}}
												characters.</div>
										</div>
									</div>
								</div>
								<div class="csz-row__zip">
									<label for="" class="form__label">ZIP</label>
									<div class="form__field">
										<input type="text" formControlName="zip" maxlength="12" NumbersOnly />
										<div *ngIf="zip.touched && zip.invalid" class="message message--is-invalid">
											<div *ngIf="zip.errors.required">This field is required.</div>
											<div *ngIf="zip.errors.minlength">Zip number must exceed
												{{zip.errors.minlength.requiredLength}} numbers.</div>
											<div *ngIf="zip.errors.maxlength">Zip number must not exceed
												{{zip.errors.maxlength.requiredLength}}
												numbers.</div>
										</div>
									</div>
								</div>
							</div>
						</div>


						<div class="form__row">
							<label for="" class="form__label">Country</label>
							<div class="form__field">
								<select formControlName="country_id">
									<option *ngFor="let country of countriesList" value={{country.country_id}}>
										{{country.country_name}}</option>
								</select>
							</div>
							<div *ngIf="country_id.touched && country_id.invalid" class="message message--is-invalid">
								<div *ngIf="country_id.errors.required">This field is required.</div>
							</div>
						</div>


					</div>

				</div>
			</div>

			<div *ngIf="userType == 'admin' || userType == 'careteam'" class="col col__md-6 gutter--right-3x">
				<div class="form__row">
					<label for="" class="form__label">Ally User Reference *</label>
					<div class="form__field">
						<input type="text" formControlName="allyUserRef" [appFormatOnly]='"trimonly"' />
					</div>
					<div *ngIf="allyUserRef.touched && allyUserRef.invalid" class="message message--is-invalid">
						<div *ngIf="allyUserRef.errors.required">Ally User Reference is required.</div>
					</div>
				</div>
			</div>

			<div class="hr hr--space-down-2x hr--space-up-2x"></div>

			<h4 class="form__subtitle">Contact Information</h4>
			<div class="form--two-cols">
				<div class="row">
					<div class="col col__md-6 gutter--right-3x">
						<div class="form__row">
							<label for="" class="form__label">Phone Number</label>
							<div class="form__field">
								<input type="text" formControlName="phone_number" maxlength="15" PhoneNumber />
								<div *ngIf="phone_number.touched && phone_number.invalid"
									class="message message--is-invalid">
									<div *ngIf="phone_number.errors.minlength">Please enter a valid phone number.</div>
									<div *ngIf="phone_number.errors.maxlength">Please enter a valid phone number.</div>
									<div *ngIf="phone_number.errors.required">This field is required.</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col col__md-6">
						<div class="form__row">
							<label for="" class="form__label">Email *</label>
							<div class="form__field">
								<input type="email" formControlName="email" />
							</div>
							<div *ngIf="email.touched && email.invalid" class="message message--is-invalid">
								<div *ngIf="email.errors.required">This field is required.</div>
								<div *ngIf="email.errors.email && !email.errors.required">Enter a valid email</div>
							</div>
						</div>
					</div>

					<div class="form__row">
						<label for="" class="form__label">Timezone *</label>
						<div class="form__field">
							<select formControlName="time_zone" (change)="setKey(tz)" #tz>
								<option *ngFor="let timezone of timezones" [value]="timezone[0]">{{timezone[1]}}
								</option>
							</select>
							<div *ngIf="time_zone.touched && time_zone.invalid" class="message message--is-invalid">
								<div *ngIf="time_zone.errors.required">This field is required.</div>
							</div>


						</div>
					</div>

				</div>
			</div>
			<div class="hr hr--space-down-2x hr--space-up-2x"></div>
			<div class="form--two-cols">
				<div formArrayName="contacts">
					<div class="row margin--bottom-x" *ngFor="let contact of contacts.controls; let i=index"
						[formGroupName]="i">
						<div class="col col__md-6 gutter--right-3x">
							<label for="" class="form__label">Type</label>
							<div class="form__field">
								<select id="social_type_id" formControlName="social_type_id">
									<option *ngFor="let type of socialTypes" [ngValue]="+type.social_type_id">
										{{type.social_type}}</option>
								</select>
								<div *ngIf="contact.get('social_type_id').touched &&contact.get('social_type_id').invalid"
									class="message message--is-invalid">
									<div>This field is required.</div>
								</div>
							</div>

						</div>
						<div class="col col__md-4 gutter--right-x">
							<label for="" class="form__label">UserID</label>
							<div class="form__field">
								<input type="text" id="social_id" formControlName="social_id"
									[appFormatOnly]='"trimonly"' />
								<div *ngIf="contact.get('social_id').touched && contact.get('social_id').invalid"
									class="message message--is-invalid">
									<div *ngIf="contact.get('social_id').errors.required">This field is required.</div>
									<div *ngIf="contact.get('social_id').errors.maxlength">User id must not exceed
										{{contact.get('social_id').errors.maxlength.requiredLength}} characters</div>
								</div>
							</div>
						</div>
						<div class="col col__md-2">
							<button type="button" class="btn btn--red btn--small margin--top-2x"
								(click)="removeContactAt(i)" *ngIf="contacts.controls.length > 1">X</button>
						</div>
					</div>
				</div>
			</div>
			<div class="btns-row">
				<a href="#" class="btn btn--blue" (click)="addAnotherContact($event)" *ngIf='!isMdManager'>Add Another
					Contact Method</a>
			</div>
			<div class="hr hr--space-down-2x hr--space-up-2x"></div>
			<div class="form--two-cols">
				<div class="row row--flex">
					<div class="col-12 col__md-3 gutter--right-2x">
						<h4 class="form__subtitle margin--top-0">Social</h4>
						<div class="form__row">
							<label for="" class="form__label">Avatar</label>
							<div class="form__field upload overlay__wrapper"
								[class.overlay--visible]="uploader.isUploading">
								<div class="overlay__content">
									<div class="overlay__spinner">
										<span class="icon icon--loading"></span>
									</div>
								</div>

								<input type="file" accept="image/*" class="btn btn--blue btn--small" name="photo"
									ng2FileSelect [uploader]="uploader" />

								<a href="#" class="btn btn--blue btn--small btn--full-width"
									*ngIf='!isMdManager'>Browse</a>

							</div>
						</div>
						<div class="message message--is-invalid" *ngIf="uploadErrorMessage">
							{{uploadErrorMessage}}
						</div>
						<div class="message message--is-valid" *ngIf="uploadCompleteMessage">
							{{uploadCompleteMessage}}
						</div>
						<div class="message message--is-valid" *ngIf="uploadCompleteMessage">
							{{uploadErrorMessage}}
						</div>
					</div>
					<div class="col-12 col__md-3">
						<div class="avatar avatar--small">
							<!-- <img *ngIf="!avatarimage" [src]="avatarMale" />
							<img *ngIf="avatarimage" [src]="'data:image/jpg;base64,'+avatarimage" /> -->
							<app-loader *ngIf="loadingImage"></app-loader>
							<img [src]="'data:image/jpg;base64,'+avatarimage" *ngIf="!loadingImage && avatarimage" />
							<img [src]="avatarMale" *ngIf="!loadingImage && !avatarimage && avatarMale" />
						</div>
					</div>
					<div class="col-12 col__md-6" *ngIf="operation != 'Edit' || showPassword == true">
						<div class="form__row">
							<label for="" class="form__label label--dark">Password *</label>
							<div class="form__field">
								<div class="form__field--checkbox">
									<input type="checkbox" (change)="generate(checkbox)"
										formControlName="auto_generate_pwd" #checkbox />
									<label for="option">Auto-Generated Password</label>
								</div>
							</div>
						</div>
						<div class="form__row" *ngIf="operation != 'Edit' || showPassword == true">
							<label for="" class="form__label">Password</label>
							<div class="form__field">
								<div class="input-group" id="show_hide_password">
									<input  type="password" formControlName="password" class="form-control"/>
									<button type="button" class="btn btn-secondary" (click)="$event.preventDefault()" appPasswordToggle>
									  <span [ngClass]="password_type == 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'"></span>
									</button>
								  </div>
							</div>
							<!-- <div *ngIf="password.touched && password.invalid" class="message message--is-invalid">
								<div *ngIf="password.errors.required">This field is required.</div>
								<div *ngIf="password.errors.password && !password.errors.required">Password should be
									minimum 8 characters, at
									least one capital letter and at least one small letter.</div>
							</div> -->
							<div *ngIf="!doctorForm.get('auto_generate_pwd').value">
								<p class="password-info-text" [class.passed] = "password.value.length > 7">Password should be minimum of 8 characters</p>
								<p class="password-info-text" [class.passed] = "userService.checkPassword(password.value, 'capital')">Password should include at least one capital letter</p>
								<p class="password-info-text" [class.passed] = "userService.checkPassword(password.value, 'special_char')">Password should include at least one special character</p>
								<p class="password-info-text" [class.passed] = "userService.checkPassword(password.value, 'number')">Password should include at least one number</p>
								<p class="password-info-text" [class.passed] = "!userService.checkPassword(password.value, 'repetitive_char')">Password should not include repetitive characters </p>
								<p class="password-info-text" [class.passed] = "!userService.checkPassword(password.value, 'sequential_character')">Password should not include sequential characters </p>
								<p class="password-info-text" [class.passed] = "!userService.checkPassword(password.value, 'sequential_number')">Password should not include sequential number</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="hr hr--space-down-2x hr--space-up-2x"></div>
			<div class="form--two-cols">
				<div class="row">
					<div class="col col__md-6 gutter--right-3x">
						<div formGroupName="doctor_info">
							<div class="form__row">
								<label for="" class="form__label">Degree</label>
								<div class="mdDegree form__field is-sarch-input">
									<input type="text" auto-complete [source]="degreeList"
										[list-formatter]="suggestionListDegree"
										[value-formatter]="valueListFormatterDegree.bind(this)"
										[re-focus-after-select]="false" [disabled]="isMdManager" #degreeField />
									<div class="message message--is-invalid"
										*ngIf="doctor_degrees.invalid && validateDegree">
										Atleast one degree should be added.
									</div>
									<div class="search-list auto-height" formArrayName="doctor_degrees">
										<div class="search-item" *ngFor="let degree of doctor_degrees.controls">
											{{degree.value.degree_name}}
											<span (click)="	removeDegree(degree)" *ngIf='!isMdManager'>X</span>
										</div>
									</div>
								</div>

							</div>
							<div class="form__row" formGroupName="doctor_titles">
								<div class="form__field">
									<label for="" class="form__label">Title 1</label>
									<input type="text" formControlName="title_one" [appFormatOnly]='"trimonly"' />
									<div *ngIf="title_one.touched && title_one.invalid"
										class="message message--is-invalid">
										<div *ngIf="title_one.errors.required">This field is required.</div>
										<div *ngIf="title_one.errors.maxlength">Title 1 must not exceed
											{{title_one.errors.maxlength.requiredLength}}
											characters.</div>
									</div>
								</div>
							</div>
							<div class="form__row" formGroupName="doctor_titles">
								<div class="form__field">
									<label for="" class="form__label">Title 2</label>
									<input type="text" formControlName="title_two" [appFormatOnly]='"trimonly"' />
									<div *ngIf="title_two.touched && title_two.invalid"
										class="message message--is-invalid">
										<div *ngIf="title_two.errors.required">This field is required.</div>
										<div *ngIf="title_two.errors.maxlength">Title 2 must not exceed
											{{title_two.errors.maxlength.requiredLength}}
											characters.</div>
									</div>
								</div>
							</div>
							<div class="form__row" formGroupName="doctor_titles">
								<div class="form__field">

									<label for="" class="form__label">Title 3</label>
									<input type="text" formControlName="title_three" [appFormatOnly]='"trimonly"' />
									<div *ngIf="title_three.touched && title_three.invalid"
										class="message message--is-invalid">
										<div *ngIf="title_three.errors.required">This field is required.</div>
										<div *ngIf="title_three.errors.maxlength">Title 3 must not exceed
											{{title_three.errors.maxlength.requiredLength}} characters.</div>
									</div>
								</div>

							</div>
						</div>
						<div class="form__row">
							<!---------------- New Client Selection ------------------>
							<div class="form__row">
								<label for="" class="form__label">Clients</label>
								<client-dropdown-tree-view [items]="items"
									(valueChange)="onClientSelectChanged($event)" (resetClients)="resetClients($event)">
								</client-dropdown-tree-view>
								<div class="message message--is-invalid"
									*ngIf="!validatePartner">
									Atleast one client should be added.
								</div>
							</div>
							<!---------------- New Client Selection End Here------------------>
						</div>
					</div>
					<div class="col col__md-6">
						<div class="form__row">
							<label for="" class="form__label">Languages Spoken</label>
							<div class="mdLanguage form__field is-sarch-input">
								<input type="text" auto-complete [source]="languagesList"
									[list-formatter]="suggestionListFormatter" [disabled]="isMdManager"
									[value-formatter]="valueListFormatter.bind(this)" [re-focus-after-select]="false"
									#languageField />
								<div class="message message--is-invalid"
									*ngIf="user_languages.invalid && validateLanguage">
									Atleast one language should be added.
								</div>
								<div class="search-list  auto-height" formArrayName="user_languages">
									<div class="search-item" *ngFor="let language of user_languages.controls">
										{{language.value.language}}
										<span (click)="	removeLanguages(language)" *ngIf='!isMdManager'>X</span>
									</div>
								</div>
							</div>
						</div>
						<div class="form__row" formGroupName="doctor_info">
							<label for="" class="form__label">Website</label>
							<div class="form__field">
								<input type="text" formControlName="website" [appFormatOnly]='"trimonly"' />
								<div *ngIf="website.touched && website.invalid" class="message message--is-invalid">
									<div *ngIf="website.errors.required">This field is required.</div>
									<div *ngIf="website.errors.maxlength">Website must not exceed
										{{website.errors.maxlength.requiredLength}}
										characters.</div>
								</div>
							</div>
						</div>
						<div class="form__row" formGroupName="doctor_info">
							<label for="" class="form__label">Institution *</label>
							<div class="form__field">
								<input type="text" formControlName="institution" [appFormatOnly]='"trimonly"' />
								<div *ngIf="institution.touched && institution.invalid"
									class="message message--is-invalid">
									<div *ngIf="institution.errors.required">This field is required.</div>
									<div *ngIf="institution.errors.maxlength">Institution must not exceed
										{{institution.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>
						<div class="form__row" formGroupName="doctor_info">
							<label for="" class="form__label">Previous Employer</label>
							<div class="form__field">
								<input type="text" formControlName="previous_employer" [appFormatOnly]='"trimonly"' />
								<div *ngIf="previous_employer.touched && previous_employer.invalid"
									class="message message--is-invalid">
									<div *ngIf="previous_employer.errors.required">This field is required.</div>
									<div *ngIf="previous_employer.errors.maxlength">Previous Employer must not exceed
										{{previous_employer.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="hr hr--space-down-2x hr--space-up-2x"></div>
			<h4 class="form__subtitle">Doctor Availaibility</h4>
			<div class="form--two-cols">
				<div class="row">
					<div class="col col__md-6 gutter--right-3x">
						<div class="form__row">
							<label for="doctor_availability" class="form__label">Availability</label>
							<div class="form__field">
								<select id="doctor_availability" formControlName="availability">
									<option value="">Select</option>
									<option value="HIGH_AVAILABILITY">High Availability</option>
									<option value="MEDIUM_AVAILABILITY">Medium Availability</option>
									<option value="LOW_AVAILABILITY">Low Availability</option>
								</select>
							</div>
							<div *ngIf="(doctorForm.get('availability').touched && doctorForm.get('availability').invalid)"
								class="message message--is-invalid">
								<div *ngIf="doctorForm.get('availability').errors.required">This field is required.
								</div>
							</div>
						</div>
					</div>
					<div class="col col__md-6 gutter--right-3x">
						<div class="form__row">
							<label for="" class="form__label">Note</label>
							<div class="form__field">
								<textarea formControlName="note" maxlength="5000"></textarea>
								<div *ngIf="doctorForm.get('note').touched && doctorForm.get('note').invalid"
									class="message message--is-invalid">
									<div *ngIf="doctorForm.get('note').errors.required">This field is required.</div>
									<div *ngIf="doctorForm.get('note').errors.maxlength">Cannot enter more than 5000
										characters</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="hr hr--space-down-2x hr--space-up-2x"></div>

			<h3 class="form__title">Details</h3>

			<div class="form--two-cols">
				<div class="row">
					<div class="col col__md-6 gutter--right-3x">
						<div class="form__row" formGroupName="doctor_info">
							<label for="" class="form__label">Bio *</label>
							<div class="form__field">
								<textarea formControlName="bio" [appFormatOnly]='"trimonly"'></textarea>
								<div *ngIf="bio.touched && bio.invalid" class="message message--is-invalid">
									<div *ngIf="bio.errors.required">This field is required.</div>
									<div *ngIf="bio.errors.maxlength">Bio must not exceed
										{{bio.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>
						<div class="form__row" formGroupName="doctor_info">
							<label for="" class="form__label">Sub-Specialty</label>
							<div class="mdSubSpeciality form__field is-sarch-input">
								<input type="text" auto-complete [source]="subSpecialityList"
									[list-formatter]="suggestionListCategoryField" [disabled]="isMdManager"
									[value-formatter]="valueListFormatterCategoryField.bind(this)"
									[re-focus-after-select]="false" #subSpecialityField />
								<div class="message message--is-invalid"
									*ngIf="doctor_sub_speciality.invalid && validateSubspeciality">
									Atleast one specialty should be added.
								</div>
								<div class="search-list auto-height" formArrayName="doctor_sub_speciality">
									<div class="search-item"
										*ngFor="let sub_speciality of doctor_sub_speciality.controls">
										{{sub_speciality.value.category_field}}
										<span (click)="	removeCategoryField(sub_speciality)"
											*ngIf='!isMdManager'>X</span>
									</div>
								</div>
							</div>


						</div>
						<div class="form__row">
							<label for="" class="form__label">Doctor Status</label>
							<div class="form__field checkbox--in-row">
								<div class="form__field--checkbox">
									<input type="checkbox" formControlName="director"
										(change)="changeDoctorStatus(checkbox1,'Director')" #checkbox1 />
									<label for="option">Director</label>
								</div>
								<div class="form__field--checkbox">
									<input type="checkbox" formControlName="chief"
										(change)="changeDoctorStatus(checkbox2,'Chief')" #checkbox2 />
									<label for="option">Chief</label>
								</div>

							</div>
						</div>
						<div class="form__row" formGroupName="doctor_info"
							*ngIf="userType.toLowerCase() === 'careteam' || userType.toLowerCase() === 'admin'">
							<label for="" class="form__label">Note</label>
							<div class="form__field">
								<textarea formControlName="doctor_note" [appFormatOnly]='"trimonly"'
									maxlength="50000"></textarea>
								<div *ngIf="doctor_note.touched && doctor_note.invalid"
									class="message message--is-invalid">
									<div *ngIf="doctor_note.errors.maxlength">Note must not exceed
										{{doctor_note.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>
						<div class="form__row" formGroupName="doctor_info" style="display:none">
							<div class="form__field checkbox--in-row">
								<div class="form__field--checkbox">
									<input type="checkbox" [checked]="true" formControlName="publicly_listed" />
									<label for="option">Publicly Listed</label>
								</div>
							</div>
						</div>
					</div>
					<div class="col col__md-6">
						<div class="form__row" formGroupName="doctor_info">
							<label for="" class="form__label">Field of Medicine</label>
							<div class="form__field">
								<select formControlName="category_id"
									(change)="changeSubSpeciality($event.target.value)">
									<option *ngFor="let cat of categoryList" [value]="cat.category_id">{{cat.category}}
									</option>
								</select>
								<div *ngIf="category_id.touched && category_id.invalid"
									class="message message--is-invalid">
									<div *ngIf="category_id.errors.required">This field is required.</div>
									<div *ngIf="category_id.errors.maxlength">Field of medicine must not exceed
										{{category_id.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>
						<div class="form__row" formGroupName="doctor_info">
							<label for="" class="form__label">MD Type *</label>
							<div class="mdType form__field is-sarch-input">
								<input type="text" auto-complete [source]="mdTypesList"
									[list-formatter]="suggestionListMdType" [disabled]="isMdManager"
									[value-formatter]="valueListFormatterMdType.bind(this)"
									[re-focus-after-select]="false" #mdTypeField />
								<div class="message message--is-invalid"
									*ngIf="doctor_md_types.invalid && validateMdType">
									Atleast one md type should be added.
								</div>
								<div class="search-list auto-height" formArrayName="doctor_md_types">
									<div class="search-item" *ngFor="let mdType of doctor_md_types.controls">
										{{mdType.value.md_type}}
										<span (click)="	removeMdType(mdType)" *ngIf='!isMdManager'>X</span>
									</div>
								</div>
							</div>
						</div>
						<div class="form__row" formGroupName="doctor_info">
							<label for="" class="form__label">Board Certifications</label>
							<div class="mdCertification form__field is-sarch-input">
								<input type="text" auto-complete [source]="certificationList"
									[list-formatter]="suggestionListCertification" [disabled]="isMdManager"
									[value-formatter]="valueListFormatterCertification.bind(this)"
									[re-focus-after-select]="false" #certificationField />
								<div class="message message--is-invalid"
									*ngIf="doctor_certifications.invalid && validateCertification">
									Atleast one certification should be added.
								</div>
								<div class="search-list auto-height" formArrayName="doctor_certifications">
									<div class="search-item"
										*ngFor="let certification of doctor_certifications.controls">
										{{certification.value.certification}}
										<span (click)="	removeCertification(certification)"
											*ngIf='!isMdManager'>X</span>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			<div class="hr hr--space-down-2x hr--space-up-2x"></div>

			<h3 class="form__title">Background</h3>
			<div class="form--two-cols">
				<div class="row">
					<div class="col col__md-6 gutter--right-3x">
						<div class="form__row">
							<label for="" class="form__label">Research / Interests *</label>
							<div class="form__field" formGroupName="doctor_info">
								<textarea formControlName="research_interest"></textarea>
								<div *ngIf="research_interest.touched && research_interest.invalid"
									class="message message--is-invalid">
									<div *ngIf="research_interest.errors.required">This field is required.</div>
									<div *ngIf="research_interest.errors.maxlength">Research / Interests must not exceed
										{{research_interest.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>

						<div class="form__row">
							<label for="" class="form__label">Affiliated Hospital</label>
							<div class="form__field" formGroupName="doctor_info">
								<input type="text" formControlName="affiliated_hospital" [appFormatOnly]='"trimonly"' />
								<div *ngIf="affiliated_hospital.touched && affiliated_hospital.invalid"
									class="message message--is-invalid">
									<div *ngIf="affiliated_hospital.errors.required">This field is required.</div>
									<div *ngIf="affiliated_hospital.errors.maxlength">Affiliated Hospital must not
										exceed
										{{affiliated_hospital.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>

					</div>
					<div class="col col__md-6" formGroupName="doctor_info">
						<div class="form__row">
							<label for="" class="form__label">Conditions</label>

							<div class="mdCondition form__field is-sarch-input">
								<input type="text" auto-complete [source]="conditionList"
									[list-formatter]="suggestionListCondition" [disabled]="isMdManager"
									[value-formatter]="valueListFormatterCondition.bind(this)"
									[re-focus-after-select]="false" #conditionField />
								<div class="message message--is-invalid"
									*ngIf="doctor_conditions.invalid && validateCondition">
									Atleast one condition should be added.
								</div>
								<div class="search-list auto-height" formArrayName="doctor_conditions">
									<div class="search-item" *ngFor="let condition of doctor_conditions.controls">
										{{condition.value.condition}}
										<span (click)="	removeCondition(condition)" *ngIf='!isMdManager'>X</span>
									</div>
								</div>
							</div>

						</div>
						<div class="form__row">
							<label for="" class="form__label">Procedural Expertise</label>
							<div class="form__field">
								<input type="text" formControlName="procedures" [appFormatOnly]='"trimonly"' />
								<div *ngIf="procedures.touched && procedures.invalid"
									class="message message--is-invalid">
									<div *ngIf="procedures.errors.required">This field is required.</div>
									<div *ngIf="procedures.errors.maxlength">Procedural Expertise must not exceed
										{{procedures.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
							<!-- <div class="form__field is-sarch-input">
								<input type="text" auto-complete [source]="procedureList" [list-formatter]="suggestionListProcedure" [value-formatter]="valueListFormatterProcedure.bind(this)"
								 [re-focus-after-select]="false" #procedureField/>
								<div class="message message--is-invalid" *ngIf="doctor_procedures.invalid">
									Atleast one procedure should be added.
								</div>
								<div class="search-list auto-height" formArrayName="doctor_procedures">
									<div class="search-item" *ngFor="let procedure of doctor_procedures.controls">{{procedure.value.procedure}}
										<span (click)="	removeProcedure(procedure)">X</span>
									</div>
								</div>
							</div> -->
						</div>
					</div>
				</div>
			</div>

			<div class="hr hr--space-down-2x hr--space-up-x"></div>
			<h3 class="form__title">Education</h3>
			<div class="form--two-cols">
				<div class="row">
					<div class="col col__md-6 gutter--right-3x border-right" formGroupName="doctor_info">
						<div class="form__row">
							<label for="" class="form__label">Medical School *</label>
							<div class="form__field">
								<input type="text" formControlName="medical_school" [appFormatOnly]='"trimonly"' />
								<div *ngIf="medical_school.touched && medical_school.invalid"
									class="message message--is-invalid">
									<div *ngIf="medical_school.errors.required">This field is required.</div>
									<div *ngIf="medical_school.errors.maxlength">Medical School must not exceed
										{{medical_school.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>
						<div _ngcontent-c6="" class="hr hr--space-down-2x hr--space-up-2x"></div>
						<div class="form__row">
							<label for="" class="form__label">Residency *</label>
							<div class="form__field">
								<input type="text" formControlName="residency" [appFormatOnly]='"trimonly"' />
								<div *ngIf="residency.touched && residency.invalid" class="message message--is-invalid">
									<div *ngIf="residency.errors.required">This field is required.</div>
									<div *ngIf="residency.errors.maxlength">Residency must not exceed
										{{residency.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>
						<div class="form__row">
							<label for="" class="form__label">Residency 2</label>
							<div class="form__field">
								<input type="text" formControlName="residency_two" [appFormatOnly]='"trimonly"' />
								<div *ngIf="residency_two.touched && residency_two.invalid"
									class="message message--is-invalid">
									<div *ngIf="residency_two.errors.required">This field is required.</div>
									<div *ngIf="residency_two.errors.maxlength">Residency 2 must not exceed
										{{residency_two.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>
						<div _ngcontent-c6="" class="hr hr--space-down-2x hr--space-up-2x"></div>
						<div class="form__row">
							<label for="" class="form__label">Internship</label>
							<div class="form__field">
								<input type="text" formControlName="internship" [appFormatOnly]='"trimonly"' />
								<div *ngIf="internship.touched && internship.invalid"
									class="message message--is-invalid">
									<div *ngIf="internship.errors.required">This field is required.</div>
									<div *ngIf="internship.errors.maxlength">Internship must not exceed
										{{internship.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>
						<div class="form__row">
							<label for="" class="form__label">Internship 2</label>
							<div class="form__field">
								<input type="text" formControlName="internship_two" [appFormatOnly]='"trimonly"' />
								<div *ngIf="internship_two.touched && internship_two.invalid"
									class="message message--is-invalid">
									<div *ngIf="internship_two.errors.required">This field is required.</div>
									<div *ngIf="internship_two.errors.maxlength">Internship 2 must not exceed
										{{internship_two.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col col__md-6 fellowship-margin-top" formGroupName="doctor_info">
						<div class="form__row">
							<label for="" class="form__label">Fellowship</label>
							<div class="form__field">
								<input type="text" formControlName="fellowship" [appFormatOnly]='"trimonly"' />
								<div *ngIf="fellowship.touched && fellowship.invalid"
									class="message message--is-invalid">
									<!-- <div *ngIf="fellowship.errors.required">This field is required.</div> -->
									<div *ngIf="fellowship.errors.maxlength">Fellowship must not exceed
										{{fellowship.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>
						<div class="form__row">
							<label for="" class="form__label">Fellowship 2</label>
							<div class="form__field">
								<input type="text" formControlName="fellowship_two" [appFormatOnly]='"trimonly"' />
								<div *ngIf="fellowship_two.touched && fellowship_two.invalid"
									class="message message--is-invalid">
									<div *ngIf="fellowship_two.errors.required">This field is required.</div>
									<div *ngIf="fellowship_two.errors.maxlength">Fellowship 2 must not exceed
										{{fellowship_two.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="userType.toLowerCase() != 'doctor'" class="hr hr--space-down-2x hr--space-up-2x"></div>
			<h3 *ngIf="userType.toLowerCase() != 'doctor'" class="form__title">Cost Center</h3>
			<div *ngIf="userType.toLowerCase() != 'doctor'" class="form--two-cols" formGroupName="doctor_info">
				<div class="row">
					<div class="col col__md-6 gutter--right-3x">
						<div class="form__row">
							<label for="" class="form__label">Rate per Hour</label>
							<div class="form__field">
								<input type="number" formControlName="rate_per_hour" NumbersOnly />
								<div *ngIf="rate_per_hour.touched && rate_per_hour.invalid"
									class="message message--is-invalid">
									<div *ngIf="rate_per_hour.errors.required">This field is required.</div>
									<div *ngIf="rate_per_hour.errors.maxlength">Rate per Hour must not exceed
										{{rate_per_hour.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col col__md-6">
						<div class="form__row">
							<label for="" class="form__label">Aquisition</label>
							<div class="form__field">
								<select formControlName="aquisition_id">
									<option *ngFor="let aquisition of aquisitionList"
										value="{{aquisition.aquisition_id}}">{{aquisition.aquisition}}</option>
								</select>
								<div *ngIf="aquisition_id.touched && aquisition_id.invalid"
									class="message message--is-invalid">
									<div *ngIf="aquisition_id.errors.required">This field is required.</div>
									<div *ngIf="aquisition_id.errors.maxlength">Aquisition must not exceed
										{{aquisition_id.errors.maxlength.requiredLength}} characters.</div>
								</div>

							</div>
						</div>
						<div class="form__row">
							<label for="" class="form__label">Referred By</label>
							<div class="form__field">
								<input type="text" formControlName="referred_by" [appFormatOnly]='"trimonly"' />
								<div *ngIf="referred_by.touched && referred_by.invalid"
									class="message message--is-invalid">
									<div *ngIf="referred_by.errors.required">This field is required.</div>
									<div *ngIf="referred_by.errors.maxlength">Reffered By must not exceed
										{{referred_by.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<!-- Additional Fields added To sync with Sales Force -->
			<div class="hr hr--space-down-2x hr--space-up-x"></div>
			<h3 class="form__title">Additional Fields</h3>
			<div class="form--two-cols">
				<div class="row">
					<div class="col col__md-6 gutter--right-3x">
						<div class="form__row" formGroupName="doctor_info">
							<label for="" class="form__label">Care Philosophy</label>
							<div class="form__field">
								<input type="text" formControlName="care_philosophy" [appFormatOnly]='"trimonly"' />
								<div *ngIf="carePhilosophy.touched && carePhilosophy.invalid"
									class="message message--is-invalid">
									<div *ngIf="carePhilosophy.errors.required">This field is required.</div>
									<div *ngIf="carePhilosophy.errors.maxlength">Care Philosophy must not exceed
										{{carePhilosophy.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>
						<div class="form__row" formGroupName="doctor_info">
							<label for="" class="form__label">Category *</label>
							<div class="form__field">
								<input type="text" formControlName="category_value" [appFormatOnly]='"trimonly"' />
								<div *ngIf="categoryString.touched && categoryString.invalid"
									class="message message--is-invalid">
									<div *ngIf="categoryString.errors.required">This field is required.</div>
									<div *ngIf="categoryString.errors.maxlength">Category must not exceed
										{{categoryString.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>
						<div class="form__row" formGroupName="doctor_info">
							<label for="" class="form__label">Conditions *</label>
							<div class="form__field">
								<input type="text" formControlName="conditions" [appFormatOnly]='"trimonly"' />
								<div *ngIf="conditionString.touched && conditionString.invalid"
									class="message message--is-invalid">
									<div *ngIf="conditionString.errors.required">This field is required.</div>
									<div *ngIf="conditionString.errors.maxlength">Conditions must not exceed
										{{conditionString.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col col__md-6">
						<div class="form__row" formGroupName="doctor_info">
							<label for="" class="form__label">Degrees *</label>
							<div class="form__field">
								<input type="text" formControlName="degrees" [appFormatOnly]='"trimonly"' />
								<div *ngIf="degreeString.touched && degreeString.invalid"
									class="message message--is-invalid">
									<div *ngIf="degreeString.errors.required">This field is required.</div>
									<div *ngIf="degreeString.errors.maxlength">Degrees must not exceed
										{{degreeString.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>
						<div class="form__row" formGroupName="doctor_info">
							<label for="" class="form__label">Titles *</label>
							<div class="form__field">
								<input type="text" formControlName="titles" [appFormatOnly]='"trimonly"' />
								<div *ngIf="titleString.touched && titleString.invalid"
									class="message message--is-invalid">
									<div *ngIf="titleString.errors.required">This field is required.</div>
									<div *ngIf="titleString.errors.maxlength">Titles must not exceed
										{{titleString.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>
						<div class="form__row" formGroupName="doctor_info">
							<label for="" class="form__label">Languages *</label>
							<div class="form__field">
								<input type="text" formControlName="languages" [appFormatOnly]='"trimonly"' />
								<div *ngIf="languageString.touched && languageString.invalid"
									class="message message--is-invalid">
									<div *ngIf="languageString.errors.required">This field is required.</div>
									<div *ngIf="languageString.errors.maxlength">Titles must not exceed
										{{languageString.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</form>


	</div>
	<div class="btn-row text-center">
		<a href="#" class="btn btn--green" (click)="useSupportPassword($event)" *ngIf="isSupportPassword">Use
			Support Password</a>
		<a href="#" class="btn btn--green" (click)="recoverPassword($event)" *ngIf="isRecoverPassword">Recover
			Password</a>
		<a href="#" class="btn btn--green" (click)="resetPassword($event, link)" *ngIf="isResetPassword && currentUser.user_id != userId" #link>Reset
			Password</a>
			<a href="#" class="btn btn--green" [routerLink]="['/change-password']" *ngIf="isResetPassword && currentUser.user_id == userId" #link>Change Password</a>
		<a  (click)="validateForm()" class="btn btn--green" *ngIf='!isMdManager'>{{addUpdateLabel}}</a>

			<swal #addDoctorSwal title="Confirmation" text="Are you sure you want to {{addUpdateLabel}}?"
			(confirm)="addDoctor(doctorForm)" closeOnConfirm="true" closeOnCancel="true" backdrop="#eef3f6" cancelButtonColor="#db161e"
			reverseButtons="true" showCancelButton="true" confirmButtonColor="green" confirmButtonText="{{addUpdateLabel}} Doctor"></swal>

			
		<a href="#" class="btn btn--red" (click)="cancel($event)" *ngIf="operation.toLowerCase() === 'edit'">Cancel</a>
	</div>
</div>
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CaseModel } from '../../../domains/case/CaseModel';
// import { MailServerService } from '../../../services/mail-server.service';
import { PopUpService } from '../../../services/pop-up.service';
import { CaseService } from '../../../services/case.service';
import { LoginService } from '../../../services/login.service';
import { CommonService } from '../../../services/common.service';

@Component({
  selector: 'app-view-case',
  templateUrl: './view-case.component.html',
  styleUrls: ['./view-case.component.css']
})
export class ViewCaseComponent implements OnInit {
  caseId: number;
  isInprogress = false;
  caseObj: CaseModel;
  isLoading: boolean;
  caseDetails: any;
  patientList: any;
  patient: any

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private popUpService: PopUpService,
    private caseService: CaseService,
    private loginService: LoginService,
    private commonService: CommonService,
  ) {
    this.activatedRoute.params.subscribe(
      params => {
        const { id } = params;
        this.caseId = id;
        this.getCaseDetail(id);
      }
    );
  }

  getCaseDetail(_caseid: number): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const user = this.loginService.getCurrentUser();
    this.isLoading = true;
    this.caseService.getCaseDetail(this.caseId).subscribe(
      result => {
        this.caseDetails = result;
        this.caseDetails.surgical_recommendation = JSON.parse(result.surgical_recommendation);
        this.getPatients();
        this.isLoading = false;
      },
      error => {
        const action = function () {
          this.router.navigate(['/technical/case']);
          this.popUpService.hideDiv('alertDialog');
        }.bind(this);

        if (error && error.status === 401) {
          this.showAlert('The case cannot be accessed.', action);
        }
        else {
          this.showAlert('Error while getting the case.', action);
        }

        this.isLoading = false;
        this.caseDetails = null;
      }
    );
  }

  ngOnInit(): void {
  }

  getPatients() {
    this.commonService.getPatients().subscribe(
      patients => {
        this.patientList = patients;
        this.patient = patients.filter(patient => patient.patient_id === this.caseDetails.patient_id);
      }, error => {
        console.log('error :>> ', error);
      }
    );
  }

  callUpdateAction(): void {
    const postiveAction = function(): void {
      this.popUpService.hideDiv('alertDialog');
    }.bind(this);

    this.isLoading = true;
    if(this.caseDetails.case_status === 'CANCELLED'){
      this.caseDetails['case_status'] = 'REQUESTED';
    }
    else{
      this.caseDetails['case_status'] = 'CANCELLED';
    }
    this.caseService.updateCaseDetail(this.caseId, this.caseDetails).subscribe(
      (_result) => {
        this.isLoading = false;
        const message = 'Case status changed!';
        this.showAlert(message, postiveAction);
      },
      (_error) => {
        this.isLoading = false;
        this.showAlert('Failed to change case status.');
      }
    );
  }

  updateCaseStatus(event: Event): void {
    event.preventDefault();

    const message = 'Are you sure you want to change the status?';
    const postiveAction = function(): void {
      this.callUpdateAction();
      this.popUpService.hideDiv('confirmDialog');
    }.bind(this);
    const negativeAction = function(): void {
      this.popUpService.hideDiv('confirmDialog');
    }.bind(this);

    this.showConfirmation(message, postiveAction, negativeAction);
  }

  /** Function to show the confirmation dialog
   * It needs the <app-dialog> component to be loaded 
   */
  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function): void {
    this.popUpService.destoryConfirmDialog();
  
    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );
  
    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }
    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }
  
    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);
  }

  /** Function to show the confirmation dialog
   * It needs the <app-dialog> component to be loaded 
   */
  private showAlert(message: string, action?: Function): void {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (!action) {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }
    else {
      this.popUpService.setPositiveAction(
        action
      );
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) { this.popUpService.showDiv(dialog); }
  }

}

<app-dialog></app-dialog>
<div class="header">
	<div class="header__page-name">
		<h1 class="heading heading--main">Change Password</h1>
	</div>
</div>
<div class="wrapper--dash-content dash-content">
	<form [formGroup]="adminForm">
		<div class="form tabs__content--white-bg">
			<div class="form--two-cols">
				<div class="row">
					<div class="col col__md-6 gutter--right-3x">
						<div class="form__row">
							<label for="" class="form__label">Current Password</label>
							<div class="form__field col__md-6">
								<div class="input-group" id="show_hide_password">
									<input type="password" id="currentPassword" formControlName="currentPassword" class="form-control"/>
									<button type="button" class="btn btn-secondary" (click)="$event.preventDefault()" appPasswordToggle>
									  <span [ngClass]="confirm_password_type == 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'"></span>
									</button>
							
								</div>
							</div>
						</div>

						<div class="form__row">
							<label for="" class="form__label">Password</label>
							<div class="form__field col__md-6">
								<div class="input-group" id="show_hide_password">
									<input type="password" id="password" formControlName="newPassword" class="form-control" />
									<button type="button" class="btn btn-secondary" (click)="$event.preventDefault()" appPasswordToggle >
									  <span [ngClass]="password_type == 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'"></span>
									</button>
								  </div>
								<div class="message message--is-invalid">
									<p class="password-info-text" [class.passed] = "newPassword.value.length > 7">Password should be minimum of 8 characters</p>
									<p class="password-info-text" [class.passed] = "userService.checkPassword(newPassword.value, 'capital')">Password should include at least one capital letter</p>
									<p class="password-info-text" [class.passed] = "userService.checkPassword(newPassword.value, 'special_char')">Password should include at least one special character</p>
									<p class="password-info-text" [class.passed] = "userService.checkPassword(newPassword.value, 'number')">Password should include at least one number</p>
									<p class="password-info-text" [class.passed] = "!userService.checkPassword(newPassword.value, 'repetitive_char')">Password should not include repetitive characters </p>
									<p class="password-info-text" [class.passed] = "!userService.checkPassword(newPassword.value, 'sequential_character')">Password should not include sequential characters </p>
									<p class="password-info-text" [class.passed] = "!userService.checkPassword(newPassword.value, 'sequential_number')">Password should not include sequential number</p>
								
								</div>
							</div>
						</div>
                        <div class="form__row">
							<label for="" class="form__label">Confirm Password</label>
							<div class="form__field col__md-6">
								<div class="input-group" id="show_hide_password">
									<input type="password" id="password" formControlName="confirmPassword" class="form-control"/>
									<button type="button" class="btn btn-secondary" (click)="$event.preventDefault()" appPasswordToggle>
									  <span [ngClass]="confirm_password_type == 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'"></span>
									</button>
								  </div>

								<div class="message message--is-invalid">
									<p class="password-info-text" *ngIf="newPassword.value != confirmPassword.value">Should be same as the new password.</p>
									<div *ngIf="this.adminForm.controls['confirmPassword'].invalid && this.adminForm.controls['confirmPassword'].dirty || this.adminForm.controls['confirmPassword'].touched">
										<p class="alert alert-danger alert-dismissible fade show mt-5" role="alert" *ngIf="showError"><b>Error : </b>{{message}}</p>
									</div>
								
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<br><br>
		</div>

		<div class="btn-row text-center">
			<a href="#" class="btn btn--green" (click)="changePassword($event)">Save</a>
			<a href="#" class="btn btn--red" (click)="cancel($event)">Cancel</a>
		</div>
	</form>
</div>
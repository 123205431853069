<app-dialog></app-dialog>

<div class="wrapper wrapper--login" *ngIf="!isExpired || forcePasswordReset">

	<div class="app-logo">

		<img [src]="logoPath" />

	</div>

	<div class="row login">

		<div class="col col__md-5 login__form">

			<div class="login__form-content">

				<h2 class="heading heading--main heading--login"></h2>

				<div class="form">

					<form (submit)="changePassword()" [formGroup]="resetForm">

						<div class="form__row">

							<label class="form__label" for="newPassword">New Password</label>

							<div class="form__field">

								<div class="input-group" id="show_hide_password">
									<input type="password"  id="newPassword" name="newPassword"   [appFormatOnly]='"trimonly"' formControlName="newPassword" class="form-control validation"/>
									<button type="button" class="btn btn-secondary" (click)="$event.preventDefault()" appPasswordToggle>
									  <span [ngClass]="new_password_type == 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'"></span>
									</button>
								  </div>


								<!-- <div *ngIf="newPassword.touched && newPassword.invalid" class="message message--is-invalid">
									<div *ngIf="newPassword.errors.required">Password is a required field.</div>
									<div *ngIf="newPassword.errors.minlength && !newPassword.errors.required">Password must have more than 8 characters.</div>
									<div *ngIf="newPassword.errors.password  && !newPassword.errors.minlength && !newPassword.errors.required">Password must have more than 8 characters, a Upper-case character and a lower-case character.</div>
								</div> -->

								<div class="mt-1">
									<p class="password-info-text" [class.passed] = "newPassword.value.length > 7">Password should be minimum of 8 characters</p>
									<p class="password-info-text" [class.passed] = "userService.checkPassword(newPassword.value, 'capital')">Password should include at least one capital letter</p>
									<p class="password-info-text" [class.passed] = "userService.checkPassword(newPassword.value, 'special_char')">Password should include at least one special character</p>
									<p class="password-info-text" [class.passed] = "userService.checkPassword(newPassword.value, 'number')">Password should include at least one number</p>
									<p class="password-info-text" [class.passed] = "!userService.checkPassword(newPassword.value, 'repetitive_char')">Password should not include repetitive characters </p>
									<p class="password-info-text" [class.passed] = "!userService.checkPassword(newPassword.value, 'sequential_character')">Password should not include sequential characters </p>
									<p class="password-info-text" [class.passed] = "!userService.checkPassword(newPassword.value, 'sequential_number')">Password should not include sequential number</p>
								</div>

							</div>

						</div>

						<div class="form__row">

							<label class="form__label" for="confirmPassword">Confirm Password</label>

							<div class="form__field">

								<div class="input-group" id="show_hide_password">
									<input type="password" name="confirmPassword" id="confirmPassword" formControlName="confirmPassword"  [appFormatOnly]='"trimonly"' class="form-control validation" />
									<button type="button" class="btn btn-secondary" (click)="$event.preventDefault()" appPasswordToggle>
									  <span [ngClass]="confirm_password_type == 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'"></span>
									</button>
								  </div>

								<div *ngIf="confirmPassword.touched && confirmPassword.invalid" class="message message--is-invalid">

									<div *ngIf="confirmPassword.errors.required">Confirm password is a required field.</div>

									<div *ngIf="confirmPassword.errors.minlength">Password must have more than 8 characters.</div>

								</div>



							</div>

						</div>

						<div class="form__row">

							<input type="submit" class="btn btn--green btn--full-width" value="Reset Password" />

						</div>

						<p class="alert alert-danger alert-dismissible fade show mt-5" role="alert" *ngIf="showError"><b>Error : </b>{{message}}
					</form>

				</div>

			</div>

		</div>

		<div class="col col__md-7 login__welcome">
			<div class="login__welcome-content">
				<h1 class="heding heading--welcome">Reset Password</h1>
				<p>Your updated password will be expire after 90 days</p>
			</div>
		</div>

	</div>

</div>
<app-dialog></app-dialog>
<div class="header">
    <div class="header__page-name">
        <div class="row">
            <div class="col-md-3">
                <h1 class="heading heading--main">My Dashboard</h1>
            </div>
            <div class="col-md-6 ">
             
                <div class="container-release-notice-banner" *ngIf="is_visible">
					<div class="col-md-12">
						<div class="row">
							<div class="col-md-1 offset-1">
                                <h4 class="mt-3">
                                    <i class="fa fa-info-circle mr-5 blink_me"></i>
                                    </h4>
							</div>
							<div class="col-md-10" >
								<p >{{subject}}</p>
							</div>
							<!-- <div class="col-md-2" *ngIf="priority">
								<span class="badge badge-green priority">{{priority}}</span>
							</div> -->
							<!-- <div class="col-md-2" *ngIf="content">
								<span [swal]="releaseNotice" id="read-more">Read More</span>
							</div> -->
						</div>
						
					</div>
				</div>
                <!-- <swal #releaseNotice title="{{subject}}" icon="warning" iconColor="red" closeOnConfirm="true"
                    closeOnCancel="true" backdrop="#eef3f6" buttons="false" confirmButtonColor="#DB161E"
                    confirmButtonText="X Close">
                    <swal-text *swalPortal>
                        <hr>
						<div *ngIf="is_update">
							<span class="badge badge-secondary">Updated:</span>
							<p class="text-center mt-2">Updated time: {{update_at | date:'short'}}</p>
						</div>
						<div *ngIf="!is_update">
							<span class="badge badge-secondary">New</span>
							<p class="text-center mt-2">Created time: {{created_at | date:'short'}}</p>
						</div>
						<br>
						 <textarea class="form-control" rows="30" readonly>
							{{content}}
						 </textarea>
						<hr>
                    </swal-text>
                </swal> -->
            </div>
            <div class="col-md-3">
             </div>
        </div>
    </div>

    <div class="header__top-nav">
        <div class="top-menu">
            <div class="top-menu__user-stat">
                <!-- use variable here -->
                <span>Total Cases</span> {{ totalCase }} Cases
            </div>
            <div class="top-menu__menu"></div>
            <div class="top-menu__search">
                <div class="search-wrapper">
                    <div class="search-input-trigger sarch-box">
						<!-- Search -->
						<input id="caseSearch" type="text" [(ngModel)]="searchValue" #searchBox autocomplete="off"/>
						<ul class="search-column" *ngIf="searchValue !== ''">
							<li (click)="currentSearch(column.key,column.value,searchValue)" *ngFor="let column of columnOptions | keyvalue"> <strong><em>{{column.value}}</em></strong> : {{searchValue}}
							</li>
						</ul>
					</div>
                </div>
                <small *ngIf="searchColumnValue !== ''">  <span class="btn btn--small--circle  btn--remove--search" ><strong>{{searchColumnName}}</strong>:	{{searchColumnValue}}<span (click)="clearSearch()"><b>&nbsp; X</b></span> </span></small> 

            </div>
        </div>
        
    </div>
    
</div>


<div class="wrapper--dash-content dash-content overlay__wrapper" >
    <div class="overlay__content">
        <div class="overlay__spinner">
            <span class="icon icon--loading"></span>
        </div>
    </div>
    
    <div class="tabs">
        <div class="col col-12 mt-5 ml-3" >
            <div class="row">
                <div class="col col__md_8"  id="tab-case-container">
                    <div class="tabs__content ">
                        <ul class="tabs__tab tabs__tab--dash-table">
                            <li>
                                <a>Direct Assignment</a>
                            </li>
                        </ul>
            
                        <!-- Direct Assignment  -->
                        <table id="directDatatable" datatable [dtOptions]="dtOptionsDirect"  #activeCasetable>
                            <thead>
                                <tr>
                                    <th>Accept</th>
                                    <th>Decline</th>
                                    <th>Case Id</th>
                                    <th>Status</th>
                                    <th>Type</th>
                                    <th>Patient Name</th>
                                    <th>Diagnosis</th>
                                    <th>Create Date</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="directCases?.length > 0">
                                <tr *ngFor="let directCase of directCases" (click) ="onRowClick(directCase)" class="row__container">
                                    <td>
                                        <div class="follow_up_id tooltip-box" *ngIf="directCase.follow_up_id > 0" > <span class="tooltip-box-text purple__bg">Follow Up {{ directCase.follow_up_id }}+</span> </div>
                                        <a class="btn btn--green"(click)="acceptCase($event ,'ACCEPT', directCase)"> Accept</a>
                                    </td>
                                    <td><a class="btn btn--red"(click)="rejectCase($event, directCase)"> Decline</a></td>
                                    <td>{{ directCase?.case_id }} </td>
                                    <td>{{ directCase?.status }}</td>
                                    <td>
                                        {{ getCaseType(directCase?.case?.case_type) }} 
                                        <span class="badge-small badge-orange" *ngIf="directCase?.case?.speciality_program && directCase?.case?.speciality_program.includes('Peer to Peer') 
                                        || (directCase?.case.reason && directCase?.case.reason.reason.includes('Peer to Peer'))" >P2P</span> 
                                    </td>
                                    <td>{{ directCase?.case?.patient?.user?.full_name }}</td>
                                    <td [innerHtml]="directCase?.case?.patient_diagnosis"></td>
                                    <td>{{ getCaseCreatedAt(directCase?.createdAt) }}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="directCases?.length === 0">
                                <tr>
                                    <td colspan="9" class="no-data-available">No data!</td>
                                </tr>
                            </tbody>
                        </table>
            
                        <ul class="tabs__tab tabs__tab--dash-table">
                            <li>
                                <a>Queue Assignment</a>
                            </li>
                        </ul>
                        <!-- Queue Assignment -->
                        <table datatable [dtOptions]="dtOptionsQueue"  #activeCasetable><thead>
                            <tr>
                                <th>Accept</th>
                                <th>Decline</th>
                                <th>Case Id</th>
                                <th>Status</th>
                                <th>Type</th>
                                <th>Patient Name</th>
                                <th>Diagnosis</th>
                                <th>Create Date</th>
                            </tr>
                            </thead>
                            <tbody *ngIf="optionCases?.length > 0">     
                                <tr *ngFor="let optionCase of optionCases" (click) ="onRowClick(optionCase)" class="row__container">
                                    <td>
                                        <div class="follow_up_id tooltip-box" *ngIf="optionCase.follow_up_id > 0" > <span class="tooltip-box-text purple__bg">Follow Up {{ optionCase.follow_up_id }}+</span> </div>
                                        <a class="btn btn--green"(click)="acceptCase($event ,'ACCEPT', optionCase)"> Accept</a>
                                    </td>
                                    <td><a class="btn btn--red"(click)="rejectCase($event, optionCase)"> Decline</a></td>
                                    <td>{{ optionCase?.case_id }} </td>
                                    <td>{{ optionCase?.status }}</td>
                                    <td>
                                        {{ getCaseType(optionCase?.case?.case_type) }} 
                                        <span class="badge-small badge-orange" *ngIf="optionCase?.case?.speciality_program && optionCase?.case?.speciality_program.includes('Peer to Peer') 
                                        || (optionCase?.case?.reason && optionCase?.case?.reason.reason.includes('Peer to Peer'))" >P2P</span> 
                                    </td>
                                    <td>{{ optionCase?.case?.patient?.user?.full_name }}</td>
                                    <td [innerHtml]="optionCase?.case?.patient_diagnosis"></td>
                                    <td>{{ getCaseCreatedAt(optionCase?.createdAt) }}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="optionCases?.length === 0">
                                <tr>
                                    <td colspan="9" class="no-data-available">No data!</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div  class="col col__md_3" id="tab-faq-container">
                    <app-faqs-template></app-faqs-template>
                </div>
            </div>

        </div>


        <div>
    
    </div>
    </div>  
	<app-reject-case-template [name]="activeCase?.case_id" [hideTemplate]="hideDeleteUserBox" (toggleTemplate)="toggleDeleteTemplate($event)"  [handleValueSubmit]="handleValueSubmit"></app-reject-case-template>
</div>
<div class="box box--white box--radius box--margin-bottom-2x">
    <h3>
        <fa-icon [icon]="faBriefcaseMedical"></fa-icon>
        Case Information</h3>

    <!-- No Cases Asssigned -->
    <h5 *ngIf="false">No any cases assigned !</h5>

    <!-- Total Cases -->
    <div class="hr hr--space-down-x hr--space-up-x"></div>
    <div class="contact-details">
      <div class="profile-card__row">
        <div class="contact-details__label">Total Cases ({{caseList?.totalCases || 0}})</div>
        <div *ngIf="caseList?.totalCases > 0" class="contact-details__details">
          <a [routerLink]="['/user', userId, 'cases']" [queryParams]="{status: 'all'}"><span class="btn btn--green btn--mini">Details</span></a>
        </div>
      </div>
    </div>
    
    <!-- Active Cases -->
    <div *ngIf="caseList?.activeCases?.length > 0" class="hr hr--space-down-x hr--space-up-x"></div>
    <div *ngIf="caseList?.activeCases?.length > 0" class="contact-details">
      <div class="profile-card__row">
        <div class="contact-details__label">Active Cases ({{caseList?.activeCases?.length || 0}})</div>
        <!-- <div class="contact-details__details">
            <a [routerLink]="['/user', userId, 'cases']" [queryParams]="{status: 'requested'}"><span class="btn btn--green btn--mini">Details</span></a>
        </div> -->
      </div>
    </div>

    <!-- Completed Cases -->
    <div *ngIf="caseList?.completedCases?.length > 0" class="hr hr--space-down-x hr--space-up-x"></div>
    <div *ngIf="caseList?.completedCases?.length > 0" class="contact-details">
      <div class="profile-card__row">
        <div class="contact-details__label">Completed Cases ({{caseList.completedCases?.length || 0}})</div>
        <!-- <div class="contact-details__details">
          <a [routerLink]="['/user', userId, 'cases']" [queryParams]="{status: 'finished'}"><span class="btn btn--green btn--mini">Details</span></a>
        </div> -->
      </div>
    </div>

    <!-- Cancelled Cases -->
    <div *ngIf="caseList?.cancelledCases?.length > 0" class="hr hr--space-down-x hr--space-up-x"></div>
    <div *ngIf="caseList?.cancelledCases?.length > 0" class="contact-details">
      <div class="profile-card__row">
        <div class="contact-details__label">Cancelled Cases ({{caseList.cancelledCases?.length || 0}})</div>
        <!-- <div class="contact-details__details">
          <a [routerLink]="['/user', userId, 'cases']" [queryParams]="{status: 'cancelled'}"><span class="btn btn--green btn--mini">Details</span></a>
        </div> -->
      </div>
    </div>

    <!-- Hold Cases -->
    <div *ngIf="caseList?.holdCases?.length > 0" class="hr hr--space-down-x hr--space-up-x"></div>
    <div *ngIf="caseList?.holdCases?.length > 0" class="contact-details">
      <div class="profile-card__row">
        <div class="contact-details__label">Hold Cases ({{caseList.holdCases?.length || 0}})</div>
        <!-- <div class="contact-details__details">
          <a [routerLink]="['/user', userId, 'cases']" [queryParams]="{status: 'hold'}"><span class="btn btn--green btn--mini">Details</span></a>
        </div> -->
      </div>
    </div>
  </div>
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-undef */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-empty-function */
import {takeWhile} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import {
  FormGroup,
  FormControl,
  FormArray
} from '@angular/forms';
import { UserService } from '../../services/userservice.service';

import { CommonFuntions } from '../../common/common.function';
import { PopUpService } from '../../services/pop-up.service';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  operation = 'Default Careteam';
  showSpinner = false;
  alive : boolean;
  isInprogress: boolean;
  careteams = [];
  partnerCareteams = [];
  partnerCareteamList = [];
  IMDCareteamList = [];

  rowSelectedData = [];
  settingForm: FormGroup;
  constructor(
    public router: Router,
    public loginService: LoginService,
    private userService: UserService, 
    private popUpService: PopUpService) { 
  }

  ngOnInit(): void {
    this.alive = true;
    this.getData();

    this.settingForm = this.initializeForm();
  }
  ngOnDestroy() {
    this.alive = false;
    this.popUpService.destoryConfirmDialog();
    this.popUpService.removePopUpView();
  }

  get partnerCareteamControl() {
    return this.settingForm.get('partnerCareteamControl') as FormArray;
  }

  get imdCareteamControl() {
    return this.settingForm.get('imdCareteamControl') as FormArray;
  }

  getData() {
    this.getAllDefaultCareteamUsers();
    this.getAllCareteamUsers();
  }

  getAllDefaultCareteamUsers() {
    this.userService.getDefaultCareteamList().subscribe(
      (result) => {
        this.careteams = [];
        this.partnerCareteams = [];

        const careteamUsers = result.careTeamList || [];
        careteamUsers.forEach(careteam => {
          if (careteam.user_type_id === 2) {
            this.careteams.push(careteam);
            if (careteam.avatar && careteam.avatar != null) {
              this.getProfilePicture(careteam.user_id, careteam.avatar, this.careteams);
            }
            else {
              careteam['avatarImage'] = null;
            }
          }
          
          if (careteam.user_type_id === 3) {
            this.partnerCareteams.push(careteam);
            if (careteam.avatar && careteam.avatar != null) {
              this.getProfilePicture(careteam.user_id, careteam.avatar, this.partnerCareteams);
            }
            else {
              careteam['avatarImage'] = null;
            }
          }
        });
      }, (error) => {
        console.log('error', error);
      }
    );
  }

  getAllCareteamUsers() {
    this.userService.getAllCareteamUsers().subscribe(
      (result) => {
        this.IMDCareteamList = [];
        this.partnerCareteamList = [];

        const careteamUsers = result.careTeamList || [];
        careteamUsers.forEach(element => {
          if (element.user_type_id === 2) {
            this.IMDCareteamList.push(element);
          }
          
          if (element.user_type_id === 3) {
            this.partnerCareteamList.push(element);
          }
        });
      }, (error) => {
        console.log('error', error);
      }
    );
  }
  initializeForm(): FormGroup {
    return new FormGroup(
      {
        partnerCareteamControl: new FormArray([],
          [
            CommonFuntions.fomrArrayMinLength(1)
          ]
        ),
        imdCareteamControl: new FormArray([],
          [
            CommonFuntions.fomrArrayMinLength(1)
          ]
        )
      }
    );
  }

  removeThisCareTeam(careteam, event) {
    const careTeamName = careteam.full_name || careteam.first_name || '';
    const careTeamId = careteam.user_id;
    event.preventDefault();
    if (CommonFuntions.guardForAction(this.loginService,
      this.router)) {

      const onPostiveAction = function () {

        this.userService.removeUserFromDefaultCareTeam(careTeamId)
          .pipe(takeWhile(() => this.alive))
          .subscribe(
            result => {
              if (result) {
                const message = '\''+careTeamName + '\' has been removed from default careteam.';
                // show alert

                this.alert(message, function () {
                  this.hideDiv('alertDialog');
                });
                this.getData();
              }

            }, error => {
              this.alert(this.partnerService.handleError(error, this.operation.toLowerCase()));
            }
          );

        this.popUpService.hideDiv('confirmDialog');
      }.bind(this);

      const onNegativeAction = function () {
        this.hideDiv('confirmDialog');
      };

      this.confirm(
        'Are you sure you want to remove \'' + careTeamName + '\' from the default careteam?',
        onPostiveAction,
        onNegativeAction
      );
    }

  }

  suggestionListFormatter(data: any): string {
    return data.full_name || data.first_name;
  }

  valueListFormatter(data: any): string {

    this.rowSelectedData.push(data);

    if (data.user_type_id === 2) {
      this.imdCareteamControl.push(new FormControl(data));
    }
    
    if (data.user_type_id === 3) {
      this.partnerCareteamControl.push(new FormControl(data));
    }
    return '';
  }

  addToDefaultList() {
    const users = [];
    const rawData = this.settingForm.getRawValue();
    
    if (rawData.partnerCareteamControl) {
      rawData.partnerCareteamControl.forEach(element => {
        users.push({
          user_id: element.user_id
        });
      });
    }

    if (rawData.imdCareteamControl) {
      rawData.imdCareteamControl.forEach(element => {
        users.push({
          user_id: element.user_id
        });
      });
    }

    if (users.length !== 0) {
      this.isInprogress = true;
      this.userService.addUsersToDefault({users: users}).subscribe((_result)=>{
        this.isInprogress = false;
        this.imdCareteamControl.controls = [];
        this.partnerCareteamControl.controls = [];
        this.getData();
      }, (_error) => {
        this.isInprogress = false;
      // console.log('error', error);
      });
    }
  }

  getProfilePicture(_user_id: number, avatar: string, userList: any[]): any {
    this.userService.getUploadedFile(avatar)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        result => {
          const careteam = userList.find(careteam => careteam.avatar == avatar);

          if (!careteam) {
            return;
          }

          careteam['avatarImage'] = result.image;
        }
      );
  }

  viewUserInfo(event: Event, user_id: number) {
    event.preventDefault();
    this.router.navigate(['/profile', user_id]);
  }

  confirm(message: string, positiveCallback: Function, negativeCallback: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Delete Confirmation',
      message,
      'Yes',
      'No',
      positiveCallback,
      negativeCallback
    );
    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);
  }
  
  alert(message: string, callback: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );
    const div = this.popUpService.getPopUpView('alertDialog');
    this.popUpService.setPositiveAction(callback);
    this.popUpService.showDiv(div);
  }

  clearPartner(careteam: FormControl) {
    let index = -1;

    const rawIndex = this.rowSelectedData.indexOf(careteam.value);
    if (rawIndex !== -1) { this.rowSelectedData.splice(rawIndex, 1);}

    switch (careteam.value.user_type_id) {
    case 2: // CM Careteam
      index = this.imdCareteamControl.controls.indexOf(careteam);
      this.imdCareteamControl.removeAt(index);
      break;
    
    case 3: // Partner Careteam
      index = this.partnerCareteamControl.controls.indexOf(careteam);
      this.partnerCareteamControl.removeAt(index);
      break;
    default:
      break;
    }
  }
}

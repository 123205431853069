/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { ConfigAssetLoaderService } from '../../../services/config-asset-loader.service';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { CommonFuntions } from '../../../common/common.function';
import { Component, OnInit, ViewChild } from '@angular/core';

declare let $: any;
@Component({
  selector: 'app-eligible-member-list',
  templateUrl: './eligible-member-list.component.html',
  styleUrls: ['./eligible-member-list.component.css']
})
export class EligibleMemberListComponent implements OnInit {

  title: string;
  searchValue: string;
  userCount: number;
  valueToSearch: string;
  errorString: string;
  activeUsers: number
  inactiveUsers: number

  @ViewChild(DataTableDirective, { static: true })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  token = 'Bearer ' + localStorage.getItem('token');

  constructor(
    private loginService: LoginService,
    private router: Router
  ) { }

  ngOnInit() {

    this.title = 'Eligible Member';
    const user = this.loginService.getCurrentUser();

    this.dtOptions = {
      ajax: {
        url: ConfigAssetLoaderService.getApiURL() + '/eligibleMember/role/1?type=' + user.user_type.type + '&userid=' + user.user_id,
        dataSrc: function (json) {
          this.activeUsers = json.activeUserTotal;
          this.inactiveUsers = json.inactiveUserTotal;
          return json.data;
        }.bind(this),
        type: 'GET',
        beforeSend: function (xhr) {
          if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
            xhr.abort();
            return;
          }

          this.errorString = null;
          const token = localStorage.getItem('token');
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }.bind(this),
        error: this.datatablesError.bind(this)
      },
      pagingType: 'full_numbers',
      serverSide: true,
      processing: true,
      lengthChange: false,
      stateSave: true,
      searching: true,
      pageLength: 25,
      order: [[0, 'ASC']],
      info: false,
      columns: [
        {
          title: 'Name',
          render: function (_data, _type, row) {
            let fullName = `${row.first_name} ${row.last_name}`;
            if (row.middle_name) {
              fullName = `${row.first_name} ${row.middle_name} ${row.last_name}`;
            }
            return $.fn.dataTable.render.text().display(fullName);
          }
        },
        {
          title: 'Email',
          data: 'email',
          render: $.fn.dataTable.render.text()
        },
        {
          title: 'Company',
          data: 'company_name',
          render: $.fn.dataTable.render.text()
        },
        {
          title: 'Active',
          data: 'active',
          render: $.fn.dataTable.render.text()
        },
        {
          title: 'Status',
          render: function (_data, _type, row, _meta) {
            if (row.is_deleted) {
              return 'Inactive';
            }
            else {
              return 'Active';
            }
          },
          orderable: false
        },
        {
          title: 'Actions',
          data: null,
          orderable: false,
          render: (_data, _type, _full) =>
            '<button id="editMember" class="btn btn--green btn--mini" >Edit</button>'
        }
      ],
      rowCallback: (row: Node, data: any | Object, _index: number) => {
        const self = this;

        // Unbind first in order to avoid duplicate handler
        $('td', row).unbind('click');
        $('td', row).bind('click', () => {
          self.viewUserInfo(data);
        });

        // Unbind first in order to avoid duplicate handler
        $('#editMember', row).on('click', () => {
          this.navigateToEditComponent(data.id);
        });

      }
    };
    this.setSearchValueFromSavedState();
  }

  setSearchValueFromSavedState() {

    const state = localStorage.getItem('DataTables_doctor_/eligible-member-list');

    if (state == null) {
      this.searchValue = '';
      return;
    }

    const searchValue = JSON.parse(state).search['search'];

    if (searchValue != null) {

      this.searchValue = searchValue;

    }
  }


  navigateToEditComponent(userId) {
    this.router.navigate(['/edit/eligible-member/', userId]);
  }

  search() {
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => {
        dtInstance.search(this.valueToSearch).draw();
      }
    );
  }

  searchString(searchValue: string) {
    this.valueToSearch = searchValue;
    this.search();
  }

  viewUserInfo(info: any) {
    this.router.navigate(['/eligible-member/profile', info.id]);
  }

  datatablesError(xhr, _error, _thrown) {
    const errorStatus = xhr.status;
    if (errorStatus == 401) {
      this.router.navigate(['/login']);
    }
    else if (errorStatus == 404) {
      this.errorString = 'Invalid Url';
    }
    else {
      this.errorString = 'Problem occurred. Please contact your administrator';
    }
  }

}

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { UserService } from '../../services/userservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatashareService } from '../../services/datashare.service';

/**
 * Profile component holds Display picture, user's name, user's role and setting link.
 */
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit {
  /**
   * stores path for display picture
   */
  profilePicPath: string;

  /**
   * stores payload data form jwt token
   */
  tokenPayLoad: any;

  avatarimage: any;

  sub: any;
  user: any;
  userId: number;
  isSettings: any;
  loadingImage = true

  /**
   * Constructs an instance of profile component injecting an instance of LoginService
   * @param loginService Inject instance of login service used.
   */
  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private route: ActivatedRoute,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private router: Router,
    private loginService: LoginService,
    private userService: UserService,
    public dataShareService: DatashareService
  ) {
    this.avatarimage = null;
  }

  /**
   * Initializes path for display picture.
   * Extracts payLoad data from jwt using login service.
   */

  ngOnInit() {
    this.tokenPayLoad = this.loginService.getCurrentUser();

    this.user = this.loginService.getCurrentUser();

    if(this.user.user_type.type.toLowerCase() == 'admin'){
      this.isSettings = true;
    }
    else{
      this.isSettings = false;
    }

    this.dataShareService.$displayPicture.subscribe(function (image) {
      this.avatarimage = image;
    }.bind(this));

    this.tokenPayLoad.user_type.type = this.tokenPayLoad.user_type.type.toLowerCase() === 'admin' ? 'Administrator' : this.tokenPayLoad.user_type.type;

    this.userService.getUser(this.tokenPayLoad.user_id).subscribe(
      function (user) {
        if(user.avatar != null){
          const path = user.avatar;
          if (path && path !== '') {
            this.userService.getUploadedFile(path).subscribe(
              function (image) {
                this.loadingImage = false;
                this.dataShareService.setDisplayPicture(image.image);
              }.bind(this),
              function (_error) {
                this.dataShareService.setDisplayPicture(null);
                this.profilePicPath = '/assets/images/profile-pic-male.png';
                this.loadingImage = false;
              }.bind(this)
            );
          }
          else{
            this.profilePicPath = '/assets/images/profile-pic-male.png';
            this.loadingImage = false;
          }
        }
        else{
          this.profilePicPath = '/assets/images/profile-pic-male.png';
          this.loadingImage = false;
        }
      }.bind(this),
      function (_error) {}.bind(this)
    );

  }

}

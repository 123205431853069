<div class="menu-logo" *ngIf="isCollapsed && !showLogoRole; else showImgLogo">
  <a (click)="actionDrawer()" class="left-menu-trigger">
    <span class="icon icon--previous"></span>
  </a>
</div>

<ng-template #showImgLogo>
  <div class="menu-logo">
    <img *ngIf="showLogo" [src]="logoPath" routerLink="" />
    <a (click)="actionDrawer()" class="left-menu-trigger">
      <span class="icon icon--previous"></span>
    </a>
  </div>
</ng-template>
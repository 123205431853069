/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
export class CategoryModel {
    category_id: number;
    category: string;
    created_at: Date;
    updated_at: Date;

    constructor(data) {
      const { category_id, category, created_at, updated_at } = data;
      this.category_id = category_id;
      this.category = category;
      this.created_at = created_at;
      this.updated_at = updated_at;
    }
}
/* eslint-disable no-restricted-imports */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { PopUpService } from '../../../services/pop-up.service';
import { Component, OnInit, Input, SimpleChanges, OnChanges, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { CommonFuntions } from '../../../common/common.function';
import { LoginService } from '../../../services/login.service';
import { Router } from '@angular/router';
import { UserService } from '../../../services/userservice.service';
import { CaseService } from '../../../services/case.service';
import { DoctorService } from '../../../services/doctor.service';
import { ConfigAssetLoaderService } from '../../../services/config-asset-loader.service';
import * as moment from 'moment/moment';
import { get } from 'lodash';
import { ICaseRequestedPresetner } from '../case-requested/Presenter/ICaseRequestedPresetner';
import { CASE_STATUS_TYPE } from '../../../constant';

@Component({
  selector: 'app-md-management',
  templateUrl: './md-management.component.html',
  styleUrls: ['./md-management.component.css']
})
export class MdManagementComponent implements OnInit, OnChanges, AfterContentInit {
  @Input() caseDetail;
  @Input() final_reports;
  @Input() translated_document;
  @Input() document_for_download;
  @Input() isMdManager;
  @Input() followUpNumber;
  @Output() caseEmitter = new EventEmitter<any>();
  @Output() showStatusEmitter = new EventEmitter<string>();
  mdMgmtForm: FormGroup;
  md_list = [];
  assigned_MDList = [];
  @Input() isAutoTriage: boolean;
  doctorsList = [];
  isReAssign: boolean;
  doctor_time_selected = false;
  obj = {};
  careteam: any;
  disableInputs: boolean;
  // @Input() disableEdits;
  schedules = [];
  sendEmailLabel;
  sendFollowUpEmailLabel;
  isEmailSending: boolean;
  isFollowUpEmailSending: boolean;
  emailSalutation: string;
  emailBody: string;
  time_zone = '';
  time_zone_offset = '';
  // email_template = '';
  prevMDObj: any
  validAssignedMD = true;
  selectionList = [{
    assigned_doc_user_id: '',
    doctor_name: 'Select'
  }];
  mdScheduleFetched = false;
  isMDMngmtCollapse = true;
  role;

  public presenter: ICaseRequestedPresetner
  
  constructor(private loginService: LoginService,
    private popUpService: PopUpService,
    private router: Router,
    private userService: UserService,
    private caseService: CaseService,
    private doctorService: DoctorService
  ) { }

  initMdMgmtForm(): FormGroup {
    const formGroup = new FormGroup(
      {
        findings: new FormControl({value: '', disable: this.isMdManager}, [Validators.maxLength(50000)]),
        email_template: new FormControl('', [Validators.maxLength(50000)]),
        follow_up_email_template: new FormControl('', [Validators.maxLength(50000)]),
        is_email_sent: new FormControl(false),
        is_follow_up_email_sent: new FormControl(false)
      },
      {
        updateOn: 'blur'
      }
    );

    if (this.caseDetail.case_type === 'MDT WRITTEN' || this.caseDetail.case_type === 'MDT VIDEO') {
      formGroup.addControl('assigned_md', new FormArray([], [Validators.required, Validators.maxLength(8)]));
      formGroup.addControl('primary_md', new FormControl('', [Validators.required, CommonFuntions.validateDropDown]));
    }
    else {
      const assignedMdOptions = [Validators.required, Validators.maxLength(1)];
      if (this.role === 'admin') assignedMdOptions.push(Validators.required);

      formGroup.addControl('assigned_md', new FormArray([], assignedMdOptions));
    }

    formGroup.disable();

    return formGroup;
  }

  ngOnInit(): void {
    this.role = this.loginService.getCurrentUser().user_type.type.toLowerCase();
    this.mdMgmtForm = this.initMdMgmtForm();
    this.sendEmailLabel = 'Send email to MD';
    this.isEmailSending = false;
    this.sendFollowUpEmailLabel = 'Send follow-up email';
    this.isFollowUpEmailSending = false;
    this.isReAssign = false;

    this.userService.getDoctorList().subscribe(
      doctors => {
        if (!Array.isArray(doctors)) {
          this.md_list = [];
          return;
        }

        doctors.forEach(
          (doctor, _index) => {
            const doctorObj = {};
            doctorObj['assigned_doc_user_id'] = doctor.user_id;
            doctorObj['doctor_name'] = doctor.doctor;
            doctorObj['doctor_last_name'] = doctor.doctor_last_name;
            doctorObj['email'] = doctor.email;
            // doctorObj['time_zone'] = doctor.time_zone;
            // doctorObj['time_zone_offset'] = doctor.time_zone_offset;
            this.md_list.push(doctorObj);
          }
        );
      },
      err => {
        console.log(err);
      },
      () => {
        this.initializeMDArray();
      }
    );

  }

  private initializeMDArray() {
    if (this.caseDetail && this.mdMgmtForm) {
      if (this.caseDetail.case_md_management && this.caseDetail.case_md_management.assigned_md_list.length > 0) {
        this.caseDetail.case_md_management.assigned_md_list.forEach(mdItem => {
          this.addMD(mdItem);
        });
      }
      else {
        if (this.caseDetail.assigned_doctor != null) {
          const obj = {
            assigned_doc_user_id: this.caseDetail.assigned_doctor.user.user_id,
            doctor_name: this.caseDetail.assigned_doctor.user.full_name,
            doctor_last_name: this.caseDetail.assigned_doctor.user.last_name,
            email: this.caseDetail.assigned_doctor.user.email,
            active: this.caseDetail.assigned_doctor.user.active || true
          };
          const prevMDObj = Object.assign({}, obj);
          this.addMD(prevMDObj);
        }
      }
    }
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.setDataInit();
  }

  ngAfterContentInit(): void {
    this.setDataInit();
  }

  private setTimeZone() {
    if (this.caseDetail.case_md_management != null &&
      this.caseDetail.case_md_management.user &&
      this.caseDetail.case_md_management.user != null) {
      this.time_zone = this.caseDetail.case_md_management.user.time_zone;
      this.time_zone_offset = this.caseDetail.case_md_management.user.time_zone_offset;
    }
    else {
      if (this.caseDetail.assigned_doctor != null) {
        this.time_zone = this.caseDetail.assigned_doctor.user.time_zone;
        this.time_zone_offset = this.caseDetail.assigned_doctor.user.time_zone_offset;
      }
    }
  }

  setDataInit() {
    if (this.mdMgmtForm && this.caseDetail != null) {
      this.careteam = this.caseDetail.status.find(element => element.status.status.toLowerCase() === 'mdmgmt');

      // set timezone
      this.setTimeZone();

      if (this.caseDetail.case_md_management != null) {
        if ((this.caseDetail.case_type === 'MDT WRITTEN' ||
          this.caseDetail.case_type === 'MDT VIDEO') && this.primary_md != null) {
          this.primary_md.setValue(this.caseDetail.case_md_management.assigned_md_id);
        }

        this.mdMgmtForm.get('is_email_sent').setValue(this.caseDetail.case_md_management.is_email_sent);
        this.mdMgmtForm.get('is_follow_up_email_sent').setValue(this.caseDetail.case_md_management.is_follow_up_email_sent);
        this.isReAssign = true;

        if (this.caseDetail.case_md_management.is_email_sent) {
          this.sendEmailLabel = 'Re-send email to MD';
        }

        if (this.caseDetail.case_md_management.is_follow_up_email_sent) {
          this.sendFollowUpEmailLabel = 'Re-send follow-up email';
        }

        this.findings.setValue(this.caseDetail.case_md_management.findings);
      }
      else {
        this.isReAssign = false;
      }

      const mdMgmt = this.caseDetail.status.find(status => status.status_id === 2);
      if (mdMgmt && mdMgmt != null) {
        if (mdMgmt.state === 'INPROGRESS') { this.isReAssign = false; }
      }

      if (this.caseDetail.selected_doctor_id === '' || this.caseDetail.selected_doctor_id == null) { // autotriage was unsuccessful
        this.isAutoTriage = false;
      }
      else {
        this.isAutoTriage = true;
      }

      this.doctorService.checkDoctorSchedule(this.caseDetail.id).subscribe(
        schedules => {
          if (schedules) {
            this.doctor_time_selected = true;
          }
        }
      );

      if (!this.mdScheduleFetched) {
        this.doctorService.getDoctorAvailabilityCases(this.caseDetail.id).subscribe(
          datetimes => {
            console.log('datetimes', datetimes);
            if (datetimes && Array.isArray(datetimes)) {
              datetimes.forEach(element => {
                const objSchedule = {};

                objSchedule['start_date_time'] = element.start_date_time;
                objSchedule['end_date_time'] = element.end_date_time;

                this.schedules.push(objSchedule);
              });
            }
            this.mdScheduleFetched = true;
            this.getEmailTemplate();
            this.getFollowUpEmailTemplate();
          }
        );
      }
      else {
        this.getEmailTemplate();
        this.getFollowUpEmailTemplate();
      }

      this.userService.findDoctorBySubSpecility(this.caseDetail.category_field_id, this.caseDetail.user_id).subscribe(
        doctors => {
          if (!Array.isArray(doctors)) {
            this.doctorsList = [];
            return;
          }
          this.doctorsList = doctors;
        },
        (_error) => this.doctorsList = []
      );  

      this.disableInputs = this.role === 'admin' ? false : CommonFuntions.checkIfHoldOrCancelledOrFinishedorMd(this.caseDetail, this.isMdManager);
    }
  }

  get primary_md() {
    return this.mdMgmtForm.get('primary_md') as FormControl;
  }

  get assigned_md() {
    return this.mdMgmtForm.get('assigned_md') as FormArray;
  }

  get findings() {
    return this.mdMgmtForm.get('findings') as FormControl;
  }

  get email_template() {
    return this.mdMgmtForm.get('email_template') as FormControl;
  }

  get follow_up_email_template() {
    return this.mdMgmtForm.get('follow_up_email_template') as FormControl;
  }

  valueListFormatter(data: any): string {
    this.prevMDObj = data;
    if (data.hasOwnProperty('time_zone')) {
      this.time_zone = data.time_zone;
      this.time_zone_offset = data.time_zone_offset;
    }

    this.addMD(data);

    return '';
  }

  changePrimaryMD(userId: string) {
    if (userId) {
      this.assigned_md.value.map(each => {
        if (each.assigned_doc_user_id === parseInt(userId, 10)) {
          each.primary = true;
        }
        else {
          each.primary = false;
        }
      });
      this.primary_md.setValue(userId);
    }
  }

  private processPrimaryMD(mdObj: any): boolean {
    let primary = false;

    // if the md detail is on db then make it primary or not based on it previous db value otherwise the md is not primary
    if (this.caseDetail.case_md_management && this.caseDetail.case_md_management.assigned_md_list.length > 0) {
      const mdValue = this.caseDetail.case_md_management.assigned_md_list.find(item => item.assigned_doc_user_id === mdObj.assigned_doc_user_id);

      if (mdValue) {
        primary = mdValue.is_primary;
      }
    }

    return primary;
  }

  private addMD(data: any) {
    let mdObj = this.md_list.find(mdItem => mdItem.assigned_doc_user_id === data.assigned_doc_user_id);

    if (mdObj) mdObj.active = get(data, 'user.active', get(data, 'active', true));
    if (!mdObj) {
      // if doctor is inactive then
      mdObj = {
        ...data,
        email: get(data, 'user.email', ''),
        doctor_last_name: get(data, 'user.last_name', ''),
        doctor_name: get(data, 'user.full_name', ''),
        active: get(data, 'user.active', get(data, 'active', true))
      };
    }
    const index = this.md_list.indexOf(mdObj);
    this.md_list.splice(index, 1);

    if (this.caseDetail.case_type === 'MDT WRITTEN' ||
      this.caseDetail.case_type === 'MDT VIDEO') {
      // Add doctors as option to be assigned as Primary MD
      this.selectionList.push(mdObj);

      mdObj['primary'] = this.processPrimaryMD(mdObj);

      this.assigned_md.push(new FormControl(mdObj));
    }
    else {
      mdObj['primary'] = true;

      // In non-MDT case, In course of adding MD, if there is already another md selected previously then add it to doctorList
      if (this.assigned_md.value.length > 0) {
        this.addInMDList(this.assigned_md.controls[0] as FormControl);
      }

      // In non-MDT case, only one md can be assigned in list so while adding md from list clear the previous value
      this.assigned_md.controls = [];
      this.assigned_md.push(new FormControl(mdObj));
    }
  }

  private addInMDList(md: FormControl) {
    this.md_list.push(md.value);
  }

  private checkMDCanBeRemoved(md: FormControl): boolean {
    let value = true;

    const assignedMDList = this.caseDetail?.case_md_management?.assigned_md_list || [];
    if(assignedMDList && assignedMDList.length > 0){
      const doctorObj = assignedMDList.find(mdItem => mdItem.assigned_doc_user_id === md.value.assigned_doc_user_id);
      if(doctorObj && doctorObj.is_submitted){
        value = false;
      }
    }

    return value;
  }

  clearPartner(md: FormControl) {
    const canRemove = this.checkMDCanBeRemoved(md);

    if(canRemove) {
      const index = this.assigned_md.controls.indexOf(md);
      this.assigned_md.removeAt(index);

      if (this.caseDetail.case_type === 'MDT WRITTEN' ||
        this.caseDetail.case_type === 'MDT VIDEO') {
        // remove the doctor from the primary select options once cleared
        this.selectionList = this.selectionList.filter(listItem => listItem.assigned_doc_user_id !== md.value.assigned_doc_user_id);
        // while removing the md, if it is listed as primary md then clear the primary md value in select Option
        if (this.primary_md.value === md.value.assigned_doc_user_id) {
          this.primary_md.setValue('');
        }
      }

      this.addInMDList(md);

    }
    else {
      this.showAlert('You cannot remove the doctor as he has already submitted the consultation.' ,function () {
        this.popUpService.hideDiv('alertDialog');
      }.bind(this));
      return false;
    }
  }

  suggestionListFormatter(data: any): string {

    if (data.hasOwnProperty('doctor_name')) {
      return data.doctor_name;
    }
  }

  private checkAssignedMDValidity(assignedMDList: []) {
    let check = true;

    if (this.caseDetail.case_type === 'MDT WRITTEN' ||
      this.caseDetail.case_type === 'MDT VIDEO') {
      if (assignedMDList.length === 0 || assignedMDList.length > 8) {
        check = false;
      }
    }
    else {
      if (assignedMDList.length === 0 || assignedMDList.length > 1) {
        check = false;
      }
    }

    return check;
  }

  private showAlert(message: string ,positiveCallback: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'alert',
      message,
      'Ok',
    );

    this.popUpService.setPositiveAction(positiveCallback);

    const alertDialog = this.popUpService.getPopUpView('alertDialog');
    this.popUpService.showDiv(alertDialog);
  }

  onSubmit(form: FormGroup) {
    this.validAssignedMD = this.checkAssignedMDValidity(form.controls.assigned_md.value);
    if (CommonFuntions.guardForAction(this.loginService,
      this.router)) {
      if (form.valid) {
        this.popUpService.destoryConfirmDialog();
        this.popUpService.setDialogParams(
          'Confirmation',
          'Are you sure you want to proceed?',
          'Yes',
          'No',
          function () {
            this.isEmailSending = true;

            this.mdMgmtForm.addControl(
              'case_id', new FormControl(this.caseDetail.id)
            );

            // if (this.prevMDObj['assigned_user_id'] != this.mdMgmtForm.value.assigned_md.assigned_user_id) {
            // 	this.mdMgmtForm.value.is_email_sent = false;
            // }

            this.caseService.createOrUpdateMdMgmt(this.mdMgmtForm.value, this.caseDetail.id).subscribe(
              function (_result) {
                const message = this.isReAssign ? 'MD Management updated successfully' : 'MD Management finished successfully';
                this.showAlert(message ,function () {
                  this.popUpService.hideDiv('alertDialog');
                }.bind(this));
                this.updateLocalStages();
              }.bind(this), 
              function(error) {
                const message = error.error.message || 'Failed to complete the Md Management stage';
                this.showAlert(message, function () {
                  this.isEmailSending = false;
                  this.popUpService.hideDiv('alertDialog');
                }.bind(this));	
              }.bind(this)
            );				
            this.popUpService.hideDiv('confirmDialog');
          }.bind(this),
          function () {
            this.popUpService.hideDiv('confirmDialog');
          }.bind(this)
        );

        const dialog = this.popUpService.getPopUpView('confirmDialog');
        if (dialog) { this.popUpService.showDiv(dialog); }
      }
      else {
        CommonFuntions.validateAllFormFields(this.mdMgmtForm);
      }
    }
  }

  onCaseUpdate() {
    if (CommonFuntions.guardForAction(this.loginService,
      this.router)) {
      this.popUpService.destoryConfirmDialog();
      this.popUpService.setDialogParams(
        'Confirmation',
        'Are you sure you want to proceed?',
        'Yes',
        'No',
        function () {
          this.isEmailSending = true;

          this.mdMgmtForm.addControl(
            'case_id', new FormControl(this.caseDetail.id)
          );

          this.caseService.createOrUpdateMdMgmtAsAdmin(this.caseDetail.id).subscribe(
            function (_result) {
              this.isEmailSending = false;

              this.updateLocalStages();

            }.bind(this), 
            function(error) {
              const message = error.error.message || 'Failed to complete the Md Management stage';
              this.showAlert(message, function () {
                this.isEmailSending = false;
                this.popUpService.hideDiv('alertDialog');
                
              }.bind(this));
            }.bind(this)
          );
          this.popUpService.hideDiv('confirmDialog');
        }.bind(this),
        function () {
          this.popUpService.hideDiv('confirmDialog');
        }.bind(this)
      );

      const dialog = this.popUpService.getPopUpView('confirmDialog');
      if (dialog) { this.popUpService.showDiv(dialog); }
      else {
        CommonFuntions.validateAllFormFields(this.mdMgmtForm);
      }
    }
  }

  clearIfNotInList(assignedMDInput: HTMLInputElement, _value: any) {
    const assignedMDName = assignedMDInput.value;
    const assigned_md = this.md_list.find(each => each.doctor_name === assignedMDName);
    if (assigned_md == null) {
      this.mdMgmtForm.get('assigned_md').reset();
      this.mdMgmtForm.get('assigned_md').markAsTouched();
      assignedMDInput.value = null;
    }
    else {
      this.mdMgmtForm.get('assigned_md').setValue(assignedMDName);
      this.obj = assigned_md;
    }
  }

  changeEmailSalutation(emailTemplate: string | null, isFollowUp = false): string {
    const doctorLastName = this.caseDetail.case_md_management.user.last_name;
    let new_template = emailTemplate;
    /** Check if the <br /> tag exists in the emailTemplate or not
     * If exists then replace with new doctor
     * If not then leave as it is.
     */
    if (emailTemplate.includes('<br />')) {
      const replaceString = emailTemplate.split('<br />')[0];
      if (isFollowUp) {
        new_template = emailTemplate.replace(replaceString, `<html></body> Hello Dr. ${doctorLastName}, `);
      }
      else {
        new_template = emailTemplate.replace(replaceString, `<html></body> Dear Dr. ${doctorLastName}, `);
      }
    }

    return new_template;

  }

  private getEmailContent(emailTemplate: string | null): string {
    let emailBody = '';

    if(!emailTemplate){
      let patientPronoun = 'his/her';
      const patientAge = moment().diff(moment(this.caseDetail.patient.dob), 'years');
      const patientGender = this.caseDetail.patient.gender === 'F' ? 'female': this.caseDetail.patient.gender === 'M' ? 'male' : 'unspecified';
      if (this.caseDetail.patient.gender === 'F') {
        patientPronoun = 'her';
      }
      else if (this.caseDetail.patient.gender === 'M') {
        patientPronoun = 'his';
      }
      const patientDiagnosis = this.caseDetail.patient_diagnosis ? this.caseDetail.patient_diagnosis : 'N/A';
      const caseType = ['VIDEO CONSULTATION', 'MDT VIDEO'].includes(this.caseDetail.case_type) ? 'video': 'written';
      const link = `'${ConfigAssetLoaderService.getDomainURL()}/case/doctor-consultation/${this.caseDetail.id}'`;
      const doctorLastName = this.caseDetail.case_md_management.user.last_name;
      emailBody += `<html></body> Dear Dr. ${doctorLastName}, <br /><br />`;
      emailBody += `We are reaching out to you today to seek your availability to consult for us. The patient is a ${patientAge} year old ${patientGender} with ${patientDiagnosis}. The patient and their family are seeking a ${caseType} second opinion on ${patientPronoun} condition and potential treatment options. <br /><br />`;
      
      if (caseType === 'written') {
        emailBody += `Here is a link to review the case and medical records: <a href=${link} target='_blank'>Case Summary Link</a> <br /><br />`;
      }
      else {
        emailBody += 'If you are available to consult on this case, please let us know your availability for a 30 minute video consultation in the next week <br />';
        emailBody += `You can review the case and available medical records here: <a href=${link} target='_blank'>Case Summary Link</a> <br /><br />`;
      }

      emailBody += 'We look forward to hearing from you and appreciate your time and efforts in this case.<br/>If you are not available to consult on the case at this time, please reply to this message within 24 hours.<br /><br />';
      emailBody += 'If there are any issues accessing the platform, please reply to this email and a member of our Careteam will assist. <br /><br />';
      emailBody += 'Wishing you a good day,<br /><br />';
      emailBody += 'Careteam';
    }
    else {
      emailBody = this.changeEmailSalutation(emailTemplate);
    }  

    return emailBody;
  }

  getEmailTemplate(): void {
    let template;
    if (this.caseDetail.case_md_management && this.caseDetail.case_md_management != null) {
      const emailTemplate = this.caseDetail.case_md_management.email_template;
      template = this.getEmailContent(emailTemplate);
    }
    this.mdMgmtForm.get('email_template').setValue(template);
  }

  private getFolloUpEmailContent(emailTemplate: string | null): string {
    let emailBody = '';

    if(!emailTemplate){
      const patientAge = moment().diff(moment(this.caseDetail.patient.dob), 'years');
      const patientDiagnosis = this.caseDetail.patient_diagnosis ? this.caseDetail.patient_diagnosis : 'N/A';
      const link = `'${ConfigAssetLoaderService.getDomainURL()}/case/doctor-consultation/${this.caseDetail.id}'`;
      const doctorLastName = this.caseDetail.case_md_management.user.last_name;
      emailBody += `<html></body> Hello Dr. ${doctorLastName}, <br /><br />`;
      emailBody += 'After reviewing your completed consultation report, the patient has returned with additional questions. Please let us know if you will be able to respond to these follow up questions. <br /><br />';
      emailBody += `As a reminder the patient is a ${patientAge} year old with ${patientDiagnosis}.<br /><br />`;
      
      emailBody += `Here is a link to access the case: <a href=${link} target='_blank'>Case Link</a> <br /><br />`;

      emailBody += 'If you have any questions or concerns, please do not hesitate to reach out to our Care Team who can assist you.<br /><br />';
      emailBody += 'Thank you,<br /><br />';
      emailBody += 'Careteam';
    }
    else {
      emailBody = this.changeEmailSalutation(emailTemplate, true);
    }  

    return emailBody;
  }

  getFollowUpEmailTemplate(): void {
    let template;
    if (this.caseDetail.case_md_management && this.caseDetail.case_md_management != null) {
      const emailTemplate = this.caseDetail.case_md_management.follow_up_email_template;
      template = this.getFolloUpEmailContent(emailTemplate);
    }
    this.mdMgmtForm.get('follow_up_email_template').setValue(template);
  }

  sendEmailToMD(): void {
    this.isEmailSending = true;
    const doctorLastName = this.caseDetail.case_md_management.user.last_name;
    const payload = {
      caseid: this.caseDetail.id,
      email_template: this.email_template.value
    };
    this.caseService.sendEmailToMD(payload).subscribe(
      function(_result) {
        this.caseDetail.case_md_management.is_email_sent = true;
        this.mdMgmtForm.get('is_email_sent').setValue(this.caseDetail.case_md_management.is_email_sent);
        this.sendEmailLabel = 'Re-send email to MD';
        this.showAlert(`Email sent to Dr. ${doctorLastName}.`, function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
        this.isEmailSending = false;
      }.bind(this),
      function(_error) {
        this.caseDetail.case_md_management.is_email_sent = false;
        this.showAlert(`Failed to send email to Dr. ${doctorLastName}.`, function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
        this.isEmailSending = false;
      }.bind(this)
    );
  }

  sendFollowUpEmailToMD(): void {
    this.isEmailSending = true;
    const doctorLastName = this.caseDetail.case_md_management.user.last_name;
    const payload = {
      caseid: this.caseDetail.id,
      email_template: this.follow_up_email_template.value,
      isFollowUpEmail: true
    };
    this.caseService.sendEmailToMD(payload).subscribe(
      function(_result) {
        this.caseDetail.case_md_management.is_follow_up_email_sent = true;
        this.mdMgmtForm.get('is_follow_up_email_sent').setValue(this.caseDetail.case_md_management.is_follow_up_email_sent);
        this.sendFollowUpEmailLabel = 'Re-send follow-up email';
        this.showAlert(`Email sent to Dr. ${doctorLastName}.`, function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
        this.isEmailSending = false;
      }.bind(this),
      function(_error) {
        this.caseDetail.case_md_management.is_follow_up_email_sent = false;
        this.showAlert(`Failed to send email to Dr. ${doctorLastName}.`, function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
        this.isEmailSending = false;
      }.bind(this)
    );
  }

  isActive(md) {
    if (md.value && !md.value.active) {
      return 'inactive-alert';
    }
    return '';
  }

  updateLocalStages() {
    const user = this.loginService.getCurrentUser();

    this.caseService.getCaseById(this.caseDetail.id, user.user_id).subscribe(
      function (caseDetails) {
        caseDetails.date_mr_complete = moment(caseDetails.date_mr_complete).format('MM/DD/YYYY, h:mm A');
        this.caseDetail = caseDetails;

        let inprogressList = CommonFuntions.setStatusInProgress(this.caseDetail.status, 'mdMgmt');
        const statusList = [];

        inprogressList = inprogressList.filter(eachStatus => eachStatus.state.toLowerCase() !== 'finished');

        inprogressList.forEach(eachStatus => {
          statusList.push({ status_id: eachStatus.status.id });
        });

        this.caseService.updateStaus(
          statusList,
          this.caseDetail.id
        ).subscribe(
          function (_updateStatusResult) {
            this.caseService.getCaseById(this.caseDetail.id, user.user_id).subscribe(
              function (updatedCaseDetails) {
                updatedCaseDetails.date_mr_complete = moment(updatedCaseDetails.date_mr_complete).format('MM/DD/YYYY, h:mm A');
                updatedCaseDetails['final_reports'] = this.object.final_reports;
                updatedCaseDetails['document_for_download'] = this.object.document_for_download;
                updatedCaseDetails['translated_document'] = this.object.translated_document;
                if (updatedCaseDetails && updatedCaseDetails.case_md_management) {
                  this.object.prevMDObj = {
                    assigned_user_id: updatedCaseDetails.case_md_management.user?.user_id,
                    doctor_last_name: updatedCaseDetails.case_md_management.user?.last_name,
                    email: updatedCaseDetails.case_md_management.user?.email
                  };

                  if (updatedCaseDetails.case_md_management.is_email_sent) {
                    this.object.sendEmailLabel = 'Re-send email to MD';
                  }
                  else {
                    this.object.sendEmailLabel = 'Send email to MD';
                  }

                  if (updatedCaseDetails.case_md_management.is_follow_up_email_sent) {
                    this.object.sendFollowUpEmailLabel = 'Re-send follow-up email';
                  }
                  else {
                    this.object.sendFollowUpEmailLabel = 'Send follow-up email';
                  }

                }
                this.object.caseEmitter.emit(updatedCaseDetails);
                this.object.showStatusEmitter.emit('mdmgmt');
                this.object.isEmailSending = false;
              }.bind({
                object: this,
                inprogressList
              }),
              function(_error) {
                this.showAlert('Error while getting the case.' ,function () {
                  this.caseDetail = null;
                  this.popUpService.hideDiv('alertDialog');
                }.bind(this));	
              }.bind(this)
            );
          }.bind(this),
          function(_error) {
            this.showAlert('Cannot update status of case.' ,function () {
              this.popUpService.hideDiv('alertDialog');
            }.bind(this));	
          }.bind(this)

        );
      }.bind(this),
      function(_error) {
        this.showAlert('Error while getting the case.' ,function () {
          this.caseDetail = null;
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));	
      }.bind(this)
    );
  }

  isStagePaused(statusId: number, statusArr?: string[]): boolean {
    const mdMgmt = this.caseDetail.status.find(status => status.status_id === statusId);
    
    if (statusArr) return statusArr.includes(mdMgmt.state);
    
    const statusTypes = [ CASE_STATUS_TYPE.FINISHED, CASE_STATUS_TYPE.CANCELLED, CASE_STATUS_TYPE.HOLD ];
    return statusTypes.includes(mdMgmt.state);
  }

}

<div class="header">
	<div class="header__page-name">
		<h1 class="heading heading--main">{{ userObj?.full_name }} - Associated Cases</h1>
	</div>
	<div class="header__top-nav">
		<div class="top-menu" >
			<div class="top-menu__user-stat">
				<span>Total Count: </span> {{ caseCount || 0 }}
			</div>
			<div class="top-menu__menu">
			</div>
		
			<div class="top-menu__search">
				<div class="search-wrapper">
					<form #form="ngForm">
						<select name="caseStatus" [(ngModel)]="status">
							<option *ngFor="let type of CASE_STATUS" [value]="type?.id">
								{{ type?.value }}
							</option>
						</select>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="wrapper--dash-content dash-content">
	<div *ngIf="errorString" class="message message--is-invalid">
		{{errorString}}
	</div>

	<table datatable [dtOptions]="dtOptions" class="row-border hover" id="user-assigned-cases">
	</table>
</div>
const USER_ROLE_TYPE = {
  ADMIN: 'admin',
  CARE_TEAM: 'careteam',
  PARTNER: 'partner',
  PATIENT: 'patient',
  DOCTOR: 'doctor',
  TRANSLATOR: 'translator',
  INTERPRETER: 'interpreter',
  SYSTEM_ACCOUNT: 'systemaccount',
  MD_MANAGER: 'mdmanager',
  CM_MANAGER: 'cmmanager',
  CASE_COORDINATOR: 'casecoordinator'
};

const USER_TYPE = {
  ADMIN: 1,
  CARE_TEAM: 2,
  PARTNER: 3,
  DOCTOR: 4,
  PATIENT: 5,
  TRANSLATOR: 6,
  INTERPRETER: 7,
  MD_MANAGER: 8,
  SYSTEM_ACCOUNT: 9,
  CM_MANAGER: 10,
  CASE_COORDINATOR: 11
};
const USER_TYPE_BY_ID = {
  1: 'Admin',
  2: 'Care Team',
  3: 'Partner',
  4: 'Doctor',
  5: 'Patient',
  6: 'Summary Coordinator',
  7: 'Interpreter',
  8: 'MD Manager',
  9: 'System Account',
  10: 'CM manager',
  11: 'Case Coordinator'
};

const USER_ROLES_CHANGE = {
  ADMIN: 'Admin',
  CARE_TEAM: {
    CASE_COORDINATOR: 'Case Coordinator',
    CM_MANAGER: 'CM manager',
  },
  CLIENT_CARE_TEAM: 'Care Team',
  CM_MANAGER: 'Care Team',
  CASE_COORDINATOR: 'Case Coordinator',
};

const USER_ROLES_CHANGE_LIST = [{User_Role: 'Alight Client Careteam',User_Type_Id: 3},
  {User_Role: 'Alight Case Coordinator',User_Type_Id: 11},
  {User_Role: 'Alight Careteam',User_Type_Id: 2},
  {User_Role: 'Alight CM Manager',User_Type_Id: 10}];

const CASE_ASSIGNMENT_TYPE = {
  DIRECT: 'DIRECT',
  QUEUE: 'QUEUE',
  DIRECT_URL: '/case-assignments/cases/current?assignmentType=DIRECT',
  QUEUE_URL: '/case-assignments/cases/current?assignmentType=QUEUE',
  ALL_CASE_ASSIGNMENTS_URL: 'case-assignments',
  CASE_ASSIGNMENT_URL: '/case-assignments?caseId='
};

const USER_ASSIGNED_CASES = {
  ME: '/v2/cases/assigned/me',
  USER: '/v2/cases/assigned'
};

const CASE_ASSIGNMENT_STATUS = {
  DRAFT: 'DRAFT',
  IN_REVIEW: 'IN REVIEW',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED'
};

const PASSWORD_VALIDATION_CONSTRAINTS = {
  CAPITAL: 'capital',
  SPECIAL_CHAR: 'special_char',
  NUMBER: 'number'
};

const CASE_TYPE = {
  WRITTEN: 'written consultation',
  VIDEO: 'video consultation'
};

const CASE_STATUS_TYPE = {
  REQUESTED: 'REQUESTED',
  HOLD: 'HOLD',
  CANCELLED: 'CANCELLED',
  FINISHED: 'FINISHED',
  INPROGRESS: 'INPROGRESS'
};

const CASE_STAGE_ID = {
  REQUESTED: 1,
  MD_MGMT: 2,
  COMPILE_MR: 3,
  SCHEDULE: 6,
  VIDEO: 7,
  SUMMARY: 8,
  TRANSLATE_DR: 9,
  REPORT: 10
};

const SPECIALITY_PROGRAM = {
  MSO: 'mso',
  PEER_TO_PEER_ONCOLOGY: 'peer to peer oncology'
};

const FOLLOW_UP_TYPE = {
  QUESTIONS_ONLY: 'Questions only',
  RECORD_COLLECTION: 'Additional record collection',
  PEER_TO_PEER: 'Peer to Peer'
};
const LOG_BACKUP_TYPE = {
  WEBHOOK: 'WEBHOOK',
  EMAIL: 'EMAIL'
};

const CLEAN_STRING = (string, removeNewLine = false) => {
  // left
  // eslint-disable-next-line quotes
  string = string.replace(/&lsquo;/g, "'");

  string = string.replace(/‟/g, '"');
  string = string.replace(/&#8223;/g, '"');

  // eslint-disable-next-line quotes
  string = string.replace(/‛/g, "'");
  // eslint-disable-next-line quotes
  string = string.replace(/&#8219;/g, "'");

  // right
  // eslint-disable-next-line quotes
  string = string.replace(/’/g, "'");
  // eslint-disable-next-line quotes
  string = string.replace(/&#8217;/g, "'");

  
  string = string.replace(/&sbquo;/g, '‚');

  // eslint-disable-next-line quotes
  string = string.replace(/"/g, '"');
  // eslint-disable-next-line quotes
  string = string.replace(/&rsquo;/g, "'");

  string = string.replace(/[\t]+/gm, '    ');

  string = string.replace(/[\r]+/gm, '');
  string = string.replace(/&nbsp;/g, ' ');
  string = string.replace(/&quot;/g, '"');
  string = string.replace(/&rdquo;/g, '"');
  string = string.replace(/&ldquo;/g, '"');
  string = string.replace(/&lt;/, '<');
  string = string.replace(/&gt;/, '>');
  // eslint-disable-next-line quotes
  string = string.replace(/&#39;/g, "'");
  string = string.replace(/&amp;/g, '&');
  if (removeNewLine) string = string.replace(/[\n]+/gm, '');
  return string;
};

export {
  USER_ROLE_TYPE,
  USER_TYPE,
  CASE_TYPE,
  CASE_ASSIGNMENT_TYPE,
  CASE_ASSIGNMENT_STATUS,
  PASSWORD_VALIDATION_CONSTRAINTS,
  CASE_STATUS_TYPE,
  USER_ROLES_CHANGE,
  CASE_STAGE_ID,
  USER_TYPE_BY_ID,
  USER_ASSIGNED_CASES,
  SPECIALITY_PROGRAM,
  FOLLOW_UP_TYPE,
  LOG_BACKUP_TYPE,
  USER_ROLES_CHANGE_LIST,
  CLEAN_STRING
  
};

/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigAssetLoaderService } from '../services/config-asset-loader.service';
import {  Observable } from 'rxjs';

@Injectable()
export class PasswordService {

  constructor(
		private http: HttpClient
  ) { }

  public checkToken(token: string): Observable<any> {

    return this.http.get(
      ConfigAssetLoaderService.getApiURL() + '/' + 'check/resettoken?token=' + token
    );

  }

  public requestPwd(requestForm: any): Observable<any> {

    return this.http.post(
      ConfigAssetLoaderService.getApiURL() + '/' + 'user/reset?platform=web',
      requestForm
    );

  }

  public changePassword(passwordChangeForm: any): Observable<any> {
    const token = localStorage.getItem('token');
    if (token) {
      return this.http.post(
        ConfigAssetLoaderService.getApiURL() + '/' + 'password/change',
        passwordChangeForm,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          })
        }
      );
    }
    else {
      return this.http.post(
        ConfigAssetLoaderService.getApiURL() + '/' + 'password/change',
        passwordChangeForm
      );
    }
  }

}

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigAssetLoaderService } from '../../../../services/config-asset-loader.service';
import { LoginService } from '../../../../services/login.service';
import { DataTableDirective } from 'angular-datatables';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFuntions } from '../../../../common/common.function';

declare let $: any;
@Component({
  selector: 'app-active-users',
  templateUrl: './active-users.component.html',
  styleUrls: ['./active-users.component.css'],
})
export class ActiveUsersComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: true })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  token = 'Bearer ' + localStorage.getItem('token');
  valueToSearch: string;
  errorString: string;

  title: string;
  searchValue = '';

  userCount: number;
  activeUsers: number;
  inactiveUsers: number;
  userListRole: number;

  userTypes = {
    admin: 1,
    careteam: 2,
    partner: 3,
    client_careteam: 3,
    doctor: 4,
    patient: 5,
    translator: 6,
    interpreter: 7,
    mdmanager: 8,
    cmmanager: 10,
    casecoordinator: 11,
    eligibleMember: 12,
  };

  userTypeTitles = {
    admin: 'Active Admins',
    careteam: 'Active Alight Careteams',
    partner: 'Active Client Careteams',
    client_careteam: 'Active Client Careteams',
    doctor: 'Active Doctors',
    patient: 'Active Members',
    translator: 'Active Summary Coordinators',
    interpreter: 'Active Interpreters',
    mdmanager: 'Active MD Managers',
    cmmanager: 'Active CM Managers',
    casecoordinator: 'Active Case Coordinator',
    eligibleMember: 'Active Eligible Member',
  };
  userRole: any;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private loginService: LoginService
  ) {
    this.userCount = 0;
  }

  ngOnInit() {
    this.title = 'Active Users';
    const user = this.loginService.getCurrentUser();
    this.route.params.subscribe((params) => {
      if (!params['role']) {
        this.router.navigate(['/page-not-found']);
        return;
      }

      if (!this.userTypes[params['role']]) {
        this.router.navigate(['/page-not-found']);
        return;
      }
      this.userListRole = this.userTypes[params['role']];
      this.userRole = params['role'];
      this.title = this.userTypeTitles[params['role']];
    });

    if (this.userRole != 'eligibleMember') {
      {
        const optionObject = {
          ajax: {
            url:
              ConfigAssetLoaderService.getApiURL() +
              `/user/role/${this.userListRole}?type=` +
              user.user_type.type +
              '&userid=' +
              user.user_id +
              '&filterBy=active',
            dataSrc: function (json) {
              this.userCount = json.recordsTotal;
              this.activeUsers = json.data.filter(
                (user) => user.is_deleted === false
              ).length;
              this.inactiveUsers = json.data.filter(
                (user) => user.is_deleted === true
              ).length;
              return json.data;
            }.bind(this),
            type: 'POST',
            beforeSend: function (xhr) {
              if (
                !CommonFuntions.guardForAction(this.loginService, this.router)
              ) {
                xhr.abort();
                return;
              }

              this.errorString = null;
              const token = localStorage.getItem('token');
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            }.bind(this),
            error: this.datatablesError.bind(this),
          },
          pagingType: 'full_numbers',
          serverSide: true,
          processing: true,
          lengthChange: false,
          searching: true,
          pageLength: 25,
          order: [[0, 'ASC']],
          info: false,
          stateSave: true,
          stateDuration: 5 * 60,
          rowCallback: (row: Node, data: any[] | Object, _index: number) => {
            // Unbind first in order to avoid duplicate handler
            if (this.userRole != 'admin') {
              $('td', row).unbind('click');
              $('td', row).bind(
                'click',
                function () {
                  this.viewUserInfo(data);
                }.bind(this)
              );
            }
          },
        };
        optionObject['columns'] = this.getColumn(user);
        this.dtOptions = optionObject;
      }
    }
    else {
      this.dtOptions = {
        ajax: {
          url:
            ConfigAssetLoaderService.getApiURL() +
            '/eligibleMember/role/1?type=' +
            user.user_type.type +
            '&userid=' +
            user.user_id +
            '&filterBy=active',
          dataSrc: function (json) {
            this.userCount = json.data.length;
            return json.data;
          }.bind(this),
          type: 'GET',
          beforeSend: function (xhr) {
            if (
              !CommonFuntions.guardForAction(this.loginService, this.router)
            ) {
              xhr.abort();
              return;
            }

            this.errorString = null;
            const token = localStorage.getItem('token');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }.bind(this),
          error: this.datatablesError.bind(this),
        },
        pagingType: 'full_numbers',
        serverSide: true,
        processing: true,
        lengthChange: false,
        stateSave: true,
        searching: true,
        pageLength: 25,
        order: [[0, 'ASC']],
        info: false,
        columns: [
          {
            title: 'Name',
            render: function (_data, _type, row) {
              let fullName = `${row.first_name} ${row.last_name}`;
              if (row.middle_name) {
                fullName = `${row.first_name} ${row.middle_name} ${row.last_name}`;
              }
              return $.fn.dataTable.render.text().display(fullName);
            },
          },
          {
            title: 'Email',
            data: 'email',
            render: $.fn.dataTable.render.text(),
          },
          {
            title: 'Company',
            data: 'company_name',
            render: $.fn.dataTable.render.text(),
          },
          {
            title: 'Active',
            data: 'active',
            render: $.fn.dataTable.render.text(),
          },
          {
            title: 'Status',
            render: function (_data, _type, row, _meta) {
              if (row.is_deleted) {
                return 'Inactive';
              }
              else {
                return 'Active';
              }
            },
            orderable: false,
          },
          {
            title: 'Actions',
            data: null,
            orderable: false,
            render: (_data, _type, _full) =>
              '<button id="editMember" class="btn btn--green btn--mini" >Edit</button>',
          },
        ],
        rowCallback: (row: Node, data: any | Object, _index: number) => {
          if (this.userRole != 'admin') {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this;
            // Unbind first in order to avoid duplicate handler
            $('td', row).unbind('click');
            $('td', row).bind('click', () => {
              self.viewEligibleUserInfo(data);
            });

            // Unbind first in order to avoid duplicate handler
            $('#editMember', row).on('click', () => {
              this.navigateToEditComponent(data.id);
            });
          }
        },
      };
    }
  }

  navigateToEditComponent(userId) {
    this.router.navigate(['/edit/eligible-member/', userId]);
  }

  viewEligibleUserInfo(info: any) {
    this.router.navigate(['/eligible-member/profile', info.id]);
  }

  datatablesError(xhr, _error, _thrown) {
    const errorStatus = xhr.status;
    if (errorStatus == 401) {
      this.router.navigate(['/login']);
    }
    else if (errorStatus == 404) {
      this.errorString = 'Invalid Url';
    }
    else {
      this.errorString = 'Problem occurred. Please contact your administrator';
    }
  }

  search() {
    this.datatableElement.dtInstance?.then((dtInstance: DataTables.Api) => {
      dtInstance.search(this.searchValue).draw();
    });
  }

  searchString(searchValue: string) {
    this.valueToSearch = searchValue;
    this.search();
  }

  viewUserInfo(info: any) {
    this.router.navigate(['/profile', info.user_id]);
  }

  private getColumn(user: any) {
    let columns;
    if (
      user.user_type.type.toLowerCase() === 'admin' ||
      user.user_type.type.toLowerCase() === 'careteam' ||
      user.user_type.type.toLowerCase() === 'cmmanager'
    ) {
      columns = [
        {
          title: 'Name',
          render: function (_data, _type, row, _meta) {
            return $.fn.dataTable.render.text().display(row.full_name);
          },
        },
        {
          title: 'Email',
          render: function (_data, _type, row, _meta) {
            return $.fn.dataTable.render.text().display(row.email);
          },
        },
        {
          title: 'Status',
          render: function (_data, _type, row, _meta) {
            if (row.is_deleted) {
              return 'Inactive';
            }
            else {
              return 'Active';
            }
          },
          orderable: false,
        },
      ];
    }

    return columns;
  }

  setSearchValueFromSavedState() {
    const state = localStorage.getItem(
      'DataTables_users_/active-users/translator'
    );

    if (state == null) {
      this.searchValue = '';
      return;
    }
    const searchValue = JSON.parse(state).search['search'];

    if (searchValue != null) {
      this.searchValue = searchValue;
    }
  }
}

const EMAIL_SERVICE = [
  {
    label: 'Outlook365',
    value: 'outlook365'
  },
  {
    label: 'Gmail',
    value: 'gmail'
  }
];


export { EMAIL_SERVICE };
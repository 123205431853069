/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-spread */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-patients-questions',
  templateUrl: './patients-questions.component.html',
  styleUrls: ['./patients-questions.component.css'],
})
export class PatientsQuestionsComponent implements OnInit {
  @Input() isQuestionCollapse: boolean;
  @Input() case_questions: any;
  @Input() maxFollowUpId: number;

  constructor() {}

  ngOnInit(): void {}
}

/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';

import { LoginService } from '../../../../services/login.service';
import { Router, UrlTree, UrlSegmentGroup, UrlSegment, PRIMARY_OUTLET, ActivatedRoute } from '@angular/router';
import { CommonFuntions } from '../../../../common/common.function';
import { AdminModel } from '../../../../domains/adminModel';
import { UserService } from '../../../../services/userservice.service';
import { ViewChild, ElementRef } from '@angular/core';
import { DatashareService } from '../../../../services/datashare.service';
import { PopUpService } from '../../../../services/pop-up.service';
import { findIndex } from 'lodash';
import { DataTableDirective } from 'angular-datatables';
import { permissionArr } from './permissions';

interface permissionItem {
  label: string,
  endpoint: string,
  access: boolean
}

@Component({
  selector: 'app-system-account',
  templateUrl: './system-account-upsert.html',
  styleUrls: ['./system-account-upsert.css']
})
export class SystemAccountUpsertComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: true })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  @ViewChild('languageField') languageField: ElementRef;
  @ViewChild('dateInput', { static: true }) dobInput;

  avatarMale: string;
  adminForm: FormGroup;
  adminModel = new AdminModel();
  operation: string;
  userId: number;
  uploadErrorMessage: string;
  uploadCompleteMessage: string;
  permissions: permissionItem[];
  isValidJSON = true;
  isSettings: any;
  userType: string;
  userTypeId: number;
  showPassword = true;

  addUpdateLabel: string;

  passwordReadOnly = false;
  currentUser: any;
  isSupportPassword = false;
  isRecoverPassword = false;
  isResetPassword = false;
  role: string;
  currentDate;
  isPermissionEditable = false;
  password_type = 'password';

  constructor(
    public loginService: LoginService,
    public userService: UserService,
    public router: Router,
    private route: ActivatedRoute,
    private datashare: DatashareService,
    private popUpService: PopUpService
  ) {
  }

  ngOnInit() {
    const optionObject = {
      paging: false,
      pagingType: 'full_numbers',
      lengthChange: false,
      serverSide: false,
      searching: false,
      pageLength: 25,
      order: [],
      ordering: false,
      info: false,
      stateSave: false,
      stateDuration: 5 * 60,
    };

    this.dtOptions = optionObject;

    this.currentDate = new Date();
    this.currentDate.setHours(23, 59, 59, 999);

    this.route.queryParams.subscribe(
      queries => {
        this.isSettings = queries['isSettings'] === 'true' ? true : false;
      }
    );

    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g.segments;
    console.log(s);
    this.operation = s[0].path === 'add' ? 'Add' : 'Edit';
    this.addUpdateLabel = s[0].path === 'add' ? 'Add' : 'Edit';
    this.userType = s[1].path;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const self = this;

    this.userTypeId = 9;

    this.currentUser = this.loginService.getCurrentUser();
    this.role = this.currentUser.user_type.type.toLowerCase() || '';
    this.adminForm = this.initializeForm();

    this.showPassword = true;

    this.getPermission();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const sub = this.route.params
      .subscribe(params => {
        const self = this;
        console.log('params', params);
        if (params['user_id'] && isNaN(params['user_id'])) {
          this.router.navigate(['/page-not-found']);
          return;
        }

        this.userId = +params['user_id'];

        if (this.userId) {
          const formControl = this.adminForm.get('user_id');
          if (formControl) { formControl.setValue(this.userId); }

          self.showPassword = false;

          this.addUpdateLabel = 'Save';

          self.getUserDetail();
        }
        else {
          this.avatarMale = 'assets/images/avatar-male.jpg';
          this.addFormControlOnCreate();
        }
      });
    if (this.password) this.generate(true);
  }

  getUserDetail() {
    this.userService.getUser(this.userId).subscribe(
      result => {
        console.log('result', result);
        this.adminModel = result as AdminModel;

        this.configSettings(result);

        this.checkValidEdit(result);

        this.datashare.setData(this.adminModel.full_name);
        delete result['full_name'];
        this.adminForm.addControl('user_id', new FormControl(this.adminModel.user_id));

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const user_type = '';

        this.adminForm.patchValue(result);

        this.getPermission(result);
      },
      (_error) => {
        this.router.navigate(['/']);
      }
    );
  }

  getUserIdAndLabel(_userType: string): [number, string] {
    const userTypeId = 9;
    return [userTypeId, ''];
  }

  initializeForm(): FormGroup {
    return new FormGroup(
      {
        first_name: new FormControl(this.adminModel.full_name, [
          Validators.required,
          Validators.maxLength(50)
        ]),
        email: new FormControl(this.adminModel.email, [
          Validators.required,
          CommonFuntions.ValidateEmail
        ]
        ),
        user_type_id: new FormControl(this.userTypeId)
      }, {
        updateOn: 'blur'
      }
    );
  }

  /** Add these form control only when doing create operation. */
  addFormControlOnCreate() {
    this.adminForm.addControl('password', new FormControl('', [CommonFuntions.ValidatePassword]));
    this.adminForm.addControl('auto_generate_pwd', new FormControl(true));
  }

  get first_name() {
    return this.adminForm.get('first_name');
  }


  get email() {
    return this.adminForm.get('email');
  }

  get password() {
    return this.adminForm.get('password');
  }

  public showAlert(message: string, positiveAction?: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (positiveAction) {
      console.log('set posivit actions');
      this.popUpService.setPositiveAction(positiveAction);
    }
    else {
      console.log('default set posivit actions');
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) { this.popUpService.showDiv(dialog); }
  }

  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function) {

    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }

    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }

    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);

  }

  addAdmin(event: Event, form: FormGroup) {
    event.preventDefault();

    if (CommonFuntions.guardForAction(this.loginService,
      this.router)) {
      if (form.valid) {
        if (this.operation.toLowerCase() === 'add') {
          this.showConfirmation('Are you sure you want to add?', function () {

            // check if contact method exists and remove if not present
            if (form.getRawValue().contacts) {
              let i = 0;
              form.getRawValue().contacts.forEach(element => {
                if (element.social_id == '' || element.social_type_id == 0) {
                  this.contacts.removeAt(i);
                }
                else {
                  i++;

                }
              });
            }

            const admin: AdminModel = form.getRawValue() as AdminModel;

            if (this.time_zone_selected != undefined) {
              admin.time_zone_offset = this.time_zone_selected;
            }

            this.userService.addSystemAccount({ ...admin, permissions: this.permissions }).subscribe(
              result => {
                this.userId = result.user_id;
                this.showAlert('Account Added sucessfully.', function () {
                  this.popUpService.hideDiv('alertDialog');
                  this.router.navigate(['/edit/systemaccount', result.user_id]);
                }.bind(this));
                this.popUpService.hideDiv('confirmDialog');
              }, error => {
                this.showAlert(this.userService.handleError(error, this.operation.toLowerCase()));
                this.popUpService.hideDiv('confirmDialog');
              }
            );
            this.popUpService.hideDiv('confirmDialog');
          }.bind(this));

        }
        else if (this.operation.toLowerCase() === 'edit') {
          this.showConfirmation('Are you sure you want to edit?', function () {
            if (form.getRawValue().contacts) {
              let i = 0;
              form.getRawValue().contacts.forEach(element => {
                if (element.social_id === '' || element.social_type_id === 0) {
                  this.contacts.removeAt(i);
                }
                else {
                  i++;

                }
              });
            }

            const admin: AdminModel = form.getRawValue() as AdminModel;

            if (this.time_zone_selected != undefined) {
              admin.time_zone_offset = this.time_zone_selected;
            }

            admin.user_id = this.userId;

            this.userService.updateSystemAccount({ ...admin, permissions: this.permissions }).subscribe(
              (_result) => {
                this.popUpService.hideDiv('confirmDialog');
                this.showAlert('Account updated sucessfully.', function () {
                  this.showPassword = false;
                  this.popUpService.hideDiv('alertDialog');
                  this.router.navigate(['/edit/systemaccount', this.userId]);
                }.bind(this));
              }, error => {
                this.showAlert(this.userService.handleError(error, this.operation.toLowerCase()));
                this.popUpService.hideDiv('confirmDialog');
              }
            );
            this.popUpService.hideDiv('confirmDialog');

          }.bind(this));
        }
      }
      else {
        CommonFuntions.validateAllFormFields(form);
      }
    }
  }

  randomPassword(length) {
    const chars = 'abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890';
    let pass = '';
    for (let x = 0; x < length; x++) {
      const i = Math.floor(Math.random() * chars.length);
      pass += chars.charAt(i);
    }
    return pass;
  }

  generate(checkbox: HTMLInputElement | boolean): string {
    let status;
    if (typeof (checkbox) === 'boolean') {
      status = checkbox;
    }
    else if (checkbox instanceof HTMLInputElement) {
      status = checkbox.checked;
    }
    else {
      status = false;
    }

    if (status) {
      this.password.markAsTouched({ onlySelf: true });
      const password = this.randomPassword(8);
      this.password.setValue(password, { onlySelf: true });
      this.passwordReadOnly = true;
      this.adminForm.get('password').clearValidators();
      this.adminForm.get('password').updateValueAndValidity();
      return password;
    }
    else {
      this.passwordReadOnly = false;
      this.password.reset();
      this.password.setValue('');
      this.adminForm.get('password').setValidators([Validators.required, CommonFuntions.ValidatePassword]);
      this.adminForm.get('password').updateValueAndValidity();
      return '';
    }
  }


  resetPassword(event, _link: HTMLLinkElement) {
    event.preventDefault();
    this.adminForm.removeControl('use_support_password');
    this.adminForm.addControl('use_support_password', new FormControl(false));
    if (this.showPassword && this.operation.toLowerCase() === 'edit') { return; }
    this.showPassword = true;
    if (!this.adminForm.controls.hasOwnProperty('password')) {
      this.adminForm.addControl('password', new FormControl('', [CommonFuntions.ValidatePassword]));
      this.adminForm.addControl('auto_generate_pwd', new FormControl(true));
    }
    this.generate(true);
  }

  checkValidEdit(resultUser) {
    if (this.userTypeId == resultUser.user_type_id) {
      return;
    }

    this.router.navigate(['/']);
  }

  configSettings(result) {
    /** Check settings. */
    if (this.currentUser.user_id === result.user_id) {
      this.isSettings = true;
    }

    /** Check support password */
    if (this.operation.toLowerCase() === 'edit' && this.isSettings && !result.temp_password && this.currentUser.user_type.type.toLowerCase() === 'admin') {
      this.isSupportPassword = true;
    }

    /** Check recover password */
    if (this.operation.toLowerCase() === 'edit' && this.isSettings && result.temp_password && this.currentUser.user_type.type.toLowerCase() === 'admin') {
      this.isRecoverPassword = true;
    }

    /** Check reset password */
    if (this.operation.toLowerCase() === 'edit' && this.isSettings) {
      this.isResetPassword = true;
    }

    delete result.temp_password; /** Need to delete this. */
  }

  preventDefault(event: Event) {
    const userAgent = window.navigator.userAgent;

    const browsers = { chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i };

    for (const key in browsers) {
      if (browsers[key].test(userAgent)) {
        if (key == 'firefox') {
          event.preventDefault();
        }
      }
    }
  }

  get code() {
    return JSON.stringify(this.permissions, null, 2);
  }

  set code(v) {
    try {
      console.log('e', v);
      console.log('JSON.parse(v)', JSON.parse(v));
      this.permissions = JSON.parse(v);
      this.isValidJSON = true;
    }
    catch (e) {
      console.log('error occored while you were typing the JSON');
      this.isValidJSON = false;
    }
  }

  getPermission(userDetails = null) {
    let permissions = [];
    if (userDetails) {
      permissions = userDetails.permissions || [];
    }
    else {
      permissions = permissionArr;
    }

    this.permissions = [...permissions];
  }

  toggleRawData(isChecked) {
    console.log('isChecked', isChecked);
    this.isPermissionEditable = !this.isPermissionEditable;
  }

  toggleApiData(api) {
    const apiIndex = findIndex(this.permissions, { endpoint: api.endpoint, label: api.label });
    if (apiIndex > -1) {
      this.permissions[apiIndex].access = !api.access;
    }
  }
  
  cancel(_e) {
    this.router.navigate(['/system-accounts']);
  }


  
}

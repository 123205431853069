<div class="box box--white box--no-pad box--radius">
  <div class="box__title align-items-center case-section-box">
    <div class="box-title-left flexDisplay">
      <div class="left-title mr20">Intake Form</div>
      <div *ngIf="stateDetail" [class]="'mr-auto badge ' + stateDetail.color">{{stateDetail.text}}</div>
    </div>
    <div class="box-title-right flexDisplay">
      <button *ngIf="(role=='translator' || role==='admin' || role==='cmmanager') && state == 'SUBMITTED'"
        class="btn btn--blue btn--save btn--small d-flex align-items-center ml-2 mr20"
        (click)="handleFormSubmit($event, intakeForm, 'edit')">
        <div class="label mr-1">Edit</div>
        <span class="icon icon--loading" *ngIf="isLoading"></span>
      </button>
      <a href="javascript:void(0)" class="go-back pull-right collapseToggle top10"
        [ngClass]="{collapseToggleActive: isIntakeFormCollapse===true}"
        (click)="isIntakeFormCollapse = !isIntakeFormCollapse">
        <span class="icon icon--time-nav"></span>
      </a>
    </div>
  </div>
  <div [hidden]="!isIntakeFormCollapse" class="document__content tracking-wrapper">
    <form [formGroup]="intakeForm" novalidate>
      <div class="row" 
        cdkDropList
        (cdkDropListDropped)="drop($event)"
        [cdkDropListSortPredicate]="sortPredicate" >
        <ng-template ngFor let-field [ngForOf]="formFields">
          <ng-container *ngIf="isNotTranslatorField(field); else default_field">
            <div cdkDrag [cdkDragDisabled]="true" *ngIf="role !== 'translator'" class="col__md-6 gutter--right-2x is_relative">
              <!-- <div cdkDragHandle class="drag_handle" ></div> -->
              <div *ngIf="field.toLowerCase() !== 'gender'; else gender" class="form__row">
                <label for="" class="form__label">{{ field }}</label>
                <div class="form__field">
                  <input type="text" formControlName="{{generateFormName(field)}}" 
                    [attr.disabled]="true" 
                    [attr.readonly]="true"
                    [attr.maxLength]="undefined"/>
                </div>
              </div>

              <ng-template #gender>
                <div class="form__row">
                  <label for="" class="form__label">Gender</label>
                  <div class="form__field">
                    <select formControlName="gender" [attr.disabled]="true" aria-readonly="true">
                      <option disabled value="M">Male</option>
                      <option disabled value="F">Female</option>
                      <option disabled value="U">Unspecified</option>
                    </select>
                  </div>
                </div>
              </ng-template>
            </div>
          </ng-container>
          <ng-template #default_field>
            
            <ng-container *ngIf="isHeightOrWeight(field); else nonHeightOrWeight">
              <div class="col__md-6 gutter--right-2x is_relative ">
                <label for="" class="form__label">{{ field }}</label>

                <div class="form__row">
                  <div class="form__field">
                    <input type="text" formControlName="{{generateFormName(field)}}" 
                    [attr.disabled]="isFormDisabled() || null" 
                    [attr.readonly]="isFormDisabled() || null"
                    [attr.maxLength]="11"/>
                  </div>
                </div>
                
              </div>
            </ng-container>

            <ng-template #nonHeightOrWeight>
              <div cdkDrag class="col__md-12 gutter--right-2x is_relative" [cdkDragDisabled]="!isDraggable()">
                <div *ngIf="isDraggable()" cdkDragHandle class="drag_handle" >
                  <i class="fa fa-bars isHover" aria-hidden="true"></i>
                </div>
                <div class="form__row">
                  <label for="" class="form__label">{{field}}</label>
                  <div class="form__field">
                    <ckeditor type="text" formControlName="{{generateFormName(field)}}"
                      [config]="{ toolbar: [
                      { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', 'NumberedList', 'BulletedList','Indent','Outdent'] }
                      ]}"
                      [attr.disabled]="isFormDisabled()" 
                      [readonly]="isFormDisabled()"
                      ></ckeditor>
                  </div>
                </div>
              </div>
            </ng-template>

          </ng-template>
        </ng-template>
      </div>
      <div class="row">
        <div class="col col__md-12 d-flex">
          <button
            *ngIf="(role==='translator' || role==='admin' || role==='cmmanager') && (state === 'DRAFT' || state === 'SAVED')"
            class="btn btn--blue btn--save d-flex align-items-center"
            (click)="handleFormSubmit($event, intakeForm, 'save')">
            <div class="label mr-1">Save</div>
            <span class="icon icon--loading" *ngIf="isLoading && buttonKey==='save'"></span>
          </button>
          <button
            *ngIf="(role==='translator' || role==='admin' || role==='cmmanager') && (state === 'DRAFT' || state === 'SAVED')"
            class="btn btn--blue btn--save d-flex align-items-center"
            (click)="handleFormSubmit($event, intakeForm, 'submit')">
            <div class="label mr-1">Submit</div>
            <span class="icon icon--loading" *ngIf="isLoading && buttonKey==='submit'"></span>
          </button>
          <button *ngIf="(role==='careteam' || role==='admin' || role==='cmmanager' || role ==='casecoordinator') && state === 'SUBMITTED'"
            class="btn btn--blue btn--save d-flex align-items-center"
            (click)="handleFormSubmit($event, intakeForm, 'lock')">
            <div class="label mr-1">Lock</div>
            <span class="icon icon--loading" *ngIf="isLoading && buttonKey==='lock'"></span>
          </button>
          <button *ngIf="(role==='careteam' || role==='admin' || role==='cmmanager' || role ==='casecoordinator') && state === 'APPROVED'"
            class="btn btn--blue btn--save d-flex align-items-center"
            (click)="handleFormSubmit($event, intakeForm, 'unlock')">
            <div class="label mr-1">Unlock</div>
            <span class="icon icon--loading" *ngIf="isLoading && buttonKey==='unlock'"></span>
          </button>
          <button *ngIf="(role==='careteam' || role==='admin' || role==='cmmanager' || role ==='casecoordinator') && state === 'APPROVED'"
            class="btn btn--blue btn--save d-flex align-items-center" (click)="generateIntakeFormPdf($event)">
            <div class="label mr-1">Generate PDF</div>
            <span class="icon icon--loading" *ngIf="isGeneratingPdf"></span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
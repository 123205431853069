<app-dialog></app-dialog>
<div class="header">
    <div class="header__page-name">
        <h1 class="heading heading--main">{{operation}} System Account</h1>
    </div>
</div>
<div class="wrapper--dash-content dash-content">
    <form [formGroup]="adminForm">
        <div class="form tabs__content--white-bg">
            <h3 class="form__title">Account Information</h3>
            <div class="form--two-cols">
                <div class="row">
                    <div class="col col__md-6 gutter--right-3x">
                        <div class="form__row">
                            <label for="" class="form__label">Label</label>
                            <div class="form__field">
                                <input type="text" id="email" formControlName="first_name" />
                                <div *ngIf="first_name.touched && first_name.invalid"
                                    class="message message--is-invalid">
                                    <div *ngIf="first_name.errors.required">this field is required.</div>
                                    <div *ngIf="first_name.errors.first_name && !first_name.errors.required">Please
                                        enter a valid label
                                        address</div>
                                </div>
                            </div>
                        </div>

                        <div class="form__row" *ngIf="operation != 'Edit' || showPassword == true">
                            <label for="" class="form__label">Password</label>
                            <div class="form__field">
                                    <div class="input-group" id="show_hide_password">
                                        <input type="password" id="password" formControlName="password" [readonly]="passwordReadOnly" class="form-control"/>
                                        <button type="button" class="btn btn-secondary" (click)="$event.preventDefault()" appPasswordToggle>
                                          <span [ngClass]="password_type == 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'"></span>
                                        </button>
                                      </div>
                                <div *ngIf="password.touched && password.invalid" class="message message--is-invalid">
                                    <div *ngIf="password.errors.required">this field is required.</div>
                                    <div *ngIf="password.errors.password && !password.errors.required">Password should
                                        be minimum 8 characters, at least one capital letter and at least one small
                                        letter.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col col__md-6 gutter--right-3x">
                        <div class="form__row">
                            <label for="" class="form__label">Email</label>
                            <div class="form__field">
                                <input type="email" id="email" formControlName="email" />
                                <div *ngIf="email.touched && email.invalid" class="message message--is-invalid">
                                    <div *ngIf="email.errors.required">this field is required.</div>
                                    <div *ngIf="email.errors.email && !email.errors.required">Please enter a valid email
                                        address</div>
                                </div>
                            </div>
                        </div>

                        <div class="form__row" *ngIf="operation != 'Edit' || showPassword == true">
                            <label for="" class="form__label label--dark"></label>
                            <div class="form__field" style="margin-top: 50px;">
                                <div class="form__field--checkbox">
                                    <input id="autogenerate" type="checkbox" formControlName="auto_generate_pwd"
                                        (change)="generate(checkbox)" #checkbox />
                                    <label for="autogenerate">Auto-Generated Password</label>
                                    <!-- <div *ngIf="this.adminForm.get('auto_generate_pwd').value" ><strong>{{password.value}}</strong></div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <!-- API Permissions -->

                <div class="row">
                    <div class="form__field" style="padding: 10px 0px;">
                        <div class="form__field--checkbox" style="text-align: end;">
                            <input type="checkbox" [checked]="isPermissionEditable"
                                (click)="toggleRawData(cbAddRemoveDR.checked)" #cbAddRemoveDR />
                            <label for="option" (click)="toggleRawData(cbAddRemoveDR.checked)">Show Raw Data</label>
                        </div>
                    </div>
                    <table datatable [dtOptions]="dtOptions" [hidden]="isPermissionEditable">
                        <thead>
                            <tr>
                                <th>Endpoints</th>
                                <th style="text-align: center;">Grant Access</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of permissions">
                                <td>
                                    <p style="margin: 0;" (click)="toggleApiData(item)">{{item.label}}</p>
                                    <span style="font-size: 10px;margin: 0;" (click)="toggleApiData(item)">{{item.type}} : </span>
                                    <span style="font-size: 10px;margin: 0; font-weight: 600;" (click)="toggleApiData(item)">{{item.endpoint}}</span>
                                </td>
                                <td style="text-align: center;">
                                    <input type="checkbox" [checked]="item.access" (click)="toggleApiData(item)" style="cursor: pointer;"/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="isPermissionEditable">
                        <ace-editor [(text)]="code" mode="json" [autoUpdateContent]="true" style="height: 500px;margin-top: 10px;"></ace-editor>
                    </div>
                </div>
            </div>
        </div>

        <div class="btn-row text-center">
            <a href="#" class="btn btn--green" (click)="resetPassword($event, link)" *ngIf="operation.toLowerCase() === 'edit'" #link>Reset
                Password</a>
            <a href="#" class="btn btn--green" (click)="addAdmin($event, adminForm)">{{addUpdateLabel}}</a>
            <a routerLink="/system-accounts" class="btn btn--red" 
                *ngIf="operation.toLowerCase() === 'edit'">Cancel</a>
        </div>
    </form>
</div>
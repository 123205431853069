
<div class="mt-5" *ngIf="currentUser.user_type.type.toLowerCase() === 'admin' && UserRoleLength > 0">
    <div class="client-list-item" (click)="showUserRoles = !showUserRoles">
        <a><span class="icon icon--settings  icon--setting mt-1"></span> User Setting</a>
        <a href="javascript:void(0)" class="go-back pull-right collapseToggle" 
            [ngClass]="{collapseToggleActive: showUserRoles===true}">
            <span class="icon icon--time-nav"></span></a>
    </div>
    <br>
    <ul [hidden]="!showUserRoles" class="profile-card__details-list client-list-item-ul">
        <div class="col-md-12">
            <div class="row">
                <div class="col__md-4">
                    Change User Role
                </div>
                <div class="col__md-6">
                    <div class="form__field">
                        <form [formGroup]="currentRoleForm">
                            <select formControlName="new_user_role">
                                <option [attr.disabled]="true" selected>{{currentRoleName}}</option>
                                <option *ngFor="let role of userRoleList;" [value]="role.User_Type_Id">{{role.User_Role}}
                                </option>
                            </select>
                        </form>
                    </div>
                </div>
                <div class="col__md-2">
                    <button class="btn btn--mini btn--green"
                        (click)="getRoleName()">Edit</button>
                    <swal #changeButtonSwal title="Confirmation"
                        (confirm)="changeUserRole()" backdrop="#eef3f6" type="warning" confirmButtonText="Change Role"
                        iconColor="orange" cancelButtonColor="#db161e" reverseButtons="true" showCancelButton="true"
                        confirmButtonColor="green" icon="question" closeOnConfirm="false"
                        closeOnCancel="false">
                        <div class="form__field" *swalPortal>
                            <swal-title> Are you sure you want to change the user role for :
                            <br>
                            <b class="text-center">{{userToEdit.email}}</b></swal-title>
                            <hr>
							<swal-title class="mt-2">Associated Cases : <b>{{casesAssignedCount}}</b>
                            </swal-title>
                            <br>
                            <swal-title class="mt-2">Current Role : <b>{{currentRoleName}}</b>
                            </swal-title>
                            <br>
                            <swal-title class="mt-2">New Role : {{newRoleName}}</swal-title>
                            <hr>
                        </div>
                    </swal>
                    <swal #editRoleConfirmation title="Success" text="Current user role has been changed successfully changed to {{newRoleName}}"
                        backdrop="#eef3f6" type="success" confirmButtonText="Close"
                        iconColor="green" cancelButtonColor="#db161e" buttons="false"
                        confirmButtonColor="green" icon="success" closeOnConfirm="false">
                    </swal>
                </div>
            </div>
        </div>
    </ul>
    <br>
</div>
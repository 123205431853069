/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-partner-setting',
  templateUrl: './partner-setting.component.html',
  styleUrls: ['./partner-setting.component.css']
})
export class PartnerSettingComponent implements OnInit {
  @Input() settingForm: FormGroup;

  constructor() { }

  initializeForm() {
    this.settingForm.addControl('isElevatedPartner', new FormControl(false));
  }

  ngOnInit(): void {
    this.initializeForm();
  }

}

/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable max-len */
/* eslint-disable no-undef */
import {
  Directive,
  ElementRef
} from '@angular/core';

@Directive({
  selector: '[NumbersOnly]'
})
export class NumbersOnlyDirective {

  constructor(
		public el: ElementRef
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const numberpattern = '/^\d+$/';

    el.nativeElement.onkeypress = (event) => {
      let stop = false;
      if (event.which < 48 || event.which > 57) {
        stop = true;
      }

      if (event.key == 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        stop = false;
      }

      if (stop) {
        event.preventDefault();
      }
    };

    const inputElement: HTMLElement = el.nativeElement;
    inputElement.onpaste = (event) => {
      const value = event.clipboardData.getData('text');

      if (!(/^\+?\d*$/.test(value))) {
        event.preventDefault();
      }
    };
  }

}

<div class="popup tracking-details" [hidden]='shouldHide'>
    <div class="popup__overlay"></div>
    <div class="popup__video overlay__wrapper"  [class.overlay--visible]="loading">
        <div class="overlay__content" [class.overlay--visible]="loading">
            <div class="overlay__spinner">
                <span class="icon icon--loading"></span>
            </div>
        </div>
        <div class="popup__header d-flex justify-content-between">
            <h1>Medical Record Collection Details</h1>
            <button class="btn btn--small btn--red" (click)="toggleThisTemplate()">X</button>
        </div>
        <div class="popup__content tracking_content">
            <fieldset class="mb-2">
                <legend>Tracking Details</legend>
                <div class="form__row tracking-detail-section mb-0">
                    <div class="col col__md-6">
                        <div class="form__label">Tracking Number: <span class="text-black">{{ trackingLineObj?.tl_number || 'N/A'}}</span></div>
                        <div class="form__label">Status: <span class="text-black">{{ trackingLineObj?.tl_status }}</span></div>
                        <div class="form__label">Type: <span class="text-black">{{ trackingLineObj?.tl_type }}</span></div>
                    </div>
    
                    <div class="col col__md-6">
                        <div class="form__label">Facility: <span class="text-black">{{ trackingLineObj?.tl_facilityName }}</span></div>
                        <div class="form__label">Provider: <span class="text-black">{{ trackingLineObj?.tl_provider_name }}</span></div>
                        <div class="form__label">Owner: <span class="text-black">{{ trackingLineObj?.tl_owner_name }}</span></div>
                    </div>
                    <div class="col col__md-3">
                        <div class="form__label text-black"></div>
                        <div class="form__label text-black"></div>
                        <div class="form__label text-black"></div>
                    </div>
                </div>
            </fieldset>

            <div class="tabs">
                <ul class="tabs__tab tabs__tab--dash-table mb-2">
                    <li [class.tab-active]="(tabs | async) == null || (tabs | async)?.toLowerCase() === 'notes'">
                        <a href="#" (click)="changeTab('notes', $event)">Notes</a>
                    </li>
                    <li [class.tab-active]="(tabs | async)?.toLowerCase() === 'documents'">
                        <a href="#" (click)="changeTab('documents', $event)">Documents</a>
                    </li>
                    <li [class.tab-active]="(tabs | async)?.toLowerCase() === 'images'">
                        <a href="#" (click)="changeTab('images', $event)">Images</a>
                    </li>
                </ul>

                <!-- Notes Tab -->
                <div class="tabs__content overlay__wrapper overlay--visible mt-1" *ngIf="(tabs | async) == null || (tabs | async)?.toLowerCase() === 'notes'">
                    <form [formGroup]="trackingNoteForm">
                        <div class="form__row">
                            <div class="form__field">
                                <input type="text" name="title" placeholder="Title" formControlName="title" disabled class="mb-2 mt-2">
                                <div *ngIf="trackingNoteForm.get('message').touched && trackingNoteForm.get('message').invalid" class="message message--is-invalid">
                                    <div *ngIf="trackingNoteForm.get('message').errors.maxlength">This field cannot exceed more than
                                        {{trackingNoteForm.get('message').errors.maxlength.requiredLength}} characters.</div>
                                    <!-- <div class="mt-2" *ngIf="trackingNoteForm.get('message').errors.required">Message/Note cannot be empty.</div> -->
                                </div>
                            </div>
                            <div class="form__field">
                                <ckeditor class="customheader" formControlName="message" placeholder="Add your note" [readonly]='isMdManager' #ckeditorField [config]="{toolbar: []}"></ckeditor>
                                <div *ngIf="trackingNoteForm.get('message').touched && trackingNoteForm.get('message').invalid" class="message message--is-invalid">
                                    <div *ngIf="trackingNoteForm.get('message').errors.maxlength">This field cannot exceed more than
                                        {{trackingNoteForm.get('message').errors.maxlength.requiredLength}} characters.</div>
                                    <!-- <div class="mt-2" *ngIf="trackingNoteForm.get('message').errors.required">Message/Note cannot be empty.</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="btns-row text-right" >
                            <button *ngIf='!isMdManager' class="btn btn--blue" [disabled]="trackingNoteForm.invalid" (click)="postComment()">Submit</button>
                        </div>
                    </form>
                    <!-- Message Listing -->
                    <div class="text-center" *ngIf="loadingNotes; else notes"><h4>Loading notes...</h4></div>
                    <ng-template #notes>
                        <div id="activity-container" *ngFor="let note of trackingLineNoteList">
                            <div class="d-flex w-100 h-100">
                            <div class="profile-avatar">
                                <!-- <img src="/assets/images/profile-pic-male.png" alt=""> -->
                                <img [src]="getImageUrl(note)" alt="">
                            </div>
                            <div class="w-100 h-100">
                                <p class="activity-title-light title text-bold"> {{note.title}}</p>
                                <div class="activity-title-light message note" [innerHTML]="note.activity | safeHtml"></div>
                                <div class="d-flex justify-content-between w-100">
                                <p class="activity-title-light info">{{note.message_from_name || note?.user?.full_name }} ({{ note.message_from_name ? 'Ally User' : note?.user?.user_type?.type }})</p>
                                <p class="activity-title-light info">{{ note?.created_at | date: 'MM/dd/yyyy, h:mm a' }}</p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </ng-template>
                </div>

                <!-- Document Uploads Tab -->
                <div class="tabs__content overlay__wrapper overlay--visible mb-2 mt-1" *ngIf="(tabs | async)?.toLowerCase() === 'documents'">
                    <fieldset>
                        <legend>Upload Document</legend>
                        <div class="form__row mb-0 d-flex align-items-center">
                            <div class="col col__md-6 gutter--right-2x">
                                <div class="form__row">
                                    <label for="" class="form__label">Type</label>
                                    <div class="form__field">
                                        <select class="text--black" [disabled]='isMdManager' #documentType>
                                            <ng-container *ngFor="let type of types">
                                                <option [value]="type.value">
                                                    {{type.key}}</option>
                                            </ng-container>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col col__md-6 gutter--right-2x">
                                <div class="form__field upload-btn-file">
                                    <div class="form__field upload">
                                        <input type="file" class="btn btn--grey btn--small" name="document" #trackingDocument
                                            ng2FileSelect [disabled]='isMdManager' [uploader]="uploaderObj" />
                                        <a class="btn btn--grey btn--small btn--full-width" [disabled]='isMdManager' href="#">Browse</a>
                                    </div>
                                    <div class="uploaded-file">
                                        {{trackingDocument.value == null || trackingDocument.value === '' ? 'No File Choosen' :
                                        trackingDocument?.files?.item(0)?.name}}
                                    </div>
                                </div>
                            </div>
                            <div class="btns-row text-right">
                                <button *ngIf='!isMdManager' [disabled]="uploaderObj.queue.length < 1" (click)="uploadFile(documentType)" class="btn btn--blue btn-upload-doc margin--top-22">
                                    {{!uploading ? "Upload" : "Uploading..."}}
                                </button>
                            </div>
                        </div>
                    </fieldset>
                </div>

                <!-- Scan Link Upload -->
                <div class="tabs__content overlay__wrapper overlay--visible mb-2" *ngIf="(tabs | async)?.toLowerCase() === 'documents'">
                    <fieldset>
                        <legend>Uploads</legend>
                        <div class="contact-details__row--single">
                            <ul  *ngIf="getDocuments(trackingLineObj.tl_id).length>0; else noDocument" class="doc-list__list doc-list__list--sidebar fileList">
                                <li class="document-container" *ngFor="let document of getDocuments(trackingLineObj.tl_id)" [title]="getTitle(document)">
                                    <span class="icon icon--uploaded-file m-1"></span>
                                    <div class="fileName">
                                        <a href="#" (click)="downloadDoc(document.id, document.document_name, $event)" [target]="'_blank'" class="file-name"> {{document.document_name}} </a>
                                        <span (click)="deleteDocument(document)" class="delete-icon" *ngIf='!isMdManager'>x</span>
                                    </div>
                                </li>
                            </ul>
                            <ng-template #noDocument>
                                <p>No Documents with tracking line.</p>
                            </ng-template>
                        </div>
                    </fieldset>
                </div> 

                <!-- Images Tab -->
                <div class="tabs__content overlay__wrapper overlay--visible mt-2" *ngIf="(tabs | async)?.toLowerCase() === 'images'">
                    <fieldset class="mb-2">
                        <legend>Scan Link</legend>
                        <div class="form__row mb-1">
                            <div class="col col__md-4 gutter--right-2x">
                                <label for="" class="form__label">Name</label>
                                <div class="form__field">
                                    <input type="text" name="document_name" [disabled]='isMdManager' #documentName />
                                    <div class="error">{{errors && errors.scanName}} </div>
                                </div>
                            </div>
                            <div class="col col__md-6 gutter--right-2x">
                                <div class="form__row">
                                    <label for=" " class="form__label">Link</label>
                                    <div class="form__field">
                                        <input type="text" name="location" [disabled]='isMdManager' #documentLocation />
                                        <div class="error">{{errors && errors.scanLink}} </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col__md-2 gutter--right-2x">
                                <a href="#"  *ngIf='!isMdManager'
                                    class="btn btn--blue btn--full-width btn-upload-doc margin--top-22"
                                    (click)="editAddScan(documentName, documentLocation, 'uploads',$event)"
                                    >
                                    <span class="icon icon--loading "
                                    *ngIf="isLinkUploading"></span> {{isLinkUploading
                                ? '': 'Add Scan'}}  
                                </a>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset class="mt-2">
                        <legend>Scan Links</legend>
                        <div class="contact-details__row--single">
                            <ul  *ngIf="getScanLinks(trackingLineObj.tl_id).length>0; else noScans" class="doc-list__list doc-list__list--sidebar fileList">
                                <li class="document-container" *ngFor="let document of getScanLinks(trackingLineObj.tl_id)" [title]="getTitle(document)">
                                    <span class="icon icon--uploaded-link m-1"></span>
                                    <div class="fileName">
                                        <a [href]="document.location" [target]="'_blank'" class="file-name"> {{document.document_name}} </a>
                                        <span (click)="deleteDocument(document)" class="delete-icon" *ngIf='!isMdManager'>x</span>
                                    </div>
                                </li>
                            </ul>
                            <ng-template #noScans>
                                <p>No Scan Links with tracking line.</p>
                            </ng-template>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
</div>
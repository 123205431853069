<div class="header">
	<div class="header__page-name">
		<h1 class="heading heading--main">{{title}}</h1>
	</div>
</div>

<div class="wrapper--dash-content dash-content">
	
	<div class="row">
		<div class="col col__md-10 single-centered-col">
			<div class="tabs">
				<ul *ngIf="operation === 'add'" class="tabs__tab">
					<li [class.tab-active]="(activeTab == 'admin')" *ngIf="role?.toLowerCase() === 'admin'">
						<a routerLink="admin" [skipLocationChange]="false" (click)="activateTab('admin')" title="Admin">Admin</a>
					</li>
					<li [class.tab-active]="(activeTab == 'careteam')" *ngIf="role?.toLowerCase() === 'admin' || role?.toLowerCase() === 'careteam' || role?.toLowerCase() === 'cmmanager'">
						<a routerLink="careteam" [skipLocationChange]="false" (click)="activateTab('careteam')" title="Careteam">Careteam</a>
					</li>
					<li [class.tab-active]="(activeTab == 'casecoordinator')" *ngIf="role?.toLowerCase() === 'admin' || role?.toLowerCase() === 'casecoordinator' || role?.toLowerCase() === 'cmmanager'">
						<a routerLink="casecoordinator" [skipLocationChange]="false" (click)="activateTab('casecoordinator')" title="casecoordinator">CaseCoordinator</a>
					</li>
					<li [class.tab-active]="(activeTab == 'partner')" >
						<a routerLink="partner" [skipLocationChange]="false" (click)="activateTab('partner')" title="Client Careteam">Client Careteam</a>
					</li>
					<li [class.tab-active]="(activeTab == 'doctor')" *ngIf="role?.toLowerCase() === 'admin' || role?.toLowerCase() === 'careteam' || role?.toLowerCase() === 'cmmanager'">
						<a routerLink="doctor" [skipLocationChange]="false" (click)="activateTab('doctor')" title="Doctor">Doctor</a>
					</li>
					<!-- <li [class.tab-active]="(activeTab == 'doctor')">
						<a routerLink="doctor"  (click)="activateTab('doctor')">Doctor</a>
					</li> -->
					<li [class.tab-active]="(activeTab == 'patient')">
						<a routerLink="patient" [skipLocationChange]="false" (click)="activateTab('patient')" title="Member">Member</a>
					</li>
					<li [class.tab-active]="(activeTab == 'translator' || activeTab == 'interpreter')" *ngIf="role?.toLowerCase() === 'admin' || role?.toLowerCase() === 'careteam'">
						<a routerLink="translator" [skipLocationChange]="false" (click)="activateTab('translator')" title="Summary Coordinator">Summary Coordinator</a>
					</li>
					<li [class.tab-active]="(activeTab == 'eligible-member')" *ngIf="role?.toLowerCase() === 'admin'">
						<a routerLink="eligible-member" [skipLocationChange]="false" (click)="activateTab('eligible-member')" title="Eligible Members">Eligible Member</a>
					</li>
					<li [class.tab-active]="(activeTab == 'mdmanager')" *ngIf="role?.toLowerCase() === 'admin'">
						<a routerLink="mdmanager" [skipLocationChange]="false" (click)="activateTab('mdmanager')" title="MD Manager">MD Manager</a>
					</li>
					<li [class.tab-active]="(activeTab == 'cmmanager')" *ngIf="role?.toLowerCase() === 'admin'">
						<a routerLink="cmmanager" [skipLocationChange]="false" (click)="activateTab('cmmanager')" title="CM Manager">CM Manager</a>
					</li>
				</ul>

				<router-outlet></router-outlet>

			</div>
		</div>
	</div>

</div>
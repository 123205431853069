<app-dialog></app-dialog>

<div class="header">
  <div class="header__page-name">
    <h1 class="heading heading--main">Report Settings</h1>
  </div>
</div>

<div class="wrapper--dash-content dash-content">
    <div class="form tabs__content--white-bg">
        <div class="row">
            <form [formGroup]="reportSettingForm">
                <h4 class="form__subtitle">Case Details for selected MD</h4>
                <div class="row">
                    <div class="col col__md-6 gutter--right-3x">
                        <div class="form__row">
                          <label for="" class="form__label">Type MD Name</label>
                          <div class="partnerList form__field is-sarch-input">
                            <input type="text" auto-complete [source]="MDList" [list-formatter]="suggestionListFormatter"
                              [value-formatter]="valueListFormatter.bind(this)" [re-focus-after-select]="false" #pat />
                            <div class="search-list">
                              <div class="search-list" formArrayName="mdControl">
                                <div class="search-item" *ngFor="let user of mdControl['controls']">
                                  {{user.value.doctor}}
                                  <span (click)="clearUser(user)">X</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
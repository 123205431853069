import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { LoginService } from './services/login.service';
import { AppRoutingModule } from './routers/app-routing.module';
import { GuardService } from './services/guard.service';
import { DashboardModule } from './dashboard/dashboard.module';
import { UserService } from './services/userservice.service';
import { CommonService } from './services/common.service';
import { PartnersModule } from './dashboard/partners/partners.module';
import { PartnerService } from './services/partner.service';
import { DatashareService } from './services/datashare.service';
import { CasesModule } from './cases/cases.module';
import { MyDashboardModule } from './my-dashboard/my-dashboard.module';
import { DoctorService } from './services/doctor.service';
import { ConfigAssetLoaderService, SocketConfig } from './services/config-asset-loader.service';
import { ConfigLoaderProviderFactory } from './providers/config-loader-provider-factory';
import { SocketIoModule } from 'ngx-socket-io';
import { PopUpService } from './services/pop-up.service';
import { SharedModule } from './shared/shared.module';
import { CaseAssignmentsModule } from './case-assignments/case-assignments.module';
import { EditCaseAssignmentsModule } from './case-assignments/edit-case-assignments/edit-case-assignments.module';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';
import { TimeoutService } from './services/timeout.service';
import { AuthGuardService } from './services/auth-guard.service';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PasswordService } from './services/password.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PasswordResetService } from './services/password-reset.service';
import { MainPipe } from './custom-pipe/main-pipe.module';
import { SignupComponent } from './signup/signup.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NotificationService } from './services/notification.service';
import { ErrorsHandlerService } from './errorshandler.service';
import { HealthCheckComponent } from './health-check/health-check.component';
import { ToastrModule } from 'ngx-toastr';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    PageNotFoundComponent,
    SignupComponent,
    HealthCheckComponent,
  ],
  imports: [
    MyDashboardModule,
    CaseAssignmentsModule,
    EditCaseAssignmentsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    DashboardModule,
    PartnersModule,
    CasesModule,
    SharedModule,
    MomentModule,
    MainPipe,
    NgIdleKeepaliveModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FontAwesomeModule,
    SweetAlert2Module.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true
    }),
    BrowserAnimationsModule,
    SocketIoModule.forRoot(SocketConfig),
  ],
  providers: [
    ConfigAssetLoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigLoaderProviderFactory,
      deps: [ConfigAssetLoaderService],
      multi: true
    },
    LoginService,
    GuardService,
    UserService,
    CommonService,
    PartnerService,
    DatashareService,
    DoctorService,
    PopUpService,
    TimeoutService,
    AuthGuardService,
    PasswordService,
    PasswordResetService,
    NotificationService,
    ErrorsHandlerService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}

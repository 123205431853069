<div class="popup" [hidden]="hideTemplate" #DELETEUSER>
    <div class="popup__overlay"></div>
    <div class="container">
      <div class="row">
      <div class="popup__container">
          <form [formGroup]="deleteUserForm">
              <div class="popup__header">
                  <h1>In Activate User</h1>
                  <a href="#" class="popup__close" (click)="closeDeleteBox($event)">
                      <span class="icon icon--cross"></span>
                  </a>
              </div>
              <div class="popup__content--with-bg">
                  <div class="text--small text--bold">
                      <span class="inline-block padding-r-2x">User to delete: {{ name }}</span>
                      <a href="#" class="link-btn text--uppercase"></a>
                  </div>
              </div>
              <div class="popup__content">
                  <div class="form__row">
                      <div class="form__label">Please let us know why you are <span class="badge bg-secondary">inactivating</span> this user</div>
                      <div class="form__field">
                          <textarea formControlName="delete_reason" [class.validation--is-invalid]="delete_reason.touched 
                          && delete_reason.invalid">Enter Reason</textarea>
                          <div *ngIf="delete_reason.touched && delete_reason.invalid" class="message message--is-invalid">
                              <div *ngIf="delete_reason.errors.required">This field is required.</div>
                              <div *ngIf="delete_reason.errors.maxlength">This field must not
                                  contain more than {{delete_reason.errors.maxlength.requiredLength}} characters.
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="popup__footer popup__footer--centered">
                  <a href="#" [ngClass]="{'disabled': deleteUserForm.invalid}" class="btn btn--green" (click)="deleteDoctor($event)">Inactivate User</a>
              </div>
          </form>
      </div>
      </div>
    </div>
  </div>
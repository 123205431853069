/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { PartnerService } from '../../services/partner.service';

/**
 * Logo component hold project logo and button to inflate or collapse side navigation drawer.
 */
@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})

export class LogoComponent implements OnInit {

  /**
   * Holds path for profile image.
   */
  logoPath = '/assets/images/logos/Alight_H.png';
  logoPathv = '/assets/images/logos/Alight_V.png';

  /**
   * Event emitter to parent component 'dashboard component' that emits state of side navigating drawer
   */
  @Output() collapseEmitter = new EventEmitter<boolean>();

  /**
   * Stores the state of side navigating drawer.
   */
  isCollapsed = false;

  /**
   * Stores the role of the current user.
   */

  // This variable is set to fix the logo glitch issue
  showLogo = false;

  // This variable handles the logo visibility based on the role
  currentUser;
  showLogoRole = true;
  shouldFetchPartnerLogo;

  constructor(private loginService: LoginService, private partnerService: PartnerService){}

  /**
   * set up path of display picture.
   */
  ngOnInit(): void {
    this.currentUser = this.loginService.getCurrentUser();
    this.shouldFetchPartnerLogo = this.checkPartnerLogoFetch();
    this.getRole();
  }

  public checkPartnerLogoFetch(): boolean {
    let check = false;

    const partnerCount = this.currentUser.partners.length || 0;
    const userRole = this.currentUser.user_type.type.toLowerCase();
    if ((userRole === 'patient' || userRole === 'partner') && partnerCount === 1) {
      check = true;
    }

    return check;
  }

  // Function that fetch the currently logged in user details and and again call the 
  // another function to fetch the partner logo hash data,passing the partner id 
  private getRole(): void {
    if (this.shouldFetchPartnerLogo) {
      this.getPartnerLogo(this.currentUser.partners[0].partner_id);
    }
    else{
      this.showLogo = true;
      this.showLogoRole = true;
    }
  }

  // Function calls the another function to fetch actual logo of partner using partner id 
  // and hash file
  private getPartnerLogo(id: number): void {
    this.partnerService.getPartner(id).subscribe((partner: any)=> {
      if(!partner.partner_logo){
        this.showLogo = true;
        this.showLogoRole = true;
      }
      else {
        this.getPartnerActualLogo(id, partner.partner_logo);	
      }
    });
  }

  // This function returns the base64 data of the partner Image and assign to logoPath
  private getPartnerActualLogo(id: number, hashFile: string): void {
    this.partnerService.getPartnerLogo(id, hashFile).subscribe((logo) =>{
      this.logoPath = 'data:image/png;base64,' + logo;
      this.showLogo = true;
      this.showLogoRole = false;
    },
    (_error) => {
      this.showLogo = true;
      this.showLogoRole = true;
    });
  }

  /**
   * Changes the state of drawer.
   * Later, emits the state to dashboard component.
   */
  actionDrawer(): void {
    this.isCollapsed = this.isCollapsed == true ? false : true;
    this.collapseEmitter.emit(this.isCollapsed);
  }
}

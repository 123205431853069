/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EmailTemplateService } from '../../../services/email-template.service';
import { EmailTemplateModel } from '../../../domains/EmailTemplateModel';
import { PopUpService } from '../../../services/pop-up.service';

@Component({
  selector: 'app-view-email-template',
  templateUrl: './view-email-template.component.html',
  styleUrls: ['./view-email-template.component.css']
})
export class ViewEmailTemplateComponent {
  isInprogress = false;
  templateId: number;
  templateObj: EmailTemplateModel;

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private EmailTemplateService: EmailTemplateService,
    private popUpService: PopUpService
  ) {
    this.activatedRoute.params.subscribe(
      params => {
        const { id } = params;
        this.templateId = id;
        this.getEmailTemplateDetail(this.templateId);
      }
    );
  }

  getEmailTemplateDetail(id: number): void {
    this.isInprogress = true;
    this.EmailTemplateService.getEmailTemplateById(id).subscribe(
      result => {
        const { status, data } = result;
        if (status === 200) {
          this.isInprogress = false;
          this.templateObj = new EmailTemplateModel(data);
        }
      },
      error => {
        console.log(error);
        this.isInprogress = false;
      }
    );
  }

  deleteTemplate() {
    const message = 'Are you sure you want to delete this template.';
    const deleteAction = function() {
      this.popUpService.hideDiv('alertDialog');
      this.router.navigate(['/email-templates']);
    }.bind(this);

    const postiveAction = function(): void {
      this.EmailTemplateService.deleteEmailTemplate(this.templateId).subscribe(
        result => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { status, data } = result;
          if (status === 200) {
            this.isInprogress = false;
            this.showAlert('Email Template Deleted.', deleteAction);
          }
        },
        error => {
          this.isInprogress = false;
          this.showAlert(error.error.message ? error.error.message : 'Failed to delete email template.');
        }
      );
      this.popUpService.hideDiv('confirmDialog');
    }.bind(this);
    const negativeAction = function(): void {
      this.popUpService.hideDiv('confirmDialog');
    }.bind(this);
      
    this.showConfirmation(message, postiveAction, negativeAction);
  }

  /** Function to show the confirmation dialog
   * It needs the <app-dialog> component to be loaded 
   */
  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function): void {
    this.popUpService.destoryConfirmDialog();
  
    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );
  
    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }
    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }
  
    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);
  }

  /** Function to show the confirmation dialog
   * It needs the <app-dialog> component to be loaded 
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private showAlert(message: string, action?: Function): void {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (!action) {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }
    else {
      this.popUpService.setPositiveAction(
        action
      );
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) { this.popUpService.showDiv(dialog); }
  }
}


/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import  { USER_ROLE_TYPE } from '../../constant';

@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.css']
})
export class CaseComponent implements OnInit {

  user: any;
  caseAssignmentId = 0;

  constructor(
    private loginService: LoginService,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.user = this.loginService.getCurrentUser();
    const userType = this.user.user_type.type.toLowerCase();
    if (userType == USER_ROLE_TYPE.DOCTOR){
      this.router.navigate(['/case']);
    }

    if (userType === USER_ROLE_TYPE.TRANSLATOR) {
      this.getCaseAssignmentId();
    }
  }

  getCaseAssignmentId(): void {
    this.route.queryParams
      .subscribe(
        params => {
          this.caseAssignmentId = +params.assignmentId || 0;
        }
      );
  }

}

/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-spread */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit, ViewChildren ,QueryList } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../services/login.service';
import { USER_ROLE_TYPE, CASE_TYPE, CASE_ASSIGNMENT_TYPE, CASE_ASSIGNMENT_STATUS} from '../constant';
import { CaseAssignmentService } from '../services/case-assignment.service';
import { CommonFuntions } from '../common/common.function';
import * as moment from 'moment';
import { PopUpService } from '../services/pop-up.service';
import { CaseService } from '../services/case.service';
import { SEARCH_OPTION_DASHBOARD } from '../cases/translator-caselist/constant';
import { Subject } from 'rxjs';
import { ReleaseSettingService } from '../services/release-setting.service';

@Component({
  selector: 'app-my-dashboard',
  templateUrl: './my-dashboard.component.html',
  styleUrls: ['./my-dashboard.component.css']
})
export class MyDashboardComponent implements OnInit {
  @ViewChildren(DataTableDirective)
  datatableElement: QueryList<DataTableDirective>;
  dtOptionsDirect: DataTables.Settings= {};
  dtOptionsQueue: DataTables.Settings = {};

  role: string;
  user: any;
  activeCases: number;
  totalCase = 0;
  directCases: any[]  
  optionCases: any[]  
  hideDeleteUserBox = true;
  selectedRejectReason = '';
  searchValue = '';
  dtDirectTrigger: Subject<any> = new Subject<any>();
  dtOptionTrigger: Subject<any> = new Subject<any>();
  is_visible: boolean;
  // update_at: Date;
  // created_at: Date;
  // content: string;
  // is_update: boolean;
  subject: string;
  priority: string;
  clearSearch(): void{
    this.searchColumn={
      column: '',
      value: ''
    };
    this.searchColumnName = '';
    this.searchColumnValue = '';
    this.search('');

  }
  columnOptions= {};
  selectedSearchOption: '';
  searchColumn = {column: '',value: ''};
  searchColumnName = '';
  searchColumnValue='';
  activeCase: any


  constructor(
    public loginService: LoginService,
    public caseAssignmentService: CaseAssignmentService,
    public caseService: CaseService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private popUpService: PopUpService,
    private releaseService: ReleaseSettingService
  ) {}
  
  getDatatableColumn(): any[] {
    return [
      { data: 'acceptBtn' },
      { data: 'declineBtn' },
      { data: 'caseId' },
      { data: 'status' },
      { data: 'caseType' },
      { data: 'fullName' },
      { data: 'patientDiagnosis' },
      { data: 'createdAt' }
    ];
  }

  getDatatableOption(ASSIGNMENT_TYPE: string): {} {
    const that = this;

    return {
      pagingType: 'full_numbers',
      processing: true,
      lengthChange: false,
      serverSide: true,
      pageLength: 5,
      stateSave: true,
      searchDelay: 350,
      stateDuration: 5 * 20,
      order: [[0, 'ASC']],
      info: false,
      language: {
        emptyTable: 'No case assignment to show'
      },
      ajax: (dataTablesParameters: any, callback) => {
        that.caseAssignmentService.getCaseAssignments(ASSIGNMENT_TYPE, dataTablesParameters).subscribe(
          res => {
            if (ASSIGNMENT_TYPE === CASE_ASSIGNMENT_TYPE.DIRECT) that.directCases = res.data;
            if (ASSIGNMENT_TYPE === CASE_ASSIGNMENT_TYPE.QUEUE) that.optionCases = res.data;
            this.totalCase = that.directCases?.length + that.optionCases?.length;

            callback({
              recordsTotal: res.recordsTotal,
              recordsFiltered: res.recordsFiltered,
              data: []
            });
          }
        );
      },
      columns: this.getDatatableColumn()
    };
  }

  ngOnInit(): void {
    this.user = this.loginService.getCurrentUser();
    if (!this.user) {
      throw new Error('No user logged in');
    }
    this.role = this.user.user_type.type.toLowerCase();
    this.getReleaseNotice();
    this.columnOptions = (SEARCH_OPTION_DASHBOARD[`${this.role}`]);
    this.fetchTableData();
  }

  fetchTableData (): void {
    this.dtOptionsDirect = this.getDatatableOption(CASE_ASSIGNMENT_TYPE.DIRECT);
    this.dtOptionsQueue = this.getDatatableOption(CASE_ASSIGNMENT_TYPE.QUEUE);
  }

  currentSearch(columnKey: string,columnValue: string, value: string): void {
    this.searchColumn.column = columnKey;
    this.searchColumn.value = value.toLowerCase();
    this.searchColumnName = columnValue;
    this.searchColumnValue = value;
    this.search(JSON.stringify(this.searchColumn));
  }

  search(search: string): void {
    if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
      return;
    }

    const dTables = this.datatableElement.toArray();
    dTables.forEach(each => {
      each.dtInstance.then(
        (dtInstance: DataTables.Api) => {
          dtInstance.search(search).draw();
        }
      );
    });
  }
  getCaseType(caseType: string): string {
    let caseTypeRes = 'Written';
    if (caseType.toLowerCase() === CASE_TYPE.VIDEO) {
      caseTypeRes = 'Video';
    }
    return caseTypeRes;
  }
  noAction(event: Event) {
    event.preventDefault();
    return false;
  }
  toggleThis(event: Event, toggle_part: string) {
    event.preventDefault();
    $('section[id=\'' + toggle_part + '\']').toggleClass('is-expanded');

  }
  getCaseCreatedAt(date: string): string {
    return moment(date).format('MM/DD/YYYY');
  }

  onRowClick(data: any): void {
    if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
      return;
    }

    if (this.role === USER_ROLE_TYPE.DOCTOR) this.router.navigate(['/case/doctor-consultation', data['case_id']]);
    if (this.role === USER_ROLE_TYPE.TRANSLATOR) this.router.navigate(['/case', data['case_id']]);
  }

  toggleDeleteTemplate(value: boolean): void {
    this.hideDeleteUserBox = value;
  }

  acceptCase(event: Event , action: string, caseObj: any): void{
    console.log(action, caseObj);
    if (event) event.preventDefault();
    if (event) event.stopPropagation();
    
    const confirmDialog = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.destoryConfirmDialog();
    this.popUpService.setDialogParams(
      'Case Confimation',
      'Are you sure you want to accept this Case Assignment ?',
      'Yes',
      'No',
      function () {
        const payload = {
          caseId: caseObj?.case_id,
          comments: this.selectedRejectReason,
          status: CASE_ASSIGNMENT_STATUS.ACCEPTED
        };
        this.caseAssignmentService.acceptRejectCase(payload).subscribe(
          function (_result) {
            this.showAlert(action + '  successful.', () => {
              this.popUpService.hideDiv('alertDialog');
              if (this.role === USER_ROLE_TYPE.TRANSLATOR) {
                this.router.navigate(['/case', caseObj?.case_id]);
              }
              else {
                this.router.navigate(['/case/doctor-consultation', caseObj?.case_id]);
              }
            });
          }.bind(this),
          function (err) {
            this.showAlert(`${err.error.message}.`);
          }.bind(this)
        );
        this.popUpService.hideDiv(confirmDialog);
      }.bind(this),
      function () {
        this.hideDiv(confirmDialog);
      }
    );

    if(confirmDialog) this.popUpService.showDiv(confirmDialog);
  }

  showAlert(message: string, action?: ()=> void): void {
    this.popUpService.destoryConfirmDialog();
    const dialog = this.popUpService.getPopUpView('alertDialog');

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok',
      undefined,
      () => {
        this.popUpService.hideDiv('alertDialog');
        if(action)action();
      }
    );

    this.popUpService.showDiv(dialog);
  }

  rejectCase(event: Event, caseObj: any): void{
    
    if (event) event.preventDefault();
    if (event) event.stopPropagation();
    this.activeCase = caseObj;
    this.totalCase -= 1;
    this.hideDeleteUserBox = false;
  }

  handleValueSubmit = (value): void =>  {
    this.selectedRejectReason=value.reject_reason? value.reject_reason: value.reject_type;
    const payload = {
      caseId: this.activeCase.case_id,
      comments: this.selectedRejectReason,
      status: CASE_ASSIGNMENT_STATUS.DECLINED
    };
    this.caseAssignmentService.acceptRejectCase(payload).subscribe(
      function () {
        this.router.navigate(['/dashboard']);
        if (this.activeCase.assignment_type === CASE_ASSIGNMENT_TYPE.DIRECT) {
          this.directCases = this.directCases.filter(directCase => directCase.id !== this.activeCase.id);
        }
        else {
          this.optionCases = this.optionCases.filter(optionCase => optionCase.id !== this.activeCase.id);
        }
      }.bind(this),
      function (err) {
        this.showAlert(`${err.error.message}.`);
      }.bind(this)
    );
    this.hideDeleteUserBox = true;
  }

  getReleaseNotice():void{
    this.releaseService.getReleaseNotice().subscribe (response => {
      const data = response.data;
      this.is_visible = data?.is_visible;
      // this.update_at = data?.updated_at;
      // this.created_at = data?.created_at;
      this.priority = data?.release_priority;
      this.subject = data?.subject;
      // this.is_update = data?.is_update;
      // this.content = data?.content;

    });
  }
}


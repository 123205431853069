/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigAssetLoaderService } from '../services/config-asset-loader.service';

@Injectable({
  providedIn: 'root'
})
export class ReleaseSettingService {
  
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  constructor(private http: HttpClient) { }

  // api to fetch the release email information from database
  getReleaseNotice(){
    const token = localStorage.getItem('token');
    return this.http.get<any>(ConfigAssetLoaderService.getApiURL() +'/get-release-notice', this.getHeaderWithToken(token));
  }
  
  updateModalVisibility(value: boolean) {
    const token = localStorage.getItem('token');
    return this.http.post<any>(ConfigAssetLoaderService.getApiURL() +'/release-notice-modal?is_visible='+value, {}, this.getHeaderWithToken(token));
  }
  // api to save release email values in database
  saveNotice(releasePayload: object){
    const token = localStorage.getItem('token');
    return this.http.post<any>(ConfigAssetLoaderService.getApiURL() +'/create-release-notice', releasePayload ,this.getHeaderWithToken(token));
  }

  private getHeaderWithToken(token: string) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
  }
  saveOrUpdateReleaseNotice(payload: any) {
    const token = localStorage.getItem('token');
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL()+ '/create-release-notice',
      payload,
      this.getHeaderWithToken(token)
    );
  }

}

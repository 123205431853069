<app-dialog></app-dialog>

<div class="header">
  <div class="header__page-name">
    <h1 class="heading heading--main">Settings</h1>
  </div>
</div>

<div class="wrapper--dash-content dash-content">
  <div class="tabs__content">

    <div class="form tabs__content--white-bg">
        <div class="row">
            <div class="contact-details no-border no-pad no-mar">
                <h3 class="settingTitle">1. Default Careteam List</h3>
                <div class="contact-details__row">
                    <div class="contact-details__label">Default ALLY Careteam List</div>
                    <div class="contact-details__details">
                        <button [routerLink]="['/careteamList']" class="btn btn--blue btn--mini">Link</button>
                    </div>
                </div>
            </div>
            <div class="hr hr--space-down-2x"></div>
        </div>
        <div class="row">
            <div class="contact-details no-border no-pad no-mar">
                <h3 class="settingTitle">2. Zoom Settings</h3>
                <div class="contact-details__row">
                    <div class="contact-details__label">You can configure zoom settings here.</div>
                    <div class="contact-details__details">
                        <button [routerLink]="['/zoom-settings']" class="btn btn--blue btn--mini">Link</button>
                    </div>
                </div>
            </div>
            <div class="hr hr--space-down-2x"></div>
        </div>
        <div class="row">
            <div class="contact-details no-border no-pad no-mar">
                <h3 class="settingTitle">3. Email Settings</h3>
                <div class="contact-details__row">
                    <div class="contact-details__label">You can configure email settings here.</div>
                    <div class="contact-details__details">
                        <button [routerLink]="['/emailSettings']" class="btn btn--blue btn--mini">Link</button>
                    </div>
                </div>
            </div>
            <div class="hr hr--space-down-2x"></div>
        </div>
        <div class="row">
            <div class="contact-details no-border no-pad no-mar">
                <h3 class="settingTitle">4. Email Templates</h3>
                <div class="contact-details__row">
                    <div class="contact-details__label">You can configure email templates here.</div>
                    <div class="contact-details__details">
                        <button [routerLink]="['/email-templates']" class="btn btn--blue btn--mini">Link</button>
                    </div>
                </div>
            </div>
            <div class="hr hr--space-down-2x"></div>
        </div>
        <div class="row">
            <div class="contact-details no-border no-pad no-mar">
                <h3 class="settingTitle">5. Report Settings</h3>
                <div class="contact-details__row">
                    <div class="contact-details__label">You can configure the Report generation from here.</div>
                    <div class="contact-details__details">
                        <button [routerLink]="['/reportSettings']" class="btn btn--blue btn--mini">Link</button>
                    </div>
                </div>
            </div>
            <div class="hr hr--space-down-2x"></div>
        </div>
        <div class="row">
            <div class="contact-details no-border no-pad no-mar">
                <h3 class="settingTitle">6. Case Settings</h3>
                <div class="contact-details__row">
                    <div class="contact-details__label">You can configure the case related settings from here.</div>
                    <div class="contact-details__details">
                        <button [routerLink]="['/case-settings']" class="btn btn--blue btn--mini">Link</button>
                    </div>
                </div>
            </div>
            <div class="hr hr--space-down-2x"></div>
        </div>
        <div class="row">
            <div class="contact-details no-border no-pad no-mar">
                <h3 class="settingTitle">7. Archive Settings</h3>
                <div class="contact-details__row">
                    <div class="contact-details__label">You can configure the archive related settings from here.</div>
                    <div class="contact-details__details">
                        <button [routerLink]="['/archive-settings']" class="btn btn--blue btn--mini">Link</button>
                    </div>
                </div>
            </div>
            <div class="hr hr--space-down-2x"></div>
        </div>

        <div class="row">
            <div class="contact-details no-border no-pad no-mar">
                <h3 class="settingTitle">8. Release Settings</h3>
                <div class="contact-details__row">
                    <div class="contact-details__label">You can configure the release setting here.</div>
                    <div class="contact-details__details">
                        <button [routerLink]="['/release-settings']" class="btn btn--blue btn--mini">Link</button>
                    </div>
                </div>
            </div>
            <div class="hr hr--space-down-2x"></div>
        </div>
    </div>
  </div>
</div>

<app-dialog></app-dialog>

<div class="wrapper wrapper--login">

	<div class="app-logo">

		<img [src]="logoPath" />

	</div>

	<div class="row login">

		<div class="col col__md-5 login__form">

			<div class="login__form-content">

				<h2 class="heading heading--main heading--login">Forgot Password</h2>

				<div class="form">

					<form (submit)="requestPasswordReset()" [formGroup]="requestForm">

						<div class="form__row">

							<label class="form__label" for="email">Email</label>

							<div class="form-control form__field" style="border: none;">

								<input type="email" class="validation" name="email" id="email" [appFormatOnly]='"trimonly"' formControlName="email" />

								<div *ngIf="email.touched && email.invalid" class="message message--is-invalid">

									<div *ngIf="email.errors.required && email.errors.email">Email is a required field</div>

									<div *ngIf="!email.errors.required && email.errors.email">Enter a valid email</div>

								</div>

							</div>

						</div>

						<div *ngIf="enabledDOB" class="form__row">

							<label for="dob" class="form__label">Date of Birth</label>

							<div class="form__field">

								<div class="login_form__field--date full-width-input-form">
									<input type="date" formControlName="dob" class="full-width-input" placeholder="Enter Date" [owlDateTime]="dt2"
										(dateTimeChange)="onDateChange($event, dt2, dateInput)" [max]="currentDate"
										(click)="preventDefault($event)" #dateInput name="dob" id="dateInput" />
									<span class="trigger  icon icon--calendar" [owlDateTimeTrigger]="dt2"></span>
									<owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
								</div>

							</div>

						</div>

						<div class="form__row">
							<div *ngIf="errorMessage" class="message message--is-invalid">

								<span class="icon icon--is-invalid"></span>
								{{ errorMessage }}

							</div>
						</div>

						<div class="form__row">

							<input type="submit" class="btn btn--green btn--full-width" value="Send Reset Link" *ngIf="!isSending" />

							<div class="btn btn--green btn--full-width is-loading" *ngIf="isSending">
								<span class="icon icon--loading "></span>
							</div>

						</div>

					</form>

				</div>

			</div>

		</div>

		<div class="col col__md-7 login__welcome">
			<div class="login__welcome-content">
				<h1 class="heding heading--welcome">Welcome
				</h1>
				<p>A platform designed for doctors to help them manage their patients and partners. Created with love.</p>
			</div>
		</div>

	</div>

</div>
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-partner',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {
  user: any;
  /**
	 * stores payload data form jwt token
	 */
  tokenPayLoad: any;

  constructor(
    private loginService: LoginService
  ) {
    this.user = this.loginService.getCurrentUser();
  }

  ngOnInit() {
    this.tokenPayLoad = this.loginService.getCurrentUser();
  }

}

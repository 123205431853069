<div *ngIf="false" class="document overlay__wrapper" [class.overlay--visible]="showSpinner">
	<div class="overlay__content">
		<div class="overlay__spinner">
			<span class="icon icon--loading"></span>
		</div>
	</div>

	<div class="document__title padding-2x">
		<div class="document__title-image">
			<span class="icon icon--document-title"></span>
		</div>
		<div class="document__title-name">
			<h2 class="title-heading">Translated Documents</h2>
			<div class="title-subheading">To be Uploaded</div>
		</div>
	</div>
	<div class="document__content no-pad">
		<div class="upload-row" *ngIf="case?.translated_document?.length === 0">
			<div class="upload-row__file-name">
				<div class="file-name">No files translated</div>
			</div>
		</div>

		<div *ngFor="let document of case?.translated_document">
			<div class="upload-row" *ngIf="document.document_type != null">
				<div class="upload-row__file-name">
					<div class="file-name"
						[title]="(document.updated_at | amLocal | amDateFormat: 'HH:mm') +'\n'+(document.user && (document.user.full_name))">
						{{document.document_type}} - {{document.document_name}}</div>
					<div class="status-row__size-status">
						{{document.created_at | date: 'MM/dd/yyyy'}} - {{document.size}} kb
					</div>
				</div>
				<div class="upload-row__action">
					<a href="#" class="link-btn is-uppercase" (click)="deleteFile(document, $event)"
						*ngIf="checkCanDelete(document.created_by)">Delete</a>
				</div>
			</div>
		</div>
		<div *ngFor="let files of uploadingFiles">
			<div class="upload-row" *ngIf="files.uploader.isUploading">
				<div class="upload-row__file-name">
					<div class="file-name">{{files.type}} - {{files.name}}</div>
					<div class="status-row">
						<div class="status-row__size-status">
							{{files.uploader.progress / 100 * files.size | number: '1.2-2'}} kb of
							{{files.size | number: '1.2-2'}} kb({{files.uploader.progress}}%
							done)
						</div>
						<div class="status-row__bar-status">
							<div class="bar-status">
								<div class="bar-status__count" [style.width.%]="files?.uploader.progress">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="upload-row__action" *ngIf="files?.uploader.isUploading">
					<a href="#" class="link-btn is-uppercase" (click)="cancelUpload(files, $event)">Cancel</a>
				</div>
			</div>
		</div>

		<div class="upload-form">
			<div class="form">
				<div class="row">
					<div class="col col__md-5 gutter--right-2x">
						<div class="form__row">
							<label for="" class="form__label">Upload Document</label>

							<div class="form__field upload-btn-file">

								<div class="form__field upload">
									<input type="file" accept="application/pdf" class="btn btn--grey btn--small"
										name="document" #document ng2FileSelect [uploader]="uploader"
										(change)="emptyUploadIfEmpty(document)" />
									<a class="btn btn--grey btn--small btn--full-width" href="#">Browse</a>
								</div>
								<div class="uploaded-file">{{document.value == null || document.value === ''
									? 'No File Choosen' : document?.files?.item(0)?.name}}</div>

								<!-- <select class="text--black">
									<option>Doctor_Report_in_Chinese.pdf</option>
									<option>Doctor_Report_in_English.pdf</option>
									overlay__wrapper overlay--visible
									<option>Doctor_Report_in_Nepali.pdf</option>
								</select> -->
							</div>
						</div>
					</div>
					<div class="col col__md-4 gutter--right-2x">
						<div class="form__row">
							<label for="" class="form__label">Type</label>
							<div class="form__field">
								<select class="text--black" #documentType>
									<option [value]="type.value" *ngFor="let type of caseDocumentType">
										{{type.key}}</option>
									<!-- <option value="">Select</option>
									<option value="MEDICAL SUMMARY">Medical Summary</option>
									<option value="DOCTOR SUMMARY">Doctor Summary</option>
									<option value="DOCTOR REPORT">Doctor Report</option>
									<option value="WRITTEN SUMMARY">Written Summary</option> -->
								</select>
							</div>
						</div>
					</div>
					<div class="col col__md-3 text-right">
						<a href="#" class="btn btn--blue btn-upload-doc" (click)="uploadDocs($event)">Upload</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
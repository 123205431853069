<div class="header">
	<div class="header__page-name">
		<h1 class="heading heading--main">Cases</h1>
	</div>
	<div class="header__top-nav">
		<div class="top-menu" >
            <div class="top-menu__user-stat">
                <span>Total Cases</span> {{ totalRecord }}
            </div>
            <div class="top-menu__menu">
            </div>
        
            <!-- Search box -->
            <div class="top-menu__search">
                <div class="search-wrapper">
                    <div class="sarch-box">
                        <input type="text" [(ngModel)]="searchValue" (ngModelChange)="onModelChange()" />
                    </div>
                </div>
            </div>
        </div>
	</div>
</div>
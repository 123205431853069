/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable no-fallthrough */
/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const currentUser = this.loginService.getCurrentUser();
    const role: string = currentUser.user_type.type;
    const url: string = state.url;

    switch (true) {
    case /^\/case\/create\/\d+.*$/.test(url):
      // eslint-disable-next-line max-len
      if (role.toLowerCase() === 'partner' || role.toLowerCase() === 'careteam' || role.toLowerCase() === 'casecoordinator'|| role.toLowerCase() === 'cmmanager') {
        return true;
      }
      else if (role.toLowerCase() === 'patient') {
        const pattern = /^\/case\/create\/(\d+).*$/;
        var match = pattern.exec(url);

        if (match) {
          const userId = +match[1];
          if (userId == currentUser.user_id) {
            return true;
          }
          else {
            this.redirectToHome(currentUser);
            return false;
          }
        }
        else {
          this.redirectToHome(currentUser);
          return false;
        }
      }
      else {
        this.redirectToHome(currentUser);
        return false;
      }
    case /^\/case\/\d+.*$/.test(url):
      if (role.toLowerCase() !== 'doctor' && role.toLowerCase() !== 'interpreter') {
        return true;
      }
      else {
        this.redirectToHome(currentUser);
        return false;
      }
    case /^\/case\/doctor-consultation\/\d+.*$/.test(url):
      if (role.toLowerCase() === 'doctor' || role.toLowerCase() === 'interpreter') {
        return true;
      }
      else {
        this.redirectToHome(currentUser);
        return false;
      }
    case /^\/partners(\/dashboard\/\d+)?(\?.*)?$/.test(url):
      // eslint-disable-next-line max-len
      if ((role.toLowerCase() === 'admin' || role.toLowerCase() === 'careteam'|| role.toLowerCase() === 'casecoordinator' || role.toLowerCase() === 'partner' || role.toLowerCase() === 'cmmanager') && /^\/partners(\?.*)?$/.test(url)) {
        return true;
      }
      // eslint-disable-next-line max-len
      else if ((role.toLowerCase() === 'admin' || role.toLowerCase() === 'careteam' || role.toLowerCase() === 'casecoordinator' || role.toLowerCase() === 'cmmanager'
      || role.toLowerCase() === 'partner') && /^\/partners\/dashboard\/\d+(\?.*)?$/.test(url)) {
        return true;
      }
      else {
        this.redirectToHome(currentUser);
        return false;
      }
    case /^\/partners\/create.*$/.test(url):
      if (role.toLowerCase() === 'admin' || role.toLowerCase() === 'cmmanager') {
        return true;
      }
      else {
        this.redirectToHome(currentUser);
        return false;
      }
    case /^\/partners\/edit\/\d+.*$/.test(url):
      if (role.toLowerCase() === 'admin' || role.toLowerCase() === 'cmmanager') {
        return true;
      }
      else {
        this.redirectToHome(currentUser);
        return false;
      }
    case /^\/add\/([a-zA-Z\\-]+)(\?.*)?$/.test(url):
      if (role.toLowerCase() === 'doctor' || role.toLowerCase() === 'patient'
					|| role.toLowerCase() === 'translator' || role.toLowerCase() === 'interpreter') {
        this.redirectToHome(currentUser);
        return false;
      }

      var addUserRegex = /^\/add\/([a-zA-Z\\-]+)(\?.*)?$/;
      var match = addUserRegex.exec(url);

      if (!match || match.length < 2) {
        this.redirectToHome(currentUser);
        return false;
      }

      if (role.toLowerCase() === 'admin' || role.toLowerCase() === 'cmmanager') {
        return true;
      }
      else if (role.toLowerCase() === 'careteam' || role.toLowerCase() === 'casecoordinator') {

        if (match[1].toLowerCase() === 'admin') {
          this.redirectToHome(currentUser);
          return false;
        }

        return true;
      }
      else if (role.toLowerCase() === 'partner') {

        if (match[1].toLowerCase() === 'partner'
						|| match[1].toLowerCase() === 'patient') {
          return true;
        }

        this.redirectToHome(currentUser);
        return false;
      }
    case /^\/edit\/([a-zA-Z\\-]+)\/(\d+)(\?.*)?$/.test(url):
      var addUserRegex = /^\/edit\/([a-zA-Z\\-]+)\/(\d+)(\?.*)?$/;
      var match = addUserRegex.exec(url);

      if (!match || match.length < 2) {
        this.redirectToHome(currentUser);
        return false;
      }

      if (match[2] == currentUser.user_id) {
        return true;
      }

      if (role.toLowerCase() === 'admin' || role.toLowerCase() === 'mdmanager' || role.toLowerCase() === 'cmmanager') {
        return true;
      }
      else if (role.toLowerCase() === 'careteam' || role.toLowerCase() === 'casecoordinator') {

        if (match[1].toLowerCase() === 'admin') {
          this.redirectToHome(currentUser);
          return false;
        }
        else if ((match[1].toLowerCase() === 'careteam' && (match[2] != currentUser.user_id)) || ((match[1].toLowerCase() === 'casecoordinator') && (match[2] != currentUser.user_id))) {
          this.redirectToHome(currentUser);
          return false;
        }

        return true;
      }
      else if (role.toLowerCase() === 'partner') {

        if (match[1].toLowerCase() === 'patient') {
          return true;
        }
        else if (match[1].toLowerCase() === 'partner') {
          if (match[2] != currentUser.user_id) {
            this.redirectToHome(currentUser);
            return false;
          }
          else {
            return true;
          }
        }

        this.redirectToHome(currentUser);
        return false;
      }
      else if (role.toLowerCase() === 'doctor') {
        if (match[1].toLowerCase() !== 'doctor' || match[2] != currentUser.user_id) {
          this.redirectToHome(currentUser);
          return false;
        }
        return true;
      }
      else if (role.toLowerCase() === 'patient') {
        if (match[1].toLowerCase() !== 'patient' || match[2] != currentUser.user_id) {
          this.redirectToHome(currentUser);
          return false;
        }
        return true;
      }
      else if (role.toLowerCase() === 'translator') {
        if (match[1].toLowerCase() !== 'translator' || match[2] != currentUser.user_id) {
          this.redirectToHome(currentUser);
          return false;
        }
        return true;
      }
      else if (role.toLowerCase() === 'interpreter') {
        if (match[1].toLowerCase() !== 'interpreter' || match[2] != currentUser.user_id) {
          this.redirectToHome(currentUser);
          return false;
        }
        return true;
      }
    case /^\/eligible-member-list\/\d+.*$/.test(url):
      if (role.toLowerCase() === 'admin') {
        return true;
      }

      this.redirectToHome(currentUser);
      return false;
    case /^\/eligible-member\/profile\/\d+.*$/.test(url):
      if (role.toLowerCase() === 'admin') {
        return true;
      }
				
      this.redirectToHome(currentUser);
      return false;
    case /^\/profile\/\d+.*$/.test(url):
    {
      const pattern = /^\/profile\/(\d+).*$/;
      const match = pattern.exec(url);
      const userId = +match[1] || 0;

      if (role.toLowerCase() === 'admin' ||
					role.toLowerCase() === 'careteam' ||
          role.toLowerCase() === 'casecoordinator' ||
          role.toLowerCase() === 'cmmanager' ||
					role.toLowerCase() === 'partner') {
        return true;
      }
      else if (userId === currentUser.user_id) {
        // if self user then
        return true;
      }

      this.redirectToHome(currentUser);
      return false;
    }
    case /^\/doctor\/profile\/\d+.*$/.test(url):
      if (role.toLowerCase() === 'admin' ||
					role.toLowerCase() === 'careteam' ||
          role.toLowerCase() === 'casecoordinator' ||
          role.toLowerCase() === 'cmmanager' ||
					role.toLowerCase() === 'mdmanager') {
        return true;
      }

      this.redirectToHome(currentUser);
      return false;
    case /^\/doctor-role.*$/.test(url):
      if (role.toLowerCase() === 'admin' ||
          role.toLowerCase() === 'cmmanager' ||
					role.toLowerCase() === 'careteam' ||
					role.toLowerCase() === 'mdmanager'||
          role.toLowerCase() === 'casecoordinator'
      ) {
        return true;
      }

      this.redirectToHome(currentUser);
      return false;
    case /^\/imd-careteam-role.*$/.test(url):
      if (role.toLowerCase() === 'admin' ||
          role.toLowerCase() === 'cmmanager' ||
					role.toLowerCase() === 'careteam' ||
          role.toLowerCase() === 'casecoordinator'
      ) {
        return true;
      }

      this.redirectToHome(currentUser);
      return false;
    case /^\/case-coordinator-role.*$/.test(url):
      if (role.toLowerCase() === 'admin' ||
      role.toLowerCase() === 'cmmanager' ||
      role.toLowerCase() === 'casecoordinator'|| 
      role.toLowerCase() === 'careteam'
      ) {
        return true;
      }

      this.redirectToHome(currentUser);
      return false;

    case /^\/client-role.*$/.test(url):
      if (role.toLowerCase() === 'admin' ||
					role.toLowerCase() === 'careteam' ||
          role.toLowerCase() === 'casecoordinator' ||
          role.toLowerCase() === 'cmmanager' ||
					role.toLowerCase() === 'partner') {
        return true;
      }

      this.redirectToHome(currentUser);
      return false;
    case /^\/patient-role.*$/.test(url):
      if (role.toLowerCase() === 'admin' ||
					role.toLowerCase() === 'careteam' ||
          role.toLowerCase() === 'casecoordinator' ||
					role.toLowerCase() === 'cmmanager' ||
					role.toLowerCase() === 'partner') {
        return true;
      }

      this.redirectToHome(currentUser);
      return false;
    case /^\/translator-role.*$/.test(url):
      if (role.toLowerCase() === 'admin' ||
					role.toLowerCase() === 'careteam' ||
          role.toLowerCase() === 'casecoordinator' ||
					role.toLowerCase() === 'cmmanager') {
        return true;
      }
    case /^\/cm-manager-role.*$/.test(url):
      if (role.toLowerCase() === 'admin') {
        return true;
      }

      this.redirectToHome(currentUser);
      return false;
    case /^\/interpreter-role.*$/.test(url):
      if (role.toLowerCase() === 'admin' ||
					role.toLowerCase() === 'careteam' || 
          role.toLowerCase() === 'casecoordinator') {
        return true;
      }
    case /^\/admin-role.*$/.test(url):
      if (role.toLowerCase() === 'admin' ||
					role.toLowerCase() === 'careteam' || 
          role.toLowerCase() === 'casecoordinator') {
        return true;
      }

      this.redirectToHome(currentUser);
      return false;
    case /^\/patientdashboard.*$/.test(url):
      if (role.toLowerCase() === 'patient') {
        return true;
      }
      else {
        this.redirectToHome(currentUser);
        return false;
      }

    case /^\/emailSettings.*$/.test(url):
      if (role.toLowerCase() === 'admin') {
        return true;
      }
      else {
        this.redirectToHome(currentUser);
        return false;
      }
    case /^\/reportSettings.*$/.test(url):
      if (role.toLowerCase() === 'admin') {
        return true;
      }
      else {
        this.redirectToHome(currentUser);
        return false;
      }
    case /^\/settings.*$/.test(url):
      if (role.toLowerCase() === 'admin') {
        return true;
      }
      else {
        this.redirectToHome(currentUser);
        return false;
      }
    case /^\/new-settings.*$/.test(url):
      if (['admin', 'cmmanager'].includes(role.toLowerCase())) {
        return true;
      }
      else {
        this.redirectToHome(currentUser);
        return false;
      }
    case /^\/zoom-settings.*$/.test(url):
      if (role.toLowerCase() === 'admin') {
        return true;
      }
      else {
        this.redirectToHome(currentUser);
        return false;
      }
    case /^\/settings\/outgoing-mail-server.*$/.test(url):
      if (role.toLowerCase() === 'admin') {
        return true;
      }
      else {
        this.redirectToHome(currentUser);
        return false;
      }
    case /^\/case\/search.*$/.test(url):
      // disabled search route for all other users except admin
      // if (role.toLowerCase() === 'admin') {
      //   return true;
      // }
      
      // this.redirectToHome(currentUser);
      // return false;
      return true;
    case /^\/active-users\/([a-zA-Z\\-]+).*$/.test(url):
    case /^\/inactive-users\/([a-zA-Z\\-]+).*$/.test(url):
      if (role.toLowerCase() === 'admin' ||
					role.toLowerCase() === 'cmmanager') {
        return true;
      }
      else {
        this.redirectToHome(currentUser);
        return false;
      }
    default:
      return true;
    }
  }

  constructor(private loginService: LoginService, private router: Router) { }

  private redirectToHome(currentUser?: any) {
    if (!currentUser) {
      this.router.navigate(['/']);
      return;
    }

    if (currentUser.user_type.type.toLowerCase() === 'partner') {
      let routePath = `/clients/dashboard/${currentUser.partners[0].partner_id}`;

      if (currentUser.partners.length > 1) {
        routePath = '/clients';
      }

      this.router.navigate([routePath]);
    }
    else {
      this.router.navigate(['/']);
    }
  }

}

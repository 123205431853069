/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CaseService } from '../../../services/case.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CaseModel } from '../../../domains/case/CaseModel';
import { PopUpService } from '../../../services/pop-up.service';
import { UserService } from '../../../services/userservice.service';
import { CommonService } from '../../../services/common.service';
import { CommonFuntions } from '../../../common/common.function';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-case',
  templateUrl: './edit-case.component.html',
  styleUrls: ['./edit-case.component.css']
})
export class EditCaseComponent implements OnInit {

  caseForm: FormGroup;
  caseId: number;
  isLoading: boolean;
  isReason: boolean;
  caseDetails: any;
  caseModel: CaseModel;
  statusList: [];
  patientList: any;
  recommendationList = [];
  caseStatusList = [];
  isDisabled: true

  constructor(
    private activatedRoute: ActivatedRoute,
    private caseService: CaseService,
    private popUpService: PopUpService,
    private userService: UserService,
    private commonService: CommonService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.activatedRoute.params.subscribe(
      params => {
        const { id } = params;
        this.caseId = id;
        this.getCaseDetail(id);
      }
    );
  }

  ngOnInit(): void {
    this.caseModel = new CaseModel([]);
    this.getCaseStatus();
    this.getPatients();
    this.initializeCaseForm();
    this.initializeCaseStatusList();

    this.recommendationList = [
      { rec_id: 1,
        recommendation_name: 'Additional testing'},
      { rec_id: 2,
        recommendation_name: 'Conservative Treatment'},
      { rec_id: 3,
        recommendation_name: 'Alternative Therapy'},
      { rec_id: 4,
        recommendation_name: 'Lifestyle Modifications'},
      { rec_id: 5,
        recommendation_name: 'N/A'},
    ];
  }

  getCaseDetail(caseid: number): void {
    this.isLoading = true;
    this.caseService.getCaseDetail(this.caseId).subscribe(
      result => {
        this.caseModel = result as CaseModel;
        this.caseDetails = result;
        this.initializeCaseForm();
        
        this.patient_id.setValue(result.patient_id);
        this.case_status.setValue(result.case_status);

        if(this.caseDetails?.surgical_recommendation){
          const case_rec = JSON.parse(this.caseDetails.surgical_recommendation);

          case_rec.map(
            value => this.addRecommendation(value)
          );

          if(this.caseDetails.surgical_recommendation_note){
            this.surgical_recommendation_note.setValue(this.caseDetails.surgical_recommendation_note);
          }
        }
        this.isLoading = false;
      },
      error => {
        const action = function () {
          this.router.navigate(['/technical/case', caseid]);
          this.popUpService.hideDiv('alertDialog');
        }.bind(this);

        if (error && error.status === 401) {
          this.showAlert('The case cannot be accessed.', action);
        }
        else {
          this.showAlert('Error while getting the case.', action);
        }

        this.isLoading = false;
        this.caseDetails = null;
      }
    );
  }

  private initializeCaseForm () {
    this.caseForm = new FormGroup({
      allyCaseNumber: new FormControl(this.caseModel.allyCaseNumber,
        [
          Validators.required,
          Validators.maxLength(50)
        ]),
      allyCaseRef: new FormControl(this.caseModel.allyCaseRef,
        [
          Validators.required,
          Validators.maxLength(50)
        ]),
      case_status: new FormControl(this.caseModel.case_status,
        [
          CommonFuntions.validateDropDown,
        ]),
      status_reason: new FormControl(this.caseModel.status_reason,
        [
          Validators.required,
          Validators.maxLength(50),
        ]),
      patient_id: new FormControl(this.caseModel.patient_id,
        [
          CommonFuntions.validateDropDown,
        ]),
      importance: new FormControl({value: this.caseModel.importance, disabled: true}),
      case_type: new FormControl({value: this.caseModel.case_type, disabled: true}),
      case_id: new FormControl({value: this.caseModel.id, disabled: true}),
      speciality_program: new FormControl({value: this.caseModel.speciality_program, disabled: true}),
      summary_state: new FormControl({value: this.caseModel.summary_state, disabled: true}),
      phone_number: new FormControl({value: this.caseModel.phone_number, disabled: true}),
      interpreter_needed: new FormControl(this.caseModel.interpreter_needed),
      translator_needed: new FormControl(this.caseModel.translator_needed),
      is_mr_complete: new FormControl(this.caseModel.is_mr_complete),
      is_missing_info_addressed: new FormControl(this.caseModel.is_missing_info_addressed),
      is_submitted: new FormControl(this.caseModel.is_submitted),
      is_vip: new FormControl(this.caseModel.is_vip),
      agreement: new FormControl({value: this.caseModel.agreement, disabled: true}),
      case_additional_note: new FormControl(this.caseModel.case_additional_note,
        [
          Validators.required,
          Validators.maxLength(50),
        ]),
      case_careteam_note: new FormControl(this.caseModel.case_careteam_note,
        [
          Validators.required,
          Validators.maxLength(50),
        ]),
      case_patient_note: new FormControl(this.caseModel.case_patient_note,
        [
          Validators.required,
          Validators.maxLength(50),
        ]),
      report_notes: new FormControl(this.caseModel.report_notes,
        [
          Validators.required,
          Validators.maxLength(50),
        ]),
      patient_diagnosis: new FormControl(this.caseModel.patient_diagnosis,
        [
          Validators.required,
          Validators.maxLength(50),
        ]),
      surgical_intervention: new FormControl({value: this.caseModel.surgical_intervention, disabled: true}),
      surgical_intervention_note: new FormControl(this.caseModel.surgical_intervention_note,
        [
          Validators.required,
          Validators.maxLength(50),
        ]),
      surgical_recommendation: new FormArray([]),
      surgical_recommendation_note: new FormControl(this.caseModel.surgical_recommendation_note,
        [
          Validators.required,
          Validators.maxLength(50),
        ]),
    });
  }

  get allyCaseNumber() {
    return this.caseForm.get('allyCaseNumber');
  }
  get allyCaseRef() {
    return this.caseForm.get('allyCaseRef');
  }

  get case_status() {
    return this.caseForm.get('case_status');
  }

  get case_id() {
    return this.caseForm.get('case_id');
  }

  get interpreter_needed() {
    return this.caseForm.get('interpreter_needed');
  }

  get translator_needed() {
    return this.caseForm.get('translator_needed');
  }
  get is_mr_complete() {
    return this.caseForm.get('is_mr_complete');
  }

  get is_missing_info_addressed() {
    return this.caseForm.get('is_missing_info_addressed');
  }

  get is_submitted() {
    return this.caseForm.get('is_submitted');
  }

  get is_vip() {
    return this.caseForm.get('is_vip');
  }

  get case_type() {
    return this.caseForm.get('case_type');
  }

  get patient_id() {
    return this.caseForm.get('patient_id');
  }

  get status_reason() {
    return this.caseForm.get('status_reason');
  }
  get report_notes() {
    return this.caseForm.get('report_notes');
  }
  get case_patient_note() {
    return this.caseForm.get('case_patient_note');
  }
  get speciality_program() {
    return this.caseForm.get('speciality_program');
  }
  get case_careteam_note() {
    return this.caseForm.get('case_careteam_note');
  }
  get case_additional_note() {
    return this.caseForm.get('case_additional_note');
  }
  get patient_diagnosis() {
    return this.caseForm.get('patient_diagnosis');
  }
  get surgical_intervention() {
    return this.caseForm.get('surgical_intervention');
  }
  get surgical_intervention_note() {
    return this.caseForm.get('surgical_intervention_note');
  }
  get surgical_recommendation(): FormArray {
    return this.caseForm.get('surgical_recommendation') as FormArray;
  }
  get surgical_recommendation_note() {
    return this.caseForm.get('surgical_recommendation_note');
  }

  getCaseStatus() {
    this.userService.getCaseStatusList().subscribe(
      statuses => {
        this.statusList = statuses;
      }, error => {
        console.log('error :>> ', error);
      }
    );
  }

  initializeCaseStatusList() {
    this.caseStatusList = [
      {
        id: 1,
        value: 'CANCELLED'
      },
      {
        id: 2,
        value: 'REQUESTED'
      },
      {
        id: 3,
        value: 'FINISHED'
      },
      {
        id: 4,
        value: 'HOLD'
      },
    ];
  }

  getPatients() {
    this.commonService.getPatients().subscribe(
      patients => {
        const commonPatients = patients.filter(patient => 
          this.caseDetails.patient.user.user_partners[0].partner_id === patient.user.user_partners[0].partner_id);
        this.patientList = commonPatients;
      }, error => {
        console.log('error :>> ', error);
      }
    );
  }


  addRecommendation(recommendation_name: string) {
    const value = this.recommendationList.find(
      object => object.recommendation_name === recommendation_name
    );
    const checkNA = this.surgical_recommendation.value.find(
      object => object.recommendation_name === 'N/A'
    );

    if(this.surgical_recommendation.length !== 0 && recommendation_name === 'N/A'){
      return;
    }

    if(checkNA){
      return;
    }

    if(recommendation_name === 'N/A'){
      this.recommendationList = this.recommendationList.filter(value=> {
        return value.recommendation_name === 'N/A';
      });
    }
    else{
      this.recommendationList = this.recommendationList.filter(value=> {
        return value.recommendation_name !== 'N/A';
      });
      this.surgical_recommendation_note.clearValidators();
      this.surgical_recommendation_note.updateValueAndValidity();
    }

    const indexOf = this.recommendationList.indexOf(value);
    this.recommendationList.splice(indexOf, 1);
    
    if (value == null) {return;}

    this.surgical_recommendation.push(new FormControl(value));
  }

  suggestionListFormatter(data: any): string {
    if (data.hasOwnProperty('recommendation_name')) {
      return data.recommendation_name;
    }
  }

  valueListFormatter(data: any): string {
    if (data.hasOwnProperty('recommendation_name')) {
      this.addRecommendation(data.recommendation_name);
    }
    return '';
  }

  extractValue(arr, prop) {
    // extract value from property
    const extractedValue = arr.map(item => item[prop]);

    return extractedValue;
  }

  callUpdateAction(): void {
    const postiveAction = function(): void {
      this.popUpService.hideDiv('alertDialog');
    }.bind(this);
    this.caseForm.value.surgical_recommendation = JSON.stringify(this.extractValue(this.surgical_recommendation.value, 'recommendation_name')) ;

    this.caseService.updateCaseDetail(this.caseId, this.caseForm.value).subscribe(
      (_result) => {
        this.isLoading = false;
        const message = 'Case has been updated!';
        this.showAlert(message, postiveAction);
      },
      (_error) => {
        this.isLoading = false;
        this.showAlert('Failed to update case.');
      }
    );
  }

  covertCaseToWrittenConsultation(): void {
    this.caseService.convertCase(this.case_id.value, this.case_type.value)
      .subscribe(response => {
        if (response?.statusCode == 200) {
          Swal.fire({
            text: response?.message,
            title: 'Success',
            backdrop: '#eef3f6',
            reverseButtons: true,
            iconColor: 'green',
            icon: 'success',
            showCancelButton: false,
            showDenyButton: false,
            confirmButtonColor: '#249533',
            confirmButtonText: 'X Close'
          }).then((result)=>{
            if(result.isConfirmed){
              window.location.reload();
            }
          });
        }
        else {
          Swal.fire({
            text: 'Unable to convert the case in this stage',
            title: 'Error',
            backdrop: '#eef3f6',
            reverseButtons: true,
            iconColor: 'red',
            icon: 'error',
            showCancelButton: false,
            showDenyButton: false,
            confirmButtonColor: '#249533',
            confirmButtonText: 'X Close'
          });
        }
      }, () => {
        this.toastr.error('Network Error');
      }
      );
  }

  updateCase(event: Event): void {
    event.preventDefault();

    const message = 'Are you sure you want to update the case?';
    const postiveAction = function(): void {
      this.callUpdateAction();
      this.router.navigate(['/technical/case']);
      this.popUpService.hideDiv('confirmDialog');
    }.bind(this);
    const negativeAction = function(): void {
      this.popUpService.hideDiv('confirmDialog');
    }.bind(this);

    this.showConfirmation(message, postiveAction, negativeAction);
  }

  cancel(event: Event) {
    event.preventDefault();
    this.router.navigate(['/technical/case/', this.caseId]);
  }

  /** Function to show the confirmation dialog
   * It needs the <app-dialog> component to be loaded 
   */
  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function): void {
    this.popUpService.destoryConfirmDialog();
    
    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );
    
    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }
    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }
    
    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);
  }

  /** Function to show the confirmation dialog
   * It needs the <app-dialog> component to be loaded 
   */
  private showAlert(message: string, action?: Function): void {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (!action) {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }
    else {
      this.popUpService.setPositiveAction(
        action
      );
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) { this.popUpService.showDiv(dialog); }
  }

}

<app-dialog></app-dialog>
<div class="header">
	<div class="header__page-name">
		<h1 class="heading heading--main">Mail Logs</h1>
	</div>
</div>
<div class="header">
	<div class="header__top-nav">
		<div class="top-menu">
			<div class="top-menu__user-stat">
				<span>Total: </span> {{totalCounts}}
			</div>
			<div class="top-menu__menu">
			</div>

			<div class="top-menu__menu">
				<ul class="top-menu__wrapper">
					<li class="status-list-trigger">
						<a href="javascript:void(0)">Filter</a>
						<ul class="status-list" style="right: -15px;left: auto;">
							<li *ngFor="let type of logTypes" (click)="addRemoveFilter(type)" style="cursor:pointer;"><input type="checkbox" [value]="type.id" [checked]="type.checked"> {{type.name}}</li>
						</ul>
					</li>			

				</ul>
			</div>

			<div *ngIf="['admin', 'cmmanager'].includes(user?.user_type?.type.toLowerCase())" class="top-menu__search">

				<div class="search-wrapper">
					<div class="search-input-trigger sarch-box">
						<input id="caseSearch" type="text" [(ngModel)]="searchValue" #searchBox autocomplete="off"/>
						
						<ul class="search-column" *ngIf="searchValue !== ''">
							<li (click)="currentSearch(column.key,column.value,searchValue)" *ngFor="let column of columnOptions | keyvalue"> <strong><em>{{column.value}}</em></strong> : {{searchValue}} 
								<!-- <button ></button> -->
							</li>
						</ul>
								
					</div>	
				</div>

				<small *ngIf="searchColumnValue">  <span class="btn btn--small--circle  btn--remove--search" ><strong>{{searchColumnName}}</strong>:	{{searchValue}}<span (click)="clearSearch()"><b>&nbsp; X</b></span> </span></small> 
				
				<div class="add-user">
					<a href="javascript:void(0)" class="btn btn--blue btn--mini" (click)="triggerAll = true">
						Bulk Try Again
					</a>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- [class.overlay--visible] ="isInprogress" -->
<div class="wrapper--dash-content dash-content overlay__wrapper" [class.overlay--visible]="isInprogress">
	<div class="overlay__content">
		<div class="overlay__spinner">
			<span class="icon icon--loading"></span>
		</div>
	</div>

	<table datatable [dtOptions]="dtOptions1" id="MailLogTable_active" #activeMailLogTable></table>

</div>

<app-mail-log-details [showDetails]="showDetails" [logData]="currentData" (toggleTemplate)="toggleTemplate($event)"
	(reloadData)="reloadData()">
</app-mail-log-details>

<div class="popup webhook-details" [hidden]='!triggerAll'>
	<div class="popup__overlay"></div>
	<div class="popup__video overlay__wrapper">
		<div class="popup__header d-flex justify-content-between">
			<h1 style="align-self: center;">Re-send bulk mails</h1>
			<button class="btn btn--small btn--red" (click)="resetModal()">X</button>
		</div>
		<div class="popup__content webhook_logs_content">
			<!-------->
			<div class="form-check mb20">
				<input class="form-check-input" type="radio" name="mailFrom" value="all" [(ngModel)]="mailFrom" id="allRadio">
				<label class="form-check-label" for="allRadio">
					Re-send all unsent mails
				</label>
			</div>
			<div class="form-check">
				<input class="form-check-input" type="radio" name="mailFrom" value="fromDate" [(ngModel)]="mailFrom" id="fromDateRadio">
				<label class="form-check-label" for="fromDateRadio">
					Re-send all unsent mails from date time.
				</label>
			</div>
			<div style="margin: 0px 20px;">
				<div class="form__field" style="width: 250px;">
					<div class="form__field--date">
						<input [type]="'datetime-local'"  placeholder="Enter Date" [owlDateTime]="dt3" [max]="currentDate" 
						 #consultationDate (dateTimeChange)="onDateTimeChange(dt3, consultationDate)"   (change)="onDateTimeChange(dt3, consultationDate)"/>

						<span class="trigger  icon icon--calendar" [owlDateTimeTrigger]="dt3"></span>
						<owl-date-time  #dt3 [hour12Timer]="true"></owl-date-time>
					</div>
					<p *ngIf="isDateError" class="message message--is-invalid"> DateTime is required</p>
				</div>
			</div>
		</div>
		<!--step 0-->
		<div *ngIf="!sendingMails && step == 0" class="popup__footer popup__footer--centered">
			<a href="javascript:void(0)" class="btn btn--green" [ngClass]="mailFrom === undefined ? 'btn-disable':''" (click)="checkMails()">Next</a>
		</div>
		<!--step 1-->
		<div *ngIf="!sendingMails && step == 1" class="popup__footer popup__footer--centered">
			<p>This will send total <b>{{mailCount}} mail(s)</b>. Are you sure you want to re-send?</p>
			<a href="javascript:void(0)" class="btn btn--grey" (click)="resetModal()">Cancel</a>
			<a href="javascript:void(0)" class="btn btn--green" [ngClass]="mailFrom === undefined ? 'btn-disable':''" (click)="sendMails()">Send</a>
		</div>
		<!--Sending-->
		<div  *ngIf="sendingMails" class="popup__footer popup__footer--centered">
			<div class="message message--bold message--black">Sending...</div>
			<div class="message message--black">You can close this dialog, we will notify the summary on your register mail.</div>
		</div>
	</div>
</div>
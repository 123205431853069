/* eslint-disable quote-props */
/* eslint-disable quotes */
export const permissionArr = [
  {
    "label": "Get Case By All Ref",
    "type": "GET",
    "endpoint": "/case/case_by_ally_ref/:case_ally_number",
    "access": true
  },
  {
    "label": "Create Case",
    "type": "POST",
    "endpoint": "/case/create/bypartner",
    "access": true
  },
  {
    "label": "Add Case Questions",
    "type": "POST",
    "endpoint": "/questions/:caseid",
    "access": true
  },
  {
    "label": "Case Cancel",
    "type": "POST",
    "endpoint": "/case/changeCaseStatus",
    "access": true
  },
  {
    "label": "Case Complete",
    "type": "POST",
    "endpoint": "/case/upsert",
    "access": true
  },
  {
    "label": "Document Upload",
    "type": "POST",
    "endpoint": "/edit/upload",
    "access": true
  },
  {
    "label": "Update Case Medical Data",
    "type": "POST",
    "endpoint": "/medicaldata/:caseId",
    "access": true
  },
  {
    "label": "Patient Intake",
    "type": "POST",
    "endpoint": "/case/intake/:caseId",
    "access": true
  },
  {
    "label": "Expert Info",
    "type": "GET",
    "endpoint": "/cases/doctor/",
    "access": true
  },
  {
    "label": "Create Tracking",
    "type": "POST",
    "endpoint": "/case/tracking/:caseId",
    "access": true
  },
  {
    "label": "Get Tracking By Case Id",
    "type": "GET",
    "endpoint": "/case/tracking/:caseId",
    "access": true
  },
  {
    "label": "Get Tracking By Case Id and Tracking Id",
    "type": "GET,POST,PATCH,DELETE",
    "endpoint": "/case/tracking/:caseId/:tlId",
    "access": true
  },
  {
    "label": "Tracking Line Note",
    "type": "GET,POST",
    "endpoint": "/case/tracking/activity/:caseid/:tlid",
    "access": true
  },
  {
    "label": "Create Activity",
    "type": "POST",
    "endpoint": "/case/activity/:resourceId",
    "access": true
  },
  {
    "label": "User Detail By AllyUserRef",
    "type": "GET",
    "endpoint": "/user/user_by_ally_ref/:user_ally_ref",
    "access": true
  },
  {
    "label": "User Create",
    "type": "POST",
    "endpoint": "/user/create",
    "access": true
  },
  {
    "label": "User Edit",
    "type": "PUT",
    "endpoint": "/user/edit/:user_id",
    "access": true
  },
  {
    "label": "Case Message",
    "type": "POST",
    "endpoint": "/case/message/:case_id",
    "access": true
  },
  {
    "label": "Get Case Message",
    "type": "GET",
    "endpoint": "/case/case_messages/:case_id/:to_user_id",
    "access": true
  },
  {
    "label": "Partner Create",
    "type": "POST",
    "endpoint": "/partner/create",
    "access": true
  },
  {
    "label": "Partner Edit",
    "type": "PUT",
    "endpoint": "/partner/edit/:partner_id",
    "access": true
  },
  {
    "label": "Partner Detail By AllPartnerReference",
    "type": "GET",
    "endpoint": "/partner/partner_by_ally_ref/:partner_ally_ref",
    "access": true
  },
  {
    "label": "Assign CT to Partner",
    "type": "POST",
    "endpoint": "/assignCareTeamToThisPartner/:partner_id",
    "access": true
  }
];
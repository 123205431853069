/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Directive, HostListener, ElementRef, Input } from '@angular/core';


@Directive({
  selector: '[appFormatOnly]'
})
export class FormatOnlyDirective {
  @Input('appFormatOnly') format;

  constructor(private el: ElementRef) { }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  @HostListener('blur') onblur() {
    const value: string = this.el.nativeElement.value;
    if (this.format == 'lowercase') {
      this.el.nativeElement.value = value.toLowerCase().trim();
    }
    else if (this.format == 'uppercase') {
      this.el.nativeElement.value = value.toUpperCase().trim();
    }
    else if (this.format == 'trim') {
      const changeLetter: string = value.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
      this.el.nativeElement.value = changeLetter.trim();
    }
    else if (this.format == 'trimonly') {
      const changeLetter: string = value.trim();
      this.el.nativeElement.value = changeLetter;
    }
    else if (this.format == 'email') {
      const changeLetter: string = value.trim();
      const atpos = changeLetter.indexOf('@');
      const dotpos = changeLetter.lastIndexOf('.');
      if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= changeLetter.length) {
        return false;
      }
      else{
        this.el.nativeElement.value = changeLetter;
      }
    }


  }

}

/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseDoctorOnePager } from './doctor-one-pager';

export class DoctorOnePagerModel extends BaseDoctorOnePager {

  case_questions: any[];

  constructor() {
    super();
    this.case_questions = [];
  }
}

<div *ngIf="trackingList?.length > 0" class="box box--white box--no-pad box--radius margin--bottom-2x">
  <div class="box__title align-items-center case-section-box">
    <div class="box-title-left">
      <div class="left-title">Medical Records Collection ({{trackingList.length}})</div>
    </div>
    <div class="box-title-right">
      <!-- <div class="right-status margin--top-x">Last Updated
				{{trackingList[0]?.updatedAt | date: 'MM/dd/yyyy, h:mm a'}}</div> -->
      <!--For MR Complete-->
      <a href="javascript:void(0)" class="go-back pull-right collapseToggle top10"
        [ngClass]="{collapseToggleActive: isTrackingCollapse===true}"
        (click)="isTrackingCollapse = !isTrackingCollapse">
        <span class="icon icon--time-nav"></span>
      </a>
    </div>
  </div>
  <div [hidden]="!isTrackingCollapse" class="tracking-wrapper">
    <div class="scrollable-table">
      <table width="100%" border="0" class="tracking-table row-border hover dataTable no-footer" id="trackingTable">
        <thead>
          <tr class="tracking-table-header">
            <th>Tracking Line Number</th>
            <th>Status</th>
            <th>Type</th>
            <th>Facility</th>
            <th>Provider</th>
            <th>Owner</th>
            <th>Uploads</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tracking of trackingList" (click)="showTrackingDetails(tracking.tl_id)">
            <td class="tlId">
              <span *ngIf="tracking.newNotificationCount> 0" class="unread-item" title="New Notification"></span>
              <span *ngIf="tracking.newNotificationCount == 0" class="emptyIndicator"></span>
              <span  class="indicatorText">{{ tracking.tl_number || 'N/A' }}</span>
            </td>
            <td>{{ tracking.tl_status }}</td>
            <td>{{ tracking.tl_type }}</td>
            <td>{{ tracking.tl_facilityName }}</td>
            <td>{{ tracking.tl_provider_name }}</td>
            <td>{{ tracking.tl_owner_name || 'NA' }}</td>
            <td class="d-flex align-items-center document_count_container">
              <span class="document_count" *ngIf="getTrackingLineDocumentCount(tracking.tl_id) > 0">{{
                getTrackingLineDocumentCount(tracking.tl_id) || 0 }} </span>
              <span class="icon icon--uploaded-file table-icon"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="margin--top-x" *ngIf="role == 'admin' || role == 'careteam' || role == 'cmmanager' || role =='casecoordinator'">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div>
            <label for="isMRComplete">MR Collection Complete</label>
          </div>
          <div class="ml-1">
            <input type="checkbox" [disabled]="caseStatus === 'FINISHED' || caseStatus === 'CANCELLED'"
              (change)="onChangeMRComplete(isMRChecked)" [checked]="isMRComplete" #isMRChecked />
          </div>
        </div>
        <div class="right-status d-flex align-items-center" *ngIf="isMRComplete">
          <div class="mr-date-details ml-1">
            Date MR Complete {{dateMrComplete | date: 'MM/dd/yyyy, hh:mm aaa'}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Tracking Lines Detail Template
Hidden by default
-->
<app-tracking-line-detail-template  *ngIf="trackingList?.length" (deleteTrackingDocument)="deleteTrackingDocument($event)"
  [trackingLineObj]="trackingLineObj" [trackingline_upload]="trackingline_upload"
  [trackingLineNoteList]="trackingLineNoteList" [shouldHide]="hideChildTemplate"
  (toggleTemplate)="toggleTrackingDetailTemplate($event)" #TRACKING_LINE_DETAIL
  (updateCaseDocuments)="updateDocuments()" [loadingNotes]="loadingNotes" [isMdManager]="isMdManager">
</app-tracking-line-detail-template>
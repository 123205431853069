/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-undef */
import { HttpHeaders } from '@angular/common/http';

const getHeaderWithToken = () => {
  const token = localStorage.getItem('token');
  return {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    })
  };
};

const CASE_TECHNICAL = {
  LIST: 'v2/cases',
  GET: 'v2/cases',
  PUT: 'v2/cases/edit',
};

const PATIENT = {
  GET: 'v2/patient',
};

const MAIL_SERVER = {
  CREATE: 'v2/mailserver/create',
  LIST: 'v2/mailservers',
  GET: 'v2/mailserver',
  PUT: 'v2/mailserver/edit',
  VERIFYCONNECTION: 'v2/mailserver/verify-connection'
};

const MAIL_SETTING= {
  CREATE: 'v2/mailsetting/create',
  LIST: 'v2/mailsettings',
  PUT: 'v2/mailsetting/edit'
};

const EMAIL_TEMPLATE= {
  CREATE: 'v2/emailTemplate/create',
  LIST: 'v2/emailTemplates',
  GET: 'v2/emailTemplate',
  PUT: 'v2/emailTemplate/edit',
  DELETE: 'v2/emailTemplate/delete'
};

export { 
  MAIL_SERVER,
  CASE_TECHNICAL,
  PATIENT,
  MAIL_SETTING,
  EMAIL_TEMPLATE,
  getHeaderWithToken
};
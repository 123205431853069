import Swal from 'sweetalert2';
/* eslint-disable max-len */
/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-spread */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { FileUploader } from 'ng2-file-upload';
import * as moment from 'moment/moment';
import * as momentTz from 'moment-timezone';

import { MdManagementComponent } from './md-management/md-management.component';
import { LoginService } from '../../services/login.service';
import { UserService } from '../../services/userservice.service';
import { CaseService } from '../../services/case.service';
import { PopUpService } from '../../services/pop-up.service';
import { CommonFuntions } from '../../common/common.function';
import { ConfigAssetLoaderService } from '../../services/config-asset-loader.service';
import { CommonService } from '../../services/common.service';
import { CaseStatus } from '../../domains/case-status-model';
import { TranslateMrComponent } from './translate-mr/translate-mr.component';
import { CasehistoryComponent } from '../casehistory/casehistory.component';
import { ActivityModel } from '../../domains/ActivityModel';
import { UserModel } from '../../domains/userModel';
import { CASE_STAGE_ID, CASE_STATUS_TYPE } from '../../constant';
import { ToastrService } from 'ngx-toastr';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-general-case',
  templateUrl: './general-case.component.html',
  styleUrls: ['./general-case.component.css']
})
export class GeneralCaseComponent implements OnInit, OnDestroy {

  @Output() caseEmitter = new EventEmitter<any>();
  @Output() showStatusEmitter = new EventEmitter<string>();
  @Output() isCaseAccepted = new EventEmitter<string>();
  @ViewChild('CANCELLED', { static: true }) cancel: ElementRef;
  @ViewChild('HOLD', { static: true }) hold: ElementRef;
  @ViewChild('REQUESTED', { static: true }) inprogress: ElementRef;
  @ViewChild('VIDEO', { static: true }) video: ElementRef;
  @ViewChild('wechatID', { static: true }) wechatInput: ElementRef;
  @ViewChild('document', { static: true }) documentInput: ElementRef;
  @ViewChild('activeUser') private activeUser: SwalComponent;
  @ViewChild('continueCase') private continueCase: SwalComponent;
  @ViewChild('dateMrComplete')
  dateMRCompleteInput: ElementRef

  @ViewChild(TranslateMrComponent)
  translatedmrComponent: TranslateMrComponent

  @ViewChild(MdManagementComponent)
  mdManagementComponent: MdManagementComponent;

  @ViewChild(CasehistoryComponent)
  caseHisotry: CasehistoryComponent;

  @ViewChild('dateInput')
  earliest_available_dateInput

  public caseDetails: any;
  public caseMedications: any;
  public hasFollowUp: boolean;
  public followUpNumber: number;

  sub: any;
  caseId: number;
  isInProgress: boolean;
  frmCancelCase: FormGroup;
  frmHoldCase: FormGroup;
  frmAssignee: FormGroup;
  showStatus: string;
  role: string;
  userId: number;
  comment: any = {
    message: '',
    case_id: 0,
    from_user_id: 0,
    to_user_id: 0
  };
  finalHistory = [];
  assigneeList = [];
  finishedList = [];
  caseMessages = [];
  document_for_download = [];
  trackingline_upload = [];
  videoLinkList = [];
  final_reports = [];
  translated_document = [];
  videoConsultationList = [];
  validateAssignee = false;
  showReport = true;
  isValidDate: boolean;
  // disableEdits = false;
  currentDate
  currentDateTime
  createdAtDate
  createdAtStartOfDay
  isEditEnabled: boolean;
  isDateEditEnabled: boolean;
  docTypeList: any[];
  types: any[] = [{
    key: 'Select',
    value: 'select'
  }];
  showCommunicationTime: boolean;
  isCommunicationRecordEdit: boolean
  isMedicalRecordEdit: boolean
  isPeerToPeerInfoEdit: boolean
  refinedStatus: CaseStatus[]
  editNotes: boolean
  updateCareTeamNotes: boolean
  isVIP: boolean
  editVIP: boolean;
  caseStatusData: any;
  isAllyRef = false;
  isMRComplete: boolean;
  statusWhitelist = [ CASE_STAGE_ID.COMPILE_MR, CASE_STAGE_ID.SUMMARY, CASE_STAGE_ID.REPORT ]

  assignedMdList: any[];
  showNote: boolean;
  videoSrc: any;
  errors: any;
  activities = [];
  cmManagerList = [];
  editEnabledDocument: any = null;
  editEnabledVideoLink: any = null;
  uploader: FileUploader

  uploadsSectionUploader: FileUploader[] = []
  translatedSectionUploader: FileUploader[] = []
  isLinkUploading = false;
  isVideoLinkUploading = false;

  categories;
  category_fields;
  md_types;
  communicationDetailForm: FormGroup;
  medicalForm: FormGroup
  peerToPeerForm: FormGroup
  noteForm: FormGroup
  careteamNoteForm: FormGroup
  dateForm: FormGroup
  documentUploadType: string
  isMDT: boolean;
  isLoading = false;
  loadingActivities = false;
  CopyText = 'Click to Copy';
  user: any;
  trackingList: any;
  isMdManager = false;
  tagElement = '';
  parentAllyCaseNumber;
  parentAllyCaseRef;
  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private caseService: CaseService,
    private route: ActivatedRoute,
    private popUpService: PopUpService,
    private router: Router,
    private commonService: CommonService,
    private _location: Location,
    private toastr:ToastrService
  ) {
    
  }
  

  checkForElevatedPCT(role: string, partnerObj: any): boolean {
    let check = false;
    const isElevatedPartner = partnerObj.isElevatedPartner;
    
    if(role === 'partner' && isElevatedPartner) {
      check = true;
    }
  
    return check;
  }

  ngOnInit(): void {
    this.isValidDate = true;
    this.showStatus = '';
    this.isEditEnabled = false;
    this.isDateEditEnabled = false;
    this.editNotes = false;
    this.updateCareTeamNotes = false;
    this.showCommunicationTime = false;
    this.currentDate = new Date();
    this.isAllyRef = false;
    this.currentDate.setHours(0, 0, 0, 0);

    this.currentDateTime = new Date();

    this.categories = [{
      category_id: 0,
      category: 'Select'
    }];

    this.category_fields = [{
      category_field_id: 0,
      category_field: 'Select'
    }];

    this.md_types = [
      {
        md_type: 'Select',
        md_type_id: 'select'
      }
    ];

    this.documentUploadType = '';

    this.frmCancelCase = new FormGroup({
      status_reason: new FormControl('', [Validators.required, Validators.maxLength(50000)])
    }, {
      updateOn: 'blur'
    });
    this.frmAssignee = new FormGroup({
      message: new FormControl()
    });
    this.frmHoldCase = new FormGroup({
      status_reason: new FormControl('', [Validators.required, Validators.maxLength(50000)])
    }, {
      updateOn: 'blur'
    });

    this.isInProgress = true;
    this.addPopupTemplates();
    this.user = this.loginService.getCurrentUser();
    this.userId = this.user.user_id;
    this.role = this.user.user_type.type.toLowerCase();

    if (this.role.toLowerCase() === 'doctor' || this.role.toLowerCase() === 'interpreter') { return; }

    if (this.role.toLowerCase() === 'mdmanager'){
      this.isMdManager = true;
    }

    this.sub = this.route.params
      .subscribe(params => {
        this.caseId = +params['caseid'];
        this.isLoading = true;
        this.getCaseById(this.caseId);
        if (this.role.toLowerCase() === 'admin' || this.role.toLowerCase() === 'careteam' ||  this.role.toLowerCase() === 'partner' || this.role.toLowerCase() === 'mdmanager' || this.role.toLowerCase() === 'cmmanager' || this.role.toLowerCase() === 'casecoordinator') { 
          this.getCaseTrackingLines();
        }
        this.updateCaseDocuments();
      });
      
    this.isInProgress = true;
     
    // Todo: Need to remove multiple calls for caseMessage   

    this.getCmManagers();

    this.communicationDetailForm = this.getCommunicationDataForm();
    this.communicationDetailFormControlValueChanged();

    this.dateForm = this.getDateForm();

    this.uploader = this.getUploader();
    this.markOldComment(this.caseId, this.user.user_id);

    this.frmAssignee.addControl('user_case_messages', new FormArray([], CommonFuntions.fomrArrayMinLength(1)));

    this.getMdType();

    this.medicalForm = new FormGroup(
      {
        speciality_program: new FormControl('', [Validators.required, Validators.maxLength(100)]),
        patient_diagnosis: new FormControl('', [Validators.required, Validators.maxLength(50000)]),
        category_id: new FormControl('0', CommonFuntions.validateDropDown),
        category_field_id: new FormControl('0', CommonFuntions.validateDropDown),
        md_type_id: new FormControl('0', CommonFuntions.validateDropDown),
        category_value: new FormControl(''),
        category_field_value: new FormControl(''),
        md_type_value: new FormControl(''),
        case_additional_note: new FormControl(null, [Validators.maxLength(50000)]),
      }
    );

    this.peerToPeerForm = new FormGroup(
      {
        code: new FormControl('', [Validators.required, Validators.maxLength(50000)]),
        description: new FormControl('', [Validators.required, Validators.maxLength(50000)]),
        case_status: new FormControl('', [Validators.required, Validators.maxLength(50000)]),
        case_type: new FormControl('', [Validators.required, Validators.maxLength(50000)]),
        treatment_start_date: new FormControl('', [Validators.required, Validators.maxLength(50000)]),
        treatment_regimen: new FormControl('', [Validators.required, Validators.maxLength(50000)]),
        clinical_consult_diagnosis: new FormControl('', [Validators.required]),
        clinical_consult_diagnosis_notes: new FormControl('', [Validators.required, Validators.maxLength(100)]),
        clinical_consult_recommend_treatment_plan: new FormControl('', [Validators.required]),
        clinical_consult_recommend_treatment_plan_notes: new FormControl('', [Validators.required, Validators.maxLength(100)]),
  
      }
    );

    if (this.role === 'admin' || this.role === 'careteam' || this.role === 'mdmanager' || this.role === 'cmmanager' || this.role === 'casecoordinator') {
      this.medicalForm.addControl('case_careteam_note', new FormControl(null, [Validators.maxLength(50000)]));
    }
  }

  /** Popup templates added so that they can used within this component. */
  private addPopupTemplates(): void {
    this.popUpService.addPopUpView('CANCELLED', this.cancel);
    this.popUpService.addPopUpView('HOLD', this.hold);
    this.popUpService.addPopUpView('REQUESTED', this.inprogress);
    this.popUpService.addPopUpView('VIDEO', this.video);
  }

  getActivities(): void {
    this.caseService.getAllActivities(this.caseId).subscribe(
      results => {
        if (results.length > 0) {
          this.activities = results.map((activity)=>{
            activity.user = activity.user as UserModel;
            return activity as ActivityModel;
          });  
        }
      });
  }

  get assignee(): FormControl {
    return this.frmAssignee.get('assignee') as FormControl;
  }

  get message(): FormControl {
    return this.frmAssignee.get('message') as FormControl;
  }

  get user_case_messages(): FormArray {
    return this.frmAssignee.get('user_case_messages') as FormArray;
  }

  checkCanEditOrDelete(role: string, createdBy: number): boolean {
    let canDelete = false;
    if (role === 'careteam' || role === 'admin' || role === 'cmmanager' || role === 'casecoordinator') {
      canDelete = true;
    }
    else if (role === 'partner' && this.userId === createdBy) {
      canDelete = true;
    }
    else if (role === 'patient' && this.userId === createdBy) {
      canDelete = true;
    }

    return canDelete;
  }

  copyText(val: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.CopyText = 'Copied';
  }

  getCmManagers() {
    this.userService.getCmManagerList().subscribe(
      result => {
        this.cmManagerList = result;
      }, error => {
        console.log('error :>> ', error);
      }
    );
  }

  getFinishedList(): void {
    this.caseService.getFinishedList(this.caseId).subscribe(
      finishedStatuses => {
        if (Array.isArray(finishedStatuses)) {
          finishedStatuses.forEach(
            finish => {
              const statusObj = {};
              statusObj['status'] = finish.status.status;

              statusObj['full_name'] = finish.user != null ? finish.user.full_name : '';
              statusObj['created_at'] = finish.created_at;
              statusObj['updated_at'] = finish.updated_at;
              statusObj['finishedList'] = true;
              this.finishedList.push(statusObj);
            }
          );
        }
      }
    );
  }

  // getAssigneeList() {
  // 	this.assigneeList = [];
  // 	this.caseService.getAssigneeList(this.caseId).subscribe(
  // 		assignees => {
  // 			assignees.forEach(
  // 				assignee => {
  // 					let assigneeObj = {};
  // 					assigneeObj['to_user_id'] = assignee.assigned_user_id;
  // 					assigneeObj['full_name'] = assignee.full_name;
  // 					assigneeObj['user_type'] = assignee.user_type;
  // 					assigneeObj['email'] = assignee.email;
  // 					this.assigneeList.push(assigneeObj);
  // 				}
  // 			)
  // 		}
  // 	)
  // }


  navigateToOnePager() {
    this.showStatus = 'all';
    this._location.back();
  }

  suggestionListFormatter(data: any): string {
    if (data.hasOwnProperty('full_name')) {
      if(data.user_type === 'Partner'){
        return  data.full_name + ' - ' + 'Client';
      }
      else if(data.user_type === 'Translator'){
        return  data.full_name + ' - ' + 'Summary Coordinator';
      }
      else{
        return data.full_name + ' - ' + data.user_type;
      }
    }
  }

  valueListFormatter(data: any): string {
    if (data.hasOwnProperty('full_name')) {
      this.addAssignee(data.full_name);
    }
    return '';
  }

  clearAssignee(user: FormControl) {

    const index = this.user_case_messages.controls.indexOf(user);
    this.assigneeList.push(user.value);
    this.assigneeList.sort(
      (assignee1, assignee2) => {
        return assignee1.to_user_id - assignee2.to_user_id;
      }
    );
    this.user_case_messages.removeAt(index);
  }


  addAssignee(assignee: string) {
    // if (this.userType.toLowerCase() === 'partner' && this.user_partners.controls.length > 0) {
    // 	this.partnerList.push(this.user_partners.controls[0].value);
    // 	this.partnerList.sort(function (partner1, partner2) {
    // 		return partner1.partner_id - partner2.partner_id
    // 	});
    // 	this.user_partners.removeAt(0);
    // }

    const partnerObj = this.assigneeList.find(assigneeItem => {
      return assigneeItem.full_name === assignee;
    });
    const index = this.assigneeList.indexOf(partnerObj);
    this.assigneeList.splice(index, 1);
    this.user_case_messages.push(new FormControl(partnerObj));

    this.validateAssignee = true;
  }

  constructCaseHistory() {
    // this.finalHistory = [];
    this.finalHistory = this.caseMessages.concat(this.finishedList);
    if (this.caseDetails.case_status.toLowerCase() === 'hold') {
      this.finalHistory = this.finalHistory.concat(this.caseDetails);
    }
    this.finalHistory.sort(function (obj1, obj2) {
      const dateA = new Date(obj1.updated_at), dateB = new Date(obj2.updated_at);
      return dateB.getTime() - dateA.getTime();
    });
  }

  markOldComment(_id: number, user_id: number): void {
    this.caseService.markOldComment(this.caseId, user_id).subscribe(
      result => {
        console.log(result);
      }
    );
  }

  private showVideoConsultationList(role: string, case_status: string, case_type: string): any[] {
    const list = [];

    if(case_status === 'FINISHED' && role === 'patient' && case_type === 'CONNECT ASYNCHRONOUS') {
      const caseQuestions = this.caseDetails.case_questions;
      if(caseQuestions && caseQuestions.length > 0) {
        caseQuestions.forEach(eachQuestion => {
          if(eachQuestion.video_consultation && !list.includes(eachQuestion.video_consultation)) {
            list.push(eachQuestion.video_consultation);
          }
        });
      }

      if(this.caseDetails.video_consultation && !list.includes(this.caseDetails.video_consultation)) {
        list.push(this.caseDetails.video_consultation);
      }
    }

    return list;

  }

  /**
 * Function to add careteam note if the patner is elevated.
 * @param {*} caseDetails
 * @memberof GeneralCaseComponent
 */
  addCareteamForm(caseDetails: any): void {
    if (this.role === 'partner' && caseDetails?.patient?.user?.user_partners[0]?.partner.isElevatedPartner) {
      this.medicalForm.addControl('case_careteam_note', new FormControl(null, [Validators.maxLength(50000)]));
    }
  }

  revertToCompileMR(caseId: number){
    this.caseService.revertToCompileMr(caseId,this.userId).subscribe((response) => {
      const successMessage = response['message'];
      Swal.fire({
        text: successMessage,
        title: 'Success',
        backdrop: '#eef3f6',
        reverseButtons: true,
        iconColor: 'green',
        icon: 'success',
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonColor: '#249533',
        confirmButtonText: 'X Close'
      }).then((result)=>{
        if(result.isConfirmed){
          window.location.reload();
        }
      });


    },(error)=>{
      const errorMessage = error['error'].message;
      Swal.fire({
        text: errorMessage,
        title: 'Failed',
        backdrop: '#eef3f6',
        reverseButtons: true,
        iconColor: 'red',
        icon: 'error',
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonColor: '#249533',
        confirmButtonText: 'X Close'
      });

    });
  }

  getCaseById(_id: number) {
  
    const user = this.loginService.getCurrentUser();
    this.caseService.getCaseById(this.caseId, user.user_id).subscribe(
      caseDetails => {
        this.addCareteamForm(caseDetails);
        this.assignedMdList = this.setMdList(caseDetails.case_md_management);
        this.setIsMDT(caseDetails.case_type);
        if (this.role === 'patient' && caseDetails.user && caseDetails.user.user_id !== this.userId) {
          this.showNote = false;
        }

        if((caseDetails.created_at <= ConfigAssetLoaderService.getREODeploymentTime()) &&(caseDetails.case_status === 'REQUESTED')) {
          this.tagElement += ' <span class=\'badge-small badge-green\'>Pre-SF</span>';
        }
        this.checkCanEditVIP(caseDetails);

        if (caseDetails.date_mr_complete == null) {
          caseDetails.date_mr_complete = caseDetails.created_at;
        }

        if (caseDetails.earliest_available_date && caseDetails.earliest_available_date != null) {
          const timezoneString = momentTz.tz.guess();
          const earliest_available_date = momentTz.tz(caseDetails.earliest_available_date, timezoneString).format('YYYY-MM-DDTHH:mm:ssZ');
          caseDetails.earliest_available_date = earliest_available_date;
        }
        this.isVIP = caseDetails.is_vip;
        this.isMRComplete = caseDetails.is_mr_complete;
        this.caseDetails = caseDetails;

        this.processParentAllyNumber();

        this.videoConsultationList = this.showVideoConsultationList(this.role, this.caseDetails.case_status, this.caseDetails.case_type);

        // this.caseDetails.date_mr_complete = moment(this.caseDetails.date_mr_complete).format('MM/DD/YYYY, h:mm A')
        this.caseDetails['document_for_download'] = this.document_for_download;
        this.caseDetails['final_reports'] = this.final_reports;
        this.caseDetails['translated_document'] = this.translated_document;
        this.caseDetails['videoLinkList'] = this.videoLinkList;
        this.createdAtDate = caseDetails.created_at;
        this.createdAtStartOfDay = moment(this.createdAtDate).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
        this.assigneeList.push({
          to_user_id: caseDetails.user.user_id, full_name: caseDetails.user.full_name,
          user_type: 'Client Careteam', email: caseDetails.user.email
        });
        this.constructCaseHistory();
        this.caseDetails.status.sort(function (obj1, obj2) {
          return obj1.status.id - obj2.status.id;
        });

        this.getFollowUpNumber(this.caseDetails.status);

        let inprogressStatus = null;
        if (this.role === 'admin' || this.role === 'careteam' || this.role === 'mdmanager' || this.role === 'cmmanager' || this.role === 'casecoordinator') {
          inprogressStatus = this.getInProgressStatus(caseDetails);
        }

        if (inprogressStatus != null) {
          this.showStatus = inprogressStatus.status.status;
        }
        else {
          const completedStatus = caseDetails.status.filter(status => status.state.toLowerCase() === 'finished');

          if (completedStatus.length === caseDetails.status.length) {
            this.showStatus = 'all';
          }
          else {
            const statusToInprogress = caseDetails.status.find(status => status.state.toLowerCase() === 'not started');

            if (statusToInprogress != null) {
              statusToInprogress.state = 'INPROGRESS';
              inprogressStatus = statusToInprogress;

              this.showStatus = inprogressStatus.status.status;
            }
          }
        }

        const dobMoment = moment(caseDetails.patient.dob, 'YYYY-MM-DD');
        const year = moment().diff(dobMoment, 'years');

        this.getCategory(year);

        const medicalData = {};
        medicalData['speciality_program'] = caseDetails.speciality_program? caseDetails.speciality_program : null;
        medicalData['patient_diagnosis'] = caseDetails.patient_diagnosis;
        medicalData['category_value'] = caseDetails.category_value;
        medicalData['md_type_value'] = caseDetails.md_type_value;
        medicalData['category_field_value'] = caseDetails.category_field_value;
        medicalData['category_id'] = caseDetails.category_id ? caseDetails.category_id : 0;
        medicalData['category_field_id'] = caseDetails.category_field_id ? caseDetails.category_field_id : 0;
        medicalData['md_type_id'] = caseDetails.md_type_id ? caseDetails.md_type_id : 'select';
        medicalData['case_additional_note'] = caseDetails.case_additional_note;
        if (this.role === 'admin' || this.role === 'careteam' || this.role === 'mdmanager' || this.role === 'casecoordinator' || this.role === 'cmmanager' || caseDetails?.patient?.user?.user_partners[0]?.partner.isElevatedPartner) {
          medicalData['case_careteam_note'] = caseDetails.case_careteam_note;
        }
       
        this.medicalForm.setValue(medicalData);


        const  peerToPeerData = {};
        let p2pDetails = JSON.parse(caseDetails.peer_to_peer_details);
        if(!p2pDetails){
          p2pDetails = {};
        }
        peerToPeerData['code'] = p2pDetails.code? p2pDetails.code : null;
        peerToPeerData['description'] = p2pDetails.description? p2pDetails.description : null;
        peerToPeerData['case_status'] = p2pDetails.case_status? p2pDetails.case_status : null;
        peerToPeerData['case_type'] = p2pDetails.case_type? p2pDetails.case_type : null;
        peerToPeerData['treatment_start_date'] = p2pDetails.treatment_start_date? p2pDetails.treatment_start_date : null;
        peerToPeerData['treatment_regimen'] = p2pDetails.treatment_regimen? p2pDetails.treatment_regimen : null;
        peerToPeerData['clinical_consult_diagnosis'] = p2pDetails.clinical_consult_diagnosis? p2pDetails.clinical_consult_diagnosis : null;
        peerToPeerData['clinical_consult_diagnosis_notes'] = p2pDetails.clinical_consult_diagnosis_notes? p2pDetails.clinical_consult_diagnosis_notes : null;
        peerToPeerData['clinical_consult_recommend_treatment_plan'] = p2pDetails.clinical_consult_recommend_treatment_plan? p2pDetails.clinical_consult_recommend_treatment_plan : null;
        peerToPeerData['clinical_consult_recommend_treatment_plan_notes'] = p2pDetails.clinical_consult_recommend_treatment_plan_notes? p2pDetails.clinical_consult_recommend_treatment_plan_notes : null;

        this.peerToPeerForm.setValue(peerToPeerData);

        if (caseDetails.preferred_communication_mode && caseDetails.preferred_communication_mode !== 'EMAIL') {
          this.communicationDetailForm.get('best_communication_time').setValidators([Validators.required]);
          this.communicationDetailForm.get('phone_number').setValidators([Validators.required]);
          this.showCommunicationTime = true;
        }
        else {
          this.communicationDetailForm.get('best_communication_time').setValidators(null);
          this.communicationDetailForm.get('phone_number').setValidators(null);
          this.showCommunicationTime = false;
        }

        this.communicationDetailForm.get('best_communication_time').updateValueAndValidity();
        this.communicationDetailForm.get('phone_number').updateValueAndValidity();

        const communicationData = {};
        communicationData['preferred_communication_mode'] = caseDetails.preferred_communication_mode ? caseDetails.preferred_communication_mode : '';
        communicationData['best_communication_time'] = caseDetails.best_communication_time ? caseDetails.best_communication_time : '';
        communicationData['phone_number'] = caseDetails.phone_number ? caseDetails.phone_number : '';

        this.communicationDetailForm.setValue(communicationData);

        if (medicalData['category_id'] === 0) { this.enableDisableCategoryField(false); }

        if (this.role !== 'admin' && this.role !== 'careteam' && this.role !== 'mdmanager' && this.role !== 'cmmanager' && this.role !== 'casecoordinator') {
          this.setExternalStatus(caseDetails.status, caseDetails.case_type, this.role);
          this.showStatus = 'requested';
        }

        let translatedFinalReport = [];

        if (caseDetails.final_reports !== undefined) {
          const targetReport = caseDetails.final_reports.filter(report => report.document_type === 'CONSULTATION SUMMARY TRANSLATED');

          translatedFinalReport = translatedFinalReport.concat(targetReport);

          caseDetails['translatedFinalReport'] = translatedFinalReport;
        }
        this.isInProgress = false;

        if (caseDetails.patient.user.is_deleted) {
          this.showConfirmation(`Patient '${caseDetails.patient.user.full_name}' has been deleted from the system.\
           Do you want to continue the case?`,function(){
            this.popUpService.hideDiv('confirmDialog');
          }.bind(this),function(){
            this.router.navigate(['/case'], { queryParams: { list: 'current' } });
            this.popUpService.hideDiv('confirmDialog');
          }.bind(this));

        }
        if(caseDetails.allyCaseNumber && caseDetails.allyCaseNumber !== null){
          this.isAllyRef = true;
        }
        this.sfLoginInitialRedirect();
        
        // check if it's an sf login, a follow up case and the intake form has not been submitted
        // compile Mr is not finished
        const compileMr = this.caseDetails.status.find(status => status.status_id === CASE_STAGE_ID.COMPILE_MR);
        if (this.isSFLogin() && this.followUpNumber > 0 && caseDetails.summary_state !== 'SUBMITTED' && compileMr.state !== CASE_STATUS_TYPE.FINISHED)
          this.setShowStatus('Requested');
        
        this.isLoading = false;
      },
      error => {
        const action = function () {
          this.router.navigate(['/']);
          this.popUpService.hideDiv('alertDialog');
        }.bind(this);

        if (error && error.status === 401) {
          this.showAlert('The case cannot be accessed.', action);
        }
        else {
          this.showAlert('Error while getting the case.', action);
        }

        this.isLoading = false;
        this.caseDetails = null;
        this.isInProgress = false;
      }
    );
    this.caseService.fetchCaseMedications(this.caseId).subscribe(
      caseMedications => {
        this.caseMedications = caseMedications;
      }
    );

  }

  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }

    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }

    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);

  }

  communicationDetailFormControlValueChanged() {
    const best_time = this.communicationDetailForm.get('best_communication_time');
    const phone_number = this.communicationDetailForm.get('phone_number');
    this.communicationDetailForm.get('preferred_communication_mode').valueChanges.subscribe(
      (mode: string) => {
        if (mode) {
          if (mode === 'EMAIL') {
            best_time.setValidators(null);
            phone_number.setValidators(null);
            this.showCommunicationTime = false;
          }
          else {
            best_time.setValidators([Validators.required]);
            phone_number.setValidators([Validators.required]);
            this.showCommunicationTime = true;
          }
          best_time.updateValueAndValidity();
          phone_number.updateValueAndValidity();
        }
        else {
          this.showCommunicationTime = false;
        }
      }
    );
  }
  
  isStatusSelected(status: string) {
    let isSelected = false;
    // this.caseDetails
    this.caseDetails.status.forEach(stat => {
      if (status.toLowerCase() === stat.status.status.toLowerCase()) {
        isSelected = true;
      }
    });

    return isSelected;
  }

  onVIPChanged(switchBox: HTMLInputElement) {
    this.showConfirmation('Are you sure? Priority for the case will be changed.',
      function () {
        this.callVIPChange();
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this),
      function () {
        const tempValue = switchBox.checked ? false : true;
        switchBox.checked = tempValue;
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this)
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private callVIPChange() {
    this.isInProgress = true;
    this.isVIP = !this.isVIP;
    const data = {
      caseid: this.caseDetails.id,
      is_vip: this.isVIP
    };

    this.caseService.updateVIPStatus(data).subscribe(vipData => {
      this.caseStatusData = vipData.result;
      this.caseDetails.is_vip = this.isVIP;
      this.isInProgress = false;
      this.getActivities();
    }, (_err) => {
      this.isVIP = !this.isVIP;
      this.isInProgress = false;
    });
  }

  onMRComplete(checkbox) {
    this.showConfirmation('Are you sure? MR Complete Status and Date will be updated.',
      function () {
        this.callMRComplete();
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this),
      function () {
        const tempValue = checkbox.checked ? false : true;
        checkbox.checked = tempValue;
        this.isMRComplete = tempValue;
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this)
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private callMRComplete() {
    this.isInProgress = true;
    
    const data = {};
    data['caseId'] = this.caseDetails.id;
    data['is_mr_complete'] = !this.isMRComplete;
    
    this.caseService.caseUpsert({...data}).subscribe(MRData => {
      this.caseDetails.is_mr_complete = this.isMRComplete;
      this.caseDetails.date_mr_complete = MRData.result.date_mr_complete;
      this.isMRComplete = !this.isMRComplete;
      this.isInProgress = false;
      this.getActivities();
    }, (_err) => {
      this.isInProgress = false;

      this.showAlert('Update failed.' ,function () {
        this.popUpService.hideDiv('alertDialog');
      }.bind(this));
    });
  }

  ngOnDestroy() {
    this.popUpService.destoryConfirmDialog();
    this.popUpService.removePopUpView();
  }

  openVideo(event: Event, data: any) {
    if(event) { event.preventDefault(); }

    if(data) {
      this.videoSrc = data;
      const div = this.popUpService.getPopUpView('VIDEO');
      if(div != null) { this.popUpService.showDiv(div); }
    }
    else {
      this.showAlert('Video file is empty.' ,function () {
        this.popUpService.hideDiv('alertDialog');
      }.bind(this));		
    }
  }

  cancelCase(event: Event) {
    event.preventDefault();
    if(this.isAllyRef === false){
      const div = this.popUpService.getPopUpView('CANCELLED');
      // this.popUpService.showCareTeam.next(true);
      if (div != null) { this.popUpService.showDiv(div); }
    }
  }

  // Function to check whether file has been already cloned or not
  checkDisable(file: any) {
    let count = 0;
    let disableFlag: true | null = null;

    this.caseDetails.translated_document.map((t) => {
      if (file.location === t.location) {
        count += 1;
      }
    });

    if (count > 0) {
      disableFlag = true;
    }

    return disableFlag;
  }

  cloneUploadedDocument(upload: any, event?: Event) {
    if (event) { event.preventDefault(); }

    const disableCheck = this.checkDisable(upload);
    if (disableCheck == null) {
      const data = {
        id: upload.id,
        location: upload.location
      };

      this.caseService.cloneDocument(data)
        .subscribe((clonedData) => {
          this.caseDetails.translated_document.push(clonedData);
        },
        err => {
          if (err) {
            if (err.error.msg) {
              this.showAlert(err.error.msg ,function () {
                this.popUpService.hideDiv('alertDialog');
              }.bind(this));
            }
            else {
              this.showAlert('Cannot clone the document.' ,function () {
                this.popUpService.hideDiv('alertDialog');
              }.bind(this));
            }
          }
        });
    }
    else {
      this.showAlert('File has already been cloned !' ,function () {
        this.popUpService.hideDiv('alertDialog');
      }.bind(this));
    }

  }

  downloadDoc(id, filename?: string, event?: Event ,test ?:boolean) {
    if (event) { event.preventDefault(); }

    this.caseService.downloadPDF(id, filename, 0, test).subscribe(
      result => {
        const { url } = result;
        window.open(url, '_blank');
      },
      error => {
        const errorText = error.error.message || 'Cannot open file';
        this.showAlert(errorText ,function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
      }
    );
  }

  holdResumeCase(status: string) {
    if (this.isReadOnly()) return console.info('For Non Read Access Only');

    const div = this.popUpService.getPopUpView(status);
    if (div != null) { this.popUpService.showDiv(div); }
  }

  hideDiv(divName: string, event?: Event) {
    if (event) {
      event.preventDefault();
    }
    const div = this.popUpService.getPopUpView(divName);
    if (div != null) { this.popUpService.hideDiv(div); }
  }

  
  validReason():void {
    const minLength = 3;
    const maxLength = 5000;
    if (this.activeUser === undefined) { return; }
    this.activeUser.inputValidator = (value) => {
      return new Promise<any>((resolve) => {

        if (value.length >= minLength && value.length <= maxLength) {
          this.frmHoldCase.controls['status_reason'].setValue(value);
          this.continueCase.fire();

        }
        else if(value.length >= maxLength){
          resolve('This field must not contain more than 50,000 characters !');
        }
        else {
          resolve('This field must contain at least 3 characters!');
        }
      });
    };
  }

  changeModalStatus(status: string,form: FormGroup){

    if (CommonFuntions.guardForAction(this.loginService, this.router)) {

      if (form.valid) {
        form.addControl(
          'case_id', new FormControl(this.caseDetails.id)
        );

        form.addControl(
          'case_status', new FormControl(status)
        );

        if (status === 'REQUESTED') {
          form.addControl(
            'last_hold_on', new FormControl(this.caseDetails.last_hold_on)
          );

          form.addControl(
            'total_hold_time', new FormControl(this.caseDetails.total_hold_time)
          );
        }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.caseService.changeCaseStatus(form.value).subscribe(()=>{
          const message = 'Case status changed successfully';
          this.toastr.success(message);
          this.router.navigate(['/case']);
          this.isLoading=false;
        },()=>{
          this.toastr.error('Network Error');
          this.isLoading=false;
        });
      }
      else{
        console.log('Form is invalid');
      }
    }
  }

  
  changeStatus(status: string, event: Event, form: FormGroup) {
    if (event) { event.preventDefault(); }
    if (CommonFuntions.guardForAction(this.loginService,
      this.router)) {
      if (form.valid) {
        this.hideDiv(status);
        this.showConfirmation('Are you sure you want to proceed?',function(){
          this.isLoading=true;

          form.addControl(
            'case_id', new FormControl(this.caseDetails.id)
          );
  
          form.addControl(
            'case_status', new FormControl(status)
          );
  
          if (status === 'REQUESTED') {
            form.addControl(
              'last_hold_on', new FormControl(this.caseDetails.last_hold_on)
            );
  
            form.addControl(
              'total_hold_time', new FormControl(this.caseDetails.total_hold_time)
            );
          }
  
          this.caseService.changeCaseStatus(form.value).subscribe(
            (_result) => {
              this.showAlert('Case status changed successfully.', function() {
                this.popUpService.hideDiv('alertDialog');
                this.router.navigate(['/case']);
              }.bind(this));
              this.popUpService.hideDiv('confirmDialog');
              this.isLoading = false;
            }, (_error) => {
              this.popUpService.hideDiv('confirmDialog');
              this.isLoading = false;
            }
          );
          this.popUpService.hideDiv('confirmDialog');

        }.bind(this));

      }
      else {
        CommonFuntions.validateAllFormFields(form);
      }
    }
  }

  // Function to handle the event emitted for case requested component
  setEmittedCase(caseDetail: any) {
    caseDetail['document_for_download'] = this.caseDetails.document_for_download;
    caseDetail['translated_document'] = this.caseDetails.translated_document;
    caseDetail['final_reports'] = this.caseDetails.final_reports;

    this.caseDetails = caseDetail;
    this.assignedMdList = this.setMdList(caseDetail.case_md_management);
    this.getFinishedList();
    this.constructCaseHistory();
    this.getActivities();
    this.caseDetails.status.sort(function (obj1, obj2) {
      return obj1.status.id - obj2.status.id;
    });
  }

  setShowStatus(status: string, state?: string) {
    // this.disableEdits = false;
    if (state && (state != null || state !== '') && state.toLowerCase() === 'not started') {
      return;
    }

    /* if (status.toLowerCase() === 'requested') {
      return;
    } */
    this.showStatus = status;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private filterVideoSummary(reportList: any[]): any[] {
    let filteredReport: any[] = [];

    if(reportList.length > 0){
      filteredReport = reportList.filter(eachReport => eachReport.document_type === 'VIDEO SUMMARY');
    }

    return filteredReport;
  }

  updateCaseDocuments() {
    const caseDocumentInterval = setInterval(function () {
      if (this.caseDetails && this.caseDetails != null) {
        this.caseService.getCaseDocuments(this.caseId).subscribe(case_documents => {
          this.final_reports = case_documents.final_reports;
          this.document_for_download = case_documents.document_for_download;
          this.translated_document = case_documents.translated_document;
          this.trackingline_upload = case_documents.trackingline_upload;
          this.videoLinkList = this.filterVideoSummary(case_documents.final_reports);
          this.caseDetails['document_for_download'] = this.document_for_download;
          this.caseDetails['trackingline_upload'] = this.trackingline_upload;
          this.caseDetails['final_reports'] = this.final_reports.reverse();
          this.caseDetails['translated_document'] = this.translated_document;
          this.caseDetails['videoLinkList'] = this.videoLinkList;
          this.caseDetails['test_document'] = case_documents.test_document;
        });

        clearInterval(caseDocumentInterval);
      }
    }.bind(this), 300);
  }

  getCaseDocuments(caseDetails, final_reports, document_for_download, compiled_document) {
    caseDetails['final_reports'] = final_reports;
    caseDetails['document_for_download'] = document_for_download;
    caseDetails['translated_document'] = compiled_document;
    return caseDetails;
  }

  private getInProgressStatus(caseDetail: any) {
    if (caseDetail == null) {
      return null;
    }

    const statusList = caseDetail.status;

    for (let i = 0; i < statusList.length; i++) {
      const state = statusList[i].state;
      if (state.toLowerCase() === 'inprogress') {
        return statusList[i];
      }
    }

    return null;
  }

  public getDisplayLabel(label: string) {

    if (label == null) {
      return 'N/A';
    }
    switch (label.toLowerCase()) {
    case 'mdmgmt': {
      return 'MDMgnt';
    }
    case 'translatedmr': {
      return 'CompileMR';
    }
    case 'view': {
      return 'Video';
    }
    case 'requested': {
      return this.followUpNumber > 0 ? 'Follow Up' : 'Requested';
    }
    default: {
      return label;
    }
    }
  }

  // postComment(event) {
  // 	event.preventDefault();
  // 	// this.comment.case_id = this.caseDetails.id;
  // 	// this.comment.user_id = this.loginService.getCurrentUser().user_id;
  // 	// this.comment.message = this.response.value;

  // 	if (this.message.value == null
  // 		|| this.message.value === '') {
  // 		return;
  // 	}
  // 	this.validateAssignee = true;
  // 	this.frmAssignee.addControl(
  // 		'case_id', new FormControl(this.caseDetails.id)
  // 	);

  // 	this.frmAssignee.addControl(
  // 		'user_id', new FormControl(this.loginService.getCurrentUser().user_id)
  // 	);
  // 	if (this.role === 'partner' || this.role === 'admin') {
  // 		this.frmAssignee.get('user_case_messages').clearValidators();
  // 		this.frmAssignee.get('user_case_messages').updateValueAndValidity();
  // 	}

  // 	if (this.frmAssignee.valid) {
  // 		this.caseService.addComment(this.frmAssignee.value).subscribe(
  // 			response => {
  // 				alert('Comment added sucessfully');
  // 				this.getCaseById(this.caseDetails.id);
  // 				this.getCaseMessages();
  // 				this.user_case_messages.controls = [];
  // 				this.frmAssignee.get('message').setValue('');
  // 			},
  // 			err => {
  // 				alert('Failed to add comment');
  // 			}
  // 		)
  // 	} else {
  // 		CommonFuntions.validateAllFormFields(this.frmAssignee);
  // 	}
  // }

  private setExternalStatus(statusList, case_type, role) {
    this.refinedStatus = CaseStatus.setExternalStatus(statusList, case_type, role);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private editDates() {
    if (this.isReadOnly()) return console.info('For Non Read Access Only');

    this.currentDateTime = new Date();

    if (this.caseDetails.date_mr_complete != null) {
      this.setDateTime(this.caseDetails.date_mr_complete, true);
    }

    const self = this;
    this.isDateEditEnabled = true;

    if (self && self.caseDetails && self.caseDetails.earliest_available_date && self.caseDetails.earliest_available_date != null) {
      setTimeout(() => {
        if (self.earliest_available_dateInput) {
          self.earliest_available_dateInput.nativeElement.value = self.caseDetails.earliest_available_date.substring(0, 10);
        }
      }, 300);
      //
      this.dateForm.get('earliest_available_date').setValue(this.caseDetails.earliest_available_date.substring(0, 10));
    }

    if (this.caseDetails.case_type.toLowerCase() === 'asynchronous video' ||
      this.caseDetails.case_type.toLowerCase() === 'written consultation') {
      this.dateForm.get('earliest_available_date').clearValidators();
      this.dateForm.get('earliest_available_date').updateValueAndValidity();
      this.dateForm.get('earliest_available_date').setValue(null);
    }
    else {
      this.dateForm.get('earliest_available_date').setValidators([Validators.required]);
      this.dateForm.get('earliest_available_date').updateValueAndValidity();
    }
    // this.isInProgress = true;
  }

  public fetchDocumentType(): void {
    this.docTypeList = [{
      key: 'Select',
      value: 'SELECT'
    }];

    this.commonService.getCaseDocumentType(this.user.user_type.type).subscribe(
      types_fetched => {
        this.docTypeList = this.docTypeList.concat(types_fetched);
        this.updateCaseDocuments();

        // this.isInProgress =false;
      },
      error => {
        console.log(error);
      },
      () => {
        this.isInProgress = false;
      }
    );
  }

  public editDocuments(event?: any) {
    if (event?.event) { event.event.preventDefault(); }
    if (this.isReadOnly()) return console.info('For Non Read Access Only');

    this.isEditEnabled = true;
    this.isInProgress = true;

    this.documentUploadType = event.uploads;
    this.fetchDocumentType();
    
  }

  /** Function to update the document type in the upload document list */
  updateUploadList(documentId: number, documentType: string): void {
    this.caseDetails.document_for_download = this.caseDetails.document_for_download.map(each => {
      if (each.id === documentId) {
        each.document_type = documentType;
      }
      return each;
    });
  }

  /** Function to update the document type in the compiled document list */
  updateCompiledList(documentId: number, documentType: string): void {
    this.caseDetails.translated_document = this.caseDetails.translated_document.map(each => {
      if (each.id === documentId) {
        each.document_type = documentType;
      }
      return each;
    });
  }

  /** Function to update the document type of both upload and compiled documents */
  public changeDocType(type: HTMLInputElement, file: any, uploadType: string, _event?: Event) {
    if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
      this.router.navigate(['/']);
      return;
    }

    const reqBody = {};
    reqBody['type'] = type.value;
    reqBody['documentId'] = file.id;
    reqBody['upload_stage'] = file.upload_stage;

    this.isInProgress = true;
    this.caseService.updateDocumentType(reqBody).subscribe(
      response => {
        const { status, message } = response;
        if (status === 200) {
          if (uploadType === 'uploads') {
            this.updateUploadList(file.id, type.value);
          }

          if (uploadType === 'compiled') {
            this.updateCompiledList(file.id, type.value);
          }

          this.popUpService.destoryConfirmDialog();
          this.popUpService.setDialogParams('Alert', message, 'Ok');
          this.popUpService.setPositiveAction(function() {
            this.hideDiv('alertDialog');
          }.bind(this));

          const alertDialog = this.popUpService.getPopUpView('alertDialog');
          if (alertDialog != null) { this.popUpService.showDiv(alertDialog); }
          this.isInProgress = false;
        }
      },
      (_error) => {
        this.showAlert('An error occured while updating the document type.', function() {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
        this.isInProgress = false;
        file.isUpdating = false;
        type.value = file.document_type;
      }
    );
  }

  public deleteDocument(file: any, event?: Event) {
    if (event) { event.preventDefault(); }

    if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
      this.router.navigate(['/']);
      return;
    }

    if (this.editEnabledDocument != null) {
      this.popUpService.destoryConfirmDialog(); // destroy if initialized

      this.popUpService.setDialogParams(
        'Confirmation',
        'Do you want to save changes?',
        'Yes',
        'No',
        function () {
          this.saveLinkEdit(this.editEnabledDocument);

          this.editEnabledDocument.editEnabled = false;
          this.editEnabledDocument = null;

          // hide pop up after action
          this.popUpService.hideDiv('confirmDialog');
        }.bind(this),
        function () {

          this.editEnabledDocument.editEnabled = false;
          this.editEnabledDocument = null;

          // hide pop up after action
          this.popUpService.hideDiv('confirmDialog');
        }.bind(this)
      );

      const confirmDialog = this.popUpService.getPopUpView('confirmDialog');
      if (confirmDialog != null) { this.popUpService.showDiv(confirmDialog); }
    }
    else {
      const message = 'Do you want to remove ' + file.document_name + ' from case?';
      const dialog = this.popUpService.getPopUpView('confirmDialog');
      this.popUpService.destoryConfirmDialog();

      this.popUpService.setDialogParams(
        'Confirmation',
        message,
        'Yes',
        'No',
        function () {
          this.isInProgress = true;
          this.caseService.deleteDocument(file.id).subscribe(
            response => {

              this.caseService.getCaseDocuments(this.caseDetails.id).subscribe(function (documents) {

                this.component.document_for_download = documents.document_for_download;
                this.component.final_reports = documents.final_reports;
                this.component.translated_document = documents.translated_document;
                this.component.trackingline_upload = documents.trackingline_upload;
                
                this.component.caseDetails['document_for_download'] = documents.document_for_download;
                this.component.caseDetails['final_reports'] = documents.final_reports;
                this.component.caseDetails['translated_document'] = documents.translated_document;
                this.component.caseDetails['trackingline_upload'] = documents.trackingline_uploads;
                this.component.popUpService.destoryConfirmDialog(); // destory if already initialized

                this.component.popUpService.setDialogParams(
                  'Alert',
                  this.response.msg + '.',
                  'Ok'
                );
                this.component.popUpService.setPositiveAction(
                  function () {
                    // reset edit document

                    this.hideDiv('alertDialog');
                  }.bind(this.component)
                );

                const alertDialog = this.component.popUpService.getPopUpView('alertDialog');

                if (alertDialog != null) { this.component.popUpService.showDiv(alertDialog); }

                this.component.isInProgress = false;

              }.bind(
                {
                  component: this,
                  response: response
                }
              ));
            },
            error => {
              this.showAlert(error.error.message ,function () {
                this.popUpService.hideDiv('alertDialog');
              }.bind(this));
              this.isInProgress = false;
            }
          );

          this.popUpService.hideDiv('confirmDialog');
        }.bind(this),
        function () {
          this.hideDiv('confirmDialog');
        }
      );

      this.popUpService.showDiv(dialog);
    }
  }

  public deleteVideoLink(event: Event, videoLink: any) {
    if(event) { event.preventDefault(); }

    if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
      this.router.navigate(['/']);
      return;
    }

    if(this.editEnabledVideoLink != null){
      this.popUpService.destoryConfirmDialog();
      this.popUpService.setDialogParams(
        'Confirmation',
        'Do you want to save changes?',
        'Yes',
        'No',
        function(){
          this.saveVideoLinkEdit(this.editEnabledDocument);

          this.editEnabledVideoLink.editEnabled = false;
          this.editEnabledVideoLink = null;
          this.popUpService.hideDiv('confirmDialog');
        }.bind(this),
        function(){
          this.editEnabledVideoLink.editEnabled = false;
          this.editEnabledVideoLink = null;
          this.popUpService.hideDiv('confirmDialog');
        }.bind(this)
      );
    }
    else {
      const message = 'Do you want to remove ' + videoLink.document_name + ' from case?';
      this.popUpService.destoryConfirmDialog();

      this.popUpService.setDialogParams(
        'Confirmation',
        message,
        'Yes',
        'No',
        function(){
          this.isInProgress = true;
          this.caseService.deleteVideoLink(videoLink.id).subscribe(
            (_response) => {
              this.popUpService.hideDiv('confirmDialog');
              this.caseDetails.videoLinkList = this.caseDetails.videoLinkList.filter(eachVideoLink => eachVideoLink.id !== videoLink.id);
              this.caseDetails.final_reports = this.caseDetails.final_reports.filter(eachReport => eachReport.id !== videoLink.id);

              this.isInProgress = false;
              this.showAlert('Successfully deleted link.' ,function () {
                this.popUpService.hideDiv('alertDialog');
              }.bind(this));
            },
            (_err) => {
              this.isInProgress = false;
              this.showAlert('Failed to delete link.' ,function () {
                this.popUpService.hideDiv('alertDialog');
              }.bind(this));
            }
          ).bind(this);
        }.bind(this),
        function(){
          this.popUpService.hideDiv('confirmDialog');
        }.bind(this)
      );

      const dialog = this.popUpService.getPopUpView('confirmDialog');
      if (dialog) { this.popUpService.showDiv(dialog); }
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private getReadableFileSizeString(fileSizeInBytes) {
    let i = -1;
    const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
      fileSizeInBytes = fileSizeInBytes / 1024;
      i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  }

  public completeDocEdit() {
    if (this.editEnabledDocument != null) {
      this.popUpService.setDialogParams(
        'Confirmation',
        'Do you want to save changes?',
        'Yes',
        'No',
        function () {
          this.saveLinkEdit(this.editEnabledDocument);
          this.editEnabledDocument.editEnabled = false;
          this.editEnabledDocument = null;

          // hide pop up after action
          this.popUpService.hideDiv('confirmDialog');
        }.bind(this),
        function () {

          this.editEnabledDocument.editEnabled = false;
          this.editEnabledDocument = null;

          // hide pop up after action
          this.popUpService.hideDiv('confirmDialog');
        }.bind(this)
      );
      const confirmDialog = this.popUpService.getPopUpView('confirmDialog');
      if (confirmDialog != null) { this.popUpService.showDiv(confirmDialog); }
    }
    else {
      this.getCaseById(this.caseDetails.id);
      this.getActivities();
      this.updateCaseDocuments();
      this.isEditEnabled = false;
    }

    this.documentUploadType = '';
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private hasWeChatID(caseDetail): boolean {
    if (caseDetail == null) {
      return false;
    }

    const contactList = caseDetail.patient.user.contacts;

    if (contactList == null) { return false; }

    const index = contactList.findIndex(contact => contact.social_type.social_type.toLowerCase() === 'wechat');

    if (index > -1) {
      return true;
    }
    else {
      return false;
    }
  }

  editEnableVideoLink(event: Event, videoLink: any): void {
    if(event) { event.preventDefault(); }

    videoLink.editEnabled = true;
    videoLink.modifyVideoLink = videoLink.location;
    videoLink.modifyVideoName = videoLink.document_name;
    
    this.editEnabledVideoLink= videoLink;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private enableLinkEdit(event: Event, link: any) {
    event.preventDefault();

    link.editEnabled = true;
    link.modifyLink = link.location;
    link.modifyScanName = link.document_name;

    this.editEnabledDocument = link;
  }

  public saveLinkEdit(link: any, event?: Event) {
    if (event) { event.preventDefault(); }

    if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
      this.router.navigate(['/']);
      return;
    }

    const reqBody = {};
    reqBody['location'] = link.modifyLink;
    reqBody['documentName'] = link.modifyScanName;
    reqBody['documentId'] = link.id;
    reqBody['upload_stage'] = link.upload_stage;

    this.isInProgress = true;
    this.caseService.updateDocumentType(reqBody).subscribe(
      response => {
        this.caseService.getCaseDocuments(this.caseDetails.id).subscribe(function (documents) {
          this.component.caseDetails['trackingline_upload'] = documents.trackingline_upload;
          this.component.caseDetails['document_for_download'] = documents.document_for_download;
          this.component.caseDetails['final_reports'] = documents.final_reports;
          this.component.caseDetails['translated_document'] = documents.translated_document;
          this.component.isInProgress = false;

          this.component.popUpService.destoryConfirmDialog(); // destory if already initialized

          this.component.popUpService.setDialogParams(
            'Alert',
            this.response.message,
            'Ok'
          );
          this.component.popUpService.setPositiveAction(
            function () {
              // reset edit document

              this.hideDiv('alertDialog');
            }.bind(this.component)
          );

          const alertDialog = this.component.popUpService.getPopUpView('alertDialog');

          if (alertDialog != null) { this.component.popUpService.showDiv(alertDialog); }

          this.updateFlag = false;

          this.component.editEnabledDocument = null;

        }.bind(
          {
            component: this,
            response: response,
            updateFlag: link.isUpdating
          }
        ));
      },
      error => {
        this.showAlert(error.message ,function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
        link.isUpdating = false;
      }
    );
    link.editEnabled = false;
  }

  public saveVideoLinkEdit(videoLink: any, event?: Event) {
    if(event) { event.preventDefault(); }

    if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
      this.router.navigate(['/']);
      return;
    }

    const reqBody = {};
    reqBody['location'] = videoLink.modifyVideoLink;
    reqBody['documentName'] = videoLink.modifyVideoName;
    reqBody['documentId'] = videoLink.id;
    reqBody['upload_stage'] = videoLink.upload_stage;

    this.isInProgress = true;
    this.caseService.updateVideoLink(reqBody).subscribe(
      (_result) => {
        this.caseDetails.videoLinkList = this.caseDetails.videoLinkList.map(eachVideoLink => {
          if(eachVideoLink.id === videoLink.id){
            eachVideoLink.location = videoLink.modifyVideoLink;
            eachVideoLink.document_name = videoLink.modifyVideoName;
          }

          return eachVideoLink;
        });

        this.caseDetails.final_reports = this.caseDetails.final_reports.map(eachVideoReport => {
          if(eachVideoReport.id === videoLink.id){
            eachVideoReport.document_name = videoLink.modifyVideoName;
            eachVideoReport.location = videoLink.modifyVideoLink;
          }

          return eachVideoReport;
        });

        videoLink.editEnabled = false;
        this.editEnabledVideoLink = null;

        this.isInProgress = false;
        this.showAlert('Link updated successfully.' ,function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
      },
      (_err) => {
        this.showAlert('Failed to update link.' ,function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
        videoLink.editEnabled = false;
        this.editEnabledVideoLink = null;
        this.isInProgress = false;
      }
    );

  }

  dropFocus(dropdown, event) {
    if (this.editEnabledDocument == null) { return; }

    if (event && event != null) { event.preventDefault(); }
    dropdown.style.display = 'none';
    setTimeout(() => {
      dropdown.style.display = 'block';
    }, 10);

    // alert('are you sure');

    this.popUpService.setDialogParams(
      'Confirmation',
      'Do you want to save changes?',
      'Yes',
      'No',
      function () {
        this.saveLinkEdit(this.editEnabledDocument);

        this.editEnabledDocument.editEnabled = false;
        this.editEnabledDocument = null;

        // hide pop up after action
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this),
      function () {

        this.editEnabledDocument.editEnabled = false;
        this.editEnabledDocument = null;

        // hide pop up after action
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this)
    );

    dropdown.hidden = true;
    const confirmDialog = this.popUpService.getPopUpView('confirmDialog');
    if (confirmDialog != null) { this.popUpService.showDiv(confirmDialog); }
  }

  private getUploader() {
    const urlString = ConfigAssetLoaderService.getApiURL() +
      '/edit/upload?caseid=' + this.caseId +
      '&upload_stage=PARTNER_CARETEAM_UPLOADS&type=';
    const token = localStorage.getItem('token');
    const fileUploader = new FileUploader(
      {
        url: urlString,
        itemAlias: 'document',
        authToken: 'Bearer ' + token,
        queueLimit: 1,
        // allowedMimeType: this.listOfMime,
        // maxFileSize: 10 * 1024 * 1024
      }
    );

    fileUploader.onAfterAddingFile = function (file) {
      file.withCredentials = false;

      if (fileUploader.queue.length > 1) {
        fileUploader.queue.splice(0, 1);
      }

      setTimeout(
        function () {
          if (this.documentInput) { this.documentInput.value = null; }

        }.bind(this)
        , 300
      );
    }.bind(this);

    fileUploader.onCompleteItem = function (_item, response, status, _header) {
      if (response == null || response === '') { return; }
      if (response !== '' && status !== 200) {
        const error = JSON.parse(response);
        console.log('error', error);
        let message = 'Failed to upload document.';
        if (error.message === 'File too large') {
          message = 'File is too large to upload';
        }

        if (error && error.msg) { message = error.msg; }
        this.showAlert(message);
      }
      else if (response !== '') {
        if (
          (this.role.toLowerCase() === 'patient') ||
          (this.role.toLowerCase() === 'partner') ||
          ((this.role.toLowerCase() === 'careteam' || this.role.toLowerCase() === 'admin' || this.role.toLowerCase() === 'casecoordinator') && this.documentUploadType === 'uploads')
        ) {
          this.caseDetails.document_for_download.push(JSON.parse(response));
        }
        else if ((this.role.toLowerCase() === 'careteam' || this.role.toLowerCase() === 'admin' || this.role.toLowerCase() === 'casecoordinator') && this.documentUploadType === 'compiled') {
          const uploadedDoument = JSON.parse(response);

          if (uploadedDoument.document_type === 'CONSULTATION SUMMARY' || uploadedDoument.document_type === 'CONSULTATION SUMMARY TRANSLATED') {
            this.caseDetails.final_reports.push(uploadedDoument);

            let translatedFinalReport = this.caseDetails.translated_document;

            const targetReport = this.caseDetails.final_reports.filter(report => report.document_type === 'CONSULTATION SUMMARY TRANSLATED');

            translatedFinalReport = translatedFinalReport.concat(targetReport);

            this.caseDetails.translated_document = translatedFinalReport;
            this.translated_document = this.caseDetails.translated_document;
          }
          else {
            this.caseDetails.translated_document.push(uploadedDoument);
            this.translated_document = this.caseDetails.translated_document;
          }
        }
      }
      this.getCaseById(this.caseDetails.id);
      this.updateCaseDocuments();
    }.bind(this);

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fileUploader.onErrorItem = function (_item, _response, _status, _header) {
    };

    fileUploader.onWhenAddingFileFailed = function (_item, filter, _option) {
      switch (filter.name) {
      case 'mimeType':
        this.documentInput.nativeElement.value = '';
        this.showAlert('System does not support this type of file format.');
        break;
      case 'fileSize':
        this.documentInput.nativeElement.value = '';
        this.showAlert('Files to be uploaded must be less than of size 50 MB.');
        break;
      default:
        break;
      }
    }.bind(this);

    return fileUploader;
  }

  private showAlert(message: string, action?: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (!action) {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }
    else {
      this.popUpService.setPositiveAction(
        action
      );
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) { this.popUpService.showDiv(dialog); }
  }

  uploadUploadSectionFile(documentType: HTMLSelectElement, documentInput: HTMLInputElement,
    uploader: FileUploader, section: string) {
    if (documentType.value === 'select' || (uploader && uploader.queue.length < 1)) {
      return;
    }

    if (section.toLowerCase() === 'uploads') {
      uploader.onBeforeUploadItem = function (item) {
        item.url = ConfigAssetLoaderService.getApiURL() +
          '/edit/upload?caseid=' + this.component.caseId +
          '&upload_stage=CASE_CREATION&type=' + this.documentType;
        item.withCredentials = false;	
      }.bind({
        component: this,
        documentType: documentType.value
      });
      this.uploadsSectionUploader.push(uploader);
      uploader.uploadAll();
      
      // this.caseDetails.date_mr_complete = new Date()
      // this.setDateTime(this.caseDetails.date_mr_complete, true)
    }
    else if (section.toLowerCase() === 'translated') {
      uploader.onBeforeUploadItem = function (item) {
        item.url = ConfigAssetLoaderService.getApiURL()
        + '/edit/upload?caseid=' + this.component.caseId +
        '&upload_stage=TRANSLATOR_ATTACHMENT&type=' + this.documentType;
      }.bind({
        component: this,
        documentType: documentType.value
      });
      this.translatedSectionUploader.push(uploader);
      uploader.uploadAll();
    }
    
    this.uploader = this.getUploader();
    documentInput.value = null;
    documentType.value = 'select';
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private confirmAddingVideoLink(videoName: HTMLInputElement, videoLocation: HTMLInputElement, section?: string): void{
    this.isVideoLinkUploading = true;

    const videoObj = {};
    videoObj['case_id'] = this.caseId;
    videoObj['document_name'] = videoName.value;
    videoObj['location'] = videoLocation.value;
    videoObj['document_type'] = 'VIDEO SUMMARY';
    if (section === 'uploads') {
      videoObj['upload_stage'] = 'CASE_CREATION';
    }
    else {
      videoObj['upload_stage'] = 'TRANSLATOR_ATTACHMENT';
    }

    this.caseService.addScanList(videoObj).subscribe(
      addVideo => {
        this.caseDetails.final_reports.push(addVideo);
        this.showAlert('Link has been successfully added.' ,function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
        this.caseDetails.videoLinkList.push(addVideo);
        this.isVideoLinkUploading = false;
      },
      (_error) => {
        this.isVideoLinkUploading = false;
      }
    );

    videoName.value = '';
    videoLocation.value = '';
  }
  
  public isValidUrl(string) {
    try {
      new URL(string);
    }
    catch (_) {
      return false;  
    }
    
    return true;
  }

  public addVideoLink(videoName: HTMLInputElement, videoLocation: HTMLInputElement, section?: string): void {
    this.errors={};

    if(videoName.value.toLowerCase() === '') { 
      this.errors = {
        ...this.errors,
        name: 'Name is required.',
        linkError: true
      };
    }
    if(videoLocation.value.toLowerCase() === '') { 
      this.errors = {
        ...this.errors,
        link: 'Link is required.',
        linkError: true
      };
    }

    if(this.errors.linkError){ return;}

    this.popUpService.destoryConfirmDialog();
    this.popUpService.setDialogParams(
      'Confirmation',
      'Are you sure you want to add the Link?',
      'Yes',
      'No',
      function () {
        this.confirmAddingVideoLink(videoName, videoLocation, section);
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this),
      function () {
        videoName.value = videoLocation.value = '';
        this.hideDiv('confirmDialog');
      }.bind(this)
    );

    const dialog = this.popUpService.getPopUpView('confirmDialog');
    if (dialog) { this.popUpService.showDiv(dialog); }
  }

  public editAddScan(scanName: HTMLInputElement, scanLocation: HTMLInputElement, section?: string) {
    this.errors={};
    if(scanName.value.toLowerCase() === '') { 
      this.errors = {
        ...this.errors,
        scanName: 'Name is required.',
        scanError: true
      };
    }
    if(scanLocation.value.toLowerCase() === '') { 
      this.errors = {
        ...this.errors,
        scanLink: 'Link is required.',
        scanError: true
      };
    }

    if(this.errors.scanError){ return;}


    this.isLinkUploading = true;

    const scan = {};
    scan['case_id'] = this.caseId;
    scan['document_name'] = scanName.value;
    scan['location'] = scanLocation.value;
    if (section === 'uploads') {
      scan['upload_stage'] = 'CASE_CREATION';
    }
    else {
      scan['upload_stage'] = 'TRANSLATOR_ATTACHMENT';
    }


    this.caseService.addScanList(scan).subscribe(
      addScan => {
        if (section === 'uploads') {
          this.caseDetails.document_for_download.push(addScan);
        }
        else {
          this.caseDetails.translated_document.push(addScan);
        }
        this.isLinkUploading = false;
      },
      (_error) => {
        this.isLinkUploading = false;
      }
    );

    scanName.value = '';
    scanLocation.value = '';
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private cancelUpload(uploader: FileUploader, index: number, section: string, event?: Event) {
    if (event) { event.preventDefault(); }

    uploader.cancelAll();

    if (section.toLowerCase() === 'uploads') {
      this.uploadsSectionUploader.splice(index, 1);
    }
    else if (section.toLowerCase() === 'translated') {
      this.translatedSectionUploader.splice(index, 1);
    }
  }

  public canContinue(caseDetail: any, stage) {
    const report = caseDetail.status.find(status => status.status.status.toLowerCase() === stage.toLowerCase());

    if (report == null || report.state.toLowerCase() === 'not started') {
      return false;
    }

    return true;
  }

  public onCommunicationRecordEdit() {
    if (this.isReadOnly()) return console.info('For Non Read Access Only');
    this.isCommunicationRecordEdit = this.isCommunicationRecordEdit ? false : true;
  }

  public onMedicalRecordEdit() {
    this.isMedicalRecordEdit = this.isMedicalRecordEdit ? false : true;
  }

  public onPeerToPeerEdit() {
    this.isPeerToPeerInfoEdit = this.isPeerToPeerInfoEdit ? false : true;
  }

  public saveDate() {
    if (this.isReadOnly()) return console.info('For Non Read Access Only');
    if (
      CommonFuntions.guardForAction(this.loginService, this.router)
      && this.dateForm.valid
    ) {
      this.showConfirmation('Do you want to save the changes?',function(){

        this.isDateEditEnabled = false;
  
        this.isInProgress = true;
  
        const value = this.dateForm.value;
        value['speciality_program'] = this.caseDetails.speciality_program;
        value['patient_diagnosis'] = this.caseDetails.patient_diagnosis;
        value['md_type_id'] = this.caseDetails.md_type_id;
        value['category_id'] = this.caseDetails.category_id;
        value['category_field_id'] = this.caseDetails.category_field_id;
        value['notes'] = this.caseDetails.case_additional_note;
  
        this.caseService.updateMedicalData(
          this.dateForm.value,
          this.caseId
        ).subscribe(
          result => {
            const timezoneString = momentTz.tz.guess();
            const earliest_available_date = momentTz.tz(result.earliest_available_date, timezoneString).format('YYYY-MM-DDTHH:mm:ssZ');
            this.caseDetails.earliest_available_date = earliest_available_date;
  
            this.caseDetails.date_mr_complete = result.date_mr_complete;
            this.showAlert('Date updated successful.' ,function () {
              this.popUpService.hideDiv('alertDialog');
            }.bind(this));
            this.popUpService.hideDiv('confirmDialog');
            this.getActivities();

          },
          (_error) => {
            this.showAlert('Date update failed.' ,function () {
              this.popUpService.hideDiv('alertDialog');
            }.bind(this));
            this.isInProgress = false;
            this.popUpService.hideDiv('confirmDialog');
          },
          () => {
            this.isInProgress = false;
          }
        );
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this),function(){

        const dateData = {};
        dateData['earliest_available_date'] = this.caseDetails.earliest_available_date;
        dateData['date_mr_complete'] = this.caseDetails.date_mr_complete;

        this.dateForm.setValue(dateData);

        this.isDateEditEnabled = false;
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this)
      );

    }
    else {
      CommonFuntions.validateAllFormFields(this.dateForm);
    }
  }

  setDateTime(date, programSet?: boolean) {
    if (!programSet) {
      this.dateForm.get('date_mr_complete').markAsTouched();
    }

    let momentDate;
    if (date != null) {
      if (date._selected) {
        momentDate = moment(date._selected);
      }
      else {
        momentDate = moment(date);
      }
    }

    const abc = moment().diff(momentDate, 'days');

    this.isValidDate = (abc > 0) ? false : true;

    const userAgent = window.navigator.userAgent;

    const browsers = { chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i };
    let selectedDate = '';


    for (const key in browsers) {
      if (browsers[key].test(userAgent)) {
        if (key === 'firefox') {
          selectedDate = momentDate.format('MM/DD/YYYY, h:mm a').substring(0, 24);

        }
        else {
          if (userAgent.toLowerCase().indexOf('safari') !== -1) {
            if (userAgent.toLowerCase().indexOf('chrome') > -1) {
              selectedDate = momentDate.format('YYYY-MM-DDTHH:mm').substring(0, 16);
            }
            else {
              selectedDate = momentDate.format('YYYY-MM-DDTHH:mm');
            }
          }
        }

        setTimeout(() => {
          if (this.caseDetails.date_mr_complete !== undefined) {
            if (this.dateMRCompleteInput) { this.dateMRCompleteInput.nativeElement.value = selectedDate; }
          }
        }, 300);

        this.dateForm.get('date_mr_complete').setValue(momentDate.format('YYYY-MM-DDTHH:mm:ssZ'));
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private onDateChange(_event, calender, _dateInput) {
    let date = moment(calender.selected);
    const createdDateMoment = moment(this.caseDetails.created_at);

    if (date.isBefore(createdDateMoment)) {
      date = createdDateMoment;
    }
    this.dateForm.get('earliest_available_date').setValue(date.format('YYYY-MM-DD'));
    if (this.earliest_available_dateInput) { this.earliest_available_dateInput.nativeElement.value = date.format('YYYY-MM-DD'); }
  }

  preventDefault(event: Event) {
    const userAgent = window.navigator.userAgent;
    const browsers = { chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i };

    for (const key in browsers) {
      if (browsers[key].test(userAgent)) {
        if (key === 'firefox') {
          event.preventDefault();

        }
      }
    }

  }

  private hasCommunicationValueChanged(communication_mode: string, best_communication_time: string, phone_number: number): Boolean {
    let changed = true;
    if (communication_mode !== 'EMAIL') {
      if (communication_mode === this.caseDetails.preferred_communication_mode
        && best_communication_time === this.caseDetails.best_communication_time
        && phone_number === this.caseDetails.phone_number) {
        changed = false;
      }
    }
    else {
      if (communication_mode === this.caseDetails.preferred_communication_mode) {
        changed = false;
      }
    }

    return changed;
  }

  public saveCommunicationData() {
    if (this.isReadOnly()) return console.info('For Non Read Access Only');
    if (CommonFuntions.guardForAction(this.loginService, this.router) && this.communicationDetailForm.valid) {
      const value = this.communicationDetailForm.value;
      const isValueChanged = this.hasCommunicationValueChanged(value['preferred_communication_mode'],
        value['best_communication_time'], value['phone_number']);
      if (!isValueChanged) {
        this.isCommunicationRecordEdit = false;
      }
      else {
        this.showConfirmation('Do you want to save the changes?', function(){
          this.isCommunicationRecordEdit = false;
  
          this.isInProgress = true;
  
          this.caseService.updateCommunicationData(value, this.caseId).subscribe(result => {
            this.caseDetails.preferred_communication_mode = result.preferred_communication_mode;
            this.caseDetails.best_communication_time = result.best_communication_time;
            this.caseDetails.phone_number = result.phone_number;
            this.isInProgress = false;
            this.showAlert('Communication details updated.' ,function () {
              this.popUpService.hideDiv('alertDialog');
            }.bind(this));
          },
          (_error) => {
            this.showAlert('Update communication details failed.' ,function () {
              this.popUpService.hideDiv('alertDialog');
            }.bind(this));
            this.isInProgress = false;
            this.popUpService.hideDiv('confirmDialog');

          },
          () => {
            this.isInProgress = false;
          });
          this.popUpService.hideDiv('confirmDialog');
        }.bind(this),function(){
          const communicationData = {};
          communicationData['preferred_communication_mode'] = this.caseDetails.preferred_communication_mode;
          communicationData['best_communication_time'] = this.caseDetails.best_communication_time;
          this.communicationDetailForm.setValue(communicationData);
  
          this.isCommunicationRecordEdit = false;
          this.popUpService.hideDiv('confirmDialog');

        }.bind(this));

      }
    }
    else {
      CommonFuntions.validateAllFormFields(this.communicationDetailForm);
    }
  }

  public saveMedicalData() {
    if (
      CommonFuntions.guardForAction(this.loginService, this.router)
      && this.medicalForm.valid
    ) {
      this.showConfirmation('Do you want to save the changes?',function(){
        const dateFormat = /[\d]{4}-[\d]{2}-[\d]{2}T[\d]{2}:[\d]{2}:[\d]{2}Z/g;
        this.isMedicalRecordEdit = false;

        this.isInProgress = true;

        const value = this.medicalForm.value;
        const earliestDateMoment = moment(this.caseDetails.earliest_available_date);
        if (earliestDateMoment.isValid()) {
          value['earliest_available_date'] = earliestDateMoment.format('YYYY-MM-DD');
        }
        else {
          value['earliest_available_date'] = null;
        }
        if (!dateFormat.test(this.caseDetails.date_mr_complete)) {
          const mrMoment = moment(this.caseDetails.date_mr_complete);
          value['date_mr_complete'] = mrMoment.format('YYYY-MM-DDTHH:mm:ssZ');
        }
        else {
          value['date_mr_complete'] = this.caseDetails.date_mr_complete;
        }

        this.caseService.updateMedicalData(
          value,
          this.caseId
        ).subscribe(
          result => {
            this.caseDetails.speciality_program = result.speciality_program? result.speciality_program : null;
            this.caseDetails.patient_diagnosis = result.patient_diagnosis;
            this.caseDetails.category = result.category;
            this.caseDetails.category_field = result.category_field;
            this.caseDetails.md_type = result.md_type;
            if (this.mdManagementComponent && this.mdManagementComponent != null) { this.mdManagementComponent.getEmailTemplate(); }
            this.showAlert('Medical Data update successful.' ,function () {
              this.popUpService.hideDiv('alertDialog');
            }.bind(this));
            this.popUpService.hideDiv('confirmDialog');

          },
          (_error) => {
            this.showAlert('Medical Data update failed.' ,function () {
              this.popUpService.hideDiv('alertDialog');
            }.bind(this));
            this.isInProgress = false;
            this.popUpService.hideDiv('confirmDialog');

          },
          () => {
            this.isInProgress = false;
          }
        );
        this.popUpService.hideDiv('confirmDialog');

      }.bind(this), function(){
        this.category_fields = [];
        const index = this.categories.findIndex(eachCategory => eachCategory.category_id === this.caseDetails.category_id);
        const category_fields = this.categories[index].category_fields.slice(0);
        this.category_fields = category_fields;
        this.category_fields.splice(0, 0, { category_field_id: 0, category_field: 'Select' });

        const medicalData = {};
        medicalData['speciality_program'] = this.caseDetails.speciality_program ? this.caseDetails.speciality_program : null;
        medicalData['category_id'] = this.caseDetails.category_id;
        medicalData['category_field_id'] = this.caseDetails.category_field_id;
        medicalData['md_type_id'] = this.caseDetails.md_type_id;
        medicalData['patient_diagnosis'] = this.caseDetails.patient_diagnosis;
        medicalData['case_additional_note'] = this.caseDetails.case_additional_note;
        if (this.role === 'admin' || this.role === 'careteam' || this.role === 'mdmanager' || this.role === 'casecoordinator' || this.role === 'cmmanager' || this.caseDetails?.patient?.user?.user_partners[0]?.partner.isElevatedPartner) {
          medicalData['case_careteam_note'] = this.caseDetails.case_careteam_note;
        }

        this.medicalForm.setValue(medicalData);

        this.isMedicalRecordEdit = false;
        this.popUpService.hideDiv('confirmDialog');

      }.bind(this));
    }
    else {
      CommonFuntions.validateAllFormFields(this.medicalForm);
    }
  }

  public savePeerToPeerData() {
    // Uncomment when needed
    // this.caseService.caseUpsert({
    //   ...this.peerToPeerForm.value,
    //   caseId: this.caseDetails.id
    // });
  }

  public getCategory(age: number) {
    this.commonService.getCategory(age).subscribe(
      ageGroups => {
        ageGroups.categories.forEach(
          category => {
            this.categories.push(category);
          }
        );

        if (this.caseDetails != null && this.caseDetails.category != null) {
          let oldCategory = this.categories.findIndex(each => each.category === this.caseDetails.category.category);

          if (oldCategory < 0) {
            this.categories.push(this.caseDetails.category);

            this.categories.sort(
              function (category1, category2) {
                if (category1.category > category2.category) {
                  return 1;
                }

                return -1;
              }
            );

            oldCategory = this.categories.findIndex(each => each.category === this.caseDetails.category.category);

          }

          const caseCategory = this.categories[oldCategory];
          if (!caseCategory.category_fields || caseCategory.category_fields == null) {
            caseCategory['category_fields'] = [{ category_field_id: 0, category_field: 'Select' }];
          }

          if (
            (!this.caseDetails.category_field || this.caseDetails.category_field == null) &&
            caseCategory.category_fields.length < 1
          ) {
            this.medicalForm.get('category_field_id').setValue(0);
            this.medicalForm.get('category_field_id').clearValidators();
            this.medicalForm.get('category_field_id').disable();
          }
          else if (
            (!this.caseDetails.category_field || this.caseDetails.category_field == null) &&
            caseCategory.category_fields.length > 0
          ) {
            this.medicalForm.get('category_field_id').setValue(0);
          }

          const categoryFieldList = caseCategory.category_fields;
          if (this.caseDetails.category_field && this.caseDetails.category_field != null) {
            const categoryFieldIndex = categoryFieldList.findIndex(each => each.category_field === this.caseDetails.category_field.category_field);

            if (categoryFieldIndex < 0) {
              categoryFieldList.push({
                category_field: this.caseDetails.category_field,
                category_field_id: this.caseDetails.category_field_id
              });

              categoryFieldList.sort(
                function (categoryField1, categoryField2) {
                  if (categoryField1.category_field > categoryField2.category_field) {
                    return 1;
                  }

                  return -1;
                }
              );
            }

            this.categories[oldCategory] = caseCategory;

            if (!this.caseDetails.category_field) {
              this.medicalForm.get('category_id').setValue(0);
            }

            this.category_fields = caseCategory.category_fields;

            if (this.category_fields.length < 2) {
              this.enableDisableCategoryField(false);
            }
            else {
              this.enableDisableCategoryField(true);
            }

          }
          else {
            this.category_fields = this.category_fields.concat(caseCategory.category_fields);
          }
        }
      }
    );
  }

  public getCommunicationDataForm() {
    return new FormGroup(
      {
        preferred_communication_mode: new FormControl('', [Validators.required]),
        best_communication_time: new FormControl(''),
        phone_number: new FormControl('')
      }
    );
  }

  public getDateForm() {
    return new FormGroup(
      {
        earliest_available_date: new FormControl('', Validators.required),
        date_mr_complete: new FormControl('', Validators.required)
      }
    );
  }

  get date_mr_complete() {
    return this.dateForm.get('date_mr_complete');
  }

  public getMdType() {
    this.commonService.getMdType().subscribe(
      mdTypes => {
        mdTypes.forEach(
          mdType => {
            this.md_types.push(mdType);
          }
        );
      }
    );
  }

  public onCategorySelect(index: number) {
    if (index < 1) {
      this.category_fields = [{
        category_field_id: 0,
        category_field: 'Select'
      }];
    }
    else {
      this.medicalForm.get('category_field_id').setValue(0);
      this.medicalForm.get('category_field_id').markAsUntouched();

      this.category_fields = [];
      const category_fields = this.categories[index].category_fields.slice(0);
      this.category_fields = category_fields;
      this.category_fields.splice(0, 0, { category_field_id: 0, category_field: 'Select' });
    }

    if (index < 1 || this.category_fields.length < 2) {
      this.enableDisableCategoryField(false);
    }
    else {
      this.enableDisableCategoryField(true);
    }
  }

  public enableDisableCategoryField(enable: boolean) {
    if (!enable) {
      this.medicalForm.get('category_field_id').setValue(0);
      this.medicalForm.get('category_field_id').clearValidators();
      this.medicalForm.get('category_field_id').disable();
    }
    else {
      this.medicalForm.get('category_field_id').setValidators([CommonFuntions.validateDropDown]);
      this.medicalForm.get('category_field_id').enable();
    }
  }

  public onNotesEdit() {
    this.editNotes = true;

    this.noteForm = new FormGroup(
      {
        case_additional_note: new FormControl(this.caseDetails.case_additional_note, Validators.maxLength(50000))
      },
      {
        updateOn: 'change'
      }
    );
  }
  
  public onCareTeamNotesUpdate() {
    this.updateCareTeamNotes = true;
    
    this.careteamNoteForm = new FormGroup(
      {
        case_careteam_note: new FormControl(this.caseDetails?.case_careteam_note, Validators.maxLength(50000))
      },
      {
        updateOn: 'change'
      }
    );
  }

  public onNoteSave(button: HTMLButtonElement) {
    if (
      CommonFuntions.guardForAction(this.loginService, this.router)
      && this.noteForm.valid
    ) {
      button.classList.add('is-loading');
      button.disabled = true;

      const dateFormat = /[\d]{4}-[\d]{2}-[\d]{2}T[\d]{2}:[\d]{2}:[\d]{2}Z/g;
      const value: any = this.noteForm.value;
      value['speciality_program'] = this.caseDetails.speciality_program? this.caseDetails.speciality_program : null;
      value['patient_diagnosis'] = this.caseDetails.patient_diagnosis;
      value['md_type_id'] = this.caseDetails.md_type_id;
      value['category_id'] = this.caseDetails.category_id;
      value['category_field_id'] = this.caseDetails.category_field_id;
      const earliestDateMoment = moment(this.caseDetails.earliest_available_date);
      if (earliestDateMoment.isValid()) {
        value['earliest_available_date'] = earliestDateMoment.format('YYYY-MM-DD');
      }
      else {
        value['earliest_available_date'] = null;
      }
      if (!dateFormat.test(this.caseDetails.date_mr_complete)) {
        const mrMoment = moment(this.caseDetails.date_mr_complete);
        value['date_mr_complete'] = mrMoment.format('YYYY-MM-DDTHH:mm:ssZ');
      }
      else {
        value['date_mr_complete'] = this.caseDetails.date_mr_complete;
      }

      this.caseService.updateMedicalData(
        value,
        this.caseId
      ).subscribe(
        result => {
          this.caseDetails.case_additional_note = result.case_additional_note;
          button.classList.remove('is-loading');
          button.disabled = false;
          this.editNotes = false;
          this.getActivities();
        },
        (_error) => {
          this.showAlert('Case Additional Note update failed.' ,function () {
            this.popUpService.hideDiv('alertDialog');
          }.bind(this));
          button.classList.remove('is-loading');
          button.disabled = false;
          this.editNotes = false;
        },
        () => {
          button.classList.remove('is-loading');
          button.disabled = false;
          this.editNotes = false;
        }
      );
    }
    else {
      CommonFuntions.validateAllFormFields(this.medicalForm);
      button.classList.remove('is-loading');
      button.disabled = false;
    }
  }

  public onCareteamNoteSave(button: HTMLButtonElement) {
    if (
      CommonFuntions.guardForAction(this.loginService, this.router)
      && this.careteamNoteForm.valid
    ) {
      button.classList.add('is-loading');
      button.disabled = true;
      
      const dateFormat = /[\d]{4}-[\d]{2}-[\d]{2}T[\d]{2}:[\d]{2}:[\d]{2}Z/g;
      const value: any = this.careteamNoteForm.value;
      value['speciality_program'] = this.caseDetails.speciality_program? this.caseDetails.speciality_program : null;
      value['patient_diagnosis'] = this.caseDetails.patient_diagnosis;
      value['md_type_id'] = this.caseDetails.md_type_id;
      value['category_id'] = this.caseDetails.category_id;
      value['category_field_id'] = this.caseDetails.category_field_id;
      const earliestDateMoment = moment(this.caseDetails.earliest_available_date);
      if (earliestDateMoment.isValid()) {
        value['earliest_available_date'] = earliestDateMoment.format('YYYY-MM-DD');
      }
      else {
        value['earliest_available_date'] = null;
      }
      if (!dateFormat.test(this.caseDetails.date_mr_complete)) {
        const mrMoment = moment(this.caseDetails.date_mr_complete);
        value['date_mr_complete'] = mrMoment.format('YYYY-MM-DDTHH:mm:ssZ');
      }
      else {
        value['date_mr_complete'] = this.caseDetails.date_mr_complete;
      }

      if(this.careteamNoteForm.get('case_careteam_note').value) {
        this.caseService.updateMedicalData(
          value,
          this.caseId
        ).subscribe(
          result => {
            this.caseDetails.case_careteam_note = result.case_careteam_note;
            button.classList.remove('is-loading');
            button.disabled = false;
            this.updateCareTeamNotes = false;
            this.getActivities();
          },
          (_error) => {
            this.showAlert('Care Team note update failed.' ,function () {
              this.popUpService.hideDiv('alertDialog');
            }.bind(this));
            button.classList.remove('is-loading');
            button.disabled = false;
            this.updateCareTeamNotes = false;
          },
          () => {
            button.classList.remove('is-loading');
            button.disabled = false;
            this.updateCareTeamNotes = false;
          }
        );
      }
    }
    else {
      CommonFuntions.validateAllFormFields(this.careteamNoteForm);
      button.classList.remove('is-loading');
      button.disabled = false;
    }
  }

  public onReopenCase(button: HTMLButtonElement) {

    const message = `Are you sure? The case will be reoponed for Follow up ${this.followUpNumber + 1}.`;

    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No'
    );

    this.popUpService.setPositiveAction(
      function () {
        this.callReopen(button);
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this)
    );

    this.popUpService.setNegativeAction(
      function () {
        this.hideDiv('confirmDialog');
      }
    );

    const dialog = this.popUpService.getPopUpView('confirmDialog');
    if (dialog != null) { this.popUpService.showDiv(dialog); }
  }

  public callConstructHisotry(_event: Event) {
    if (this.caseHisotry) { this.caseHisotry.constructCaseHistory(); }
  }

  public callGetAssignes(_event: Event) {
    if (this.caseHisotry) { this.caseHisotry.getAssigneeList(); }
  }

  public getFollowUpNumber(status: any[]) {
    if (status == null) {
      this.hasFollowUp = false;

      return;
    }

    const followUp = Math.max.apply(Math, status.map(eachStatus => eachStatus.follow_up_id));

    this.hasFollowUp = followUp > 0 ? true : false;

    if (this.hasFollowUp) this.statusWhitelist = [CASE_STAGE_ID.REQUESTED, ...this.statusWhitelist];

    this.followUpNumber = followUp;
  }

  private checkCanEditVIP(caseDetails) {
    this.editVIP = true;
    if (caseDetails.case_status === 'FINISHED' || caseDetails.case_status === 'CANCELLED') {
      this.editVIP = false;
    }
    else if (this.role === 'patient' || this.role === 'doctor' || this.role === 'mdmanager') {
      this.editVIP = false;
    }
    /** Case for the elevated partner */
    else if(this.role === 'partner' && !caseDetails?.patient?.user?.user_partners[0]?.partner.isElevatedPartner) {
      this.editVIP = false;
    }
  }

  public callReopen(button: HTMLButtonElement) {
    button.classList.add('is-loading');
    button.disabled = true;

    this.caseService.requestReopen(this.caseId).subscribe(
      result => {
        button.classList.remove('is-loading');
        button.disabled = false;

        this.caseDetails.case_status = result.case_status;
        this.caseDetails.status = result.status;

        // Enable vip edit status
        this.checkCanEditVIP(this.caseDetails);

        if (this.mdManagementComponent) { this.mdManagementComponent.setDataInit(); }

        this.setEmittedCase(this.caseDetails);
        this.setShowStatus('mdmgmt', 'finished');

        this.followUpNumber += 1;
        this.hasFollowUp = true;
        window.scroll(0, 0);
        this.showAlert('Case reopened for follow-up' ,function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
      },
      (_error) => {
        this.showAlert('Problem reopening case. Please contact your administrator.' ,function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
        button.classList.remove('is-loading');
        button.disabled = false;
      }
    );
  }

  private setMdList(case_md_management: any) {
    if (!case_md_management || !case_md_management.assigned_md_list) { return []; }

    if (case_md_management.assigned_md_list.length < 1) { return []; }

    const list: any[] = case_md_management.assigned_md_list;

    const index = list.findIndex(doctor => doctor.is_primary);

    if (index > -1) {
      const primaryDoc = list.splice(index, 1);
      if (primaryDoc.length > 0 && primaryDoc[0] != null) { list.splice(0, 0, primaryDoc[0]); }
    }

    return list;
  }

  private setIsMDT(case_type: string) {
    if (case_type.toUpperCase() === 'MDT WRITTEN'
      || case_type.toUpperCase() === 'MDT VIDEO') {
      this.isMDT = true;
    }
    else {
      this.isMDT = false;
    }
  }

  public intakePdfEmitter() {
    this.updateCaseDocuments();
  }

  getCaseTrackingLines(): void {
    this.trackingList = [];
    this.caseService.getCaseTrackingLines(this.caseId).subscribe(
      results => {
        this.trackingList = results.data || [];
      });
  }

  getUploadsFileName(upload): string {
    if(upload.document_name.toLowerCase().includes('patient intake')){
      return 'INTAKE NOTES';
    }
    return upload.document_type;
  }

  getCombinedDocuments(documents1, docuemnts2 = []){
    docuemnts2 = docuemnts2.filter(document=>document.tracking_id && document.document_type);
    return [...documents1];
  }

  getUploadsTooltipText(document){
    const date = moment(document.updated_at).format('MM/DD/YYYY hh:mm');
    const fullName = document.user? `${document.user.first_name} ${document.user.middle_name} ${document.user.last_name}`: 'NA';
    const trackingDetail = document.trackingDetail;

    if(trackingDetail){
      return `${trackingDetail.tl_number} - ${date}\n${fullName}` || 'N/A';
    }

    return `${date}\n${fullName}`;
  }

  markAsAddress(is_missing_info_addressed) {
    this.caseDetails.is_missing_info_addressed = !is_missing_info_addressed;
    this.caseService.markAsAddressOrUnaddressMissingInfo(this.caseId, !is_missing_info_addressed).subscribe(
      results => {
        console.log('results', results);
      });
  }

  /** Returns the follow up text for the Consultation Summary Document */
  getFollowUpText(caseIdentifier, documentName): string {
    let followup = '';
    const check = documentName.split('_')[1];
    let replaceString = `${caseIdentifier}_Consulatation_Summary`;
    if (check === 'Consultation') {
      replaceString = `${caseIdentifier}_Consultation_Summary`;
    }
    const document = documentName.split('.')[0].replace(replaceString, '');
    if (document) {
      followup = document.replace('_', '').split('_').join(' ');
    } 
    return followup;
  }

  isSFLogin() {
    return this.loginService.isSFLogin();
  }

  isWhitelisted(id: number) {
    return !this.isSFLogin() || this.statusWhitelist.includes(id);
  }

  sfLoginInitialRedirect() {
    if(!this.loginService.isSFLogin()) return console.info('Not an SSO Login');

    const statuses = [];

    this.caseDetails?.status.forEach(status => {
      if (this.statusWhitelist.includes(status.status_id)){
        statuses.push(status);
      }
    });

    let lastFinished = null;
    let latestState = null;

    statuses.some(status => {
      latestState = status;
      if (status.state === CASE_STATUS_TYPE.FINISHED) {
        lastFinished = status;
        return false;
      }

      return true;
    });

    const caseState = latestState.state === 'NOT STARTED' ? lastFinished : latestState;
    
    if (caseState)
      this.setShowStatus(caseState.status.status, caseState.state);
    else console.error('falsy value');
  }

  containsWhitelistStatus() {
    let hasWhitelistStatus = false;

    this.caseDetails?.status.some(status => {
      if (this.statusWhitelist.includes(status.status_id)){
        hasWhitelistStatus = true;
        return true;
      }

      return false;
    });

    return hasWhitelistStatus;
  }

  processParentAllyNumber(): void {
    const caseNumberList = (this.caseDetails?.parent_child_cases) || [];
    if (caseNumberList && caseNumberList.length > 0) {
      const parentNumberObj = caseNumberList.find((each) => each?.follow_up_id === 0);
      if (parentNumberObj && parentNumberObj?.ally_case_ref && parentNumberObj?.ally_case_ref.length > 15) this.parentAllyCaseRef = parentNumberObj?.ally_case_ref.slice(0, 15) + '...';
      if (parentNumberObj && parentNumberObj?.ally_case_ref && parentNumberObj?.ally_case_ref.length <= 15) this.parentAllyCaseRef = parentNumberObj?.ally_case_ref;
      if (parentNumberObj && parentNumberObj?.ally_case_number && parentNumberObj?.ally_case_number.length > 15) this.parentAllyCaseNumber = parentNumberObj?.ally_case_number.slice(0, 15) + '...';
      if (parentNumberObj && parentNumberObj?.ally_case_number && parentNumberObj?.ally_case_number.length <= 15) this.parentAllyCaseNumber = parentNumberObj?.ally_case_number;
    }
  }

  isReadOnly(): boolean {
    this.user = this.loginService.getCurrentUser();
    return this.user.isReadOnly;
  }

  isPeerToPeer() {
    const { reason, speciality_program} = this.caseDetails;

    if (reason && reason.reason.includes('Peer to Peer')) return true; 
    if (speciality_program && speciality_program.includes('Peer to Peer')) return true; 

    return false;
  }

}

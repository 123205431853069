import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseAssignmentsComponent } from './case-assignments.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';



@NgModule({
  declarations: [
    CaseAssignmentsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    DataTablesModule,
    ToastrModule,
    SweetAlert2Module
  ]
})
export class CaseAssignmentsModule { }

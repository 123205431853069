/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-reject-case-template',
  templateUrl: './reject-case-template.component.html',
  styleUrls: ['./reject-case-template.component.css']
})
export class RejectCaseTemplateComponent implements OnInit {
  @ViewChild('REJECTCASE', { static: true }) 
  deleteUser: ElementRef;
  
  @Input() handleValueSubmit: (args: string)=> void;
  @Input() name: string;
  @Input() hideTemplate: boolean;
  @Output() toggleTemplate = new EventEmitter<boolean>();

  rejectCaseForm: FormGroup;
  public rejectReasons = {
    a: 'Not my area of expertise.',
    b: 'No Interest.',
    c: 'No Time.',
    d: 'Not Appropriate for 2nd Opinion.',
    e: 'Other'
  }

  constructor(
    private fb: FormBuilder
  ) { }

  initializeForm(): FormGroup {
    return this.fb.group({
      reject_type: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.rejectCaseForm = this.initializeForm();
  }

  closeDeleteBox(event: Event): void {
    if (event) event.preventDefault();

    this.toggleTemplate.emit(true);
  }

  rejectCase(event: Event): void {
    if (event) event.preventDefault();

    this.rejectCaseForm.markAllAsTouched();
    if (this.rejectCaseForm.valid) {
      this.handleValueSubmit(this.rejectCaseForm.value);
    }
  }

  handleReasonChange(value: string): void {
    if (value === this.rejectReasons.e) {
      this.rejectCaseForm.addControl('reject_reason', new FormControl('',[Validators.required,Validators.maxLength(500)]));
    }
    else {
      if (this.rejectCaseForm.get('reject_reason')) this.rejectCaseForm.removeControl('reject_reason');
    }
  }

  get reject_type(): FormControl {
    return this.rejectCaseForm.get('reject_type') as FormControl;
  }

  get reject_reason(): FormControl {
    return this.rejectCaseForm.get('reject_reason') as FormControl;
  }
}

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import {Subject } from 'rxjs';

@Injectable()
export class DatashareService {

  private shareDataSource = new Subject<string>();
  shareData$ = this.shareDataSource.asObservable();

  private displayPicture = new Subject<string>()
  $displayPicture = this.displayPicture.asObservable()

  private eventArrived = new Subject<any>();
  $eventArrived = this.eventArrived.asObservable();

  public loggedInToken = null

  constructor() { }

  setData(data: string) {
    this.shareDataSource.next(data);
  }

  setDisplayPicture(data: string) {
    this.displayPicture.next(data);
  }

  sendEvent(data: any) {
    this.eventArrived.next(data);
  }
}

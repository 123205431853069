/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';

import {
  FormGroup,
  FormControl,
  FormArray
} from '@angular/forms';
import { UserService } from '../../services/userservice.service';

import { CommonFuntions } from '../../common/common.function';
// import { PopUpService } from '../../services/pop-up.service';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-report-settings',
  templateUrl: './report-settings.component.html',
  styleUrls: ['./report-settings.component.css']
})
export class ReportSettingsComponent implements OnInit {

  partnerCareteamList = [];
  MDList = [];
  rowSelectedData = [];
  reportSettingForm: FormGroup;

  constructor(
    public router: Router,
    public loginService: LoginService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.getData();

    this.reportSettingForm = this.initializeForm();
  }

  get mdControl(): FormArray {
    return this.reportSettingForm.get('mdControl') as FormArray;
  }

  get summaryCoordinatorControl(): FormArray {
    return this.reportSettingForm.get('summaryCoordinatorControl') as FormArray;
  }

  valueListFormatter(data: any): string {
    this.rowSelectedData.push(data);
    if (data.active === 1) {
      this.mdControl.push(new FormControl(data));
    }
    return '';
  }

  suggestionListFormatter(data: any): string {
    return data.doctor || data.doctor_last_name;
  }

  getData(): void {
    // this.getAllDefaultCareteamUsers();
    // this.getAllCareteamUsers();
    this.getAllMDUsers();
  }

  getAllMDUsers(): void {
    this.userService.getDoctorList().subscribe(
      (result) => {
        this.MDList =[];

        const mdUsers = result || [];
        mdUsers.forEach(element => {
          if (element.active == 1) {
            this.MDList.push(element);
          }
        }, (error) => {
          console.log('error', error);
        }
        );
      }
    );
  }

  initializeForm(): FormGroup {
    return new FormGroup(
      {
        mdControl: new FormArray([],
          [
            CommonFuntions.fomrArrayMinLength(1)
          ]
        ),
        summaryCoordinatorControl: new FormArray([],
          [
            CommonFuntions.fomrArrayMinLength(1)
          ]
        )
      }
    );
  }

  clearUser(careteam: FormControl): void {
    let index = -1;

    const rawIndex = this.rowSelectedData.indexOf(careteam.value);
    if (rawIndex !== -1) { this.rowSelectedData.splice(rawIndex, 1);}

    switch (careteam.value.user_type_id) {
    case 4: // mdControl
      index = this.mdControl.controls.indexOf(careteam);
      this.mdControl.removeAt(index);
      break;
    
    case 6: // Partner Careteam
      index = this.summaryCoordinatorControl.controls.indexOf(careteam);
      this.summaryCoordinatorControl.removeAt(index);
      break;
    default:
      break;
    }
  }

}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-generic-button',
  templateUrl: './generic-button.component.html',
  styleUrls: ['./generic-button.component.css']
})

/*
  By Default, if no isBlue and isGrey provided, color
  would depend on whether it's disabled or not.
  Blue = Not Disabled
  Grey = Disabled
*/

export class GenericButtonComponent implements OnInit {
  @Input() disabled = false;
  @Input() class = '';
  @Input() isBlue = undefined;
  @Input() isGrey = undefined;
  @Input() isPurple = undefined;
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  user;
  isDisabled = false;

  constructor(
    private loginService: LoginService,
  ) { }

  ngOnInit(): void {
    this.user = this.loginService.getCurrentUser();

    if (this.user.isReadOnly) this.isDisabled = true;
  }

  onBtnClick(): void {
    this.onClick.emit();
  }

  isBtnDisabled(): boolean {
    return this.isDisabled || this.disabled;
  }

  isBtnBlue(): boolean {
    if (this.isBlue === undefined) return !this.isBtnDisabled();

    return this.isBlue;
  }

  isBtnGrey(): boolean {
    if (this.isGrey === undefined) return this.isBtnDisabled();

    return this.isGrey;
  }

  // Blue is prioritized
  isBtnPurple(): boolean {
    if ((!this.isPurple && !this.isBlue) || this.isBlue) return false;

    return !this.isBtnDisabled();
  }

}

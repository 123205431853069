<div class="header">
    <div class="header__page-name">
      <h1 class="heading heading--main">Users - Case Coordinator</h1>
    </div>
    <div class="header__top-nav">
      <app-topmenu (searchValueEmitter)="searchString($event)" [userList]="'casecoordinator'" [searchValue]="searchValue" [activeUsers]="activeUsers" [inactiveUsers]="inactiveUsers"></app-topmenu>
    </div>
  </div>
  <div class="wrapper--dash-content dash-content">
    <div *ngIf="errorString" class="message message--is-invalid">
      {{errorString}}
    </div>
  
    <table datatable [dtOptions]="dtOptions" class="row-border hover" id="imd_careteams">
    </table>
  </div>
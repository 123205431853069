/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonFuntions } from '../../../common/common.function';
import { MailServerService } from '../../../services/mail-server.service';
import { MailServerModel } from '../../../domains/MailServerModel';
import { EMAIL_SERVICE } from '../constant';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-outgoing-mail-server',
  templateUrl: './edit-outgoing-mail-server.component.html',
  styleUrls: ['./edit-outgoing-mail-server.component.css']
})
export class EditOutgoingMailServerComponent implements OnInit {
  mailId: number;
  isInprogress = false;
  mailServerForm: FormGroup;
  emailServiceList = EMAIL_SERVICE;
  showSMTPSetting = true;
  mailObj: MailServerModel;
  password_type= 'password';
  constructor(
    private fb: FormBuilder,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private mailServerService: MailServerService,
    private toastrService:ToastrService
  ) { 
    this.activatedRoute.params.subscribe(
      params => {
        const { id } = params;
        this.mailId = id;
        this.getMailServerDetail(this.mailId);
      }
    );
  }

  syncValueWithForm(data): void {
    this.mailServerForm.setValue(data);
    if (data.email_service) {
      this.showSMTPSetting = false;
    }
    else {
      this.showSMTPSetting = true;
    }
    this.updateFormValidator(data.email_service);
  }

  /** Function to get the mail server detail. */
  getMailServerDetail(id: number) {
    this.isInprogress = true;
    this.mailServerService.getMailServerById(id).subscribe(
      result => {
        const { status, data } = result;
        if (status === 200) {
          this.isInprogress = false;
          this.mailObj = new MailServerModel(data);
          this.syncValueWithForm(data);
        }
      },
      error => {
        console.log(error);
        this.isInprogress = false;
      }
    );
  }

  private updateFormValidator(value: string): void {
    if (value) {
      this.smtp_host.clearValidators();
      this.smtp_host.updateValueAndValidity();
      this.smtp_port.clearValidators();
      this.smtp_port.updateValueAndValidity();
    }
    else {
      this.smtp_host.setValidators([Validators.required]);
      this.smtp_host.updateValueAndValidity();
      this.smtp_port.setValidators([Validators.required]);
      this.smtp_port.updateValueAndValidity();
    }
  }

  onEmailServiceChange(event): void {
    const value = event.target.value;
    if (value) {
      this.showSMTPSetting = false;
    }
    else {
      this.showSMTPSetting = true;
    }
    this.updateFormValidator(value);
  }

  getMailServerForm(): FormGroup {
    return this.fb.group({
      id: [0, Validators.required],
      name: ['', Validators.required],
      description: [''],
      sequence: [0, Validators.required],
      active: [false],
      smtp_host: ['', Validators.required],
      smtp_port: [0, Validators.required],
      smtp_encryption: ['', Validators.required],
      smtp_user: [null],
      smtp_password: [null],
      email_service: ['']
    });
  }

  onFormChange() {
    this.mailServerForm.valueChanges.subscribe((formValue) => {
      if (formValue.smtp_user?.trim() === '') {
        this.smtp_user.setValue(null);
      }
      if (formValue.smtp_password?.trim() === '') {
        this.smtp_password.setValue(null);
      }
    });
  }

  callSubmitAction(): void {

    this.router.navigate(['/settings/outgoing-mail-server']);
    this.isInprogress = true;
    if (this.mailServerForm.valid) {
      const { id, ...payload } = this.mailServerForm.value;
      this.mailServerService.editMailServer(id, payload).subscribe(
        result => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { status} = result;
          if (status === 200) {
            this.isInprogress = false;
            this.toastrService.success('Mail server updated success!');
          } 
        },
        error => {
          this.isInprogress = false;
          this.toastrService.error(error.error.message ? error.error.message : 'Failed to edit the mail server');
        }
      );
    }
    else {
      this.isInprogress = false;
      CommonFuntions.validateAllFormFields(this.mailServerForm);
    }
  }

  onSubmit(): void {
    this.callSubmitAction();
  }

  ngOnInit(): void {
    this.mailServerForm = this.getMailServerForm();
    this.onFormChange();
  }

  get name() {
    return this.mailServerForm.get('name');
  }

  get description() {
    return this.mailServerForm.get('description');
  }

  get sequence() {
    return this.mailServerForm.get('sequence');
  }

  get active() {
    return this.mailServerForm.get('active');
  }

  get smtp_host() {
    return this.mailServerForm.get('smtp_host');
  }

  get smtp_port() {
    return this.mailServerForm.get('smtp_port');
  }

  get smtp_encryption() {
    return this.mailServerForm.get('smtp_encryption');
  }

  get smtp_user() {
    return this.mailServerForm.get('smtp_user');
  }

  get smtp_password() {
    return this.mailServerForm.get('smtp_password');
  }

  get email_service() {
    return this.mailServerForm.get('email_service');
  }

}

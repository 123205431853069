/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonFuntions } from '../common/common.function';
import { PasswordService } from '../services/password.service';
import { PopUpService } from '../services/pop-up.service';
import { Router } from '@angular/router';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  requestForm: FormGroup
  isSending: boolean
  logoPath: string;

  enabledDOB: boolean;

  errorMessage: string;

  constructor(
    private passwordService: PasswordService,
    private popUpService: PopUpService,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private router: Router
  ) {
  }

  ngOnInit() {
    this.enabledDOB = false;
    this.logoPath = '/assets/images/logos/Alight_H.png';
    this.requestForm = this.getForm();
  }

  getForm(): FormGroup {
    return new FormGroup(
      {
        email: new FormControl(
          '',
          [Validators.required, Validators.email]
        ),
        dob: new FormControl('')
      }
    );
  }

  public requestPasswordReset() {

    if (this.requestForm.valid) {
      this.handleRequestForm();
    }
    else {
      CommonFuntions.validateAllFormFields(
        this.requestForm
      );
    }

  }

  public handleRequestForm() {
    const modelSubmit = { email: null };
    modelSubmit.email = this.requestForm.get('email').value;
    if (this.enabledDOB) {
      modelSubmit['dob'] = moment(this.requestForm.get('dob').value).format('YYYY-MM-DD');
    }

    this.isSending = true;
    
    this.passwordService.requestPwd(modelSubmit).subscribe(
      result => {
        this.isSending = false;

        this.showAlert(result.message);
      },
      error => {
        this.isSending = false;
        if (error.status === 405) {
          this.enabledDOB = true;
          this.errorMessage = 'Please provide birth date';
        }
        else if (error.status === 401) {
          this.errorMessage = error.error.message;
        }
        else {
          this.showAlert('Password reset request sending failed. Please try again or contact your administrator');
        } 
        
      }
    );

  }

  private showAlert(message: string) {

    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    this.popUpService.setPositiveAction(
      function () {
        this.router.navigate(['/']);
      }.bind(
        this
      )
    );

    const alertDialog = this.popUpService.getPopUpView('alertDialog');
    this.popUpService.showDiv(alertDialog);

  }

  get email() {
    return this.requestForm.get('email');
  }

  get dob() {
    return this.requestForm.get('dob');
  }

  public onDateChange(_event, calender, dateInput) {
    const calenderDate = calender.selected;
    if (calenderDate == null) {
      dateInput.value = '';
      return;
    }
    if (calenderDate.getFullYear() < 1900 || calenderDate.getFullYear() > 9999) {
      if (calenderDate.getFullYear() > 9999) {dateInput.value = '';}
      return;
    }
    const date = moment(calender.selected.toUTCString()).format('YYYY-MM-DD');

    if (moment().isBefore(date)) {
      dateInput.value = '';
      return;
    }

    dateInput.value = date;
  }

  preventDefault(event: Event) {
    const userAgent = window.navigator.userAgent;

    const browsers = { chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i };

    for (const key in browsers) {
      if (browsers[key].test(userAgent)) {
        if (key == 'firefox') {
          event.preventDefault();

        }
      }
    }
  }
}

/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-undef */
import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ReleaseSettingService } from '../../services/release-setting.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  is_visible: boolean;
  // update_at: Date;
  // created_at: Date;
  priority: string;
  subject: string;
  // is_update: boolean;
  // content: string;

  constructor(
    private releaseService: ReleaseSettingService,
  ) { }

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  ngOnInit() {
    this.getReleaseNotice();
  }

  isSalesForceLogin() {
    const token = localStorage.getItem('token');
    const jwtHelper = new JwtHelperService();

    const userInfo = jwtHelper.decodeToken(token);

    return userInfo.isSFLogin;
  }

  // Commented out just in case we need a logout on IMD Cloud app if it's embedded as an Iframe
  // logout(event: Event) {
  //   event.preventDefault();

  //   this.loginService.removeToken();
  //   this.router.navigate(['/login']);
  // }

  getReleaseNotice(){
    this.releaseService.getReleaseNotice().subscribe (response => {
      const data = response.data;
      this.subject = data?.subject;
      this.priority = data?.release_priority;
      this.is_visible = data?.is_visible;
      // this.update_at = data?.updated_at;
      // this.created_at = response.data?.created_at;
      // this.is_update = data?.is_update;
      // this.content = data?.content;
    });
  }
  
}

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-spread */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { takeWhile, debounceTime, distinctUntilChanged, switchMap, pairwise, map } from 'rxjs/operators';
import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { UserService } from '../../services/userservice.service';
import { CaseService } from '../../services/case.service';
import { CaseAssignmentService } from '../../services/case-assignment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PopUpService } from '../../services/pop-up.service';
import { CommonFuntions } from '../../common/common.function';
import { DoctorOnePagerModel } from '../../domains/case/consultation/doctorOnePagerModel';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import * as RecordRTC from 'recordrtc';

import * as moment from 'moment/moment';

import { ConfigAssetLoaderService } from '../../services/config-asset-loader.service';

import { AttachmentCaseModel } from '../../domains/attachmentCaseModel';
import { DoctorOnePagerAnswer } from '../../domains/case/consultation/doctor-one-pager-answer';
import { Observable, Subject } from 'rxjs';
import { CASE_ASSIGNMENT_STATUS, USER_ROLE_TYPE, CASE_STATUS_TYPE, CASE_STAGE_ID, USER_TYPE } from '../../constant';
import { ToastrService } from 'ngx-toastr';
import { CKEditorComponent } from 'ng2-ckeditor/ckeditor.component';

@Component({

  selector: 'app-doctor-consultation',
  templateUrl: './doctor-consultation.component.html',
  styleUrls: ['./doctor-consultation.component.css'],
})
export class DoctorConsultationComponent implements OnInit, OnDestroy {
  @ViewChild('doctorProfile', { static: true }) doctorProfile: ElementRef;
  @ViewChild('commentSummary') commentSummary: CKEditorComponent;
  @ViewChild('summary') summary: CKEditorComponent;
  @ViewChild('patientAnswer') patientAnswer: CKEditorComponent;
  @ViewChild('missingInfo') missingInfo: CKEditorComponent;
  @ViewChild('VIDEODIALOG', { static: true }) videoDialog: ElementRef;

  stream: MediaStream;
  recordRTC: any;
  ElementId: string | number;

  avatarMale: string;
  case: any;
  showSpinner = true;
  disableInputs
  sub: any;
  caseId: number;
  userId: number;
  hideDeleteUserBox = true;
  selectedRejectReason = '';
  role: string;
  public caseDetails: any;
  caseAttachmentList = new AttachmentCaseModel();
  formValid = false;
  private alive: boolean
  private isBeingAutoSave: boolean
  writtenReportList = [];
  case_documents = [];
  upload_stage = [];
  assignedMDList = [];
  attachments: any;
  written_summary: string;
  missing_information: string;
  private missingInformationSubject
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private missingInformation$: Observable<any>
  patient_diagnosis: string;
  consultation_date_time: string;
  zoom_link: string;
  doctorOnePagerModel = new DoctorOnePagerModel();
  doctorOnePagerForm: FormGroup;
  patientName: string;
  patientGender: string;
  patientAge: number;
  start_consultation: boolean;
  finished_consultation: boolean;
  show_consultation: boolean;
  state_one: boolean;
  state_two: boolean;
  state_three: boolean;
  isEditable: boolean
  previousDoctorOnePagerFormValue: any
  isMissingInfoValid: boolean
  isOtherCommentValid: boolean
  doctorAnswer: DoctorOnePagerAnswer
  isSettingFormValue: boolean;
  isSummaryEditable: boolean;
  mdtCase: boolean;
  submittedMedication: any[];
  bucketName: string;
  hideRecordBtns = false;

  selectedDoctorInfo: any
  selectedDoctorBoardCertification: any
  selectedDoctorCondition: any
  selectedDoctorAvatarImage: any
  uploading = false;
  singleCallOnly = false;
  apiCallTrigger = null;
  followUpNumber = 0;
  caseAssignmentId = 0;
  showAcceptButton = false;
  showDeclineButton = false;
  showDueDate = false;
  showPhysicianSubmitted = false;
  currentAssignmentObj;
  CaseAssignmentStatus;
  peerToPeerDetails;
  showIntakeForm = false;
  showMessage = false;
  showAcceptDeclineButton = false;
  showDeclineButtonDoctor = false;
  isCaseComplete = false;
  caseAssignments: any;

  isClinicalConsultDiagnosisValid: boolean;
  isClinicalConsultRecommendTreatmentPlanValid: boolean;
  isClinicalConsultValid:boolean;
  casePeerToPeerDetailParsed: any;
  isEdit= false;
  config = {
    readOnly: false,
    toolbar: [
      {
        name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ],
        items: [ 'Bold', 'Italic', 'Underline', 'NumberedList', 'BulletedList','Indent','Outdent']
      }
    ]
  };
  disableMedicationButton=false;

  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private caseService: CaseService,
    public caseAssignmentService: CaseAssignmentService,
    private route: ActivatedRoute,
    private popUpService: PopUpService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.missingInformationSubject = new Subject<string>();
    this.caseId = +this.activatedRoute.snapshot.paramMap.get('caseId');
    this.CaseAssignmentStatus = CASE_ASSIGNMENT_STATUS;
  }

  getCaseAssignmentId(): void {
    this.route.queryParams
      .subscribe(
        params => {
          this.caseAssignmentId = +params.assignmentId || 0;
          console.log(this.caseAssignmentId);
        }
      );
  }

  ngOnInit() {
    this.state_one = false;
    this.state_two = false;
    this.state_three = false;

    this.start_consultation = false;
    this.show_consultation = false;
    this.finished_consultation = false;
    this.written_summary = '';
    this.missing_information = '';
    this.isEditable = false;

    this.isMissingInfoValid = true;
    this.isOtherCommentValid = true;
    this.alive = true;
    this.isBeingAutoSave = true;
    this.isSettingFormValue = true;
    this.isSummaryEditable = false;
    this.bucketName = ConfigAssetLoaderService.getS3BucketName();

    this.popUpService.addPopUpView('VIDEODIALOG', this.videoDialog);
  
    this.avatarMale = 'assets/images/avatar-male.jpg';
    const currentUser = this.loginService.getCurrentUser();
    this.role = currentUser.user_type.type.toLowerCase();
    this.userId = currentUser.user_id;

    if (this.role === USER_ROLE_TYPE.DOCTOR) {
      this.getCaseAssignmentId();
    }

    this.sub = this.route.params
      .subscribe(params => {
        if (params['caseId'] && isNaN(params['caseId'])) {
          this.router.navigate(['/page-not-found']);
          return;
        }

        this.caseId = +params['caseId'];

        if (this.caseId && this.role === 'doctor') {
          this.authorizationForm();
        }
        else if (this.caseId && this.role === 'interpreter') {
          this.authorizationInterpreterForm();
        }
        else {
          this.router.navigate(['/case'], { queryParams: { list: 'current' } });
        }
       
        this.markOldComment(this.caseId, this.userId);

        this.changeCaseAssignmentStatus();

        this.doctorOnePagerModel = new DoctorOnePagerModel();
      
        this.doctorOnePagerForm = this.setupConsultationForm();
   

      });
  }

  isFormEditable(){

    if(this.isEdit == false){
      this.isEdit = true;

      this.disableClinicalConsultInputs();
      // config for ng2-ckeditor to disable the field
      this.config.readOnly = true;
      this.summary?.instance.setReadOnly(true);
      this.commentSummary?.instance.setReadOnly(true);
      this.missingInfo?.instance.setReadOnly(true);
      this.disableMedicationButton = true;
      this.disableFormArrayName(this.case_medication);

    }
    else{
      this.isEdit = false;
      // enable the clinical consult input fields
      this.clinical_consult_diagnosis.enable();
      this.clinical_consult_diagnosis_notes.enable();
      this.clinical_consult_recommend_treatment_plan.enable();
      this.clinical_consult_recommend_treatment_plan_notes.enable();
      this.disableMedicationButton = false;
      // config for ng2-ckeditor to disable the field
      this.config.readOnly = false;
      this.summary?.instance.setReadOnly(false);
      this.commentSummary?.instance.setReadOnly(false);
      this.missingInfo?.instance.setReadOnly(false);
      this.enableFormArrayName(this.case_medication);
    }


  }

  checkCaseIsPeerToPeer(peer_to_peer_details: any){

    if(this.isPeerToPeer()){
      const clinical_consult_diagnosis = this.doctorOnePagerForm.controls['clinical_consult_diagnosis'];
      const  clinical_consult_diagnosis_notes = this.doctorOnePagerForm.controls['clinical_consult_diagnosis_notes'];
      const clinical_consult_recommend_treatment_plan =  this.doctorOnePagerForm.controls['clinical_consult_recommend_treatment_plan'];
      const clinical_consult_recommend_treatment_plan_notes =  this.doctorOnePagerForm.controls['clinical_consult_recommend_treatment_plan_notes'];

      clinical_consult_diagnosis.setValidators([Validators.required]);
      clinical_consult_diagnosis.updateValueAndValidity();

      clinical_consult_recommend_treatment_plan.setValidators(Validators.required);
      clinical_consult_recommend_treatment_plan.updateValueAndValidity();

      if(peer_to_peer_details.clinical_consult_diagnosis){
        clinical_consult_diagnosis.setValue(peer_to_peer_details.clinical_consult_diagnosis);
      }

      if(peer_to_peer_details.clinical_consult_recommend_treatment_plan){
        clinical_consult_recommend_treatment_plan.setValue(peer_to_peer_details.clinical_consult_recommend_treatment_plan);
      }
  
      if(this.showPhysicianSubmitted && peer_to_peer_details || peer_to_peer_details){

        if(peer_to_peer_details.clinical_consult_diagnosis == 'Other'){

          clinical_consult_diagnosis_notes.setValidators([Validators.required,Validators.maxLength(100)]);

          if(peer_to_peer_details.clinical_consult_diagnosis_notes){
            clinical_consult_diagnosis_notes.setValue(peer_to_peer_details.clinical_consult_diagnosis_notes.trimLeft() ?? '');
          }
       
          this.isClinicalConsultDiagnosisValid = true;
        }
        else{
          clinical_consult_diagnosis_notes.clearValidators();
        }

        clinical_consult_diagnosis_notes.updateValueAndValidity();

        if(peer_to_peer_details.clinical_consult_recommend_treatment_plan  ==  'Other'){

          clinical_consult_recommend_treatment_plan_notes.setValidators([Validators.required,Validators.maxLength(100)]);
          clinical_consult_recommend_treatment_plan_notes.setValue(peer_to_peer_details.clinical_consult_recommend_treatment_plan_notes.trimLeft() ?? '');
          this.isClinicalConsultRecommendTreatmentPlanValid = true;
        }

        else{
          clinical_consult_recommend_treatment_plan_notes.clearValidators();
        }

        clinical_consult_recommend_treatment_plan_notes.updateValueAndValidity();
      }
      // conditions that trims the value when user enters whitespace at initializing t in diagnosis notes
      clinical_consult_diagnosis_notes.valueChanges.subscribe(value => {
        const trimmedData = value?.trimLeft() ?? '';
        if (trimmedData.length < 0) {
          clinical_consult_diagnosis_notes.invalid;
        }
        else{
          clinical_consult_diagnosis_notes.setValue(value?.trimLeft() ?? '', { emitEvent: false });
        }
      });
      // conditions that trims the value when user enters whitespace at beginning treatment plan
      clinical_consult_recommend_treatment_plan_notes.valueChanges.subscribe(value => {
        const trimmedData = value?.trimLeft() ?? '';
        if (trimmedData.length < 0) {
          clinical_consult_recommend_treatment_plan_notes.invalid;
        }
        else{
          clinical_consult_recommend_treatment_plan_notes.setValue(value?.trimLeft() ?? '', { emitEvent: false });
        }
     
      });
    }


  }
  ngOnDestroy(): void {
    this.alive = false;
  }

  shouldShowDeclineButton(caseStatus: string, inprogress_statusid: number, allyCaseRef: string): boolean {
    let check = true;

    if ([CASE_STATUS_TYPE.FINISHED, CASE_STATUS_TYPE.CANCELLED, CASE_STATUS_TYPE.HOLD].includes(caseStatus)
    || inprogress_statusid > CASE_STAGE_ID.SUMMARY || !allyCaseRef) {
      check = false;
    }

    return check;
  }

  private changeCaseAssignmentStatus() {
    const payload = {
      caseId: this.caseId,
      status: CASE_ASSIGNMENT_STATUS.IN_REVIEW,
      comments: ''
    };
    this.caseAssignmentService.acceptRejectCase(payload).subscribe(
      _result => {
        console.log(_result);
      },
      _error => {
        console.log(_error);
      }
    );
  }

  private setupConsultationForm(): FormGroup {
    return new FormGroup ({
      written_summary: new FormControl('', [
        Validators.maxLength(50000)
      ]),
      id: new FormControl(),
      case_questions: new FormArray([
        this.generateCaseResponse()
      ]),
      comment_summary: new FormControl('', [
        Validators.maxLength(50000)
      ]),
      case_medication: new FormArray([
        this.generateMedication()
      ]),
      is_complete: new FormControl(false),
      is_submit: new FormControl(false),
      clinical_consult_diagnosis: new FormControl(),
      clinical_consult_diagnosis_notes: new FormControl(),
      clinical_consult_recommend_treatment_plan: new FormControl(),
      clinical_consult_recommend_treatment_plan_notes: new FormControl(),

    });
  }

  pause() {
    if(this.recordRTC.state && this.recordRTC.state === 'recording'){
      this.recordRTC.pauseRecording();
      this.toggleMediaDevice(true, false, false, true, false, true, false, false);
    }
    else {
      this.showAlert('Video should be in recording mode.', function () {
        this.popUpService.hideDiv('alertDialog');
      }.bind(this));
    }
  }

  resume() {
    if(this.recordRTC.state && this.recordRTC.state === 'paused'){
      this.recordRTC.resumeRecording();
      this.toggleMediaDevice(true, false, false, true, true, false, false, false);
    }
    else {
      this.showAlert('Video should be in stopped mode to resume.', function () {
        this.popUpService.hideDiv('alertDialog');
      }.bind(this));
    }
  }

  start_over() {
    this.recordRTC.reset();
    this.recordRTC.destroy();
    if(this.ElementId === 'Summary' && this.caseDetails.video_consultation){
      this.caseDetails.editVideoConsultation = true;
    }
    if(this.ElementId!=='Summary' && this.caseDetails.case_questions[this.ElementId].video_consultation){
      this.caseDetails.case_questions[this.ElementId].editVideoConsultation = true;
    }
    this.toggleMediaDevice(true, false, true, false, false, false, false, false);
    this.hideDiv('VIDEODIALOG');
  }

  uploadToServer() {
    this.showSpinner = true;
    this.uploading = true;
    const blob = this.recordRTC.getBlob();
    let questionObj;

    let fileName = `video-consultation-${this.caseId}.webm`;
    if (this.ElementId !== 'Summary') {
      questionObj = this.case_questions.value[this.ElementId];
      fileName = `video-consultation-${this.caseId}-${questionObj.id}.webm`;
    }

    const file = new File([blob], fileName, {
      type: 'video/webm'
    });

    const formdata = new FormData();
    formdata.append('file', file);
    formdata.append('caseId', (this.caseId).toString());

    if(this.ElementId !== 'Summary'){
      formdata.append('questionId', (questionObj.id.toString()));
    }

    this.userService.uploadRecordedData(formdata).subscribe(
      result => {
        const detail: any = result;

        if(this.ElementId === 'Summary') {
          this.caseDetails.video_consultation = detail.data;
        }
        else {
          this.caseDetails.case_questions[this.ElementId].video_consultation = detail.data;
          this.caseDetails.case_questions[this.ElementId].editVideoConsultation = true;
        }
        this.showAlert('Video uploaded successfully.', function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
        this.toggleMediaDevice(true, false, true, false, false, false, false, false);
        this.showSpinner = false;
        this.uploading = false;
        this.hideDiv('VIDEODIALOG');
        this.caseDetails.editVideoConsultation = true;
      },
      (_err) => {
        this.showAlert('Failed to upload the video consultation.', function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
        this.toggleMediaDevice(true, false, true, false, false, false, false, false);
        this.showSpinner = false;
        this.uploading = false;
        if(this.ElementId==='Summary'){
          this.caseDetails.editVideoConsultation = true;
        }
        else{
          this.caseDetails.case_questions[this.ElementId].editVideoConsultation = true;
        }
        this.hideDiv('VIDEODIALOG');
      }
    );
  }

  toggleMediaDevice(liveBool, recordedBool, recordBool, stopBool, pauseBool, resumeBool, startOverBool, uploadBool): void {
    const liveVideo: any = document.querySelector('#videoLive' + this.ElementId);
    const recordedVideo: any = document.querySelector('#videoRecorded' + this.ElementId);
    const recordBtn: any = document.querySelector('#record' + this.ElementId);
    const stopBtn: any = document.querySelector('#stop' + this.ElementId);
    const pauseBtn: any = document.querySelector('#pause' + this.ElementId);
    const resumeBtn: any = document.querySelector('#resume' + this.ElementId);
    const startOverBtn: any = document.querySelector('#startOver' + this.ElementId);
    const uploadBtn: any = document.querySelector('#upload' + this.ElementId);
    const statusInfo: any = document.querySelector('#status' + this.ElementId);

    liveVideo.style.display = liveBool ? 'inline-block': 'none';
    recordedVideo.style.display = recordedBool ? 'inline-block': 'none';
    recordBtn.style.display = recordBool ? 'inline-block': 'none';
    stopBtn.style.display = stopBool ? 'inline-block': 'none';
    pauseBtn.style.display = pauseBool ? 'inline-block': 'none';
    resumeBtn.style.display = resumeBool ? 'inline-block': 'none';
    startOverBtn.style.display = startOverBool ? 'inline-block': 'none';
    uploadBtn.style.display = uploadBool ? 'inline-block': 'none';

    if(liveBool && stopBool){
      statusInfo.innerHTML = 'Recording';
    }

    if(recordedBool && startOverBool && uploadBool) {
      statusInfo.innerHTML = 'Stopped';
    }

    if(liveBool && stopBool && pauseBool) {
      statusInfo.innerHTML = 'Recording';
    }

    if(liveBool && stopBool && resumeBool) {
      statusInfo.innerHTML = 'Paused';
    }

    if(!recordedBool){
      recordedVideo.src = null;
    }
  }

  async start_recording(id: (string | number), _data: any): Promise<void> {
    this.ElementId = id;
    if(id==='Summary' && this.caseDetails.video_consultation){
      this.caseDetails.editVideoConsultation = false;
    }
    if(id!=='Summary' && this.caseDetails.case_questions[id].video_consultation){
      this.caseDetails.case_questions[id].editVideoConsultation = false;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({video: true, audio: true});
      this.stream = stream;
      this.recordRTC = RecordRTC(stream, {
        type: 'video',
        mimeType: 'video/webm',
        bitsPerSecond: 250 * 1000,
        frameRate: 30,
        bitrate: 250 * 1000,
      });

      this.recordRTC.startRecording();

      const liveVideo: any = document.querySelector('#videoLive' + this.ElementId);
      if('srcObject' in liveVideo){
        liveVideo.srcObject = stream;
      }

      liveVideo.muted = true;
      liveVideo.controls = false;
      liveVideo.autoplay = true;
    }
    catch(err) {
      console.log(err);
    }

    this.toggleMediaDevice(true, false, false, true, true, false, false, false);
  }

  openVideo(): void {
    const div = this.popUpService.getPopUpView('VIDEODIALOG');
    if(div != null) { this.popUpService.showDiv(div); }
  }

  hideDiv(divName: string): void {
    const div = this.popUpService.getPopUpView(divName);
    if (div != null) { this.popUpService.hideDiv(div); }
  }

  processVideo(blobURL): void {
    const popupVideo: any = document.querySelector('#videoPopup');
    popupVideo.src = blobURL;
    popupVideo.muted = false;
    popupVideo.controls = true;
    popupVideo.autoplay = false;
    popupVideo.currentTime = 10000000*Math.random();

    this.openVideo();
  }

  stop_recording(): void {
    const recordRTC = this.recordRTC;

    this.toggleMediaDevice(false, true, false, false, false, false, true, true);
    recordRTC.stopRecording(this.processVideo.bind(this));

    const stream = this.stream;
    stream.getAudioTracks().forEach(eachTrack => eachTrack.stop());
    stream.getVideoTracks().forEach(eachVTrack => eachVTrack.stop());
  }

  private markOldComment(id: number, user_id: number) {
    this.caseService.markOldComment(id, user_id).subscribe(
      result => {
        console.log(result);
      }
    );
  }

  keys(medicationDict: any[]): Array<string> {
    return Object.keys(medicationDict);
  }

  getUsername(id: number): string {
    let name = 'Previous Physician';
    const userId = Number(id);

    const assignedMDList = this.caseDetails.case_md_management.assigned_md_list;
    if (assignedMDList && assignedMDList.length > 0) {
      const mdObj = assignedMDList.find(mdItem => mdItem.assigned_doc_user_id === userId);
      if (mdObj) name = mdObj?.user?.full_name;
    }

    return name;
  }

  hideDocProfile(event?: Event) {
    if (event) { event.preventDefault(); }

    this.doctorProfile.nativeElement.hidden = true;
  }

  generateMedication() {
    return new FormGroup({
      case_medication_id: new FormControl(),
      medication: new FormControl('',
        [
          // Validators.required,
          Validators.maxLength(50000)
        ]),
      medication_by_user_id: new FormControl(),
      follow_up_id: new FormControl(0)
    });
  }


  get id() {
    return this.doctorOnePagerForm.get('id');
  }

  get comment_summary() {
    return this.doctorOnePagerForm.get('comment_summary');
  }

  get case_medication() {
    return this.doctorOnePagerForm.get('case_medication') as FormArray;
  }


  get case_questions() {
    return this.doctorOnePagerForm.get('case_questions') as FormArray;
  }

  get writtenSummary() {
    return this.doctorOnePagerForm.get('written_summary');
  }

  get clinical_consult_diagnosis(){
    return this.doctorOnePagerForm.get('clinical_consult_diagnosis');
  }

  get clinical_consult_diagnosis_notes(){
    return this.doctorOnePagerForm.get('clinical_consult_diagnosis_notes');
  }

  get clinical_consult_recommend_treatment_plan_notes(){
    return this.doctorOnePagerForm.get('clinical_consult_recommend_treatment_plan_notes');
  }

  get clinical_consult_recommend_treatment_plan(){
    return this.doctorOnePagerForm.get('clinical_consult_recommend_treatment_plan');
  }


  authorizationForm() {
    const user = this.loginService.getCurrentUser();
    if (this.caseId > 0 && user.user_id > 0) {
      this.getCaseConsultation(this.caseId, false);
    }
    else {
      this.router.navigate(['/case'], { queryParams: { list: 'current' } });
    }

  }

  authorizationInterpreterForm() {
    const user = this.loginService.getCurrentUser();
    if (this.caseId > 0 && user.user_id > 0) {
      this.getCaseConsultation(this.caseId, true);
    }
    else {
      this.router.navigate(['/case'], { queryParams: { list: 'current' } });
    }

  }
  acceptCase(event , action: string): void{
    event.preventDefault();
    const confirmDialog = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.destoryConfirmDialog();
    this.popUpService.setDialogParams(
      'Case Confirmation',
      'Are you sure you want to ACCEPT the case' +' '+this.caseId + '?',
      'Yes',
      'No',
      function () {
        const payload = {
          caseId: this.caseId,
          comments: this.selectedRejectReason,
          status: CASE_ASSIGNMENT_STATUS.ACCEPTED
        };
        this.caseAssignmentService.acceptRejectCase(payload).subscribe(
          function (_result) {
            this.showAlert(action+ '  successful.', () => {
              if (this.role === USER_ROLE_TYPE.DOCTOR) {
                this.showAcceptButton = false;
                this.authorizationForm();
              }
              if (this.role === USER_ROLE_TYPE.INTERPRETER) this.authorizationInterpreterForm();
              this.popUpService.hideDiv('alertDialog');
              this.router.navigate(['/case/doctor-consultation', this.caseId]);
            });
          }.bind(this),
          function (err) {
            this.showAlert(`${err.error.message}.`);
          }.bind(this)
        );

        this.popUpService.hideDiv(confirmDialog);
      }.bind(this),
      function () {
        this.hideDiv(confirmDialog);
      }
    );
    this.popUpService.showDiv(confirmDialog);

  }

  rejectCase(event): void{
    event.preventDefault();
    this.hideDeleteUserBox = false;
  }

  handleValueSubmit = (value): void =>  {
    this.selectedRejectReason=value.reject_reason? value.reject_reason: value.reject_type;
    const payload = {
      caseId: this.caseId,
      comments: this.selectedRejectReason,
      status: CASE_ASSIGNMENT_STATUS.DECLINED
    };
    this.caseAssignmentService.acceptRejectCase(payload).subscribe(
      function () {
        this.showAlert(' Case rejected successfully.', () => {
          this.popUpService.hideDiv('alertDialog');
          this.router.navigate(['/dashboard']);
        });
      }.bind(this),
      function (err) {
        this.showAlert(`${err.error.message}.`);
      }.bind(this)
    );
    this.hideDeleteUserBox = false;

  }

  toggleDeleteTemplate(value: boolean): void {
    this.hideDeleteUserBox = value;
  }

  generateCaseResponse() {
    return new FormGroup({
      id: new FormControl(),
      // answer: new FormControl('', [Validators.maxLength(50000)]),
      answer: new FormArray([this.generateAnswerControl()]),
      question_text: new FormControl(),
      video_consultation: new FormControl(),
      follow_up_id: new FormControl(),
      editable: new FormControl(),
      case_id: new FormControl()
    });
  }

  saveOnePager(event: Event, form: FormGroup) {
    event.preventDefault();

    if (form.invalid) {
      this.toastr.warning('Please fill all the mandatory fields','Form Invalid',);
      return false;
    }

    this.showSpinner = true;
    const doctorResponse: DoctorOnePagerModel = form.getRawValue() as DoctorOnePagerModel;
    this.doctorAnswer = new DoctorOnePagerAnswer(doctorResponse);
    this.doctorAnswer.setAnswer(doctorResponse.case_questions, this.userId);

    if(this.isPeerToPeer()){

      const clinical_consult_diagnosis = form.get('clinical_consult_diagnosis');
      const clinical_consult_diagnosis_notes = form.get('clinical_consult_diagnosis_notes');
      const clinical_consult_recommend_treatment_plan= form.get('clinical_consult_recommend_treatment_plan');
      const clinical_consult_recommend_treatment_plan_notes= form.get('clinical_consult_recommend_treatment_plan_notes');

      const clinicalClientData = {
        clinical_consult_diagnosis: clinical_consult_diagnosis.value,
        clinical_consult_diagnosis_notes: clinical_consult_diagnosis_notes.value,
        clinical_consult_recommend_treatment_plan: clinical_consult_recommend_treatment_plan.value,
        clinical_consult_recommend_treatment_plan_notes: clinical_consult_recommend_treatment_plan_notes.value
      };

      const newClinicalData = Object.assign(JSON.parse(this.caseDetails.peer_to_peer_details),clinicalClientData);
      this.doctorAnswer.set_peer_to_peer_details(newClinicalData);
    }
 
    // check if all responses and written summary are filled

    doctorResponse.is_complete = false;
    this.showSpinner = true;

    if (this.apiCallTrigger) {
      this.apiCallTrigger.unsubscribe();
    }
    this.apiCallTrigger = this.caseService.saveOnePager(this.doctorAnswer, false).subscribe(
      result => {
        if(result.medication.length > 0) {
          (this.case_medication as FormArray).patchValue(result.medication);
        }
        
        /** Do not detect changes after adding the medication */
        this.isSettingFormValue = true;

        this.popUpService.hideDiv('confirmDialog');
        this.popUpService.hideDiv('alertDialog');

        this.showAlert(result['msg'], function () {
          // this.router.navigate(['/']);
          this.popUpService.hideDiv('alertDialog');
          this.showSpinner = false;
          this.state_one = true;
          this.state_three = false;

          this.doctorOnePagerForm.addControl(
            'send_email', new FormControl(false)
          );
        }.bind(this));

      }, error => {
        this.popUpService.hideDiv('confirmDialog');
        let msg = null;
        if (error.error && error.error.status && error.error.status === 402) {
          if (error.error.message) { msg = error.error.message; }
          else { msg = 'Failed to save this case!!'; }

          this.showAlert(msg, function () {
            this.getCaseConsultation(this.caseId, true);
            this.popUpService.hideDiv('alertDialog');
            this.showSpinner = false;
          }.bind(this));
        }
        else {
          msg = 'Failed to save this case!!';

          this.showSpinner = false;
          this.showAlert(msg, function () {
            this.popUpService.hideDiv('alertDialog');
          }.bind(this));
        }
      }
    );
  }

  submitOnePager(event: Event, form: FormGroup) {
    event.preventDefault();
    if (!form.valid) {
      form.markAllAsTouched();
      this.toastr.warning('Please fill all the mandatory fields','Form Invalid',);
      return false;
    }

    this.showSpinner = true;
    const doctorResponse: DoctorOnePagerModel = form.getRawValue() as DoctorOnePagerModel;
    // check if all responses and written summary are filled
    doctorResponse.is_complete = true;
    doctorResponse.case_identifier = this.caseDetails.case_identifier;
    doctorResponse.case_owner = this.caseDetails.case_owner.email;

    let peer_to_peer;
    if(this.isPeerToPeer()){

      const clinical_consult_diagnosis = form.get('clinical_consult_diagnosis');
      const clinical_consult_diagnosis_notes = form.get('clinical_consult_diagnosis_notes');
      const clinical_consult_recommend_treatment_plan= form.get('clinical_consult_recommend_treatment_plan');
      const clinical_consult_recommend_treatment_plan_notes= form.get('clinical_consult_recommend_treatment_plan_notes');
    
      peer_to_peer = JSON.parse(this.caseDetails.peer_to_peer_details);

      peer_to_peer.clinical_consult_diagnosis = clinical_consult_diagnosis.value;
      peer_to_peer.clinical_consult_diagnosis_notes= clinical_consult_diagnosis_notes.value;
      peer_to_peer.clinical_consult_recommend_treatment_plan= clinical_consult_recommend_treatment_plan.value;
      peer_to_peer.clinical_consult_recommend_treatment_plan_notes = clinical_consult_recommend_treatment_plan_notes.value;
    }

    doctorResponse.peer_to_peer_details = peer_to_peer;

    const objSummary = this.caseDetails.status.find(eachStatus => eachStatus.status_id === 8);
    doctorResponse.summary_assignee = objSummary.user?.email;
    this.showSpinner = true;

    const confirmMsg = 'Do you want submit this case?';
    this.popUpService.destoryConfirmDialog();

    this.doctorAnswer = new DoctorOnePagerAnswer(doctorResponse);
    this.doctorAnswer.setAnswer(doctorResponse.case_questions, this.userId);
    this.doctorAnswer.setPatientInfo(this.caseDetails.patient.user);
    this.doctorAnswer.setPartnerCareteamInfo(this.caseDetails.user);
    this.doctorAnswer.setSendEmail(true);
    this.doctorAnswer.set_peer_to_peer_details(peer_to_peer);
    this.doctorAnswer.setLinkUrl(ConfigAssetLoaderService.getApiURL() + '/case/' + this.caseDetails.id);
    this.doctorAnswer.setStatus(this.caseDetails.status);

    this.popUpService.setDialogParams(
      'Submit Confirmation',
      confirmMsg,
      'Yes',
      'No',
      function () {
        const manualSave = true;
        if (!this.singleCallOnly) {
          // disabled multi click here
          this.singleCallOnly = true;
          this.caseService.saveOnePager(this.doctorAnswer, true, manualSave).subscribe(
            function (result) {
              this.singleCallOnly = false;
              this.popUpService.hideDiv('confirmDialog');
              if (result) {
                const message = result['msg'];
                this.showAlert(message, function () {
                  this.popUpService.hideDiv('alertDialog');
                  this.state_one = true;
                  this.state_three = true;
                  this.showSpinner = false;
                  this.alive = false;
                  this.router.navigate(['/case'], { queryParams: { list: 'current' } });
  
                }.bind(this));
  
  
              }
              else {
                const msg = 'Failed to submit this case!!';
                this.showSpinner = false;
                this.showAlert(msg, function () {
                  // this.router.navigate(['/']);
                  this.popUpService.hideDiv('alertDialog');
                }.bind(this));
              }
            }.bind(this), error => {
              this.popUpService.hideDiv('confirmDialog');
              let msg = null;
              if (error.error && error.error.status && error.error.status === 402) {
                if (error.error.message) { msg = error.error.message; }
                else { msg = 'Failed to submit this case!!'; }
  
                this.showAlert(msg, function () {
                  this.getCaseConsultation(this.caseId, true);
                  this.popUpService.hideDiv('alertDialog');
                  this.showSpinner = false;
                }.bind(this));
              }
              else {
                msg = 'Failed to submit this case!!';
                this.showAlert(msg, function () {
                  this.popUpService.hideDiv('alertDialog');
                  this.showSpinner = false;
                }.bind(this));
              }
            }
          );
        }
        else{
          console.log('already in progress');
        }
      }.bind(this),
      function () {
        this.showSpinner = false;
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this)
    );

    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);

  }

  confirmSaveMissingInformation(event: Event, missing_information: string) {
    event.preventDefault();

    if (!missing_information) this.isMissingInfoValid = false;

    if (!this.isMissingInfoValid) {
      return;
    }

    this.showSpinner = true;
    this.popUpService.destoryConfirmDialog(); // destory if any
    const thisCaseId = this.caseId;

    if (!this.singleCallOnly) {
      // disabled multiple click
      this.singleCallOnly = true;
      const manualSave = true;
      this.caseService.saveMissingInformation(thisCaseId, missing_information, true, manualSave).subscribe(
        result => {
          this.singleCallOnly = false;
          const message = result['msg'];
          // show alert
          this.popUpService.destoryConfirmDialog();
  
          this.showAlert(message, function () {
            // this.router.navigate(['/']);
            this.popUpService.hideDiv('alertDialog');
            this.state_two = missing_information ? true : false;
          }.bind(this));
  
          this.showSpinner = false;
  
        }, _error => {
          this.singleCallOnly = false;
          this.showAlert('Failed to save missing information', function () {
            this.popUpService.hideDiv('alertDialog');
          }.bind(this));
          this.showSpinner = false;
        }
      );
      this.popUpService.hideDiv('confirmDialog');
    }
    else{
      console.log('already in progress');
    }
   
  }

  openDialog(inputField: HTMLInputElement) {
    inputField.addEventListener('change', function () {
      this.uploadImage();
      inputField.value = null;
    }.bind(this));
    inputField.click();
  }

  getCaseAttachmentsByUploadStage(_id: number) {
    const currentUser = this.loginService.getCurrentUser();
    const self = this;
    this.caseService.orderCaseAttachmentsByUploadStage(this.caseId,
      currentUser.user_id).subscribe(
      case_attachments => {
        self.caseAttachmentList = case_attachments as AttachmentCaseModel;
      },
      (_error) => {
        this.showAlert('Error while getting the case attachments.', function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
        self.caseAttachmentList = null;
        this.showSpinner = false;
      }
    );
  }

  private getDoctorAvatarImage(avatarLocation: string) {
    this.userService.getUploadedFile(avatarLocation).subscribe(
      image => this.selectedDoctorAvatarImage = image
    );
  }

  public getDoctorProfile(userid: number) {
    this.userService.getDoctorProfile(userid).pipe(takeWhile(
      () => this.alive
    )).subscribe(
      doctor => {
        this.selectedDoctorInfo = doctor;
        if (doctor.avatar && doctor.avatar != null) {
          this.getDoctorAvatarImage(doctor.avatar);
        }
      }
    );
  }

  private getDoctorBoardCertification(userid: number) {
    this.userService.getDoctorCertifications(userid).subscribe(
      certification => {
        this.selectedDoctorBoardCertification = certification;
      }
    );
  }

  private getDoctorCondition(userid: number) {
    this.userService.getDoctorCondition(userid).pipe(takeWhile(
      () => this.alive
    )).subscribe(
      condition => {
        this.selectedDoctorCondition = condition;
      }
    );
  }

  viewDoctorCard(event: Event, assignedMD: any) {
    if (event) { event.preventDefault(); }
    this.selectedDoctorAvatarImage = null;
    this.selectedDoctorInfo = null;
    this.selectedDoctorBoardCertification = null;
    this.selectedDoctorCondition = null;

    this.doctorProfile.nativeElement.hidden = false;
    setTimeout(() => {
      this.doctorProfile.nativeElement.scrollTop = 0;
    }, 300);
    this.getDoctorProfile(assignedMD.assigned_doc_user_id);
    this.getDoctorBoardCertification(assignedMD.assigned_doc_user_id);
    this.getDoctorCondition(assignedMD.assigned_doc_user_id);
  }

  combineDoctorDegree(doctorDegrees) {
    let doctorDegreeString = '';

    if (doctorDegrees.length > 0) {
      const degreeObj = doctorDegrees.map(element => element.degree.degree_name);
      doctorDegreeString = degreeObj.join(', ');
    }

    return doctorDegreeString;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private sortBasedOnChampion(assignedMDs) {
    assignedMDs = assignedMDs.filter(element => element.assigned_doc_user_id !== this.userId);
    return assignedMDs.sort(function (a, b) {
      if (a.is_primary) {
        return -1;
      }
      else if (b.is_primary) {
        return 1;
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private checkMDCaseType(case_type: string): boolean {
    let check = false;

    if (case_type === 'MDT VIDEO' || case_type === 'MDT WRITTEN') {
      check = true;
    }

    return check;
  }

  getCaseConsultation(_id: number, isDisable: boolean) {
    const user = this.loginService.getCurrentUser();
    this.role = user.user_type.type.toLowerCase();

    this.caseService.getDoctorCaseById(this.caseId, user.user_id).subscribe(
      function (caseDetails) {
        caseDetails = caseDetails.body;
        this.showDeclineButton = this.shouldShowDeclineButton(caseDetails?.case_status, caseDetails?.inprogress_statusid, caseDetails?.allyCaseRef);

        if(caseDetails.video_consultation && caseDetails.video_consultation !==null){
          caseDetails.editVideoConsultation = true;
        }
        else{
          caseDetails.editVideoConsultation = false;
        }
        const updatedQuestions = caseDetails.case_questions.map(detail=>{
          if(detail.video_consultation && detail.video_consultation !== null){
            return {...detail, editVideoConsultation: true};
          }
          else{
            return {...detail, editVideoConsultation: false};
          }
        });
        this.caseDetails = {...caseDetails, case_questions: updatedQuestions};
        if(this.caseDetails?.case_status === 'FINISHED') {
          this.isCaseComplete = true;
        }

        this.peerToPeerDetails = JSON.parse(caseDetails.peer_to_peer_details);
        this.checkCaseIsPeerToPeer(this.peerToPeerDetails);
        // get folowUpNp
        if (caseDetails.status) {
          this.getFollowUpNumber(caseDetails.status);
        }

        /** Case Assignments */
        this.processCaseAssignments(caseDetails?.case_assignments);

        const caseAssign =  this.caseDetails?.case_assignments?.find(({user_id}) => user_id === this.userId);
        // eslint-disable-next-line max-len
        const caseAssignmentCheck = this.caseDetails.case_assignments?.find(value => value.user_id === this.userId && value.status !== 'ACCEPTED') ? this.caseDetails.case_assignments.find(value => value.user_id === this.userId && value.status !== 'ACCEPTED') : null;
        // eslint-disable-next-line max-len
        const isAcceptedCheck = this.caseDetails.case_assignments?.find(value => value.user_id === this.userId && value.status === 'ACCEPTED' && this.caseId == value.case_id) ? this.caseDetails.case_assignments.find(value => value.user_id === this.userId && value.status === 'ACCEPTED' && this.caseId == value.case_id) : null;
        this.caseAssignments = caseAssign? caseAssign : null ;
        if(!caseAssignmentCheck){
          this.showAcceptDeclineButton = false;
          this.showDeclineButtonDoctor = false;
          this.showIntakeForm = false;
          this.showMessage = true;
        }

        if(!caseAssignmentCheck && isAcceptedCheck?.status === 'ACCEPTED'){
          this.showDeclineButtonDoctor = true;
          this.showAcceptDeclineButton = false;
          this.showIntakeForm = true;
          this.showMessage = true;

        }
        if(caseAssignmentCheck?.status === 'DRAFT' || caseAssignmentCheck?.status === 'IN REVIEW'){
          this.showDeclineButtonDoctor = true;
          this.showAcceptDeclineButton = true;
          this.showMessage = true;

        }

        if (caseDetails.case_md_management) this.assignedMDList = this.sortBasedOnChampion(caseDetails?.case_md_management?.assigned_md_list);

        // check whether case is of type MDT or not
        this.mdtCase = this.checkMDCaseType(caseDetails.case_type);

        const selfMdMgmt = caseDetails?.case_md_management?.assigned_md_list.find(eachMdMgmt => eachMdMgmt?.assigned_doc_user_id === user?.user_id);
        if (!!selfMdMgmt && selfMdMgmt.is_submitted) {
          this.caseDetails.is_submitted = selfMdMgmt.is_submitted;
          this.state_three = true;
        }

        if (this.caseDetails.case_md_management && !this.isConsultationSumbittedByChampion(this.caseDetails.case_md_management.assigned_md_list)) {
          this.caseDetails.written_summary = '';
          caseDetails.written_summary = '';
          this.caseDetails.comment_summary = '';
          caseDetails.comment_summary = '';
        }

        this.isSummaryEditable = this.caseDetails?.case_md_management?.assigned_md_id === this.userId;

        if (this.caseDetails.case_md_management) {
          try {
            this.caseDetails.case_md_management['user'] = this.caseDetails.case_md_management.assigned_md_list
              .find(doctor => doctor.is_primary).user;
            this.caseDetails.case_md_management['user']['doctorTitle'] = this.combineDoctorDegree(
              this.caseDetails.case_md_management['user']['doctor_info']['doctor_degrees']);
          }
          catch (error) {
            this.caseDetails.case_md_management['user'] = null;
          }
        }

        this.doctorOnePagerModel = caseDetails as DoctorOnePagerModel;
        this.comment_summary.setValue(caseDetails.comment_summary == null ? '' : caseDetails.comment_summary);
        this.id.setValue(caseDetails.id);
        CommonFuntions.clearFormArray(this.case_questions);
        for (let i = 0; i < this.doctorOnePagerModel.case_questions.length; i++) {
          const caseResponse = this.generateCaseResponse();
          const answerControl = caseResponse.get('answer') as FormArray;
          const question = this.doctorOnePagerModel.case_questions[i];
          CommonFuntions.clearFormArray(answerControl);
          const userAnswerIndex = question.answer.findIndex(each => each.answer_by_user_id === this.userId);
          if (userAnswerIndex > -1) {
            const tempAnswer = question.answer.splice(userAnswerIndex, 1);
            question.answer.splice(question.answer.length, 0, tempAnswer[0]);
          }

          if (question.answer.length > 0) {
            question.answer = question.answer.filter(eachAnswer => this.isConsultationSubmited(this.caseDetails?.case_md_management?.assigned_md_list, eachAnswer?.answer_by_user_id));

            question.answer.forEach(eachAnswer => {
              eachAnswer.showAnswer = true;
              eachAnswer.user['doctorTitle'] = this.constructTitle(eachAnswer);
              delete eachAnswer.user['doctor_info'];
              const control = this.generateAnswerControl();
              control.get('case_question_id').setValue(question.id);
              answerControl.push(control);
            });
          }

          if (!this.hasAnswer(question.answer, question)) {
            const control = this.generateAnswerControl();
            control.get('case_question_id').setValue(question.id);
            control.get('answer_by_user_id').setValue(user.user_id);
            answerControl.push(control);
            question.answer.push(control.value);
          }

          this.case_questions.push(caseResponse);
        }

        if (this.doctorOnePagerModel.case_questions.length > 0) {
          this.case_questions.patchValue(this.doctorOnePagerModel.case_questions);
        }

        const caseMedicationList = this.doctorOnePagerModel.case_medications.filter(medicationItem => medicationItem.medication_by_user_id === this.userId);

        const otherMedicationList = this.doctorOnePagerModel.case_medications.filter(medicationItem => medicationItem.medication_by_user_id !== this.userId);

        this.submittedMedication = this.submittedMedicationList(this.caseDetails?.case_md_management?.assigned_md_list, otherMedicationList, this.userId);

        this.submittedMedication = this.groupMedicationByUser(this.submittedMedication, 'medication_by_user_id');

        if ((this.case_medication as FormArray).length < caseMedicationList.length) {
          for (let i = 1; i < caseMedicationList.length; i++) {
            (this.case_medication as FormArray).push(this.generateMedication());
          }
        }

        if (caseMedicationList.length > 0) {
          if(this.showPhysicianSubmitted){
            this.disableFormArrayName(<FormArray>this.case_medication);
            this.isEdit = true;
          }
          else{  
            this.enableFormArrayName(<FormArray>this.case_medication);
            this.isEdit = false;
          }
          (this.case_medication as FormArray).patchValue(caseMedicationList);
        }

        if (this.followUpNumber > 0 && caseMedicationList.length === 0) {
          const caseMedicationArr = <FormArray>this.case_medication;
          caseMedicationArr.controls = [];
        }

        this.id.setValue(caseDetails.id);
        this.writtenSummary.setValue(caseDetails.written_summary == null ? '' : caseDetails.written_summary);


        if (caseDetails.missing_information) { this.state_two = true; }

        this.missing_information = caseDetails.missing_information;
        this.patient_diagnosis = caseDetails.patient_diagnosis;


        this.patientGender = caseDetails.patient.gender === 'M' ? 'Male' : (caseDetails.patient.gender === 'F' ? 'Female' : 'Unspecified');
        this.patientAge = moment().diff(moment(caseDetails.patient.user.dob), 'years');
        this.patientName = caseDetails.patient.user.full_name;

        const maxFollowUp = Math.max.apply(Math, caseDetails.status.map(status => status.follow_up_id));
        const statusWithFW = caseDetails.status.filter(status => {
          if (status.status_id < 3) { return true; }

          if (status.follow_up_id === maxFollowUp) { return true; }

          return false;
        });

        if (statusWithFW.length > 0) {
          statusWithFW.forEach(element => {
            if (element.status_id === 4 && element.state.toLowerCase() === 'finished') {
              this.state_one = true;
            }
            if (element.status_id === 8 && element.state.toLowerCase() === 'finished') {
              this.state_three = caseDetails.is_submitted;
            }
            if (element.status_id === 6 && element.state.toLowerCase() === 'finished') {
              this.start_consultation = true;
              this.finished_consultation = false;
              this.show_consultation = true;
            }
            if (element.status_id === 7 && element.state.toLowerCase() === 'finished') {
              this.start_consultation = false;
              this.finished_consultation = true;
              this.show_consultation = true;
            }
          });
        }

        // this.state_three = caseDetails.is_submitted


        this.zoom_link = caseDetails.schedule ? caseDetails.schedule.zoom_link : '';

        if (!this.zoom_link) {
          this.start_consultation = false;
          this.finished_consultation = false;
        }

        this.consultation_date_time = caseDetails.schedule ? caseDetails.schedule.consultation_date_time ? moment(caseDetails.schedule.consultation_date_time).format('MMMM D, YYYY hh:mm A') : '' : '';
        this.disableInputs = isDisable ? isDisable : CommonFuntions.checkIfHoldOrCancelledOrFinished(caseDetails);
        this.hideRecordBtns = CommonFuntions.checkIfHoldOrCancelledOrFinished(caseDetails);

        if (!this.disableInputs) {
          this.disableInputs = !this.isConsultationEditable(statusWithFW);
        }

        if (isDisable) {
          this.start_consultation = this.start_consultation ? this.start_consultation : false;
          this.finished_consultation = this.finished_consultation ? this.finished_consultation : false;
        }
        else if (this.disableInputs) {
          this.start_consultation = false;
          this.finished_consultation = true;
        }

        this.autoSave(this.doctorOnePagerForm);

        this.missingInformation$ = this.missingInformationSubject.pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap((information: string) => this.caseService.saveMissingInformation(this.caseId, information, false))
        );

        this.missingInformation$.pipe(
          takeWhile(() => this.alive && this.isMissingInfoValid))
          .subscribe(
            (_result) => {
              // do nothing
            },
            (_error) => {
              if (this.isBeingAutoSave) {
                this.isBeingAutoSave = false;
                this.popUpService.hideDiv('confirmDialog');
                const msg = 'Auto save failed. Further changes made will not be saved automatically.';
                this.showAlert(msg, function () {
                  this.popUpService.hideDiv('alertDialog');
                }.bind(this));
              }
            }
          );

        this.previousDoctorOnePagerFormValue = this.doctorOnePagerForm.value;
        this.formValid = true;
        this.showSpinner = false;
      }.bind(this),
      error => {

        if (error && error.status) {

          let message = 'Error while getting the case';

          if (error.status === 400 && error.error.message) {
            message = error.error.message;
          }
          else if (error.status === 401 && error.error.message) {
            message = 'This case is not accessible.';
          }

          this.showAlert(
            message,
            function () {
              this.popUpService.hideDiv('alertDialog');
              this.router.navigate(['/']);
            }.bind(this)
          );
        }
        else {

          const message = 'Error while getting the case';

          this.showAlert(
            message,
            function () {
              this.popUpService.hideDiv('alertDialog');
              this.router.navigate(['/']);
            }.bind(this)
          );

        }
        this.caseDetails = null;
        this.showSpinner = false;
      }
    );

    this.getCaseDocuments();

    /** Todo: Need to delete this commented line. */
    // this.caseService.orderCaseAttachmentsByUploadStage(this.caseId, user.user_id).subscribe(
    //   caseDoc => {
    //     self.case_documents = self.sortDocument(caseDoc, self);

    //   },
    //   error => {
    //     this.showAlert('Error while getting the attachment.', function () {
    //       this.popUpService.hideDiv('alertDialog');
    //     }.bind(this));
    //     this.showSpinner = false;
    //   }
    // );


  }

  getCaseDocuments(): void {
    this.caseService.getCaseDocuments(this.caseId, this.caseAssignmentId).subscribe(
      documentList => {
        this.case_documents = this.sortDocument(documentList.translated_document, this);
      },
      (_error) => {
        this.showAlert('Error while getting the attachment.', function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
        this.showSpinner = false;
      }
    );
  }

  private getExtension(filename: string): string {
    let ext = '';

    if(filename){
      ext = filename.split('.')[1];
    }

    return ext;
  }

  private sortDocument(documentList: any, self: DoctorConsultationComponent): any {
    const docExtensionList = ['pdf', 'doc', 'docx', 'ppt', 'xls', 'xlsx'];

    if(documentList.length > 0){
      documentList.sort(function(a, b){
        let comparison = 0;

        if(a.document_type === 'MEDICAL SUMMARY' || b.document_type === 'MEDICAL SUMMARY'){
          if(a.document_type === 'MEDICAL SUMMARY'){
            comparison = -1;
          }
          else {
            comparison = 1;
          }
        }
        else {
          if(docExtensionList.includes(self.getExtension(a.document_name)) || docExtensionList.includes(self.getExtension(b.document_name))){
            if(docExtensionList.includes(self.getExtension(a.document_name))){
              comparison = -1;
            }
            else{
              comparison = 1;
            }
          }
        }

        return comparison;
      });
    }

    return documentList;
  }

  toggle(toggle_part: string) {
    $('section[id=\'' + toggle_part + '\']').toggleClass('is-expanded');

  }

  toggleThis(event: Event, toggle_part: string) {
    event.preventDefault();
    $('section[id=\'' + toggle_part + '\']').toggleClass('is-expanded');

  }

  toggleOpenClose(event: Event, toggle_part: string){
    event.preventDefault();
    $('section[id=\'' + toggle_part + '\']').toggleClass('is-open');
  }

  downloadDoc(event: Event, id: number, name: string): void {
    event.preventDefault();

    this.caseService.downloadPDF(id, name, this.caseAssignmentId).subscribe(
      result => {
        const { url } = result;
        window.open(url, '_blank');
      },
      error => {
        const errorText = error.error.message || 'Cannot open file';
        this.showAlert(errorText ,function () {
          this.popUpService.hideDiv('alertDialog');
        }.bind(this));
      }
    );
  }

  noAction(event: Event) {
    event.preventDefault();
    return false;
  }

  addAnotherMedication(event: Event) {
    event.preventDefault();
    const newMedication = this.generateMedication();
    newMedication.get('follow_up_id').setValue(this.followUpNumber);
    (this.case_medication as FormArray).push(newMedication);
  }

  removeMedicationAt(index: number) {
    const medication = (this.case_medication as FormArray).controls[index];

    const value = medication.value.medication;
    if (value === '') {
      (this.case_medication as FormArray).removeAt(index);
      return;
    }


    /* let confirmStr = 'Are you sure you want to delete ' + value + ' from medication list';
    if (confirm(confirmStr)) {
      (this.case_medication as FormArray).removeAt(index);
    } */



    this.popUpService.destoryConfirmDialog(); // destory if any
    const confirmMessage = 'Are you sure you want to delete ' + value + ' from medication list';
    this.popUpService.setDialogParams(
      'Delete Medication',
      confirmMessage,
      'Yes',
      'No',
      function () {
        (this.component.case_medication as FormArray).removeAt(index);
        this.component.popUpService.destoryConfirmDialog();
        this.component.popUpService.hideDiv('confirmDialog');
      }.bind({
        component: this
      }),
      function () {
        // do nothing
        this.hideDiv('confirmDialog');
      }
    );
    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);
  }

  openNewTab(event: Event, link: string) {
    event.preventDefault();

    this.router.navigate(['/redirect'], { queryParams: { openUrl: link } });
  }

  private showAlert(message: string, positiveCallback: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok',
    );

    this.popUpService.setPositiveAction(positiveCallback);
    const alertDialog = this.popUpService.getPopUpView('alertDialog');

    // this.popUpService.setDialogParams(
    //   'Alert',
    //   message,
    //   'Ok',
    //   undefined,
    //   () => {
    //     this.popUpService.hideDiv(alertDialog);
    //   }
    // );

    this.popUpService.showDiv(alertDialog);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private isConsultationEditable(status: any[]): boolean {
    if (!status || status == null || status.length < 1) {
      return false;
    }

    const summaryStatus = status.find(eachStatus => eachStatus.status_id === 8);

    if (summaryStatus?.state === 'NOT STARTED' || summaryStatus?.state === 'INPROGRESS') {
      return true;
    }

    return false;
  }

  public validateField(name: string, inputField: HTMLInputElement) {
    let isInValid = false;

    const value = inputField.value;

    if (value.length > 50000) {
      isInValid = true;
    }

    if (isInValid && name === 'missingInformation') {
      this.isMissingInfoValid = false;
    }
    else if (isInValid && name === 'otherComment') {
      this.isOtherCommentValid = false;
    }
    else if (!isInValid && name === 'missingInformation') {
      this.isMissingInfoValid = true;
    }
    else if (!isInValid && name === 'otherComment') {
      this.isOtherCommentValid = true;
    }

    if (name === 'missingInformation') {
      if (this.isMissingInfoValid) { this.onMissingInformationChange(value); }
    }
  }

  private saveConsultation(value: any, consultationValue: any) {
    const doctorResponse: DoctorOnePagerModel = value as DoctorOnePagerModel;

    // updates the auto save value in the text area 
    if(consultationValue.clinical_consult_diagnosis == 'Other'){
      this.clinical_consult_diagnosis_notes.setValue(consultationValue.clinical_consult_diagnosis_notes);
    }
    if(consultationValue.clinical_consult_recommend_treatment_plan == 'Other'){
      this.clinical_consult_recommend_treatment_plan_notes.setValue(consultationValue.clinical_consult_recommend_treatment_plan_notes);
    }
   
    this.doctorAnswer = new DoctorOnePagerAnswer(doctorResponse);
    this.doctorAnswer.setAnswer(doctorResponse.case_questions, this.userId);

    if(this.isPeerToPeer()){
      const clinicalConsultJsonData = Object.assign(JSON.parse(this.caseDetails.peer_to_peer_details),consultationValue);
      this.doctorAnswer.set_peer_to_peer_details(clinicalConsultJsonData);
    }

    if (this.apiCallTrigger) {
      this.apiCallTrigger.unsubscribe();
    }
    this.apiCallTrigger = this.caseService.saveOnePager(this.doctorAnswer, false).subscribe(
      (_result) => {
        /** Do not detect changes after adding the medication */
        this.isSettingFormValue = true;
      },
      (_error) => {
        if (this.isBeingAutoSave) {
          this.isBeingAutoSave = false;
          this.popUpService.hideDiv('confirmDialog');
          const msg = 'Auto save failed. Further changes made will not be saved automatically.';
          this.showAlert(msg, function () {
            this.popUpService.hideDiv('alertDialog');
          }.bind(this));
        }
      }
    );
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private autoSave(form: FormGroup) {
    form.valueChanges.
      pipe(
        map((_: any) => form.getRawValue()),
        debounceTime(2000),
        distinctUntilChanged(),
        pairwise()
      )
      .pipe(takeWhile(() => this.alive))
      .subscribe(([previousValue, currentValue]) => {
        if (!this.isSettingFormValue) {
          // Trim the previous and current values
          const trimmedPreviousValue = this.trimFormValues(previousValue);
          const trimmedCurrentValue = this.trimFormValues(currentValue);

          // Check if the values are different
          if (JSON.stringify(trimmedPreviousValue) !== JSON.stringify(trimmedCurrentValue)) {
            const clinicalResponse = {
              clinical_consult_diagnosis: currentValue.clinical_consult_diagnosis,
              clinical_consult_diagnosis_notes: currentValue.clinical_consult_diagnosis_notes,
              clinical_consult_recommend_treatment_plan: currentValue.clinical_consult_recommend_treatment_plan,
              clinical_consult_recommend_treatment_plan_notes: currentValue.clinical_consult_recommend_treatment_plan_notes
            };
            // save consultation information
            this.saveConsultation(currentValue, clinicalResponse);
          }
        }
  
        this.isSettingFormValue = false;
      });
  }
  

  public onMissingInformationChange(information: string) {
    this.missingInformationSubject.next(information);
  }

  generateAnswerControl(): FormGroup {
    const form = new FormGroup({
      id: new FormControl(),
      answer: new FormControl(null, [Validators.maxLength(50000)]),
      created_at: new FormControl(),
      updated_at: new FormControl(),
      case_question_id: new FormControl(),
      answer_by_user_id: new FormControl(),
      showAnswer: new FormControl(),
      user: new FormGroup({
        first_name: new FormControl(),
        middle_name: new FormControl(),
        last_name: new FormControl(),
        full_name: new FormControl(),
        doctorTitle: new FormControl()
      })
    });


    return form;
  }

  hasAnswer(answer: any[], question) {
    try {
      const currentUser = this.loginService.getCurrentUser();
      const answerObject = answer.find(each => each.answer_by_user_id === currentUser.user_id);

      if (!answerObject && question.follow_up_id < this.followUpNumber) {
        return true;
      }

      return !!answerObject;
    }
    catch (e) {
      return false;
    }
  }

  showHideAnswer(answer: FormControl) {
    if (!answer) { return; }
    let value = answer.get('showAnswer').value;
    value = value ? false : true;
    answer.get('showAnswer').setValue(value);
  }

  constructTitle(answer) {
    try {
      const doctor_degrees = answer.user.doctor_info.doctor_degrees.map(each => each.degree.degree_name);
      return doctor_degrees.join(', ');
    }
    catch (error) {
      return '';
    }
  }

  isConsultationSubmited(assigned_md_list: any[], user_id: number): boolean {
    if (this.userId === user_id) { return true; }

    if (!assigned_md_list || assigned_md_list.length < 1) {
      return false;
    }

    const assigned_md = assigned_md_list.find(md => md.assigned_doc_user_id === user_id);

    if (!assigned_md && this.followUpNumber > 0) { return true; }

    if (!assigned_md && this.followUpNumber === 0) { return false; }

    return assigned_md.is_submitted;
  }

  isConsultationSumbittedByChampion(assigned_md_list: any[]) {
    if (!assigned_md_list || assigned_md_list.length < 1) {
      return false;
    }

    const assigned_md = assigned_md_list.find(md => md.is_primary);

    if (assigned_md.assigned_doc_user_id === this.userId) { return true; }

    if (!assigned_md) { return false; }

    return assigned_md.is_submitted;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private submittedMedicationList(assigned_md_list: any[], medicationList: any[], _userId: number): any[] {
    let list = [];

    if (medicationList.length > 0){
      list = medicationList.filter(medicationItem => {
        const assignedMDObj = assigned_md_list.find(element => element.assigned_doc_user_id === medicationItem.medication_by_user_id);
        if (assignedMDObj && assignedMDObj.is_submitted && medicationItem.medication) {
          return medicationItem;
        }

        if (!assignedMDObj && medicationItem.medication && medicationItem.follow_up_id < this.followUpNumber) {
          return medicationItem;
        }
      });
    }

    return list;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private groupMedicationByUser(medication_list: any[], property: string) {
    let list = [];

    if (medication_list.length > 0) {
      list = medication_list.reduce((accumulator, object) => {
        const key = object[property];

        if (!accumulator[key]) {
          accumulator[key] = [];
        }

        accumulator[key].push(object);

        return accumulator;

      }, {});
    }

    return list;
  }

  public getFollowUpNumber(status: any[]) {
    if (status == null) {
      return;
    }

    const followUp = Math.max.apply(Math, status.map(eachStatus => eachStatus.follow_up_id));

    this.followUpNumber = followUp;
  }

  processCaseAssignments(caseAssignmentList): void {
    if (this.caseDetails?.allyCaseRef && caseAssignmentList && caseAssignmentList.length > 0) {
      this.currentAssignmentObj = caseAssignmentList.find((eachAssignment) => this.userId === eachAssignment?.user_id && eachAssignment?.follow_up_id === (this.followUpNumber || 0));
      
      if (this.currentAssignmentObj) {
        if ([CASE_ASSIGNMENT_STATUS.DRAFT, CASE_ASSIGNMENT_STATUS.IN_REVIEW].includes(this.currentAssignmentObj?.status)) this.showAcceptButton = true;
        if (![CASE_ASSIGNMENT_STATUS.DECLINED].includes(this.currentAssignmentObj?.status)) this.showDeclineButton = true;
        if ([CASE_ASSIGNMENT_STATUS.ACCEPTED].includes(this.currentAssignmentObj?.status) && this.caseDetails?.physician_due_date) this.showDueDate = true;
      }

      if (this.currentAssignmentObj && this.currentAssignmentObj?.status === CASE_ASSIGNMENT_STATUS.ACCEPTED
        && this.currentAssignmentObj?.user_type_id === USER_TYPE.DOCTOR) {
        if (this.caseDetails?.case_md_management && this.caseDetails?.case_md_management?.assigned_md_list
            && this.caseDetails?.case_md_management?.assigned_md_list.length > 0) {
          const assignedPhysicianObj = this.caseDetails?.case_md_management?.assigned_md_list.find((each) => each.assigned_doc_user_id === this.currentAssignmentObj.user_id);
          if (assignedPhysicianObj && assignedPhysicianObj.is_submitted) this.showPhysicianSubmitted = true;
          // disable all the input while while initializing the case in resubmit
          if(this.showPhysicianSubmitted){
            this.disableClinicalConsultInputs();
            this.config.readOnly = true;
            this.isEdit = true;
            this.disableMedicationButton = true;
          }
        }
      }
    }

    if (caseAssignmentList && caseAssignmentList.length === 0) {
      if (this.caseDetails?.case_md_management && this.caseDetails?.case_md_management?.assigned_md_list
        && this.caseDetails?.case_md_management?.assigned_md_list.length > 0) {
        const assignedMDObj = this.caseDetails?.case_md_management?.assigned_md_list[0];
        if (assignedMDObj && assignedMDObj.is_submitted) this.showPhysicianSubmitted = true;
      }
    }
  }

  isPeerToPeer() {
    const { reason, speciality_program} = this.caseDetails;

    if (reason && reason.reason.includes('Peer to Peer')) return true; 
    if (speciality_program && speciality_program.includes('Peer to Peer')) return true; 

    return false;
  }

  stylingCondition() {

    // If already accepted, do not center
    if (!this.showAcceptButton)
      return '';
    

    const isFollowUp = this.followUpNumber > 0;
    
    if (isFollowUp && this.isPeerToPeer()) return '';

    if (isFollowUp || this.isPeerToPeer()) 
      return 'center__badge';

    return '';
  }

  changeClinicalConsultDiagnosis(value:string){
    this.isClinicalConsultDiagnosisValid = false;

    //  Clinical consult treatment plan  marked as dirty and touched when select option does not have value
    if(value == '' || value != 'Other'){
      this.validateClinicalConsultDiagnosisNotes();
    }
    //sets the clinical consult diagnosis value
    if(this.caseDetails.clinical_consult_diagnosis){
      this.clinical_consult_diagnosis.setValue(this.caseDetails.clinical_consult_diagnosis);
    }
    // sets the clinical consult diagnosis form value when clinical_consult_diagnosis_notes value is selected as other 
    if(value == 'Other'){
      this.clinical_consult_diagnosis_notes.setValidators([Validators.required,Validators.maxLength(100)]);
      this.isClinicalConsultDiagnosisValid = true;
      this.validateClinicalConsultDiagnosisNotes();

    }

    else{
      this.doctorOnePagerForm.controls['clinical_consult_diagnosis_notes'].removeValidators(Validators.required);
    }
    this.clinical_consult_diagnosis_notes.updateValueAndValidity();
  
  }

  changeClinicalConsultRecommendTreatmentPlan(value:string){

    this.isClinicalConsultRecommendTreatmentPlanValid = false;

    //  Clinical consult treatment plan  marked as dirty and touched when select option does not have value
    if(value == '' || value != 'Other'){  
      this.validateClinicalConsultRecommendTreatmentPlanNotes();
    }
    //sets the clinical consult recommend treatment plan value 
    if(this.caseDetails.clinical_consult_recommend_treatment_plan){
      this.clinical_consult_recommend_treatment_plan.setValue(this.caseDetails.clinical_consult_recommend_treatment_plan);  
    }
    //sets the clinical consult recommend treatment plan notes value when clinical consult recommend treatment plan is selected as other 
    if(value == 'Other'){
      this.clinical_consult_recommend_treatment_plan_notes.setValidators([Validators.required,Validators.maxLength(100)]);
      this.isClinicalConsultRecommendTreatmentPlanValid = true;
      this.validateClinicalConsultRecommendTreatmentPlanNotes();
    }
    else{
      this.doctorOnePagerForm.controls['clinical_consult_recommend_treatment_plan_notes'].clearValidators();
    }
    this.doctorOnePagerForm.controls['clinical_consult_recommend_treatment_plan_notes'].updateValueAndValidity();

  }

  validateClinicalConsultDiagnosisNotes(): void{
    this.clinical_consult_diagnosis_notes.invalid;
    this.clinical_consult_diagnosis_notes.setValue('');
    this.clinical_consult_diagnosis_notes.markAsDirty();
    this.clinical_consult_diagnosis_notes.markAsTouched();
  }

  validateClinicalConsultRecommendTreatmentPlanNotes(): void{
    this.clinical_consult_recommend_treatment_plan_notes.invalid;
    this.clinical_consult_recommend_treatment_plan_notes.setValue('');
    this.clinical_consult_recommend_treatment_plan_notes.markAsDirty();
    this.clinical_consult_recommend_treatment_plan_notes.markAsTouched();
  }

  // method to disable the case_medication child controls while in re-submitting 
  disableFormArrayName(formArray: FormArray): void {
    formArray.controls.forEach(control => {
      const shouldDisable = this.followUpNumber > 0 && this.showPhysicianSubmitted
        && control.get('follow_up_id').value !== this.followUpNumber;
      shouldDisable ? control.disable() : control.disable();
    });
  }
  
  enableFormArrayName(formArray: FormArray): void {
    formArray.controls.forEach(control => {
      const shouldDisable = this.followUpNumber > 0 && this.showPhysicianSubmitted
        && control.get('follow_up_id').value !== this.followUpNumber;
        
      shouldDisable ? control.disable() : control.enable();
    });
  }

  disableClinicalConsultInputs(): void{
    // disable the clinical consult input fields
    this.clinical_consult_diagnosis.disable();
    this.clinical_consult_diagnosis_notes.disable();
    this.clinical_consult_recommend_treatment_plan.disable();
    this.clinical_consult_recommend_treatment_plan_notes.disable();
  }

  private trimFormValues(formValue: any): any {
    const trimmedValues = {};
  
    Object.keys(formValue).forEach(key => {
      if (typeof formValue[key] === 'string') {
        const data = formValue[key].replace(/&nbsp;/g, ' ');
        trimmedValues[key] = data.trim();
      }
      else {
        trimmedValues[key] = formValue[key];
      }
    });
  
    return trimmedValues;
  }
  
}

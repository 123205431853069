<app-dialog></app-dialog>

<div class="header">
  <div class="header__page-name">
    <h1 class="heading heading--main">Edit Case</h1>
  </div>
</div>
<div class="wrapper--dash-content dash-content overlay__wrapper" [class.overlay--visible]="isLoading">
  <div class="overlay__content">
    <div class="overlay__spinner">
      <span class="icon icon--loading"></span>
    </div>
  </div>

  <form [formGroup]="caseForm">
    <div class="form tabs__content--white-bg">
                  <div class="row">
                    <div class="col__md-6 margin--bottom-x gutter--right-3x">
                      <label for="" class="form__label">Case Id</label>
                      <div class="form__field">
                        <input type="text" formControlName="case_id" [appFormatOnly]='"trimonly"'/>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x">
                      <label for="" class="form__label">Case Type
                        <a *ngIf="case_type.value == 'VIDEO CONSULTATION'" [swal]="convertCase" class="ml-5 offset-8" >Not a Video Case ?</a>
                        </label>
                      <div class="form__field">
                        <input type="text" formControlName="case_type" [appFormatOnly]='"trimonly"'/>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x">
                        <label for="" class="form__label">Ally Case Number</label>
                        <div class="form__field">
                            <input type="text" formControlName="allyCaseNumber" [appFormatOnly]='"trimonly"' />
                            <div *ngIf="allyCaseNumber.touched && allyCaseNumber.invalid" class="message message--is-invalid">
                                <div *ngIf="allyCaseNumber.errors.required">This field is required.</div>
                                <div *ngIf="allyCaseNumber.errors.maxlength">First name must not exceed {{allyCaseNumber.errors.maxlength.requiredLength}} characters.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x">
                        <label for="" class="form__label">Ally Case Ref</label>
                        <div class="form__field">
                            <input type="text" formControlName="allyCaseRef" [appFormatOnly]='"trimonly"' />
                            <div *ngIf="allyCaseRef.touched && allyCaseRef.invalid" class="message message--is-invalid">
                                <div *ngIf="allyCaseRef.errors.required">This field is required.</div>
                                <div *ngIf="allyCaseRef.errors.maxlength">First name must not exceed {{allyCaseRef.errors.maxlength.requiredLength}} characters.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x">
                      <label for="" class="form__label">Case Status</label>
                      <div class="form__field">
                        <select formControlName="case_status" >
                          <option *ngFor="let status of caseStatusList" value={{status.value}}>
                            {{status.value}}</option>
                        </select>
                      </div>
                      <div *ngIf="case_status.touched && case_status.invalid" class="message message--is-invalid">
                        <div *ngIf="case_status.errors.required">This field is required.</div>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x">
                      <label for="" class="form__label">Patient</label>
                      <div class="form__field">
                        <!-- <input type="text" formControlName="patient" [appFormatOnly]='"trimonly"' disabled/> -->
                        <select formControlName="patient_id" >
                          <option *ngFor="let patient of patientList" value={{patient.patient_id}}>
                            {{patient.user.full_name}}</option>
                        </select>
                      </div>
                      <div *ngIf="patient_id.touched && patient_id.invalid" class="message message--is-invalid">
                        <div *ngIf="patient_id.errors.required">This field is required.</div>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x" *ngIf="case_status.value === 'CANCELLED' || case_status.value === 'FINISHED' || case_status.value === 'HOLD'">
                      <div class="form__field report-stage mt-2" >
                        <label for="" class="form__label">Reason</label>
                        <textarea formControlName="status_reason"></textarea>
                        <div *ngIf="status_reason.invalid && status_reason.touched" class="message message--is-invalid">
                          <div *ngIf="status_reason.errors.required">This field is required.</div>
                        </div>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x">
                      <label for="" class="form__label">Importance</label>
                      <div class="form__field">
                        <input type="text" formControlName="importance" [appFormatOnly]='"trimonly"'/>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x">
                      <label for="" class="form__label">Clinical Specialties Program</label>
                      <div class="form__field">
                        <input type="text" formControlName="speciality_program" [appFormatOnly]='"trimonly"'/>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x">
                      <label for="" class="form__label">Phone Number</label>
                      <div class="form__field">
                        <input type="text" formControlName="phone_number" [appFormatOnly]='"trimonly"'/>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x">
                      <label for="" class="form__label">Summary State</label>
                      <div class="form__field">
                        <input type="text" formControlName="summary_state" [appFormatOnly]='"trimonly"'/>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x form__field checkbox--in-row">
                      <div class="form__field--checkbox">
                        <input type="checkbox" formControlName="interpreter_needed"
                          id="interpreter_needed"/>
                        <label for="interpreter_needed">Interpreter Needed</label>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x form__field checkbox--in-row">
                      <div class="form__field--checkbox">
                        <input type="checkbox" formControlName="translator_needed"
                          id="translator_needed" />
                        <label for="translator_needed">Translator Needed</label>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x form__field checkbox--in-row">
                      <div class="form__field--checkbox">
                        <input type="checkbox" formControlName="is_mr_complete"
                          id="is_mr_complete" />
                        <label for="is_mr_complete">MR Complete</label>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x form__field checkbox--in-row">
                      <div class="form__field--checkbox">
                        <input type="checkbox" formControlName="is_missing_info_addressed"
                          id="is_missing_info_addressed" />
                        <label for="is_missing_info_addressed">Mission Info Addressed</label>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x form__field checkbox--in-row">
                      <div class="form__field--checkbox">
                        <input type="checkbox" formControlName="is_submitted"
                          id="is_submitted" />
                        <label for="is_submitted">Submitted</label>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x form__field checkbox--in-row">
                      <div class="form__field--checkbox">
                        <input type="checkbox" formControlName="is_vip"
                          id="is_vip" />
                        <label for="is_vip">Vip</label>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x">
                      <div class="form__field report-stage mt-2" >
                        <label for="" class="form__label">Case Additional Note</label>
                        <textarea formControlName="case_additional_note"></textarea>
                        <div *ngIf="case_additional_note.invalid && case_additional_note.touched" class="message message--is-invalid">
                          <div *ngIf="case_additional_note.errors.required">This field is required.</div>
                        </div>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x">
                      <div class="form__field report-stage mt-2" >
                        <label for="" class="form__label">Case Careteam Note</label>
                        <textarea formControlName="case_careteam_note"></textarea>
                        <div *ngIf="case_careteam_note.invalid && case_careteam_note.touched" class="message message--is-invalid">
                          <div *ngIf="case_careteam_note.errors.required">This field is required.</div>
                        </div>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x">
                      <div class="form__field report-stage mt-2" >
                        <label for="" class="form__label">Case Patient Note</label>
                        <textarea formControlName="case_patient_note"></textarea>
                        <div *ngIf="case_patient_note.invalid && case_patient_note.touched" class="message message--is-invalid">
                          <div *ngIf="case_patient_note.errors.required">This field is required.</div>
                        </div>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x">
                      <div class="form__field report-stage mt-2" >
                        <label for="" class="form__label">Report Notes</label>
                        <textarea formControlName="report_notes"></textarea>
                        <div *ngIf="report_notes.invalid && report_notes.touched" class="message message--is-invalid">
                          <div *ngIf="report_notes.errors.required">This field is required.</div>
                        </div>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x">
                      <div class="form__field report-stage mt-2" >
                        <label for="" class="form__label">Patient Diagnosis</label>
                        <textarea formControlName="patient_diagnosis"></textarea>
                        <div *ngIf="patient_diagnosis.invalid && patient_diagnosis.touched" class="message message--is-invalid">
                          <div *ngIf="patient_diagnosis.errors.required">This field is required.</div>
                        </div>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x" *ngIf="speciality_program.value === 'MSO'">
                      <div class="form__field report-stage mt-2" >
                        <label for="" class="form__label">Surgical Intervention</label>
                        <input type="text" formControlName="surgical_intervention" [appFormatOnly]='"trimonly"'/>
                        <div *ngIf="surgical_intervention.invalid && patient_diagnosis.touched" class="message message--is-invalid">
                          <div *ngIf="surgical_intervention.errors.required">This field is required.</div>
                        </div>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x" *ngIf="speciality_program.value === 'MSO'">
                      <div class="form__field report-stage mt-2" >
                        <label for="" class="form__label">Surgical Recommendation</label>
                        <input type="text" [attr.disabled]="true" auto-complete [source]="recommendationList" 
                        [list-formatter]="suggestionListFormatter"
                        [value-formatter]="valueListFormatter.bind(this)"
                        [re-focus-after-select]="false" #recommendationField/>
                      <div class="search-list auto-height" formArrayName="surgical_recommendation" *ngIf="surgical_recommendation.length !== 0">
                        <div class="search-item" *ngFor="let recommendation of surgical_recommendation?.controls">
                          {{recommendation.value.recommendation_name}}
                        </div>
                      </div>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x" *ngIf="speciality_program.value === 'MSO'">
                      <div class="form__field report-stage mt-2" >
                        <label for="" class="form__label">Surgical Intervention Note</label>
                        <textarea formControlName="surgical_intervention_note"></textarea>
                        <div *ngIf="surgical_intervention_note.invalid && surgical_intervention_note.touched" class="message message--is-invalid">
                          <div *ngIf="surgical_intervention_note.errors.required">This field is required.</div>
                        </div>
                      </div>
                    </div>
                    <div class="col__md-6 margin--bottom-x gutter--right-3x" *ngIf="speciality_program.value === 'MSO'">
                      <div class="form__field report-stage mt-2" >
                        <label for="" class="form__label">Surgical Recommendation Note</label>
                        <textarea formControlName="surgical_recommendation_note"></textarea>
                        <div *ngIf="surgical_recommendation_note.invalid && surgical_recommendation_note.touched" class="message message--is-invalid">
                          <div *ngIf="surgical_recommendation_note.errors.required">This field is required.</div>
                        </div>
                      </div>
                    </div>

            </div>
    </div>

    <div class="btn-row text-center">

      <swal #convertCase (confirm)="covertCaseToWrittenConsultation()" icon="warning" denyButtonText="Cancel" iconColor="orange" showDenyButton="true" reverseButtons="true" denyButtonColor="#DB161E" denyButtonText="Cancel" confirmButtonText="Convert" confirmButtonColor="green"  title="Alert" text="Are you sure you want to convert the Case into Written Consultation ?"  [swalOptions]="{showClass: { backdrop: '',popup: ''}}"  backdrop="#eef3f6" showCloseButton="false">
    </swal>
      <a href="#" class="btn btn--red" (click)="cancel($event)">Cancel</a>
      <a href="#" class="btn btn--green" (click)="updateCase($event)">Save</a>
    </div>
    <br><br>
</form>
</div>
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
export class DoctorCalenderModel {
    doctor_availability: any;
    doctor_availability_id: number;
    doctor_id: number;
    // available_date: string;
    // start_time: string;
    // end_time: string;
    created_at: string;
    single: string;
    multiple: string;
    is_reoccurring: number;
    timezone: string;


    constructor() {
      this.single = '';
      this.multiple = '';
      this.timezone = '';
    }
}

<div class="top-menu" >
	<div class="top-menu__user-stat">
		<span>Active Users</span>
		<a *ngIf="activeUsers > 0 && (role?.toLowerCase() =='admin' || role?.toLowerCase() =='cmmanager') " [routerLink]="['/active-users/'+userList]">{{ activeUsers }} Users</a>
		<span *ngIf="activeUsers == 0 ||  (role?.toLowerCase() !='admin' && role?.toLowerCase() !='cmmanager') ">{{ activeUsers }} Users</span>
		<span class="ml-3">Inactive Users</span>
		<a *ngIf="inactiveUsers > 0 && (role?.toLowerCase() =='admin' || role?.toLowerCase() =='cmmanager') " [routerLink]="['/inactive-users/'+userList]">{{ inactiveUsers }} Users</a>
		<span *ngIf="inactiveUsers == 0 ||  (role?.toLowerCase() !='admin' && role?.toLowerCase() !='cmmanager') ">{{ inactiveUsers }} Users</span>
	</div>
	<div class="top-menu__menu">
	</div>

	<div class="top-menu__search">
		<div class="search-wrapper">
			<div class="sarch-box">
				<input type="text" [(ngModel)]="searchValue" (keyup)="callSearch()" />
			</div>
		</div>

		<!-- Only Admin and CM Manager should be able to create an account: gitlab#265 -->
		<div class="add-user" *ngIf="['admin', 'cmmanager'].includes(role?.toLowerCase())">
			<a [routerLink]="userList == 'eligibleMember' ? '/add/eligible-member' : '/add/'+userList" class="btn btn--blue btn--mini">
				Add New User (s)
			</a>
		</div>
	</div>
</div>

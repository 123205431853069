/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { CommonFuntions } from '../../../common/common.function';
import { CommonService } from '../../../services/common.service';
import { UserService } from '../../../services/userservice.service';
import { LoginService } from '../../../services/login.service';
import { UrlTree, Router, UrlSegmentGroup, UrlSegment, PRIMARY_OUTLET, ActivatedRoute } from '@angular/router';
import { EligibleMember } from '../../../domains/eligible-member.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-eligible-member',
  templateUrl: './eligible-member.component.html',
  styleUrls: ['./eligible-member.component.css']
})
export class EligibleMemberComponent implements OnInit {

  first_name: string;
  middle_name: string;
  last_name: string;
  dob: string;
  email: string;
  company_name: string;
  phone_number: string;
  active: boolean;
  gender: string;

  genders: Object[];
  partnerList: Object[] = [];
  currentDate: Date;
  userId: any;

  eligibleMemberFormGroup: FormGroup
  eligibleMember: EligibleMember = new EligibleMember();

  operation: string
  submitButtonLabel: string

  @ViewChild('dateInput', {static: true})
  dobInput

  constructor(
    private loginService: LoginService,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {

    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g.segments;

    const user = this.loginService.getCurrentUser();
    this.operation = s[0].path;
    this.operation = this.operation.toLowerCase() === 'add' ? 'Add' : 'Edit';
    this.submitButtonLabel = this.operation.toLowerCase() === 'add'? 'Add': 'Save';

    this.currentDate = new Date();
    this.currentDate.setHours(23, 59, 59, 999);

    this.genders = [{ value: 'M', label: 'Male' }, { value: 'F', label: 'Female' }, { value: 'U', label: 'Unspecified' }];

    if(CommonFuntions.getPartnersForAuthorizedUser(user.user_type_id)) {
      this.commonService.getPartners().subscribe(
        partners => {
          if (user && user != null) {
            if (user.user_type.type.toLowerCase() === 'admin') {
              partners.map(
                partner => {
                  const partnerObj = {};
                  partnerObj['partner_id'] = partner.partner_id;
                  partnerObj['partner_name'] = partner.partner_name;
                  this.partnerList.push(partnerObj);
                }
              );
            }
          }
        }
      );
    }

    this.eligibleMemberFormGroup = new FormGroup(
      {
        first_name: new FormControl(this.eligibleMember.first_name, [
          Validators.required
        ]),
        middle_name: new FormControl(this.eligibleMember.middle_name),
        last_name: new FormControl(this.eligibleMember.last_name, [
          Validators.required
        ]),
        gender: new FormControl(this.eligibleMember.gender, [
          Validators.required
        ]),
        dob: new FormControl(this.eligibleMember.dob, [
          Validators.required
        ]),
        email: new FormControl(this.eligibleMember.email, [Validators.required, CommonFuntions.ValidateEmail]),
        phone_number: new FormControl(this.eligibleMember.phone_number, [Validators.required]),
        company_name: new FormControl(this.eligibleMember.company_name, [Validators.required]),
        active: new FormControl(this.eligibleMember.active)
      }, { updateOn: 'change' }
    );

    if(this.operation.toLowerCase() === 'edit'){
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const id = this.route.params.subscribe(
        response => {
          this.userId =  response.user_id;
          this.userService.getEligibleMember(this.userId).subscribe(
            response => {
              const dob = moment(response.dob).format('YYYY-MM-DD');
              this.eligibleMemberFormGroup.get('first_name').setValue(response.first_name);
              this.eligibleMemberFormGroup.get('middle_name').setValue(response.middle_name);
              this.eligibleMemberFormGroup.get('last_name').setValue(response.last_name);
              this.eligibleMemberFormGroup.get('email').setValue(response.email);
              this.eligibleMemberFormGroup.get('phone_number').setValue(response.phone_number);
              this.eligibleMemberFormGroup.get('company_name').setValue(response.company_name);
              this.eligibleMemberFormGroup.get('gender').setValue(response.gender);

              this.dob = dob;
              this.eligibleMemberFormGroup.get('dob').setValue(dob);
              this.dobInput.nativeElement.value = this.dob;
            }
          );
        }
      );
    }
  }



  public onDateChange(_event, calender, dateInput) {
    const calenderDate = calender.selected;
    if (calenderDate == null) {
      dateInput.value = '';
      return;
    }
    if (calenderDate.getFullYear() < 1900 || calenderDate.getFullYear() > 9999) {
      if (calenderDate.getFullYear() > 9999) {dateInput.value = '';}
      return;
    }
    const date = moment(calender.selected.toUTCString()).format('YYYY-MM-DD');
    if (moment().isBefore(date)) {
      dateInput.value = '';
      return;
    }

    this.eligibleMemberFormGroup.get('dob').setValue(date);
    dateInput.value = date;
  }



  preventDefault(event: Event) {
    const userAgent = window.navigator.userAgent;

    const browsers = { chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i };

    for (const key in browsers) {
      if (browsers[key].test(userAgent)) {
        if (key == 'firefox') {
          event.preventDefault();

        }
      }
    }

  }

  onCancel(event: Event){
    event.preventDefault();
    this.router.navigate(['/eligible-member-list']);
  }

  onSubmit(form: FormGroup){

    if(CommonFuntions.guardForAction(this.loginService, this.router)){
      if(form.valid){
        if(this.operation.toLowerCase() === 'add'){
          Swal.fire({
            text: 'Are you sure you want to add eligible member?',
            title: 'Confirmation',
            backdrop: '#eef3f6',
            cancelButtonColor: '#db161e',
            reverseButtons: true,
            showCancelButton: true,
            showDenyButton: false,
            confirmButtonColor: 'green',
            confirmButtonText: 'Continue'
          }).then((result)=>{
            //fires the service when user clicks the button
            if(result.isConfirmed){
              const eligibleMember = form.getRawValue() as EligibleMember;
              this.userService.addEligibleMember(eligibleMember)
                .subscribe((_response) =>{
                  this.router.navigate(['/eligible-member-list']);
                  this.toastrService.success('User Added successfully.');
                },
                error => {
                  this.toastrService.error(error['error'].message);

                });
            }
          });
        }
        else if(this.operation.toLowerCase() === 'edit'){

          Swal.fire({
            text: 'Are you sure you want to save ?',
            title: 'Confirmation',
            backdrop: '#eef3f6',
            cancelButtonColor: '#db161e',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Continue',
          }).then((result)=>{
            //fires the service when user clicks the button
            if(result.isConfirmed){
              const eligibleMember = form.getRawValue() as EligibleMember;
              this.userService.updateEligibleMember(this.userId,eligibleMember)
                .subscribe((_response) => {
                  this.router.navigate(['/eligible-member-list']);
                  this.toastrService.success('User Updated Successfully.');
                },(error) => {
                  this.toastrService.error(error['error'].message);
                },
                );
            }
          
          });
        }
      }
      else{
        this.toastrService.warning('Please fill all mandatory input fields','Invalid Form');
        CommonFuntions.validateAllFormFields(form);
      }
    }
  }

}
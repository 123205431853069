/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-empty-function */
import {Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { CaseService } from '../../../../services/case.service';
import { PopUpService } from '../../../../services/pop-up.service';

@Component({
  selector: 'app-mail-log-details',
  templateUrl: './mail-log-details.component.html',
  styleUrls: ['./mail-log-details.component.css']
})
export class MailLogDetailsComponent implements OnInit {
  @Input() showDetails: boolean;
  @Input() logData: any;
  @Output() toggleTemplate = new EventEmitter<boolean>();
  @Output() reloadData = new EventEmitter<boolean>();
  loading = false;
  isChanged = false;
  isValidJSON = true;
  data: any = {};
  ckeditorContent = '<p>Dear Dr. Rabin,<br />';
  constructor(
    private changeRef: ChangeDetectorRef,
    private popUpService: PopUpService,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private caseService: CaseService) { 
  }

  ngOnInit(): void {
    this.data = {};
    this.isChanged = false;
    console.log('this.logData', this.logData);
    if (this.logData && this.logData.body_html) {
      this.ckeditorContent = this.logData.body_html;
    }
    else {
      this.ckeditorContent = '';
    }
  }

  ngAfterViewChecked(): void { this.changeRef.detectChanges(); }

  /**
 * This function emit the value to its parent component. ie; Tracking line component
 * @memberof TrackingLineDetailTemplateComponent
 */
  toggleThisTemplate(): void {
    this.data = {};
    this.code = JSON.stringify({});
    this.toggleTemplate.emit(false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.logData && changes.logData.currentValue && changes.logData.currentValue.body_html) {
      this.ckeditorContent = changes.logData.currentValue.body_html;
    }
  }

  trigger() {
    try{
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const validJSON = JSON.parse(JSON.stringify(this.data));
      this.toggleTemplate.emit(false);
      this.showConfirmation('Are you sure you want to re-trigger webhook?',function(){
        const data = { logId: this.logData.logId, payload: null };
        if (this.isChanged) {
          data.payload = this.data;
        }
        this.caseService.reTriggerWebhookFor(data).subscribe(
          function(result) {
            if (result['msg']) {
              this.showAlert(`${result['msg']}.`);
              this.reloadData.emit();
            }
            this.popUpService.hideDiv('confirmDialog');								
          }.bind(this), 
          function(_error) {
            const message = 'Failed to re-trigger webhook.';
            this.showAlert(message);
            this.popUpService.hideDiv('confirmDialog');
            this.reloadData.emit();
          }.bind(this),
        );
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this));
    }
    catch(e) {
      console.log('error occored while you were typing the JSON');
      const message = 'Failed to re-trigger webhook.';
      this.showAlert(message);
      this.popUpService.hideDiv('confirmDialog');
    }
  }

  get code () {
    return JSON.stringify(this.logData.payload, null, 2);
  }

  set code (v) {
    try{
      this.data = JSON.parse(v);
      this.isValidJSON = true;
    }
    catch(e) {
      console.log('error occored while you were typing the JSON');
      this.isValidJSON = false;
    }
    if (JSON.stringify(this.data) !== '{}' && JSON.stringify(this.logData.payload) !== JSON.stringify(this.data)) {
      this.isChanged = true;
      console.log('data change', this.isChanged);
    }
    else {
      this.isChanged = false;
    }
  }

  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function) {

    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }

    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }

    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);

  }

  public showAlert(message: string, positiveAction?: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(positiveAction);
    }
    else {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) {this.popUpService.showDiv(dialog);}
  }
}

<div class="base" >
	<div class="space">
		<div class="content_wrapper">
			<h1 class="not_found" >{{ this.title() }}</h1>
	
			<p class="message">
				{{ this.message() }}
			</p> 
		</div>
	</div>
</div>
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MailServerService } from '../../services/mail-server.service';
import { PopUpService } from '../../services/pop-up.service';
import { CommonFuntions } from '../../common/common.function';

@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.css']
})
export class EmailSettingsComponent implements OnInit {

  isEdit: boolean;
  isRecipientEdit: boolean;
  mailServers= [];
  mailSettings: any;
  mailServerForm: FormGroup;
  recipientForm: FormGroup;
  errorMailServer: '';
  businessMailServer: '';
  emailRecipient: '';
  replyToEmail: '';

  isMailServerLoading: boolean;
  isMailSettingLoading: boolean;

  constructor(
    private mailServerService: MailServerService,
    private popUpService: PopUpService,
  ) { 

  }

  getMailServerForm(): FormGroup {
    return new FormGroup({
      business_mail_server_id: new FormControl(0,
        [ CommonFuntions.validateDropDown,
          Validators.required]
      ),
      error_mail_server_id: new FormControl(0,
        [ CommonFuntions.validateDropDown,
          Validators.required])
    });
  }

  getRecipientForm(): FormGroup {
    return new FormGroup({
      email_recipient: new FormControl(null,
        [ CommonFuntions.ValidateEmail,
          Validators.required]
      ),
      reply_email: new FormControl('', [Validators.required, CommonFuntions.ValidateEmail])
    });
  }

  ngOnInit(): void {
    this.mailServerForm = this.getMailServerForm();
    this.recipientForm = this.getRecipientForm();
    this.getMailServers();
  }

  get businessServerId() {
    return this.mailServerForm.get('business_mail_server_id');
  }

  get errorServerId() {
    return this.mailServerForm.get('error_mail_server_id');
  }

  get recipientEmailAddress() {
    return this.recipientForm.get('email_recipient');
  }
  
  get replyEmail() {
    return this.recipientForm.get('reply_email');
  }

  getMailServers() {
    this.isMailServerLoading = true;
    this.mailServerService.getAllMailServers().subscribe(
      result => {
        this.mailServers = result;
        this.mailServers.splice(0, 0, {
          id: 0,
          name: 'Select'
        });
        this.isMailServerLoading = false;
        this.getMailSettings();
      }, 
      (_error) => {
        this.mailServers.splice(0, 0, {
          id: 0,
          name: 'Select'
        });
        this.isMailServerLoading = false;
      }
    );
  }

  getMailSettings() {
    this.isMailSettingLoading = true;
    this.mailServerService.getMailSettings().subscribe(
      result => {
        this.mailSettings = result;
        if(result !== null){
          this.businessServerId.setValue(result.business_mail_server_id);
          this.errorServerId.setValue(result.error_mail_server_id);
          this.businessMailServer = this.getServerName(result.business_mail_server_id);
          this.errorMailServer = this.getServerName(result.error_mail_server_id);

          this.recipientEmailAddress.setValue(result.email_recipient);
          this.replyEmail.setValue(result.reply_email);
          this.emailRecipient = result.email_recipient;
          this.replyToEmail = result.reply_email || 'N/A';
        }
        this.isMailSettingLoading = false;
      }, 
      (_error) => {
        this.mailSettings = [];
        this.isMailSettingLoading = false;
      }
    );
  }

  getServerName(id){
    const result = this.mailServers.find(server => server.id === id);
    return result?.name;
  }

  toggleEdit(){
    this.isEdit =!this.isEdit;
  }

  toggleRecipientEdit(){
    this.isRecipientEdit =!this.isRecipientEdit;
  }

  /** Function to execute when the form is submitted. */
  onSubmit() {
    if(this.mailServerForm.valid){
      const message = 'Are you sure? Email setting will be updated.';
      const postiveAction = function(): void {
        this.callSubmitAction();
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this);
      const negativeAction = function(): void {
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this);
  
      this.showConfirmation(message, postiveAction, negativeAction);
    }
    else{
      CommonFuntions.validateAllFormFields(this.mailServerForm);
    }
  }
  /** Function to execute when the form is submitted. */
  onRecipientSubmit() {
    if(this.recipientForm.valid){
      const message = 'Are you sure? Email details will be updated.';
      const postiveAction = function(): void {
        this.callSubmitRecipientAction();
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this);
      const negativeAction = function(): void {
        this.popUpService.hideDiv('confirmDialog');
      }.bind(this);
  
      this.showConfirmation(message, postiveAction, negativeAction);
    }
    else{
      // const message = 'Please select Email Servers first.';
      // const postiveAction = function(): void {
      //   this.callSubmitAction();
      //   this.popUpService.hideDiv('confirmDialog');
      // }.bind(this);
      // const negativeAction = function(): void {
      //   this.popUpService.hideDiv('confirmDialog');
      // }.bind(this);
  
      // this.showConfirmation(message, postiveAction, negativeAction);
      CommonFuntions.validateAllFormFields(this.recipientForm);
    }
  }

  callSubmitAction(){
    if(this.mailSettings === null){
      this.mailServerService.createMailSetting(this.mailServerForm.value).subscribe(
        result => {
          this.mailSettings = result.data; 
          this.businessServerId.setValue(result.data.business_mail_server_id);
          this.errorServerId.setValue(result.data.error_mail_server_id);
          this.businessMailServer = this.getServerName(result.data.business_mail_server_id);
          this.errorMailServer = this.getServerName(result.data.error_mail_server_id);
          this.showAlert('Mail Setting created sucessfully.');
        }, (_error) => {
          this.showAlert('Cannot create new mail setting. Please try again.');
        }
      );
    }
    else{
      this.mailServerService.editMailSetting(this.mailSettings.id,this.mailServerForm.value).subscribe(
        result => {
          this.mailSettings = result.data; 
          this.businessServerId.setValue(result.data.business_mail_server_id);
          this.errorServerId.setValue(result.data.error_mail_server_id);
          this.businessMailServer = this.getServerName(result.data.business_mail_server_id);
          this.errorMailServer = this.getServerName(result.data.error_mail_server_id);
          this.showAlert('Mail Setting updated sucessfully.');
        }, (_error) => {
          this.showAlert('Cannot update mail setting. Please try again.');
        }
      );
    }
    
    this.isEdit = !this.isEdit;
  }


  callSubmitRecipientAction(){
    const data = {
      ...this.mailServerForm.value,
      ...this.recipientForm.value
    };

    this.mailServerService.editMailSetting(this.mailSettings.id,data).subscribe(
      result => {
        this.mailSettings = result.data; 
        this.recipientEmailAddress.setValue(result.data.email_recipient);
        this.emailRecipient = result.data.email_recipient;
        this.replyToEmail = result.data.reply_email;
        this.showAlert('Email details updated sucessfully.');
      }, (_error) => {
        this.showAlert('Cannot update email details. Please try again.');
      }
    );
    
    this.isRecipientEdit = !this.isRecipientEdit;
  }

  /** Function to show the confirmation dialog
   * It needs the <app-dialog> component to be loaded 
   */
  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function): void {
    this.popUpService.destoryConfirmDialog();
  
    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );
  
    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }
    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }
  
    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);
  }


  /** Function to show the confirmation dialog
   * It needs the <app-dialog> component to be loaded 
   */
  private showAlert(message: string, action?: Function): void {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (!action) {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }
    else {
      this.popUpService.setPositiveAction(
        action
      );
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) { this.popUpService.showDiv(dialog); }
  }

}

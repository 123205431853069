<app-dialog></app-dialog>

<div class="popup" hidden="" #doctorProfile>
	<div class="popup__overlay"></div>
	<div class="popup__container popup__container--doc-profile overlay--visible">
		<a class="popup__close popup__close--doc-profile" href="#" (click)="hideDocProfile($event)">
			<span class="icon icon--cross"></span>
		</a>
		<div class="overlay__spinner"
			*ngIf="!(selectedDoctorInfo && selectedDoctorBoardCertification != null && selectedDoctorCondition != null)">
			<span class="icon icon--loading"></span>
		</div>
		<div class="popup__content popup__content--doc-profile"
			*ngIf="selectedDoctorInfo && selectedDoctorBoardCertification != null && selectedDoctorCondition != null">
			<div class="row">
				<div class="col col__md-12">
					<div class="box box--white box--radius">
						<div class="profiles profiles--intro">
							<div class="profiles__image">
								<div class="profile-card__image">
									<div class="image-round">
										<img [src]="avatarMale" *ngIf="selectedDoctorAvatarImage == null" />
										<img [src]="'data:image/jpg;base64,'+selectedDoctorAvatarImage.image"
											*ngIf="selectedDoctorAvatarImage != null" />
									</div>
								</div>
								<div class="profiles__name">{{selectedDoctorInfo?.full_name}}</div>
							</div>
							<div class="profiles__details">
								<h1>Doctor - {{ selectedDoctorInfo?.field_of_medicine }}</h1>
								<div class="profiles__rating rating-row">
									<div class="position">{{selectedDoctorInfo?.title?.title}}</div>
									<div class="location">{{selectedDoctorInfo?.affiliated_hospital}}</div>
								</div>
								<div class="profiles__url doctor__profile">
									<a [title]="selectedDoctorInfo?.website"
										[href]="selectedDoctorInfo?.website" [target]="'_blank'"
										*ngIf="selectedDoctorInfo?.website">
										External Profile Link</a>
								</div>
							</div>
						</div>
					</div>
					<div class="box box--grey box--radius">
						<div class="resume resume--centered">
							<h2 class="resume__title">Qualifications and Experience</h2>
							<div class="resume__row">
								<div class="resume-field">Education</div>
								<div class="resume-details">
									<ul class="resume__list">
										<li *ngFor="let education of selectedDoctorInfo?.education_list">{{education}}
										</li>
									</ul>
								</div>
							</div>
							<div class="resume__row">
								<div class="resume-field">Institution</div>
								<div class="resume-details">
									<ul class="resume__list">
										<li *ngFor="let institute of selectedDoctorInfo.institution_list">{{institute}}
										</li>
									</ul>
								</div>
							</div>

							<div class="resume__row">
								<div class="resume-field">Languages Spoken</div>
								<div class="resume-details">
									<ul class="resume__list">
										<li *ngFor="let user_language of selectedDoctorInfo?.user_languages">
											{{user_language}}</li>
									</ul>
								</div>
							</div>
							<div class="resume__row">
								<div class="resume-field">Board Certifications</div>
								<div class="resume-details">
									<ul class="resume__list">
										<li
											*ngFor="let cert of selectedDoctorBoardCertification?.doctor_certifications">
											{{cert}}</li>
									</ul>
								</div>
							</div>

							<div class="resume__row">
								<div class="resume-field">Conditions</div>
								<div class="resume-details">
									<ul class="resume__list">
										<li *ngFor="let doctor_md_type of selectedDoctorCondition?.doctor_md_types">
											{{doctor_md_type}}</li>
									</ul>
								</div>
							</div>

							<div class="resume__row" *ngIf="selectedDoctorInfo?.procedures">
								<div class="resume-field">Procedural Expertise</div>
								<div class="resume-details">
									<ul class="resume__list">
										<li>{{selectedDoctorInfo?.procedures}} </li>
									</ul>
								</div>
							</div>

							<div class="resume__row" *ngIf="selectedDoctorInfo?.bio">
								<div class="resume-field">Bio</div>
								<div class="resume-details">
									<ul class="resume__list">
										<li>{{selectedDoctorInfo?.bio}}</li>
									</ul>
								</div>
							</div>

						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>

<div class="wrapper--dash-content dash-content">
	<div>
		<div *ngIf="role?.toLowerCase()==='careteam' || role?.toLowerCase()==='cmmanager' || 
		role?.toLowerCase()==='partner'">
			<form [formGroup]="caseForm">

				<div class="col col__md-8 gutter--right-3x" *ngIf="step == 1">
					<div class="box box--white box--radius box--margin-bottom-x box--pad-2x overlay__wrapper"
						[class.overlay--visible]="isProgress || categories?.length < 2 || md_types?.length < 2">
						<div class="overlay__content">
							<div class="overlay__spinner">
								<span class="icon icon--loading"></span>
							</div>
						</div>
						<div class="form">
							<div class="form__label text--uppercase">Step 1</div>
							<h3 class="form__title">Enter Case Information</h3>

							<div class="row">
								<div class="col col__md-4 gutter--right-2x">
									<div class="form__row">
										<div class="form__label">Case Type</div>
										<div class="form__field">
											<select (change)="onChange()" formControlName="case_type">
												<option *ngFor="let type of caseType" [value]="type.value">{{type.key}}
												</option>
											</select>
											<div *ngIf="caseForm.get('case_type').touched 
											&& caseForm.get('case_type').invalid" class="message message--is-invalid">
												<div>This field is required.</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col col__md-4 gutter--right-2x">
									<div class="form__row">
										<div class="form__label">Importance</div>
										<div class="form__field">
											<select formControlName="importance">
												<option *ngFor="let important of importance" [value]="important.value">
													{{important.key}}</option>
											</select>
											<div *ngIf="caseForm.get('importance').touched 
										&& caseForm.get('importance').invalid" class="message message--is-invalid">
												<div>This field is required.</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col col__md-4 gutter--right-2x">
									<div class="form__row">
										<div class="form__label">Earliest Available Date</div>
										<div class="form__field">
											<div class="form__field--date"
												*ngIf="!(caseTypeField.value==='VIDEO CONSULTATION' || caseTypeField.value==='MDT VIDEO' || caseTypeField.value==='PATHOLOGY REVIEW' || caseTypeField.value==='ELSE')">
												<input [attr.disabled]="''" type="date" placeholder="Enter Date"
													formControlName="earliest_available_date" />
												<span class="trigger  icon icon--calendar"></span>
											</div>
											<div class="form__field--date"
												*ngIf="caseTypeField.value==='VIDEO CONSULTATION' || caseTypeField.value==='MDT VIDEO' || caseTypeField.value==='PATHOLOGY REVIEW' || caseTypeField.value==='ELSE'">
												<input type="date" placeholder="Enter Date" [owlDateTime]="dt2"
													(dateTimeChange)="onDateChange($event, dt2, dateInput)"
													formControlName="earliest_available_date" [min]="currentDate"
													(click)="preventDefault($event)" #dateInput />
												<span class="trigger  icon icon--calendar"
													[owlDateTimeTrigger]="dt2"></span>
												<owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
											</div>
										</div>
										<div *ngIf="caseForm.get('earliest_available_date').touched 
									&& caseForm.get('earliest_available_date').invalid" class="message message--is-invalid">
											<div *ngIf="caseForm.get('earliest_available_date').errors?.required">This
												field is required.</div>
											<div
												*ngIf="caseForm.get('earliest_available_date').errors?.owlDateTimeMin?.min">
												Date must be later than
												{{caseForm.get('earliest_available_date').errors.owlDateTimeMin.actual}}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col col__md-4 gutter--right-2x">
									<div class="form__row">
										<div class="form__label">Category</div>
										<div class="form__field">
											<select formControlName="category_id"
												(change)="onCategorySelect(category.selectedIndex)" #category>
												<option *ngFor="let category of categories; let i = index"
													[ngValue]="category.category_id">{{category.category}}</option>
											</select>
										</div>
										<div *ngIf="caseForm.get('category_id').touched 
											&& caseForm.get('category_id').invalid" class="message message--is-invalid">
											<div>This field is required.</div>
										</div>
									</div>
								</div>
								<div class="col col__md-4 gutter--right-2x">
									<div class="form__row">
										<div class="form__label">Sub Specialty</div>
										<div class="form__field">
											<select formControlName="category_field_id"
												(change)="onCategoryFieldSelect(categoryFields.selectedIndex)"
												#categoryFields>
												<option *ngFor="let field of category_fields; let i = index"
													[ngValue]="field.category_field_id">{{field.category_field}}
												</option>
											</select>
										</div>
										<div *ngIf="caseForm.get('category_field_id').touched 
									&& caseForm.get('category_field_id').invalid" class="message message--is-invalid">
											<div>This field is required.</div>
										</div>
									</div>
								</div>
								<div class="col col__md-4 gutter--right-2x">
									<div class="form__row">
										<div class="form__label">MD Type</div>
										<div class="form__field">
											<select formControlName="md_type_id">
												<option *ngFor="let md_type of md_types" [ngValue]="md_type.md_type_id">
													{{md_type.md_type}}</option>
											</select>
										</div>
										<div *ngIf="caseForm.get('md_type_id').touched 
									&& caseForm.get('md_type_id').invalid" class="message message--is-invalid">
											<div>This field is required.</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col col__md-4 gutter--right-2x">
									<div class="form__row">
										<div class="form__label">Ally Case Number</div>
										<div class="form__field">
											<div class="form__field">
												<input type="text" name="allyCaseNumber" formControlName="allyCaseNumber" />
											</div>
										</div>
									</div>
								</div>
								<div class="col col__md-4 gutter--right-2x">
									<div class="form__row">
										<div class="form__field">
											<div class="form__field--checkbox">
												<input type="checkbox" formControlName="is_vip" />
												<label for="option">VIP</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="form__row">
								<div class="form__label">Patient Diagnosis</div>
								<div class="form__field">
									<textarea placeholder="Enter Patient Diagnosis"
										formControlName="patient_diagnosis"></textarea>
								</div>
								<div *ngIf="caseForm.get('patient_diagnosis').touched 
							&& caseForm.get('patient_diagnosis').invalid" class="message message--is-invalid">
									<div *ngIf="caseForm.get('patient_diagnosis').errors.required">This field is
										required.</div>
									<div *ngIf="caseForm.get('patient_diagnosis').errors.maxlength">This field must not
										exceed more than 50000 characters</div>
								</div>
							</div>
							<div class="hr hr--space-down-2x"></div>
							<div formArrayName="case_questions">
								<div class="form__row" *ngFor="let question of case_questions.controls; let i = index"
									[formGroupName]="i">
									<div class="form__label">Question {{i+1}}</div>
									<div class="row">
										<div class="col col__md-11 gutter--right-x">
											<div class="form__field">
												<input type="text" placeholder="Please enter your question here..."
													formControlName="question_text" />
											</div>
											<div *ngIf="question?.get('question_text').touched 
											&& question?.get('question_text').invalid" class="message message--is-invalid">
												<div *ngIf="question?.get('question_text').errors.required">This field is
													required.</div>
												<div *ngIf="question?.get('question_text').errors.maxlength">This field
													must not exceed more than 50000 characters.</div>
											</div>
										</div>
										<div class="col col__md-1">
											<a href="#" (click)="removeQuestionAtIndex(i, $event)"
												*ngIf="case_questions.controls.length > 1"
												class="btn btn--red btn--small btn--remove">X</a>
										</div>
									</div>

								</div>
							</div>

							<div class="btns-row">
								<a href="#" class="btn btn--blue" (click)="getQuestionForm($event)">Add Question</a>
							</div>

							<div class="hr"></div>

							<div class="form__row">
								<div class="form__field checkbox--in-row">
									<div class="form__field--checkbox">
										<input type="checkbox" formControlName="interpreter_needed"
											id="interpreter_needed" />
										<label for="interpreter_needed">Interpreter Needed</label>
									</div>
									<div class="form__field--checkbox">
										<input type="checkbox" formControlName="translator_needed"
											id="translator_needed" />
										<label for="translator_needed">Summary Coordinator Needed</label>
									</div>
								</div>
							</div>

							<div class="form__row">
								<div class="form__label">Additional Notes</div>
								<div class="form__field">
									<textarea formControlName="case_additional_note"
										placeholder="Enter Additional Notes"></textarea>
									<div class="message message--is-invalid"
										*ngIf="caseForm.get('case_additional_note').touched && !caseForm.get('case_additional_note').valid">
										This field must not exceed more than 50000 characters</div>
								</div>
							</div>

							<div class="agree">
								<div class="agree__row">
									<div class="check">
										<div class="form__field">
											<div class="form__field--checkbox" style="margin-top:-18px;">
												<input type="checkbox" formControlName="agreement" id="agreement"
													style="visibility:hidden;" />
												<label for="agreement" style="margin-left:0;">&nbsp;</label>
											</div>

										</div>
									</div>
									<div class="read text text--dark-grey text--small" *ngIf="!agreementShowAll">
										I acknowledge the terms and conditions and that this medical opinion does not
										constitute a physician/patient relationship,
										and is not intended as a solicitation of an individual to become the physician’s
										patient or client. I also acknowledge
										that the final report does not constitute a medical diagnosis, treatment, or
										prescription of any kind. All information
										provided within the final report, or any ...
										<a href="#" (click)="changeAgreementShowAll($event, 'read_more')">Read more</a>
										<div class="btns margin--top-2x">
											<a href="#" class="btn" [class.btn--blue]="caseForm.valid"
												[class.btn--grey]="!caseForm.valid" (click)="onAccept($event)">Agree
												&amp; Continue</a>
										</div>
									</div>
									<div class="read text text--dark-grey text--small" *ngIf="agreementShowAll">
										I acknowledge the terms and conditions and that this medical opinion does not
										constitute a physician/patient relationship,
										and is not intended as a solicitation of an individual to become the physician’s
										patient or client. I also acknowledge
										that the final report does not constitute a medical diagnosis, treatment, or
										prescription of any kind. All information
										provided within the final report, or any communications supported by Alight
										(to include, but not limited to,
										real-time video consultations), is intended to be for general informational and
										health education purposes only,
										and is in no way intended to create a physician/patient relationship, as defined
										by state and federal law. Alight
										is not a substitute for a professional medical diagnosis or treatment. Reliance
										on any information provided by Alight
										is solely at your own risk.
										<!-- <a href="#" (click)="changeAgreementShowAll($event, 'less')">Show less</a> -->
										<div class="btns margin--top-2x">
											<a href="#" class="btn" [class.btn--blue]="caseForm.valid"
												[class.btn--grey]="!caseForm.valid" (click)="onAccept($event)">Agree
												&amp; Continue</a>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</form>

			<div class="col col__md-8 gutter--right-3x overlay__wrapper"
				[class.overlay--visible]="isDocumentTypeInprogress" *ngIf="step == 2">
				<div class="overlay__content">
					<div class="overlay__spinner">
						<span class="icon icon--loading"></span>
					</div>
				</div>
				<div class="box box--white box--radius box--margin-bottom-x box--pad-2x">
					<div class="form__label text--uppercase">Step 2</div>
					<h3 class="form__title">Upload Relevant Case Documentation</h3>
					<div class="text text--small text--dark-grey margin--bottom-2x">Please upload all medical records
						and data if they are available now. You will have the opportunity to upload more documents
						later.
					</div>
					<div class="form__field">
						<div class="upload-form">
							<div class="form">
								<div class="row">
									<div class="col col__md-4 gutter--right-2x">
										<div class="form__row">
											<label for="" class="form__label">Type</label>
											<div class="form__field">
												<select class="text--black" #documentType>
													<option [value]="type.value" *ngFor="let type of types">{{type.key}}
													</option>
												</select>
											</div>
										</div>
									</div>
									<div class="col col__md-5 gutter--right-2x">
										<div class="form__row">
											<label for="" class="form__label">Upload Document</label>

											<div class="form__field upload-btn-file">

												<div class="form__field upload">
													<input type="file" class="btn btn--grey" name="document" #document
														ng2FileSelect ng2FileSelect
														(change)="inputFieldOnChange(document)" [uploader]="uploader" />
													<a class="btn btn--grey btn--full-width" href="#">Browse</a>
												</div>
												<div class="uploaded-file">
													{{document.value == null || document.value === '' ? 'No File Choosen' : document?.files?.item(0)?.name}}
												</div>
											</div>
										</div>
									</div>

									<div class="col col__md-3 text-right">
										<a href="#" class="btn btn--blue btn-upload-doc"
											(click)="uploadAddItem('file',$event,document,documentType,uploader)">Upload</a>
									</div>
								</div>

								<div class="text-right">
									<a [href]="partnerDetails?.scan_link"
										[target]="'_blank'" class="btn btn--blue btn--add-scans">Add Scans</a>

								</div>


								<!-- <form #scanForm="ngForm">
								<div class="row">
									<div class="col col__md-4 gutter--right-2x">
										<label for="" class="form__label">Scan Name</label>
										<div class="form__field">
											<input type="text" name="document_name" ngModel/>
										</div>
									</div>
									<div class="col col__md-6 gutter--right-2x">
										<div class="form__row">
											<label for=" " class="form__label">Scan Link</label>
											<div class="form__field">
												<input type="text" name="location" ngModel/>
											</div>
										</div>
									</div>
									<div class="col col__md-2">
										<a href="#" class="btn btn--blue btn--full-width btn-upload-doc" (click)="uploadAddItem('link', $event, scanForm)">Add Scan</a>
									</div>
								</div>
							</form> -->
							</div>
						</div>

					</div>
					<div class="cloud-upload">
						<div class="cloud-upload__block" *ngFor="let uploader of uploads">
							<div class="upload-doc cloud-progress" *ngIf="uploader.type == 'file'">
								<div class="icon icon--upload-cloud" *ngIf="uploader.uploader.progress < 100"></div>
								<div class="icon icon--uploaded-file" *ngIf="uploader.uploader.progress > 99"></div>
								<div class="upload-row no-pad margin--bottom-2x"
									*ngIf="uploader.uploader.progress < 100">
									<div class="upload-row__file-name">
										<div class="status-row">
											<div class="status-row__bar-status">
												<div class="bar-status">
													<div class="bar-status__count"
														[style.width.%]="uploader.uploader.progress"></div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div *ngIf="uploader.uploader.progress < 100">
									<div class="text upload-doc__file-name">Uploading
										{{uploader.uploader.queue[0].file.name}} ... </div>
									<div class="text text--small text--dark-grey">{{uploader.uploader.progress}}% of
										{{getReadableFileSizeString(uploader.uploader.queue[0].file.size)}} Uploaded
									</div>
								</div>
								<div *ngIf="uploader.uploader.progress > 99">
									<div class="text upload-doc__file-name">
										{{uploader.uploader.queue[0].file.name}}-{{uploader.documentType}}</div>
									<div class="text text--small text--dark-grey">
										{{getReadableFileSizeString(uploader.uploader.queue[0].file.size)}}</div>
								</div>
								<a *ngIf="uploader.type === 'file' && uploader.uploader.progress < 100"
									class="btn btn--red btn--small margin--bottom-x" href="#"
									(click)="cancelUpload(uploader.uploader, $event)">Cancel</a>
								<a *ngIf="uploader.type === 'file' && uploader.uploader.progress == 100"
									class="btn btn--red btn--small margin--bottom-x" href="#"
									(click)="deleteUpload(uploader, $event)">Delete</a>
							</div>
							<a *ngIf="uploader.type === 'link'"
								[href]="uploader.link.location" [target]="'_blank'">
								<div class="upload-doc cloud-progress" [title]="uploader.link.location">
									<div class="icon icon--uploaded-link"></div>
									<div *ngIf="uploader.type === 'link'">
										<div class="text">{{uploader.link.document_name}}</div>
									</div>
									<a *ngIf="uploader.type === 'link'" class="btn btn--red btn--small margin--bottom-x"
										href="#" (click)="deleteUpload(uploader.link.document_name, $event)">Delete</a>
								</div>
							</a>
						</div>
					</div>
				</div>
				<div class="btns__row text-right" *ngIf="partnerDetails?.display_specialist == true">
					<a href="#" class="btn btn--blue" (click)="continue($event)">Continue</a>
				</div>
				<!-- <div class="col col__md-2" *ngIf="partnerDetails?.display_specialist == false"> -->
				<div class="btns__row text-right" *ngIf="partnerDetails?.display_specialist == false">
					<a href="#" class="btn btn--blue" (click)="onSubmit($event)">Submit</a>
				</div>
				<!-- </div> -->
			</div>

			<div class="col col__md-8 gutter--right-3x" *ngIf="step == 3 && isHasLikeDoctor">
				<div class="box box--white box--radius box--margin-bottom-x box--pad-2x overlay__wrapper"
					[class.overlay--visible]="isProgress">
					<div class="overlay__content">
						<div class="overlay__spinner">
							<span class="icon icon--loading"></span>
						</div>
					</div>
					<div class="form__label text--uppercase">Step 3</div>
					<h3 class="form__title" *ngIf="doctorsList && doctorsList.length > 0">Select a Doctor</h3>
					<div class="text text--small text--dark-grey margin--bottom-2x"
						*ngIf="doctorsList && doctorsList.length > 0">Below are recommended doctors based on your case
						information.</div>

					<div *ngIf="!doctorsList || doctorsList.length < 1">We will send you experts profiles to choose from
						shortly</div>

					<div class="cards" *ngIf="doctorsList && doctorsList.length > 0">
						<div class="cards__wrapper" *ngFor="let doctor of doctorsList">
							<div class="cards__box" (click)="viewDoctorCard(doctor)">
								<div class="photo">
									<div class="avatar avatar--cards">
										<app-loader *ngIf="doctor.loading "></app-loader>
										<img [src]="'data:image/jpg;base64,'+doctor.doctor_info.user.avatar.image"
											*ngIf="!doctor.loading && doctor.doctor_info.user.avatar.image && !doctor.doctor_info.user.avatarFallback" />
										<img [src]="doctor.doctor_info.user.avatarFallback" *ngIf="!doctor.loading  && !doctor.doctor_info.user.avatar.image && doctor.doctor_info.user.avatarFallback" />
									</div>
									<!-- <div class="rating">
									<span class="rating__value">4.9</span>
									<span class="icon icon--star-red"></span>
								</div> -->
								</div>
								<div class="details">
									<div class="details__name">Dr. {{doctor?.doctor_info?.user?.full_name}}</div>
									<div class="details__text">{{doctor?.doctor_info?.institution}}</div>
									<div class="hr"></div>
									<div class="details__text" *ngFor="let title of doctor?.doctor_info?.doctor_titles">
										{{title?.title?.title}}</div>
								</div>
							</div>
							<div class="cards__button">
								<a href="#" class="btn btn--grey btn--full-width"
									*ngIf="!selectedDoctor || selectedDoctor !== doctor"
									(click)="selectDoctor(doctor, $event)">Select</a>
								<a href="#" class="btn btn--blue btn--full-width"
									*ngIf="selectedDoctor && selectedDoctor != null && selectedDoctor === doctor"
									(click)="unSelectDoctor($event)">Selected</a>
							</div>
						</div>
					</div>
				</div>

				<div class="btns__row text-right" *ngIf="(!doctorsList || doctorsList.length < 1)">
					<a href="#" class="btn btn--blue" (click)="onSubmit($event)">Continue</a>
				</div>

				<!-- <div class="box box--white box--radius box--margin-bottom-x box--pad-2x overlay__wrapper" *ngIf="isSelected && isPublicallyListed"
			 [class.overlay--visible]="isProgress">
				<div class="overlay__content">
					<div class="overlay__spinner">
						<span class="icon icon--loading"></span>
					</div>
				</div>
				<h3 class="form__title margin--top-0">Select a Time
					<span class="text text--small text--dark-grey margin--bottom-2x">Please select all times you are available to meet with Dr. {{selectedDoctor?.doctor_info?.user?.full_name}}.</span>
				</h3>
				<div *ngIf="isCalenderLoading">
					Loading
				</div>
				<div class="time-selector" *ngIf="!isCalenderLoading">
					<div class="time-selector__col time-selector__col--prev" *ngIf="!containsCurrentDate()">
						<a href="#" class="time-nav time-nav--prev" (click)="getPrevDates($event)">
							<span class="icon icon--time-nav"></span>
						</a>
					</div>
					<div class="time-selector__col time-selector__col--day-time">
						<div class="day-time">
							<div class="day-time__col" *ngFor="let date of available_dates?.times">
								<div class="day-time__day-date">
									<div class="day-name">{{date.day}}</div>
									<div class="day-date">{{date.date | date: 'MMM dd'}}</div>
								</div>
								<div class="day-time__day-slots" *ngFor="let time of date.available_time">
									<div class="day-slot" *ngIf="time == null">
										<span class="time-slot-na"></span>
									</div>
									<div class="day-slot" *ngIf="time != null">
										<a href="#" (click)="selectOrReleseTimeSlot(time, date.date, daySlot, $event)" class="btn" [class.btn--grey]="selectedTimeIndex(time, date.date) < 0"
										 [class.btn--blue]="selectedTimeIndex(time, date.date) > -1" #daySlot>{{date.date+' '+time.timezone_start_time | date: 'hh:mm aa'}}</a>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="!available_dates?.haveDate">
							No dates available
						</div>
					</div>
					<div class="time-selector__col time-selector__col--next">
						<a href="#" (click)="getNextDates($event)" class="time-nav time-nav--next">
							<span class="icon icon--time-nav"></span>
						</a>
					</div>
				</div>
			</div> -->

				<div class="box box--white box--radius box--margin-bottom-x box--pad-2x overlay__wrapper"
					*ngIf="isSelected && isPublicallyListed && caseForm?.get('case_type')?.value === 'VIDEO CONSULTATION'"
					[class.overlay--visible]="isCalenderLoading">
					<div class="overlay__content">
						<div class="overlay__spinner">
							<span class="icon icon--loading"></span>
						</div>
					</div>

					<h3 class="form__title margin--top-0">Select a Time
						<span class="text text--small text--dark-grey margin--bottom-2x">Please select all times you are
							available to meet with Dr. {{selectedDoctor?.doctor_info.user.full_name}}.</span>
					</h3>

					<div class="av-time-selector">
						<div class="av-time-selector__heading">
							<div class="headinng-date">
								Date
							</div>
							<div class="heading-start-time">Start Time</div>
							<div class="heading-end-time">End Time</div>
						</div>
						<div class="av-time-selector__content">
							<div class="av-time-selector__row" [class.av-time-selector__row--selected]="dates.selected"
								(click)="selectOrUnselectRow(dates)" *ngFor="let dates of available_dates">
								<div class="headinng-date">
									{{dates.start_date_time | date: 'MM/dd/yyyy, EEE'}}
								</div>
								<div class="heading-start-time">{{dates.start_date_time | date: 'hh:mm a'}}</div>
								<div class="heading-end-time">{{dates.end_date_time | date: 'hh:mm a'}}</div>
							</div>
						</div>
					</div>

				</div>

				<div class="box box--white box--radius box--margin-bottom-x box--pad-2x overlay__wrapper"
					[class.overlay--visible]="isCalenderLoading"
					*ngIf="isSelected && !isPublicallyListed && caseForm?.get('case_type').value === 'VIDEO CONSULTATION'">
					<div class="overlay__content">
						<div class="overlay__spinner">
							<span class="icon icon--loading"></span>
						</div>
					</div>

					<div class="text text-center">
						Dr. {{selectedDoctor?.doctor_info.user.full_name}} does not presently have recurring office
						hours listed but is most likely
						available. Alight will coordinate with you directly to schedule this consultation.
					</div>
					<div class="btns__row text-right">
						<a href="#" class="btn btn--blue" (click)="onSubmit($event)">Submit</a>
					</div>
				</div>

				<div class="row">
					<div class="btns__row text-center"
						*ngIf="(!isSelected || (isSelected && !isPublicallyListed && caseForm?.get('case_type').value === 'VIDEO CONSULTATION'))">
						<a href="#" (click)="doNotLikeDoctor($event)">Don’t see a doctor you like?</a>
					</div>
					<div class="col col__md-10"
						*ngIf="(isSelected && isPublicallyListed) || (isSelected && caseForm?.get('case_type').value !== 'VIDEO CONSULTATION')">
						<div class="btns__row text-center">
							<a href="#" (click)="doNotLikeDoctor($event)">Don’t see a doctor you like?</a>
						</div>
					</div>
					<div class="col col__md-2"
						*ngIf="(isSelected && isPublicallyListed) || (isSelected && caseForm?.get('case_type').value !== 'VIDEO CONSULTATION')">
						<div class="btns__row text-right">
							<a href="#" class="btn btn--blue" (click)="onSubmit($event)">Submit</a>
						</div>
					</div>
				</div>
			</div>

			<div class="col col__md-8 gutter--right-3x overlay__wrapper"
				[class.overlay--visible]="!caseDetails || caseDetails == null" *ngIf="step == 4">
				<div class="overlay__content">
					<div class="overlay__spinner">
						<span class="icon icon--loading"></span>
					</div>
				</div>

				<div class="box box--white box--radius box--margin-bottom-2x box--pad-2x">

					<div class="form__label text--uppercase" *ngIf="role?.toLowerCase()==='careteam' || role?.toLowerCase()==='cmmanager' || role?.toLowerCase()==='casecoordinator'">Step 2</div>
					<div class="form__label text--uppercase"
						*ngIf="partnerDetails?.display_specialist == true && (role?.toLowerCase()!='careteam' || role?.toLowerCase()==='casecoordinator')">Step 4
					</div>
					<div class="form__label text--uppercase"
						*ngIf="partnerDetails?.display_specialist == false && (role?.toLowerCase()!='careteam' || role?.toLowerCase()==='casecoordinator')">Step 3
					</div>
					<h3 class="form__title">Confirmation</h3>
					<div class="text text--small text--dark-grey margin--bottom-2x">Your information has been
						successfully sent over! You’ll receive an email confirmation shortly.</div>
					<div class="row">
						<div class="col col__md-4">
							<div class="form__label">Patient Name</div>
							<div class="text text--semibold">{{caseDetails?.patient?.user?.full_name}}</div>
						</div>
						<div class="col col__md-4">
							<div class="form__label">Consultation Date</div>
							<div class="text text--semibold" *ngIf="caseDetails?.earliest_available_date == null">N/A
							</div>
							<div class="text text--semibold" *ngIf="caseDetails?.earliest_available_date != null">
								{{caseDetails?.earliest_available_date | date:'MMM dd, yyyy'}}</div>
						</div>
						<div class="col col__md-4">
							<div class="form__label">Reason</div>
							<div class="text text--semibold wrap wrap--case-reason">{{caseDetails?.patient_diagnosis}}
							</div>
						</div>

					</div>
				</div>
				<div class="row">
					<div class="col col__md-6">
						<div class="btns__row text-left">
							<a [routerLink]="['/case/search']" [queryParams]="{list: 'current'}" class="btn btn--blue">Return
								to Case List</a>
						</div>
					</div>
					<div class="col col__md-6">
						<div class="btns__row text-right">
							<a [routerLink]="['/case',case_id]" class="btn btn--blue">Go to Case</a>
						</div>
					</div>
				</div>
			</div>


			<form *ngIf="step == 3 && !isHasLikeDoctor" [formGroup]="caseForm">
				<div class="col col__md-8 gutter--right-3x overlay__wrapper" [class.overlay--visible]="isProgress">
					<div class="overlay__content">
						<div class="overlay__spinner">
							<span class="icon icon--loading"></span>
						</div>
					</div>
					<div class="box box--white box--radius box--margin-bottom-x box--pad-2x">
						<div class="form__label text--uppercase">Step 3</div>
						<h3 class="form__title">Don’t see a doctor you like?</h3>

						<div class="form">
							<div class="form__row">
								<div class="form__label">Reason</div>
								<div class="form__field">
									<select formControlName="reason_id">
										<option *ngFor="let reason of reasonList" [ngValue]="reason.value">
											{{reason.key}}</option>
									</select>
								</div>
								<div *ngIf="caseForm.get('reason_id').touched 
							&& caseForm.get('reason_id').invalid" class="message message--is-invalid">
									<div>This field is required.</div>
								</div>
							</div>
							<div class="hr hr--space-down-2x hr--space-up-2x"></div>
							<div class="form__row">
								<div class="form__label">Additional Notes</div>
								<div class="form__field">
									<textarea formControlName="reason_additional_note">Enter Additional Notes</textarea>
									<div class="message message--is-invalid"
										*ngIf="caseForm.get('reason_additional_note').touched && !caseForm.get('reason_additional_note').valid">
										<div *ngIf="caseForm.get('reason_additional_note').errors.maxlength">This field
											must not have more than 50000 characters.</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="btns__row text-right">
						<a href="#" class="btn btn--blue" (click)="onSubmit($event)">Continue</a>
					</div>
				</div>
			</form>
		</div>

		<div *ngIf="role?.toLowerCase()==='patient'">
			<div class="col col__md-8 gutter--right-2x" *ngIf="step == 1">
				<div class="box box--white box--radius box--margin-bottom-x box--pad-2x overlay__wrapper"
					[class.overlay--visible]="isProgress">
					<div class="overlay__content">
						<div class="overlay__spinner">
							<span class="icon icon--loading"></span>
						</div>
					</div>

					<form [formGroup]="caseForm">
						<div class="form__row">
							<div class="form__label">
								How can we help you today?
							</div>
							<div class="form__field">
								<textarea formControlName="case_patient_note" class="validation"
									placeholder="E.g. I have been diagnosed with lower back pain and scheduled for surgery. I would like a second opinion for alternative treatment options."
									[class.validation--is-invalid]="case_patient_note?.touched && case_patient_note?.errors"></textarea>
								<div class="message message--is-invalid"
									*ngIf="case_patient_note?.touched && case_patient_note?.errors">
									<div *ngIf="case_patient_note.errors.required">This field is required.</div>
									<div *ngIf="case_patient_note.errors.maxlength">This field must not exceed more
										than
										{{case_patient_note.errors.maxlength.requiredLength}} characters.</div>
								</div>
							</div>
						</div>

						<div class="form__row">
							<div class="form__label">
								Diagnosis
							</div>
							<div class="form__field">
								<textarea type="text" placeholder="Enter your diagnosis here"
									formControlName="patient_diagnosis" class="validation"
									[class.validation--is-invalid]="patient_diagnosis?.touched && patient_diagnosis?.errors"></textarea>
							</div>
							<div class="message message--is-invalid"
								*ngIf="patient_diagnosis?.touched && patient_diagnosis?.errors">
								<div *ngIf="patient_diagnosis?.errors.maxlength">This field must not exceed more than
									{{patient_diagnosis?.errors.maxlength.requiredLength}} characters.</div>
							</div>
						</div>

						<div class="form__row">
							<div class="col col__md-8 gutter--right-2x">
								<div class="form__label">
									Category
								</div>
								<div class="form__field">
									<select formControlName="category_id">
										<option *ngFor="let category of categories" [ngValue]="category.category_id">
											{{category.category}}</option>
									</select>
								</div>
							</div>
						</div>

						<div class="form__row">
							<div class="col col__md-8 gutter--right-2x">
								<div class="form__label">
									Your preference receiving experts opinion
								</div>
								<div class="form__field">
									<div class="form__field">
										<select formControlName="case_type" class="validation"
											[class.validation--is-invalid]="case_type.touched && case_type.errors">
											<option *ngFor="let option of caseType" [value]="option.value">
												{{option.key}}</option>
										</select>
									</div>
								</div>
								<div class="message message--is-invalid" *ngIf="case_type.touched && case_type.errors">
									<div>This field is required.</div>
								</div>
							</div>
						</div>

						<div class="form__row">
							<div class="col col__md-8 gutter--right-2x">
								<div class="form__label">
									Your preference form of communication
								</div>
								<div class="form__field">
									<select formControlName="preferred_communication_mode" class="validation"
										[class.validation--is-invalid]="preferred_communication_mode.touched && preferred_communication_mode.errors"
										(change)="onPreferredModeSelect(communication_mode_dropdown.value)"
										#communication_mode_dropdown>
										<option *ngFor="let mode of communicationMode" [value]="mode.value">{{mode.key}}
										</option>
									</select>
								</div>
								<div class="message message--is-invalid"
									*ngIf="preferred_communication_mode.touched && preferred_communication_mode.errors">
									<div>This field is required.</div>
								</div>
							</div>
						</div>

						<div class="form__row"
							*ngIf="preferred_communication_mode.value === 'PHONE' || preferred_communication_mode.value === 'EMAIL_PHONE'">
							<div class="form__label">
								BEST TIMES TO CALL, IF PHONE IS PREFERRED
							</div>
							<div class="form__field">
								<div class="form__row">
									<div *ngFor="let time of preferredTime" class="col col__md-3 gutter--right-2x">
										<div class="form__field--radio"><input type="radio" [value]="time.value"
												formControlName="best_communication_time">{{time.key}}
										</div>
									</div>
								</div>
							</div>
							<div class="message message--is-invalid"
								*ngIf="best_communication_time.touched && best_communication_time.errors">
								This field is required.
							</div>
						</div>

						<div class="form__row"
							*ngIf="preferred_communication_mode.value === 'PHONE' || preferred_communication_mode.value === 'EMAIL_PHONE'">
							<div class="col col__md-8 gutter--right-2x">
								<div class="form__label">
									Please enter the best number to reach you here:
								</div>
								<div class="form__label">
									Phone Number
								</div>
								<div class="form__field">
									<input type="text" class="validation"
										[class.validation--is-invalid]="phone_number.touched && phone_number.errors"
										formControlName="phone_number" maxlength="15" PhoneNumber />
								</div>
								<div class="message message--is-invalid"
									*ngIf="phone_number.touched && phone_number.errors">
									This field is required.
								</div>
							</div>
						</div>

						<div formArrayName="case_questions">
							<div *ngFor="let question of case_questions.controls; let i = index" [formGroupName]="i">
								<div class="form__row">

									<div class="form__label">
										Question {{i+1}}
									</div>
									<div class="row">
										<div class="col col__md-11 gutter--right-2x">
											<div class="form__field">
												<input type="text" placeholder="Please enter your question here"
													formControlName="question_text" class="validation"
													[class.validation--is-invalid]="question?.get('question_text').touched && question?.get('question_text').errors" />
											</div>
										</div>
										<div class="col col__md-1">
											<a href="#" (click)="removeQuestionAtIndex(i, $event)"
												*ngIf="case_questions.controls.length > 1"
												class="btn btn--red btn--small btn--remove">X</a>
										</div>
									</div>
									<div class="message message--is-invalid"
										*ngIf="question?.get('question_text').touched && question?.get('question_text').errors">
										<div *ngIf="question?.get('question_text').errors.required">This field is
											required.</div>
										<div *ngIf="question?.get('question_text').errors.maxlength">This field must not
											exceed more than
											{{question?.get('question_text').errors.maxlength.requiredLength}}
											characters.</div>
									</div>
								</div>
							</div>
						</div>

						<div class="btns-row">
							<a href="#" class="btn btn--blue" (click)="getQuestionForm($event)">Add Question</a>
						</div>

						<div class="agree">
							<div class="agree__row">
								<div class="check">
									<div class="form__field">
										<div class="form__field--checkbox" style="margin-top:-18px;">
											<input type="checkbox" id="agreement" style="visibility:hidden;"
												formControlName="agreement" />
											<label for="agreement" style="margin-left:0;">&nbsp;</label>
										</div>

									</div>
								</div>
								<div class="read text text--dark-grey text--small" *ngIf="!agreementShowAll">
									I acknowledge the terms and conditions and that this medical opinion does not
									constitute a physician/patient relationship,
									and is not intended as a solicitation of an individual to become the physician’s
									patient or client. I also acknowledge
									that the final report does not constitute a medical diagnosis, treatment, or
									prescription of any kind. All information
									provided within the final report, or any ...
									<a href="#" (click)="changeAgreementShowAll($event, 'read_more')">Read more</a>
									<div class="btns margin--top-2x">
										<a href="#" class="btn" [class.btn--blue]="caseForm.valid"
											[class.btn--grey]="!caseForm.valid" (click)="onAccept($event)">Agree
											&amp; Continue</a>
									</div>
								</div>
								<div class="read text text--dark-grey text--small" *ngIf="agreementShowAll">
									I acknowledge the terms and conditions and that this medical opinion does not
									constitute a physician/patient relationship,
									and is not intended as a solicitation of an individual to become the physician’s
									patient or client. I also acknowledge
									that the final report does not constitute a medical diagnosis, treatment, or
									prescription of any kind. All information
									provided within the final report, or any communications supported by Alight
									(to include, but not limited to,
									real-time video consultations), is intended to be for general informational and
									health education purposes only,
									and is in no way intended to create a physician/patient relationship, as defined
									by state and federal law. Alight
									is not a substitute for a professional medical diagnosis or treatment. Reliance
									on any information provided by Alight
									is solely at your own risk.
									<div class="btns margin--top-2x">
										<a href="#" class="btn" [class.btn--blue]="caseForm.valid"
											[class.btn--grey]="!caseForm.valid" (click)="onAccept($event)">Agree
											&amp; Continue</a>
									</div>
								</div>

							</div>
						</div>
					</form>
				</div>
			</div>

			<div class="col col__md-8 gutter--right-2x" *ngIf="step == 4">
				<div class="box box--white box--radius box--margin-bottom-x box--pad-2x overlay__wrapper"
					[class.overlay--visible]="isProgress">
					<div class="overlay__content">
						<div class="overlay__spinner">
							<span class="icon icon--loading"></span>
						</div>
					</div>


					<div class="confirmation">
						<div class="confirmation-header">
							Confirmation
						</div>

						<div class="confirmation-wrapper">
							<img src="assets/images/confirmation.png" />
						</div>

						<div class="confirmation-wrapper">
							<div class="confirmation-wrapper--content text--dark-grey">
								Your request has been recieved! Look forward to recieve a phone call (or email) in the
								next
								2 business days.
							</div>
						</div>

						<div class="confirmation-wrapper">
							<a class="btn btn--green" [routerLink]="['/case', case_id]">VIEW CASE DETAIL</a>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col col__md-4 case-sidebar">
			<div class="box box--white box--radius box--margin-bottom-x box--pad-2x overlay__wrapper"
				[class.overlay--visible]="isProgress">
				<div class="overlay__content">
					<div class="overlay__spinner">
						<span class="icon icon--loading"></span>
					</div>
				</div>
				<div class="contact-details no-border no-pad no-mar">
					<div class="contact-details__row contact-details__row--single">
						<div class="contact-details__label label-is-heading">Create a Case</div>
						<div class="text text--small text--dark-grey">
							{{ role?.toLowerCase()==='careteam' ||  role?.toLowerCase()==='casecoordinator'|| role?.toLowerCase()==='patient' || role?.toLowerCase()==='cmmanager' ? 'Two' : 'Four' }}
							simple steps to access the worlds
							best doctors.</div>
					</div>
					<div class="steps__data-row"
						*ngIf="role?.toLowerCase()==='partner' || role?.toLowerCase()==='careteam' || role?.toLowerCase()==='casecoordinator' || role?.toLowerCase()==='cmmanager'">
						<div class="data-label">Patient</div>
						<div class="data-details">
							{{patient != null ? patient?.full_name : 'No Name'}}, {{patient != null ? (patient?.patient_detail?.gender === 'M' ? 'Male'
							: (patient?.patient_detail?.gender === 'F' ? "Female" : "Unspecified")) : 'Unspecified'}}
							({{patient != null ? patient?.age : 0}} y/o)
						</div>
					</div>
					<div class="steps">
						<div class="steps__row" [class.row-active]="step == 1">
							<div class="steps__counter" *ngIf="step > 1">
								<span class="icon icon--grey-tick"></span>
							</div>
							<div class="steps__counter" *ngIf="step <= 1">1</div>
							<div class="steps__title">Enter Case Information</div>
						</div>
						<div class="steps__row" *ngIf="role?.toLowerCase()==='partner'" [class.row-active]="step == 2">
							<div class="steps__counter" *ngIf="step > 2">
								<span class="icon icon--grey-tick"></span>
							</div>
							<div class="steps__counter" *ngIf="step <= 2">2</div>
							<div class="steps__title">Upload Relevant Documents</div>
						</div>
						<div class="steps__row"
							*ngIf="role?.toLowerCase()==='partner' && partnerDetails?.display_specialist"
							[class.row-active]="step == 3">
							<div class="steps__counter" *ngIf="step > 3">
								<span class="icon icon--grey-tick"></span>
							</div>
							<div class="steps__counter" *ngIf="step <= 3">3</div>
							<div class="steps__title">Select Doctor</div>
						</div>
						<div class="steps__row" [class.row-active]="step == 4">
							<div class="steps__counter" *ngIf="step > 4">
								<span class="icon icon--grey-tick"></span>
							</div>
							<div class="steps__counter" *ngIf="step <= 4">
								{{ role?.toLowerCase()==='partner' ? (partnerDetails?.display_specialist ? '4' : '3') : '2' }}
							</div>
							<div class="steps__title">Confirmation</div>
						</div>
					</div>
				</div>
			</div>
			<div class="box text-center text--dark-grey text--small">
				In case you’ll be struggling with any of these steps just hit our support team at
				<a href="#">support@infiniteMD.com</a>. We will happily help you!
			</div>
		</div>
	</div>
</div>
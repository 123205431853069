/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigAssetLoaderService } from '../services/config-asset-loader.service';

import { getHeaderWithToken, PATIENT } from './constant';

@Injectable()
export class CommonService {

  constructor(
		private http: HttpClient
  ) { }

  getUserType(): Observable<object[]> {
    const token = localStorage.getItem('token');
    return this.http.get<object[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'usertypes', this.getHeaderWithToken(token));
  }

  getLanguages(): Observable<any[]> {
    const token = localStorage.getItem('token');
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'languages', this.getHeaderWithToken(token));
  }

  getPartners(): Observable<any[]> {
    const headers = this.getHeaderWithToken(localStorage.getItem('token'));

    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'partner/partnerName', headers);
  }

  getCountries(): Observable<any[]> {
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'country');
  }

  /**
   * Function to fetch all patients
   * @return {*}  {Observable<any>}
   * @memberof MailServerService
   */
  getPatients(): Observable<any> {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${PATIENT.GET}`;
    return this.http.get<any>(URL, getHeaderWithToken());
  }

  getSocialTypes(): Observable<any[]> {
    const token = localStorage.getItem('token');
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'socialtypes', this.getHeaderWithToken(token));
  }
  getConditions(): Observable<any[]> {
    const token = localStorage.getItem('token');
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'condition', this.getHeaderWithToken(token)).pipe(
      map(
        conditions => {
          conditions.sort(
            (condition1, condition2) => {
              if (condition1.condition === 'Other') {
                return 1;
              }

              if (condition1.condition > condition2.condition) {
                return 1;
              }

              return -1;
            }
          );

          return conditions;
        }
      ));
  }

  getProcedures(): Observable<any[]> {
    const token = localStorage.getItem('token');
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'procedure', this.getHeaderWithToken(token));
  }

  getCertification(): Observable<any[]> {
    const token = localStorage.getItem('token');
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'certification', this.getHeaderWithToken(token)).pipe(map(
      certifications => {

        const otherCertificationIndex = certifications.findIndex(each => each.certification === 'Other');

        if (otherCertificationIndex > -1) {
          const otherCertification = certifications[otherCertificationIndex];

          certifications.splice(otherCertificationIndex, 1);

          const newCertification = certifications;

          newCertification.sort(
            (cert1, cert2) => {
              if (cert1.certification > cert2.certification) {
                return 1;
              }

              return -1;
            }
          );

          newCertification.push(otherCertification);

          certifications = newCertification;
        }
        else {

          certifications.sort(
            (cert1, cert2) => {
              if (cert1.certification > cert2.certification) {
                return 1;
              }

              return -1;
            }
          );

        }

        return certifications;
      }
    ));
  }

  getTimeZones(): Observable<any[]> {
    const token = localStorage.getItem('token');
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'timezones', this.getHeaderWithToken(token));

  }

  getGroupList(): Observable<any[]> {
    const token = localStorage.getItem('token');
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'groups', this.getHeaderWithToken(token));
  }
  getDegreeList(): Observable<any[]> {
    const token = localStorage.getItem('token');
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'degree', this.getHeaderWithToken(token));
  }

  getRecommendations(): Observable<any[]> {
    const token = localStorage.getItem('token');
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'recommendations', this.getHeaderWithToken(token));
  }

  getInterventions(): Observable<any[]> {
    const token = localStorage.getItem('token');
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'surgical_interventions', this.getHeaderWithToken(token));
  }

  getOrganSystem(): Observable<any[]> {
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'organ_system',);
  }

  getTitleList(): Observable<any[]> {
    const token = localStorage.getItem('token');
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'title', this.getHeaderWithToken(token));
  }

  getCaseType(): Observable<any[]> {
    return this.http.get<any[]>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'cases/case_type',
      this.getHeaderWithToken(localStorage.getItem('token'))
    );
  }

  getCaseStatus(): Observable<any[]> {
    return this.http.get<any[]>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'cases/case_status',
      this.getHeaderWithToken(localStorage.getItem('token'))
    );
  }

  getImportance(): Observable<any[]> {
    const token = localStorage.getItem('token');
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'cases/importance', this.getHeaderWithToken(token));
  }

  getCategory(age: number): Observable<any> {
    const token = localStorage.getItem('token');
    return this.http.get<any>(ConfigAssetLoaderService.getApiURL() + '/' + 'cases/category?age=' + age, this.getHeaderWithToken(token)).pipe(map(
      data => {
        data.categories.sort(
          function (category1, category2) {
            if (category1.category > category2.category) {
              return 1;
            }
            else {
              return -1;
            }
          }
        );

        const otherSpecialities = data.categories.findIndex(each => each.category === 'Other Specialties');
        data.categories.push(data.categories[otherSpecialities]);
        data.categories.splice(otherSpecialities, 1);

        data.categories.forEach(eachCategory => {
          eachCategory.category_fields = eachCategory.category_fields.filter(each => each.age_groups.length > 0);

          eachCategory.category_fields.sort(
            function (field1, field2) {
              if (field1.category_field > field2.category_field) {
                return 1;
              }
              else {
                return -1;
              }
            }
          );

          const OtherCategoryField = eachCategory.category_fields.findIndex(each => (each.category_field === 'Other' || each.category_field === 'other'));
          eachCategory.category_fields.push(eachCategory.category_fields[OtherCategoryField]);
          eachCategory.category_fields.splice(OtherCategoryField, 1);
        });

        return data;
      }
    ));
  }

  getCategoryField(): Observable<any[]> {
    const token = localStorage.getItem('token');
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'cases/category_field', this.getHeaderWithToken(token));
  }

  getMdType(): Observable<any[]> {
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'md_type', this.getHeaderWithToken(localStorage.getItem('token')));
  }

  getCaseReason(): Observable<any[]> {
    const token = localStorage.getItem('token');
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'cases/reason', this.getHeaderWithToken(token));
  }

  getCaseDocumentType(userType?: string): Observable<any[]> {
    const token = localStorage.getItem('token');
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'type/document?type=' + userType.toLowerCase(), this.getHeaderWithToken(token));
  }
  sendEmail(payload) {
    const token = localStorage.getItem('token');
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() +'/faqemail',
      payload,
      this.getHeaderWithToken(token)
    );
  }
  getScheduleLogs(): Observable<any[]> {
    const token = localStorage.getItem('token');
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/v2/' + 'archive/list', this.getHeaderWithToken(token));
  }
  setScheduleLogs(payload){
    const token = localStorage.getItem('token');
    return this.http.post<any[]>(ConfigAssetLoaderService.getApiURL() + '/v2/' + 'archive/change_time', payload ,this.getHeaderWithToken(token));
  }
  private getHeaderWithToken(token: string) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
  }
}

/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-spread */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-translated-documents',
  templateUrl: './translated-documents.component.html',
  styleUrls: ['./translated-documents.component.css'],
})
export class TranslatedDocumentsComponent implements OnInit {
  @Input() case: any;
  @Input() showSpinner: any;
  @Input() role: any;
  @Input() userId: any;
  @Input() caseDocumentType: any;
  @Output() uploadDocsEmiter = new EventEmitter<any>();

  uploader: FileUploader;
  token = localStorage.getItem('token');
  files = {};
  uploadingFiles = [];

  constructor() {}

  ngOnInit(): void {}

  checkCanDelete(createdBy: number): boolean {
    let canDelete = false;
    if (this.role === 'translator' && this.userId === createdBy) {
      canDelete = true;
    }
    return canDelete;
  }

  uploadDocs(event): void {
    event.preventDefault();
    this.uploadDocsEmiter.emit(event);
  }

  public emptyUploadIfEmpty(input: HTMLInputElement) {
    if (input.value == '') {
      this.uploader.clearQueue();
      input.value = null;
    }
  }
}

/* eslint-disable @typescript-eslint/explicit-member-accessibility */
export class EligibleMember {
    first_name: string;
    middle_name: string;
    last_name: string;
    dob: string;
    email: string;
    gender: string;
    company_name: string;
    phone_number: string;
    active: boolean;
    is_deleted: boolean;

    constructor() {
      this.first_name = '';
      this.middle_name = '';
      this.last_name = '';
      this.dob = '';
      this.company_name = '';
      this.email = '';
      this.gender = '';
      this.company_name = '';
      this.phone_number = '';
      this.active = false;
      this.is_deleted = false;
    }
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigAssetLoaderService } from '../../../services/config-asset-loader.service';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { CommonFuntions } from '../../../common/common.function';
import { MAIL_SERVER } from '../../../services/constant';
import { MailServerModel } from '../../../domains/MailServerModel';

declare let $: any;
@Component({
  selector: 'app-outgoing-mail-server',
  templateUrl: './outgoing-mail-server.component.html',
  styleUrls: ['./outgoing-mail-server.component.css']
})
export class OutgoingMailServerComponent implements OnInit {
  searchValue: string;
  totalMailServerCount: number;
  errorString: string;
  
  @ViewChild(DataTableDirective, { static: true })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private loginService: LoginService,
    private router: Router
  ) { }


  getColumnList(): any[] {
    return [
      {
        title: 'Priority',
        data: 'sequence',
        render: $.fn.dataTable.render.text()
      },
      {
        title: 'Name',
        data: 'name',
        render: $.fn.dataTable.render.text()
      },
      {
        title: 'Email Service',
        data: 'displayEmailService',
        render: $.fn.dataTable.render.text()
      },
      {
        title: 'Smtp User',
        data: 'smtp_user',
        render: $.fn.dataTable.render.text()
      },
      {
        title: 'Smtp Encryption',
        data: 'displaySmtpEncryption',
        render: $.fn.dataTable.render.text()
      },
      {
        title: 'Active',
        data: 'displayActive',
        render: $.fn.dataTable.render.text()
      },
      {
        title: 'Actions',
        data: null,
        orderable: false,
        render: (_data, _type, _full) => {
          return '<button id="editMailServer" class="btn btn--green btn--mini" >Edit</button>';
        }
      }
    ];
  }

  setDatatableOption(): void {
    const URL = `${ConfigAssetLoaderService.getApiURL()}/${MAIL_SERVER.LIST}`;
    this.dtOptions = {
      ajax: {
        url: URL,
        dataSrc: function (json) {
          const { recordsTotal, data } = json;
          const model = data.map(eachItem => new MailServerModel(eachItem));
          this.totalMailServerCount = recordsTotal;
          return model;
        }.bind(this),
        type: 'GET',
        beforeSend: function (xhr) {
          if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
            xhr.abort();
            return;
          }

          this.errorString = null;
          const token = localStorage.getItem('token');
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }.bind(this),
        error: this.datatablesError.bind(this)
      },
      pagingType: 'full_numbers',
      serverSide: true,
      processing: true,
      lengthChange: false,
      stateSave: true,
      searching: true,
      pageLength: 10,
      order: [[0, 'ASC']],
      info: false,
      columns: this.getColumnList(),
      rowCallback: (row: Node, data: MailServerModel, _index: number) => {
        // Unbind first in order to avoid duplicate handler
        $('td', row).unbind('click');
        $('td', row).bind('click', () => {
          this.router.navigate(['settings/outgoing-mail-server/', data.id]);
        });

        /** Handle edit button click
         * Navigate to the outgoing mail server edit
         */
        $('#editMailServer', row).on('click', () => {
          this.router.navigate(['settings/outgoing-mail-server/edit/', data.id]);
        });
      }
    };
  }

  ngOnInit(): void {
    this.setDatatableOption();
  }

  search(value: string): void {
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => {
        dtInstance.search(value).draw();
      }
    );
  }

  searchString(value: string) {
    this.search(value);
  }

  datatablesError(xhr, _error, _thrown): void {
    const errorStatus = xhr.status;
    if (errorStatus == 401) {
      this.router.navigate(['/login']);
    }
    else if (errorStatus == 404) {
      this.errorString = 'Invalid Url';
    }
    else {
      this.errorString = 'Problem occurred. Please contact your administrator';
    }
  }

}

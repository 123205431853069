/* eslint-disable @typescript-eslint/explicit-member-accessibility */
export class CalenderSelectedModel {

    date: string;
    remarks: string;

    constructor() {
      this.date = '';
      this.remarks = '';
    }

}

/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-spread */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-follow-up-questions',
  templateUrl: './follow-up-questions.component.html',
  styleUrls: ['./follow-up-questions.component.css'],
})
export class FollowUpQuestionsComponent implements OnInit {
  @Input() case_questions: any;
  @Input() maxFollowUpId: any;
  @Input() isFollowUpQuestionCollapse: any;

  constructor() {}

  ngOnInit(): void {}

  getFollowUpQuestions() {
    const followUpQuestions = [];
    this.case_questions.forEach((obj) => {
      if (this.maxFollowUpId == obj.follow_up_id) followUpQuestions.push(obj);
    });
    return followUpQuestions;
  }
}

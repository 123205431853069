<div class="box box--white box--no-pad box--radius" [ngClass]="role==='translator'? 'top20': ''">
	<div class="box__title case-section-box align-middle"
		*ngIf="role==='admin' || role==='careteam' || role==='casecoordinator'|| role==='mdmanager' || role==='cmmanager' || role==='partner' || role==='patient'">
		<div class="box-title-left">
			<div class="left-title">Case Messages</div>
			<span *ngIf="combinedActivitiesLength()" class="left-status">Last Updated
				{{finalHistory[0]?.updated_at | date: 'MM/dd/yyyy,h:mma'}}</span>
		</div>
		<div class="box-title-right">
			<span class="boxDiv2">
				<a href="javascript:void(0)" class="go-back pull-right collapseToggle"
					[ngClass]="{collapseToggleActive: isHistoryCollapse===true}"
					(click)="isHistoryCollapse = !isHistoryCollapse">
					<span class="icon icon--time-nav"></span>
				</a>
			</span>
		</div>
	</div>
	<div class="box__title case-section-box align-middle" *ngIf="role==='doctor' || role==='translator'">
		<div class="box-title-left">
			<div class="left-title">Messages</div>
		</div>
	</div>
	<div [hidden]="!isHistoryCollapse">
		<form [formGroup]="frmAssignee">
			<div class="document__content" *ngIf="!isSFLogin()">
				<div *ngIf="showToField()">

					<div class="form__row">
						<label for="" class="form__label">TO</label>
						<div class="form__field is-sarch-input">
							<input type="text" ngui-auto-complete [source]="assigneeList"
								[list-formatter]="suggestionListFormatter"
								[value-formatter]="valueListFormatter.bind(this)" [re-focus-after-select]="false"
								#pat />
							<div class="message message--is-invalid"
								*ngIf="user_case_messages.invalid && validateAssignee">
								Atleast one assignee should be added.
							</div>
							<div class="search-list">
								<div class="search-list" formArrayName="user_case_messages">
									<div class="search-item"
										*ngFor="let user of frmAssignee.get('user_case_messages').controls">
										{{user.value.full_name}}
										<span (click)="clearAssignee(user)">X</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--  -->
				<div class="form__row">
					<div class="d-flex title">
						<label for="" class="form__label">Title</label>
						<!-- <div class="d-flex align-items-center" *ngIf="role.toLowerCase() !== 'doctor' && isAllyCase">
							<div class="form__label m-1">
								<label for="for_ally">Send To Ally</label>
							</div>
							<div>
								<input type="checkbox" formControlName="for_ally" name="for_ally" id="for_ally">
							</div>
						</div> -->
					</div>
					<div class="form__field">
						<input type="text" name="title" id="title" formControlName="title">
						<div *ngIf="showError && frmAssignee.get('title').touched 
								&& frmAssignee.get('title').invalid" class="message message--is-invalid">
							<div *ngIf="frmAssignee.get('title').errors.required">Title cannot be empty.</div>
						</div>
					</div>
				</div>
				<div class="form__row">
					<label for="" class="form__label">Message</label>
					<div class="form__field">
						<!-- <ckeditor formControlName="message" [readonly]="isMdManager" placeholder="Add your comment"
							#ckeditorField>
						</ckeditor> -->
						<ckeditor formControlName="message" [readonly]="isMdManager" placeholder="Add your comment" class="customheader"
						#ckeditorField [config]="{toolbar: []}"></ckeditor>
						<div *ngIf="showError && frmAssignee.get('message').touched 
								&& frmAssignee.get('message').invalid" class="message message--is-invalid">
							<div *ngIf="frmAssignee.get('message').errors.maxlength">This field cannot exceed more than
								{{frmAssignee.get('message').errors.maxlength.requiredLength}} characters.</div>
							<div *ngIf="frmAssignee.get('message').errors.required">Comments cannot be empty.</div>
						</div>
					</div>
				</div>
				<!-- <div class="form__row">
					<div class="d-flex title">
						<label for="" class="form__label">Chatter Identifer</label>
					</div>
					<div class="form__field">
						<input type="text" name="chatter_identifier" id="chatter_identifier" formControlName="chatter_identifier">
						<div *ngIf="showError && frmAssignee.get('chatter_identifier').touched 
								&& frmAssignee.get('chatter_identifier').invalid" class="message message--is-invalid">
							<div *ngIf="frmAssignee.get('chatter_identifier').errors.required">Chatter Id cannot be empty.</div>
						</div>
					</div>
				</div> -->

				<div class="btns-row text-right">
					<app-generic-button [isBlue]="true" (onClick)="postComment($event)" *ngIf="!isMdManager">Submit</app-generic-button>
				</div>
			</div>
			<div class="box box--white box--no-pad box--radius d-flex align-items-center"
				*ngIf="combinedActivitiesLength() && filterVisible">
				<div class="box__title box--no-pad">
					<p>Filter</p>
				</div>
				<div class="file-type-select form__field fix-full-width">
					<select name="filter" [ngModel]="filter" [ngModelOptions]="{standalone: true}"
						(ngModelChange)="handleFilterChange($event)">
						<ng-container *ngFor="let option of getFilterOptions();">
							<option [value]="option">
								{{option}}</option>
						</ng-container>
					</select>
				</div>
			</div>

			<div class="comment-steps">
				<div class="shimmer-card" *ngIf="messageReceiving">
					<div class="p-32">
						<div class="shimmer-container">
							<div class="shimmerBG img-avatar"></div>
							<div class="shimmer-content">
								<div class="shimmerBG title-line end"></div>
								<div class="shimmerBG content-line m-t-24"></div>
								<div class="shimmerBG content-line"></div>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="loader" *ngIf="messageReceiving">
					<div class="loader__element"></div>
				</div> -->
				<!-- <div class="dotsContainer" *ngIf="messageReceiving">
					<span id="dot1"></span>
					<span id="dot2"></span>
					<span id="dot3"></span>
				</div> -->

				<!-- Activities section -->
				<div *ngIf="filteredActivities.length > 0;else noContent">
					<div class="comment-steps__row" *ngFor="let activity of filteredActivities">
						<div class="hr"></div>
						<app-activitycard [activity]="activity" [isElevatedPCT]="isElevatedPartnerCheck()" [messageThread]="messageThread" (messageThreadObj)="postComment($event)" [caseId]="caseId" (resetMessages)="resetMessages($event)"
							[images]="userAvatar" *ngIf="activity"></app-activitycard>
					</div>
				</div>
				<ng-template #noContent>
					<div *ngIf="filterVisible" class="comment-steps-row p-3">
						Data not available with current filter.
					</div>
				</ng-template>

				<!-- Legacy Case History -->
				<div *ngIf="false">
					<div class="activities box--white box__title mt-4"
						*ngIf="role==='admin' || role==='careteam' || role ==='casecoordinator' || role==='cmmanager' || (role==='partner' && caseDetail?.patient?.user?.user_partners[0]?.partner.isElevatedPartner)">
					</div>
					<div class="activities box--white box__title"
						*ngIf="role==='admin' || role==='careteam' || role ==='casecoordinator' ||  role==='cmmanager' || (role==='partner' && caseDetail?.patient?.user?.user_partners[0]?.partner.isElevatedPartner)">
						Old Logs
					</div>
					<div class="comment-steps__row" *ngFor="let finishedCase of finalHistory">
						<div class="comment-steps__heading comment-steps__heading--brown"
							*ngIf="finishedCase?.isHold; else notHold">
							<div class="step-icon">
								<span class="icon icon--green-tick-white-bg"></span>
							</div>
							<div class="step-title">Consultation - Put on Hold</div>
							<br />
							<div class="step-time">Last Updated {{finishedCase?.updated_at | date: 'MM/dd/yyyy, h:mm a'
								}}
							</div>
						</div>
						<ng-template #notHold>
							<div class="comment-steps__heading comment-steps__heading--green"
								*ngIf="finishedCase && !finishedCase?.isComment; else commentView">
								<div class="step-icon">
									<span class="icon icon--green-tick-white-bg"></span>
								</div>
								<div class="step-title" *ngIf="finishedCase?.getStatus() == 'Requested'">Consultation -
									Accepted
								</div>
								<div class="step-title" *ngIf="finishedCase?.getStatus() != 'Requested'">
									<span *ngIf="finishedCase?.status_id != 100">
										{{finishedCase?.getStatus()}} - Finished by
										{{finishedCase?.getAsigneeFullName()}}
									</span>
									<span *ngIf="finishedCase?.status_id === 100">
										<span *ngIf="finishedCase?.state == 'SET VIP'">
											Case Priority Changed From Normal To VIP
										</span>
										<span *ngIf="finishedCase?.state == 'UNSET VIP'">
											Case Priority Changed From VIP To Normal
										</span>
										- Changed by
										{{finishedCase?.getAsigneeFullName()}}
									</span>
								</div>
								<br />
								<div class="step-time">Last Updated {{finishedCase?.updated_at | date: 'MM/dd/yyyy, h:mm
									a'}}
								</div>
							</div>
							<ng-template #commentView>
								<div class="message-area">
									<div class="response">
										<div class="response__by">
											<div class="thumb thumb--small">
												<img *ngIf="(!userAvatar.hasOwnProperty(finishedCase?.sender_user_id) || finishedCase?.avatar.length==35); else showUserAvatar"
													[src]="profilePicPath" />
												<ng-template #showUserAvatar>
													<img *ngIf="finishedCase?.avatar.length > 100"
														[src]="'data:image/jpg;base64,'+finishedCase?.avatar" />
													<img *ngIf="finishedCase?.avatar.length < 100"
														[src]="'data:image/jpg;base64,'+ userAvatar[finishedCase?.sender_user_id]" />
												</ng-template>
											</div>
										</div>
										<div class="response__details">
											<div class="name-time"
												*ngIf="finishedCase?.user_type.toLowerCase()!=='patient'; else patientComment">
												{{finishedCase?.sender.includes('(') ? finishedCase.sender :
												(finishedCase.sender | firstString)}} to
												{{finishedCase?.getRevieverName(caseDetail?.patient?.user?.user_partners[0]?.partner.isElevatedPartner)}}
												<span class="time">{{finishedCase?.created_at | date: 'MM/dd/yyyy, h:mm
													a'}}</span>
											</div>
											<ng-template #patientComment>
												<div class="name-time">{{finishedCase?.sender | firstString}}
													<span class="time">{{finishedCase?.created_at | date: 'MM/dd/yyyy,
														h:mm
														a'}}</span>
												</div>
											</ng-template>
											<div class="the-message" [innerHTML]="finishedCase?.comment | safeHtml">
											</div>
										</div>
									</div>
								</div>
							</ng-template>
						</ng-template>
					</div>
				</div>
			</div>
		</form>
	</div>

</div>
<app-dialog></app-dialog>

<div class="header">
  <div class="header__page-name">
    <h1 class="heading heading--main">Release Settings</h1>
  </div>
</div>

<div class="wrapper--dash-content dash-content">
  <div class="tabs__content">

    <div class="form tabs__content--white-bg">
        <div class="row">
            <div class="contact-details no-border no-pad no-mar">
                <h3 class="settingTitle">1. Release Email Settings</h3>
                <div class="contact-details__row">
                    <div class="contact-details__label">You can configure your release settings here</div>
                    <div class="contact-details__details">
                        <button [routerLink]="['/settings/release-email']" class="btn btn--blue btn--mini">Link</button>
                    </div>
                </div>
            </div>
            <div class="hr hr--space-down-2x"></div>
        </div>
        <div class="row">
            <div class="contact-details no-border no-pad no-mar">
                <h3 class="settingTitle">2. Release Notice Settings</h3>
                <div class="contact-details__row">
                    <div class="contact-details__label">You can configure release notice settings here.</div>
                    <div class="contact-details__details">
                        <button [routerLink]="['/settings/release-notice']" class="btn btn--blue btn--mini">Link</button>
                    </div>
                </div>
            </div>
            <div class="hr hr--space-down-2x"></div>
        </div>
     
    </div>
  </div>
</div>

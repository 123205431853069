/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit, EventEmitter, Output, Input, HostListener} from '@angular/core';
import { Router, UrlTree, UrlSegmentGroup, UrlSegment, PRIMARY_OUTLET } from '@angular/router';
import { PartnersModel } from '../../../domains/partnersModel';
import { LoginService } from '../../../services/login.service';

@Component({
  selector: 'app-top-menu-partner',
  templateUrl: './top-menu-partner.component.html',
  styleUrls: ['./top-menu-partner.component.css']
})
export class TopMenuPartnerComponent implements OnInit {

  title: string;
  operation: string;
  partnerModel = new PartnersModel();
  showActivePartner: boolean;
  searchValue = '';

  count: number;

  @Input('activePartner')
  activePartner: number

  @Input('PARTNER_STATUS')
  PARTNER_STATUS: any

  @Output()
  searchPartnerEmitter = new EventEmitter<string>();

  @Output()
  partnerTypeFilter =  new EventEmitter<string>()

  @HostListener('change', ['$event'])
  onClick(event) {
    event.preventDefault();
    this.partnerTypeFilter.emit(event.target.value);
  }

  /**
  * stores payload data form jwt token
  */
  tokenPayLoad: any;

  userRole: string

  constructor(
    public router: Router,
    private loginService: LoginService
  ) {

  }

  callSearchPartner() {
    this.searchPartnerEmitter.emit(this.searchValue);
  }

  ngOnInit() {
    this.count=0;
    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const s: UrlSegment[] = g.segments;

    this.showActivePartner=false;
    this.title = 'Clients';

    const currentUser = this.loginService.getCurrentUser();
    this.userRole = currentUser.user_type.type;
    
  }

}

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-spread */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */

import { takeWhile } from 'rxjs/operators';
import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';

import { ConfigAssetLoaderService } from '../../services/config-asset-loader.service';
import { LoginService } from '../../services/login.service';
import { DatashareService } from '../../services/datashare.service';

import * as moment from 'moment/moment';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { AfterViewInit, OnDestroy } from '@angular/core';
import { CommonFuntions } from '../../common/common.function';
import { BehaviorSubject, Subject } from 'rxjs';
import { CaseService } from '../../services/case.service';
import { display } from './display-status';
import { SEARCH_OPTIONS } from './constant';
import { CLEAN_STRING } from '../../../app/constant';

@Component({
  selector: 'app-translator-caselist',
  templateUrl: './translator-caselist.component.html',
  styleUrls: ['./translator-caselist.component.css']
})
export class TranslatorCaselistComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(DataTableDirective)
  datatableElement: QueryList<DataTableDirective>;
  dtOptions1: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  activeCases = 0;
  searchValue = '';
  clearSearch(): void{
    this.searchColumn={
      column: '',
      value: ''
    };
    this.searchColumnName = '';
    this.searchColumnValue = '';
    this.search('');

  }
  role: string;
  columnOptions = {};
  selectedSearchOption: '';
  searchColumn = {column: '',value: ''};
  searchColumnName = '';
  searchColumnValue='';

  user: any
  user_type_id = 0;
  tabs: Subject<string> = new BehaviorSubject(null)
  isInprogress: boolean;
  private alive: boolean

  statusList: any[]
  statusFilter: any[]

  filterByCaseStatus: string;

  constructor(
    public loginService: LoginService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public caseService: CaseService,
    public dataShare: DatashareService
  ) { }

  ngOnInit(): void {
    this.alive = true;
    this.isInprogress = true;
    this.statusFilter = [];
    this.user = this.loginService.getCurrentUser();
    if (!this.user) {
      throw new Error('No user logged in');
    }

    if (this.user) {
      this.role = this.user.user_type.type;

      this.initTable();
    }

    this.dtOptions1 = this.getDtOption(null);
    this.columnOptions = (SEARCH_OPTIONS[`${this.role}`]);
  }

  ngAfterViewInit(): void {
    const registeredList = {
      current: 1,
      previous: 2
    };

    this.activatedRoute.queryParams.pipe(takeWhile(() => this.alive)).subscribe(
      function (params) {
        setTimeout(() => {
          if (!params['list']) {
            this.tabs.next('current');
            this.filterByCaseStatus = 'open';
          }
          else {
            if (registeredList[params['list']]) {
              this.tabs.next(params['list']);
              this.filterByCaseStatus = params['list'] === 'current'? 'open' : 'finished';
            }
            else {
              this.router.navigate(['/page-not-found']);
            }
          }
        });
      }.bind(this)
    );

    this.clearSearch();

  }

  ngOnDestroy(): void {
    this.alive = false;
    const now = moment().add('10', 'm');
    const timestamp = now.unix();

    const stateListSaveObject = {};
    stateListSaveObject['stateFilter'] = this.statusList;
    stateListSaveObject['time'] = timestamp;
    stateListSaveObject['userType'] = this.role;

    localStorage.setItem('statusFilterSaveState', JSON.stringify(stateListSaveObject));
  }

  getDtOption(list: string) {
    const user = this.user;
    const url = ConfigAssetLoaderService.getApiURL() + '/v1/cases';

    const option = {
      ajax: {
        url: url,
        dataSrc: function (json) {
          if (!list) {
            this.activeCases = json.recordsTotal;
          }
          return json.data;
        }.bind(this),
        type: 'POST',
        data: function (d) {
          const statusArr = [];
          this.statusFilter.forEach(element => {
            const item = {
              status: element.status,
              status_id: element.id
            };
            if(element.type){ item['type'] = element.type; }
            if(element.action){ item['action'] = element.action; }
            if(element.submitted !== undefined){ item['is_submitted'] = element.submitted; }
            if(element.saved !== undefined){ item['is_save'] = element.saved; }
            statusArr.push(item);
          });
          d.statusfilter = statusArr;
          d.status = this.filterByCaseStatus;
        }.bind(this),
        beforeSend: function (xhr) {
          if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
            xhr.abort();
            return;
          }
          this.errorString = null;
          const token = localStorage.getItem('token');
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }.bind(this)
      },
      pagingType: 'full_numbers',
      serverSide: true,
      processing: true,
      lengthChange: false,
      searching: true,
      pageLength: 10,
      stateSave: true,
      searchDelay: 350,
      stateDuration: 5 * 20,
      order: [[0, 'ASC']],
      info: false,
      language: {
        emptyTable: 'No cases to show'
      },
      rowCallback: this.handleEvent.bind(this)
    };
    if (user.user_type.type.toLowerCase() === 'admin' || user.user_type.type.toLowerCase() === 'careteam' || user.user_type.type.toLowerCase() === 'casecoordinator' || user.user_type.type.toLowerCase() === 'mdmanager' || user.user_type.type.toLowerCase() === 'cmmanager') {
      this.user_type_id = 1;
      option['columns'] = [
        {
          title: 'Stage',
          render: function (_data, _type, row) {

            const updatedMoment = moment(row.updated_at);
            const updated_at_date = updatedMoment.format('MM/DD/YYYY');

            const displayStatus = display(row.status, row.case_status, user.user_type.type.toLowerCase());
            console.log(displayStatus);
            
            let classStatus = null;

            switch (displayStatus.toLowerCase()) {
            case 'requested': {
              classStatus = 'stage-color--requested';
              break;
            }
            case 'mdmgnt': {
              classStatus = 'stage-color--md-mgmt';
              break;
            }
            case 'compilemr': {
              classStatus = 'stage-color--compile-mr';
              break;
            }
            case 'schedule': {
              classStatus = 'stage-color--schdeule';
              break;
            }
            case 'video': {
              classStatus = 'stage-color--video';
              break;
            }
            case 'summary': {
              classStatus = 'stage-color--summary';
              break;
            }
            case 'translatedr': {
              classStatus = 'stage-color--translate-dr';
              break;
            }
            case 'report': {
              classStatus = 'stage-color--report';
              break;
            }
            case 'hold': {
              classStatus = 'text--red text--uppercase';
              break;
            }
            case 'cancelled': {
              classStatus = 'text--red text--uppercase';
              break;
            }
            case 'finished': {
              classStatus = 'stage-color--finished';
              break;
            }
            default: {
              classStatus = '';
              break;
            }
            }

            let cell;
            if (status == null) {
              cell = '<div class="text ' + classStatus + ' text--bold">  </div> <div class="text text--dark-grey"></div>';
            }
            else {
              cell = '<div class="text ' + classStatus + ' text--bold">' + displayStatus + '</div> <div class="text text--dark-grey">  Last updated on ' + updated_at_date + '</div>';
            }
            return cell;
          }
        },
        {
          title: 'Type',
          render: function (_data, _type, row) {
            let vipElement = '';
            if (row.is_vip) {
              vipElement = ' <span class=\'badge-small badge-secondary\'>VIP</span>';
            }

            // if ally case
            if (row.allyCaseRef || row.allyCaseNumber) {
              vipElement += ' <span class=\'badge-small badge-yellow\'>Ally</span>';
            }

            if((row.created_at <= ConfigAssetLoaderService.getREODeploymentTime()) && (row.case_status === 'REQUESTED')) {
              vipElement += ' <span class=\'badge-small badge-green\'>Pre-SF</span>';
            }

            if(row.speciality_program && row.speciality_program.includes('Peer to Peer') 
              || (row.reason && row.reason.reason.includes('Peer to Peer'))) {
              vipElement += ' <span class=\'badge-small badge-orange\'>P2P</span>';
            }

            let cell = '';
            if (row.case_type === 'WRITTEN CONSULTATION') {
              if (row.case_documents && row.case_documents.length > 0) {

                const consultationSummary = row.case_documents.find(each => each.document_type === 'CONSULTATION SUMMARY');

                if (consultationSummary != null) {
                  const consultationMoment = moment(consultationSummary.created_at);
                  const consultationValue = consultationMoment.isValid() ? consultationMoment.format('MM/DD/YYYY') + ' at ' + consultationMoment.format('hh:mm a') : consultationSummary.created_at;
                  cell = '<div class="text">' + row.case_type + ' ' + vipElement + '</div> <div class="text text--dark-grey"> ' + consultationValue + ' </div>';
                }
                else {
                  cell = '<div class="text">' + row.case_type + ' ' + vipElement + '</div>';
                }
              }
              else {
                cell = '<div class="text">' + row.case_type + ' ' + vipElement + '</div>';
              }
            }
            else {
              if (!row.schedule || row.schedule == null) {
                return '<div class="text">' + row.case_type + ' ' + vipElement + '</div>';
              }

              const consultation_date = (row.schedule.consultation_date_time && row.schedule.consultation_date_time != null) ? row.schedule.consultation_date_time : '';
              const consultationMoment = moment(consultation_date);
              const consultationValue = consultationMoment.isValid() ? consultationMoment.format('MM/DD/YYYY') + ' at ' + consultationMoment.format('hh:mm a') : consultation_date;
              cell = '<div class="text">' + row.case_type + ' ' + vipElement + '</div> <div class="text text--dark-grey"> ' + consultationValue + ' </div>';
            }
            return cell;
          }
        },
        {
          title: 'Patient',
          render: function (_data, type, row, meta) {
            const patientInfo = row.patient_info ? JSON.parse(row.patient_info) : {} || {};
            const year = moment().diff(moment(patientInfo.dob), 'years') || 0;

            let patientName = row.patient_name || '';
            const sanitize = $.fn.dataTable.render.text();

            if (typeof sanitize.display === 'function') {
              patientName = sanitize.display(patientName, type, row, meta);
            }
            else {
              patientName = '';
            }
            const cell = '<div class="text text--bold">' + patientName + '</div>\
                  <div class="text text--dark-grey">' + year + ' ' + (patientInfo.gender || '') + '</div>';
            return cell;
          }
        },
        {
          title: 'Client',
          render: function (_data, type, row, meta) {
            let partner = '';
            if (row.patient && row.patient.user && row.patient.user.user_partners && row.patient.user.user_partners.length > 0) {
              partner = row.patient.user.user_partners.map(up=>up.partner.partner_name).toString();
            } 
            else if (row.partner_name) {
              partner = row.partner_name.toString() || '';
            }
            
            const sanitizer = $.fn.dataTable.render.text();

            if (typeof sanitizer.display === 'function') {
              partner = sanitizer.display(partner, type, row, meta);
              const cell = '<div class="text text--bold">' + (partner.length > 15 ? partner.substring(0, 15) + '...' : partner) + '</div>';
              return cell;
            }
            else {
              return '';
            }
          }
        },
        {
          title: 'Specialist',
          render: function (_data, type, row, meta) {
            let doctor;
            if (!row.case_md_management || row.case_md_management == null) {
              doctor = 'None';
            }
            else {
              doctor = row.case_md_management.user? row.case_md_management.user.full_name : null;
              const sanitize = $.fn.dataTable.render.text();

              if (typeof sanitize.display === 'function') {
                doctor = sanitize.display(doctor, type, row, meta);
              }
              else {
                doctor = null;
              }

              if (doctor == null) {
                doctor = 'None';
              }
            }

            const doctorName = '<div class=\'text text--bold\'>' + doctor + '</div>';

            return doctorName;
          }
        },
        {
          title: 'Diagnosis',
          orderable: false,
          render: function (_data, type, row, meta) {
            if (row.patient_diagnosis) {
              const sanitize = $.fn.dataTable.render.text();
              if (typeof sanitize.display === 'function') {
                const patientDiagnosis = CLEAN_STRING(row.patient_diagnosis);
                if (patientDiagnosis.length > 20) {
                  return '<div class="text text--dark-grey">' + sanitize.display(patientDiagnosis.replace(/<[^>]+>/gm, '').substring(0, 20), type, row, meta) + '<span title="' + sanitize.display(patientDiagnosis.replace(/<[^>]+>/gm, ''), type, row, meta) + '">...</span></div>';
                }
                else {
                  return '<div class="text text--dark-grey">' + sanitize.display(patientDiagnosis.replace(/<[^>]+>/gm, ''), type, row, meta) + '</div>';
                }
              }
              else {
                return '';
              }
            }
            else {
              return '';
            }
          }
        },
        {
          title: 'Case ID',
          render: function (_data, _type, row) {
            const caseIdentifier = row.case_identifier;
            const created_at_date = moment(row.created_at).format('MM/DD/YYYY');
            const followUp = Math.max.apply(Math, row.status.map(status => status.follow_up_id));
            let cell = '<div class="text text--bold">' + caseIdentifier + '</div>  <div class="text text--dark-grey"> Created on ' + created_at_date + '</div>';
            if (followUp > 0) {
              cell += `<div class="text text--follow-up">+${followUp} followup</div>`;
            }
            return cell;
          }
        },
        {
          title: 'M',
          orderable: false,
          render: function (_data, _type, row) {
            let count = 0;
            let role = '';
            const jsonObj = JSON.parse(row.new_messages) || {};
           
            if (this.user && this.user.user_type) {
              role = this.user.user_type.type.toLowerCase();
            }

            if (jsonObj.user_messages && jsonObj.user_messages.length > 0 && this.user && jsonObj.user_messages.includes(this.user.user_id)) {
              count = 1;
            }
            else if (['partner'].includes(role) && jsonObj.requested && jsonObj.requested.partner){
              count = 1;
            }
            else if (['admin', 'careteam','casecoordinator', 'cmmanager','mdmanager'].includes(role) && jsonObj.requested && jsonObj.requested.careteam){
              count = 1;
            }

            if (count > 0) {
              return '<div class="message-icon"><span class="icon icon--message-green"></span></div>';
            }

            return '<div><span></span></div>';
          }.bind({
            user: this.loginService.getCurrentUser()
          })
        }
      ];
      option['order'] = [[0, 'asc']];
      return option;
    }
    else if (user.user_type.type.toLowerCase() === 'partner') {
      this.user_type_id = 3;
      option['columns'] = [
        {
          title: 'Patient',
          render: function (_data, type, row, meta) {
            const patientInfo = row.patient_info ? JSON.parse(row.patient_info) : {} || {};
            const year = moment().diff(moment(patientInfo.dob), 'years') || 0;

            let patientName = row.patient_name || '';
            const sanitize = $.fn.dataTable.render.text();

            if (typeof sanitize.display === 'function') {
              patientName = sanitize.display(patientName, type, row, meta);
            }
            else {
              patientName = '';
            }
            const cell = '<div class="text text--bold">' + patientName + '</div>\
                  <div class="text text--dark-grey">' + year + ' ' + (patientInfo.gender || '') + '</div>';
            return cell;
          }
        },
        {
          title: 'Stage',
          render: function (_data, _type, row) {
            let classStatus = '';
            const externalStatus = display(row.status, row.case_status, user.user_type.type.toLowerCase());

            switch (externalStatus.toLowerCase()) {
            case 'requested': {
              classStatus = 'stage-color--requested';
              break;
            }
            case 'mdmgnt': {
              classStatus = 'stage-color--md-mgmt';
              break;
            }
            case 'processing': {
              classStatus = 'stage-color--compile-mr';
              break;
            }
            case 'video': {
              classStatus = 'stage-color--video';
              break;
            }
            case 'reviewing': {
              classStatus = 'stage-color--summary';
              break;
            }
            case 'report': {
              classStatus = 'stage-color--report';
              break;
            }
            case 'finished': {
              classStatus = 'stage-color--finished';
              break;
            }
            case 'cancelled': {
              classStatus = 'text--red text--uppercase';
              break;
            }
            case 'hold': {
              classStatus = 'text--red text--uppercase';
              break;
            }
            default: {
              classStatus = '';
              break;
            }
            }

            const updatedMoment = moment(row.updated_at);
            const updated_at_date = updatedMoment.format('MM/DD/YYYY');

            let cell;
            if (status == null) {
              cell = '<div class="text ' + classStatus + ' text--bold"> </div> <div class="text text--dark-grey"></div>';
            }
            else {
              cell = '<div class="text ' + classStatus + ' text--bold">' + externalStatus + '</div> <div class="text text--dark-grey"> Last updated on ' + updated_at_date + '</div>';
            }
            return cell;
          }
        },
        {
          title: 'Type',
          render: function (_data, _type, row) {
            let vipElement = '';
            if (row.is_vip) {
              vipElement = ' <span class=\'badge-small badge-secondary\'>VIP</span>';
            }

            if (!row.schedule || row.schedule == null) {
              return '<div class="text">' + row.case_type + ' ' + vipElement + '</div> <div class="text text--dark-grey"></div>';
            }

            const consultation_date = (row.schedule.consultation_date_time && row.schedule.consultation_date_time != null) ? row.schedule.consultation_date_time : '';
            const consultationMoment = moment(consultation_date);
            const consultationValue = consultationMoment.isValid() ? consultationMoment.format('MM/DD/YYYY') + ' at ' + consultationMoment.format('hh:mm a') : consultation_date;
            const cell = '<div class="text">' + row.case_type + ' ' + vipElement + '</div> <div class="text text--dark-grey"> ' + consultationValue + ' </div>';
            return cell;
          }
        },
        {
          title: 'Date Case Submitted',
          render: function (_data, _type, row) {
            const created_at_date = moment(row.created_at).format('MM/DD/YYYY');
            const cell = '<div class="text text--bold">' + created_at_date + '</div>';
            return cell;
          }
        },
        {
          title: 'Diagnosis',
          orderable: false,
          render: function (_data, type, row, meta) {
            if (row.patient_diagnosis) {
              const sanitize = $.fn.dataTable.render.text();
              const patientDiagnosis = CLEAN_STRING(row.patient_diagnosis);
              if (typeof sanitize.display === 'function') {
                if (patientDiagnosis.length > 20) {
                  return '<div class="text text--dark-grey">' + sanitize.display(patientDiagnosis.replace(/<[^>]+>/gm, '').substring(0, 20), type, row, meta) + '<span title="' + sanitize.display(row.patient_diagnosis.replace(/<[^>]+>/gm, ''), type, row, meta) + '">...</span></div>';
                }
                else {
                  return '<div class="text text--dark-grey">' + sanitize.display(patientDiagnosis.replace(/<[^>]+>/gm, ''), type, row, meta) + '</div>';
                }
              }
              else {
                return '';
              }
            }
            else {
              return '';
            }
          }
        },
        {
          title: 'Case ID',
          render: function (_data, _type, row) {
            const caseIdentifier = row.case_identifier;
            const followUp = Math.max.apply(Math, row.status.map(status => status.follow_up_id));
            let cell = '<div class="text text--bold">' + caseIdentifier + '</div>';
            if (followUp > 0) {
              cell += `<div class="text text--follow-up">+${followUp} followup</div>`;
            }
            return cell;
          }
        },
        {
          title: 'M',
          orderable: false,
          render: function (_data, _type, row) {
            let count = 0;
            let role = '';
            const jsonObj = JSON.parse(row.new_messages) || {};
           
            if (this.user && this.user.user_type) {
              role = this.user.user_type.type.toLowerCase();
            }

            if (jsonObj.user_messages && jsonObj.user_messages.length > 0 && this.user && jsonObj.user_messages.includes(this.user.user_id)) {
              count = 1;
            }
            else if (['partner'].includes(role) && jsonObj.requested && jsonObj.requested.partner){
              count = 1;
            }
            else if (['admin', 'careteam','casecoordinator', 'cmmanager','mdmanager'].includes(role) && jsonObj.requested && jsonObj.requested.careteam){
              count = 1;
            }

            if (count > 0) {
              return '<div class="message-icon"><span class="icon icon--message-green"></span></div>';
            }

            return '<div><span></span></div>';
          }.bind({
            user: this.loginService.getCurrentUser()
          })
        }
      ];
      option['order'] = [[1, 'ASC']];
      return option;

    }
    else if (user.user_type.type.toLowerCase() === 'doctor') {
      this.user_type_id = 4;
      option['columns'] = [
        {
          title: 'Status',
          render: function (_data, _type, row) {
            let classStatus = '';

            const externalStatus = display(
              row.status,
              row.case_status,
              user.user_type.type.toLowerCase(),
              row.case_type,
              row.is_save,
              row.is_submitted
            );

            switch (externalStatus.toLowerCase()) {
            case 'hold': {
              classStatus = 'text--red text--uppercase';
              break;
            }
            case 'cancelled': {
              classStatus = 'text--red text--uppercase';
              break;
            }
            case 'requested': {
              classStatus = 'stage-color--requested';
              break;
            }
            case 'ready for review': {
              classStatus = 'stage-color--compile-mr';
              break;
            }
            case 'scheduled': {
              classStatus = 'stage-color--schdeule';
              break;
            }
            case 'report pending': {
              classStatus = 'stage-color--translate-dr';
              break;
            }
            case 'finished': {
              classStatus = 'stage-color--report';
              break;
            }
            case 'completed': {
              classStatus = 'stage-color--finished';
              break;
            }
            default: {
              classStatus = '';
              break;
            }
            }

            const updatedMoment = moment(row.updated_at);
            const updated_at_date = updatedMoment.format('MM/DD/YYYY');

            let cell;
            if (status == null) {
              cell = '<div class="text ' + classStatus + ' text--bold"></div> <div class="text text--dark-grey"></div>';
            }
            else {
              cell = '<div class="text ' + classStatus + ' text--bold">' + externalStatus + '</div> <div class="text text--dark-grey">Last updated on: ' + updated_at_date + '</div>';
            }
            return cell;
          }
        },
        {
          title: 'Type',
          render: function (_data, _type, row) {
            if (!row.schedule || row.schedule == null) {
              let element = '<div class="text">' + row.case_type + '</div> <div class="text text--dark-grey"></div>';

              if(row.speciality_program && row.speciality_program.includes('Peer to Peer') 
                || (row.reason && row.reason.reason.includes('Peer to Peer'))) {

                element += ' <span class=\'badge-small badge-orange\'>P2P</span>';
              }
              
              return element;
            }

            const consultation_date = (row.schedule.consultation_date_time && row.schedule.consultation_date_time != null) ? row.schedule.consultation_date_time : '';
            const consultationMoment = moment(consultation_date);
            const consultationValue = consultationMoment.isValid() ? consultationMoment.format('MM/DD/YYYY') + ' at ' + consultationMoment.format('hh:mm a') : consultation_date;
            const cell = '<div class="text">' + row.case_type + '</div> <div class="text text--dark-grey"> ' + consultationValue + ' </div>';
            return cell;
          }
        },
        {
          title: 'Patient',
          render: function (_data, type, row, meta) {
            const patientInfo = row.patient_info ? JSON.parse(row.patient_info) : {} || {};
            const year = moment().diff(moment(patientInfo.dob), 'years') || 0;

            let patientName = row.patient_name || '';
            const sanitize = $.fn.dataTable.render.text();

            if (typeof sanitize.display === 'function') {
              patientName = sanitize.display(patientName, type, row, meta);
            }
            else {
              patientName = '';
            }
            const cell = '<div class="text text--bold">' + patientName + '</div>\
                  <div class="text text--dark-grey">' + year + ' ' + (patientInfo.gender || '') + '</div>';
            return cell;
          }
        },
        {
          title: 'Diagnosis',
          orderable: false,
          render: function (_data, type, row, meta) {
            if (row.patient_diagnosis) {
              const sanitize = $.fn.dataTable.render.text();
              if (typeof sanitize.display === 'function') {
                const patientDiagnosis = CLEAN_STRING(row.patient_diagnosis);
                if (row.patient_diagnosis.length > 20) {
                  return '<div class="text text--dark-grey">' + sanitize.display(patientDiagnosis.replace(/<[^>]+>/gm, '').substring(0, 20), type, row, meta) + '<span title="' + sanitize.display(row.patient_diagnosis.replace(/<[^>]+>/gm, ''), type, row, meta) + '">...</span></div>';
                }
                else {
                  return '<div class="text text--dark-grey">' + sanitize.display(patientDiagnosis.replace(/<[^>]+>/gm, ''), type, row, meta) + '</div>';
                }
              }
              else {
                return '';
              }
            }
            else {
              return '';
            }
          }
        },
        {
          title: 'Case ID',
          render: function (_data, _type, row) {
            const followUp = Math.max.apply(Math, row.status.map(status => status.follow_up_id));
            let cell = '<div class=\'text text--red text--bold\'>' + row.case_identifier + '</div>';
            if (followUp > 0) {
              cell += `<div class="text text--follow-up">+${followUp} followup</div>`;
            }
            return cell;
          }
        },
        {
          title: 'M',
          orderable: false,
          render: function(_data, _type, row){
            let count = 0;
            let role = '';
            const jsonObj = JSON.parse(row.new_messages) || {};
           
            if (this.user && this.user.user_type) {
              role = this.user.user_type.type.toLowerCase();
            }

            if (jsonObj.user_messages && jsonObj.user_messages.length > 0 && this.user && jsonObj.user_messages.includes(this.user.user_id)) {
              count = 1;
            }
            else if (['partner'].includes(role) && jsonObj.requested && jsonObj.requested.partner){
              count = 1;
            }
            else if (['admin', 'careteam','casecoordinator', 'cmmanager','mdmanager'].includes(role) && jsonObj.requested && jsonObj.requested.careteam){
              count = 1;
            }

            if (count > 0) {
              return '<div class="message-icon"><span class="icon icon--message-green"></span></div>';
            }

            return '<div><span></span></div>';
          }.bind({
            user: this.loginService.getCurrentUser()
          })
        }
      ];
      option.order = [[0, 'ASC']];
      return option;
    }
    else if (user.user_type.type.toLowerCase() === 'translator'
      || user.user_type.type.toLowerCase() === 'interpreter') {
      this.user_type_id = 6;
      option['columns'] = [
        {
          title: 'Stage',
          render: function (_data, _type, row) {
            let classStatus = '';
            const externalStatus = display(row.status, row.case_status, user.user_type.type.toLowerCase());

            switch (externalStatus.toLowerCase()) {
            case 'mdmgnt': {
              classStatus = 'stage-color--md-mgmt';
              break;
            }
            case 'hold': {
              classStatus = 'text--red text--uppercase';
              break;
            }
            case 'processing': {
              classStatus = 'stage-color--compile-mr';
              break;
            }
            case 'requested': {
              classStatus = 'stage-color--requested';
              break;
            }
            case 'cancelled': {
              classStatus = 'text--red text--uppercase';
              break;
            }
            case 'ready': {
              classStatus = 'stage-color--video';
              break;
            }
            case 'reviewing': {
              classStatus = 'stage-color--translate-dr';
              break;
            }
            case 'report': {
              classStatus = 'stage-color--report';
              break;
            }
            case 'finished': {
              classStatus = 'stage-color--finished';
              break;
            }
            default: {
              classStatus = '';
              break;
            }
            }

            const updatedMoment = moment(row.updated_at);
            const updated_at_date = updatedMoment.format('MM/DD/YYYY');

            let cell;
            if (status == null) {
              cell = '<div class="text ' + classStatus + ' text--bold">  </div> <div class="text text--dark-grey"></div>';
            }
            else {
              cell = '<div class="text ' + classStatus + ' text--bold">' + externalStatus + '</div> <div class="text text--dark-grey">Last updated on: ' + updated_at_date + '</div>';
            }
            return cell;
          }
        },
        {
          title: 'Type',
          render: function (_data, _type, row) {
            let vipElement = '';
            if (row.is_vip) {
              vipElement = ' <span class=\'badge-small badge-secondary\'>VIP</span>';
            }

            if(row.speciality_program && row.speciality_program.includes('Peer to Peer') 
              || (row.reason && row.reason.reason.includes('Peer to Peer'))) {

              vipElement += ' <span class=\'badge-small badge-orange\'>P2P</span>';
            }

            const consultation_date = (row.consultation_date_time && row.consultation_date_time != null) ? row.consultation_date_time : '';
            const consultationMoment = moment(consultation_date);
            const consultationValue = consultationMoment.isValid() ? consultationMoment.format('MM/DD/YYYY') : consultation_date;
            const cell = '<div class="text">' + row.case_type + ' ' + vipElement + '</div> <div class="text text--dark-grey"> ' + consultationValue + ' </div>';
            return cell;
          }
        },
        {
          title: 'Patient',
          render: function (_data, type, row, meta) {
            const patientInfo = row.patient_info ? JSON.parse(row.patient_info) : {} || {};
            const year = moment().diff(moment(patientInfo.dob), 'years') || 0;

            let patientName = row.patient_name || '';
            const sanitize = $.fn.dataTable.render.text();

            if (typeof sanitize.display === 'function') {
              patientName = sanitize.display(patientName, type, row, meta);
            }
            else {
              patientName = '';
            }
            const cell = '<div class="text text--bold">' + patientName + '</div>\
                  <div class="text text--dark-grey">' + year + ' ' + (patientInfo.gender || '') + '</div>';
            return cell;
          }
        },
        {
          title: 'Date Case Submitted',
          render: function (_data, _type, row) {
            const created_at_date = moment(row.created_at).format('MM/DD/YYYY');
            const cell = '<div class="text text--bold">' + created_at_date + '</div>';
            return cell;
          }
        },
        {
          title: 'Specialist',
          render: function (_data, type, row, meta) {
            let doctor;
            if (!row.case_md_management || row.case_md_management == null) {
              doctor = 'None';
            }
            else {
              doctor = row?.case_md_management?.user?.full_name;
              const sanitize = $.fn.dataTable.render.text();

              if (typeof sanitize.display === 'function') {
                doctor = sanitize.display(doctor, type, row, meta);
              }
              else {
                doctor = null;
              }

              if (doctor == null) {
                doctor = 'None';
              }
            }

            const doctorName = '<div class=\'text text--bold\'>' + doctor + '</div>';

            return doctorName;
          }
        },
        {
          title: 'Diagnosis',
          orderable: false,
          render: function (_data, type, row, meta) {
            if (row.patient_diagnosis) {
              const sanitize = $.fn.dataTable.render.text();
              if (typeof sanitize.display === 'function') {
                const patientDiagnosis = CLEAN_STRING(row.patient_diagnosis);
                if (row.patient_diagnosis.length > 20) {
                  return '<div class="text text--dark-grey">' + sanitize.display(patientDiagnosis.replace(/<[^>]+>/gm, '').substring(0, 20), type, row, meta) + '<span title="' + sanitize.display(row.patient_diagnosis.replace(/<[^>]+>/gm, ''), type, row, meta) + '">...</span></div>';
                }
                else {
                  return '<div class="text text--dark-grey">' + sanitize.display(patientDiagnosis.replace(/<[^>]+>/gm, ''), type, row, meta) + '</div>';
                }
              }
              else {
                return '';
              }
            }
            else {
              return '';
            }
          }
        },
        {
          title: 'Case ID',
          render: function (_data, _type, row) {
            const caseIdentifier = row.case_identifier;
            const cell = '<div class="text text--bold">' + caseIdentifier + '</div>';
            return cell;
          }
        },
        {
          title: 'M',
          orderable: false,
          render: function (_data, _type, row) {
            let count = 0;
            let role = '';
            const jsonObj = JSON.parse(row.new_messages) || {};
           
            if (this.user && this.user.user_type) {
              role = this.user.user_type.type.toLowerCase();
            }

            if (jsonObj.user_messages && jsonObj.user_messages.length > 0 && this.user && jsonObj.user_messages.includes(this.user.user_id)) {
              count = 1;
            }
            else if (['partner'].includes(role) && jsonObj.requested && jsonObj.requested.partner){
              count = 1;
            }
            else if (['admin', 'careteam', 'casecoordinator', 'cmmanager','mdmanager'].includes(role) && jsonObj.requested && jsonObj.requested.careteam){
              count = 1;
            }

            if (count > 0) {
              return '<div class="message-icon"><span class="icon icon--message-green"></span></div>';
            }

            return '<div><span></span></div>';
          }.bind({
            user: this.loginService.getCurrentUser()
          })
        }
      ];
      option.order = [[0, 'ASC']];
      return option;

    }
    else {
      this.user_type_id = 5;
      option['columns'] = [
        {
          title: 'Stage',
          render: function (_data, _type, row) {

            let classStatus = '';
            const externalStatus = display(
              row.status,
              row.case_status,
              user.user_type.type.toLowerCase(),
              row.case_type
            );

            switch (externalStatus.toLowerCase()) {
            case 'collecting medical records': {
              classStatus = 'stage-color--requested';
              break;
            }
            case 'matching md': {
              classStatus = 'stage-color--md-mgmt';
              break;
            }
            case 'analyzing medical records': {
              classStatus = 'stage-color--compile-mr';
              break;
            }
            case 'scheduling': {
              classStatus = 'stage-color--schdeule';
              break;
            }
            case 'video consultation': {
              classStatus = 'stage-color--video';
              break;
            }
            case 'sent to md for review': {
              classStatus = 'stage-color--summary';
              break;
            }
            case 'report': {
              classStatus = 'stage-color--report';
              break;
            }
            case 'hold': {
              classStatus = 'text--red text--uppercase';
              break;
            }
            case 'cancelled': {
              classStatus = 'text--red text--uppercase';
              break;
            }
            case 'finished': {
              classStatus = 'stage-color--finished';
              break;
            }
            default: {
              classStatus = '';
              break;
            }
            }

            let cell;
            if (externalStatus == null) {
              cell = '<div class="text ' + classStatus + ' text--bold">  </div> <div class="text text--dark-grey"></div>';
            }
            else {
              cell = '<div class="text ' + classStatus + ' text--bold">' + externalStatus + '</div>';
            }
            return cell;
          }
        },
        {
          title: 'Type',
          render: function (_data, _type, row) {
            const cell = '<div class="text">' + row.case_type + '</div>';
            return cell;
          }
        },
        {
          title: 'Specialist',
          render: function (_data, type, row, meta) {

            if (!row.case_md_management
              || row.case_md_management == null
              || !row.case_md_management.user
              || row.case_md_management.user == null
              || !row.case_md_management.user.full_name || row.case_md_management.user.full_name == null) {
              return 'N/A';
            }
            const sanitize = $.fn.dataTable.render.text();

            if (typeof sanitize.display === 'function') {
              return sanitize.display(row.case_md_management.user.full_name, type, row, meta);
            }
            else {
              return '';
            }
          }
        },
        {
          title: 'Diagnosis',
          orderable: false,
          render: function (_data, type, row, meta) {
            if (row.patient_diagnosis) {
              const sanitize = $.fn.dataTable.render.text();
              if (typeof sanitize.display === 'function') {
                const patient_diagnosis = CLEAN_STRING(row.patient_diagnosis);
                if (patient_diagnosis.length > 20) {
                  let displayText = sanitize.display(patient_diagnosis.replace(/<[^>]+>/gm, '').substring(0, 20), type, row, meta);
                  displayText += '<span title="' + sanitize.display(patient_diagnosis.replace(/<[^>]+>/gm, ''), type, row, meta) + '">...</span>';
                  return '<div class="text text--dark-grey">' + displayText + '</div>';
                }
                else {
                  return '<div class="text text--dark-grey">' + sanitize.display(patient_diagnosis.replace(/<[^>]+>/gm, ''), type, row, meta) + '</div>';
                }
              }
              else {
                return '';
              }
            }
            else {
              return '';
            }
          }
        },
        {
          title: 'Consultation Date',
          render: function (_data, _type, row) {
            let consultation_date = null;
            if (row.schedule && row.schedule.consultation_date_time && row.schedule.consultation_date_time != null) {
              consultation_date = row.schedule.consultation_date_time;
            }
            else {
              consultation_date = 'N/A';
            }
            let cell = null;
            if (consultation_date === 'N/A') {
              cell = '<div class="text text--dark-grey"> ' + consultation_date + ' </div>';
              return cell;
            }

            const consultationMoment = moment(consultation_date);
            const formattedDateTime = consultationMoment.format('MM/DD/YYYY') + ' at ' + consultationMoment.format('hh:mm a');
            const consultationValue = consultationMoment.isValid() ? formattedDateTime : consultation_date;
            cell = '<div class="text text--dark-grey"> ' + consultationValue + ' </div>';
            return cell;
          }
        },
        {
          title: 'CaseID',
          render: function (_data, _type, row) {
            const followUp = Math.max.apply(Math, row.status.map(status => status.follow_up_id));
            let cell = '<div class=\'text text--bold\'>' + row.case_identifier + '</div>';
            if (followUp > 0) {
              cell += `<div class="text text--follow-up">+${followUp} followup</div>`;
            }
            return cell;
          }
        },
        {
          title: 'M',
          orderable: false,
          render: function (_data, _type, row) {
            let count = 0;
            let role = '';
            const jsonObj = JSON.parse(row.new_messages) || {};
           
            if (this.user && this.user.user_type) {
              role = this.user.user_type.type.toLowerCase();
            }

            if (jsonObj.user_messages && jsonObj.user_messages.length > 0 && this.user && jsonObj.user_messages.includes(this.user.user_id)) {
              count = 1;
            }
            else if (['partner'].includes(role) && jsonObj.requested && jsonObj.requested.partner){
              count = 1;
            }
            else if (['admin', 'careteam','casecoordinator', 'cmmanager','mdmanager'].includes(role) && jsonObj.requested && jsonObj.requested.careteam){
              count = 1;
            }

            if (count > 0) {
              return '<div class="message-icon"><span class="icon icon--message-green"></span></div>';
            }

            return '<div><span></span></div>';
          }.bind({
            user: this.loginService.getCurrentUser()
          })
        }
      ];
      option['order'] = [[0, 'asc']];
      return option;
    }
  }

  currentSearch(columnKey,columnValue,value){
    this.searchColumn.column = columnKey;
    this.searchColumn.value = value.toLowerCase();
    this.searchColumnName = columnValue;
    this.searchColumnValue = value;
    this.search(JSON.stringify(this.searchColumn));
  }

  handleEvent(row: Node, data: any[] | Object): void {
    // Unbind first in order to avoid duplicate handler
    $('td', row).unbind('click');
    $('td', row).bind('click', function () {
      if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
        return;
      }

      if (this.role.toLowerCase() === 'doctor') {
        this.router.navigate(['/case/doctor-consultation', data['id']]);
      }
      else if (this.role.toLowerCase() === 'interpreter') {
        this.router.navigate(['/case/doctor-consultation', data['id']]);
      }
      else {
        this.router.navigate(['/case', data['id']]);
      }
    }.bind(this));
  }


  search(search: string): void {
    if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
      return;
    }

    this.datatableElement.toArray()[0].dtInstance.then(
      (dtInstance: DataTables.Api) => {
        dtInstance.search(search).draw();
      }
    );
  }

  onCreateCase(role: string, event?: Event): void {
    if (event) { event.preventDefault(); }
    if (role === 'careteam' || role === 'partner' || role === 'cmmanager' || role === 'casecoordinator') {
      this.router.navigate(['/patient-role']);
    }
    else if (role === 'patient') {
      if (!this.user?.partners[0]?.partner?.active) {
        alert('Client is not active. Please contact administrator to create a case.');
        return;
      }
      this.router.navigate(['/case', 'create', this.user.user_id]);
    }
  }

  /**
   * It unchecks the status filter if the tab is in complete case
   * @param {string} status
   * @memberof TranslatorCaselistComponent
   */
  removeStatusFilterOnCompletedCase(status: string): void {
    if (status === 'previous') {
      this.statusFilter = [];
      this.statusList.forEach((each) => {
        each.checked = false;
      });
    }
  }

  changeTab(caseStatus: string, event?: Event): void {
    if (event) { event.preventDefault(); }

    localStorage.removeItem('DataTables_casetable_active_/case');

    this.removeStatusFilterOnCompletedCase(caseStatus);

    this.router.navigate(['/case'], { queryParams: { list: caseStatus } });
  }

  setSearchValueFromSavedState(): void {

    if (this.searchColumn.value == '') { return; }
    const searchValue = this.searchColumn.value;


    if (searchValue !== null) { this.searchValue = searchValue; }
  }

  setStatusFilterForHoldCancelled(status: string): void {
    if (['HOLD', 'CANCELLED'].includes(status) && this.statusFilter.length > 1) {
      this.statusFilter = this.statusFilter.filter(each => ['HOLD', 'CANCELLED'].includes(each.id));
      this.statusList = this.statusList.map((each) => {
        if (!['HOLD', 'CANCELLED'].includes(each.id)) {
          each.checked = false;
        }
        return each;
      });
    }

    if (!['HOLD', 'CANCELLED'].includes(status) && this.statusFilter.length > 1) {
      this.statusFilter = this.statusFilter.filter(each => !['HOLD', 'CANCELLED'].includes(each.id));
      this.statusList = this.statusList.map((each) => {
        if (['HOLD', 'CANCELLED'].includes(each.id)) {
          each.checked = false;
        }
        return each;
      });
    }
  }

  addRemoveStatusFilter(status: any, event?: Event): void {
    const checkbox: any = event.target;

    if (checkbox.checked) {
      const eachStatus = this.statusFilter.find(filterStatus => filterStatus.id === status.id);

      if (eachStatus == null) {
        const insertStatus = this.statusList.find(eachInsertStatus => eachInsertStatus.id === status.id);
        insertStatus.checked = true;
        this.statusFilter.push(insertStatus);
      }

      this.setStatusFilterForHoldCancelled(status.id);
    }
    else {
      const eachStatus = this.statusFilter.find(filterStatus => filterStatus.id === status.id);

      if (eachStatus != null) {

        const removeStatus = this.statusFilter.findIndex(eachRemoveStatus => eachRemoveStatus.id === status.id);

        this.statusFilter.splice(removeStatus, 1);
      }

      const insertStatus = this.statusList.find(eachInsertStatus => eachInsertStatus.id === status.id);

      insertStatus.checked = false;
    }

    const url = ConfigAssetLoaderService.getApiURL() + '/v1/cases';

    const tabAsString = (this.tabs as BehaviorSubject<string>).getValue();

    if (tabAsString == null || tabAsString.toLowerCase() === 'current') {
      this.filterByCaseStatus = 'open';
    }
    else {
      this.filterByCaseStatus = 'finished';
    }

    if (this.role.toLowerCase() !== 'admin' && this.role.toLowerCase() !== 'careteam' && this.role.toLowerCase() !== 'casecoordinator' && this.role.toLowerCase() !== 'mdmanager' && this.role.toLowerCase() !== 'cmmanager') {
      if (this.role.toLowerCase() === 'partner') {
        this.statusFilter = this.getStatusMapping(this.role, status);
      }
      else {
        this.statusFilter = this.getStatusMapping(this.role);
      }
    }

    this.datatableElement.toArray()[0].dtInstance.then(
      (dtInstance: DataTables.Api) => {
        // abort previous api call
        dtInstance.settings()[0].jqXHR.abort();
        dtInstance.ajax.url(url).load();
      }
    );
  }

  private getStatusMapping(userType: string, status?: any) {
    userType = userType.toLowerCase();

    let returnStatusMapping = [];

    switch (userType) {
    case 'doctor': {
      const statusFilterCopy = this.statusList.filter(eachStatusList => eachStatusList.checked);

      let requestedObject = statusFilterCopy.find(eachStatus => eachStatus.id === 'REQUESTED');

      if (requestedObject != null) {

        returnStatusMapping.push({
          id: 1,
          status: 'Requested'
        });

        returnStatusMapping.push({
          id: 2,
          status: 'MD Management'
        });

        returnStatusMapping.push({
          id: 3,
          status: 'Compile MR'
        });

      }
      else {
        this.statusFilter = this.statusFilter.filter(
          eachStatus => eachStatus.id !== 1
              && eachStatus.id !== 2
              && eachStatus.id !== 3
        );
      }

      const mrpendingObject = statusFilterCopy.find(eachStatus => eachStatus.id === 'READYFORREVIEW');

      if (mrpendingObject != null) {
        returnStatusMapping.push({
          status: 'READY_FOR_REVIEW'
        });
      }
      else {
        this.statusFilter = this.statusFilter.filter(
          eachStatus => eachStatus.id !== 6
        );
      }

      const readyforreviewObject = statusFilterCopy.find(eachStatus => eachStatus.id === 'SCHEDULED');

      if (readyforreviewObject != null) {

        returnStatusMapping.push({
          id: 7,
          status: 'Video'
        });

      }
      else {
        this.statusFilter = this.statusFilter.filter(
          eachStatus => eachStatus.id !== 7
        );
      }

      const reportPendingObject = statusFilterCopy.find(eachStatusList => eachStatusList.id === 'REPORTPENDING');

      if (reportPendingObject != null) {
        returnStatusMapping.push({
          status: 'REPORT_PENDING'
        });
      }
      else {
        this.statusFilter = this.statusFilter.filter(
          eachStatus => eachStatus.id !== 8
        );
      }

      const finishedObject = statusFilterCopy.find(eachStatusList => eachStatusList.id === 'FINISHED');

      if (finishedObject != null) {
        returnStatusMapping.push({
          status: 'FINISHED'
        });
      }
      else {
        this.statusFilter = this.statusFilter.filter(
          eachStatus => eachStatus.id !== 8
              && eachStatus.id !== 9
              && eachStatus.id !== 10
        );
      }

      requestedObject = statusFilterCopy.find(eachStatus => eachStatus.id === 'HOLD');

      if (requestedObject != null) {

        returnStatusMapping.push({
          id: 'HOLD',
          status: 'Hold'
        });

      }
      else {
        this.statusFilter = this.statusFilter.filter(
          eachStatus => eachStatus.id !== 'HOLD'
        );
      }

      break;
    }
    case 'partner': {
      if (status && (status.id === 'PROCESSING' || status.id === 'REVIEWING')) {
        let arrayToInsert = null;
        let index = -1;
        switch (status.id) {
        case 'PROCESSING': {
          index = this.statusFilter.findIndex(eachStatus => eachStatus.id === 'PROCESSING');

          if (index !== -1 && status.checked) {
            this.statusFilter.splice(index, 1);

            arrayToInsert = [
              {
                id: 3,
                status: 'Compile MR'
              },
              {
                id: 6,
                status: 'Schedule'
              }
            ];

            arrayToInsert.forEach(eachStatus => this.statusFilter.push(eachStatus));
          }
          else if (!status.checked) {
            this.statusFilter = this.statusFilter.filter(eachStatus =>
              eachStatus.id !== 3
                  && eachStatus.id !== 6);
          }
          break;
        }
        case 'REVIEWING': {
          index = this.statusFilter.findIndex(eachStatus => eachStatus.id === 'REVIEWING');

          if (index !== -1 && status.checked) {
            this.statusFilter.splice(index, 1);

            arrayToInsert = [
              {
                id: 8,
                status: 'Summary'
              },
              {
                id: 9,
                status: 'TranslateDR'
              }
            ];

            arrayToInsert.forEach(eachStatus => this.statusFilter.push(eachStatus));
          }
          else if (!status.checked) {
            this.statusFilter = this.statusFilter.filter(eachStatus =>
              eachStatus.id !== 8
                  && eachStatus.id !== 9);
          }
        }
        }

        returnStatusMapping = this.statusFilter;

      }
      else {
        returnStatusMapping = this.statusFilter;
      }
      break;
    }
    default: {
      break;
    }
    }

    return returnStatusMapping;
  }

  private initTable() {
    const savedStatusFilterString = localStorage.getItem('statusFilterSaveState');
    const savedStatusFilter = JSON.parse(savedStatusFilterString);

    if (savedStatusFilter == null) {
      this.statusList = this.caseService.getStatus(this.role);
    }
    else {
      const now = moment();
      const timestamp = now.unix();
      if ((this.role.toLowerCase() === 'careteam' && savedStatusFilter.userType.toLowerCase() === 'careteam')) {
        this.statusList = savedStatusFilter.stateFilter;
      }
      else if (savedStatusFilter.time < timestamp
        || savedStatusFilter.userType.toLowerCase() !== this.role.toLowerCase()) {
        this.statusList = this.caseService.getStatus(this.role);
      }
      else {
        this.statusList = savedStatusFilter.stateFilter;
      }
    }

    if (this.statusList != null && this.statusList.length > 0) {
      this.statusList.forEach(eachStatus => {
        if (!eachStatus.checked) {
          eachStatus['checked'] = false;
        }

        if (eachStatus.checked) {
          this.statusFilter.push(eachStatus);
        }

      });
    }

    if (this.role.toLowerCase() !== 'admin' && this.role.toLowerCase() !== 'careteam' && this.role.toLowerCase() !== 'casecoordinator' && this.role.toLowerCase() !== 'mdmanager' && this.role.toLowerCase() !== 'cmmanager') {
      let mappedStatus = [];

      if (this.statusList) {
        const checkedStatus = this.statusList.filter(eachStatus => eachStatus.checked);

        checkedStatus.forEach(eachStatus => {
          const status = this.getStatusMapping(this.role, eachStatus);

          mappedStatus = mappedStatus.concat(status);
        });
      }

      this.statusFilter = mappedStatus;
    }

    this.setSearchValueFromSavedState();

    this.tabs.pipe(takeWhile(() => this.alive)).subscribe(function (tab) {

      if (tab == null) { return; }

      const newOption = this.getDtOption(tab);

      this.datatableElement.toArray()[0].dtInstance.then(
        (dtInstance: DataTables.Api) => {
          dtInstance.ajax.url(newOption.ajax['url']);
          // abort previous api call
          dtInstance.settings()[0].jqXHR.abort();
          dtInstance.ajax.reload();
        }
      );

      this.isInprogress = false;
    }.bind(this));
  }
}

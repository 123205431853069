import { SharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule } from '@angular/common/http';
import { MailServerService } from '../services/mail-server.service';

import { LogoComponent } from './logo/logo.component';
import { ProfileComponent } from './profile/profile.component';
import { LeftmenuComponent } from './leftmenu/leftmenu.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppRoutingModule } from '../routers/app-routing.module';
import { UsersModule } from '../dashboard/users/users.module';
import { CalenderModule } from '../dashboard/calender/calender.module';

import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { CKEditorModule } from 'ng2-ckeditor';
import { AceEditorModule } from 'ng2-ace-editor';
// import { MentionModule } from 'angular-mentions';

import { UsersComponent } from './users/users.component';
import { SettingsComponent } from './settings/settings.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationTextComponent } from './notifications/notification-text/notification-text.component';
import { LogsComponent } from './logs/logs.component';
import { LogDetailsComponent } from './logs/log-details/log-details.component';
import { NewSettingsComponent } from './new-settings/new-settings.component';
import { OutgoingMailServerComponent } from './outgoing-mail-server/list/outgoing-mail-server.component';
import { OutgoingMailServerHeaderComponent } from './outgoing-mail-server/outgoing-mail-server-header/outgoing-mail-server-header.component';
import { NewOutgoingMailServerComponent } from './outgoing-mail-server/add/new-outgoing-mail-server.component';
import { EditOutgoingMailServerComponent } from './outgoing-mail-server/edit/edit-outgoing-mail-server.component';
import { ViewOutgoingMailServerComponent } from './outgoing-mail-server/view/view-outgoing-mail-server.component';
import { CaseListComponent } from './settings-case-list/list/case-list.component';
import { EditCaseComponent } from './settings-case-list/edit/edit-case.component';
import { ViewCaseComponent } from './settings-case-list/view/view-case.component';
import { CaseWrapperComponent } from './settings-case-list/case-wrapper/case-wrapper.component';
import { SettingListComponent } from './setting-list/setting-list.component';
import { EmailSettingsComponent } from './email-settings/email-settings.component';
import { ZoomSettingsComponent } from './zoom-settings/zoom-settings.component';
import { MailLoggerComponent } from './outgoing-mail-server/maillogs/mail-logs.component';
import { MailLogDetailsComponent } from './outgoing-mail-server/maillogs/mail-log-details/mail-log-details.component';
import { EmailTemplateComponent } from './email-template/list/email-template.component';
import { EditEmailTemplateComponent } from './email-template/edit/edit-email-template.component';
import { NewEmailTemplateComponent } from './email-template/add/new-email-template.component';
import { ViewEmailTemplateComponent } from './email-template/view/view-email-template.component';
import { EmailTemplateHeaderComponent } from './email-template/email-template-header/email-template-header.component';
import { EmailTemplateService } from '../services/email-template.service';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { ReportSettingsComponent } from './report-settings/report-settings.component';
import { CaseSettingsComponent } from './case-settings/case-settings.component';
import { ArchiveSettingsComponent } from './archive-settings/archive-settings.component';
import { ToastrModule } from 'ngx-toastr';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ReleaseSettingsComponent } from './release-settings/release-settings.component';
import { ReleaseEmailComponent } from './release-settings/release-email/release-email.component';
import { ReleaseNoticeComponent } from './release-settings/release-notice/release-notice.component';

@NgModule({
  imports: [
    CommonModule,
    DataTablesModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    UsersModule,
    CalenderModule,
    SharedModule,
    NguiAutoCompleteModule,
    AceEditorModule,
    CKEditorModule,
    // MentionModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ToastrModule,
    SweetAlert2Module
  ],
  declarations: [
    LogoComponent,
    ProfileComponent,
    LeftmenuComponent,
    DashboardComponent,
    UsersComponent,
    SettingsComponent,
    NotificationsComponent,
    NotificationTextComponent,
    LogsComponent,
    LogDetailsComponent,
    NewSettingsComponent,
    OutgoingMailServerComponent,
    OutgoingMailServerHeaderComponent,
    NewOutgoingMailServerComponent,
    EditOutgoingMailServerComponent,
    ViewOutgoingMailServerComponent,
    CaseListComponent,
    EditCaseComponent,
    ViewCaseComponent,
    CaseWrapperComponent,
    SettingListComponent,
    EmailSettingsComponent,
    ZoomSettingsComponent,
    MailLoggerComponent,
    MailLogDetailsComponent,
    EmailTemplateComponent,
    EditEmailTemplateComponent,
    NewEmailTemplateComponent,
    ViewEmailTemplateComponent,
    EmailTemplateHeaderComponent,
    ChangePasswordComponent,
    ReportSettingsComponent,
    CaseSettingsComponent,
    ArchiveSettingsComponent,
    ReleaseSettingsComponent,
    ReleaseEmailComponent,
    ReleaseNoticeComponent
  ],
  providers: [
    MailServerService,
    EmailTemplateService,
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-US' },
  ]
})
/**
 * Module for dashboard
 * 
 */
export class DashboardModule { }

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-new-settings',
  templateUrl: './new-settings.component.html',
  styleUrls: ['./new-settings.component.css']
})
export class NewSettingsComponent implements OnInit {
  user;
  userType: string;
  isInprogress = false;

  technicalList = {
    admin: [
      {
        label: 'Outgoing Mail Server',
        subLabel: 'Please set the mail server configuration from here',
        btnLink: '/settings/outgoing-mail-server'
      },
      {
        label: 'Cases',
        subLabel: 'You can view and edit case details here.',
        btnLink: '/technical/case'
      }
    ],
    cmmanager: [
      {
        label: 'Emails',
        subLabel: 'All the outgoing business emails are here.',
        btnLink: '/mail-logs'
      }
    ]
  }
  menuList;

  constructor(
    private loginService: LoginService
  ) { 
    this.user = this.loginService.getCurrentUser();
    this.userType = this.user?.user_type?.type.toLowerCase();
    this.menuList = this.technicalList[this.userType];
  }

  ngOnInit(): void {
  }

}

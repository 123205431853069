/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/naming-convention */

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ConfigAssetLoaderService } from '../services/config-asset-loader.service';
import { LoginService } from './login.service';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { PartnersModel } from '../domains/partnersModel';
import { PartnerDashboardModel } from '../domains/partnerDashboardModel';
import { CareTeamDashboardModel } from '../domains/careTeamDashboardModel';
import { PartnerCareTeamDashboardModel } from '../domains/partnerCareTeamDashboardModel';
import { CasePartnerModel } from '../domains/casePartnerModel';



@Injectable()
export class PartnerService {

  partnerMap = new Map();



  constructor(
    private http: HttpClient,
    private loginService: LoginService,
  ) { }

  createPartner(partner: PartnersModel): Observable<PartnersModel> {
    const fullUrl = ConfigAssetLoaderService.getApiURL() + '/' + 'partner/create';
    partner.created_by = this.loginService.getCurrentUser().user_id;

    const date = new DatePipe('en-US').transform(Date.now(), 'MM/dd/yyyy');
    partner.created_date = date;
    partner.start_date = partner.start_date.toLocaleString();
    partner.end_date = partner.end_date.toLocaleString();

    const finalResult = this.http.post<PartnersModel>(fullUrl,
      partner,
      this.getHeaderWithToken(localStorage.getItem('token'))
    );
    return finalResult;
  }

  updatePartner(partner: PartnersModel , isCodeValueChanged: boolean, isEmailDomainChanged: boolean) {

    const fullUrl = ConfigAssetLoaderService.getApiURL() + '/' + 'partner/edit/' + partner.partner_id;

    partner.start_date = partner.start_date.toLocaleString();
    partner.end_date = partner.end_date.toLocaleString();
    const partnerWithFlag = {...partner, isCodeValueChanged, isEmailDomainChanged};
    return this.http.put(fullUrl,
      partnerWithFlag,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      response => {
        if (response == null) {
          return false;
        }
        return true;
      }
    ));

  }

  getPartner(partnerId: number) {
    const fullBaseUrlById = ConfigAssetLoaderService.getApiURL() + '/' + 'partner/' + partnerId;
    return this.http.get(fullBaseUrlById,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      (result) => {
        if (result === null) {
          return new PartnersModel();
        }
        return result;
      }
    ));
  }

  getUserPartner(user_id: number) {
    const fullBaseUrlById = ConfigAssetLoaderService.getApiURL() + '/partner/user/' + user_id;
    return this.http.get(fullBaseUrlById,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      (result) => {
        if (result === null) {
          return new PartnersModel();
        }
        return result;
      }
    ));
  }

  checkDuplicatePartnerName(partnerId: number, partner_name: string) {
    const fullBaseUrlById = ConfigAssetLoaderService.getApiURL() + '/' + 'partner/checkDuplicatePartnerName/' + partnerId + '/' + partner_name;
    return this.http.get(fullBaseUrlById,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      (result) => {
        if (result === null) {
          return new PartnersModel();
        }
        return result;
      }
    ));
  }

  getPartnerDashboard(partnerId: number) {
    const fullBaseUrlById = ConfigAssetLoaderService.getApiURL() + '/' + 'partner/partnerDashboard/' + partnerId;
    return this.http.get(fullBaseUrlById,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      (result) => {
        if (result === null) {
          return new PartnerDashboardModel();
        }
        return result;
      }
    ));
  }

  getPatientsRelated(partnerId: number): Observable<any> {
    const fullBaseUrlById = ConfigAssetLoaderService.getApiURL() + '/' + 'getMemberListCase/' + partnerId;
    return this.http.get(fullBaseUrlById,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      (result) => {
        if (result === null) {
          return new PartnerDashboardModel();
        }
        return result;
      }
    ));
  }

  getPatientsAndMember(patientList: any): Observable<any> {
    const fullBaseUrlById = ConfigAssetLoaderService.getApiURL() + '/' + 'getPatientListCase' + '?patientList=' + patientList;
    return this.http.get(fullBaseUrlById,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      (result) => {
        // console.log(">>>>>>>>>>>>>>>>>>>>>");
        if (result === null) {
          return new PartnerDashboardModel();
        }
        return result;
      }
    ));
  }
  getAverageTurnAroundTime(patientList: any) {
    const fullBaseUrlById = ConfigAssetLoaderService.getApiURL() + '/' + 'getAverageTurnAroundTime' + '?id=' + patientList;
    return this.http.get(fullBaseUrlById,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      (result) => {
        if (result === null) {
          return new CasePartnerModel();
        }
        return result;
      }
    ));
  }

  getCasesRelated(patientList: any) {
    const fullBaseUrlById = ConfigAssetLoaderService.getApiURL() + '/' + 'getPartnerCase' + '?id=' + patientList;
    return this.http.get(fullBaseUrlById,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      (result) => {
        if (result === null) {
          return new CasePartnerModel();
        }
        return result;
      }
    ));
  }

  getCareTeam(partnerId: number) {
    const fullBaseUrlById = ConfigAssetLoaderService.getApiURL() + '/' + 'partner/careTeam/' + partnerId + '/2';
    return this.http.get(fullBaseUrlById,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      (result) => {
        if (result === null) {
          return new CareTeamDashboardModel();
        }
        return result;
      }
    ));
  }

  getPartnerCareTeam(partnerId: number) {
    const fullBaseUrlById = ConfigAssetLoaderService.getApiURL() + '/' + 'partner/careTeam/' + partnerId + '/3';
    return this.http.get(fullBaseUrlById,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      (result) => {
        if (result === null) {
          return new PartnerCareTeamDashboardModel();
        }
        return result;
      }
    ));
  }

  getPartnerCount() {
    const fullBaseUrlById = ConfigAssetLoaderService.getApiURL() + '/' + 'partner/partnerCount';
    return this.http.get(fullBaseUrlById,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      (result) => {
        return result;
      }
    ));
  }




  restorePartner(partnerId: number) {
    const fullBaseUrlById = ConfigAssetLoaderService.getApiURL() + '/' + 'partner/restore/' + partnerId;
    return this.http.delete(fullBaseUrlById,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      result => {
        if (result == null) {
          return false;
        }
        return true;
      }
    ));
  }

  deletePartner(partnerId: number) {
    const fullBaseUrlById = ConfigAssetLoaderService.getApiURL() + '/' + 'partner/delete/' + partnerId;
    return this.http.delete(fullBaseUrlById,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      result => {
        if (result == null) {
          return false;
        }
        return true;
      }
    ));
  }


  removeCareTeam(user_id: number, partner_id: number) {

    const fullBaseUrlById = ConfigAssetLoaderService.getApiURL() + '/' + 'partner/deleteCareteam/' + user_id + '/' + partner_id;
    return this.http.delete(fullBaseUrlById,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      result => {
        if (result == null) {
          return false;
        }
        return true;
      }
    ));
  }

  assignCareTeamToThisPartner(partner_id: number, selectedCareTeam: any) {
    const fullBaseUrlById = ConfigAssetLoaderService.getApiURL() + '/' + 'assignCareTeamToThisPartner/' + partner_id;
    return this.http.post(fullBaseUrlById, selectedCareTeam,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      result => {
        if (result == null) {
          return false;
        }
        return true;
      }
    ));
  }

  getPartnerBanner(fileName: string): any {
    return this.http.get<any>(ConfigAssetLoaderService.getApiURL() + '/' + 'partnerlogo?file=' + fileName,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      (result) => result.image
    ));
  }

  getPartnerLogo(partnerId: number, fileName: string): any {
    return this.http.get<any>(ConfigAssetLoaderService.getApiURL() + '/' + 'partnerbacklogo?partner_id=' + partnerId + '&file=' + fileName,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      (result) => result.image
    ));
  }

  exportCSV(partnerId: number, value: any){
    const fullUrl = ConfigAssetLoaderService.getApiURL() + '/' + 'export/csv/' + partnerId;
    return this.http.post(fullUrl, value,
      this.getHeaderWithToken(localStorage.getItem('token'))
    );
  }

  downloadAsCsv(data, filename = 'data'){
    const csvData = this.ConvertToCSV(data,
      ['Client Name','Case Identifier', 'Name', 'Age', 'Gender', 'Category', 'Case Type', 'VIP', 'Request Date', 'Company', 'MR Complete', 'Consultation Date', 'Completed Date']);
    const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    // if Safari open in new window to save file with random filename.
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) { 
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (const index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i+1)+'';
      for (const index in headerList) {
        const head = headerList[index];
        line += ',"' + array[i][head]+'"';
      }
      str += line + '\r\n';
    }
    return str;
  } 

  /* editPartner(partnerId: number) {
    console.log("Edit Partner: "+partnerId)
    var fullBaseUrlById = ConfigAssetLoaderService.getApiURL() + "/" + 'partner/edit/' + partnerId
    console.log(fullBaseUrlById);
    return this.http.put(fullBaseUrlById,
        this.getHeaderWithToken(localStorage.getItem('token'))
      ).map(
    result => {
      console.log("this is result==="+result)
      if (result == null) {
      return false;
      }
      return true;
    }
    )
  } */

  handleError(error: HttpErrorResponse, operation: string): string {
    if (operation === 'add' || operation === 'create') {
      if (error.status === 401 || error.status === 400) {
        if (error.error && error.error.msg) {
          return error.error.msg;
        }
        else if (error.error && error.error.message) {
          return error.error.message;
        }
        else if(error.error && error.error.message.toLowerCase() === 'duplicate activation code') {
          return 'Failed to create client. Activation Code already exist, please use another code';
        }
        else if(error.error && error.error.message.toLowerCase() === 'duplicate email domain') {
          return 'Failed to create client. Email Domain already exist.';
        }
      }
      else {
        return 'Failed to create client';
      }
    }
    else if (operation === 'edit') {
      if(error.error && error.error.message.toLowerCase() === 'duplicate activation code') {
        return 'Failed to update client. Activation Code already exist, please use another code';
      }
      else if(error.error && error.error.message.toLowerCase() === 'duplicate email domain') {
        return 'Failed to update client. Email Domain already exist.';
      }
      else{
        return 'Failed to update client';
      }
    }
    else if (operation === 'delete') {
      return 'Failed to delete client';
    }
    else if (operation === 'restore') {
      return 'Failed to restore client';
    }

  }

  getPartnerOrganization() {
    let partnerOrganizationMap;

    return partnerOrganizationMap;

  }

  getOrganizationTypeList(): Observable<any[]> {
    const token = localStorage.getItem('token');
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'partnerOrganization', this.getHeaderWithToken(token));
  }

  getPlanTypeList(): Observable<any[]> {
    const token = localStorage.getItem('token');
    return this.http.get<any[]>(ConfigAssetLoaderService.getApiURL() + '/' + 'planType', this.getHeaderWithToken(token));
  }

  private getHeaderWithToken(token: string) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
  }
}

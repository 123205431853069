/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigAssetLoaderService } from '../services/config-asset-loader.service';

@Injectable()
export class NotificationService {

  constructor(
		private http: HttpClient
  ) { }

  private getHeaderWithToken(token: string) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
  }

  getAllNotifications(data?): Observable<object[]> {
    
    const groupType = (data && data.groupType)? data.groupType : 'all';
    const offset = (data && data.offset) ? data.offset : 0;
    const limit = (data && data.limit) ? data.limit : 20;
    
    const query = `groupType=${groupType}&limit=${limit}&offset=${offset}`;
    const token = localStorage.getItem('token');
    return this.http.get<object[]>(ConfigAssetLoaderService.getApiURL() + '/v2/notifications' + '?' + query, this.getHeaderWithToken(token));
  }

  markAsReadOrUnread(data?): Observable<object[]> {
    const token = localStorage.getItem('token');
    return this.http.post<object[]>(ConfigAssetLoaderService.getApiURL() + '/v2/mark-as-read-or-unread', data, this.getHeaderWithToken(token));
  }

  clearNotification(data?): Observable<object[]> {
    
    const notificationIds = data.length !== 0 ? data : ['all'];
    
    const token = localStorage.getItem('token');
    return this.http.post<object[]>(ConfigAssetLoaderService.getApiURL() + '/v2/clear-notifications', { notificationIds }, this.getHeaderWithToken(token));
  }

}
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';

/**
* Guard service to check if local storage contains jwt token.
* It redirects to login page if local storage does not contain token.
* Currently used only by dashboard component.
*/
@Injectable()
export class GuardService implements CanActivate {

  /**
  * Initiates guard service by taking an instance of LoginService and Router
  * @param {LoginService} loginService LoginService used to login operation
  * @param {Router} router Router used to create routerLinks
  */
  constructor(
    private loginService: LoginService,
    private router: Router,
  ) { }

  /** Guard to check if user is logged in.
  * redirects to login page if user is not authenticated.
  * @return Observable<Boolean>
  */
  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.loginService.isLoggedIn()) {
      return true;
    }
    else {
      this.router.navigate(['/login'], {
        queryParams: {
          returnUrl: state.url
        }
      });
      return false;
    }
  }
}

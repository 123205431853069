/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MailServerService } from '../../../services/mail-server.service';
import { MailServerModel } from '../../../domains/MailServerModel';
import { PopUpService } from '../../../services/pop-up.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-outgoing-mail-server',
  templateUrl: './view-outgoing-mail-server.component.html',
  styleUrls: ['./view-outgoing-mail-server.component.css']
})
export class ViewOutgoingMailServerComponent {
  isInprogress = false;
  mailId: number;
  mailObj: MailServerModel;

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private mailServerService: MailServerService,
    private popUpService: PopUpService,
    private toastrService : ToastrService
  ) {
    this.activatedRoute.params.subscribe(
      params => {
        const { id } = params;
        this.mailId = id;
        this.getMailServerDetail(this.mailId);
      }
    );
  }

  getMailServerDetail(id: number): void {
    this.isInprogress = true;
    this.mailServerService.getMailServerById(id).subscribe(
      result => {
        const { status, data } = result;
        if (status === 200) {
          this.isInprogress = false;
          this.mailObj = new MailServerModel(data);
        }
      },
      error => {
        console.log(error);
        this.isInprogress = false;
      }
    );
  }

  verifyConnection(event: Event): void {
    event.preventDefault();
    this.mailServerService.verifyConnection(this.mailId).subscribe(
      result => {
        const message = 'Test Connection succeeded';
        this.toastrService.success(message);
        console.log(result);
      },
      error => {
        const message = 'Failed to test connection';
        this.toastrService.error(message);
        console.log(error);
      }
    );
  }

  callDeleteAction(): void {
    const postiveAction = function(): void {
      this.popUpService.hideDiv('alertDialog');
      this.router.navigate(['/settings/outgoing-mail-server']);
    }.bind(this);

    this.isInprogress = true;
    this.mailServerService.deleteMailServer(this.mailId).subscribe(
      result => {
        this.isInprogress = false;

        const message = 'Mailserver delete success!';
        this.toastrService.show(message, postiveAction);
        console.log(result);
      },
      (_error) => {
        this.isInprogress = false;
        this.toastrService.error('Failed to delete the mail server');
      }
    );
  }

  deleteMailServer(event: Event): void {
    event.preventDefault();

    const message = 'Are you sure? You want to delete this mail server';
    const postiveAction = function(): void {
      this.callDeleteAction();
      this.popUpService.hideDiv('confirmDialog');
    }.bind(this);
    const negativeAction = function(): void {
      this.popUpService.hideDiv('confirmDialog');
    }.bind(this);

    this.showConfirmation(message, postiveAction, negativeAction);
  }

  /** Function to show the confirmation dialog
   * It needs the <app-dialog> component to be loaded 
   */
  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function): void {
    this.popUpService.destoryConfirmDialog();
  
    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );
  
    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }
    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }
  
    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);
  }
}

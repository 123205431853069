/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumDisplay'
})
export class EnumDisplayPipe implements PipeTransform {
  communicationTimeDict = {
    MORNING: 'Morning',
    AFTERNOON: 'Afternoon',
    EVENING: 'Evening',
    ANYTIME: 'Anytime'
  };

  communicationModeDict = {
    EMAIL: 'Email',
    PHONE: 'Phone',
    EMAIL_PHONE: 'Email & Phone',
  };

  doctorAvailabilityDict = {
    HIGH_AVAILABILITY: 'High Availability',
    MEDIUM_AVAILABILITY: 'Medium Availability',
    LOW_AVAILABILITY: 'Low Availability'
  };

  transform(value: any, args: any): any {
    let displayWord = 'N/A';
    if(value && args){
      if(args === 'communication_time'){
        displayWord = this.communicationTimeDict[value];
      }
      else if(args === 'communication_mode'){
        displayWord = this.communicationModeDict[value];
      }
      else if(args === 'doctor_availability'){
        displayWord = this.doctorAvailabilityDict[value];
      }
    }
    return displayWord;
  }

}

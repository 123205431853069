/* eslint-disable no-restricted-imports */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { LoginService } from './services/login.service';
import { PopUpService } from './services/pop-up.service';

@Injectable()
export class ErrorsHandlerService implements ErrorHandler {


  constructor(
    private popUpService: PopUpService,
    private loginService: LoginService,
    private router: Router
  ) { }

  handleError(_error: any): void {
    //
  }

  handleAuthError(error: any): void {
    const serverError = error.error || error;
    if (serverError.type && serverError.type === 'PASSWORD_EXPIRED') {
      if (serverError.expiryToken) {
        localStorage.setItem('expiryToken', serverError.expiryToken);
      }

      const action = function () {
        this.router.navigate(['/reset', 'password'], {
          queryParams: {
            type: 'expiryToken'
          }
        });
        this.popUpService.hideDiv('alertDialog');
      }.bind(this);

      this.showAlert(serverError.msg, serverError.description, action);
    }
    else {
      this.showAlert('Error', serverError.msg || serverError.message, null);
    }
  }

  private showAlert(title = 'alert', message: string, positiveCallback: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      title,
      message,
      'Ok',
    );

    if (positiveCallback) this.popUpService.setPositiveAction(positiveCallback);
    if (!positiveCallback) {
      this.popUpService.setPositiveAction(()=> {
        this.popUpService.hideDiv('alertDialog');
      });
    }

    const alertDialog = this.popUpService.getPopUpView('alertDialog');
    if (alertDialog) this.popUpService.showDiv(alertDialog);
  }

  showUpdatePasswordAlert(returnUrl: any): void {
    const action = function () {
      const expiryToken = localStorage.getItem('expiryToken');
      if (!expiryToken) {
        this.popUpService.hideDiv('alertDialog');
        this.router.navigate(['/reset', 'password']);
        return;
      }

      sessionStorage.removeItem('userId');
      localStorage.removeItem('token');
      this.router.navigate(['/login'], {
        queryParams: {
          returnUrl: returnUrl || '',
          type: 'PASSWORD_EXPIRED'
        }
      });
      this.popUpService.hideDiv('alertDialog');
    }.bind(this);

    this.showAlert('Password Expired', 'Your password has expired and must be changed. Please click Ok and reset password.', action);
  }

  private showConfirmation(title: string, message: string, positiveAction?: Function, negativeAction?: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      title,
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }

    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }

    const div = this.popUpService.getPopUpView('confirmDialog');
    if(div) this.popUpService.showDiv(div);
  }

  showPasswordExpiryWarning(daysLeft, isNew = false): void {
    const positiveAction = function () {
      this.setPasswordExpiryLocalSetting(isNew, true);
      this.popUpService.hideDiv('confirmDialog');
    }.bind(this);

    const negativeAction = function () {
      this.setPasswordExpiryLocalSetting(isNew);
      this.popUpService.hideDiv('confirmDialog');
    }.bind(this);

    this.showConfirmation('Important!', `Your password expires in ${daysLeft} days! Do you want to change it now?`, positiveAction, negativeAction);
  }

  setPasswordExpiryLocalSetting(isNew = false, positiveClick = false) {
    const user = this.loginService.getCurrentUser();
    if (user) {
      const serverTodayDate = moment(user.iat * 1000);
      // create new store
      if (isNew) {
        localStorage.setItem('passwordExpirySetting', JSON.stringify([serverTodayDate.format('DD-MM-YYYY')]));
      }
      else {
        // update existing store
        const isAlertShown = localStorage.getItem('passwordExpirySetting');
        const settings = JSON.parse(isAlertShown) || [];
        settings.push(serverTodayDate.format('DD-MM-YYYY'));
        localStorage.setItem('passwordExpirySetting', JSON.stringify(settings));
      }
      // on positive click redirect to edit profile
      if (positiveClick) {
        this.router.navigate(['/change-password']);
      }
    }
  }

  checkForPasswordAlert(user) {
	  // check password alert message
	  if (user && !user.force_password_reset) {
	    const passwordUpdatedOn = user.password_updated_on || null;
	    if (passwordUpdatedOn) {
	      const serverTodayDate = moment(user.iat * 1000);
	      // add 90 days to updated date and calculate remaining days;
	      let daysLeft = moment(passwordUpdatedOn).add(90, 'days').diff(serverTodayDate, 'days');
	      daysLeft = Math.abs(daysLeft);
	
	      if (daysLeft > 0 && daysLeft < 8) {
	        const isAlertShown = localStorage.getItem('passwordExpirySetting');
	        if (isAlertShown) {
	          const settings = JSON.parse(isAlertShown) || [];
	          if (!settings.includes(serverTodayDate.format('DD-MM-YYYY'))) {
	            this.showPasswordExpiryWarning(daysLeft);
	          }
	        }
	        else {
	          this.showPasswordExpiryWarning(daysLeft, true);
	        }
	      }
	    }
	  }
  }
}

/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Subject } from 'rxjs';
import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { CaseService } from '../../../services/case.service';
import { ConsultationAnswer } from '../../../domains/case/consultation/consultation-answer';
import { OnChanges } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ConfigAssetLoaderService } from '../../../services/config-asset-loader.service';
import { LoginService } from '../../../services/login.service';

@Component({
  selector: 'app-written-case',
  templateUrl: './written-case.component.html',
  styleUrls: ['./written-case.component.css']
})
export class WrittenCaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() caseDetail: any;
  @Input() final_reports;
  @Input() translated_document;
  @Input() document_for_download;
  @Input() forReport: boolean;
  @Input() isEditEnable: boolean;
  @Input() isProgress: boolean;
  @Input() followUpNumber;
  @Input() caseStatus: any;
  @Output() edit: EventEmitter<boolean> = new EventEmitter();
  @Output() consultationFormValue:  EventEmitter<boolean> = new EventEmitter();

  written;

  writtenSummary: string;
  missingInfo: string;

  writtenReportList = [];
  case_questions = [];
  public isSummaryFinish: boolean
  mdtCase: boolean;
  bucketName: string;
  peerToPairDetails: any;
  form: FormGroup
  answers: ConsultationAnswer[];
  unsubscribe$: Subject<void>;
  user;
  userId;
  clinicalConsultForm: FormGroup;

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  isWrittenCollapse = true;
  isClinicalConsultationCollapse = true;
  showClinicalConsultDiagnosisValidation = false;
  showClinicalConsultRecommendTreatmentPlanValidation = false;
  isEditClinicalConsultation: boolean;
  showPeerToPeerMessage: string;
  disabledInputs = false;
  role: any;

  
  constructor(
    private caseService: CaseService,
    private formBuilder: FormBuilder,
    private loginService: LoginService
  ) {
    this.unsubscribe$ = new Subject<void>();
    this.user = this.loginService.getCurrentUser();
    this.userId = this.user.user_id;
    this.role = this.user.user_type.type.toLowerCase();
  }


  ngOnChanges(_changes: SimpleChanges): void {
    this.case_questions = [];
    this.writtenReportList = [];

    this.getForm();

    this.clearFormArray(this.caseQuestions);

    // emits and updates the form value to a the clinical form in review case
    if(this.isPeerToPeer()){
      this.clinicalConsultForm.valueChanges.subscribe(()  => {
        if(this.form.valid && this.clinicalConsultForm.valid){
          this.consultationFormValue.emit(this.clinicalConsultForm.value);
        }
      });
    }

    if (this.caseDetail) {
      this.written = this.caseDetail.status.find(element => element.status.status.toLowerCase() === 'written');

      this.isSummaryFinish = this.checkSummary(this.caseDetail);
      
      if (this.caseDetail && this.case_questions) {

        if (this.caseDetail.is_submitted) { this.written_summary.patchValue(this.writtenSummary); }
        this.missingInfo = this.caseDetail.missing_information;
        
      }
    }

    this.caseService.getCaseQuestions(this.caseDetail.id).subscribe(case_questions => {
      case_questions.case_questions.forEach(eachQuestion => {
        
        // add default empty answer to summary questions so CTN can edit all questions
        if (eachQuestion.answer.length == 0) {
          eachQuestion.answer.push({
            id: 0,
            case_question_id: eachQuestion.id,
            answer: '',
            answer_by_user_id: this.userId,
          });
        }

        this.case_questions.push(eachQuestion);
        this.writtenReportList.push(eachQuestion);

        const eachForm = this.formBuilder.group({
          id: [0, [Validators.required]],
          question_text: ['', [Validators.required, Validators.maxLength(50000)]],
          video_consultation: [''],
          video_mp4: [''],
          case_id: [this.caseDetail.id, [Validators.required]],
          answer: this.formBuilder.array([]),
          follow_up_id: [0]
        });

        eachQuestion.answer.forEach(_eachAnswer => {
          const eachAnswerForm = this.formBuilder.group({
            id: [0],
            answer: [''],
            created_at: [''],
            updated_at: [''],
            case_question_id: [0],
            answer_by_user_id: [0],
            user: this.formBuilder.group({
              first_name: [''],
              middle_name: [''],
              last_name: [''],
              full_name: ['']
            })
          });
          (eachForm.get('answer') as FormArray).push(eachAnswerForm);
        });
        eachForm.patchValue(eachQuestion);
        (eachForm.get('answer') as FormArray).controls.forEach((eachControl: FormControl) => {
          this.subscribeToAnswer(eachControl);
        });
        this.caseQuestions.push(eachForm);
      });
    });

    if (this.caseDetail && this.case_questions) {
      this.writtenSummary = this.caseDetail.written_summary;
      if (this.caseDetail.is_submitted) { this.written_summary.patchValue(this.writtenSummary); }
      this.missingInfo = this.caseDetail.missing_information;
    }
    this.validIsPeerToPeerCase();
  }
  
  validIsPeerToPeerCase(): void {

    if(this.isPeerToPeer()){
  
      this.peerToPairDetails =  JSON.parse(this.caseDetail?.peer_to_peer_details);

      if(this.peerToPairDetails && this.caseDetail.is_submitted){

        this.clinical_consult_diagnosis.setValue(this.peerToPairDetails.clinical_consult_diagnosis);

        if(this.peerToPairDetails.clinical_consult_diagnosis  != 'Other'){
          this.clinical_consult_diagnosis_notes.setValue('');
          this.showClinicalConsultDiagnosisValidation=true;
        }

        this.clinical_consult_diagnosis_notes.setValue(this.peerToPairDetails.clinical_consult_diagnosis_notes);

        this.clinical_consult_recommend_treatment_plan.setValue(this.peerToPairDetails.clinical_consult_recommend_treatment_plan);

        if(this.peerToPairDetails.clinical_consult_recommend_treatment_plan  != 'Other'){
          this.clinical_consult_recommend_treatment_plan_notes.setValue('');
          this.showClinicalConsultRecommendTreatmentPlanValidation=true;
        }

        this.clinical_consult_recommend_treatment_plan_notes.setValue(this.peerToPairDetails.clinical_consult_recommend_treatment_plan_notes);

        if(this.caseStatus == 'FINISHED'){
          this.disabledInputs = true;
        }

        if(this.caseStatus == 'INPROGRESS'){
          this.disabledInputs = false;
        }
         
      }
    }
  }
  public onEditClicked(event?: Event) {
    if (event) { event.preventDefault(); }

    this.isEditEnable = this.isEditEnable ? false : true;
    this.answers = [];

    this.edit.emit(this.isEditEnable);
    if(this.isPeerToPeer()){
      this.consultationFormValue.emit(this.clinicalConsultForm?.value);
    }
    
  }



  ngOnInit() {
    this.mdtCase = this.checkCaseType(this.caseDetail.case_type);
    this.bucketName = ConfigAssetLoaderService.getS3BucketName();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private checkCaseType(caseType: string): boolean {
    let mdtType = false;

    if(caseType === 'MDT VIDEO' || caseType === 'MDT WRITTEN') {
      mdtType = true;
    }

    return mdtType;
  }

  getForm() : void{
    this.form = this.formBuilder.group(
      {
        written_summary: ['', [Validators.required, Validators.maxLength(50000)]],
        case_questions: this.formBuilder.array([]),
      }
    );

    if(this.isPeerToPeer()){
      this.clinicalConsultForm = this.formBuilder.group({
        clinical_consult_diagnosis: ['',Validators.required],
        clinical_consult_diagnosis_notes: ['',Validators.compose([Validators.required,Validators.maxLength(100)])],
        clinical_consult_recommend_treatment_plan: ['',Validators.required],
        clinical_consult_recommend_treatment_plan_notes: ['',Validators.compose([Validators.required,Validators.maxLength(100)])],
      });
    }

  }


  getUniqueQuestionsList(arr) {

    // store the comparison  values in array
    const unique =  arr.map(e => e['value'].id)

    // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the false indexes & return unique objects
      .filter((e) => arr[e]).map(e => arr[e]);

    return unique;
  }

  get written_summary() {
    return this.form.get('written_summary');
  }

  get caseQuestions(): FormArray {
    return this.form.get('case_questions') as FormArray;
  }

  get clinical_consult_diagnosis_notes(){
    return this.clinicalConsultForm.get('clinical_consult_diagnosis_notes');
  }

  get clinical_consult_diagnosis(){
    return this.clinicalConsultForm.get('clinical_consult_diagnosis');
  }

  get clinical_consult_recommend_treatment_plan(){
    return this.clinicalConsultForm.get('clinical_consult_recommend_treatment_plan');
  }

  get clinical_consult_recommend_treatment_plan_notes(){
    return this.clinicalConsultForm.get('clinical_consult_recommend_treatment_plan_notes');
  }

  validateAndReturnValue() {
    const object = {};

    // CommonFuntions.validateAllFormFields(this.form)

    object['valid'] = this.form.valid;
    object['answer'] = this.answers;
    object['written_summary'] = this.form.value.written_summary;
    if(this.isPeerToPeer()){
      object['peer_to_peer_details'] = this.clinicalConsultForm.value;
    }
    return object;
  }

  checkSummary(caseDetail): boolean {

    const status = caseDetail.status;

    const translateDR = status.find(each => each.status_id === 8);

    if (translateDR && translateDR.state === 'FINISHED') {
      return true;
    }

    return false;
  }

  subscribeToAnswer(formcontrol: FormControl): void{
    const value =  formcontrol.get('answer').value;
    formcontrol.get('answer').patchValue(value);
    formcontrol.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      const editAnswer = this.answers.find(eachAnswer => eachAnswer.id === value.id && eachAnswer.case_question_id === value.case_question_id);

      if (editAnswer) {
        editAnswer.answer = value.answer;
      }
      else {
        const newEditedAnswer = new ConsultationAnswer();
        newEditedAnswer.id = value.id;
        newEditedAnswer.answer = value.answer;
        newEditedAnswer.case_question_id = value.case_question_id;
        this.answers.push(newEditedAnswer);
      }
    });
  }

  isPeerToPeer() {
    const { reason, speciality_program} = this.caseDetail;

    if (reason && reason.reason.includes('Peer to Peer')) return true; 
    if (speciality_program && speciality_program.includes('Peer to Peer')) return true; 

    return false;
  }

  changeClinicalConsultDiagnosis(value:string): void{
    
    this.showClinicalConsultDiagnosisValidation = false;

    if(value == 'Other'){
      this.showClinicalConsultDiagnosisValidation = true;
    }
    this.clinical_consult_diagnosis_notes.setValue('');
  }

  changeClinicalConsultRecommendTreatmentPlan(value:string) : void{
    
    this.showClinicalConsultRecommendTreatmentPlanValidation = false;

    if(value == 'Other'){
      this.showClinicalConsultRecommendTreatmentPlanValidation = true;
    }

    this.clinical_consult_recommend_treatment_plan_notes.setValue('');

  }
  
  /** Event to download the video */
  downloadVideo(videoLink: string): void {
    const win = window.open(videoLink, '_blank');
    win.focus();
  }
}

/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/naming-convention */

import {map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ConfigAssetLoaderService } from '../services/config-asset-loader.service';
import { UserModel } from '../domains/userModel';
import { LoginService } from './login.service';
import { DatePipe } from '@angular/common';
import { Observable ,  of } from 'rxjs';
import { DoctorModel } from '../domains/doctorModel';
import * as moment from 'moment/moment';
import { tz } from 'moment-timezone';

@Injectable()
export class DoctorService {

  doctorMap = new Map();

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) { }


  getDoctor(user_id: number) {
    // console.log("I am at service @ " + partnerId);
    const fullBaseUrlById = ConfigAssetLoaderService.getApiURL() + '/' + '/partner/getDoctor/' + user_id;
    // console.log(fullBaseUrlById);
    return this.http.get(fullBaseUrlById,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      (result) => {
        if (result === null) {
          return new DoctorModel();
        }
        return result;
      }
    ));
  }

  getDoctorAvailabilityCases(case_id: number) {
    return this.http.get<any[]>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'doctor_availability_cases/' + case_id,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(
      catchError(
        (_error) => of([])
      )
    );
  }

  checkDoctorSchedule(case_id: number) {
    const fullBaseUrlById = ConfigAssetLoaderService.getApiURL() + '/' + 'doctor/checkSchedule/' + case_id;
    // console.log(fullBaseUrlById);
    return this.http.get(fullBaseUrlById,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      (result) => result
    ));
  }

  addDoctor(user: UserModel): Observable<UserModel> {
    const fullUrl = ConfigAssetLoaderService.getApiURL() + '/' + 'user/create';
    user.created_by = this.loginService.getCurrentUser().user_id;

    const date = new DatePipe('en-US').transform(Date.now(), 'MM/dd/yyyy');
    user.created_date = date;

    return this.http.post<UserModel>(fullUrl,
      user,
      this.getHeaderWithToken(localStorage.getItem('token'))
    );
  }

  updateDoctor(user: UserModel) {
    delete user['active'];
    const fullUrl = ConfigAssetLoaderService.getApiURL() + '/' + 'user/edit/' + user.user_id;
    return this.http.put(fullUrl,
      user,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      response => {
        if (response == null) {
          return false;
        }
        return true;
      }
    ));

  }

  public getDoctorAvailableTime(doctorId: number, date: string) {
    const timezone = tz.guess();
    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + '/' + 'doctor_availability/time/' + doctorId + '?date=' + date + '&timezone=' + timezone,
      this.getHeaderWithToken(localStorage.getItem('token'))
    ).pipe(map(
      response => {
        if (!response || !response.times) {return [];}

        // eslint-disable-next-line no-shadow
        const dates = response.times.filter(date => date.available_time.length > 0);
        let filteredDate = [];

        // eslint-disable-next-line no-shadow
        dates.forEach(date => {
          date.available_time.forEach(time => {
            if (time.is_reoccurring) {
              const splitdate = date.date.split('-');
              const correctedStartDate = moment(time.start_date_time);
              correctedStartDate.set({ year: splitdate[0], month: splitdate[1] - 1, date: splitdate[2] });
              const correctedEndDate = moment(time.end_date_time);
              correctedEndDate.set({ year: splitdate[0], month: splitdate[1] - 1, date: splitdate[2] });
              time.start_date_time = correctedStartDate.utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ').toString();
              time.end_date_time = correctedEndDate.utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ').toString();
            }
            filteredDate.push(time);
          });
        });

        filteredDate = filteredDate.filter(each => moment(each.start_date_time).isAfter(moment()));

        return filteredDate;
      }
    ));
  }

  handleError(error: HttpErrorResponse, operation: string): string {
    if (operation === 'add') {
      if (error.status === 401 || error.status === 400) {
        return 'Something is duplicate.';
      } {
        return 'Failed to create doctor';
      }
    }
    else if (operation === 'edit') {
      return 'Failed to update doctor';
    }
    else if (operation === 'delete') {
      return 'Failed to delete doctor';
    }
    else if (operation === 'restore') {
      return 'Failed to restore doctor';
    }
  }


  private getHeaderWithToken(token: string) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
  }

}

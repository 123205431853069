/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

// import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigAssetLoaderService } from '../services/config-asset-loader.service';
// import { DoctorCaseQuestionAnswerModel } from '../domains/doctorCaseQuestionAnswerModel';
// import { BaseDoctorOnePager } from '../domains/case/consultation/doctor-one-pager';
// import { LoginService } from './login.service';
// import { CareteamReport } from '../domains/case/general-case/careteam-report-update';
// import { Socket } from 'ngx-socket-io';
// import { CASE_TECHNICAL, getHeaderWithToken } from  './constant';

@Injectable({
  providedIn: 'root'
})
export class CaseAssignmentService {

  constructor(
    private http: HttpClient,
	 	// private loginServce: LoginService,
    // private socket: Socket
  ) { }


  private getTokenHeader() {
    const token = localStorage.getItem('token');

    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token })
    };
  }

  acceptRejectCase(payload: { caseId: number, comments: string, status: string}): Observable<any> {
    const url = ConfigAssetLoaderService.getApiURL() + `/case-assignments/status/current/${payload.caseId}?status=${payload.status}`;
    return this.http.put<any>(
      url,
      { comments: payload.comments },
      this.getTokenHeader()
    );
  }

  getCaseAssignments(assignmentType: string, payload: any): Observable<any> {
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + `/case-assignments/cases/current?assignmentType=${assignmentType}`,
      payload,
      this.getTokenHeader()
    );
  }

  fetchCaseAssignment(Id: number): Observable<any> {
    return this.http.post<any>(
      ConfigAssetLoaderService.getApiURL() + `/case-assignments/?caseAssignmentId=${Id}`,
      {},
      this.getTokenHeader()
    );
  }

  changeCaseAssignmentStatus(payload: any){
    return this.http.put<any>(
      ConfigAssetLoaderService.getApiURL() + `/case-assignments/status/${payload.userId}/${payload.caseId}?status=${payload.statusType}`,
      {
        commments: payload.rejectReason
      },
      this.getTokenHeader()
    );
  }

  searchCaseAssignment(assignmentType: string , payload) : Observable<any> {
    return this.http.get<any>(
      ConfigAssetLoaderService.getApiURL() + `/case-assignments/cases/current?assignmentType=${assignmentType}&search=${payload.column},${payload.value}`,
      this.getTokenHeader()
    );
  }

}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */

export class MailServerModel {
  id: number;
  name: string;
  sequence: number;
  description: string;
  active: boolean;
  smtp_host: string;
  smtp_port: number;
  smtp_encryption: string;
  smtp_user: string;
  smtp_password: string;
  email_sender: string;
  email_service: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  createdBy: number;
  updatedBy: number;
  deletedBy: number;

  static SMTP_ENCRYPTION = {
    none: 'None',
    tls: 'TLS (STARTTLS)',
    ssl: 'SSL/TLS'
  };

  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.sequence = data.sequence;
    this.description = data.description;
    this.active = data.active;
    this.smtp_host = data.smtp_host;
    this.smtp_port = data.smtp_port;
    this.smtp_encryption = data.smtp_encryption;
    this.smtp_user = data.smtp_user;
    this.smtp_password = data.smtp_password;
    this.email_sender = data.email_sender;
    this.email_service = data.email_service;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.deleted_at = data.deleted_at;
    this.createdBy = data.createdBy;
    this.updatedBy = data.updatedBy;
    this.deletedBy = data.deletedBy;
  }

  get displayEmailService(): string {
    return this.email_service ? (this.email_service.charAt(0).toUpperCase() + this.email_service.slice(1)) : 'N/A';
  }

  get displaySmtpEncryption(): string {
    return MailServerModel.SMTP_ENCRYPTION[this.smtp_encryption];
  }

  get displayActive(): string {
    return this.active ? 'Active' : 'Not Active';
  }
}

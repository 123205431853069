/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-delete-user-template',
  templateUrl: './delete-user-template.component.html',
  styleUrls: ['./delete-user-template.component.css']
})
export class DeleteUserTemplateComponent implements OnInit {
  @ViewChild('DELETEUSER', { static: true }) 
  deleteUser: ElementRef;

  @Input() name: string;
  @Input() hideTemplate: boolean;
  @Output() toggleTemplate = new EventEmitter<boolean>();
  @Output() inActivateUser = new EventEmitter<any>();
  
  deleteUserForm: FormGroup;

  constructor(
    private fb: FormBuilder
  ) { }

  initializeForm(): FormGroup {
    return this.fb.group({
      delete_reason: ['', [Validators.required, Validators.maxLength(50000)]]
    });
  }

  ngOnInit(): void {
    this.deleteUserForm = this.initializeForm();
  }

  closeDeleteBox(event: Event): void {
    if (event) event.preventDefault();

    this.toggleTemplate.emit(true);
  }

  deleteDoctor(event: Event): void {
    if (event) event.preventDefault();

    this.deleteUserForm.markAllAsTouched();
    if (this.deleteUserForm.valid) {
      const reason = this.delete_reason.value;
      this.inActivateUser.emit(reason);
    }
  }

  get delete_reason(): FormControl {
    return this.deleteUserForm.get('delete_reason') as FormControl;
  }

}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgForm } from '@angular/forms';
import { CommonFuntions } from '../../../common/common.function';
import { ConfigAssetLoaderService } from '../../../services/config-asset-loader.service';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoginService } from '../../../services/login.service';

import { USER_ASSIGNED_CASES } from '../../../constant';
import * as moment from 'moment';

declare let $: any;

@Component({
  selector: 'app-assigned-cases-list',
  templateUrl: './assigned-cases-list.component.html',
  styleUrls: ['./assigned-cases-list.component.css']
})
export class AssignedCasesListComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: true })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  formChangesSubscription: any;
  @ViewChild('form', { static: true }) ngForm: NgForm;

  userId: number;
  errorString: string;
  caseCount: number;
  userObj;
  CASE_STATUS = [{
    id: 'all',
    value: 'Select'
  },
  {
    id: 'requested',
    value: 'Active'
  },{
    id: 'finished',
    value: 'Completed'
  }, {
    id: 'hold',
    value: 'Hold'
  }, {
    id: 'cancelled',
    value: 'Cancelled'
  }];
  status: string;
  URL: string;
  loadCount = 0;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public loginService: LoginService
  ) { 
    this.status = 'all';
    this.caseCount = 0;
  }

  getCaseStatus(status): string {
    let result;
    if (status === 'CANCELLED') {
      result = 'Cancelled';
    }
    else if (status === 'FINISHED') {
      result = 'Completed';
    }
    else if (status === 'HOLD') {
      result = 'Hold';
    }
    else {
      result = 'Active';
    }

    return result;
  }

  initializeDatatable(): void {
    this.URL = ConfigAssetLoaderService.getApiURL() + USER_ASSIGNED_CASES.USER + '/' + this.userId;
    this.dtOptions = {
      ajax: {
        url: this.URL,
        dataSrc: function (json): void {
          const { totalCases, activeCases, completedCases, holdCases, cancelledCases }= json?.data;
          this.caseCount = totalCases;
          this.userObj = json?.data?.user;
          return activeCases.concat(completedCases, holdCases, cancelledCases);
        }.bind(this),
        type: 'POST',
        beforeSend: function (xhr): void {
          if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
            xhr.abort();
            return;
          }
          const token = localStorage.getItem('token');
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }.bind(this),
        error: this.datatablesError.bind(this)
      },
      // pagingType: 'full_numbers',
      // serverSide: true,
      processing: true,
      lengthChange: false,
      stateSave: true,
      searching: true,
      // pageLength: 2,
      order: [[0, 'ASC']],
      info: false,
      columns: [
        {
          title: 'Id',
          render: function (_data, _type, row, _meta): string {
            return row?.id || 'N/A';
          }
        },
        {
          title: 'CaseId',
          render: function (_data, _type, row, _meta): string {
            let result = 'N/A';
            if (row?.case_identifier) {
              result = `<a href='${ConfigAssetLoaderService.getDomainURL()}/case/${row?.id}' target='_blank'>${row?.case_identifier}</a>`;
            }
            return result;
          }
        },
        {
          title: 'Ally Case Number',
          render: function (_data, _type, row, _meta): string {
            return row?.allyCaseNumber || 'N/A';
          }
        },
        {
          title: 'Ally CaseRef',
          render: function (_data, _type, row, _meta): string {
            return row?.allyCaseRef || 'N/A';
          }
        },
        {
          title: 'Care Team Nurse',
          render: function (_data, _type, row, _meta): string {
            return row?.case_owner?.full_name || 'N/A';
          }
        },
        {
          title: 'Create Date',
          render: function (_data, _type, row, _meta): string {
            const createdAt = moment(row.created_at);
            const formattedDateTime = createdAt.format('MM/DD/YYYY') + ' at ' + createdAt.format('hh:mm a');
            const caseCreateTime = createdAt.isValid() ? formattedDateTime : row.created_at;

            return caseCreateTime;
          }
        },
        {
          title: 'Complete Date',
          render: function (_data, _type, row, _meta): string {
            const createdAt = moment(row.updated_at);
            const formattedDateTime = createdAt.format('MM/DD/YYYY') + ' at ' + createdAt.format('hh:mm a');
            const updateTime = createdAt.isValid() ? formattedDateTime : row.updated_at;
            const finishedTime = row?.case_status ==='FINISHED' ? updateTime : 'N/A';

            return finishedTime;
          }
        },
        {
          title: 'Diagnosis',
          render: function (_data, _type, row, _meta): string {
            const diagnosis = row?.case_status ==='FINISHED' ? row?.patient_diagnosis : '--';

            return diagnosis;
          }
        },

        {
          title: 'Status',
          render: function (_data, _type, row, _meta): string {
            const status = this.getCaseStatus(row?.case_status);
            return `<span class="badge-small badge-yellow">${status}</span>`;
          }.bind(this)
        },
      ],
      columnDefs: [
        {
          targets: 0,
          visible: false,
        },
      ],
    };
  }

  handleFormChanges(): void {
    this.formChangesSubscription = this.ngForm.valueChanges.subscribe(
      form => {
        if (this.loadCount === 0) {
          this.loadCount++;
          return;
        }
        const { caseStatus } = form;
        this.datatableElement.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            dtInstance.ajax.url(this.URL + '?status=' + caseStatus);
            dtInstance.ajax.reload();
          }
        );
      }
    );
  }

  ngOnInit(): void {
    combineLatest(this.route.params, this.route.queryParams)
      .pipe(map(result => ({ params: +result[0]?.userId, query: result[1]?.status })))
      .subscribe(result => {
        this.userId = result?.params;
        this.status = result?.query;
        this.initializeDatatable();
        this.handleFormChanges();
      });
  }

  datatablesError(xhr, _error, _thrown): void {
    const errorStatus = xhr.status;
    if (errorStatus == 401) {
      this.router.navigate(['/login']);
    }
    else if (errorStatus == 404) {
      this.errorString = 'Invalid Url';
    }
    else {
      this.errorString = 'Problem occurred. Please contact your administrator';
    }
  }

  ngOnDestroy(): void {
    this.formChangesSubscription.unsubscribe();
  }
  

}

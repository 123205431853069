<app-dialog></app-dialog>

<div class="header">
  <div class="header__page-name">
    <h1 class="heading heading--main">Technical Settings</h1>
  </div>
</div>

<div class="wrapper--dash-content dash-content">
  <div class="tabs__content" [class.overlay--visible]="isInprogress">
    <div class="overlay__content">
      <div class="overlay__spinner">
        <span class="icon icon--loading"></span>
      </div>
    </div>

    <div class="form tabs__content--white-bg">
      <div class="row" *ngFor="let menu of menuList;let i=index;">
        <div class="contact-details no-border no-pad no-mar" *ngIf="i !=3">
          <h3 class="settingTitle">{{i+1 }}. {{ menu?.label }}</h3>
          <div class="contact-details__row">
            <div class="contact-details__label">{{ menu?.subLabel }}</div>
            <div class="contact-details__details">
              <button [routerLink]="menu?.btnLink" class="btn btn--blue btn--mini">Link</button>
            </div>
          </div>
          <div class="hr hr--space-down-2x"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form tabs__content--white-bg">
  <div class="row">
    <h4 id="logs-header">Logs </h4>
    <div class="contact-details no-border no-pad no-mar mt-2">
      <h3 class="settingTitle">1. Webhook Log</h3>
      <div class="contact-details__row">
        <div class="contact-details__label">All the webhook logs are here.</div>
        <div class="contact-details__details">
          <button [routerLink]="['/logs']" class="btn btn--blue btn--mini">Link</button>
        </div>
      </div>
    </div>
    <div class="hr hr--space-down-2x"></div>
    <div class=" no-border no-pad no-mar ">
      <div class="contact-details no-border no-pad no-mar">
        <h3 class="settingTitle">2. Email Log</h3>
        <div class="contact-details__row">
          <div class="contact-details__label">All the outgoing business emails are here.</div>
          <div class="contact-details__details">
            <button [routerLink]="'/mail-logs'" class="btn btn--blue btn--mini">Link</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-undef */
import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[PhoneNumber]'
})
export class PhoneNumberDirective {
  constructor(
		public el: ElementRef
  ) {
    el.nativeElement.onkeypress = (event) => {
      let stop = false;
      if (event.which < 48 || event.which > 57) {
        stop = true;
      }

      if (event.key == 'Backspace'
				|| event.key === 'Delete'
				|| event.key === 'ArrowLeft'
				|| event.key === 'ArrowRight'
				|| event.key === 'ArrowUp'
				|| event.key === 'ArrowDown'
				|| event.key === '+') {
        stop = false;
      }

      if (!(event.key == 'Backspace'
				|| event.key === 'Delete'
				|| event.key === 'ArrowLeft'
				|| event.key === 'ArrowRight'
				|| event.key === 'ArrowUp'
				|| event.key === 'ArrowDown')) {
        let value: string = el.nativeElement.value;
        value = value.slice(0, this.el.nativeElement.selectionStart) + event.key + value.slice(this.el.nativeElement.selectionStart);

        if (!(/^\+?\d*$/.test(value))) {
          stop = true;
        }
      }

      if (stop) {
        event.preventDefault();
      }
    };

    const inputElement: HTMLElement = el.nativeElement;
    inputElement.onpaste = (event) => {
      const value = event.clipboardData.getData('text');

      if (!(/^\+?\d*$/.test(value))) {
        event.preventDefault();
      }
    };
  }
}

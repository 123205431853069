/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { PopUpService } from '../../services/pop-up.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { Router, UrlTree, UrlSegmentGroup, UrlSegment, PRIMARY_OUTLET } from '@angular/router';
import { CommonFuntions } from '../../common/common.function';
import { UserService } from '../../services/userservice.service';
import * as moment from 'moment/moment';

import { DoctorCalenderModel } from '../../domains/doctorCalenderModel';
import { DoctorAvailiabilityModel } from '../../domains/doctorAvailiabilityModel';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.css']
})
export class CalenderComponent implements OnInit {
  @ViewChild('minTime', {static: true}) minTime: ElementRef;
  @ViewChild('maxTime', {static: true}) maxTime: ElementRef;
  title: string;
  label: string;

  min_time: string;
  max_time: string;
  valid_time: boolean;
  valid_time_edit: boolean;

  showSpinner: boolean;


  calenderForm: FormGroup;
  calenderEditForm: FormGroup;
  doctorCalenderModel = new DoctorCalenderModel();
  doctorAvailiabilityModel = new DoctorAvailiabilityModel()

  doctors: any[] = [];
  hours: any[] = [];
  mins: any[] = [];


  isNew: boolean;

  doctor_edit_id: number;

  isEditShowSave: boolean
  role: string
  isDoctor: boolean


  public selectedMoment = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);

  constructor(
    public loginService: LoginService,
    public router: Router,
    private userService: UserService,
    private popUpService: PopUpService,
    private toastrService: ToastrService
  ) { }


  getDoctorCalenderEdit(doctorsId: number) {
    return this.userService.getDoctorCalenderEdit(doctorsId);
  }

  getDoctorReoccuringCalenderEdit(doctorsId: number) {
    return this.userService.getDoctorReoccuringCalenderEdit(doctorsId);
  }


  ngOnInit() {
    this.showSpinner = true;
    this.valid_time_edit = true;
    this.isEditShowSave = false;
    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const s: UrlSegment[] = g.segments;

    this.title = 'Doctor Availability';
    this.label = 'Save';

    const user = this.loginService.getCurrentUser();

    this.role = user.user_type.type.toLowerCase();

    this.isDoctor = (this.role === 'doctor') ? true : false;

    this.calenderForm = new FormGroup(
      {
        doctor_id: new FormControl(0, [CommonFuntions.validateDropDown, Validators.required]),
        single: new FormControl(),
        multiple: new FormControl(),
        doctor_availability: new FormArray([
          this.generateTime()
        ]),
        is_reoccurring: new FormControl([Validators.required]),
      }, {
        updateOn: 'blur'
      }
    );
    this.calenderEditForm = new FormGroup(
      {
        doctor_availability: new FormArray([
          this.generateTime()
        ]),
      }, {
        updateOn: 'blur'
      }
    );

    this.checkValidTime();
    /* this.title = "Create Doctor Availability"
    this.label = "Save" */


    this.userService.getDoctorList().subscribe(
      results => {
        this.doctors = results;
        this.doctors.splice(0, 0, {
          doctor_id: 0,
          doctor: 'Select'
        });

      }, (_error) => {
        this.doctors.splice(0, 0, {
          doctor_id: 0,
          doctor: 'Select'
        });
      }
    );

    this.selectInitialDoctor();

  }

  setMaxTime(value: string) {
    this.max_time = value;
    this.checkValidTime();
  }

  setMinTime(value: string) {
    this.min_time = value;
    this.checkValidTime();
  }

  setDateTime(time) {
    const fullDate = new Date();
    const d = moment(fullDate).format('L');
    const date = moment(d + ' ' + time).format();
    const jsDate = moment(date).add(1, 'hours').format('h:mm A');
    return jsDate;
  }

  fetchData(status) {
    this.doctor_availability_edit.controls = [];

    if (this.doctor_edit_id) {
      if (status == 'Single') {

        this.getDoctorCalenderEdit(this.doctor_edit_id).subscribe(
          result => {
            if (result.doctor_availability.length > 0) {
              this.addAnotherEditTimes();

              let i = 0;
              result.doctor_availability.forEach((_element) => {
                i > 0 ? this.addAnotherEditTimes() : '';
                i++;
              });
            }
            const doctor_availability = [];
            if (result.doctor_availability) {
              result.doctor_availability.forEach(element => {
                const obj = {
                  doctor_availability_id: element.doctor_availability_id,
                  available_date: moment(element.available_date).format(),
                  start_time: moment(element.start_time).format(),
                  end_time: moment(element.end_time).format(),
                  remarks: element.remarks,
                };
                doctor_availability.push(obj);
              });
            }
            this.doctor_availability_edit.setValue(doctor_availability);
            this.single.setValue(true);
            this.multiple.setValue(false);
            this.is_reoccurring.setValue(false);
            this.showSpinner = false;
          }
        );

      }
      else {
        this.getDoctorReoccuringCalenderEdit(this.doctor_edit_id).subscribe(
          result => {
            if (result.doctor_availability.length > 0) {
              this.addAnotherEditTimes();

              let i = 0;
              result.doctor_availability.forEach((_element) => {
                i > 0 ? this.addAnotherEditTimes() : '';
                i++;
              });
            }
            this.doctor_availability_edit.setValue(result.doctor_availability);
            this.single.setValue(false);
            this.multiple.setValue(true);
            this.is_reoccurring.setValue(true);
            this.showSpinner = false;
          }
        );
      }
    }
    else {
      this.checkUncheckStatus(status);
    }

  }

  selectInitialDoctor() {
    const user = this.loginService.getCurrentUser();
    this.checkUncheckStatus('Single');

    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g.segments;
    const getDoctorId = s[1] ? parseInt(s[1].path) : null;

    (user.user_type.type.toLowerCase() === 'doctor') ? this.selectDoctor(user.user_id) : this.doctor_availability_edit.controls = [];
    (user.user_type.type.toLowerCase() === 'admin' || user.user_type.type.toLowerCase() === 'cmmanager' 
    || user.user_type.type.toLowerCase() === 'careteam') ? getDoctorId ? (this.getDoctorInfo(getDoctorId)) : '' : '';
    (user.user_type.type.toLowerCase() === 'partner' || user.user_type.type.toLowerCase() === 'translator' 
    || user.user_type.type.toLowerCase() === 'interpreter') ? this.router.navigate(['/']) : '';
    this.showSpinner = false;
  }

  getDoctorInfo(id: number) {
    this.doctor_edit_id = id;
    this.doctor_id.setValue(id);
    this.fetchData('Single');
    this.showSpinner = false;
  }

  selectDoctor(id: number) {
    this.userService.getDoctorById(id).subscribe(
      result => {
        this.doctor_edit_id = result['doctor_id'];
        this.doctor_id.setValue(result['doctor_id']);
        this.fetchData('Single');
        this.showSpinner = false;

      }
    );
  }

  public changeDoctorCalender(event): void {  // event will give you full breif of action
    const newVal = event.target.value;
    this.doctor_edit_id = newVal;
    this.doctor_id.setValue(newVal);
    this.fetchData('Single');
  }


  compareTime(minTime, maxTime) {

    if (maxTime.value < minTime.value) {
      this.valid_time = false;
    }
    else {
      this.valid_time = true;
    }
    this.checkForValidTime(this.calenderForm);
  }

  public showAlert(message: string, positiveAction?: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(positiveAction);
    }
    else {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) {this.popUpService.showDiv(dialog);}
  }



  checkValidTime() {
    if (!this.max_time && !this.min_time) {
      this.valid_time = true;
    }
    else {
      if (this.max_time < this.min_time) {
        this.valid_time = false;
      }
      else {
        this.valid_time = true;
      }
    }
  }

  checkForValidTime(form: FormGroup) {
    form.getRawValue().doctor_availability.forEach(element => {
      if (element.start_time > element.end_time) {
        this.valid_time = false;
      }
    });
  }

  createModalCalender(form: FormGroup) {
   
    this.valid_time = true;
    this.checkForValidTime(form);

    const timeList = (form.get('doctor_availability') as FormArray).controls;

    timeList.forEach(time => {

      const availableDate = moment(time.get('available_date').value).format('YYYY-MM-DDTHH:mm:ssZ');
      const startDate = moment(time.get('start_time').value).format('YYYY-MM-DDTHH:mm:ssZ');
      const endDate = moment(time.get('end_time').value).format('YYYY-MM-DDTHH:mm:ssZ');

      time.get('available_date').setValue(availableDate);
      time.get('start_time').setValue(startDate);
      time.get('end_time').setValue(endDate);
    });

   
    if (CommonFuntions.guardForAction(this.loginService, this.router)) {
      if (form.valid && this.valid_time) {
        const doctorCalender: DoctorCalenderModel = form.getRawValue() as DoctorCalenderModel;
      
        this.userService.createDoctorCalender(doctorCalender).subscribe((data)=>{
          if (data['msg']) {
            this.calenderForm.reset();
            this.toastrService.success(data['msg']);
            this.doctor_availability.controls = [];
            this.addAnotherTime(event);
            this.fetchData(data['checkboxStatus']);
          }
          else{
            this.toastrService.error(data['msg']);
          }

        });  
      }
      else{
        this.toastrService.error('Form not valid');
      }
    }


  }

  get doctor_id() {
    return this.calenderForm.get('doctor_id');
  }

  get doctor_availability() {
    return (this.calenderForm.get('doctor_availability') as FormArray);
  }
  get doctor_availability_edit() {
    return (this.calenderEditForm.get('doctor_availability') as FormArray);
  }

  get minimum_time() {
    return this.doctor_availability.get('start_time');
  }

  get maximum_time() {
    return this.doctor_availability.get('end_time');
  }

  get single() {
    return this.calenderForm.get('single');
  }

  get multiple() {
    return this.calenderForm.get('multiple');
  }

  get is_reoccurring() {
    return this.calenderForm.get('is_reoccurring');
  }


  addAnotherTime(event) {
    event.preventDefault();
    this.doctor_availability.push(this.generateTime());
  }
  addAnotherEditTime(event) {
    event.preventDefault();
    this.doctor_availability_edit.push(this.generateTime());
  }
  addAnotherTimes() {
    this.doctor_availability.push(this.generateTime());
  }
  addAnotherEditTimes() {
    this.doctor_availability_edit.push(this.generateTime());
  }

  removeTime(index: number) {
    this.doctor_availability.removeAt(index);
    this.toastrService.success('Date time removed ');
  }

  generateTime() {
    return new FormGroup({
      doctor_availability_id: new FormControl(''),
      available_date: new FormControl('', Validators.required),
      start_time: new FormControl('', Validators.required),
      end_time: new FormControl('', Validators.required),
      remarks: new FormControl(),
    });
  }



  changeOccurrence(checkbox: HTMLInputElement | boolean, status, _isNew: boolean) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let whichStatus;
    if (typeof (checkbox) === 'boolean') {
      whichStatus = checkbox;
    }
    else if (checkbox instanceof HTMLInputElement) {
      whichStatus = checkbox.checked;
    }
    else {
      whichStatus = false;
    }

    this.fetchData(status);
  }

  checkUncheckStatus(status: string) {
    if (status == 'Single') {
      this.single.setValue(true);
      this.multiple.setValue(false);
      this.is_reoccurring.setValue(false);
    }
    else {
      this.multiple.setValue(true);
      this.single.setValue(false);
      this.is_reoccurring.setValue(true);

    }


  }

  removeMsgEdit() {
    this.valid_time_edit = true;

  }


  saveTime(index: number) {
    const da = this.doctor_availability_edit.controls[index];
    const da_id = da.get('doctor_availability_id').value;
    const da_available_date = da.get('available_date').value;
    const da_start_time = da.get('start_time').value;
    const da_end_time = da.get('end_time').value;
    const da_remarks = da.get('remarks').value;

    const availabileDateMoment = moment(da_available_date);
    const startMoment = moment(da_start_time);
    const endMoment = moment(da_end_time);

    startMoment.year(availabileDateMoment.year());
    startMoment.month(availabileDateMoment.month());
    startMoment.date(availabileDateMoment.date());

    endMoment.year(availabileDateMoment.year());
    endMoment.month(availabileDateMoment.month());
    endMoment.date(availabileDateMoment.date());

    if (startMoment.isSameOrAfter(endMoment)) {
      this.valid_time_edit = false;
      // showing  invalid message
      const errorMsg = $('div[id=\'valid_time_' + index + '\']');
      errorMsg.removeClass('hideErrMsg').addClass('showErrMsg');
    }
    else {
      this.valid_time_edit = true;
      //  hiding invalid message
      const errorMsg = $('div[id=\'valid_time_' + index + '\']');
      errorMsg.removeClass('showErrMsg').addClass('hideErrMsg');
    }

    const availabileDateMomentString = moment(da_available_date).format('YYYY-MM-DDTHH:mm:ssZ');
    const startMomentString = moment(da_start_time).format('YYYY-MM-DDTHH:mm:ssZ');
    const endMomentString = moment(da_end_time).format('YYYY-MM-DDTHH:mm:ssZ');

    da.get('available_date').setValue(availabileDateMomentString);
    da.get('start_time').setValue(startMomentString);
    da.get('end_time').setValue(endMomentString);

    if (this.valid_time_edit) {
      const doctor_calender_object = {
        // doctor_availability_id: da_id,
        available_date: da_available_date.substr(0,10),
        start_time: da_start_time,
        end_time: da_end_time,
        remarks: da_remarks,
      };

      this.doctorAvailiabilityModel = doctor_calender_object as DoctorAvailiabilityModel;
      // const confirmStr = 'Are you sure you want to update this doctor availability?';
      this.userService.updateSchedule(this.doctorAvailiabilityModel, da_id).subscribe((result)=>{
        if (result) {
          this.toastrService.success(result['msg']);
        }
        else {
          const message = 'Doctor Schedule not updated.';
          this.toastrService.success(message);
        }
      },() =>{
        this.toastrService.error('Doctor Schedule not updated.');

      });
      
 
    }

  }

  deleteTime(index: number) {
    const da = this.doctor_availability_edit.controls[index];
    const da_id = da.get('doctor_availability_id').value;

    // const confirmStr = 'Are you sure you want to delete this doctor availability?';

    this.userService.deleteSchedule(da_id).subscribe((result)=>{
        
      if (result) {
        this.toastrService.show('Doctor Schedule removed from calender.');
        this.doctor_availability.removeAt(index);
        this.fetchData('Single');
      }
      else {
        this.toastrService.error('Doctor Schedule not deleted');
      }
    },()=> {
      this.toastrService.error('Doctor Schedule not deleted');
    
    });
   
  }


  deleteTimeUpdate(index: number) {
    const da = this.doctor_availability_edit.controls[index];
    const da_id = da.get('doctor_availability_id').value;

    this.userService.deleteSchedule(da_id).subscribe((result)=>{
      if (result) {
        this.toastrService.success('Doctor Schedule removed from calender.');
        this.doctor_availability_edit.removeAt(index);
        this.fetchData('Single');
      }
      else {
        this.toastrService.error('Doctor Schedule not deleted.');
      }

    },() =>  {
      this.toastrService.error('Doctor Schedule not deleted.');
     
    });
  }

  removeInvalidMsg() {
    this.valid_time = true;
  }

}


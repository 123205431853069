/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigAssetLoaderService } from '../../../services/config-asset-loader.service';
import { LoginService } from '../../../services/login.service';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { CommonFuntions } from '../../../common/common.function';

declare let $: any;

@Component({
  selector: 'app-doctor-role',
  templateUrl: './doctor-role.component.html',
  styleUrls: ['./doctor-role.component.css']
})
export class DoctorRoleComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: true })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  token = 'Bearer ' + localStorage.getItem('token');
  valueToSearch: string;
  errorString: string;

  title: string;
  searchValue = ''
  userCount: number
  activeUsers: number
  inactiveUsers: number

  constructor(
    public router: Router,
    private loginService: LoginService
  ) {
    this.userCount = 0;
  }

  ngOnInit() {
    this.title = 'Doctor';
    const user = this.loginService.getCurrentUser();
    this.dtOptions = {
      ajax: {
        url: ConfigAssetLoaderService.getApiURL() + '/user/role/4?type=' + user.user_type.type + '&userid=' + user.user_id,
        dataSrc: function (json) {
          this.userCount = json.recordsTotal;
          this.activeUsers = json.activeCount || 0;
          this.inactiveUsers = json.inActiveCount || 0;
          return json.data;
        }.bind(this),
        type: 'POST',
        beforeSend: function (xhr) {
          if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
            xhr.abort();
            return;
          }

          this.errorString = null;
          const token = localStorage.getItem('token');
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }.bind(this),
        error: this.datatablesError.bind(this)
      },
      pagingType: 'full_numbers',
      serverSide: true,
      processing: true,
      lengthChange: false,
      stateSave: true,
      searching: true,
      pageLength: 25,
      order: [[0, 'ASC']],
      info: false,
      columns: [
        {
          title: 'Name',
          data: 'full_name',
          render: function (_data, _type, row, _meta) {
            const nameText = row.full_name || '';
            if (row.is_all_partners) {
              if (row.is_all_partners == 1) {
                return `<div style="width:150px;">${nameText} <span class="badge-small badge-secondary" title="All Clients">All</span></div>`;
              }
              else if (row.is_all_partners == 2) {
                return `<div style="width:150px;">${nameText} <span class="badge-small badge-yellow" title="All Ally Clients">Ally</span></div>`;
              }
              else if (row.is_all_partners == 3) {
                return `<div style="width:150px;">${nameText} <span class="badge-small badge-orange" title="All Non-ally Clients">Cloud</span></div>`;
              }
            }
            return `<div style="width:150px;">${nameText}</div>`;
          }
        },
        {
          title: 'Institution',
          data: 'doctor_info.institution',
          render: function (_data, _type, row, _meta) {
            if (row.doctor_info) {
              const institution = row.doctor_info.institution;
              return institution;
            }
            else {
              return '';
            }
          }
        },
        {
          title: 'Availability',
          data: 'doctor_info.availability',
          render: function (_data, _type, row, _meta) {
            if (row.doctor_info) {
              const availability = row.doctor_info.availability && row.doctor_info.availability.split('_').join(' ').toLowerCase().replace(/\b[a-z]/g, match => match.toUpperCase()) || 'N/A';
              return availability;
            }
            else {
              return '';
            }
          },
        },
        {
          title: 'Field of Medicine',
          data: 'doctor_info.category_value',
          render: function (_data, _type, row, _meta): string {
            let value = 'N/A';
            if (row?.doctor_info && row?.doctor_info?.category_value) {
              value = row?.doctor_info?.category_value;
            }
            return value;
          }
        },
        {
          title: 'Sub-Specialty',
          render: function (_data, _type, row, _meta) {
            const category_fields = [];
            if (!row.doctor_info || !row.doctor_info.doctor_category_fields || row.doctor_info.doctor_category_fields < 1) {
              return '';
            }

            row.doctor_info.doctor_category_fields.forEach(category_field => {
              category_fields.push(category_field.category_field.category_field);
            });

            let list = '';
            category_fields.forEach(fields => {
              list = list + '<li>' + fields + '</li>';
            });

            return '<ul>' + list + '</ul>';
          },
          orderable: false
        },
        {
          title: 'Status',
          render: function (_data, _type, row, _meta) {
            if (row.is_deleted) {
              return 'Inactive';
            }
            else {
              return 'Active';
            }
          },
          orderable: false
        }
      ],
      rowCallback: (row: Node, data: any[] | Object, _index: number) => {
        const self = this;

        // Unbind first in order to avoid duplicate handler
        $('td', row).unbind('click');
        $('td', row).bind('click', () => {
          self.viewUserInfo(data);
        });
      }
    };

    this.setSearchValueFromSavedState();
  }

  datatablesError(xhr, _error, _thrown) {
    const errorStatus = xhr.status;
    if (errorStatus == 401) {
      this.router.navigate(['/login']);
    }
    else if (errorStatus == 404) {
      this.errorString = 'Invalid Url';
    }
    else {
      this.errorString = 'Problem occurred. Please contact your administrator';
    }
  }

  search() {
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => {
        dtInstance.search(this.valueToSearch).draw();
      }
    );
  }

  searchString(searchValue: string) {
    this.valueToSearch = searchValue;
    this.search();
  }

  viewUserInfo(info: any) {
    this.router.navigate(['/doctor/profile', info.user_id]);

  }

  setSearchValueFromSavedState() {

    const state = localStorage.getItem('DataTables_doctor_/doctor-role');

    if (state == null) {
      this.searchValue = '';
      return;
    }

    const searchValue = JSON.parse(state).search['search'];

    if (searchValue != null) {

      this.searchValue = searchValue;

    }
  }

}

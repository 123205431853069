/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Status } from './status-model';

const state = {
  NOTSTARTED: 'NOT STARTED',
  INPROGRESS: 'INPROGRESS',
  FINISHED: 'FINISHED'
};
export class CaseStatus {
    id: number;
    state: string;
    created_at: Date;
    updated_at: Date;
    case_id: number;
    status_id: number;
    assigned_user_id?: any;
    status: Status;
    user?: any;

    static sort(list: CaseStatus[]) {
      list.sort(function (obj1, obj2) {
        return obj1.status_id - obj2.status_id;
      });
    }

    static setExternalStatus(list: CaseStatus[], case_type: string, role: string): CaseStatus[] {
      let externalStatus = null;
      const latestStatus: CaseStatus = this.getLatestStatus(list);
      const isFinished = this.isFinished(list);

      switch (role) {
      case 'partner': {
        externalStatus = [
          'Requested',
          'MDMgnt',
          'Processing',
          'Video',
          'Reviewing',
          'Report'
        ];

        this.checkAndRemove(list, externalStatus, ['translatedmr', 'schedule'], 'Processing');

        this.checkAndRemove(list, externalStatus, ['view'], 'Video');

        this.checkAndRemove(list, externalStatus, ['summary', 'translatedr'], 'Reviewing');

        this.checkAndRemove(list, externalStatus, ['report'], 'Report');

        const status = this.constructStatus(externalStatus);

        let latestStatusLabel: string = null;
        let refinedStatus: CaseStatus[];

        if (latestStatus) {
          latestStatusLabel = Status.mapStatusForPartner(
            Status.mapStatus(latestStatus.status.status)
          );
          refinedStatus = list.length > 1 ? this.setState(status, isFinished, latestStatusLabel) : this.setState(status, isFinished, latestStatusLabel).slice(0, 1);
        }
        else {
          refinedStatus = list.length > 1 ? this.setState(status, isFinished) : this.setState(status, isFinished).slice(0, 1);
        }


        return refinedStatus;
      }
      case 'patient': {
        if (
          case_type.toLowerCase() === 'written consultation' ||
                    case_type.toLowerCase() === 'asynchronous consultation' ||
                    case_type.toLowerCase() === 'mdt written' ||
                    case_type.toLowerCase() === 'connect' ||
                    case_type.toLowerCase() === 'connect asynchronous'
        ) {
          externalStatus = [
            'Collecting Medical Records',
            'Matching MD',
            'Analyzing Medical Records',
            'Sent to MD for review',
            'Report'
          ];
        }
        else {
          externalStatus = [
            'Collecting Medical Records',
            'Matching MD',
            'Analyzing Medical Records',
            'Scheduling',
            'Video Consultation',
            'Report'
          ];
        }

        const status = this.constructStatus(externalStatus);
        let refinedStatus: CaseStatus[];

        if (latestStatus) {
          const latestStatusLabel = Status.mapStatusForMembers(
            case_type,
            Status.mapStatus(latestStatus.status.status)
          );
          refinedStatus = list.length > 1 ? this.setState(status, isFinished, latestStatusLabel) : this.setState(status, isFinished, latestStatusLabel).slice(0, 1);
        }
        else {
          refinedStatus = list.length > 1 ? this.setState(status, isFinished) : this.setState(status, isFinished).slice(0, 1);
        }


        return refinedStatus;
      }
      default: {
        return list;
      }
      }
    }

    static constructStatus(list: string[]): CaseStatus[] {
      const constructedStatus = [];
      let externalStatusId = 1;

      list.forEach(externalLabel => {
        const status: Status = new Status;
        status.status = externalLabel;

        const caseStatus: CaseStatus = new CaseStatus;
        caseStatus.state = state.NOTSTARTED;
        caseStatus.status = status;
        caseStatus.status_id = externalStatusId;
        externalStatusId = externalStatusId + 1;

        constructedStatus.push(caseStatus);
      });

      return constructedStatus;
    }

    static setState(list: CaseStatus[], isFinished: boolean, stage?: string): CaseStatus[] {

      if (isFinished) {
        list.forEach(each => each.state = state.FINISHED);
        return list;
      }

      const index = list.findIndex(each => each.status.status === stage);

      for (let i = index; i > -1; i--) {
        const operand: CaseStatus = list[i];
        if (i == index) {
          operand.state = state.INPROGRESS;
          continue;
        }

        operand.state = state.FINISHED;
      }

      return list;
    }

    static getLatestStatus(list: CaseStatus[]): CaseStatus {
      let latestStage: CaseStatus = null;
      const inprogressStatus: CaseStatus[] = list.filter(each => each.state === state.INPROGRESS);

      if (inprogressStatus && inprogressStatus.length > 0) {
        CaseStatus.sort(inprogressStatus);
        latestStage = inprogressStatus[0];
      }
      else {
        CaseStatus.sort(list);
        latestStage = list.find(each => each.state === state.NOTSTARTED);
      }

      return latestStage;
    }

    static isFinished(list: CaseStatus[]): boolean {
      const finished = list.filter(each => each.state === state.FINISHED);

      return finished.length == list.length ? true : false;
    }

    private static checkAndRemove(list: CaseStatus[], externalStatus: string[], stage: string[], checkFor: string) {

      let stageInList = null;
      let count = 0;
      let index = -1;

      while(stageInList == null && count < stage.length) {
        stageInList = list.find(each => each.status.status.toLowerCase() === stage[count]);
        count += 1;
      }

      if (!stageInList) {
        index = externalStatus.findIndex(each => each === checkFor);
        if (index > -1) {externalStatus.splice(index, 1);}
      }
    }
}

/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { LoginService } from '../../../services/login.service';
import { Router } from '@angular/router';
import { CommonFuntions } from '../../../common/common.function';
import { UserService } from '../../../services/userservice.service';
import { PopUpService } from '../../../services/pop-up.service';
import { PasswordService } from '../../../services/password.service';
import { ErrorsHandlerService } from '../../../errorshandler.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  avatarMale: string;
  adminForm: FormGroup;
  operation: string;
  userId: number;
  userType: string;
  userTypeId: number;
  validatePartner = true;
  filteredLanguageList = [];
  socialTypes: any[];
  passwordReadOnly = false;
  currentUser: any;
  role: string;
  currentDate;
  showPassword = false;
  showError: boolean;
  user: any;
  password_type = 'password';
  confirm_password_type ='password'
  constructor(
    public loginService: LoginService,
    public userService: UserService,
    public passwordService: PasswordService,
    public router: Router,
    private popUpService: PopUpService,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private errorHandleService: ErrorsHandlerService
  ) {

  }

  ngOnInit() {
    this.adminForm = this.initializeForm();
  }

  initializeForm(): FormGroup {
    return new FormGroup(
      {
        newPassword: new FormControl('', { validators: [CommonFuntions.ValidatePassword], updateOn: 'change' }),
        currentPassword: new FormControl('', { validators: [CommonFuntions.ValidatePassword] }),
        confirmPassword: new FormControl('', { validators: [CommonFuntions.ValidatePassword], updateOn: 'change' })
      }, {
        updateOn: 'blur'
      }
    );
  }

  get newPassword() {
    return this.adminForm.get('newPassword');
  }

  get confirmPassword() {
    return this.adminForm.get('confirmPassword');
  }

  get currentPassword() {
    return this.adminForm.get('currentPassword');
  }

  private showAlert(message: string, hasPositiveAction?: boolean, callBackFunction?: Function) {


    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok',
    );

    if (hasPositiveAction) {

      if (callBackFunction) {
        this.popUpService.setPositiveAction(
          callBackFunction
        );
      }
      else {
        this.popUpService.setPositiveAction(
          function () {
            this.router.navigate(['/']);
          }.bind(
            this
          )
        );
      }
    }
    else {
      this.popUpService.setPositiveAction(
        function () {
          this.hideDiv('alertDialog');
        }
      );
    }

    const alertDialog = this.popUpService.getPopUpView('alertDialog');
    this.popUpService.showDiv(alertDialog);

  }

  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function) {

    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }

    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }

    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);

  }

  changePassword(event: Event) {
    event.preventDefault();
    this.user = this.loginService.getCurrentUser();
    const admin = {
      newPassword: this.adminForm.get('newPassword').value,
      confirmPassword: this.adminForm.get('confirmPassword').value,
      currentPassword: this.adminForm.get('currentPassword').value,
      user_id: this.user?.user_id
    };

    if (admin.newPassword !== admin.confirmPassword) {
      this.showAlert(
        'Passwords do not match',
      );
      return;
    }

    if (CommonFuntions.guardForAction(this.loginService,
      this.router)) {
      if (this.adminForm.valid) {
        this.showError= false;
        this.showConfirmation('Are you sure you want to change password?', function () {
          this.passwordService.changePassword(admin).subscribe(
            (_result) => {
              if(_result.status == 201){
                this.message = _result.message;
                !this.adminForm.valid;
                return ;
              }
              else{
                this.showAlert(
                  'Password has been changed successfully. Please login with new credentials.',true,function () {
                    this.router.navigate(['/']);
                  }.bind(this),function(){
                    this.popUpService.hideDiv('alertDialog');
                  });
              }

              this.popUpService.hideDiv('confirmDialog');
              this.loginService.removeToken();
            }, error => {
              if(error.status == 401){
                this.message = error.error.message;
                this.showError= true;
                setTimeout(()=>{
                  this.showError = false;
                },4000);
                return;
              }
              this.popUpService.hideDiv('confirmDialog');
              this.errorHandleService.handleAuthError(error);
            }
          );
          this.popUpService.hideDiv('confirmDialog');

        }.bind(this));
      }
      else {
        CommonFuntions.validateAllFormFields(this.adminForm);
      }
    }
  }

  cancel(event: Event) {
    event.preventDefault();
    const currentUser = this.loginService.getCurrentUser();
    return this.router.navigate(['/profile', currentUser.user_id]);
  }
}

/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-spread */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit, ViewChild, ViewChildren, ElementRef, QueryList, EventEmitter, Input, Output, Inject } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { DatashareService } from '../../../services/datashare.service';
import { CaseService } from '../../../services/case.service';
import { ActivityService } from '../../../services/activity.service';
import { PopUpService } from '../../../services/pop-up.service';
import { DOCUMENT } from '@angular/common';
import { find } from 'lodash';

@Component({
  selector: 'app-tracking-lines',
  templateUrl: './tracking-lines.component.html',
  styleUrls: ['./tracking-lines.component.css']
})
export class TrackingLinesComponent implements OnInit {
  @Input() caseId: number;
  @Input() trackingList: any;
  @Input() caseStatus: any;
  @Input() trackingline_upload;
  @Output() deleteDocument = new EventEmitter<any>();
  @Output() updateCaseDocuments = new EventEmitter<any>();
  @Input() isMRComplete: any;
  @Input() isMdManager: any;
  @Input() role: any;
  @Input() dateMrComplete: any;
  @Output() onMRComplete = new EventEmitter();

  @ViewChildren(DataTableDirective)
  @ViewChild('TRACKING_LINE_DETAIL', { static: true }) trackingLineDetailTemplate: ElementRef
  datatableElement: QueryList<DataTableDirective>;
  dtOptions: DataTables.Settings = {};
  activeTrackings: number;
  errorString: string;
  hideChildTemplate: boolean;
  trackingLineObj;
  trackingLineNoteList;
  loadingNotes = false;

  isTrackingCollapse = true;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public caseService: CaseService,
    public activityService: ActivityService,
    public popUpService: PopUpService,
    private dataShareService: DatashareService
  ) { }

  ngOnInit(): void {
    // this.dtOptions = {
    //   paging: false,
    //   pagingType: 'full_numbers',
    //   pageLength: 3,
    //   lengthChange: false,
    //   searching: false,
    //   info: false,
    //   scrollX: true,
    //   autoWidth: false
    // };
    this.hideChildTemplate = true;
    this.toogleNotificationBox();
  
    // get new notification for TL
    this.dataShareService.$eventArrived.subscribe(function (data) {
      const notification = data.notification;
      const action = data.action;

      if (notification) {
        const doc = find(this.trackingList, { tl_id: notification.metaId});
        if (doc) {
          this.trackingList.forEach(tl => {
            if (tl.tl_id === notification.metaId) {
              tl.newNotificationCount = (action === 'unread') ? tl.newNotificationCount + 1: tl.newNotificationCount - 1;
            }
          });
        }
      }
      else if (action === 'all') {
        this.trackingList.forEach(tl => {
          tl.newNotificationCount = 0;
        });
      }
    }.bind(this));
  }

  toogleNotificationBox () {
    // this method can be replace with css (z-index) but it will effect on too many places
    if (!this.hideChildTemplate) {
      this.document.body.classList.add('hideNotification');
    }
    else {
      this.document.body.classList.remove('hideNotification');
    }
  }

  toggleTrackingDetailTemplate(currentStatus: boolean): void {
    this.hideChildTemplate = !currentStatus;
    this.toogleNotificationBox();
  }

  updateDocuments(): void {
    this.updateCaseDocuments.emit();
  }

  deleteTrackingDocument(data): void {
    this.deleteDocument.emit(data);
  }

  setTrackingDetail(trackingId: string): void {
    this.trackingLineObj = this.trackingList.filter((eachItem) => eachItem.tl_id === trackingId)[0];
    this.trackingLineObj.caseId = this.caseId;
  }

  getTrackingLineNote(trackingId: number): void {
    this.loadingNotes = true;
    this.activityService.getTrackingLineActivity(this.caseId, trackingId).subscribe(
      result => {
        this.loadingNotes = false;
        const { data } = result;
        this.trackingLineNoteList = data;
      },
      error => {
        this.loadingNotes = false;
        console.log(error);
      }
    );
  }

  handleEvent(row: Node, data: any[] | Object): void {
    $('td', row).unbind('click');
    $('td', row).bind('click', function () {
      this.setTrackingDetail(data[0]);
      this.toggleTrackingDetailTemplate(true);
      this.getTrackingLineNote(data[0]);
    }.bind(this));
  }
  
  showTrackingDetails(trackingId) {
    this.setTrackingDetail(trackingId);
    this.toggleTrackingDetailTemplate(true);
    this.getTrackingLineNote(trackingId);
  }

  onChangeMRComplete(isMRChecked) {
    this.onMRComplete.emit(isMRChecked);
  }

  datatablesError(xhr, _error, _thrown) {
    const errorStatus = xhr.status;
    if (errorStatus == 401) {
      this.errorString = 'Not Autherized';
    }
    else if (errorStatus == 404) {
      this.errorString = 'Invalid Url';
    }
    else {
      this.errorString = 'Problem occurred. Please contact your administrator';
    }
  }

  viewTrackingInfo(_data) {
    // don table click here
  }

  getTrackingLineDocumentCount(trackingId){
    return this.trackingline_upload.filter(line=>line.tracking_id===trackingId).length;
  }

}

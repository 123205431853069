/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ConfigAssetLoaderService } from '../../../services/config-asset-loader.service';
import { LoginService } from '../../../services/login.service';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { UserService } from '../../../services/userservice.service';
import { CommonFuntions } from '../../../common/common.function';
import {find} from 'lodash';
import { PopUpService } from '../../../services/pop-up.service';

declare let $: any;

@Component({
  selector: 'app-system-account-role',
  templateUrl: './system-account.component.html',
  styleUrls: ['./system-account.component.css']
})
export class SystemAccountRoleComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: true})
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  token = 'Bearer ' + localStorage.getItem('token');
  valueToSearch: string;
  errorString: string;

  title: string;
  searchValue = '';

  userCount: number;
  allUsers = [];

  constructor(
    public router: Router,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private userService: UserService,
    private loginService: LoginService,
    public renderer: Renderer2,
    public popUpService: PopUpService
  ) {
    this.userCount = 0;
  }

  ngOnInit() {
    this.title = 'Ally Admin Role';

    const user = this.loginService.getCurrentUser();

    const optionObject = {
      ajax: {
        url: ConfigAssetLoaderService.getApiURL() + '/user/role/9?type=' + user.user_type.type + '&userid=' + user.user_id,
        dataSrc: function(json) {
          this.userCount = json.recordsTotal;
          this.allUsers = json.data;
          return json.data;
        }.bind(this),
        type: 'POST',
        beforeSend: function (xhr) {
          if (!CommonFuntions.guardForAction(this.loginService, this.router)) {
            xhr.abort();
            return;
          }

          this.errorString = null;
          const token = localStorage.getItem('token');
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }.bind(this),
        error: this.datatablesError.bind(this)
      },
      pagingType: 'full_numbers',
      serverSide: true,
      processing: true,
      lengthChange: false,
      searching: true,
      pageLength: 25,
      order: [[0, 'ASC']],
      info: false,
      stateSave: true,
      stateDuration: 5 * 60,
      rowCallback: (row: Node, _data: any[] | Object, _index: number) => {
        // Unbind first in order to avoid duplicate handler
        $('td', row).unbind('click');
        $('td', row).bind('click', function () {
          // row click event
          console.log('row click');
        }.bind(this));
      }
    };

    optionObject['columns'] = this.getColumn(user);

    this.dtOptions = optionObject;

    this.setSearchValueFromSavedState();
  }
  datatablesError(xhr, _error, _thrown) {
    const errorStatus = xhr.status;
    if (errorStatus == 401) {
      this.router.navigate(['/login']);
    }
    else if (errorStatus == 404) {
      this.errorString = 'Invalid Url';
    }
    else {
      this.errorString = 'Problem occurred. Please contact your administrator';
    }
  }

  search() {
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => {
        dtInstance.search(this.valueToSearch).draw();
      }
    );
  }

  searchString(searchValue) {
    if (searchValue.target) {
      this.valueToSearch = searchValue.target.value;
      this.search();
    }
  }

  viewUserInfo(info: any) {
    this.router.navigate(['/edit/systemaccount', info.user_id]);
  }

  private getColumn(user: any) {
    let columns;

    if (user.user_type.type.toLowerCase() === 'admin') {
      columns = [
        {
          title: 'Label',
          orderable: false,
          render: function (_data, _type, row, _meta) {
            return `<span>${$.fn.dataTable.render.text().display(row.first_name)}</span>`;
          }
        },
        {
          title: 'Email',
          orderable: true,
          render: function (_data, _type, row, _meta) {
            return `<span>${$.fn.dataTable.render.text().display(row.email)}</span>`;
          }
        },
        {
          title: 'Status',
          orderable: false,
          render: function (_data, _type, row, _meta) {
            return `<label style="color: ${row.active? 'green': 'red'}">${row.active? 'Enabled': 'Disabled'}</label>`;
          }
        },
        {
          title: '',
          orderable: false,
          render: function (_data, _type, row) {
            let cell = '';
            if (!row.active) cell += `<div class="text-center" ><a href="javascript:void(0)" class="btn btn--green btn--mini margin--l-x" triggerData="${row.user_id}">Enable</a>`;
            if (row.active) cell += `<div class="text-center" ><a href="javascript:void(0)" class="btn btn--red btn--mini margin--l-x" triggerData="${row.user_id}">Disable</a>`;
            cell += `<a href="javascript:void(0)" class="btn btn--blue btn--mini margin--l-x" viewData="${row.user_id}">View</a> </div>`;
            return cell;
          }
        }
      ];
    }
  
    return columns;
  }

  setSearchValueFromSavedState() {

    const state = localStorage.getItem('DataTables_systemaccount_/system-accounts');

    if (state == null) {
      this.searchValue = '';
      return;
    }

    const searchValue = JSON.parse(state).search['search'];

    if (searchValue != null) {

      this.searchValue = searchValue;

    }
  }

  ngAfterViewInit(): void {
    this.renderer.listen('document', 'click', (event: any) => {
      // user actions
      if (event.target.hasAttribute('triggerData')) {
        const userId = parseInt(event.target.getAttribute('triggerData'));
        const data = find(this.allUsers, { user_id: userId });
        if (data) {
          this.showConfirmation(`Are you sure you want to ${data.active? 'disable': 'enable'} this account?`,function(){
            this.userService.activeOrDeactiveUser({ user_id: userId, active: !data.active }).subscribe(
              (_result) => {
                this.reloadData();
                this.popUpService.hideDiv('confirmDialog');
                this.showAlert('Account updated sucessfully.', function () {
                  this.popUpService.hideDiv('alertDialog');
                }.bind(this));
              }, error => {
                this.showAlert(this.userService.handleError(error, this.operation.toLowerCase()));
                this.popUpService.hideDiv('confirmDialog');
              }
            );
          }.bind(this));
        }
      }

      // view use info
      if (event.target.hasAttribute('viewData')) {
        const userId = parseInt(event.target.getAttribute('viewData'));
        const data = find(this.allUsers, { user_id: userId });
        if (data) {
          this.viewUserInfo(data);
        }
      }
    });
  }

  private showConfirmation(message: string, positiveAction?: Function, negativeAction?: Function) {

    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Confirmation',
      message,
      'Yes',
      'No',
      function () {
        this.hideDiv('confirmDialog');
      },
      function () {
        this.hideDiv('confirmDialog');
      }
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(
        positiveAction
      );
    }

    if (negativeAction) {
      this.popUpService.setNegativeAction(
        negativeAction
      );
    }

    const div = this.popUpService.getPopUpView('confirmDialog');
    this.popUpService.showDiv(div);

  }

  public showAlert(message: string, positiveAction?: Function) {
    this.popUpService.destoryConfirmDialog();

    this.popUpService.setDialogParams(
      'Alert',
      message,
      'Ok'
    );

    if (positiveAction) {
      this.popUpService.setPositiveAction(positiveAction);
    }
    else {
      this.popUpService.setPositiveAction(function () {
        this.hideDiv('alertDialog');
      });
    }

    const dialog = this.popUpService.getPopUpView('alertDialog');
    if (dialog != null) {this.popUpService.showDiv(dialog);}
  }

  reloadData() {
    const user = this.loginService.getCurrentUser();
    const url = ConfigAssetLoaderService.getApiURL() + '/user/role/9?type=' + user.user_type.type + '&userid=' + user.user_id;
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => {
        // abort previous api call
        dtInstance.settings()[0].jqXHR.abort();
        dtInstance.ajax.url(url).load();
      }
    );
  }
}

/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

export interface Configuration {
  production: boolean;
  apiUrl: string;
  domainUrl: string;
  bucketName: string;
  socketUrl: string;
  REOPhaseDeploymentTime: string;
}

export const SocketConfig = {url: 'https://badSocket.infinitemd.com', options: {transports: ['websocket']}};

export const CONFIG_URL = 'assets/config.json';

@Injectable({ providedIn: 'root' })
export class ConfigAssetLoaderService {

  constructor(private http: HttpClient) {}

  load(){
    return this.http.get<Configuration>(CONFIG_URL)
      .pipe(
        tap(result => {
          environment.production = result.production;
          environment.apiUrl = result.apiUrl;
          environment.domainUrl = result.domainUrl;
          environment.bucketName = result.bucketName;
          environment.socketUrl = result.socketUrl;
          environment.REOPhaseDeploymentTime = result.REOPhaseDeploymentTime;
          SocketConfig.url = result.socketUrl;
        })
      )
      .toPromise();
  }

  public static getProduction() {
    return environment.production;
  }

  public static getApiURL() {
    return environment.apiUrl;
  }

  public static getDomainURL() {
    return environment.domainUrl;
  }

  public static getSocketURL() {
    return environment.socketUrl;
  }

  public static getS3BucketName() {
    return environment.bucketName;
  }

  public static getREODeploymentTime() {
    return environment.REOPhaseDeploymentTime;
  }
}
